import React, { useMemo } from "react";
import Circle001 from "./Circle001";
import Circle002 from "./Circle002";
import Circle003 from "./Circle003";
import Circle004 from "./Circle004";

interface Props {
    id?: string;
    effectId: number;
    width: number | string;
    style?: React.CSSProperties;
}

export const AvatarEffectType = {
    None: 0,
    Login: 1,
    Sitting: 2,
    Waiting: 3,
    GoToSee: 4, // 「会いに行く」での強調表示で使用
} as const;

const Frame: React.FC<{ id?: string, width?: number | string, style?: React.CSSProperties }> = ({ id, width, style, children }) => (
    <div
        id={id}
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: width || '100%',
            height: width || '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...style
        }}>
        {children}
    </div>
)

export default function AvatarEffect(props: Props) {
    const { id, effectId, width, style } = props;

    const component = useMemo(() => (
        <Frame id={id} width={width} style={style}>
            <Circle001 isAnimate={effectId === AvatarEffectType.Login} width={width}/>
            <Circle002 isAnimate={effectId === AvatarEffectType.Sitting} width={width} />
            <Circle003 isAnimate={effectId === AvatarEffectType.Waiting} width={width}/>
            <Circle004 isAnimate={effectId === AvatarEffectType.GoToSee} width={width}/>
        </Frame>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [effectId, width]);

    return component;
}
import { Button, createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChildTestRef} from './ChildTest';

const styles = (theme: Theme): StyleRules => createStyles({
    root: {
        // display: 'flex',
        width: '100%',
        height: '100%',
    },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
    dummy: string,
}

class ParentTest extends React.Component<Props, State> {
    
    private ref: any;

    constructor(props: Props) {
        super(props);

        this.setState({
            dummy: "aaaa",
        })

        this.ref = React.createRef();
    }

    componentDidMount() {
    }

    handleClick = () => {
        this.ref.current?.plus();
        let users = this.ref.current?.getUsers();
        console.log(users);
        users[0].ping++;
        users[0].ping++;
        // this.ref.current?.updateUsers(users);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {console.log("ParentTest:render")}
                <div>{this.state?.dummy}</div>
                ParentTest
                <ChildTestRef dummy={"Parent to child"} ref={this.ref} />
                <Button onClick={this.handleClick}>TEST</Button>
            </div>  
        )
    }
}

export default withStyles(styles)(ParentTest);
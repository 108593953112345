import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import ClearIcon from '@material-ui/icons/Clear';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SELECT_NONE } from "../common/Constants";
import TextField from '@material-ui/core/TextField';

interface Props {
    className?: string,
    isOpen: boolean,
    floorObjectId: number,
    title: string,
    text1: string,
    text2: string,
    maxLength: number,
    isPlayAudio?: boolean,
    onClose: () => void,
    sendText: (id: number, text1: string, text2: string, isPlayAudio: boolean) => void
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    backgroundColor: 'rgba(0, 0, 255, 1)',
};

export default function EditText1Text2Dialog(props: Props) {
    const defaultProps = {
        isPlayAudio: false
    }
    const { className, isOpen, floorObjectId, title, text1, text2, maxLength } = props;
    const isPlayAudio = props.isPlayAudio ?? defaultProps.isPlayAudio;
    const text1MaxLength = 6;
    const [inputText1, setinputText1] = useState(text1);
    const [inputText2, setinputText2] = useState(text2);
    const [temptext1, setTemptext1] = useState(text1);
    const [temptext2, setTemptext2] = useState(text2);
    const [dialogWidth, setDialogWidth] = useState("480px");

    useEffect(() => {
        setinputText1(text1);
        setinputText2(text2);
        setTemptext1(inputText1);
        setTemptext2(inputText2);
        return () => {
            setTemptext1(inputText1);
            setTemptext2(inputText2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, text1, text2]);

    // useEffect(() => {
    //     // ダイアログの幅を設定
    //     if(maxLength === 18){
    //         setDialogWidth("480px")
    //     }
    // }, [maxLength, dialogWidth])

    const onOk = useCallback(() => {
        let len1 = judgehz(inputText1)
        let len2 = judgehz(inputText2)
        if (len1 > text1MaxLength || len2 > maxLength) {
            return;
        }
        props.sendText(floorObjectId, inputText1, inputText2, isPlayAudio);
        props.onClose();
        setTemptext1(inputText1);
        setTemptext2(inputText2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, floorObjectId, inputText1, inputText2, text1, text2, isPlayAudio]);

    const onClear = useCallback(() => {
        props.sendText(floorObjectId, '', '', isPlayAudio);
        props.onClose();
        setinputText1('');
        setinputText2('');
        setTemptext1('');
        setTemptext2('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, text1, text2, isPlayAudio]);

    const onClearText1 = useCallback(() => {
        setinputText1('');
        setTemptext1('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, text1, temptext2, isPlayAudio]);

    const onClearText2 = useCallback(() => {
        setinputText2('');
        setTemptext2('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, text2, temptext1, isPlayAudio]);

    const onClearOnlyText2 = useCallback(() => {
        props.sendText(floorObjectId, inputText1, '', isPlayAudio);
        props.onClose();
        setinputText1(inputText1);
        setinputText2('');
        setTemptext2('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, text2, temptext1, isPlayAudio]);

    const onCancel = useCallback(() => {
        props.onClose();
        setinputText1(temptext1);
        setinputText2(temptext2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temptext2, temptext1]);

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }

        return len
    };

    const text1Length = useMemo(() => {
        let len = judgehz(inputText1)
        if (text1MaxLength < len) {
            return (
                <div style={{ color: "red" }}>入力した文字列が長すぎます。</div>
            );
        }
    }, [inputText1]);

    const text2Length = useMemo(() => {
        let len = judgehz(inputText2)
        if (maxLength < len) {
            return (
                <div style={{ color: "red" }}>入力した文字列が長すぎます。</div>
            );
        }
    }, [maxLength, inputText2]);

    const progressBarText1 = useMemo(() => {
        let parsent = (judgehz(inputText1) / text1MaxLength) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = 'rgba(0, 0, 255, 0.3)';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {text1Length}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <LinearProgress
                        variant="determinate"
                        value={parsent}
                        style={{
                            ...LinearProgressStyle,
                            backgroundColor: progressColor,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }, [inputText1])

    const progressBarText2 = useMemo(() => {
        let parsent = (judgehz(inputText2) / maxLength) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = 'rgba(0, 0, 255, 0.3)';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {text2Length}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <LinearProgress
                        variant="determinate"
                        value={parsent}
                        style={{
                            ...LinearProgressStyle,
                            backgroundColor: progressColor,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }, [inputText2])

    const draw = useMemo(() =>
        <BaseDialog
            className={className}
            open={isOpen}
            onClose={() => props.onClose()}
            aria-labelledby='editTtext2DialogTitle'
            // fullWidth={true}
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    minWidth: dialogWidth,
                },
            }}
        >
            <DialogTitle id='editTtext2DialogTitle' style={{ padding: "15px 25px 5px 25px" }} >{title}</DialogTitle>
            <DialogContent style={{ padding: "0px 25px 0px 25px", overflow: "hidden" }}>
                <DialogContentText style={{ marginBottom: "0px" }} >
                    {progressBarText1}
                </DialogContentText>
                <TextField
                    id="outlined-basic"
                    label="タイトル"
                    variant="outlined"
                    // multiline
                    // autoFocus
                    margin='dense'
                    fullWidth
                    value={inputText1}
                    autoComplete={'off'}
                    onChange={e => setinputText1(e.target.value)}
                    style={{ pointerEvents: 'auto' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onClearText1}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>

            <DialogContent style={{ padding: "5px 25px 0px 25px" }}>
                <DialogContentText style={{ marginBottom: "0px" }}>
                    {progressBarText2}
                </DialogContentText>
                <TextField
                    id="outlined-basic"
                    label="内容"
                    variant="outlined"
                    multiline
                    autoFocus
                    margin='dense'
                    fullWidth
                    value={inputText2}
                    autoComplete={'off'}
                    onChange={e => setinputText2(e.target.value)}
                    style={{ pointerEvents: 'auto' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onClearText2}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={onClear} color='primary' style={{ pointerEvents: 'auto' }}>すべて消去</Button>
                <Button onClick={onClearOnlyText2} color='primary' style={{ pointerEvents: 'auto' }}>内容のみ消去</Button> */}
                <Button onClick={onOk} color='primary' style={{ pointerEvents: 'auto' }}>OK</Button>
                <Button onClick={onCancel} color='primary' style={{ pointerEvents: 'auto' }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isOpen, floorObjectId, title, text1, text2, inputText1, inputText2, temptext2])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )

}
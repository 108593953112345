import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Page from './common/Page';

// import MaxWidthTest5 from './test/MaxWidthTest5';
// import Login from './user/Login';
import Floor from './user/Floor'
import Top from './user/Top'
import Signout from './user/Signout'
import ParentTest from './react-test/ParentTest';
import AddUser from './test/AddUser';
import TestApi from './test/TestApi';
import MobileLogin from './user/mobile/MobileLogin';
import MobileTop from './user/mobile/MobileTop';
import NewAddUser from './test/NewAddUser';
import NewUpdateUser from './test/NewUpdateUser';
import NewDeleteUser from './test/NewDeleteUser';
import UserList from './admin/UserList';
import Teacher from './admin/Teacher';
import CopyFloor from './test/CopyFloor';
import LoginTest from './user/Login2';
import SingleSignOn from './user/SingleSignOn';
import { useTranslation } from 'react-i18next';
import SystemTop from './system/SystemDashboard';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './locales/en.json';
import jaJson from './locales/ja.json';
import SystemTenantList from './system/SystemTenantList';
import SystemEditFloorList from './system/SystemEditFloorList';
import SystemFloorList from './system/SystemFloorList';
import EventLogin from './user/EventLogin';
import SelectDevice from './user/SelectDevice';
import ChangePassword from './user/ChangePassword';
import ResetPassword from './user/ResetPassword';
import SystemYouTube from './system/SystemYouTubeSetting';
import { printable } from './log/LogSetting'
import NotFound from './user/NotFound';
import PrivateRoute from './common/PrivateRoute';
import TabId from './user/TabId';
import EventRegist from './test/EventRegist'
import SigninPage from './user/SigninPage';
import SystemDeployFunction from './system/SystemDeployFunction';

if(printable == 0){
  console.log = function(){}
  console.warn = function(){}
  console.error = function(){}
}


i18n.use(initReactI18next).init({
  debug: true,
  resources: {
    en: { translation: enJson },
    ja: { translation: jaJson },
  },
  lng: 'ja',
  fallbackLng: false,
  returnEmptyString: false,
});

const App: React.FC = () => {
  const [t, i18n] = useTranslation();
  const [lang] = useState('ja');

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' render={ () => Page(t("ログイン"), LoginTest)} />
          <PrivateRoute path='/floor' component={Floor} />
          <Route path='/top' render={() => Page("トップ", Top)} />
          <Route path='/signinpage' render={() => Page("ログイン", SigninPage)} />
          <Route path='/signin/:registrationId' render={() => Page("シングルサインオン", SingleSignOn)} />
          <Route path='/EBTejp7CkaesyGIV/:registrationId' render={() => Page("シングルサインオン(メンテ用)", SingleSignOn)} />
          <Route path='/signout' render={() => Page("ログアウト", Signout)} />
          <Route path='/changepassword' render={() => Page("パスワード変更", ChangePassword)} />
          <Route path='/resetpassword' component={ResetPassword} />
          <Route path='/test' component={ParentTest} />
          <Route path='/adduser' render={() => Page("ユーザー登録", AddUser)} />
          <Route path='/testapi' render={() => Page("App Api Test", TestApi)} />
          <Route path='/mobilelogin' component={MobileLogin} />
          <Route path='/mobiletop' render={ () => Page("ログイン", MobileTop)} />
          <Route path='/NewAddUser' render={() => Page("新ユーザー登録", NewAddUser)} />
          <Route path='/NewUpdateUser' render={() => Page("新ユーザー更新", NewUpdateUser)} />
          <Route path='/NewDeleteUser' render={() => Page("新ユーザー削除", NewDeleteUser)} />
          <Route path='/userlist' render={() => Page("管理機能画面", UserList)} />
          <Route path='/teacher' render={() => Page("先生機能画面", Teacher)} />
          <Route path='/copyfloor' component={CopyFloor} />
          <Route path='/systemtop' component={SystemTop} />
          <Route path='/systemtenant' component={SystemTenantList} />
          <Route path='/systemEditFloorList' component={SystemEditFloorList} />
          <Route path='/systemfloor' component={SystemFloorList} />
          <Route path='/systemyoutube' component={SystemYouTube} />
          <Route path='/systemdeployfunction' component={SystemDeployFunction} />
          <Route path='/eventlogin' render={() => Page("ログイン", EventLogin)} />
          <Route path='/selectdevice' component={SelectDevice} />
          <Route path='/tabid' component={TabId} />
          <Route path='/eventregist' render={() => Page("イベント登録", EventRegist)} />
          <Route render={() => Page("Not Found", NotFound)} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

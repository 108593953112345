import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LoginJson } from '../../common/JsonClass';
import { RouteComponentProps } from 'react-router-dom';
import { Utility } from '../../common/Utility';
import theme from './../../test/Thema';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://fsi.co.jp/famoffice/">
        FAMoffice
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(./api/service/picture)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Props extends RouteComponentProps {
}

export default function MobileLogin(props: Props) {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { history } = useReactRouter();

  const recaptcha = React.useRef(0);
  const recaptchaToken = React.useRef("");

  const [loginState, setLoginState] = useState({
    id: "",
    password: "",
  })
  const mobileAppCodeComponent = require('./mobileAppCode');
  //sessionStorage.setItem("SIGNINPAGE", "/mobilelogin")
  Utility.setSigninPage("/mobilelogin");

  useEffect(() => {
    function preLogin() {
      var params = new URLSearchParams();
      axios.post('/login/prelogin', params)
        .then((e: AxiosResponse) => {
          // console.log("prelogin OK!");
          getRecaptcha();
        }).catch(err => {
          console.log(err);
        });
    }
    function getRecaptcha() {
      var params = new URLSearchParams();
      axios.post('/login/recaptcha', params)
        .then((e: AxiosResponse) => {
          // console.log("recaptcha OK!");
          recaptcha.current = e.data;
        }).catch(() => {
        });
    }

    preLogin();
    let query: string = props.location.search;
    if (query !== "") {
      let id = Utility.getQueryParam(query, "id");
      let pass = Utility.getQueryParam(query, "pass");
      setLoginState({
        id: id,
        password: pass,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      id: event.target.value,
    })
  }
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      password: event.target.value,
    })
  }
  const handleLogin = async () => {

    function login() {
      let tabID = new Date().getTime();
      sessionStorage.setItem("TABID", tabID.toString());
      let params = new URLSearchParams();
      params.append('id', loginState.id);
      params.append('pass', loginState.password);
      params.append('token', "v3-" + recaptchaToken.current);
      params.append('tabid', tabID.toString());
      axios.post('/api/login', params)
        .then(response => {
          let loginJson = response.data as LoginJson;
          let cpmflag = true;
          sessionStorage.setItem("TABID", loginJson.tabId);
          if(response.data.webSocketUrl){
          }else{
            mobileAppCodeComponent.didloginFailed("本アプリをご利用いただくには、ブラウザ版FAMofficeでログイン及び初期設定をお願いいたします。");
            cpmflag = false;
          }
          if (loginJson.isResetPassword && cpmflag) {
            history.push({
              pathname: "/changepassword",
              state: { type: "mobile" }
            });
          }else
          if (loginJson.action === "TOP" && cpmflag) {
              history.push("/mobiletop");
          } else if (loginJson.action === "FLOOR" && cpmflag) {
            history.push({
              pathname: "/mobiletop",
              state: { path: loginJson.webSocketUrl, id: loginJson.floorId }
            });
          }
        }).catch(err => {
          let response_msg = err.response.data;
          if(response_msg === 'BOTまたは攻撃の可能性があります。'){
            response_msg = '';
          }else{
            response_msg = 'ログインIDまたはパスワードが不正です。もう一度サインイン情報を入力してください。'
          }
          mobileAppCodeComponent.didloginFailed(response_msg);
          console.log(err);
        });
    }

    if (!executeRecaptcha) {
      return;
    }

    recaptchaToken.current = await executeRecaptcha("tinysolution");

    login();
  }

  const handleUserKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 /* 13=Enter Key */) {
      handleLogin();
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            サインイン
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="ログインID"
            name="email"
            autoComplete="email"
            autoFocus
            value={loginState.id}
            onChange={handleChangeId}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            value={loginState.password}
            onChange={handleChangePassword}
            onKeyDown={handleUserKeyDown}
          />

          <ThemeProvider theme={theme}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            id="loginbutton"
            className={classes.submit}
            onClick={handleLogin}
          >
            サインイン
            </Button>
            <p className="recaptcha_policy">This site is protected by reCAPTCHA and the Google<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
            </ThemeProvider>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
import React, { Fragment, useMemo } from 'react';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    open: boolean,
    onClick: any,
}

export default function ReleaseOtherSeat(props: Props) {

    const onOk = () => props.onClick(true);
    const onClose = () => props.onClick(false);
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter") {
            onOk();
        }
    };

    const draw = useMemo(() => {
        return (
            <BaseDialog
                id="releaseOtherSeatDialog"
                open={props.open}
                onClose={onClose}
                aria-labelledby='releaseOtherSeatDialogTitle'
                onKeyDown={handleKeyDown}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="releaseOtherSeatDialogTitle" style={{padding: '4px 25px 5px 20px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>席設定解除</DialogTitle>
                <DialogContent>この席の自席設定を解除しますか？</DialogContent>
                <DialogActions>
                    <Button onClick={onOk} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 95 }}>OK</Button>
                    <Button onClick={onClose} color="primary" style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 95 }} >キャンセル</Button>
                </DialogActions>
            </BaseDialog >
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
import React, { useEffect, useState, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useCallback } from 'react'
import axios, { AxiosResponse } from 'axios';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';
import { MemoGroup } from '../common/JsonClass';
import JfsClient from '@fsi/jfs-sdk';

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface Props {
    subId: string;// otherUserのsubId
    handleCloseParent: (event?: any) => void;
    handleOpenMemo: (groupId: number, groupName: string) => void;
    className?: string;
    style?: React.CSSProperties;
}

interface IMemoGroup extends MemoGroup {
    isClicked: boolean
}

export interface SelectMemoGroupHandler {
    getDataAndDisplay: () => void;
    getData: (subId: string) => Promise<IMemoGroup[]>;
}

// request
// const getGroupList = async (otherSubId: string) => {
//     try {
//         const res: AxiosResponse<MemoGroup[]> = await axios.post('/api/user/memo/groups', {
//             tabId: sessionStorage.getItem('TABID'),
//             otherSubId
//         });
//         const list = res.data.map(d => ({ ...d, isClicked: false }));
//         return list;
//     } catch (err) {
//         throw err;
//     }
// }

const SelectMemoGroupComponent: ForwardRefRenderFunction<SelectMemoGroupHandler, Props> = (props, ref) => {
    const [groupList, setGroupList] = useState([] as MemoGroup[]);
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const getGroupList = async (otherSubId: string) => {
        try {
            const res = await httpClient.getMemoGroupList(sessionStorage.getItem('TABID') as string, otherSubId);
            const list = res.map(d => ({ ...d, isClicked: false }));
            return list;
        } catch (err) {
            throw err;
        }
    }

    const getDataAndDisplay = async () => {
        setMessage('');
        setLoading(true);
        try {
            const data = await getGroupList(props.subId);
            if (data.length === 0) {
                // 本機能では有り得ないケース
                setMessage('メモグループは0件です。');
            } else if (data.length > 0) {
                setGroupList([...data]);
            }
        } catch (err) {
            console.error(err)
            setMessage('データの取得に失敗しました。');
        } finally {
            setLoading(false);
        }
    }

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        getDataAndDisplay() {
            getDataAndDisplay();
        },
        getData(subId: string) {
            return getGroupList(subId);
        }
    }));

    /**
     * useEffect
     */
    // memo groupデータが一件の時、メモを起動し、要素を閉じる
    useEffect(() => {
        if (groupList.length === 1) {
            const { groupId, groupName } = groupList[0];
            handleClickItem(groupId, groupName, 0);
            props.handleCloseParent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupList]);

    /**
     * event handlers
     */
    const handleClickItem = (groupId: number, groupName: string, index: number) => {
        props.handleOpenMemo(groupId, groupName);
        setSelectedIndex(index);
    }

    /**
     * draw elements
     */
    const drawGroupItem = useCallback((group: MemoGroup, index: number) => {
        const { groupId, groupName } = group;
        return (
            <ListItem
                key={`memo-group-${index}`}
                button
                dense
                selected={selectedIndex === index}
                onClick={() => handleClickItem(groupId, groupName, index)}
            >
                <ListItemText primary={groupName} />
            </ListItem>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);

    const drawGroupList = useMemo(() => {
        return (
            <List
                component='nav'
                aria-label='select memo group'
                style={{ width: '100%' }}
            >
                {groupList.map((d, index) => drawGroupItem(d, index))}
            </List>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupList, selectedIndex]);

    const drawLoading = useMemo(() => {
        return (
            <Grid container justify='center' alignItems='center' style={{ height: '100%' }}>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color='primary' />
                </ThemeProvider>
            </Grid>
        )
    }, []);

    const component = useMemo(() => {
        return (
            <div
                className={props.className}
                style={{
                    display: 'flex',
                    justifyContent: isLoading || !!message ? 'center' : 'flex-start',
                    alignItems: isLoading || !!message ? 'center' : 'flex-start',
                    width: '100%',
                    height: '100%',
                    ...props.style
                }}
            >
                {isLoading
                    ? drawLoading
                    : !!message
                        ? message
                        : drawGroupList
                }
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, message, drawGroupItem, drawGroupList]);

    return component;
}

export const SelectMemoGroup = forwardRef(SelectMemoGroupComponent);

import React, { useState, useMemo, Fragment } from 'react';
import CreateIcon from '../img/edit_B.png';
import EditTextDialog from './EditTextDialog';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";

interface Props {
    className: string,
    floorObject: FloorObject,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    role: string,
    length: number
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 15,
    lineHeight: '15px',
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

const kanbanMaxWidth = 128;

export default function DynamicKanban(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, text1, text2 } = props.floorObject;
    const { role } = props;
    const { length } = props;

    const [isOpen, setIsOpen] = useState(false);

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }

        return len
    };

    const kanbanWidth = useMemo(
        () => text2.length === 0 ? 24 : Math.min((judgehz(text2) + 0.5) * 12 + 2, kanbanMaxWidth)
        , [text2]
    );

    const kanbanContent = useMemo(
        () => text2.length === 0
            ? <img src={CreateIcon} style={{ width: 24, height: 24 }} />
            : text2
        , [text2]);

    const draw = useMemo(() =>
        <Fragment>
            <div
                key={`dynamicKanban${id}`}
                id={`dynamicKanban${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...kanbanTextStyle,
                    left: offsetLeft,
                    top: offsetTop,
                    minWidth: kanbanWidth,
                    maxWidth: kanbanMaxWidth,
                    minHeight: text2.length === 0 ? 24 : 15,
                    marginTop: text2.length === 0 ? -4 : 0,
                    border: text2.length === 0 ? "none" : "1px solid #AAA",
                    backgroundColor: text2.length === 0 ? "rgba(0,0,0,0)" : "#ffffff",
                }}
            >
                {kanbanContent}
            </div>

            <EditTextDialog
                className={className}
                isOpen={isOpen && (role.match(/ROLE_ADMIN/) != null)}
                floorObjectId={id}
                title={text1 ? `${text1}: 表示内容` : '表示内容'}
                text={text2}
                maxLength={length}
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [text1, text2, isOpen])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
import { Box, Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Theme } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { TenantInfo, Office, Floor, FloorInfo, objctRegistCheck } from '../common/JsonClass';
import { errorMonitor } from 'stream';
import { AlarmTwoTone } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
    }),
);

interface Props {
    masterData: {[index: string]:[{id:number, name:string}]};
}

export interface SystemRegistFloorHandler {
    open: (id: number) => void;
}

// export default function OtherUsers() {
const SystemRegistFloorComponent: ForwardRefRenderFunction<SystemRegistFloorHandler, Props> = (props, ref) => {
    const [dispType, setDispType] = useState("登録");
    const [dispInputTitle, setDispInputTitle] = useState("none");
    const [dispTenantName, setDispTenantName] = useState("none");
    const [dispOfficeName, setDispOfficeName] = useState("none");
    const [dispFloorName, setDispFloorName] = useState("none");
    const [dispFloorInfo, setDispFloorInfo] = useState("none");
    const [dispFileSelect, setDispFileSelect] = useState("none");
    const classes = useStyles();
    const { history } = useReactRouter();
    const [open, setOpen] = useState(false);
    const [selectFloorId, setSelectFloorId] = useState(0);
    const [tenantList, setTenantList] = React.useState([] as TenantInfo[]);
    const [officeList, setOfficeList] = React.useState([] as Office[]);
    const [floorList, setFloorList] = React.useState([] as Floor[]);
    const [floorInfo, setFloorInfo] = React.useState(new Floor());
    const [floorId, setFloorId] = useState("0");
    const [uploadCSVFile, setUploadCSVFile] = React.useState({});   /**　CSVアップロード用 */
    const [dealType, setDealType] = useState("");
    const [objNotRegistOpened, setObjNotRegistOpened] = useState(false);
    const [objNotRegistList, setObjNotRegistList] = React.useState([] as objctRegistCheck[]);
    const [dispfloorSetting, setDispFloorSetting] = useState("none");
    const [dispModifyfloorInfo, setDispModifyfloorInfo] = useState("none");
    const [ state, setState ] = useState({
        functionType: 0,
        tenantId: '',
        officeId: '',
        selectFloorName: '',
        floorId: '',
        csvFilePath: '',
        textFloorName: '',
        textFloorHeight: 0,
        textFloorWidth: 0,
        enabledMemoButton: true,
        enabledWhiteBoardButton: true,
        enabledScreenShareButton: true,
        enabledFloor: true,
        requireCamera: false,
        requireMic: false,
        textFloorOrder: '',
    });

    // テナント追加の入力データをクリア
    const clearState = () => {
        setState({...state, 
            functionType: 0, 
            tenantId: "", 
            officeId: "", 
            selectFloorName: "", 
            floorId: "", 
            csvFilePath: "",
            textFloorName: "", 
            textFloorHeight: 0,
            textFloorWidth: 0,
            enabledMemoButton: true,
            enabledWhiteBoardButton: true,
            enabledScreenShareButton: true,
            enabledFloor: true,
            requireCamera: false,
            requireMic: false,
            textFloorOrder: "",
        })
        setDealType("");
        setDispInputTitle("none");
        setDispTenantName("none");
        setDispOfficeName("none");
        setDispFloorName("none");
        setDispFloorInfo("none");
        setFloorId("0");
        setDispFileSelect("none");
        setDispType("登録");
        setDispFloorSetting("none");
        setDispModifyfloorInfo("none");
    }

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id.toString())
            setSelectFloorId(id);
            setOpen(true);
        },
    }))

    useEffect(() => {
        handleGetTenantName();
    }, [])

    const handleCheck = (value: any):void => {
        // alert("check " + value.target.name + ":" +value.target.checked);
        setState(() => {
            return {...state, [value.target.name]: value.target.checked };
        });
    };

    // セレクトボックス用のテナント名取得
    const handleGetTenantName = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/tenant/list', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                keyword: "%%" as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as TenantInfo[];
                setTenantList(result);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("TenantSearch error.");
                    console.log(err.response);
                }
            });
    }

    // セレクトボックス用のオフィス名取得
    const handleGetOfficeInfo = (tenantId:number) => {
        // 選択したテナントIDからオフィス一覧を取得
        // alert("handleGetOfficeInfo   tenantId:" + tenantId);
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/office/list', new URLSearchParams({
                tenantId: tenantId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as Office[];
                setOfficeList(result);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("OfficeSearch error.");
                    console.log(err.response);
                }
            });
    }

    // セレクトボックス用のフロア名取得
    const handleGetFloorInfo = (officeId:number) => {
        // 選択したオフィスIDからフロア一覧を取得
        // alert("handleGetFloorInfo   officeId:" + officeId);
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floor/list', new URLSearchParams({
                officeId: officeId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as Floor[];
                setFloorList(result);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("FloorSearch error.");
                    console.log(err.response);
                }
            });
    }
    // セレクトボックス用のフロア情報取得
    const handleGetFloorInfoById = (floorId:number) => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floor/info', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                floorId: floorId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as FloorInfo;
                setFloorInfo(result);
            })
            .catch(() => {
                console.log("login error.");
            });
    }

/** 
 * CSVアップロード（未登録チェック用）
 */
 const uploadCSVCheck = (floorId:string) => {
    // alert("uploadCSVFile (as File):" + (uploadCSVFile as File));
    const params = new FormData();
    params.append('tabId', (sessionStorage.getItem("TABID") as string));
    params.append('file', (uploadCSVFile as File));
    params.append('div', dealType);
    params.append('floorId', floorId)
    axios.post('/api/system/createFloor/read/checkCsv', params,
    {
        headers: { "Content-Type": "multipart/form-data" },
    }
    )
    .then((response: AxiosResponse) => {
        let result = response.data as objctRegistCheck[];
        setObjNotRegistList(result);
        setObjNotRegistOpened(true);
    }).catch(err => {
        console.log(err);
    });
}

    /** 
     * CSVアップロード
     */
    const uploadCSV = (floorId:string) => {
        // alert("uploadCSVFile (as File):" + (uploadCSVFile as File));
        const params = new FormData();
        params.append('tabId', (sessionStorage.getItem("TABID") as string));
        params.append('file', (uploadCSVFile as File));
        params.append('div', dealType);
        params.append('floorId', floorId)
        axios
            .post('/api/system/createFloor/read/csv', params, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((result: AxiosResponse) => {
                console.log(result.data['code']);
                // alert("code:" + result.data['code']);
                // alert("message:" + result.data['message']);
                if (result.data['code'] === '0000') {
                    if(dealType === "object"){
                        alert("オブジェクトマスタの登録が完了しました。");
                    } else if (dealType === "floor"){
                        alert("フロア 「" + state.textFloorName + "」 の登録が完了しました。");
                    } else if (dealType === "floorobject"){
                        alert("フロア 「" + floorInfo.floorName + "」 の更新が完了しました。");
                    }
                }else if (result.data['code'] === '9999') {
                    alert("指定したファイルにデータが存在しないため、登録を中止しました。");
                }else{
                    if(dealType === "object"){
                        alert("オブジェクトマスタの登録に失敗しました。");
                    } else if (dealType === "floor"){
                        alert("フロア 「" + state.textFloorName + "」 の登録に失敗しました。");
                    } else if (dealType === "floorobject"){
                        alert("フロア 「" + floorInfo.floorName + "」 の更新に失敗しました。");
                    }
                }
            }).catch(err => {
                console.log(err);
                if(dealType === "object"){
                    alert("オブジェクトマスタの登録に失敗しました。");
                } else if (dealType === "floor"){
                    alert("フロア 「" + state.textFloorName + "」 の登録に失敗しました。");
                } else if (dealType === "floorobject"){
                    alert("フロア 「" + floorInfo.floorName + "」 の更新に失敗しました。");
                }
        });
    }

    const handleClose = () => {
        clearState();
        setSelectFloorId(0);
        setObjNotRegistOpened(false);
        setOpen(false);
    };

    // フロア情報登録処理
    const handleRegistFloor = () => {
        switch(state.functionType){
            case 1:
                // オブジェクトマスタ登録処理
                handleAddObjectMaster();
                break;
            case 2:
                // フロア・フロアオブジェクト登録処理
                handleAddFloorObject();
                break;
            case 3:
                // フロアオブジェクト出力処理
                handleOutputFloorObject();
                break;
            case 4:
                // フロアオブジェクト更新処理
                handleUploadFloorObject();
                //handleRegistFloorObject();
                break;
            case 5:
                // フロアオブジェクト削除処理
                handleDeleteFloor();
                break;
            case 6:
                // フロア情報更新処理
                handleModifyFloorInfo();
                break;
            case 7:
                // オブジェクトマスタ・シート出力処理
                handleOutputObjectMasterSeat();
                break;
            default:
                alert("処理を選択してください。");
                return;
        }

        if(state.functionType !== 2 && state.functionType !== 6){
            clearState();
        }
        setObjNotRegistList([]);
        setSelectFloorId(0);
        setOpen(false);
    };

    // オブジェクトマスタ登録処理
    const handleAddObjectMaster = () => {
            // alert("csvFilePath:" + state.csvFilePath);
            if(state.csvFilePath !== ""){
                if(window.confirm("選択したデータをオブジェクトマスタに登録します。よろしいですか？")){
                        // csvファイルのアップロードとobject_master,object_seatの登録
                        uploadCSV(floorId);
                }else{
                    alert("キャンセルしました。");
                }
            }else{
                alert("入力必須項目が未入力のため、登録できません。");
                return;
            }
    };

    // フロア・フロアオブジェクト登録処理
    const handleAddFloorObject = () => {
            // alert("handleAddFloorObject");
            // alert("officeId:" + state.officeId);
            // alert("textFloorName:" + state.textFloorName);
            // alert("textFloorSize:" + state.textFloorHeight + ":" + state.textFloorWidth);
            // alert("csvFilePath:" + state.csvFilePath);

            if( (state.officeId !== "") && (state.textFloorName !== "") && (state.csvFilePath !== "") ){
                handleCheckObject();
            }else{
                alert("入力必須項目が未入力のため、登録できません。");
                clearState();
                return;
            }
    };

    // CSVファイル名取得
    const getFileName = (contentDisposition: string) => {
        let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
          contentDisposition.length
        );
        //デコードするとスペースが"+"になるのでスペースへ置換します
        fileName = decodeURI(fileName).replace(/\+/g, " ");
        return fileName;
    }

    // フロアオブジェクト出力処理
    const handleOutputFloorObject = () => {
        // alert("officeId:" + state.officeId + "  floorName:" + floorInfo.floorName);
        if(floorInfo.floorName !== ""){
            if(window.confirm("フロア「" + floorInfo.floorName + "」 の構成を出力します。よろしいですか？")){
                // フロア存在チェック
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/floor/exist', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        officeId: state.officeId.toString().trimEnd(),
                        floorName: floorInfo.floorName.toString().trimEnd(),
                    }))
                    .then((response: AxiosResponse) => {
                        // alert("selectFloorId:" + selectFloorId.toString());
                        let result = response.data as number;
                        // 出力対象が存在する場合、出力処理
                        if(result > 0){
                            axios
                                .get('/api/system/floorobject/output', {
                                    params: {
                                        floorId: selectFloorId.toString().trimEnd(),
                                        floorName:floorInfo.floorName, 
                                        tab_id: sessionStorage.getItem("TABID") as string,
                                    },
                                })
                                .then((e: AxiosResponse) => {
                                    //レスポンスヘッダからファイル名を取得
                                    const contentDisposition = e.headers["content-disposition"];
                                    const fileName = getFileName(contentDisposition);
                                    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                                    const blob = new Blob([bom, e.data], { type: 'text/csv' });
                                    const url = window.URL.createObjectURL(blob);
                            
                                    //ダウンロード
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', fileName);
                                    document.body.appendChild(link);
                                    link.click();
                                    alert("フロア「" + floorInfo.floorName + "」 の出力が完了しました。");
                                }).catch(() => {
                                    alert("フロア「" + floorInfo.floorName + "」 の出力に失敗しました。");
                                });
                                clearState();
                                setSelectFloorId(0);
                                setOpen(false);
                        }else{
                            alert("フロア「" + floorInfo.floorName + "」 は存在しません。");
                        }

                    })
                    .catch(() => {
                        alert("出力対象のデータが見つかりませんでした。");
                        setSelectFloorId(0);
                        setOpen(false);
                    });
            }else{
                alert("キャンセルしました。");
            }
        }else{
            alert("入力必須項目が未入力のため、削除できません。");
            return;
        }
    };

    // フロアオブジェクト登録処理
    const handleUploadFloorObject = () => {
        // alert("floorName:" + floorInfo.floorName);
        // alert("csvFilePath:" + state.csvFilePath);

        if( (floorInfo.floorName !== "") && (state.csvFilePath !== "") ){
            if(window.confirm("フロア「" + floorInfo.floorName + "」 を更新します。よろしいですか？\n【注意】対象フロアが稼働していないことを確認の上、実行してください。")){
                // 更新前にバックアップCSVを出力
                axios
                .get('/api/system/floorobject/output', {
                    params: {
                        floorId: selectFloorId.toString().trimEnd(),
                        floorName:floorInfo.floorName + "_backup", 
                        tab_id: sessionStorage.getItem("TABID") as string,
                    },
                })
                .then((e: AxiosResponse) => {
                    //レスポンスヘッダからファイル名を取得
                    const contentDisposition = e.headers["content-disposition"];
                    const fileName = getFileName(contentDisposition);
                    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                    const blob = new Blob([bom, e.data], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
            
                    //ダウンロード
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    alert("フロア「" + floorInfo.floorName + "」 のバックアップ用CSVを出力しました。\n" + 
                        "更新前に戻す場合は、本機能を使ってバックアップCSVを登録してください。\n" + 
                        "続けて、フロアオブジェクトの登録を行います。");

                    // フロアオブジェクト登録
                    uploadCSV(selectFloorId.toString().trimEnd());

                }).catch(() => {
                    alert("フロア「" + floorInfo.floorName + "」 のバックアップに失敗しました。");
                });

            }else{
                alert("キャンセルしました。");
            }
        }else{
            alert("入力必須項目が未入力のため、登録できません。");
            return;
        }
    };

    // フロアオブジェクト登録処理
    const handleRegistFloorObject = () => {
        // alert("floorName:" + floorInfo.floorName);
        // alert("csvFilePath:" + state.csvFilePath);

        if( (floorInfo.floorName !== "") && (state.csvFilePath !== "") ){
            if(window.confirm("フロア「" + floorInfo.floorName + "」 を更新します。よろしいですか？")){
                // 更新前にバックアップCSVを出力
                axios
                .get('/api/system/floorobject/output', {
                    params: {
                        floorId: selectFloorId.toString().trimEnd(),
                        floorName:floorInfo.floorName + "_backup", 
                        tab_id: sessionStorage.getItem("TABID") as string,
                    },
                })
                .then((e: AxiosResponse) => {
                    //レスポンスヘッダからファイル名を取得
                    const contentDisposition = e.headers["content-disposition"];
                    const fileName = getFileName(contentDisposition);
                    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                    const blob = new Blob([bom, e.data], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
            
                    //ダウンロード
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    alert("フロア「" + floorInfo.floorName + "」 のバックアップ用CSVを出力しました。\n" + 
                        "更新前に戻す場合は、本機能を使ってバックアップCSVを登録してください。\n" + 
                        "続けて、フロアオブジェクトの登録を行います。");

                    // 既存フロアオブジェクトの削除
                    axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/floor/delete', new URLSearchParams({
                        floorId: selectFloorId.toString().trimEnd(),
                        deleteFloorTable: "0",          // "0":フロア自体は削除しない
                    }))
                    .then((response: AxiosResponse) => {
                        let msg = response.data as String;
                        // alert("result:" + msg);
                        // 削除OKならCSV登録処理へ
                        if(msg === "OK"){
                            // フロアオブジェクト登録
                            uploadCSV(selectFloorId.toString().trimEnd());

                        }else if (msg.startsWith("ERR") || msg === "NG") {
                            if(msg === "ERR01"){
                                alert("フロア「" + floorInfo.floorName + "」 の削除に失敗しました。\n対象フロアがuser_info.lastfloor_idに設定されています。");
                            }
                        }
                    })
                    .catch(() => {
                        alert("フロア「" + floorInfo.floorName + "」 の削除に失敗しました。");
                    });
                }).catch(() => {
                    alert("フロア「" + floorInfo.floorName + "」 のバックアップに失敗しました。");
                });

            }else{
                alert("キャンセルしました。");
            }
        }else{
            alert("入力必須項目が未入力のため、登録できません。");
            return;
        }
    };

    // オブジェクトチェック
    const handleCheckObject = () => {
        uploadCSVCheck(floorId)
    }

    // floor、avater_menu、floor_object登録
    const handleExist = () => {
        // alert("officeId:" + state.officeId);
        // alert("textFloorName:" + state.textFloorName);
        // alert("textFloorSize:" + state.textFloorHeight + ":" + state.textFloorWidth);
        // alert("csvFilePath:" + state.csvFilePath);

        if(window.confirm("フロア「" + state.textFloorName + "」 を登録します。よろしいですか？")){
            // フロア重複チェック
            axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floor/exist', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                officeId: state.officeId.toString().trimEnd(),
                floorName: state.textFloorName.toString().trimEnd(),
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as number;
                // alert("result:" + result);
                if(result === 0){
                    // フロア登録
                    axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/floor/add', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        officeId: state.officeId.toString().trimEnd(),
                        floorName: state.textFloorName.toString().trimEnd(),
                        floorHeight: state.textFloorHeight.toString().trimEnd(),
                        floorWidth: state.textFloorWidth.toString().trimEnd(),
                        enabledMemoButton: state.enabledMemoButton.toString(),
                        enabledWhiteBoardButton: state.enabledWhiteBoardButton.toString(),
                        enabledScreenShareButton: state.enabledScreenShareButton.toString(),
                        enabledFloor: state.enabledFloor.toString(),
                        requireCamera: state.requireCamera.toString(),
                        requireMic: state.requireMic.toString(),
                    }))
                    .then((response: AxiosResponse) => {
                        let msg = response.data as string;
                        if(msg === "NG"){
                            alert("フロア「" + state.textFloorName + "」 の登録に失敗しました。");
                        } else {
                                uploadCSV(msg);
                        }
                    })
                    .catch(() => {
                        alert("フロア「" + state.textFloorName + "」 の" + dispType + "に失敗しました。");
                    });
                }else{
                    alert("そのフロア名は既に登録されているため登録できません。");
                    return;
                }
            })
            .catch(() => {
                alert("フロアの登録に失敗しました。");
                setSelectFloorId(0);
                setOpen(false);
            });
        }else{
            alert("キャンセルしました。");
        }
        clearState();
        setObjNotRegistOpened(false);
    }

    const handleDeleteFloor = () => {
        // alert("officeId:" + state.officeId + "  floorName:" + floorInfo.floorName);
        if(floorInfo.floorName !== ""){
            if(window.confirm("フロア「" + floorInfo.floorName + "」 を削除します。よろしいですか？")){
                // フロア存在チェック
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/floor/exist', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        officeId: state.officeId.toString().trimEnd(),
                        floorName: floorInfo.floorName.toString().trimEnd(),
                    }))
                    .then((response: AxiosResponse) => {
                        let result = response.data as number;
                        // 削除対象が存在する場合、削除処理
                        if(result > 0){
                            axios
                                .create({
                                    withCredentials: true,
                                })
                                .post('/api/system/floor/delete', new URLSearchParams({
                                    floorId: selectFloorId.toString().trimEnd(),
                                    deleteFloorTable: "1",          // "1":フロア自体も削除
                                }))
                                .then((response: AxiosResponse) => {
                                    let msg = response.data as string;
                                    //alert("result:" + msg);
                                    if(msg === "OK"){
                                        alert("フロア「" + floorInfo.floorName + "」 の削除が完了しました。");
                                    }else if (msg.startsWith("ERR") || msg === "NG") {
                                        if(msg === "ERR01"){
                                            alert("フロア「" + floorInfo.floorName + "」 の削除に失敗しました。\n対象フロアがuser_info.lastfloor_idに設定されています。");
                                        }
                                    }
                                })
                                .catch(() => {
                                    alert("フロア「" + floorInfo.floorName + "」 の削除に失敗しました。");
                                });
                                clearState();
                                setSelectFloorId(0);
                                setOpen(false);
                        }else{
                            alert("フロア「" + floorInfo.floorName + "」 は存在しません。");
                        }

                    })
                    .catch(() => {
                        alert("削除対象のデータが見つかりませんでした。");
                        setSelectFloorId(0);
                        setOpen(false);
                    });
            }else{
                alert("キャンセルしました。");
            }
        }else{
            alert("入力必須項目が未入力のため、削除できません。");
            return;
        }
    };

    const handleModifyFloorInfo = () => {
        if(floorInfo.floorName !== ""){
            if(window.confirm("フロア「" + floorInfo.floorName + "」 を更新します。よろしいですか？")){
                // フロア存在チェック
                //console.log("officeId:", state.officeId.toString().trimEnd());
                //console.log("floorName:", floorInfo.floorName.trimEnd());
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/floor/exist', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        officeId: state.officeId.toString().trimEnd(),
                        floorName: floorInfo.floorName.trimEnd(),   // 確認なので更新する前のフロア名(本当はfloorIdで確認するべきな気がする)
                    }))
                    .then((response: AxiosResponse) => {
                        //console.log("response:", response);
                        
                        //console.log("floorId:", selectFloorId.toString().trimEnd());
                        //console.log("floorName:", state.textFloorName.toString().trimEnd());
                        //console.log("floorOrder:",state.textFloorOrder.toString().trimEnd());
                        //console.log("enabledMemoButton:",state.enabledMemoButton.toString());
                        //console.log("enabledWhiteBoardButton:",state.enabledWhiteBoardButton.toString());
                        //console.log("enabledScreenShareButton:",state.enabledScreenShareButton.toString());
                        //console.log("enabledFloor:",state.enabledFloor.toString());
                        //console.log("requireCamera:",state.requireCamera.toString());
                        //console.log("requireMic:",state.requireMic.toString());

                        let result = response.data as number;
                        // 更新対象が存在する場合は処理続行
                        if(result > 0){
                            axios
                                .create({
                                    withCredentials: true,
                                })
                                .post('/api/system/floor/mod', new URLSearchParams({
                                    tab_id: sessionStorage.getItem("TABID") as string,
                                    floorId: selectFloorId.toString().trimEnd(),
                                    floorName: state.textFloorName.toString().trimEnd(),
                                    floorOrder: state.textFloorOrder.toString().trimEnd(),
                                    enabledMemoButton: state.enabledMemoButton.toString(),
                                    enabledWhiteBoardButton: state.enabledWhiteBoardButton.toString(),
                                    enabledScreenShareButton: state.enabledScreenShareButton.toString(),
                                    enabledFloor: state.enabledFloor.toString(),
                                    requireCamera: state.requireCamera.toString(),
                                    requireMic: state.requireMic.toString(),
                                }))
                                .then((response: AxiosResponse) => {
                                    let msg = response.data as string;
                                    //alert("result:" + msg);
                                    if(msg === "OK"){
                                        alert("フロア「" + floorInfo.floorName + "」 の更新が完了しました。");
                                    }
                                })
                                .catch(() => {
                                    alert("フロア「" + floorInfo.floorName + "」 の更新に失敗しました。");
                                });
                                clearState();
                                setSelectFloorId(0);
                                setOpen(false);
                        }else{
                            alert("フロア「" + floorInfo.floorName + "」 は存在しません。");
                        }

                    })
                    .catch(() => {
                        alert("更新対象のデータが見つかりませんでした。");
                        setSelectFloorId(0);
                        setOpen(false);
                    });
            }else{
                alert("キャンセルしました。");
                handleClose();
            }
        }else{
            alert("入力必須項目が未入力のため、更新できません。");
            return;
        }

        
    };

    const handleGetModifyFloorInfo = () => {
        //console.log("select floorId: ", selectFloorId);
        //console.log("floorInfo.enabledMemoButton", floorInfo.enabledMemoButton);
        //console.log("floorInfo.enabledWhiteBoardButton", floorInfo.enabledWhiteBoardButton);
        //console.log("floorInfo.enabledScreenShareButton", floorInfo.enabledScreenShareButton);
        //console.log("floorInfo.enabledFloor", floorInfo.enabledFloor);
        //console.log("floorInfo.requireCamera", floorInfo.requireCamera);
        //console.log("floorInfo.requireMic", floorInfo.requireMic);
        setState({...state, 
            textFloorName : floorInfo.floorName,
            textFloorOrder : floorInfo.floorOrder.toString(),
            enabledMemoButton: floorInfo.enabledMemoButton,
            enabledWhiteBoardButton: floorInfo.enabledWhiteBoardButton,
            enabledScreenShareButton: floorInfo.enabledScreenShareButton,
            enabledFloor: floorInfo.enabledFloor,
            requireCamera: floorInfo.requireCamera,
            requireMic: floorInfo.requireMic,
        });       
    }

    // オブジェクトマスタ・シート出力処理
    const handleOutputObjectMasterSeat = () => {
        if(window.confirm("登録されているオブジェクトマスタ情報CSVを出力します。よろしいですか？")){
            axios
                .get('/api/system/objectmasterseat/output', {
                    params: {
                        tab_id: sessionStorage.getItem("TABID") as string,
                    },
                })
                .then((e: AxiosResponse) => {
                    //レスポンスヘッダからファイル名を取得
                    const contentDisposition = e.headers["content-disposition"];
                    const fileName = getFileName(contentDisposition);
                    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                    const blob = new Blob([bom, e.data], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
            
                    //ダウンロード
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    alert("オブジェクトマスタ情報CSVの出力が完了しました。");
                }).catch(() => {
                    alert("オブジェクトマスタ情報CSVの出力に失敗しました。");
                });
                clearState();
                setSelectFloorId(0);
                setOpen(false);
        }else{
            alert("キャンセルしました。");
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        // alert(name + ":" + target.value);
        setState(() => {
            return {...state, [name]: target.value };
        });
     }

     const handleChangeFunction = React.useCallback((value: any):void => {
         // alert("select " + value.target.name + ":" +value.target.value);
        state.functionType = value.target.value;
        switch (value.target.value) {
            case 1:
                // オブジェクトマスタ登録
                setDealType("object");
                setDispInputTitle("");
                setDispTenantName("none");
                setDispOfficeName("none");
                setDispFloorName("none");
                setDispFloorInfo("none");
                setDispFileSelect("");
                setDispType("登録");
                setDispFloorSetting("none");
                setDispModifyfloorInfo("none");
                break;
            case 2:
                // フロア・フロアオブジェクト登録
                setDealType("floor");
                setDispInputTitle("");
                setDispTenantName("");
                setDispOfficeName("");
                setDispFloorName("none");
                setDispFloorInfo("");
                setDispFileSelect("");
                setDispType("登録");
                setDispFloorSetting("");
                setDispModifyfloorInfo("none");
                break;
            case 3:
                // フロアオブジェクト出力
                setDealType("");
                setDispInputTitle("");
                setDispTenantName("");
                setDispOfficeName("");
                setDispFloorName("");
                setDispFloorInfo("none");
                setDispFileSelect("none");
                setDispType("出力");
                setDispFloorSetting("none");
                setDispModifyfloorInfo("none");
                break;
            case 4:
                // フロアオブジェクト更新
                setDealType("floorobject");
                setDispInputTitle("");
                setDispTenantName("");
                setDispOfficeName("");
                setDispFloorName("");
                setDispFloorInfo("none");
                setDispFileSelect("");
                setDispType("登録");
                setDispFloorSetting("none");    // 表示しても良さそうだが影響を限定的にするため、今は出さない
                setDispModifyfloorInfo("none");
                break;
            case 5:
                // フロアオブジェクト削除
                setDealType("");
                setDispInputTitle("");
                setDispTenantName("");
                setDispOfficeName("");
                setDispFloorName("");
                setDispFloorInfo("none");
                setDispFileSelect("none");
                setDispType("削除");
                setDispFloorSetting("none");
                setDispModifyfloorInfo("none");
                break;
            case 6:
                // フロア情報更新
                setDealType("");
                setDispInputTitle("");
                setDispTenantName("");
                setDispOfficeName("");
                setDispFloorName("");
                setDispFloorInfo("none");
                setDispFileSelect("none");
                setDispType("更新");
                setDispFloorSetting("none");
                setDispModifyfloorInfo("");
                break;
            case 7:
                // オブジェクトマスタ・シート出力
                setDealType("");
                setDispInputTitle("none");
                setDispTenantName("none");
                setDispOfficeName("none");
                setDispFloorName("none");
                setDispFloorInfo("none");
                setDispFileSelect("none");
                setDispType("出力");
                setDispFloorSetting("none");
                setDispModifyfloorInfo("none");
                break;
            default:
                break;
         }
         setState(() => {
             return {...state, [value.target.name]: value.target.value,tenantId: "", officeId:"", floorName:"" };
         });
     }, [state]);

    const handleChangeSelect = React.useCallback((value: any):void => {
        // alert("target " + value.target.name + ":" +value.target.value);
        if(value.target.name === "tenantId"){
            handleGetOfficeInfo(value.target.value);
            state.officeId = "";
            state.floorId = "";
        }else if(value.target.name === "officeId"){
            handleGetFloorInfo(value.target.value);
            state.floorId = "";
        }else if(value.target.name === "floorId"){
            setSelectFloorId(value.target.value);
            handleGetFloorInfoById(value.target.value);
        }
        setState(() => {
            return {...state, [value.target.name]: value.target.value };
        });
    }, [state]);

    const handleFileSelect = React.useCallback((value: any):void => {
        let eventTarget: HTMLInputElement = (value.target as HTMLInputElement);
        if(eventTarget !== null && eventTarget.files !== null) {
            setUploadCSVFile(eventTarget.files[0]);
        }
        
        setState(() => {
            return {...state, ["csvFilePath"]: value.target.value };
        });
    }, [state]);


    // 未登録チェック結果の表示（未登録画像がなければそのままフロア登録）
    const objNotRegChkController = React.useMemo(() => {
        // alert("objNotRegChkController:" + objNotRegistList.length + ", " + objNotRegistOpened);
        if(objNotRegistList.length === 0 && objNotRegistOpened === true){
            handleExist();
        } else {
            return(
                <Dialog open={objNotRegistOpened} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                    <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>未登録画像チェック結果</DialogTitle>
                    <DialogContent style={{ padding: "20px 20px" }}>
                        <Box fontSize="15px">
                            以下の{objNotRegistList.length}画像が未登録です。<br></br>
                            このまま登録を行う場合は{dispType}ボタンを選択してください。<br></br>
                            ※未登録リストに記載されている画像は登録されません。
                        </Box>
                        <Box fontSize="18px"><br></br><b>未登録リスト</b></Box>
                        {objNotRegistList.map((objNotRegist, index) => (
                            <Box fontSize="15px">
                                {objNotRegist.pictureName}
                            </Box>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">キャンセル</Button>
                        <Button id="submit" onClick={handleExist} color="primary">{dispType}</Button>
                    </DialogActions>
                </Dialog>
            )
        }
    },[objNotRegistList, objNotRegistOpened])

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>フロア情報</DialogTitle>
                <DialogContent style={{ padding: "20px 20px" }}>
                    <Box fontSize="18px"><b>フロア情報操作選択</b></Box>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">処理内容</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="functionType"
                            onChange={handleChangeFunction}
                            label="Age"
                            input={<OutlinedInput margin='dense' aria-label={"Age"} label={"処理内容"} color="primary" />}
                            style={{ width: 400 }}
                            value={state.functionType}
                        >
                            <MenuItem value={1}>オブジェクトマスタ・シート登録</MenuItem>
                            <MenuItem value={2}>フロア・フロアオブジェクト登録</MenuItem>
                            <MenuItem value={4}>フロアオブジェクト更新</MenuItem>
                            <MenuItem value={6}>フロア情報更新</MenuItem>
                            <MenuItem value={7}>オブジェクトマスタ・シート出力</MenuItem>
                            <MenuItem value={3}>フロアオブジェクト出力</MenuItem>
                            <MenuItem value={5}>フロア・フロアオブジェクト削除</MenuItem>
                        </Select>
                    </FormControl>
                    <Box display={dispInputTitle}>
                        <br></br><hr></hr>
                        <Box fontSize="18px"><b>フロア情報入力</b></Box>
                    </Box>
                    <Box display={dispTenantName}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">テナント名</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="tenantId"
                                onChange={handleChangeSelect}
                                label="Age"
                                input={<OutlinedInput margin='dense' aria-label={"Age"} label={"テナント名"} color="primary" />}
                                style={{ width: 400 }}
                                value={state.tenantId}
                            >
                                {tenantList.map((tenant) => (
                                    <MenuItem value={tenant.id}>{tenant.tenantName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    
                    <Box display={dispOfficeName}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">オフィス名</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="officeId"
                                onChange={handleChangeSelect}
                                label="Age"
                                input={<OutlinedInput margin='dense' aria-label={"Age"} label={"オフィス名"} color="primary" />}
                                style={{ width: 400 }}
                                value={state.officeId}
                            >
                                {officeList.map((office) => (
                                    <MenuItem value={office.id}>{office.officeName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box display={dispFloorName}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">フロア名</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="floorId"
                                onChange={handleChangeSelect}
                                label="Age"
                                input={<OutlinedInput margin='dense' aria-label={"Age"} label={"フロア名"} color="primary" />}
                                style={{ width: 400 }}
                                value={state.floorId}
                            >
                                {floorList.map((floor) => (
                                    <MenuItem value={floor.id}>{floor.floorName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <br></br>
                    <Box display={dispFloorInfo}>   
                        <TextField
                            autoFocus
                            margin="dense"
                            id="textFloorName"
                            name="textFloorName"
                            label="フロア名"
                            variant={'outlined'}
                            style={{ width: 400 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.textFloorName}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="textFloorHeight"
                            name="textFloorHeight"
                            label="フロア高さ"
                            variant={'outlined'}
                            style={{ width: 400 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.textFloorHeight}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="textFloorWidth"
                            name="textFloorWidth"
                            label="フロア幅"
                            variant={'outlined'}
                            style={{ width: 400 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.textFloorWidth}
                            required
                        />
                    </Box>
                    <Box display={dispfloorSetting}>
                        <br></br>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledWhiteBoardButton}
                                            onChange={handleCheck}
                                            id="enabledWhiteBoardButton"
                                            name="enabledWhiteBoardButton"
                                            color="primary"
                                            value={state.enabledWhiteBoardButton}
                                        />
                                    }
                                    label="資料・ホワイトボードの共有"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.requireCamera}
                                            onChange={handleCheck}
                                            id="requireCamera"
                                            name="requireCamera"
                                            color="primary"
                                            value={state.requireCamera}
                                        />
                                    }
                                    label="カメラ必須"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.requireMic}
                                            onChange={handleCheck}
                                            id="requireMic"
                                            name="requireMic"
                                            color="primary"
                                            value={state.requireMic}
                                        />
                                    }
                                    label="マイク必須"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledMemoButton}
                                            onChange={handleCheck}
                                            id="enabledMemoButton"
                                            name="enabledMemoButton"
                                            color="primary"
                                            value={state.enabledMemoButton}
                                        />
                                    }
                                    label="メモ機能"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledScreenShareButton}
                                            onChange={handleCheck}
                                            id="enabledScreenShareButton"
                                            name="enabledScreenShareButton"
                                            color="primary"
                                            value={state.enabledScreenShareButton}
                                        />
                                    }
                                    label="画面共有機能"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledFloor}
                                            onChange={handleCheck}
                                            id="enabledFloor"
                                            name="enabledFloor"
                                            color="primary"
                                            value={state.enabledFloor}
                                        />
                                    }
                                    label="有効フロア"
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box display={dispModifyfloorInfo}>
                        <hr></hr>
                        <Box fontSize="18px"><b>更新するフロア情報入力</b></Box>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="textFloorNameModify"
                            name="textFloorName"
                            label="フロア名"
                            variant={'outlined'}
                            style={{ width: 400 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.textFloorName}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="textFloorOrderModify"
                            name="textFloorOrder"
                            label="フロア表示順"
                            variant={'outlined'}
                            style={{ width: 400 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.textFloorOrder}
                            required
                        />
                        <br/>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledWhiteBoardButton}
                                            onChange={handleCheck}
                                            id="enabledWhiteBoardButtonModify"
                                            name="enabledWhiteBoardButton"
                                            color="primary"
                                            value={state.enabledWhiteBoardButton}
                                        />
                                    }
                                    label="資料・ホワイトボードの共有"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.requireCamera}
                                            onChange={handleCheck}
                                            id="requireCameraModify"
                                            name="requireCamera"
                                            color="primary"
                                            value={state.requireCamera}
                                        />
                                    }
                                    label="カメラ必須"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.requireMic}
                                            onChange={handleCheck}
                                            id="requireMicModify"
                                            name="requireMic"
                                            color="primary"
                                            value={state.requireMic}
                                        />
                                    }
                                    label="マイク必須"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledMemoButton}
                                            onChange={handleCheck}
                                            id="enabledMemoButtonModify"
                                            name="enabledMemoButton"
                                            color="primary"
                                            value={state.enabledMemoButton}
                                        />
                                    }
                                    label="メモ機能"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledScreenShareButton}
                                            onChange={handleCheck}
                                            id="enabledScreenShareButtonModify"
                                            name="enabledScreenShareButton"
                                            color="primary"
                                            value={state.enabledScreenShareButton}
                                        />
                                    }
                                    label="画面共有機能"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.enabledFloor}
                                            onChange={handleCheck}
                                            id="enabledFloorModify"
                                            name="enabledFloor"
                                            color="primary"
                                            value={state.enabledFloor}
                                        />
                                    }
                                    label="有効フロア"
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box display={dispFileSelect} onChange={handleFileSelect}>
                        <br></br>
                        <label >登録用CSVファイル選択</label>
                        <br></br>
                        <input type="file" accept=".csv"></input>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                    <Box display={dispModifyfloorInfo}>
                        <Button id="getFloorInfo" onClick={handleGetModifyFloorInfo} color="primary">フロア情報取得</Button>
                    </Box>
                    <Button id="submit" onClick={handleRegistFloor} color="primary">{dispType}</Button>
                </DialogActions>
            </Dialog>

            {objNotRegChkController}
        </div>
    )
}

export const SystemRegistFloor = forwardRef(SystemRegistFloorComponent);
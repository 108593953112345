import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { KeyboardEventHandler, KeyboardEvent } from 'react';
import { withStyles, makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Divider, Box, FormLabel, DialogTitle, DialogContent, FormControl, Select, DialogActions, Button,Radio, RadioGroup,FormControlLabel, ZoomProps } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FloorObject, Office as resOffice } from '../common/JsonClass';
import { Utility } from '../common/Utility';
import FormHelperText from '@material-ui/core/FormHelperText';

import { userListConst } from './UserListConst';
import JfsClient from '@fsi/jfs-sdk';

export default function ZoomUrlSetting() {

  const classes = useStyles();
  const tabId = sessionStorage.getItem('TABID') || '';

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);

  const [officeList, setOfficeList] = React.useState([] as Office[]);
  const [zoomUrlList, setZoomUrlList] = React.useState([] as FloorObjectData[]);
  const [zoomUrlListOrg, setZoomUrlListOrg] = React.useState([] as FloorObjectData[]);
  const [showBackdropAfterSaving, setBackdropAfterSaving] = React.useState(false);
  const [floorUrlList, setFloorUrlList] = React.useState([] as string[]);
  const [floorResetList, setFloorResetList] = React.useState([] as boolean[]);
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;

  /**
  * useEffect
  */
   useEffect(() => {
    setCrudType(0);
    setLoading(true);
    fetchOfficeFloorList();
  }, []);

  const fetchOfficeFloorList = async () => {
    try {
      const res1 = await Utility.requestOfficeList();
      const officeListData = res1 as resOffice[];

      // UI表示用のofficeデータを生成
      const promiseArray = officeListData.map(async (office) => {
        const res = await getFloorList(office.id);
        const floorListData = res as resFloor[];
        const enabledFloorListData = floorListData.filter(x => x.enabledFloor === true);
        return setFloorToOffice(office, enabledFloorListData);
      });
      // フロア０件のオフィスは選択データに入れない
      const _officeList_tmp = await Promise.all(promiseArray);

      // const res = await axios.get('/api/v1/admin/tenant/zoomUrlSetting', { params: { tabId } });
      const res = await httpClient.getZoomUrlList(tabId);
      const list = res
      ? res.map((v: any, i: number, d: any) => {
        const e: FloorObjectData = new FloorObjectData();
        e.id = v.id;
        e.floorId = v.floorId;
        e.resetURL = v.resetURL;
        e.url = v.url;
        e.name = v.name;

        return e;
      }) : [] as FloorObjectData[];

      setZoomUrlListOrg(JSON.parse(JSON.stringify(list)));
      setZoomUrlList(list);

      //外部連携部屋無いのフロアのフラグを設定する
      _officeList_tmp.forEach((office:Office) => {
        office.floorList.forEach((floor:Floor) => {
          floor.isDisplay = list.find((o:FloorObjectData) => o.floorId == floor.id) != undefined;
        });
      });

      var _officeList = _officeList_tmp.filter(x => x.floorList.length > 0);
      // setState
      _officeList.sort((a, b) => a.id > b.id ? 1 : -1);

      // 一括反映のURLおよびスイチの初期化処理
      var index = 0;
      var initFlag = floorUrlList.length == 0;

      for(var i = 0; i < _officeList.length; i++){
        var office = _officeList[i];
        for(var j = 0; j < office.floorList.length; j++){
          var floor = office.floorList[j];
          if(initFlag){
            floorUrlList.push("");
            floorResetList.push(false);
          }
          floor.index = index;
          index++;
        }
      }

      setFloorResetList([...floorResetList]);
      setFloorUrlList([...floorUrlList]);
      setOfficeList(_officeList);

      setLoading(false);

    } catch (err) {
      console.info(err);
      setLoading(false);
    }
  }

  const getFloorList = (id: number) => {
    // const params = new URLSearchParams();
    // params.append("office_id", id.toString());
    // params.append("tab_id", sessionStorage.getItem("TABID") as string);
    // return axios.post('/api/user/floor/list/get', params)
    return httpClient.getFloorList(id, sessionStorage.getItem("TABID") as string);
  }

  function setFloorToOffice(resOffice: resOffice, floorList: resFloor[]) {
    const office = new Office();
    office.id = resOffice.id;
    office.officeName = resOffice.officeName;
    office.privacyRoom = resOffice.privacyRoom;

    if (floorList.length > 0) {
      // id の昇順で並びかえ
      office.floorList = floorList.map(floor => ({ ...floor, stay: false }))
      .sort((floor1, floor2) => {
        if (floor1.floorOrder > floor2.floorOrder) return 1;
        if (floor1.floorOrder < floor2.floorOrder) return -1;
        return 0;
      });
    }
    office.floorList.forEach((data) => data.url == "");
    office.floorList.forEach((data) => data.resetURL == false);

    return office;
  }

  const handleSaveOrCancel = (mode: boolean) =>{
    if(mode){
      setCrudType(1);
      setLoading(true);
      let before = JSON.stringify(zoomUrlListOrg);
      let current= JSON.stringify(zoomUrlList);


      const postTarget = [] as FloorObjectData[];

      //post前の判定
      for(var i = 0; i<zoomUrlList.length;i++){
        let oldData = zoomUrlListOrg[i];
        let newData = zoomUrlList[i];

        if(JSON.stringify(oldData) != JSON.stringify(newData)){
          postTarget.push(newData);
        }
      }

      if(postTarget.length == 0){
        alert("変更ありません。");
        setLoading(false);
        return;
      }

      // const params = new URLSearchParams();
      // params.append("tab_id", tabId);
      // params.append("data", JSON.stringify(postTarget));
      // axios.post('/api/v1/admin/tenant/zoomUrlSetting', params)
      httpClient.registZoomUrlSettingList(tabId, postTarget)
      .then((e) => {
        setBackdropAfterSaving(true);
        setTimeout(function(){setBackdropAfterSaving(false)}, 2000);
        fetchOfficeFloorList();
      })
      .catch(err => {
      })
      .finally(() => {
        setLoading(false);
      });

    }else{
      setZoomUrlList(JSON.parse(JSON.stringify(zoomUrlListOrg)));
    }
  }

  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  const handleSwitch= (e: React.ChangeEvent<HTMLInputElement>, obj:FloorObjectData)=> {

    let index = zoomUrlList.indexOf(obj);

    const list = [...zoomUrlList];
    const item = list[index];
    item.resetURL = e.target.checked;
    setZoomUrlList([...list]);
  }

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number)=> {

    const list = [...zoomUrlList];
    const item = list[index];
    item.url = e.target.value;
    setZoomUrlList([...list]);
  }

  const handleFloorInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, office:Office, floor:Floor) =>{

    var list = [...officeList];
    var o = list[list.indexOf(office)];
    var f = o.floorList[o.floorList.indexOf(floor)];
    f.url = e.target.value;
    setOfficeList([...list]);

    floorUrlList[floor.index] = e.target.value;
    setFloorUrlList([...floorUrlList]);
  }

  const handleFloorSwitch = (e: React.ChangeEvent<HTMLInputElement>, office:Office, floor:Floor) =>{

    var list = [...officeList];
    var o = list[list.indexOf(office)];
    var f = o.floorList[o.floorList.indexOf(floor)];
    f.resetURL = e.target.checked;
    setOfficeList([...list]);

    floorResetList[floor.index] = e.target.checked;
    setFloorResetList([...floorResetList]);
  }

  const handleApplyToAllRoomInThisFloor = (floor:Floor, floorId:number, url:string, resetURL:boolean) =>{

    const list = [...zoomUrlList];
    list.filter((obj:FloorObjectData) => obj.floorId == floor.id).forEach((obj:FloorObjectData) => {
      obj.resetURL = floorResetList[floor.index];
      obj.url = floorUrlList[floor.index];
    });

    setZoomUrlList([...list]);
  }

  return (
    <Fragment>
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{marginTop: -20}}
      >
      <Card className={classes.root}>
        <CardHeader
        title='外部Web会議ツール設定'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      <Fragment key="zoom-url-table">
        <Paper style={{ width: '100%' }}>
          <TableContainer style={{ height: `calc(100vh - 180px)` }}>
            <Table aria-label="simple table">
              <TableBody>
              {
                officeList.map((office: Office, officeIdx) => {
                  return(
                    <Fragment key={office.id} >
                      <TableRow>
                        <TableCell colSpan={3}>
                          <label style={{ fontSize:18, fontWeight:'bolder'}}>{office.officeName}</label>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                      {
                        office.floorList.map((floor: Floor, floorIdx) => {
                          return(
                            <Fragment key = "">
                              <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell colSpan={2}>
                                  <label style={{ fontSize:16,fontWeight:'bold'}}>{floor.floorName}</label>
                                </TableCell>
                                <TableCell>
                                  {
                                    floor.isDisplay && <TextField
                                      InputLabelProps={{shrink: true}}
                                      onChange = {(e) => handleFloorInput(e, office, floor)}
                                      value={floorUrlList[floor.index]}
                                      style={{ pointerEvents: 'auto', width:400 }}
                                    />
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    floor.isDisplay && <Fragment>
                                      利用後のURL削除：<Switch value={floorResetList[floor.index]} onChange = {(e) => handleFloorSwitch(e, office, floor)}></Switch>
                                    </Fragment>
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    floor.isDisplay && <Fragment>
                                      <Button variant="contained" color="primary" onClick ={(e) => handleApplyToAllRoomInThisFloor(floor, floor.id, floor.url, floor.resetURL)}>フロア内一括反映</Button>
                                    </Fragment>
                                  }
                                </TableCell>
                              </TableRow>
                                {
                                  zoomUrlList.filter(x => x.floorId == floor.id).map((floorObj:FloorObjectData, objIdx) => {
                                    return(
                                      <Fragment key="">
                                        <TableRow>
                                          <TableCell>
                                          </TableCell>
                                          <TableCell>
                                          </TableCell>
                                          <TableCell>
                                            {floorObj.name}
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              InputLabelProps={{shrink: true}}
                                              value={floorObj.url}
                                              onChange={(e) => handleTextInput(e, zoomUrlList.indexOf(floorObj))}
                                              style={{ pointerEvents: 'auto', width:400 }}
                                            />
                                          </TableCell>
                                          <TableCell colSpan={2}>
                                            利用後のURL削除：<Switch checked={floorObj.resetURL} onChange={(e) => handleSwitch(e, floorObj)}></Switch>
                                          </TableCell>
                                        </TableRow>
                                      </Fragment>
                                    )
                                  })
                                }
                            </Fragment>
                          );
                        })
                      }
                    </Fragment>
                  );
              })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div>
          <Button variant="contained" disabled={JSON.stringify(zoomUrlList) == JSON.stringify(zoomUrlListOrg)} color="primary" onClick={() => handleSaveOrCancel(true)}>保存</Button>&nbsp;
          <Button variant="contained" color="primary" onClick={() => handleSaveOrCancel(false)}>キャンセル</Button>
        </div>
      </Fragment>
      {drawLoading}
    </Grid>
    <Backdrop className={classes.backdrop} open={showBackdropAfterSaving} >
    <Slide direction="up" in={showBackdropAfterSaving}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="subtitle2" >保存しました。</Typography>
        </CardContent>
      </Card>
    </Slide>
    </Backdrop>
    </Fragment>
)
}
 
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
      maxWidth: 450,
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})
  
// レスポンス受渡用
interface resFloor {
  id: number;
  floorName: string;
  floorOrder: number;
  enabledFloor: boolean;
  checked: boolean;
  index: number;
  url: string;
  resetURL: boolean;
  isDisplay: boolean;
}

// UI表示用
class FloorObjectData {
  id: number = 0;
  floorId: number = 0;
  name: string = "";
  url: string = "";
  resetURL: boolean = false;
  index: number = 0;
}

class Office {
  id: number = 0;
  officeName: string = "";
  stay: boolean = false;
  open: boolean = false;
  floorList: Floor[] = [];
  offset: number = 0;
  privacyRoom: boolean = false;
}

class Floor {
  index: number = 0;
  id: number = 0;
  floorName: string = "";
  floorOrder: number = 0;
  stay: boolean = false;
  checked: boolean = false;
  url: string = "";
  resetURL: boolean = false;
  isDisplay: boolean = true;
} 

import Cookie from 'js-cookie';
import axios, { AxiosResponse } from 'axios';
import JfsClient from '@fsi/jfs-sdk';

export class Utility {
    /** SessionStorage に保存する SIGNINPAGE の参照キー */
    private static SIGNINPAGE_KEY = 'SIGNINPAGE';

    public static getQueryParam = (query: string, paramName: string) => {
        let temp: string[] = query.split("?");
        let params: string[] = temp[1].split("&");

        let result: string = "";
        params.forEach(e => {
            const keys = e.split("=");
            if (keys[0] === paramName) {
                result = keys[1];
                return;
            }
        })
        return result;
    }

    /**
     * Cookieを数値として取り出す
     * ない場合は、引数で指定した初期値を返す
     */
    public static getCookieNumber(cookieName: string, init: number) {
        if (Cookie.get(cookieName) === undefined) return init;
        let param = Cookie.get(cookieName)?.toString() as string;

        return Number.parseInt(param);
    }

    /**
     * 低解像度か否かを返す
     * @return true:低解像度, false:高解像度
     */
    public static isLowScreenResolution = (): boolean => {
        // console.log("- [Screen]width:" + screen.width + ", height:" + screen.height + ", [Window]width:" + window.innerWidth + ", height:" + window.innerHeight);

        let shortBoundary: number = (window.screen.width < window.screen.height) ? window.screen.width : window.screen.height;
        let longBoundary: number = (window.screen.width >= window.screen.height) ? window.screen.width : window.screen.height;
        // let shortBoundary:number = (innerWidth < innerHeight) ? innerWidth : innerHeight;
        // let longBoundary:number = (innerWidth >= innerHeight) ? innerWidth : innerHeight;

        if (longBoundary < 1024) {
            return true;
        }
        if (shortBoundary < 768) {
            return true;
        }

        return false;
    }

    /**
     * 引数の文字列からURLを抽出してリストとして返す
     * @param str 
     */
    public static getUrlList(str: string) {
        var pat = /(https?:\/\/[\x21-\x7e]+)/g;
        var list = str.match(pat);
        if (!list) return [];
        return list;
    }

    /**
     * HTTP RestAPIにアクセスして、HTTPセッションの延長をする処理
     */
    public static requestExtendSession(func: () => void) {
        const jfsClient = JfsClient.getInstance();
        const { httpClient } = jfsClient;

        // var params = new URLSearchParams();
        // axios.post('/api/user/session/extend', params)
        //     .then((e: AxiosResponse) => {
                
        //     }).catch(err => {
        //         // console.log(err);
        //         func();
        //     });
        httpClient.requestExtendSession()
            .then((e) => {
                
            }).catch(err => {
                // console.log(err);
                func();
            });
    }

    /**
     * ByteからGBに表示を変換
     */
    public static changeByteSize(size: number) {
        return (size / 1024 / 1024 / 1024).toFixed(3);
    }

    /**
     * バージョン文字列比較処理     リリースノート対応
     * @description 数値のみ、もしくは「.」区切りの数値を対象とする
     */
    public static compareVersion = (a: string, b: string): number => {
        if (a === b) {
            return 0;
        }

        // バージョンを"."でユニットに切り分ける
        const aUnits = a.split(".");
        const bUnits = b.split(".");

        // ユニット数が少ない方の表記に探索幅を合わせる
        const len = Math.min(aUnits.length, bUnits.length);

        // 探索幅に従ってユニット毎に比較していく
        for (var i = 0; i < len; i++) {
            // A > B
            if (parseInt(aUnits[i]) > parseInt(bUnits[i])) return 1;
            // B > A
            if (parseInt(aUnits[i]) < parseInt(bUnits[i])) return -1;
        }
        
        // 個々のユニットが完全に一致している場合はユニット数が多い方が大きいとする
        if (aUnits.length > bUnits.length)　return 1;
        if (aUnits.length < bUnits.length) return -1;

        // 上記の処理で判定仕切れない場合は同一とみなす
        return 0;
    }

    /**
     * office list をリクエストする
     */
    public static requestOfficeList() {
        const params = new URLSearchParams();
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // return axios.post('/api/user/office/list/get', params);
        const jfsClient = JfsClient.getInstance();
        const { httpClient } = jfsClient;
        return httpClient.getOfficeList(sessionStorage.getItem("TABID") as string);
    }

    /**
     * error レスポンスを受けて funcを実行する
     */
    public static checkErrorResponseOfOffice(err: any, func: () => void) {
        if (
            !window.navigator.onLine ||
            [400, 403, 502, 504].includes(err.response.status)
        ) {
            func();
        }
    }

    /**
     * login状態を取得する。
     * @returns boolean 
     */
    public static async getIsLogin() {
        const params = new URLSearchParams();
        params.append('tab_id', sessionStorage.getItem("TABID") || "");
        try {
            const res = await axios.post('/api/user/login/status', params);
            return res.status === 200;
        } catch (err) {
            return false;
        }
    }

    /**
     * Date を 'yyyy-MM-dd hh:mm:ss' 形式に変換
     */
    public static dateToFormatString(date: Date) {
        let yyyy: string = date.getFullYear().toString();
        let MM: string = ("0" + (date.getMonth() + 1)).slice(-2);
        let dd: string = ("0" + date.getDate()).slice(-2);
        let hh: string = ("0" + date.getHours()).slice(-2);
        let mm: string = ("0" + date.getMinutes()).slice(-2);
        let ss: string = ("0" + date.getSeconds()).slice(-2);

        return yyyy + "-" + MM + "-" + dd + " " + hh + ":" + mm + ":" + ss;
    }

    /**
     * Date を 'yyyy/MM/dd hh:mm' 形式に変換
     */
    public static dateToFormatString2(date: Date) {
        let yyyy: string = date.getFullYear().toString();
        let MM: string = ("0" + (date.getMonth() + 1)).slice(-2);
        let dd: string = ("0" + date.getDate()).slice(-2);
        let hh: string = ("0" + date.getHours()).slice(-2);
        let mm: string = ("0" + date.getMinutes()).slice(-2);

        return yyyy + "/" + MM + "/" + dd + " " + hh + ":" + mm;
    }

    /**
     * Date を 'yyyyMMddhhmm' 形式に変換
     */
    public static dateToFormatString3(date: Date) {
        let yyyy: string = date.getFullYear().toString();
        let MM: string = ("0" + (date.getMonth() + 1)).slice(-2);
        let dd: string = ("0" + date.getDate()).slice(-2);
        let hh: string = ("0" + date.getHours()).slice(-2);
        let mm: string = ("0" + date.getMinutes()).slice(-2);

        return yyyy + MM + dd + hh + mm;
    }

    /**
     * SSO(SAML)で使用する RegistrationID を 返却
     */
     public static getRegistrationId() {
        const is_sso = this.isSSO();
        const registrationId: string = is_sso ? (sessionStorage.getItem(Utility.SIGNINPAGE_KEY) as string).replace("/signin/", "") : "";
        return registrationId;
    }

    /**
     * SSO(SAML)で使用する SIGNINPAGE を SessionStorageおよびLocalStrage に保存する
     */
     public static setSigninPage(signinPage: string) {
        sessionStorage.setItem(Utility.SIGNINPAGE_KEY, signinPage);
        localStorage.setItem(Utility.SIGNINPAGE_KEY, signinPage);
    }

    /**
     * SSO(SAML)で使用する SIGNINPAGE を SessionStorageおよびLocalStrage から取得する
     */
     public static getSigninPage() {
        const buf: any = sessionStorage.getItem(Utility.SIGNINPAGE_KEY);
        const buf2: any = localStorage.getItem(Utility.SIGNINPAGE_KEY);
        //sessionStrageの設定を優先して返却
        const signinpage = (buf !== null) ? buf : (buf2 !== null) ? buf2 : '/notfound';

        return signinpage;
    }

    /**
     * SSO(SAML)で使用する SIGNINPAGE を SessionStorage から削除する
     */
    public static removeSigninPage() {
        sessionStorage.removeItem(Utility.SIGNINPAGE_KEY);
    }

    /**
     * 現在のタブが、SSO(SAML)でサインインしたものかどうかを返却する
     */
     public static isSSO() {
        const index : number = (sessionStorage.getItem(Utility.SIGNINPAGE_KEY) as string).indexOf("signin");
        const is_sso = (index !== -1) ? true: false;
        return is_sso;
     }


    /**
     * HTTPリクエストで 302 リダイレクトが発生した場合はログアウトする
     * @param response
     * @param history
     */
    public static checkRedirectAndLogout(response: AxiosResponse, history: any) {
        // 302 は検知不可。 (ブラウザは検知しているが、勝手にリダイレクトした結果が HTML形式で返ってくる)
        // 代わりに content-type で、本来得られる application/json ではなく、text/html が返ってきているか否かで判断。
        let contentType = response.headers['content-type'];
        if (contentType === undefined) { // API がレスポンスボディに何も書き込まない場合に、htmldemo で発生
            return false;
        }
        if (contentType !== null && contentType.indexOf('text/html') < 0) {
            return false;
        }

        console.log("302 Found.");
        Utility.logout(history);
        return true;
    }

    /**
     * HTTPリクエストで 302 リダイレクトが発生した場合はログアウトする
     * @param request
     * @param history
     */
    public static checkRedirectAndLogoutForXMLHttpRequest(xhr: XMLHttpRequest, history: any) {
        if (xhr.readyState !== 4) {
            return false;
        }
        // 302 は検知不可。 (ブラウザは検知しているが、勝手にリダイレクトした結果が HTML形式で返ってくる)
        // 代わりに content-type で、本来得られる application/json ではなく、text/html が返ってきているか否かで判断。
        let contentType = xhr.getResponseHeader("Content-Type");
        if (contentType === undefined) { // API がレスポンスボディに何も書き込まない場合に、htmldemo で発生
            return false;
        }
        if (contentType !== null && contentType.indexOf('text/html') < 0) {
            return false;
        }

        console.log("302 Found.");
        Utility.logout(history);
        return true;
    }

    /**
     * HTTPリクエストで 302 リダイレクトが発生した場合はログアウトする
     * @param judge
     * @param history
     */
     public static logout(history: any) {
        var params = new URLSearchParams();
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        axios.post('/api/user/logout', params).catch((err) => {
            console.log("/api/user/logout error : ", err.response);
        });
        sessionStorage.removeItem("ISLOGIN");

        try {
            history.replace('/signout');
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * 文字のフォーマットを確認
     * @param loginId
     * @param regexpStr
    */
    public static checkFormat = (text: string, regexpStr: string) => {
        var reg = new RegExp(regexpStr);
        return reg.test(text);
    }
    
    /*
     * 外部連携部屋か判定する。
     * @returns boolean 
     */
    public static isVideoConference(type: number) {
        switch(type) {
            case 2000004: // 外部連携部屋
            case 2000100: // 教室
            case 2000101: // グラウンド
            case 2000102: // 個別指導室
            case 2000105: //イベントルーム
                return true;
            default:
                return false;
        }
    }

    /**
     * プライバシールームか判定する。
     * @returns boolean 
     */
     public static isPrivacyRoom(type: number) {
        switch(type) {
            case 2000103: // プライバシールーム
                return true;
            default:
                return false;
        }
    }
    
    /**
     * 自習席か判定する。
     * @returns boolean 
     */
        public static isStudySeat(type: number, paletteText: string) {
            switch(type) {
                case 1000100: // 8人自習席
                case 2000100: // 教室
                case 2000102: // 個別指導スペース(100人)
                    return true;
                case 2000004: // 連携部屋
                    switch(paletteText) {
                        case '個別指導ブース(2人)':
                        case '個別指導ブース(3人)':
                            return true;
                        default:
                            return false;
                    }
                default:
                    return false;
            }
        }

    /**
     * 末尾のテナントIDを末尾から除去したログインIDを取得
     * @param loginId
    */
    public static getLoginIdRemoveTenantId(loginId: string): string {
        const delimiterIndex = loginId.lastIndexOf("#");
        if(delimiterIndex === -1){
            //console.log("login id に区切り文字（#）が存在しない");
            return loginId;
        }

        if(delimiterIndex + 1 >= loginId.length){
            //console.log("区切り文字の後ろに文字が存在しない");
            return loginId;
        }

        if(Utility.checkFormat(loginId.substr(delimiterIndex+1), '^[0-9]*$') === false ){
            //console.log("login id の末尾に区切り文字（#）＋テナントIDが存在しない");
            return loginId;
        }

        const retLoginId = loginId.substr(0, delimiterIndex);
        return retLoginId;
    }

    // 長いフロア名を変換
    // 1. \nを全角スペースに変換
    // 2. 全角コロンの後ろ(実質のフロア名)が20文字を超ええる場合は...を付けて省略
    public static changeLongFloorName(floorName: string): string {

        const chgLFtoSpace:string = floorName.replace(/\\n/g, '　');
        let splitText = chgLFtoSpace.split("：");
        
        // 全角コロンがある
        if(splitText.length >= 2){
            if(splitText[1].length > 20){
                return splitText[0] + "：" + splitText[1].substring(0,19) + "…";        
            }
        }

        return chgLFtoSpace;
    }

    public static getWidth = (pcL:number, pcH:number, mobileV:number, mobileH:number): number => {

        const type = Utility.getType();

        switch(type){
            case 1:
                return mobileH;
            case 2:
                return mobileV;
            case 3:
                return pcH;
            case 4:
                return pcL;
            default:
                return 720;
        }
    }

    public static getType = (): number => {

        const ua = window.navigator.userAgent.toLowerCase();
        
        if( ua.indexOf('iphone') > -1 || ua.indexOf('android') > -1){
            if( window.orientation != 0){
                // 1:スマホ横
                return 1;
            }else{
                // 2:スマホ縦
                return 2;
            }
        }

        //その他
        //4:低解像度
        //3:低解像度でない
        const ret = Utility.isLowScreenResolution()?4:3;
        return ret;
    }

    /**
     * iOS判定
     */
    public static isiOS(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);
    }

    /**
     * iPhone判定
     */
    public static isiPhone(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('iphone') > -1;
    }

    /**
     * iPad判定
     */
    public static isiPad(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('ipad') > -1;
    }

}
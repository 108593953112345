import { useState, useEffect, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, ChangeEvent } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Header from './Header';
import UserSearch from './UserSearch';
import SelectBox from './SelectBox';
import RefreshButton from './RefreshButton';
import UserList from './UserList';

import { User, Coord, Size } from '../../common/JsonClass';
import { DraggableFrame, DraggableFrameHandler } from '../../common/DraggableFrame';
import {
    DisplayUserInfo,
    frameSettingBase,
    SortType,
    sortOptions,
    RaisedHand,
    raisedHandOptions,
    RAISED_HAND_STATE_NUMBER,
    FamMeet,
    famMeetOptions,
    ZoomDevice,
    zoomDeviceOptions
} from './specifics';
import WebrtcService from '../../webrtc/WebrtcService';

const headerH = 40;
const toolsH = 57;
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '100%',
        userSelect: 'text',
        border: '1px solid #717171',
        borderRadius: '4px',
    },
    header: {
        height: headerH,
        background: 'rgba(250,250,250,0.36)',
        boxShadow: '0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)',
        borderBottom: '1px solid #717171',
    },
    body: {
        width: '100%',
        height: `calc(100% - ${headerH}px)`,
        background: 'rgba(255,255,255,0.5)',
    },
    // search, sort
    tool1: {
        width: '100%',
        height: toolsH,
    },
    queryField: {
        width: '60%',
        marginTop: 4,
        marginLeft: '3%',
    },
    sortField: {
        width: '30%',
        marginLeft: 'auto',
        marginRight: '4%',
    },
    // filter, refresh
    tool2: {
        width: '100%',
        height: toolsH,
    },
    filterField: {
        width: '22%',
        marginLeft: '1%',
        '&:first-child': {
            marginLeft: '3%',
        },
    },
    refreshBtn: {
        width: '20%',
        marginLeft: 'auto',
        marginRight: '4%',
    },
    results: {
        width: '100%',
        height: `calc(100% - ${toolsH * 2}px)`,
        background: 'rgba(255,255,255,0.5)',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default',
    },
}));

interface Props {
    scrollbarWidth: number;
    defaultSize: Size;
    getData: () => User[];
    sendZoomSpeakable: (id: number, isZoomSpeakable: boolean) => void;
}

export interface UsersControlHandler {
    open: (defaultCoord: Coord) => void;
    close: () => void;
    getShow: () => boolean;
    setData: (list: User[]) => void;
}

const getWebRtcRoomType = (roomId: string) => !!roomId ? WebrtcService.getWebRtcRoomType(roomId) : 0;
const isRaisedHand = (state: number) => state === RAISED_HAND_STATE_NUMBER;

const sortUser = (list: DisplayUserInfo[], type: SortType) => {
    switch (type) {
        case SortType.NAME_ASC:
            return list.sort((a, b) => a.displayName.localeCompare(b.displayName, 'ja'));
        case SortType.NAME_DEC:
            return list.sort((a, b) => b.displayName.localeCompare(a.displayName, 'ja'));
        case SortType.RAISED_HAND_ASC:
            return list.sort((a, b) => (isRaisedHand(b.state) ? b.state : 0) - (isRaisedHand(a.state) ? a.state : 0));
        case SortType.RAISED_HAND_DEC:
            return list.sort((a, b) => (isRaisedHand(a.state) ? a.state : 0) - (isRaisedHand(b.state) ? b.state : 0));
        case SortType.FAM_MEET_ASC:
            return list.sort((a, b) => getWebRtcRoomType(b.webRtcRoomId) - getWebRtcRoomType(a.webRtcRoomId));
        case SortType.FAM_MEET_DEC:
            return list.sort((a, b) => getWebRtcRoomType(a.webRtcRoomId) - getWebRtcRoomType(b.webRtcRoomId));
        case SortType.ZOOM_DEVICE_ASC:
            return list.sort((a, b) => (b.isZoomSpeakable ? 1 : 0) - (a.isZoomSpeakable ? 1 : 0));
        case SortType.ZOOM_DEVICE_DEC:
            return list.sort((a, b) => (a.isZoomSpeakable ? 1 : 0) - (b.isZoomSpeakable ? 1 : 0));
        default:
            return list;
    }
}

const sortUsersByRaisedHand = (list: DisplayUserInfo[]) => {
    return list.sort((a, b) => {
        if (isRaisedHand(a.state) && isRaisedHand(b.state)) {
            return a.state - b.state;
        } 
        else if (isRaisedHand(a.state) || isRaisedHand(b.state)) {
            return (isRaisedHand(b.state) ? b.state : 0) - (isRaisedHand(a.state) ? a.state : 0);
        }
        else {
            return (b.isZoomSpeakable ? 1 : 0) - (a.isZoomSpeakable ? 1 : 0)
        }
    })
}

const judgeRaisedHand = (user: DisplayUserInfo, raisedHand: RaisedHand) => {
    switch (raisedHand) {
        case RaisedHand.ALL:
            return true;
        case RaisedHand.YES:
            return user.state === RAISED_HAND_STATE_NUMBER;
        case RaisedHand.NO:
            return user.state !== RAISED_HAND_STATE_NUMBER;
        default:
            return false;
    }
}

const judgeFamMeet = (user: DisplayUserInfo, famMeet: FamMeet) => {
    const type = getWebRtcRoomType(user.webRtcRoomId);
    switch (famMeet) {
        case FamMeet.ALL:
            return true;
        case FamMeet.OPEN:
            return type === 1;
        case FamMeet.TELE:
            return type === 2;
        case FamMeet.WHOLE:
            return type === 4;
        default:
            return false;
    }
}

const judgeZoomDevice = (user: DisplayUserInfo, zoomDevice: ZoomDevice) => {
    switch (zoomDevice) {
        case ZoomDevice.ALL:
            return true;
        case ZoomDevice.YES:
            return user.isZoomSpeakable;
        case ZoomDevice.NO:
            return !user.isZoomSpeakable;
        default:
            return false;
    }
}

const UsersControlComponent: ForwardRefRenderFunction<UsersControlHandler, Props> = ({
    scrollbarWidth = 0,
    defaultSize,
    getData,
    sendZoomSpeakable
}, ref) => {
    const classes = useStyles();
    const frameRef = useRef({} as DraggableFrameHandler);
    const [data, setData] = useState<User[]>([]);
    const [isShow, setShow] = useState(false);
    const [defaultCoord, setDefaultCoord] = useState({ x: 0, y: 0 });
    const [isEnterContent, setEnterContent] = useState(false);

    const [query, setQuery] = useState('');
    const [sortType, setSortType] = useState(SortType.RAISED_HAND_ASC);
    const [filters, setFilters] = useState({
        raisedHand: RaisedHand.ALL,
        famMeet: FamMeet.ALL,
        zoomDevice: ZoomDevice.ALL,
    });

    const frameSetting = useMemo(() => ({
        ...frameSettingBase,
        defaultCoord,
        defaultSize,
        scrollbarWidth,
        minWidth: defaultSize.width,
        minHeight: defaultSize.height,
        enableResizing: isEnterContent ? false : frameSettingBase.enableResizing,
        disableDragging: isEnterContent ? true : frameSettingBase.disableDragging,
    }), [defaultCoord, isEnterContent]);

    // フロア上のマルチアバターを除外し、聴衆設定表示で使用するプロパティを抽出したリストを生成
    const userList = useMemo(() => {
        const list = data.filter(d => d.isStayFloor).map(d => {
            const { id, displayName, state, webRtcRoomId, isZoomSpeakable, zoomMeetingId } = d;
            return { id, displayName, state, webRtcRoomId, isZoomSpeakable, zoomMeetingId };
        })
        console.log("[聴衆設定>表示データ]", list);
        return list;
    }, [data]);

    const filteredList = useMemo(() => {
        return userList
            .filter(user => judgeRaisedHand(user, filters.raisedHand))
            .filter(user => judgeFamMeet(user, filters.famMeet))
            .filter(user => judgeZoomDevice(user, filters.zoomDevice));
    }, [userList, filters.raisedHand, filters.famMeet, filters.zoomDevice]);

    const sortedList = useMemo(() => {
        return sortUsersByRaisedHand(Array.from(filteredList));
    }, [filteredList, sortType]);

    const displayList = useMemo(() => {
        return sortedList.filter(user => user.displayName.includes(query));
    }, [sortedList, query]);

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open(defaultCoord: Coord) {
            openUI(defaultCoord);
        },
        close() {
            closeUI();
        },
        getShow() {
            return isShow;
        },
        setData(data: User[]) {
            setData(data);
        },
    }));

    useEffect(() => {
        if (isShow) {
            const data = getData()
            setData(data);
        }
    }, [isShow]);

    const openUI = (coord: Coord) => {
        setDefaultCoord(coord);
        setShow(true);
        frameRef.current?.open();
    }

    const closeUI = () => {
        setShow(false);
        frameRef.current?.close();
    }

    /**
     * handlers
     */
    const handleClose = () => {
        closeUI();
    }

    const handleFrameDoubleClick = () => {
        frameRef.current?.onFrameDoubleClick();
    }

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        // Prevents React from resetting its properties:
        event.persist();
        setQuery(event.target.value);
    }

    const handleSortChange = (event: ChangeEvent<{ name?: string, value: unknown }>): void => {
        if (!Number.isNaN(event.target.value)) {
            setSortType(Number(event.target.value));
        }
    }

    const handleFiltersChange = (event: ChangeEvent<{ name?: string, value: unknown }>): void => {
        const name = event.target.name as keyof typeof filters;
        if (!Number.isNaN(event.target.value)) {
            setFilters({
                ...filters,
                [name]: Number(event.target.value),
            });
        }
    }

    const handleRefresh = () => {
        setQuery('');
        setSortType(SortType.NAME_ASC);
        setFilters({
            raisedHand: RaisedHand.ALL,
            famMeet: FamMeet.ALL,
            zoomDevice: ZoomDevice.ALL,
        });
    }

    const getNewData = () => {
        const data = getData();
        setData(data);
    }

    return (
        <DraggableFrame ref={frameRef} {...frameSetting} handleResize={() => {}}>
            <CssBaseline />
            <Paper elevation={5} className={classes.root}>
                <Header
                    className={classes.header}
                    title='ユーザー一覧'
                    handleFrameDoubleClick={handleFrameDoubleClick}
                    handleRefresh={getNewData}
                    handleClose={handleClose}
                />
                <Grid
                    container
                    className={classes.body}
                    direction='column'
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                >
                    {/* <Grid
                        container
                        item
                        justify='flex-start'
                        alignItems='center'
                        className={classes.tool1}
                    >
                        <UserSearch
                            label='ユーザー検索'
                            query={query}
                            handleChange={handleQueryChange}
                            clearQuery={() => setQuery('')}
                            className={classes.queryField}
                        />
                        <SelectBox
                            key='userControlSort'
                            label='挙手'
                            value={sortType}
                            options={sortOptions}
                            inputProps={{
                                name: 'userControlSort',
                                id: 'userControlSortInput'
                            }}
                            handleChange={handleSortChange}
                            className={classes.sortField}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        justify='flex-start'
                        alignItems='center'
                        className={classes.tool2}
                    >
                        <SelectBox
                            key='raisedHand'
                            label='挙手'
                            value={filters.raisedHand}
                            options={raisedHandOptions}
                            inputProps={{
                                name: 'raisedHand',
                                id: 'raisedHandFilterInput'
                            }}
                            handleChange={handleFiltersChange}
                            className={classes.filterField}
                        />
                        <SelectBox
                            key='famMeet'
                            label='ミート'
                            value={filters.famMeet}
                            options={famMeetOptions}
                            inputProps={{
                                name: 'famMeet',
                                id: 'famMeetFilterInput'
                            }}
                            handleChange={handleFiltersChange}
                            className={classes.filterField}
                        />
                        <SelectBox
                            key='zoomDevice'
                            label='デバイス'
                            value={filters.zoomDevice}
                            options={zoomDeviceOptions}
                            inputProps={{
                                name: 'zoomDevice',
                                id: 'zoomDeviceFilterInput'
                            }}
                            handleChange={handleFiltersChange}
                            className={classes.filterField}
                        />
                        <RefreshButton
                            color='primary'
                            size='large'
                            handleRefresh={handleRefresh}
                            className={classes.refreshBtn}
                        />
                    </Grid> */}
                    <Grid item className={`customScrollBar ${classes.results}`}>
                        {displayList.length > 0
                            ? <UserList list={displayList} sendZoomSpeakable={sendZoomSpeakable} />
                            : <p>一致するユーザーはいません。</p>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </DraggableFrame>
    )
}

export const UsersControl = forwardRef(UsersControlComponent);
import React, { forwardRef, ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios, { AxiosResponse } from 'axios';
import { Button, DialogContentText, Switch, Typography, Grid } from '@material-ui/core';
import { JsonFloor } from '../admin/FloorList';
import BaseDialog from '../user/BaseDialog';
import FloorSettingRestrictInfo from '@fsi/jfs-sdk';
import JfsClient from '@fsi/jfs-sdk';
import { JfsError } from '@fsi/jfs-sdk';
import { useHistory } from 'react-router';
import { Utility } from '../common/Utility';

class ConfirmInfo {
    open: boolean = true;
    title: string = "";
    message1: string = "";
    message2: string = "";
    isOk: boolean = false;

    constructor(init: Partial<ConfirmInfo>) {
        Object.assign(this, init);
    }
}

interface Props {
    jsonFloor: JsonFloor
}

// 公開したいメソッドの定義
export interface FloorSettingRestrictHandler {
    open(): void;
}

/**
 * FAMcampus専用機能(フロア内機能制限設定)
 * @param props 
 * @param ref 
 * @returns 
 */
const FloorSettingRestrictComponent: ForwardRefRenderFunction<FloorSettingRestrictHandler, Props> = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [targetFloorName, setTargetFloorName] = useState("");
    const [enabledMeet, setEnabledMeet] = useState(false);
    const [enabledScreenShare, setEnabledScreenShare] = useState(false);
    const [enabledWhiteBoard, setEnabledWhiteBoard] = useState(false);
    const [enabledChat, setEnabledChat] = useState(false);
    const [enabledTubuyaki, setEnabledTubuyaki] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const history = useHistory();
    // const [floorSettingRestrictInfo, setFloorSettingRestrictInfo] = useState(FloorSettingRestrictInfo);

    useEffect(() => {
        async function getInfo() {
            await handleFetchFloorSetting();
        }
        if(props.jsonFloor.id > 0){
            setTargetFloorName(props.jsonFloor.floorName);
            getInfo();
        }
    }, [props.jsonFloor])

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
    }))

    const handleChangeEnabledMeet = async (event: React.ChangeEvent<HTMLInputElement>) => {
            setEnabledMeet(event.target.checked);
            if(!event.target.checked){
                // 通話を無効にした場合は画面共有機能・ホワイトボード機能も無効にする
                setEnabledScreenShare(false);
                setEnabledWhiteBoard(false);
            }
    }

    const handleChangeEnabledScreenShare = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabledScreenShare(event.target.checked);
    }

    const handleChangeEnabledWhiteBoard = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabledWhiteBoard(event.target.checked);
    }

    const handleChangeEnabledChat = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabledChat(event.target.checked);
    }

    const handleChangeEnabledTubuyaki = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabledTubuyaki(event.target.checked);
    }

    const handleFetchFloorSetting = () => {
        // フロア機能制限設定の取得
        httpClient.getFloorSettingRestrict(sessionStorage.getItem("TABID") as string, props.jsonFloor.id)   //エラーにはなっているがhttpclientでは定義済み
        .then(response => {
            // 送られたオリジナルオブジェクトの設定情報を保存する
            setTargetFloorName(props.jsonFloor.floorName);
            setEnabledMeet(response.enabledMeet);
            setEnabledScreenShare(response.enabledScreenShareButton);
            setEnabledWhiteBoard(response.enabledWhiteBoardButton);
            setEnabledChat(response.enabledMemoButton);
            setEnabledTubuyaki(response.enabledTubuyaki);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleConfirm = () => {
        confirmUpdate();
    }

    const handleRegist = () => {
        // フロア機能制限設定の登録
        httpClient
          .updateFloorSettingRestrict(
            sessionStorage.getItem("TABID") as string, 
            props.jsonFloor.id, 
            enabledMeet, 
            enabledScreenShare, 
            enabledWhiteBoard, 
            enabledChat, 
            enabledTubuyaki
          )
          .then(response => {
            console.log("response:" + response.toString());
            handleClose();
            if(response.toString() === "LOGOUT"){
                // 更新時に自分もログアウトされた場合は処理後に強制ログアウト
                Utility.logout(history);
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    const confirmUpdate = () => {
        // alert("enable:" + state.enable);
        setConfirmInfo(new ConfirmInfo({
            title: "注意！",
            message1: "OKを押すと、「" + targetFloorName + "」の設定更新を開始します。",
            message2: "現在フロア内に滞在しているユーザーは、いったん強制ログアウトされます。"
        }));
    }

    useEffect(() => {
        if(props.jsonFloor.id > 0){
            if(confirmInfo.isOk){
                setLoading(true);
                handleRegist();
            }
        }
    }, [confirmInfo.isOk])

    const handleClose = () => {
        handleFetchFloorSetting();
        setOpen(false);
    }

    const draw = useMemo(() => {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth disableBackdropClick>
                <DialogTitle id="floor-manage-dialog-title" >{props.jsonFloor.floorName+"　フロア内機能制限設定"}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item sm={12}>
                            <span style={{fontSize: 18, fontWeight: "bold", marginLeft: 20, marginRight: 225}}>ビデオ通話を有効にする</span>
                                <Switch
                                    checked={enabledMeet}
                                    onChange={handleChangeEnabledMeet}
                                    name="checkedEnabledMeet"
                                />
                        </Grid>
                        <Grid item sm={12} style={{marginLeft:40}}>
                            <span style={{fontSize: 18, marginLeft: 20, marginRight: 96}}>通話中の画面共有機能を有効にする</span>
                                <Switch
                                    disabled={!enabledMeet}
                                    checked={enabledScreenShare}
                                    onChange={handleChangeEnabledScreenShare}
                                    name="checkedEnabledScreenShare"
                                />
                        </Grid>
                        <Grid item sm={12} style={{marginLeft:40, display:"flex"}}>
                            <span style={{fontSize: 18, marginLeft: 20, marginRight: 132}}>通話中のホワイトボード機能と<br/>ファイル表示機能を有効にする</span>
                                <Switch
                                    disabled={!enabledMeet}
                                    checked={enabledWhiteBoard}
                                    onChange={handleChangeEnabledWhiteBoard}
                                    name="checkedEnabledWhiteBoard"
                                />
                        </Grid>
                        <Grid item sm={12} style={{marginTop: 12}}>
                        <span style={{fontSize: 18, fontWeight: "bold", marginLeft: 20, marginRight: 205}}>チャット機能を有効にする</span>
                            <Switch
                                checked={enabledChat}
                                onChange={handleChangeEnabledChat}
                                name="checkedEnabledChat"
                            />
                        </Grid>
                        <Grid item sm={12} style={{marginTop: 10}}>
                            <span style={{fontSize: 18, fontWeight: "bold", marginLeft: 20, marginRight: 205}}>つぶやき機能を有効にする</span>
                                <Switch
                                    checked={enabledTubuyaki}
                                    onChange={handleChangeEnabledTubuyaki}
                                    name="checkedEnabledTubuyaki"
                                />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        OK
                    </Button>
                    <Button onClick={handleClose} color="primary" >
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }, [open, enabledMeet, enabledScreenShare, enabledWhiteBoard, enabledChat, enabledTubuyaki, props.jsonFloor.floorName])

    return (
        <Fragment>
            {draw}
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText style={{fontSize: 18, color: "red", fontWeight: "bold"}}>{confirmInfo.title}</DialogContentText>
                    <DialogContentText style={{fontSize: 16}}>{confirmInfo.message1}</DialogContentText>
                    <DialogContentText style={{fontSize: 16, fontWeight: "bold"}}>{confirmInfo.message2}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true }) }}>OK</Button>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        </Fragment>
    )
}

export const FloorSettingRestrict = forwardRef(FloorSettingRestrictComponent);
import React, { useMemo, Fragment } from 'react';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    className: string,
    floorObject: FloorObject,
}

export default function AdminMessageBoard(props: Props) {
    const { className } = props;
    const { id, offsetLeft, objectMaster, offsetTop, text1 } = props.floorObject
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const drawAdminMessageBoard = useMemo(() => {
        return (
            <div
                style={{
                    position: 'absolute',
                    background: 'transparent',
                    padding: '15px 20px',
                    margin: '0',
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                    textAlign: 'left',
                    whiteSpace: 'pre-line',
                    overflow: 'auto',
                    cursor: 'auto',
                    zIndex: ZIndex.floorObject + 1,
                }}
            >
                {text1.length === 0
                    ? <div style={{ width: 15, height: 15 }} />
                    : text1}
            </div>)
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps 
        [text1])

    const draw = useMemo(() =>
        <Fragment>
            <div
                key={`adminMessageBoard${id}`}
                id={`adminMessageBoard${id}`}
                className={className}
                style={{
                    position: "absolute",
                    left: offsetLeft,
                    top: offsetTop,
                    backgroundSize: "contain",
                    //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                    backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                    backgroundRepeat: "no-repeat",
                    width: objectMaster.width,
                    height: objectMaster.height,
                    zIndex: ZIndex.floorObject,
                }}
            >
                {drawAdminMessageBoard}
            </div>
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [offsetLeft, offsetTop, objectMaster, text1]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
import React, { forwardRef, ForwardRefRenderFunction, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Grid, DialogContentText, Switch, TextField, Typography } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import { StatusTimerInfo } from '../common/JsonClass';
import List from '@material-ui/core/List';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import JfsClient, { User, StatusTimerInfo as StatusTimerResponse } from '@fsi/jfs-sdk';

const switchTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

interface Props {
    user: User;
}

export interface statusTimerMenuHandler {
    setStatusTimer(): void;
    checkErr(): boolean,
}


const MyUserStatusTimerMenuCompornent: ForwardRefRenderFunction<statusTimerMenuHandler, Props> = (props, ref) => {
    const [dense, setDense] = useState(false);
    const [checked, setChecked] = useState([] as string[]);
    const [setTimeList, setSetTimeList] = useState([] as number[]);
    const [errMsgFlagList, setErrMegFlagList] = useState([] as boolean[]);
    const [getDataFlag, setGetDataFlag] = useState(true);
    const [data, setData] = useState({
        user: props.user,     // ユーザ情報
        statusMenuIdList: [0],    // ステータスのid
        setTimerList: [0],    // idに応じたステータスタイマー
        timerFlagList: [] as string[],   // ステータスタイマーのオンオフ
    });
    const [statusTimerMenuData, setStatsuTimerMenuData] = useState([] as StatusTimerInfo[]);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        setStatusTimer: () => {
            // OKボタン押下時
            handleAddStatuTimerData();
        },
        checkErr: () => {
            // ステータスタイマーでエラーになっているかチェック
            let errFlag = false;
            let errcheck = errMsgFlagList.indexOf(true);
            if (errcheck !== -1) {
                errFlag = true;
            }
            return errFlag;
        }
    }));

    useEffect(() => {
        if (getDataFlag === true) {
            // DBから値を取得
            handleGetStatuTimerData();
            setGetDataFlag(false);
        }
    }, []);

    // DBからデータを取得してきたら対応する値に格納
    useEffect(() => {
        let setTimer = [] as number[];
        let check = [] as string[];
        statusTimerMenuData.forEach(data => {
            if (data.avatarMenuMasterId === 0) {
                return;
            }

            if (data.setTimer === 0) {
                setTimer.push(data.statusTimerInit);
            } else {
                setTimer.push(data.setTimer);
            }

            if (data.timerActive === false) {
                check.push(data.avatarMenuMasterName);
            }

        })
        setSetTimeList(setTimer);
        setChecked(check);

    }, [statusTimerMenuData]);

    // DBに値を登録、更新
    const handleAddStatuTimerData = () => {
        // テキストボックスに小数点が入力されたら、切り捨てる
        for (let i = 0; i < data.setTimerList.length; i++) {
            if (!data.setTimerList[i].toString().match(/^[0-9\b]+$/)) {
                let newValue = 0;
                newValue = Math.floor(Number(data.setTimerList[i]));
                data.setTimerList[i] = newValue;
            }
        }

        // axios
        //     .create({
        //         withCredentials: true,
        //     })
        //     .post('/api/user/status/add', new URLSearchParams({
        //         tab_id: sessionStorage.getItem("TABID") as string,
        //         user_login_id: data.user.loginId.toString(),
        //         statusMenu_id_list: data.statusMenuIdList.toString(),
        //         setTimer_list: data.setTimerList.toString(),
        //         timer_flag_list: data.timerFlagList.toString(),
        //     }))
        httpClient.addStatusTimer(
            sessionStorage.getItem("TABID") as string,
            data.user.loginId,
            data.statusMenuIdList,
            data.setTimerList,
            data.timerFlagList,
        )
            .then((response) => {
                let result = response;
                // console.log("result:", result);
            })
            .catch((err) => {
                console.log("handleAddStatuTimerData error.");
                console.log(err.response);
            });
    }

    // DBから値を取得
    const handleGetStatuTimerData = () => {
        // axios
        //     .create({
        //         withCredentials: true,
        //     })
        //     .post('/api/user/status/get', new URLSearchParams({
        //         tab_id: sessionStorage.getItem("TABID") as string,
        //         user_login_id: data.user.loginId.toString(),
        //     }))
        httpClient.getStatusTimer(sessionStorage.getItem("TABID") as string, data.user.loginId)
            .then((response: StatusTimerResponse[]) => {
                let result = response;
                // console.log("result:", result);

                // データを整える
                if (result.length !== 0) {
                    let getStatusTimerData = [];
                    for (let i = 0; i < result.length; i++) {
                        let temp = {
                            avatarMenuMasterId: 0,
                            avatarMenuMasterName: "",
                            setTimer: 0,
                            timerActive: false,
                            orderBy: 0,
                            statusTimerInit: 0,
                        };

                        temp["avatarMenuMasterId"] = result[i].avatarIdAndName.avaterId;
                        temp["avatarMenuMasterName"] = result[i].avatarIdAndName.avaterName;
                        temp["setTimer"] = result[i].setTimer;
                        temp["timerActive"] = result[i].timerActive;
                        temp["orderBy"] = result[i].orderby;
                        temp["statusTimerInit"] = result[i].statusTimerInit;
                        getStatusTimerData.push(temp);
                    }
                    // ソート
                    getStatusTimerData.sort((item1, item2) => {
                        return item1.orderBy - item2.orderBy
                    })

                    // データをセット
                    setStatsuTimerMenuData(getStatusTimerData);
                }
            })
            .catch((err) => {
                console.log("handleGetStatuTimerData error.");
                console.log(err.response);
            });
    }

    // サーバに渡す用のアバターメニューリストIDと初期値とタイマーフラグを取得
    const getDataList = (menuList: StatusTimerInfo[], setTimeList: number[], timerFlagList: string[]) => {
        let avatarMenuMasterIdList = [] as number[];
        menuList.forEach(menu => {
            avatarMenuMasterIdList.push(menu.avatarMenuMasterId);
        })
        setData({ ...data, statusMenuIdList: avatarMenuMasterIdList, setTimerList: setTimeList, timerFlagList: timerFlagList });
    }

    // switchのオンオフ切替
    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };


    const handleChange = (value: string, index: number) => {
        const newSetTimeList = [...setTimeList];
        const newErrMegFlagList = [...errMsgFlagList];
        newSetTimeList[index] = Number(value);

        if (Number(value) < 1 || Number(value) > 180) {
            newErrMegFlagList[index] = true;
        } else {
            newErrMegFlagList[index] = false;
        }

        setErrMegFlagList(newErrMegFlagList);
        setSetTimeList(newSetTimeList);
        setData({ ...data, setTimerList: newSetTimeList });

    }

    const ErrMsg = useMemo(() => {
        return (
            <div>
                <Typography variant="h6" style={{ color: "red", padding: "3px 0px 3px 17px", fontSize: "17px" }}>
                    タイマーは180分までです。
                </Typography>
            </div>
        );
    }, [setTimeList])


    // ダイアログに描画(SetMyAvatarから呼び出す)
    const drawStatusTimer = useMemo(() => {

        if (statusTimerMenuData.length === 0) {
            return;
        }
        // DBに登録・更新するデータを取得
        getDataList(statusTimerMenuData, setTimeList, checked);

        return (
            <Fragment>
                <hr />
                <DialogContentText id="setMyAvatarDialog-description">
                    <Typography variant="h6">ステータスタイマー</Typography>
                    <Typography variant="body2">
                        ステータス変更後、設定した時間が経過するとお知らせを表示します。<br></br>
                        設定できる時間は1分～180分までです。
                    </Typography>
                </DialogContentText>
                <List dense={dense}>
                    {statusTimerMenuData.map((subMenu, index) => (
                        <Grid container spacing={2} style={{ padding: "0px 0px 0px 15px" }}>
                            <Grid key={1} item>
                                <Typography variant="h6">{subMenu.avatarMenuMasterName}</Typography>
                            </Grid>
                            <Grid key={2} item>
                                <ThemeProvider theme={switchTheme}>
                                    <Switch
                                        checked={checked.indexOf(subMenu.avatarMenuMasterName) === -1}
                                        onChange={handleToggle(subMenu.avatarMenuMasterName)}
                                        color="primary"
                                        name="checkedStatusTimer"
                                        inputProps={{ 'aria-label': 'checkedStatusTimer' }}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid key={3} item>
                                <TextField
                                    id="loginId_search"
                                    size="small"
                                    // label={subMenu.avatarMenuMasterName}
                                    variant="outlined"
                                    type={"number"}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    style={{ width: 90 }}
                                    disabled={checked.indexOf(subMenu.avatarMenuMasterName) === -1 ? false : true}
                                    onChange={(event) => handleChange(event.target.value, index)}
                                    value={setTimeList[index]}
                                />
                            </Grid>
                            <Grid key={4} item>
                                <Typography variant="h6">分</Typography>
                            </Grid>
                            <Grid key={5} item>
                                {errMsgFlagList[index] === true ? ErrMsg : ""}
                            </Grid>
                        </Grid>
                    ))}
                </List>
            </Fragment>
        );
    }, [checked, setTimeList]);

    return (
        <Fragment>
            {drawStatusTimer}
        </Fragment>
    );

}

export const MyUserStatusTimerMenu = forwardRef(MyUserStatusTimerMenuCompornent);

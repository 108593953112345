import React, { Fragment, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LoginJson } from '../common/JsonClass';
import AudioPlayer from './AudioPlayer';
import { Hidden } from '@material-ui/core';
import { Utility } from '../common/Utility';


export default function SigninPage() {
  const { history } = useReactRouter();
  const query: string = history.location.search;
  var errorParam = "";
  if (query !== "") {
    errorParam = "?code=" + Utility.getQueryParam(query, "code");
  }

  const signinpage = Utility.getSigninPage();
  history.push({
      pathname: signinpage,
      search: errorParam,
  });

  return (
    <Fragment/>
  );
}
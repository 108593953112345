import { useState, useEffect } from 'react';

const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: getWidth(),
        height: getHeight(),
    });

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            let timeoutId: NodeJS.Timeout;
            const resizeListener = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(
                    () => setWindowSize({ width: getWidth(), height: getHeight() }),
                    150
                );
            }
            window.addEventListener('resize', resizeListener);
            // clean up
            return () => {
                window.removeEventListener('resize', resizeListener);
                unmounted = true;
            }
        }
    }, []);

    return windowSize;
}

export default useWindowSize;
import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.palette.background.paper,
            textAlign: 'center',
        },
    })
)
const NotFound: React.FC = () => {
    const classes = useStyles();
    return (
        <Grid
            className={classes.root}
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Typography
                style={{ marginBottom: 0 }}
                variant='h1'
                component='h1'
            >
                404
            </Typography>
            <p style={{ marginTop: 0 }}>そのページは存在しません。</p>
            {/*
            <Button
                component={Link}
                to={'/'}
                variant='contained'
                color='primary'
                style={{ marginTop: 40, width: 220 }}
            >
                サインインページへ戻る
            </Button>
            */}
        </Grid>
    )
}
export default NotFound
import React, { Fragment } from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import TenantSettingSamlPaper from './TenantSettingSamlPaper';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
    },
}));


export default function TenantSettingPage() {
    const classes = useStyles();
    const [t] = useTranslation();

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                <div>
                    <Box m={5}></Box>
                    <TenantSettingSamlPaper />
                </div>
            </main>

        </div>
    );

}

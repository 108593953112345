import React, { Fragment, useEffect, useMemo, useState } from 'react';
import useReactRouter from 'use-react-router';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import { RouteComponentProps } from 'react-router-dom';
import { Utility } from '../common/Utility';
import JfsClient, { JfsError } from '@fsi/jfs-sdk';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(./api/service/picture)`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        marginTop: '80px',
        padding: '20px',
        backgroundColor: 'rgba(255,255,255,0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface Props extends RouteComponentProps {
}

export default function ResetPassword(props: Props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { history } = useReactRouter();
    const [isDisable, setDisable] = useState(false);
    const [loginId, setLoginId] = useState("");
    const [postParams, setPostParams] = useState({
        newPassword: "",
        newPasswordConfirm: "",
    })
    const [initUrl, setInitURL] = useState("http://localhost:3000/resetpassword?token=");
    const [url, setUrl] = useState("");
    const [changePageId, setChangePageId] = useState(0); /** 0:サインインID入力画面, 1:パスワード変更画面 */
    const [tokenFlag, setTokenFlag] = useState(false);
    const [resetpwFlag, setResetpwFlag] = useState(false);
    const [sendMailOpen, setSendMailOpen] = useState(false);
    const [ErrMsgOpen, setErrMsgOpen] = useState(false);
    const [resetPwOpen, setResetPwOpen] = useState(false);
    const [errMsgOnLoginId, setErrMsgOnLoginId] = useState('');
    const [errMsgOnNewPassword, setErrMsgOnNewPassword] = useState('');
    const [errMsgOnNewPasswordConfirm, setErrMsgOnNewPasswordConfirm] = useState('');
    const [errMsgOnDialog, setErrMsgOnDialog] = useState('');
    const [message, setMessage] = useState('');
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const MessageBox = {
        backLogin: 'サインイン画面に戻る',
        sendEmail: 'メールを送信しました。',
        ResetPwSuccsess: 'パスワードの再設定が完了しました。',
    }

    const errorMessageBox = {
        LoginIdNull: 'サインインIDを入力してください。',
        LoginIdNotUse: 'このサインインIDは使用できません。',
        changeFail: 'パスワードの変更に失敗しました。',
        newPasswordNull: '新しいパスワードを入力してください。',
        newPasswordConfirmNull: '新しいパスワード（確認）を入力してください。',
        newPasswordUnmatch: '新しいパスワードが一致しません。',
        TokenGetFail: '認証情報の取得に失敗しました。',
        TokenSaveDBFail: '認証情報の保存に失敗しました。',
        TokenNotFind: '認証情報が見つかりませんでした。サインイン画面に戻ります。',
        Tokenexpired: '認証情報の有効期限が切れています。パスワード再設定の手順をやり直してください。',
        MailSendFail: 'メールの送信に失敗しました。',
        ValidFail: '新しいパスワードは、半角英数8～32文字(大文字,小文字,数字を含む) で設定してください。',
        UserDeleted: 'ユーザーが削除されているため、再設定できません。',
        TenantDisabled: '所属テナントが削除されているため、再設定できません。',
        SSOUser: 'シングルサインオンユーザーであるため、再設定できません。',
        UserNotFound: 'ユーザデータが見つかりません。',
    }

    useEffect(() => {
        function preLogin() {
            var params = new URLSearchParams();
            axios.post('/login/prelogin', params)
                .then((e: AxiosResponse) => {
                    // console.log(e);
                }).catch(err => {
                    console.log(err);
                });
        }

        preLogin();
        handleURLcheck();
    }, [])

    // トークン付きリンク押下判定
    const handleURLcheck = () => {
        let query: string = props.location.search;
        if (query !== '') {
            let token = Utility.getQueryParam(query, "token");
            if (token !== '') {
                setChangePageId(1);
                handlechkTokenTime();
                setResetpwFlag(false);
            } else {
                setChangePageId(0);
            }
        } else {
            setChangePageId(0);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostParams({ ...postParams, [event.target.name]: event.target.value })
    }

    const handleChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginId(event.target.value);
    }

    const handleSendEmailProcess = () => {
        // パスワード再発行ボタン押下時の処理（メール送信）
        handleSendResetPasswordMail();
    }

    const handleResetPasswordProcess = () => {
        // パスワード再発行ボタン押下時の処理（パスワード再設定）
        // トークンの有効時間を確認
        handlechkTokenTime();
        setResetpwFlag(true);
    }

    useEffect(() => {
        if (tokenFlag === true) {
            // パスワード再発行
            handleResetPassword();
            setTokenFlag(false);
        }
    }, [tokenFlag]);

    const handleSendResetPasswordMail = async () => {
        // サインインID入力チェック
        function checkParameters(): boolean {
            let isSuccess = true;
            if (!loginId) {
                isSuccess = false;
                setErrMsgOnLoginId(errorMessageBox.LoginIdNull);
            } else {
                setErrMsgOnLoginId('');
            }

            return isSuccess;
        }

        // 入力チェック
        if (!checkParameters()) {
            return;
        }

        // パスワード再設定メールの送信
        function sendResetPasswordMail() {
            // let tabId = new Date().getTime()
            // let params = new URLSearchParams();
            // params.append('tab_id', tabId.toString());
            // params.append('login_id', loginId);
            // axios.post('/api/reset/password/sendResetPasswordMail', params)
            //     .then(response => {
            //         setMessage("メールの送信が完了しました。送信されたメールの内容に従ってパスワードを再設定してください。");
            //         setSendMailOpen(true);

            //     }).catch(err => {
            //         console.log(err);
            //         setMessage(err.response.data);
            //         // メール送信ダイアログを表示
            //         setSendMailOpen(true);
            //     });
            httpClient.sendResetPasswordMail(new Date().getTime().toString(), loginId, window.location.href)
                .then(response => {
                    // setMessage("メールの送信が完了しました。送信されたメールの内容に従ってパスワードを再設定してください。");
                    // setSendMailOpen(true);

                }).catch((err: JfsError) => {
                    // console.info(err.code);
                    // setMessage(err.response.data);
                    // setMessage(errorMessageBox.MailSendFail);
                    // メール送信ダイアログを表示
                    // setSendMailOpen(true);
                }).finally(() => {
                    setMessage("メールの送信が完了しました。送信されたメールの内容に従ってパスワードを再設定してください。");
                    setSendMailOpen(true);
                });
        }

        sendResetPasswordMail();
    }

    const handlechkTokenTime = () => {
        // トークンの有効時間確認処理
        let tabId = new Date().getTime().toString();
        let url = window.location.href;
        // let params = new URLSearchParams();
        // params.append('tab_id', tabId.toString());
        // params.append('url', window.location.href);
        // axios.post('/api/reset/password/tokencheck', params)
        //     .then(response => {
        //         let result = response.data as string;
        //         setTokenFlag(true);
        //     }).catch(err => {
        //         console.log(err);
        //         if (err.response.data === "NOT MATCH") {
        //             setErrMsgOnDialog(errorMessageBox.TokenNotFind);
        //         } else if (err.response.data === "EXPIRED") {
        //             setErrMsgOnDialog(errorMessageBox.Tokenexpired);
        //         }
        //         setTokenFlag(false);
        //         setErrMsgOpen(true);
        //     });
        httpClient.checkResetPasswordToken(tabId, url)
            .then(response => {
                let result = response as string;
                setTokenFlag(true);
            }).catch((err: JfsError) => {
                console.log(err);
                if (err.code === 102005) {
                    setErrMsgOnDialog(errorMessageBox.TokenNotFind);
                } else if (err.code === 101102) {
                    setErrMsgOnDialog(errorMessageBox.Tokenexpired);
                }
                setTokenFlag(false);
                setErrMsgOpen(true);
            });
    }

    // パスワード再設定
    const handleResetPassword = async () => {
        // パスワード再設定の実行判定
        if (resetpwFlag == false) {
            return;
        }

        // 入力チェック
        function checkParameters(): boolean {
            let isSuccess = true;

            if (!postParams.newPassword) {
                isSuccess = false;
                setErrMsgOnNewPassword(errorMessageBox.newPasswordNull);
            } else {
                setErrMsgOnNewPassword('');
            }

            if (!postParams.newPasswordConfirm) {
                isSuccess = false;
                setErrMsgOnNewPasswordConfirm(errorMessageBox.newPasswordConfirmNull);
            } else if (postParams.newPassword !== postParams.newPasswordConfirm) {
                isSuccess = false;
                setErrMsgOnNewPasswordConfirm(errorMessageBox.newPasswordUnmatch);
            } else {
                setErrMsgOnNewPasswordConfirm('');
            }

            return isSuccess;
        }

        // 入力チェック
        if (!checkParameters()) {
            return;
        }

        if (isDisable) {
            return;
        }
        setDisable(true);

        // パスワード再設定リクエスト
        let tabId: string = sessionStorage.getItem("TABID") as string;
        // let params = new URLSearchParams();
        // params.append('tab_id', tabId);
        // params.append('new', postParams.newPassword);
        // params.append('url', window.location.href);
        // axios.post('/api/reset/password/reset', params)
        //     .then(response => {
        //         setResetPwOpen(true);
        //     }).catch(err => {
        //         setDisable(false);
        //         if (err.response.status === 400) {
        //             enqueueSnackbar(err.response.data, { variant: 'error' });
        //         } else {
        //             enqueueSnackbar(errorMessageBox.changeFail, { variant: 'error' });
        //         }
        //     });

        httpClient.resetPassword(tabId, postParams.newPassword, window.location.href)
            .then(response => {
                setResetPwOpen(true);
            }).catch((err: JfsError) => {
                setDisable(false);
                let message = errorMessageBox.changeFail;
                if (err.httpStatusCode === 400) {
                    if (err.code === 102002) {
                        message = errorMessageBox.ValidFail;
                    } else if (err.code === 102005) {
                        message = errorMessageBox.UserNotFound;
                    } else if (err.code === 102003) {
                        message = errorMessageBox.TenantDisabled;
                    } else if (err.code === 102008) {
                        message = errorMessageBox.UserDeleted;
                    } else if (err.code === 103002) {
                        message = errorMessageBox.SSOUser;
                    }
                }
                enqueueSnackbar(message, { variant: 'error' });
            });
        setResetpwFlag(false);
    }

    const handleUserKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (isDisable) {
            return;
        }
        if (event.keyCode === 13 /* 13=Enter Key */) {
            if (changePageId === 0) {
                handleSendEmailProcess();
            } else if (changePageId === 1) {
                handleResetPasswordProcess();
            }
        }
    }

    const handleBackLoginPage = () => {
        history.push("/");
    }

    const handleSendMailClose = () => {
        setSendMailOpen(false);
        handleBackLoginPage();
    };

    const handleErrDialogClose = () => {
        setErrMsgOpen(false);
        setErrMsgOnDialog('');
        handleBackLoginPage();
    };

    const handleResetPwClose = () => {
        setResetPwOpen(false);
        handleBackLoginPage();
    };

    const SendMailDialog = useMemo(() => {
        return (
            <div>
                <Dialog open={sendMailOpen} onClose={handleSendMailClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                    {/*<DialogTitle id="form-dialog-title" style={{ backgroundColor: "#ef6c00", color: "white" }}>メール送信</DialogTitle>*/}
                    <DialogContent>
                        <DialogContentText id="disconnect-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="release-dialog-OK-Button" onClick={handleSendMailClose} color="primary" autoFocus> OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }, [sendMailOpen])

    const ErrDialog = useMemo(() => {
        return (
            <div>
                <Dialog open={ErrMsgOpen} onClose={handleErrDialogClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                    <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#ef6c00", color: "white" }}>パスワード再設定 エラー</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="disconnect-dialog-description">
                            {errMsgOnDialog}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="release-dialog-OK-Button" onClick={handleErrDialogClose} color="primary" autoFocus> OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }, [ErrMsgOpen])

    const ResetPwDialog = useMemo(() => {
        return (
            <div>
                <Dialog open={resetPwOpen} onClose={handleResetPwClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                    {/*<DialogTitle id="form-dialog-title" style={{ backgroundColor: "#ef6c00", color: "white" }}>パスワード再設定</DialogTitle>*/}
                    <DialogContent>
                        <DialogContentText id="disconnect-dialog-description">
                            パスワードの再設定が完了しました。サインイン画面に戻ります。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="release-dialog-OK-Button" onClick={handleResetPwClose} color="primary" autoFocus> OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }, [resetPwOpen])

    const ChengePage = useMemo(() => {
        // ここでメール送信とパスワード変更の画面を切り替える
        if (changePageId === 1) {
            return (
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        パスワード再設定
                    </Typography>
                    <Typography variant="body2" >
                        パスワードを再設定する場合は、<br></br>
                        新しく設定するパスワードを入力してください。
                    </Typography>
                    {errMsgOnNewPassword.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnNewPassword}</div>) : ('')}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="新しいパスワード"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        autoFocus
                        value={postParams.newPassword}
                        onChange={handleChange}
                        onKeyDown={handleUserKeyDown}
                        disabled={isDisable}
                    />
                    {errMsgOnNewPasswordConfirm.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnNewPasswordConfirm}</div>) : ('')}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="newPasswordConfirm"
                        label="新しいパスワード（確認）"
                        type="password"
                        id="newPasswordConfirm"
                        autoComplete="new-password-confirm"
                        value={postParams.newPasswordConfirm}
                        onChange={handleChange}
                        onKeyDown={handleUserKeyDown}
                        disabled={isDisable}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleResetPasswordProcess}
                        disabled={isDisable}
                    >
                        パスワード再設定
                    </Button>
                    <Link style={{ cursor: 'pointer' }} onClick={handleBackLoginPage}>
                        {MessageBox.backLogin}
                    </Link>
                    {ErrDialog}
                    {ResetPwDialog}
                </div>
            );
        } else {
            return (
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        パスワード再設定
                    </Typography>
                    <Typography variant="body2" >
                        入力したサインインIDにメールが送信されます。<br></br>
                        メールの内容に従い、引き続きパスワード再設定を行ってください。
                    </Typography>
                    {errMsgOnLoginId.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnLoginId}</div>) : ('')}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="loginId"
                        label="サインインID"
                        name="loginId"
                        autoComplete="loginId"
                        autoFocus
                        value={loginId}
                        onChange={handleChangeId}
                        onKeyDown={handleUserKeyDown}
                        disabled={isDisable}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSendEmailProcess}
                        disabled={isDisable}
                    >
                        メール送信
                    </Button>
                    <Link style={{ cursor: 'pointer' }} onClick={handleBackLoginPage}>
                        {MessageBox.backLogin}
                    </Link>
                    {SendMailDialog}
                </div>
            );
        }
    }, [loginId, postParams.newPassword, postParams.newPasswordConfirm, changePageId, errorMessageBox]);


    return (
        <Fragment>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.image}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} sm={6} md={4}>
                            {ChengePage}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
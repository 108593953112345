import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { LoginJson } from '../common/JsonClass';
import JfsClient from '@fsi/jfs-sdk';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(../service_info/famcampus_login_image.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    // margin: theme.spacing(8, 4),
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    marginTop: '80px',
    padding: '20px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    // margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useReactRouter();
  const [isDisable, setDisable] = useState(false);
  const state = history.location.state as any;

  const [postParams, setPostParams] = useState({
    password: "",
    newPassword: "",
    newPasswordConfirm: "",
  })
  const [errMsgOnPassword, setErrMsgOnPassword] = useState('');
  const [errMsgOnNewPassword, setErrMsgOnNewPassword] = useState('');
  const [errMsgOnNewPasswordConfirm, setErrMsgOnNewPasswordConfirm] = useState('');
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;

  const errorMessageBox = {
    forbidden: 'ログイン認証してください。',
    changeFail: 'パスワードの変更に失敗しました。',
    passwordNull: 'パスワードを入力してください。',
    newPasswordNull: '新しいパスワードを入力してください。',
    newPasswordConfirmNull: '新しいパスワード（確認）を入力してください。',
    newPasswordUnmatch: '新しいパスワードが一致しません。',
    newPasswordNotChange: '現在のパスワードと同一のパスワードへは変更できません。',
    UserNotFound: 'ユーザデータが見つかりません。',
    ValidFail: '新しいパスワードは、半角英数8～32文字(大文字,小文字,数字を含む) で設定してください。',
    oldPasswordUnmatch: 'パスワードが間違っています。',
  }

  const mobileAppCodeComponent = require('./mobile/mobileAppCode');
  useEffect(() => {
    function viewDidloadTop(){
      mobileAppCodeComponent.viewDidload();
    }
    setTimeout(viewDidloadTop,1);
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostParams({...postParams, [event.target.name]: event.target.value})
  }

  const handleChangePassword = async () => {
    // 入力チェック
    function checkParameters(): boolean {
      let isSuccess = true;

      if (!postParams.password) {
        isSuccess = false;
        setErrMsgOnPassword(errorMessageBox.passwordNull);
      } else {
        setErrMsgOnPassword('');
      }

      if (!postParams.newPassword) {
        isSuccess = false;
        setErrMsgOnNewPassword(errorMessageBox.newPasswordNull);
      } else if (postParams.newPassword === postParams.password) {
        isSuccess = false;
        setErrMsgOnNewPassword(errorMessageBox.newPasswordNotChange);
      } else {
        setErrMsgOnNewPassword('');
      }

      if (!postParams.newPasswordConfirm) {
        isSuccess = false;
        setErrMsgOnNewPasswordConfirm(errorMessageBox.newPasswordConfirmNull);
      } else if (postParams.newPassword !== postParams.newPasswordConfirm) {
        isSuccess = false;
        setErrMsgOnNewPasswordConfirm(errorMessageBox.newPasswordUnmatch);
      } else {
        setErrMsgOnNewPasswordConfirm('');
      }

      return isSuccess;
    }

    // 入力チェック
    if (!checkParameters()) {
      return;
    }

    if (isDisable) {
      return;
    }
    setDisable(true);

    // パスワード変更リクエスト
    let tabId:string = sessionStorage.getItem("TABID") as string;
    // let params = new URLSearchParams();
    // params.append('tab_id', tabId);
    // params.append('current', postParams.password);
    // params.append('new', postParams.newPassword);
    // console.log(params);
    // axios.post('/api/user/password/change', params)
    httpClient.changePassword(tabId, postParams.password, postParams.newPassword)
      .then(response => {
        let timeoutTime = 3000;
        enqueueSnackbar("パスワードを変更しました。", { variant: 'success', autoHideDuration: timeoutTime });
        setTimeout(() => {
          let loginJson = response as LoginJson;
          let isMobile = false;
          if(state){
            if(state.type === "mobile"){
              isMobile = true;
            }
          }
          if(isMobile){
            history.push("/mobiletop");
          }else
          if (loginJson.action === "TOP") {
            history.push({
              pathname: "/selectdevice",
              state: { nextpage: "/top" }
            });
          } else if (loginJson.action === "FLOOR") {
            history.push({
              pathname: "/selectdevice",
              state: { 
                nextpage: "/floor", 
                path: loginJson.webSocketUrl, 
                id: loginJson.floorId,
                requireCamera: loginJson.requireCamera,
                requireMic: loginJson.requireMic,
              }
            });
          }
        }, timeoutTime);
      }).catch(err => {
        setDisable(false);
        if (err.httpStatusCode === 403) {
          // ログインしていない ⇒ Snackbar で、メッセージ＋ログアウトボタン
          enqueueSnackbar(errorMessageBox.forbidden, { variant: 'error', autoHideDuration: 10000, action });
        } else if (err.httpStatusCode === 400) {
          console.log(err.code);
          // enqueueSnackbar(err.response.data, { variant: 'error' });
          let message = errorMessageBox.changeFail;
          if (err.code === 102005) {
            message = errorMessageBox.UserNotFound;
          } else if (err.code === 101011) {
            message = errorMessageBox.oldPasswordUnmatch;
          } else if (err.code === 102002) {
            message = errorMessageBox.ValidFail;
          }
          enqueueSnackbar(message, { variant: 'error' });
        } else {
          enqueueSnackbar(errorMessageBox.changeFail, { variant: 'error' });
        }
        // 未入力以外のエラーが出た時非表示
        setErrMsgOnPassword('');
      });
  }

  const action = (key: any) => (
    <Button onClick={() => {
      // systemtop外なので要対応
      //axios.post('/api/system/logout').finally(() => { history.replace("/"); });
      httpClient.sendSystemSignout().finally(() => { history.replace("/"); });
    }}>ログイン</Button>
  );

  const handleUserKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isDisable) {
      return;
    }
    if (event.keyCode === 13 /* 13=Enter Key */) {
      handleChangePassword();
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.image}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                FAMcampus
              </Typography>
              {errMsgOnPassword.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnPassword}</div>) : ('')}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="現在のパスワード"
                type="password"
                id="password"
                autoComplete="current-password"
                value={postParams.password}
                onChange={handleChange}
                onKeyDown={handleUserKeyDown}
                disabled={isDisable}
              />
              {errMsgOnNewPassword.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnNewPassword}</div>) : ('')}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="新しいパスワード"
                type="password"
                id="newPassword"
                autoComplete="new-password"
                value={postParams.newPassword}
                onChange={handleChange}
                onKeyDown={handleUserKeyDown}
                disabled={isDisable}
              />
              {errMsgOnNewPasswordConfirm.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnNewPasswordConfirm}</div>) : ('')}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPasswordConfirm"
                label="新しいパスワード（確認）"
                type="password"
                id="newPasswordConfirm"
                autoComplete="new-password-confirm"
                value={postParams.newPasswordConfirm}
                onChange={handleChange}
                onKeyDown={handleUserKeyDown}
                disabled={isDisable}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleChangePassword}
                disabled={isDisable}
              >
                パスワードを変更
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
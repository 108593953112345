import { makeStyles } from '@material-ui/styles';
import { FloorObject } from '../common/JsonClass';
import ZIndex from '../common/ZIndex';
import { useState, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';

const useStyles = makeStyles(() => ({
    textStyle: {
        fontWeight: 550,
    }
}))

export interface Props {
    className: string,
    floorObject: FloorObject,
}

export interface usersAmountHandler {
    getData: () => string;
    setData: (usersAmount: string) => void;
}

const UsersAmountComponent: ForwardRefRenderFunction<usersAmountHandler, Props> = (props, ref) => {
    const { className } = props;
    const [usersAmount, setData] = useState('000');
    const classes = useStyles();
    const { id, offsetLeft, offsetTop, objectMaster } = props.floorObject
    
    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        getData() {
            return usersAmount;
        },
        setData(usersAmount: string) {
            const amount = ( '000' + usersAmount ).slice( -3 );
            setData(amount);
        },
    }));

    const component = useMemo(() => {
        return (
            <div 
                key={`floorUsersAmount${id}`}
                id={`floorUsersAmount${id}`}
                className={className}
                style={{
                    left: offsetLeft,
                    top: offsetTop,
                    height: objectMaster.height,
                    width: objectMaster.width,
                    display: 'inline-block',
                    textAlign: 'center',
                    alignItems: 'baseline',
                    lineHeight: `${objectMaster.height}px`,
                    backgroundColor: 'white',
                    position: 'absolute',
                    border: '1px solid black',
                    zIndex: ZIndex.floorObject,
                }}>
                <span
                    className={classes.textStyle}
                    style={{
                        marginRight: '5px',
                        fontSize: '20px', 
                    }}>来場者数</span>
                <span
                    className={classes.textStyle}
                    style={{
                        fontSize: '25px', 
                    }}>{usersAmount}</span>
                <span
                    className={classes.textStyle}
                    style={{
                        fontSize: '18px', 
                    }}>名</span>
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersAmount]);

    return component;
}

export const FloorUsersAmount = forwardRef(UsersAmountComponent);
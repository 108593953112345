import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { DialogContentText } from '@material-ui/core';


interface Props {
    open: boolean;
    type: number;
    floorId: number;
    onClose: () => void;
    onSendSocket: (action: string, data: any) => void;
}


export default function TestUpdateSessionDataDialog(props: Props) {
    const [isOpen, setOpen] = useState(false);
    const [inputText, setInputText] = useState("");


    useEffect(() => {
        if (props.open) {
            // setInputText(""); 前回値を残す
        }
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        setInputText("");
    }, [props.type]);

    const dialogTitle = useMemo(() => {
        switch (props.type) {
            case 0:
                return "セッションデータを一括更新";
            case 1:
                return "配信する列を指定";
            default:
                return ""
        }
    }, [props.type]);

    const dialogText = useMemo(() => {
        switch (props.type) {
            case 0:
                return <span>
                    更新するセッションデータを Json形式で指定。<br />
                    ※一番外側の中括弧は省略可<br />
                    ※文字列の値は、ダブルクォーテーションで囲まないと GSON が勝手に消します！
                    </span>;
            case 1:
                return <span>
                    配信対象のセッションデータの列を指定。(カンマ区切りで複数指定可能)<br />
                    ※ネストする場合は、"/" で結合する。<br />
                    例）SessionData.mySeat.x を配信する場合、"mySeat/x"
                    </span>;
            default:
                return "";
        }
    }, [props.type]);

    const onClose = useCallback(() => {
        props.onClose();
    }, [props]);

    const onUpdate = useCallback(() => {
        // console.log(inputText);

        switch (props.type) {
            case 0:
                let jsonText = inputText.trim();
                if (!jsonText.startsWith("{") || !jsonText.endsWith("}")) {
                    jsonText = "{" + jsonText + "}";
                }
                jsonText = jsonText
                    .replaceAll("\n", "\\n")
                    .replaceAll("'", "\\'")
                    .replaceAll('"', '\\"')
                    .replaceAll("&", "\\&")
                    .replaceAll("\r", "\\r")
                    .replaceAll("\t", "\\t")
                    .replaceAll("\b", "\\b")
                    .replaceAll("\f", "\\f");
                // console.log("escaped json\n" + jsonText);

                props.onSendSocket('UPDATE_SESSION_DATA', { floorId: props.floorId, json: jsonText });
                break;

            case 1:
                props.onSendSocket('TEST_UPDATE_ALL_USER_WITH_ROW', inputText.trim());
                break;
        }
        onClose();
    }, [inputText, props, onClose]);


    const onChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setInputText(event.target.value);
    }, []);


    const draw = useMemo(() => {
        return (
            <Fragment>
                <Dialog open={isOpen} onClose={() => props.onClose()} style={{ pointerEvents: 'auto' }}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialogText}</DialogContentText>
                        <DialogContent>
                            <TextField style={{margin:0, padding:0}}
                                value={inputText}
                                onChange={onChange}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                />
                        </DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color='primary' style={{ pointerEvents: 'auto' }}>キャンセル</Button>
                        <Button onClick={onUpdate} color='primary' style={{ pointerEvents: 'auto' }}>更新</Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    } , [isOpen, dialogTitle, dialogText, props, inputText, onChange, onClose, onUpdate])


    return (
        <Fragment>
            {draw}
        </Fragment>
    )

}
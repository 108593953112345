import React, { Fragment, useState, useEffect, useMemo } from 'react';
import BaseUser from './BaseUser';
// import { AvatarMenuData, User, FloorObject, Seat, FloorData, GridSnap } from '../common/JsonClass';
import HitPersonEffect, { AvatarEffectType } from './effect/Index';
import ZIndex from "../common/ZIndex";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { PowerOffSharp } from '@material-ui/icons';
import JfsClient, { FloorData, FloorObject } from '@fsi/jfs-sdk';


interface Props {
    object: FloorObject,
    mode: boolean,
    sDragg: () => void,
    eDragg: () => void,
    status: boolean,
    isFloorObjSelected: boolean,
    getCurrentObj: (obj: FloorObject) => void,
    checkIsSit: () => void,
    isSittingError: (message: string) => void,
    gridMode: boolean,
    gridRange: number,
    isDragging: boolean,
    floor: FloorData,
    floorObjectList: FloorObject[],//this.state?.floorObjectList
    webSocket: (action: string, object: object) => void,
    myMoveAutoScroll: (x:number, y:number) => void,
}

export default function SelectedFloorObject(props: Props) {
    const [isDisplay, setDisplay] = useState("");
    const [currentPosition, setCurrentPosition] = useState({x:props.object?.offsetLeft,y:props.object?.offsetTop});
    const [O, setO] = useState(new FloorObject());
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useEffect(() => {
        //var tmp = props.mode ? 'none' : 'block'
        setDisplay(props.mode && props.isFloorObjSelected ? 'block' : 'none');
        
    }, [props.mode, props.isFloorObjSelected]);

    //選択されたオブジェクトが変わった場合に位置をリセット
    useEffect(() => {
        //var tmp = props.mode ? 'none' : 'block'
        if(props.object?.id != 0){searchO();} //grid mode on の場合に原点を元にオブジェクトを動かす
        setCurrentPosition({x:props.object?.offsetLeft,y:props.object?.offsetTop});
        props.getCurrentObj(props.object);
         
    }, [props.object?.id]);

     // オブジェクト選択中にグリッドが有効になった場合
     useEffect(() => {
         if (props.object?.id != 0 && props.gridMode) { searchO();}
     }, [props.gridMode]);

    // ユーザーが着席しているか判定
    useEffect(() => {
       checkIsSit();
    }, [props.object]);

    // 選択されたオブジェクトの位置情報更新
    useEffect(() => {
        setCurrentPosition({x:props.object?.offsetLeft,y:props.object?.offsetTop});
     }, [props.object?.offsetLeft, props.object?.offsetTop]);

    //ドラッグ開始判定
    const startDragg = (e: DraggableEvent, data: DraggableData) => {
        //setCurrentPosition({x:data.lastX,y:data.lastY});
        props.sDragg();
    }

    //ドラッグ終了判定
    const endDragg = (e: DraggableEvent, data: DraggableData) => {
        setCurrentPosition({x:data.lastX,y:data.lastY});
        var editedObj = new FloorObject();
        editedObj.id = props.object.id;
        editedObj.objectMaster = props.object.objectMaster;
        editedObj.text1 = props.object.text1;
        editedObj.text2 = props.object.text2;
        editedObj.videoURL = props.object.videoURL;
        editedObj.offsetLeft = data.lastX;
        editedObj.offsetTop = data.lastY;
        props.getCurrentObj(editedObj);
        props.eDragg();
    }

    // ドラッグ中
    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        setCurrentPosition({x:data.lastX,y:data.lastY});
        props.myMoveAutoScroll(data.lastX + props.object?.objectMaster.width / 2, data.lastY + props.object?.objectMaster.height / 2);//画面はしでフロアスクロール　selsected object 真ん中起点にスクロールが始まるように
    }

    //グリッドスナップ改造
    const searchO = () => {
        if(props.floorObjectList == undefined) return;
        const cpyFloorObjList = props.floorObjectList.slice();
        cpyFloorObjList.sort((objA, objB) => objA.offsetTop - objB.offsetTop).sort((objA, objB) => objA.offsetLeft - objB.offsetLeft);
        const o = cpyFloorObjList.shift();
        setO(o as FloorObject);

        // alert('ox='+o?.offsetLeft+' oy='+o?.offsetTop+' obj_x='+props.object.offsetLeft+' obj_y='+props.object.offsetTop);

        //原点をappに送って選択中オブジェクトの左上に一番近い ox+(n+25) oy+(n+25)を探す
        props.webSocket("FLOOR_EDIT_GRID", {selectedObject:props.object, x: o?.offsetLeft, y: o?.offsetTop, gridRange: props.gridRange});
    }

    // 着席確認
    const checkIsSit = () => {
        props.checkIsSit();
        // props.webSocket("CHECK_IS_SIT", props.object);
    }

    const draw = useMemo(() => {
        return(
            <Draggable 
                onStart={startDragg} 
                onStop={endDragg}
                onDrag={onDrag}
                position={currentPosition}
                grid={[props.gridRange, props.gridRange]}
            >
                <div
                    id='selectedFloorObject'
                    //onClick={endDragg}
                    style={{
                        //border: '5px solid red',
                        width: props.object?.objectMaster.width,
                        height: props.object?.objectMaster.height,
                        position: 'absolute',
                        //top: props.object?.offsetTop+10,
                        //left: props.object?.offsetLeft+10,
                        backgroundSize: "contain",
                        // backgroundImage: "url(./api/user/object/picture/" + props.object?.objectMaster.id + ")",
                        backgroundImage: `url(${httpClient.createObjectImgUrl(props.object?.objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                        backgroundRepeat: "no-repeat",
                        zIndex: 1000000000,
                        display: isDisplay,
                        outline:'5px solid #35baf6',
                        borderRadius: '10px',
                    }}
                >
                    {/* State: {props.isDragging ? 'True' : 'false'} */}
                </div>
            </Draggable>
        )
    }, [props, currentPosition])

    return(
        <Fragment>
            {draw}
        </Fragment>
    )
}
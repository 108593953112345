import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import axios, { AxiosResponse } from 'axios';
import { withStyles, makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import {DialogTitle, DialogContent, FormControl, Select, DialogActions, Button,Radio, } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Office as resOffice } from '../common/JsonClass';
import { Utility } from '../common/Utility';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ThemeProvider } from '@material-ui/styles';

import { userListConst } from './UserListConst';
import JfsClient from '@fsi/jfs-sdk';

export default function ChimeSetting() {
  const tabId = sessionStorage.getItem('TABID') || '';
  const classes = useStyles();

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);

  const [isShowRepeatSetting, setIsShowRepeatSetting] = React.useState(false);
  const [isShowTargetSetting, setIsShowTargetSetting] = React.useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = React.useState(false);
  const [chimeList, setChimeList] = React.useState<ChimeData[]>([]);
  const [chimeListOrg, setChimeListOrg] = React.useState<ChimeData[]>([]);

  const [officeList, setOfficeList] = React.useState([] as Office[]);

  const [officeCheckList, setOfficeCheckList] = React.useState([] as boolean[]);
  const [floorCheckList, setFloorCheckList] = React.useState([] as boolean[]);

  const [repeatSetting, setRepeatSetting] = React.useState(new Repeat());

  const [repeatMode, setRepeatMode] = React.useState(0);

  const [weekdayChecks, setWeekdayChecks] = React.useState([] as boolean[]);

  const [untilMode, setUntilMode] = React.useState(false);

  const [currentRowNo, setCurrentRowNo] = React.useState(0);

  const [showBackdropAfterSaving, setBackdropAfterSaving] = React.useState(false);

  const weekdayName = ["日","月","火","水","木","金","土"];

  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);
  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });

  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;

  const handleTargetSetting = (isShow: boolean, rowIdx: number) => {
    toggleRepeatSetting();
    toggleConfirmDialog();
    setIsShowTargetSetting(isShow);
    setCurrentRowNo(rowIdx);

    const _chimeList = [...chimeList];
    const _officeList = [...officeList];

    var checkedFloorList = _chimeList[rowIdx].checkedFloorIdList;

    var floorArr = [] as boolean[];
    var officeArr = [] as boolean[];
    officeArr.length = _officeList.length;

    for(var i = 0; i < _officeList.length; i++) {
      officeArr[i] = true;
      var length = floorArr.length;
      _officeList[i].offset = length;
      floorArr.length = length + _officeList[i].floorList.length;

      for(var j = 0; j < _officeList[i].floorList.length;j++){

        var isChecked = checkedFloorList.find((item) => _officeList[i].floorList[j].id == item) != undefined;
        floorArr[length+j] = isChecked;
        if(!isChecked){
          officeArr[i] = false;
        }
      _officeList[i].floorList[j].index = length+j;

      }
    }

    setOfficeCheckList(officeArr);
    setFloorCheckList(floorArr);
  }

  const toggleRepeatSetting = () => {
    isShowRepeatSetting && setIsShowRepeatSetting(false);
  }

  const toggleTargetSetting = () => {
    isShowTargetSetting && setIsShowTargetSetting(false);
  }

  const toggleConfirmDialog = () => {
    isShowConfirmDialog && setIsShowConfirmDialog(false);
  }

  //const getChimeList = async (tabId: string): Promise<AxiosResponse<ChimeInfo[]>> => {
  const getChimeList = async (tabId: string) => {
    try {
      // const res: AxiosResponse<ChimeInfo[]> = await axios.get(
      // '/api/v1/admin/tenant/chimeList',
      // { params: { tabId } }
      // );
      const res: ChimeInfo[] = await httpClient.getChimeList(tabId); 
      return res;
    } catch (err) {
      throw err;
    }
  }

  const fetchChimeList = async () => {

    try {
      const res = await getChimeList(tabId);

      const _chimeList = res
      ? res.map((v: any, i: number, d: any) => {
        const chime: ChimeData = new ChimeData();
        chime.id = v.id;
        chime.tenantId = v.tenantId;
        chime.repeatMode = v.repeatMode;
        chime.enabled = v.enabled;
        chime.repeatInterval = v.repeatInterval;
        chime.weekdaySun = v.weekdaySun;
        chime.weekdayMon = v.weekdayMon;
        chime.weekdayThur = v.weekdayThur;
        chime.weekdayWed = v.weekdayWed;
        chime.weekdayTues = v.weekdayTues;
        chime.weekdayFri = v.weekdayFri;
        chime.weekdaySat = v.weekdaySat;
        chime.checkedFloorIdList = v.checkedFloorIdList;

        chime.untilDate = v.untilDate == null?"":v.untilDate;
        chime.chimeDate = v.chimeDate;
        chime.untilMode = v.untilMode;

        var time = v.chimeTime as string;
        chime.chimeTime = time.substring(0,5);

        chime.repeatModeDisp = getRepeatSelectDispValue(chime);
        chime.prevRepeatModeDisp = getRepeatSelectDispValue(chime);
        getRepeatSelectDispValue(chime);
        return chime;
      }) : [] as ChimeData[];

      setChimeList((prev) => _chimeList);
      setChimeListOrg(JSON.parse(JSON.stringify(_chimeList)));
      setLoading(false);
    } catch (err) {
      console.error(err);
      assignErrorData(err);
    }
  }

  function getRepeatSelectDispValue(chime:ChimeData){

    const repeat = chime.repeatMode;
    if(repeat == 0){
      return 0;
    }

    var date = chime.chimeDate;
    if(date == null || date == "") return 0;
    const day = Number(date.substring(8)).toString();
    const weekday = (new Date(Date.parse(chime.chimeDate))).getDay();

    // 間隔が設定された場合
    if(chime.repeatInterval > 1) return 9;
    // 終了日が設定された場合
    if(chime.untilMode == 1) return 9;

    if(repeat == 1) return 1;
    if(repeat == 3) return 4;
    if(repeat == 4) return 3;

    var checksWorkday = [chime.weekdayMon, chime.weekdayTues, chime.weekdayWed, chime.weekdayThur, chime.weekdayFri] as boolean[];
    var checksAll = [chime.weekdaySun, chime.weekdayMon, chime.weekdayTues, chime.weekdayWed, chime.weekdayThur, chime.weekdayFri, chime.weekdaySat] as boolean[];

    if(checksWorkday.find((e) => e == false) == undefined && !chime.weekdaySun && !chime.weekdaySat)
      return 5;

    for(var i = 0; i < 7; i++){
      if(checksAll[i] && weekday != i){
        return 9;
      }
    }

    return 2;
  }

  const fetchOfficeFloorList = async () => {
    try {
      const res1 = await Utility.requestOfficeList();
      const officeListData = res1 as resOffice[];

      // UI表示用のofficeデータを生成
      const promiseArray = officeListData.map(async (office) => {
        const res = await getFloorList(office.id);
        const floorListData = res as resFloor[];
        floorListData.forEach((data) => data.checked == false);
        const enabledFloorListData = floorListData.filter(x => x.enabledFloor === true);
        return setFloorToOffice(office, enabledFloorListData);
      });
      // フロア０件のオフィスは選択データに入れない
      const _officeList_tmp = await Promise.all(promiseArray);
      var _officeList = _officeList_tmp.filter(x => x.floorList.length > 0);

      // setState
      _officeList.sort((a, b) => a.id > b.id ? 1 : -1);
      setOfficeList(_officeList);

    } catch (err) {
      console.error(err);
    }
  }

  const getFloorList = (id: number) => {
    // const params = new URLSearchParams();
    // params.append("office_id", id.toString());
    // params.append("tab_id", sessionStorage.getItem("TABID") as string);
    // return axios.post('/api/user/floor/list/get', params)
    return httpClient.getFloorList(id, sessionStorage.getItem("TABID") as string)
  }
  function setFloorToOffice(resOffice: resOffice, floorList: resFloor[]) {
    const office = new Office();
    office.id = resOffice.id;
    office.officeName = resOffice.officeName;
    office.privacyRoom = resOffice.privacyRoom;

    if (floorList.length > 0) {
      // id の昇順で並びかえ
      office.floorList = floorList.map(floor => ({ ...floor, stay: false }))
      .sort((floor1, floor2) => {
        if (floor1.floorOrder > floor2.floorOrder) return 1;
        if (floor1.floorOrder < floor2.floorOrder) return -1;
        return 0;
      });
    }

    return office;
  }

  /**
  * useEffect
  */
  useEffect(() => {
    setCrudType(0);
    setLoading(true);
    fetchChimeList();
    fetchOfficeFloorList();
  }, []);

  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  const handleOnInput = (e: any) => {
    e.persist();
    let target = e.target as HTMLInputElement;
    const inf = newRepeat(repeatSetting);
    var value:number = Math.floor( + target.value);
    if(value < 1) value = 1;
    if(value > 99) value = 99;
    inf.repeatInterval = value;
    setRepeatSetting(inf);
  }

  function newRepeat(item: Repeat){

    const ret = new Repeat();
    ret.repeatMode      =    repeatSetting.repeatMode      ;
    ret.repeatInterval  =    repeatSetting.repeatInterval  ;
    ret.untilMode       =    repeatSetting.untilMode       ;
    ret.weekdaySun      =    repeatSetting.weekdaySun      ;
    ret.weekdayMon      =    repeatSetting.weekdayMon      ;
    ret.weekdayTues     =    repeatSetting.weekdayTues     ;
    ret.weekdayWed      =    repeatSetting.weekdayWed      ;
    ret.weekdayThur     =    repeatSetting.weekdayThur     ;
    ret.weekdayFri      =    repeatSetting.weekdayFri      ;
    ret.weekdaySat      =    repeatSetting.weekdaySat      ;
    ret.untilDate       =    repeatSetting.untilDate       ;

    return ret;
  }

  const drawRepeatSettingContent = () => {
    return(
      <Fragment key="repeatSettings">
        <div>
          繰り返す間隔：
            <TextField type="number"
              style={{ width:80}} 
              disabled={repeatSetting.repeatMode == 3} 
              value={repeatSetting.repeatMode == 3 ? 1 : repeatSetting.repeatInterval}
              onInput={handleOnInput}
              inputProps={{ min: 1, max: 99 }
              }
            />

          <Select native onChange={(e) => handleRepeatModeChange(e)} value={repeatSetting.repeatMode}>
            <option key="1" value="1">日ごと</option>
            <option key="2" value="2">週間ごと</option>
            <option key="3" value="3">か月ごと</option>
          </Select>
          <br/>曜日：<br/>
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 0)} checked={repeatSetting.weekdaySun}></Checkbox>日
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 1)} checked={repeatSetting.weekdayMon}></Checkbox>月
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 2)} checked={repeatSetting.weekdayTues}></Checkbox>火
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 3)} checked={repeatSetting.weekdayWed}></Checkbox>水
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 4)} checked={repeatSetting.weekdayThur}></Checkbox>木
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 5)} checked={repeatSetting.weekdayFri}></Checkbox>金
          <Checkbox color="primary" disabled={repeatSetting.repeatMode != 2} onChange={(e) => handleWeekdayChecks(e, 6)} checked={repeatSetting.weekdaySat}></Checkbox>土
          <br/>
          <br/>
          <br/>
        </div>
        <div>
          終了日：<br />
          <Radio checked={!untilMode} onClick={() => handleClickUntilRadio(false)} />なし<br/>
          <Radio checked={untilMode}  onClick={() => handleClickUntilRadio(true)}  />
          終了日：　
          <FormControl error = {repeatSetting.untilMode == 1 && checkDateInputError(repeatSetting.untilDate) != 0}>
            <TextField id="date" label="" type="date" 
              value={repeatSetting.untilDate} 
              InputLabelProps={{shrink: true,}} 
              disabled={!untilMode}
              onChange={(e) => handleUntilDateChange(e)}
            />
            <FormHelperText id="loginIdHelperText">
              {repeatSetting.untilMode == 1 && checkDateInputError(repeatSetting.untilDate) != 0 ?
               getDateErrorMsg(checkDateInputError(repeatSetting.untilDate), "終了日"):""}
            </FormHelperText>
            <br/>
          </FormControl>
        </div>
      </Fragment>
    );
  }

  const handleUntilDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> {
    const inf = newRepeat(repeatSetting);
    inf.untilDate = e.target.value;
    setRepeatSetting(inf);
  }

  const handleClickUntilRadio= (mode:boolean)=> {
    const inf = repeatSetting;
    inf.untilMode = mode?1:0;
    setRepeatSetting(inf);
    setUntilMode(mode);
  }

  const handleWeekdayChecks = (e: React.ChangeEvent<HTMLInputElement>, weekdays:number)=> {

    const repeat = [...weekdayChecks] as boolean[];
    repeat[weekdays] = e.target.checked;
    setWeekdayChecks([...repeat]);

    const inf = repeatSetting;
    switch(weekdays){
      case 0:
        inf.weekdaySun = e.target.checked;
        break;
      case 1:
        inf.weekdayMon = e.target.checked;
        break;
      case 2:
        inf.weekdayTues = e.target.checked;
        break;
      case 3:
        inf.weekdayWed = e.target.checked;
        break;
      case 4:
        inf.weekdayThur = e.target.checked;
        break;
      case 5:
        inf.weekdayFri = e.target.checked;
        break;
      case 6:
        inf.weekdaySat = e.target.checked;
        break;
      default:
        break;
    }

    setRepeatSetting(inf);

  }

  const handleRepeatModeChange = (e: React.ChangeEvent<{name?: string | undefined;value: unknown;}>)=> {

    const repeat = repeatSetting;
    repeat.repeatMode = e.target.value as number;
    setRepeatSetting(repeat);
    setRepeatMode(e.target.value as number);

  }

  const drawOfficeList = () => {

    return officeList.map((office, officeIdx) => {
      if(!office.floorList) return;
        return (
          <Fragment key={office.id}>
            {office.officeName}<br/>
            　　<Checkbox id={office.id + ''} color="primary" 
              checked= {officeCheckList[officeIdx]}
              name = {'office' + office.id}
              onChange={(e) => handleCheckAllFloor(e, office, officeIdx)}
              ></Checkbox>すべてのフロア<br />
            {drawFloorList(office, officeIdx)}
          </Fragment>
      )
    })
  }

  const handleCheckAllFloor= (e: React.ChangeEvent<HTMLInputElement>, office:Office, officeIdx:number)=> {

    var officeArr = [...officeCheckList];
    officeArr[officeIdx] = e.target.checked;

    var floorArr = [...floorCheckList];
    // 該当オフィス配下フロアの全選択・解除
    for(var i = 0; i<office.floorList.length;i++){
      floorArr[i + office.offset] = e.target.checked;
    }

    setFloorCheckList([...floorArr]);
    setOfficeCheckList([...officeArr]);
  }

  const drawFloorList = (office: Office, officeIdx: number) => {

    var floorList = office.floorList;
    if (!floorList) return;
    return floorList.map((floor) => {
      return (
        <Fragment key={floor.id}>
          　　　　
          <Checkbox id={'floor' + floor.id} color="primary" 
            name = {'floor' + floor.id}
            checked={floorCheckList[floor.index]}
            onChange={(e) => handleCheckFloor(e, office, officeIdx, floor.id, floor.index)}
          ></Checkbox>
          {floor.floorName}
          <br/>
        </Fragment>
      );
    });
  }

  const handleCheckFloor= (e: React.ChangeEvent<HTMLInputElement>, 
    office:Office, officeIdx:number,
    floorId:number, index:number)=> {

    var floorArr = [...floorCheckList];
    floorArr[index] = e.target.checked;

    // 該当オフィス配下フロアの全選択状況を判定する
    var isAllChecked = true;
    for(var i = 0; i<office.floorList.length;i++){
      if(!floorArr[i + office.offset]){
        isAllChecked = false;
        break;
      }
    }

    var officeArr = [...officeCheckList];
    if(isAllChecked != officeArr[officeIdx]){
      officeArr[officeIdx] = isAllChecked;
    }
    setFloorCheckList([...floorArr]);
    setOfficeCheckList([...officeArr]);
  }

  const handleTargetSelectOkCancel = (mode:boolean) => {

    // 完了ボタン押下の場合、選択されたフロアidを一時保存
    if(mode){
      const checkedFloorArr = [] as number[];
      officeList.forEach((office) => {
        office.floorList.forEach((floor) =>{
          if(floorCheckList[floor.index]){
            checkedFloorArr.push(floor.id);
          }
        })
      })

      const _chimeList = [...chimeList];
      _chimeList[currentRowNo].checkedFloorIdList = checkedFloorArr;
      setChimeList([..._chimeList]);

    }

    setIsShowTargetSetting(false);
  }

  const handleRepeatSettingOkCancel = (mode:boolean) => {

    const _chimeList = [...chimeList];

    // 完了ボタン押下の場合、選択されたカスタマ繰り返すを一時保存
    if(mode){

      _chimeList[currentRowNo].repeatMode     = repeatSetting.repeatMode     ;
      _chimeList[currentRowNo].repeatInterval = repeatSetting.repeatMode == 3 ? 1 : repeatSetting.repeatInterval;
      _chimeList[currentRowNo].untilMode      = repeatSetting.untilMode      ;  
      _chimeList[currentRowNo].untilDate      = repeatSetting.untilDate      ;  
      _chimeList[currentRowNo].weekdaySun     = repeatSetting.weekdaySun     ;
      _chimeList[currentRowNo].weekdayMon     = repeatSetting.weekdayMon     ;
      _chimeList[currentRowNo].weekdayTues    = repeatSetting.weekdayTues    ;
      _chimeList[currentRowNo].weekdayWed     = repeatSetting.weekdayWed     ;
      _chimeList[currentRowNo].weekdayThur    = repeatSetting.weekdayThur    ;
      _chimeList[currentRowNo].weekdayFri     = repeatSetting.weekdayFri     ;
      _chimeList[currentRowNo].weekdaySat     = repeatSetting.weekdaySat     ;
      _chimeList[currentRowNo].repeatModeDisp = getRepeatSelectDispValue( _chimeList[currentRowNo]);
      _chimeList[currentRowNo].prevRepeatModeDisp = _chimeList[currentRowNo].repeatModeDisp;

    }else{
    //プルダウンを戻す
      _chimeList[currentRowNo].repeatModeDisp = _chimeList[currentRowNo].prevRepeatModeDisp;
    }
    setChimeList([..._chimeList]);

    //ダイヤログ非表示
    setIsShowRepeatSetting(false);
  }

  const handleChimeSwitch= (e: React.ChangeEvent<HTMLInputElement>, rowIdx:number)=> {
    const arr = [...chimeList];
    arr[rowIdx].enabled = e.target.checked;
    setChimeList([...arr]);
  }

  const handleRepeatSelectChange = (e: React.ChangeEvent<{name?: string | undefined;value: unknown;}>,
    chime:ChimeData, rowIdx:number)=> {

    const value = e.target.value as number;
    const arr = [...chimeList] as ChimeData[];
    arr[rowIdx].repeatModeDisp = value;

    // カスタマの場合、カスタマ設定ダイヤログを表示
    if(value == 6){

      const repeatSetting = new Repeat();
      repeatSetting.repeatMode = chime.repeatMode == 0 || chime.repeatMode == 4 ? 1 : chime.repeatMode;
      repeatSetting.repeatInterval = chime.repeatInterval < 1 ? 1 : chime.repeatInterval;
      repeatSetting.untilMode = chime.untilMode;
      repeatSetting.weekdaySun = chime.weekdaySun;
      repeatSetting.weekdayMon = chime.weekdayMon;
      repeatSetting.weekdayTues = chime.weekdayTues;
      repeatSetting.weekdayWed = chime.weekdayWed;
      repeatSetting.weekdayThur = chime.weekdayThur;
      repeatSetting.weekdayFri = chime.weekdayFri;
      repeatSetting.weekdaySat = chime.weekdaySat;

      if(chime.untilMode == 1){
        repeatSetting.untilDate = chime.untilDate;
      }
      repeatSetting.index = rowIdx;

      setRepeatSetting(repeatSetting);
      setIsShowRepeatSetting(true);
      setUntilMode(chime.untilMode == 1);
      setCurrentRowNo(rowIdx);
    }else{
      arr[rowIdx].prevRepeatModeDisp = value;
    }
    setChimeList([...arr]);
  }

  const handleChimeListSaveOrCancel = (mode:boolean)=>{

    if(mode){

      setCrudType(1);
      setLoading(true);
      const params = new URLSearchParams();

      //post前の判定

      const oldDatas = chimeListOrg;
      const newDatas = chimeList;
      const postTarget = [] as ChimeData[];

      for(var i = 0; i<newDatas.length;i++){
        var newData = newDatas[i];

        // 毎日、毎週ｘ曜日、毎月第一ｘ曜日、毎月ｘ日、毎週平日の場合、繰り返し設定を反映
        if(newData.repeatModeDisp == 1){
          newData.repeatInterval = 1;
          newData.repeatMode = 1;
          newData.untilMode = 0;
          newData.untilDate = "";
          newData.weekdaySun = false;
          newData.weekdayMon = false;
          newData.weekdayTues = false;
          newData.weekdayWed = false;
          newData.weekdayThur = false;
          newData.weekdayFri = false;
          newData.weekdaySat = false;
        }else if(newData.repeatModeDisp == 2 || newData.repeatModeDisp == 3){
          newData.repeatInterval = 1;
          newData.repeatMode = newData.repeatModeDisp == 2? 2 : 4;
          newData.untilMode = 0;
          newData.untilDate = "";
          const weekday = (new Date(Date.parse(newData.chimeDate))).getDay();
          newData.weekdaySun  = weekday == 0;
          newData.weekdayMon  = weekday == 1;
          newData.weekdayTues = weekday == 2;
          newData.weekdayWed  = weekday == 3;
          newData.weekdayThur = weekday == 4;
          newData.weekdayFri  = weekday == 5;
          newData.weekdaySat  = weekday == 6;
        }else if(newData.repeatModeDisp == 4){
          newData.repeatInterval = 1;
          newData.repeatMode = 3;
          newData.untilMode = 0;
          newData.untilDate = "";
          newData.weekdaySun = false;
          newData.weekdayMon = false;
          newData.weekdayTues = false;
          newData.weekdayWed = false;
          newData.weekdayThur = false;
          newData.weekdayFri = false;
          newData.weekdaySat = false;
        }else if(newData.repeatModeDisp == 5){
          newData.repeatInterval = 1;
          newData.repeatMode = 2;
          newData.untilMode = 0;
          newData.untilDate = "";
          newData.weekdaySun = false;
          newData.weekdayMon = true;
          newData.weekdayTues = true;
          newData.weekdayWed = true;
          newData.weekdayThur = true;
          newData.weekdayFri = true;
          newData.weekdaySat = false;
        }else if(newData.repeatModeDisp == 0){
          newData.repeatInterval = 1;
          newData.repeatMode = 0;
          newData.untilMode = 0;
          newData.untilDate = "";
          newData.weekdaySun = false;
          newData.weekdayMon = false;
          newData.weekdayTues = false;
          newData.weekdayWed = false;
          newData.weekdayThur = false;
          newData.weekdayFri = false;
          newData.weekdaySat = false;
        }

        // 新規の場合
        if(newData.id == 0){
          newData.type = 1;
          postTarget.push(newData);
          continue;
        }

        var oldData = oldDatas.find((e) => e.id == newData.id);
        if(oldData == undefined){
          continue;
        }

        const oldStr = JSON.stringify(oldData);
        const newStr = JSON.stringify(newData);

        // 変更の場合
        if(oldStr != newStr){
          newData.type = 1;
          postTarget.push(newData);
          continue;
        }
      }

      // 削除
      for(var i = 0; i<oldDatas.length;i++){
        var item = oldDatas[i];
        if(newDatas.find(newData => item.id == newData.id) == undefined){
          item.type = 3;
          postTarget.push(item);
        }
      }

      if(postTarget.length == 0){
        alert("変更ありません。");
        setLoading(false);
        return;
      }

      // params.append("tab_id", tabId);
      // params.append("data", JSON.stringify(postTarget));
      
      // axios.post('/api/v1/admin/tenant/chimeList', params)
      httpClient.registChimeList(tabId, postTarget)
        .then((e) => {
          fetchChimeList();
          setBackdropAfterSaving(true);
          setTimeout(function(){setBackdropAfterSaving(false)}, 2000);
        })
        .catch(err => {
          assignErrorData(err);
       })
       .finally(() => {
        setLoading(false);
       });

    }else{
      setChimeList(JSON.parse(JSON.stringify(chimeListOrg)));
    }
  }

  const handleAddChime = () =>{

    if(chimeList.length >= 30) {
      alert("最大３０件設定可能");
      return;
    }

    var arrs = [] as number[];
    officeList.forEach(o => o.floorList.forEach(f => arrs.push(f.id)));
    var newChime = new ChimeData();
    newChime.enabled = true;
    newChime.checkedFloorIdList = arrs;
    setChimeList([...chimeList, newChime]);

  }

  const handleDelChime = (rowIdx:number) =>{

    setIsShowConfirmDialog(true);
    setCurrentRowNo(rowIdx);
  }

  const handleConfirmDialogOkCancel = (mode:boolean) =>{

    if(mode){
      var arrs = [] as ChimeData[];
      for(var i = 0; i < chimeList.length;i++){
        if(i != currentRowNo){
          arrs.push(chimeList[i]);
        }
      }
      setChimeList(arrs);
    }

    setIsShowConfirmDialog(false);
  }

  const handleChangeChimeDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIdx: number)=> {
    const arr = [...chimeList];
    arr[rowIdx].chimeDate = e.target.value;
    setChimeList([...arr]);
  }

  const handleChangeChimeTime = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIdx: number)=> {
    const arr = [...chimeList];
    arr[rowIdx].chimeTime = e.target.value;
    setChimeList([...arr]);
  }

  const drawCustomize = (str: string) =>{
    if(str == "") return;

    return(
      <option value="9">{str}</option>
    );

  }

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  function checkDateInputError(date: string){

    console.info(date);

    // 未入力
    if(date.length == 0){
      return 1;
    }

    // 年が4桁yyyyを超えた場合
    if(date.length > 10){
      return 2;
    }

    return 0;
  }

  const getDateErrorMsg = (err : number, str: string) => {

    if(err == 1){
      return str + "を入力ください";
    }
    if(err == 2){
      return "年をYYYYで入力ください";
    }

    return "";
  }

  const checkIfFirstWeek = (dateString : string) => {
    const inputDate = new Date(dateString);
    return inputDate.getDate() <= 7;
  };

  return (
    <Fragment>
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{marginTop: -20}}
      >
      <Card className={classes.root}>
        <CardHeader
        title='チャイム設定'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      <Fragment key="chime-table">
        <Paper style={{ width: '100%' }}>
          <TableContainer style={{ height: `calc(100vh - 180px)` }}>
            <Table stickyHeader aria-label="customized table" style={{tableLayout:'fixed',}}>
              <TableHead>
                <TableRow>
                    <StyledTableCell key = 'chimeNo'     style={{minWidth: 40 ,maxWidth: 40 ,width: 40 ,}}>No.</StyledTableCell>
                    <StyledTableCell key = 'switch'      style={{minWidth: 100,maxWidth: 100,width: 100,}}>オン/オフ</StyledTableCell>
                    <StyledTableCell key = 'chimeDate'   style={{minWidth: 220,maxWidth: 220,width: 220,}}>初回チャイム日付</StyledTableCell>
                    <StyledTableCell key = 'chimeTime'   style={{minWidth: 200,maxWidth: 200,width: 200,}}>時刻</StyledTableCell>
                    <StyledTableCell key = 'chimeRepeat' style={{minWidth: 370,maxWidth: 370,width: 370,}}>繰り返しの設定</StyledTableCell>
                    <StyledTableCell key = 'target'      style={{minWidth: 130,maxWidth: 130,width: 130,}}></StyledTableCell>
                    <StyledTableCell style={{minWidth: 100,maxWidth: 100,width: 100,}}>
                      <Button variant="contained" color="primary" onClick={() => handleAddChime()}>追加</Button>
                  </StyledTableCell>
                  <StyledTableCell style={{width: `calc(100% - 1160px)`,}}>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chimeList.map((chime: ChimeData, rowIdx) => {

                  var date = chime.chimeDate;
                  var day = "";
                  var weekday = "";
                  if(date != ""){
                    day = Number(date.substring(8)).toString();
                    weekday = weekdayName[(new Date(Date.parse(chime.chimeDate))).getDay()];
                  }
                  const repeat = chime.repeatMode;
                  // カスタマイズごの繰り返す文字列
                  var customize = "";
                  var untilStr = chime.untilMode == 1? "、" + chime.untilDate + "まで": "";

                  if(repeat == 1){
                    if(chime.repeatInterval !=1){
                      customize = chime.repeatInterval + "日ごと" + untilStr;
                    }else{
                      customize = untilStr == ""? "" : "毎日" + untilStr;
                    }
                  }else if(repeat == 2){
                    var checkedWeekdayText = "";
                    if(chime.weekdaySun)  checkedWeekdayText = checkedWeekdayText + "," + weekdayName[0];
                    if(chime.weekdayMon)  checkedWeekdayText = checkedWeekdayText + "," + weekdayName[1];
                    if(chime.weekdayTues) checkedWeekdayText = checkedWeekdayText + "," + weekdayName[2];
                    if(chime.weekdayWed)  checkedWeekdayText = checkedWeekdayText + "," + weekdayName[3];
                    if(chime.weekdayThur) checkedWeekdayText = checkedWeekdayText + "," + weekdayName[4];
                    if(chime.weekdayFri)  checkedWeekdayText = checkedWeekdayText + "," + weekdayName[5];
                    if(chime.weekdaySat)  checkedWeekdayText = checkedWeekdayText + "," + weekdayName[6];
                    if(checkedWeekdayText != "") checkedWeekdayText=checkedWeekdayText.substring(1);
                    if(checkedWeekdayText == "月,火,水,木,金"){
                      checkedWeekdayText = "平日：月～金";
                    }
                    if(chime.repeatInterval == 1){
                      if(checkedWeekdayText != weekday || untilStr != ""){
                        customize = "毎週" + checkedWeekdayText + "曜日" + untilStr;
                      }
                    }else{
                      customize = chime.repeatInterval + "週間ごとの" + checkedWeekdayText + "曜日" + untilStr;
                    }
                  }else if(repeat == 3){
                    if(chime.repeatInterval !=1){
                      customize = chime.repeatInterval + "か月ごとの" + day + "日" + untilStr;
                    }else{
                      customize = untilStr == ""? "" : "毎月の" + day + "日" + untilStr;
                    }
                  }else if(repeat == 4){
                    customize = untilStr == ""? "" : "毎月第一" + weekday + "曜日" + untilStr;
                  }

                  return (
                    <StyledTableRow>
                      <StyledTableCell>
                        {rowIdx + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Switch checked={chime.enabled} onChange={(e) => handleChimeSwitch(e, rowIdx)}></Switch>
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormControl error={checkDateInputError(chime.chimeDate) != 0} fullWidth>
                          <div>
                            <TextField id="date" label="" type="date" onChange={(e) => handleChangeChimeDate(e, rowIdx)} value={chime.chimeDate} InputLabelProps={{shrink: true,}}/>
                            <FormHelperText id="loginIdHelperText">{getDateErrorMsg(checkDateInputError(chime.chimeDate), "初回チャイム日付")}</FormHelperText>
                          </div>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell>
                      <FormControl error={chime.chimeTime == ""} fullWidth>
                          <div>
                            <TextField id="time" label="" type="time" onChange={(e) => handleChangeChimeTime(e, rowIdx)} value={chime.chimeTime} InputLabelProps={{shrink: true,}}/>
                            <FormHelperText id="loginIdHelperText">{chime.chimeTime == "" ? "チャイム時刻を入力ください":""}</FormHelperText>
                          </div>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Select native style={{ width:360}} value = {chime.chimeDate == "" ? 0 : chime.repeatModeDisp}
                          onChange={(event) => handleRepeatSelectChange(event, chime, rowIdx)}
                          disabled = {checkDateInputError(chime.chimeDate) != 0}>
                          <option value="0">なし</option>
                          <option value="1">毎日</option>
                          <option value="2">毎週{weekday}曜日</option>
                          {checkIfFirstWeek(chime.chimeDate) && <option value="3">毎月第一{weekday}曜日</option>}
                          <option value="4">毎月{day}日</option>
                          <option value="5">毎週平日：月～金</option>
                          {drawCustomize(customize)}
                          <option value="6">カスタム</option>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button variant="contained" color="primary" onClick={() => handleTargetSetting(true, rowIdx)}>配信対象</Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button variant="contained" color="primary" onClick={() => handleDelChime(rowIdx)}>削除</Button>
                      </StyledTableCell>
                      <StyledTableCell>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
          <div>
            <Button variant="contained" color="primary" 
                    disabled = {chimeList.find((item) => item.chimeDate == "" || item.chimeTime == "") != undefined || JSON.stringify(chimeList) == JSON.stringify(chimeListOrg)} 
                    onClick={() => handleChimeListSaveOrCancel(true)}>保存</Button>&nbsp;
            <Button variant="contained" color="primary" onClick={() => handleChimeListSaveOrCancel(false)}>キャンセル</Button>
          </div>
      </Fragment>

      {drawLoading}

      <Dialog open={isShowTargetSetting}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}
        >
        <DialogTitle id="dialog-registUser">配信対象フロア設定</DialogTitle>
        <DialogContent>
          <div>
            {drawOfficeList()}
          </div>
        </DialogContent>
        <DialogContentText>
        </DialogContentText>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" 
                    disabled = {floorCheckList.find((item) => item == true) == undefined}
                    onClick={() => { handleTargetSelectOkCancel(true) }}>OK</Button>&nbsp;
            <Button variant="contained" color="primary" onClick={() => { handleTargetSelectOkCancel(false) }}>キャンセル</Button>&nbsp;
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={isShowConfirmDialog}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle id="dialog-registUser">削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            チャイムを削除します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" onClick={() => { handleConfirmDialogOkCancel(true) }}>OK</Button>&nbsp;
            <Button variant="contained" color="primary" onClick={() => { handleConfirmDialogOkCancel(false) }}>キャンセル</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={isShowRepeatSetting}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle id="dialog-registUser">カスタムの繰り返し</DialogTitle>
        <DialogContent>
          {drawRepeatSettingContent()}
        </DialogContent>
        <DialogContentText>
        </DialogContentText>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" 
                    disabled = {(repeatSetting.untilMode == 1 && repeatSetting.untilDate == "") 
                      || (repeatSetting.repeatMode == 2 
                        && !repeatSetting.weekdayMon
                        && !repeatSetting.weekdayThur
                        && !repeatSetting.weekdayWed
                        && !repeatSetting.weekdayTues
                        && !repeatSetting.weekdayFri
                        && !repeatSetting.weekdaySat
                        && !repeatSetting.weekdaySun
                      )}
                    onClick={() => { handleRepeatSettingOkCancel(true) }}>OK</Button>&nbsp;
            <Button variant="contained" color="primary" onClick={() => { handleRepeatSettingOkCancel(false) }}>キャンセル</Button>
          </div>
        </DialogActions>
      </Dialog>
    </Grid>
    <Backdrop className={classes.backdrop} open={showBackdropAfterSaving} >
      <Slide direction="up" in={showBackdropAfterSaving}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="subtitle2" >保存しました。</Typography>
          </CardContent>
        </Card>
      </Slide>
    </Backdrop>
    </Fragment>
  )
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
  
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
      maxWidth: 450,
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    tableLayout:'fixed',
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

const columns = [
  { id: 'chimeNo', label: 'No.', minWidth: 15, maxWidth: 15 },
  { id: 'switch', label: 'オン/オフ', minWidth: 40, maxWidth: 40 },
  { id: 'chimeDate', label: '初回チャイム日付', minWidth: 110, maxWidth: 110 },
  { id: 'chimeTime', label: '時刻', minWidth: 110, maxWidth: 110 },
  { id: 'chimeRepeat', label: '繰り返す', minWidth: 230, maxWidth: 230 },
  { id: 'target', label: '', minWidth: 10, maxWidth: 10 },
];
  
// レスポンス受渡用
interface resFloor {
  id: number;
  floorName: string;
  floorOrder: number;
  enabledFloor: boolean;
  checked: boolean;
  index: number,
}

interface ChimeInfo{
  id: number;
  tenantId: number;
  chimeNo: number;
  repeatCount: number;
  untilMode: number;
  repeatMode: number;
  repeatModeDisp: number;
  repeatInterval: number;

  enabled: boolean;
  isrepeat: boolean;
  weekdaySun: boolean;
  weekdayMon: boolean;
  weekdayTues: boolean;
  weekdayWed: boolean;
  weekdayThur: boolean;
  weekdayFri: boolean;
  weekdaySat: boolean;

  chimeTime: Date;
  chimeDate: Date;
  untilDate: Date;

  isChanged: boolean;
  checkedFloorIdList:number[];
}

// UI表示用
class ChimeData {
  id: number = 0;
  tenantId: number = 0;
  untilMode: number = 0;
  repeatMode: number = 0;
  repeatModeDisp: number = 0;
  repeatInterval: number = 1;

  enabled: boolean = false;
  weekdaySun: boolean = false;
  weekdayMon: boolean = false;
  weekdayTues: boolean = false;
  weekdayWed: boolean = false;
  weekdayThur: boolean = false;
  weekdayFri: boolean = false;
  weekdaySat: boolean = false;

  chimeTime: string = "";
  chimeDate: string = "";
  untilDate: string = "";

  type: number = 0;

  prevRepeatModeDisp:number = 0;

  checkedFloorIdList:number[] = [];
}

class Office {
  id: number = 0;
  officeName: string = "";
  stay: boolean = false;
  open: boolean = false;
  floorList: Floor[] = [];
  offset: number = 0;
  privacyRoom: boolean = false;
}

class Floor {
  index: number = 0;
  id: number = 0;
  floorName: string = "";
  floorOrder: number = 0;
  stay: boolean = false;
  checked: boolean = false;
} 

class Repeat {
  index:number = 0;
  repeatMode: number = 0;
  repeatInterval: number = 0;

  weekdaySun: boolean = false;
  weekdayMon: boolean = false;
  weekdayTues: boolean = false;
  weekdayWed: boolean = false;
  weekdayThur: boolean = false;
  weekdayFri: boolean = false;
  weekdaySat: boolean = false;

  untilMode: number = 0;
  untilDate: string = "";

  intervalEnabled:boolean = false;
  weekdayCheckEnabled: boolean = false;
  untilDateEnabled: boolean = false;

  prevRepeatModeDisp:number = 0;

}
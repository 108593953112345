import React from 'react';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";

export interface Props {
    className?: string,
    floorObject: FloorObject,
    noBorder?: boolean,
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    whiteSpace: 'nowrap',
    height: 15,
    padding: '0 5',
    pointerEvents: 'none',
}

export default function staticKanban(props: Props) {
    const { className, noBorder } = props;
    const { id, offsetLeft, offsetTop, text1 } = props.floorObject;

    return (
        <div
            key={`kanban${id}`}
            id={`kanban${id}`}
            className={className}
            style={{
                ...kanbanTextStyle,
                left: offsetLeft,
                top: offsetTop,
                width: text1.length === 0 ? 15 : text1.length * 12 + 2,
                border: noBorder ? '0' : '1px solid #AAA',
                borderRadius: noBorder ? '' : '2%',
                boxShadow: noBorder ? '' : '1px 1px 1px 1px rgba(0,0,0,0.1)',
                backgroundColor: noBorder ? '' : 'rgba(255, 255, 255, 0.9)',
            }}
        >
            {text1.length === 0
                ? <div style={{ width: 15, height: 15 }} />
                : text1}
        </div>
    )
}
import { ChatGroupData, FloorData, FloorObject, MoveUserData, MicData, Seat, User, TatehudaText, Tatehuda2Text, TatehudaTextText1, PingResult, GetFloor, AvatarCommand, HitBusyUser, SelectFloor, AvatarMenuData, WebRtcLimitUser, MeetLimitOver, Memo, ReadMemoInfo, ServiceInfoData, ReleaseFloorRequest, DevErrDisp, UpdateZoomInfo, InternalNotice, AdminMessage, EditorSnackBar, GridSnap, MyRoomFloorData, MyRoomFloorObject, UpdateMyRoom, PrivacyState, LoginoutNoticeData, NewVisitor, LeaveRoom, PrivacyRoomPermission, PrivacyRoomTimeOut, EnterPrivacyRoom, ExtendPrivacyRoomPermission } from "../common/JsonClass";
import { Floor } from "../user/Floor";
import axios, { AxiosResponse } from 'axios';
import JfsClient, { JfsError } from "@fsi/jfs-sdk";

const jfsClient = JfsClient.getInstance();
const { JFS_ERRORS  } = jfsClient;

export class JsonAction {
    action: string = "";
    object: object | null = null;
}

export class FloorWebSocket {
    public websocket: WebSocket | null = null;
    private parent: Floor | null = null;

    private planDisconnect: boolean = false;

    private webSocketUrl: string = "";

    public browserBack: boolean = false;

    private moveFloorRetryCount: number = 0;    // ダイアログ自動クローズ対応

    //public closeSocketRecv: boolean = false;

    private jfsClient = JfsClient.getInstance();
    private httpClient =  this.jfsClient.httpClient;
    private wsClient = this.jfsClient.wsClient;

    connect = (parent: Floor, path: string) => {
        this.parent = parent;
        console.log(document.location)
        this.createPath(path);
        this.traceSend("WebSOcketFloor Connect: [" + "url=" + this.webSocketUrl + "],[tabid=" + sessionStorage.getItem("TABID") + "]");
        if (this.websocket === null) this.websocket = new WebSocket(this.webSocketUrl + "?tabid=" + sessionStorage.getItem("TABID"));

        this.websocket.onopen = this.open.bind(this);
        this.websocket.onclose = this.close.bind(this);
        this.websocket.onmessage = this.message.bind(this);
        this.websocket.onerror = this.error.bind(this);
    }
    reconnect = () => {
        // webSocket自動再接続対応 戻り値追加
        // let retval = 0;
        // if (this.websocket === null) {
        //     this.traceSend("WebSOcketFloor reconnect: [" + "url=" + this.webSocketUrl + "],[tabid=" + sessionStorage.getItem("TABID") + "]");
        //     this.websocket = new WebSocket(this.webSocketUrl + "?tabid=" + sessionStorage.getItem("TABID"));
        //     this.traceLog("[WebSocketTrace] reconnect new websocket get ["+(this.websocket ? "OK" : "null")+"]");
        //     this.traceSend("[WebSocketTrace] reconnect new websocket get ["+(this.websocket ? "OK" : "null")+"]");
        //     if(this.websocket !== null) {
        //         retval = 1;
        //     }
        // } else {
        //     this.traceLog("[WebSocketTrace] reconnect websocket not null");
        //     this.traceSend("[WebSocketTrace] reconnect new websocket get ["+(this.websocket ? "OK" : "null")+"]");
        // }
        // this.websocket.onopen = this.open.bind(this);
        // this.websocket.onclose = this.close.bind(this);
        // this.websocket.onmessage = this.message.bind(this);
        // this.websocket.onerror = this.error.bind(this);
        const retval = this.wsClient.reconnect(sessionStorage.getItem("TABID") as string);
        return retval;
    }
    disconnect = (planDisconnect: boolean, path: string) => {
        this.traceLog("[WebSocketTrace] recv_disconnect planDisconnect=["+planDisconnect+"]")
        this.planDisconnect = planDisconnect;
        if(path !== "") this.createPath(path);
        this.traceLog("[WebSocketTrace] recv_disconnect websocket close=["+(this.websocket ? "yes":"no")+"]")
        this.websocket?.close();
        this.websocket = null;

        try {
            this.wsClient.disconnect(planDisconnect, Number(path));
        } catch (error) {
            console.error(error);            
        }
    }
    open = (event: any) => {
        // 多重ログインチェック前にフロアデータを取得しないように削除
        // this.sendGetFloorData();
    }
    close = (event: any) => {
        this.traceLog("[WebSocketTrace] recv_close planDisconnect=["+this.planDisconnect+"] browserBack=["+this.browserBack+"]");
        if(this.planDisconnect === false) {
            //this.closeSocketRecv = true;
            this.parent?.disconnectDialogRef?.current?.open();
            this.parent?.allCloseINSnackbar();  // iPad対応-3 iPad対応-21 snackbarをすべて閉じる
            this.parent?.setDisconnectWebrtc();     // #284 webrtc中ならばwebrtc終了したい
        } else {
            this.planDisconnect = false;
            if (!this.browserBack) {
                this.moveFloorProcess();
                // バックエンドの afterConnectionClosed() の処理待機は、バックエンドの synchronized で行われる。
            } else {
                this.browserBack = false;
            }
        }
    }
    //getCloseState = () => {
    //    return this.closeSocketRecv;
    //}
    moveFloorProcess = () => {
        this.httpClient.checkDisconnectSession(sessionStorage.getItem("TABID") as string)
            .then((e: string) => {
                if(e === JFS_ERRORS.OK.code) {
                        this.moveFloorRetryCount = 0;
                        this.reconnect();
                } else {
                    this.parent?.openMoveFloorFailedDialog();
                }
            }).catch(err => {
                console.log(err);
            });
    }
    retryMoveFloor = () => {
        this.moveFloorRetryCount += 1;
        if(this.moveFloorRetryCount > 50) {
            this.moveFloorRetryCount = 0;
            this.parent?.openMoveFloorRetryOutDialog();
        } else {
            this.moveFloorProcess();
        }
    }
    message = (event: any) => {
        // this.parent?.message(event);
        // console.log(event.data)
        const json = JSON.parse(event.data);
//         if (json.action === "FLOOR_DATA") {
//           this.parent?.receiveFloorData(json.floor as FloorData);
//         } else if (json.action === "SERVICEINFO_DATA") {
//           this.parent?.receiveServiceInfoData(
//             json.serviceInfo as ServiceInfoData
//           );
//         } else if (json.action === "AVATAR_MENU_DATA") {
//           this.parent?.receiveAvatarMenuData(
//             json.avatarMenuList as AvatarMenuData[]
//           );
//         } else if (json.action === "FLOOR_OBJECT_DATA") {
//           this.parent?.receiveFloorObject(json.object as FloorObject[]);
//         } else if (json.action === "FLOOR_SEAT_DATA") {
//           this.parent?.receiveSeatData(json.object as Seat[]);
//         } else if (json.action === "OTHER_USERS_MOVE") {
//           this.parent?.receiveMoveUsers(
//             json.object as MoveUserData[],
//             json.object2 as Seat[],
//             json.object3 as User[],
//             json.object4 as MicData[],
//             json.object5 as string[],
//             json.object6 as { [key: string]: object },
//             json.object7 as { [key: number]: { [key: string]: object } }
//           );
//         } else if (json.action === "UPDATE_USER_DATA") {
//           this.parent?.receiveUpdateOneSession(
//             json.object as { [key: string]: object }
//           );
//         } else if (json.action === "MY_USER") {
//           this.parent?.receiveMyUser(json.object as User);
//         } else if (json.action === "CONNECT_OTHER_USER") {
//           // this.parent?.receiveConncetOtherUser(json.object as User);
//         } else if (json.action === "DISCONNECT_OTHER_USER") {
//           // this.parent?.receiveDisconnectOtherUser(json.object as User);
//         } else if (json.action === "OTHER_USERS") {
//           this.parent?.receiveOtherUsers(json.object as User[]);
//         } else if (json.action === "TATEHUDA_TEXT") {
//             this.parent?.receiveTatehudaText(json.object as TatehudaText)
//         } else if (json.action === "TATEHUDA2_TEXT") {
//             this.parent?.receiveTatehuda2Text(json.object as Tatehuda2Text)
//         } else if (json.action === "TATEHUDA_TEXT_TEXT1") {
//             this.parent?.receiveTatehudaTextText1(json.object as TatehudaTextText1)
//         } else if (json.action === "PRIVACY_STATE") {
//             this.parent?.receivePrivacyState(json.object as PrivacyState)
//         // 20211206 TATEHUDA_TEXT に集約したため、コメントアウト
// //        } else if (json.action === "VIDEOURL_TEXT") {
// //            this.parent?.receiveVideoURLText(json.object as VideoURLText)
//         } else if (json.action === "PONG") {
//           this.parent?.receivePong();
//         } else if (json.action === "PING") {
//           this.parent?.receivePing();
//         } else if (json.action === "HIT_OTHER_FLOOR_USER") {
//           this.parent?.receiveHitOtherFloorUser(
//             "HIT_OTHER_FLOOR_USER",
//             json.object as AvatarCommand
//           );
//         } else if (json.action === "HIT_BUSY_USER") {
//           this.parent?.recieveHitBusyUser(json.object as HitBusyUser);
//         } else if (json.action === "WEB_RTC_LIMIT_USER") {
//           this.parent?.recieveWebRtcLimit(json.object as WebRtcLimitUser);
//         } else if (json.action === "DEVICE_UNSELECTED") {
//             this.parent?.receiveDeviceUnselected(json.object as WebRtcLimitUser);
//         } else if (json.action === "DEVICE_ERROR_DISPLAY_OTHER") {      // #369
//             this.parent?.recieveDisplayErrorSnackBar(json.object as DevErrDisp);    // #369
//         } else if (json.action === "CHANGE_FLOOR") {
//           this.parent?.handleMoveFloor((json.object as SelectFloor).floorId);
//         } else if (json.action === "INVITE_MORE_NOTE") {
//             this.parent?.receiveInvitedMoreNote(json.object as string);
//         }else if (json.action === "CHECK_LOGIN"){
//             this.parent?.receiveLoginChecked();
//         }else if (json.action === "LOGIN"){
//             this.sendGetFloorData();
//         } else if (json.action === "MEET_LIMIT_OVER") {
//           this.parent?.receiveMeetLimitOver(json.object as MeetLimitOver);
//         } else if (json.action === "UPDATE_SESSION_DATA_RESULT") {
//           this.parent?.receiveUpdateSessionData(json.object as string);
//         } else if (json.action === "NEW_MEMO") {
//           this.parent?.receiveNewMemo(json.object as Memo);
//         } else if (json.action === "READ_MEMO") {
//           this.parent?.receiveReadMemo(json.object as ReadMemoInfo);
//         } else if (json.action === "CLAPPING") {
//           this.parent?.receiveClapping();
//         } else if (json.action === "UPDATE_YOUTUBE_DATA") {
//             this.parent?.receiveUpdateYouTubeData(json.floor as FloorData);
//         } else if(json.action === "OPENED_HELLO") {
//             this.parent?.receiveOpenedHello(json);
//         } else if(json.action === "REQ_RELEASE_FLOOR") {
//             this.parent?.releaseFloorRequest(json.object as ReleaseFloorRequest);
//         } else if(json.action === "FLOOR_OBJECT_ERR"){
//             this.parent?.handleObjectMoveError(json.object);
//         } else if(json.action === "FLOOREDIT_OK"){
//             this.parent?.setFloorEditMonde(true);
//         } else if(json.action === "FLOOREDIT_NG"){
//             this.parent?.setFloorEditMonde(false);
//         } else if(json.action === "FLOOREDITOR_NOTICE") {
//             this.parent?.floorEditorSnackBar(json.object as EditorSnackBar);
//         } else if(json.action === "FLOOR_OBJECT_SNAPPOINT") {
//             this.parent?.selectedFloorObjSnap((json.object as GridSnap).x, (json.object as GridSnap).y);
//         } else if(json.action === "FLOOR_RESIZE") {
//             this.parent?.floorReSize((json.floor as FloorData).floorHeight, (json.floor as FloorData).floorWidth);
//         } else if(json.action === "FLOOR_BACKGROUND_CHANGE") {
//             this.parent?.changeFloorBackground((json.object as FloorData).backgroundObject.id);
//         } else if(json.action === "CREATED_OBJECT") {
//             this.parent?.changeSelectedFloorObject(json.object as FloorObject);
//         } else if(json.action === "NO_ENTRANCE_NOTICE") {
//             this.parent?.noEntranceAlert(json.object as EditorSnackBar);
//         } else if (json.action === "UPDATE_YOUTUBE_DATA") {
//             this.parent?.receiveUpdateYouTubeData(json.floor as FloorData);
//         } else if (json.action === "OPENED_HELLO") {
//           this.parent?.receiveOpenedHello(json);
//         } else if (json.action === "REQ_RELEASE_FLOOR") {
//           this.parent?.releaseFloorRequest(json.object as ReleaseFloorRequest);
//         } else if (json.action === "UPDATE_OTHERWEBSITE_DATA") {
//           this.parent?.receiveUpdateOtherWebsiteData(json.floor as FloorData);
//         } else if (json.action === "UPDATE_ZOOM_INFO") {
//             this.parent?.receiveUpdateZoomInfo(json.object as UpdateZoomInfo);
//         } else if (json.action === "INTERNAL_NOTICE"){
//             this.parent?.receiveInternalNotice(json.object as InternalNotice);
//         } else if (json.action === "NEW_VISITOR") {
//             this.parent?.receiveNewVisitor(json.object as NewVisitor);
//         } else if (json.action === "EXTEND_PRIVACY_ROOM_PERMISSION") {
//             this.parent?.receiveExtendPrivacyRoomPermission(json.object as ExtendPrivacyRoomPermission);
//         } else if (json.action === "LEAVE_ROOM") {
//             this.parent?.receiveLeaveRoom(json.object as LeaveRoom);
//         } else if (json.action === "PRIVACY_ROOM_PERMISSION") {
//             this.parent?.receivePrivacyRoomPermison(json.object as PrivacyRoomPermission);
//         } else if (json.action === "PRIVACY_ROOM_TIME_OUT") {
//             this.parent?.receivePrivacyRoomTimeOut(json.object as PrivacyRoomTimeOut);
//         } else if (json.action === "ENTER_PRIVACY_ROOM") {
//             this.parent?.receiveEnterPrivacyRoom(json.object as EnterPrivacyRoom);
//         } else if (json.action === "CLOSE_MORE_NOTE") {
//             this.parent?.receiveCloseMoreNote();
//         } else if (json.action === "UPDATE_ADMINMESSAGE") {
//             this.parent?.receiveUpdateAdminMessage(json as AdminMessage);
//         } else if (json.action === "UPDATE_POPUPSIGNBOARD") {
//             this.parent?.updatePopupSignboard(json.object);
//         } else if (json.action === "MY_ROOM_FLOOR_DATA") {
//             this.parent?.receiveMyRoomFloorData(json.floor as MyRoomFloorData);
//         } else if (json.action === "MY_ROOM_FLOOR_OBJECT_DATA") {
//             this.parent?.receiveMyRoomFloorObject(json.object as MyRoomFloorObject[]);
//         } else if (json.action === "MY_ROOM_FLOOR_SEAT_DATA") {
//             this.parent?.receiveMyRoomSeatData(json.object as Seat[]);
//         } else if (json.action === "MY_ROOM_OTHER_USERS") {
//             this.parent?.receiveMyRoomOtherUsers(json.object as User[]);
//         } else if (json.action === "MY_ROOM_MESSAGE") {
//             this.parent?.receiveMyRoomMessage(json.object as string);
//         } else if (json.action === "MY_ROOM_UPDATE") {
//             this.parent?.receiveUpdateMyRoom(json.object as UpdateMyRoom);
//         } else if (json.action === "CHATGROUP_NAME") {
//             this.parent?.receiveChatGroupName(json.object as ChatGroupData);
//         } else if (json.action === "CHATGROUP_ADD") {
//             this.parent?.receiveChatGroupAdd(json.object as ChatGroupData);
//         } else if (json.action === "CHATGROUP_QUIT") {
//             this.parent?.receiveChatGroupQuit(json.object as ChatGroupData);
//         } else if (json.action === "RING_CHIME") {
//             this.parent?.receiveChime();
//         } else if (json.action === "FORCE_EXIT") {
//             this.parent?.receiveForceExit();
//         } else if (json.action === "FORCE_EXIT_ALERT") {
//             this.parent?.receiveForceExitAlert();
//         } else if (json.action === "UPDATE_POPUPSIGNBOARD") {
//             this.parent?.updatePopupSignboard(json.object);
//         } else if (json.action === "LOGINOUT_NOTICE") {
//             this.parent?.recieveLoginoutNotice(json.object as LoginoutNoticeData);
//         }
    }
    error = (event: any) => {
    }
    send = (message: string) => {
        try {
            this.websocket?.send(message);
        }catch(e) {
            // 何もしない
        }        
    }

    /**
     * Websocketが接続完了と同時にフロアデータを取得
     */
    sendGetFloorData = () => {
        const sendJson = new JsonAction();
        sendJson.action = "GET_FLOOR_DATA";
        const getFloor = new GetFloor();
        sendJson.object = getFloor;
        const json = JSON.stringify(sendJson);
        // this.send(json);
    }

    /**
     * Pingの送信
     */
    sendPing = () => {
        // const sendJson = new JsonAction();
        // sendJson.action = "PING";
        // const json = JSON.stringify(sendJson);
        // this.send(json);
        this.wsClient.sendPing();
        // console.log("ping")
    }

    /**
     * Pingの結果を送信
     */
    sendPingResult = (ping: number) => {
        // const sendJson = new JsonAction();
        // sendJson.action = "PING_RESULt";
        // const pingResult = new PingResult();
        // pingResult.result = ping;
        // sendJson.object = pingResult;
        // const json = JSON.stringify(sendJson);
        // this.send(json);
        this.wsClient.sendPingResult(ping);
    }

    /**
     * Pongを送信
     */
    sendPong = () => {
        // const sendJson = new JsonAction();
        // sendJson.action = "PONG";
        // const json = JSON.stringify(sendJson);
        // this.send(json);
        try {
            this.wsClient.sendPong();
        } catch (error) {
            console.error(error);            
        }
    }

    /**
     * フロア切り替えの通知
     */
    sendStartChageFloor = (floorId: number) => {
        // const sendJson = new JsonAction();
        // sendJson.action = "START_CHANGE_FLOOR";
        // const getFloor = new GetFloor();
        // getFloor.floorId = floorId;
        // sendJson.object = getFloor;
        // const json = JSON.stringify(sendJson);
        // this.send(json);

        try {
            this.wsClient.sendStartChangeFloor(floorId);
        } catch (error) {
            console.error(error);            
        }
    }

    /**
     * WebSocketサーバーの接続先URLを作成する
     * 接続先は下記のようになる
     * 例：
     * wss://example.com/1/websocket
     * 
     * この[/1/]の部分が接続先を分岐している箇所となっている。
     * ALBのルールでこの数字によりサーバーを分岐している
     * @param path 
     */
    createPath = (path: string) => {
        this.webSocketUrl = (document.location.protocol === "http:" ? "ws://" : "wss://") + document.location.hostname
            + (document.location.port === "" ? "" : (":" + document.location.port)) + "/" + path + "/websocket";
    }

    /**
     * Helloが開かれているかチェックする
     */
    checkOpenedHello = () => {
        // const sendJson = new JsonAction();
        // sendJson.action = "OPENED_HELLO_ON_WEBRTCROOM";
        // const json = JSON.stringify(sendJson);
        // this.send(json);

        try {
            this.wsClient.checkOpenedHello();
        } catch (error) {
            console.error(error); 
        }
    }

    /**
     * Helloが開かれた時のコールバックを登録する
     */
     registCallbackOpenedHello = () => {
        // const sendJson = new JsonAction();
        // sendJson.action = "REGIST_CALLBACK_OPENED_HELLO_ON_WEBRTCROOM";
        // const json = JSON.stringify(sendJson);
        // this.send(json);
        try {
            this.wsClient.registCallbackOpenedHello();
        } catch (error) {
            console.error(error); 
        }
    }

    sendCloseMoreNote = () => {
        // const sendJson = new JsonAction();
        // sendJson.action = "CLOSE_MORE_NOTE";
        // const json = JSON.stringify(sendJson);
        // this.send(json);

        try {
            this.wsClient.sendClosedHello();
        } catch (error) {
            console.error(error); 
        }
    }

    setPlanDisconnect = (planDisconnect: boolean) => {
        this.traceLog("[WebSocketTrace] setPlanDisconnect planDisconnect=["+planDisconnect+"]")
        this.planDisconnect = planDisconnect;
    }

    /**
     *  トレースログ
     * 
     */
     private traceLog = (msg: string) => {
        let now = new Date().toTimeString();
        console.log(now+" "+msg);
    }

    /**
     *  トレースログ:サーバーへ送信
     * 
     */
    private traceSend = (msg: string) => {
        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append('tracemsg', msg);
        // axios.post('/api/user/log/tracelog', params)
        // .then((e: AxiosResponse) => {
        // }).catch((e) => {
        //     this.traceLog("traceSend error:"+e)
        // });

        this.httpClient.sendTraceLog(sessionStorage.getItem("TABID") as string, msg)
            .then((e) => {
            }).catch((e) => {
                this.traceLog("traceSend error:"+e);
            });
    }
}
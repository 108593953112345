/* memo
object master: 930, 93, C:/temp/youth/signature/立て看板_青.png, 3000009, 98,, 12, 0, 0, 60, 40

floor_object:
text2 setting json format
{
    img_url: "",
    notification: false,
    signboardName: "",
    opensite: "newTab"
    updateTime: timestamp,
    updateUser: props.userName,
}
*/

import React, { Fragment, useMemo, useRef, useEffect, useCallback, createRef } from 'react';
// import { FloorObject } from '../common/JsonClass';
import { makeStyles, Theme, createStyles, useTheme, withStyles, Icon } from '@material-ui/core';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Zoom from '@material-ui/core/Zoom';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '../img/edit_B.png';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { sign } from 'crypto';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import axios, { AxiosResponse } from 'axios';
import { Utility } from '../common/Utility';
import JfsClient, { FloorObject, JfsError, OpenSiteMode, FloorNoticeBoard } from '@fsi/jfs-sdk';

const textFieldTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        signboardImg: {
            backgroundRepeat: 'no-repeat',
            wordBreak: 'break-all',
            position: "absolute",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        signboard:{
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain",
            zIndex: ZIndex.floorObject,
        },
        clickMeIcon: {

        },
        settingIcon: {
            position: 'absolute',
            right: -12,
            zIndex: ZIndex.fixedUiLayer,
            backgroundColor: 'white',
            border: '1px solid black',
            width: 11,
            height: 11,
            '&:hover': {
                background: 'lightgray',
            },
        },
        settingDialog: {
            //width: '45%',
        },
        settingTitle:{
            fontWeight:'bold', 
            fontSize: 16
        },
    }),
);

interface Props {
    floorId: number,
    floorObject: FloorObject,
    openPage: (url: string, boardName: string, enableDownload: boolean, floorId: number, objectId: number, noticeNo: number) => void,
    sendWebSocket: (action: string, object: object) => void,
    setWebpageUrl: (url: string) => void,
    userName: string,
    role: string, 
}

class NoticeData {
    id: number = 0;
    floorId: number = 0;
    objectId: number = 0;

    name1: string = "";
    type1: string = "url";
    url1: string = "";
    imgData1: string = "";
    iconData1: string = "";
    updateTime1: string = "";
    updateUser1: string = "";
    readed1:boolean = false;
    download1:boolean = false;
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //backgroundColor: 'rgba(0, 0, 255, 1)',
};

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

const radioButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

export default function WebpagePopupSignboard(props: Props) {
    const MAX_URL_LENGTH = 512;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const [dispSetting, setDispSetting] = useState(false);
    const [dispConfirm, setDispConfirm] = useState(false);
    const [notification, setNotification] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [signboardName, setSignboardName] = useState('');
    const [openMethod, setOpenMethod] = useState('newTab');
    const [updateTime, setUpdateTime] = useState('');
    const [updateUser, setUpdateUser] = useState('');
    const [errMsgOnBoardName, setErrMsgOnBoardName] = useState('');
    const [errMsgOnSiteUrl, setErrMsgOnSiteUrl] = useState('');
    const [errMsgOnImgUrl, setErrMsgOnImgUrl] = useState('');
    const [uploadFile, setUploadFile] = React.useState({});
    const [previewSrc, setPreviewSrc] = React.useState("");
    const [noticeData, setNoticeData] = useState(new NoticeData());
    const [isShowClearConfirmDialog, setShowClearConfirmDialog] = React.useState(false);
    const [type, setType] = useState('url');
    const classes = useStyles();
    const MAX_TEXT_LENGTH = objectMaster.textLength;

    const jfsClient = JfsClient.getInstance();
    const { wsClient, httpClient } = jfsClient;

    //tooltipをカスタマイズして上のエフェクトへ
    const CustomizedTooltip = withStyles((Theme) => ({
        tooltip: {
            backgroundColor: 'transparent',
        },
    }))(Tooltip);

    useEffect(() => {
        //frameRef.current?.open();
        parseJsonSetting();
        setSiteUrl(text1);

    }, [props.floorObject, dispSetting]);

    useEffect(() => {
        checkLength();
    }, [siteUrl, imgUrl, disableButton, signboardName]);


    //text2のjsonをそれぞれのstateに割り振る
    const parseJsonSetting = () => {
        if(text2 !== "" && text2 !== null) {
            try{
                const setting = JSON.parse(text2);
                //setNotification(setting.notification);
                setNotification(false);
                setSignboardName(setting.signboardName);
                setOpenMethod(setting.opensite === '' ? 'newTab' : setting.opensite);
                setType(setting.type === '' ? 'url' : setting.type);
                setUpdateTime(setting.updateTime);
                setUpdateUser(setting.updateUser);
                setImgUrl('');
                /*if(setting.imgUrl.slice(0,5) === 'https' || setting.imgUrl.slice(0,4) === 'http'){
                    setImgUrl(setting.imgUrl);
                }else{
                    setImgUrl('');
                }*/

                var data = new NoticeData();
                data.floorId=props.floorId;
                data.objectId=props.floorObject.id;

                // axios.get('/api/user/floor/notice', {
                //     params: {
                //     floorId: props.floorId,
                //     objectId: props.floorObject.id,
                //     tabId: sessionStorage.getItem("TABID") as string,
                //     }
                // })
                httpClient.getNotice(
                    props.floorObject.id,
                    props.floorId,
                    sessionStorage.getItem("TABID") as string,
                )
                .then((e: FloorNoticeBoard) => {
                    let notices = e;
                    data.id=notices.id;
                    if(notices.name1 != null && notices.name1 !== ""){
                        data.name1=notices.name1;
                        data.type1=notices.type1;
                        data.url1=notices.url1?notices.url1:"";
                        data.imgData1=notices.imgData1?notices.imgData1:"";
                        data.iconData1=notices.iconData1?notices.iconData1:"";
                        // data.updateTime1=notices.updateTime1;
                        data.updateTime1=notices.updateDate1;
                        data.updateUser1=notices.updateUser1.toString();
                        data.readed1=notices.readed1;
                        data.download1=notices.download1;
                    }
                    setPreviewSrc(data.imgData1);
                    setNoticeData({...data});
                })
                .catch(err => {
                })
                .finally(() => {
                });
                
            } catch(e) {
                console.log('text2の内容が不適切: ' + text2);
            }
        }
    }

    /*
    * 設定ダイアログでキャンセルを押されたときのハンドラ
    */
    const handleCloseDialog = () => {
        setDispSetting(false);
        setSignboardName("");
        setUploadFile({});
        setPreviewSrc("");
        setSiteUrl("");
        setType("url")
        setOpenMethod('newTab');
        setNotification(false);
    }

    /*
    * 設定ダイアログでOKを押されたときのハンドラ
    */
    const handleClickCompleate = () => {
        if(!checkLength()){return;}
        if(type === 'url' && openMethod === 'iframe'){
            // 画面内でWebサイトを表示する場合、確認ダイアログを表示
            setDispConfirm(true);
            return;
        }

        //websocketで設定送信
        sendWebSocket();
        sendLocalImage();
        props.setWebpageUrl(siteUrl);
        setDispSetting(false);
    }

    /*
    * 確認ダイアログでOKを押されたときのハンドラ
    */
    const handleClickConfirm = () => {
        //websocketで設定送信
        sendWebSocket();
        sendLocalImage();
        props.setWebpageUrl(siteUrl);
        setDispConfirm(false);
        setDispSetting(false);
    }

    /*
    * websocketで設定送信
    */
    const sendWebSocket = () => {
        // 送信内容の作成
        const date = new Date().getTime();
        const timestamp: string = new Date(date).toLocaleString();
        const settingJson = {
            floorObjectId: id,
            siteUrl: siteUrl,
            imgUrl: imgUrl,
            notification: notification,
            signboardName: signboardName,
            opensite: openMethod,
            type: type,
            updateTime: timestamp,
            updateUser: props.userName,
        }
        // props.sendWebSocket('SIGNBOARD_SETTING', settingJson);
        try {
            wsClient.sendSignboardSetting(
                id, 
                siteUrl, 
                imgUrl, 
                notification, 
                signboardName, 
                openMethod as OpenSiteMode, 
                type, 
                new Date(), 
                props.userName
            );
        } catch (error) {
            console.error(error);
        }
    }

    /*
    * 看板名前の入力内容を削除
    */
    const onClearSignboardName = useCallback(() => {
        setSignboardName('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signboardName]);

    /*
    * サイトURLの入力内容を削除
    */
    const onClearSiteUrl = useCallback(() => {
        setSiteUrl('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteUrl]);

    /*
    * 画像URLの入力内容を削除
    */
    const onClearImageUrl = useCallback(() => {
        setImgUrl('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgUrl]);

    /*
    * フロア通知のチェックボックス用のハンドラ
    */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotification(event.target.checked);
    };

    /*
    * テキストボックス用のハンドラ
    */
    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisableButton(false);

        if(event.target.name === 'siteUrl'){
            setSiteUrl(event.target.value);   
        }
        //if(event.target.name === 'imgUrl'){
        //    setImgUrl(event.target.value);
        //}
        if(event.target.name === 'signboardName'){
            setSignboardName(event.target.value);
        }
    }

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        // setUploadFile({});
        // setPreviewSrc("");
        setType(event.target.value);
        // 画像を表示する場合は別タブで表示は選択不可
        if(event.target.value === 'img'){
            setOpenMethod('iframe');
            setSiteUrl('');
        }else{
        }
    }

    /*
    * 未入力チェック
    */
    function checkInput(): boolean {
      if (signboardName === ""){
        return false;
      }
      if (type === "url" && siteUrl === ""){
        return false;
      }
      if (type === "img" && !(uploadFile instanceof File || previewSrc !== "")){
        return false;
      }
      return true;
    }

    /*
    * 文字数チェック
    */
    const checkLength = ():boolean => {
        // 文字数チェック
        var result = true;
        if(signboardName.length === 0) {
            // 看板の名前は入力必須
            result = false;
        } else if(judgehz(signboardName) > MAX_TEXT_LENGTH) {
            // 看板の名前
            result = false;
        } else if(siteUrl.length > MAX_URL_LENGTH) {
            // サイトURL
            result = false;
        } /*else if(imgUrl.length > MAX_URL_LENGTH) {
            // 画像RL
            result = false;
        }*/
        return result;
    }

    /*
    * 全角・半角を考慮した文字数チェック
     */
    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')
 
        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
 
        return len
    };

    /*
    * テキストボックスについてのエラーメッセージを出力
    */
    const textLength = (maxLength: number, inputText: string, distinctionHz: boolean = false, required: boolean = false) => {
        /* let len = judgehz(inputText) */
        let len = 0
        if(distinctionHz) {
            len = judgehz(inputText)
        } else {
            len = inputText.length
        }
        if( maxLength < len ){
            return (
                <div style={{ color:"red" }}>入力した文字列が長すぎます。 </div>
            );
        }

        if( required && len === 0){
            return (
                <div style={{ color:"red" }}>入力必須です。 </div>
            );
        }
    };

    /*
    * 看板の名前用プログレスバー
    */
    const progressBarSignBoradName = useMemo(() => {
        let parsent = (judgehz(signboardName)/MAX_TEXT_LENGTH) * 100;
        let progressColor = '#FFE1A2';
        
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
        
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_TEXT_LENGTH, signboardName, true, true)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "27%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[signboardName])

    /*
    * サイトURL用プログレスバー
    */
    const progressBarSiteUrl = useMemo(() => {
        let parsent = (siteUrl.length/MAX_URL_LENGTH) * 100;
        let progressColor = '#FFE1A2';
         
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
         
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_URL_LENGTH, siteUrl, false, type === 'url' ? true : false )}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "27%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[siteUrl])

    /*
    * 画像URL用プログレスバー
    */
    const progressBarImageUrl = useMemo(() => {
        let parsent = (imgUrl.length/MAX_URL_LENGTH) * 100;
        let progressColor = '#FFE1A2';
         
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
         
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_URL_LENGTH, imgUrl, false, type === 'img' ? true : false)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" } }>
                    <LinearProgress 
                        variant="determinate"
                        value={parsent}
                        style={{
                            ...LinearProgressStyle,
                            backgroundColor: progressColor,
                        }}
                    />
                </Grid>
            </Grid>
        );
    },[imgUrl])


    /*
    * 開き方用ハンドラ
    */
    const handleOpenMethodChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setOpenMethod(event.target.value);
    }

    /*
    * 別タブで開く
    */
    const openNewTab = () => {
        if(type === "url"){
            if(siteUrl === ""){
                return;
            }
            window.open(siteUrl, "_blank");
        }else{
            if(noticeData.imgData1 === ""){
                return;
            }
            props.openPage(noticeData.imgData1, noticeData.name1, false, 0, 0, 0);
        }
    }

    /*
    * 画面内で開く
    */
    const openIframe = () => {
        if(type === "url"){
            if(siteUrl === ""){
                return;
            }
            props.openPage(siteUrl, signboardName, false, 0, 0, 0)
        }else{
            if(noticeData.imgData1 === ""){
                return;
            }
            props.openPage(noticeData.imgData1, noticeData.name1, false, 0, 0, 0);
        }
    }

    const handleAllClear = (mode: boolean) =>{

        if(mode){

            if(noticeData.id == 0){
                handleCloseDialog();
            }
    
            const settingJsonForImg = {
                noticeId: noticeData.id,
                floorId: noticeData.floorId,
                objectId: noticeData.objectId,
                noticeNo: 1,
            };

            const settingJsonForOther = {
                floorObjectId: id,
                siteUrl: '',
                imgUrl: '',
                notification: false,
                signboardName: '',
                opensite: 'newTab',
                type: 'url',
                updateTime: '',
                updateUser: '',
            }
            setImgUrl('');
            setNoticeData(new NoticeData());
            setNotification(false);
            setOpenMethod('newTab');
            setPreviewSrc('');
            setUploadFile({});
            setSignboardName('');
            setSiteUrl('');
            setUpdateTime('');
            setUpdateUser('');
            setType('url');

            //props.sendWebSocket('SIGNBOARD_SETTING', settingJsonForOther);
            try {
                wsClient.sendClearSignboard(id);
            } catch (error) {
                console.error(error);            
            }

            // props.sendWebSocket('NOTICE_CLEAR', settingJsonForImg);
            try {
                wsClient.sendNoticeClear(noticeData.id, noticeData.floorId, noticeData.objectId, 1);
            } catch (error) {
                console.error(error);            
            }
            setDispSetting(false);
        }

        setShowClearConfirmDialog(false);
    }

    /*
     * アップロードボタンクリック用ハンドラ 
     */
    const handleClickFileSelectButton = async () => {
        
        setUploadFile({});
        await showOpenFileDialog();
    }

    /**
     * アップロードボタンを押した際に開くファイル選択画面 
     */
    const showOpenFileDialog = () => {
        return new Promise(resolve => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
 
            input.onchange = (ev) => {
                let eventTarget: HTMLInputElement = (ev.target as HTMLInputElement);
                if (eventTarget !== null && eventTarget.files !== null) {

                    let file = eventTarget.files[0];
                    //ファイルタイプチェック
                    if(!file.type.match('image.*')){
                        // 画像タイプですが、画像ファイルではない場合
                        alert("画像ファイルを選択してください。");
                        return;
                    }

                    //ファイルサイズチェック
                    if(file.size > 5 * 1024 * 1024){
                        alert("選択されたファイルのサイズが5MB超えています。");
                        return;
                    }

                    setUploadFile(file);
                    setPreviewSrc(URL.createObjectURL(file));
                    if (uploadFile != null) {
                        resolve(uploadFile);
                    }
                }
            };
          input.click();
        });
    };

    function createFileFromBase64(base64:string, name:string) {
        // base64のデコード
        var bin = atob(base64.replace(/^.*,/, ''));
        // バイナリデータ化
        var buffer = new Uint8Array(bin.length);
        for (var i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
        }

        let fileType = "";
        try{
            fileType = base64.split(",")[0].split(":")[1].split(";")[0];
        }catch{
            console.info("err");
        }
        // ファイルオブジェクト生成
        return new File([buffer.buffer], name, {type: fileType});
    };
    
    /*
    * アップロードしたローカル画像の更新、websocketで設定送信
    */
    const sendLocalImage = () => {

        // const params = new FormData();
        // params.append('tabId', (sessionStorage.getItem("TABID") as string));

        //params.append('download', download?"true":"false");
        var file = {} as File;
        var fileType = '';

        if(uploadFile instanceof File){
            //console.info("fileSize:" ,(uploadFile as File).size);
            // params.append('file', (uploadFile as File));
            file = uploadFile as File;
            // params.append('fileType', (uploadFile as File).type);
            fileType = (uploadFile as File).type;
        }else{
            // let file = createFileFromBase64(previewSrc, "notice");
            //console.info("filePreSize:" ,file.size);
            // params.append('file', file);
            // params.append('fileType', file.type);
            file = createFileFromBase64(previewSrc, "notice");
            fileType = file.type;
        }

        // params.append('name', signboardName);
        // params.append('floorId', noticeData.floorId + "");
        // params.append('objectId', noticeData.objectId + "");
        // params.append('id', noticeData.id + "");
        // params.append('noticeNo', 1 + "");
        // params.append('url', siteUrl);
        // params.append('type', "img");

        // axios.post('/api/user/floor/notice', params,
        // {
        //     headers: { "Content-Type": "multipart/form-data" },
        // })
        // .then((response: AxiosResponse) => {
        //     const settingJson = {
        //         name: signboardName,
        //         notification: false,
        //     };
        //     props.sendWebSocket('NOTICE_SETTING', settingJson);
        // }).catch(err => {
        //     console.log(err);
        // });

        httpClient.setNotice(
            sessionStorage.getItem("TABID") as string,
            null,
            file,
            fileType,
            null,
            null,
            null,
            signboardName,
            props.floorId,
            props.floorObject.id,
            noticeData.id,
            1,
            siteUrl,
            "img"
        ).then(() => {
            // const settingJson = {
            //     name: signboardName,
            //     notification: false,
            // };
            // props.sendWebSocket('NOTICE_SETTING', settingJson);
            wsClient.sendNoticeSetting(signboardName, false);
        }).catch(err => {
            console.log(err);
        });
    }

    /*
    * 画面内で表示する際の警告を表示
    */
    const warnMessageIframe = useMemo(() => {
        // 表示・非表示の確認
        if(openMethod === 'iframe') {
            return (
                <Fragment>
                    <div style={{ color: "red" }}>※「画面内に表示」にした場合、対象のサイトの構成によっては、</div>
                    <div style={{ color: "red" }}>　　・サイトURL内のリンク</div>
                    <div style={{ color: "red" }}>　　・画面内表示の右上の「戻る」「進む」ボタン</div>
                    <div style={{ color: "red" }}>　が正しく動作しない可能性があります。</div>
                    <div style={{ color: "red" }}>　正しく動作しない場合、「別タブで表示」に設定してください。</div>
                </Fragment>
            );
        }
    }, [openMethod]);

    /*
    * 編集アイコンの表示
    */
    const drawSettingIcon = (role: string) => {
        let allowSetting:boolean = false;

        // 表示・非表示の確認
        if(props.role !== undefined) {
            // 全ユーザが編集可能（管理者のみ編集できるようにする場合、ROLE_ADMINに変更）
            if(props.role.indexOf('ROLE_ADMIN') !== -1) {
                allowSetting = true;
            }
        }
        
        if(allowSetting) {
            return (
                <Fragment>
                    <IconButton onClick={() => setDispSetting(true)} disableTouchRipple={true} className={classes.settingIcon}>
                        <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                    </IconButton>
                </Fragment>
            )
        }
    }

    /*
    * 最終更新情報を表示
    */
    const drawLastUpdate = useMemo(() => {
        if (updateUser === undefined || updateUser === null || updateUser === '' 
            || updateTime === undefined || updateTime === null || updateTime === ''){
            return;
        }
        return (
            <div style={{marginBottom: 10, position: "absolute", bottom: 0}}>
                最終更新: {updateUser}  {updateTime}
            </div>
        )
    }, [updateUser, updateTime]);


    /*
    * 設定ダイアログ
    */
    const settingDialog = useMemo(() => {
        // 表示・非表示の確認
        if(!dispSetting) {
            return;
        }

        return (
            <BaseDialog
                open={dispSetting}
                onClose={() => handleCloseDialog()}
                aria-labelledby='DeleteObjectDialog'
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="dialog-title" style={{ userSelect: 'none', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>目立つポスターの設定</DialogTitle>
                <DialogContent className={classes.settingDialog}>
                    <Grid container justify='center' alignItems='center' style={{marginTop: "20px"}}>
                        <Grid item xs={3} className={classes.settingTitle}>
                            看板の名前
                        </Grid>
                        <Grid item xs={9}>
                            <DialogContentText style={{ marginBottom: "0px" }} >
                                {progressBarSignBoradName}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="signboardName"
                                    name="signboardName"
                                    //label="看板の名前"
                                    variant={'outlined'}
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    onChange={handleUrlChange}
                                    value={signboardName}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={onClearSignboardName}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    {/*<Grid container justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            {DialogContentText style={{ marginBottom: "0px" }} >
                                {progressBarImageUrl}
                            </DialogContentText>
                        </Grid>
                    </Grid>*/}
                    <Grid container justify='center' alignItems='center' style={{ marginBottom: 20, marginTop: 20 }}>
                        {/*<Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="imgUrl"
                                name="imgUrl"
                                label="画像URL"
                                variant={'outlined'}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                onChange={handleUrlChange}
                                value={imgUrl}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onClearImageUrl}>
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            ※URL以外の文字を設定すると、看板に文字が表示されます。
                        </Grid>*/}
                        <Grid item xs={5} className={classes.settingTitle}>
                            表示する画像
                        </Grid>
                        <Grid item xs={7} className={classes.settingTitle}>
                            <div style={{alignContent:"start", marginLeft: 25, marginRight:15, alignItems: "start", textAlign: "start"}}>
                                <Button variant="contained" color="primary" size="small" style={{background: '#006FBC', borderRadius: '31px'}} onClick={() => handleClickFileSelectButton()}>アップロード</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 0, }}>
                        アップロードを押して、表示する画像を設定してください。<br />
                        ※画面の読み込みが遅くなる場合があるため、画像の容量は5MB以下を推奨します。
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center' style={{ display : previewSrc === '' || previewSrc === 'data:;base64,' ? 'none' : '' }}>
                        {<iframe id='popup-iframe' src={previewSrc} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
                    </Grid>
                    
                    <Grid container justify='center' alignItems='center' style={{ marginTop: 30 }}>
                        <Grid item xs={12} className={classes.settingTitle}>表示する内容</Grid>
                    </Grid>
                    <ThemeProvider theme={radioButtonTheme}>
                    <RadioGroup aria-label="radio" name="radio" value={type} onChange={handleTypeChange}>
                    <Grid container alignItems='center'>
                        <Grid item xs={Utility.getType() === 2 ? 12: 3}>
                            <FormControlLabel value="url" control={<Radio color="primary" />} label="Webページ" />
                        </Grid>
                        <Grid item xs={Utility.getType() === 2 ? 12: 3}>
                            <FormControlLabel value="img" control={<Radio color="primary" />} label="画像ファイル" />
                        </Grid>
                    </Grid>
                    </RadioGroup>
                    </ThemeProvider>

                    <Grid container justify='center' alignItems='center' style = {{display : type === 'url' ? '' : 'none'}}>
                        <Grid item xs={3}>
                            サイトURL
                        </Grid>
                        <Grid item xs={9}>
                            <DialogContentText>
                                {progressBarSiteUrl}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <div style={{marginTop: -8}}>※半角512文字まで入力できます。</div>
                    <Grid container justify='center' alignItems='center' style = {{display : type === 'url' ? '' : 'none'}}>
                        <Grid item xs={12}>
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    margin="dense"
                                    id="siteUrl"
                                    name="siteUrl"
                                    //label="サイトURL"
                                    variant={'outlined'}
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    onChange={handleUrlChange}
                                    value={siteUrl}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={onClearSiteUrl}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>

                    <Grid container justify='center' alignItems='center' style={{marginTop: 20}}>
                        <Grid item xs={12} className={classes.settingTitle}>表示する方法</Grid>
                    </Grid>
                    <ThemeProvider theme={radioButtonTheme}>
                        <RadioGroup aria-label="radio" name="radio" value={openMethod} onChange={handleOpenMethodChange}>
                        <Grid container alignItems='center'>
                            <Grid item xs={3}>
                                <FormControlLabel value="newTab" control={<Radio color="primary" />} label="別タブで表示" disabled={type === 'url' ? false : true} />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel value="iframe" control={<Radio color="primary" />} label="画面内に表示" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style = {{display : type === 'url' ? '' : 'none', marginBottom: 20}}>
                            ※サイトURLによって、画面内に表示できないことがあります。画面内に表示できない場合は、「別タブで表示」に設定してください。
                            {warnMessageIframe}
                        </Grid>
                        </RadioGroup>
                    </ThemeProvider>

                    <Grid container justify='center' alignItems='center' style={{ marginTop: 10, marginLeft: -10 }}>
                        <Grid item xs={12}>
                            <ThemeProvider theme={checkBoxTheme}>
                                <Checkbox
                                    color='primary'
                                    checked={notification}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </ThemeProvider>
                            フロア通知　※通知したい場合はONにしてください。
                        </Grid>
                    </Grid>
                {drawLastUpdate}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => {setShowClearConfirmDialog(true)}} style={{ pointerEvents: 'auto', color: '#006FBC', fontFamily: 'Hiragino Maru Gothic StdN' }}>クリア</Button>
                    <Button color='primary' disabled={checkInput() === false} onClick={handleClickCompleate} style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110 }} >OK</Button>
                    <Button color='primary' onClick={() => handleCloseDialog()} style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [dispSetting, notification, imgUrl, previewSrc, type, siteUrl, props.floorObject, openMethod, signboardName, disableButton, errMsgOnBoardName, errMsgOnImgUrl, errMsgOnSiteUrl]);


    /*
    * 確認ダイアログ
    */
    const confirmDialog = useMemo(() => {
        // 表示・非表示の確認
        if(!dispConfirm) {
            return;
        }

        return (
            <BaseDialog
                open={dispConfirm}
                onClose={() => setDispConfirm(false)}
                aria-labelledby='DeleteObjectDialog'
                maxWidth={'md'}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="confirm-title" style={{ userSelect: 'none', padding: '10px 25px 10px 25px' ,background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>「画面内に表示」についてのご注意</DialogTitle>
                <DialogContent className={classes.settingDialog}>
                    {warnMessageIframe}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={handleClickConfirm} style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110 }} >OK</Button>
                    <Button color='primary' onClick={() => setDispConfirm(false)} style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [dispConfirm]);

    const clearConfirmDialog = useMemo(() => {
        return (
            <BaseDialog open={isShowClearConfirmDialog}
                style={{ pointerEvents: 'auto' }}
                fullWidth={true}
                maxWidth={Utility.getType() === 2 ? "xs":"sm"}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}>
                <DialogTitle id="dialog-registUser" style={{padding: '10px 25px 10px 25px',background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>確認</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    全ての入力をクリアします。
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <div>
                    <Button color="primary" onClick={() => { handleAllClear(true) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>&nbsp;
                    <Button color="primary" onClick={() => { handleAllClear(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
                </div>
                </DialogActions>
            </BaseDialog>
        )
    },[isShowClearConfirmDialog])

    /*
    * 看板の表示
    */
    const draw = useMemo(() => {
        return (
            <Fragment>
                <div
                    key={`WebpagePopupSignBoard${id}`}
                    id={`WebpagePopupSignBoard${id}`}
                    className={classes.signboard}
                    style={{
                        position: "absolute",
                        left: offsetLeft,
                        top: offsetTop,
                        width: objectMaster.width, //+30
                        height: objectMaster.height, //+30
                        backgroundSize: "contain",
                        // backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                        backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                        backgroundRepeat: "no-repeat",
                        zIndex: ZIndex.floorObject,
                    }}
                >
                    {/* 掲示板と同様にマウスオーバー時に下矢印を表示しないよう改修
                    <CustomizedTooltip 
                        title={
                            <Fragment>
                                <KeyboardArrowDownIcon fontSize={'large'} className={classes.clickMeIcon}/>
                            </Fragment>
                        } 
                        TransitionComponent={Zoom}
                        placement={'top'}
                    >
                    */}
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardImg}
                            style={{
                                top: objectMaster.textOffsetTop,
                                left: objectMaster.textOffsetLeft,
                                height: objectMaster.textHeight,
                                width: objectMaster.textWidth,
                                backgroundSize: "contain",
                                backgroundImage: `url(${noticeData.imgData1})`,//https://image.pia.jp/images/202106/202106220030_ex.png //https://ocadweb.com/wp-content/uploads/2016/03/32.png 3:2
                                backgroundRepeat: "no-repeat",
                                zIndex: ZIndex.floorObject,
                                backgroundPosition: 'center',
                                fontWeight: 'bold',
                                fontSize: 18,
                                // backgroundColor: "red", // debug用に領域の色を変更
                            }}
                            onClick={openMethod==='newTab' ? openNewTab : openIframe}
                            // onClick={openMethod==='newTab' ? openNewTab :() => props.openPage(siteUrl, signboardName, false, 0, 0, 0)}
                        >
                            {noticeData.imgData1==='' || noticeData.imgData1==='data:;base64,' ? signboardName : ''}
                        </div>
                    {/* 
                    </CustomizedTooltip>
                    */}
                    {drawSettingIcon(props.role)}
                </div>
            </Fragment>
            
        )
    }, [ notification, imgUrl, siteUrl, noticeData,props.floorObject, signboardName, openMethod, props.role])

    return (
        <Fragment>
            {draw}
            {settingDialog}
            {confirmDialog}
            {clearConfirmDialog}
        </Fragment>
    )
}
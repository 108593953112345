import React, { Fragment, useRef, useState, useEffect, useMemo } from 'react'
import { withStyles, makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import {Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, DialogContent, DialogContentText, DialogActions, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@material-ui/core';
import { Box, Divider } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/styles';
import useReactRouter from 'use-react-router';

import BaseDialog from '../user/BaseDialog';
import { Utility } from '../common/Utility';

import { userListConst } from './UserListConst';
import { PointManagerDialog, PointManagerDialogHandler } from './PointManagerDialog';

import JfsClient from "@fsi/jfs-sdk";

export type HeadCell = {
  id: string;
  label: string;
};

class FinishInfo {
  open: boolean = true;
  message: string = "";
  password: null | string = null;

  constructor(init: Partial<FinishInfo>) {
    Object.assign(this, init);
  }
}

class ConfirmInfo {
  open: boolean = true;
  message: string = "";
  isOk: boolean = false;
  type: number = 0;

  constructor(init: Partial<ConfirmInfo>) {
    Object.assign(this, init);
  }
}

class Tag {
  item: string = '';
  value: string = '';
  profileOrder: number = 0;
}

class YearMonthData {
  id: number = 0;
  yearMonth: number = 0;
}

export default function ContractInfo() {
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;
  const tabId = sessionStorage.getItem('TABID') || '';
  const classes = useStyles();

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);
  const [updateAll, setUpdateAll] = React.useState(false);

  const [yearMonthList, setYearMonthList] = React.useState([] as YearMonthData[]);

  const [ state, setState ] = useState({
    latestCalcDate: '',
    latestUsedSize: '',
    summaryDate: '',
    selectYearMonth: 0,
    contractYearMonth: 0,
    course: "",
    plan: "",
    basicContractBytes: 0.0,
    excessIdUsedBytes: 0.0,
    contractBytesSummary: 0.0,
    loginIdSummary: 0,
    excessLoginId: 0,
    usedBytesSummary: 0,
    excessUsedBytes: 0,
    loginIdOver8d: 0,
    loginId7d: 0,
    loginId6d: 0,
    loginId5d: 0,
    loginId4d: 0,
    loginId3d: 0,
    loginId2d: 0,
    loginId1d: 0,
  });

  const { history } = useReactRouter();
  /** pagenation */
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  /** message */
  const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);
  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });
    const clearErrorData = () => {
    errorData.code = '';
    errorData.message = '';
    if (errorData.errorDetail === null || errorData.errorDetail === undefined) {
      errorData.errorDetail = [];
    } else {
      errorData.errorDetail.length = 0;
    }
    return errorData;
  }

  const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
  const showBackdropForDowndating = useRef(false);

  /**
  * useEffect
  */
  useEffect(() => {
    setCrudType(0);
    getContractYearMonthList();
  }, []);

  useEffect(() => {
    if (confirmInfo.isOk) {
      if(confirmInfo.type === 1){
        handleDownloadCSV();
      }
    }
  }, [confirmInfo.isOk]);

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  // 利用実績画面の年月変更ハンドラ
  const handleYearMonthChange = async (e: React.ChangeEvent<{name?: string | undefined; value: unknown;}>)=> {
    let yearMonth = e.target.value as number;
    setState(() => {
      return { ...state, ["selectYearMonth"]: yearMonth };
    });
    await fetchContractInfoData(yearMonth);
  }

  // 利用実績画面がある年月リスト取得
  const getContractYearMonthList = async () => {
    // 契約年月の一覧を取得
    await setLoading(true);
    const res = await httpClient.getContractYearMonth(tabId);
    
    const _YearMonthList = res ? res.map((v: any) => {
      const yearMonth: YearMonthData = new YearMonthData();
      yearMonth.id = v.id as number;
      yearMonth.yearMonth = v.yearMonth as number;
      return yearMonth;
    }) : [] as YearMonthData[];

    if(_YearMonthList.length > 0){
      setYearMonthList(_YearMonthList);
      setState(() => {
        return { ...state, ["selectYearMonth"]: _YearMonthList[0].yearMonth };
      });
      await fetchContractInfoData(_YearMonthList[0].yearMonth);
    }
    await setLoading(false);
  }

  // 利用実績表示
  const fetchContractInfoData = async (yearMonth: number) => {
    await setLoading(true);

    const now = new Date();
    const formattedDate = formatDate(now);
    
    let usedSize = '0.000';
    let currentYearMonth = now.getFullYear() * 100 + now.getMonth() + 1;

    // 利用実績データ取得
    await httpClient.getContractInfoData(
      tabId,
      yearMonth,
    )
    .then((res: ContractInfoData) => {
      let formattedSummaryDate = '----/--/-- --:--';
      // alert(yearMonth + " : " + res.course + " - " + res.plan);
      if(res.summaryDate !== null){
        if(yearMonth === currentYearMonth){
          formattedSummaryDate = formattedDate;
          setState({
            ...state,
            latestCalcDate: formattedDate,
            latestUsedSize: res.usedBytesSummary.toFixed(2), 
            summaryDate: formattedSummaryDate,
            selectYearMonth: yearMonth,
            contractYearMonth: res.contractYearMonth,
            course: res.course,
            plan: res.plan,
            basicContractBytes: res.basicContractBytes,
            excessIdUsedBytes: res.excessIdUsedBytes,
            contractBytesSummary: res.contractBytesSummary,
            loginIdSummary: res.loginIdSummary,
            excessLoginId: res.excessLoginId,
            usedBytesSummary: res.usedBytesSummary,
            excessUsedBytes: res.excessUsedBytes,
            loginIdOver8d: res.loginIdOver8d,
            loginId7d: res.loginId7d,
            loginId6d: res.loginId6d,
            loginId5d: res.loginId5d,
            loginId4d: res.loginId4d,
            loginId3d: res.loginId3d,
            loginId2d: res.loginId2d,
            loginId1d: res.loginId1d,
          })
        }else{
          formattedSummaryDate = formatDate(new Date(res.summaryDate));
          setState({
            ...state,
            summaryDate: formattedSummaryDate,
            selectYearMonth: yearMonth,
            contractYearMonth: res.contractYearMonth,
            course: res.course,
            plan: res.plan,
            basicContractBytes: res.basicContractBytes,
            excessIdUsedBytes: res.excessIdUsedBytes,
            contractBytesSummary: res.contractBytesSummary,
            loginIdSummary: res.loginIdSummary,
            excessLoginId: res.excessLoginId,
            usedBytesSummary: res.usedBytesSummary,
            excessUsedBytes: res.excessUsedBytes,
            loginIdOver8d: res.loginIdOver8d,
            loginId7d: res.loginId7d,
            loginId6d: res.loginId6d,
            loginId5d: res.loginId5d,
            loginId4d: res.loginId4d,
            loginId3d: res.loginId3d,
            loginId2d: res.loginId2d,
            loginId1d: res.loginId1d,
          })
        }
      }else{
        setState({
          ...state,
          summaryDate: formattedSummaryDate,
          selectYearMonth: yearMonth,
          contractYearMonth: res.contractYearMonth,
          course: '',
          plan: '',
          basicContractBytes: 0,
          excessIdUsedBytes: 0,
          contractBytesSummary: 0,
          loginIdSummary: 0,
          excessLoginId: 0,
          usedBytesSummary: 0,
          excessUsedBytes: 0,
          loginIdOver8d: 0,
          loginId7d: 0,
          loginId6d: 0,
          loginId5d: 0,
          loginId4d: 0,
          loginId3d: 0,
          loginId2d: 0,
          loginId1d: 0,
        })
      }
    }).catch(err => {
      assignErrorData(err);
    })
    await setLoading(false);
  }

  /**「検索結果ダウンロード」ハンドラ
   *    ユーザー検索結果のcsvをダウンロードする
   */
  const handleDownloadCSV = async () => {
    clearErrorData();
    await downloadCSV();
    showBackdropForDowndating.current = false;
  } 

  /** CSVダウンロード
   *  ダウンロード条件はsearchDataで指定
   */
  const downloadCSV = async() => {
    
    httpClient.downloadContractInfoCSV(
      sessionStorage.getItem("TABID") as string,
    )
      .then((e) => {
        //レスポンスヘッダからファイル名を取得
        const contentDisposition = e.headers["content-disposition"];
        const fileName = getFileName(contentDisposition);
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([bom, e.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        //ダウンロード
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }).catch(err => {
        assignErrorData(err);
      }).finally(() => {
        setConfirmInfo({...confirmInfo,isOk: false, type:0});
      });
  }

  const getFileName = (contentDisposition: string) => {
    let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
      contentDisposition.length
    );
    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");
    return fileName;
  }

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  const drawGetContractInfo = useMemo(() => {
    return (
      <Fragment>
        <Paper style={{ width: '100%' }}>
          <Box textAlign="left" style={{height: `calc(100vh - 180px)`, overflowY: "scroll" }}>
            <Grid container style={{marginTop: "30px", marginBottom: "30px"}}>
              <Grid item xs={12}><Divider style={{height: "3px"}} /></Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{textAlign: "left"}}><label style={{fontSize: 24}}>当月のご利用状況（通信量）</label></Grid><Grid item xs={8}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{textAlign: "left"}}><label style={{fontSize: 16, marginLeft: 10}}>{state.latestCalcDate}　時点</label></Grid><Grid item xs={8}></Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">通信量合計</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key={state.selectYearMonth}>
                        <StyledTableCell align="center">{state.latestUsedSize}　GB</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={12}><br /><br /></Grid>
              <Grid item xs={12}><Divider style={{height: "3px"}} /></Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{textAlign: "left"}}><label style={{fontSize: 24}}>月ごとのご利用状況</label></Grid><Grid item xs={8}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{textAlign: "left"}}><label style={{fontSize: 16, marginLeft: 10}}>{state.summaryDate}　時点</label></Grid><Grid item xs={8}></Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">対象年月</InputLabel>
                  <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="yearMonth"
                      onChange={(event) => handleYearMonthChange(event)}
                      label="Age"
                      input={<OutlinedInput margin='dense' aria-label={"Age"} label={"契約年月"} color="primary" />}
                      style={{ width: '100%', fontSize: '20px' }}
                      value={state.selectYearMonth}
                  >
                      {yearMonthList.map((yearMonth) => (
                          <MenuItem key={yearMonth.id} value={yearMonth.yearMonth}>
                            {Math.floor(yearMonth.yearMonth / 100)}年{yearMonth.yearMonth % 100}月
                          </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={2}>
                <div className={classes.button}>
                    <Button variant="contained" color="primary" onClick={() => {setConfirmInfo({...confirmInfo, open: true, message: '利用実績データを出力します。よろしいですか？', type: 1})}}>ＣＳＶダウンロード</Button>&nbsp;
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}><br/></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">ご契約コース</StyledTableCell>
                        <StyledTableCell align="center">ご契約プラン</StyledTableCell>
                        <StyledTableCell align="center">月額基本料金に含まれる通信量(a)</StyledTableCell>
                        <StyledTableCell align="center">id超過分に含まれる通信量(b)</StyledTableCell>
                        <StyledTableCell align="center">通信総量(a+b)</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key={state.selectYearMonth}>
                        <StyledTableCell align="center">{state.course}</StyledTableCell>
                        <StyledTableCell align="center">{state.plan}</StyledTableCell>
                        <StyledTableCell align="center">{state.basicContractBytes}&nbsp;GB</StyledTableCell>
                        <StyledTableCell align="center">{state.excessIdUsedBytes.toFixed(2)}&nbsp;GB</StyledTableCell>
                        <StyledTableCell align="center">{state.contractBytesSummary.toFixed(2)}&nbsp;GB</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={12}><br/></Grid>
              <Grid item xs={12}><br/></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{textAlign: 'left'}}><b>（当月のご利用実績）</b></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={8}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">ログインid合計</StyledTableCell>
                        <StyledTableCell align="center">うち、id超過分</StyledTableCell>
                        <StyledTableCell align="center">通信量合計</StyledTableCell>
                        <StyledTableCell align="center">うち、通信量超過分</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key={state.selectYearMonth}>
                        <StyledTableCell align="center">{state.loginIdSummary}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.excessLoginId}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.usedBytesSummary.toFixed(2)}&nbsp;GB</StyledTableCell>
                        <StyledTableCell align="center">{state.excessUsedBytes.toFixed(2)}&nbsp;GB</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={12}><br/></Grid>
              <Grid item xs={12}><br/></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{textAlign: 'left'}}><b>（当月のログインid内訳）</b></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table" style={{marginBottom: 0}}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">日数別ログインid数</StyledTableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">8日以上</StyledTableCell>
                        <StyledTableCell align="center">7日</StyledTableCell>
                        <StyledTableCell align="center">6日</StyledTableCell>
                        <StyledTableCell align="center">5日</StyledTableCell>
                        <StyledTableCell align="center">4日</StyledTableCell>
                        <StyledTableCell align="center">3日</StyledTableCell>
                        <StyledTableCell align="center">2日</StyledTableCell>
                        <StyledTableCell align="center">1日</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow key={state.selectYearMonth}>
                        <StyledTableCell align="center">{state.loginIdOver8d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId7d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId6d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId5d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId4d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId3d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId2d}&nbsp;id</StyledTableCell>
                        <StyledTableCell align="center">{state.loginId1d}&nbsp;id</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </Paper>
      </Fragment>
    );
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // 処理中表示
  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  const confirm = useMemo(() =>
        <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false })}}>
            <DialogContent>
            <DialogContentText>{confirmInfo.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true});showBackdropForDowndating.current = true; }}>OK</Button>
            <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [confirmInfo.open]);

    const downdating = useMemo(() =>
        <BaseDialog className={classes.backdrop} open={showBackdropForDowndating.current}>
            <Slide direction="up" in={showBackdropForDowndating.current}>
            <Card className={classes.card}>
                <CardContent>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress size={55} style={{ marginBottom: 18, marginLeft:14 }} color='primary' />
                </ThemeProvider>
                <Typography variant="subtitle2" >データ取得中</Typography>
                </CardContent>
            </Card>
            </Slide>
        </BaseDialog>
    , [showBackdropForDowndating.current]);

  return (
    <Fragment>
    <div style={{marginTop: -20}}>
      <Card className={classes.root} style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10}}>
        <CardHeader
        title='利用実績画面'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      {drawGetContractInfo}
      {drawLoading}
    </div>
    <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
      <DialogContent>
        <DialogContentText>{finishInfo.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
      </DialogActions>
    </BaseDialog>
      {confirm}
      {downdating}
    </Fragment>
  )
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
  },
  content: {
    //    flexShrink: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    tableLayout:'fixed',
  },
  search: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    marginLeft: 0,
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

// UI表示用
class ContractInfoData {
  summaryDate: Date = new Date();
  contractYearMonth: number = 0;
  course: string = "";
  plan: string = "";
  basicContractBytes: number = 0.0;
  excessIdUsedBytes: number = 0.0;
  contractBytesSummary: number = 0.0;
  loginIdSummary: number = 0;
  excessLoginId: number = 0;
  usedBytesSummary: number = 0;
  excessUsedBytes: number = 0;
  loginIdOver8d: number = 0;
  loginId7d: number = 0;
  loginId6d: number = 0;
  loginId5d: number = 0;
  loginId4d: number = 0;
  loginId3d: number = 0;
  loginId2d: number = 0;
  loginId1d: number = 0;
}
import { Box, Button, TextField } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import React, { Fragment, useState } from 'react';
import { eventIdLengthMax } from '../user/EventLogin';

export default function EventRegist() {
    const { history } = useReactRouter();
    const [addButton, setAddButton] = useState(true);
    const [errMsg, setErrMsg] = useState('');

    const [state, setState] = useState({
        eventname: "" as string,
        loginid: "" as string,
        adminpassword: "" as string,
        guestpassword: "" as string,
        validstarttime: "" as string,
        validendtime: "" as string,
        eventmembermax: "" as string,
        floorid: "" as string
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        setState({ ...state, [name]: event.target.value });
    }

    const isValid = () => {
        if (state.eventname.length <= 0 || state.loginid.length <= 0 || state.adminpassword.length <= 0 || state.guestpassword.length <= 0 ||
            state.validstarttime.length <= 0 || state.validendtime.length <= 0 || state.eventmembermax.length <= 0 || state.floorid.length <= 0) {
            setErrMsg("入力されていない項目があります");
            return false;
        }

        if (state.loginid.length > eventIdLengthMax) {
            setErrMsg("イベントIDは最大" + eventIdLengthMax + "文字です");
            return false;
        }

        setErrMsg("");
        return true;
    }

    const registUser = () => {
        if (!isValid()) {
            return;
        }

        var params = new URLSearchParams();
        params.append("eventname", state.eventname);
        params.append("loginid", state.loginid);
        params.append("adminpassword", state.adminpassword);
        params.append("guestpassword", state.guestpassword);
        params.append("validstarttime", state.validstarttime);
        params.append("validendtime", state.validendtime);
        params.append("eventmembermax", state.eventmembermax);
        params.append("floorid", state.floorid);
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        setAddButton(false);
        axios.post('/api/system/event/regist', params)
            .then((e: AxiosResponse) => {
                history.push({
                    pathname: "/top",
                });
            }).catch(err => {
                alert(err.response.data)
                setAddButton(true);
            });
    }

    return (
        <Fragment>

            <div style={{ padding: 10, textAlign: "left" }}>
                <form noValidate autoComplete="off">
                    <div>
                        <TextField
                            variant="standard"
                            id="eventname"
                            label="イベント名"
                            name="eventname"
                            value={state.eventname}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="loginid"
                            label="イベントID"
                            name="loginid"
                            value={state.loginid}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="adminpassword"
                            label="管理者パスワード"
                            name="adminpassword"
                            value={state.adminpassword}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="guestpassword"
                            label="ゲストパスワード"
                            name="guestpassword"
                            value={state.guestpassword}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="validstarttime"
                            label="有効開始日時"
                            name="validstarttime"
                            value={state.validstarttime}
                            type="datetime-local"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="validendtime"
                            label="有効終了日時"
                            name="validendtime"
                            type="datetime-local"
                            value={state.validendtime}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="eventmembermax"
                            label="イベント最大人数"
                            name="eventmembermax"
                            type="number"
                            value={state.eventmembermax}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="floorid"
                            label="フロアID"
                            name="floorid"
                            type="number"
                            value={state.floorid}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    { // Auth Error Message
                        errMsg.length > 0
                            ? <div style={{ padding: 5, color: 'red', fontWeight: 'bold' }}>{errMsg}</div>
                            : ''
                    }
                </form>
                <div>
                    <Button disabled={!addButton} onClick={registUser} variant="contained" color="primary">登録</Button>
                    <p>※システム権限を持っているユーザーのみ登録可能です</p>
                </div>
            </div>
        </Fragment>
    )
}

import { Fragment, useEffect, useMemo, useState } from 'react';
import SystemFrame from './SystemFrame';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 2,
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'left',
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '95%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

export default function SystemYouTubeSetting() {
    const classes = useStyles();
    const [youtubeListBoxMaxHeight, setYoutubeListBoxMaxHeight] = useState(0);
    const [isValiFailed, setIsValiFailed] = useState(false);
    const [valiMessage, setValiMessage] = useState("");
    const [videoId, setVideoId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const messageSuccess = "YouTubeビデオの変更が成功しました！";
    const messageFailed = "YouTubeビデオの変更に失敗しました！";

    useEffect(() => {
        setYoutubeListBoxMaxHeight(window.innerHeight - 100);
    }, [])

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // YouTubeのvideoIdが固定の11位のため、11位超えた場合は入力を禁止する
        setVideoId(event.target.value.length > 11 ? event.target.value.substring(0, 11) : event.target.value);
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setVideoId(value);
        // YouTubuのビデオIDが11位
        if (value && value.length !== 11) {
            setIsValiFailed(true);
            setValiMessage('ビデオIDは11位に指定してくだい。');
            return;
        }
        setIsValiFailed(false);
        setValiMessage('');
    }

    const handleCancel = () => {
        setVideoId('');
        setIsValiFailed(false);
        setValiMessage('');
    }

    const handleSubmit = async () => {
        if (!isValiFailed) {
            setIsLoading(true);
            const param = new URLSearchParams();
            param.append("videoid", videoId);
            try {
                await Axios.post("/api/system/youtube/update", param);
                enqueueSnackbar(messageSuccess, { variant: "success" });
            } catch (e) {
                console.error(e);
                enqueueSnackbar(messageFailed, { variant: "error" });
            } finally {
                setVideoId('');
                setIsLoading(false);
            }
        }
    }

    const drawInput = useMemo(() => {
        return <TextField
            error={isValiFailed}
            label="YouTubeビデオID"
            style={{ margin: 10 }}
            value={videoId}
            placeholder={"videoIdを入力してください"}
            helperText={valiMessage}
            fullWidth
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValiFailed, valiMessage, videoId])

    const drawLoading = useMemo(() => isLoading &&
        <ThemeProvider theme={circularTheme}>
            <CircularProgress color='primary' />
        </ThemeProvider>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [isLoading])

    return (
        <Fragment>
            <div className={classes.root}>
                <SystemFrame />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{overflowY: 'auto', maxHeight: youtubeListBoxMaxHeight + 'px'}}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        >
                            <div className={classes.column}>
                                <Typography className={classes.heading}>YouTubeビデオID設定</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <div className={classes.column}>
                                {drawInput}
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                        <Button size="large" color="primary" onClick={handleCancel}>キャンセル</Button>
                        <Button size="large" color="primary" onClick={handleSubmit}>変更</Button>
                        </AccordionActions>
                        {drawLoading}
                    </Accordion>
                    </div>
                </main>
            </div>
        </Fragment>
    )
}
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect } from 'react';

import { makeStyles, Theme, createStyles, useTheme, DialogContentText } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios, { AxiosResponse } from 'axios';
import { releaseNoteURL } from '../common/DocumentURL';

import Grid from '@material-ui/core/Grid';

/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notify: {
            //marginTop: '50px',
            overflow: 'auto',
            //height: '250px',,
            maxHeight: '400px',
            textAlign: 'left',
          },
          paper2: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
          paper3: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
    }),
);
*/

interface Props {

}

// 公開したいメソッドの定義
export interface ChildHandler {
    setReleaseNoteOpen: (open: boolean) => void,
    getReleaseVersion: () => string
}

const ReleaseNoteDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    //const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [releaseNote, setReleaseNote] = useState('');
    const [releaseVersion, setReleaseVersion] = useState('');

    useImperativeHandle(ref, () => ({
        setReleaseNoteOpen: (open: boolean) => {
            setOpen(open);
        },
        getReleaseVersion: () => {
            //getReleaseVersionStr();
            console.log("getReleaseVersion ret:"+releaseVersion)
            return(releaseVersion);
        }
    }));

    useEffect(() => {
        getReleaseNote();
        //getReleaseVersion();

        function getReleaseNote() {
        //   var params = new URLSearchParams();
        //   axios.post('/api/service/releaseNoteURL', params)
        //     .then((e: AxiosResponse) => {
        //         //setReleaseNote(e.data);
        //         let ddd = new Date();
        //         let rURL = e.data + "?" + ddd.getTime();
        //         setReleaseNote(rURL);
        //     }).catch((e) => {
		// 		console.log("releaseNoteURL error:"+e)
        //     });

            let ddd = new Date();
            let rURL = releaseNoteURL + "?" + ddd.getTime();
            // let rURL = "../" + releaseNoteURL + "?" + ddd.getTime();
            setReleaseNote(rURL);
        }

        /*
        async function getReleaseVersion() {
            console.log("ReleaseVersion read start")
            var params = new URLSearchParams();
            await axios.post('/api/service/releaseVersion', params)
              .then((e: AxiosResponse) => {
                setReleaseVersion(e.data);
                console.log("releaseVersion=["+e.data+"]")
            }).catch((e) => {
				console.log("releaseVersion error:"+e)
            });
        }
        */
    }, [])

    // リリースバージョン取得
    /*
    const getReleaseVersionStr = () =>{
        let ver:string = '';
        var params = new URLSearchParams();
        axios.post('/api/service/releaseVersion', params)
            .then((e: AxiosResponse) => {
            setReleaseVersion(e.data);
            ver = e.data;
            console.log("releaseVersion=["+e.data+"]")
        }).catch((e) => {
            console.log("releaseVersion error:"+e)
        });
    }
    */

    const handleClickClose = () =>{
        setOpen(false);
    }
    
    const handleClickTarget = () =>{
        setOpen(false);
    }
    
    const drawDialog = useMemo(() => {

        return (
            <BaseDialog
                open={open} onClose={handleClickClose}
                aria-labelledby="releasenote-dialog-title"
                aria-describedby="releasenote-dialog-description"
                fullWidth={true}
                //maxWidth={"sm"}
                maxWidth={"md"}
                //maxWidth={"lg"}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
                >

                <DialogContent>
                    <DialogContentText id="release-dialog-description"  style={{textAlign: "left", marginBottom: '0px'}}>
                        <iframe src={releaseNote}
                            frameBorder='0'
                            title='ReleaseNote'
                            //style={{width:'100%', height:'250px'}}
                            style={{width:'100%', height:'400px'}}
                        />
                    </DialogContentText>
                    <Divider/>
                </DialogContent>

                <DialogActions>
                    {/*
                    <Button id="release-dialog-open-Button" href={releaseNote} target="_releaseNote_target" onClick={handleClickTarget} color="primary">
                        別画面に表示
                    </Button>
                    */}
                    <Button id="release-dialog-OK-Button" onClick={handleClickClose} color="primary" autoFocus style={{color: '#006FBC'}}>
                        閉じる
                    </Button>
                </DialogActions>
            </BaseDialog>
        );

    }, [open, releaseNote, releaseVersion]);

    return (
        <Fragment>
            {drawDialog}
        </Fragment>
    )
}

export const ReleaseNoteDialogRef = forwardRef(ReleaseNoteDialog);

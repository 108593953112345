import React, { useEffect, useState, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, useCallback, ReactNode } from 'react'
import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import { createStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';//import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import DelIcon from '@material-ui/icons/DeleteOutline';

import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
//import StarIcon from '@material-ui/icons/Star';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';

import { DraggableFrame, DraggableFrameHandler } from '../common/DraggableFrame';
import ZIndex from '../common/ZIndex';
import { Coord, Size } from '../common/JsonClass';
import { USER_SEARCH_BG_COLOR } from '../common/Constants';
import { SelectMemoGroup, SelectMemoGroupHandler } from './SelectMemoGroup';
import { GoToSeeDialog, GoToSeeDialogHandler, dialogType } from './dialog/GoToSeeDialog';
import { SelectChatGroup, SelectChatGroupHandler } from './SelectChatGroup';
import { Utility } from '../common/Utility';
import { Button } from '@material-ui/core';
import JfsClient, { JfsError, User, SearchedUser } from '@fsi/jfs-sdk';

const dotColor = '#222'
const SELECT_ACTIONS_MIN_HEIGHT = 100;
const useStyles = makeStyles((theme) => createStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        userSelect: 'text',
        background: '#F2EDED',
        border: '6px solid #57BBFF',
        borderRadius: '25px',
    },
    rootiPhone: {
        width: '100%',
        height: '100%',
        position: 'relative',
        userSelect: 'text',
        background: '#F2EDED',
        border: '6px solid #57BBFF',
        borderRadius: '25px',
    },
    header: {
        height: 40,
        background: '#57BBFF',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerItem: {
        height: '24px',
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    body: {
        width: '100%',
        height: `calc(100% - 90px)`,// 80px = header + textbox
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column',// 上が最新
    },
    bodyInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    on: {
        color: "#006FBC",
    },
    off: {
        color: theme.palette.common.white,
    },
    endMessage: {
        color: dotColor,
        alignSelf: 'center',
    },
    loading: {
        color: dotColor,
        alignSelf: 'center',
        '&::after': {
            content: '" ."',
            animation: '$dots 1s steps(5, end) infinite;'
        }
    },
    // loading dot definition
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0,0,0,0)',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '40%': {
            color: dotColor,
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '60%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 rgba(0,0,0,0)`,
        },
        '80%,100%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 ${dotColor}`,
        },
    },
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Hiragino Maru Gothic StdN',
        color: '#989898'
    },

    selectActionsContainer: {
        marginLeft: 20,
    },
    selectActions: {
        minWidth: 110,
        minHeight: SELECT_ACTIONS_MIN_HEIGHT,
        maxWidth: 250,
        maxHeight: 250,
        background: USER_SEARCH_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: 4,
    },
    selectMemoGroupContainer: {
        minWidth: 150,
        minHeight: 150,
        background: USER_SEARCH_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: 4,
    },
    selectChatGroupContainer: {
        minWidth: 150,
        minHeight: 150,
        background: USER_SEARCH_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: 4,
    },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})


interface ResTarget {
    target: User | SearchedUser | undefined;
    isSameFloor: boolean;
}
interface Props {
    getTargetOfGoToSee: (subId: string) => Promise<ResTarget>;
    handleGoToSee: (target: User | SearchedUser, isSameFloor: boolean) => void;
    handleOpenMemo: (groupId: number, groupName: string) => void;
    scrollbarWidth: number;
    defaultSize: Size;
    myUserSubId: string;
    //frameSetting?: DraggableFrameProps;
    enabledMyRoom: boolean;
    handleAddMyRoomUser: (id:number, subId:string) => void;
    handleOpenChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => void;
    setToTop:(name: string) => void;
    handleCloseUserSearch: () => void;
    privacyRoom: boolean;
    otherUsers: User[];
}

export interface UserSearchHandler {
    open: (defaultCoord: Coord) => void;
    close: () => void;
    getOpen: () => boolean;
    setCoord: (coord: Coord) => void;
    setIsiPhoneLayout: (isiPhone: boolean) => void;
    goToSee: (subId: string) => void;   // マイルームから「会いに行く」を実行するため外部公開
    getZindex:() => void;
}

// request
// const getUsers = async (tabId: string, keyword: string): Promise<SearchedUser[]> => {
//     try {
        // const res: AxiosResponse<SearchedUser[]> = await axios.post('/api/user/users', {
        //     tabId,
        //     keyword
        // });

//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

const UserSearchComponent: ForwardRefRenderFunction<UserSearchHandler, Props> = ({
    getTargetOfGoToSee,
    handleGoToSee,
    handleOpenMemo,
    scrollbarWidth = 0,
    defaultSize,
    myUserSubId,
    //frameSetting = {},
    enabledMyRoom = false,
    handleAddMyRoomUser,
    handleOpenChat,
    setToTop,
    handleCloseUserSearch,
    privacyRoom,
}, ref) => {
    const tabId = sessionStorage.getItem('TABID') as string;
    const classes = useStyles();
    const frameRef = useRef({} as DraggableFrameHandler);
    const userSearchRef = useRef<HTMLInputElement>(null);
    const selectMemoGroupRef = useRef({} as SelectMemoGroupHandler);
    const goToSeeDialogRef = useRef({} as GoToSeeDialogHandler);
    const TEXT_TRIM_LENGTH = 50;
    const MAX_LENGTH = 6;
    const selectChatGroupRef = useRef({} as SelectChatGroupHandler);

    // state
    const [keyword, setKeyword] = useState('');
    const [userList, setUserList] = useState([] as SearchedUser[]);
    const [message, setMessage] = useState<string | ReactNode>('');
    // frame display
    const [defaultCoord, setDefaultCoord] = useState({ x: 0, y: 0 });
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isEnterContent, setEnterContent] = useState(false);
    // list display
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedUser, setSelectedUser] = useState({} as SearchedUser);
    // select action
    const [userItemElm, setUserItemElm] = useState<HTMLDivElement | null>(null);
    const isSelectActionOpen = Boolean(userItemElm);
    const popoverId = isSelectActionOpen ? 'actionSelectPopover' : undefined;
    // select memo group
    const [writeMemoElm, setWriteMemoElm] = useState<HTMLDivElement | null>(null);
    const isSelectGroupOpen = Boolean(writeMemoElm);
    // select chat group
    const [writeChatElm, setWriteChatElm] = useState<HTMLDivElement | null>(null);
    const isSelectChatOpen = Boolean(writeChatElm);

    const [isiPhoneLayout, setIsiPhoneLayout] = React.useState(false);
    const [zindex, setZindex] = React.useState(ZIndex.experiment);

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // TODO: V2でFloor.tsx側へ移動するかを検討
    const frameSetting = {
        parentElementId: 'floorContainer',
        zIndex: ZIndex.experiment,
        defaultCoord,
        //スマホ縦持ちorPCの場合のサイズ
        defaultSize:  
            {
                width:  Utility.getType() < 3 ? 200 : 600, 
                height: Utility.getType() < 3 ? 200 : 300, 
            },
        //スマホ横持ち場合のサイズ
        defaultSize2:
            {
                width:  Utility.getType() < 3 ? 200 : 600, 
                height: Utility.getType() < 3 ? 200 : 300, 
            },
        scrollbarWidth,
        enableResizing: true,
        disableDragging: false,
    }
    const _frameSetting = {
        ...frameSetting,
        // minWidth: defaultSize.width,
        // minHeight: defaultSize.height,
        enableResizing: isEnterContent ? false : frameSetting.enableResizing,
        disableDragging: isEnterContent ? true : frameSetting.disableDragging,
    }

    const getUsers = async (tabId: string, keyword: string): Promise<SearchedUser[]> => {
        try {
            const res: SearchedUser[] = await httpClient.getUserInfoList(tabId, keyword);
    
            return res;
        } catch (err) {
            throw err;
        }
    }

    const isMyUserSubIdEmpty = useMemo(() => {
        return myUserSubId === "" || myUserSubId === null;
    }, [myUserSubId]);

    const searchUser = async () => {
        setUserList([]);
        setMessage('');

        if (keyword !== '') {
            try {
                setLoading(true);
                const data = await getUsers(tabId, keyword);
                if (data.length === 0) {
                    const m1 = <p><b>"{keyword}"</b>を含む &nbsp;&nbsp;<br />ユーザーは0件です。</p>
                    setMessage(m1);
                } else if (data.length > 0) {
                    setUserList([...data]);
                }
            } catch (err) {
                console.error(err);
                const m2 = <p>データ取得に&nbsp;&nbsp;<br />失敗しました。</p>
                setMessage(m2);
            } finally {
                setLoading(false);
            }
        } else {
            const m3 = <p>キーワードを入力して<br />「検索」ボタンを押してください</p>
            setMessage(m3);
        }
    }

    const adjustText = (text: string) => {
        return text.length > TEXT_TRIM_LENGTH ? `${text.slice(0, TEXT_TRIM_LENGTH)}...` : text;
    }

    const adjustLength= (text: string) => {
        return text.length > MAX_LENGTH ? `${text.slice(0, MAX_LENGTH)}...` : text;
    }

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open: (defaultCoord: Coord) => {
            openUI(defaultCoord);
        },
        close() {
            closeUI();
        },
        getOpen() {
            return isShow
        },
        setCoord(coord: Coord) {
            setDefaultCoord(coord);
        },
        setIsiPhoneLayout(isiPhone: boolean) {
            //console.log("setisiphone");
            setIsiPhoneLayout(isiPhone);
        },
        goToSee(subId: string){
            onGoToSeeClick(subId);
        },
        getZindex(){
            return zindex;
        },
        setZindex(z: number){
            setZindex(z);
        }
    }));

    const openUI = (coord: Coord) => {
        setKeyword('');
        setMessage(<p>キーワードを入力して<br />「検索」ボタンを押してください</p>);
        setUserList([]);
        setDefaultCoord(coord);
        setShow(true);
        frameRef.current?.open();
        setTimeout(() => {
            document.getElementById('userSearchTextField')?.focus();
        }, 500);
    }

    const closeUI = () => {
        setKeyword('');
        setMessage('');
        setUserList([]);
        setShow(false);
        frameRef.current?.close();
        handleCloseUserSearch();
    }

    /**
     * useEffect
     */
    useEffect(() => {
        frameRef.current?.changeToAtOpen();
        userSearchRef.current?.focus();
    }, [defaultCoord])

    /**
     * event handlers
     */
    const onClose = () => {
        closeUI();
    }

    var TimeFn = null as any;

    const onFrameDoubleClick = () => {
        clearTimeout(TimeFn);
        frameRef.current?.onFrameDoubleClick();
    }

    const setZIndex = () =>{
        clearTimeout(TimeFn);

        TimeFn = setTimeout(function(){
            setToTop("UserSearch");
        }, 200);
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            searchUser();
        }
    }

    const onSearchClick = () => {
        searchUser();
        setWriteMemoElm(null)
        setWriteChatElm(null)
        setUserItemElm(null)
    }

    const onUserItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, user: SearchedUser, index: number) => {
        setUserItemElm(event.currentTarget);
        setSelectedUser(user);
        setSelectedIndex(index);
    }

    const onSelectActionClose = (event: any) => {
        // !ListItemTextに「disableTypography」がないとクラスが指定されないので注意
        if (
            !isSelectGroupOpen &&
            typeof event.target?.className === 'string' &&
            !event.target?.className.includes('searched-user-item')
        ) {
            setWriteMemoElm(null)
            setWriteChatElm(null)
            setUserItemElm(null)
        }
    }

    const onStartChatClick = async (event: any) => {
        const data = await selectChatGroupRef.current?.getData(selectedUser.subId);
        if (data.length === 1) {
            var group = data[0];
            handleOpenChat(group.groupId, group.groupName, 
                group.groupMemberNumber, 0, [selectedUser.subId]);
            setUserItemElm(null);
        } else if (data.length > 1) {
            setWriteChatElm(event.target);
            selectChatGroupRef.current?.getDataAndDisplay();
        }
    }

    const onSelectChatClose = (event?: any) => {
        // !ListItemTextに「disableTypography」がないとクラスが指定されないので注意
        if (
            typeof event.target?.className === 'string' &&
            !event.target?.className.includes('searched-user-action-item')
        ) {
            setWriteChatElm(null);
            setUserItemElm(null);
        }
    }

    const onGoToSeeClick = async (subId: string) => {
        try {
            const res = await getTargetOfGoToSee(subId);
            const { target, isSameFloor } = res;
            if (target && isSameFloor) {
                handleGoToSee(target as User, isSameFloor);
            } else if (target && !isSameFloor) {
                // 異なるフロア
                const { officeName, floorName } = target as SearchedUser;
                goToSeeDialogRef.current?.open(
                    <span><b>{target.displayName}</b>さんは<b>{officeName}-{floorName}</b>にいます。移動しますか？</span>,
                    dialogType.Confirm,
                    target as SearchedUser
                );
            } else {
                goToSeeDialogRef.current?.open('対象ユーザーのデータが存在しません。', dialogType.Notify, {} as SearchedUser);
            }
        } catch (err) {
            console.error(err);
            goToSeeDialogRef.current?.open('対象ユーザーのデータ取得に失敗しました。', dialogType.Notify, {} as SearchedUser);
        } finally {
            setWriteMemoElm(null);
            setUserItemElm(null);
        }
    }

    const onWriteMemoClick = async (event: any) => {
        const data = await selectMemoGroupRef.current?.getData(selectedUser.subId);
        if (data.length === 1) {
            // TODO: V2で消す
            const { groupId, groupName } = data[0];
            handleOpenMemo(groupId, groupName);
            setUserItemElm(null);
        } else if (data.length > 1) {
            setWriteMemoElm(event.target);
            selectMemoGroupRef.current?.getDataAndDisplay();
        }
    }

    const onSelectMemoClose = (event?: any) => {
        // !ListItemTextに「disableTypography」がないとクラスが指定されないので注意
        if (
            typeof event.target?.className === 'string' &&
            !event.target?.className.includes('searched-user-action-item')
        ) {
            setWriteMemoElm(null);
            setUserItemElm(null);
        }
    }

    const onClickAddMyRoomUser = (id:number, subId:string) => {
        handleAddMyRoomUser(id, subId);
        closeUI();
    }

    /**
     * draw elements
     */
    const drawUserItem = useCallback((user: SearchedUser, index: number) => {
        let { displayName, isOnline } = user;
        if(user.isMySeat !== null && user.isMySeat === false){
            isOnline = false;
        }  
        return (
            <ListItem
                className='searched-user-item'
                key={`searched-user-item-${index}`}
                aria-describedby={popoverId}
                button
                dense
                selected={selectedIndex === index}
                onClick={(event) => onUserItemClick(event, user, index)}
            >
                <ListItemIcon className='searched-user-item' style={{ minWidth: 35 }}>
                    <FiberManualRecordIcon className={`searched-user-item ${isOnline ? classes.on : classes.off}`} />
                </ListItemIcon>
                <Tooltip title={displayName.length > TEXT_TRIM_LENGTH ? displayName : ''} placement="bottom-start" arrow>
                    <ListItemText className='searched-user-item' primary={adjustText(displayName)} disableTypography />
                </Tooltip>
                {/* // TODO: v2 お気に入りボタン 
                    <ListItemSecondaryAction>
                        <IconButton className='searched-user-item' disabled disableRipple edge='end' aria-label='favorite'>
                            <StarIcon className={isFavorite ? classes.on : classes.off} />
                        </IconButton>
                    </ListItemSecondaryAction>
                */}
            </ListItem>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, popoverId]);

    /**
     * draw elements
     */
     const drawSelfItem = useCallback((user: SearchedUser, index: number) => {
        const { displayName, isOnline } = user;
        return (
            <ListItem
                className='searched-user-item'
                key={`searched-user-item-${index}`}
                aria-describedby={popoverId}
                dense
                selected={selectedIndex === index}
            >
                <ListItemIcon className='searched-user-item' style={{ minWidth: 35 }}>
                </ListItemIcon>
                <Tooltip title={displayName.length > TEXT_TRIM_LENGTH ? displayName : ''} placement="bottom-start" arrow>
                    <ListItemText className='searched-user-item' primary={adjustText(displayName)} disableTypography style={{color:'gray'}}/>
                </Tooltip>
                <div style={{textAlign:'end',color:'gray'}}>あなた</div>
            </ListItem>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, popoverId]);

    // 検索結果の描画
    // myUserは表示しない）
    const drawUserSearchResult = useMemo(() => {
        return (
            <List
                component='nav'
                aria-label='searched user list'
                style={{ width: '100%', paddingTop: 0 }}
            >
                {
                    userList.map((user: SearchedUser, index: number) => {
                        if (user.subId === "" || user.subId === null) console.log(`「${user.displayName}」のsubIdが空のため、「会いに行く」「メモを残す」を使用できません。`);
                        return (user.subId !== myUserSubId) ? drawUserItem(user, index) : drawSelfItem(user, index);
                    })
                }
            </List>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyword, message, userList, selectedUser]);

    const drawLoading = useMemo(() => {
        return (
            <Grid container justify='center' alignItems='center' style={{ height: '100%' }}>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color='primary' />
                </ThemeProvider>
            </Grid>
        )
    }, []);

    const drawMessage = useMemo(() => {
        return (
            <div className={classes.messageContainer}>
                {message}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const drawSelectAction = useMemo(() => {
        const p = document.getElementById('userSearch')?.getBoundingClientRect();
        const c = userItemElm?.getBoundingClientRect();
        const deltaW = 20;
        let xx = 0;
        let yy = 0;

        if(p !== undefined && c !== undefined){
            yy = Math.min(document.documentElement.clientHeight - 120 - p.y, c.y - p.y);
            xx = Math.min(c.width + deltaW , window.innerWidth - 120 - p.x);
        }

        return (
            p && c &&
            <ClickAwayListener onClickAway={onSelectActionClose}>
                <div
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                >
                <Paper
                    elevation={3}
                    className={classes.selectActions}
                    style={{
                        position: 'absolute',
                        top: yy,
                        left: xx,
                        minHeight: privacyRoom ? 70 : SELECT_ACTIONS_MIN_HEIGHT,
                    }}
                >
                    <ArrowRightIcon fontSize='large' style={{ position: 'absolute', top: 0, left: -24 }} />
                    <List>
                        <h4 style={{ margin: 0, paddingLeft: 5, textAlign: 'left' }}>
                            {adjustLength(selectedUser.displayName)}
                        </h4>
                        <Divider />
                        { !privacyRoom && (
                                <ListItem
                                    className='searched-user-action-item'
                                    button
                                    dense
                                    disableGutters
                                    disabled={!selectedUser.isOnline || selectedUser.subId === '' || selectedUser.subId === null || selectedUser.isMySeat !== null && selectedUser.isMySeat === false}
                                    onClick={() => onGoToSeeClick(selectedUser.subId)}
                                    style={{ padding: 0 }}
                                    hidden
                                >
                                    <ListItemText
                                        className='searched-user-action-item'
                                        primary='会いに行く'
                                        disableTypography
                                        style={{ width: '100%', textAlign: 'center', paddingTop: 6 }}
                                    />
                                </ListItem>
                            )
                        }
                        {/*<ListItem
                            className='searched-user-action-item'
                            button
                            dense
                            disableGutters
                            disabled={isMyUserSubIdEmpty || selectedUser.subId === '' || selectedUser.subId === null}
                            onClick={onWriteMemoClick}
                            style={{ padding: 0 }}
                        >
                            <ListItemText
                                className='searched-user-action-item'
                                primary='メモを残す'
                                disableTypography
                                style={{ width: '100%', textAlign: 'center', padding: 5 }}
                            />
                        </ListItem>*/}
                        <ListItem
                            className='searched-user-action-item'
                            button
                            dense
                            disableGutters
                            disabled={isMyUserSubIdEmpty || selectedUser.subId === '' || selectedUser.subId === null}
                            onClick={onStartChatClick}
                            style={{ padding: 0 }}
                        >
                            <ListItemText
                                className='searched-user-action-item'
                                primary='チャットする'
                                disableTypography
                                style={{ width: '100%', textAlign: 'center', padding: 5 }}
                            />
                        </ListItem>

                        {enabledMyRoom === true ?
                        <ListItem
                            className='searched-user-action-item'
                            button
                            dense
                            disableGutters
                            disabled={selectedUser.subId === '' || selectedUser.subId === null}
                            onClick={() => onClickAddMyRoomUser(0, selectedUser.subId)} // フォロー時はidの値は使ってないので0でよい
                            style={{ padding: 0 }}
                        >
                            <ListItemText
                                className='searched-user-action-item'
                                primary='フォローする'
                                disableTypography
                                style={{ width: '100%', textAlign: 'center'}}
                            />
                        </ListItem>
                        :
                        ''
                        }
                    </List>
                </Paper>
                </div>
            </ClickAwayListener>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userItemElm, selectedUser, isSelectGroupOpen, isMyUserSubIdEmpty, enabledMyRoom]);

    const drawSelectChatGroup = useMemo(() => {
        const p = document.getElementById('userSearch')?.getBoundingClientRect();
        const c = writeChatElm?.getBoundingClientRect();
        const deltaH = 3
        const deltaW = 12;

        let xx = 0;
        let yy = 0;

        if(p != undefined && c!=undefined){
            yy = Math.min(document.documentElement.clientHeight - 200 - p.y, c.y - p.y - deltaH);
            xx = Math.min(c.x - p.x + c.width + deltaW , window.innerWidth - 200 - p.x);
        }
        return (
            p && c
                ?
                <ClickAwayListener onClickAway={onSelectChatClose}>
                    <div
                        onPointerEnter={() => setEnterContent(true)}
                        onPointerLeave={() => setEnterContent(false)}
                    >
                    <Paper
                        elevation={4}
                        className={classes.selectChatGroupContainer}
                        style={{
                            position: 'absolute',
                            top: yy,
                            left: xx,
                        }}
                    >
                        <ArrowRightIcon fontSize='large' style={{ position: 'absolute', top: 0, left: -24 }} />
                        <h4 style={{ margin: 0, paddingTop: 8, paddingLeft: 5, textAlign: 'left' }}>
                            チャット一覧
                        </h4>
                        <Divider />
                        <div style={{ height: 150, maxHeight: 200, overflow: 'auto' }}>
                            <SelectChatGroup
                                ref={selectChatGroupRef}
                                subId={selectedUser.subId}
                                handleCloseParent={onSelectChatClose}
                                handleOpenChat={handleOpenChat}
                            />
                        </div>
                    </Paper>
                    </div>
                </ClickAwayListener>
                :
                <SelectChatGroup
                    ref={selectChatGroupRef}
                    subId={selectedUser.subId}
                    handleCloseParent={onSelectChatClose}
                    handleOpenChat={handleOpenChat}
                    style={{ visibility: 'hidden' }}
                />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writeChatElm, selectedUser, isSelectChatOpen]);

    const drawSelectMemoGroup = useMemo(() => {
        const p = document.getElementById('userSearch')?.getBoundingClientRect();
        const c = writeMemoElm?.getBoundingClientRect();
        const deltaH = 3
        const deltaW = 12;
        return (
            p && c
                ?
                <ClickAwayListener onClickAway={onSelectMemoClose}>
                    <Paper
                        elevation={4}
                        className={classes.selectMemoGroupContainer}
                        style={{
                            position: 'absolute',
                            top: c.y - p.y - deltaH,
                            left: c.x - p.x + c.width + deltaW,
                        }}
                    >
                        <ArrowRightIcon fontSize='large' style={{ position: 'absolute', top: 0, left: -24 }} />
                        <h4 style={{ margin: 0, paddingTop: 8, paddingLeft: 5, textAlign: 'left' }}>
                            メモ
                        </h4>
                        <Divider />
                        <div style={{ height: 150, maxHeight: 200, overflow: 'auto' }}>
                            <SelectMemoGroup
                                ref={selectMemoGroupRef}
                                subId={selectedUser.subId}
                                handleCloseParent={onSelectMemoClose}
                                handleOpenMemo={handleOpenMemo}
                            />
                        </div>
                    </Paper>
                </ClickAwayListener>
                :
                <SelectMemoGroup
                    ref={selectMemoGroupRef}
                    subId={selectedUser.subId}
                    handleCloseParent={onSelectMemoClose}
                    handleOpenMemo={handleOpenMemo}
                    style={{ visibility: 'hidden' }}
                />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writeMemoElm, selectedUser, isSelectGroupOpen]);

    return (
        <DraggableFrame ref={frameRef} {..._frameSetting} zIndex={zindex} handleResize={() => {}} minWidth={200} minHeight={200}>
            <CssBaseline />
            <Paper id='userSearch' elevation={5} className={!isiPhoneLayout ? classes.root : classes.rootiPhone}>
                <div className={classes.header} onDoubleClick={onFrameDoubleClick} onClick={setZIndex}>
                    <div style={{ marginLeft: 8 , fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555', fontSize: 20}}>人を探す</div>
                    <div
                        onPointerEnter={() => setEnterContent(true)}
                        onPointerLeave={() => setEnterContent(false)}
                    >
                        <IconButton className={classes.headerItem} onClick={onClose} style={{ width: 22 }}>
                            <CloseIcon viewBox='0 0 16 16' />
                        </IconButton>
                    </div>
                </div>
                <div style={{ padding: 5 }} 
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                    onClick={setZIndex}>
                    <TextField
                        id='userSearchTextField'
                        variant='outlined'
                        size='small'
                        placeholder='キーワード'
                        style={{ width: '100%', background: '#FFFFFF'}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment onClick={onSearchClick} position='end'>
                                    <Button variant="contained" size="small" color='primary' style={{ pointerEvents: 'auto', background: '#006FBC', borderRadius: '31px' }}>検索</Button>
                                </InputAdornment>
                            ),
                        }}
                        //autoFocus
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        onKeyDown={onKeyDown}
                        inputRef = {userSearchRef}
                    />
                </div>
                <div
                    id='userListBody'
                    className={'customScrollBar ' + classes.body}
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                    onClick={setZIndex}
                >
                    {isLoading
                        ? drawLoading
                        : message !== ''
                            ? drawMessage
                            : <InfiniteScroll
                                className={classes.bodyInnerFrame}
                                scrollableTarget='userListBody'
                                dataLength={userList.length}
                                next={() => { }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: userList.length === 0 ? 'center' : 'flex-start',
                                    alignItems: userList.length === 0 ? 'center' : 'flex-start',
                                }}
                                inverse={false}// 上から下
                                hasMore={false}// scrollによる追加取得なし
                                loader={<h4 className={classes.loading}>loading</h4>}
                                endMessage={!isLoading && <p className={classes.endMessage}></p>}
                            >
                                {drawUserSearchResult}
                            </InfiniteScroll>
                    }
                </div>
                {drawSelectAction}
                {/*drawSelectMemoGroup*/}
                {drawSelectChatGroup}
                {selectedUser &&
                    <GoToSeeDialog ref={goToSeeDialogRef} handleGoToSee={handleGoToSee} />}
            </Paper>
        </DraggableFrame >
    )
}

export const UserSearch = forwardRef(UserSearchComponent);
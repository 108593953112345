import React, { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Size } from '../common/JsonClass';
import { calcAvatarWidth, getAvatarVideoSize} from "../common/AvatarSize";
import { SELECT_NONE } from "../common/Constants";
import Divider from '@material-ui/core/Divider';
import { Button, makeStyles, styled, createStyles, Theme, Container } from '@material-ui/core';
import { Height } from '@material-ui/icons';
import Draggable from "react-draggable";
import axios, { AxiosResponse } from 'axios';
import { BusinessCard } from '../common/JsonClass'
import useWindowSize from '../common/useWindowSize';
import {Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';//import Typography from '@material-ui/core/Typography';
import JfsClient, { JfsError, Profile, User, UserProfile } from '@fsi/jfs-sdk';
import BaseDialog from './BaseDialog';
import MyPageCard from './MyPageCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        
        dialogTitle: {
            //maxWidth: tutorialWidth,
            background: '#57BBFF 0% 0% no-repeat padding-box',
        },
        
        tutorialFont: {
            //fontFamily: tutorialFont,
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        }
    }),
);

interface Props {
  className?: string,
  user: User,
  open: boolean,
  ping: number,
  onClickRefresh: () => void,
  onClickClose: () => void,
  zIndex: number,
  isLarge: boolean,
  enabledBusinessCard: boolean,
  kind:string | null,
  isMyUser: boolean,
  floorSize: Size,
}

// const bodyStyle: React.CSSProperties = {
//   ...SELECT_NONE,
//   overflow: 'hidden',
//   textOverflow: 'ellipsis',
//   display: '-webkit-box',
//   WebkitLineClamp: 2,
//   WebkitBoxOrient: 'vertical'
// };
const bodyStyle2: React.CSSProperties = {
  ...SELECT_NONE,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
};

// export class Profile {
//   userProfileId: number = 0;
//   item: string = "";
//   value: string = "";
//   profileOrder: number = 0;
// }

export class UserPointInfo {
  userId: number = 0;
  loginId: string = "";
  dispName: string = "";
  additionalSummary: number = 0;
  deductionalSummary: number = 0;
}

export default function UserInfoCard(props: Props) {
  useEffect(() => {
    onProfileTouch(props.open);
  }, [props.open])

  useEffect(() => {
    if(props.kind !== 'myroom'){  // myroomの使い方だとuserとopenを同時にセットするので、1回目のopenで開かない
      props.onClickClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.x, props.user.y, props.kind])

  useEffect(() => {
    if (props.open) {
      if (props.user.role === "ROLE_USER") setDispPoint(true);
      setShowIntroEditDialog(false);
      getProfile();
      getUserPoint();
    }
  }, [props.open, props.user.isCardDisclose, props.user.subId])

  const handleClickEditProfile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!props.open) return;
    var pf = userProfile.find(pf => pf.item == "自己紹介");
    if(pf != undefined){
      setIntroInput(pf.value);
    }
    setShowIntroEditDialog(true);
  }

  // プロフィールに表示する最大項目数
  const MAX_ITEM_NUM: number = 10;
  // profileのコピー
  const [userProfile, setUserProfile] = useState([] as Profile[]);
  const [introInput, setIntroInput] = useState("");
  const [isShowIntroEditDialog, setShowIntroEditDialog] = useState(false);
  
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;

  // profileのソート
  userProfile.sort((profile1, profile2) => {
    if (profile1.profileOrder == null && profile2.profileOrder == null) return 0;
    if (profile1.profileOrder == null) return 1;
    if (profile2.profileOrder == null) return -1;
    if (profile1.profileOrder > profile2.profileOrder) return 1;
    if (profile1.profileOrder < profile2.profileOrder) return -1;
    if (profile1.id > profile2.id) return 1;
    if (profile1.id < profile2.id) return -1;
    return 0;
});
  const [systemMessage, setSystemMessage] = useState("");

  const networkSpeed = React.useMemo(() => {
    if (props.kind === 'myroom' && props.ping == -1){
      return " - ";
    } else if (props.ping <= 100) {
      return "高速";
    } else if (props.ping <= 1000) {
      return "中速";
    } else {
      return "低速";
    }

  }, [props.ping]);

  // プロフィール下に表示する状態の定義
  const state = [
    {
      head: "ネットワーク速度",
      value: networkSpeed,
      tail: ""
    },
    {
      head: "つぶやき",
      value: props.user.tubuyaki,
      tail: ""
    },
  ];
  
  const [userPointInfo, setUserPointInfo] = useState(new UserPointInfo());
  const [summaryPoint, setSummaryPoint] = useState(0);
  const [additionalSummary, setAdditionalSummary] = useState(0);
  const [dispPoint, setDispPoint] = useState(false);

  // プロフィール下に表示する状態の定義
  const userPoint = [
    {
      head: "利用可能ポイント",
      value: summaryPoint.toString(),
      tail: "ポイント"
    },
    {
      head: "累計獲得ポイント",
      value: additionalSummary.toString(),
      tail: "ポイント"
    },
  ];

  const judgehz = (intext: string) => {
    let len = 0
    if (intext === null) return len;
    let text = intext.split('')

    // 半角全角判定
    for (let i = 0; i < intext.length; i++) {
        if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
            len = len + 0.5     // 半角
        } else {
            len = len + 1       // 全角
        }
    }

    return len;
  };

  const isVisible = (item: string) => {
    switch (item) {
      case '住所（国）':
      case '郵便番号':
      case '住所（都道府県）':
      case '住所（市町村）':
      case '住所（通り）':
      case '住所（ビル名など）':
      case '固定電話番号・内線番号':
        return false;
      default:
        break;
    }
    return true;
  }

  const MAX_HEIGHT = useMemo(() => {
    let enableItemNum: number = 0;

    let itemProfile = userProfile.find(pf => pf.item == "自己紹介" && pf.value.trim() != "") as Profile;
    let itemSoshiki = userProfile.find(pf => pf.item == "組織" && pf.value.trim() != "") as Profile;
    let itemYakusyoku = userProfile.find(pf => pf.item == "役職" && pf.value.trim() != "") as Profile;

    let ret = 0;
    enableItemNum = 0;
    // タイトル(100px)
    ret += 106;
    // 状態(ヘッダ(20px) 
    ret += 20;
    // つぶやきの最大折り返し行 * 20px)
    ret += 20;
    //組織
    if(itemSoshiki != undefined){
      ret += Math.floor((judgehz(itemSoshiki.value)/20) + 1) * 20;
    }
    //役職
    if(itemYakusyoku != undefined){
      ret += Math.floor((judgehz(itemYakusyoku.value)/20) + 1) * 20;
    }
    // その他プロフィール(項目数 * 60px)
    userProfile.forEach((pf) =>{
      if(enableItemNum < MAX_ITEM_NUM && isVisible(pf.item)){
        ret += Math.floor((judgehz(pf.value)/20) + 1) * 20;
        enableItemNum++;
      }
    })
    // 累計ポイント表示
    if(dispPoint){
      // alert(dispPoint + ":" + userPoint.length);
      ret += (userPoint.length * 28);
    }

    /* 
    //プロフィール
    let proLines = 0;
    itemProfile?.value.split("\n").map((txt) => {
      proLines += Math.floor((judgehz(txt)/20) + 1);
    })
    //TODO もし自己紹介表示最大行数制限必要の場合、下記コメントを解除し、数字5表示したい行数変更
    //proLines = Math.min(proLines, 5);
    ret += proLines * 20;
    */

    return ret;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.profile, userProfile, userPoint, dispPoint]);

// avatar要素の幅
  const avatarWidth = useMemo(() => {
    return calcAvatarWidth(props.user.width, props.isLarge);
  }, [props.user.width, props.isLarge]);

  // avatarの映像サイズ
  const avatarVideoHeight = useMemo(() => {
    // ビデオ拡大のときだけサイズを返す
    return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
      ? 0.9 * getAvatarVideoSize(props.user.isLargeVideo)  // 0.9は微調整
      : 0;
  }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);
  
  const avatarVideoWidth = useMemo(() => {
    // ビデオ拡大のときだけサイズを返す
    return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
      ? 0.4 * getAvatarVideoSize(props.user.isLargeVideo) // 0.4は微調整
      : 0;
  }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

  const handleClickRefresh = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!props.open) return;
    if (props.user.role === "ROLE_USER") setDispPoint(true);
    props.onClickRefresh();
    getProfile();
    getUserPoint();
  }

  const handleClickClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.onClickClose();
  }

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // props.onClickClose();  // ドラッグできるようにするため、閉じない
  }

  const createVCardContents = (user: User, bizCard: BusinessCard) => {
    return "BEGIN:VCARD\n" +
      "VERSION:3.0\n" +
      `REV:${new Date().toISOString()}\n` +
      `N;CHARSET=utf-8:${user.lastName};${user.firstName};;;\n` +
      `FN;CHARSET=utf-8:${user.lastName}${user.firstName}\n` +
      `ORG;CHARSET=utf-8:${bizCard.company};${bizCard.department};\n` +
      `TITLE:${bizCard.position}\n` +
      `EMAIL;WORK;INTERNET:${bizCard.mailAddress}\n` +
      `TEL;WORK:${bizCard.phoneNumber}\n` +
      `ADR;WORK;POSTAL;CHARSET=utf-8:;${bizCard.building};${bizCard.street};${bizCard.city};${bizCard.prefecture};${bizCard.postalCode};${bizCard.country}\n` +
      `END:VCARD\n`;
  };

  const createHTMLContents = (user: User, bizCard: BusinessCard) => {

    let resultHtml = '';
    resultHtml += `<div>氏名：${user.lastName} ${user.firstName}<br/></div>`;
    resultHtml += `<div>会社：${bizCard.company}<br/></div>`;
    if (bizCard.department !== '') {
      resultHtml += `<div>部署：${bizCard.department}<br/></div>`;
    }
    if (bizCard.position !== '') {
      resultHtml += `<div>役職：${bizCard.position}<br/></div>`;
    }
    if (bizCard.phoneNumber !== '') {
      resultHtml += `<div>電話番号：${bizCard.phoneNumber}<br/></div>`;
    }
    if (bizCard.postalCode !== '' || bizCard.country !== '' || bizCard.prefecture !== '' || bizCard.city !== '' || bizCard.street !== '' || bizCard.building !== '') {
      let postalCode = '';
      if (bizCard.postalCode !== '') {
        postalCode += `〒${bizCard.postalCode}`;
      }
      resultHtml += `<div>住所：${postalCode} ${bizCard.country} ${bizCard.prefecture} ${bizCard.city} ${bizCard.street} ${bizCard.building}<br/></div>`;
    }
    resultHtml += `<div>メールアドレス：${bizCard.mailAddress}<br/></div>`;

    return resultHtml;
  };

  // プロフィールから名刺情報に変換
  const profile2BizCard = (profileList: Profile[]) => {
    let bizCard = new BusinessCard();
    profileList.forEach((profile) => {
      switch (profile.item) {
        case '住所（国）':
          if (profile.value === '') {
            profile.value = '日本'; // 登録がなければ日本固定
          }
          bizCard.country = profile.value;
          break;
        case '企業・団体・学校名':
          bizCard.company = profile.value;
          break;
        case '部署名':
          bizCard.department = profile.value;
          break;
        case '役職':
          bizCard.position = profile.value;
          break;
        case '郵便番号':
          bizCard.postalCode = profile.value;
          break;
        case '住所（都道府県）':
          bizCard.prefecture = profile.value;
          break;
        case '住所（市町村）':
          bizCard.city = profile.value;
          break;
        case '住所（通り）':
          bizCard.street = profile.value;
          break;
        case '住所（ビル名など）':
          bizCard.building = profile.value;
          break;
        case '固定電話番号・内線番号':
          bizCard.phoneNumber = profile.value;
          break;
        case 'メールアドレス':
          bizCard.mailAddress = profile.value;
          break;
      }
    });
    return bizCard;
  }

  const DOWLOAD_FILENAME = useMemo(() => {
    return `${props.user.lastName}${props.user.firstName}様`;
  }, [props.user.firstName, props.user.lastName])

  const onDownloadBizCard = () => {
    const bizCard = profile2BizCard(userProfile);
    try {
      // vCardのダウンロード
      const vcardFileName = `${DOWLOAD_FILENAME}.vcf`;
      const vcardContents = createVCardContents(props.user, bizCard);
      const vcardBlob = new Blob([vcardContents], { "type": "text/plain" });
      const vcardUrl = window.URL.createObjectURL(vcardBlob);
      const vCardLink = document.createElement('a');
      vCardLink.href = vcardUrl;
      vCardLink.setAttribute('download', vcardFileName);
      document.body.appendChild(vCardLink);
      vCardLink.click();
      document.body.removeChild(vCardLink);
      window.URL.revokeObjectURL(vcardUrl);

      // htmlのダウンロード
      const htmlFileName = `${DOWLOAD_FILENAME}.html`;
      const htmlContents = createHTMLContents(props.user, bizCard);
      const htmlBlob = new Blob([htmlContents], { "type": "text/plain" });
      const htmlUrl = window.URL.createObjectURL(htmlBlob);
      const htmlLink = document.createElement('a');
      htmlLink.href = htmlUrl;
      htmlLink.setAttribute('download', htmlFileName);
      document.body.appendChild(htmlLink);
      htmlLink.click();
      document.body.removeChild(htmlLink);
      window.URL.revokeObjectURL(htmlUrl);
    } catch (error) {
      console.log(error);
      alert("名刺情報のダウンロードに失敗しました。");
    }
  }

  const drawCardHeader = () => {
    return (
      <CardHeader
        className={props.className}
        title={props.user.lastName + " " + props.user.firstName}
        titleTypographyProps={{ variant: "body1", component: "h2" }}
        style={{ 
          marginTop: "-8px",
          marginLeft: "-4px", 
          padding: 12, 
          textAlign: "left", 
          background: '#57BBFF 0% 0% no-repeat padding-box',
          borderRadius: '20px 20px 0 0',
          width: 290,
          maxWidth: 345,
          wordBreak: "break-all" 
        }}
        action={
          <Fragment>
            <div style={{marginLeft: -80}}>
            <IconButton size="small" onClick={handleClickRefresh} onTouchEnd={handleClickRefresh}>  {/* iPad対応-17 タップ対応 */}
              <RefreshIcon />
            </IconButton>
            <IconButton size="small" onClick={handleClickClose} onTouchEnd={handleClickClose}>    {/* iPad対応-17 タップ対応 */}
              <CloseIcon />
            </IconButton>
            </div>
          </Fragment>
        }
      />
    );
  }

  const drawCardContent = () => {
    return ( props.enabledBusinessCard ?  
      // 名刺機能オンの場合：名刺表示
      <CardContent className={props.className} style={{ textAlign: "left", padding: 8}}>
        {drawBizcard()}
        {drawSystemMessage()}
        <Divider />
        {drawState()}
      </CardContent>
      :
      // 名刺機能オフの場合：プロフィール表示
      <CardContent className={props.className} style={{ textAlign: "left", padding: 8 }}>
        {drawProfile()}
        <Divider />
        {drawState()}
        <Divider />
        {drawIntro()} 
        <Divider />
        {drawUserPoint()}        
      </CardContent>
    );
  }

  const drawCardButton = (isCardDisclose: boolean) => {
    if (!isCardDisclose) return;
    if (!props.enabledBusinessCard) return; // 名刺機能オフの場合表示しない
    return (
      <CardContent className={props.className} style={{ textAlign: "left", padding: 8 }}>
        <Button onClick={onDownloadBizCard} variant="contained" color="primary">名刺情報をダウンロード</Button>
      </CardContent>
    );
  }

  const noScroll = useCallback((event: any) => {
    event.stopPropagation();
  }, [])

  const onProfileTouch = (open: boolean) => {
    const name = props.className;
    if (name === null || name === undefined) return;

    const targetList = document.getElementsByClassName(name);
    if (targetList.length <= 0) {
      return;
    }

    // プロフィールをつかんだ時、そのイベントを上位に伝播させない
    const elements = Array.from(targetList);
    elements.forEach(
      elem => {
        if (open) {
          elem.addEventListener('pointerdown', noScroll, { passive: false });
          elem.addEventListener('pointerup', noScroll, { passive: false });   // iPad対応-17
          elem.addEventListener('touchmove', noScroll, { passive: false });
        } else {
          elem.removeEventListener('pointerdown', noScroll);
          elem.removeEventListener('pointerup', noScroll);   // iPad対応-17
          elem.removeEventListener('touchmove', noScroll);
        }
      }
    )
  }

  const getProfile = () => {
    // const params = new URLSearchParams();
    // params.append('tab_id', (sessionStorage.getItem("TABID") as string));
    // params.append("sub_id", props.user.subId);
    // axios.post('/api/user/profile/get', params)
    //   .then((e: AxiosResponse) => {
    //     setUserProfile(e.data as Profile[]);
    //     setSystemMessage("");
    //   }).catch(err => {
    //     setUserProfile([] as Profile[]);
    //     setSystemMessage(err.response.data);
    //     console.log(err);
    //   });

    const tabId = sessionStorage.getItem("TABID") as string;
    const subId = props.user.subId;

    httpClient.getUserProfile(tabId, subId)
      .then((e) => {
        const userProfile = e as UserProfile;
        setUserProfile(userProfile.itemList);
        console.info('prof updated');
        // ユーザーが入力したプロフィールを設定
        setSystemMessage("");
      }).catch(err => {
        setUserProfile([] as Profile[]);
        setSystemMessage(err?.response?.data);
        console.log(err);
      });
  }

  const getUserPoint = () => {
    // const params = new URLSearchParams();
    // params.append('tab_id', (sessionStorage.getItem("TABID") as string));
    // params.append("sub_id", props.user.subId);
    // axios.post('/api/user/point/get', params)
    //   .then((e: AxiosResponse) => {
    //     if(e.data != null){
    //       setUserPointInfo(e.data as UserPointInfo);
    //       setSummaryPoint(e.data.additionalSummary + e.data.deductionalSummary);
    //       setAdditionalSummary(e.data.additionalSummary);
    //     }
    //   }).catch(err => {
    //     setSummaryPoint(0);
    //     setAdditionalSummary(0);
    //     setDispPoint(false);
    //     console.log(err);
    //   });

    httpClient.getUserPoint(sessionStorage.getItem("TABID") as string, props.user.subId)
      .then((e: UserPointInfo) => {
        if(e != null){
          setUserPointInfo(e);
          setSummaryPoint(e.additionalSummary + e.deductionalSummary);
          setAdditionalSummary(e.additionalSummary);
        }
      }).catch(err => {
        setSummaryPoint(0);
        setAdditionalSummary(0);
        setDispPoint(false);
        console.log(err);
      });
  }

  /**
   * 文字列が正規表現に従っているかチェック
   * 
   * @param text チェック対象の文字列 
   * @param regexpStr 正規表現
   * @returns true: 正規表現に従っている, false:正規表現に従っていない
   */
  const checkFormat = (text: string, regexpStr: string) => {
    var reg = new RegExp(regexpStr);
    return reg.test(text);
  }

  // 名刺表示
  const drawBizcard = () => {
    let enableItemNum: number = 0;
    return (
      <Fragment>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          {userProfile.map((profile) => {
            if (isVisible(profile.item)) {
              // 最大項目数内であれば表示する
              if (enableItemNum++ < MAX_ITEM_NUM) {
                return drawProfileItem(profile);
              }
            }
            return <Fragment></Fragment>;
          })
          }
          <Fragment>{'表示名：' + props.user.displayName}<br /></Fragment>
        </Typography>
      </Fragment >
    );
  }

  const drawProfile = () => {
    let enableItemNum: number = 0;
    return (
      <Fragment>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          {userProfile.map((profile) => {
            if (checkFormat(profile.item, "^役職[0-9]*$") || checkFormat(profile.item, "^組織[0-9]*$")) {
              // 最大項目数内であれば表示する
              if (enableItemNum++ < MAX_ITEM_NUM) {
                return drawOrganizationItem(profile);
              }
            }
            return <Fragment></Fragment>;
          })
          }
        </Typography>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          {userProfile.map((profile) => {
            if (!checkFormat(profile.item, "^役職[0-9]*$") && !checkFormat(profile.item, "^組織[0-9]*$")) {
              // 最大項目数内であれば表示する
              if (enableItemNum++ < MAX_ITEM_NUM) {
                return drawProfileItem(profile);
              }
            }
            return <Fragment></Fragment>;
          })
          }
        </Typography >
      </Fragment >
    );
  }

  const drawSystemMessage = () => {
    if (systemMessage === "") return;
    return (
      <Fragment>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          {systemMessage}<br />
        </Typography>
      </Fragment>
    );
  }

  const drawOrganizationItem = (profile: any) => {
    if (profile.item === "" || profile.item == "自己紹介" || profile.value === "") return;
    return (
      <Fragment key={profile.item}>{profile.value}<br /></Fragment>
    );
  }

  const drawProfileItem = (profile: any) => {
    if (profile.item === "" || profile.item == "自己紹介" || profile.value === "") return;
    return (
      <Fragment key={profile.item}>{profile.item + "：" + profile.value}<br /></Fragment>
    );
  }

  const drawState = () => {
    return (
      <Fragment>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          {state.map((e) => {
            return <Fragment key={e.head}>{e.head + "：" + e.value + e.tail}<br /></Fragment>;
          })}
        </Typography>
      </Fragment>
    );
  }

  const drawIntro = () => {
    return (
      <Fragment>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          プロフィール　　　　　　　　　　　 
          {props.isMyUser && <IconButton size="small" onClick={handleClickEditProfile} onTouchEnd={handleClickEditProfile}>
            <EditIcon />
          </IconButton>}
          {
            userProfile.find(pf => pf.item == "自己紹介")?.value.split("\n").map((line) =>{
              return(
                <Fragment>
                  <br/>
                  {line}
                </Fragment>
                )
            })
          }
        </Typography >
      </Fragment >
    );
  }

  const drawIntroText = () => {
    let text = userProfile.find(pf => pf.item == "自己紹介")?.value;
    if(text == undefined ||  text == "") return;
    let arr = text.split("\n");

    arr.map((line) =>{

      return(
        <Fragment>
          <br/>
          {line}
        </Fragment>
        );
    });
  }

  const drawUserPoint = () => {
    return (
      <Fragment>
        <Typography className={props.className} variant="caption" color="textPrimary" component="p" gutterBottom style={bodyStyle2}>
          { userPoint.map((e) => {
              return <Fragment key={e.head}>{ dispPoint ? e.head + "： " + e.value + " " + e.tail : "" }<br /></Fragment>;
          })}
        </Typography>
      </Fragment>
    );
  }

  const draw = useMemo(() =>
    props.open &&
    <Draggable>
      <Card
        id="user-profile"
        className={props.className}
        style={{
          ...SELECT_NONE,
          position: 'absolute',
          top: props.kind === 'myroom' || props.user.y < props.floorSize.height - MAX_HEIGHT ? props.user.y - avatarVideoHeight : props.user.y - avatarVideoHeight - MAX_HEIGHT,
          left: props.kind === 'myroom' || props.user.x < props.floorSize.width - 360 ? props.user.x + avatarWidth + 10 + avatarVideoWidth : props.user.x + avatarWidth + 10 + avatarVideoWidth - 370,
          width: 290,
          minHeight: 150,
          maxHeight: MAX_HEIGHT,
          maxWidth: 345,
          padding: 3,
          opacity: 0.90,
          zIndex: props.zIndex,
          border: '6px solid #57BBFF',
          borderRadius: '25px',
        }}
        onClick={handleClick}
      >
        {drawCardHeader()}
        {drawCardContent()}
        {drawCardButton(props.user.isCardDisclose)}
      </Card >
    </Draggable>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [props.open, props.user.x, props.user.y, props.ping, props.user.isCardDisclose, userProfile, userPoint, systemMessage, avatarWidth, isShowIntroEditDialog, introInput]
  );

  const updateProfile = () => {
    // const params = new URLSearchParams();
    // params.append('tab_id', (sessionStorage.getItem("TABID") as string));
    // params.append("sub_id", props.user.subId);
    // params.append("value", introInput);
    // axios.post('/api/user/profile/updt', params)
    //   .then((e: AxiosResponse) => {
    //     getProfile();
    //   }).catch(err => {
    //     console.log(err);
    //   });

    httpClient.updateUserProfileKeyValue(
        sessionStorage.getItem("TABID") as string, 
        props.user.subId,
        "自己紹介",
        introInput
      ).then((e: string) => {
        getProfile();
      }).catch(err => {
        console.log(err);
      });
  }

  const onClearIntroInput = () => {
    setIntroInput("");
  }

  const handleIntroInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    var val = event.target.value;
    if(val.length <= 100){
      setIntroInput(event.target.value);
    }else{
      event.preventDefault();
      event.stopPropagation();
    }
  }

  //自己紹介編集のダイヤログのボタン押下
  const handleIntroEditDialogOkCancel = (mode:boolean) =>{
      if(mode){
          updateProfile();
      }
      setShowIntroEditDialog(false);
  }

  return (
    <Fragment>
      { props.isMyUser ?
        <MyPageCard 
          className={props.className}
          user={props.user}
          userProfile={userProfile}
          open={props.open}
          ping={props.ping}
          zIndex={props.zIndex}
          onClickRefresh={props.onClickRefresh}
          onClickClose={props.onClickClose}
          handleClickEditProfile={handleClickEditProfile}
          isMyUser={props.isMyUser}
        />
        :
        draw
      }
      
      
      <Dialog open={isShowIntroEditDialog}
                style={{ pointerEvents: 'auto' }}
                fullWidth={true}
                maxWidth={"sm"}
                PaperProps={{
                  style:{
                      border: '6px solid #57BBFF',
                      borderRadius: '25px',
                  }
                }}>
                <DialogTitle id="dialog-registUser" style={{padding: '7px 25px 9px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>プロフィール編集</DialogTitle>
                <DialogContent>
                    <div style={{color: '#707070'}}>内容</div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="groupNameInput"
                        name="groupNameInput"
                        //label="内容"
                        variant={'outlined'}
                        rows='4'
                        multiline
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => handleIntroInputChange(e)}
                        value={introInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={(e) => onClearIntroInput()}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                <div>
                    <Button color="primary" onClick={() => { handleIntroEditDialogOkCancel(true) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100}}>OK</Button>&nbsp;
                    <Button color="primary" onClick={() => { handleIntroEditDialogOkCancel(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 100}}>キャンセル</Button>&nbsp;
                </div>
                </DialogActions>
            </Dialog>
    </Fragment>
  )
}
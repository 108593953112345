import React, { Fragment, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react';
import { makeStyles, Theme, createMuiTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import TutorialIcon from '@material-ui/icons/HelpOutline';
import Video from '@material-ui/icons/Videocam';
import VideoOff from '@material-ui/icons/VideocamOff';
import PersonAdd from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import MailIcon from '@material-ui/icons/Mail';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import LoopIcon from '@material-ui/icons/Loop';
import AlarmOn from '@material-ui/icons/AlarmOn';
import AlarmOff from '@material-ui/icons/AlarmOff';
import Timer from '@material-ui/icons/Timer';
import { ThemeProvider } from '@material-ui/styles';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut'

import SignoutIcon from '../img/ic_signout.svg'
import FitIcon from '../img/ic_fit.svg';
import FitIconInv from '../img/ic_fit_inv.svg';
import FitIconGrey from '../img/ic_fit_grey.svg';
import FitIconInvGrey from '../img/ic_fit_inv_grey.svg';
import WhiteBoardIcon from '../img/icn_whiteboard_fillwhite.svg';
import FloorIcon from '../img/ic-Updown_mod.svg';
import LeaveIcon from '../img/ic-leave_room.svg';

import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import { MoveFloorRef, MoveFloorHandler } from './MoveFloor';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import MessageIcon from '@material-ui/icons/Message';
import LaunchIcon from '@material-ui/icons/Launch';

import axios from 'axios';
import { PrivacyRoomInfo } from '../common/JsonClass';
import { TurnedIn } from '@material-ui/icons';
import JfsClient from '@fsi/jfs-sdk';
import { Utility } from '../common/Utility';
import { boolean } from 'yargs';
import { RFC_2822 } from 'moment';

interface Props {
    isWebRtcConnecting: boolean;
    onClickMuteButton: (on: boolean) => void;
    onClickFitButton: (scale: number) => void;
    onClickOverlookButton: (scale: number) => void;
    onSelectedSignout: any;
    clearScreenShare: () => void;
    isAudioMute: boolean;
    isForceMute: boolean;
    systemMessage: string;
    enabledTutorialButton: boolean;
    openTutorial: () => void;
    scale: number;
    isCommuting: boolean;
    onChangeCommuting: (commuting: boolean) => void;
    purposeOfUse: number;
    isVideoMute: boolean;
    isTrafficLimitVideoMute: boolean;   // 通信量制限によるカメラ使用制限中
    onClickVideoMuteButton: (on: boolean) => void;
    onMoveFloor: (id: number) => void;
    floorId: number;
    centeringView: (isEffect?: boolean) => void;
    handleClickMoreNote: (iswhiteBoard: boolean) => void;
    enabledWhiteBoardButton: boolean;
    enableReconnectHelloButton: boolean;
    enabledMemoButton: boolean;
    openMemoGroupList: () => void;
    openUserSearch: (open: boolean) => void;
    subId: string;
    myDeviceNoSelect: number;   // カメラ未選択時の表示対応
    myMicDeviceNoSelect: number;   // マイク未選択時の表示対応
    enabledScreenShareButton: boolean;
    onChangeScreenShare: (isScreenShare: boolean) => void;
    enabledFloorEditMode: boolean;
    enabledGridMode: boolean;
    gridRange: number;
    toggleGrid: () => void;
    openNewFloorObjectWindow: () => void,
    handleDeleteFloorObject: () => void,
    isFloorObjSelected: boolean,
    floorEditorRef: any,
    enabledOverlookButton: boolean;
    isiPhoneLayout: boolean;
    enabledZoomMicButton: boolean;
    enabledZoomCamButton: boolean;
    //enabledZoomScreenShareButton: boolean;
    //enabledZoomFullScreenButton: boolean;
    syncZoomMicButton: (isZoomMicMute: boolean, msec: number) => void;
    syncZoomCamButton: (isZoomCamMute: boolean, msec: number) => void;
    //syncZoomScreenShareButton: (isZoomScreenShare: boolean, msec: number) => void;
    //syncZoomFullScreenButton: () => void;
    onClickMyRoomButton: (open: boolean) => void;
    enabledMyRoomButton: boolean;
    openGroupChat: (groupId:number, groupName:string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[], open: boolean) => void;
    enabledExternalUrl: boolean;
    externalUrl: string;
    myUserPrivacyRoomInfo: PrivacyRoomInfo;
    myRole: string;
    myState: number;
    onJumptoMySeat: () => void;
    onStateChange: (state: number, enableMeet: boolean) => void;
    requestPrivacyRoom: (floorId: number, userId: number, targetFloorId:number) => void;
    leavePrivacyRoom: (floorId: number) => void;
    sendPrivacyRoomTimeOut: (kind: number, floorId: number) => void;
    sendMovePrivacyRoomInfo:(stayPrivacyroom: boolean, preFloorId: number, preFloorName: string) => void;
    privacyRoom: boolean;
    changeShowToolLauncher: () => void;
    enabledToolLauncherButton: boolean;
    isSelfStudyTable: boolean;
    isStudy: boolean;
    openSelfStudyChangeDialog: (open: boolean) => void;
    openSelfStudyFinishDialog: (open: boolean) => void;
    startStudyTimer: (time: number) => void;
    changeStudyTimerState: (state: boolean, additionalTime: number) => void;
}

export interface FloorMenuHandler {
    setUnreadMemoCount: (count: number) => void;
    setScreenShare: (isOn: boolean) => void;
    setScreenShareWindowSelecting: (isSelecting: boolean) => void;  // #761 共有ウインド選択のバッティング対応
    setZoomSpeakable: (isSpeakable: boolean) => void;
    setOpenMyRoom: (open: boolean) => void;
    setOpenGroupChat: (open: boolean) => void;
    setOpenSearch: (open: boolean) => void;
    permission: () => void;
    notPermission: () => void;
    leaveRoom: (preFloorId: number) => void;
    extendPermission: () => void;
    changeStudyTimerState: (state: boolean) => void;
}
interface privacyRoomInfo {
    id: number;
    subId: string;
    preFloorId: number;
    preFloorName: string;
    floorId: number;
    stayPrivacyroom: boolean;
    waitForPermit: boolean;
}

const useStyles = makeStyles<Theme, Props>({
    floorMenuSignoutImg: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 16 :  6 ),
        left  : (props) => (props.isiPhoneLayout === false ? 28 : 16 ),
        width : (props) => (props.isiPhoneLayout === false ? 27 : 13 ),
        height: (props) => (props.isiPhoneLayout === false ? 27 : 13 ),
        zIndex: ZIndex.fixedUiLayer,
    },

    floorMenuPrivacyImg: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  5 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 14 ),
        width : (props) => (props.isiPhoneLayout === false ? 30 : 15 ),
        height: (props) => (props.isiPhoneLayout === false ? 30 : 15 ),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuMuteMic: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  5 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 12 ),
        width : (props) => (props.isiPhoneLayout === false ? 35 : 17 ),
        height: (props) => (props.isiPhoneLayout === false ? 35 : 17 ),
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuMuteCamera: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  5 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 12 ),
        width : (props) => (props.isiPhoneLayout === false ? 35 : 17 ),
        height: (props) => (props.isiPhoneLayout === false ? 35 : 17 ),
        zIndex: ZIndex.fixedUiLayer,
    },

    floorMenuTutorial: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  5 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 12 ),
        width : (props) => (props.isiPhoneLayout === false ? 32 : 12 ),
        height: (props) => (props.isiPhoneLayout === false ? 32 : 12 ),
        fontSize: 10,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuFitImg: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  3 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 14 ),
        width : (props) => (props.isiPhoneLayout === false ? 30 : 15 ),
        height: (props) => (props.isiPhoneLayout === false ? 30 : 15 ),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuHalfImgTop: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top: (props) => (props.isiPhoneLayout === false ? 1 : 4),
        left: (props) => (props.isiPhoneLayout === false ? 24 : 4),
        width: (props) => (props.isiPhoneLayout === false ? 30 : 15),
        height: (props) => (props.isiPhoneLayout === false ? 30 : 15),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuHalfImgBottom: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top: (props) => (props.isiPhoneLayout === false ? 43 : 28),
        left: (props) => (props.isiPhoneLayout === false ? 24 : 4),
        width: (props) => (props.isiPhoneLayout === false ? 30 : 15),
        height: (props) => (props.isiPhoneLayout === false ? 30 : 15),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuWhiteBoardImg: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 11 :  3 ),
        left  : (props) => (props.isiPhoneLayout === false ? 20 : 13 ),
        width : (props) => (props.isiPhoneLayout === false ? 40 : 20 ),
        height: (props) => (props.isiPhoneLayout === false ? 40 : 20 ),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuDiscriptionImg: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 13 :  3 ),
        left  : (props) => (props.isiPhoneLayout === false ? 22 : 13 ),
        width : (props) => (props.isiPhoneLayout === false ? 36 : 16 ),
        height: (props) => (props.isiPhoneLayout === false ? 36 : 16 ),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuMyLocation: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  3 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 13 ),
        width : (props) => (props.isiPhoneLayout === false ? 32 : 16 ),
        height: (props) => (props.isiPhoneLayout === false ? 32 : 16 ),
        fontSize: 10,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuFloorImg: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 14 :   5 ),
        left  : (props) => (props.isiPhoneLayout === false ? 20 :  13 ),
        width : (props) => (props.isiPhoneLayout === false ? 105 : 52 ),
        height: (props) => (props.isiPhoneLayout === false ? 105 : 52 ),
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuButton: {
        ...SELECT_NONE,
        backgroundColor: '#006FBC', 
        '&:hover': {
            background: '#107FCC',
        },
    },


    floorMenuText: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 55 : 22 ),
        width : (props) => (props.isiPhoneLayout === false ? 82 : 44 ),
        fontSize: 10,
        lineHeight:1.0,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuTextHalfTop: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top: (props) => (props.isiPhoneLayout === false ? 29 : 6),
        width: (props) => (props.isiPhoneLayout === false ? 82 : 30),
        fontSize: 10,
        lineHeight: 1.0,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuTextHalfBottom: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top: (props) => (props.isiPhoneLayout === false ? 71 : 30),
        width: (props) => (props.isiPhoneLayout === false ? 82 : 30),
        fontSize: 10,
        lineHeight: 1.0,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuLeaveText: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 55 : 22 ),
        //left  : (props) => (props.isiPhoneLayout === false ? 12 :  1 ),
        width : (props) => (props.isiPhoneLayout === false ? 82 : 44 ),
        fontSize: 10,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuSignoutText: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 55 : 22 ),
        width : (props) => (props.isiPhoneLayout === false ? 82 : 44 ),
        fontSize: 10,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuWhiteBoardText: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 55 : 23 ),
        width : (props) => (props.isiPhoneLayout === false ? 82 : 44 ),
        height: (props) => (props.isiPhoneLayout === false ? 10 : 10 ),
        lineHeight: 1.0,
        fontSize: 10,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
    floorMenuMyRoom: {
        ...SELECT_NONE,
        pointerEvents: 'none',
        position: "absolute",
        top   : (props) => (props.isiPhoneLayout === false ? 15 :  3 ),
        left  : (props) => (props.isiPhoneLayout === false ? 24 : 13 ),
        width : (props) => (props.isiPhoneLayout === false ? 32 : 16 ),
        height: (props) => (props.isiPhoneLayout === false ? 32 : 16 ),
        fontSize: 10,
        color: 'white',
        zIndex: ZIndex.fixedUiLayer,
    },
})

const buttonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                "&:hover": {
                    backgroundColor: "#0095FA",
                },
            },
            containedSecondary: {
                "&:hover": {
                    backgroundColor: "#0095FA",
                },
            },
        },
    },
})

const studyButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#F8B62B",
        },
        secondary:{
            main: "#E0792D",
        },
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                "&:hover": {
                    backgroundColor: "#EFA207",
                },
            },
            containedSecondary: {
                "&:hover": {
                    backgroundColor: "#EFA207",
                },
            },
        },
    },
})

const FloorMenuComponent: ForwardRefRenderFunction<FloorMenuHandler, Props> = (props: Props, ref) => {

    const [mute, setMute] = React.useState(false);
    const [videoMute, setVideoMute] = React.useState(false);
    const [showSignoutDialog, setShowSignoutDialog] = React.useState(false);
    const [signoutMessage, setSignoutMessage] = React.useState("");
    const [showMoveFloorDialog, setShowMoveFloorDialog] = React.useState(false);
    const [unreadMemoCount, setUnreadMemoCount] = React.useState(0); // MyUserの未読メモ総数
    const [isScreenShare, setScreenShare] = React.useState(false);
    const [isScreenShareWindowSelecting, setScreenShareWindowSelecting] = React.useState(false);    // #761 共有ウインド選択のバッティング対応
    const [isDisabledFitScale, setDisabledFitScale] = React.useState(false);
    const [isDisabledOverlook, setDisabledOverlook] = React.useState(false);
    const [isZoomSpeakable, setZoomSpeakable] = React.useState(false); 
    const [isZoomMicMute, setZoomMicMute] = React.useState(true);
    const [isZoomCamMute, setZoomCamMute] = React.useState(true);
    const [openToolLauncher, setOpenToolLauncher] = React.useState(false);
    //const [isZoomScreenShare, setZoomScreenShare] = React.useState(false);
    const [openMyRoom, setOpenMyRoom] = React.useState(false);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [openGroupChat, setOpenGroupChat] = React.useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const [avatarCustomize, setAvatarCustomize] = React.useState(false);
    const [timerState, setTimerState] = React.useState(false); // user.isTimerでタイマー一時停止/再開の状態を変更したいが、反映が遅いため本変数を利用
    const moveFloorRef = useRef({} as MoveFloorHandler);

    const classes = useStyles(props);

    const PURPOSE_OF_USE_OFFICE: number = 1;    // フロアの使用目的(オフィス)
    const FLOORMENU_BUTTON_SIZE: number = 82;
    const FLOORMENU_BUTTON_SIZE_IPHONE: number = 44;
    
    const processing = useRef(false);

    const OFF_BT_BGCOLOR = '#00d4be';  // 機能OFF時のボタン背景色

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        setUnreadMemoCount(count: number) {
            setUnreadMemoCount(count);
        },
        setScreenShare(isOn: boolean) {
            //if(isOn === false || isScreenShareWindowSelecting === false) {
                setScreenShare(isOn);
            //}
        },
        // #761 共有ウインド選択のバッティング対応
        setScreenShareWindowSelecting(isSelecting: boolean) {
            setScreenShareWindowSelecting(isSelecting);
        },

        setZoomSpeakable(isSpeakable: boolean){
            setZoomSpeakable(isSpeakable);
        },
        setOpenMyRoom(open: boolean) {
            setOpenMyRoom(open);
        },
        setOpenSearch(open: boolean) {
            setOpenSearch(open);
        },
        setOpenGroupChat(open: boolean) {
            setOpenGroupChat(open);
        },
        permission(){
            moveFloorRef.current.permission();
        },
        notPermission(){
            moveFloorRef.current.notPermission();
        },
        leaveRoom(preFloorId: number){
            moveFloorRef.current.leaveRoom(preFloorId);
        },
        extendPermission(){
            moveFloorRef.current.extendPermission();
        },
        changeStudyTimerState(state: boolean){
            handleChangeStudyTimerState(state);
        },
    }));

    const isSubIdEmpty = useMemo(() => {
        return props.subId === "" || props.subId === null;
    }, [props.subId]);

    React.useEffect(() => {
        let unmounted = false;
        async function getServiceName() {
            let message = "";
            try {
                //const x = await axios.post('/api/service/get');
                const x = await httpClient.getServiceInfo();
                message = x.name + "からログアウトします。";
            } catch (err) {
                console.error(err);
                message = "ログアウトします。";
            } finally {
                if (!unmounted) {
                    setSignoutMessage(message);
                }
            }
        }

        if (props.systemMessage !== null && props.systemMessage.length > 0) {
            setSignoutMessage(props.systemMessage);
        } else {
            getServiceName();
        }
        return () => { unmounted = true }; // cleanup
    }, [props.systemMessage]);

    React.useEffect(() => {
        if (isSubIdEmpty)
            console.log("MyUserのsubIdが空のため、メモ機能を使用できません。FloorMenu内の「メモを残す」「未読メモを確認する」を非表示にしました。");
    }, [isSubIdEmpty]);

    React.useEffect(() => {
        if (props.scale === 1.0) {
            // 拡大率が初期値になったら、拡大/全体ボタンの状態を戻す
            setDisabledFitScale(false);
            setDisabledOverlook(false);
        }
    }, [props.scale]);

    React.useEffect(() => {
        if (!isZoomSpeakable) {
            setZoomMicMute(true);
            setZoomCamMute(true);
            //setZoomScreenShare(false);
        }
    }, [isZoomSpeakable]);

    const handleClickMuteButton = (on: boolean) => {
        // マイク未選択時の表示対応
        // マイク未選択時は、マイクミュート状態を変更しない
        if(props.myMicDeviceNoSelect === 1) {
            return;
        }
        setMute(on);
        props.onClickMuteButton(on);
    }

    // ビデオミュートボタン押下
    const handleClickVideoMuteButton = (on: boolean) => {
        // カメラ未選択時、通信量制限によるカメラ使用制限中の表示対応
        // カメラ未選択時、通信量制限によるカメラ使用制限中は、ビデオミュート状態を変更しない
        if( (props.myDeviceNoSelect === 1) || (props.isTrafficLimitVideoMute) ) {
            return;
        }
        
        setVideoMute(on);
        props.onClickVideoMuteButton(on);
    }

    const handleClickSignoutButton = () => {
        setShowSignoutDialog(true);
    }

    const handleClickSignoutDialog = async (yes: boolean) => {
        setShowSignoutDialog(false);
        if (yes) {
            // プライバシールーム一斉退室処理
            await leavePrivacyRoomAll(1);
            props.clearScreenShare();
            props.onSelectedSignout();
        }
    }

    const handleOpenTutorialMenu = () => {
        props.openTutorial();
    }

    const handleClickMyRoom = (open: boolean) => {
        setOpenMyRoom(open);
        props.onClickMyRoomButton(open);
    }

    const handleClickZoomButton = (val: number) => {
        props.onClickFitButton(val);
    }

    const handleClickFitButton = (on: boolean) => {
        if (on) {
            // 拡大ボタン押下時、全体ボタンを無効化
            setDisabledOverlook(true);
            props.onClickFitButton(2.0);
        } else {
            setDisabledOverlook(false);
            props.onClickFitButton(1.0);
        }
    }

    const handleClickOverlookButton = (on: boolean) => {
        if (on) {
            // 全体ボタン押下時、拡大ボタンを無効化
            setDisabledFitScale(true);
            props.onClickOverlookButton(0.5);
        } else {
            setDisabledFitScale(false);
            props.onClickOverlookButton(1.0);
        }
    }

    const handleClickSearch = (open: boolean) => {
        setOpenSearch(open);
        props.openUserSearch(open);
    }

    const handleClickCheckMemo = () => {
        props.openMemoGroupList();
    }

    const handleClickMoveFloor = () => {
        setShowMoveFloorDialog(true);
    }

    const getUserNumber = (id: number) => {
        // const params = new URLSearchParams();
        // params.append("floor_id", id.toString());
        // return axios.post('/api/user/floor/participants/number/get', params);
        return httpClient.getParticipantsNumber(id);
    }

    const openWaitingForLeaveAllDialog = async () => {
        const res = await getUserNumber(props.floorId);
        if(res > 0){
            //申請を送る
            props.leavePrivacyRoom(props.floorId); // wsでバックエンドにアクションを送る -> アクションを受け取った管理者ユーザに申請許可ダイアログが出る
        }
    }

    const getAdminUserList = (id: number) => {
        // const params = new URLSearchParams();
        // params.append("floor_id", id.toString());
        // return axios.post('/api/user/roleadmin/list/get', params);
        return httpClient.getRoleAdminPrivacyRoomInfo(id, sessionStorage.getItem("TABID") as string);
    }

    const getPrivacyRoomInfo = (subId: string) => {
        // const params = new URLSearchParams();
        // params.append("sub_id", subId);
        // return axios.post('/api/user/privacyroominfo/get', params);
        return httpClient.getPrivacyRoomInfo(subId);
    }

    const leavePrivacyRoomAll = async (num: number) => {
        // 退室者が管理者アカウントかつ、面談室に1人も管理者アカウントが残っていない場合、生徒の一斉退室が必要
        if(props.privacyRoom&&props.myRole.indexOf('ROLE_ADMIN') !== -1 ){
            const res = await getAdminUserList(props.myUserPrivacyRoomInfo.floorId);
            const privacyInfoList = res as privacyRoomInfo[];
            if(privacyInfoList.length === num){
                // 生徒の一斉退室処理
                openWaitingForLeaveAllDialog();
            }
        }
    }

    const handleClickLeaveFloor = async () => {
        if(props.myUserPrivacyRoomInfo){      
            if(props.myUserPrivacyRoomInfo.preFloorId === 0){
                //セッションデータのprivacyRoomInfoがなくなった際に、通る処理
                //セッションデータのprivacyRoomInfoがなくなることはないはずだが、念のため処理挿入
                const res = await getPrivacyRoomInfo(props.subId);
                const myPrivacyRoomInfoList = res as privacyRoomInfo[];
                const myPrivacyRoomInfo = myPrivacyRoomInfoList.find((e) => {return e.subId === props.subId});
                if(myPrivacyRoomInfo){
                    await leavePrivacyRoomAll(1);
                    props.onMoveFloor(myPrivacyRoomInfo.preFloorId);
                }
            }else{
                await leavePrivacyRoomAll(1);
                props.onMoveFloor(props.myUserPrivacyRoomInfo.preFloorId);
            }
        }else{
            setShowMoveFloorDialog(true);
        }
    }

    const handleMoveFloor = (id: number) => {
        setShowMoveFloorDialog(false);
        if (id !== -1) {    // ダイアログ外をクリックされたとき-1になる模様
            props.onMoveFloor(id);
        }
    }

    // 自習タイマーボタンクリック用
    const handleClickSelfStudyTimer = (state: boolean, additionalTime: number) => {
        // 本来は自習席着席時に自習開始ダイアログを表示させるが、現在バックエンドから着席のアクションを受け取れていないので、本フロアメニューから表示
        // props.openSelfStudyStartDialog(open);
        props.changeStudyTimerState(state,additionalTime);
    }

    // 自習タイマーの一時停止/再開を制御する(外部公開済み)
    // true: 一時停止ボタン false: 再開ボタン
    const handleChangeStudyTimerState = (state: boolean) => {
        setTimerState(state);
    }

    // 自習状況確認ボタンクリック用
    const handleClickSelfStudyCheck = () => {
        props.openSelfStudyChangeDialog(true);
    }

    // 自習計画完了ボタンクリック用
    const handleClickSelfStudyLookBack = () => {
        //終了時間の登録のapiを投げる
        props.openSelfStudyFinishDialog(true);
        
    }

    const handleClickMylocation = () => {
        props.centeringView(true);
    }

    const handleClickDiscription = () => {
        console.log("ファイル表示");
        props.handleClickMoreNote(false);
    }

    const handleClickWhiteBoard = () => {
        console.log("ホワイトボード");
        props.handleClickMoreNote(true);
    }

    const handleClickScreenShare = (isScreenShare: boolean) => {
        setScreenShare(isScreenShare);
        props.onChangeScreenShare(isScreenShare);
    }

    const floorMenuButtonSize = useMemo(() => {
        if (props.isiPhoneLayout) {
            return FLOORMENU_BUTTON_SIZE_IPHONE;
        }
        else{
            return FLOORMENU_BUTTON_SIZE;
        }
    }, [props.isiPhoneLayout]);

    const handleClickZoomMicMute = (isMicMute: boolean) => {
        setZoomMicMute(isMicMute);
        props.syncZoomMicButton(isMicMute, 1000);
    }
    const handleClickZoomCamMute = (isCamMute: boolean) => {
        if (processing.current) return;

        processing.current = true;
        setTimeout(() => {
            setZoomCamMute(isCamMute);
            props.syncZoomCamButton(isCamMute, 3000);
                // 処理中フラグを下げる
            processing.current = false;
        }, 1000);
    }
    // !syncZoomScreenShareButtonは未完成
    // !現状、Zoom UI内の画面共有ボタン押下のみ実装してある
    /* const handleClickZoomScreenShare = (isScreenShare: boolean) => {
        setZoomScreenShare(isScreenShare);
        props.syncZoomScreenShareButton(isScreenShare, 1000);
    } */
    /* const handleClickZoomFullScreen = () => {
        props.syncZoomFullScreenButton();
    } */

    const drawSignoutButton = useMemo(() => {
        // 通常サイズ(iPhoneではない場合)
        if(props.purposeOfUse === PURPOSE_OF_USE_OFFICE) {
            return (
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginBottom: 5 }}>
                    <img src={SignoutIcon} className={classes.floorMenuSignoutImg} alt="" />
                    <div className={classes.floorMenuLeaveText} >ログアウト</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickSignoutButton} />
                    </ThemeProvider>
                </div>
            );
        }
        else {
            return (
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginBottom: 5 }}>
                    <img src={SignoutIcon} className={classes.floorMenuSignoutImg} alt="" />
                    <div className={classes.floorMenuSignoutText} >ログアウト</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickSignoutButton} />
                    </ThemeProvider>
                </div>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.purposeOfUse, props.isiPhoneLayout]);

    const drawMuteButton = useMemo(() => {
        if (props.isAudioMute !== mute) {
            setMute(props.isAudioMute);
        }

        // #11252 ビデオ通話をしていない場合はボタンを表示しない
        if(!props.isWebRtcConnecting) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                {!props.isWebRtcConnecting || props.isForceMute ?
                    <Fragment>
                    {/* ミートしていない場合、ボタン非活性 */}
                        <MicOff className={classes.floorMenuMuteMic} style={{ color: 'grey' }} />
                        <div className={classes.floorMenuText} style={{ color: 'grey' }} >マイク<br/>オフ</div>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" />
                    </Fragment>
                : (props.myMicDeviceNoSelect === 1) ?
                    <Fragment>
                    {/* マイクデバイス未選択の場合 */}
                        <MicOff className={classes.floorMenuMuteMic} style={{ color: 'white' }} />
                        <div className={classes.floorMenuText} style={{ color: 'white' }} >マイク<br/>オフ</div>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor: 'gray' }} disabled variant="contained" />
                    </Fragment>
                : mute ?
                    <Fragment>
                        {/* ボタン活性 (ミュート解除) */}
                        <MicOff className={classes.floorMenuMuteMic} style={{ color: 'white' }} />
                        <div className={classes.floorMenuText} style={{ color: 'white' }} >マイク<br/>オフ</div>
                        <ThemeProvider theme={buttonTheme}>
                            <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='secondary' onClick={() => { handleClickMuteButton(false) }} />
                        </ThemeProvider>
                    </Fragment>
                :
                    <Fragment>
                        {/* ボタン活性 (ミュート) */}
                        <Mic className={classes.floorMenuMuteMic} style={{ color: 'white' }} />
                        <div className={classes.floorMenuText} style={{ color: 'white' }} >マイク</div>
                        <ThemeProvider theme={buttonTheme}>
                            <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickMuteButton(true) }} />
                        </ThemeProvider>
                    </Fragment>
                }
            </div >
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mute, props.isWebRtcConnecting, props.isAudioMute, props.isForceMute, props.isiPhoneLayout])

    const drawVideoMuteButton = useMemo(() => {
        if (props.isVideoMute !== videoMute) {
            setVideoMute(props.isVideoMute);
        }

        // #11252 ビデオ通話をしていない場合はボタンを表示しない
        if(!props.isWebRtcConnecting) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                {
                !props.isWebRtcConnecting || props.isForceMute ?
                    <Fragment>
                    {/* ミートしていない、強制ミュートの場合 */}
                        <VideoOff className={classes.floorMenuMuteCamera} style={{ color: 'grey' }} />
                        <div className={classes.floorMenuText} style={{ color: 'grey' }} >カメラ<br/>オフ</div>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" />
                    </Fragment>
                : (props.myDeviceNoSelect === 1) || (props.isTrafficLimitVideoMute)?
                    <Fragment>
                    {/* カメラデバイス未選択、カメラ制限ありの通信量制限の場合 */}
                        <VideoOff className={classes.floorMenuMuteCamera} style={{ color: 'white' }} />
                        <div className={classes.floorMenuText} style={{ color: 'white' }} >カメラ<br/>オフ</div>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor: 'gray' }} disabled variant="contained" />
                    </Fragment>
                : videoMute ?
                    <Fragment>
                    {/* ボタン活性 (ミュート解除) */}
                        <VideoOff className={classes.floorMenuMuteCamera} style={{ color: 'white' }} />
                        <div className={classes.floorMenuText} style={{ color: 'white' }} >カメラ<br/>オフ</div>
                        <ThemeProvider theme={buttonTheme}>
                            <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color="secondary" onClick={() => { handleClickVideoMuteButton(false) }} />
                        </ThemeProvider>
                    </Fragment>
                :
                    <Fragment>
                    {/* ボタン活性 (ミュート) */}
                        <Video className={classes.floorMenuMuteCamera} style={{ color: 'white' }} />
                        <div className={classes.floorMenuText} style={{ color: 'white' }} >カメラ</div>
                        <ThemeProvider theme={buttonTheme}>
                            <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickVideoMuteButton(true) }} />
                        </ThemeProvider>
                    </Fragment>
            }
            </div >
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoMute, props.isWebRtcConnecting, props.isVideoMute, props.isTrafficLimitVideoMute, props.isForceMute, props.isiPhoneLayout])

    const drawSignoutDialog = useMemo(() => {
        return (
            <BaseDialog
                id="signOutDialog"
                open={showSignoutDialog}
                aria-labelledby="signOutDialogTitle"
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="signOutDialogTitle">ログアウト</DialogTitle>
                <DialogContent>{signoutMessage}</DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClickSignoutDialog(true) }} color="primary" style={{ pointerEvents: 'auto', borderRadius: '31px', background: '#006FBC', color: '#FFFFFF' }} >ログアウト</Button>
                    <Button onClick={() => { handleClickSignoutDialog(false) }} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767' }} >キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSignoutDialog, signoutMessage, props.isiPhoneLayout])

    const drawTutorialButton = useMemo(() => {

        // 機能が有効でないならボタンを表示しない
        if (!props.enabledTutorialButton) return;
        
        return (
            <Fragment>
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <TutorialIcon className={classes.floorMenuTutorial} />
                    <div style={{ pointerEvents: 'none', fontSize: 10, color: 'white', width: 80, top: 55, left: 2, zIndex: 1, position: "absolute" }} >チュートリアル</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleOpenTutorialMenu} />
                    </ThemeProvider>
                </div>
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledTutorialButton, props.isiPhoneLayout]);

    const drawMyRoomButton = useMemo(() => {

        // 機能が有効でないならボタンを表示しない
        if (!props.enabledMyRoomButton) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
            {openMyRoom ?
                <Fragment>
                    <PeopleAltIcon className={classes.floorMenuMyRoom} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} style={{ color: 'white' }} >フォロー<br/>リスト</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='secondary' onClick={() => { handleClickMyRoom(false) }} />
                    </ThemeProvider>
                </Fragment>
                :
                <Fragment>
                    {/* ボタン活性 (ミュート) */}
                    <PeopleAltIcon className={classes.floorMenuMyRoom} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} style={{ color: 'white' }} >フォロー<br/>リスト</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickMyRoom(true) }} />
                    </ThemeProvider>
                </Fragment>
            }
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMyRoom, props.enabledMyRoomButton, props.isiPhoneLayout]);

    const colors = () => {
        switch (props.scale) {
            case 2.0:
            case 0.5:
                return (
                    <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                        <img src={props.scale==2.0? FitIconInv : FitIcon} className={classes.floorMenuFitImg} alt="" />
                        <div className={classes.floorMenuText} >通常倍率</div>
                        <ThemeProvider theme={buttonTheme}>
                            <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='secondary' onClick={() => { handleClickZoomButton(1.0) }} />
                        </ThemeProvider> 
                    </div>
                )
            case 1.0:
                if (Utility.isiOS()) {
                    return (
                        <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                            <div style={{ marginBottom: 4 }}>
                                <ZoomInIcon className={classes.floorMenuHalfImgTop} style={{ color: 'white' }} />
                                <div className={classes.floorMenuTextHalfTop} style={{left: 13}}>拡大</div>
                                <Button style={{ height: floorMenuButtonSize / 2 - 2, width: floorMenuButtonSize, minHeight: floorMenuButtonSize / 3, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickZoomButton(2.0) }} />
                            </div>
    
                            <div>
                                <ZoomOutIcon className={classes.floorMenuHalfImgBottom} style={{ color: 'white' }} />
                                <div className={classes.floorMenuTextHalfBottom} style={{left: 13}}>縮小</div>
                                <Button style={{ height: floorMenuButtonSize / 2 - 2, width: floorMenuButtonSize, minHeight: floorMenuButtonSize / 3, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickZoomButton(0.5) }} />
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                            <div style={{ marginBottom: 4 }}>
                                <img src={FitIcon} className={classes.floorMenuHalfImgTop} alt="" />
                                <div className={classes.floorMenuTextHalfTop}>拡大</div>
                                <ThemeProvider theme={buttonTheme}>
                                    <Button style={{ height: floorMenuButtonSize / 2 - 2, width: floorMenuButtonSize, minHeight: floorMenuButtonSize / 3, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickZoomButton(2.0) }} />
                                </ThemeProvider>
                            </div>
    
                            <div>
                                    <img src={FitIconInv} className={classes.floorMenuHalfImgBottom} alt="" />
                                    <div className={classes.floorMenuTextHalfBottom} >縮小</div>
                                <ThemeProvider theme={buttonTheme}>
                                    <Button style={{ height: floorMenuButtonSize / 2 - 2, width: floorMenuButtonSize, minHeight: floorMenuButtonSize / 3, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickZoomButton(0.5) }} />
                                </ThemeProvider>
                            </div>
                        </div>
                    )
                }
        }
    }

    const drawFitButton = useMemo(() => {
        if (isDisabledFitScale) {
            return (
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <img src={FitIconGrey} className={classes.floorMenuFitImg} alt="" />
                    <div className={classes.floorMenuText} style={{ color: 'grey' }}  >拡大！</div>
                    <Button style={{ height: floorMenuButtonSize / 2, width: floorMenuButtonSize, minHeight: floorMenuButtonSize / 2, minWidth: floorMenuButtonSize }} variant="contained" />
                </div>
            )
        } else {
            return (
                <>
                {colors()}
                </>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scale, isDisabledFitScale, props.isiPhoneLayout])

    {/**
    const drawFitButton = useMemo(() => {
        return (
            <Fragment>
            {isDisabledFitScale ?
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <img src={FitIconGrey} className={classes.floorMenuFitImg} alt="" />
                <div className={classes.floorMenuText} style={{ color: 'grey' }}  >拡大</div>
                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" />
                </div>
                :
                props.scale !== 1 ?
                        <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                            <img src={FitIconInv} className={classes.floorMenuFitImg} alt="" />
                            <div className={classes.floorMenuText} >通常倍率</div>
                            <ThemeProvider theme={buttonTheme}>
                                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='secondary' onClick={() => { handleClickFitButton(false) }} />
                                </ThemeProvider>
                                </div>
                                :
                                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                                <img src={FitIcon} className={classes.floorMenuFitImg} alt="" />
                                <div className={classes.floorMenuText} >拡大</div>
                                <ThemeProvider theme={buttonTheme}>
                                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickFitButton(true) }} />
                                </ThemeProvider>
                                </div>
                            }
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scale, isDisabledFitScale, props.isiPhoneLayout])
*/}

    const drawOverlookButton = useMemo(() => {
        // 全体ボタン非表示に設定されている場合、表示しない
        if (!props.enabledOverlookButton) return;

        return (
            <Fragment>
                {isDisabledOverlook ?
                    <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                        <img src={FitIconInvGrey} className={classes.floorMenuFitImg} alt="" />
                        <div className={classes.floorMenuText} style={{ color: 'grey' }}>縮小</div>
                            <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" />
                    </div>
                    :
                    props.scale !== 1 ?
                        <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                            <img src={FitIcon} className={classes.floorMenuFitImg} alt="" />
                            <div className={classes.floorMenuText} >通常倍率</div>
                            <ThemeProvider theme={buttonTheme}>
                                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='secondary' onClick={() => { handleClickOverlookButton(false) }} />
                            </ThemeProvider>
                        </div>
                        :
                        <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                            <img src={FitIconInv} className={classes.floorMenuFitImg} alt="" />
                            <div className={classes.floorMenuText} >縮小</div>
                            <ThemeProvider theme={buttonTheme}>
                                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={() => { handleClickOverlookButton(true) }} />
                            </ThemeProvider>
                        </div>
                }
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scale, isDisabledOverlook, props.enabledOverlookButton, props.isiPhoneLayout])

    const drawReconnectHelloButton = useMemo(() => {
        
        // 機能が有効でないならボタンを表示しない
        if(!props.enableReconnectHelloButton) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                    <DescriptionIcon className={classes.floorMenuDiscriptionImg} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} style={{ color: 'white' }} >ファイルの<br />再読み込み</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={handleClickDiscription} />
                    </ThemeProvider>
                </Fragment>
            </div >
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enableReconnectHelloButton, props.isiPhoneLayout])

    const drawDescriptionButton = useMemo(() => {
        
        // #11252 ビデオ通話をしていない場合はボタンを表示しない
        if(!props.isWebRtcConnecting) return;

        // 機能が有効でないならボタンを表示しない
        if(!props.enabledWhiteBoardButton) return;

        // Helloが起動済みの場合はボタンを表示しない
        if (props.enableReconnectHelloButton) return;

        // 課題管理 #1695 一時的にiphone AndroidでmoreNoteHelloをふさぐ
        // iPhone/Androidの時は、
        // 会議室/打ち合わせスペース/アバター同士の会話のとき、「ファイル表示」「ホワイトボード」ボタンを隠す
        // if(WebrtcService.isAndroid() || WebrtcService.isiOS()) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                    <DescriptionIcon className={classes.floorMenuDiscriptionImg} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} style={{ color: 'white' }} >ファイル表示</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' onClick={handleClickDiscription} />
                    </ThemeProvider>
                </Fragment>
            </div >
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isWebRtcConnecting, props.enabledWhiteBoardButton, props.enableReconnectHelloButton, props.isiPhoneLayout])

    const drawWhiteBoardButton = useMemo(() => {

        // #11252 ビデオ通話をしていない場合はボタンを表示しない
        if(!props.isWebRtcConnecting) return;

        // 機能が有効でないならボタンを表示しない
        if(!props.enabledWhiteBoardButton) return;

        // Helloが起動済みの場合はボタンを表示しない
        if (props.enableReconnectHelloButton) return;

        // 課題管理 #1695 一時的にiphone AndroidでmoreNoteHelloをふさぐ
        // iPhone/Androidの時は、
        // 会議室/打ち合わせスペース/アバター同士の会話のとき、「ファイル表示」「ホワイトボード」ボタンを隠す
        // if(WebrtcService.isAndroid() || WebrtcService.isiOS()) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <img src={WhiteBoardIcon} className={classes.floorMenuWhiteBoardImg} />
                <div className={classes.floorMenuWhiteBoardText} >ホワイト<br/>ボード</div>
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickWhiteBoard} />
                </ThemeProvider>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isWebRtcConnecting, props.enabledWhiteBoardButton, props.enableReconnectHelloButton, props.isiPhoneLayout]);

    const drawMyLocationButton = useMemo(() => {
        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                <MyLocationIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <div className={classes.floorMenuText} >自分の<br/>場所</div>
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickMylocation} />
                </ThemeProvider>
                </Fragment>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isiPhoneLayout]);

    const drawFloorButton = useMemo(() => {
        return (
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <img src={FloorIcon} className={classes.floorMenuFloorImg} />
                    <div className={classes.floorMenuText} >フロア移動</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickMoveFloor} />
                    </ThemeProvider>
                </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isiPhoneLayout,props.myUserPrivacyRoomInfo]);

    const drawLeaveRoomButton = useMemo(() => {
        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <img src={LeaveIcon} className={classes.floorMenuFloorImg} />
                <div className={classes.floorMenuText} >退室</div>
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickLeaveFloor} />
                </ThemeProvider>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isiPhoneLayout,props.myUserPrivacyRoomInfo]);

    const drawMenuInterval = useMemo(() => {
        // グループ分けのためメニュー間の挿入するスペース
        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: props.isiPhoneLayout? 6:10, marginBottom: 5 }}>
                <p></p>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isiPhoneLayout]);

    const drawMemoButton = useMemo(() => {

        // メモ機能が有効でないならボタンを表示しない
        if(!props.enabledMemoButton || isSubIdEmpty) return;
        
        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Badge
                    invisible={unreadMemoCount === 0}
                    badgeContent={" "}
                    color="error"
                    overlap="circle"
                >
                    <MailIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >メモ確認</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={handleClickCheckMemo} />
                    </ThemeProvider>
                </Badge>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledMemoButton, isSubIdEmpty, unreadMemoCount, props.isiPhoneLayout]);

    const drawSearchButton = useMemo(() => {

        // メモ機能が有効でないならボタンを表示しない
        if(!props.enabledMemoButton || isSubIdEmpty) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
            {openSearch ?
                <Fragment>
                <SearchIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <div className={classes.floorMenuText} >人を探す</div>
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="secondary" variant="contained" onClick={() => { handleClickSearch(false) }} />
                </ThemeProvider>
                </Fragment>
                :
                <Fragment>
                {/* ボタン活性 (ミュート) */}
                <SearchIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <div className={classes.floorMenuText} >人を探す</div>
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => { handleClickSearch(true) }} />
                </ThemeProvider>
                </Fragment>
            }
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openSearch, props.enabledMemoButton, isSubIdEmpty, props.isiPhoneLayout]);

    const drawScreenShareButton = useMemo(() => {

        // #11252 ビデオ通話をしていない場合はボタンを表示しない
        if(!props.isWebRtcConnecting) return;

        // 画面共有機能が有効でないならボタンを表示しない
        if (!props.enabledScreenShareButton) return;

        return (
            // #761 共有ウインド選択のバッティング対応
            //props.isTrafficLimitVideoMute ?
            (props.isTrafficLimitVideoMute || isScreenShareWindowSelecting || props.isiPhoneLayout) ?
                // 通信量制限によるカメラ使用制限中の場合はボタンを非活性
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <ScreenShareIcon className={classes.floorMenuMyLocation}  />
                    <div className={classes.floorMenuText} >画面共有</div>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor: 'gray' }} disabled variant="contained" />
                </div>
            : isScreenShare ?
                // 画面共有中
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <StopScreenShareIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >共有停止</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="secondary" variant="contained" onClick={() => handleClickScreenShare(false)} />
                    </ThemeProvider>
                </div>
            :
                // 画面共有前
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <ScreenShareIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >画面共有</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => handleClickScreenShare(true)} />
                    </ThemeProvider>
                </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isWebRtcConnecting, props.enabledScreenShareButton, props.isTrafficLimitVideoMute, isScreenShare, props.isiPhoneLayout, isScreenShareWindowSelecting]);
    
    const drawMoveFloorDialog = useMemo(() => {
        return (
            <MoveFloorRef ref={moveFloorRef} open={showMoveFloorDialog} floorId={props.floorId} onMoveFloor={handleMoveFloor} myRole={props.myRole} myState={props.myState} onJumpToMySeat={props.onJumptoMySeat} onStateChange={props.onStateChange} requestPrivacyRoom={props.requestPrivacyRoom} sendPrivacyRoomTimeOut={props.sendPrivacyRoomTimeOut} sendMovePrivacyRoomInfo={props.sendMovePrivacyRoomInfo}></MoveFloorRef>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.floorId, showMoveFloorDialog, props.isiPhoneLayout])

    const drawZoomMicButton = useMemo(() => {
        if (!props.enabledZoomMicButton) return;

        return (
            isZoomMicMute
                ?
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <Mic className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >マイク</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => handleClickZoomMicMute(false)} />
                    </ThemeProvider>
                </div>
                :
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <MicOff className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >マイクオフ</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="secondary" variant="contained" onClick={() => handleClickZoomMicMute(true)} />
                    </ThemeProvider>
                </div>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledZoomMicButton, isZoomMicMute, props.isiPhoneLayout]);

    const drawZoomCamButton = useMemo(() => {
        if (!props.enabledZoomCamButton) return;

        return (
            isZoomCamMute
                ?
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <Video className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >カメラ</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => handleClickZoomCamMute(false)} />
                    </ThemeProvider>
                </div>
                :
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <VideoOff className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >カメラオフ</div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="secondary" variant="contained" onClick={() => handleClickZoomCamMute(true)} />
                    </ThemeProvider>
                </div>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledZoomCamButton, isZoomCamMute, props.isiPhoneLayout]);

    // !見本市対応で作成したが不使用
    /* const drawZoomScreenShareButton = useMemo(() => {
        if (!props.enabledZoomScreenShareButton) return;

        return (
            isZoomScreenShare
                ?
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <StopScreenShareIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >画面共有オフ</div>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor: '#00d4be' }} color="secondary" variant="contained" onClick={() => handleClickZoomScreenShare(false)} />
                </div>
                :
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <ScreenShareIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >画面共有</div>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => handleClickZoomScreenShare(true)} />
                </div>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledZoomMicButton, isZoomScreenShare]); */

    // !見本市対応で作成したが不使用
    /* const drawZoomFullScreenButton = useMemo(() => {
        if (!props.enabledZoomFullScreenButton) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <FullscreenIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <div className={classes.floorMenuText} >全画面表示</div>
                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => handleClickZoomFullScreen()} />
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledZoomMicButton]); */

    const drawZoomButtons = useMemo(() => {
        if (props.isWebRtcConnecting || !isZoomSpeakable) return;
        const top = 20, left = 6;
        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: "right", zIndex: ZIndex.fixedUiLayer }}>
                <Grid
                    container
                    justify="center"
                    style={{
                        position: "absolute",
                        top: -1 * top,
                        left: -1 * left,
                        width: `calc(100% + ${left}px)`,
                        height: `calc(100% + ${top}px)`,
                        borderRadius: 8,
                        //background: "rgba(255,255,255, 0.7)",
                    }}>
                    {/* <span style={{ color: "black", fontWeight: "bold" }}>Zoom</span> */}
                </Grid>
                {/* {drawZoomFullScreenButton} */}
                {/* {drawZoomScreenShareButton} */}
                {drawZoomCamButton}
                {drawZoomMicButton}
            </div>
        )
    }, [props.isWebRtcConnecting, isZoomSpeakable, drawZoomMicButton, drawZoomCamButton, /* drawZoomScreenShareButton, drawZoomFullScreenButton */]);

    const drawGridToggle = useMemo(() => {
        // 機能が有効でないならボタンを表示しない
        //if(!props.enabledWhiteBoardButton) return;
        if(!props.enabledFloorEditMode) return;

        var isEnable = props.enabledGridMode ? "有効" : "無効";

        return (
            props.gridRange != 1 ?
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                {props.gridRange == 1 ? <GridOffIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }}/> : <GridOnIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }}/>}
                <div className={classes.floorMenuText} >グリッドスナップ</div>
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={props.toggleGrid} />
                </ThemeProvider>
                </Fragment>
            </div>
            :
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                
                {props.gridRange == 1 ? <GridOffIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }}/> : <GridOnIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }}/>}
                <div className={classes.floorMenuText} >グリッドスナップ</div>
                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor:'grey' }} variant="contained" onClick={props.toggleGrid}
                />
                </Fragment>
            </div>

        );
        // <MyLocationIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
    }, [props.enabledFloorEditMode, props.enabledGridMode, props.gridRange]);

    const drawNewFloorObject = useMemo(() => {

        // 機能が有効でないならボタンを表示しない
        if(!props.enabledFloorEditMode) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                <div className={classes.floorMenuText} >オブジェクト追加</div>
                <AddBoxIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={props.openNewFloorObjectWindow} />
                </ThemeProvider>
                </Fragment>
            </div>
        );
        // <MyLocationIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
    }, [props.enabledFloorEditMode]);

    const drawDeleteFloorObject = useMemo(() => {
        // 機能が有効でないならボタンを表示しない
        if(!props.enabledFloorEditMode) return;

        return (
            props.isFloorObjSelected
            ?
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                
                <div className={classes.floorMenuText} >オブジェクト削除</div>
                <DeleteIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={props.handleDeleteFloorObject}/>
                </ThemeProvider>
                </Fragment>
            </div>
            :
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                
                <div className={classes.floorMenuText} >オブジェクト削除</div>
                <DeleteIcon className={classes.floorMenuMyLocation} style={{ color: 'white'}} />
                <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor:'grey' }} variant="contained"/>
                </Fragment>
            </div>
        );
    }, [props.enabledFloorEditMode, props.isFloorObjSelected]);

    // フロアエディター終了ボタン
    const drawExitFloorEditor = useMemo(() => {
        // 機能が有効でないならボタンを表示しない
        if(!props.enabledFloorEditMode) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                
                    <div className={classes.floorMenuText} >フロアエディター終了</div>
                    <ViewQuiltIcon className={classes.floorMenuMyLocation} style={{ color: 'white'}} />
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize}} color="primary" variant="contained" onClick={() => props.floorEditorRef.current?.toggleFloorEdit()}/>
                    </ThemeProvider>
                </Fragment>
            </div>
        );
    }, [props.enabledFloorEditMode, props.isFloorObjSelected]);

    const changeShowToolLauncher = () => {
        props.changeShowToolLauncher();
        setOpenToolLauncher(!openToolLauncher);
    }

    const drawToolLauncher = useMemo(() => {
        // 機能が有効でないならボタンを表示しない
        if(!props.enabledToolLauncherButton) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                    <div className={classes.floorMenuText} >リンク</div>
                    <LaunchIcon className={classes.floorMenuMyLocation} style={{ color: 'white'}} />
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize,minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize, backgroundColor: openToolLauncher ? "secondary" : 'primary'}} color={openToolLauncher ? "primary" : "secondary"} variant="contained" onClick={changeShowToolLauncher}/>
                    </ThemeProvider>
                </Fragment>
            </div>
        );
    }, [props.enabledToolLauncherButton, openToolLauncher, props.changeShowToolLauncher]);

    const handleClickGroupChat = (open: boolean) => {
        setOpenGroupChat(open);
        props.openGroupChat(-1, "", 0, null, [], open);
    }

    const drawChatButton = useMemo(() => {

        // メモ機能が有効でないならボタンを表示しない
        if(!props.enabledMemoButton || isSubIdEmpty) return;
        
        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Badge
                    invisible={unreadMemoCount === 0}
                    badgeContent={" "}
                    color="error"
                    overlap="circle"
                >
                {openGroupChat ?
                    <Fragment>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="secondary" variant="contained" onClick={() => { handleClickGroupChat(false) }} />
                    </ThemeProvider>
                    <MessageIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >チャット</div>
                    </Fragment>
                    :
                    <Fragment>
                    {/* ボタン活性 (ミュート) */}
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => { handleClickGroupChat(true) }} />
                    </ThemeProvider>
                    <MessageIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >チャット</div>
                    </Fragment>
                }
                </Badge>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openGroupChat, props.enabledMemoButton, isSubIdEmpty, unreadMemoCount, props.isiPhoneLayout]);

    const drawExternalUrl = useMemo(() => {
        
        if (!props.enabledExternalUrl) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                    <DescriptionIcon className={classes.floorMenuFitImg} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} style={{ color: 'white' }} >外部教材</div>
                    <ThemeProvider theme={buttonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} variant="contained" color='primary' 
                        onClick={() => {window.open(props.externalUrl, "_blank")}} />
                    </ThemeProvider>
                </Fragment>
            </div >
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enabledExternalUrl, props.externalUrl, props.isiPhoneLayout])

    // 自習タイマーフロアメニュー
    const drawSelfStudyTimerButton = useMemo(() => {

        // 自習席に着いていないならボタンを表示しない
        // 現状バックエンドから自習席に着席したかどうかの情報が取得できていないので常時表示
        if(!props.isSelfStudyTable || !props.isStudy) return;

        return (
            timerState ?
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <Fragment>
                    {/* <AlarmOff className={classes.floorMenuMyLocation} style={{ color: 'white' }} /> */}
                    <Timer className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >タイマー<br/>一時停止</div>
                    <ThemeProvider theme={studyButtonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => { handleClickSelfStudyTimer(false,0) }} />
                    </ThemeProvider>
                    </Fragment>
                </div>
            :
                <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                    <Fragment>
                    {/* <AlarmOn className={classes.floorMenuMyLocation} style={{ color: 'white' }} /> */} 
                    <Timer className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                    <div className={classes.floorMenuText} >タイマー<br/>再開</div>
                    <ThemeProvider theme={studyButtonTheme}>
                        <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="secondary" variant="contained" onClick={() => { handleClickSelfStudyTimer(true,0) }} />
                    </ThemeProvider>
                    </Fragment>
                </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelfStudyTable, props.isStudy, props.isiPhoneLayout, timerState]);

    // 自習計画変更フロアメニュー
    const drawSelfStudyCheckButton = useMemo(() => {

        // 自習席に着いていないならボタンを表示しない
        // 現状バックエンドから自習席に着席したかどうかの情報が取得できていないので常時表示
        if(!props.isSelfStudyTable || !props.isStudy) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                <LoopIcon className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <div className={classes.floorMenuText} >目標の<br/>確認/変更</div>
                <ThemeProvider theme={studyButtonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => { handleClickSelfStudyCheck() }} />
                </ThemeProvider>
                </Fragment>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelfStudyTable, props.isStudy, props.isiPhoneLayout]);

    // 自習計画完了用フロアメニュー
    const drawSelfStudyLookBackButton = useMemo(() => {

        // 自習席に着いていないならボタンを表示しない
        // 現状バックエンドから自習席に着席したかどうかの情報が取得できていないので常時表示
        if(!props.isSelfStudyTable || !props.isStudy) return;

        return (
            <div style={{ ...SELECT_NONE, position: "relative", float: 'right', zIndex: ZIndex.fixedUiLayer, marginRight: 5, marginBottom: 5 }}>
                <Fragment>
                <AssignmentTurnedIn className={classes.floorMenuMyLocation} style={{ color: 'white' }} />
                <div className={classes.floorMenuText} >自習を終了</div>
                <ThemeProvider theme={studyButtonTheme}>
                    <Button style={{ height: floorMenuButtonSize, width: floorMenuButtonSize, minHeight: floorMenuButtonSize, minWidth: floorMenuButtonSize }} color="primary" variant="contained" onClick={() => { handleClickSelfStudyLookBack() }} />
                </ThemeProvider>
                </Fragment>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelfStudyTable, props.isStudy, props.isiPhoneLayout]);

    return (
        <Fragment>
            <div style={{ ...SELECT_NONE, position: "fixed", zIndex: ZIndex.fixedUiLayer, bottom: props.isiPhoneLayout? 6:17, right: props.isiPhoneLayout? 10:17 }}>
                {drawSignoutButton}
                {drawMenuInterval}
                {drawMyLocationButton}
                {!props.privacyRoom ? drawFloorButton : drawLeaveRoomButton}
                {/*drawOverlookButton*/}
                {drawFitButton}
                {drawChatButton}
                {/*drawMemoButton*/}
                {drawToolLauncher}
                {drawSearchButton}
                {drawExternalUrl}
                {drawMyRoomButton}
                {drawMenuInterval}
                {drawMuteButton}
                {drawVideoMuteButton}
                {drawWhiteBoardButton}
                {drawDescriptionButton}
                {drawReconnectHelloButton}
                {drawScreenShareButton}
                {drawZoomButtons}
                {drawMenuInterval}
                {drawSignoutDialog}
                {drawMoveFloorDialog}
                {drawNewFloorObject}
                {drawGridToggle}
                {drawDeleteFloorObject}
                {drawExitFloorEditor}
                {drawSelfStudyLookBackButton}
                {drawSelfStudyCheckButton}
                {drawSelfStudyTimerButton}
            </div>
            <div style={{ ...SELECT_NONE, position: "fixed", bottom: 17, left: 17 }}>
                {drawTutorialButton}
            </div>
        </Fragment >
    )
}

export const FloorMenu = forwardRef(FloorMenuComponent);
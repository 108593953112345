import React, { Fragment } from 'react';
import StaticKanban, { Props as StaticKanbanProps} from './StaticKanban';

interface Props extends StaticKanbanProps {
    noBorder: boolean,
}

export default function StaticKanbanNoBorder(props: Props) {
    return (
        <Fragment>
            <StaticKanban {...props}/>
        </Fragment>
    );
}
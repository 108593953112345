import { Logger } from "../common/Logger";
import WebrtcService from "../webrtc/WebrtcService";
import NoSleep from 'nosleep.js'
import axios, { AxiosResponse } from 'axios';

/**
 * iPad対応-3 iPad対応-21
 * npSleepの制御を行うクラス
 */
export class NoSleepWrapper {
    private static noSleep: any = new NoSleep();
    private static handleNoSleepTimer: any = null; // 周期確認用タイマーハンドル（ログ用）
    private static noSleepBeforeState:boolean = false; // 周期確認用 前回状態（ログ用）

    private constructor() {
    }

    /**
     * noSleep init処理
     */
    public static initNoSleep(): void {
        if(WebrtcService.isiOS() === true){
            if(this.handleNoSleepTimer === null) {
                this.traceSend("NOSLEEP : initNoSleep")
                //window.addEventListener('touchstart', () => {this.onTapTouchStart()}, false);
                window.addEventListener('click', () => {this.onTapClick()}, false);

                // iPad対応-3 周期でnoSleep状態確認（ログ用）
                this.handleNoSleepTimer = setInterval(() => {
                    let nState = this.noSleep.isEnabled;
                    //this.traceSend("NOSLEEP : Interval check before ["+this.noSleepBeforeState+"] now ["+nState+"]");
                    if(nState !== this.noSleepBeforeState) {
                        this.traceSend("NOSLEEP : Interval check CHANGE ["+this.noSleepBeforeState+"] --> ["+nState+"]");
                        // ここで再設定したいが、ユーザー操作を伴わないので意味がない
                    }
                    this.noSleepBeforeState = nState;
                }, 30*1000);
                
            } else {
                this.traceSend("NOSLEEP : already set interval");
            }
        }
    }

    /**
     * noSleep終了処理
     */
     public static finishNoSleep(): void {
        if(WebrtcService.isiOS() === true){
            this.traceSend("NOSLEEP : finishNoSleep");
            this.noSleep.disable();
            this.noSleepBeforeState = false;
        }
    }

    /**
     * noSleep有効化
     */
     public static enableNoSleep(): void {
        if(WebrtcService.isiOS() === true){
            this.noSleep.enable();
        }
    }

    /**
     * noSleep無効化
     */
    public static desableNoSleep(): void {
        if(WebrtcService.isiOS() === true){
            this.noSleep.disable();
        }
    }

    // iPad対応-3 タップしたらnoSleepのチェックを行い、enableでなければenableにする
    // ユーザー操作のタイミングでenableにしないと反応しない（と思われる）ので、ここで実施
    // サインイン後何もしないと、noSleepがenableになっていない問題が残る
    // また、放置していてnoSleepがdesableになることがあれば、これもこの対応では回避できない
    // sleepしかけて、タップすることで、noSleep有効状態になる
    //　　 --> ならない！ スリープ前の輝度落としたカスケード状態はタップ拾えない？
    public static onTapClick(): void {
        if(WebrtcService.isiOS() === true){
            this.traceSend("NOSLEEP : onTapClick isEnabled check ["+this.noSleep.isEnabled+"]");
            if(this.noSleep.isEnabled == false) {
                this.noSleep.enable();
                this.traceSend("NOSLEEP : onTapClick isEnabled false and set enable");
            } else {
                // イヤホンを外した時、isEnabledがtreだがスリープするので、毎回disable＆enableする
                this.noSleep.disable();
                this.noSleep.enable();
                //this.traceSend("NOSLEEP : onTapClick set disable --> enable");
            }
        }
    }
    
    /**
     *  トレースログ:サーバーへ送信 デバッグ用
     * 
     */
     private static traceSend = (msg: string) => {
        let logger: Logger = Logger.getInstance();
        logger?.info(msg);
        /*
        var params = new URLSearchParams();
        //params.append("tab_id", sessionStorage.getItem("TABID") as string);
        params.append('tracemsg', msg);
        axios.post('/api/user/log/tracelogforce', params)
        .then((e: AxiosResponse) => {
        }).catch((e) => {
            console.log("traceSend error:"+e)
        });
        */
    }

}

export default NoSleepWrapper;
import JfsClient,{ MonthlyPointInfo, PointData, PointHistoryInfo, PointHistoryResponse, PointSummaryResponse, Profile, StudySubjectMasterInfo, User, UserProfile } from "@fsi/jfs-sdk";
import { Backdrop, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Slide, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Tabs, TextField, Theme, ThemeProvider, Typography, createMuiTheme, createStyles, makeStyles } from "@material-ui/core";
import { SELECT_NONE } from "../common/Constants";
import { Fragment, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import {
    Chart,
    BarSeries,
    ArgumentAxis,
    ValueAxis,
    Legend,
} from '@devexpress/dx-react-chart-material-ui'; // 2.7.6をインストールすること

// icons
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { UserPointInfo } from "./UserInfoCard";
import EditIcon from '@material-ui/icons/Edit';
import ImportExport from '@material-ui/icons/ImportExport';
import BarChart from '@material-ui/icons/BarChart';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ScaleObject, Stack, ValueScale } from "@devexpress/dx-react-chart";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import BaseDialog from "./BaseDialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        
        dialogTitle: {
            //maxWidth: tutorialWidth,
            background: '#57BBFF 0% 0% no-repeat padding-box',
        },
        
        tutorialFont: {
            //fontFamily: tutorialFont,
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        },
        avatarImg: {
            border: '4px solid #57BBFF',
            paddingLeft: 20,
            paddingRight: 20,
            // width: '100%',
            // height: '100%',
            // minWidth: 160,
        },
        pointStackContainer: {
            padding: 10,
            height: '100%',
        },
        chartBox: {
            height: 180,
            borderRadius: 1,
            // paddingTop: 50
        },
        settingTitle:{
            fontWeight:'bold', 
            fontSize: 16,
            fontFamily: 'Hiragino Maru Gothic StdN',
        },
        picker: {
            width: 150,
            height: 30,
            fontSize: 24,
            textAlign: 'center',
            margin: 10,
            marginLeft: 10,
        },
        grid: {
            margin: "auto",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 2,
            color: '#fff',
          },
          card: {
            width: 150,
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
  })

const bodyStyle2: React.CSSProperties = {
    ...SELECT_NONE,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
};

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;
  
    constructor(init: Partial<FinishInfo>) {
      Object.assign(this, init);
    }
  }
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

interface Props {
    className?: string,
    userProfile: Profile[],
    user: User,
    open: boolean,
    ping: number,
    zIndex: number,
    onClickRefresh: () => void,
    onClickClose: () => void,
    handleClickEditProfile: (event: any) => void,
    isMyUser: boolean,
    // isLarge: boolean,
    // enabledBusinessCard: boolean,
    // kind:string | null,
    // isMyUser: boolean,
    // floorSize: Size,
}


export default function MyPageCard({
    className,
    user,
    userProfile,
    open,
    ping,
    zIndex,
    onClickRefresh,
    onClickClose,
    handleClickEditProfile,
    isMyUser
}: Props) {
    // const [userProfile, setUserProfile] = useState([] as Profile[]);
    const [content, setContent] = useState('default');
    const [userPointInfo, setUserPointInfo] = useState(new UserPointInfo());
    const [userPointThisMonth, setUserPointThisMonth] = useState({
        point: 0,
        position: 0 
    }); //左
    const [userAllAdd, setUserAllAdd] = useState({
        point: 0,
        position: 0 
    }); //右
    const [summaryPoint, setSummaryPoint] = useState(0); // 真ん中
    const [userMonthlyPointsList, setMonthlyPointsList] = useState([] as MonthlyPointInfo[]);
    const [pointHistory, setPointHistory] = useState(new PointHistoryResponse());
    const [historyList, setHistoryList] = useState([] as PointHistoryInfo[]);
    const [page, setPage] = useState(0);
    const [additionalSummary, setAdditionalSummary] = useState(0);
    const [dispPoint, setDispPoint] = useState(false);
    const [historyLength, setHistoryLength] = useState({ length: 0, });
    // const [introInput, setIntroInput] = useState("");
    // const [isShowIntroEditDialog, setShowIntroEditDialog] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectDate, setSelectDate] = useState(new Date());
    const maxDate = new Date();
    const [totalHour, setTotalHour] = useState(0);
    const [totalMinute, setTotalMinute] = useState(0);
    const [maxMinute, setMaxMinute] = useState(180);
    const [subjectsList, setSubjectsList] = useState([] as StudySubjectMasterInfo[]);
    const [subjects, setSubjects] = useState([] as string[]);
    const [studyHistoryList, setStudyHistoryList] = useState([] as any[]);
    const [showBackdropForDowndating, setShowBackdropForDowndating] = useState(false);
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));


    const rowsPerPage = 7;

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const classes = useStyles();

    const divStyle = {
        border: '1px solid #ccc', // 枠線
        padding: '15px',           // 内側の余白
        margin: '5px',            // 外側の余白
        width: '100%',           // 幅
        height: 'auto',          // 高さ（自動）
        borderRadius: '5px',    // 角の丸み
        fontSize:'15px'
      };

    const avatarImgUrl = useMemo(() => {
        return `${httpClient.createAvatarImgUrl(user.avatarId)}`
    }, [user.avatarId]);

    const networkSpeed = useMemo(() => {
        if (ping <= 100) {
          return "高速";
        } else if (ping <= 1000) {
          return "中速";
        } else {
          return "低速";
        }
    
    }, [ping]);

    useEffect(() => {
        if (open) {
            if (user.role === "ROLE_USER") setDispPoint(true);
        //   setShowIntroEditDialog(false);
        //   getProfile();
            getUserPoint();
            getAllUserPointsThisMonth();
            getUserMonthlyPoints();
            getUserPointHistory();
            getSubject();
            getStudyHistory(0, selectDate);
            // setContent('default');
        }
    }, [open, user.subId])

    const handleClickCard = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        // onClickClose();  // ドラッグできるようにするため、閉じない
    }

    // 順位から背景色のカラーコードを返す
    const positionToColor = (position: number): string => {
        var color = 'white';
        switch (position) {
            case 1: // １位
                color = '#ffecb3';
                break;
            case 2: // ２位
                color = '#cfd8dc';
                break;
            case 3: // ３位
                color = '#bcaaa4';
                break;
            default:
                break;
        }
        return color;
    }

    // contentに応じてダイアログのタイトルを取得
    const getDialogTitle = (): string => {
        switch (content) {
            case 'pointDetail':
                return "マイページ ＞ ポイント";
            case 'studyHistory':
                return "マイページ ＞ 自習履歴";
            default:
                return user.lastName + " " + user.firstName + " (" + user.displayName + ") さんのマイページ";
        }

    }

    // 閉じる時にマイページに戻しておく
    const handleClickClose = () => {
        setContent('default');
        setTabIndex(0);
        getStudyHistory(0, selectDate);
        setSelectDate(new Date());
        onClickClose();
    }

    const handleClickBack = () => {
        setContent('default');
        setTabIndex(0);
        getStudyHistory(0, selectDate);
        setSelectDate(new Date());
    }

    // 表示デバイスチェック
    const isSmartPhone = (): boolean =>{
        // スマホの場合はtrueを返却
        const ua = window.navigator.userAgent.toLowerCase();
        if( ua.indexOf('iphone') > -1 || ua.indexOf('android') > -1){
            return true;
        }
        return false;
    }

    // マイページ情報更新処理
    const handleClickRefresh = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (!open) return;
        if (user.role === "ROLE_USER") setDispPoint(true);
        onClickRefresh();
        // getProfile();
        getUserPoint();
        getAllUserPointsThisMonth();
        getUserMonthlyPoints();
        getUserPointHistory();
    }

    // ユーザーのポイント取得
    const getUserPoint = () => {
        httpClient.getUserPoint(sessionStorage.getItem("TABID") as string, user.subId)
            .then((e: UserPointInfo) => {
                if(e != null){
                    setUserPointInfo(e);
                    setSummaryPoint(e.additionalSummary + e.deductionalSummary);
                    setAdditionalSummary(e.additionalSummary);
                }
            }).catch(err => {
                setSummaryPoint(0);
                setAdditionalSummary(0);
                setDispPoint(false);
                console.log(err);
            });
    }

    // 所属テナントの月間ポイント一覧を取得
    const getAllUserPointsThisMonth = () => {
        httpClient.getAllUserPointsThisMonth(sessionStorage.getItem("TABID") as string, user.subId)
            .then((e: PointSummaryResponse) => {
                if(e.pointSummary != null){
                    var tempUserMonthlySummary = 0; // 月の取得ポイント合計
                    var monthlyPosition = e.pointSummary.length+1; //データがない場合は最下位と解釈し、サマリーの長さ＋１の順位
                    var tempUserAdditionalSummary = 0; //過去の取得ポイント合計
                    var allAdditionalSummaryPosition = 0; //過去の取得ポイント合計の順位

                    e.pointSummary.map((e) => {
                        if (e.loginId === user.loginId) {
                            tempUserMonthlySummary = e.monthlySummary;
                            tempUserAdditionalSummary = e.additionalSummary;
                        }
                    })
                    
                    // 集合を作成して月間ポイントのランキングを作成、自分のポイントが見つかればそのインデックス+1を順位とする
                    const tempMonthlySummary = Array.from(new Set(e.pointSummary.map((e) => e.monthlySummary)));
                    monthlyPosition = tempMonthlySummary.findIndex((e) => e === tempUserMonthlySummary) + 1;
                    setUserPointThisMonth({point: tempUserMonthlySummary, position: monthlyPosition});

                    // additionalSummaryでソート後の集合を作成、同じく自分のポイントが見つかればそのインデックス+1を順位とす
                    const tempAllAddSummary = Array.from(new Set(e.pointSummary.map((e) => e.additionalSummary).sort((a, b) => b -a)));
                    allAdditionalSummaryPosition = tempAllAddSummary.findIndex((e) => e === tempUserAdditionalSummary) + 1;
                    setUserAllAdd({point: tempUserAdditionalSummary, position: allAdditionalSummaryPosition});
                    // console.info(allAdditionalSummaryPosition);
                }
            }).catch(err => {
                console.log(err);
            });
    }

    // 所属テナントの月間ポイント一覧を取得
    const getUserMonthlyPoints = () => {
        httpClient.getUserMonthlyPoints(sessionStorage.getItem("TABID") as string, user.subId)
            .then((e: MonthlyPointInfo[]) => {
                setMonthlyPointsList(e.reverse());
            }).catch((err) => {
                console.error(err);
            });
    }

    const getUserPointHistory = () => {
        httpClient.getUserPointHistory(sessionStorage.getItem("TABID") as string, user.subId, pointHistory.endIndex)
            .then((e: PointHistoryResponse) => {
                const newLength = e.history.length;
                setPointHistory(e);
                setHistoryList(e.history);
                // setHistoryList([...historyList, ...e.history]);
                setHistoryLength({...historyLength, length: newLength,});
            })
            .catch((e) => {
                console.error(e);
            })
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
        // 過去の履歴追加データ取得タイミング
        if ((newPage + 1) * rowsPerPage + rowsPerPage > historyList.length){
            // まとめて取る方式に変わったため、再取得処理が不要になった
            // getUserPointHistory();
        }
        // console.info(newPage * rowsPerPage + rowsPerPage);
    };

    const timestampToTime = (timestamp: number) => {
        const date = new Date(timestamp);
        const yyyy = `${date.getFullYear()}`;
        // .slice(-2)で文字列中の末尾の2文字を取得する
        // `0${date.getHoge()}`.slice(-2) と書くことで０埋めをする
        const MM = `0${date.getMonth() + 1}`.slice(-2); // getMonth()の返り値は0が基点
        const dd = `0${date.getDate()}`.slice(-2);
      
        return `${yyyy}/${MM}/${dd}`;
    }

    // ページネーション
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleChangePage(event, page + 1);
    };

    const drawProfileText = (text: string) => {
        return (
            <Typography className={className} >
                {text}
            </Typography>
        )
    }
    const drawProfileTextBold = (text: string) => {
        return (
            <Typography className={className} >
                <b>{text}</b>
            </Typography>
        )
    }

    // 棒グラフの棒自体の見た目設定
    const getPath = (x:any, width:any, y:any, y1:any) => `M ${x} ${y1}
    L ${width + x} ${y1}
    L ${width + x} ${y}
    L ${x} ${y}
    Z`;

    const labelStyle = { fill: 'black' };

    // 棒のカスタマイズ
    const BarWithLabel = (props: any) => {
        const {arg, barWidth, maxBarWidth, val, startVal, color, value, style, index} = props;
        const width = maxBarWidth * barWidth;

        // 基本はpropsに渡されている色を指定するが、今月のデータだけ色を濃くしたい
        let barColor = index !== userMonthlyPointsList.length -1 ? color : '#ff6d00';
        return (
            <Fragment>
                <path d={getPath(arg - width / 2, width, val, startVal)} fill={barColor} style={style} />
                <Chart.Label
                    x={arg}
                    y={val - 8}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    style={labelStyle}
                >
                    {value}
                </Chart.Label>
            </Fragment>
        );
    };
    
    // AxisLabel(下のラベル)
    const AxisLabel = (props: any) => {
        const { text, x, y } = props;
        // スマホの場合はラベルのフォントサイズを下げる
        if(isSmartPhone()){
            return (
                <ArgumentAxis.Label style={{fontSize: '8px'}} {...props} y={y} text={text.split('-')[1] + '月'} />
            )
        }
        return (
            <ArgumentAxis.Label {...props} y={y} text={text.split('-')[1] + '月'} />
        )
    }
    
    // デフォルトのマイページ
    const drawMyPageContent = useMemo(() => {
        return (
            <Fragment>
                <Grid container> 
                    <Grid container item xs={6} alignItems="center" justify="center">
                        <img className={classes.avatarImg} src={avatarImgUrl}/>
                    </Grid>
                    <Grid container item xs={6} alignContent="flex-start">
                        <Grid item xs={6} style={{marginTop: 5}}>
                            {drawProfileText("氏名")}
                        </Grid>
                        <Grid item xs={6} style={{marginTop: 5}}>
                            {drawProfileText(user.lastName + " " + user.firstName)}
                        </Grid>
                        <Grid item xs={6} style={{marginTop: 5}}>
                            {drawProfileText("ネットワーク速度")}
                        </Grid>
                        <Grid item xs={6} style={{marginTop: 5}}>
                            {drawProfileText(networkSpeed)}
                        </Grid>
                        {
                            userProfile.map((profile, index) => {
                                if (profile.item === "" || profile.item == "自己紹介" || profile.value === "") return;
                                return (
                                    <Fragment>
                                        <Grid item xs={6} style={{marginTop: 5}}>
                                            {drawProfileText(profile.item)}
                                        </Grid>
                                        <Grid item xs={6} style={{marginTop: 5}}>
                                            {drawProfileText(profile.value)}
                                        </Grid>
                                    </Fragment>
                                )
                            })
                        }
                        {/* {drawProfile} */}
                    </Grid>
                </Grid>
                
                {/* <p style={{ marginLeft: 10 }}>プロフィール編集</p> */}
                <Grid container>
                    { isMyUser &&
                        <Grid 
                            container item 
                            xs={6} 
                            alignItems="center" 
                            justify="center"
                            style={{
                                minWidth: 180
                            }}
                        >
                            {drawProfileText("プロフィール編集")}
                            <IconButton 
                                size="small" 
                                onClick={handleClickEditProfile} 
                                onTouchEnd={handleClickEditProfile}
                            >
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    }
                    
                </Grid>
                <Container>
                    {/**
                    <TextField
                        fullWidth
                        multiline
                        InputProps={{ readOnly: true }}
                        value={userProfile.find(pf => pf.item == "自己紹介")?.value}
                        rowsMax={4}
                        variant="outlined"
                    />
                    TextFieldだと編集アイコンを押さずに編集できると勘違いしてしまう為、以下に変更。
                    */}
                    <div style={divStyle}>
                     {userProfile.find(pf => pf.item == "自己紹介")?.value}
                    </div>
                </Container>
                { user.role.indexOf('ROLE_ADMIN') === -1 &&
                    <Fragment>
                        <Button
                            onClick={() => setContent('pointDetail')}
                            // onTouchEnd={onClickClose}  
                            variant="outlined"
                            color="primary"
                            startIcon={<ImportExport />}
                            style={{ borderRadius: 30, margin: 20 }}
                        >
                            ポイント
                        </Button>
                        <Button
                            onClick={() => setContent('studyHistory')}
                            variant="outlined"
                            color="primary"
                            startIcon={<BarChart />}
                            style={{ borderRadius: 30 }}
                        >
                            自習履歴
                        </Button>
                    </Fragment>
                }
                
            </Fragment>
        )
    }, [open, user.x, user.y, ping, user.isCardDisclose, userProfile]);

    // ポイント詳細確認
    const drawMyPagePointDetailContent = useMemo(() => {
        return (
            <Fragment>
                {drawProfileTextBold('●あなたのポイント')}
                <Grid className={classes.pointStackContainer} container>
                    <Grid container item xs={4} alignItems="center" justify="center" 
                        style={{ backgroundColor: positionToColor(userPointThisMonth.position) }}
                    >
                        <p>今月の獲得ポイント</p>
                        <Grid container item xs={12} alignItems="center" justify="center">
                            <h1 style={{ margin: 0, paddingRight: 3 }}>{userPointThisMonth.point}</h1>pt     
                        </Grid>
                            { /* userPointThisMonth.position <= 3 &&    //一旦全ての順位を表示しておく */
                                <Typography>({userPointThisMonth.position}位)</Typography>
                            }
                    </Grid>
                    <Grid container item xs={4} alignItems="center" justify="center">
                        <p>利用可能ポイント</p>
                        <Grid container item xs={12} alignItems="center" justify="center">
                            <h1 style={{ margin: 0, paddingRight: 3 }}>{summaryPoint}</h1>pt
                        </Grid>
                                <Typography>&nbsp;</Typography>
                    </Grid>
                    <Grid container item xs={4} alignItems="center" justify="center"
                        style={{ backgroundColor: positionToColor(userAllAdd.position) }}
                    >
                        <p>累計獲得ポイント</p>
                        <Grid container item xs={12} alignItems="center" justify="center">
                            <h1 style={{ margin: 0, paddingRight: 3 }}>{userAllAdd.point}</h1>pt
                        </Grid>
                            { /* userAllAdd.position <= 3 &&    //一旦全ての順位を表示しておく */
                                <Typography>({userAllAdd.position}位)</Typography>
                            }
                    </Grid>
                </Grid>
                {drawProfileTextBold('●あなたのポイント獲得履歴')}
                <Box className={classes.chartBox}>
                    <Chart
                        data={userMonthlyPointsList}
                        height={140}
                    >
                        {/* <ArgumentAxis /> */}
                        <ArgumentAxis
                            labelComponent={AxisLabel}
                            // showTick={false}
                        />
                        <BarSeries
                            valueField="point"
                            argumentField="yearMonth"
                            color="#ffa726"
                            pointComponent={BarWithLabel}

                        />
                    </Chart>
                </Box>

                {/* component={Paper} */}
                <Paper style={{ width: '100%' }}>
                    <TableContainer >
                        <Table size="small">
                            <TableBody>
                                {(rowsPerPage > 0
                                ? historyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : historyList).map((e, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" style={{width: 130, textAlign: 'right'}} id="date">{timestampToTime(e.getDate)}</TableCell>
                                        <TableCell component="th" id="pointsettingname">{e.memo === "" ? e.pointSetting.pointSettingName : e.memo}</TableCell>
                                        <TableCell component="th" style={{textAlign: 'right'}} id="point">
                                            <div
                                                style={{color: e.point >= 0 ? '#f44336' : '#3f51b5'}}
                                            >
                                                {e.point}
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" style={{width: 40}} id="free"></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box style={{ margin: 'auto' }}>
                        <Grid container alignItems="center" justify="center" >
                        <IconButton
                            onClick={handleBackButtonClick}
                            disabled={page === 0}
                            aria-label="previous page"
                        >
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        {page + 1}/{Math.ceil(historyList.length / rowsPerPage)}
                        <IconButton
                            onClick={handleNextButtonClick}
                            disabled={page >= Math.ceil(historyList.length / rowsPerPage) - 1}
                            aria-label="next page"
                        >
                            <KeyboardArrowRightIcon />
                        </IconButton>
                        </Grid>
                    </Box>
                </Paper>
                
            </Fragment>
        )
    }, [open, user.x, user.y, ping, user.isCardDisclose, userProfile, 
         userPointThisMonth.position, userPointThisMonth.point, userAllAdd.position,
         userAllAdd.point, summaryPoint, pointHistory.history, pointHistory.endIndex,
         page, historyList, historyLength, userMonthlyPointsList]);

    
    // AxisLabel2(下のラベル)
    const AxisLabel2 = (props: any) => {
        const { text, x, y } = props;
        const date = new Date(text.split('-')[0], text.split('-')[1] - 1, text.split('-')[2]);
        const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
        const week = daysOfWeek[date.getDay()];
        // スマホの場合はラベルのフォントサイズを下げる
        if(isSmartPhone()){
            return (
                <Fragment>
                    <ArgumentAxis.Label style={{fontSize: '8px'}} {...props} y={y} text={text.split('-')[2] + '日'} />
                    <ArgumentAxis.Label style={{fontSize: '8px'}} {...props} y={y + 20} text={week} />
                </Fragment>
            )
        }
        return (
            <Fragment>
                <ArgumentAxis.Label style={{fontSize: '10px', fontWeight: 'bold'}} {...props} interval={2} y={y} text={text.split('-')[2] + '日'} />
                <ArgumentAxis.Label style={{fontSize: '10px', fontWeight: 'bold'}} {...props} interval={2} y={y + 20} text={week} />
            </Fragment>
        )
    }

    const formatTick =  (scale: ScaleObject) => (tick: string) => {
        
        const value = parseInt(tick);
        const hours = Math.floor(value / 60);
        const minutes = value % 60;
        if (minutes === 0) {
          return `${hours}h`;
        } else {
          return '';
        }
    }
    const Root = (props: any) => {
        return (
            <Legend.Root {...props} style={{ display: 'flex', flexWrap: 'nowrap', margin: 'auto', flexDirection: 'row', justifyContent: 'flex-end' }} />
        )
    };
    
    const Label = (props: any) => (
        <Legend.Label {...props} style={{ whiteSpace: 'nowrap' }} />
    );

    const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
        getStudyHistory(newTabIndex, selectDate);
        setTabIndex(newTabIndex);
    };

    const onDownload = () => {
        // 未実装自習データダウンロード処理
        setShowBackdropForDowndating(true);
        httpClient.downloadStudyHistory(
            sessionStorage.getItem("TABID") as string,
          )
            .then((e) => {
              //レスポンスヘッダからファイル名を取得
              const fileName = e.fileName;
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: "自習データのダウンロードが完了しました。"
              }));
            }).catch(() => {
              setFinishInfo(new FinishInfo({
                message: "自習データのダウンロードに失敗しました。"
              }));
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
    }

    // 日付変更・セット
    const handleChangeDate = (date: Date) => {
        getStudyHistory(1, date);
        setSelectDate(date);
    }

    const getSubject = async () => {
        // 教科一覧を取得する（※order順に取得すること）
        await httpClient.getStudySubjectMaster(sessionStorage.getItem("TABID") as string)
        .then((e: StudySubjectMasterInfo[]) => {
            setSubjectsList(e);
            const subjectsData: string[] =  e.map(u => (u.subjectName));
            setSubjects(subjectsData);
        }).catch(err => {
            console.log(err);
        });
    }

    const getStudyHistory = async (type: number, date: Date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        console.info(year + ':' + month);
        const convertedDate = year + month; 
        await httpClient.getPastStudyHistory(sessionStorage.getItem("TABID") as string, type, Number(convertedDate))
            .then((e: any[]) => {
                setStudyHistoryList(e);
                const totalSummarys : number[] = e.map(item => item['totalSummary']);
                const max = Math.max(...totalSummarys);
                const maxMinutes = (Math.floor(max / 60) + 1) * 60;
                const sum = totalSummarys.reduce((total, num) => total + num, 0);
                const hour = Math.floor(sum / 60);
                const minutes = sum % 60;
                // 合計時間と一日の最大時間を取得
                setMaxMinute(180 >= maxMinutes ? 180 : maxMinutes);
                setTotalHour(hour);
                setTotalMinute(minutes);
            }).catch(err => {
                console.log(err);
            });
    }

    const drawSelectMonthContent = useMemo(() => {
        return (
            <Box margin="0px 0px 0px 10px" fontSize="18px" paddingLeft={10} width={60}>
                <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "40px", marginBottom: "15px" }}>
                    <DatePicker
                        className={classes.picker}
                        locale="ja"
                        selected={selectDate}
                        onChange={handleChangeDate}
                        maxDate={maxDate}
                        dateFormat="yyyy/MM"
                        showMonthYearPicker
                    />
                </Grid>
            </Box>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, selectDate]);


    // 自習履歴表示
    const drawStudyHistory = useMemo(() => {
        const monthTotalText = '月合計：' + totalHour + '時間' + totalMinute + '分'
        
        return (
            <Fragment>
                <Grid container >
                    <Grid item xs={2} >
                        <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' style={{width: 800}}>
                            <Tab label="直近2週間" style={{minWidth: '18%'}}/>
                            <Tab label="月別" style={{minWidth: '18%'}}/>                             
                        </Tabs>
                    </Grid>
                    <Grid item xs={4} >
                        {tabIndex === 1 && drawSelectMonthContent}
                    </Grid>
                    <Grid item xs={6} className={classes.settingTitle}>
                        <div style={{ alignContent: "center", alignItems: "center", textAlign: "end", marginTop: 30, marginRight: 20 }}>
                            <Button variant="contained" color="primary" size="medium" style={{background: '#006FBC', borderRadius: '31px', marginTop: -10}} onClick={onDownload}>自習データをダウンロード</Button>
                        </div>
                    </Grid>
                </Grid>              
                <Grid item xs={12} style={{height: 28}}>
                    <div style={{ fontWeight: 'bold', fontFamily: 'Hiragino Maru Gothic StdN', fontSize: 20, textAlign: "end", marginTop: 30, marginRight: 10 }}>
                        {tabIndex === 1 ? monthTotalText : ""}
                    </div>
                </Grid>
                <Box className={classes.chartBox}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Chart
                            data={studyHistoryList}
                            height={150 + maxMinute / 60 * 50}
                            width={tabIndex === 1 ? 1000 : 600}
                        >
                            <ValueScale modifyDomain={() => [0, maxMinute]} />
                            <ArgumentAxis
                                labelComponent={AxisLabel2}
                            />
                            <ValueAxis tickFormat={formatTick} indentFromAxis={10}/>
                            
                            {subjectsList.filter(u => (u.displayOrder > 0))
                                    .map((u) => (
                                <BarSeries
                                    name={u.subjectName}
                                    valueField={u.subjectName}
                                    argumentField="date"
                                    color={u.graphColor}
                                    barWidth={0.8}
                                />
                            ))}
                            <Legend position="top" rootComponent={Root} labelComponent={Label}/>
                            <Stack
                                stacks={[
                                    { series: subjects },
                                ]}
                            />
                        </Chart>
                    </div>
                </Box>
                
            </Fragment>
        )
    },[open, user.x, user.y, ping, user.isCardDisclose, userProfile, tabIndex, selectDate, totalHour, totalMinute, subjects, subjectsList, studyHistoryList, maxMinute])
    
    // 表示するコンテンツの切替
    const switchContent = useMemo(() => {
        switch (content) {
            case 'pointDetail':
                // getAllUserPointsThisMonth();
                return drawMyPagePointDetailContent;
            case 'studyHistory':
                return drawStudyHistory;
            default:
                return drawMyPageContent;
        }
    }, [open, user.x, user.y, ping, user.isCardDisclose, userProfile, content, page, pointHistory, historyList, historyLength, userMonthlyPointsList, tabIndex, selectDate, totalHour, totalMinute, subjects, subjectsList, studyHistoryList, maxMinute]);

    return (
        <Fragment>
            <Dialog 
                open={open}
                style={{ pointerEvents: 'auto'}}
                fullWidth={true}
                maxWidth= {content === 'studyHistory' ? 'md' : 'sm'}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                        height: content === 'studyHistory' ?  700 : 'auto'
                    }
                }}
            >
                <DialogTitle 
                    id="dialog-registUser" 
                    style={{
                        padding: '7px 25px 9px 25px', 
                        background: '#57BBFF 0% 0% no-repeat padding-box', 
                        fontFamily: 'Hiragino Maru Gothic StdN', 
                        color: content ==='default' ? '#555555' : '#FFFFFF'
                    }}
                >
                    {getDialogTitle()}
                    <IconButton 
                        size="small" 
                        onClick={handleClickRefresh} 
                        onTouchEnd={handleClickRefresh}
                        style={{
                            color: '#006FBC', 
                            position: 'absolute', 
                            right: 10, 
                            top: 10,
                            display: content === 'studyHistory' ? 'none' : 'inline-flex'
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={className} style={{ textAlign: "left", padding: 8 }}>
                    {switchContent}
                </DialogContent>
                {/* </Card > */}
                <DialogActions>
                    { content !== 'default' &&
                        <Button
                            onClick={handleClickBack} 
                            onTouchEnd={handleClickBack} 
                            color="primary" 
                            autoFocus 
                            style={{color: '#006FBC', position: 'absolute', left: 0}}
                        > 
                            戻る
                        </Button>
                    }
                    <Button 
                        onClick={handleClickClose} 
                        onTouchEnd={handleClickClose} 
                        color="primary" 
                        autoFocus 
                        style={{color: '#006FBC'}}
                    >
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={showBackdropForDowndating} >
                <Slide direction="up" in={showBackdropForDowndating}>
                    <Card className={classes.card}>
                        <CardContent>
                            <ThemeProvider theme={circularTheme}>
                                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
                            </ThemeProvider>
                            <Typography variant="subtitle2" >データ取得中</Typography>
                        </CardContent>
                    </Card>
                </Slide>
            </Backdrop>
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText>{finishInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
                </DialogActions>
            </BaseDialog>
        </Fragment>
    )
}
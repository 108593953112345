import React, { useState, useMemo, Fragment } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import EditCountdownDialog from './EditCountdownDialog';
import { FloorObject, chkJsonFormat } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient from '@fsi/jfs-sdk';

import NumberZero from '../img/number/0.png';
import NumberOne from '../img/number/1.png';
import NumberTwo from '../img/number/2.png';
import NumberThree from '../img/number/3.png';
import NumberFour from '../img/number/4.png';
import NumberFive from '../img/number/5.png';
import NumberSix from '../img/number/6.png';
import NumberSeven from '../img/number/7.png';
import NumberEight from '../img/number/8.png';
import NumberNine from '../img/number/9.png';

interface Props {
    className: string,
    floorObject: FloorObject,
    role: string
    sendCountdownText: (id: number, text: string, date: string, font: string, color: string) => void
}

export default function CountdownBoard(props: Props) {
    
    // 表示するメッセージの取得
    // elm eventName:イベント名
    //     date:イベント開始までの残り日数
    //     font:残り日数の表示フォント
    function GetInText(text:string, elm:string ) {
        
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_COUNTDOWNBOARD, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            if (elm === 'eventName') {
                text = intextJson.eventName;
            } else if (elm === 'eventDate') {
                text = intextJson.eventDate;
            } else if (elm === 'font') {
                text = intextJson.font;
            } else if (elm === 'color') {
                text = intextJson.color;
            } else {
                // 処理なし(textをそのまま使用)
            }
        }else{
            // Json形式ではない場合
            if (elm === 'eventName') {
                // 処理なし（textをそのまま使用）
            } else if (elm === 'eventDate') {
                // 処理なし (textをそのまま使用)
            } else if (elm === 'font'){
                // 処理なし (textをそのまま使用) 
            } else if (elm === 'color'){
                // 処理なし (textをそのまま使用) 
            } else {
                // 処理なし(textをそのまま使用)
            }
        }
        return text;
    }
/* sawadam
    function hankaku2Zenkaku(str:string) {
        return str.replace(/[0-9]/g, function(s){
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0)
        });
    }
*/
    // 日付比較
    function isSameDate(date1: Date, date2: Date){
        return date1.getFullYear() === date2.getFullYear()
            && date1.getMonth() === date2.getMonth()
            && date1.getDate() === date2.getDate();
    }

    // カウントダウン日数計算
    function getDateDiff() {
        // 日付の文字列から日付オブジェクトを生成
        let dateString = GetInText(text2,'eventDate');
        let date1 = new Date(dateString);
        let now = new Date();
        let diff = 0;
        let msDiff = 0;
        
        // 2つの日付の差分を計算する
        if(date1 >= now){
            msDiff = date1.getTime() - now.getTime();
            diff = Math.ceil(msDiff / (1000*60*60*24));
        }else if(dateString === "" || !isSameDate(date1, now)){
            // 期限が切れたカウントダウンはクリアする
            if(!isSameDate(date1, now)){
                props.sendCountdownText(id, "", "", "", "");
            }
            return <div />;
        }
        return getDispNumber(diff);
    }

    // カウントダウン日数を数値画像に変換して表示
    function getDispNumber(diff: number) {
        let retH = Math.floor(diff / 100 % 10);
        let retT = Math.floor(diff / 10 % 10);
        let retO = Math.floor(diff % 10);
        // alert("diff: " + diff + " - state:" + retH + retT + retO);
        return (
            <div>
                <img src={DispNumer[retH]} style={{marginTop: -2, marginRight: 2, height: 26, width: 16}} />
                <img src={DispNumer[retT]} style={{marginTop: -2, marginRight: 2, height: 26, width: 16}} />
                <img src={DispNumer[retO]} style={{marginTop: -2, marginRight: 2, height: 26, width: 16}} />
            </div>
        )
    }

    const { className, role } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const DispNumer = [NumberZero, NumberOne, NumberTwo, NumberThree, NumberFour, NumberFive, NumberSix, NumberSeven, NumberEight, NumberNine];
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const setFont = (fontName: string) => {
        let font = fontName.split("　");
        if(font.length > 1){
            return font[1];
        }else{
            return "sans-serif";
        }
    }

    const content = useMemo(
      () => (
          <div
            style={{
              marginTop: GetInText(text2, "eventName").length > 5 ? 15 : 10,
              marginBottom: GetInText(text2, "eventName").length > 5 ? 2 : 3,
              marginLeft: GetInText(text2, "eventName").length > 6 ? -10 : -2,
              fontSize:
                GetInText(text2, "eventName").length > 6
                  ? 10
                  : GetInText(text2, "eventName").length === 6
                  ? 12
                  : 14,
              fontWeight: "bold",
              fontFamily: setFont(GetInText(text2, "font")),
              color: GetInText(text2, "color"),
            }}
          >
            {GetInText(text2, "eventName")} {/*まで*/}
          </div>
        ),
      [text2, props.role]
    );

    const countdown = useMemo(() => {
        return (
            <>
                {/* sawadam <div style={{display: 'inline-block'}}>あと</div>*/}
                <div style={{marginTop: 8, marginLeft: 36, display: 'inline-block'}}>
                    {getDateDiff()}
                </div>
                {/* sawadam <div style={{display: 'inline-block'}}>日</div>*/}
            </>
        )
    }, [text2]);

    const draw = useMemo(() =>
        <Fragment>
            <div
                key={`countdownBoard${id}`}
                id={`countdownBoard${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...SELECT_NONE,
                    zIndex: ZIndex.floorObject,
                    cursor: 'pointer',
                    position: 'absolute',
                    //fontSize: 12,
                    whiteSpace: 'nowrap',
                    width: objectMaster.width,
                    height: objectMaster.height,
                    lineHeight: 1,
                    textAlign: 'center',
                    padding: 5,
                    borderRadius: '2%',
                    left: offsetLeft,
                    top: offsetTop,
                    backgroundSize: "contain",
                    //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                    backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                    backgroundRepeat: "no-repeat",
                }}
            >
                {content}
                {countdown}
            </div>
            <EditCountdownDialog
                className={className}
                isOpen={isOpen && (role.match(/ROLE_ADMIN/) != null)}
                floorObjectId={id}
                title={'カウントダウン設定'}
                text={text2}
                maxLength={8}
                onClose={() => setIsOpen(false)}
                sendText={props.sendCountdownText}
            />
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [text1, text2, isOpen, props.role])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
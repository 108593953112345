/**
 * DBに設定されたツールランチャーを表示する
 */
import React, { Fragment, useState, useMemo, useEffect, ForwardRefRenderFunction, forwardRef, useImperativeHandle } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Slide, Tooltip, Typography } from '@material-ui/core';
import JfsClient from '@fsi/jfs-sdk';

export class TenantLauncherJson {
    /** 画像URL */
    picturUrl: string = "";
    /** リンクURL */
    url: string = "";
    /** ボタン文字 */
    displayString: string = "";
    /** ソート順 */
    sortNo: number = 0;
    /** 表示フラグ */
    enableView: boolean = false;
}

interface Props {
    /** 有効化通知処理 */
    changeEnabled: () => void,
    /** 表示切替 */
    show: boolean,
}

export interface TenantLauncherHandler {
    show: () => void;
}

const TenantLauncherComponent: ForwardRefRenderFunction<TenantLauncherHandler, Props> = (props, ref) => {
    /** 表示切替 */
    const [show, setShow] = useState(false);
    /** ランチャーリスト */
    const [launcherList, setLauncherList] = useState([] as TenantLauncherJson[]);

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        show: () => {
            setShow(true);
        },
    }))

    /**
     * 外部から有効化された時、ランチャーのリストをサーバーから取得する
     * 成功した時、親に有効になった事をProps経由で通知する
     */
    useEffect(() => {
        if (!show) return;
        const tabId = sessionStorage.getItem("TABID") as string;
        httpClient.getTenantLancherList(tabId)
            .then((e) => {
                const res = e as unknown as String;
                if(res === "NG") return;
                setLauncherList(e as TenantLauncherJson[]);
                props.changeEnabled();
            }).catch(err => {

            });
    }, [show])

    /** 1つのツールランチャーの高さ */
    const divHeight = 40;

    /**
     * 1つのツールランチャーの描画
     * 画像URLがある場合は画像、ない場合はボタン文字を描画する
     * @param e 
     * @returns 
     */
    const drawImageOrText = (e: TenantLauncherJson) => {
        if (e.picturUrl === "") {
            return (
                <div style={{ height: "100%", width: "100%" }}>
                    <Typography variant='body1' style={{ position: "absolute", top: 4, bottom: 0, left: 0, right: 0, margin: 0, width: "100%", height: "50px" }}>{e.displayString}</Typography>
                </div>
            );
        } else {
            return (
                <img src={e.picturUrl} alt={e.displayString} style={{ height: divHeight - 13, width: 145, objectFit: "contain", padding: 3 }} />
            )
        }
    }

    /**
     * ツールランチャー全体を描画する
     */
    const draw = useMemo(() => {
        // console.log(launcherList);
        let startY = 70 + launcherList.length * divHeight;
        return (
            launcherList.map((e: TenantLauncherJson, index: number) => {
                let bottom = startY - (index * divHeight) - (launcherList.length - index) * 3 + 3;  // 最下の表示は今までの位置にあわせつつ表示の間を詰める
                //console.log("index = " + String(index) + " bottom = " + String(bottom));
                return (
                    <Slide direction="right" in={props.show} mountOnEnter unmountOnExit>
                        <Tooltip title={e.displayString} placement="right">
                            <div style={{ position: "fixed", bottom: bottom, left: 7, height: divHeight - 8, width: 150, backgroundColor: "white", borderRadius: "5px 5px 5px 5px", overflow: "hidden" }}>
                                <a href={e.url} target="_blank" rel="noreferrer">
                                    {/* <img src={e.picturUrl} alt={e.displayString} style={{ height: 47, width: 145, objectFit: "contain",padding: 3 }} /> */}
                                    {drawImageOrText(e)}
                                </a>
                            </div>
                        </Tooltip>
                    </Slide>
                )
            })
        )

    }, [launcherList, props.show]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const TenantLauncher = forwardRef(TenantLauncherComponent);
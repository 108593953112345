import React, { Fragment, useMemo, useRef, useEffect, useState, ForwardRefRenderFunction, forwardRef, useImperativeHandle, } from 'react';
import useReactRouter from 'use-react-router';
import { makeStyles, Theme, createStyles, useTheme, Box, Icon, FilledInput } from '@material-ui/core';
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { DraggableFrame, DraggableFrameHandler, DraggableFrameProps } from '../common/DraggableFrame'
import { Rnd, RndResizeCallback, RndResizeStartCallback } from "react-rnd";
import { useSnackbar } from 'notistack';
import ZIndex from "../common/ZIndex";
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GetAppIcon from '@material-ui/icons/GetApp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { Size } from '../common/JsonClass';
import { ImageTwoTone } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { Utility } from '../common/Utility';
import {Document, Page, pdfjs} from "react-pdf";
import JfsClient from '@fsi/jfs-sdk';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        signboardImg: {
            //background-repeat: no-repeat;
            backgroundRepeat: 'no-repeat',
        },
        draggableArea: {
            height: '5%', 
            width: '100%', 
            backgroundColor: '#e0e0e0',
            
        },
        closeButton: {
            marginLeft: '95%',
        },
        overlayBottom: {
            position: "absolute",
            left: 0,
            top: 0,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: 40,
            //background: "rgba(0,0,0,0.5)"
        },
        downloadBotton: {
            position: "absolute",
            left: 0,
            bottom: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 40,
            //background: "rgba(0,0,0,0.5)"
        },
        imgToolbar: {
            position: "absolute",
            left: 0,
            top: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 40,
            //background: "rgba(0,0,0,0.5)"
        },
        icon: {
            //color: "white"
        },
        title: {
            position: "absolute",
            left: 10,
            top: 6,
            fontWeight: 'bold',
            fontSize: 20,
        },
          
    }),
);

interface Props {
    //url: string,
}

export interface WindowHandler {
    open: (url: string, boardName: string, download: boolean, floorId: number, objectId: number, noticeNo: number) => void;
    close: () => void;
    editPopupSignboard:(boardName: string) => void;
    setURL: (url: string) => void;
}

const DraggableWebpage: ForwardRefRenderFunction<WindowHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [currentPosition, setCurrentPosition] = useState({x:0, y:0});
    const [url, setUrl] = useState('');
    const [isVisible, setVisible] = useState('none');
    const [loadcount, setLoadcount] = useState(0);
    const [boardName, setBoardName] = useState('');
    const [boardNameAdjust, setBoardNameAdjust] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { history } = useReactRouter();
    const rndRef = useRef({} as Rnd | null);
    const [divSize, setDivSize] = useState({} as Size);
    const [imgSize, setImgSize] = useState({} as Size);
    const [defSize, setDefSize] = useState({} as Size);
    const [size, setSize] = useState(100);
    const [pages, setPages] = useState(0);
    const [pageArr, setPageArr] = useState([] as number[]);
    const [isEnterContent, setEnterContent] = useState(false);
    const [enableDownload, setEnableDownload] = useState(false);
    const scrollDom = useRef(null);
    const [pdfHeight, setPdfHeight] = useState(0);
    const [pdfIndex, setPdfIndex] = useState(1);
    const [scrollHeight, setScrollHeight] = useState(0);
    const MAX_SIZE = 300;
    const MIN_SIZE = 5;
    //const [downloadUrl, setDownloadUrl] = useState("");
    const [floorId, setFloorId] = useState(0);
    const [objectId, setObjectId] = useState(0);
    const [noticeNo, setNoticeNo] = useState(0);
    const [param, setParam] = useState({});
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

     // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open: (data: string, boardName: string, download: boolean, floorId: number, objectId: number, noticeNo: number) => {
            calcPosition(data);
            if(data != url){
                setUrl(data);
                setPages(0);
                setPageArr([]);
                setPdfIndex(1);
                // setDownloadUrl("/api/user/floor/notice/getpdf/" 
                //     + sessionStorage.getItem("TABID") + "/"
                //     + floorId +"/"
                //     + objectId +"/" 
                //     + noticeNo);
                setFloorId(floorId);
                setObjectId(objectId);
                setNoticeNo(noticeNo);
            }
            setBoardName(boardName);
            setVisible('flex');
            setLoadcount(0);
            adjustBoardName(boardName);
            //setImageSize(data);
            setEnableDownload(download);
            setParam({
                url: data, 
                boardName: boardName, 
                enableDownload: download, 
                floorId: floorId, 
                objectId: objectId, 
                noticeNo: noticeNo,
            })
        },
        close() {
            onClose();
        },
        // フロア通知
        editPopupSignboard(boardName: string) {
            enqueueSnackbar("看板「"+boardName+"」が更新されました", { variant: "success" });
        },
        setURL(url: string) {
            setUrl(url);
        },
        getOpen() {
            return isVisible == 'flex';
        },
        getParam() {
            return param;
        }
    }));

    useEffect(() => {
        //frameRef.current?.open();
        const dom:any = scrollDom?.current;
        dom.addEventListener('scroll', handleScroll);
        return () => dom.removeEventListener('scroll', handleScroll);
    }, []);

    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        //setCurrentPosition({x:data.lastX,y:data.lastY});
    }

    /* 
    * 閉じるボタンのハンドラ
    */
    const onClose = () => {
        setVisible('none');
        setUrl('');
        setBoardName('');
    }

    /* 
    * 表示位置の計算
    */
    const calcPosition = async (data:string) => {
        const parent = document.getElementById('floorContainer') as HTMLDivElement;
        const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = parent;
        rndRef.current?.updatePosition({ x: offsetWidth / 5, y: offsetHeight / 7 });
        rndRef.current?.updateSize({ width: offsetWidth / 1.5 , height: offsetHeight / 1.3});

        let domsize = { width: offsetWidth / 1.5  * 0.85 - 10, height: offsetHeight / 1.3 - (Utility.getType() == 1 ? 90 : 110)} as Size;
        //console.info("domSize", { width: offsetWidth / 1.5  * 0.85 - 10, height: offsetHeight / 1.3 * 0.8 - 10});
        setDivSize(domsize);

        // 画像の場合
        if(data.startsWith("data:image")){
            const image = new Image();
            image.src = data;
            await image.decode();

            const imgSize = {} as Size;
            imgSize.width = image.width;
            imgSize.height = image.height;

            if(domsize.width/imgSize.width < domsize.height/imgSize.height){
                // 横フィット
                imgSize.height = domsize.width/imgSize.width * imgSize.height;
                imgSize.width = domsize.width;
            }else{
                // 縦フィット
                imgSize.width = (domsize.height)/imgSize.height * imgSize.width;
                imgSize.height = domsize.height;
            }
            //console.info("imgSize", imgSize.width, imgSize.height);
            setImgSize(imgSize);
            setDefSize(imgSize);
        }else{
            //console.info("pdfSize", domsize.width, domsize.height);
            setImgSize(domsize);
            setDefSize(domsize);
        }
        setSize(100);
    }

    /* 
    * 進むボタンのハンドラ
    */
    const goForward = () => {
        //const iframe = document.getElementById('popup-iframe') as HTMLIFrameElement;
        //iframe.contentWindow?.history.go(1);
        history.goForward();
    }

    /* 
    * 戻るボタンのハンドラ
    */
    const goBack = () => {
        if(loadcount<=1){return;}
        history.goBack();
        setLoadcount(loadcount-2);
    }

    /* 
    * サイトページのロード時のハンドラ
    */
    const handleOnload = () => {
        setLoadcount(loadcount+1);
    }

    /* 
    * 画面上部に看板の名前、3つのボタンを表示
    */
    const Overlay = () => {
        return (
            <Fragment>
                <div className={classes.title} onPointerEnter={() => setEnterContent(false)}>
                    {boardNameAdjust}
                </div>
                <div 
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                    className={classes.overlayBottom}>
                    {!url.startsWith("data:") && 
                    <IconButton
                        className={classes.icon}
                        aria-label="goback"
                        onClick={goBack}
                        onTouchEnd={goBack}
                    >
                        <ArrowBackIcon />
                    </IconButton>}
                    {!url.startsWith("data:") && 
                    <IconButton
                        className={classes.icon}
                        aria-label="goforward"
                        onClick={goForward}
                        onTouchEnd={goForward}
                    >
                        <ArrowForwardIcon />
                    </IconButton>}
                    {url.startsWith("data:") && (Utility.getType() == 1) &&  
                    <IconButton disabled = {size >= MAX_SIZE}
                        className={classes.icon}
                        aria-label="zoomin"
                        onClick={()=>{zoom(5)}}
                        onTouchEnd={()=>{zoom(5)}}
                    >
                        <ZoomInIcon />
                    </IconButton>}
                    {url.startsWith("data:") && (Utility.getType() == 1) &&  
                    <IconButton
                        className={classes.icon}
                        aria-label="zoomout"
                        onClick={()=>{zoom(-5)}}
                        onTouchEnd={()=>{zoom(-5)}}
                    >
                        <ZoomOutIcon />
                    </IconButton>}
                    {url.startsWith("data:") && (Utility.getType() == 1) &&  
                    <IconButton
                        className={classes.icon}
                        aria-label="fit"
                        onClick={setToFit}
                        onTouchEnd={setToFit}
                    >
                        <ZoomOutMapIcon />
                    </IconButton>}
                    {
                        url.startsWith("data:app") && pages > 0 && (Utility.getType() == 1) &&
                            <Fragment>
                            {pdfIndex} / {pages}　ページ
                            </Fragment>
                    }
                    {url.startsWith("data:") && enableDownload &&
                    <IconButton
                        className={classes.icon}
                        aria-label="download"
                        onClick={download}
                        onTouchEnd={download}
                    >
                        <GetAppIcon />
                    </IconButton>}
                    <IconButton
                        className={classes.icon}
                        aria-label="reset"
                        onClick={onClose}
                        onTouchEnd={onClose}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </Fragment>
        )
    }

    const download = () => {
        const link = document.createElement('a');

        if(url.startsWith("data:image")){
            link.href = url;
            link.setAttribute('download', "掲示板");
            document.body.appendChild(link);
            link.click();
        } else {
            //link.href = downloadUrl;
            //link.setAttribute('download', "掲示板.pdf");
            let res = httpClient.downloadPdf(sessionStorage.getItem("TABID") as string, floorId, objectId, noticeNo);        
        }
        
    }

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }

        return len
    };
    
    const adjustBoardName = (text: string) => {
        if(text == undefined || text == null || text == "") return "";
        //return text.length > textTrimLength ? `${text.slice(0, textTrimLength)}...` : text;
        //200,525
        const parent = document.getElementById('floorContainer') as HTMLDivElement;
        const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = parent;
        var width = offsetWidth / 1.5;
        var length = judgehz(text);
        var length2 = Math.floor((width - 200) / 17);

        if(length > length2){
            setBoardNameAdjust(`${text.slice(0, length2)}...`);
        }else{
            setBoardNameAdjust(text);
        }
    }

    const onResizeStop: RndResizeCallback = (e, dir, refToElement, delta, position,) => {
        const { width, height } = refToElement.style;
        const toNumber = (str: string) => Number(str.replace('px', ''));
        var length = judgehz(boardName);
        var length2 = Math.floor((toNumber(width) - 200) / 17);

        if(length > length2){
            setBoardNameAdjust(`${boardName.slice(0, length2)}...`);
        }else{
            setBoardNameAdjust(boardName);
        }
    };
      
    const zoom = (per: number) => {

        let rate = size;
        if(rate + per >= MIN_SIZE && rate + per <= MAX_SIZE){
            rate = rate + per;
        }else if(rate + per >= MIN_SIZE && rate + per >= MAX_SIZE && rate <= MAX_SIZE){
            rate = MAX_SIZE;
        }else if(rate + per <= MIN_SIZE && rate + per <= MAX_SIZE && rate >= MIN_SIZE){
            rate = MIN_SIZE;
        }

        setSize(rate);
        setDefSize({width:imgSize.width * rate/100, height: imgSize.height * rate/100});
    }

    const setToFit = () => {

        const dom:any = scrollDom?.current;
        var domFullsize = {width: dom.offsetWidth, height: dom.offsetHeight};
        var domsize = {width: dom.clientWidth, height: dom.clientHeight};
        if(domsize.width == domFullsize.width){
            domsize.width = domsize.width - 10;
        }
        if(domsize.height == domFullsize.height){
            domsize.height = domsize.height - 10;
        }

        let rate = Math.min(domsize.width / imgSize.width, domsize.height / imgSize.height);
        setSize(rate * 100);
        let ret = {width:imgSize.width * rate, height:imgSize.height * rate} as Size;
        setDefSize(ret);
    }

    const onPdfLoadSuccess = (pdfObj:any) =>{

        const count = pdfObj.numPages as number;
        const arr = [] as number[];
        for(var i = 1; i<= count; i++){
            arr.push(i);
        }
        setPages(count);
        setPageArr(arr);
    }

    const documentOnRenderSuccess = (index:number) => {

        let pdfDom: any = document.getElementsByClassName('pdf-page')[0];
        let height = pdfDom.offsetHeight;
        setPdfHeight(height);
    }
    
    //scrollイベント
    const handleScroll = (e: any) => {
        // console.log(e)
        const tempScrollHeight = (e.srcElement ? e.srcElement.scrollTop : false)
            || (e.srcElement.body ? e.srcElement.body.scrollTop : 0);
        setScrollHeight(tempScrollHeight);
    }

    // スクロールの位置より、ページ数を判定する
    useEffect(() => {
        if(url.startsWith("data:app")){
            computeScroll(scrollHeight);
        }
    }, [scrollHeight, pdfHeight])

    // スクロール位置判定
      const computeScroll = (scrollHeight: number) => {
        const dom:any = scrollDom?.current;
        if(pages == 1){
            setPdfIndex(1);
            return;
        }
        if (!dom) return;
        if (pdfIndex <= 1 && scrollHeight > pdfHeight / 2) {
            setPdfIndex(2);
        } else {
            // 
            if (dom.scrollHeight > scrollHeight && scrollHeight > (pdfHeight + 10) * (pdfIndex - 1) 
            	&& scrollHeight % (pdfHeight + 10)> pdfHeight / 2) {
                setPdfIndex((prevalue) => {
                    if (prevalue == pages) {
                        return prevalue;
                    }
                    return prevalue + 1;
                })
            }else if(scrollHeight < (pdfHeight + 10) * (pdfIndex - 1) && scrollHeight % (pdfHeight + 10) < pdfHeight /2){
                setPdfIndex((prevalue) => {
                    if (prevalue == 1) {
                        return prevalue;
                    }
                    return prevalue - 1;
                })
            }
        }
      }

    /*
    * 画面全体を表示
    */
    const drawFrame = useMemo(() => {

        return (
            <Fragment>
                <Rnd
                    ref={rndRef}
                    enableResizing={true}
                    disableDragging={isEnterContent}
                    bounds={`#floorContainer`} // 移動可能範囲をCSSセレクターで指定 
                    default={{
                        x: 0,
                        y: 0,
                        width: 900,
                        height: 600,
                    }}
                    minWidth="200"
                    onResizeStop={onResizeStop}
                        style={{
                        display: isVisible,
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }}
                >
                    <div style={{width:'100%', height:'100%'}}>
                        <div style={{width:'100%', display: "flex", height:40, }}>
                            <div className={classes.title}>
                                {boardNameAdjust}
                            </div>
                            <div className={classes.overlayBottom}>
                                {!url.startsWith("data:") && 
                                <IconButton
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    className={classes.icon}
                                    aria-label="goback"
                                    onClick={goBack}
                                    onTouchEnd={goBack}
                                >
                                    <ArrowBackIcon />
                                </IconButton>}
                                {!url.startsWith("data:") && 
                                <IconButton
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    className={classes.icon}
                                    aria-label="goforward"
                                    onClick={goForward}
                                    onTouchEnd={goForward}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>}
                                {url.startsWith("data:") && (Utility.getType() == 1) &&  
                                <IconButton disabled = {size >= MAX_SIZE}
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    className={classes.icon}
                                    aria-label="zoomin"
                                    onClick={()=>{zoom(5)}}
                                    //onTouchEnd={()=>{zoom(5)}}
                                >
                                    <ZoomInIcon />
                                </IconButton>}
                                {url.startsWith("data:") && (Utility.getType() == 1) &&  
                                <IconButton
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    className={classes.icon}
                                    aria-label="zoomout"
                                    onClick={()=>{zoom(-5)}}
                                    //onTouchEnd={()=>{zoom(-5)}}
                                >
                                    <ZoomOutIcon />
                                </IconButton>}
                                {url.startsWith("data:") && (Utility.getType() == 1) &&  
                                <IconButton
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    className={classes.icon}
                                    aria-label="fit"
                                    onClick={setToFit}
                                    onTouchEnd={setToFit}
                                >
                                    <ZoomOutMapIcon />
                                </IconButton>}
                                {
                                    url.startsWith("data:app") && pages > 0 && (Utility.getType() == 1) &&
                                        <Fragment>
                                        {pdfIndex} / {pages}　ページ
                                        </Fragment>
                                }
                                {url.startsWith("data:") && enableDownload &&
                                <IconButton
                                    className={classes.icon}
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    aria-label="download"
                                    onClick={download}
                                    onTouchEnd={download}
                                >
                                    <GetAppIcon />
                                </IconButton>}
                                <IconButton
                                    className={classes.icon}
                                    aria-label="reset"
                                    onClick={onClose}
                                    onTouchEnd={onClose}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        </div>
                        {Utility.getType()!=1 && <div style={{height:20}}>
                        {
                            url.startsWith("data:") && (Utility.getType() != 1) && 
                            <div className = {classes.imgToolbar}>
                                <Tooltip title='拡大'>
                                <IconButton disabled = {size >= MAX_SIZE}
                                    className={classes.icon}
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    aria-label="zoomin"
                                    onClick={()=>{zoom(5)}}
                                    //onTouchEnd={()=>{zoom(5)}}
                                >
                                    <ZoomInIcon />
                                </IconButton>
                                </Tooltip>
                                <Tooltip title='縮小'>
                                <IconButton disabled = {size <= MIN_SIZE}
                                    className={classes.icon}
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    aria-label="zoomout"
                                    onClick={()=>{zoom(-5)}}
                                    //onTouchEnd={()=>{zoom(-5)}}
                                >
                                    <ZoomOutIcon />
                                </IconButton>
                                </Tooltip>
                                <Tooltip title='画面合わせ'>
                                <IconButton
                                    className={classes.icon}
                                    onPointerEnter={() => setEnterContent(true)}
                                    onPointerLeave={() => setEnterContent(false)}
                                    aria-label="fit"
                                    onClick={setToFit}
                                    onTouchEnd={setToFit}
                                >
                                    <ZoomOutMapIcon />
                                </IconButton>
                                </Tooltip>
                                {
                                    url.startsWith("data:app") && pages > 0 &&
                                        <Fragment>
                                        {pdfIndex} / {pages}　ページ
                                        </Fragment>
                                }
                            </div>
                        }
                        </div>}
                        <div style={{
                            width: '100%', 
                            height: Utility.getType()==1 ? `calc(100% - 80px)` : `calc(100% - 100px)`,
                            alignContent : 'center',
                            alignItems : 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                            <div ref={scrollDom}
                                id="imageDiv"
                                onPointerEnter={() => setEnterContent(true)}
                                onPointerLeave={() => setEnterContent(false)}
                                style={{
                                    width: '85%',
                                    height: '100%',
                                    backgroundSize: "contain",
                                    backgroundPosition:"center",
                                    backgroundRepeat: 'no-repeat',
                                    overflow : 'auto',
                                    alignContent : 'center',
                                    alignItems : 'center',
                                }}
                            >
                                {
                                    url.startsWith("data:app") &&
                                        <Document
                                            file = {url}
                                            onLoadSuccess = {onPdfLoadSuccess}
                                            //onLoadError = {(err) => //console.info(err)}
                                            renderMode="canvas"
                                            >
                                            {
                                                pages != 0 && pageArr.map((item, index) =>{
                                                    return (
                                                        <Page 
                                                            key={index}
                                                            className="pdf-page"
                                                            pageNumber={item} 
                                                            onRenderSuccess={() => documentOnRenderSuccess(index)}
                                                            width={pages == 0 ? document.getElementById("imageDiv")!.clientWidth - 10 : defSize.width} 
                                                            />
                                                    )
                                                })

                                            }
                                        </Document>
                                }
                                {
                                    url.startsWith("data:image") &&
                                    <img src={url} width={defSize.width} height={defSize.height}></img>
                                }
                                {
                                    url.startsWith("http") &&
                                    <iframe id='popup-iframe' onLoad={handleOnload} src={url} style={{width: '85%', height: '80%'}} frameBorder="0"/>
                                }
                            </div>
                        </div>
                    </div>
                </Rnd>
            </Fragment>
        )
    }, [pdfHeight, pdfIndex, scrollHeight, divSize, imgSize, defSize, size, url, isVisible, currentPosition, loadcount, boardNameAdjust, pages, pageArr, isEnterContent])

    return (
        <Fragment>
            {drawFrame}
        </Fragment>
    )
}

export const DraggableWebpageRef = forwardRef(DraggableWebpage);
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios, { AxiosResponse } from 'axios';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notify: {
            //marginTop: '50px',
            overflow: 'auto',
            //height: '250px',,
            maxHeight: '400px',
            textAlign: 'left',
          },
          paper2: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
          paper3: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
    }),
);

interface Props {

}

// 公開したいメソッドの定義
export interface ChildHandler {
    setOpen: (open: boolean) => void
    setMessage: (message: string) => void
}

const MyRoomMessageFunction: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    useImperativeHandle(ref, () => ({
        setOpen: (open: boolean) => {
            setOpen(open);
        },
        setMessage: (message: string) => {
            setMessage(message);
        },
    }));

    useEffect(() => {

    }, [])

    const handleClickClose = () =>{
        setOpen(false);
    }
    
    const draw = useMemo(() => {

        return (
            <BaseDialog
                id="myroom-message-dialog"
                open={open}
                aria-labelledby="myroom-message-title"
            >
                <DialogTitle id="myroom-message-title">フォローリスト</DialogTitle>
                <DialogContent>{message}</DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose} color="primary" autoFocus>閉じる</Button>
                </DialogActions>
            </BaseDialog>
        );

    }, [open, message]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const MyRoomMessage = forwardRef(MyRoomMessageFunction);

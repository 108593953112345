import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';

import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/styles';

import { userListConst } from './UserListConst';
import { useSnackbar } from 'notistack';
import JfsClient from '@fsi/jfs-sdk';

const MAX_TEXT_LENGTH = 50;
const Message = {
    FAILED_GET_DATA: 'データの取得に失敗しました。',
    FAILED_UPDATE_DATA: 'データの更新に失敗しました。画面を再読み込みし、再度更新を行ってください。',
    SUCCESS_UPDATE_DATA: 'データの更新に成功しました。',
    UNEXPECTED: userListConst.unexpectedMessage,
    REQUEST_ERROR: userListConst.requestError.message,
} as const;
const OVER_MAXTEXTLENGTH_ERROR = (text: string) => {
    return `メッセージが長すぎます。（${text.length}文字 / ${MAX_TEXT_LENGTH}文字)`;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '50%',
        minWidth: 300,
        maxWidth: 450,
    },
    cardContent: {
        padding: '0 40px',
    },
    cardActions: {
        paddingLeft: 40,
        paddingRight: 40,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    inputTextField: {
        width: '100%',
        background: '#FFFFFF',
        borderRadius: 4,
        color: '#A39F98',
    },
    errorMessage: {
        ...theme.typography.caption,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingCard: {
        width: 150,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface ResAdminMessage {
    adminMessage: string;
}

/**
 * API Request
 */
// const getAdminMessage = async (tabId: string): Promise<AxiosResponse<ResAdminMessage>> => {
//     try {
//         const res: AxiosResponse<ResAdminMessage> = await axios.get(
//             '/api/v1/admin/tenant/adminmessage',
//             { params: { tabId } }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

// const updateAdminMessage = async (tabId: string, message: string): Promise<AxiosResponse<ResAdminMessage>> => {
//     try {
//         const res: AxiosResponse<ResAdminMessage> = await axios.patch(
//             '/api/v1/admin/tenant/adminmessage',
//             { tabId, message }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

export default function AdminMessage() {
    const tabId = sessionStorage.getItem('TABID') || '';
    const classes = useStyles();
    const { history } = useReactRouter();

    const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
    const [isLoading, setLoading] = useState(false);
    const [enableAdminMessage, setEnableAdminMessage] = useState(false);
    const [inputedText, setInputedText] = useState('');
    const [adminMessageErrMsg, setAdminMessageErrMsg] = useState<ReactNode>(null);
    const { enqueueSnackbar } = useSnackbar();
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const resetDisplayData = () => {
        setCrudType(0);
        setLoading(false);
        setInputedText('');
        setAdminMessageErrMsg(null);
    }

    /**
     * API Request
     */
    const getAdminMessage = async (tabId: string): Promise<ResAdminMessage> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResAdminMessage> = await axios.get(
            //     '/api/v1/admin/tenant/adminmessage',
            //     { params: { tabId } }
            // );

            const res: ResAdminMessage = await httpClient.getAdminMessage(tabId);
            return res;
        } catch (err) {
            throw err;
        }
    }

    const updateAdminMessage = async (tabId: string, message: string): Promise<ResAdminMessage> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResAdminMessage> = await axios.patch(
            //     '/api/v1/admin/tenant/adminmessage',
            //     { tabId, message }
            // );
            const res: ResAdminMessage = await httpClient.updateAdminMessage(tabId, message);

            return res;
        } catch (err) {
            throw err;
        }
    }

    const validateInputedText = (text: string): boolean => {
        let isValidLength = true;
        let msgList: string[] = [];

        // 文字数チェック
        isValidLength = text.length <= MAX_TEXT_LENGTH;
        if (!isValidLength) {
            const errMessage = OVER_MAXTEXTLENGTH_ERROR(text);
            msgList.push(errMessage);
        }

        // メッセージをセット
        if (isValidLength) {
            setAdminMessageErrMsg(null);
        } else {
            setAdminMessageErrMsg(drawError(msgList));
        }

        return isValidLength;
    }

    /**
     * error handle
     */
    const handleAdminMessageError = (err: any, type: number) => {
        const msgList: string[] = [];

        if (err.response) {
            if (err.response.status >= 500) {
                if (type === 0) {
                    msgList.push(Message.UNEXPECTED);
                } else {
                    msgList.push(Message.FAILED_UPDATE_DATA);
                }
            } else if (err.response.status === 403) {
                history.push({ pathname: '/' });
                return;
            } else {
                if (type === 0) {
                    msgList.push(Message.FAILED_GET_DATA);
                } else {
                    msgList.push(Message.FAILED_UPDATE_DATA);
                }
            }
            setAdminMessageErrMsg(drawError(msgList));
            return;
        } else if (err.request) {
            setAdminMessageErrMsg(Message.REQUEST_ERROR);
            return;
        }
    }

    const drawError = useCallback((messageList: string[] = []) => {
        return (
            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                {
                    messageList.length > 0 &&
                    <ul style={{ margin: 5, paddingLeft: 10 }}>
                        {messageList.map(msg => <li>{msg}</li>)}
                    </ul>
                }
            </div>
        )
    }, []);

    /**
     * handle data
     */
    const fetchAdminMessage = async () => {
        const newCrudType = 0;
        try {
            resetDisplayData();
            setCrudType(newCrudType);
            setLoading(true);
            const res = await getAdminMessage(tabId);
            const message = res?.adminMessage;
            setInputedText(message);
        } catch (err) {
            console.error(err);
            handleAdminMessageError(err, newCrudType);
        } finally {
            setLoading(false);
            setEnableAdminMessage(true);
        }
    }

    /**
     * useEffect
     */
    useEffect(() => {
        fetchAdminMessage();
    }, []);

    const handleChangeInputedText = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 1 && event.target.value === '\n') {
            return false;
        }

        validateInputedText(event.target.value);
        setInputedText(event.target.value);
    }

    const handleClickRegisterBtn = async () => {
        let newCrudType = 1;
        try {
            setCrudType(newCrudType);
            setLoading(true);
            const res = await updateAdminMessage(tabId, inputedText);
            const message = res?.adminMessage;
            setInputedText(message);
            enqueueSnackbar(Message.SUCCESS_UPDATE_DATA, { variant: "success" });
        } catch (err) {
            console.error(err);
            handleAdminMessageError(err, newCrudType);
        } finally {
            setLoading(false);
        }
    }

    const drawRegisterAddressHeader = useMemo(() => {
        return (
            <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
            >
                <Typography align='left' variant='subtitle1' >
                    管理者メッセージの更新
                </Typography>
            </Grid>
        )
    }, []);

    const drawLoading = useMemo(() => {
        const operation = crudType === 0 ? '取得' : '更新';

        return (
            <Backdrop className={classes.backdrop} open={isLoading} >
                <Slide direction="up" in={isLoading}>
                    <Card className={classes.loadingCard}>
                        <CardContent>
                            <ThemeProvider theme={circularTheme}>
                                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
                            </ThemeProvider>
                            <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
                        </CardContent>
                    </Card>
                </Slide>
            </Backdrop>
        );
    }, [crudType, isLoading]);

    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Card className={classes.root}>
                {
                    setAdminMessageErrMsg &&
                    <CardContent classes={{ root: classes.cardContent }}>
                        <Typography align='left' variant='caption' color='error'>
                            {setAdminMessageErrMsg}
                        </Typography>
                    </CardContent>
                }
                {
                    enableAdminMessage &&
                    <Fragment>
                        <CardContent classes={{ root: classes.cardContent }}>
                            {drawRegisterAddressHeader}
                            <TextField
                                className={`customScrollBar ${classes.inputTextField}`}
                                id='inputTextField'
                                multiline
                                rowsMax='10'
                                rows='10'
                                value={inputedText}
                                variant='outlined'
                                onChange={handleChangeInputedText}
                                FormHelperTextProps={{
                                    classes: {
                                        root: classes.errorMessage,
                                    },
                                }}
                                error={adminMessageErrMsg !== null}
                                helperText={adminMessageErrMsg}
                            />
                        </CardContent>
                        <CardActions classes={{ root: classes.cardActions }}>
                            <Button
                                variant='contained'
                                disabled={adminMessageErrMsg !== null}
                                onClick={handleClickRegisterBtn}
                                color='primary'
                                aria-label='register ips'
                            >
                                更新
                            </Button>
                        </CardActions>
                    </Fragment>
                }
            </Card>
            {drawLoading}
        </Grid>
    )
}
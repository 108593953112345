import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo } from 'react';

import BaseDialog from './BaseDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { SendAction } from '../common/JsonClass';
import { FloorWebSocket } from '../websocket/WebSocketFloor';
import { DialogTitle } from '@material-ui/core';

interface Props {
    // websocket: FloorWebSocket; // 使われていない？
}

// 公開したいメソッドの定義
export interface ChildHandler {
    setForceExitAlertDialogOpen: (open: boolean) => void
}

const ForceExitAlertDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        setForceExitAlertDialogOpen: (open: boolean) => {
            setIsOpen(open);
        }
    }));

    // キャンセル
    const handleClickClose = () =>{
        setIsOpen(false);
    }
    
    // 設定ダイアログの描画
    const drawDialog = useMemo(() => {
        return (
            <BaseDialog
                open={isOpen} 
                onClose={handleClickClose}
                aria-labelledby='ForceExitAlert-dialog-title'
                aria-describedby='ForceExitAlert-dialog-description'
                maxWidth={'md'}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle style={{padding: '7px 25px 9px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>
                    お知らせ
                </DialogTitle>
                <DialogContent>
                    本フロアはまもなく閉鎖します、ご注意ください。
                </DialogContent>
                <DialogActions>
                    <Button id='ForceExitAlert-dialog-OK-Button' onClick={handleClickClose} autoFocus style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100}}>
                        OK
                    </Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [isOpen]);

    return (
        <Fragment>
            {drawDialog}
        </Fragment>
    )
}

export const ForceExitAlertDialogRef = forwardRef(ForceExitAlertDialog);

/**
 * WebRTC接続時間が既定の時間を超えた時に表示するダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import AudioPlayer, { AudioId } from '../AudioPlayer';

interface Props {
    continueWebRtcShowDialogTime: number;
    handleDisconnectWebRtc: (isContinue: boolean) => void;
}

export interface CheckContinueWebRtcDialogHandler {
    open: () => void;
    close: () => void;
}

const CheckContinueWebRtcDialogComponent: ForwardRefRenderFunction<CheckContinueWebRtcDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);

    useImperativeHandle(ref, () => ({
        open: () => {
            setCount(props.continueWebRtcShowDialogTime);
            setOpen(true);
        },
        close: () => {
            setOpen(false);
        },
    }))

    const handleClose = (isContinue: boolean) => {
        setOpen(false);
        props.handleDisconnectWebRtc(isContinue);
    };

    useEffect(() => {
        if (!open) return;
        let temp = props.continueWebRtcShowDialogTime;
        console.log("会議の継続確認のカウントダウン開始（"+temp+"秒）");
        const interval = setInterval(() => {
            temp--;
            setCount(temp);
            if (temp === 0) {
                clearInterval(interval);
                handleClose(false);
            }
            AudioPlayer.play(AudioId.TimeLimit);
        }, 1000)
        return function cleanUp() {
            clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="disconnect-dialog-title"
                aria-describedby="disconnect-dialog-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="disconnect-dialog-title">{"ビデオ通話"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="disconnect-dialog-description">
                        <Typography>{"通話開始から長時間経過しています。このまま通話を継続しますか？"}</Typography>
                        <Typography>{"「終了する」を選択した場合、通話を終了して自席に戻ります。"}</Typography>
                        <Typography variant="body2">{"※このフロアに自席がない場合は、エントランスに戻ります。"}</Typography>
                        <Typography variant="body2">{"※" + props.continueWebRtcShowDialogTime + "秒以内に選択されない場合は、自動的に終了します。"}</Typography>
                        
                        <div style={{width: "100%", textAlign: "right"}}>{"残り " + count + "秒"}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        {"終了する"}
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary">
                        {"継続する"}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, count])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const CheckContinueWebRtcDialog = forwardRef(CheckContinueWebRtcDialogComponent);
import React, { useEffect, useState, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, ReactNode } from 'react'
import { Rnd, RndResizeCallback, RndResizeStartCallback } from 'react-rnd';
import { DraggableEventHandler } from 'react-draggable';
import useWindowSize from './useWindowSize';
import { Coord, Size } from './JsonClass';
import { Utility } from '../common/Utility';

export interface DraggableFrameProps {
    parentElementId?: string; // frameの親要素のID
    children?: ReactNode; // frameの子要素（frame内のコンテンツ） 
    minWidth?: number; // resize時の最小幅
    minHeight?: number; // resize時の最小高さ
    zIndex?: number;
    scrollbarWidth?: number; // Windowのスクロール幅（サイズ・位置計算に使用）
    enableResizing?: boolean;
    disableDragging?: boolean;
    style?: React.CSSProperties; // frameに適用したいスタイル
    defaultCoord?: Coord; // frameの初期位置
    defaultSize?: Size; // frameの初期サイズ
    defaultSize2?: Size; // frameの初期サイズ2
    handleResize:(size:Size) => void;
}

// 公開するメソッドの型
export interface DraggableFrameHandler {
    open: () => void;
    close: () => void;
    changeToAtOpen: () => void;
    changeToFullWidth: () => void;
    changeToFullHeight: () => void;
    changeToFullSize: () => void;
    onFrameDoubleClick: () => void;
}

const DraggableFrameComponent: ForwardRefRenderFunction<DraggableFrameHandler, DraggableFrameProps> = ({
    parentElementId = "root",
    children,
    minWidth = 100,
    minHeight = 100,
    zIndex = 100,
    scrollbarWidth = 0,
    enableResizing = false,
    disableDragging = true,
    style = {},
    defaultCoord = { x: 0, y: 0 },
    defaultSize = { width: 100, height: 100 },
    defaultSize2 = { width: 100, height: 100 },
    handleResize,
}, ref) => {
    const windowSize = useWindowSize();

    // state
    const [isShow, setShow] = useState(false);
    const [coord, setCoord] = useState({ x: 0, y: 0 });
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [isFullWidth, setFullWidth] = useState(false);
    const [isFullHeight, setFullHeight] = useState(false);
    const [isFullSize, setFullSize] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [sizeDC, setSizeDC] = useState({ width: 0, height: 0 });
    const [coordDC, setCoordDC] = useState({ x: 0, y: 0 });

    // refs
    const rndRef = useRef({} as Rnd | null);
    let parent: HTMLElement;

    // DraggableFrame内のメソッドを公開
    useImperativeHandle(ref, () => ({
        open() {
            setShow(true);
        },
        close() {
            setShow(false);
        },
        changeToAtOpen() {
            changeToAtOpen();
        },
        changeToFullWidth() {
            changeToFullWidth();
        },
        changeToFullHeight() {
            changeToFullHeight();
        },
        changeToFullSize() {
            changeToFullSize();
        },
        onFrameDoubleClick() {
            onFrameDoubleClick();
        }
    }))

    const resetSizeFlag = () => {
        setFullWidth(false);
        setFullHeight(false);
        setFullSize(false);
    }

    /**
     * useEffect
     */
    // mount時処理
    useEffect(() => {
        calcInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // UI open時処理
    useEffect(() => {
        if (isFullWidth || isFullHeight || isFullSize) {
            resetSizeFlag();
        }
        if (isShow) {
            calcAtOpen();
        } else {
            setLoading(true);
            calcInit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    // window resize時の処理
    useEffect(() => {
        if (!isFullWidth || !isFullHeight || !isFullSize) {
            calcAtRightAndBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize]);

    /**
     * size adjust
     */
    const setCoordAndSize = (coord: Coord, size: Size) => {
        rndRef.current?.updatePosition(coord);
        rndRef.current?.updateSize(size);
        setCoord(coord);
        setSize(size);
        handleResize(size);
    }
    // frameのサイズ・位置のリセット(画面左上に非表示で最小化)
    const calcInit = () => {
        parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { scrollTop, scrollLeft } = parent;
        const coord = {
            x: scrollLeft - scrollbarWidth,
            y: scrollTop
        };
        const size = {
            width: 1,
            height: 1
        };
        setCoordAndSize(coord, size);
    };
    // frame要素表示時のサイズ・位置 // TODO 初期位置座標の変更必要
    const calcAtOpen = () => {
        parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { x, y } = defaultCoord;
        //スマホ縦orPCの場合、defaultsizeで表示
        //スマホ横の場合、defaultsize2で表示
        const { width, height } = Utility.getType() != 1 ? defaultSize : defaultSize2;
        const { scrollTop, scrollLeft } = parent;
        const coord = {
            x: scrollLeft + x - scrollbarWidth,
            y: scrollTop + y
        };
        const size = {
            width,
            height
        };
        setCoordAndSize(coord, size);
    };
    const calcAtDoubleClick = () => {
        setCoordAndSize(coordDC, sizeDC);
    };
    // 共有要素がWindow外へ出た時のサイズ・位置
    const calcAtRightAndBottom = () => {
        parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = parent;
        const { x, y } = coord;
        const { width, height } = size;
        let newX = x;
        let newY = y;
        let isChangeCoord = false;
        //let isChangeSize = false;

        // resizeで共有要素がwindow外となったとき、位置を更新
        if (x + width > scrollLeft + offsetWidth - scrollbarWidth) {
            isChangeCoord = true;
            const calcX = offsetWidth - width - scrollbarWidth;
            newX = calcX < scrollLeft ? scrollLeft : calcX;
        }
        if (y + height > scrollTop + offsetHeight - scrollbarWidth) {
            isChangeCoord = true;
            const calcY = offsetHeight - height - scrollbarWidth;
            newY = calcY < scrollTop ? scrollTop : calcY;
        }
        if (isChangeCoord) {
            const newSize = { x: newX, y: newY }
            setCoord(newSize);
            rndRef.current?.updatePosition(newSize);
            isChangeCoord = false;
        }
    };
    // frameのサイズ・位置を幅最大表示用に計算する
    const culcFullWidth = () => {
        parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { scrollLeft, offsetWidth } = parent;
        rndRef.current?.updateSize({
            width: offsetWidth - scrollbarWidth,
            height: size.height
        });
        rndRef.current?.updatePosition({ x: scrollLeft, y: coord.y });
    };
    // frameのサイズ・位置を高さ最大表示用に計算する
    const culcFullHeight = () => {
        parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { scrollTop, offsetHeight, } = parent;
        rndRef.current?.updateSize({
            width: size.width,
            height: offsetHeight - scrollbarWidth
        });
        rndRef.current?.updatePosition({ x: coord.x, y: scrollTop });
    };
    // frameのサイズ・位置を最大表示用に計算する
    const culcFullSize = () => {
        parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { scrollTop, /* scrollLeft, offsetWidth, offsetHeight */ } = parent;
        //rndRef.current?.updateSize({ width: offsetWidth - scrollbarWidth, height: offsetHeight - scrollbarWidth });
        rndRef.current?.updateSize({ width: size.width, height: '100%' });
        rndRef.current?.updatePosition({ x: coord.x, y: scrollTop });
    };

    /**
     * change display
     */
    // open時初期表示にする
    const changeToAtOpen = () => {
        calcAtOpen();
        resetSizeFlag();
    };
    // 幅最大化表示にする
    const changeToFullWidth = () => {
        resetSizeFlag();
        culcFullWidth();
        setFullWidth(true);
    };
    // 高さ最大化表示にする
    const changeToFullHeight = () => {
        resetSizeFlag();
        culcFullHeight();
        setFullHeight(true);
    };
    // 最大化表示にする
    const changeToFullSize = () => {
        resetSizeFlag();
        culcFullSize();
        setFullSize(true);
    };

    /**
     * event handler
     */
    const onDrag: DraggableEventHandler = (e, data) => {
        const { x, y } = data;
        setCoord({ x, y });
    };
    const onDragStop: DraggableEventHandler = (e, data) => {
        const { x, y } = data;
        setCoord({ x, y });
    };
    const onResizeStart: RndResizeStartCallback = (e, dir, refToElement) => { };
    const onResize: RndResizeCallback = (e, dir, refToElement, delta, position) => { 
        const { width, height } = refToElement.style;
        const toNumber = (str: string) => Number(str.replace('px', ''));
        handleResize({ width: toNumber(width), height: toNumber(height) });
    };
    const onResizeStop: RndResizeCallback = (e, dir, refToElement, delta, position,) => {
        const { x, y } = position;
        const { width, height } = refToElement.style;
        const toNumber = (str: string) => Number(str.replace('px', ''));
        setCoord({ x, y });
        setSize({ width: toNumber(width), height: toNumber(height) });
        handleResize({ width: toNumber(width), height: toNumber(height) });
    };

    const onFrameDoubleClick = () => {
        if (isFullHeight) {
            calcAtDoubleClick();
            resetSizeFlag();
            //setCoord(coord);
            //setSize(size);
        } else {
            changeToFullHeight();
            setSizeDC(size);
            setCoordDC(coord);
        }
    };

    /**
     * 
     */
    const component = useMemo(() => {
        return (
            <Rnd
                ref={rndRef}
                style={{
                    visibility: isShow ? 'visible' : 'hidden',
                    zIndex,
                    ...style
                }}
                enableResizing={enableResizing}
                disableDragging={disableDragging}
                bounds={`#${parentElementId}`} // 移動可能範囲をCSSセレクターで指定
                maxWidth='100vw'
                maxHeight='100vh'
                minWidth={isShow ? minWidth : 1}
                minHeight={isShow ? minHeight : 1}
                default={{ ...defaultCoord, ...defaultSize }}
                onDrag={onDrag}
                onDragStop={onDragStop}
                onResizeStart={onResizeStart}
                onResize={onResize}
                onResizeStop={onResizeStop}
            >
                {children}
            </Rnd>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, enableResizing, disableDragging, isFullWidth, isFullHeight, isFullSize,
        isLoading, defaultCoord, defaultSize, children])

    return component;
}

export const DraggableFrame = forwardRef(DraggableFrameComponent);
import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import SystemFrame from './SystemFrame';
import { Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ApartmentIcon from '@material-ui/icons/Apartment';
import TimelineIcon from '@material-ui/icons/Timeline';
import LayersIcon from '@material-ui/icons/Layers';
import PeopleIcon from '@material-ui/icons/People';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
    },
    media: {
        height: 140,
    },
    dividerMargin: {
        marginBottom: '20px',
    },
    alert: {
        background: "#f44336",
        color: "#fff",
    },

}));

export default function SystemDashboard() {
    const [t] = useTranslation();
    const classes = useStyles();
    const { history } = useReactRouter();

    const [tenantSize, setTenantSize] = useState(0);
    const [floorSize, setFloorSize] = useState(0);
    const [userSize, setUserSize] = useState(0);
    const [officeSize, setOfficeSize] = useState(0);

    useEffect(() => {
        handleGetTenantSize();
        handleGetFloorSize();
        handleGetUserSize();
        handleGetOfficeSize();
    },[])

    const handleGetTenantSize = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/tenant/size', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
            }))
            .then((response: AxiosResponse) => {
                setTenantSize(response.data);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("getTenantSize error.");
                    console.log(err.response);
                }
            });
    }
    const handleGetFloorSize = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floor/size', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
            }))
            .then((response: AxiosResponse) => {
                setFloorSize(response.data);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("getFloorSize error.");
                    console.log(err.response);
                }
            });
    }
    const handleGetUserSize = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/user/size', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
            }))
            .then((response: AxiosResponse) => {
                setUserSize(response.data);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("getUserSize error.");
                    console.log(err.response);
                }
            });
    }
    const handleGetOfficeSize = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/office/size', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
            }))
            .then((response: AxiosResponse) => {
                setOfficeSize(response.data);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("getOfficeSize error.");
                    console.log(err.response);
                }
            });
    }

    return (
        <div className={classes.root}>
            <SystemFrame />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <Paper style={{ width: "100%", height: "80px" }}>
                            <div style={{ padding: "10px", display: "flex" }}>
                                <ApartmentIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                <div style={{ marginTop: "10px", width: "200px" }}>
                                    <Typography variant="subtitle2">Total Tenant</Typography>
                                    <Typography variant="subtitle1">{tenantSize}</Typography>
                                </div>
                                <div style={{ textAlign: "right", width: "100%" }}>
                                    <IconButton style={{ top: "5px" }}>
                                        <TimelineIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper style={{ width: "100%", height: "80px" }}>
                            <div style={{ padding: "10px", display: "flex" }}>
                                <LayersIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                <div style={{ marginTop: "10px", width: "200px" }}>
                                    <Typography variant="subtitle2">Total Floor</Typography>
                                    <Typography variant="subtitle1">{floorSize}</Typography>
                                </div>
                                <div style={{ textAlign: "right", width: "100%" }}>
                                    <IconButton style={{ top: "5px" }}>
                                        <TimelineIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper style={{ width: "100%", height: "80px" }}>
                            <div style={{ padding: "10px", display: "flex" }}>
                                <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                <div style={{ marginTop: "10px", width: "200px" }}>
                                    <Typography variant="subtitle2">Total User</Typography>
                                    <Typography variant="subtitle1">{userSize}</Typography>
                                </div>
                                <div style={{ textAlign: "right", width: "100%" }}>
                                    <IconButton style={{ top: "5px" }}>
                                        <TimelineIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper style={{ width: "100%", height: "80px" }}>
                            <div style={{ padding: "10px", display: "flex" }}>
                                <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                <div style={{ marginTop: "10px", width: "200px" }}>
                                    <Typography variant="subtitle2">Total Office</Typography>
                                    <Typography variant="subtitle1">{officeSize}</Typography>
                                </div>
                                <div style={{ textAlign: "right", width: "100%" }}>
                                    <IconButton style={{ top: "5px" }}>
                                        <TimelineIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper style={{ width: "100%", height: "80px" }} onClick={() => history.push('/systemyoutube')}>
                            <div style={{ padding: "10px", display: "flex" }}>
                                <YouTubeIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                <div style={{ marginTop: "10px", width: "200px" }}>
                                    <Typography variant="subtitle2">{t("YouTubeビデオ")}</Typography>
                                    <Typography variant="subtitle1">{floorSize}</Typography>
                                </div>
                                <div style={{ textAlign: "right", width: "100%" }}>
                                    <IconButton style={{ top: "5px" }}>
                                        <TimelineIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

            </main>
        </div>
    )
}
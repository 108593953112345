import React, { forwardRef, ForwardRefRenderFunction, Fragment, useImperativeHandle, useMemo, useState } from 'react';
import { isPropertySignature } from 'typescript';
// import { AvatarMenuData, User, Size } from '../common/JsonClass';
import { AvatarMenuData, Size } from '../common/JsonClass';
import OtherUser from './OtherUser';
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';

interface Props {
    scale: number;
    myUserSubId: string | null;
    handleOpenMemo: (groupId: number, groupName: string) => void;
    enabledMemoOtherUserMenu: boolean;
    myUser: User;
    myFloorName: string;
    getEnabledBusinessCard:() => boolean;
    kind: string | null;
    handleAddMyRoomUser: (id: number, subId:string) => void,
    handleDeleteMyRoomUser: (id: number, subId:string, seatNo:number) => void,
    handleClickMyRoomUserInfo: (user:User) => void,
    handleGoToSee: (subId:string) => void,
    enabledMyRoom: boolean,
    enabledChat: boolean,
    handleOpenChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => void;
    handleClickMyRoomMemo: (user:User) => void,
    handleClickMyRoomChat: (user:User) => void,
    isPrivacyGimmickOn?: (floorObjectId: number) => boolean,
    floorSize: Size,
}

export interface OtherUsersHandler {
    setUsers(users: User[]): void;
    getUsers(): User[];
    getUsersCount(): number;
    setAvatarMenuDataList: (avatarMenuDataList: AvatarMenuData[]) => void;
}

// export default function OtherUsers() {
const OtherUsersFunction: ForwardRefRenderFunction<OtherUsersHandler, Props> = (props, ref) => {
    
    const [state, setState] = useState({
        users: [] as User[],
        avatarMenuDataList: [] as AvatarMenuData[],
    })

    useImperativeHandle(ref, () => ({
        setUsers: (users: User[]) => {
            setState({
                ...state,
                users: users,
            })
            // console.info('otherUsers:'+users);
        },
        getUsers: () => {
            return [...state.users];
        },
        getUsersCount: () => {
            return state.users.length;
        },
        setAvatarMenuDataList: (avatarMenuDataList: AvatarMenuData[]) => {
            setState({
                ...state,
                avatarMenuDataList: avatarMenuDataList,
            })
        },
    }))

    const draw = useMemo(() => {
        return state.users.map((e) => {
            // マイルーム用描画で出勤してない
            //if(props.kind !== null && e.isCommuting === false){
            //    return "";
            //}
            
            return <OtherUser
                key={props.kind + "-otherusers-" + e.id}
                user={e}
                avatarMenuDataList={state.avatarMenuDataList}
                scale={props.scale}
                myUserSubId={props.myUserSubId}
                handleOpenMemo={props.handleOpenMemo}
                enabledMemoOtherUserMenu={props.enabledMemoOtherUserMenu}
                myUser={props.myUser}
                myFloorName={props.myFloorName}
                enabledBusinessCard={props.getEnabledBusinessCard()}
                kind={props.kind}
                handleAddMyRoomUser={props.handleAddMyRoomUser}
                handleDeleteMyRoomUser={props.handleDeleteMyRoomUser}
                handleClickMyRoomUserInfo={props.handleClickMyRoomUserInfo}
                handleGoToSee={props.handleGoToSee}
                enabledMyRoom={props.enabledMyRoom}
                enabledChat={props.enabledChat}
                handleOpenChat={props.handleOpenChat}
                handleClickMyRoomMemo={props.handleClickMyRoomMemo}
                handleClickMyRoomChat={props.handleClickMyRoomChat}
                isPrivacyGimmickOn={props.isPrivacyGimmickOn}
                floorSize={props.floorSize}
            />
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.users, state.avatarMenuDataList, props.scale, props.myUserSubId, props.kind]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const OtherUsers = forwardRef(OtherUsersFunction);
export const MyRoomOtherUsers = forwardRef(OtherUsersFunction);

export function viewDidload() {
    // alert("test");
    try {
    // eslint-disable-next-line no-undef
    loadComplete("loadComplete");
    }
    catch (err) {
    }
    }
    export function didloginFailed(err) {
        // alert("test");
        try {
        // eslint-disable-next-line no-undef
        loginFailed(err);
        }
        catch (err) {
        }
        }
        export function sessionTimeout(errorCode) {
            // alert("test");
            try {
            // eslint-disable-next-line no-undef
            sessionExpired("セッションタイムアウト");
            }
            catch (err) {
            }
            }
        export function didLogout(){
            // alert("test");
            try {
                // eslint-disable-next-line no-undef
                signOut("logout");
                }catch (err) {
                }
        }
export default class ZIndex {
    // 最背面
    static base: number = 0;
    // 装飾(床材) type=3000104
    static yuka: number = 1;
    // floor上の区画（座席島、会議室など）
    static floorSection: number = 100;
    // 席周りのオブジェクト（名札、PCなど）
    static seatObject: number = 200;
    // floor上のオブジェクト（立札、看板など）
    static floorObject: number =  300;
    // 装飾（観葉植物等)
    static floorDecoration: number =  310;
    // OtherUser関連の要素
    static otherUser: number = 400;
    // オリジナルオブジェクト
    static originalObject: number = 410;
    // MyUser関連の要素
    static myUser: number = 500;
    // UserInfoCard(プロフィール上表などを表示する要素)
    static userInfoCard: number = 600;
    // ユーザが見たいもの
    static userDocument: number = 650;
    // App UI （サインアウト、プライバシー、ミュートなどのボタン）
    static fixedUiLayer: number = 700;
    // 開発・試験用UI
    static experiment: number = 1000;
}
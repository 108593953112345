/**
 * 自習完了ダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { SELECT_NONE } from "../common/Constants";
import BaseDialog from './BaseDialog';
import {
    Chart,
    BarSeries,
    ArgumentAxis,
    ValueAxis,
    Legend,
} from '@devexpress/dx-react-chart-material-ui'; // 2.7.6をインストールすること
import ClearIcon from '@material-ui/icons/Clear';
import { Typography, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, InputAdornment, LinearProgress, IconButton, Switch, createMuiTheme, Divider, Tabs, Tab, Box, createStyles, Theme, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Utility } from '../common/Utility';
import JfsClient, { StudyHistoryInfo, StudyPlan, StudyPlanDetail, StudyRatingMasterInfo, StudySubjectMasterInfo } from '@fsi/jfs-sdk';
import DatePicker from "react-datepicker";
import { Label } from '@material-ui/icons';
import BarChart from '@material-ui/icons/BarChart';
import { ScaleObject, Stack, ValueScale } from "@devexpress/dx-react-chart";
import { User } from '../common/JsonClass';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        
        dialogTitle: {
            //maxWidth: tutorialWidth,
            background: '#57BBFF 0% 0% no-repeat padding-box',
        },
        
        tutorialFont: {
            //fontFamily: tutorialFont,
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        },
        avatarImg: {
            border: '4px solid #57BBFF',
            paddingLeft: 20,
            paddingRight: 20,
            // width: '100%',
            // height: '100%',
            // minWidth: 160,
        },
        pointStackContainer: {
            padding: 10,
            height: '100%',
        },
        chartBox: {
            height: 180,
            borderRadius: 1,
            // paddingTop: 50
        },
        settingTitle:{
            fontWeight:'bold', 
            fontSize: 16,
            fontFamily: 'Hiragino Maru Gothic StdN',
        },
        picker: {
            width: 150,
            height: 30,
            fontSize: 24,
            textAlign: 'center',
            margin: 10,
            marginLeft: 10,
        },
        grid: {
            margin: "auto",
        },
    }),
);

interface Props {
    openSelfStudyStartDialog: (open: boolean) => void;
    getMyUser: () => User;
    onJumpToMySeat: () => void;
}


export interface ChildHandler {
    openSelfStudyCompleteDialog: (open : boolean) => void;
}


const SelfStudyCompleteDialog: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const [isShowSelfStudyCompleteDialog, setShowSelfStudyCompleteDialog] = React.useState(false);
    const [studyHistoryList, setStudyHistoryList] = React.useState([] as StudyHistoryInfo[]);

    // 過去の自習履歴
    const [tabIndex, setTabIndex] = React.useState(0);
    const [selectDate, setSelectDate] = React.useState(new Date());
    const maxDate = new Date();
    const [totalHour, setTotalHour] = React.useState(0);
    const [totalMinute, setTotalMinute] = React.useState(0);
    const [maxMinute, setMaxMinute] = React.useState(180);
    const [subjectsList, setSubjectsList] = React.useState([] as StudySubjectMasterInfo[]);
    const [subjects, setSubjects] = React.useState([] as string[]);
    const [pastHistoryList, setPastHistoryList] = React.useState([] as any[]);

    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient } = jfsClient;
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
        openSelfStudyCompleteDialog: (open : boolean) => {
            setShowSelfStudyCompleteDialog(open);
        }
    }))

    /**
     * 振り返り入力ダイアログが開いた際にDBにアクセスし、本日の自習内容のリスト,設定可能な自習計画評価を取得する
     */
    useEffect(() => {
        if(!isShowSelfStudyCompleteDialog){
            return;
        }
        
        getStudyHistoryList();
        getSubject();
        getPastStudyHistory(0, selectDate);


    }, [isShowSelfStudyCompleteDialog])

    // 当日の自習履歴を取ってくる処理(async)
    const getStudyHistoryList = async () => {
        await httpClient.getTodayStudyHistory(sessionStorage.getItem("TABID") as string)
            .then((e: StudyHistoryInfo[]) => {
                setStudyHistoryList(e);
            }).catch(err => {
                console.log(err);
            });
    }

    const getSubject = async () => {
        // 教科一覧を取得する（※order順に取得すること）
        await httpClient.getStudySubjectMaster(sessionStorage.getItem("TABID") as string)
        .then((e: StudySubjectMasterInfo[]) => {
            setSubjectsList(e);
            const subjectsData: string[] =  e.map(u => (u.subjectName));
            setSubjects(subjectsData);
        }).catch(err => {
            console.log(err);
        });
    }

    const getPastStudyHistory = async (type: number, date: Date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        console.info(year + ':' + month);
        const convertedDate = year + month; 
        await httpClient.getPastStudyHistory(sessionStorage.getItem("TABID") as string, type, Number(convertedDate))
            .then((e: any[]) => {
                setPastHistoryList(e);
                const totalSummarys : number[] = e.map(item => item['totalSummary']);
                const max = Math.max(...totalSummarys);
                const maxMinutes = (Math.floor(max / 60) + 1) * 60;
                const sum = totalSummarys.reduce((total, num) => total + num, 0);
                const hour = Math.floor(sum / 60);
                const minutes = sum % 60;
                setMaxMinute(180 >= maxMinutes ? 180 : maxMinutes);
                setTotalHour(hour);
                setTotalMinute(minutes);
            }).catch(err => {
                console.log(err);
            });
    }

    // 「自習席を離れる」ボタン
    const handleClickCancel = () => {

        setStudyHistoryList([] as StudyHistoryInfo[]);
        setShowSelfStudyCompleteDialog(false);
        setTabIndex(0);
        setSelectDate(new Date());
        setSubjectsList([] as StudySubjectMasterInfo[]);
        setSubjects([] as string[]);
        setPastHistoryList([] as any[]);
        
        // エントランスに移動
        if(props.getMyUser().mySeat != null){
            props.onJumpToMySeat();
        }else{
            wsClient.sendMoveEntrance();
        }
    }

    // 「自習を行う」ボタン
    const handleClickButton = () => {

        setStudyHistoryList([] as StudyHistoryInfo[]);
        setShowSelfStudyCompleteDialog(false);
        setTabIndex(0);
        setSelectDate(new Date());
        setSubjectsList([] as StudySubjectMasterInfo[]);
        setSubjects([] as string[]);
        setPastHistoryList([] as any[]);

        // 自習開始ダイアログを開く
        props.openSelfStudyStartDialog(true);
    }

    // 日付変更・セット
    const handleChangeDate = (date: Date) => {
        getPastStudyHistory(1, date);
        setSelectDate(date);
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
        getPastStudyHistory(newTabIndex, selectDate);
        setTabIndex(newTabIndex);
    };

    // 表示デバイスチェック
    const isSmartPhone = (): boolean => {
        // スマホの場合はtrueを返却
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('iphone') > -1 || ua.indexOf('android') > -1) {
            return true;
        }
        return false;
    }

    // AxisLabel2(下のラベル)
    const AxisLabel2 = (props: any) => {
        const { text, x, y } = props;
        const date = new Date(text.split('-')[0], text.split('-')[1] - 1, text.split('-')[2]);
        const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
        const week = daysOfWeek[date.getDay()];
        // スマホの場合はラベルのフォントサイズを下げる
        if(isSmartPhone()){
            return (
                <Fragment>
                    <ArgumentAxis.Label style={{fontSize: '8px'}} {...props} y={y} text={text.split('-')[2] + '日'} />
                    <ArgumentAxis.Label style={{fontSize: '8px'}} {...props} y={y + 20} text={week} />
                </Fragment>
            )
        }
        return (
            <Fragment>
                <ArgumentAxis.Label style={{fontSize: '10px', fontWeight: 'bold'}} {...props} interval={2} y={y} text={text.split('-')[2] + '日'} />
                <ArgumentAxis.Label style={{fontSize: '10px', fontWeight: 'bold'}} {...props} interval={2} y={y + 20} text={week} />
            </Fragment>
        )
    }

    const formatTick =  (scale: ScaleObject) => (tick: string) => {
        
        const value = parseInt(tick);
        const hours = Math.floor(value / 60);
        const minutes = value % 60;
        if (minutes === 0) {
          return `${hours}h`;
        } else {
          return '';
        }
    }
    const Root = (props: any) => {
        return (
            <Legend.Root {...props} style={{ display: 'flex', flexWrap: 'nowrap', margin: 'auto', flexDirection: 'row', justifyContent: 'flex-end' }} />
        )
    };
    
    const Label = (props: any) => (
        <Legend.Label {...props} style={{ whiteSpace: 'nowrap' }} />
    );



    const drawSelectMonthContent = useMemo(() => {
        return (
            <Box margin="0px 0px 0px 10px" fontSize="18px" paddingLeft={10} width={60}>
                <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "80px", marginBottom: "15px" }}>
                    <DatePicker
                        className={classes.picker}
                        locale="ja"
                        selected={selectDate}
                        onChange={handleChangeDate}
                        maxDate={maxDate}
                        dateFormat="yyyy/MM"
                        showMonthYearPicker
                    />
                </Grid>
            </Box>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectDate]);


    const drawTodayStudyHistory = useMemo(() => {
        let totalText = '';
        if (studyHistoryList.length != 0) {
            const totalStudyMinutes = studyHistoryList[0].studySummary;
            if (totalStudyMinutes >= 60) {
                totalText = (Math.floor(totalStudyMinutes / 60)) + '時間' + (totalStudyMinutes % 60) + '分';
            } else {
                totalText = totalStudyMinutes + '分';
            }
    
            return (
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <div style={{color: '#707070', fontSize: 20, paddingBottom: 10}}>今日の自習履歴</div>
                    </Grid>
                    <Grid item xs={10} style={{marginLeft: -20, alignSelf: "center"}}>
                        <Grid container style={{paddingBottom: 20}}>
                            <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>時間</Grid>
                            <Grid item xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>科目</Grid>
                            <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>内容</Grid>
                            <Grid item xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>目標時間</Grid>
                            <Grid item xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>実績時間</Grid>
                        </Grid>
                        {studyHistoryList.map((history) => {
                            return (
                                <>
                                    <Grid container style={{ paddingBottom: 10 }}>
                                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{history.startTime.slice(0, 5)}~{history.endTime.slice(0, 5)}</Grid>
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{history.subjectMasterName}</Grid>
                                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{history.purposeMasterName}</Grid>
                                        {history.timerMinutes !== 0 ?
                                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{history.timerMinutes}分</Grid>
                                            :
                                            <Grid item xs={2} />
                                        }
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{history.studyMinutes}分</Grid>
    
                                    </Grid>
                                    {history.subjectMasterName === 'その他' || history.purposeMasterName === 'その他' ?
                                        <Grid container style={{ marginTop: -10 }}>
                                            <Grid item xs={3} />
                                            {history.subjectName !== '' ?
                                                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>({history.subjectName})</Grid>
                                                :
                                                <Grid item xs={2} />
                                            }
                                            {history.purposeName !== '' ?
                                                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>({history.purposeName})</Grid>
                                                :
                                                <Grid item xs={3} />
                                            }
                                            <Grid item xs={2} />
                                            <Grid item xs={2} />
                                        </Grid>
                                        :
                                        <></>
                                    }
                                </>
                            )
                        })}
                    </Grid>
                    <Grid item xs={10} style={{marginTop: 5, alignSelf: "center"}} >
                        <hr></hr>
                    </Grid>
                    <Grid item xs={10} style={{marginTop: 5, alignSelf: "center"}} >
                        <Grid container>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={2}>合計</Grid>
                            <Grid item xs={2}>{totalText}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }, [studyHistoryList])

    const drawPastStudyHistory = useMemo(() => {
        const monthTotalText = '月合計：' + totalHour + '時間' + totalMinute + '分'
        if (pastHistoryList.length != 0) {
            return (
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <div style={{ color: '#707070', fontSize: 20, paddingBottom: 10 }}>過去の自習履歴</div>
                    </Grid>
                    <Grid item xs={10} style={{ marginLeft: -20, alignSelf: "center" }}>
                        <Grid container >
                            <Grid item xs={2} >
                                <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' style={{ width: 800 }}>
                                    <Tab label="直近2週間" style={{ minWidth: '18%' }} />
                                    <Tab label="月別" style={{ minWidth: '18%' }} />
                                </Tabs>
                            </Grid>
                            <Grid item xs={4} >
                                {tabIndex === 1 && drawSelectMonthContent}
                            </Grid>
                            <Grid item xs={6} />
                        </Grid>
                        <Grid item xs={12} style={{ height: 28 }}>
                            <div style={{ fontWeight: 'bold', fontFamily: 'Hiragino Maru Gothic StdN', fontSize: 20, textAlign: "end", marginTop: 30, marginRight: 10 }}>
                                {tabIndex === 1 ? monthTotalText : ""}
                            </div>
                        </Grid>
                        <Box className={classes.chartBox}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Chart
                                    data={pastHistoryList}
                                    height={150 + maxMinute / 60 * 50}
                                    width={tabIndex === 1 ? 1200 : 600}
                                >
                                    <ValueScale modifyDomain={() => [0, maxMinute]} />
                                    <ArgumentAxis
                                        labelComponent={AxisLabel2}
                                    />
                                    <ValueAxis tickFormat={formatTick} indentFromAxis={10} />
    
                                    {subjectsList.filter(u => (u.displayOrder > 0))
                                        .map((u) => (
                                            <BarSeries
                                                name={u.subjectName}
                                                valueField={u.subjectName}
                                                argumentField="date"
                                                color={u.graphColor}
                                                barWidth={0.8}
                                            />
                                        ))}
                                    <Legend position="top" rootComponent={Root} labelComponent={Label} />
                                    <Stack
                                        stacks={[
                                            { series: subjects },
                                        ]}
                                    />
                                </Chart>
                            </div>
                        </Box>
                    </Grid>
    
                </Grid>
            )
        }
    }, [pastHistoryList, subjects, tabIndex, totalHour, totalMinute])

    const drawSelfStudyCompleteDialog = useMemo(() => {
        return (
            <BaseDialog
                open={isShowSelfStudyCompleteDialog}
                aria-labelledby="selfstudycomplete-dialog-title"
                aria-describedby="selfstudycomplete-description"
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    style:{
                        border: '5px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="selfstudystart-dialog-title" style={{ userSelect: 'none', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>自習完了</DialogTitle>
                <DialogContent style={{marginLeft: 10, paddingTop: 12, paddingBottom: 12}}>
                    <Grid container>
                        <Grid item xs={12} style={{paddingRight: 20}}>
                            <Grid container justify='center' alignItems='center' style={{paddingBottom: 20}}>
                                <Grid item xs={12}>
                                    <div style={{ fontSize: 16 }}>お疲れ様です。以下より自習履歴を参照ください。<br />また、引き続き自習を行う場合は「自習を行う」ボタンを<br/>終了する場合は「自習席を離れる」ボタンを押してください。</div>
                                </Grid>
                            </Grid>
                            {drawTodayStudyHistory}
                            {drawPastStudyHistory}
                        </Grid>
                            {/* <Divider orientation='vertical' flexItem style={{marginTop: 12}}></Divider> */}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleClickButton()} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 130, marginBottom: 20}}>自習を行う</Button>
                    <Button color='primary' onClick={() => handleClickCancel() } style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 130, marginRight: 30, marginBottom: 20 }}>席をはなれる</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [isShowSelfStudyCompleteDialog, studyHistoryList, pastHistoryList, subjects, tabIndex, totalHour, totalMinute])

    return (
        <Fragment>
            {drawSelfStudyCompleteDialog}
        </Fragment>
    )
}

export const SelfStudyCompleteDialogRef = forwardRef(SelfStudyCompleteDialog);
import { makeStyles, IconButton, Tooltip, Button } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Crop169Icon from '@material-ui/icons/Crop169';
import SyncIcon from '@material-ui/icons/Sync';
import Zoom from '@material-ui/core/Zoom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { forwardRef, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { DraggableEventHandler } from "react-draggable";
import { Rnd } from 'react-rnd';
import useWindowSize from '../common/useWindowSize';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';
import ZIndex from '../common/ZIndex';

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        height: "100%",
    }, 
    windowHeader: {
        height: "30px",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        width: "100%",
        backgroundColor: "lightgray",
        padding: 0,
    },
    windowHeaderItemLeft: {
        textAlign: "left",
    },
    windowHeaderItemCenter: {
        textAlign: "center",
        height: "30px",
        lineHeight: "30px",
    },
    windowHeaderItemRight: {
        height: "30px",
        margin: "0 10px 0 auto",
        textAlign: "right",
    },
    windowInlineFrame: {
        width: "100%",
        height: "calc(100% - 30px)",
        backgroundColor: "white",
        padding: 0,
    },
    dialogTitle: {
        textAlign: "center",
    },
    dialogContent: {
        marginLeft: "20px",
        textAlign: "left",
    }
}));

interface Props {
    openUrl: string,
    close: any,
    zIndex: number,
    parentElementId: string,
    iPhoneLayout: boolean,
    handleClickReloadIcon: (iswhiteBoard: boolean) => void,
    handleClickFrame: (type: number) => void,
};

// 公開したいメソッドの定義
export interface HelloFrameHandler {
};

const HelloFrameComponent: React.ForwardRefRenderFunction<HelloFrameHandler, Props> = (props, ref) => {
    const scrollBarWidth = 8;
    const bottomButtonHeight = props.iPhoneLayout ? 49 : 98;
    const parentElementId = props.parentElementId;
    const classes = useStyles();    
    const rndRef = useRef({} as Rnd | null);
    const windowSize = useWindowSize();
    const [frameSrc, setFrameSrc] = useState("");
    const [openDialogCloseConfirm, setOpenDialogCloseConfirm] = useState(false);
    const [windowFull, setWindowFull] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [inlineFrameWidth, setInlineFrameWidth] = useState(0);
    const [inlineFrameHeight, setInlineFrameHeight] = useState(0);
    const [zIndex, setZIndex] = useState(props.zIndex);
    
    useLayoutEffect(() => {
        setFrameSrc(props.openUrl);
        setInlineFrameWidth(Math.floor(0.7 * window.innerWidth));
        setInlineFrameHeight(Math.floor(0.7 * window.innerHeight));
        setPositionX(100);
        setPositionY(100);
    }, [props.openUrl])

    // window resize時の処理
    useEffect(() => {
        if (windowFull) {
            adjustFrameFullSize();
        } else {
            calcAtRightAndBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize, windowFull]);

    useEffect(() => {
        setZIndex(props.zIndex);
    }, [props.zIndex])

    const toNumber = (str: string) => {
        return Number(str.replace("px", ""));
    }

    const adjustFrameFullSize = () => {
        if (rndRef && rndRef.current) {
            rndRef.current.updateSize({ 
                width: windowSize.width + "px",
                height: windowSize.height - bottomButtonHeight + "px"
            });
        }
    }

    const calcAtRightAndBottom = () => {
        if (rndRef && rndRef.current) {
            let newX = positionX;
            let newY = positionY;
            let newWidth = inlineFrameWidth;
            let newHeight = inlineFrameHeight;
            let isChangeCoord = false;
            let isChangeSize = false;

            if ((positionX + inlineFrameWidth) > (windowSize.width - scrollBarWidth)) {
                isChangeCoord = true;
                newX = windowSize.width - inlineFrameWidth - scrollBarWidth;
                if (newX < 0) {
                    isChangeSize = true;
                    newX = 0;
                    newWidth = windowSize.width - scrollBarWidth;
                }
            }

            if ((positionY + inlineFrameHeight) > (windowSize.height - bottomButtonHeight)) {
                isChangeCoord = true;
                newY = windowSize.height - inlineFrameHeight - bottomButtonHeight;
                if (newY < 0) {
                    isChangeSize = true;
                    newY = 0;
                    newHeight = windowSize.height - bottomButtonHeight;
                }
            }

            if (isChangeCoord) {
                setPositionX(newX);
                setPositionY(newY);
                rndRef.current.updatePosition({ 
                    x: newX,
                    y: newY
                });

                if (isChangeSize) {
                    setInlineFrameWidth(newWidth);
                    setInlineFrameHeight(newHeight);
                    rndRef.current.updateSize({ 
                        width: newWidth,
                        height: newHeight
                    });
                }
            }          
        }
    }

    const onDrag: DraggableEventHandler = (e, data) => {
        // zIndex をHelloのインラインフレームと画面共有で切り替えるための処理
        if (zIndex !== (ZIndex.userDocument + 1)) {
            props.handleClickFrame(2);
        }
    };

    const onDragStop: DraggableEventHandler = (e, data) => {
        // zIndex をHelloのインラインフレームと画面共有で切り替えるための処理
        if (zIndex !== (ZIndex.userDocument + 1)) {
            props.handleClickFrame(2);
        }                

        setPositionX(data.x);
        setPositionY(data.y);
    };

    const handleClose = () => {
        props.close();
    }

    const handleReload = () => {
        props.handleClickReloadIcon(false);
    }

    const handleWindowSizeChange = () => {
        if (rndRef && rndRef.current) {
            if (windowFull) {
                rndRef.current.updatePosition({ 
                    x: positionX,
                    y: positionY
                });
    
                rndRef.current.updateSize({ 
                    width: inlineFrameWidth,
                    height: inlineFrameHeight
                });
            } else {
                rndRef.current.updatePosition({ 
                    x: 0,
                    y: 0
                });

                // サイズの変更は、useEffect -> adjustFrameFullSize が呼び出されることで行われる
            }
        }

        setWindowFull(windowFull === false);
    }

    return (
        <Rnd ref={rndRef}
            bounds={`#${parentElementId}`} // 移動可能範囲をCSSセレクターで指定
            disableDragging={windowFull}
            enableResizing={windowFull === false}
            cancel=".cancel"
            style={{ zIndex: zIndex, boxShadow: "0 10px 25px 0 rgba(0, 0, 0, .5)", margin: 0 }}
            default={{
                x: 100,
                y: 100,
                width: Math.floor(0.7 * window.innerWidth) + "px",
                height: Math.floor(0.7 * window.innerHeight) + "px",
            }}
            onDrag={onDrag}
            onDragStop={onDragStop}
            onResizeStop={(e, direction, ref, delta, position) => {
                setInlineFrameWidth(toNumber(ref.style.width));
                setInlineFrameHeight(toNumber(ref.style.height));
                // console.log(ref.style.width + ", " + ref.style.height);
            }}            
            >
                <div className={classes.root}>
                <div className={classes.windowHeader}>
                    <span className={classes.windowHeaderItemLeft}>
                        <Tooltip title="リロード" TransitionComponent={Zoom} aria-label="reload">
                            <IconButton
                                size="small" 
                                aria-label="reload"
                                onClick={handleReload}
                                onTouchEnd={handleReload}    // iPad対応
                            >
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                    <span className={classes.windowHeaderItemCenter}>
                    資料/ホワイトボード
                    </span>
                    <span className={classes.windowHeaderItemRight}>
                        {windowFull ? 
                        <Tooltip title="通常表示" TransitionComponent={Zoom} aria-label="init screen">
                            <IconButton
                                size="small" 
                                aria-label="reset"
                                onClick={handleWindowSizeChange}
                                onTouchEnd={handleWindowSizeChange}   // iPad対応
                            >
                                <Crop169Icon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="全画面表示" TransitionComponent={Zoom} aria-label="full screen">
                            <IconButton
                                size="small" 
                                aria-label="full screen"
                                onClick={handleWindowSizeChange}
                                onTouchEnd={handleWindowSizeChange}    // iPad対応
                            >
                                <CropFreeIcon />
                            </IconButton>
                        </Tooltip>
                        }

                        &nbsp;
                        <Tooltip title="閉じる" TransitionComponent={Zoom} aria-label="close screen">
                            <IconButton 
                                size="small" 
                                onClick={() => { setOpenDialogCloseConfirm(true) }} 
                                onTouchEnd={() => { setOpenDialogCloseConfirm(true) }} 
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
                <iframe className={classes.windowInlineFrame} title="資料/ホワイトボード" src={frameSrc} scrolling="no" />
                </div>
            <Dialog open={openDialogCloseConfirm}
                style={{ pointerEvents: 'auto' }}
                fullWidth={true}
                maxWidth={"sm"}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle style={{padding: '7px 25px 9px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>確認</DialogTitle>
                <DialogContentText>
                    <div className={classes.dialogContent}>
                    参加者全員の資料/ホワイトボードを閉じますか？<br />
                    閉じるとデータが失われる可能性があります。
                    </div>
                </DialogContentText>
                <DialogActions>
                <div>
                    <Button variant="contained" onClick={ handleClose } style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>&nbsp;
                    <Button variant="contained" onClick={() => { setOpenDialogCloseConfirm(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
                </div>
                </DialogActions>
            </Dialog>

        </Rnd>
    )
}

export const HelloFrame = forwardRef(HelloFrameComponent);
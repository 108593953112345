import React, { Fragment, useState, useEffect, useMemo, ChangeEvent, forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import { chkJsonFormat, ObjectMaster, User } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import { Utility } from '../common/Utility';
import JfsClient, { FloorObject } from '@fsi/jfs-sdk';

// icons
import { Switch } from '@material-ui/core';

const privacySwitchStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
}

interface Props {
    className?: string,
    floorObject: FloorObject,
    sendPrivacyState: (id: number, state: boolean) => void,
    role: string,
    gimmickObject?: ObjectMaster,
    getMyUser: () => User,
}

// 公開したいメソッドの定義
export interface ChildHandler {
    checkGimmickState: () => void;
    isIt: (floorObjectId: number) => boolean;
    isPrivacyGimmickOn: () => boolean;
}

const PrivacyRoomComponent: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
//export default function PrivacyRoom(props: Props) {
    // ON/OFFの取得
    function GetInText(text:string, objecttype:number, elm:string ) {
        if (Utility.isPrivacyRoom(objecttype)) {
            // Jsonフォーマットチェック
            let jf: chkJsonFormat = new chkJsonFormat();
            let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_PRIVACYROOM, text);
            
            if(jsonCheck[0]){
                // Jsonから取得
                let intextJson = jsonCheck[2];
                if(elm==='state'){
                    text = intextJson.state;
                }
            }
        }
        return text;
    }

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        checkGimmickState() {
            setGimmickState(isGimmickOn());
        },
        isIt(floorObjectId: number) {
            return props.floorObject.id == floorObjectId;
        },
        isPrivacyGimmickOn() {
            return isGimmickOn();
        }
    }));

    // 摺りガラスギミックが表示されるか判定
    function isGimmickOn() {
        let myUser = getMyUser();
        if (!privacyState.checkedA) {
            return false;
        }
        return !(myUser.seat && myUser.seat.floorObjectId === floorObject.id);
    }

    const { className, role, floorObject, gimmickObject, getMyUser } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const [privacyState, setPrivacyState] = useState({
        checkedA: Boolean(GetInText(text2, objectMaster.type, 'state'))
    });
    const [gimmickState, setGimmickState] = useState(isGimmickOn());
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const handlePrivacyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPrivacyState({ ...privacyState, [event.target.name]: event.target.checked });
        props.sendPrivacyState(id, event.target.checked);
    };

    const kanbanLeft = useMemo(
        () => (objectMaster.width - 50)
        , [objectMaster.width]);
    
    const gimmickLeft = useMemo(
        () => gimmickObject? (offsetLeft + objectMaster.width - gimmickObject.width) : offsetLeft
        , [offsetLeft, objectMaster.width, gimmickObject]);
    
    const gimmickTop = useMemo(
        () => offsetTop
        , [offsetTop]);
    
    useEffect(() => {
        setGimmickState(isGimmickOn());
    }, [privacyState]);

    useEffect(() => {
        setPrivacyState({ ...privacyState, checkedA: Boolean(GetInText(text2, objectMaster.type, 'state')) });
    }, [text2]);
    
    // 摺りガラスギミックアニメーション定義
    useEffect(() => {
        if (!gimmickObject) {
            return;
        }

        const animationName = `gimmickPR-${gimmickObject.height}px-${gimmickObject.frames}f`;
        const headElement = document.getElementsByTagName("head")[0];
        for (let i = headElement.childNodes.length - 1; i >= 0; i--) {
            const node = headElement.childNodes[i];
            if (node.nodeType === 1 && node.textContent && node.textContent.indexOf(animationName) >= 0) {
                // すでに定義済みの場合
                return;
            }
        }

        // 着座時のCSSスプライト定義
        const cssAnimation = document.createElement('style');
        const rules = document.createTextNode(`@keyframes ${animationName} { to { background-position: 0 -${gimmickObject.height * (gimmickObject.frames - 1)}px; } }`);
        cssAnimation.appendChild(rules);
        document.getElementsByTagName("head")[0].appendChild(cssAnimation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gimmickObject]);

    // 摺りガラススイッチON/OFFアイコン
    const privacySwitch = useMemo(() => {
        return(
            <Switch
                checked={privacyState.checkedA}
                onChange={handlePrivacyChange}
                color="primary"
                name="checkedA"
                inputProps={{'aria-label': 'primary checkbox'}}
            />
        )}, [privacyState]);

    const drawPrivacySwitch = useMemo(() => {
        let isAdmin = role.match(/ROLE_ADMIN/) != null;
        return (isAdmin ?
            <Fragment>
                <div
                    key={`meetingRoomPrivacySwitch${id}`}
                    id={`meetingRoomPrivacySwitch${id}`}
                    className={className}
                    style={{
                        ...privacySwitchStyle,
                        left: kanbanLeft,
                        width: 30,
                        pointerEvents: "auto",
                    }}
                >
                    {privacySwitch}
                </div>
            </Fragment>
        :
            <Fragment/>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    )}, [role, privacyState]);

    const drawFrostedGlass = useMemo(() => {
        return (gimmickObject && gimmickState ?
        <Fragment>
            <div 
                className={NEED_TO_POINTER_CAPTURE}
                onMouseDown={e => e.preventDefault()} // window外へのgif画像コピー禁止
                onMouseMove={e => e.preventDefault()} // window外へのgif画像コピー禁止
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    top: gimmickTop,
                    left: gimmickLeft,
                    width: gimmickObject.width,
                    height: gimmickObject.height,
                    zIndex: ZIndex.myUser + 6,
                    //backgroundImage: `url(./api/user/object/picture/${gimmickObject.id})`,
                    backgroundImage: `url(${httpClient.createObjectImgUrl(gimmickObject.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                    backgroundSize: `${gimmickObject.width}px ${gimmickObject.height * gimmickObject.frames}px`,
                    backgroundRepeat: "no-repeat",
                    animation: `gimmickPR-${gimmickObject.height}px-${gimmickObject.frames}f ${1 / gimmickObject.fps}s steps(${gimmickObject.frames - 1}) forwards`,
                    pointerEvents: "none",
                }}
            />
        </Fragment> : <Fragment />
    )}, [gimmickState]);

    const draw = useMemo(() => (
        <Fragment>
        <div
            key={`meetingRoom${id}`}
            id={`meetingRoom${id}`}
            className={className}
            style={{
                ...SELECT_NONE,
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorSection,
                pointerEvents: "none",
            }}
        >
            {drawPrivacySwitch}    
        </div>
        {drawFrostedGlass}
        </Fragment>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text2, role, privacyState, gimmickState]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    );
}

export const PrivacyRoom = forwardRef(PrivacyRoomComponent);
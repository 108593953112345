/**
 * iPad対応-12 iPadの場合、FAMがアクティブでなくなり切断されるのでメッセージを出す
 * 外部ビデオ通話システム連携前の確認ダイアログ（iPadのみ使う予定）
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

interface Props {
    videoURL: string;
    handleVideoURLConfirmResult: (result: number, url: string) => void;
}

export interface VideoURLStartConfirmDialogHandler {
    open: () => void;
    close: () => void;
}

const VideoURLStartConfirmDialogComponent: ForwardRefRenderFunction<VideoURLStartConfirmDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
        close: () => {
            setOpen(false);
        },
    }))

    const handleClose = (result: number) => {
        setOpen(false);
        props.handleVideoURLConfirmResult(result, props.videoURL);
    };

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                //onClose={handleClose}
                aria-labelledby="v-startconfirm-dialog-title"
                aria-describedby="v-startconfirm-dialog-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="v-startconfirm-dialog-title">{"外部ビデオ通話システム連携"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="v-startconfirm-dialog-description">
                        <Typography>{"外部ビデオ通話システムへ参加します。"}</Typography>
                        <Typography>{"一定時間経過後、FAMcampusは切断されます。"}</Typography>
                        <Typography>{"FAMcampusに戻る場合はもう一度ログインしてください。"}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(1)} color="primary">
                        {"ＯＫ"}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [open])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const VideoURLStartConfirmDialog = forwardRef(VideoURLStartConfirmDialogComponent);
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import theme from './test/Thema';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SnackbarProvider } from 'notistack';
import JfsClient from '@fsi/jfs-sdk';

// snackbarを外部コンポーネント化すると下記エラーが発生し動作しなかった。
// TypeError: Cannot read property 'getBoundingClientRect' of null
//import SnackbarLabel from "./common/SnackbarLabel"

const client = JfsClient.getInstance();

const primaryUrl = '';
const frontDomainName = '';
// export const serviceAPIKEY = 'testapikey';
export const serviceAPIKEY = 'QGnfDuaMui3q';

const siteKey = () => {
    console.log('sitekey document.documentURI:'+document.documentURI);
    if (document.domain === "localhost") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "intra.famoffice.jp") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "business.famoffice.jp") {
        return "6Lfxo_4aAAAAAPtNvJ_PDBC9pPb5Yl4Axl1dL7PS";
    } else if (document.domain === "earth.famoffice.jp") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "aidwd6q71wmt7usp3sb3hn7li.famoffice.jp") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "rt8ritjhyu5ypzdcn8mdbmi4g.famoffice.jp") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "youth.famoffice.jp") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "bengal.magmeet.net") {
        return "6LeGIqkaAAAAANsqWJfDwSNT2QM7PwF9PLtM8HNY";
    } else if (document.domain === "bengal2.magmeet.net") {
        return "6LeGIqkaAAAAANsqWJfDwSNT2QM7PwF9PLtM8HNY";
    } else if (document.domain === "bengal-test1.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test2.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test3.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test4.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test5.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test6.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test7.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test8.magmeet.net") {
        return "6LfkEMoUAAAAAHn7kcQZaQTM_QvBp7hJPpymvD0t";
    } else if (document.domain === "bengal-test9.magmeet.net") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "bengal-test10.magmeet.net") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "bengal-test11.magmeet.net") {
        return "6LcuKgEbAAAAAOEIi66hzZ3aID2grfJMUQ3RVkxG";
    } else if (document.domain === "famoffice.magmeet.net") {
        return "6LeCl7UaAAAAAAQmBMHDoEU6ifT5wk4_FbugDhez";
    } else if (document.domain === "www.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "dev.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "dev2.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "shikiten.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "famparty.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "famevent.coarea.jp") {
        return "6Ldc3cEbAAAAANAHTD3m0BgAlnUkoEqjL-C9r9OU";
    } else if (document.domain === "sys.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "jobofferceremony.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "tradefair.coarea.jp") {
        return "6Lczf1QaAAAAAGafzRJ8Xle0Xc8ZN7Ej3QbU5Wpn";
    } else if (document.domain === "datahealth2021.famevent.jp") {
        return "6LeLKMEcAAAAAKKLW4m78co_CO0WYsrY6kEb1F42";
    } else if (document.domain === "famevent.jp") {
        return "6LeFSf4cAAAAAHFtx7SzkX83CAPFUurMt9ye4EZr";
    } else if (document.domain === "develop.famschool.jp") {
        return "6Lf4JB0eAAAAAIpvPkRbQ8DmmoDmpn9jSCwkKMTA";
    // campus用のキー変更
    // } else if (document.domain === "service.famcampus.jp") {
    //    return "6LfuCOQeAAAAAFSKEs12cmtLC9TVA150wyohEluY";
    } else if (document.domain === "famcampus.jp") {
        return "6LcEFKweAAAAADy_mHYtfrW6OuiVNcliafe4vIVC";
    } else if (document.domain === "service.staging-armadillo.famcampus.jp") {
        return "6Lfle50fAAAAAGI3Bp3nz4hL3LeyPIG4XapBdknV";
    } else if (document.domain === "service.develop2-buffalo.famcampus.jp") {
        return "6LfbfqUfAAAAAJtCg7oC-s41KxXoKbEY9eu-zWjx";
	} else if (document.domain === "visc.famcampus.jp") {
		return "6LdnozYnAAAAABq7-r4daC75F6eXXkCIky0jqLmZ";
	} else if (document.domain === "visc-rear.famcampus.jp") {
		return "6LdnozYnAAAAABq7-r4daC75F6eXXkCIky0jqLmZ";
    } else {
        if(document.documentURI.indexOf("dev-fampaas-ahead.com") !== -1 ||
                document.documentURI.indexOf("dev-fampaas-front.com") !== -1 ||
                document.documentURI.indexOf("magmeet.net") !== -1 ) {
            return "6LcLOgwfAAAAAA20FqdP31Es2wWj8h5o6Cse1teX";
        }
        else if(document.documentURI.indexOf("fampaas-front.jp") !== -1 ) {
            return "6LeApwolAAAAAMUlCXAqck3BxAmu-0Uh81FVCUsD";
        }
        else if(document.documentURI.indexOf("ara-poc.fampaas.jp") !== -1 ) {
            return "6LdJKEUoAAAAAC0i18CFA9pZJ6EKWlSdqoCUlG3Y";
        }
        // for campus
        else if(document.documentURI.indexOf("://service.famcampus.jp") !== -1) {
            return "6LdnozYnAAAAABq7-r4daC75F6eXXkCIky0jqLmZ";
        }
        else if(document.documentURI.indexOf("://service-rear.famcampus.jp") !== -1) {
            return "6LdY_jYnAAAAAO2wfOxhwWE98Zh6ZBfXHARoeowX";
        }
        else if(document.documentURI.indexOf("://service.famschool.jp") !== -1) {
            return "6Ld1AjcnAAAAAHfZZnuMj-vt-m5oPpzVr2Sg1duI";
        }
        else if(document.documentURI.indexOf("://service-rear.famschool.jp") !== -1) {
            return "6LdZBTcnAAAAAJfDMCPF-aMqry6Wqt2R1fYCIbtI";
        }
        else if(document.documentURI.indexOf("space.famcampus.jp") !== -1 ) {
            return "6LdJKEUoAAAAAC0i18CFA9pZJ6EKWlSdqoCUlG3Y";
        }
        else if(document.documentURI.indexOf("fampaas.jp") !== -1 ) { // fampaas.jp含むものは全て引っかかるので最後に移動
            return "6Ld9owolAAAAAJDmyzm3Qnqi0naElPaTrXGTLqne";
        }
        console.log("サイトキーの設定に失敗しました")
        return "";
    }
}

export const siteKeyV2 = () => {
    console.log('siteKeyV2 document.documentURI:'+document.documentURI);
    if (document.domain === "localhost") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "intra.famoffice.jp") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "business.famoffice.jp") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "earth.famoffice.jp") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "aidwd6q71wmt7usp3sb3hn7li.famoffice.jp") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "rt8ritjhyu5ypzdcn8mdbmi4g.famoffice.jp") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "youth.famoffice.jp") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "bengal.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal2.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test1.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test2.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test3.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test4.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test5.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test6.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test7.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test8.magmeet.net") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "bengal-test9.magmeet.net") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "bengal-test10.magmeet.net") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "bengal-test11.magmeet.net") {
        return "6LegugsbAAAAAKY8tOlZUnwKJVW466whMunf03pI";
    } else if (document.domain === "famoffice.magmeet.net") {
        return "6LeCl7UaAAAAAAQmBMHDoEU6ifT5wk4_FbugDhez";
    } else if (document.domain === "www.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "dev.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "dev2.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "shikiten.coarea.jp") {
        return "6Lc4lKkaAAAAAPeFMLgYEeoBb6iu1ICNJLSYT5g7";
    } else if (document.domain === "famparty.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "famevent.coarea.jp") {
        return "6LeO3sEbAAAAAF_JNWvMbedvHkDLvBWT_HmHPLNE";
    } else if (document.domain === "sys.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "jobofferceremony.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "tradefair.coarea.jp") {
        return "6LcS8csbAAAAABrTx1JPERE9UBpsXK3r0Ux_xxNE";
    } else if (document.domain === "datahealth2021.famevent.jp") {
        return "6LdSL8EcAAAAAJJbwJxLGlIvhcVHgton4qaVBGks";
    } else if (document.domain === "famevent.jp") {
        return "6Le5Sv4cAAAAANJlBnWiWd3CthmNYw06sT1_xwI2";
    } else if (document.domain === "develop.famschool.jp") {
        return "6Lf5Jh0eAAAAAEXaCQ1Ypr3mzN0uy6pj8jNMQQ3x";
    // campus用のキー変更
    // } else if (document.domain === "service.famcampus.jp") {
    //     return "6LdXCeQeAAAAAKmyIQziVhmRW2QKDfh-8woTKsXu";
    } else if (document.domain === "famcampus.jp") {
        return "6LdGFaweAAAAADDcssT3499eFAihDlLQmEC3-Mq0";
    } else if (document.domain === "service.staging-armadillo.famcampus.jp") {
        return "6LfOfp0fAAAAAKGSeuXoX5UE7Y08zhjjewDZYoXu";
    } else if (document.domain === "service.develop2-buffalo.famcampus.jp") {
        return "6LfugqUfAAAAAIkSUSvyS7Z7lqb7dWPiz-kwWjeK";
	} else if (document.domain === "visc.famcampus.jp") {
		return "6LdO_DYnAAAAAKm509riOAYwo2naNVpfy-CoowU0";
	} else if (document.domain === "visc-rear.famcampus.jp") {
		return "6LdO_DYnAAAAAKm509riOAYwo2naNVpfy-CoowU0";
    } else {
        if(document.documentURI.indexOf("dev-fampaas-ahead.com") !== -1 ||
                document.documentURI.indexOf("dev-fampaas-front.com") !== -1 ||
                document.documentURI.indexOf("magmeet.net") !== -1 ) {
            return "6LeCpQolAAAAAOpkZe-hXjeItnG5bMknjZDKg1mA";
        }
        else if(document.documentURI.indexOf("fampaas-front.jp") !== -1 ) {
            return "6LeApwolAAAAAMUlCXAqck3BxAmu-0Uh81FVCUsD";
        }
        else if(document.documentURI.indexOf("ara-poc.fampaas.jp") !== -1 ) {
            return "6LfRKkUoAAAAAOXQVrMjE8DaLe4pmyMseSG3uLcR";
        }
        // for campus
        else if(document.documentURI.indexOf("://service.famcampus.jp") !== -1) {
            return "6LdO_DYnAAAAAKm509riOAYwo2naNVpfy-CoowU0";
        }
        else if(document.documentURI.indexOf("://service-rear.famcampus.jp") !== -1) {
            return "6LdqADcnAAAAABjJguXBySqEOc-UAHWoroq9tUQn";
        }
        else if(document.documentURI.indexOf("space.famcampus.jp") !== -1 ) {
            return "6LfRKkUoAAAAAOXQVrMjE8DaLe4pmyMseSG3uLcR";
        }
        else if(document.documentURI.indexOf("://service.famschool.jp") !== -1) {
            return "6LdIAzcnAAAAAELqtH2EXlwfXN3eCwen0t60We3-";
        }
        else if(document.documentURI.indexOf("://service-rear.famschool.jp") !== -1) {
            return "6LfKBTcnAAAAAB5Vbj-i6M_R0tdnB1BAFv3MOMxI";
        }
        else if(document.documentURI.indexOf("://bifsbxlz.fampaas.jp") !== -1) {
            return "6LeLk8UnAAAAAK1ovJjtAqKae6IvZOcBpwB7T-U1";
        }
        else if(document.documentURI.indexOf("://dora.famcampus.jp") !== -1) {
            return "6LeLk8UnAAAAAK1ovJjtAqKae6IvZOcBpwB7T-U1";
        }
        else if(document.documentURI.indexOf("://dora.fampaas.jp") !== -1) {
            return "6LeLk8UnAAAAAK1ovJjtAqKae6IvZOcBpwB7T-U1";
        }
        else if(document.documentURI.indexOf("://dora-rear.fampaas.jp") !== -1) {
            return "6LeLk8UnAAAAAK1ovJjtAqKae6IvZOcBpwB7T-U1";
        }
        else if(document.documentURI.indexOf("fampaas.jp") !== -1 ) { // fampaas.jp含むものは全て引っかかるので最後に移動
            return "6Ld9owolAAAAAJDmyzm3Qnqi0naElPaTrXGTLqne";
        }
        console.log("サイトキーの設定に失敗しました")
        return "";
    }
}

// const snackbarStyle: React.CSSProperties = {
//     display: "flex",
//     alignItems: "center",
//     padding: "0 12px",
//     height: 46,
//     borderRadius: 6,
//     fontSize: 12.5,
//     color: "#f0f0f0",
//     background: "grey"
// }

client
  .init(primaryUrl, serviceAPIKEY, frontDomainName)
  .then(() => {
    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <GoogleReCaptchaProvider reCaptchaKey={siteKey()}>
                <SnackbarProvider
                    maxSnack={3}
                    // anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    // content={(key, message) => <div id={`snackbar${key}`}>{message}</div>}
                >
                    <App />
                </SnackbarProvider>
            </GoogleReCaptchaProvider>
        </ThemeProvider>
        , document.getElementById('root'));
    })
    .catch((err) => {
    console.error('sdk init error');
    });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import isIp from 'is-ip';

import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import RefreshIcon from '@material-ui/icons/Refresh';

import { userListConst } from './UserListConst';
import { Utility } from '../common/Utility';
import JfsClient from '@fsi/jfs-sdk';

const MAX_LIST_LENGTH = 255;
const Message = {
    OPERATIONAL_ATTENTION: '改行区切りでIPアドレスを入力してください。',
    FAILED_GET_DATA: 'データの取得に失敗しました。',
    OVER_MAX_LIST_LENGTH: `IPアドレスが${MAX_LIST_LENGTH}件を超えています。`,
    INVALID_IP_FORMAT: 'IPアドレスの形式が正しくありません。',
    UNEXPECTED: userListConst.unexpectedMessage,
    REQUEST_ERROR: userListConst.requestError.message,
} as const;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '50%',
        minWidth: 300,
        maxWidth: 450,
    },
    cardContent: {
        padding: '0 40px',
    },
    cardActions: {
        paddingLeft: 40,
        paddingRight: 40,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    inputTextField: {
        width: '100%',
        background: '#FFFFFF',
        borderRadius: 4,
        color: '#A39F98',
    },
    errorMessage: {
        ...theme.typography.caption,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingCard: {
        width: 150,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface IpInfo {
    id: number;
    ipAddress: string;
    ipv: number;
    tenantId: number;
    createdAt: string;
}

interface ResEnabledWhiteList {
    tenantId: number;
    enabledWhiteList: boolean;
}

/**
 * API Request
 */
// const getEnabledWhiteList = async (tabId: string): Promise<AxiosResponse<ResEnabledWhiteList>> => {
//     try {
//         const res: AxiosResponse<ResEnabledWhiteList> = await axios.get(
//             '/api/v1/admin/tenant/enabledwhitelist',
//             { params: { tabId } }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

// const updateEnabledWhiteList = async (tabId: string, enabledWhiteList: boolean): Promise<AxiosResponse<ResEnabledWhiteList>> => {
//     try {
//         const res: AxiosResponse<ResEnabledWhiteList> = await axios.patch(
//             '/api/v1/admin/tenant/enabledwhitelist',
//             { tabId, enabledWhiteList }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

// const getWhiteList = async (tabId: string, ipv: number = 0): Promise<AxiosResponse<IpInfo[]>> => {
//     try {
//         const res: AxiosResponse<IpInfo[]> = await axios.get(
//             '/api/v1/admin/whitelist/ip',
//             { params: { tabId, ipv } }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

// const registerWhiteList = async (tabId: string, ips: string[], ipv: number = 0): Promise<AxiosResponse<IpInfo[]>> => {
//     try {
//         const res: AxiosResponse<IpInfo[]> = await axios.post(
//             '/api/v1/admin/whitelist/ip',
//             { tabId, ips, ipv }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

/**
 * Utility
 */
const ipListToText = (list: IpInfo[]): string => {
    let text = '';
    list.forEach((d, i) => text = `${text}${d.ipAddress}\n`);
    return text;
}

const textToList = (text: string): string[] => {
    return text.split('\n').filter(t => t !== '');
}

export default function WhiteList() {
    const tabId = sessionStorage.getItem('TABID') || '';
    const classes = useStyles();
    const { history } = useReactRouter();

    const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
    const [isLoading, setLoading] = useState(false);
    const [enabledWhiteList, setEnabledWhiteList] = useState(false);
    const [inputedText, setInputedText] = useState('');
    const [enabledWhiteListErrMsg, setEnabledWhiteListErrMsg] = useState<ReactNode>(null);
    const [whiteListErrMsg, setWhiteListErrMsg] = useState<ReactNode>(null);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const resetDisplayData = () => {
        setCrudType(0);
        setLoading(false);
        setInputedText('');
        //setEnabledWhiteListErrMsg(null);
        setWhiteListErrMsg(null);
    }

    /**
     * API Request
     */
    const getEnabledWhiteList = async (tabId: string): Promise<ResEnabledWhiteList> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResEnabledWhiteList> = await axios.get(
            //     '/api/v1/admin/tenant/enabledwhitelist',
            //     { params: { tabId } }
            // );
            const res: ResEnabledWhiteList = await httpClient.getEnabledWhiteList(tabId);

            return res;
        } catch (err) {
            throw err;
        }
    }

    const updateEnabledWhiteList = async (tabId: string, enabledWhiteList: boolean): Promise<ResEnabledWhiteList> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResEnabledWhiteList> = await axios.patch(
            //     '/api/v1/admin/tenant/enabledwhitelist',
            //     { tabId, enabledWhiteList }
            // );
            const res: ResEnabledWhiteList = await httpClient.updateEnabledWhiteList(tabId, enabledWhiteList);
            return res;
        } catch (err) {
            throw err;
        }
    }

    const getWhiteList = async (tabId: string, ipv: number = 0): Promise<IpInfo[]> => {
        try {
            // SDKにない
            // const res: AxiosResponse<IpInfo[]> = await axios.get(
            //     '/api/v1/admin/whitelist/ip',
            //     { params: { tabId, ipv } }
            // );
            const res: IpInfo[] = await httpClient.getWhiteList(tabId, ipv);
            return res;
        } catch (err) {
            throw err;
        }
    }

    const registerWhiteList = async (tabId: string, ips: string[], ipv: number = 0): Promise<IpInfo[]> => {
        try {
            // SDKにない
            // const res: AxiosResponse<IpInfo[]> = await axios.post(
            //     '/api/v1/admin/whitelist/ip',
            //     { tabId, ips, ipv }
            // );

            const res: IpInfo[] = await httpClient.registerWhiteList(tabId, ips, ipv);
            return res;
        } catch (err) {
            throw err;
        }
    }

    const validateInputedText = (text: string): boolean => {
        let isValidLength = true;
        let isValidIp = true;
        let msgList: string[] = [];

        // text を listへ変換（""は除外）
        const ipList = textToList(text);

        // 件数チェック
        isValidLength = ipList.length <= MAX_LIST_LENGTH;
        if (!isValidLength) {
            msgList.push(Message.OVER_MAX_LIST_LENGTH);
        }

        // IPチェック
        const invalidIpList = ipList.filter(ip => !isIp.v4(ip));
        if (invalidIpList.length > 0) {
            isValidIp = false;
            msgList.push(`下記の${Message.INVALID_IP_FORMAT}`);
        }

        // メッセージをセット
        if (isValidLength && isValidIp) {
            setWhiteListErrMsg(null);
        } else {
            setWhiteListErrMsg(drawError(msgList, invalidIpList));
        }

        return isValidLength && isValidIp;
    }

    /**
     * error handle
     */
    const handleEnabledWhiteListError = (err: any) => {
        const msgList: string[] = [];

        if (err.response) {
            if (err.response.status >= 500) {
                msgList.push(Message.UNEXPECTED);
            } else if (err.response.status === 403) {
                const signinpage = Utility.getSigninPage();
                history.push({
                    pathname: signinpage
                });
                return;
            } else {
                msgList.push(Message.FAILED_GET_DATA);
            }
            setEnabledWhiteListErrMsg(drawError(msgList, []));
            return;
        } else if (err.request) {
            setEnabledWhiteListErrMsg(Message.REQUEST_ERROR);
            return;
        }
    }

    const handleWhiteListError = (err: any) => {
        const msgList: string[] = [];
        const invalidIpList: string[] = [];

        if (err.response) {
            if (err.response.status >= 500) {
                msgList.push(Message.UNEXPECTED);
            } else if (err.response.status === 403) {
                const signinpage = Utility.getSigninPage();
                history.push({
                    pathname: signinpage
                });
                return;
            } else if (err.response.data === 'OVER_MAX_LIST_LENGTH') {
                msgList.push(Message.OVER_MAX_LIST_LENGTH);
            } else if (err.response.data.errorMessage === 'INVALID_IP_FORMAT') {
                msgList.push(`下記の${Message.INVALID_IP_FORMAT}`);
                Object.assign(invalidIpList, err.response.data.ngIps);
            } else {
                msgList.push(Message.FAILED_GET_DATA);
            }
            setWhiteListErrMsg(drawError(msgList, invalidIpList));
            return;
        } else if (err.request) {
            setWhiteListErrMsg(Message.REQUEST_ERROR);
            return;
        }
    }

    const drawError = useCallback((messageList: string[] = [], invalidIpList: string[] = []) => {
        return (
            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                {
                    messageList.length > 0 &&
                    <ul style={{ margin: 5, paddingLeft: 10 }}>
                        {messageList.map(msg => <li>{msg}</li>)}
                    </ul>
                }
                {
                    invalidIpList.length > 0 &&
                    <ul style={{ margin: 0, paddingLeft: 30 }}>
                        {invalidIpList.map(ip => <li>{ip}</li>)}
                    </ul>
                }
            </div>
        )
    }, []);

    /**
     * handle data
     */
    const fetchEnabledWhiteList = async () => {
        try {
            const res = await getEnabledWhiteList(tabId);
            const flag = res?.enabledWhiteList;
            setEnabledWhiteList(flag);
        } catch (err) {
            console.error(err);
            handleEnabledWhiteListError(err);
        }
    }

    const fetchWhiteList = async () => {
        try {
            resetDisplayData();
            setLoading(true);
            const res = await getWhiteList(tabId, 0);
            const list = res;
            const text = ipListToText(list);
            setInputedText(text);
        } catch (err) {
            console.error(err);
            handleWhiteListError(err);
        } finally {
            setLoading(false);
        }
    }

    /**
     * useEffect
     */
    useEffect(() => {
        fetchEnabledWhiteList();
        fetchWhiteList();
    }, []);

    /**
     * event handlers
     */
    const handleChangeEnabledWhiteList = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const res = await updateEnabledWhiteList(tabId, !enabledWhiteList);
            const flag = res.enabledWhiteList;
            setEnabledWhiteList(flag);
        } catch (err) {
            console.error(err);
            handleEnabledWhiteListError(err);
        }
    }

    const handleClickRefresh = () => {
        fetchWhiteList();
    }

    const handleChangeInputedText = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 1 && event.target.value === '\n') {
            return false;
        }
        validateInputedText(event.target.value);
        setInputedText(event.target.value);
    }

    const handleClickRegisterBtn = async () => {
        try {
            setCrudType(1);
            setLoading(true);
            const ipList = textToList(inputedText);// 整形済みのinputedTextをlistへ変換
            const res = await registerWhiteList(tabId, ipList, 0);
            const list = res;
            const text = ipListToText(list);
            setInputedText(text);
        } catch (err) {
            console.error(err);
            handleWhiteListError(err);
        } finally {
            setLoading(false);
            setCrudType(0);
        }
    }

    /**
     * parts draw
     */
    const drawEnabledWhiteListSwitch = useMemo(() => {
        return (
            <Tooltip title='ホワイト機能のON・OFF'>
                <Switch
                    checked={enabledWhiteList}
                    disabled={enabledWhiteListErrMsg !== null}
                    onChange={handleChangeEnabledWhiteList}
                    color='primary'
                    name='enabledWhiteList'
                    inputProps={{ 'aria-label': 'enabledWhiteList' }}
                />
            </Tooltip>
        )
    }, [enabledWhiteList, enabledWhiteListErrMsg]);

    const drawRegisterAddressHeader = useMemo(() => {
        return (
            <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
            >
                <Typography align='left' variant='subtitle1' >
                    IPアドレス登録
                </Typography>
                <Tooltip title='ホワイトリストの再取得'>
                    <IconButton size='small' onClick={handleClickRefresh}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        )
    }, []);

    const drawLoading = useMemo(() => {
        const operation = crudType === 0 ? '取得' : '登録';

        return (
            <Backdrop className={classes.backdrop} open={isLoading} >
                <Slide direction="up" in={isLoading}>
                    <Card className={classes.loadingCard}>
                        <CardContent>
                            <ThemeProvider theme={circularTheme}>
                                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
                            </ThemeProvider>
                            <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
                        </CardContent>
                    </Card>
                </Slide>
            </Backdrop>
        );
    }, [crudType, isLoading]);

    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Card className={classes.root}>
                <CardHeader
                    action={drawEnabledWhiteListSwitch}
                    title='ホワイトリスト'
                    titleTypographyProps={{
                        align: 'left',
                        variant: 'h6',
                    }}
                />
                {
                    enabledWhiteListErrMsg &&
                    <CardContent classes={{ root: classes.cardContent }}>
                        <Typography align='left' variant='caption' color='error'>
                            {enabledWhiteListErrMsg}
                        </Typography>
                    </CardContent>
                }
                {
                    enabledWhiteList &&
                    <Fragment>
                        <CardContent classes={{ root: classes.cardContent }}>
                            {drawRegisterAddressHeader}
                            <TextField
                                className={`customScrollBar ${classes.inputTextField}`}
                                id='inputTextField'
                                multiline
                                rowsMax='10'
                                rows='10'
                                value={inputedText}
                                onChange={handleChangeInputedText}
                                variant='outlined'
                                placeholder={Message.OPERATIONAL_ATTENTION}
                                FormHelperTextProps={{
                                    classes: {
                                        root: classes.errorMessage,
                                    },
                                }}
                                error={whiteListErrMsg !== null}
                                helperText={whiteListErrMsg}
                            />
                        </CardContent>
                        <CardActions classes={{ root: classes.cardActions }}>
                            <Button
                                variant='contained'
                                disabled={whiteListErrMsg !== null}
                                onClick={handleClickRegisterBtn}
                                color='primary'
                                aria-label='register ips'
                            >
                                登録
                            </Button>
                        </CardActions>
                    </Fragment>
                }
            </Card>
            {drawLoading}
        </Grid>
    )
}
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, TableContainer, Paper, TableHead, TableCell, TableRow, Table, TableBody, TableSortLabel, TablePagination, TextField, Theme, Typography } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { BasicContract } from '../common/JsonClass';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof BasicContract;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    // { id: 'tenantId', numeric: false, disablePadding: true, label: 'テナントID' },
    // { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
    // { id: 'webRtcBasicContractBytes', numeric: false, disablePadding: true, label: '通信量制限' },
    { id: 'contractYearMonth', numeric: false, disablePadding: true, label: '契約年月' },  
    { id: 'numberContracts', numeric: false, disablePadding: true, label: '契約ユーザ数' },
    { id: 'updateDatetime', numeric: false, disablePadding: true, label: '更新日' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof BasicContract) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof BasicContract) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1) * 3,
            overflow: 'scroll',
            height: '100vh',
        },
        media: {
            height: 140,
        },
        dividerMargin: {
            marginBottom: '20px',
        },
        alert: {
            background: "#f44336",
            color: "#fff",
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 500,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            backgroundColor: 'aliceblue',
        },
    }),
);

interface Props {
    masterData: { [index: string]: [{ id: number, name: string }] };
}

export interface SystemContractUserSizeHandler {
    open: (id: number) => void;
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;

    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}

// export default function OtherUsers() {
const SystemContractUserSizeComponent: ForwardRefRenderFunction<SystemContractUserSizeHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof BasicContract>('id');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [basicContract, setBasicContract] = React.useState([] as BasicContract[]);
    const [open, setOpen] = useState(false);
    const [selectTenantId, setSelectTenantId] = useState(0);

    const [state, setState] = useState({
        tenantId: 0,
        tenantName: '',
        contractYearMonth: 0,
        numberContracts: 0,
        updateDatetime: '',
        webRtcBasicContractBytes: 0,
        updateYearMonth: 0,
        currentDays: 0, 
    });
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));

    // テナント追加の入力データをクリア
    const clearState = () => {
        let clear = [] as BasicContract[];
        setBasicContract(clear); 

        setState({
            ...state,
            tenantId: 0,
            tenantName: '',
            contractYearMonth: 0,
            numberContracts: 0,
            updateDatetime: '',
            webRtcBasicContractBytes: 0,
            updateYearMonth: 0,
            currentDays: 0, 
        })
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, basicContract.length - page * rowsPerPage);

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id.toString())
            setSelectTenantId(id);
            setOpen(true);
        },
    }))

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof BasicContract) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = basicContract.map((n) => n.id.toString());
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        // refSystemFloorInfo.current.open(id);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // サーバーからデータを取得して表示する
        if (selectTenantId > 0) {
            // alert("selId:" + selectTenantId);
            handleGetContractUserSizeById();
        } else {
            clearState();
        }
    }, [selectTenantId])

    const handleGetContractUserSizeById = () => {
        clearState();
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/tenant/contract', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                tenantId: selectTenantId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as BasicContract[];
                // alert("tenantId:" + result[0].tenantId + "  tenantName:" + result[0].tenantName);
                // alert("currentDays:" + result[0].currentDays);
                if(result[0].tenantName === ""){
                    // 既存基本契約がない場合
                    // alert("このテナントは基本契約が切れているため、内容を変更できません。");
                    setFinishInfo(new FinishInfo({
                        message: "このテナントは基本契約が切れているため、内容を変更できません。"
                    }));
                    handleClose();
                }else if(result[0].currentDays >= 27){
                    // 既存基本契約がない場合
                    // alert("翌月分の契約内容更新期日（毎月27日0時まで）を過ぎているため、基本契約内容を変更できませんでした。");
                    setFinishInfo(new FinishInfo({
                        message: "翌月分の契約内容更新期日（毎月27日0時まで）を過ぎているため、基本契約内容を変更できませんでした。"
                    }));
                    handleClose();
                }else{
                    // 既存基本契約がある場合
                    setBasicContract(result);                    
                    setState({
                        ...state,
                        tenantId: result[0].tenantId,
                        tenantName: result[0].tenantName,
                        contractYearMonth: result[0].contractYearMonth,
                        updateYearMonth: getNextYearMonth(result[0].currentYearMonth),
                        numberContracts: result[0].numberContracts, 
                        webRtcBasicContractBytes: result[0].webRtcBasicContractBytes, 
                        currentDays: result[0].currentDays,
                    });
                }
                setOrder('desc');
                setOrderBy('contractYearMonth');
            })
            .catch(() => {
                console.log("login error.");
            });
    }

    // 指定年月の翌月を算出
    const getNextYearMonth = (yearMonth: number):number => {
        let nextYearMonth = 0 as number;
        if(yearMonth % 100 === 12){
            nextYearMonth = yearMonth/100;
            nextYearMonth = parseInt(nextYearMonth.toString(), 10) + 1;
            nextYearMonth = nextYearMonth * 100 + 1;
        }else{
            nextYearMonth = yearMonth + 1;
        }
        return nextYearMonth;
    }

    const handleClose = () => {
        setSelectTenantId(0);
        setOpen(false);
    };

    // 翌月の契約ユーザ数を更新
    const handleUpdateUserSize = () => {
    
        if (state.numberContracts === 0) {
            alert("契約ユーザ数が空です。");

        } else {
            if (window.confirm("テナント " + state.tenantName + " の翌月の契約ユーザ数を更新します。よろしいですか？")) {
                let updateWebRtcBasicContractBytes = state.numberContracts * 2 * 1024 * 1024 * 1024 as number;
                // alert("updateWebRtcBasicContractBytes:" + updateWebRtcBasicContractBytes);
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/basiccontract/update', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        tenantId: state.tenantId.toString(),
                        updateYearMonth: state.updateYearMonth.toString(),
                        webRtcBasicContractBytes: updateWebRtcBasicContractBytes.toString(),
                        numberContracts: state.numberContracts.toString(),
                    }))
                    .then((response: AxiosResponse) => {
                        let msg = response.data as string;
                        // alert("result:" + msg);
                        if (msg.startsWith("ERR") || msg === "NG") {
                            if (msg === "ERR01") {
                                setFinishInfo(new FinishInfo({
                                    message: "翌月分の契約内容更新期日（毎月27日0時まで）を過ぎているため、基本契約内容を変更できませんでした。"
                                }));
                            }
                        }else{
                            // alert("テナント " + state.tenantName + " の契約ユーザ数の更新が完了しました。");
                            setFinishInfo(new FinishInfo({
                                message: "テナント " + state.tenantName + " の登録が完了しました。", password: msg
                            }));
                        }
                    })
                    .catch(() => {
                        // alert("テナント " + state.tenantName + " の契約ユーザ数の更新に失敗しました。");
                        setFinishInfo(new FinishInfo({
                            message: "テナント " + state.tenantName + " の登録に失敗しました。"
                        }));
                    });
                    handleClose();
            } else {
                alert("更新をキャンセルしました。");
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        //alert(name + ":" + target.value);

        setState(() => {
            return { ...state, [name]: target.value };
        });
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>翌月分の契約ユーザ数変更</DialogTitle>
                <DialogContent style={{ padding: "20px 20px" }}>
                    <Box fontSize="18px"><b>テナントID：{state.tenantId}　テナント名：{state.tenantName}</b></Box>
                    <br></br>
                    <Box fontSize="16px"><b>　契約ユーザ数の更新履歴</b></Box>
                    <Paper className={classes.paper} elevation={3} style={{ padding: "10px" }}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={basicContract.length}
                                />
                                <TableBody>
                                        {stableSort(basicContract, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id.toString());
                                            // const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell component="th" scope="row" padding="none">{row.contractYearMonth}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">{row.numberContracts}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">{row.updateDatetime}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 23 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={basicContract.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>

                    <TextField
                        margin="dense"
                        id="currentYearMonth"
                        name="currentYearMonth"
                        label="更新年月"
                        variant={'outlined'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        value={state.updateYearMonth}
                        type="number"
                        disabled={true}
                    />
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="numberContracts"
                                name="numberContracts"
                                label="契約ユーザー数"
                                variant={'outlined'}
                                type={"number"}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.numberContracts}
                                required
                            />
                        </Grid>
                    </Grid>
                    
                    {/* <Box margin="5px 0px" textAlign="left" fontSize="15px">【注意】翌月の契約ユーザ数の更新期日は、毎月27日0時までです。</Box> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                    <Button id="submit" onClick={handleUpdateUserSize} color="primary">　　更新　　</Button>
                </DialogActions>
            </Dialog>

            <Dialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText>{finishInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export const SystemContractUserSize = forwardRef(SystemContractUserSizeComponent);
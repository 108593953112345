import React, { Fragment, LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import useReactRouter from 'use-react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/styles';
import axios, { AxiosResponse } from 'axios';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LoginJson } from '../common/JsonClass';
import AudioPlayer from './AudioPlayer';
import NoSleepWrapper from './NoSleepWrapper';  // iPad対応-3
import ReCAPTCHA from "react-google-recaptcha";
import { siteKeyV2 } from '..';
import fsiLogo from '../img/fsiLogo.png';
import { RequireReloadDialog, RequireReloadDialogHandler } from "./RequireReloadDialog";
import { appFrontVersion } from '../common/Version';
import { Utility } from '../common/Utility';
import JfsClient, { JfsError } from '@fsi/jfs-sdk';
import { serviceAPIKEY } from './../index';
import { informationURL } from '../common/DocumentURL';

function Copyright() {

  //let p = require('../../package.json');

  return (
    <Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.famcampus.jp">
          FAMcampus
      </Link>{' '}
        {new Date().getFullYear()}
        {'.　'}

      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Ver "}{appFrontVersion}　
      </Typography>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
  },
  image: {
    backgroundImage: `url(../service_info/famcampus_login_image.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  },
  paper: {
    border: '6px solid #57BBFF',
    borderRadius: '25px',
    marginTop: '50px',
    padding: '20px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    // margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '42px',
    background: '#006FBC 0% 0% no-repeat padding-box',
    fontFamily: 'Hiragino Maru Gothic StdN',
    '&:hover': {
        background: '#107FCC',
    },
  },
  notify: {
    marginTop: '50px',
    overflow: 'auto',
    //height: '250px',
    maxHeight: '270px',
    textAlign: 'left',
  },
  paper2: {
    marginTop: '0px',
    padding: '0px',
    backgroundColor:'rgba(255,255,255,0.8)',
    // margin: theme.spacing(1, 2),
    display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'center',
  },
  fsilogo: {
    marginTop: '30px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  famOfficelogo: {
    //marginTop: '50px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //transform: 'translateY(-40%)',
  },
  famOfficeText: {
    marginTop: '30px',
    //padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Hiragino Maru Gothic StdN',
    color: '#676767',
  },
  copyright: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  '& . MuiOutlinedInput-root':{
    borderRadius: '15px',
  },
}));

/*const textFieldTheme = createMuiTheme({
  overrides:{
    MuiInputBase:{
      input:{
        color: '#006FBC',
      },
    },
  },
})*/

export default function Login2() {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { history } = useReactRouter();

  const recaptcha = React.useRef(0);
  const recaptchaTokenV3 = React.useRef("");

  const ref = useRef("" as any);

  const [loginState, setLoginState] = useState({
    id: "",
    password: "",
  })
  const [errMsgOnLoginId, setErrMsgOnLoginId] = useState('');
  const [errMsgOnPassword, setErrMsgOnPassword] = useState('');
  const [errMsgOnLogin, setErrMsgOnLogin] = useState('');
  const [information, setInformation] = useState('');
  const [isShowReCaptchav2, setShowReCaptchav2] = useState(false);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState("");
  const [termsOfUseURL, setTermsOfUseURL] = useState('');

  const jfsClient = JfsClient.getInstance();
  const { httpClient, JFS_ERRORS  } = jfsClient;

  const errorMessageBox = {
    loginFailed: 'ユーザーが登録されていない、またはID、パスワードが間違っています。',
    authFailed: '認証処理に失敗しました。システム管理者に連絡してください。',
    loginIdNull: 'IDを入力してください。',
    passwordNull: 'パスワードを入力してください。',
  }

  const requireReloadDialogRef = useRef({} as RequireReloadDialogHandler);
  //sessionStorage.setItem("SIGNINPAGE", "/");
  Utility.setSigninPage("/");

  useEffect(() => {
    function preLogin() {
      var params = new URLSearchParams();
      axios.post('/login/prelogin', params)
        .then((e: AxiosResponse) => {
          // console.log("prelogin OK!");
          getRecaptcha();
          getInformation();
          getTermsOfUseURL();   // 利用規約URL取得
        }).catch(err => {
          console.log(err);
        });
    }
    function getRecaptcha() {
      // var params = new URLSearchParams();
      // axios.post('/login/recaptcha', params)
      httpClient.getRecaptcha(serviceAPIKEY)
        .then((e: number) => {
          // console.log("recaptcha OK!");
          recaptcha.current = e;
        }).catch(() => {
        });
    }

    // iOS 音声の自動再生対応
    AudioPlayer.loadData();
    // iPad対応-3
    NoSleepWrapper.initNoSleep();
    // preLogin();
    getRecaptcha();
    getInformation();

    function getInformation() {

      console.log("getInformation documentURI ["+document.documentURI+"]");
      console.log("getInformation domain ["+document.domain+"]");
      let nowDate = new Date();
      let rURL = informationURL + "?" + nowDate.getDate();
      setInformation(rURL);
      //axios.get(document.documentURI+'/'+informationURL)
      //axios.get(Utility.BASE_URL+'/service_info/information.html')
      // .then((e: AxiosResponse) => {
      //   console.log("getInformation OK ["+e.data+"]");
      //   setInformation(e.data);
      // }).catch((err) => {
      //   console.log("getInformation NG ["+err+"]");
      // });
      // var params = new URLSearchParams();
      // axios.post('/api/service/information', params)
      // httpClient.getInformation()
      //   .then((e) => {
      //     setInformation(e);
      //   }).catch(() => {
      //   });
    }

    // 利用規約URL取得
    function getTermsOfUseURL() {
      let ddd = new Date();
      let rURL = "../service_info/FAMcampusサービス利用規約_Ver.1.0.pdf?" + ddd.getTime();
      setTermsOfUseURL(rURL);
      // var params = new URLSearchParams();
      // axios.post('/api/service/termsofuse', params)
      // httpClient.getTermsOfUseURL()
      //   .then((e) => {
      //     // setTermsOfUseURL(e.data);
      //     let ddd = new Date();
      //     let rURL = e + "?" + ddd.getTime();
      //     setTermsOfUseURL(rURL);
      //   }).catch(() => {
      //   });
    }
  }, [])

const handleChangeId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      id: event.target.value,
    })
  }
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      password: event.target.value,
    })
  }

  /* */
  // サインイン時のバージョンチェック
  const handleLoginCheck = async () => {
    setErrMsgOnLogin('');
    let check = await checkServerVersion();
    if(check === 0) {
      handleLogin();
    } else if(check === 1) {
      requireReloadDialogRef.current.open(true);
    } else {
      if(window.navigator.onLine) {
        setErrMsgOnLogin('ログインできません。しばらくしてからもう一度やり直してください。');
      } else {
        setErrMsgOnLogin('ログインできません。ネットワークの状態を確認してください。');
      }
    }
  }
  const checkServerVersion = async () => {
    console.log("######## checkVersion");
    var check = 0;
    //var params = new URLSearchParams();
    try {
      // const response = await axios.post('/api/service/version', params);
      // const response = await httpClient.getVersion();
      let ddd = new Date();
      const getVersionURL: string = window.location.protocol+'//'+window.location.host+'/service_info/version_front.txt?'+ddd.getTime()
      const response = await axios.get(getVersionURL);
      console.log("####### checkVersion get");
      if(response === undefined || response === null) {
        console.log("response null");
        return 1;
      }
      console.log("checkVersion response="+response);
      // if(response.status === 200) {
        if (response.data.trim() === "") {
            // 空文字が返ってきた(exec_varがないとき)はOK
            console.log("バージョンが空");
        }
        else if (response.data.trim() === appFrontVersion) {
            // バージョンが同じときはOK
            console.log("バージョンが同じ");
        }
        else {
            // バージョンが違う
            console.log("バージョンが違う");
            check = 1;
        }
      // } else {
      //   console.log("err");
      //   check = 2;
      // }
    } catch(err) {
      console.log("err catch : "+err);
      check = 2;
    }
    return check;
  };
  /**/

  const handleLogin = async () => {
    setErrMsgOnLoginId('');
    setErrMsgOnPassword('');
    setErrMsgOnLogin('');

    function login() {
      // どちらかが未入力場合はAPIサーバと通信しない
      if (!loginState.id || !loginState.password) {
        if (!loginState.id) {
          setErrMsgOnLoginId(errorMessageBox.loginIdNull);
        } else {
          // 入力されたらエラーメッセージ非表示
          setErrMsgOnLoginId('');
        }
        if (!loginState.password) {
          setErrMsgOnPassword(errorMessageBox.passwordNull);
        } else {
          // 入力されたらエラーメッセージ非表示
          setErrMsgOnPassword('');
        }
        setErrMsgOnLogin('');
        return;
      }

      let tabID = new Date().getTime();
      sessionStorage.setItem("TABID", tabID.toString());

      let params = new URLSearchParams();
      params.append('id', loginState.id);
      params.append('pass', loginState.password);
      let inputTwoAuthCode ="";
      // if (!isShowReCaptchav2) {
      //   // v3の判定
      //   params.append('token', "v3-" + recaptchaTokenV3.current);
      // } else {
      //   // v2の判定
      //   params.append('token', "v2-" + recaptchaTokenV2);
      // }

      let recaptcha_token = "";
      if (!isShowReCaptchav2) {
        // v3の判定
        recaptcha_token = "v3-" + recaptchaTokenV3.current;
        params.append('token', "v3-" + recaptchaTokenV3.current);
      } else {
        // v2の判定
        recaptcha_token = "v2-" + recaptchaTokenV2;
        params.append('token', "v2-" + recaptchaTokenV2);
      }

      params.append('version', appFrontVersion);
      params.append('tabid', tabID.toString());

      if(document.getElementById("twoAuthCode") !== null) {
        inputTwoAuthCode = (document.getElementById("twoAuthCode") as HTMLInputElement).value
        params.append('twoStepAuth', inputTwoAuthCode);
      }
      // axios.post('/api/login', params)
      // httpClient.signin(loginState.id, loginState.password, tabID?.toString())
      httpClient.signinTwoAuth(loginState.id, loginState.password, tabID?.toString(), inputTwoAuthCode, recaptcha_token)  
        .then(response => {
          if (response.toString().match(/^二段階認証$/)) {
            // setOpenTwoAuthDialog(true);
            // if (WebrtcService.isiOS() === false) {
            //   if (chrome.webview !== undefined) {
            //     // Windowsアプリへ二段階認証に設定されていることを伝える
            //     chrome.webview.hostObjects.sync.myHostObject.SigninErrMsgNotification('二段階認証');
            //   }
            // }
            console.info('2段階認証部分でreturn 1');
            return;
          } else if (response.toString().match(/^二段階認証に失敗$/)) {
            // setTwoAuthError("認証コードが違います。");
            // let errMsgToWinApp = '認証コードが違います。';
            // if (WebrtcService.isiOS() === false) {
            //   if (chrome.webview !== undefined) {
            //     // Windowsアプリへエラーメッセージを伝える
            //     chrome.webview.hostObjects.sync.myHostObject.SigninErrMsgNotification(errMsgToWinApp);
            //   }
            // }
            console.info('2段階認証部分でreturn 2');
            return;
          }

          let loginJson = response as LoginJson;
          sessionStorage.setItem("TABID", loginJson.tabId);
          sessionStorage.setItem("ISLOGIN", "true");
          if (loginJson.isResetPassword) {
            history.push("/changepassword");
          } else {
            if (loginJson.action === "TOP") {
              history.push({
                pathname: "/selectdevice",
                state: { nextpage: "/top" }
              });
            } else if (loginJson.action === "FLOOR") {
              history.push({
                pathname: "/selectdevice",
                state: { 
                  nextpage: "/floor", 
                  path: loginJson.webSocketUrl, 
                  id: loginJson.floorId,
                  requireCamera: loginJson.requireCamera,
                  requireMic: loginJson.requireMic,
                }
              });
            }
          }
        }).catch(err => {
          if(!window.navigator.onLine) {
              // 接続失敗メッセージの表示
              setErrMsgOnLogin('ログインできません。ネットワーク環境を見直してください。');
          } else if (isJfsError(err)) {
            const { code, httpStatusCode } = err;
            // if (err.response.data === 'BOTまたは攻撃の可能性があります。') {
            //   setShowReCaptchav2(true);
            //   setErrMsgOnLogin('');
            // }else{
            //   console.log(err);
              if(httpStatusCode >= 500) {
                // 接続失敗メッセージの表示
                setErrMsgOnLogin('ログインできません。しばらくしてからもう一度やり直してください。');
              } else {
                if (code === JFS_ERRORS.REST_SIGNIN_UNAUTH_NETWORK.code) {
                  setErrMsgOnLogin('許可されていないネットワークからのアクセスです。サインイン出来ません。');
                } else if (code === JFS_ERRORS.REST_ON_MAINTENANCE.code) { // 上もそうだがこんなif文でいいのか
                  setErrMsgOnLogin('ただいまメンテナンス中のためログインできません。しばらくお待ちください。');
                } else if(code === JFS_ERRORS.REST_SIGNIN_OUT_OF_TERM.code){
                  setErrMsgOnLogin('開催まで、ログインすることはできません。開催時間までお待ちください');
                } else if (code === JFS_ERRORS.REST_VERSION_ERROR.code) { // 上もそうだがこんなif文でいいのか
                  requireReloadDialogRef.current.open(true);
                } else if (code === JFS_ERRORS.REST_SIGNIN_RECAPTCHA_SCORE.code) {  // 101004 
                  setShowReCaptchav2(true);
                  setErrMsgOnLogin('');
                } else if (code === JFS_ERRORS.REST_SIGNIN_RECAPTCHA_ERROR.code) {  // 101005 
                  setErrMsgOnLogin('サインインできません。ブラウザの「再読み込み」を行い、再度実行してください。');
                // } else if (err.response.data === 'ログインするフロアが特定できないため、ログイン出来ません。') {
                //   setErrMsgOnLogin('ログインするフロアが特定できないため、ログイン出来ません。');
                } else {
                  // 認証失敗メッセージの表示
                  setErrMsgOnLogin('ログインIDまたはパスワードが不正です。もう一度ログイン情報を入力してください。');
                }
              }
            // }
          }

          //console.log(err);
          // 未入力以外のエラーが出た時非表示
          setErrMsgOnPassword('');
        });
    }

    console.log("handleLogin recaptcha="+recaptcha.current);
    if(recaptcha.current === 1) {
      if (!executeRecaptcha) {
        return;
      }
      recaptchaTokenV3.current = await executeRecaptcha("tinysolution");
      console.log("handleLogin recaptchaTokenV3");
    }

    login();
  }

  const handleUserKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 /* 13=Enter Key */) {
      // handleLogin();
      handleLoginCheck();
    }
  }

  const isJfsError = (arg: unknown): arg is JfsError => {
    const u = arg as JfsError;
    return ['number'].includes(typeof u?.code);
  };

  const handleChangeReCaptchav2 = (value: any) => {
    console.log(value);
    setRecaptchaTokenV2(value);
  }

  const handleResetPassword = () => {
    history.push("/resetpassword");
  }

  const reCaptchaV2Form = useMemo(() => {
    if (!isShowReCaptchav2) return;
    return (
      <Fragment>
        <Typography variant="body1">
          不正ログイン対策ご協力のお願い
        </Typography>
        <Typography variant="body2">
          セキュリティ強化のため、Google社のreCAPTCHAを導入しております。下記にチェックしログインボタンを押してください。
        </Typography>
        <ReCAPTCHA
          theme="dark"
          ref={ref}
          sitekey={siteKeyV2()}
          onChange={handleChangeReCaptchav2}
        />
      </Fragment>
    )
  }, [isShowReCaptchav2])

  const resetPassword = useMemo(() => {
    return(
      <Link style={{ cursor: 'pointer' }} onClick={handleResetPassword}>
        パスワードをお忘れの方はこちら
      </Link>
    )
  },[])

  const submitButton = useMemo(() => {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        // onClick={handleLogin}
        onClick={handleLoginCheck}
        disabled={isShowReCaptchav2 ? (recaptchaTokenV2 === "" ? true : false) : false }
      >
        ログイン
      </Button>
    )
  }, [loginState.id, loginState.password, isShowReCaptchav2, recaptchaTokenV2])

  return (
    <Fragment>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.image}>
        <Grid container justify="center" alignItems="center" >
          <Grid item xs={12} sm={6} md={4} >
            <div className={classes.paper}>
              <div className={classes.famOfficelogo}>
                {/*<img src='./api/service/famLogo'  alt="" />*/}
                <img src={'../service_info/famcampus_logo_image.png'} alt="" />
              </div>
              <Typography variant="body2" className={classes.famOfficeText}>
                IDとパスワードを入力してログインしてください
                </Typography>

              {errMsgOnLoginId.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnLoginId}</div>) : ('')} 
              {/*<ThemeProvider theme={textFieldTheme}>*/}
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="ID"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={loginState.id}
                  onChange={handleChangeId}
                  style={{background: '#FFFFFF 0% 0% no-repeat padding-box',}}
                />
              {/*</ThemeProvider>*/}
              {errMsgOnPassword.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnPassword}</div>) : ('')}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
                value={loginState.password}
                onChange={handleChangePassword}
                onKeyDown={handleUserKeyDown}
                style={{background: '#FFFFFF 0% 0% no-repeat padding-box',}}
              />
              {reCaptchaV2Form}
              {errMsgOnLogin.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnLogin}</div>) : ('')}
              {submitButton}
              {/* reCAPTCHA */}
              {/*resetPassword*/}
              <div className={classes.fsilogo}>
                <img src={fsiLogo}  alt="" />
                {/* {termsOfUseURL ?
                  <p><a href={termsOfUseURL} target="_blank">利用規約</a></p>
                :
                  <Fragment/>
                } */}
                <Fragment/>
              </div>
              <p className="recaptcha_policy">This site is protected by reCAPTCHA and the Google<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
              {/* SAML対応(FSI) ここから
              <p><Link href={"/sso/login/Original1/"}>SSO ログイン (Original1)</Link></p>
              <p><Link href={"/sso/login/Original2/"}>SSO ログイン (Original2)</Link></p>
              SAML対応(FSI) ここまで */}
            </div>

          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={11} md={7} className={classes.notify}>
            <div className={classes.paper2}>
              <iframe src={information}
                frameBorder='0'
                title='information'
                style={{width:'100%', height:'270px'}}
              />
            </div>
          </Grid>
          {/*<Grid item xs={12} sm={11} md={7} className={classes.notify}>
            <div className={classes.paper2}>
              <div dangerouslySetInnerHTML={{ __html: information }}></div>
            </div>
          </Grid>*/}

        </Grid>

        <div className={classes.copyright}>
              <Copyright />
        </div>

      </Grid>
    </Grid>
    <RequireReloadDialog ref={requireReloadDialogRef} />
    </Fragment>
  );
}
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios, { AxiosResponse } from 'axios';
import Grid from '@material-ui/core/Grid';
import {Dialog, DialogContentText} from '@material-ui/core';
import { appFrontVersion } from '../common/Version';
import JfsClient from '@fsi/jfs-sdk';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootDiv: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            //backgroundColor: '#eee',
            backgroundColor: '#fff',
          },
          news: {
            position: 'fixed',
            left: 'auto',
            right: '10px',
            zIndex: 100,
          },
          logo: {
            position: 'relative',
            left: 'auto',
            right: 'auto',
            top: '5%',
            transform: 'translateY(-50%)',
          },
          youkoso: {
            fontSize: '58px',
            position: 'relative',
            left: 'auto',
            right: 'auto',
            top: '7%',
            transform: 'translateY(-50%)',
          },
          onamae: {
            position: 'relative',
            left: 'auto',
            right: 'auto',
            top: '12%',
            transform: 'translateY(-50%)',
            padding : 5,
          },
          logins: {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '260px',
          },
          loginForm: {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '50px',
          },
          loginpassForm: {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '145px',
          },
          loginButton: {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '200px',
          },
          fieldStyle: {
            width: '250px',
            position: 'relative',
            top: '50%',
            //pattern: "^[A-Za-z]+$",
          },
          resline: {
            margin: 0,
          },
        
          copyright: {
            position: 'absolute',
            left: 0,
            right: 0,
            margin: 'auto',
            bottom: '1%',
            fontSize: 10,
          },
    }),
);

interface Props {

}

// 公開したいメソッドの定義
export interface RequireReloadDialogHandler {
    open: (open: boolean) => void
}

const RequireReloadDialogFunction: React.ForwardRefRenderFunction<RequireReloadDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [start, setStart] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // 用意したが外から閉じなくてもいい気がする
    useImperativeHandle(ref, () => ({
        open: (open: boolean) => {
            setOpen(open);
        }
    }));

    useEffect(() => {

        function getServerVer() {

            // バージョンチェック
            // クライアント
            //let p = require('../../package.json');
            console.log(appFrontVersion)

            // var params = new URLSearchParams();
            // axios.post('/api/service/version', params)
            //     .then(response => {

            //         if (response.data == "") {
            //             // 空文字が返ってきた(exec_varがないとき)はOK
            //             console.log("バージョンが空");
            //         }
            //         else if (response.data === appFrontVersion) {
            //             // バージョンが同じときはOK
            //             console.log("バージョンが同じ");
            //         }
            //         else {
            //             // バージョンが違う
            //             console.log("バージョンが違う");
            //             setOpen(true)
            //         }
            //     }).catch(err => {
            //         console.log(err);
            //         setOpen(true)
            //     });

            let ddd = new Date();
            const getVersionURL: string = window.location.protocol+'//'+window.location.host+'/service_info/version_front.txt?'+ddd.getTime()
            axios.get(getVersionURL)
                .then(res => {
                  const response = res.data.trim();
                  console.log("DiarogFunc checkVersion response=[" + response + "] appFrontVersion=["+appFrontVersion+"]");
                  if (response === "") {
                        // 空文字が返ってきた(exec_varがないとき)はOK
                        console.log("バージョンが空");
                    }
                    else if (response === appFrontVersion) {
                        // バージョンが同じときはOK
                        console.log("バージョンが同じ");
                    }
                    else {
                        // バージョンが違う
                        console.log("バージョンが違う");
                        setOpen(true)
                    }
                }).catch(err => {
                    console.log(err);
                    setOpen(true);
                });
        }

        getServerVer();
    }, [])

    const drawDialog = useMemo(() => {

        return(
            <div>
            <Dialog disableBackdropClick disableEscapeKeyDown
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/*
            <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>{"バーチャルオフィスからのお知らせ"}</DialogTitle>
              */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description" >
                新しいバージョンが公開されています。<br/>
                ブラウザの「再読み込み」を行ってください。
              </DialogContentText>
      
          </DialogContent>
          </Dialog>
          </div>
          );

    }, [open, start]);

    return (
        <Fragment>
            {drawDialog}
        </Fragment>
    )
}

export const RequireReloadDialog = forwardRef(RequireReloadDialogFunction);

import { StudyObjectSetting } from "@fsi/jfs-sdk";

export class Office {
    id: number = 0;
    officeName: string = "";
    privacyRoom: boolean = false;
}

export class BackgroundObjectData {
    id: number = 0;
}

export class FloorData {
    id: number = 0;
    office: Office = new Office();
    floorName: string = "";
    floorHeight: number = 0;
    floorWidth: number = 0;
    micMode: number = 0;
    micVolumeThreshold: number = 0;
    webRtcMode: number = 0;
    webRtcInfoInterval: number = 0;
    pingInterval: number = 0;
    continueWebRtcShowDialogTime: number = 0;
    webSocketUrl: string = "";
    checkMediaDeviceMode: number = 0;
    requireCamera: boolean = false;
    requireMic: boolean = false;
    webrtcApiKey: string = "";
    morenoteDifferentPassword: boolean = false;
    enabledMorenoteInvitedConfirm: boolean = true;      // moreNOTEへ招待するかの確認実施有無
    backgroundObject: BackgroundObjectData = new BackgroundObjectData();
    systemMessage: string = "";
    enabledTutorialButton: boolean = false;
    enabledWrStartConfirmSetting: number = 10;    // フロア単位での「ビデオ通話前の確認」処理方式
    wrStartConfirmWaitTime: number = 20;    // フロア単位での「ビデオ通話前の確認」応答タイムアウト
    reconWebSocketRetryMax: number = 6;         // 「自動再接続」切断後、再接続成功するまでのリトライ回数
    reconWebSocketActionMax: number = 3;        // 「自動再接続」一定期間での再接続実行の最大回数
    reconWebSocketActionInitTime: number = 60;  // 「自動再接続」再接続実行回数をクリアする時間 単位：時間
    webrtcJoinOpenWaitTimeOut: number = 0;  // joinRoomしてからpeer.on(open)イベント受信タイムアウト 単位：msec
    frontEndDebugLevel: number = 0;
    purposeOfUse: number = 0;
    enabledSettingSideMenu: boolean = true;
    enabledTutorialSideMenu: boolean = true;
    enabledManualSideMenu: boolean = true;
    enabledInformationSideMenu: boolean = true;
    enabledReleaseNoteSideMenu: boolean = true;     // リリースノート対応
    enabledFAQSideMenu: boolean = true;     // ＦＡＱ対応
    enabledReconnectSideMenu: boolean = false;
    enabledMicVolumeIntervalSideMenu: boolean = false;
    enabledWhiteBoardButton: boolean = true;
    enabledMemoButton: boolean = true;
    enabledMeet: boolean = true;
    enabledTubuyaki: boolean = true;
    enabledVideoNoSelectDisp: boolean = true;     // カメラ未選択時の表示対応
    //enabledMicNoSelectDisp: boolean = true;     // マイク未選択時の表示対応
    startScreenShareMessage: string = "";
    signinNoCameraMessage: string = "";
    decimationMoveData: number = 1;
    enabledMemoOtherUserMenu: boolean = true;
    videoId: string = "";
    reConnectHello: boolean = false;
    screenShareFps: number = 0;
    screenShareResolutionWidth: number = 0;
    screenShareResolutionHeight: number = 0;
    enabledScreenShareButton: boolean = true;
    enabledOverlookButton: boolean = false;
    zoomInfoId: number | null = null; 
    zoomInfo: { id: number | null } = { id: null };
    otherWebsiteUrl: string = "";
    enabledZoomMicButton: boolean = false;
    enabledZoomCamButton: boolean = false;
    //enabledZoomScreenShareButton: boolean = false; // !見本市対応で作成したが不使用
    //enabledZoomFullScreenButton: boolean = false;  // !見本市対応で作成したが不使用
    enabledBusinessCard: boolean = false;
    enabledMyRoom: boolean = false;
    enabledExternalUrl: boolean = false;
    externalUrl: string = "";
}

export class MyRoomFloorData {
    id: number = 0;
    floorHeight: number = 0;
    floorWidth: number = 0;
    backgroundObject: BackgroundObjectData = new BackgroundObjectData();
}

export class ObjectMaster {
    id: number = 0;
    type: number = 0;
    width: number = 0;
    height: number = 0;
    fps: number = 0;
    frames: number = 0;
    // picturePath: string = "";
    textOffsetTop: number = 0;
    textOffsetLeft: number = 0;
    paletteText: string = "";
    paletteOrder: number = 0;
    editMinusOffsetLeft: number = 0;
    textHeight: number = 0;
    textWidth: number = 0;
    textLength: number = 0;
}

export class AvatarMenuData {
    avatarMenuMasterId: number = 0;
    avatarMenuId: number = 0;
    floorId: number = 0;
    avatarName: string = "";
    functionId: number = 0;
    iconPath: string = "";
    orderBy: number = 0;
    authorityLevel: number = 0;
    parentId: number = 0;
    disabled?: boolean = false;
    hidden: boolean = false;
    color: string = "";
    pictograph: string = "";
}

// フロア情報
export class FloorObject {
    id: number = 0;
    offsetLeft: number = 0;
    offsetTop: number = 0;
    objectMaster: ObjectMaster = new ObjectMaster();
    text1: string = "";
    text2: string = "";
    videoURL: string = "";
    // 会議終了後にURLを自動削除するかどうか
    isResetURL: boolean = true
}

export class MyRoomFloorObject {
    id: number = 0;
    offsetLeft: number = 0;
    offsetTop: number = 0;
    objectMaster: ObjectMaster = new ObjectMaster();
    text1: string = "";
    text2: string = "";
    videoURL: string = "";
}

export class UpdateMyRoom {
    // 更新対象
    userId: number = 0;
}

export class User {
    id: number = 0;
    loginId: string = "";
    subId: string = "";
    x: number = -200;
    y: number = -200;
    width: number = 0;
    height: number = 0;
    displayName: string = "";
    lastName: string = "";
    firstName: string = "";
    state: number = 0;
    enableMeet: boolean = false;
    tubuyaki: string = "";
    ping: number = 0;
    isSitOnDesk = false;
    webRtcPeerId: string = "";
    webRtcRoomId: string = "";
    isWebrtcJoin: boolean = false;  /** 周りの人 WebRTC 参加中か（webRtcRoomIdが入っていても接続していない時があるので別の判断フラグがほしい） */  
    wrStartConfirmStatus: number = 0;   // ビデオ通話前の確認状態 0:通常、1:確認中、11:確認OK、91:確認NG
    wrStartConfirmStatusTM: number = 0;
    isAudioMute: boolean = false;
    isVideoMute: boolean = false;
    isTrafficLimitVideoMute: boolean = false;   // 通信量制限によるカメラ使用制限状態
    isVideoNoSelect: boolean = false;   // カメラ未選択時の表示対応
    isMicNoSelect: boolean = false;   // マイク未選択時の表示対応
    hittedUser: string = "";
    hittedUserId: number = 0;
    avatarConfirmSetting: number = 0;   // ビデオ通話前確認の個人設定
    avatarLoginoutNotification: number = 0;   // ログイン通知の個人設定
    avatarLoginoutNotificationSound: number = 0;   // ログイン通知音声の個人設定
    avatarId: number = 0;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    seat: Seat = new Seat();
    myPc: ObjectMaster = new ObjectMaster();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    mySeat: Seat = new Seat();
    webRtcCall: boolean = false;
    webRtcRetryCall: boolean = false;   // webrtc接続リトライ対応
    //debugSend: number = 0;  // for Debug i(webrtc retry : skyway close 対応)
    // myNameplate: ObjectMaster = new ObjectMaster();
    // myNameplateSignOut: ObjectMaster = new ObjectMaster();
    micMode: number = 0;
    micVolume: number = 0;
    micVolumeThreshold: number = 0;
    webRtcMode: number = 0;
    webRtcInfoInterval: number = 0;
    isScreenShare: boolean = false;
    isScreenSharing: boolean = false;
    isScreenShareWindowSelecting: boolean = false;  // 画面共有のウインド選択中フラグ #761 共有ウインド選択のバッティング対応
    screenShareMode: number = 1; // 1:全画面, 2: フローティング
    screenShareSessionId: string = "";
    isMediaWaiting: boolean = false;
    waitingTimerId: number = 0;    // webrtc 接続waitingタイマーのID
    isBroadcast: boolean = false;
    isBroadcastSender: boolean = false;
    profile: Object[] = [];
    isStayFloor: boolean = false;
    stayFloorName: string = "";
    stayObjectId: number = 0;
    command: string = "";
    cMessage: string = "";
    notifyTitle: string = "";
    notifyMessage: string = "";
    notifyRedirectUrl: string = "";
    notifiRedirectCaption: string = "";
    dispSignout: boolean = false;
    isGenerateMoveEnd: boolean = false;
    isReconnectWebRtc: boolean = false;
    isLargeVideo: boolean = false;
    isNoVideo: boolean = false;
    isWebRtcContinue: boolean = false;
    isDrawPc: boolean = true;
    isCommuting: boolean = false;
    isForceMute: boolean = false;
    isInForceMuteRoom: boolean = false;
    isGhost: boolean = false;
    moreNoteId: string = "";
    moreNoteHostUrl: string = "";
    moreNoteResponseUrl: string = "";
    moreNoteRegistrationId: string = "";
    moreNoteConfirm: boolean = false;
    deviceSelectedType: number = 0;
    unreadMemoCount: number = 0;
    isMemoNotify: boolean = true;
    videoURL: string = "";
    isGoToSee: boolean = false;
    role: string = "";
    userLevel: number = 0;
    isZoomSpeakable: boolean = false;
    zoomMeetingId: number = 0;
    isCardDisclose: boolean = true;
    flexibleNamePlate:FlexibleNamePlate = new FlexibleNamePlate();
    validWebSocketHostMaster:boolean = false;
    privacyRoomInfo:PrivacyRoomInfo = new PrivacyRoomInfo();

    /**
     * ユーザ情報コピー処理.
     *
     * @param user コピー元ユーザ情報
     */
    public static copyUser = (user: User) => {
        let temp = new User();
        if (temp != null) {
            temp.id = user.id;
            temp.loginId = user.loginId;
            temp.subId = user.subId;
            temp.x = user.x;
            temp.y = user.y;
            temp.width = user.width;
            temp.height = user.height;
            temp.displayName = user.displayName;
            temp.lastName = user.lastName;
            temp.firstName = user.firstName;
            temp.state = user.state;
            temp.tubuyaki = user.tubuyaki
            temp.ping = user.ping;
            temp.isSitOnDesk = user.isSitOnDesk;
            temp.webRtcRoomId = user.webRtcRoomId;
            temp.webRtcPeerId = user.webRtcPeerId;
            temp.isWebrtcJoin = user.isWebrtcJoin;  /** 周りの人からの見た目 */
            temp.wrStartConfirmStatus = user.wrStartConfirmStatus;  // ビデオ通話前の確認状態
            temp.wrStartConfirmStatusTM = user.wrStartConfirmStatusTM;  // ビデオ通話前の確認状態
            temp.isAudioMute = user.isAudioMute;
            temp.isVideoMute = user.isVideoMute;
            temp.isTrafficLimitVideoMute = user.isTrafficLimitVideoMute;
            temp.isVideoNoSelect = user.isVideoNoSelect;    // カメラ未選択時の表示対応
            temp.isMicNoSelect = user.isMicNoSelect;    // マイク未選択時の表示対応
            temp.avatarConfirmSetting = user.avatarConfirmSetting;  // ビデオ通話前確認の個人設定
            temp.avatarLoginoutNotification = user.avatarLoginoutNotification;  // ログイン通知の個人設定
            temp.avatarLoginoutNotificationSound = user.avatarLoginoutNotificationSound;  // ログイン通知音声の個人設定
            temp.avatarId = user.avatarId;
            temp.seat = user.seat;
            temp.myPc = user.myPc;
            temp.mySeat = user.mySeat;
            temp.webRtcCall = user.webRtcCall;
            temp.webRtcMode = user.webRtcMode;
            temp.webRtcInfoInterval = user.webRtcInfoInterval;
            temp.isScreenShare = user.isScreenShare;
            temp.isScreenSharing = user.isScreenSharing;
            temp.isScreenShareWindowSelecting = user.isScreenShareWindowSelecting;  // 画面共有のウインド選択中フラグ #761 共有ウインド選択のバッティング対応
            temp.screenShareMode = user.screenShareMode;
            temp.screenShareSessionId = user.screenShareSessionId;
            temp.isBroadcast = user.isBroadcast;
            temp.isBroadcastSender = user.isBroadcastSender;
            // temp.myNameplate = user.myNameplate;
            // temp.myNameplateSignOut = user.myNameplateSignOut;
            temp.micVolume = user.micVolume;
            temp.micVolumeThreshold = user.micVolumeThreshold;
            temp.micMode = user.micMode;
            temp.isMediaWaiting = user.isMediaWaiting;
            temp.waitingTimerId = user.waitingTimerId;
            temp.profile = user.profile;
            temp.isStayFloor = user.isStayFloor;
            temp.stayFloorName = user.stayFloorName;
            temp.stayObjectId = user.stayObjectId;
            temp.isGenerateMoveEnd = user.isGenerateMoveEnd;
            temp.isWebRtcContinue = user.isWebRtcContinue;
            temp.isLargeVideo = user.isLargeVideo;
            temp.isNoVideo = user.isNoVideo;
            temp.isReconnectWebRtc = user.isReconnectWebRtc;
            temp.isDrawPc = user.isDrawPc;
            temp.isCommuting = user.isCommuting;
            temp.isForceMute = user.isForceMute;
            temp.isInForceMuteRoom = user.isInForceMuteRoom;
            temp.isGhost = user.isGhost;
            temp.moreNoteId = user.moreNoteId;
            temp.moreNoteHostUrl = user.moreNoteHostUrl;
            temp.moreNoteResponseUrl = user.moreNoteResponseUrl;
            temp.moreNoteRegistrationId = user.moreNoteRegistrationId;
            temp.moreNoteConfirm = user.moreNoteConfirm;
            temp.deviceSelectedType = user.deviceSelectedType;
            temp.unreadMemoCount = user.unreadMemoCount;
            temp.isMemoNotify = user.isMemoNotify;
            temp.videoURL = user.videoURL;
            temp.isGoToSee = user.isGoToSee;
            temp.role = user.role;
            temp.userLevel = user.userLevel;
            temp.isZoomSpeakable = user.isZoomSpeakable;
            temp.zoomMeetingId = user.zoomMeetingId;
            temp.isCardDisclose = user.isCardDisclose;
            temp.flexibleNamePlate = user.flexibleNamePlate;
            temp.validWebSocketHostMaster = user.validWebSocketHostMaster;
            temp.privacyRoomInfo = new PrivacyRoomInfo();
            temp.privacyRoomInfo.preFloorName = user.privacyRoomInfo.preFloorName;
            temp.privacyRoomInfo.floorId = user.privacyRoomInfo.floorId;
            temp.privacyRoomInfo.preFloorId = user.privacyRoomInfo.preFloorId;
            temp.privacyRoomInfo.stayPrivacyroom = user.privacyRoomInfo.stayPrivacyroom;
            temp.privacyRoomInfo.subId = user.privacyRoomInfo.subId;
        }
        return temp;
    }
    /**
     * targetのUserにsourceのUserをコピーする
     * @param target 
     * @param source 
     */
    public static copyUserToUser = (target: User, source: User) => {
        if (target != null) {
            target.id = source.id;
            target.loginId = source.loginId;
            target.subId = source.subId;
            target.x = source.x;
            target.y = source.y;
            target.width = source.width;
            target.height = source.height;
            target.displayName = source.displayName;
            target.lastName = source.lastName;
            target.firstName = source.firstName;
            target.state = source.state;
            target.tubuyaki = source.tubuyaki
            target.ping = source.ping;
            target.isSitOnDesk = source.isSitOnDesk;
            target.webRtcRoomId = source.webRtcRoomId;
            target.webRtcPeerId = source.webRtcPeerId;
            target.isWebrtcJoin = source.isWebrtcJoin;  /** 周りの人からの見た目 */
            target.wrStartConfirmStatus = source.wrStartConfirmStatus;  // ビデオ通話前の確認状態
            target.wrStartConfirmStatusTM = source.wrStartConfirmStatusTM;  // ビデオ通話前の確認状態
            target.isAudioMute = source.isAudioMute;
            target.isVideoMute = source.isVideoMute;
            target.isTrafficLimitVideoMute = source.isTrafficLimitVideoMute;
            target.isVideoNoSelect = source.isVideoNoSelect;    // カメラ未選択時の表示対応
            target.isMicNoSelect = source.isMicNoSelect;    // マイク未選択時の表示対応
            target.avatarConfirmSetting = source.avatarConfirmSetting;  // ビデオ通話前確認の個人設定
            target.avatarLoginoutNotification = source.avatarLoginoutNotification;  // ログイン通知の個人設定
            target.avatarLoginoutNotificationSound = source.avatarLoginoutNotificationSound;  // ログイン通知音声の個人設定
            target.avatarId = source.avatarId;
            target.seat = source.seat;
            target.myPc = source.myPc;
            target.mySeat = source.mySeat;
            target.webRtcCall = source.webRtcCall;
            // target.myNameplate = source.myNameplate;
            // target.myNameplateSignOut = source.myNameplateSignOut;
            target.micVolume = source.micVolume;
            target.isMediaWaiting = source.isMediaWaiting;
            target.waitingTimerId = source.waitingTimerId;
            target.profile = source.profile;
            target.isStayFloor = source.isStayFloor;
            target.stayFloorName = source.stayFloorName;
            target.stayObjectId = source.stayObjectId;
            target.isGenerateMoveEnd = source.isGenerateMoveEnd;
            target.isWebRtcContinue = source.isWebRtcContinue;
            target.isLargeVideo = source.isLargeVideo;
            target.isNoVideo = source.isNoVideo;
            target.isReconnectWebRtc = source.isReconnectWebRtc;
            target.isDrawPc = source.isDrawPc;
            target.isCommuting = source.isCommuting;
            target.isScreenShare = source.isScreenShare;
            target.isScreenSharing = source.isScreenSharing;
            target.isScreenShareWindowSelecting = source.isScreenShareWindowSelecting;  // 画面共有のウインド選択中フラグ #761 共有ウインド選択のバッティング対応
            target.screenShareMode = source.screenShareMode;
            target.screenShareSessionId = source.screenShareSessionId;
            target.isBroadcast = source.isBroadcast;
            target.isBroadcastSender = source.isBroadcastSender;
            target.isForceMute = source.isForceMute;
            target.isInForceMuteRoom = source.isInForceMuteRoom;
            target.isGhost = source.isGhost;
            target.moreNoteId = source.moreNoteId;
            target.moreNoteHostUrl = source.moreNoteHostUrl;
            target.moreNoteResponseUrl = source.moreNoteResponseUrl;
            target.moreNoteRegistrationId = source.moreNoteRegistrationId;
            target.moreNoteConfirm = source.moreNoteConfirm;
            target.deviceSelectedType = source.deviceSelectedType;
            target.unreadMemoCount = source.unreadMemoCount;
            target.isMemoNotify = source.isMemoNotify;
            target.videoURL = source.videoURL;
            target.isGoToSee = source.isGoToSee;
            target.role = source.role;
            target.userLevel = source.userLevel;
            target.isZoomSpeakable = source.isZoomSpeakable;
            target.zoomMeetingId = source.zoomMeetingId;
            target.isCardDisclose = source.isCardDisclose;
            target.flexibleNamePlate = source.flexibleNamePlate;
            target.validWebSocketHostMaster = source.validWebSocketHostMaster;
            target.privacyRoomInfo = new PrivacyRoomInfo();
            target.privacyRoomInfo.preFloorName = source.privacyRoomInfo.preFloorName;
            target.privacyRoomInfo.floorId = source.privacyRoomInfo.floorId;
            target.privacyRoomInfo.preFloorId = source.privacyRoomInfo.preFloorId;
            target.privacyRoomInfo.stayPrivacyroom = source.privacyRoomInfo.stayPrivacyroom;
            target.privacyRoomInfo.subId = source.privacyRoomInfo.subId;
        }
    }
}

export class SendAction {
    action: string = "";
    // eslint-disable-next-line no-new-object
    object: object = new Object();
}

export class Seat {
    floorObjectId: number = 0;
    objectMasterId: number = 0;
    seatNo: number = 0;
    x: number = 0;
    y: number = 0;
    viewText: string = "";
    viewText2: string = "";
    isSit: boolean = false;
    deskOffsetLeft: number = 0;
    deskOffsetTop: number = 0;
    deskAngle: number = 0;
    isMeetingSeat: boolean = false;
    enabledSetMySeat: boolean = false;
    isViewName: boolean = false;
    myNameplate: ObjectMaster = new ObjectMaster();
    myNameplateSignOut: ObjectMaster = new ObjectMaster();
}

export class MoveUserData {
    id: number = 0;
    x: number = 0;
    y: number = 0;
}

export class SendMoveUserData {
    x: number = 0;
    y: number = 0;
    webRtcPeerId: string = "";
    webRtcRoomId: string = "";
}

export class MicData {
    id: number = 0;
    micVolume: number = 0;
}

// 立札情報
export class TatehudaText {
    // オブジェクトID
    id: number = 0;
    // テキスト情報（Json）
    text2: string = "";
    // 音声を鳴らすかどうか
    isPlayAudio: boolean = false;
    // オブジェクトタイプ
    objectType: number = 0;
}

// 立札情報(text1とtext2)
export class Tatehuda2Text {
    id: number = 0;
    text1: string = "";
    text2: string = "";
    isPlayAudio: boolean = false;
}

// 立札情報(text1のみ)
export class TatehudaTextText1 {
    id: number = 0;
    text1: string = "";
    isPlayAudio: boolean = false;
}

// ビデオ会議URL設定
export class VideoURLText {
    id: number = 0;
    videoURL: string = "";
    isPlayAudio: boolean = false;
    // 会議終了後にURLを自動削除するかどうか
    isResetURL: boolean = true;
}

// プライバシールーム状態設定
export class PrivacyState {
    id: number = 0;
    // テキスト情報（Json）
    text2: string = "";
    // オブジェクトタイプ
    objectType: number = 0;
    state: boolean = false;
}

export class UpdateSeatParams {
    isChangeMyUser: boolean = false;
    isWebRtcCall: boolean = false;
}

export class PingResult {
    result: number = 0;
}

export class GetFloor {
    floorId: number = 0;
}

export class AvatarCommand {
    message: string = "";
}

export class EnableWebrtcRoomCommand {
    id: number = 0;
    message: string = "";
    videoURL: string = "";
}

export class HitBusyUser {
    id: number = 0;
}

/** WebRtc制限情報
* kind=-1:     デバイス未選択による制限
*       0以上: 通信量による制限
*           0: 無制限
*           1: テナントの上限(ビデオ通話制限)
*           2: ユーザーの上限　※使用不可（未検証）
*           3: テナントの上限(P2P除く)　※使用不可（未検証）
*           4: ユーザーの上限(P2P除く)　※使用不可（未検証）
*           5: テナントの上限(ビデオ通話可能／カメラ使用制限あり)
* stat(kind=-1)
*  0: カメラとマイクが未選択
*  1: カメラが未選択
*  2: マイクが未選択
* stat(kind=0以上)
* -1: 通信量不明
*  0: 制限なし
*  1: 80%以上制限中
*  2: 100%以上制限中
*/
export class WebRtcLimitUser {
    // 制限を受けるWebRtcのID
    id: number = 0;
    // 制限の種類
    kind: number = 0;
    // 制限の状態
    stat: number = 0;
}

export class MeetLimitOver {
    // 0:テレミート, 1:オープンミート
    type: number = 0;
    // 上限数
    max: number = 0;
}

export class SelectFloor {
    floorId: number = 0;
}

export class SaveData {
    object1: object | null = null;
    object2: object | null = null;
    object3: object | null = null;
    object4: object | null = null;
    object5: object | null = null;
    object6: object | null = null;
    object7: object | null = null;
}

export class LoginLogoutAction {
    action: string = "";
    // eslint-disable-next-line no-new-object
    object: User = new User();
}

export class WebRtcStatsStream {
    id: string = "";
    kind: string = "";
    bytes: number = 0;
}

export class WebRtcStats {
    id: string = "";
    pairId: string = "";
    candidateType: string = "";
    streamInfos: { [key: string]: WebRtcStatsStream } = {};
    bytesTotal: number = 0;
}

export class WebRtcInOutInfo {
    roomId: string = "";
    sessionId: string = "";
    browser: string = "";
    inputStats: { [key: string]: WebRtcStats } = {};
    outputStats: { [key: string]: WebRtcStats } = {};
    prevInputStats: { [key: string]: WebRtcStats } = {};
    prevOutputStats: { [key: string]: WebRtcStats } = {};
    inputBytes: number = 0;
    outputBytes: number = 0;
    inputBytesP2P: number = 0;
    outputBytesP2P: number = 0;
    startUnixTime: number = 0;
    endUnixTime: number = 0;
    connectType: number = 0;
    type: string = "";
    isTurn: boolean = false;
}

export class LoginJson {
    action: string = "";
    tabId: string = "";
    isResetPassword: boolean = false;
    webSocketUrl: string = "";
    floorId: number = 0;
    requireCamera: boolean = false;
    requireMic: boolean = false;
    memberMax: number = 0;
}

export class KeyValueJson {
    key: string = "";
    value: object | null = null;
}

export class AvatarData {
    id: number = 0;
    avatarName: string = "";
    colorName: string = "";
    width: number = 0;
    height: number = 0;
}

export class AvaterIdAndName {
    avaterId: number  = 0;
    avaterName: string  = "";
}

export class StatusTimerInfo {
    avatarMenuMasterId: number =  0;
    avatarMenuMasterName: string = "";
    setTimer: number = 0;
    timerActive: boolean = false;
    orderBy: number = 0;
    statusTimerInit: number = 0;
}

/**
 * System画面で使用するテナント情報クラス
 */
export class Tenant {
    constructor(
        public id: number = 0,
        public tenantName: string = "",
        public businessStartTime: string = "",
        public insertTimestamp: string = "",
    ) { }
}
/**
 * System入力画面で使用するテナント情報クラス
 */
export class TenantInfo {
    constructor(
        public id: number = 0,
        public tenantName: string = "",
        public businessStartTime: string = "",
        public insertTimestamp: string = "",
        public webRtcLimitSize: number = 1,
        public webRtcLimitType: number = 0,
        public webrtcApiKey: string = "",
        public isMorenoteDifferentPassword: boolean = false,
        public moreNoteUrl: string = "",
        public tenantId: string = "",
        public moreNoteHelloUrl: string = "",
        public dataLimitPerPerson: number = 2,
        public maxUserNumber: number = 100,
        public usedWebRtcSize: number = 0,
        public enabledWhiteList: boolean = false,
        public enabledTenant: boolean = true,
        public maintenance: boolean = false,
        public enabledExternalUrl: boolean = false,
        public externalUrl: string = "",
        public enabledPrivacyRoom: boolean = false,
        public privacyRoomNumber: number = 0
    ) { }
}

/**
 * System画面で使用するフロア編集クラス
 * 1．フロア名：floorName
2．フロアサイズ：floorHeight、floorWidth
3．WebRTCの接続モード：webRtcMode
       0: Meshを使用せず、すべての通話がSFU
     * 1: ユーザー同士の接触がMesh
     * 2: 会議室がMesh
     * 3: ユーザー同士の接触、会議室がMesh
     * 4: 全体放送がMesh
     * 5: ユーザー同士の接触、全体放送がMesh
     * 6: 会議室、全体放送がMesh
     * 7: ユーザー同士の接触、会議室、全体放送がMesh
4．マルチアバターを対象とするか：isMultiAvatar
5．アバター接触のビデオ通話継続確認時間；hitAvatarWebRtcTime（meetingRoomWebRtcTimeも？）
6．立ち話への参加上限数：telemeetMemberMax
7．カメラの必須指定：requireCamera
8．マイクの必須指定：requireMic
9．Skyway接続用のAPIキー：webrtcApiKey
10．チュートリアルボタンを表示するか：enabledTutorialButton
11．ビデオ通話前の確認」応答タイムアウト時間：wrStartConfirmWaitTime
12．フロアの使用目的：purposeOfUse
     * 1: オフィス
     * 2: セミナー
13. 有効フロアフラグ: enabledFloor
 */
export class FloorEdit{
    constructor(
        public tenantName: string = "",
        public officeName: string = "",
        public floorName: string =  "",
        public floorHeight: number = 0,
        public floorWidth: number = 0,
        public webRtcMode: number = 0,
        public isMultiAvatar: boolean = false,
        public hitAvatarWebRtcTime: number = 0,
        public meetingRoomWebRtcTime: number = 0,
        public telemeetMemberMax: number = 0,
        public requireCamera: boolean = true,
        public requireMic: boolean = true,
        public enabledMemo: boolean = true,
        public enabledScreenShare: boolean = true,
        public webRtcApiKey: string = "",
        public enabledTutorialButton: boolean = true,
        public wrStartConfirmWaitTime: number = 0,
        public purposeOfUse: number = 1,
        public enabledFloor: boolean = true,
    ) { }
}

/**
 * System画面で使用するフロア情報クラス
 */
export class Floor {
    constructor(
        public id: number = 0,
        public floorName: string = "",
        public floorOrder: number = 1, 
        public enabledMemoButton: boolean = true,
        public enabledWhiteBoardButton: boolean = true,
        public enabledScreenShareButton: boolean = false,
        public enabledFloor: boolean = true,
        public requireCamera: boolean = true,
        public requireMic: boolean = true,
    ) { }
}
/**
 * System入力画面で使用するフロア情報クラス
 */
export class FloorInfo {
    constructor(
        public id: number = 0,
        public floorName: string = "",
        public floorHeight: number = 1,
        public floorWidth: number = 1,
        public floorOrder: number = 1,
        public officeId: string = "",
        public officeName: string = "",
        public version: number = 0,
        

        public tenantName: string = "",
        public businessStartTime: string = "",
        public insertTimestamp: string = "",
        public webRtcLimitSize: number = 1,
        public webRtcLimitType: number = 0,
        public webrtcApiKey: string = "",
        public isMorenoteDifferentPassword: boolean = false,
        public moreNoteUrl: string = "",
        public tenantId: string = "",
        public moreNoteHelloUrl: string = "",

        public enabledMemoButton: boolean = true,
        public enabledWhiteBoardButton: boolean = true,
        public enabledScreenShareButton: boolean = false,
        public enabledFloor: boolean = true,
        public requireCamera: boolean = true,
        public requireMic: boolean = true,
    ) { }
}

/**
 * System画面フロア詳細で使用するフロア別サマリクラス
 */
export class FloorSummary {
    constructor(
        public id: number = 0,
        public floorId: number = 0, 
        public floorOrder: number = 0, 
        public officeId: number = 0, 
        public tenantId: number = 0, 
        public floorName: string = "",
        public officeName: string = "",
        public tenantName: string = "",
        public monitoringTime: string = "",
        public sessionCount: number = 0, 
        public userCount: number = 0, 
        public commutingCount: number = 0, 
        public unCommutingCount: number = 0, 
        public webRtcCount: number = 0, 
        public webRtcAllUserCount: number = 0, 
        public webRtcUsedRoomCount: number = 0, 
        public webRtcFreeRoomCount: number = 0, 
        public webRtcTotalBytesPerTenant: number = 0,
        public webRtcTotalBytesP2PPerTenant: number = 0,
        public webSocketServerId = 0,
    ) { }
}

/**
 * System画面基本契約情報表示で使用するテナント別データクラス
 */
export class BasicContract {
    constructor(
        public id: number = 0,
        public contractYearMonth: number = 0, 
        public numberContracts: number = 0, 
        public updateDatetime: string = "", 
        public webRtcBasicContractBytes: number = 0, 
        public tenantId: number = 0,
        public tenantName: string = "",
        public currentYearMonth = 0,
        public currentDays = 0,
    ) { }
    // id	contractyearmonth	numberofcontracts	updated_datetime	webrtcbasiccontractbytes	tenant_id
}

/**
 * System画面基本契約情報表示で使用するテナント別データクラス
 */
 export class AdditionalContractSummary {
    constructor(
        public id: number = 0,
        public contractYearMonth: number = 0, 
        public webRtcAdditionalGigaBytes: number = 0, 
        public webRtcCarryOverGigaBytes: number = 0, 
        public tenantId: number = 0,
        public tenantName: string = "",
        public webRtcLimitSize: number = 0,
        public currentYearMonth = 0,
        public currentDays = 0,
    ) { }
    // id	contractyearmonth	numberofcontracts	updated_datetime	webrtcbasiccontractbytes	tenant_id
}

/**
 * System画面基本契約情報表示で使用するテナント別データクラス
 */
 export class AdditionalContract {
    constructor(
        public id: number = 0,
        public contractYearMonth: number = 0, 
        public updateDatetime: string = "", 
        public webRtcAdditionalBytes: number = 0, 
        public webRtcCarryOverBytes: number = 0, 
        public tenantId: number = 0,
        public tenantName: string = "",
        public webRtcLimitSize: number = 0,
        public currentYearMonth = 0,
        public currentDays = 0,
    ) { }
    // id	contractyearmonth	numberofcontracts	updated_datetime	webrtcbasiccontractbytes	tenant_id
}

export class objctRegistCheck {
    constructor(
        public id: number = 0,
        public pictureName: string = "",
    ) { }
}

/**
 * ログ出力クラス
 */
export class OutputLog {
    constructor(
        public level: number = 0,
        public message: string = "",
        ) { }
}

export class MemoGroup {
    groupId: number = 0;
    groupName: string = "";
    groupMemberNumber: number = 0;
    unreadCount: number | null = 0;
    userSubId: string[] = [];
}

export class Memo {
    constructor(
        public memoId: number = 0,
        public groupId: number = 0,
        public groupName: string = "",
        public subId: string = "",
        public userName: string = "",
        public memo: string = "",
        public memoTime: string = "",
        public readCount: number = 0,
        public latestReadTime: string = "",
        public memoType: number = 0,
        public removed: boolean = false,
    ) { }
}

export class ReadMemoInfo {
    subId: string= "";
    groupId: number = 0;
    memoId: number = 0;
    latestReadTime: string = "";
    readCount: number = 0;
}

export class chatStampInfo {
    id: number = 0;
    stampKey: string = "";
}

export class AvatarPartsInfo {
    id: number = 0;
    partsType: number = 0;
    selected: boolean = false;
    none: boolean = false;
    colorCode: string = "";
    examplePartsId: number = 0;
}

export class Coord {
    x: number = 0;
    y: number = 0;
}

export class Size {
    width: number = 1;
    height: number = 1;
}

/** 
 * ユーザ検索APIからのレスポンス
 */
export class SearchedUser {
    subId: string = "";
    displayName: string = "";
    isOnline: boolean = false;
    isFavorite: boolean = false;
    officeName: string = "";
    preOfficeName: string = "";
    floorId: number = 0;
    preFloorId: number = 0;
    floorName: string = "";
    preFloorName: string = "";
    isMySeat: boolean = false;
}

/**
 * フロア移動クラス
 */
export class MoveFloorData {
    floorId: number = 0;
    floorName: string = '';
}

// リリースノート対応
export class ServiceInfoData {
    id: number = 0;
    serviceName: string = "";
    releaseNoteURL: string = "";
    releaseVersion: string = "";
    pageFAQURL: string = "";
    usermanualURL: string = "";     // マニュアルURLをDBから取得
}

/**
 * webrtc joinRoom status クラス
 */
export class JoinRoomStatusData {
    myId: number = 0;
    roomId: string = '';
    joinSts: string = '';
    OtId: number = 0;
    peerId: string = '';
    data: string = '';
}

/**
 * #369
 * デバイスエラーのスナックバー表示通知 クラス
 */
 export class DevErrDisp {
    deviceKind: number = 0;
    //otherId: number = 0;
    otherName: string = "";
    sendId: number = 0;
}

/**
 * ReleaseFloorRequest クラス
 */
 export class ReleaseFloorRequest {
    request: number = 0;
}

export class EditorSnackBar {
    editor: string = "";
    type: boolean = false;
}


export class GridSnap {
    object:FloorObject = new FloorObject();
    x:number = 0;
    y:number = 0;
    gridRange: number = 1;
}

export class FloorObjectPaletteCategory {
    id:number = 0;
    type:number = 0;
    categoryName:string = '';
}
export class AdminMessage {
    action: string = "";
    floorId: number = 0;
    floorObjectIdList: number[] = [];
    message: string = "";
}

export class UpdateZoomInfo {
    meetingNumber: string = "";
    zoomInfoId: number = 0;
}
export class InviteMoreNoteSAML {
    url: string = '';
    roomId: string = '';
}

export class ZoomInfo {
    id: number = 0;
    meetingNumber: string = "";
}

export class UpdateZoomSpeakable {
    id: number = 0;
    isZoomSpeakable: boolean = false;
}

export class BusinessCard {
    country: string = '';
    company: string = '';
    department: string = '';
    position: string = '';
    postalCode: string = '';
    prefecture: string = '';
    city: string = '';
    street: string = '';
    building: string = '';
    phoneNumber: string = '';
    mailAddress: string = '';
}

//連絡内容
export class InternalNotice {
    // 連絡文字列
    message: string = '';
    // 送信先(Internal/Floor/Meeting)
    target: string = '';
    // 表示snackbar背景色(info/warning/error/success)
    color: string = '';
    // 受信時に音を鳴らすかどうか
    doRingAlarm: boolean = false;
    // 送信元ユーザ名(表示名)
    user: string = '';
}

export class CheckMySelfStudyPlan {
    // 未完了の自習計画があるかどうか
    notCompleted : boolean = false;
    objectSetting: StudyObjectSetting = {} as StudyObjectSetting;
}

export class NewVisitor {
    // 送信元のユーザが指定したfloorId
    floorId: number = -1;
    // 送信元のユーザの名前
    userName: string = '';
    // 送信元のユーザのid
    userId: number = 0;
    // 許可申請元のfloorId
    targetFloorId: number = 0;
}

export class ExtendPrivacyRoomPermission {
    //プライバシールーム入室許可の延長時間
    extraTime: number = 0;
}

export class LeaveRoom {
    // 送信元のユーザが指定したfloorId
    floorId: number = -1;
    // 送信元のユーザの名前
    userName: string = '';
    // 送信元のユーザのid
    userId: number = 0;
}

export class PrivacyRoomPermission {
    // 相手のユーザのid
    userId: number = 0;
    // 相手のfloorId
    floorId: number = -1;
    // 入室を許可するかどうか
    permissionPrivacyRoom: boolean = false;
}

export class PrivacyRoomTimeOut {
    // 種類
    kind: number = 0;
    // 相手のfloorId
    floorId: number = -1;
}

export class EnterPrivacyRoom {
    // 相手のfloorId
    floorId: number = -1;
}

export class PrivacyRoomInfo {
    // プライバシールームid
    id: number = 0;
    //プライバシールームにいるかどうか
    stayPrivacyroom: boolean = false;
    // 元居たフロアId
    preFloorId: number = -1;
    // 元居たフロア名
    preFloorName: string = '';
    // 今居るフロアId
    floorId: number = -1;
    // 自分のsubId
    subId: string ='';
    // ログアウト状態にすべきか
    pretendLogout: boolean = false;
}

/** Jsonフォーマットのチェック
 *  jsonKind: Jsonの種類
 *   FLOOR_OBJECT_TEXT2_MEETINGROOM: 1
 */ 
export class chkJsonFormat {
    // Json種別
    static readonly FLOOR_OBJECT_TEXT2_MEETINGROOM: number = 1;
    static readonly FLOOR_OBJECT_TEXT2_PRIVACYROOM: number = 2;
    static readonly FLOOR_OBJECT_TEXT2_COUNTDOWNBOARD: number = 3;
    static readonly FLOOR_OBJECT_TEXT1_BANNER: number = 4;

    // Jsonフォーマットかどうかチェックする
    //  input
    //   jsonkind:  Json種別
    //   inputText: 判定するJson文字列
    //  output
    //   boolean: Jsonかどうか
    //   string:  入力文字列（null undefined の場合は""を返却する）
    //   any:  parseしたJsonObject（Jsonでない場合はnull）
    public checked( jsonKind: number, inputText: string): [ boolean, string, any ]
    {
        let retObj: [ boolean, string, any ] = [ false, "", null ]

        if(inputText===null || inputText===undefined){
            // null or 未定義の場合、何もしない
        } else if (inputText==="") {
            // 空白の場合、何もしない
        } else{
            // 戻り値の設定
            retObj[1] = inputText;

            try{
                // parse
                let jsonMsg = JSON.parse(inputText);
                
                if(jsonMsg===null || jsonMsg===undefined){
                    // 何もしない
                }else if( inputText===String(jsonMsg) ){
                    // parseしても値が変わらない場合はJsonではない（number）
                }else{
                    retObj[0] = true;
                    retObj[2] = jsonMsg;
                }
            }catch(e){
                // Jsonではない場合、何もしない
            }
        }

        // Jsonの中身を取得できた場合
        if(retObj[0] && (retObj[2] !== null)){
            // オブジェクトのみを評価する
            var typeString = Object.prototype.toString
            if( typeString.call(retObj[2])==="[object Object]" ){
                // 個別のチェックを行う
                switch(jsonKind)
                {
                    // FloorObject.text2（会議室用）
                    case chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM:
                        {
                            // キーのチェック
                            if(
                                !("message" in retObj[2]) ||
                                !("url" in retObj[2]) || 
                                !("isResetURL" in retObj[2]) 
                            )
                            {
                                // いずれかが欠けている場合はJsonNGとする
                                console.log("json key lost:" + inputText, retObj[2]);
                                retObj[0] = false;
                            }
                            break;
                        }
                    // FloorObject.text2（プライバシールーム用）
                    case chkJsonFormat.FLOOR_OBJECT_TEXT2_PRIVACYROOM:
                            {
                                // キーのチェック
                                if(
                                    !("state" in retObj[2])
                                )
                                {
                                    // いずれかが欠けている場合はJsonNGとする
                                    console.log("json key lost:" + inputText, retObj[2]);
                                    retObj[0] = false;
                                }
                                break;
                            }
                    case chkJsonFormat.FLOOR_OBJECT_TEXT2_COUNTDOWNBOARD:
                            {
                                // キーのチェック
                                if(
                                    !("eventName" in retObj[2]) ||
                                    !("eventDate" in retObj[2]) || 
                                    !("font" in retObj[2]) || 
                                    !("color" in retObj[2]) 
                                )
                                {
                                    // いずれかが欠けている場合はJsonNGとする
                                    console.log("json key lost:" + inputText, retObj[2]);
                                    retObj[0] = false;
                                }
                                break;
                            }
                    case chkJsonFormat.FLOOR_OBJECT_TEXT1_BANNER:
                            {
                                // キーのチェック
                                if(
                                    !("message" in retObj[2]) ||
                                    !("font" in retObj[2]) || 
                                    !("color" in retObj[2]) 
                                )
                                {
                                    // いずれかが欠けている場合はJsonNGとする
                                    console.log("json key lost:" + inputText, retObj[2]);
                                    retObj[0] = false;
                                }
                                break;
                            }
                    default:
                        {
                            // 何もしない
                        }
                }
            } else {
                // オブジェクトではない場合（string）は対象外
                console.log("not json:" + inputText, retObj[2], typeString.call(retObj[2]));
                retObj[0] = false;
           }
        }
        return retObj;
    }
}

// 名札の装飾に使うクラス
export class FlexibleNamePlate {
    id:number = 0;
    rgba:string = '';
    hex:string = '';
    colorName:string = '';
    discription:string = '';
    textColor:string = '';
}
export class ChangeMyRoomUser {
    id: number = 0; // 要らない？
    subId: string = "";
    seatNo: number = 0;
}

// チャイム情報
export class RingChimeInfo {
    // チャイム鳴動対象(Internal/Floor)
    target: string = '';
}

// 掲示板情報
export class NoticeData {
    id: number = 0;
    floorId: number = 0;
    objectId: number = 0;
    noticeNo: number = 0;
    name: string = "";
    type: number = 0;
    url: string = "";
    imgData: string = "";
    updateTime: Date = new Date();
    updateUser: string = "";
}

export class MeetingRoomLock {
    id: number = 0;
    locked: boolean = false;
}

export class GeneralMessageData {
    title: string = "";
    message: string = "";
}

export class ChatGroupData {
    groupId: number = 0;
    groupName: string = "";
    tabId: string = "";
    subIds: string = "";
    userIds: string = "";
}

export class LoginoutNoticeData {
    user: string = "";
    type: number = 0;
}

export class PointSettingInfo {
    id: number = 0;
    pointSettingName: string = '';
    ruleCount: number = 0;
    ruleFrequency: number = 0;
    point: number = 0;
    limitCount: number = 0;
    limitFrequency: number = 0;
    startDate: Date = new Date();
    endDate: Date = new Date();
    enable: boolean = true;
}

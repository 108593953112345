/* memo
object master: 930, 93, C:/temp/youth/signature/立て看板_青.png, 3000009, 98,, 12, 0, 0, 60, 40

floor_object:
text2 setting json format
{
    img_url: "",
    notification: false,
    signboardName: "",
    opensite: "newTab"
    updateTime: timestamp,
    updateUser: props.userName,
}
*/

import React, { Fragment, useMemo, useRef, useEffect, useCallback, createRef } from 'react';
import { FloorObject } from '../common/JsonClass';
import { makeStyles, Theme, createStyles, useTheme, withStyles, Icon, FormControl } from '@material-ui/core';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Zoom from '@material-ui/core/Zoom';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '../img/edit_B.png';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import axios, { AxiosResponse } from 'axios';
import { Utility } from '../common/Utility';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Document, Page, pdfjs} from "react-pdf";
import JfsClient from '@fsi/jfs-sdk';
import icon01 from '../img/icon/icon_01.png'
import icon02 from '../img/icon/icon_02.png'
import icon03 from '../img/icon/icon_03.png'
import icon04 from '../img/icon/icon_04.png'
import icon05 from '../img/icon/icon_05.png'
import icon06 from '../img/icon/icon_06.png'
import icon07 from '../img/icon/icon_07.png'
import icon08 from '../img/icon/icon_08.png'
import icon09 from '../img/icon/icon_09.png'
import icon10 from '../img/icon/icon_10.png'
import icon11 from '../img/icon/icon_11.png'
import icon12 from '../img/icon/icon_12.png'
import icon13 from '../img/icon/icon_13.png'
import icon14 from '../img/icon/icon_14.png'
import icon99 from '../img/icon/icon_99.png'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const textFieldTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icons: {
            marginBottom: 10,
            alignContent: 'center', 
            alignItems: 'center',
            justifyContent: 'center',
            width: 50,
            height: 50,
            backgroundSize: "contain",
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
            wordBreak: 'break-all',
            display: 'flex',
        },
        iconsText: {
            marginBottom: 10,
            alignContent: 'center', 
            alignItems: 'center',
            justifyContent: 'center',
            width: 50,
            height: 20,
            wordBreak: 'break-all',
            display: 'flex',
            fontSize: '10px',
        },
        signboardImg: {
            width: 41,
            height: 37,
            backgroundSize: "contain",
            fontSize: '10px',
            zIndex: ZIndex.floorObject,
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
            wordBreak: 'break-all',
            position: "absolute",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            //backgroundColor: 'white',
        },
        signboardText: {
            width: 41,
            height: 36,
            backgroundSize: "contain",
            fontSize: '10px',
            zIndex: ZIndex.floorObject,
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
            wordBreak: 'break-all',
            position: "absolute",
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            cursor: 'pointer',
            //backgroundColor: 'white',
        },
        signboard:{
            backgroundRepeat: 'no-repeat',
            backgroundSize: "contain",
            zIndex: ZIndex.floorObject,
        },
        clickMeIcon: {

        },
        settingIcon1: {
            position: 'absolute',
            left: 177,
            top: 14,
            zIndex: ZIndex.fixedUiLayer,
            backgroundColor: 'white',
            border: '1px solid black',
            width: 11,
            height: 11,
            '&:hover': {
                background: 'lightgray',
            },
        },
        settingIcon2: {
            position: 'absolute',
            left: 260,
            top: 19,
            zIndex: ZIndex.fixedUiLayer,
            backgroundColor: 'white',
            border: '1px solid black',
            width: 11,
            height: 11,
            '&:hover': {
                background: 'lightgray',
            },
        },
        settingIcon3: {
            position: 'absolute',
            left: 339,
            top: 25,
            zIndex: ZIndex.fixedUiLayer,
            backgroundColor: 'white',
            border: '1px solid black',
            width: 11,
            height: 11,
            '&:hover': {
                background: 'lightgray',
            },
        },
        settingIcon4: {
            position: 'absolute',
            left: 419,
            top: 18,
            zIndex: ZIndex.fixedUiLayer,
            backgroundColor: 'white',
            border: '1px solid black',
            width: 11,
            height: 11,
            '&:hover': {
                background: 'lightgray',
            },
        },
        settingDialog: {
            //width: '45%',
        },
        settingTitle:{
            fontWeight:'bold', 
            fontSize: 16,
            fontFamily: 'Hiragino Maru Gothic StdN',
        },
    }),
);

const radioButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

interface Props {
    floorObject: FloorObject,
    openPage: (url: string, boardName: string, download: boolean, floorId: number, objectId: number, noticeNo: number) => void,
    sendWebSocket: (action: string, object: object) => void,
    setWebpageUrl: (url: string) => void,
    userName: string,
    role: string, 
}

class NoticeData {
    id: number = 0;
    floorId: number = 0;
    objectId: number = 0;

    name1: string = "";
    type1: string = "url";
    url1: string = "";
    imgData1: string = "";
    iconData1: string = "";
    updateTime1: string = "";
    updateUser1: string = "";
    readed1:boolean = false;
    download1:boolean = false;

    name2: string = "";
    type2: string = "url";
    url2: string = "";
    imgData2: string = "";
    iconData2: string = "";
    updateTime2: string = "";
    updateUser2: string = "";
    readed2:boolean = false;
    download2:boolean = false;

    name3: string = "";
    type3: string = "url";
    url3: string = "";
    imgData3: string = "";
    iconData3: string = "";
    updateTime3: string = "";
    updateUser3: string = "";
    readed3:boolean = false;
    download3:boolean = false;

    name4: string = "";
    type4: string = "url";
    url4: string = "";
    imgData4: string = "";
    iconData4: string = "";
    updateTime4: string = "";
    updateUser4: string = "";
    readed4:boolean = false;
    download4:boolean = false;
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //backgroundColor: 'rgba(0, 0, 255, 1)',
};

export default function DorakidsNoticeBoard(props: Props) {
    const tabId = sessionStorage.getItem('TABID') || '';

    const MAX_URL_LENGTH = 512;
    const MAX_TEXT_LENGTH = 8;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const [dispSetting, setDispSetting] = useState(false);
    const [notification, setNotification] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [imgUrl, setImgUrl] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [signboardName, setSignboardName] = useState('');
    const [updateTime, setUpdateTime] = useState('');
    const [updateUser, setUpdateUser] = useState('');
    const [errMsgOnBoardName, setErrMsgOnBoardName] = useState('');
    const [errMsgOnSiteUrl, setErrMsgOnSiteUrl] = useState('');
    const [errMsgOnImgUrl, setErrMsgOnImgUrl] = useState('');
    const [noticeData, setNoticeData] = useState(new NoticeData());
    const classes = useStyles();
    const [type, setType] = useState('url');
    const [uploadFile, setUploadFile] = React.useState({});
    const [previewSrc, setPreviewSrc] = React.useState("");
    const [noticeNo, setNoticeNo] = React.useState(0);
    const [uploadIcon, setUploadIcon] = React.useState({});
    const [previewIcon, setPreviewIcon] = React.useState("");
    const [download, setDownload] = React.useState(false);
    const [width, setWidth] = React.useState(1);
    const [icon, setIcon] = React.useState(-1);
    const iconArr = [icon01, icon02, icon03, icon04, icon05, icon06, icon07, icon08, icon09, icon10, icon11, icon12, icon13, icon14, ];
    const iconTextArr = ["PR", "予定", "案内", "受験", "模試", "イベント", "発表", "順位", "実績", "講師紹介", "連絡", "お得", "秘", "進路", ];
    const [isShowClearConfirmDialog, setShowClearConfirmDialog] = React.useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient } = jfsClient;
    //tooltipをカスタマイズして上のエフェクトへ
    const CustomizedTooltip = withStyles((Theme) => ({
        tooltip: {
            backgroundColor: 'transparent',
        },
    }))(Tooltip);

    useEffect(() => {
        parseJsonSetting();
    }, [props.floorObject, /*dispSetting*/]);

    useEffect(() => {
        checkLength();
    }, [siteUrl, imgUrl, disableButton, signboardName]);

    //text2のjsonをそれぞれのstateに割り振る
    const parseJsonSetting = () => {
        if(text2 !== "" && text2 !== null) {
            try{
                const text2Obj = JSON.parse(text2);

                var data = new NoticeData();
                data.floorId=text2Obj.floorId;
                data.objectId=text2Obj.objectId;

                // axios.get('/api/user/floor/notice', {
                //     params: {
                //     floorId: data.floorId,
                //     objectId: data.objectId,
                //     tabId: sessionStorage.getItem("TABID") as string,
                //     }
                // })
                httpClient.getNotice(
                    data.objectId,
                    data.floorId,
                    sessionStorage.getItem("TABID") as string
                )
                .then((e) => {
                    let notices = e;
                    data.id=notices.id;
                    if(notices.name1 != null && notices.name1 != ""){
                        data.name1=notices.name1;
                        data.type1=notices.type1;
                        data.url1=notices.url1?notices.url1:"";
                        data.imgData1=notices.imgData1?notices.imgData1:"";
                        data.iconData1=notices.iconData1?notices.iconData1:"";
                        data.updateTime1=notices.updateDate1;
                        data.updateUser1=notices.updateUser1.toString();
                        data.readed1=notices.readed1;
                        data.download1=notices.download1;
                    }
    
                    if(notices.name2 != null && notices.name2 != ""){
                        data.name2=notices.name2;
                        data.type2=notices.type2;
                        data.url2=notices.url2?notices.url2:"";
                        data.imgData2=notices.imgData2?notices.imgData2:"";
                        data.iconData2=notices.iconData2?notices.iconData2:"";
                        data.updateTime2=notices.updateDate2;
                        data.updateUser2=notices.updateUser2.toString();
                        data.readed2=notices.readed2;
                        data.download2=notices.download2;
                    }
                    
                    if(notices.name3 != null && notices.name3 != ""){
                        data.name3=notices.name3;
                        data.type3=notices.type3;
                        data.url3=notices.url3?notices.url3:"";
                        data.imgData3=notices.imgData3?notices.imgData3:"";
                        data.iconData3=notices.iconData3?notices.iconData3:"";
                        data.updateTime3=notices.updateDate3;
                        data.updateUser3=notices.updateUser3.toString();
                        data.readed3=notices.readed3;
                        data.download3=notices.download3;
                    }
    
                    if(notices.name4 != null && notices.name4 != ""){
                        data.name4=notices.name4;
                        data.type4=notices.type4;
                        data.url4=notices.url4?notices.url4:"";
                        data.imgData4=notices.imgData4?notices.imgData4:"";
                        data.iconData4=notices.iconData4?notices.iconData4:"";
                        data.updateTime4=notices.updateDate4;
                        data.updateUser4=notices.updateUser4.toString();
                        data.readed4=notices.readed4;
                        data.download4=notices.download4;
                    }
                    setNoticeData({...data});
                })
                .catch(err => {
                })
                .finally(() => {
                });
            } catch(e) {
                console.log('text2の内容が不適切: ' + text2);
            }
        }
    }

    /*
    * 設定ダイアログでOKを押されたときのハンドラ
    */
    const handleClickComplete = () => {
        if(!checkLength()){return;}
        sendWebSocket();
        props.setWebpageUrl(siteUrl);
        setDispSetting(false);
    }

    function createFileFromBase64(base64:string, name:string) {
        // base64のデコード
        var bin = atob(base64.replace(/^.*,/, ''));
        // バイナリデータ化
        var buffer = new Uint8Array(bin.length);
        for (var i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
        }

        let fileType = "";
        try{
            // console.info(base64.split(",")[0]);
            // console.info(base64.split(",")[0].split(":")[1]);
            // console.info(base64.split(",")[0].split(":")[1].split(";")[0]);
            fileType = base64.split(",")[0].split(":")[1].split(";")[0];
        }catch{
            console.info("err");
        }
        // ファイルオブジェクト生成
        return new File([buffer.buffer], name, {type: fileType});
    };
    
    /*
    * 作成・更新websocketで設定送信
    */
    const sendWebSocket = () => {

        // const params = new FormData();
        // params.append('tabId', (sessionStorage.getItem("TABID") as string));

        let downLoad: boolean | null = null;
        let file = {} as File;
        let fileType = "";
        let iconPre: string | null = null;
        let iCon: File | null = null;
        let iconType: string | null = null;

        if(type != "url"){

            // params.append('download', download?"true":"false");
            downLoad = download; 

            if(uploadFile instanceof File){
                //console.info("fileSize:" ,(uploadFile as File).size);
                // params.append('file', (uploadFile as File));
                file = uploadFile as File;
                // params.append('fileType', (uploadFile as File).type);
                fileType = (uploadFile as File).type;
            }else{
                //let file = createFileFromBase64(previewSrc, "notice");
                //console.info("filePreSize:" ,file.size);
                // params.append('file', file);
                file = createFileFromBase64(previewSrc, "notice");
                // params.append('fileType', file.type);
                fileType = file.type;
            }
    
            if(icon > 0){
                // params.append('iconPre', icon + '');
                //console.info("iconNo", icon);
                iconPre = icon.toString();
            }else{
                if(uploadIcon instanceof File){
                    //console.info("iconSize:" ,(uploadIcon as File).size);
                    //params.append('icon', (uploadIcon as File));
                    iCon = uploadIcon as File;
                    //params.append('iconType', (uploadIcon as File).type);
                    iconType = iCon.type;
                }else{
                    //let file = createFileFromBase64(previewIcon, "notice");
                    //console.info("iconPreSize:" ,file.size);
                    //params.append('icon', file);
                    iCon = createFileFromBase64(previewIcon, "notice");
                }
            }
        }
        // params.append('name', signboardName);
        // params.append('floorId', noticeData.floorId + "");
        // params.append('objectId', noticeData.objectId + "");
        // params.append('id', noticeData.id + "");
        // params.append('noticeNo', noticeNo + "");
        // params.append('url', siteUrl);
        // params.append('type', type);

        // axios.post('/api/user/floor/notice', params,
        // {
        //     headers: { "Content-Type": "multipart/form-data" },
        // })
        httpClient.setNotice(
            sessionStorage.getItem("TABID") as string,
            downLoad,
            file,
            fileType,
            iconPre,
            iCon,
            iconType,
            signboardName,
            noticeData.floorId,
            noticeData.objectId,
            noticeData.id,
            noticeNo,
            siteUrl,
            type
        )
        .then((response) => {
            // 送信内容の作成
            // const settingJson = {
            //     name: signboardName,
            //     notification: notification,
            // };
            //props.sendWebSocket('NOTICE_SETTING', settingJson);
            wsClient.sendNoticeSetting(signboardName, notification);
        }).catch(err => {
            console.log(err);
        });

    }

    /*
    * 看板名前の入力内容を削除
    */
    const onClearSignboardName = useCallback(() => {
        setSignboardName('');
    }, [signboardName]);

    /*
    * サイトURLの入力内容を削除
    */
    const onClearSiteUrl = useCallback(() => {
        setSiteUrl('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteUrl]);

    /*
    * フロア通知のチェックボックス用のハンドラ
    */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotification(event.target.checked);
    };

    /*
    * フロア通知のチェックボックス用のハンドラ
    */
    const handleDownloadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDownload(event.target.checked);
    };

    /*
    * テキストボックス用のハンドラ
    */
    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisableButton(false);

        if(event.target.name === 'siteUrl'){
            setSiteUrl(event.target.value);   
        }
        if(event.target.name === 'imgUrl'){
            setImgUrl(event.target.value);
        }
        if(event.target.name === 'signboardName'){
            setSignboardName(event.target.value);
        }
    }

    /*
    * 文字数チェック
    */
    const checkLength = ():boolean => {
        // 文字数チェック
        var result = true;
        if(signboardName.length === 0) {
            // 看板の名前は入力必須
            result = false;
        } else if(judgehz(signboardName) > MAX_TEXT_LENGTH) {
            // 看板の名前
            result = false;
        } else if(siteUrl.length > MAX_URL_LENGTH) {
            // サイトURL
            result = false;
        } else if(imgUrl.length > MAX_URL_LENGTH) {
            // 画像RL
            result = false;
        }

        return result;
    }

    /*
    * 全角・半角を考慮した文字数チェック
     */
    const judgehz = (intext:string) => {
        let len = 0
        let text = intext.split('')
 
        // 半角全角判定
        for(let i = 0; i < intext.length; i++){
            if(text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)){
                len = len + 0.5     // 半角
            }else{
                len = len + 1       // 全角
            }
        }
 
        return len
    };

    /*
    * テキストボックスについてのエラーメッセージを出力
    */
    const textLength = (maxLength: number, inputText: string, distinctionHz: boolean = false, required: boolean = false) => {
        /* let len = judgehz(inputText) */
        let len = 0
        if(distinctionHz) {
            len = judgehz(inputText)
        } else {
            len = inputText.length
        }
        if( maxLength < len ){
            return (
                <div style={{ color:"red" }}>入力した文字列が長すぎます。 </div>
            );
        }

        if( required && len === 0){
            return (
                <div style={{ color:"red" }}>入力必須です。 </div>
            );
        }
    };

    /*
    * 看板の名前用プログレスバー
    */
    const progressBarSignBoradName = useMemo(() => {
        let parsent = (judgehz(signboardName)/MAX_TEXT_LENGTH) * 100;
        let progressColor = '#FFE1A2';
         
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
         
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_TEXT_LENGTH, signboardName, true, true)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[signboardName])

    /*
    * サイトURL用プログレスバー
    */
    const progressBarSiteUrl = useMemo(() => {
        let parsent = (siteUrl.length/MAX_URL_LENGTH) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';
         
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
         
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_URL_LENGTH, siteUrl)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[siteUrl])

    /*
    * 画像URL用プログレスバー
    */
    const progressBarImageUrl = useMemo(() => {
        let parsent = (imgUrl.length/MAX_URL_LENGTH) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';
         
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
         
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_URL_LENGTH, imgUrl)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[imgUrl])

    /*
    * タイプ用ハンドラ
    */
    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setUploadFile({});
        setPreviewSrc("");
        setType(event.target.value);
    }

    const handleEditButtonClick = (no:number) => {
        setDispSetting(true);
        setNoticeNo(no);
        setUploadFile({});
        setUploadIcon({});
        switch(no){
            case 1:
                setSignboardName(noticeData.name1);
                setSiteUrl(noticeData.url1);
                setUpdateUser(noticeData.updateUser1);
                setType(noticeData.type1 == ""?"url":noticeData.type1);
                setUpdateTime(noticeData.updateTime1);
                setPreviewSrc(noticeData.imgData1);
                setDownload(noticeData.download1);
                if(noticeData.type1 == "img" || noticeData.type1 == "pdf"){
                    if(noticeData.iconData1.startsWith("data:")){
                        setIcon(0);
                        setPreviewIcon(noticeData.iconData1);
                    }else{
                        setIcon(+noticeData.iconData1);
                        setPreviewIcon("");
                    }
                }else{
                    setIcon(-1);
                    setPreviewIcon("");
                }
                break;
            case 2:
                setSignboardName(noticeData.name2);
                setSiteUrl(noticeData.url2);
                setUpdateUser(noticeData.updateUser2);
                setType(noticeData.type2 == ""?"url":noticeData.type2);
                setUpdateTime(noticeData.updateTime2);
                setPreviewSrc(noticeData.imgData2);
                setDownload(noticeData.download2);
                if(noticeData.type2 == "img" || noticeData.type2 == "pdf"){
                    if(noticeData.iconData2.startsWith("data:")){
                        setIcon(0);
                        setPreviewIcon(noticeData.iconData2);
                    }else{
                        setIcon(+noticeData.iconData2);
                        setPreviewIcon("");
                    }
                }else{
                    setIcon(-1);
                    setPreviewIcon("");
                }
                break;
            case 3:
                setSignboardName(noticeData.name3);
                setSiteUrl(noticeData.url3);
                setUpdateUser(noticeData.updateUser3);
                setType(noticeData.type3 == ""?"url":noticeData.type3);
                setUpdateTime(noticeData.updateTime3);
                setPreviewSrc(noticeData.imgData3);
                setDownload(noticeData.download3);
                if(noticeData.type3 == "img" || noticeData.type3 == "pdf"){
                    if(noticeData.iconData3.startsWith("data:")){
                        setIcon(0);
                        setPreviewIcon(noticeData.iconData3);
                    }else{
                        setIcon(+noticeData.iconData3);
                        setPreviewIcon("");
                    }
                }else{
                    setIcon(-1);
                    setPreviewIcon("");
                }
                break;
            case 4:
                setSignboardName(noticeData.name4);
                setSiteUrl(noticeData.url4);
                setUpdateUser(noticeData.updateUser4);
                setType(noticeData.type4 == ""?"url":noticeData.type4);
                setUpdateTime(noticeData.updateTime4);
                setPreviewSrc(noticeData.imgData4);
                setDownload(noticeData.download4);
                if(noticeData.type4 == "img" || noticeData.type4 == "pdf"){
                    if(noticeData.iconData4.startsWith("data:")){
                        setIcon(0);
                        setPreviewIcon(noticeData.iconData4);
                    }else{
                        setIcon(+noticeData.iconData4);
                        setPreviewIcon("");
                    }
                }else{
                    setIcon(-1);
                    setPreviewIcon("");
                }
                break;
        }
    }


    /*
    * 編集アイコンの表示
    */
    const drawSettingIcon = (role: string) => {

        let allowSetting = props.role !== undefined && props.role !== "ROLE_USER";
        
        if(allowSetting) {
            return (
                <Fragment>
                    <IconButton onClick={() => handleEditButtonClick(1)} disableTouchRipple={true} className={classes.settingIcon1}>
                        <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                    </IconButton>
                    <IconButton onClick={() => handleEditButtonClick(2)} disableTouchRipple={true} className={classes.settingIcon2}>
                        <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                    </IconButton>
                    <IconButton onClick={() => handleEditButtonClick(3)} disableTouchRipple={true} className={classes.settingIcon3}>
                        <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                    </IconButton>
                    <IconButton onClick={() => handleEditButtonClick(4)} disableTouchRipple={true} className={classes.settingIcon4}>
                        <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                    </IconButton>
                </Fragment>
            )
        }
    }

    /*
    * 未読アイコンの表示
    */
    const drawUnreadedIcon = (role: string) => {
        // 表示・非表示の確認
        //一般ユーザーのみ表示、管理者は非表示
        let allowSetting = props.role !== undefined && props.role == "ROLE_USER";
        
        if(allowSetting) {
            return (
                <Fragment>
                    {!noticeData.readed1 && noticeData.name1 != "" &&
                        <IconButton className={classes.settingIcon1} style = {{backgroundColor:'#FF0000', border:0}}
                            onClick={() => openDetail(1, noticeData.type1, noticeData.name1, noticeData.url1, noticeData.imgData1,noticeData.download1, noticeData.floorId, noticeData.objectId)}>
                        </IconButton>
                    }
                    {!noticeData.readed2 && noticeData.name2 != "" &&
                        <IconButton className={classes.settingIcon2} style = {{backgroundColor:'#FF0000', border:0}}
                            onClick={() => openDetail(2, noticeData.type2, noticeData.name2, noticeData.url2, noticeData.imgData2,noticeData.download2, noticeData.floorId, noticeData.objectId)}>
                        </IconButton>
                    }
                    {!noticeData.readed3 && noticeData.name3 != "" &&
                        <IconButton className={classes.settingIcon3} style = {{backgroundColor:'#FF0000', border:0}}
                            onClick={() => openDetail(3, noticeData.type3, noticeData.name3, noticeData.url3, noticeData.imgData3,noticeData.download3, noticeData.floorId, noticeData.objectId)}>
                        </IconButton>
                    }
                    {!noticeData.readed4 && noticeData.name4 != "" &&
                        <IconButton className={classes.settingIcon4} style = {{backgroundColor:'#FF0000', border:0}}
                            onClick={() => openDetail(4, noticeData.type4, noticeData.name4, noticeData.url4, noticeData.imgData4,noticeData.download4, noticeData.floorId, noticeData.objectId)}>
                    </IconButton>
                    }
                </Fragment>
            )
        }
    }

    /*
    * 最終更新情報を表示
    */
    const drawLastUpdate = useMemo(() => {
        if (updateUser === undefined || updateUser === null || updateUser === '' 
            || updateTime === undefined || updateTime === null || updateTime === ''){
            return;
        }
        return (
            <div style={{marginBottom: 10, position: "absolute", bottom: 0}}>
                最終更新: {updateUser}  {updateTime}
            </div>
        )
    }, [updateUser, updateTime]);


    const onPdfLoadSuccess = (pdfObj:any) =>{
        setWidth(document.getElementById("prePdf")!.clientWidth);
    }

    /*
    * タイプ用ハンドラ
    */
    const handleIconChange = (iconNo:number) =>{
        setIcon(iconNo);
    }
    /*
    * 設定ダイアログ
    */
    const settingDialog = useMemo(() => {
        // 表示・非表示の確認
        if(!dispSetting) {
            return;
        }

        return (
            <BaseDialog
                open={dispSetting}
                onClose={() => setDispSetting(false)}
                aria-labelledby='DeleteObjectDialog'
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="dialog-title" style={{ userSelect: 'none',  background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>掲示板の設定</DialogTitle>
                <DialogContent className={classes.settingDialog}>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={12} className={classes.settingTitle}>
                        掲示物のタイトル
                        </Grid>
                        <Grid item xs={12}>
                        掲示物のタイトルを設定してください。掲示板にはアイコンのしたに８文字まで表示されます。
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            <DialogContentText style={{ marginBottom: "0px" }} >
                                {progressBarSignBoradName}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            <div style={{color: '#707070'}}>掲示物のタイトル</div>
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="signboardName"
                                    name="signboardName"
                                    //label="掲示物のタイトル"
                                    variant={'outlined'}
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    onChange={handleUrlChange}
                                    value={signboardName}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={onClearSignboardName}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>

                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={12} className={classes.settingTitle}>掲示物の種類</Grid>
                    </Grid>
                    <ThemeProvider theme={radioButtonTheme}>
                        <RadioGroup aria-label="radio" name="radio" value={type} onChange={handleTypeChange}>
                        <Grid container alignItems='center'>
                            <Grid item xs={Utility.getType() == 2 ? 12: 3}>
                                <FormControlLabel value="url" control={<Radio color="primary" />} label="WebページURL" />
                            </Grid>
                            <Grid item xs={Utility.getType() == 2 ? 12: 3}>
                                <FormControlLabel value="img" control={<Radio color="primary" />} label="画像ファイル" />
                            </Grid>
                            <Grid item xs={Utility.getType() == 2 ? 12: 3}>
                                <FormControlLabel value="pdf" control={<Radio color="primary" />} label="PDFファイル" />
                            </Grid>
                        </Grid>
                        </RadioGroup>
                    </ThemeProvider>
                    <Grid container justify='center' alignItems='center' style = {{display : type == 'url'?'':'none'}}>
                        <Grid item xs={12}>
                            <DialogContentText style={{ marginBottom: "0px" }} >
                                {progressBarSiteUrl}
                            </DialogContentText>
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center' style = {{display : type == 'url'?'':'none'}}>
                        <Grid item xs={12}>
                            <div style={{color: '#707070'}}>WebページURL</div>
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    margin="dense"
                                    id="siteUrl"
                                    name="siteUrl"
                                    //label="WebページURL"
                                    variant={'outlined'}
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    onChange={handleUrlChange}

                                    value={siteUrl}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={onClearSiteUrl}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center' style={{ marginTop: 20, marginBottom: 20, display : type == 'url' ? 'none' : '' }}>
                        <Grid item xs={5} className={classes.settingTitle}>
                            アイコン選択
                        </Grid>
                        <Grid item xs={7} className={classes.settingTitle}>
                            <div style={{alignContent:"end", marginRight:15, alignItems: "end", textAlign: "end"}}>
                            <Button variant="contained" color="primary" size="small" style={{background: '#006FBC', borderRadius: '31px'}} onClick={() => handleClickFileSelectButton(false)}>アップロード</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                        掲示板に表示するアイコンを以下から選択するか、アップロードボタンから画像をアップロードしてください。
                        </Grid>
                    </Grid>
                    <Grid container alignItems='center' style={{ marginBottom: 20, display : type == 'url' ? 'none' : '' }}>
                        {iconArr.map((ic, index) =>{
                            return(
                            <Grid item xs={Utility.getType() == 2 ? 3: 1}>
                            <div 
                                className={classes.icons}
                                onClick={() => handleIconChange(index + 1)}
                                style={{
                                    backgroundColor: icon == index + 1 ? '#19857b' : '#ffffff',
                                    backgroundImage: `url(${ic})`,
                                }}></div>
                            <div className={classes.iconsText}>{iconTextArr[index]}</div>
                            </Grid>)
                        })}
                        {previewIcon != "" &&
                        <Grid item xs={Utility.getType() == 2 ? 3: 1}>
                            <div 
                                className={classes.icons}
                                onClick={() => handleIconChange(0)}
                                style={{
                                    backgroundColor: icon == 0 ? '#19857b' : '#ffffff',
                                    backgroundImage: `url(${previewIcon})`,
                                }}></div>
                            <div className={classes.iconsText}></div>
                        </Grid>}
                        <Grid item xs={Utility.getType() == 2 ? 3: 1}>
                            <div 
                                className={classes.icons}
                                onClick={() => handleIconChange(99)}
                                style={{
                                    backgroundColor: icon == 99 ? '#19857b' : '#ffffff',
                                    backgroundImage: `url(${icon99})`,
                                }}></div>
                            <div className={classes.iconsText}></div>
                        </Grid>
                    </Grid>
{/*
                    <Grid container justify='center' alignItems='center' style={{ marginBottom: 20, display : type == 'img'?'none':'none' }}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="imgUrl"
                                name="imgUrl"
                                label="アイコン・サムネイル"
                                variant={'outlined'}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                onChange={handleUrlChange}
                                value={uploadIcon == {}?"":(uploadIcon as File).name}
                                InputProps={{
                                    readOnly:true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant="contained" color="primary" size="small" onClick={() => handleClickFileSelectButton(false)}>ファイル選択</Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container justify='center' alignItems='center' style={{ display : type == 'img' && previewIcon != '' ? 'none':'none' }}>
                        <iframe id='popup-iframe' src={previewIcon} style={{width: '100%', height: '100%'}} frameBorder="0"/>
                    </Grid>
*/}
                    <Grid container justify='center' alignItems='center' style={{ marginTop: 20, marginBottom: 20, display : type == 'url' ? 'none' : '' }}>
                        <Grid item xs={5} className={classes.settingTitle}>
                            掲示物の内容
                        </Grid>
                        <Grid item xs={7} className={classes.settingTitle}>
                            <div style={{alignContent:"end", marginRight:15, alignItems: "end", textAlign: "end"}}>
                            <Button variant="contained" color="primary" size="small" style={{background: '#006FBC', borderRadius: '31px'}} onClick={() => handleClickFileSelectButton(true)}>アップロード</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 0, }}>
                        アップロードを押して、掲示物の内容を設定してください
                        </Grid>
                    </Grid>
{/*}
                    <Grid container justify='center' alignItems='center' style={{ marginBottom: 20, display : type == 'url'?'none':'' }}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="imgUrl"
                                name="imgUrl"
                                label="ファイル"
                                variant={'outlined'}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                onChange={handleUrlChange}
                                value={uploadFile == {}?"":(uploadFile as File).name}
                                InputProps={{
                                    readOnly:true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant="contained" color="primary" size="small" onClick={() => handleClickFileSelectButton(true)}>ファイル選択</Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
*/}
                    <Grid container justify='center' alignItems='center' style={{ display : (type == 'url' || previewSrc == '') ?'none':'' }}>
                        {type == 'img' && <iframe id='popup-iframe' src={previewSrc} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
                        {type == 'pdf' && dispSetting &&
                        <div id = "prePdf" 
                            style={{
                                width: '100%', 
                                height: '100%',                             
                                overflow : 'auto',
                                alignContent : 'center',
                                alignItems : 'center',}} >
                            <Document file = {previewSrc}>
                                <Page pageNumber={1} width={width} onLoadSuccess={onPdfLoadSuccess}/>
                            </Document>
                        </div>}
                    </Grid>
                    <Grid container justify='center' alignItems='center' style={{ marginTop: 20, marginBottom: 20, display : type == 'url' ? 'none' : '' }}>
                        <Grid item xs={12} className={classes.settingTitle}>
                        フロア通知
                        </Grid>
                        <Grid item xs={12}>
                        チェックをオンにすると、掲示板に更新があることをフロアの全員に通知します。
                        </Grid>
                        <Grid item xs={12}>
                            <ThemeProvider theme={checkBoxTheme}>
                                <Checkbox
                                    color='primary'
                                    checked={notification}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </ThemeProvider>
                            通知オン
                        </Grid>
                    </Grid>
                    <Grid container justify='center' alignItems='center' style={{ marginTop: 20, display : type == 'url'?'none':'', }}>
                        <Grid item xs={12} className={classes.settingTitle}>
                        ダウンロード許可
                        </Grid>
                        <Grid item xs={12}>
                        チェックをオンにすると、PDF/画像の元ファイルをユーザーがダウンロードして保存できるようになります。
                        </Grid>
                        <Grid item xs={12}>
                            <ThemeProvider theme={checkBoxTheme}>
                                <Checkbox
                                    color='primary'
                                    checked={download}
                                    onChange={handleDownloadChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </ThemeProvider>
                            ファイルのダウンロードを許可する
                        </Grid>
                    </Grid>
                {drawLastUpdate}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => {setShowClearConfirmDialog(true)}} style={{ pointerEvents: 'auto', color: '#006FBC', fontFamily: 'Hiragino Maru Gothic StdN' }}>クリア</Button>
                    <Button color='primary' 
                        disabled={checkInput() == false}
                        onClick={handleClickComplete} style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110 }} >OK</Button>
                    <Button color='primary' onClick={() => setDispSetting(false)} style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [icon, iconArr, uploadIcon, previewIcon, uploadFile, previewSrc, type, dispSetting, notification, imgUrl, siteUrl, props.floorObject, signboardName, disableButton, errMsgOnBoardName, errMsgOnImgUrl, errMsgOnSiteUrl, download, width]);

    function checkInput():boolean{

        if(signboardName == "") return false;
        if(type != "url" && icon < 0) return false;
        if(type != "url" &&  !(uploadFile instanceof File || previewSrc != "")) return false;
        if(type != "url" &&  icon == 0 && !(uploadFile instanceof File || previewSrc != "")) return false;
        return true;
    }

    const handleClickFileSelectButton = async (isFile: boolean) => {
        if(isFile){
            setUploadFile({});
        }else{
            setUploadIcon({});
        }
        await showOpenFileDialog(isFile);
      }
    
    const showOpenFileDialog = (isFile: boolean) => {
        return new Promise(resolve => {
            const input = document.createElement('input');
            input.type = 'file';

            if(type == "pdf" && isFile){
                input.accept = 'application/pdf';
            }else{
                input.accept = 'image/*';
            }
 
            input.onchange = (ev) => {
                let eventTarget: HTMLInputElement = (ev.target as HTMLInputElement);
                if (eventTarget !== null && eventTarget.files !== null) {

                    let file = eventTarget.files[0];
                    //ファイルタイプチェック
                    if((type=="img" || !isFile ) && !file.type.match('image.*')){
                        // 画像タイプですが、画像ファイルではない場合
                        alert("画像ファイルを選択してください。");
                        return;
                    }

                    if(type=="pdf" && isFile && !file.type.match('application/pdf')){
                        // PDFタイプですが、PDFファイルではない場合
                        alert("PDFファイルを選択してください。");
                        return;
                    }
                    //ファイルサイズチェック
                    if(file.size > 5 * 1024 * 1024){
                        alert("選択されたファイルのサイズが5MB超えています。");
                        return;
                    }

                    if(isFile){
                        setUploadFile(file);
                        setPreviewSrc(URL.createObjectURL(file));
                        if (uploadFile != null) {
                            resolve(uploadFile);
                        }
                    }else{
                        setIcon(0);
                        setUploadIcon(file);
                        setPreviewIcon(URL.createObjectURL(file));
                        if (uploadIcon != null) {
                            resolve(uploadIcon);
                        }
                    }
                }
            };
          input.click();
        });
      };

    const openDetail = (noticeNo:number, type:string, name:string, url:string, img:string, enableDownload:boolean, floorId:number, objectId:number) => {

        if(name =="" || noticeData.id == 0){
            return;
        }

        let allowSetting = props.role !== undefined && props.role != "ROLE_USER";

        if(type == "url"){
            if(url == ""){
                return;
            }
            window.open(url, "_blank");
        }else{
            if(img == ""){
                return;
            }
            props.openPage(img, name, enableDownload, floorId, objectId, noticeNo);
        }

        // 一般ユーザーのみ、既読更新を行う
        if(allowSetting){
            return;
        }
        // 既に既読の場合は処理中止
        if(    (noticeNo == 1 && noticeData.readed1)
            || (noticeNo == 2 && noticeData.readed2)
            || (noticeNo == 3 && noticeData.readed3)
            || (noticeNo == 4 && noticeData.readed4)){
            return;
        }

        // 既読更新
        const settingJson = {
            noticeId: noticeData.id,
            floorId: noticeData.floorId,
            objectId: noticeData.objectId,
            noticeNo: noticeNo,
        };
        // props.sendWebSocket('NOTICE_READED', settingJson);
        try {
            wsClient.sendNoticeReaded(noticeData.id, noticeData.floorId, noticeData.objectId, noticeNo);
        } catch (error) {
            console.error(error);            
        }
    }

    /*
    * 掲示板のクリア
    */
    const handleClearNotice = (mode: boolean) =>{

        if(mode){

            if(noticeData.id == 0){
                return;
            }
    
            // const settingJson = {
            //     noticeId: noticeData.id,
            //     floorId: noticeData.floorId,
            //     objectId: noticeData.objectId,
            //     noticeNo: noticeNo,
            // };
            //props.sendWebSocket('NOTICE_CLEAR', settingJson);
            try {
                wsClient.sendNoticeClear(noticeData.id, noticeData.floorId, noticeData.objectId, noticeNo);
            } catch (error) {
                console.error(error);            
            }
            setDispSetting(false);
        }

        setShowClearConfirmDialog(false);
    }

    function getIcon(str:string){

        if(str == ""){
            return "";
        }else if(str.startsWith("data:")){
            return str;
        }else{
            return iconArr[+str - 1];
        }
    }
    /*
    * 看板の表示
    */
    const draw = useMemo(() => {
        return (
            <Fragment>
                <div
                    key={`WebpagePopupSignBoard${id}`}
                    id={`WebpagePopupSignBoard${id}`}
                    className={classes.signboard}
                    style={{
                        position: "absolute",
                        left: offsetLeft,
                        top: offsetTop,
                        width: objectMaster.width, //+30
                        height: objectMaster.height, //+30
                        backgroundSize: "contain",
                        // backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                        backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                        backgroundRepeat: "no-repeat",
                        zIndex: ZIndex.floorObject,
                    }}
                >
                    <CustomizedTooltip 
                        title={
                            <Fragment>
                            </Fragment>
                        } 
                        TransitionComponent={Zoom}
                        placement={'top'}
                    >
                    <div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardImg}
                            style={{
                                top: objectMaster.textOffsetTop,
                                left: objectMaster.textOffsetLeft,
                                backgroundImage: `url(${getIcon(noticeData.iconData1)})`,
                            }}
                            onClick={() => openDetail(1, noticeData.type1, noticeData.name1, noticeData.url1, noticeData.imgData1,noticeData.download1, noticeData.floorId, noticeData.objectId)}
                        >
                            {noticeData.imgData1==='' || noticeData.iconData1 == '99' ? noticeData.name1 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardText}
                            style={{
                                top: objectMaster.textOffsetTop+35,
                                left: objectMaster.textOffsetLeft-8,
                                height: 20,
                            }}
                            onClick={() => openDetail(1, noticeData.type1, noticeData.name1, noticeData.url1, noticeData.imgData1,noticeData.download1, noticeData.floorId, noticeData.objectId)}
                        >
                            {noticeData.imgData1!=='' && noticeData.iconData1 != '99' ? noticeData.name1 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardImg}
                            style={{
                                top: objectMaster.textOffsetTop+5,
                                left: objectMaster.textOffsetLeft+82,
                                backgroundImage: `url(${getIcon(noticeData.iconData2)})`,
                            }}
                            onClick={() => openDetail(2, noticeData.type2, noticeData.name2, noticeData.url2, noticeData.imgData2,noticeData.download2, noticeData.floorId, noticeData.objectId)}
                            >
                            {noticeData.imgData2==='' || noticeData.iconData2 === '99' ? noticeData.name2 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardText}
                            style={{
                                top: objectMaster.textOffsetTop+40,
                                left: objectMaster.textOffsetLeft+73,
                                height: 20,
                            }}//完了
                            onClick={() => openDetail(2, noticeData.type2, noticeData.name2, noticeData.url2, noticeData.imgData2,noticeData.download2, noticeData.floorId, noticeData.objectId)}
                            >
                            {noticeData.imgData2!=='' && noticeData.iconData2 !== '99' ? noticeData.name2 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardImg}
                            style={{
                                top: objectMaster.textOffsetTop+11,
                                left: objectMaster.textOffsetLeft+159,
                                backgroundImage: `url(${getIcon(noticeData.iconData3)})`,
                            }}
                            onClick={() => openDetail(3, noticeData.type3, noticeData.name3, noticeData.url3, noticeData.imgData3,noticeData.download3, noticeData.floorId, noticeData.objectId)}
                            >
                            {noticeData.imgData3==='' || noticeData.iconData3 === '99' ? noticeData.name3 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardText}
                            style={{
                                top: objectMaster.textOffsetTop+46,
                                left: objectMaster.textOffsetLeft+152,
                                height: 20,
                            }}
                            onClick={() => openDetail(3, noticeData.type3, noticeData.name3, noticeData.url3, noticeData.imgData3,noticeData.download3, noticeData.floorId, noticeData.objectId)}
                            >
                            {noticeData.imgData3!=='' && noticeData.iconData3 !== '99' ? noticeData.name3 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardImg}
                            style={{
                                top: objectMaster.textOffsetTop+3,
                                left: objectMaster.textOffsetLeft+238,
                                backgroundImage: `url(${getIcon(noticeData.iconData4)})`,
                            }}
                            onClick={() => openDetail(4, noticeData.type4, noticeData.name4, noticeData.url4, noticeData.imgData4,noticeData.download4, noticeData.floorId, noticeData.objectId)}
                            >
                            {noticeData.imgData4==='' || noticeData.iconData4 === '99' ? noticeData.name4 : ''}
                        </div>
                        <div
                            key={`WebpagePopupSignBoard-img${id}`}
                            id={`WebpagePopupSignBoard-img${id}`}
                            className={classes.signboardText}
                            style={{
                                top: objectMaster.textOffsetTop+38,
                                left: objectMaster.textOffsetLeft+234,
                                height: 20,
                                textAlign:'start',
                            }}
                            onClick={() => openDetail(4, noticeData.type4, noticeData.name4, noticeData.url4, noticeData.imgData4,noticeData.download4, noticeData.floorId, noticeData.objectId)}
                            >
                            {noticeData.imgData4!=='' && noticeData.iconData4 != '99' ? noticeData.name4 : ''}
                        </div>
                    </div>
                    </CustomizedTooltip>
                    {drawSettingIcon(props.role)}
                    {drawUnreadedIcon(props.role)}
                </div>
            </Fragment>
            
        )
    }, [ props.floorObject, props.role, noticeData])

    return (
        <Fragment>
            {draw}
            {settingDialog}
            <BaseDialog open={isShowClearConfirmDialog}
                style={{ pointerEvents: 'auto' }}
                fullWidth={true}
                maxWidth={Utility.getType() === 2 ? "xs":"sm"}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}>
                <DialogTitle id="dialog-registUser" style={{padding: '10px 25px 10px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>確認</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    この掲示板をクリアします。
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <div>
                    <Button color="primary" onClick={() => { handleClearNotice(true) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>&nbsp;
                    <Button color="primary" onClick={() => { handleClearNotice(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
                </div>
                </DialogActions>
            </BaseDialog>
        </Fragment>
    )
}
import React, { forwardRef, ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios, { AxiosResponse } from 'axios';
import { Button, TextField, Typography } from '@material-ui/core';
// import { JsonFloor } from './FloorList';
import { JsonFloor } from '../admin/FloorList';
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    jsonFloor: JsonFloor,
    listUpdate: () => void,
}

// 公開したいメソッドの定義
export interface FloorMaintenanceHandler {
    open(): void;
}

/**
 * FAMcampus用に改造(FAMcampusでは階層名はいらない)
 * @param props 
 * @param ref 
 * @returns 
 */
const FloorMaintenanceComponent: ForwardRefRenderFunction<FloorMaintenanceHandler, Props> = (props, ref) => {
    const FLOOR_NAME_LENGTH: number = 20;
    const ERROR_FLOOR_NAME_REQUIRE: string = "フロア名は1文字以上で入力してください。";
    const ERROR_FLOOR_NAME_LENGTH: string = "フロア名は最大20文字で入力してください。";
    const ERROR_FLOOR_NAME_NO_CHANGE: string = "変更前と同じフロア名のため、更新されません。";
    const [open, setOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
    }))

    const handleFloorNameChange = () => {
        let floorName = (document.getElementById("floor_name") as HTMLInputElement).value;
        let tempErrors: string[] = [];
        if (floorName.trim().length > FLOOR_NAME_LENGTH) {
            tempErrors.push(ERROR_FLOOR_NAME_LENGTH);
        }else if(floorName.trim().length === 0){
            tempErrors.push(ERROR_FLOOR_NAME_REQUIRE);
        }else if(floorName.trim() === props.jsonFloor.floorName){
            tempErrors.push(ERROR_FLOOR_NAME_NO_CHANGE);
        }
        if (tempErrors.length > 0) {
            setErrorMessages(tempErrors);
            return;
        }
        setErrorMessages([]);
    }

    const handleUpdate = () => {
        let floorName = (document.getElementById("floor_name") as HTMLInputElement).value;

        if (errorMessages.length > 0) return;
        const tabId = sessionStorage.getItem("TABID") as string;
        
        httpClient.updateFloorName(tabId, props.jsonFloor.id, floorName)
            .then((response: string) => {
                console.log(response);
                props.listUpdate();
                handleClose();
            })
            .catch((err) => {
                handleClose();
            });
    }

    const handleClose = () => {
        setOpen(false);
        setErrorMessages([]);
    }

    const drawErrorMessages = useMemo(() => {
        if (errorMessages.length === 0) return;

        return (
            <Fragment>
                {errorMessages.map((e) => {
                    return (
                        <Typography variant='body1' color='error'>{e}</Typography>
                    )
                })}
            </Fragment>
        )
    }, [errorMessages])

    const draw = useMemo(() => {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth disableBackdropClick >
                <DialogTitle id="floor-manage-dialog-title" >フロア名編集</DialogTitle>
                <DialogContent>
                    {drawErrorMessages}
                </DialogContent>
                <DialogContent>
                    <div>
                        <TextField id="floor_name" 
                            variant='outlined' 
                            size='small' 
                            label='フロア名' 
                            defaultValue={props.jsonFloor.floorName} 
                            style={{ width: "300px" }} 
                            onChange={handleFloorNameChange}
                        />
                    </div>

                    <div>
                        <Typography variant='body2'>※フロア名は最大20文字で入力してください。</Typography>
                        <Typography variant='body2'>　例：「1F：集合学習フロア」など</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled = 
                        {
                            errorMessages.length > 0
                            // (document.getElementById("floor_name") as HTMLInputElement).value === "" || 
                            // (document.getElementById("floor_name") as HTMLInputElement).value === props.jsonFloor.floorName
                        } 
                        onClick={handleUpdate} color="primary"
                    >
                        OK
                    </Button>
                    <Button onClick={handleClose} color="primary" >
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }, [open, errorMessages, props.jsonFloor.floorName])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const FloorMaintenance = forwardRef(FloorMaintenanceComponent);
/**
 * avatarとwebRTC要素の表示サイズ計算用
 * 
 * 【DOM構造】
 * div #myUser or #otherUser        zIndex      
 *  ┣ div :when normal              zIndex      
 *  ┣ div :when waiting             zIndex + 1  
 *  ┣ div :animation                zIndex + 2  
 *  ┣ div :when meet (isLarge:true) zIndex + 3   
 *  ┗ div #webrtc-area-wrapper      zIndex + 4 正方形
 *     ┗ div #webrtc-area           zIndex + 5 マスク
 *        ┣ video                   zIndex + 6
 *        ┗ audio
 * 
 * ・isLargeがtrueのとき、アバターのサイズが
 *   AVATAR_WIDTH_GAIN, AVATAR_HEIGHT_GAIN 分大きくなる。
 * 
 * ・isLargeVideoがtrueのとき、video要素のサイズが
 *   LARGE_VIDEO_SIZE_GAIN 分大きくなる。
 * */

 // webrtc-area-wrapperに対するvideo要素の大きさの比率
export const INNER_RATIO = 0.92;

// isLarge時のアバター幅への加算値
export const AVATAR_WIDTH_GAIN = 10;
// isLarge時のアバター高さへの加算値
export const AVATAR_HEIGHT_GAIN = 2;

// video要素の表示調整用の加算値
export const VIDEO_ADDJUST_GAIN = 20;

// video要素のlarge表示時videoサイズへの加算値
export const LARGE_VIDEO_SIZE_GAIN = 100;
// video要素のlarge表示調整用の加算値
export const LARGE_VIDEO_ADDJUST_GAIN = 60;

/**
 * isLargeフラグによってavatarのpixel幅を算出する。
 *  ・isLargeはミート時のavatar imageがlargeになるフラグ
 * 
 * @param width avatar幅 
 * @param isLarge true: 通話時, false: 通常時
 */
export function calcAvatarWidth(width: number, isLarge: boolean) {
    return width + (isLarge ? AVATAR_WIDTH_GAIN : 0)
}

/**
 * isLargeフラグによってavatarのpixel高さを算出する。
 * 
 * @param height avatar高さ 
 * @param isLarge true: 通話時, false: 通常時
 */
export function calcAvatarHeight(height: number, isLarge: boolean) {
    return height + (isLarge ? AVATAR_HEIGHT_GAIN : 0);
}

/**
 * isLarge, isLargeVideoフラグによってWebRTCAreaWrapperの一辺のpixel幅を算出する。
 *  ・isLargeはミート時のavatar imageがlargeになるフラグ
 * 
 * @param width avatar幅 
 * @param isLarge true: 通話時, false: 通常時
 * @param isLargeVideo true: お立ち台上, false: お立ち台にいない
 */
export function calcWebRtcAreaWrapperSize(width: number, isLarge: boolean, isLargeVideo: boolean) {
    return width + (isLarge ? AVATAR_WIDTH_GAIN : 0) + (isLargeVideo ? LARGE_VIDEO_SIZE_GAIN : 0);
}

/**
 * isLargeVideoフラグによってWebRTC video要素の一辺のpixel幅を算出する。
 * 
 * @param width avatar幅 
 * @param isLargeVideo 
 */
export function calcWebRtcVideoSize(width: number, isLargeVideo: boolean) {
    return isLargeVideo
        ? width * INNER_RATIO + LARGE_VIDEO_ADDJUST_GAIN
        : width * INNER_RATIO + VIDEO_ADDJUST_GAIN;
}

/**
 * isLargeVideoフラグによってavatarの映像サイズを取得する。
 * 
 * @param isLargeVideo true: 映像拡大時, false: 通常時
 */
export function getAvatarVideoSize(isLargeVideo: boolean) {
    return isLargeVideo ? LARGE_VIDEO_SIZE_GAIN : 0;
}
import React, { forwardRef, ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Card, Box, Checkbox, FormControlLabel, Switch, TextField, Typography, Tooltip } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
// import axios, { AxiosResponse } from 'axios';
import { Backdrop, Button, CircularProgress, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import { FloorMaintenance, FloorMaintenanceHandler } from '../user/FloorMaintenance';//'./FloorMaintenance';
import { FloorSettingRestrict, FloorSettingRestrictHandler } from '../user/FloorSettingRestrict';
import { OkCancelDialog, OkCancelDialogHandler } from '../common/OkCancelDialog';
import { Utility } from '../common/Utility';
import { FloorEntryRestrict, FloorEntryRestrictHandler } from '../user/FloorEntryRestrict';
import JfsClient from '@fsi/jfs-sdk';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
        overflow: 'scroll',
        height: '100vh',
    },
    media: {
        height: 140,
    },
    dividerMargin: {
        marginBottom: '20px',
    },
    alert: {
        background: "#f44336",
        color: "#fff",
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHead: {
        backgroundColor: 'aliceblue',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginLeft: 0,
    },
    backdrop: {
        zIndex: 2000,
        color: '#fff',
    },
}));

interface Props {
}

// 公開したいメソッドの定義
export interface FloorListHandler {
    open(): void;

}

export class JsonFloor {
    id: number = 0;
    floorName: string = "";
    officeName: string = "";
    // フロアメンテナンスを管理する変数。FAMcampusではメンテナンスは関係ないのでコメントアウト
    // enabledLockFloor: boolean = false;
}

// function ChildTest(props: Props, ref: any) {
const FloorListComponent: ForwardRefRenderFunction<FloorListHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const tabId = sessionStorage.getItem('TABID') || '';

    const [open, setOpen] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // const [openBackdrop2, setOpenBackdrop2] = useState(false); //全フロアメンテナンスの際のバックドロップなのでFAMcampusでは必要なし
    // const [enabledFloorLock, setEnabledFloorLock] = useState(true); //テナントコントラクト単位のフロア入室制限なのでFAMcampusでは必要なし
    const [floorList, setFloorList] = useState([] as JsonFloor[]);
    const [selectedFloor, setSelectedFloor] = useState(new JsonFloor());
    const [releaseFloorId, setReleaseFloorId] = useState(0);

    const floorMaintenanceRef = useRef({} as FloorMaintenanceHandler);
    // const maintenanceAlertRef = useRef({} as OkCancelDialogHandler); //メンテナンスに関するダイアログなのでFAMcampusでは必要なし
    // const maintenanceAlertRef2 = useRef({} as OkCancelDialogHandler); //メンテナンスに関するダイアログなのでFAMcampuでは必要なし
    const floorEntryRestrictRef = useRef({} as FloorEntryRestrictHandler);
    const floorSettingRestrictRef = useRef({} as FloorSettingRestrictHandler);

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useEffect(() => {
        getFloorList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFloorList = () => {
        // プライバシールーム以外のフロアを取得
        httpClient.getGeneralFloorList(sessionStorage.getItem("TABID") as string)
            .then((response: JsonFloor[]) => {
                console.log(response);
                let temp = response; //.data as JsonFloor[];
                setFloorList(response);
            })
            .catch((err) => {
            });
    }

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
    }))

    const handleClose = () => {
        setOpen(false);
    }

    type Order = 'asc' | 'desc';

    interface EnhancedTableProps {
        classes: ReturnType<typeof useStyles>;
        numSelected: number;
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof JsonFloor) => void;
        onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
        order: Order;
        orderBy: string;
        rowCount: number;
    }

    interface HeadCell {
        disablePadding: boolean;
        // id: keyof Floor;
        id: keyof JsonFloor;
        label: string;
        numeric: boolean;
    }

    interface ResEnabledFloorLock {
        tenantId: number;
        enabledFloorLock: boolean;
    }

    const headCells: HeadCell[] = [
        // { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
        // { id: 'officeName', numeric: false, disablePadding: false, label: 'オフィス名' },
        { id: 'floorName', numeric: false, disablePadding: false, label: 'フロア名' },
        { id: 'floorName', numeric: false, disablePadding: false, label: '' },
    ];

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property: keyof JsonFloor) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead className={classes.tableHead}>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'center'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={false}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof JsonFloor>('id');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = floorList.map((n) => n.floorName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof JsonFloor) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        // 行全体のクリックイベントで編集画面に遷移しようとしたけど、
        // 編集ボタン押下時も発生して、2重イベントになったので封印
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, floorList.length - page * rowsPerPage);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**
     * メインテナンス開始・解除ボタンをクリックした際のアクション
     * FAMcampusではメインテナンスは必要ないので、コメントアウト
     */
    /*const handleClickMaintenance = (event: React.MouseEvent<unknown>, jsonFloor: JsonFloor) => {
        let filterFloors = floorList.filter(e => e.id === jsonFloor.id);
        setReleaseFloorId(-1);
        setSelectedFloor(filterFloors[0]);
        if(filterFloors[0].enabledLockFloor === true) {
            changeLockFloor(filterFloors[0].id);
        } else {
            maintenanceAlertRef.current.open();
        }
    };*/

    const handleClickEdit = (event: React.MouseEvent<unknown>, id: number) => {
        let filterFloors = floorList.filter(e => e.id === id);
        setReleaseFloorId(-1);
        setSelectedFloor(filterFloors[0]);
        floorMaintenanceRef.current.open();
    };

    /**
     * フロア管理者機能はFAMcampusでは必要ないので、コメントアウト
     */
    /*const handleClickManager = (event: React.MouseEvent<unknown>, id: number) => {
        let filterFloors = floorList.filter(e => e.id === id);
        setReleaseFloorId(-1);
        setSelectedFloor(filterFloors[0]);
        floorManagerRef.current.open();
    }*/

    const handleClickEntryRestrict = (event: React.MouseEvent<unknown>, id: number) => {
        let filterFloors = floorList.filter(e => e.id === id);
        setReleaseFloorId(-1);
        setSelectedFloor(filterFloors[0]);
        floorEntryRestrictRef.current.open();
    }

    // フロア内機能制限設定のダイアログオープン
    const handleClickFloorSettingRestrict = (event: React.MouseEvent<unknown>, id: number) => {
        let filterFloors = floorList.filter(e => e.id === id);
        setReleaseFloorId(-1);
        setSelectedFloor(filterFloors[0]);
        floorSettingRestrictRef.current.open();
    }

    /**
     * API Request
     */
    /*const getEnabledFloorLock = async (tabId: string): Promise<ResEnabledFloorLock> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResEnabledFloorLock> = await axios.get(
            //     '/api/v1/admin/tenant/enabledfloorlock',
            //     { params: { tabId } }
            // );
            const res: ResEnabledFloorLock = await httpClient.getEnabledFloorLock(tabId);

            return res;
        } catch (err) {
            throw err;
        }
    }*/

    
    /*const updateEnabledFloorLock = async (tabId: string, enabledFloorLock: boolean): Promise<ResEnabledFloorLock> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResEnabledFloorLock> = await axios.patch(
            //     '/api/v1/admin/tenant/enabledfloorlock',
            //     { tabId, enabledFloorLock }
            // );
            const res: ResEnabledFloorLock = await httpClient.updateEnabledFloorLock(tabId, enabledFloorLock);
            return res;
        } catch (err) {
            throw err;
        }
    }*/

    /**
     * useEffect
     */
    /*useEffect(() => {
        fetchEnabledFloorLock();
    }, [open]);*/


    /**
     * テナントコントラクト単位でフロア入室制限をする機能
     * FAMcampusの場合、フロア単位での制御のみ必要となるので、本機能はコメントアウト
     */
    /*const handleChangeEnabledFloorLock = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const res = await updateEnabledFloorLock(tabId, !enabledFloorLock);
            const flag = res.enabledFloorLock;
            setEnabledFloorLock(flag);
        } catch (err) {
            console.error(err);
        }
    }*/

    /**
     * テナントコントラクト単位のフロア入室制限のON/OFFを取得する機能
     * FAMcampusではこれが常にONであるべき
     * handle data
     */
    /*const fetchEnabledFloorLock = async () => {
        try {
            const res = await getEnabledFloorLock(tabId);
            const flag = res?.enabledFloorLock;
            setEnabledFloorLock(flag);
        } catch (err) {
            console.error(err);
        }
    }*/

    const drawList = useMemo(() => {

        return (
            <Fragment>
                <TableContainer style={{ height: `calc(97vh - 200px)`}}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={floorList.length}
                        />
                        <TableBody>
                            {stableSort(floorList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id.toString());
                                    // const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell align="left">{Utility.changeLongFloorName(row.floorName)}</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={(event) => handleClickEdit(event, row.id)} variant='contained' size="small" color="primary" style={{ marginRight: 10 }}>フロア名編集</Button>
                                                <Button onClick={(event) => handleClickEntryRestrict(event, row.id)} variant='contained' size="small" color="primary" style={{ marginRight: 10 }}>入室制限設定</Button>
                                                <Button onClick={(event)=> handleClickFloorSettingRestrict(event, row.id)} variant='contained' size="small" color="primary" style={{ marginRight: 10 }}>フロア内機能制限設定</Button> 
                                                {/*<Button onClick={(event) => handleClickMaintenance(event, row)} variant='contained' size="small"
                                                    color={row.enabledLockFloor ? "secondary" : "primary"}>{row.enabledLockFloor ? "メンテナンス解除" : "メンテナンス開始"}</Button>*/}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (33) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={floorList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={"1ページの表示フロア数"}
                />
            </Fragment>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorList, rowsPerPage, page, order, orderBy, openBackdrop, /*openBackdrop2*/])

    /**
     * メンテナンス確認ダイアログでOKを押したときのアクション
     * FAMcampusではメインテナンスは必要ないので、コメントアウト
     */
    /*const handleOkMaintenance = () => {
        changeLockFloor(selectedFloor.id);
    }*/

    /**
     * 対象のフロアのメインテナンス状態を変更するAPI(第3パラメーターがundefinedの場合、メインテナンス状態反転)
     * FAMcampusではメインテナンスは必要ないので、コメントアウト
     */
    /*const changeLockFloor = (floorId: number) => {
        setOpenBackdrop(true);

        httpClient.updateLockFloor(sessionStorage.getItem("TABID") as string, floorId, undefined)
            .then((response: string) => {
                console.log(response);
                getFloorList();
                setOpenBackdrop(false);
            })
            .catch(() => {
                console.log("error.");
                setOpenBackdrop(false);
            });
    }*/

    /**
     * 全フロアをメンテナンスする機能
     * FAMcampusではメンテナンスを必要としないため、コメントアウト
     * (開放時間管理がメンテナンスの代わりとなる)
     */
    /*const handleLockAllFloor = () => {
        maintenanceAlertRef2.current.open();
    }*/

    /**
     * 全フロアをメンテナンス解除する機能
     * FAMcampusではメンテナンスを必要としないため、コメントアウト
     * (開放時間管理がメンテナンスの代わりとなる)
     */
    /*const handleReleaseLockAllFloor = () => {
        setReleaseFloorId(0);
        setOpenBackdrop(true);
        unlockFloor(0, false);
    }*/

    /*const unlockFloor = (floorIndex: number, enabledLockFloor: boolean) => {

        const tabId = sessionStorage.getItem("TABID") as string;

        httpClient.updateLockFloor(tabId, floorList[floorIndex].id, enabledLockFloor)
            .then((response: string) => {
                console.log(response);
                if (floorList.length <= (floorIndex + 1)) {
                    getFloorList();
                    setOpenBackdrop(false);
                } else {
                    unlockFloor(floorIndex + 1, enabledLockFloor);
                    setReleaseFloorId(floorIndex + 1);
                }
            })
            .catch(() => {
                console.log("error.");
                setOpenBackdrop(false);
            });
    }*/


    /**
     * 全フロアをメンテナンスする確認ダイアログのOKアクション
     * FAMcampusではメインテナンスは必要ないので、コメントアウト
     */
    /*const handleOkAllMaintenance = () => {
        setOpenBackdrop2(true);
    }*/

    /**
     * メンテナンスに関するダイアログのアクションなのでFAMcampusには必要なし
     */
    /*const handleCloseDialog = () => {
    }*/

    const handleEditFloorInfo = () => {
        getFloorList();
    }

    /**
     * おそらく全フロアメンテナンス用の関数(httpClient.updateLockFloorはメンテナンスの状態を変更するSDK)
     * FAMcampusではメインテナンスは必要ないので、コメントアウト 
     */
    /*const lockFloor = (floorIndex: number, enabledLockFloor: boolean) => {

        httpClient.updateLockFloor(
            sessionStorage.getItem("TABID") as string,
            floorList[floorIndex].id,
            enabledLockFloor
        ).then((response: string) => {
            console.log(response);
            if (floorList.length <= (floorIndex + 1)) {
                getFloorList();
                setOpenBackdrop2(false);
            } else {
                lockFloor(floorIndex + 1, enabledLockFloor);
                setReleaseFloorId(floorIndex + 1);
            }
        })
        .catch(() => {
            console.log("error.");
            setOpenBackdrop2(false);
        });
    }*/

    /*useEffect(() => {
        if (openBackdrop2 === false) {
            if (floorList.length !== 0) {
                getFloorList();
            }
            return;
        }
        setReleaseFloorId(0);
        lockFloor(0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openBackdrop2])*/

    const showBackdrop = useMemo(() => {
        return (
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <Grid container>
                    <Grid item xs={12}>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item xs={12}>
                        {releaseFloorId === -1 ? "" : (releaseFloorId + 1) + " / " + floorList.length}
                    </Grid>
                </Grid>
            </Backdrop>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openBackdrop, releaseFloorId])

    /**
     * 全フロアをメンテナンスした際のバックドロップ
     * FAMcampusではメインテナンスは必要ないので、コメントアウト
     */
    /*const showBackdrop2 = useMemo(() => {
        return (
            <Backdrop className={classes.backdrop} open={openBackdrop2}>
                <Grid container>
                    <Grid item xs={12}>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item xs={12}>
                        {releaseFloorId === -1 ? "" : (releaseFloorId + 1) + " / " + floorList.length}
                    </Grid>
                </Grid>
            </Backdrop>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openBackdrop2, releaseFloorId])*/

    const draw = useMemo(()=>{
        return (
            <Fragment>
                <div style={{marginTop: -20}}>
                    <Card className={classes.root} style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10}}>
                        <CardHeader
                        title='フロア基本設定'
                        titleTypographyProps={{
                        align: 'center',
                        variant: 'h6',
                        }}
                        />
                    </Card>
                {/*<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth disableBackdropClick>
                    <DialogTitle id="floor-list-dialog-title">フロア管理</DialogTitle>
                    <DialogContent>
                        <div style ={{textAlign:'left'}}>
                        <span style={{fontSize: 15}}>フロア入室制限の切り替えを有効にする</span>*/}
                            {/* <Tooltip title='フロア入室制限機能ON・OFF' > */}
                            {/*<Switch
                                checked={enabledFloorLock}
                                onChange={handleChangeEnabledFloorLock}
                                name="checkedFloorLockSetting"
                                color="primary"
                            />
                            {/* </Tooltip> */}
                        {/*</div>
                        <div style={{ textAlign: "right" }}>
                            <span style={{marginRight: 16, color: '#0000008A'}}>フロア入室制限の切り替えを無効にすると、入室制限の設定ができなくなります。</span> 
                            <Button onClick={handleLockAllFloor} variant='contained' color='primary' size='small' style={{marginRight: 10}}>全フロアメンテナンス開始</Button>
                            <Button onClick={handleReleaseLockAllFloor} variant='contained' color='primary' size='small'>全フロアメンテナンス解除</Button>
                        </div>
                    </DialogContent>*/}
                    {drawList}
                    {/*<DialogActions>
                        <Button onClick={handleClose} color="primary">
                            閉じる
                        </Button>
                </DialogActions>*/}
                {/*</Dialog>*/}
                </div>       
            </Fragment>
        );
    },[open, /*enabledFloorLock,*/ floorList, rowsPerPage, page, order, orderBy, openBackdrop, /*openBackdrop2*/]);

    return (
        <Fragment>
            {draw}
            <FloorEntryRestrict ref={floorEntryRestrictRef} jsonFloor={selectedFloor}/>
            <FloorMaintenance ref={floorMaintenanceRef} jsonFloor={selectedFloor} listUpdate={handleEditFloorInfo} />
            <FloorSettingRestrict ref={floorSettingRestrictRef} jsonFloor={selectedFloor} />
            {/*<OkCancelDialog ref={maintenanceAlertRef} title='メンテナンス確認' message='フロアから全ユーザーを退勤させて、入室禁止にします。
                #SPLIT#※オフラインで残ってしまったユーザーも退勤させることができます。
                #SPLIT#※管理者はフロアに入室できます。'
                okButtonName='OK' closeButtonName='キャンセル' okCallback={handleOkMaintenance} closeCallback={handleCloseDialog} enableCloseButton={true} />*/}
            {/*<OkCancelDialog ref={maintenanceAlertRef2} title='メンテナンス確認' message='全フロアから全ユーザーを退勤させて、入室禁止にします。
                #SPLIT#※オフラインで残ってしまったユーザーも退勤させることができます。
                #SPLIT#※管理者はフロアに入室できます。'
                okButtonName='OK' closeButtonName='キャンセル' okCallback={handleOkAllMaintenance} closeCallback={handleCloseDialog} enableCloseButton={true} />*/}
            {showBackdrop}
            {/*showBackdrop2*/}
        </Fragment>
    );
}

export const FloorList = forwardRef(FloorListComponent);
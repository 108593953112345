import { useState, useEffect, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
import Grid from '@material-ui/core/Grid';
import JfsClient, {  } from '@fsi/jfs-sdk';

class ResZoomInfo {
    apiKey: string = '';
    meetingNumber: number = 0;
    meetingPassword: string = '';
    hostURL: string = '';
    zoomViewerURL: string = '';
}

class ZoomInfo extends ResZoomInfo {
    userName: string = '';
    userEmail: string = '';
    signature: string = '';
}

class SendData {
    constructor(
        public type: string = '',
        public body: { [key: string]: any } = {}
    ) { }
}

export interface Props {
    id: number;
    displayName: string;
    width: number;
    height: number;
    left: number;
    top: number;
    zIndex: number;
    sendZoomJoin: (id: number, zoomMeetingId: number) => void;
}

export interface ZoomViewHandler {
    getZoomInfoId: () => number | null;
    setZoomInfoId: (zoomInfoId: number | null) => void;
    reload: () => void;
    mute: (isMute: boolean) => void;
    disconnect: () => void;
}

const MESSAGE_TYPE = {
    START_VIEWER: 'START_VIEWER',
    END_VIEWER: 'END_VIEWER',
    RESTART_VIEWER: 'RESTART_VIEWER',
    MUTE: 'MUTE',
    ERROR: 'ERROR',
} as const;

/**
 * API Request
 */
// const getZoomInfo = async (tabId: string, zoomInfoId: number): Promise<AxiosResponse<ResZoomInfo>> => {
//     try {
//         const res: AxiosResponse<ResZoomInfo> = await axios.post(
//             '/api/user/zoom/info',
//             { tabId, zoomInfoId }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }

// }
// const getSignature = async (tabId: string, zoomInfoId: number): Promise<AxiosResponse<string>> => {
//     try {
//         const res: AxiosResponse<string> = await axios.post(
//             '/api/user/zoom/signature',
//             { tabId, zoomInfoId }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }

// }

const ZoomViewComponent: ForwardRefRenderFunction<ZoomViewHandler, Props> = (props, ref) => {
    const tabId = sessionStorage.getItem('TABID') || '';
    const iframeRef = useRef({} as HTMLIFrameElement);
    const retryTime = 3500; // msec
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [zoomInfoId, setZoomInfoId] = useState<number | null>(null);
    const [zoomInfo, setZoomInfo] = useState<ZoomInfo>(new ZoomInfo());
    const { id, displayName, top, left, width, height, zIndex, sendZoomJoin } = props;
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        getZoomInfoId() {
            return zoomInfoId;
        },
        setZoomInfoId(id: number | null) {
            setZoomInfoId(id);
        },
        reload() {
            setIframeLoaded(false);
            iframeRef.current?.contentDocument?.location.reload();
        },
        mute(isMute: boolean) {
            muteZoom(isMute);
        },
        disconnect() {
            endZoom();
        },
    }));

    /**
     * API Request
     */
    const getZoomInfo = async (tabId: string, zoomInfoId: number): Promise<ResZoomInfo> => {
        try {
            // const ress: AxiosResponse<ResZoomInfo> = await axios.post(
            //     '/api/user/zoom/info',
            //     { tabId, zoomInfoId }
            // );

            const res: ResZoomInfo = await httpClient.getZoomInfo(tabId, zoomInfoId);
            return res;
        } catch (err) {
            throw err;
        }

    }
    const getSignature = async (tabId: string, zoomInfoId: number): Promise<string> => {
        try {
            // const res: AxiosResponse<string> = await axios.post(
            //     '/api/user/zoom/signature',
            //     { tabId, zoomInfoId }
            // );

            const res: string = await httpClient.getZoomSignature(tabId, zoomInfoId);
            return res;
        } catch (err) {
            throw err;
        }

    }

    /**
     * methods
     */
    const sendPostMessage = (data: SendData, targetURL: string) => {
        console.log('【ZoomView.tsx】sendPostMessage', data);
        // iframeのwindowオブジェクトを取得
        const ifrm = document.getElementById('zoomViewer') as HTMLIFrameElement;
        // iframeへdataを送信
        ifrm?.contentWindow?.postMessage(data, targetURL);
    }

    const fetchZoomInfo = async (zoomInfoId: number) => {
        try {
            const res1 = await getZoomInfo(tabId, zoomInfoId);
            const res2 = await getSignature(tabId, zoomInfoId);
            const info = {
                ...res1,
                userName: displayName,
                userEmail: '',
                signature: res2
            };
            setZoomInfo(info);
            return info;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    const createHandleMessage = async (zoomInfoId: number): Promise<{ handler: (event: any) => void, info: ZoomInfo }> => {
        try {
            const info = await fetchZoomInfo(zoomInfoId);
            setZoomInfo(info);

            const handler = (event: any) => {
                const { type, body } = event.data;
                const status = body?.status;
                // viewer からの通信でない場合、処理を行なわない
                if (body?.url !== info.zoomViewerURL) return;

                if (status === 'OK') {
                    if (type === MESSAGE_TYPE.START_VIEWER) {
                        console.log('start zoom is success.');
                        sendZoomJoin(id, info.meetingNumber);
                        // fail safe: Zoom開始時、必ずmuteにする
                        muteZoom(true);
                    } else if (type === MESSAGE_TYPE.END_VIEWER) {
                        console.log('end zoom is success.');
                        sendZoomJoin(id, 0);
                    } else if (type === MESSAGE_TYPE.MUTE) {
                        console.log(`zoom mute:${body.mute} is success.`);
                    } else if (type === MESSAGE_TYPE.RESTART_VIEWER) {
                        sendZoomJoin(id, 0);
                        setTimeout(() => {
                            setIframeLoaded(false);
                            sendPostMessage(new SendData(MESSAGE_TYPE.START_VIEWER, info), info.zoomViewerURL);
                        }, retryTime);
                    }
                } else if (status === 'NG') {
                    console.error(body.error);
                } else {
                    console.error(new Error('PostMessage is failed.'));
                }
            }
            return { handler, info };
        } catch (err) {
            throw err;
        }
    }

    const startZoom = async (zoomInfoId: number) => {
        try {
            const { handler, info } = await createHandleMessage(zoomInfoId);
            window.addEventListener('message', handler, false);
            window.addEventListener('unload', () => window.removeEventListener('message', handler));

            // iframeとの接続が確認出来たら、zoom start
            const { zoomViewerURL } = info;
            sendPostMessage(new SendData(MESSAGE_TYPE.START_VIEWER, info), zoomViewerURL);
        } catch (err) {
            throw err;
        }
    }

    const endZoom = () => {
        const { hostURL, zoomViewerURL } = zoomInfo;
        if (hostURL && zoomViewerURL)
            sendPostMessage(new SendData(MESSAGE_TYPE.END_VIEWER, { hostURL, zoomViewerURL }), zoomViewerURL);
    }

    const muteZoom = (mute: boolean) => {
        const { hostURL, zoomViewerURL } = zoomInfo;
        if (hostURL && zoomViewerURL)
            sendPostMessage(new SendData(MESSAGE_TYPE.MUTE, { hostURL, zoomViewerURL, mute }), zoomViewerURL);
    }

    /**
     * useEffects
     */
    useEffect(() => {
        if (iframeLoaded && zoomInfoId) {
            try {
                startZoom(zoomInfoId);
            } catch (err) {
                console.error(err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iframeLoaded, zoomInfoId]);

    const component = useMemo(() => {
        const scale = 0.5;
        return (
            <Grid
                container
                justify='center'
                alignItems='center'
                style={{
                    position: 'absolute',
                    left,
                    top,
                    width,
                    height,
                    zIndex,
                    background: '#000',
                }}
            >
                {zoomInfoId ?
                    <iframe
                        ref={iframeRef}
                        id='zoomViewer'
                        title='Zoom Viewer'
                        src='/zoom_viewer/index.html'
                        allow='accelerometer; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        onLoad={() => setIframeLoaded(true)}
                        style={{
                            position: 'absolute',
                            width: width / scale,
                            height: height / scale,
                            transform: `scale(${scale}, ${scale})`,
                            background: '#000',
                        }}
                        frameBorder='0'
                    /> :
                    <div style={{ color: "white" }}>プレゼンテーション情報が設定されていません。</div>}
            </Grid>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iframeLoaded, zoomInfoId]);

    return component;
}

export const ZoomView = forwardRef(ZoomViewComponent);
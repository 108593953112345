
import { FloorWebSocket, JsonAction } from '../websocket/WebSocketFloor';
import { OutputLog } from './JsonClass';
import JfsClient, { LogLevelType, WsClient } from '@fsi/jfs-sdk';

export const LOG_LEVEL = {
    none: 0,
    error: 1,
    warn: 2,
    info: 3,
} as const;

export class Logger {
    private static instance: Logger;
    // private webSocket: FloorWebSocket | null = null;
    private webSocket: WsClient | null = null;
    private logLevel: number = 0;

    private constructor() {
        // do something construct...
    }
    
    static getInstance() {
        if (!Logger.instance) {
            Logger.instance = new Logger();
        }
        return Logger.instance;
    }

    public init(webSocket: WsClient, logLevel: number) {
        this.webSocket = webSocket;
        this.logLevel = logLevel;
    }

    public info(message: string) {
        if(this.logLevel >= 3) {
            console.log("info : " + message);
            this.sendLog(3,message);
        }
    }

    public warn(message: string) {
        if(this.logLevel >= 2) {
            console.log("warn : " + message);
            this.sendLog(2,message);
        }
    }

    public error(message: string) {
        if(this.logLevel >= 1) {
            console.log("error : " + message);
            this.sendLog(3,message);
        }
    }

    /**
     * ログをサーバーに送信
     * @param level 
     * @param message 
     */
    private sendLog(level: number, message: string) {
        let jsonAction: JsonAction = new JsonAction();
        jsonAction.action = "LOG";
        let outputLog: OutputLog = new OutputLog();
        outputLog.level = level;
        outputLog.message = message;
        jsonAction.object = outputLog;
        let json = JSON.stringify(jsonAction);
        // this.Logger?.send(json);
        const jfsClient = JfsClient.getInstance();
        if(jfsClient) {
            const wsClient = jfsClient.wsClient;
            if(!wsClient) {
                console.log("==== this.wsClient undefined ====");
            }
            wsClient?.logOutput(message, true, level as LogLevelType);
        } else {
            console.log("==== jfsClient undefined ====");
        }
    }
}
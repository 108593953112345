import React, { useReducer, MouseEventHandler, Fragment, } from 'react';
import { createStyles, StyleRules, Theme, withStyles, WithStyles, Button, Snackbar, SnackbarContent, Grid } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import { Redirect } from 'react-router-dom'
import { FloorWebSocket, JsonAction } from '../websocket/WebSocketFloor';
import { RouteComponentProps } from 'react-router-dom';
// import { PrivacyState, ChatGroupData, FloorData, FloorObject, MoveUserData, Seat, SendAction, User, TatehudaText, AvatarCommand, SaveData, HitBusyUser, MicData, LoginLogoutAction, SendMoveUserData, WebRtcInOutInfo, AvatarMenuData, WebRtcLimitUser, MeetLimitOver, AvatarData, EnableWebrtcRoomCommand, ReadMemoInfo, MoveFloorData, JoinRoomStatusData, ServiceInfoData, ReleaseFloorRequest, DevErrDisp, UpdateZoomInfo, AdminMessage, UpdateZoomSpeakable, EditorSnackBar, InternalNotice, NewVisitor, LeaveRoom, PrivacyRoomPermission, Tatehuda2Text, TatehudaTextText1, MyRoomFloorData, MyRoomFloorObject, ChangeMyRoomUser, UpdateMyRoom, LoginoutNoticeData, PrivacyRoomTimeOut, EnterPrivacyRoom, ExtendPrivacyRoomPermission } from '../common/JsonClass';
import { PrivacyState, ChatGroupData, SendAction, SaveData, SendMoveUserData, WebRtcInOutInfo, WebRtcLimitUser, AvatarData, EnableWebrtcRoomCommand, MoveFloorData, JoinRoomStatusData, ServiceInfoData, ReleaseFloorRequest, UpdateZoomInfo, AdminMessage, UpdateZoomSpeakable, EditorSnackBar, InternalNotice, NewVisitor, LeaveRoom, PrivacyRoomPermission, ChangeMyRoomUser, LoginoutNoticeData, PrivacyRoomTimeOut, EnterPrivacyRoom, ExtendPrivacyRoomPermission, MeetingRoomLock, GeneralMessageData, CheckMySelfStudyPlan } from '../common/JsonClass';
import { MyUser } from './MyUser';
import { OtherUsers } from './OtherUsers';
import { toStrUserState, UserState } from './BaseUser';
import { FloorMenu } from './FloorMenu';
import { AvatarEffectType } from './effect/Index';
import AudioPlayer, { AudioId } from './AudioPlayer';
import WebrtcService from '../webrtc/WebrtcService';

import { WithSnackbarProps, withSnackbar } from 'notistack';
import { SideMenu } from "./SideMenu";
import { JumpToMoveDialogRef } from "./JumpToMoveDialog";
import { TutorialDialogRef } from './TutorialDialog';
import { FloorClosedDialogRef } from './FloorClosedDialog';
import { ShareScreenRef } from './ShareScreen';
import { DraggableWebpageRef } from './DraggableWebpage';

import MeetingRoom from "./MeetingRoom";
import MeetingRoomNoText from "./MeetingRoomNoText";
import VideoConferenceRoom from "./VideoConferenceRoom";
import MeetingRoomWithTextInfo from "./MeetingRoomWithTextInfo";
import { PrivacyRoom } from "./PrivacyRoom";
import Tatehuda from "./Tatehuda";
import StaticKanban from "./StaticKanban";
import DynamicKanban from "./DynamicKanban";
import WhiteBoard from "./WhiteBoard";
import WebpagePopupSignboard from "./WebpagePopupSignboard";
import EditTextDialog from './EditTextDialog';
import Entrance from './Entrance';
import SelectedFloorObject from './SelectedFloorObject';
import{ NewFloorObjectWindow } from './NewFloorObjectWindow';
import{ FloorEditor } from './FloorEditor';
import AdminMessageBoard from './AdminMessageBoard';

import ZIndex from "../common/ZIndex";
import { DisconnectDialog } from './dialog/DisconnectDialog';
import { getAvatarVideoSize } from "../common/AvatarSize";
//import { ActionYoutubeSearchedFor } from 'material-ui/svg-icons';
import { CheckLoginDialog } from './dialog/CheckLoginDialog';
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import { ScreenShareMode } from '../webrtc/WebrtcComponent';
import TestDebugMenu from './TestDebugMenu';
import StaticKanbanNoBorder from './StaticKanbanNoBorder';
import StaticKanbanCenterAligned from './StaticKanbanCenterAligned';
import DynamicKanbanNoBorderRole from './DynamicKanbanNoBorderRole';
import DynamicKanbanOneLineRoleMaxLength from './DynamicKanbanOneLineRoleMaxLength';

import { Logger } from '../common/Logger';
import { ChatComponent, Chat } from './Chat';
import { Memo as MemoComponent} from './Memo';
// import { Memo as IMemo, SearchedUser, chkJsonFormat, MemoGroup } from '../common/JsonClass';
import { chkJsonFormat, MemoGroup } from '../common/JsonClass';
import { MemoGroupList } from './MemoGroupList';
import { UserSearch } from './UserSearch';
import { InformationDialogRef } from './InformationDialog'
import { ReleaseNoteDialogRef } from './ReleaseNoteDialog'  // リリースノート対応
import { InternalNoticeDialogRef } from './InternalNotice';
import { PrivacyRoomPermissionDialogRef } from './PrivacyRoomPermissionDialog';
import { GetAttendanceDialogRef } from './GetAttendanceDialog'
import { MyRoom2 } from './MyRoom2';
import { Utility } from '../common/Utility';
//import NoSleep from 'nosleep.js'  // iPad対応-3 NoSleepWrapper で制御する
import {NoSleepWrapper} from './NoSleepWrapper';    // iPad対応-3
import MoveFloorDialog from './MoveFloorDialog';
import YouTube, { Props as YouTubeProps } from './YouTube';
import OtherWebsite, { Props as OtherWebsiteProps } from "./OtherWebsite";
import { printable } from '../log/LogSetting'
import { OkOnlyDialog } from './dialog/OkOnlyDialog';
import { appFrontVersion } from '../common/Version';
import TestGridLine from './TestGridLine';
import { ZoomView } from './ZoomView';
import { HelloFrame } from './HelloFrame';
import { UsersControl } from './UsersControl';
import { FloorUsersAmount } from './FloorUsersAmount';
import { FlexibleNamePlate } from '../common/JsonClass';

import { MyRoomMessage } from './MyRoomMessage';
import { MyRoomUserInfoCard } from './MyRoomUserInfoCard';
import NoticeBoard from "./NoticeBoard";
import { GroupChat as GroupChatComponent} from './GroupChat';
import { ForceExitAlertDialogRef } from './ForceExitAlertDialog'
import {MyRoomMemoGroupCard} from './MyRoomMemoGroupCard'
import {MyRoomChatGroupCard} from './MyRoomChatGroupCard'
import BookShelf from './BookShelf';
import CountdownBoard from './CountdownBoard';
import MeetingRoomWithBanner from './MeetingRoomWithBanner';
import Banner from './Banner';
import { OriginalCharacterObject } from './CharacterObject';
import { SelfStudyStartDialogRef } from './SelfStudyStartDialog';
import { SelfStudyChangeDialogRef } from './SelfStudyChangeDialog';
import { SelfStudyFinishDialogRef } from './SelfStudyFinishDialog';
import { SelfStudyCompleteDialogRef } from './SelfStudyCompleteDialog';
import { TenantLauncher } from './TenantLauncher';
import { GeneralMessage } from './GeneralMessage';
import { OkCancelDialog } from '../common/OkCancelDialog';
import { usermanualURL } from '../common/DocumentURL';

import JfsClient, 
{ 
    FloorData,
    AvatarMenuData, 
    FloorObject, 
    Seat, 
    MoveUserData, 
    User, 
    MicData,
    LoginLogoutAction,
    CallStartingSetting,
    TatehudaText,
    Tatehuda2Text,
    TatehudaTextText1,
    AvatarCommand,
    HitBusyUser,
    WebRTCLimitUser,
    DevErrDisp,
    MeetLimitOver,
    TalkRequest,
    Memo as IMemo,
    ReadMemoInfo,
    MyRoomFloorData,
    MyRoomFloorObjectData as MyRoomFloorObject,
    UpdateMyRoom,
    SelectedDevice,
    ScreenShareMode as JfsScreenShareMode,
    SearchedUser,
    JfsError,
    UsedDevice,
    webRTCStartEndMessage,
    ChangeFloorNameData,    // v1.8_needCheck
    RoomText,
    StudyObjectSetting,
    StudyPlanDetail,
} from '@fsi/jfs-sdk';
import DorakidsNoticeBoard from './DorakidsNoticeBorad';
import StudySeat from './StudySeat';

if(printable == 0){
  console.log = function(){}
  console.warn = function(){}
  console.error = function(){}
}


const styles = (theme: Theme): StyleRules => createStyles({
    root: {
        display: "flex",
    }
});

const jfsClient = JfsClient.getInstance();
const { httpClient, JFS_ERRORS  } = jfsClient;
interface Props extends WithStyles<typeof styles>, RouteComponentProps, WithSnackbarProps {
}

interface PointerPosition {
    top: number;  // floorWrapperのscrollTop
    left: number; // floorWrapperのscrollLeft
    x: number;
    y: number;
}
interface State {
    floorData: FloorData,
    floorObjectList: FloorObject[],
    floorSeatList: Seat[],
    avatarMenuDataList: AvatarMenuData[],
    /** 自分のアバター */
    // myUser: User,
    // /** 他の人のアバター */
    // users: User[],
    /** WebRTC の通話状態 */
    // webRtcCall: boolean,
    /** 席リスト */
    signout: boolean,
    micVolumeInterval: number,
    openMicVolumeInterval: boolean,
    visibleScreenShareButton: boolean,
    visibleWebRtcBroadCastButton: boolean,
    visibleWebRtcBroadCastStopButton: boolean,
    // SessionData 一括更新用ダイアログの表示フラグ (試験用)
    openUpdateSessionData: boolean,
    updateSessionDataType: number,
    fitScale: number,
    myCommuting: boolean,
    openMoveFloor: boolean,
    moveFloor: MoveFloorData,
    youtubeUpdate: boolean,
    openInlineFrame: boolean,
    inlineFrameUrl: string,
    isWhiteBoard: boolean,
    floorEditMode: boolean,
    selectedFloorObj: FloorObject,//選択されたフロアオブジェクト
    isFloorObjSelected: boolean,//現在なにかフロアオブジェクトが選択されているか
    isFloorObjDragging: boolean,//フロアオブジェクトをドラッグ中か
    gridMode: boolean,//使ってない
    gridRange: number, //グリッド移動幅
    isDrawNewFloorObjWindw: boolean,//使ってない
    zIndexHelloInlineFrame: number,
    zIndexScreenShare: number,
    zIndexOther: number,

    myRoomFloorData: MyRoomFloorData,
    myRoomUpdate: boolean,
    isShowForceExitMsgDialog: boolean,
    enabledToolLauncher: boolean,
    showToolLauncher: boolean,
    updateStudyObjectSetting: StudyObjectSetting,
    hitStudyObjectSetting: StudyObjectSetting,
}

interface privacyRoomInfo {
    id: number;
    subId: string;
    preFloorId: number;
    preFloorName: string;
    floorId: number;
    stayPrivacyroom: boolean;
    waitForPermit: boolean;
    targetFloorId: number;
    targetUserId: number;
}

class FloorLocationState {
    path: string = "";
    id: number = 0;
}

class NotifyWindowSetting {
    elementId: string = "notifyWindow";
    width: number = 450;
    height: number = 50;
    top: number = 0;
    left: number = 0;
    backgroundColor: string = "#EEEEEE";
}

export class Floor extends React.Component<Props, State> {
    /** 前の画面から渡されたサーバ接続先のパス */
    private websocketPath = (this.props.location.state as FloorLocationState).path;
    /** 前の画面から渡されたフロアID */
    private floorId: number = (this.props.location.state as FloorLocationState).id;
    /** WebSocketインスタンス */
    // private websocket = new FloorWebSocket();

    /** paas 接続先websocket number */
    private websocketNumber: number = 1; 

    /** OhterUsersコンポーネントの参照 */
    private otherUsersRef: any;

    /** 自分のアバターをドラッグ中かどうか */
    private isDragMe = false;
    private isDraggingMe = false;
    /** フロアをドラッグ中かどうか */
    private isDragFloor = false;
    private isDraggingFloor = false;
    /** ping用のタイマーハンドル */
    private handlePingTimer: any = null;
    /** pingの返信pongを受け取るまでのタイマーハンドル */
    private handlePongTimer: any = null;
    private pongTime: number = 0;
    /** テストナビのフロアのデバッグメニューを描画するか */
    private isDrawTestNav: boolean = false;
    /** avartarColor設定のリスト */
    private avatarDatas: AvatarData[] = [];

    /** Flexible設定のリスト */
    private namePlateColors: FlexibleNamePlate[] = [];

    //floorobjectドラッグ中かどうか
    private isDraggingFloorObject = false;

    private floorWrapperRef: any;
    private floorRef: any;
    // Window内のマウス座標
    private pointerPosition: PointerPosition = { top: 0, left: 0, x: 0, y: 0 };
    // ジャンプ移動させるマウス座標
    private jumpToPosition: PointerPosition = { top: 0, left: 0, x: 0, y: 0 };
    private jumpToDialogRef: any;

    private myUserRef: any;
    private tutorialDialogRef: any;
    private floorClosedDialogRef: any;
    private InformationDialogRef: any;
    private ReleaseNoteDialogRef: any;  // リリースノート対応
    private InternalNoticeDialogRef: any;// 連絡
    private PrivacyRoomPermissionDialogRef: any; // プライバシールーム許可申請
    private SelfStudyStartDialogRef: any; // 自習開始ダイアログの開閉用
    private SelfStudyChangeDialogRef: any; // 自習計画変更ダイアログの開閉用
    private SelfStudyFinishDialogRef: any; // 自習振り返りダイアログの開閉用
    private SelfStudyCompleteDialogRef: any; // 自習完了ダイアログ
    private SelfStudyTimerRef: any; // 自習用タイマー
    private GetAttendanceDialogRef: any; // 入退データ
    private myRoomRef: any;
    private myRoom2Ref: any;
    private myRoomMessageRef: any;
    private myRoomUserInfoCardRef: any;
    private ForceExitAlertDialogRef: any;
    private myRoomMemoGroupCardRef: any;
    private myRoomChatGroupCardRef: any;
    private tenantLauncherRef: any;
    private characterObjectRef: any;
    private GeneralMessageRef: any;

    /** WebRTCの接続処理を開始しているかどうか */
    private isWebRtcConnecting = false;

    // アプリ内通知ウィンドウ
    private notifyWindow: Window | null = null;
    private notifyWindowSetting = new NotifyWindowSetting();
    private moreNoteWindow: Window | null = null;
    
    // ビデオ通話前の確認ダイアログ用
    private startingConfirmUser: User = new User();
    // #809 ドラッグ中に話しかけられたか
    private hittedonDrag = false;

    // audio, videoのwaiting [msec]
    private waitingTime = 5000;
    //private waitingTimeInConfirm = 10000;   // ビデオ通話前の確認ダイアログ用 --> floorデータから取得

    // window外へのドラッグ対応に使用
    private pointerId: number | null = null;
    /** 意図しないWebSocketの切断がされたときに表示するダイアログの参照 */
    public disconnectDialogRef: any;

    // webSocket自動再接続対応
    private reconWebSocketReleaseFloor: number = 0;     // 退室要求
    //private reconWebSocketDisconnect: number = 0;     // disconnect実施状態
    private reconWebSocketRetryCount: number = 0;     // 一回の切断でリトライする回数
    private reconWebSocketActionCount: number = 0;    // 自動再接続の（単位当たりの）回数
    private reconWebSocketBeforeTime: number = 0;     // 自動再接続を実施した時間

    // webrtc接続リトライ対応
    private watchJoinRoomStatusTimerId: number = 0;
    private webrtcJoinRetryCounter: number = 0;
    private webrtcJoinStatusEvent: string = "";
    private irregularCloseRetryTimerId: number = 0;     // #609 意図しないclose対応

    // デバイスエラー表示     #369
    private webrtcDeviceErrorDisp: number = 0;

    /** 多重ログイン時に表示するダイアログの参照 */
    public checkLoginDialogRef: any;

    /** フロア移動時の失敗に関するダイアログの参照 */
    public moveFloorFailedDialogRef: any;

    /** 画面共有要素の参照 */
    public shareScreenRef: any;

    // #923 画面共有起動時のメッセージ制御用
    private displayShareScreenMessage: boolean = false;

    /** ポップアップ看板のウェブページ要素の参照 */
    public draggableWebpageRef: any;

    /** メモ要素の参照 */
    public chatComponentRef: any;
    public memoComponentRef: any;
    public memoComponentSize = {
        width: Utility.isLowScreenResolution() ? 360 : 360,
        height: Utility.isLowScreenResolution() ? 300 : 472,
    }
    /** メモグループリスト要素の参照 */
    public memoGroupListRef: any;
    public memoGroupListSize = {
        width: Utility.isLowScreenResolution() ? 190 : 190,
        height: Utility.isLowScreenResolution() ? 200 : 300,
    }

    public groupChatComponentRef: any;
    public groupChatComponentSize = {
        width: Utility.isLowScreenResolution() ? 720 : 720,
        height: Utility.isLowScreenResolution() ? 480 : 720,
    }
    /** ユーザー検索要素の参照 */
    public userSearchRef: any;
    public userSearchSize = {
        width: Utility.isLowScreenResolution() ? 190 : 190,
        height: Utility.isLowScreenResolution() ? 200 : 300,
    }

    //新規フロアオブジェクト追加の参照
    public newFloorObjectWindowRef: any;

    //フロアエディター
    public floorEditorRef: any;

    /** スクロールバーの幅 */
    public scrollbarWidth = 8;

    /** チュートリアルダイアログ処理済みか */
    private displayedTutorialDialog = false;

    /** 外部ビデオ通話システムのウィンドウ */
    private popupVideoConference: any = null;

    /** 「会いに行く」ターゲットのsubId */
    private subIdOfGoToSeeTarget: string | null = null;

    /** フロアメニュー */
    private floorMenuRef: any = null;

    private PURPOSE_OF_USE_OFFICE: number = 1;    // フロアの使用目的(オフィス)


    /** YouTubeコンポーネント必要なProps */
    public youtubeProps = {} as YouTubeProps;

    /** OtherWebsiteコンポーネント必要なProps */
    public otherWebsiteProps = {} as OtherWebsiteProps;

    // リリースノート対応
    private myServiceInfoData: ServiceInfoData = new ServiceInfoData();

    // カメラ未選択時の表示対応
    private myDeviceNoSelect: number = 0;
    private myMicDeviceNoSelect: number = 0;    // マイク未選択時の表示対応

    // ダイアログ自動クローズ対応
    private isOpenMoveFloorFailedDialog: boolean = false;
    private moveFloorFailedDialogOpenCount: number = 0;
    private isOpenForceExitMsgDialog: boolean = false;

    //Grid幅の定義
    private GRID_RANGE = 15;
    /** Zoom 関連要素 */
    private zoomViewRef: any;

    // プライバシールーム
    private privacyRoomListRef = new Array();

    private isiPhoneLayout: boolean = false;

    /** 他ユーザーコントロール要素**/
    private usersControlRef: any;
    private usersControlSize = {
        width: Utility.isLowScreenResolution() ? 300 : 400,
        height: Utility.isLowScreenResolution() ? 500 : 600,
    }
    /** フロアにいる人数 **/
    private floorUsersAmountRef: any;

    /** 確認ダイアログ用Ref */
    private migratingMaintenanceDlgRef: any;
    private okCancelDialogRef: any;
    private entryRestrictDlgRef: any;

    private myRoomSize = {
        width: 144,
        height: 502,
    }

    // 自習中かどうか（ → 自習席機能のフロアメニューを表示 ＆ 自習中のポップアップを表示するためのフラグ ）
    private isStudy: boolean = false;

    // 自習席にいるかどうか（ → 自習席機能のフロアメニューを表示するためのフラグ ）
    private isSelfStudyTable: boolean = false;

    // 集中モード
    private isConcentration: boolean = false;

    // 自習タイマーの延長時間
    private studyAdditionalTime: number = 0;
    // 自習時間（count）
    private studyCount: number = 0;
    // 自習終了フラグ(false: 自習は終了していない、初期状態  true: 自習が終了している)
    private studyEnd: boolean = false;

    // サインイン時の自席着席かどうかのフラグ
    private isSigninMySeat: boolean = false;
    // 自習ダイアログを表示するかどうかのフラグ（サインイン時対応）
    private isOpenStudyDialog: boolean = false;
    // サインイン時、フロア移動時、リロード時の未完了の自習データがある状態での自席着席かどうかのフラグ(このフラグは自習席着席でfalseにする)
    private isSigninMySeatWithNotCompletedStudy = false;

    constructor(props: Props) {
        super(props);

        this.setState({
            floorData: new FloorData(),
            floorObjectList: [],
            // myUser: new User(),
            // users: [],
            // webRtcCall: false,
            signout: false,
            micVolumeInterval: 1000,
            openMicVolumeInterval: false,
            visibleScreenShareButton: false,
            visibleWebRtcBroadCastButton: false,
            visibleWebRtcBroadCastStopButton: false,
            openUpdateSessionData: false,
            updateSessionDataType: 0,
            fitScale: 1.0,    // ここじゃ設定できない？？？
            myCommuting: false,
            openMoveFloor: false,
            moveFloor: new MoveFloorData(),
            youtubeUpdate: false,
            openInlineFrame: false,
            inlineFrameUrl: "",
            isWhiteBoard: false,
            floorEditMode: false,
            selectedFloorObj: new FloorObject(),
            isFloorObjSelected: false,
            isFloorObjDragging: false,
            gridMode: true,
            gridRange: this.GRID_RANGE,
            isDrawNewFloorObjWindw: false,
            zIndexHelloInlineFrame: ZIndex.userDocument,
            zIndexScreenShare: ZIndex.userDocument,
            zIndexOther: ZIndex.experiment,

            myRoomFloorData: new MyRoomFloorData(),
            myRoomUpdate: true,
            enabledToolLauncher: false,
            showToolLauncher: true,
            // 直近の自習席更新情報
            updateStudyObjectSetting: new StudyObjectSetting(),
            // 自身が着席している自習席の情報
            hitStudyObjectSetting: new StudyObjectSetting(),
        })

        this.floorWrapperRef = React.createRef();
        this.floorRef = React.createRef();
        this.jumpToDialogRef = React.createRef();
        this.myUserRef = React.createRef();
        this.otherUsersRef = React.createRef();
        this.tutorialDialogRef = React.createRef();
        this.floorClosedDialogRef = React.createRef();
        this.InformationDialogRef = React.createRef();
        this.ReleaseNoteDialogRef = React.createRef();  // リリースノート対応
        this.disconnectDialogRef = React.createRef();
        this.checkLoginDialogRef = React.createRef();
        this.shareScreenRef = React.createRef();
        this.chatComponentRef = React.createRef();
        this.memoComponentRef = React.createRef();
        this.memoGroupListRef = React.createRef();
        this.userSearchRef = React.createRef();
        this.floorMenuRef = React.createRef();
        this.moveFloorFailedDialogRef = React.createRef();
        this.newFloorObjectWindowRef = React.createRef();
        this.floorEditorRef = React.createRef();
        this.zoomViewRef = React.createRef();
        this.InternalNoticeDialogRef = React.createRef();
        this.PrivacyRoomPermissionDialogRef = React.createRef();
        this.SelfStudyStartDialogRef = React.createRef();
        this.SelfStudyChangeDialogRef = React.createRef();
        this.SelfStudyFinishDialogRef = React.createRef();
        this.SelfStudyCompleteDialogRef = React.createRef();
        this.SelfStudyTimerRef = React.createRef();
        this.draggableWebpageRef = React.createRef();
        this.usersControlRef = React.createRef();
        this.floorUsersAmountRef = React.createRef();
        this.GetAttendanceDialogRef = React.createRef();
        this.myRoom2Ref = React.createRef();
        this.myRoomMessageRef = React.createRef();
        this.myRoomUserInfoCardRef = React.createRef();
        this.myRoomMemoGroupCardRef = React.createRef();
        this.myRoomChatGroupCardRef = React.createRef();
        this.groupChatComponentRef = React.createRef();
        this.draggableWebpageRef = React.createRef();
        this.ForceExitAlertDialogRef = React.createRef();
        this.tenantLauncherRef = React.createRef();
        this.characterObjectRef = React.createRef();
        this.GeneralMessageRef = React.createRef();
        this.migratingMaintenanceDlgRef = React.createRef();
        this.okCancelDialogRef = React.createRef();
        this.entryRestrictDlgRef = React.createRef();

        // iPad対応-3 NoSleepWrapper で制御する
        /*
        // スリープさせないためのモジュール
        this.noSleep = new NoSleep();
        */

        // スリープさせないためのモジュール
        // iPad対応-3 重複してるので削除
        //this.noSleep = new NoSleep();

        document.title = "loading..."
    }

    private jfsClient = JfsClient.getInstance();
    private httpClient =  this.jfsClient.httpClient;
    private wsClient = this.jfsClient.wsClient;
    private JFS_ERRORS = this.jfsClient.JFS_ERRORS;

    /** sdk のイベントハンドラ登録
     *  コメントアウトしている行はSDKが未対応で本家FAMには存在する
     */
    setupSdkHandlers = () => {
        this.wsClient.on('signin', this.wsClient.sendRequestGetFloorDataInfo);
        this.wsClient.on('floorData', this.receiveFloorData);
        this.wsClient.on('avatarMenuData', this.receiveAvatarMenuData);
        this.wsClient.on('floorObjectData', this.receiveFloorObject);
        this.wsClient.on('floorSeatData', this.receiveSeatData);
        this.wsClient.on('otherUsersMove', this.receiveMoveUsers);
        this.wsClient.on('updateUserData', this.receiveUpdateOneSession);
        this.wsClient.on('myUser', this.receiveMyUser);
        this.wsClient.on('otherUsers', this.receiveOtherUsers);
        this.wsClient.on('tatehudaText', this.receiveTatehudaText);
        this.wsClient.on('tatehuda2Text', this.receiveTatehuda2Text);
        this.wsClient.on('tatehudaTextText1', this.receiveTatehudaTextText1);
        this.wsClient.on('pong', this.receivePong);
        this.wsClient.on('ping', this.receivePing);
        this.wsClient.on('hitOtherFloorUser', this.receiveHitOtherFloorUser);
        this.wsClient.on('hitBusyUser', this.recieveHitBusyUser);
        this.wsClient.on('webRTCLimitUser', this.recieveWebRtcLimit);
        this.wsClient.on('deviceUnselected', this.receiveDeviceUnselected);
        this.wsClient.on('deviceErrorDisplayOther', this.recieveDisplayErrorSnackBar);
        this.wsClient.on('changeFloor', this.handleMoveFloor);
        this.wsClient.on('inviteHello', this.receiveInvitedMoreNote);
        this.wsClient.on('checkSignin', this.receiveLoginChecked);
        this.wsClient.on('meetLimitOver', this.receiveMeetLimitOver);
        // this.wsClient.on('updateUserData', this.receiveUpdateSessionData);
        // this.wsClient.on('talkRequest', this.receiveTalkRequest);
        this.wsClient.on('newMemo', this.receiveNewMemo);
        this.wsClient.on('readMemo', this.receiveReadMemo);
        this.wsClient.on('deleteMemo', this.receiveDeleteMemo);
        this.wsClient.on('chatGroupName', this.receiveChatGroupName);
        this.wsClient.on('chatGroupQuit', this.receiveChatGroupQuit);
        this.wsClient.on('chatGroupAdd', this.receiveChatGroupAdd);
        // this.wsClient.on('chatGroupUpdate', this.receiveChatGroupUpdate);
        this.wsClient.on('clapping', this.receiveClapping);
        this.wsClient.on('opendHello', this.receiveOpenedHello);
        this.wsClient.on('requestReleaseFloor', this.releaseFloorRequest); // 同上
        this.wsClient.on('floorObjectErr', this.handleObjectMoveError); // 同上
        this.wsClient.on('floorEditOK', this.setFloorEditMode);
        this.wsClient.on('floorEditNG', this.setFloorEditMode);
        this.wsClient.on('floorEditorNotice', this.floorEditorSnackBar);
        this.wsClient.on('floorObjectSnappoint', this.selectedFloorObjSnap);
        this.wsClient.on('floorResize', this.floorReSize);
        this.wsClient.on('floorBackgroundChange', this.changeFloorBackground);
        this.wsClient.on('createdObject', this.changeSelectedFloorObject);
        this.wsClient.on('noEntranceNotice', this.noEntranceAlert);
        this.wsClient.on('updateYoutubeData', this.receiveUpdateYouTubeData);
        this.wsClient.on('updateOtherWebsiteData', this.receiveUpdateOtherWebsiteData);
        this.wsClient.on('updateZoomInfo', this.receiveUpdateZoomInfo);
        this.wsClient.on('internalNotice', this.receiveInternalNotice);
        this.wsClient.on('closeHello', this.receiveCloseMoreNote);
        this.wsClient.on('updateAdminMessage', this.receiveUpdateAdminMessage);
        this.wsClient.on('updatePopupSignboard', this.updatePopupSignboard);
        this.wsClient.on('myRoomFloorData', this.receiveMyRoomFloorData);
        this.wsClient.on('myRoomFloorObjectData', this.receiveMyRoomFloorObject);
        this.wsClient.on('myRoomFloorSeatData', this.receiveMyRoomSeatData);
        this.wsClient.on('myRoomOtherUsers', this.receiveMyRoomOtherUsers);
        this.wsClient.on('myRoomMessage', this.receiveMyRoomMessage);
        this.wsClient.on('myRoomUpdate', this.receiveUpdateMyRoom);
        // this.wsClient.on('teamsStatusData', this.receiveTeamsStatusData);
        // this.wsClient.on('teamsSettingData', this.receiveTeamsSetting);
        // this.wsClient.on('missedCall', this.recvMissedCall);
        this.wsClient.on('meetingRoomLock', this.receiveMeetingRoomLock);
        this.wsClient.on('meetingRoomLockJudgedFromObjectId', this.receiveMeetingRoomLock);
        this.wsClient.on('generalMessage', this.receiveGeneralMessage);
        this.wsClient.on('websocketClose', this.receiveWebsocketClose);
        this.wsClient.on('moveFloorFailed', this.openMoveFloorFailedDialog);

        // ここから新規
        this.wsClient.on('privacyState', this.receivePrivacyState);
        this.wsClient.on('newVisitor', this.receiveNewVisitor);
        this.wsClient.on('extendPrivacyRoomPermission', this.receiveExtendPrivacyRoomPermission);
        this.wsClient.on('leaveRoom', this.receiveLeaveRoom);
        this.wsClient.on('privacyRoomPermission', this.receivePrivacyRoomPermison);
        this.wsClient.on('privacyRoomTimeOut', this.receivePrivacyRoomTimeOut);
        this.wsClient.on('enterPrivacyRoom', this.receiveEnterPrivacyRoom);
        this.wsClient.on('ringChime', this.receiveChime);
        this.wsClient.on('forceExit', this.receiveForceExit);
        this.wsClient.on('forceExitAlert', this.receiveForceExitAlert);
        this.wsClient.on('loginoutNotice', this.recieveLoginoutNotice);
        this.wsClient.on('roomText', this.receiveRoomText);
        this.wsClient.on('clickCharacterChange', this.receiveClickCharacterChange);
        this.wsClient.on('characterSetting', this.receiveCharacterSetting);
        this.wsClient.on('updateStudyObject', this.receiveUpdateStudyObject);
        this.wsClient.on('hitStudyObject', this.receiveHitSelfStudyTable);
        this.wsClient.on('leaveStudyObject', this.receiveLeaveSelfStudyTable);
        this.wsClient.on('moveEntrance', this.receiveMoveEntrance);
        // UPDATE_SESSION_DATA_RESULT ない？
    }

    componentDidMount() {
        // stateのWebSocket接続先パスが正しいかチェックする
        this.traceSend("Floor componentDidMount stateのWebSocket接続先パスが正しいかチェックする");
        // this.checkFloorWebSocket(this.websocketPath);
        this.checkFloorWebSocket(this.websocketNumber);
    }

    /**
     * フロアの初期化処理
     */
    initFloor = () => {
        this.traceSend("Floor initFloor start:");
        //if(WebrtcService.isiOS() === true){
        //    NoSleepWrapper.initNoSleep();
        //}
        // iOS 音声の自動再生対応
        AudioPlayer.loadData();
        this.clearInterval();
        this.getTestMode();
        this.handleGetAvatarColors();
        // this.websocket.connect(this, this.websocketPath);
        console.info('tabid = ' + sessionStorage.getItem("TABID") as string + ' wsNumber = ' + this.websocketNumber);
        // this.wsClient.connect(sessionStorage.getItem("TABID") as string, this.websocketNumber);
        try {
            this.wsClient.connect(sessionStorage.getItem("TABID") as string, this.websocketNumber);
            this.wsClient.planDisconnect = false;
        } catch (err) {
            console.info(err);
        }
        // wsClientにイベントハンドラをバインド
        this.setupSdkHandlers();
        this.reconWebSocketReleaseFloor = 0;
        //this.reconWebSocketDisconnect = 0;
        const target = document.getElementById("floorDiv") as HTMLDivElement;
        target.addEventListener('pointerdown', this.onMouseDown, false);
        target.addEventListener('touchmove', this.onTouchMove, false);
        target.addEventListener('pointermove', this.onMouseMove, false);
        target.addEventListener('pointerup', this.onMouseUp, false);
        window.addEventListener("beforeunload", this.handleBeforeunload, false);
        window.addEventListener("resize", this.handleWindowResize, false);
        window.addEventListener("focus", this.handleWindowFocus);
        window.addEventListener("blur", this.handleWindowBlur);
        window.document.addEventListener("visibilitychange", this.handleVisibilitychange);
        window.document.addEventListener("drop", function(event){event.preventDefault();},false);
        window.addEventListener("drop", this.stopDrag,false);
        window.addEventListener("dragenter", this.stopDrag,false);
        window.addEventListener("dragover", this.stopDrag,false);
        window.addEventListener("dragleave", this.stopDrag,false);

        // iOSのスリープ無効
        if(WebrtcService.isiOS() === true){
            // console.log("noSleep.enable");

            // iPad対応-3 NoSleepWrapper で制御する
            // iPad対応-3 iPad対応-21
            // ユーザー操作させてnoSleep起動＆効果音を再生可能に
            //this.noSleep.enable();
            NoSleepWrapper.initNoSleep();
            this.showIOSstartMessage();
        }
        // iOSの時は通らないようにする（iOSだと通知許可を求めないため）
        if(WebrtcService.isiOS() === false){
        // Notificationを使う場合は下記を使用
            if (window.Notification) {
                Notification.requestPermission();
            }
        }
    }

    stopDrag = (event:any) =>{
        event.dataTransfer!.effectAllowed = "none";
        event.dataTransfer!.dropEffect = "none";
        event.preventDefault();
        event.stopPropagation();
    }

    // iPad対応-3 iPad対応-21
    // サインイン直後に何もしないとスリープしたり、音が鳴らない（SSOの時）対応のため、
    // メッセージを表示してユーザー操作をさせる
    showIOSstartMessage = () => {
        //this.showAttentionSnackBar(true, "この端末のオーディオを使用します。");
        let key: string = "iOSStartMessage";
        const action = (key: string) => (
            <Button onClick={() => this.handleIOSstartMessage(key)} color="inherit">閉じる</Button>
        )
        this.props.enqueueSnackbar(`この端末のオーディオを使用します。  `, 
            //{ key: key, anchorOrigin: { horizontal: "center", vertical: "bottom" }, persist: true,
            { key: key, anchorOrigin: { horizontal: "center", vertical: "top" }, persist: true,
            action });
    }
    handleIOSstartMessage = (key: string) => {
        //noSleep有効化はtouchstartイベントでやっている
        //効果音はAudioPlayerのtouchstartイベントでやっている
        this.props.closeSnackbar(key);
    }
    allCloseINSnackbar = () => {
        this.InternalNoticeDialogRef.current?.allCloseINSnackbar();
    }    

    checkFloorWebSocket = (websocket: number) => {
        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("websocket_url", websocket);
        // axios.post("/api/user/floor/websocket/check", params)
        //     .then((e: AxiosResponse) => {
        //         if(e.data === "OK") {
        //             // 変更なし
        //         } else {
        //             this.websocketPath = e.data;
        //         }
        //         this.initFloor();
        //     }).catch(err => {
                
        //     });
        console.info('websocket number = ' + websocket);
        this.httpClient.checkWebSocket(sessionStorage.getItem("TABID") as string, websocket)
            .then((e: string) => {
                // if(e === "OK") {
                if(e === this.JFS_ERRORS.OK.code) {
                    // 変更なし
                } else {
                    // this.websocketPath = e;
                    this.websocketNumber = Number(e);
                }
                this.initFloor();
            }).catch(err => {
                console.log((err as JfsError).code);
                let signinPagePath = Utility.getSigninPage();
                this.props.history.push(signinPagePath);
                return;
            });
    }

    // 会議室ロック状態更新
    receiveMeetingRoomLock = (info: MeetingRoomLock) => {

        const updatedList = this.state.floorObjectList.map(obj =>
            (obj.id === info.id) ? {...obj, lockedMeetingRoom: info.locked } : obj
        )

        this.setState({
            floorObjectList: updatedList
        })
    }

    receiveGeneralMessage = (generalMessage: GeneralMessageData) => {
        this.GeneralMessageRef?.current.setTitle(generalMessage.title);
        this.GeneralMessageRef?.current.setMessage(generalMessage.message);
        this.GeneralMessageRef?.current.setOpen(true);
    }

    receiveWebsocketClose = (code: string) => {
        console.log('##### websocket on close ##### code=['+code+']');
        console.info('##### websocket on close ##### code=['+code+']');
        if(code === "1000") console.log('normal close');
        else if(code === "1001") console.log('end point delete');
        else if(code === "1002") console.log('end point protcol error');
        else if(code === "1003") console.log('not support data');
        else if(code === "1009") console.log('too long data');
        else console.log('other error');
        this.disconnectDialogRef.current.open();
        this.setDisconnectWebrtc();     // #284 webrtc中ならばwebrtc終了したい
        this.allCloseINSnackbar();  // iPad対応-3 iPad対応-21 snackbarをすべて閉じる
    }

    componentWillUnmount() {
        this.clearInterval();
        this.wsClient.browserBack = true;
        // this.websocket.disconnect(true, "");
        this.wsClient.disconnect(true);
        //this.reconWebSocketDisconnect = 1;
        this.myUserRef.current.clearStatusTimer();
        const target = document.getElementById("floorDiv") as HTMLDivElement;
        target.removeEventListener('pointerdown', this.onMouseDown, false);
        target.removeEventListener('touchmove', this.onTouchMove, false);
        target.removeEventListener('pointermove', this.onMouseMove, false);
        target.removeEventListener('pointerup', this.onMouseUp, false);
        window.removeEventListener("beforeunload", this.handleBeforeunload, false);
        window.removeEventListener("resize", this.handleWindowResize, false);
        window.removeEventListener("focus", this.handleWindowFocus);
        window.removeEventListener("blur", this.handleWindowBlur);
        window.document.removeEventListener("visibilitychange", this.handleVisibilitychange);
        document.title = "loading..."

        // スリープ無効解除
        if(WebrtcService.isiOS() === true){
            // console.log("noSleep.disenable");
            /* NoSleepWrapperへ移動
            this.noSleep.disable();
            */
            NoSleepWrapper.finishNoSleep();
        }
    }

    clearInterval = () => {
        console.log("clearInterval")
        if (this.handlePingTimer !== null) {
            clearInterval(this.handlePingTimer);
            this.handlePingTimer = null;
        }
        if (this.handlePongTimer !== null) {
            clearInterval(this.handlePongTimer);
            this.handlePongTimer = null;
        }
        this.pongTime = 0;
    }

    getAdminUserList = (id: number) => {
        // const params = new URLSearchParams();
        // params.append("floor_id", id.toString());
        // return axios.post('/api/user/roleadmin/list/get', params);
        return this.httpClient.getRoleAdminPrivacyRoomInfo(id, sessionStorage.getItem("TABID") as string);
    }

    private sendPingTime: number = 0;
    receiveFloorData = async (floorData: FloorData) => {
        const user = this.myUserRef.current.getUser();
        const preFloorData = this.state?.floorData as FloorData;
        // WebrtcService.setWebrtcApiKey(floorData.webrtcApiKey);
        // WebrtcService.getPeer().then((peer) => {
        //     if (user.webRtcPeerId !== peer.id) {
        //         user.webRtcPeerId = peer.id;
        //         this.sendWebRtcPeerId(user);
        //         if (WebrtcService.isOpenDeviceSelect === false && WebrtcService.isDeviceSelected()) {
        //             this.sendSetGhost(false);
        //             this.sendDeviceSelectedType(WebrtcService.getDeviceSelectedType());
        //         }
        //         // console.log("sendWebRtcPeerId " + user.webRtcPeerId);
        //     }
        // });

        // webRTCClientの初期化
        try {
            WebrtcService.init();
            WebrtcService.setWebrtcApiKey(floorData.webrtcApiKey);
        } catch (error) {
            console.error('webrtcservice init faild');
        }

        /* #3744 peerId取得できないことがある対応
        WebrtcService.getWebRTCClient().then((webRtcClient) => {
            // if (user.webRtcPeerId !== WebrtcService.getPeerId()) {
            if (user.webRtcPeerId !== webRtcClient.getPeerId()) {
                user.webRtcPeerId = webRtcClient.getPeerId();
                this.sendWebRtcPeerId(user);

                if (WebrtcService.isOpenDeviceSelect === false && WebrtcService.isDeviceSelected()) {
                    this.sendSetGhost(false);
                    this.sendDeviceSelectedType(WebrtcService.getDeviceSelectedType());
                }

                console.info("sendWebRtcPeerId " + user.webRtcPeerId);
            }
        });
        */
        let waitCounter = -1;   // デフォルト値を使う指定
        if(this.isDrawTestNav === true) {
            waitCounter = 0;
        }
        WebrtcService.getWebRTCClient(waitCounter).then((webRtcClient) => {
            let newPeerId = webRtcClient.getPeerId();
            if (user.webRtcPeerId !== newPeerId) {
                user.webRtcPeerId = newPeerId;
                if(newPeerId !== undefined && newPeerId !== "") {
                    this.sendWebRtcPeerId(user);
                }

                if (WebrtcService.isOpenDeviceSelect === false && WebrtcService.isDeviceSelected()) {
                    this.sendSetGhost(false);
                    this.sendDeviceSelectedType(WebrtcService.getDeviceSelectedType());
                }
            }
            if(newPeerId === undefined || newPeerId === "") {
                this.setTimerCheckConnectedSignalingServer(user, 100);
            }
        });

        this.setState({
            floorData: floorData,
            visibleWebRtcBroadCastButton: this.visibleWebRtcBroadCastButton(user.webRtcMode, user.id, user.webRtcRoomId),
            fitScale: 1.0,    // ここでやることではないけど。。constructorでset出来てないようなので
            myRoomUpdate: true,
        })
        this.floorId = floorData.id;
        this.myUserRef.current.setFoorId(this.floorId);

        this.handleGetNamePlateColors();//名札設定データ取得

        // webSocket自動再接続対応
        // 再接続時の場合があるので、タイマークリアしておく
        this.clearInterval();

        // // フロアデータの受信と同時にPingを開始
        // this.handlePingTimer = setInterval(() => {
        //     if (this.websocket !== null) {
        //         this.sendPingTime = new Date().getTime();
        //         this.websocket.sendPing();
        //     }
        // }, floorData.pingInterval * 1000);

        this.handlePongTimer = setInterval(() => {
            this.traceLog("[WebSocketTrace] PingPong check start")
            let now: number = new Date().getTime();
            // console.log(now - this.pongTime);
            if (this.pongTime === 0) {
                // webSocket自動再接続対応
                if(this.disconnectDialogRef?.current?.checkOpen() === true) {
                    this.traceLog("[WebSocketTrace] ping受信前にwebSocketがcloseされた")
                } else {
                    this.traceLog("[WebSocketTrace] PingPong check skip pongTime=0")
                    return;
                }
            }
            if ((now - this.pongTime) > (floorData.pingInterval * 2 * 1000)) {
                //console.log("PingPongが一定時間内に処理されなかった為、切断処理を実行します。");
                this.traceLog("[WebSocketTrace] PingPongが一定時間内に処理されなかった為、切断処理を実行します。")
                //if(this.disconnectDialogRef?.current?.checkOpen() === false) {
                //    this.reconWebSocketDisconnect = 1;
                //}
                // this.websocket.disconnect(false, "");
                this.wsClient.disconnect(false);
                this.disconnectDialogRef.current.open();
                this.setDisconnectWebrtc();     // #284 webrtc中ならばwebrtc終了したい
                this.allCloseINSnackbar();  // iPad対応-3 iPad対応-21 snackbarをすべて閉じる
                // webSocket自動再接続対応
                this.checkReconnectWebSocket();
            } else {
                this.traceLog("[WebSocketTrace] PingPong check OK")
            }
        }, floorData.pingInterval * 2 * 1000);

        // タブタイトル書き換え
        document.title = `${floorData.office.officeName} - ${floorData.floorName}`;

        this.checkMediaDevices(floorData.requireCamera, floorData.requireMic);

        // floorData受信後、server側のisChangeFloorフラグをfalseにする
        // const params = new URLSearchParams();
        // params.append("floor_id", this.floorId.toString());
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("is_changing", "false");
        // axios.post('/api/user/floor/change', params).catch(err => console.log(err));
        this.httpClient.moveFloor(this.floorId, sessionStorage.getItem("TABID") as string, false)
            .catch(err => console.log(err));
        // ログ出力クラスの初期化
        const logger: Logger = Logger.getInstance();
        // logger.init(this.websocket, floorData.frontEndDebugLevel);
        logger.init(this.wsClient, floorData.frontEndDebugLevel);
    
        // オフィスの場合waitingタイムアウトを変更
        if(this.getPurposeOfUse() === this.PURPOSE_OF_USE_OFFICE) {
            this.waitingTime = 20000;
        }

        // webSocket自動再接続対応
        // 再接続してきたら、切断ダイアログを閉じる
        let dialog = this.disconnectDialogRef.current.checkOpen();
        this.traceLog("[WebSocketTrace] receiveFloorData this.pongTime=["+this.pongTime+"] dialog=["+dialog+"]");
        if(dialog === true) {
            this.disconnectDialogRef.current.close();
            this.reconWebSocketReleaseFloor = 0;
            //this.reconWebSocketDisconnect = 0;
            this.reconWebSocketRetryCount = 0;
            this.checkReconWebSocketActionCount();  // 前回から一定時間離れていたらカウントクリア
            this.reconWebSocketActionCount++;
            this.reconWebSocketBeforeTime = new Date().getTime();
            this.traceLog("[WebSocketTrace] disconnectDialog close reconnect end cnt=["+this.reconWebSocketActionCount+"]");
        }

        // FloorMenu内の画面共有状態を表すstateをfalseにする
        this.floorMenuRef.current?.setScreenShare(false);
        // FloorMenu内のZoom関連ボタン表示を表すstateをfalseにする
        this.floorMenuRef.current?.setZoomSpeakable(false);

        // iPhone用レイアウト設定
        const ua = window.navigator.userAgent.toLowerCase();
        if( ua.indexOf('iphone') > -1 || ua.indexOf('android') > -1){
            // 本当はこっち
            this.userSearchRef.current?.setIsiPhoneLayout(true);
            this.isiPhoneLayout = true;
        }
        else{
            // ローカルテスト用
			//this.userSearchRef.current?.setIsiPhoneLayout(true);
			//this.isiPhoneLayout = true;
        }
        // 聴衆設定UIが開いている場合、閉じる
        if (this.usersControlRef.current?.getShow()) this.usersControlRef.current?.close();

        // フロア移動や会いに行くで移動したときに、マイルームを閉じる
        // (機能ON/OFFをFloorに持たせたので)
        // this.floorMenuRef.current?.setOpenMyRoom(false);    // フロアメニューのマイルームボタンの色を変える
        // this.myRoom2Ref.current?.open(false);   // マイルーム表示を閉じる

        // フロア移動や会いに行くで移動したときに、マイルームの更新をする
        this.updateMyRoom();

        // 移動先のフロアがつぶやき無効の場合、現在設定されているつぶやきを消す
        if(this.myUserRef.current.getUser().tubuyaki !== "" && !this.state.floorData.enabledTubuyaki){
            this.hanldeTubuyakiChange("");
        }

        //フロア移動が完了したと捉え、ここでプライバシールームの情報をセットするwsを呼ぶ
        if(preFloorData){
            if(this.state.floorData.office.privacyRoom && !preFloorData.office.privacyRoom){
                //一般フロアから面談室に移動している
                //管理者ユーザの場合、面談室の状況次第では許可申請の再出力をする
                if(this.getMyRole().indexOf('ROLE_ADMIN') !== -1){
                    const res = await this.getAdminUserList(floorData.id);
                    const privacyInfoList = res as privacyRoomInfo[];

                    if(privacyInfoList.length >= 2){
                        // 承認ダイアログ出力中であれば管理者が入室した際に再出力
                        const result = privacyInfoList.find((u) => {return u.waitForPermit === true  });
                        if(result){
                            this.sendNewVisitor(floorData.id,result.targetUserId,result.targetFloorId); 
                        }
                    }
                }

                this.sendMovePrivacyRoomInfo(true,preFloorData.id,preFloorData.floorName);
                this.handleMyStateChange(UserState.None,true);
            }else if(!this.state.floorData.office.privacyRoom && preFloorData.office.privacyRoom){
                //面談室から一般フロアに移動している
                this.sendMovePrivacyRoomInfo(false,0,"");
                this.handleMyStateChange(UserState.None,true);
            }
        }else if(preFloorData === undefined && this.state.floorData.office.privacyRoom){
            this.sendPrivacyRoomInfoCheck();
        }
    }

    // リリースノート対応
    receiveServiceInfoData = (serviceInfoData: ServiceInfoData) => {
        // let p = require('../../package.json');
        console.log("recv releaseVersion :["+serviceInfoData.releaseVersion+"]["+appFrontVersion+"]")
        this.myServiceInfoData.id = serviceInfoData.id;
        this.myServiceInfoData.serviceName = serviceInfoData.serviceName;
        this.myServiceInfoData.releaseNoteURL = serviceInfoData.releaseNoteURL;
        //this.myServiceInfoData.releaseVersion = serviceInfoData.releaseVersion;
        this.myServiceInfoData.releaseVersion = appFrontVersion;
        let ddd = new Date();
        let rURL = serviceInfoData.pageFAQURL + "?" + ddd.getTime();
        this.myServiceInfoData.pageFAQURL = rURL;
        let mURL = serviceInfoData.usermanualURL + "?" + ddd.getTime();     // マニュアルURLをDBから取得
        this.myServiceInfoData.usermanualURL = mURL;    // マニュアルURLをDBから取得
        console.log("recv releaseVersion :["+serviceInfoData.releaseVersion+"]["+appFrontVersion+"]["+serviceInfoData.pageFAQURL+"]["+serviceInfoData.releaseNoteURL+"]["+serviceInfoData.serviceName+"]")
    }

    /**
     * PeerIdの取得ができたか確認するためのタイマーセット
     * #3744 peerId取得できないことがある対応
     * 
     * @param user
     * @param retry
     *  */
    setTimerCheckConnectedSignalingServer = (user: User, retry: number) => {
        const logger: Logger = Logger.getInstance();
        logger.info("[WR_PEER_TRACE] floor.setTimerCheckConnectedSignalingServer retry=["+retry+"]");
        setTimeout(() => this.checkGetPeerId(user, retry), (60*1000));
    }

    /**
     * シグナリングサーバに接続しPeerIdを取得する
     * #3744 peerId取得できないことがある対応
     * 
     * @param user
     * @param retry
     *  */
    checkGetPeerId = (user: User, retry: number) => {
        const myUser = this.myUserRef.current.getUser();
        const logger: Logger = Logger.getInstance();
        logger.info("[WR_PEER_TRACE] floor.checkGetPeerId");

        let newPeerId = WebrtcService.getPeerIdSync();
        if(newPeerId !== undefined && newPeerId !== "") {
            logger.info("[WR_PEER_TRACE] floor.checkGetPeerId Get newPeerId=["+newPeerId+"] user.webRtcPeerId=["+user.webRtcPeerId+"]["+myUser?.webRtcPeerId+"]");
            if (myUser.webRtcPeerId !== newPeerId) {
                myUser.webRtcPeerId = newPeerId;
                this.sendWebRtcPeerId(myUser);
            }
        } else {
            if(retry !== 0) {
                retry -= 1;
                this.setTimerCheckConnectedSignalingServer(myUser, retry);
            } else {
                logger.info("[WR_PEER_TRACE] floor.checkGetPeerId retry over");
            }
        }
    }

    /**
     * ログインチェック後のダイアログを表示
     */
    receiveLoginChecked = () => {
        console.log("多重ログインが検知されました。");
        this.checkLoginDialogRef.current.open();
    }

    /**
     * Pingの返信を受信
     */
    receivePong = () => {
        this.pongTime = new Date().getTime();
        let pingResult = new Date().getTime() - this.sendPingTime;
        this.traceLog("[WebSocketTrace] recv_pong this.pongTime=["+this.pongTime+"] pingResult=["+pingResult+"]");
        // this.websocket.sendPingResult(pingResult);
        try {
            this.wsClient.sendPingResult(pingResult);
        } catch (error) {
            console.error(error);
        }
    }

    /** HTTPセッション延長処理をコールした時間 */
    private extendSessionTime: number = 0;
    /**
     * Pingの返信を受信
     */
    receivePing = () => {
        // console.log("pingを受信")
        // console.log(new Date())
        this.pongTime = new Date().getTime();
        this.traceLog("[WebSocketTrace] recv_ping this.pongTime=["+this.pongTime+"] this.extendSessionTime=["+this.extendSessionTime+"]");
        // this.websocket.sendPong();
        try {
            this.wsClient.sendPong();
        } catch (error) {
            console.error(error);
        }
        if(this.pongTime - this.extendSessionTime > (10 * 60 * 1000)) {
            this.traceLog("[WebSocketTrace]  requestExtendSession call");
            Utility.requestExtendSession(this.lostSession);
            this.extendSessionTime = this.pongTime
        }
        //this.disconnectDialogRef.current.close();
    }

    /**
     * マイルームのFloorDataを受信
     */
    receiveMyRoomFloorData = (data: MyRoomFloorData) => {
        this.setState({
            myRoomFloorData: data,
        });
        this.myRoom2Ref?.current.updateSize(data.floorWidth, data.floorHeight);
        this.myRoomSize.width = data.floorWidth;
        this.myRoomSize.height = data.floorHeight;
    }

    receiveMyRoomFloorObject = (list: MyRoomFloorObject[]) => {
        this.myRoom2Ref?.current.setFloorObjectList(list);
    }

    /**
     * セッション延長処理で失敗した時のコールバック関数
     */
    lostSession = () => {
        this.traceLog("[WebSocketTrace] requestExtendSession error");
        // this.websocket.close(null);
        this.wsClient.disconnect(false);
    }

    /**
     * ダミーアバターと接触した時、サーバから送信される[HIT_OTHER_FLOOR_USER]の処理
     */
    receiveHitOtherFloorUser = (avatarCommand: AvatarCommand) => {
        let myUser = this.myUserRef.current.getUser() as User;
        let tempMyUser = User.copyUser(myUser) as User;
        // tempMyUser.command = command;
        tempMyUser.command = "HIT_OTHER_FLOOR_USER";
        tempMyUser.cMessage = avatarCommand.message;
        this.myUserRef.current.setUser(tempMyUser);
    }

    /**
     * BUSY 状態のユーザにぶつかった時の処理
     * @param hitBusyUser
     */
    recieveHitBusyUser = (hitBusyUser: HitBusyUser) => {
        let myUser = this.myUserRef.current.getUser() as User;
        let tempMyUser = User.copyUser(myUser) as User;
        let users = this.otherUsersRef.current.getUsers() as User[];

        let busyUser = users.filter(e => e.id === hitBusyUser.id);
        if (busyUser.length !== 0) {
            // #11071 オフラインのアバターに重ねた時の対応
            //console.log("chkchk02 user.isCommuting=["+busyUser[0].isCommuting+"] stayFloorname=["+busyUser[0].stayFloorName+"] stayFloor=["+busyUser[0].isStayFloor+"]");
            let stateName = toStrUserState(this.state.avatarMenuDataList, busyUser[0].state, false);
            if(stateName == "" && busyUser[0].isStayFloor === false && busyUser[0].stayFloorName === "オフライン") {
                stateName = busyUser[0].stayFloorName;
            }
            // 登壇中のテレミート開始不可
            if(busyUser[0].isZoomSpeakable === true) {
                stateName = "Zoom 発壇中";
            }
            tempMyUser.notifyTitle = "ビデオ通話";
            tempMyUser.notifyMessage
                = busyUser[0].displayName + "さんは" + stateName + "のため、ビデオ通話できません。";
            this.myUserRef.current.setUser(tempMyUser);
        }
    }

    /**
     * WebRTC通信制限時の処理
     * @param webRtcLimitUser
     */
    recieveWebRtcLimit = (webRtcLimitUser: WebRtcLimitUser) => {
        let myUser = this.myUserRef.current.getUser() as User;
        let tempMyUser = User.copyUser(myUser) as User;

        let message = "";
        if ( webRtcLimitUser.stat === 0) {
            // 制限なし
            ;
        }
        else if (webRtcLimitUser.stat === 1){
            // 80%制限
            if (webRtcLimitUser.kind === 2 || webRtcLimitUser.kind === 4 ) {
                // ユーザ上限
                message = "ユーザー通信量制限に近づいています。";
            }
            else if (webRtcLimitUser.kind === 1 || webRtcLimitUser.kind === 3 ) {
                // テナント上限
                message = 
                    "ご契約の通信量が上限に近づいています。\n" +
                    "上限を超えると、ビデオ通話が制限されます。";
            }
            else if (webRtcLimitUser.kind === 5 ) {
                // テナント上限（カメラ制限あり）
                message = 
                    "ご契約の通信量が上限に近づいています。\n" +
                    "上限を超えると、カメラ、画面共有の使用が制限されます。\n" +
                    "※音声通話は制限されません。";
            }
        }
        else if (webRtcLimitUser.stat === 2){
            // 100%制限
            if (webRtcLimitUser.kind === 2 || webRtcLimitUser.kind === 4 ) {
                // ユーザ上限
                message = "ユーザー通信量制限のため、ビデオ通話できません。";
            }
            else if (webRtcLimitUser.kind === 1 || webRtcLimitUser.kind === 3 ) {
                // テナント上限
                message = "ご契約中の通信量が上限を超えたため、ビデオ通話できません。";
            }
            else if (webRtcLimitUser.kind === 5 ) {
                // テナント上限（カメラ制限あり）
                message = 
                    "ご契約中の通信量が上限を超えたため、カメラ、画面共有の使用を制限しています。\n" +
                    "音声通話は使用可能です。";
            }
        }
        else {
            // 予期せぬ状態(stat==-1を含む)
            message = "問題が発生したため、ビデオ通話を終了しました。";
        }

        if (myUser.id === webRtcLimitUser.id) {
            if (webRtcLimitUser.stat === 1){
                tempMyUser.notifyTitle = "通信量のご注意";
            }
            else if (webRtcLimitUser.stat === 2){
                tempMyUser.notifyTitle = "通信量による制限";
            }
            tempMyUser.notifyMessage = message;
            this.myUserRef.current.setUser(tempMyUser);
            return;
        }

        let users = this.otherUsersRef.current.getUsers() as User[];
        let limitUser = users.filter(e => e.id === webRtcLimitUser.id);
        if (limitUser.length !== 0) {
            if (webRtcLimitUser.stat === 1){
                tempMyUser.notifyTitle = "通信量のご注意";
            }
            else if (webRtcLimitUser.stat === 2){
                tempMyUser.notifyTitle = "通信量による制限";
            }
            tempMyUser.notifyMessage
                = limitUser[0].displayName + "さんは" + message;
            this.myUserRef.current.setUser(tempMyUser);
        }
    }

    receiveDeviceUnselected = (webRtcLimitUser: WebRtcLimitUser) => {
        this.displayDeviceUnselectedMessage(webRtcLimitUser.id, webRtcLimitUser.stat);
    }

    /**
     * YouTubeビデオIDを更新
     * @param floor 
     */
    receiveUpdateYouTubeData = (floor: FloorData) => {
        this.setState({
            floorData: floor, 
            youtubeUpdate: true
        })
    }

    /**
     * OtherWebsiteURLを更新
     * @param floor 
     */
    receiveUpdateOtherWebsiteData = (floor: FloorData) => {
        this.setState({floorData: floor});
    }

    displayDeviceUnselectedMessage = (userId: number, type: number) => {
        const myUser = this.myUserRef.current.getUser() as User;
        const tempMyUser = User.copyUser(myUser) as User;

        let message = "";
        if (type === 0) {
            message = "カメラとマイクが未選択のため、ビデオ通話ができません。";
        } else if (type === 1) {
            message = "マイクが未選択のため、ビデオ通話ができません。";
        } else if (type === 2) {
            message = "カメラが未選択のため、ビデオ通話できません。";
        }

        if (myUser.id === userId) {
            tempMyUser.notifyTitle = "デバイス未選択";
            tempMyUser.notifyMessage = message;
            this.myUserRef.current.setUser(tempMyUser);
            return;
        }

        let users = this.otherUsersRef.current.getUsers() as User[];
        let limitUser = users.filter(e => e.id === userId);
        if (limitUser.length !== 0) {
            tempMyUser.notifyTitle = "デバイス未選択";
            tempMyUser.notifyMessage
                = limitUser[0].displayName + "さんは" + message;
            this.myUserRef.current.setUser(tempMyUser);
        }
    }

    handleCloseMessageDialog = () => {
        const myUser = this.myUserRef.current.getUser() as User;
        const tempMyUser = User.copyUser(myUser) as User;
        tempMyUser.notifyTitle = "";
        tempMyUser.notifyMessage = "";
        this.myUserRef.current.setUser(tempMyUser);
    }

    receiveFloorObject = (floorObjectList: FloorObject[]) => {
        this.setState({
            floorObjectList: floorObjectList,
        })
    }

    /**
     * アバターメニュー受信処理
     * 自アバターと他アバターに設定する
     * @param avatarMenuDataList 
     */
    receiveAvatarMenuData = (avatarMenuDataList: AvatarMenuData[]) => {
        this.setState({
            avatarMenuDataList: avatarMenuDataList,
        })
        this.myUserRef?.current.setAvatarMenuDataList(avatarMenuDataList);
        this.otherUsersRef?.current.setAvatarMenuDataList(avatarMenuDataList);
        this.myRoom2Ref?.current.setAvatarMenuDataList(avatarMenuDataList);
    }

    receiveSeatData = (seatData: Seat[]) => {
        this.setState({
            floorSeatList: seatData,
        })
    }

    handleOpenDeviceSelect = () => {
        if (this.myUserRef.current.openDeviceSelect) {
            this.myUserRef.current.openDeviceSelect();
        }
    }

    handleOpenMicVolumeInterval = () => {
        this.setState({ openMicVolumeInterval: true });
    }

    handleCloseMicVolumeInterval = () => {
        this.setState({ openMicVolumeInterval: false });
    }

    handleMicVolumeInterval = (id: number, text: string, isPlayAudio: boolean) => {
        this.setState({ micVolumeInterval: Number(text) });
    }

    handleClickMoreNote = (isWhiteBoard: boolean) => {
        if (this.myUserRef.current.handleClickMoreNote) {
            if (this.getEnabledReconnectHelloButton()) {
                console.log("handleClickMoreNote: 再読み込み");
                // this.handleCloseMoreNoteInline();
                const myUser = this.myUserRef.current?.getUser() as User;
                // 個人でHelloを開いている場合、再読み込みさせない
                if (myUser.isWebrtcJoin) {
                    this.myUserRef.current.handleClickMoreNote(this.state.isWhiteBoard);
                }
            } else {
                console.log("handleClickMoreNote:", isWhiteBoard);
                this.myUserRef.current.handleClickMoreNote(isWhiteBoard);
                this.setState({ isWhiteBoard: isWhiteBoard });
            }
        }
        else{
            console.log("handleClickMoreNote:何もしない");
        }
    }

    setShowSetMyAvatar = () => {
        if (this.myUserRef.current.setShowSetMyAvatar) {
            this.myUserRef.current.setShowSetMyAvatar();
        }
    }
    
    handleOKMoveFloor = (floorId: number) => {
        this.setState({openMoveFloor: false});
        this.handleMoveFloor(floorId);
    }

    setClickForceExitAlert = () => {
        this.ForceExitAlertDialogRef.current?.setForceExitAlertDialogOpen(true);
    }
 
    // チャイム（バクエンドから受信）
    receiveChime = () => {
        this.playSoundFromAudioId(AudioId.Chime);
    }

    // 強制退館（バクエンドから受信）
    receiveForceExit = () => {
        this.clearScreenShare();
        this.handleSelectedSignout();
    }

    // 強制退館（バクエンドから受信）
    receiveForceExitAlert = () => {
        this.setClickForceExitAlert();
    }

    handleCloseMoveFloor = () => {
        this.setState({openMoveFloor: false});
    }

    handleClickMyRoomButton = (open: boolean) => {
        console.log("handleClickMyRoomButton " + open + this.state.myRoomUpdate);
        if(open && this.state.myRoomUpdate === true){
            this.sendGetMyRoomFloorData();
        }
        this.myRoom2Ref.current?.open(open);

        // 閉じられた時はマイルームアバターの「情報」も閉じる
        if(open === false){
            this.myRoomUserInfoCardRef.current?.setOpen(open);
        }
    }

    // マイルームのウィンドウ側から閉じられた -> FloorMenu側も状態を設定する
    handleMyRoomWindowsClose = () => {
        this.floorMenuRef.current?.setOpenMyRoom(false);

        // 閉じられた時はマイルームアバターの「情報」も閉じる
        this.myRoomUserInfoCardRef.current?.setOpen(false);
    }

    private saveReceiveMoveUsers: SaveData[] = [];
    receiveMoveUsers = (moveUserList: MoveUserData[], seatList: Seat[], userList: User[], micDataList: MicData[], loginLogoutList: string[], updateSession: { [key: string]: object }, microUpdateData: { [key: number]: { [key: string]: object } }) => {
        if (!this.isInitFloor) {
            console.log("Save receiveMoveUsers")
            let temp = new SaveData();
            temp.object1 = moveUserList;
            temp.object2 = seatList;
            temp.object3 = userList;
            temp.object4 = micDataList;
            temp.object5 = loginLogoutList;
            temp.object6 = updateSession;
            temp.object7 = microUpdateData;
            this.saveReceiveMoveUsers.push(temp);
            return;
        }
        if (this.saveReceiveMoveUsers.length > 0) {
            this.saveReceiveMoveUsers.forEach(e => {
                this.receiveMoveUsers2(e.object1 as MoveUserData[], e.object2 as Seat[], e.object3 as User[], e.object4 as MicData[], e.object5 as string[], e.object6 as { [key: string]: object }, e.object7 as { [key: number]: { [key: string]: object } });
            });
            this.saveReceiveMoveUsers = [];
        }
        this.receiveMoveUsers2(moveUserList, seatList, userList, micDataList, loginLogoutList, updateSession, microUpdateData);
    }

    receiveMoveUsers2 = (moveUserList: MoveUserData[], seatList: Seat[], userList: User[], micDataList: MicData[], loginLogoutList: string[], updateSession: { [key: string]: object }, microUpdateData: { [key: number]: { [key: string]: object } }) => {
        const logger: Logger = Logger.getInstance();
        let users = this.otherUsersRef.current?.getUsers() as User[];
        let myUser = this.myUserRef.current?.getUser() as User;
        const videoDeviceId = localStorage.getItem("videoDeviceId");
        const audioDeviceId = localStorage.getItem("audioInputDeviceId");

        if(users === undefined || users === null){
            if(logger !== undefined && logger !== null) {
                logger.info("receiveMoveUsers2 : users is null");
            } else {
                console.log("receiveMoveUsers2 : users is null");
            }
        }
        if(myUser === undefined || myUser === null){
            if(logger !== undefined && logger !== null) {
                logger.info("receiveMoveUsers2 : myUser is null");
            } else {
                console.log("receiveMoveUsers2 : myUser is null");
            }
        }

        let isChangeUsers = false;
        let isChangeUsersForZoom = false;
        let isChangeMyUser = false;
        let isWebRtcCall = myUser.webRtcCall;
        let temp = User.copyUser(myUser) as User;
        // let tempUser = [...users];

        // セッションデータ一括更新の変更内容ごとにループし、全 User に対して適用
        for (let key in updateSession) {
            let value = updateSession[key];

            let path: string[] = key.split("/");
            // console.log("users.length = " + users.length);
            for (let i = 0; i < users.length; i++) {
                // console.log("users[" + i + "] " + users[i].displayName + " ----");
                if (this.updateSessionData(path, users[i], value)) {
                    isChangeUsers = true;
                }
            }

            // console.log("myUser " + temp.displayName + " ----");
            isChangeMyUser = this.updateSessionData(path, temp, value);
        }
        for (let [sessionDataId, updateParts] of Object.entries(microUpdateData)) {
            // console.log(`sessionDataId: ${sessionDataId}, updateParts ${updateParts}`);
            let targetUserId: number = Number(sessionDataId);
            for (let [key, value] of Object.entries(updateParts)) {
                let path: string[] = key.split("/");

                if (targetUserId === temp.id) {
                    if (this.updateSessionData(path, temp, value)) {
                        isChangeMyUser = true;
                    }
                } else {
                    // 更新対象のユーザを検索
                    const index = users.findIndex(e => e.id === targetUserId);
                    if (index >= 0) {
                        const target = users[index];
                        if (this.updateSessionData(path, target, value)) {
                            isChangeUsers = true;
                            if (key === "state") isChangeUsersForZoom = true;
                        }
                    }
                }
            }
        }

        if (loginLogoutList.length !== 0) {
            loginLogoutList.forEach(e => {
                let tempLogin = JSON.parse(e) as LoginLogoutAction;
                // console.log(tempLogin.object.id)
                if (myUser.id !== tempLogin.object.id) {
                    if (tempLogin.action === "CONNECT_OTHER_USER") {
                        this.receiveConncetOtherUser(users, tempLogin.object);
                    } else if (tempLogin.action === "DISCONNECT_OTHER_USER") {
                        users = this.receiveDisconnectOtherUser(users, tempLogin.object) as User[];
                    }
                    console.log(tempLogin.action);
                    isChangeUsers = true;
                    isChangeUsersForZoom = true;
                }
            });
        }

        this.calcDecimationMoveData(moveUserList.length);
        if (moveUserList.length !== 0) {
            moveUserList.forEach(moveUser => {
                if (myUser.id === moveUser.id) {
                    temp.x = moveUser.x;
                    temp.y = moveUser.y;
                } else {
                    let target = users.filter(e => e.id === moveUser.id);
                    if (target.length !== 0) {
                        isChangeUsers = true;
                        target[0].x = moveUser.x;
                        target[0].y = moveUser.y;
                    }
                }
            })
        }

        if (micDataList.length !== 0) {
            let scrollX: number = this.floorWrapperRef.current.scrollLeft;
            let scrollY: number = this.floorWrapperRef.current.scrollTop;
            let screenX: number = window.innerWidth;
            let screenY: number = window.innerHeight;
            // console.log(scrollX + " : " + scrollY + " : " + screenX + " : " + screenY);
            let drawSx = scrollX;
            let drawEx = scrollX + screenX;
            let drawSy = scrollY;
            let drawEy = scrollY + screenY;
            micDataList.forEach(micData => {
                if (myUser.id === micData.id) {
                    temp.micVolume = micData.micVolume;
                    isChangeMyUser = true;
                } else {
                    let target = users.filter(e => e.id === micData.id);
                    if (target.length !== 0) {
                        // console.log(target[0]);
                        if (micData.micVolume < this.state.floorData.micVolumeThreshold) {
                            // マイクボリュームの吹き出しを取り下げる処理は必ず処理しないと、出たままになる
                            // 現在のマイクボリューム値が設定を上回っている（=表示している）場合のみ更新する
                            if(!(target[0].micVolume < this.state.floorData.micVolumeThreshold)) {
                                isChangeUsers = true;
                                target[0].micVolume = micData.micVolume;
                            }
                        } else {
                            // 自アバターの周辺の他アバターのみ更新する
                            if(drawSx < target[0].x && target[0].x < drawEx) {
                                if(drawSy < target[0].y && target[0].y < drawEy) {
                                    isChangeUsers = true;
                                    target[0].micVolume = micData.micVolume;
                                }
                            }
                        }
                    }
                }
            })
        }

        let tempSeatList = [...this.state.floorSeatList];
        if (userList.length !== 0) {
            userList.forEach(user => {
                // console.log(userList)
                //console.log("receive: name=["+user.displayName+"] sts=["+user.wrStartConfirmStatus+"]")
                if (myUser.id === user.id) {
                    // ビデオ通話前の確認
                    if(user.wrStartConfirmStatus === 91 || user.wrStartConfirmStatus === 100) {
                        // 確認NGかタイムアウトの配信受信（自分で送付した）したら状態変更
                        console.log("##### end recv wrStartConfirmStatus=["+user.wrStartConfirmStatus+"]");
                        temp = User.copyUser(user);
                        temp.wrStartConfirmStatus = 0;
                        temp.webRtcRoomId = "";
                        if (user.isTrafficLimitVideoMute && this.myDeviceNoSelect === 0){
                            // カメラ使用制限中でキャンセルの場合はミュート状態を戻す
                            temp.isVideoMute = false;
                            this.sendUserAVMute(temp);
                        }
                        this.myUserRef.current.setUser(temp);
                        this.sendWrStartConfirmStatus(temp);
                        // ぶつかられた時の通知ダイアログを閉じる
                        if (this.notifyWindow) {
                            this.notifyWindow.close();
                            this.notifyWindow = null;
                        }
                        return;
                    } else {
                        // ビデオ通話前の確認ダイアログを出すかチェック
                        let openWindow = this.checkWrStartingConfirm(user, myUser);
                        if(openWindow == 1) {
                            // ビデオ通話前の確認ダイアログを出す
                            // 確認結果は、handleStartConfirmResult で処理
                            this.openWrStartingConfirm(user);
                            return;
                        } else if(openWindow === 2) {
                            logger.info("[WR_TRACE] ビデオ通話前の確認中に相手が離れた");
                            user.wrStartConfirmStatus = 999;
                            user.hittedUser = "";
                            user.hittedUserId = 0;
                            this.hittedonDrag = false;  // #809 ドラッグ中に話しかけられたか
                            this.sendWrStartConfirmStatus(user);
                        }
                    }

                    isChangeMyUser = true;
                    temp = User.copyUser(user);
                    // WebRtcの接続処理
                    temp.webRtcCall = this.connectingWebRtc(user, isWebRtcCall);

                    // #3744 peerId取得できないことがある対応
                    if(myUser.webRtcCall !== true && temp.webRtcCall === true) {
                        let newPeerId = WebrtcService.getPeerIdSync();
                        if(newPeerId !== undefined && newPeerId !== "") {
                            logger.info("[WR_PEER_TRACE] floor.receiveMoveUsers2 GetAndSet peerId OK newPeerId=["+newPeerId+"] myUser.webRtcPeerId=["+myUser.webRtcPeerId+"]");
                            if (myUser.webRtcPeerId !== newPeerId) {
                                logger.info("[WR_PEER_TRACE] floor.receiveMoveUsers2 GetAndSet newPeerId=["+newPeerId+"] myUser.webRtcPeerId=["+myUser.webRtcPeerId+"]");
                                temp.webRtcPeerId = newPeerId;
                                this.sendWebRtcPeerId(temp);
                            }
                        } else {
                            // 表示
                            logger.info("[WR_PEER_TRACE] floor.receiveMoveUsers2 GetAndSet peerId error newPeerId=["+newPeerId+"] myUser.webRtcPeerId=["+myUser.webRtcPeerId+"]");
                        }
                    }

                    // webrtc接続リトライ対応 終了時チェックタイマークリア
                    if(temp.webRtcCall === false) {
                        if(this.watchJoinRoomStatusTimerId !== 0) {
                            let nTime = new Date().getTime();
                            logger.info("reset My-R-Timer : name=["+myUser.displayName+"] roomId="+myUser.webRtcRoomId+"] nTime=["+nTime+"]");
                            clearTimeout(this.watchJoinRoomStatusTimerId);
                            this.watchJoinRoomStatusTimerId = 0;
                        }
                        this.webrtcJoinRetryCounter = 0;
                        this.webrtcJoinStatusEvent = "";
                    }
    
                    // WebRTC通話終了時、または、会議室入室時にアプリ内通知ウィンドウを閉じる。
                    if (this.notifyWindow && (user.webRtcRoomId === "" || !user.webRtcRoomId.includes("-"))) {
                        this.notifyWindow.close();
                        this.notifyWindow = null;
                    }

                    // WebRTC通話終了時に通話中にミュートにされた状態を解除する。
                    // ただし、プライバシーモード、強制ミュートモードは除く。
                    if ((myUser.webRtcRoomId !== user.webRtcRoomId || temp.webRtcCall === false)
                        && (myUser.isAudioMute || myUser.isVideoMute) && !myUser.isForceMute) {
                        // マイク未選択時の表示対応
                        if(this.myDeviceNoSelect === 1 && this.myMicDeviceNoSelect === 1) {
                            console.log("VideoLabel : カメラとマイク未選択なので、ミュート状態変更しない ["+myUser.displayName+"]")
                            //temp.isAudioMute = myUser.isAudioMute;
                            //temp.isVideoMute = myUser.isVideoMute;
                            //this.sendUserAVMute(temp);
                        } else if(this.myDeviceNoSelect === 1 && this.myMicDeviceNoSelect === 0){
                            if(myUser.isAudioMute === true) {
                                // カメラ未選択の場合はマイクミュート状態を解除する
                                console.log("VideoLabel : カメラ未選択なので、マイクミュート解除のみ ["+myUser.displayName+"]");
                                temp.isAudioMute = false;
                                temp.isVideoMute = myUser.isVideoMute;
                                this.sendUserAVMute(temp);
                            }
                        } else if(this.myDeviceNoSelect === 0 && this.myMicDeviceNoSelect === 1){
                            console.log("VideoLabel : マイク未選択なので、ミュート状態変更しない ["+myUser.displayName+"]")
                            if(myUser.isVideoMute === true) {
                                console.log("VideoLabel : Videoミュート解除のため状態更新を送る ["+myUser.displayName+"]")
                                temp.isAudioMute = myUser.isAudioMute;
                                temp.isVideoMute = false;
                                this.sendUserAVMute(temp);
                            }
                        } else if (myUser.isTrafficLimitVideoMute && temp.webRtcCall){
                            // カメラ使用制限中で違う会議室へ移動するときはミュート状態を維持する
                            console.log("VideoLabel : カメラ使用制限中のため、ビデオのミュートを継続する ["+myUser.displayName+"]")
                            temp.isAudioMute = false;
                            temp.isVideoMute = true;
                            this.sendUserAVMute(temp);
                        } else {
                            // 上記以外の場合はミュート解除する
                            temp.isAudioMute = false;
                            temp.isVideoMute = false;
                            this.sendUserAVMute(temp);
                        }
                    } else if((this.myDeviceNoSelect === 1 && myUser.isVideoMute === false) || (this.myMicDeviceNoSelect === 1 && myUser.isAudioMute === false)) {
                        if(this.myDeviceNoSelect === 1 && this.myMicDeviceNoSelect === 1) {
                            console.log("VideoLabel : ビデオとマイク選択なしでミュート状態ではないのでミュートを送る ["+myUser.displayName+"]")
                            temp.isAudioMute = true;
                            temp.isVideoMute = true;
                        } else if(this.myDeviceNoSelect === 1 && this.myMicDeviceNoSelect === 0){
                            console.log("VideoLabel : ビデオ選択なしでミュート状態ではないのでミュートを送る ["+myUser.displayName+"]")
                            temp.isAudioMute = myUser.isAudioMute;
                            temp.isVideoMute = true;
                        } else if(this.myDeviceNoSelect === 0 && this.myMicDeviceNoSelect === 1){
                            console.log("VideoLabel : マイク選択なしでミュート状態ではないのでミュートを送る ["+myUser.displayName+"]")
                            temp.isAudioMute = true;
                            temp.isVideoMute = myUser.isVideoMute;
                        }
                        this.sendUserAVMute(temp);
                    }

                    // MyUserのwebRtcRoomId変更時に、MyUserと異なるwebRtcRoomIdとなった
                    // OtherUserのwebRtcCallをfalseにする。
                    if (myUser.webRtcRoomId !== user.webRtcRoomId) {
                        isChangeUsers = true;
                        users = users.map(e => {
                            return {
                                ...e,
                                webRtcCall: user.webRtcRoomId !== "" && user.webRtcRoomId === e.webRtcRoomId,
                            }
                        })
                    }

                    // webRtcRoomIdが同じでも再接続要求が来た場合、MyUserのwebrtcCallをfalseにする
                    if (myUser.webRtcRoomId === user.webRtcRoomId && user.isReconnectWebRtc) {
                        myUser.webRtcCall = false;
                        this.sendChangeReconnectStatus(false);
                    }

                    // 同じWebRtcに参加する他のユーザのステータスを変更する
                    //console.log('##############1', user.webRtcRoomId, myUser.webRtcRoomId, myUser.webRtcRoomId, myUser.displayName);
                    users.forEach(e => {
                        // webRtcで同じroomにいるユーザを対象に処理を行う
                        const isWebRtcCall = user.webRtcRoomId !== "" && user.webRtcRoomId === e.webRtcRoomId;
                        if(isWebRtcCall && (myUser.isTrafficLimitVideoMute !== e.isTrafficLimitVideoMute)){
                            // 対象のユーザ情報を取得
                            const index = users.findIndex(x => x.id === e.id);
                            const target = users[index];
                            const tempOtherUser = {} as User;
                            User.copyUserToUser(tempOtherUser, e);
                            // 通信制限中状態を更新する
                            tempOtherUser.isTrafficLimitVideoMute = myUser.isTrafficLimitVideoMute;
                            if(tempOtherUser.isTrafficLimitVideoMute){
                                // 通信制限中の場合は、ミュートも設定する
                                tempOtherUser.isVideoMute = true;
                            }
                            User.copyUserToUser(target, tempOtherUser);
                            // 変更したことを記録
                            isChangeUsers = true;
                        }
                    });

                    // WebRtc開始時、または再接続時に通知を行う。また、MyUserのwaitingを設定する。
                    if ((!isWebRtcCall && temp.webRtcCall)
                        || (temp.webRtcCall && (myUser.webRtcRoomId !== user.webRtcRoomId))
                        || (myUser.webRtcRoomId === user.webRtcRoomId && user.isReconnectWebRtc)) {
                        this.webRtcNotification(user);

                        // MyUser
                        temp.isMediaWaiting = videoDeviceId !== "" || audioDeviceId !== "";
                        if (temp.isMediaWaiting) {
                            console.log("effect_check-1 status=["+temp.wrStartConfirmStatus+"] name=["+temp.displayName+"] myUser.id=["+myUser.id+"] hited=["+user.hittedUserId+"] tmotVal=["+this.waitingTime+"]");
                            setTimeout(() => this.setIsWaitingOfMyUser(false), this.waitingTime);// 指定時間後にwaitingを解除
                            logger.info("[WR_TRACE] 自分のエフェクトタイムアウトを設定 : timer=["+this.waitingTime+"] name=["+temp.displayName+"] roomId="+user.webRtcRoomId+"]");
                        } else {
                            if(myUser.isMediaWaiting === true) {
                                logger.info("[WR_TRACE] 自分のエフェクト解除0 : name=["+temp.displayName+"] roomId="+temp.webRtcRoomId+"] pre_waiting=["+myUser.isMediaWaiting+"]");
                            }
                        }
                        // OtherUsers
                        isChangeUsers = true;
                        users = users.map(e => {
                            const isWebRtcCall = user.webRtcRoomId !== "" && user.webRtcRoomId === e.webRtcRoomId;
                            // ここでは不要？（二重でeffectかけている）--> 必要
                            // ぶつけた時に相手のeffectタイムアウトを上書きすることがあるので、タイムアウト値を補正（短い方でタイムアウトする）
                            //   --> すでにwating中ならタイマー設定しない
                            let timerId = e.waitingTimerId;
                            let tmotVal = this.waitingTime;
                            if(e.isVideoNoSelect === true) {
                                // カメラなしの人が多いとログ出すぎるので削除
                                //logger.info("[WR_TRACE] 相手のカメラ未選択1 : name=["+e.displayName+"]");
                                tmotVal = 1000;
                            }
                            if(e.isMediaWaiting === true) {
                                tmotVal = (this.getwrStartConfirmWaitTime() * 1000);
                            } else {
                                if (isWebRtcCall) {
                                    let nTime = new Date();
                                    let nTimeStr = nTime.getHours()+":"+nTime.getMinutes()+":"+nTime.getSeconds()+"."+nTime.getMilliseconds();
                                    console.log(nTimeStr + " effect_check-2 status=["+temp.wrStartConfirmStatus+"] name=["+temp.displayName+"] myUser.id=["+myUser.id+"] hited=["+user.hittedUserId+"] tmotVal=["+tmotVal+"] e.name=["+e.displayName+"] e.id=["+e.id+"] e.hit={"+e.hittedUserId+"] user.webRtcRoomId=["+user.webRtcRoomId+"] e.webRtcRoomId=["+e.webRtcRoomId+"] e.isMediaWaiting=["+e.isMediaWaiting+"]");
                                    //timerId = setTimeout(() => this.setIsWaitingOfOtherUser(e.id, false), tmotVal)
                                    timerId = window.setTimeout(() => this.setIsWaitingOfOtherUser(e.id, false), tmotVal)
                                    console.log(nTimeStr + " effect_check-2 timerId=["+timerId+"]");
                                    logger.info("[WR_TRACE] 他人のエフェクトタイムアウトを設定 : timer=["+tmotVal+"] name=["+e.displayName+"] roomId="+user.webRtcRoomId+"] myName=["+user.displayName+"] timerId=["+timerId+"]");
                                }
                            }
                            return {
                                ...e,
                                isMediaWaiting: isWebRtcCall,
                                webRtcCall: isWebRtcCall,
                                waitingTimerId: timerId,
                            }
                        })
                    }

                    if (user.seat && user.seat.isSit) {
                        this.setHitEffectIdOfMyUser(AvatarEffectType.Sitting);
                    }

                    // フロアから Zoom 開始部屋外の席へ着席時、Zoom を mute にする
                    if (user.seat && myUser.seat !== user.seat &&
                        user.seat.isSit && myUser.seat?.isSit !== user.seat.isSit &&
                        !user.isZoomSpeakable) {
                        this.muteZoom(true);
                    }

                    // 相手が画面共有中で、自分がミートを継続しながらドラッグ移動した場合
                    if (myUser.isScreenSharing && user.isScreenSharing && user.screenShareSessionId === "") {
                        temp.screenShareSessionId = `${myUser.screenShareSessionId}`;
                    }

                    // 画面共有を実施していない場合、FloorMenu内のstateをfalseにする
                    if (myUser.isScreenShare && !user.isScreenShare ) {
                        this.floorMenuRef.current?.setScreenShare(false);
                    }

                    // Zoom の mic, camera ON/OFF切替
                    if (this.state.floorData.zoomInfo?.id && myUser.isZoomSpeakable !== user.isZoomSpeakable) {
                        this.handleZoomSpeak(user.isZoomSpeakable);
                        this.muteZoom(!user.isZoomSpeakable);
                        this.floorMenuRef.current?.setZoomSpeakable(user.isZoomSpeakable);
                        this.props.enqueueSnackbar(`あなたのZoomのマイクとカメラが${user.isZoomSpeakable ? 'ON' : 'OFF'}になりました。`);
                    }

                    temp.micVolume = user.micVolume;
                    temp.micMode = this.state !== null ? this.state.floorData.micMode : 0;
                    temp.micVolumeThreshold = this.state !== null ? this.state.floorData.micVolumeThreshold : 25;
                    temp.webRtcMode = this.state !== null ? this.state.floorData.webRtcMode : 0;
                    temp.webRtcInfoInterval = this.state !== null ? this.state.floorData.webRtcInfoInterval : 60;
                } else {
                    isChangeUsers = true;
                    // ・newMyUser : 最新のmyUserデータ
                    // ・target    : 移動前のotherUserデータ（<= コレに対して、データを更新する）
                    // ・user      : 移動後のotherUserデータ
                    // ・tempOtherUser : otherUserデータ更新用の一時的オブジェクト
                    const newMyUser = userList.find(e => e.id === myUser.id) || temp;
                    const index = users.findIndex(e => e.id === user.id);
                    const target = users[index];
                    const tempOtherUser = {} as User;
                    User.copyUserToUser(tempOtherUser, user);

                    if (target) {
                        target.webRtcCall = target.webRtcRoomId !== "" && target.webRtcRoomId === myUser.webRtcRoomId;
                        user.webRtcCall = user.webRtcRoomId !== "" && user.webRtcRoomId === newMyUser.webRtcRoomId;
                        tempOtherUser.webRtcCall = user.webRtcCall;
                        tempOtherUser.isMediaWaiting = false;
                        let isChangeRoomId = target.webRtcRoomId !== user.webRtcRoomId;   // RoomIdが変更されたか


                        if (
                            target.webRtcRoomId !== user.webRtcRoomId ||
                            target.isZoomSpeakable !== user.isZoomSpeakable || 
                            target.zoomMeetingId !== user.zoomMeetingId
                        ) {
                            isChangeUsersForZoom = true;
                        }

                        // 通常アバターからマルチアバターへ切り替わった際に、isZoomSpeakable を false にする
                        if ((target.isStayFloor !== user.isStayFloor) && !user.isStayFloor) {
                            this.sendZoomSpeakable(user.id, false);
                        } 

                        // 対象のユーザがRoomを移動した場合
                        //console.log('##############2', user.webRtcRoomId, target.webRtcRoomId);
                        if(user.webRtcRoomId !== target.webRtcRoomId) {
                            // 同じWebRtcに参加する他のユーザのステータスを変更する
                            users.forEach(e => {
                                // 移動前が同じRoomのユーザ
                                if(target.webRtcRoomId === e.webRtcRoomId){
                                    // 何もしない（移動後は同じ会議ではない）
                                }
                                // 移動後が同じRoomのユーザ
                                else if ( (user.webRtcRoomId !== "") && (user.webRtcRoomId === e.webRtcRoomId) ){
                                    // 通信制限中状態が異なる場合
                                    if(user.isTrafficLimitVideoMute !== e.isTrafficLimitVideoMute){
                                        // 対象ユーザの情報を取得
                                        const other_index = users.findIndex(x => x.id === e.id);
                                        const other_target = users[other_index];
                                        const tempOtherUser_local = {} as User;
                                        User.copyUserToUser(tempOtherUser_local, e);

                                        // 通信制限中状態を更新する
                                        tempOtherUser_local.isTrafficLimitVideoMute = user.isTrafficLimitVideoMute;
                                        if(tempOtherUser_local.isTrafficLimitVideoMute){
                                            // 通信制限中の場合、ミュートも設定する
                                            tempOtherUser_local.isVideoMute = true;
                                        }
                                        User.copyUserToUser(other_target, tempOtherUser_local);
                                        // 変更したことを記録する
                                        isChangeUsers = true;
                                    }
                                }
                            });

                            // 自身もwebRtcに参加している場合、ステータスを更新する
                            //console.log('##############3', user.webRtcRoomId, target.webRtcRoomId, myUser.webRtcRoomId, target.displayName);
                            // 移動前が同じRoomのだった場合
                            if(target.webRtcRoomId === myUser.webRtcRoomId){
                                // 何もしない（移動後は同じ会議ではない）
                            }
                            // 移動後が同じRoomだった場合
                            else if ( (user.webRtcRoomId !== "") && (user.webRtcRoomId === myUser.webRtcRoomId) ){
                                if(user.isTrafficLimitVideoMute !== myUser.isTrafficLimitVideoMute){
                                    temp = User.copyUser(myUser);
                                    // 通信制限中状態を更新する
                                    temp.isTrafficLimitVideoMute = user.isTrafficLimitVideoMute;
                                    if(temp.isTrafficLimitVideoMute){
                                        // 通信制限中の場合、ミュートも設定する
                                        temp.isVideoMute = true;
                                    }
                                    // 変更したことを記録する
                                    isChangeMyUser = true;
                                }     
                            }
                        }
 
                        // WebRtc開始時、または再接続時にotherUserのwaitingを設定する。
                        if ((!target.webRtcCall && user.webRtcCall) || (user.webRtcCall && (target.webRtcRoomId !== user.webRtcRoomId))
                            || (myUser.webRtcRoomId === target.webRtcRoomId && target.webRtcRoomId === user.webRtcRoomId && user.isReconnectWebRtc)) {
                            tempOtherUser.isMediaWaiting = videoDeviceId !== "" || audioDeviceId !== "";
                            // ビデオ通話前の確認中かもしれないので、タイムアウトを変更
                            let tmotVal = this.waitingTime;
                            if(tempOtherUser.isVideoNoSelect === true) {
                                logger.info("[WR_TRACE] 相手のカメラ未選択2 : name=["+tempOtherUser.displayName+"]");
                                tmotVal = 1000;
                            }
                            if(user.hittedUserId === myUser.id) {
                                tmotVal = (this.getwrStartConfirmWaitTime() * 1000);
                            }
                            console.log("effect_check-3 status=["+target.wrStartConfirmStatus+"] name=["+target.displayName+"] myUser.id=["+myUser.id+"] hited=["+user.hittedUserId+"] tmotVal=["+tmotVal+"]");
                            if (tempOtherUser.isMediaWaiting) {
                                let nTime = new Date();
                                let nTimeStr = nTime.getHours()+":"+nTime.getMinutes()+":"+nTime.getSeconds()+"."+nTime.getMilliseconds();
                                console.log(nTimeStr + " effect_check-3 status=["+target.wrStartConfirmStatus+"] name=["+target.displayName+"] myUser.id=["+myUser.id+"] hited=["+user.hittedUserId+"] tmotVal=["+tmotVal+"]");
                                //tempOtherUser.waitingTimerId = setTimeout(() => this.setIsWaitingOfOtherUser(tempOtherUser.id, false), tmotVal)
                                tempOtherUser.waitingTimerId = window.setTimeout(() => this.setIsWaitingOfOtherUser(tempOtherUser.id, false), tmotVal)
                                console.log(nTimeStr + " effect_check-3 timerId=["+tempOtherUser.waitingTimerId+"]");
                                logger.info("[WR_TRACE] 他人のエフェクトタイムアウトを設定 other : timer=["+tmotVal+"] name=["+tempOtherUser.displayName+"] roomId="+user.webRtcRoomId+"] myName=["+user.displayName+"] timerId=["+tempOtherUser.waitingTimerId+"]");
                            }
                        }
                        // otherUserのwaitingがオフでオフタイマーが残っていたら解除する
                        if(tempOtherUser.isMediaWaiting === false && target.waitingTimerId !== undefined && target.waitingTimerId !== 0) {
                            let nTime = new Date();
                            let nTimeStr = nTime.getHours()+":"+nTime.getMinutes()+":"+nTime.getSeconds()+"."+nTime.getMilliseconds();
                            if(target.webRtcRoomId.indexOf("-") !== -1) {
                                console.log(nTimeStr + " effect_check-4 clearTimeout id=["+target.waitingTimerId+"] name=["+target.displayName+"]["+tempOtherUser.displayName+"] room=["+target.webRtcRoomId+"]");
                                clearTimeout(target.waitingTimerId);
                            }
                            tempOtherUser.waitingTimerId = 0;
                        }
                        User.copyUserToUser(target, tempOtherUser);
                        if (user.webRtcPeerId !== "") {
                            WebrtcService.addUserDataList(user);
                            // console.log("addUserDataList " + user.id + " / " + user.webRtcPeerId);
                        }
                        // 自分と同一のRommId でWebRTC を開始、
                        // かつRoomIdが変更されたり、再接続を行ったユーザがいる
                        if (user.webRtcCall && myUser.webRtcRoomId === user.webRtcRoomId && (isChangeRoomId || user.isReconnectWebRtc)) {
                            // 音を鳴らす
                            // iPad対応-11 話しかけられた時二重になってるので片方は不要では？
                            // －－＞ ３人会話の時など、音の出し方整理が必要なので、現状仕様を踏襲
                            logger.info("PLAY_SOUND_TRACE MeetingStart再生 my=["+myUser.displayName+"]["+myUser.id+"] confirm=["+myUser.wrStartConfirmStatus+"] hited=["+user.hittedUser+"]["+myUser.hittedUser+"]");
                            this.playSoundFromAudioId(AudioId.MeetingStart);

                            // #369 相手にデバイスエラー表示
                            if(this.webrtcDeviceErrorDisp !== 0) {
                                logger.info("スナックバー表示要求 : my=["+myUser.displayName+"]["+myUser.id+"] roomId=["+myUser.webRtcRoomId+"] user=["+user.displayName+"]["+user.id+"]");
                                this.sendDisplayErrorSnackBarToId(this.webrtcDeviceErrorDisp, user.id);
                            }
                        }

                        // ビデオ通話前の確認がNoで、自分が重ねた人ならば、ウインド出してwebrtcをやめる
                        let tempdata = User.copyUser(myUser) as User;
                        // #502
                        //if((user.wrStartConfirmStatus === 91 || user.wrStartConfirmStatus === 100) && myUser.webRtcRoomId !== "" && user.hittedUserId === myUser.id) {
                        if((user.wrStartConfirmStatus === 91 || user.wrStartConfirmStatus === 100) && user.hittedUserId === myUser.id) {
                            //console.log("startNone: user=["+user.displayName+"] hittedUser=["+user.hittedUser+"]["+user.hittedUserId+"] my=["+myUser.displayName+"]["+myUser.id+"]")
                            tempdata.notifyTitle = "ビデオ通話";
                            let dspMsg = "さんはビデオ通話を受けることができません。";
                            if(user.wrStartConfirmStatus === 100) {
                                // タイムアウト
                                dspMsg = "さんの応答がありません。ビデオ通話を中止します。";
                            }
                            tempdata.notifyMessage = user.displayName + dspMsg;
                            logger.info("wrStartConfirmNone: user=["+user.displayName+"] hittedUser=["+user.hittedUser+"]["+user.hittedUserId+"] my=["+myUser.displayName+"]["+myUser.id+"] msg=["+tempdata.notifyMessage+"] roomId=["+myUser.webRtcRoomId+"]");
                            this.myUserRef.current.setUser(tempdata);

                            // #11313 通話が終わっているのに、アバターが大きいままになることがある
                            //tempdata.isWebrtcJoin = false;
                            //console.log("sendWrJoinStatus false  call =["+tempdata.displayName+"]");
                            //this.sendWrJoinStatus(tempdata);
                        }
                    }
                }
            })
        }
        let isSeatUpdate = this.receiveMoveUsersSeatProcessing(tempSeatList, seatList);

        if (isChangeMyUser || isSeatUpdate) {
            this.setState({
                floorSeatList: isSeatUpdate ? tempSeatList : this.state.floorSeatList,
            })
        }
        if (isChangeMyUser) {
            this.myUserRef.current.setUser(temp);
        }
        if (isChangeUsers) {
            this.otherUsersRef.current.setUsers(users);
            this.floorUsersAmountRef.current?.setData(users.length + 1);
            if (isChangeUsersForZoom) {
                const list = users.map(({ id, displayName, state, webRtcRoomId, stayObjectId, isZoomSpeakable, zoomMeetingId }) =>
                    ({ id, displayName, state, webRtcRoomId, stayObjectId, isZoomSpeakable, zoomMeetingId }));
                console.log("[receiveMoveUsers2] otherUsers for UsersControl", list);
                this.setDataToUsersControl(users);
            }
        }

        // プライバシールームのギミック ON/OFF切替
        this.privacyRoomListRef.forEach(ref => {
            ref.current?.checkGimmickState();
        });
    }

    // 指定の User インスタンスに、セッションデータ一括変更の内容を設定する
    updateSessionData = (path: string[], data: object, value: object): boolean => {
        let target: object = data;
        let isChange = false;

        for (let i = 0; i < path.length; i++) {
            let keyword = path[i];
            let mapKey = null;

            let keyPos = keyword.indexOf('[');
            if (keyPos >= 0) {
                mapKey = keyword.substring(keyPos + 1);
                keyword = keyword.substring(0, keyPos);
            }

            if (Reflect.has(target, keyword)) {
                isChange = true;
                if (i === path.length - 1) {
                    if (mapKey !== null) {
                        // console.log("  <" + keyword + ">[" + mapKey + "] = " + value);
                        target = Reflect.get(target, keyword);
                        let map = target as { [key: string]: object };
                        map[mapKey] = value;
                    } else {
                        // console.log("  <" + keyword + "> = " + value);
                        Reflect.set(target, keyword, value);
                    }

                } else {
                    // console.log("  <" + keyword + ">");
                    target = Reflect.get(target, keyword);
                    if (mapKey !== null) {
                        target = (target as { [key: string]: object })[mapKey];
                    }
                }
            } else {
                console.error("  User から <" + keyword + "> が見つからない");
            }
        }
        return isChange;
    }

    receiveMoveUsersSeatProcessing = (tempSeatList: Seat[], receiveSeatList: Seat[]) => {
        let isUpdateSeat = false;
        receiveSeatList.forEach(seat => {
            let temp = tempSeatList.filter(e => e.floorObjectId === seat.floorObjectId && e.seatNo === seat.seatNo);
            if (temp.length !== 0) {
                temp[0].viewText = seat.viewText;
                temp[0].viewText2 = seat.viewText2;
                temp[0].isViewName = seat.isViewName;
                temp[0].myNameplate = seat.myNameplate;
                temp[0].myNameplateSignOut = seat.myNameplateSignOut;
                isUpdateSeat = true;
            }
        })
        return isUpdateSeat;
    }

    connectingWebRtc = (user: User, isWebRtcCall: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        if (user.webRtcRoomId !== "") {
            if (this.isWebRtcConnecting === false) {
                console.log("WebRTC Roomへの接続を行う " + user.webRtcRoomId);
                this.isWebRtcConnecting = true;
                // #851 調査用ログ
                const logger: Logger = Logger.getInstance();
                logger.info("connectingWebRtc this.isWebRtcConnecting set true : ["+user.displayName+"] room=["+user.webRtcRoomId+"]");
                this.setState({
                    visibleWebRtcBroadCastButton: this.visibleWebRtcBroadCastButton(user.webRtcMode, user.id, user.webRtcRoomId),
                    visibleWebRtcBroadCastStopButton: this.visibleWebRtcBroadCastStopButton(user.webRtcMode, user.id, user.webRtcRoomId),
                });
                return true;
            } else {
                console.log("WebRTC通話中に全体放送が開始されたか？ [" + user.webRtcRoomId + "] join=[" + user.isWebrtcJoin + "] : " + myUser.displayName);
                // WebRTC通話中に全体放送が開始された場合とその逆の場合の処理
                this.setState({
                    visibleWebRtcBroadCastButton: this.visibleWebRtcBroadCastButton(user.webRtcMode, user.id, user.webRtcRoomId),
                    visibleWebRtcBroadCastStopButton: this.visibleWebRtcBroadCastStopButton(user.webRtcMode, user.id, user.webRtcRoomId),
                });
            }
        } else {
            if (this.isWebRtcConnecting) {
                console.log("WebRTC Roomからの切断を行う " + myUser.webRtcRoomId);
                this.setState({
                    visibleScreenShareButton: false,
                    visibleWebRtcBroadCastButton: this.visibleWebRtcBroadCastButton(user.webRtcMode, user.id, user.webRtcRoomId),
                    visibleWebRtcBroadCastStopButton: this.visibleWebRtcBroadCastStopButton(user.webRtcMode, user.id, user.webRtcRoomId),
                });

                // 周りの人からの見た目を戻す
                myUser.isWebrtcJoin = false;
                console.log("sendWrJoinStatus call isWebrtcJoin=["+myUser.isWebrtcJoin+"]");
                this.sendWrJoinStatus(myUser);

                // 切断を行なったユーザーが画面共有者だった場合、共有解除（被共有者のときは解除しない）
                if (myUser.isScreenShare && !user.isScreenShare) {
                    this.handleScreenShare(false, ScreenShareMode.FullScreen, myUser.webRtcRoomId);
                }

                // 切断時にnoSleepが切られる(webrtcの停止に引っ張られている？)
                // もう一度開始してみる
                if (WebrtcService.isIOSDevice()){
                    /* iPad対応-3 NoSleepWrapperへ移動
                    this.noSleep.enable();
                    const logger: Logger = Logger.getInstance();
                    logger.info("NOSLEEP : connectingWebRtc set enable : ["+this.noSleep.isEnabled+"]");
                    */
                    NoSleepWrapper.enableNoSleep();
                }
                
                this.isWebRtcConnecting = false;
                // #851 調査用ログ
                const logger: Logger = Logger.getInstance();
                logger.info("connectingWebRtc this.isWebRtcConnecting set false : ["+user.displayName+"] room=["+myUser.webRtcRoomId+"]");
                return false;
            } else {
                console.log("すでにRoomからの切断されている？ [" + user.webRtcRoomId + "]");
                // 周りの人からの見た目を戻す
                if(this.getPurposeOfUse() === this.PURPOSE_OF_USE_OFFICE) {
                    if(myUser !== undefined && myUser !== null) {
                        if(myUser.isWebrtcJoin === true) {
                            myUser.isWebrtcJoin = false;
                            console.log("sendWrJoinStatus call isWebrtcJoin=["+myUser.isWebrtcJoin+"]");
                            this.sendWrJoinStatus(myUser);
                        }
                    } else {
                        const logger: Logger = Logger.getInstance();
                        logger.info("connectingWebRtc myUser is null")
                    }
                    // #851
                    if(isWebRtcCall === true) {
                        const logger: Logger = Logger.getInstance();
                        logger.info("connectingWebRtc this.isWebRtcConnecting is false and false return  now webrtc : ["+isWebRtcCall+"]");
                        return false;
                    }
                }
            }
        }
        return isWebRtcCall;
    }

    receiveUpdateOneSession = (updateSession: { [key: string]: object }) => {
        // console.log(object);

        let myUser = this.myUserRef.current.getUser() as User;
        let tempMyUser = User.copyUser(myUser) as User;

        let isUpdate: boolean = false;
        for (let key in updateSession) {
            let value = updateSession[key];
            let path: string[] = key.split("/");
            if (this.updateSessionData(path, tempMyUser, value)) {
                isUpdate = true;
            }
        }
        if (isUpdate) {
            this.myUserRef.current.setUser(tempMyUser);
            
            // 1:1テレミート中にミートされたときの通知
            if (tempMyUser.hittedUser !== "") this.webRtcNotification(tempMyUser);
            // メモ設定情報更新
            if (myUser.isMemoNotify !== tempMyUser.isMemoNotify)
                this.memoComponentRef.current.setMemoSetting({ isNotify: tempMyUser.isMemoNotify });
            // 未読通知バッジの更新
            if (myUser.unreadMemoCount !== tempMyUser.unreadMemoCount)
                this.setUnreadMemoCount(tempMyUser.unreadMemoCount);
        }
    }

    receiveMyUser = (user: User) => {
        this.myUserRef.current.setUser(user);
        this.myUserRef.current.setFoorId(this.floorId);
        this.setUnreadMemoCount(user.unreadMemoCount);
        this.tenantLauncherRef.current.show();

        this.handleMyStudyTimerState(true,0); // 一度タイマー再開状態にして、その後下記の命令で止める
        this.handleMyStudyTimerState(false,0); // サインイン時、フロア移動時、リロード時はひとまず必ずタイマーをストップ状態にする
        if (user.mySeat != null) {
            // サインイン時に自席が存在する　→　自習開始ダイアログは出さない
            this.isSigninMySeat = true;
            this.isOpenStudyDialog = false;

            if (user.isStudy) { // サインイン時、フロア移動時、リロード時に自席があり、未完了の自習データがある場合
                // サインイン時の集中モード取得
                this.httpClient.getStudyPlan(sessionStorage.getItem("TABID") as string)
                    .then((e: StudyPlanDetail[]) => {
                        const latestPlan = e[e.length - 1];
                        this.isConcentration = latestPlan.studyPlan.concentration;
                    }).catch(err => {
                        console.log(err);
                    });
                this.isStudy = user.isStudy;
                this.isSigninMySeatWithNotCompletedStudy = true;
            }
            this.isSelfStudyTable = false;          // 復帰後は自習室には着席しない
        } else {
            // サインイン時、フロア移動時、リロード時に自席がない
            if(user.isStudy) { // 自習中のフラグが立っている
                if (user.studyTime !== -1) {
                    this.handleStudyTimerStart(user.studyElapsedTime*60,user.studyTime*60);
                } else {
                    this.handleStudyTimerStart(user.studyElapsedTime*60,user.studyTime);
                }
                // サインイン時の集中モード取得
                this.httpClient.getStudyPlan(sessionStorage.getItem("TABID") as string)
                    .then((e: StudyPlanDetail[]) => {
                        const latestPlan = e[e.length - 1];
                        this.isConcentration = latestPlan.studyPlan.concentration;
                    }).catch(err => {
                        console.log(err);
                    });
                this.isStudy = user.isStudy;
            }
            this.isSelfStudyTable = false;          // 復帰後は自習室には着席しない
        }

        
    }

    receiveClickCharacterChange = (id:number) => {
        this.characterObjectRef.current.clickCharacterChange(id);
    }

    receiveCharacterSetting = () => {
        this.characterObjectRef.current.characterSetting();
    }

    receiveUpdateStudyObject = (studyObjectSetting: StudyObjectSetting) => {
        this.setState({
            updateStudyObjectSetting: studyObjectSetting
        })
    }

    /**
     * ユーザの入室処理
     * @param users 
     * @param user 
     */
     receiveConncetOtherUser = (users: User[], user: User) => {
        const newOtherUser = User.copyUser(user);
        let temp;
        if (this.state === undefined || users === undefined) {
            temp = [] as User[];
        } else {
            temp = [...users];
        }
        const filterUsers = temp.filter(e => e.id === user.id);
        if (filterUsers.length === 1) {
            User.copyUserToUser(filterUsers[0], user);
            // #11726
            //this.props.enqueueSnackbar(`${user.displayName}さんが戻りました。`);
        } else {
            users.push(newOtherUser);
            // #11726
            //this.props.enqueueSnackbar(`${user.displayName}さんが入室しました。`);
        }
    }

    /**
     * ユーザの退室処理
     * @param users 
     * @param user 
     */
    receiveDisconnectOtherUser = (users: User[], user: User) => {
        let temp = users.filter((e: User) => e.id !== user.id);
        if (temp === null) return;
        // #11726
        //this.props.enqueueSnackbar(`${user.displayName}さんが退室しました。`);
        return temp;
    }

    private isInitFloor: boolean = false;
    receiveOtherUsers = (otherUsers: User[]) => {
        let target = {} as User | undefined;
        let tempUsers = [...otherUsers];

        // 通常のフロア移動後
        if (this.subIdOfGoToSeeTarget === null) {
            otherUsers.forEach(user => {
                if (user.webRtcPeerId !== "") {
                    WebrtcService.addUserDataList(user);
                }
            });
        // 「会いに行く」でのフロア移動後
        } else {
            target = otherUsers.find(user => user.subId === this.subIdOfGoToSeeTarget && user.isStayFloor);
            if (target) {
                // OtherUsers animation change
                tempUsers = otherUsers.map(user => {
                    if (user.webRtcPeerId !== "") {
                        WebrtcService.addUserDataList(user);
                    }
                    if (user.subId === this.subIdOfGoToSeeTarget) {
                        setTimeout(() => this.setIsGoToSeeOfOtherUser(user.id, false), this.waitingTime);
                        return { ...user, isGoToSee: true }
                    } else {
                        return user
                    }
                });
            }
        }

        // Websocketをつないだ人に届く他人の座標リスト
        // console.log(otherUsers);
        // let tempSeatList = [...this.state.floorSeatList];
        // otherUsers.forEach(e => {
        //     if (e.isSitOnDesk === true) {
        //         let seat = tempSeatList.filter(x => x.floorObjectId === e.seat.floorObjectId && x.seatNo === e.seat.seatNo);
        //         if (seat.length !== 0) {
        //             seat[0].viewText = e.displayName;
        //             seat[0].isSit = true;
        //         }
        //     }
        // });
        let myUser = this.myUserRef.current.getUser() as User;;
        // if (myUser.isSitOnDesk) {
        //     let seat = tempSeatList.filter(x => x.floorObjectId === myUser.seat.floorObjectId && x.seatNo === myUser.seat.seatNo);
        //     if (seat.length !== 0) {
        //         seat[0].viewText = myUser.displayName;
        //         seat[0].isSit = true;
        //     }
        // }

        // this.setState({
        //     // users: otherUsers,
        //     floorSeatList: tempSeatList,
        // })

        this.otherUsersRef.current.setUsers(tempUsers);
        this.floorUsersAmountRef.current?.setData(tempUsers.length + 1);
        // 自席設定がある場合は、移動終了イベントを発生させて
        if (myUser.isGenerateMoveEnd === true) {
            if (myUser.mySeat !== null) {
                this.isDragMe = true;
                this.isDraggingMe = true;
                this.handleOnEnd(null, myUser.x + myUser.width / 2, myUser.y + myUser.height / 2 + 10, 0, 0, true, false);
            }
        }

        if (this.subIdOfGoToSeeTarget === null) {
            // floor login時、自位置を視点中心に
            this.centeringViewByMyUser();
        } else {
            // floor login時、target位置を視点中心に
            target && this.centeringViewToUser(target);
        }
        this.isInitFloor = true;
        this.subIdOfGoToSeeTarget = null;// 「会いに行く」実行後、クリア

        // リリースノート対応
        // #11013
        // ログイン後、OTHER_USERS受信後(一通りのデータを受信し終わった後)
        // チュートリアルダイアログを表示
        if (this.displayedTutorialDialog === false) {
            if (this.getEnabledTutorialButton() === true || this.getEnabledTutorialSideMenu() === true) {   // サインイン時のチュートリアル表示は、チュートリアルボタンもしくは基本メニューが有効になっているフロアだけにする
                //const cookies = new Cookies();
                //if (cookies.get('checkedDontShowTutorialAgain') !== "true") {
                if(localStorage.getItem('checkedDontShowTutorialAgain') !== "true") {
                    this.hundleOpenTutorial();
                }
            }
        }
        this.displayedTutorialDialog = true; // 表示済みフラグON(次回OTHER_USERSを受信しても何もしない)

        // リリースノート対応
        // リリースノート表示
        if(this.getEnabledInformationSideMenu() === true) {
            //if(dspTutorial === 0) {
                console.log("floor 1 releaseVersion=["+this.ReleaseNoteDialogRef.current?.getReleaseVersion()+"]")
                console.log("floor 2 releaseVersion=["+this.myServiceInfoData.releaseVersion+"]")
                let rdVer = "";
                let checkResult = -1;
                const checkVer = localStorage.getItem("checkReleaseVersion");
                if(checkVer !== null) {
                    rdVer = checkVer;
                    checkResult = Utility.compareVersion(rdVer, this.myServiceInfoData.releaseVersion);
                }
                if(checkResult < 0) {
                    //this.handleOpenReleaseNote();
                    this.handleOpenInformation();
                    localStorage.setItem("checkReleaseVersion", this.myServiceInfoData.releaseVersion);
                }
            //} else {
                // チュートリアル表示する場合はリリースノート表示スキップして、見たことにする。
            //    localStorage.setItem("checkReleaseVersion", this.myServiceInfoData.releaseVersion);
            //}
        }

        // サインイン後に出勤状態にする
        if(this.getPurposeOfUse() === this.PURPOSE_OF_USE_OFFICE){
            console.log("オフィスなので出勤状態にする")
            this.handleChangeMyCommuting(true);
        }

        // カメラ未選択時の表示対応
        // カメラ未選択の場合、カメラミュート表示
        let tmpVideoNoSelect = false;
        if(this.getEnabledVideoNoSelectDisp() === true) {
            let videoDeviceId = localStorage.getItem("videoDeviceId");
            console.log("VideoLabel : video=["+videoDeviceId+"]")
            if(videoDeviceId === null || videoDeviceId === "") {
                // カメラ未選択
                console.log("VideoLabel : setButton")
                this.myDeviceNoSelect = 1;
                this.displayMessageNoDevice(1, true);
                //localStorage.setItem("videoDeviceNothing_"+myUser.id, "yes");
                this.handleClickVideoMuteButton(true);
                tmpVideoNoSelect = true;
            }
        //}
        // マイク未選択時の表示対応
        // マイク未選択の場合、マイクミュート表示
        // ★★★★★一旦は、カメラミュート表示と同じ判定
        //if(this.getEnabledMicNoSelectDisp() === true) {
            let audioInputDeviceId = localStorage.getItem("audioInputDeviceId");
            console.log("AudioLabel : audio=["+audioInputDeviceId+"]")
            if(audioInputDeviceId === null || audioInputDeviceId === "") {
                console.log("AudioLabel : setButton")
                this.myMicDeviceNoSelect = 1;
                this.displayMessageNoDevice(2, true);
                this.handleClickVideoAudioMuteButton(tmpVideoNoSelect, true);
            }
        }
        
        // 通信量制限によるカメラ使用制限中の場合、カメラミュートを初期化する
        if(myUser.isTrafficLimitVideoMute){
            // 通信量制限によるカメラ使用制限中
            console.log("VideoLabel : Traffic Limit Video Mute.")

            let myUser = this.myUserRef.current.getUser();
            let temp = User.copyUser(myUser);
            // 初期状態はミュートオフとする
            temp.isVideoMute = false;

            // カメラ未選択時の表示対応
            if(this.myDeviceNoSelect === 1) {
                temp.isVideoNoSelect = true;
                // ミュート状態も設定する
                temp.isVideoMute = true;
            } else {
                temp.isVideoNoSelect = false;
            }
            // 通信制限中状態を初期化する
            temp.isTrafficLimitVideoMute = false;
            this.myUserRef.current.setUser(temp);
            this.sendUserAVMute(temp);
        }

        // 未読通知バッジの更新
        this.setUnreadMemoCount(myUser.unreadMemoCount);
    }

    // 立札の情報の受信
    receiveTatehudaText = (tatehuda: TatehudaText) => {
        let updatedList;
        if(Utility.isVideoConference(tatehuda.objectType)){
        //if(tatehuda.objectType===2000004 || tatehuda.objectType===2000100 || tatehuda.objectType===2000101){
            // Jsonフォーマットチェック
            let jf: chkJsonFormat = new chkJsonFormat();
            let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, tatehuda.text2);

            // Json形式の場合
            if(jsonCheck[0]==true){
                // 外部連携部屋の場合、JSONから取得
                let intextJson = jsonCheck[2];
                updatedList = this.state.floorObjectList.map(obj =>
                    (obj.id === tatehuda.id) ? 
                        { ...obj, 
                            text2: tatehuda.text2, 
                            videoURL: intextJson.url, 
                            isResetURL: intextJson.isResetURL 
                        }
                    : obj
                    )   
            } else {
                // Json形式ではない場合(外部連携情報は設定されていない)
                updatedList = this.state.floorObjectList.map(obj =>
                    (obj.id === tatehuda.id) ? 
                        { ...obj, 
                            text2: tatehuda.text2, 
                            videoURL: "", 
                            isResetURL: true 
                        }
                    : obj
                    )   
            }
        } else {
           // 外部連携部屋以外の場合
           updatedList = this.state.floorObjectList.map(obj =>
               (obj.id === tatehuda.id) ? { ...obj, text2: tatehuda.text2 } : obj
            )   
        }
        // 音声の実行
        if (tatehuda.isPlayAudio) {
            // 音(通知：ホワイトボードなどのダイナミックテキストをだれか編集した)
            this.playSoundFromAudioId(AudioId.WriteText);
        }
        this.setState({
            floorObjectList: updatedList
        })
    }

    // 教室に紐づくテキスト情報の受信
    receiveRoomText = (roomText: RoomText) => {
        let updatedList;
        updatedList = this.state.floorObjectList.map(obj =>
            (obj.id === roomText.id) ?
                { ...obj,
                    textList: obj.textList.map(textInfo => 
                                (textInfo.id === roomText.textId) ? 
                                    { ...textInfo,
                                        text: roomText.text,
                                    }
                                :
                                textInfo
                                )
                }
                : obj
                )
        // 音声の実行
        if (roomText.isPlayAudio) {
            // 音(通知：だれか編集した)
            this.playSoundFromAudioId(AudioId.WriteText);
        }
        this.setState({
            floorObjectList: updatedList
        })
    }

    receiveTatehuda2Text = (tatehuda: Tatehuda2Text) => {
        const updatedList = this.state.floorObjectList.map(obj =>
            (obj.id === tatehuda.id) ? { ...obj, text1: tatehuda.text1, text2: tatehuda.text2 } : obj
        )
        if (tatehuda.isPlayAudio) {
            // 音(通知：ホワイトボードなどのダイナミックテキストをだれか編集した)
            this.playSoundFromAudioId(AudioId.WriteText);
        }
        this.setState({
            floorObjectList: updatedList
        })
    }

    receiveTatehudaTextText1 = (tatehuda: TatehudaTextText1) => {
        const updatedList = this.state.floorObjectList.map(obj =>
            (obj.id === tatehuda.id) ? { ...obj, text1: tatehuda.text1 } : obj
        )
        if (tatehuda.isPlayAudio) {
            // 音(通知：ホワイトボードなどのダイナミックテキストをだれか編集した)
            this.playSoundFromAudioId(AudioId.WriteText);
        }
        this.setState({
            floorObjectList: updatedList
        })
        
    }

    // 拍手（バクエンドから受信）
    receiveClapping = () => {
        this.playSoundFromAudioId(AudioId.Clapping);
    }

    /*　20211206 receiveTatehudaText に処理を集約したため、コメントアウト
    // 外部連携情報の受信
    receiveVideoURLText = (videoURL: VideoURLText) => {
        const updatedList = this.state.floorObjectList.map(obj =>
            (obj.id === videoURL.id) ? 
                { ...obj, videoURL: videoURL.videoURL, isResetURL: videoURL.isResetURL } : 
                obj
        )
        if (videoURL.isPlayAudio) {
            // 音(通知：ホワイトボードなどのダイナミックテキストをだれか編集した)
            this.playSoundFromAudioId(AudioId.WriteText);
        }
        this.setState({
            floorObjectList: updatedList
        })
    }
*/


    // プライバシールーム状態設定の受信
    receivePrivacyState = (privacyState: PrivacyState) => {
        const updatedList = this.state.floorObjectList.map(obj =>
            (obj.id === privacyState.id) ? 
                { ...obj, text2: privacyState.text2 } : 
                obj
        )
        this.setState({
            floorObjectList: updatedList
        })
    }

    receiveInvitedMoreNote = (url: string) => {
        this.myUserRef.current?.setMoreNoteGuestUrl(url);
    }

    receiveCloseMoreNote = () => {
        this.handleCloseMoreNoteInline();
    }

    receiveMeetLimitOver = (meetLimitOver: MeetLimitOver) => {
        // campus対応、max=0に設定されているオブジェクトではスナックバーを出さない
        if (meetLimitOver.max === 0) return;
        this.props.enqueueSnackbar(`${meetLimitOver.type === 0 ? '会話' : '会議'}の参加者数が上限数に達しています。(上限${meetLimitOver.max}人)`);
    }

    receiveNewMemo = (memo: IMemo) => {
        const { subId, isMemoNotify } = this.myUserRef.current?.getUser();
        // メモ通知設定がON、かつ、他ユーザーからの場合
        if (isMemoNotify && memo.subId !== subId && !this.isConcentration && this.state.floorData.enabledMemoButton) {

            // axios.post("/api/user/memo/groupinfo", {
            //     tabId: sessionStorage.getItem("TABID") as string,
            //     groupId: memo.groupId
            // })
            this.httpClient.getMemoGroupInfo(sessionStorage.getItem("TABID") as string, memo.groupId)
                .then((e: MemoGroup)=>{
                    // let res = e.data as MemoGroup;
                    let res = e;
                    const action = (key: number) => (
                        <Button onClick={() => this.handleOpenChat(res.groupId, res.groupName, res.groupMemberNumber, res.groupId, res.userSubId)} color="inherit">チャットを開く</Button>
                    )
                    this.props.enqueueSnackbar(`${memo.userName}さんからチャットが届きました。`, { action });
                    this.playSoundFromAudioId(AudioId.RecvMemo);
                    // スマホの場合、デスクトップ通知いらない
                    if(!WebrtcService.isiOS() && !WebrtcService.isAndroid()){
                        this.desktopNotification(memo.userName + "さんからチャットが届きました。", 10000);
                    }
                }).catch((err) =>{

                }).finally(()=>{});
        }
        this.memoComponentRef.current?.setNewMemo(memo);
        this.groupChatComponentRef.current?.setNewMemo(memo);
        this.traceSend("floor.tsx receiveNewMemo [" + memo.groupId+ ":"+ memo.groupName + "]");
    }


    receiveDeleteMemo = (memo: IMemo) => {
        this.groupChatComponentRef.current?.setDeleteMemo(memo);
    }

    receiveChatGroupName = (data: ChatGroupData) => {
        this.groupChatComponentRef.current?.setChatGroupName(data);
    }

    receiveChatGroupAdd = (data: ChatGroupData) => {
        const { subId, isMemoNotify } = this.myUserRef.current?.getUser();
        // メモ通知設定がON、かつ、他ユーザーからの場合
        if (isMemoNotify) {
            // const action = (key: number) => (
            //     <Button onClick={() => this.handleOpenMemo(1, "")} color="inherit">メモを開く</Button>
            // )
            // this.props.enqueueSnackbar(`[${data.groupName}]に追加されました。`, { action });
            // this.playSoundFromAudioId(AudioId.RecvMemo);
            // this.desktopNotification(data.groupName + "に追加されました。", 10000);
        }
        this.groupChatComponentRef.current?.setChatGroupAddQuit(data);
    }

    receiveChatGroupQuit = (data: ChatGroupData) => {
        this.groupChatComponentRef.current?.setChatGroupAddQuit(data);
    }
    
    receiveReadMemo = (readMemoInfo: ReadMemoInfo) => {
        this.memoComponentRef.current?.receiveReadMemoInfo(readMemoInfo);
        this.groupChatComponentRef.current?.receiveReadMemoInfo(readMemoInfo);
        // 一旦ログ削除
        // this.traceSend("floor.tsx receiveReadMemo [" + readMemoInfo.groupId+ ":"+ readMemoInfo.memoId + "]");
    }

    // 自動再接続対応（退出操作されたら自動再接続したくない）
    releaseFloorRequest = (reqData: ReleaseFloorRequest) => {
        if(reqData.request === 1) {
            this.reconWebSocketReleaseFloor = 1;
        }
    }

    handleClick = () => {
        // this.websocket.connect(this, this.websocketPath);
        this.wsClient.connect(sessionStorage.getItem("TABID") as string, this.websocketNumber);
    }

    /**
     * myUserのhitEffect切替
     * 
     * @param hitEffectId
     *  */
    setHitEffectIdOfMyUser = (hitEffectId: number) => {
        this.myUserRef.current?.setHitEffectId(hitEffectId);
    }

    /**
     * myUserのwaitingフラグを変更し、stateを書き換える
     * 
     * @param isWaiting
     *  */
    setIsWaitingOfMyUser = (isWaiting: boolean) => {
        const logger: Logger = Logger.getInstance();
        const videoDeviceId = localStorage.getItem("videoDeviceId");
        const audioDeviceId = localStorage.getItem("audioInputDeviceId");
        let myUser = this.myUserRef.current?.getUser();
        if(myUser !== undefined) {
            let temp = User.copyUser(myUser);
            temp.isMediaWaiting = (videoDeviceId !== "" || audioDeviceId !== "") ? isWaiting : false;
            if(temp.isMediaWaiting === false && myUser.isMediaWaiting === true) {
                logger.info("[WR_TRACE] 自分のエフェクト解除 : name=["+temp.displayName+"] roomId="+temp.webRtcRoomId+"] pre_waiting=["+myUser.isMediaWaiting+"]");
            } else if(temp.isMediaWaiting === false && myUser.isMediaWaiting === false) {
                logger.info(" 自分のエフェクト解除済み : name=["+temp.displayName+"] roomId="+temp.webRtcRoomId+"] pre_waiting=["+myUser.isMediaWaiting+"]");
            } else {
                logger.info("[WR_TRACE] 自分のエフェクトセット？ : name=["+temp.displayName+"] roomId="+temp.webRtcRoomId+"] pre_waiting=["+myUser.isMediaWaiting+"]");
            }
            this.myUserRef.current.setUser(temp);
        }
    }

    /**
     * idで指定されたotherUserのwaitingフラグを変更し、stateを書き換える
     * 
     * @param id 
     * @param isWaiting 
     */
    setIsWaitingOfOtherUser = (id: number, isWaiting: boolean) => {
        const logger: Logger = Logger.getInstance();
        let users = this.otherUsersRef.current?.getUsers() as User[];
        if(users !== undefined && users !== null && users.length > 0) {
            let tempUsers = [...users];
            tempUsers.forEach(user => {
                if (user.id === id) {
                    if(isWaiting === false && user.isMediaWaiting === true) {
                        logger.info("[WR_TRACE] 他人のエフェクト解除 : name=["+user.displayName+"] roomId="+user.webRtcRoomId+"] pre_waiting=["+user.isMediaWaiting+"]");
                    } else if(isWaiting === false && user.isMediaWaiting === false) {
                        logger.info(" 他人のエフェクト解除済み : name=["+user.displayName+"] roomId="+user.webRtcRoomId+"] pre_waiting=["+user.isMediaWaiting+"]");
                    } else {
                        logger.info("[WR_TRACE] 他人のエフェクトセット？ : name=["+user.displayName+"] roomId="+user.webRtcRoomId+"] pre_waiting=["+user.isMediaWaiting+"]");
                    }
                    user.isMediaWaiting = isWaiting;
                    let nTime = new Date();
                    let nTimeStr = nTime.getHours()+":"+nTime.getMinutes()+":"+nTime.getSeconds()+"."+nTime.getMilliseconds();
                    console.log(nTimeStr + " effect_check otherUser Timeout id=["+user.waitingTimerId+"] name=["+user.displayName+"]");
                    if(user.isMediaWaiting === false && user.waitingTimerId !== undefined && user.waitingTimerId !== 0) {
                        console.log(nTimeStr + " effect_check otherUser clearTimeout id=["+user.waitingTimerId+"] name=["+user.displayName+"]");
                        //clearTimeout(user.waitingTimerId);    // タイムアウトしてるので不要
                        user.waitingTimerId = 0;
                    }
                }
            });
            this.otherUsersRef.current.setUsers(tempUsers);
        } else {
            console.log("otherUser is null")
        }
    }

    /**
     * idで指定されたotherUserのgoToSeeフラグを変更し、stateを書き換える
     * 
     * @param id GoToSeeアニメーションを行う対象のid
     * @param isGoToSee GoToSeeアニメーションを行うかどうかのフラグ
     */
    setIsGoToSeeOfOtherUser = (id: number, isGoToSee: boolean) => {
        let users = this.otherUsersRef.current?.getUsers() as User[];
        if (users && users.length > 0) {
            let tempUsers = [...users];

            tempUsers.forEach(user => {
                if (user.id === id) {
                    user.isGoToSee = isGoToSee;
                }
            });
            this.otherUsersRef.current.setUsers(tempUsers);
        }
    }

    /**
     * jumpToMoveDialogの表示・非表示切り替え
     * 
     * @param isShow true:表示/ false:非表示
     * @param x floorに対する絶対座標
     * @param y floorに対する絶対座標
     *  */
    toggleJumpToDialog = (isShow: boolean, x?: number, y?: number) => {
        this.jumpToDialogRef.current?.toggle(isShow, x, y);
    }

    /**
     * ユーザ状態変更通知.
     *
     * @param state 変更後の状態
     * @param enableMeet enableMeet状態
     */
    handleMyStateChange = (state: number, enableMeet: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.state = temp.state === state ? UserState.None : state;
        temp.enableMeet = enableMeet;
        this.myUserRef.current.setUser(temp);
        this.sendUserStateChange(temp);
    }

    handlePictograph = (state: number) =>{
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.state = temp.state === state ? UserState.None : state;
        this.myUserRef.current.setUser(temp);
        this.sendUserStateChange(temp);
    }
    /**
     * つぶやき変更通知.
     *
     * @param text 変更後の状態
     */
    hanldeTubuyakiChange = (text: string) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.tubuyaki = text;
        this.myUserRef.current.setUser(temp);
        this.sendUserTubuyakiChange(temp);
    }

    /**
     * フロア移動通知.
     *
     * @param id 移動先のフロア
     */
    handleMoveFloor = async (id: number) => {
        // 同じフロアだったら移動しない
        if (id === this.floorId) return;
        // フロア移動前にチャットウィンドウを閉じる
        this.groupChatComponentRef.current?.close();
        var params = new URLSearchParams();
        params.append("floor_id", id.toString());
        params.append("tab_id", sessionStorage.getItem("TABID") as string);

        // axios.post('/api/user/floor/checkFloorOpen', params)
        this.httpClient.checkFloorOpen(sessionStorage.getItem("TABID") as string, id)
            .then((result: boolean) => {
            // let result = e.data as boolean;
            
            // FAMofficeの「フロア入室制限」の取り込みに伴い、以下のif文をコメントアウト(resultをフラグとして後々利用)
            // if(!result){
                ////alert("ただいまの時間は、選択されたフロアには入れません。\r\n立ち入り可能な時間帯は、管理者にお問い合わせください。");
                // this.floorClosedDialogRef.current.setDialogOpen(true);
                // return;
            // }

            let destinationFloor;
            if(result){ // フロアが開放時間内であれば以下の処理を行う

                //そのフロアで編集状態だった場合解除する
                if(this.state?.floorEditMode) this.floorEditorRef.current?.requestFloorEditUnLock();
                

                // { for Debug
                const logger: Logger = Logger.getInstance();
                let myUser = this.myUserRef.current?.getUser() as User;
                if(myUser === undefined) {
                    if(logger !== undefined && logger !== null)
                        logger.info("moveFloor myUser is null");
                    else
                        console.log("moveFloor myUser is null");
                    return;
                }
                if(logger !== undefined && logger !== null)
                    logger.info("moveFloor strat  user=["+myUser.displayName+"] roomId-["+myUser.webRtcRoomId+"] webrtc=["+myUser.webRtcCall+"]");
                else
                    console.log("moveFloor strat  user=["+myUser.displayName+"] roomId-["+myUser.webRtcRoomId+"] webrtc=["+myUser.webRtcCall+"]");

                if(myUser.webRtcCall === true) {
                    if(logger !== undefined && logger !== null)
                        logger.info("moveFloor webrtc中のフロア移動  user=["+myUser.displayName+"] roomId-["+myUser.webRtcRoomId+"] webrtc=["+myUser.webRtcCall+"]");
                    else
                        console.log("moveFloor webrtc中のフロア移動  user=["+myUser.displayName+"] roomId-["+myUser.webRtcRoomId+"] webrtc=["+myUser.webRtcCall+"]");
                    
                    // webrtc 停止が必要？？？
                    // webrtc終了をサーバーに通知
                    /*
                    this.sendDisconnectWebrtc(myUser);
                    let temp = User.copyUser(myUser);
                    temp.webRtcCall = false;
                    temp.webRtcRoomId = "";
                    this.myUserRef.current.setUser(temp);
                    */
                }
                // }

                // カメラ未選択時の表示対応
                // #168
                this.myDeviceNoSelect = 0;
                this.myMicDeviceNoSelect = 0;
                this.displayMessageNoDevice(1, false);
                this.handleClickVideoAudioMuteButton(false,false);

                this.myUserRef.current.setOpenProfile(false);
                if (this.myUserRef.current.getOpenMenu()) this.myUserRef.current.click(); // UserMenu close

                // Zoomのiframeをreloadする
                this.zoomViewRef.current?.reload();

                this.floorId = id;
                // this.websocket.sendStartChageFloor(this.floorId);
                //フロア移動直前にbrowserBackをfalseにしないと正常にフロア移動しない場合があった
                this.wsClient.browserBack = false; 
                try {
                    this.wsClient.sendStartChangeFloor(this.floorId);
                } catch (error) {
                    console.error(error);
                }
                const params = new URLSearchParams();
                params.append("floor_id", this.floorId.toString());
                params.append("tab_id", sessionStorage.getItem("TABID") as string);
                params.append("is_changing", "true");

                // フロア移動時に削除しておかないと、同一のWebRTC Peer.id が残ってしまい、
                // WebRTCの通話が開始できなくなる場合がある。
                WebrtcService.clearUserDataList();
                this.isWebRtcConnecting = false;
                // #851 調査用ログ
                logger.info("handleMoveFloor this.isWebRtcConnecting set false : ["+myUser.displayName+"] room=["+myUser.webRtcRoomId+"]");

                // 画面共有UIをクリア
                this.clearScreenShare();

                // axios.post('/api/user/floor/change', params)
                //     .then((e: AxiosResponse) => {
                //         this.clearInterval();
                //         this.websocket.disconnect(true, e.data);
                //         //this.reconWebSocketDisconnect = 1;
                //         console.log(e);
                //     }).catch(err => {
                //         console.log(err);
                //     });
                // }).catch(err => {
                //     console.log(err);
                // });
                
                destinationFloor = this.floorId;
            }else{ // 仮にフロア開放時間外の場合、移動先フロアIDとして-1をセットするだけ(他の処理はしない)
                destinationFloor = -1;
            }

            this.httpClient.moveFloor(destinationFloor, sessionStorage.getItem("TABID") as string, true)
                .then((e: number) => {
                    this.clearInterval();
                    this.wsClient.disconnect(true, e);
                    
                    console.info(e);
                }).catch(err => {
                    const jfserr = err as JfsError;
                    console.log('httpClient.updateFloorManager error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
                    
                    // フロア移動がサーバーで制限された場合はメッセージを表示する
                    
                    // 下記paas化したofficeには存在するがcampusには存在しないため一旦コメントアウト
                    // this.floorId = this.beforeFloorId;
                
                    // if(jfserr.detail === '選択したフロアは入室制限がかけられています。'){	// v1.8_needCheck
                    //     this.entryRestrictDlgRef.current.open();
                    // }else if(jfserr.detail === '選択したフロアはメンテナンス開始/解除の処理中です。'){
                    //     this.migratingMaintenanceDlgRef.current.open();
                    // }else{
                    //     this.okCancelDialogRef.current.open();
                    // }
                    if(jfserr.detail === '選択したフロアは入室制限がかけられています。'){	// v1.8_needCheck
                        this.subIdOfGoToSeeTarget = null;
                        // this.subIdOfGoToTalkTarget = null;
                        this.entryRestrictDlgRef.current.open();
                    }else if(jfserr.detail === '選択したフロアはメンテナンス開始/解除の処理中です。'){
                        this.subIdOfGoToSeeTarget = null;
                        // this.subIdOfGoToTalkTarget = null;
                        this.migratingMaintenanceDlgRef.current.open();
                    }else{
                        if(!result){
                            this.subIdOfGoToSeeTarget = null;
                            this.floorClosedDialogRef.current.setDialogOpen(true);
                        }else{
                            this.subIdOfGoToSeeTarget = null;
                            // this.subIdOfGoToTalkTarget = null;
                            this.okCancelDialogRef.current.open();
                        }
                    }
                    console.log(jfserr);
                });

        });
    }
    

    // グループ名変更を送信
    sendChatGroupName = (groupName:string, tabId: string, groupId: number) => {
        // this.sendWebSocket("CHATGROUP_NAME", { groupName, tabId, groupId})
        
        try {
            this.wsClient.sendChatGroupName(groupName, tabId, groupId);
        } catch (error) {
            console.error(error);            
        }
    }

    // グループ名変更を送信
    sendChatGroupQuit = (tabId: string, groupId: number) => {
        // this.sendWebSocket("CHATGROUP_QUIT", {tabId, groupId})
        
        try {
            this.wsClient.sendChatGroupQuit(tabId, groupId);
        } catch (error) {
            console.error(error);            
        }
    }

    // グループ名変更を送信
    sendChatGroupAdd = (subIds:string[], tabId: string, groupId: number) => {
        // this.sendWebSocket("CHATGROUP_ADD", {subIds, tabId, groupId})
        
        try {
            this.wsClient.sendChatGroupAdd(subIds, tabId, groupId);
        } catch (error) {
            console.error(error);            
        }
    }

    /**
     * プライバシーボタン押下処理.
     */
    handleClickPrivacyButton = (on: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isAudioMute = on;
        temp.isVideoMute = on;
        this.myUserRef.current.setUser(temp);
        this.sendUserAVMute(temp);
    }

    /**
     * ミュートボタン押下処理.
     */
    handleClickMuteButton = (on: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isAudioMute = on;
        this.myUserRef.current.setUser(temp);
        this.sendUserAVMute(temp);
    }

    /**
     * ビデオミュートボタン押下処理.
     */
    handleClickVideoMuteButton = (on: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        if(myUser.isTrafficLimitVideoMute){
            temp.isVideoMute = true;
        }else{
            temp.isVideoMute = on;
        }
        // カメラ未選択時の表示対応
        if(this.myDeviceNoSelect === 1) {
            temp.isVideoNoSelect = true;
            // ミュート状態も設定する
            temp.isVideoMute = true;
        } else {
            temp.isVideoNoSelect = false;
        }
        this.myUserRef.current.setUser(temp);
        this.sendUserAVMute(temp);
    }

    /**
     * マイクミュートボタン押下処理.    マイク未選択時の表示対応
     */
    /*
     handleClickMicMuteButton = (on: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isAudioMute = on;
        if(this.myMicDeviceNoSelect === 1) {
            temp.isMicNoSelect = true;
        } else {
            temp.isMicNoSelect = false;
        }
        this.myUserRef.current.setUser(temp);
        this.sendUserAVMute(temp);
    }
    */

    /**
     * ミュート状態解除
     * #168（FAMoffice開発）
     */
     handleClickVideoAudioMuteButton = (von: boolean, aon: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isVideoMute = von;
        temp.isAudioMute = aon;
        // カメラ未選択時の表示対応
        if(this.myDeviceNoSelect === 1) {
            temp.isVideoNoSelect = true;
            // ミュート状態も設定する
            temp.isVideoMute = true;
        } else {
            temp.isVideoNoSelect = false;
        }
        // マイク未選択時の表示対応
        if(this.myMicDeviceNoSelect === 1) {
            temp.isMicNoSelect = true;
        } else {
            temp.isMicNoSelect = false;
        }
        this.myUserRef.current.setUser(temp);
        this.sendUserAVMute(temp);
    }

    /**
    * 一斉ミュート処理
    */
    handleReceiveForceMute = (on: boolean) => {
        // 一斉ミュート状態になったとき、ミュートボタンを押下した時と同じ処理を行う
        let myUser = this.myUserRef.current.getUser();
        this.handleClickMuteButton(on);

        this.showAttentionSnackBar(on, "現在、運用側でマイクをミュートにしています");
    }

    handleBeforeunloadVideoConderence = () => {
        let myUser = this.myUserRef.current.getUser();
        let tempUser = User.copyUser(myUser);
        tempUser.videoURL = "";
        this.myUserRef.current.setUser(tempUser);
    }

    handleReceiveVideoURLText = (url: string) => {
        const logger: Logger = Logger.getInstance();
        let myUser = this.myUserRef.current.getUser();
        if(myUser.videoURL?.indexOf('http') === 0){
            try{
                this.popupVideoConference = window.open(myUser.videoURL, "video conference", "toolbar=no,location=no,status=yes,menubar=yes,width=820,height=615,scrollbars=yes,resizable=yes");
                if (this.popupVideoConference){
                    /*
                    let handleBeforeunloadVideoConderence: any = this.handleBeforeunloadVideoConderence();
                    this.popupVideoConference.addEventListener("beforeunload", function(ev: Event) {
                        ev.preventDefault();
                        //handleBeforeunloadVideoConderence();
                        ev.returnValue = true;
                    });
                    */
                    // iPad対応-12 iPadの場合、FAMがアクティブでなくなり切断されるので退勤する
                    // ブラウザのタブ開いた場合
                    // 仕事中なのに退勤してはいけないので、ひとまずは何もしない（切断を待つ）12/22 レビュー指摘
                    /*
                    if(WebrtcService.isiOS() === true){
                        logger.info("VIDEO_URL_TRACE iOS open OK signout call");
                        this.handleSelectedSignout();
                    }
                    */
                } else {
                    console.log("failed window.open()");
                    // iPad対応-12 iPadの場合、FAMがアクティブでなくなり切断されるので退勤する
                    // ブラウザではなくアプリを起動した場合
                    // 仕事中なのに退勤してはいけないので、ひとまずは何もしない（切断を待つ）12/22 レビュー指摘
                    /*
                    if(WebrtcService.isiOS() === true){
                        logger.info("VIDEO_URL_TRACE iOS open NG signout call");
                        this.handleSelectedSignout();
                    }
                    */
                }
            } catch (err) {
                // urlが開けない場合は何もしない
                console.error(err);
                console.log("failed window.open()");
                if(WebrtcService.isiOS() === true){
                    logger.info("VIDEO_URL_TRACE iOS open NG");
                }
            }
        } else {
        }
    }

    /**
     * サインアウト実行処理.
     */
    handleSelectedSignout = () => {
        // TODO: リダイレクトすることによりWebRTC が終了しサーバ側でFloorWebSocketService.afterConnectionClosed() が動作する.
        // 上記と別にサインアウト直後に実施したい処理がある場合、サーバに対してWebRTC で通信すること.

        if (this.getPurposeOfUse() === this.PURPOSE_OF_USE_OFFICE) {
            // #609 意図しないclose対応
            this.setDisconnectWebrtc(); // webrtc中なら終了させる（意図しないcloseにならないように）

            // this.websocket.setPlanDisconnect(true);
            // this.websocket.browserBack = true;
            this.wsClient.browserBack = true;
            this.wsClient.planDisconnect = true;

            // オフィスの時はサインアウト時に退勤状態にする
            // この中でsignout: trueの設定、およびセッション破棄も行う
            let retval = this.handleChangeMyCommuting(false);
            if(retval !== 0) {
                // this.websocket.browserBack = false;         // 戻す
                // this.websocket.setPlanDisconnect(false);    // 戻す
                this.wsClient.browserBack = false;
                this.wsClient.planDisconnect = false;
            
            }
            // カメラなしのメッセージクリア
            this.myDeviceNoSelect = 0;
            this.displayMessageNoDevice(1, false);
        }
        else {
            this.setState({
                signout: true
            });

            // #11540 サインアウト時にセッションを破棄する 
            // var params = new URLSearchParams();
            // params.append("tab_id", sessionStorage.getItem("TABID") as string);
            // axios.post('/api/user/logout', params).catch((err) => {
            //     console.log("/api/user/logout error : ", err.response);
            // });
            this.httpClient.signout(sessionStorage.getItem("TABID") as string).catch((err) => {
                console.log("/api/user/logout error : ", err.response);
            });
        }
        // 画面上の連絡snackbarをすべて閉じる
        this.InternalNoticeDialogRef.current?.allCloseINSnackbar();
        sessionStorage.removeItem("ISLOGIN");
    }

    /**
     * フィットボタン押下処理.
     */
    handleClickFitButton = (scale: number) => {
        this.setState({
            fitScale: scale
        });

        // 時間差でセンタリング（時間差がないとscrollToが効かない。。）
        // （ここで scrollToは出来なかった。この時点でブラウザ上で存在しない座標に飛ぶ必要があるため。）
        setTimeout(() => this.centeringViewByMyUser(), 1);
    }

    /**
     * 全体ボタン押下処理.
     */
    handleClickOverlookButton = (scale: number) => {

        let scaleToFit = document.documentElement.clientHeight / this.state.floorData.floorHeight;

        if(scale == 0.5){
            // 0.006はギリギリ白帯が出ないための微調整
            scaleToFit = Math.max(scale, scaleToFit - 0.006);
        }else{
            scaleToFit = scale;
        }
        this.setState({
            fitScale: scaleToFit
        });

        setTimeout(() => this.centeringViewByMyUser(), 1);
        // if (scale === 1.0) {
        //     // 通常倍率に戻す場合はセンタリング
        //     setTimeout(() => this.centeringViewByMyUser(), 1);
        // } else {
        //     //  俯瞰する際は全体が見えるように
        //     setTimeout(() => this.overlookViewByMyUser(), 1);
        // }
    }

    /**
     * 出勤状態変更
     *
     * @param commuting 出勤状態 true:出勤 false:退勤
     */
    handleChangeMyCommuting = (commuting: boolean) => {
        let retval = 0;
        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // axios.post("/api/user/work/" + (commuting ? "go" : "leave"), params)
        //     .then((e: AxiosResponse) => {
        //         // "ALREADY" はスルー ... 出勤済みで出勤，退勤済みで退勤 すると "ALREADY" が返却される。
        //     	// また、自席が無いときは「NOMYSEAT」が返却される
        //         // ただ、OKとは返ってこない気がする

        //         console.log("e.data", e.data);
        //         if (e.data === "OK") {
        //             let message = commuting ? "出勤しました。" : "退勤しました。";
        //             // #11726
        //             //this.props.enqueueSnackbar(message, { variant: "success" });
        //         }

        //         // 退勤時はサインアウトも行う
        //         // サインアウトはapiから戻ってきた後に行う
        //         if(commuting === false){
        //             this.setState({
        //                 myCommuting: commuting,
        //                 signout: true,
        //             })

        //             // #11540 サインアウト時にセッションを破棄する
        //             // #165 apiからの戻りを待ってセッションを破棄する
        //             var params = new URLSearchParams();
        //             params.append("tab_id", sessionStorage.getItem("TABID") as string);
        //             axios.post('/api/user/logout', params).catch((err) => {
        //                 console.log("/api/user/logout error : ", err.response);
        //             });
        //         }
        //         else{
        //             if(e.data !== "NOMYSEAT"){
        //                 this.setState({
        //                     myCommuting: commuting
        //                 })
        //             }
        //         }

        //     }).catch(err => {
        //         let message = commuting ? "出勤に失敗しました。" : "退勤に失敗しました。";
        //         //this.props.enqueueSnackbar(message, { variant: "error" });
        //         const logger: Logger = Logger.getInstance();
        //         logger.error(message + " " + err);
        //         //console.log(message + " " + err);

        //         // #165 退勤APIで失敗しても画面遷移はしておく
        //         if(commuting === false){
        //             this.setState({
        //                 myCommuting: commuting,
        //                 signout: true,
        //             })
        //         }
        //         retval = -1;
        //     });

        const tabId = sessionStorage.getItem("TABID") as string;
        // sdkでは上記のような書き方ができないのでisCommutingで場合分け
        if (commuting === false ) {
            this.httpClient.workLeave(tabId)
                .then((data:string) => {
                    console.log("data", data);
                    if (data === this.JFS_ERRORS.OK.code) {
                        let message = "退勤しました。";
                        // #11726
                        //this.props.enqueueSnackbar(message, { variant: "success" });
                    }

                    // 退勤時はサインアウトも行う
                    // サインアウトはapiから戻ってきた後に行う
                    this.setState({
                        myCommuting: commuting,
                        signout: true,
                    })

                    // #11540 サインアウト時にセッションを破棄する
                    // #165 apiからの戻りを待ってセッションを破棄する
                    // var params = new URLSearchParams();
                    // params.append("tab_id", sessionStorage.getItem("TABID") as string);
                    // axios.post('/api/user/logout', params).catch((err) => {
                    //     console.log("/api/user/logout error : ", err.response);
                    // });
                    this.httpClient.signout(tabId).catch((err) => {
                        console.log("/api/user/logout error : ", err.response);
                    });
                })
                .catch(err => {
                    let message = "退勤に失敗しました。";
                    //this.props.enqueueSnackbar(message, { variant: "error" });
                    const logger: Logger = Logger.getInstance();
                    logger.error(message + " " + err);
                    //console.log(message + " " + err);
    
                    // #165 退勤APIで失敗しても画面遷移はしておく
                    this.setState({
                        myCommuting: commuting,
                        signout: true,
                    })
                    retval = -1;
                });
        } else {
            this.httpClient.changeMyCommuting(tabId)
                .then((data: string) => {
                    if(data !== "NOMYSEAT"){
                        this.setState({
                            myCommuting: commuting
                        })
                    }
                })
                .catch(err => {
                    let message = "出勤に失敗しました。";
                    //this.props.enqueueSnackbar(message, { variant: "error" });
                    const logger: Logger = Logger.getInstance();
                    logger.error(message + " " + err);
                })
        }

        return retval;
    }

    /**
     * タッチの時、DIVのスクロールを止めるための処理
     * @param event
     */
    noScroll = (event: any) => {
        event.preventDefault();
    }

    //簡単間引き用
    // サーバーが送信する感覚は100msなので100ms以下で送信する意味はない
    private beforeMove = 0;
    sendMove = (user: User) => {
        if (new Date().getTime() - this.beforeMove < (100 + this.averageMoveCount * this.state.floorData.decimationMoveData)) return;
        // 送信するデータを限定
        let data: SendMoveUserData = { x: user.x, y: user.y, webRtcPeerId: user.webRtcPeerId, webRtcRoomId: user.webRtcRoomId };
        // this.sendWebSocket("MY_MOVE", data);    
        
        try {
            this.wsClient.sendMyUserMove(data);
        } catch (error) {
            console.error(error);            
        }
        this.beforeMove = new Date().getTime();
    }

    // 移動データ受信量に比例してサーバーにアバター移動情報を送信するデータの間引き量を決定する
    private averageMoveCount = 0;
    calcDecimationMoveData = (moveUserCount: number) => {
        this.averageMoveCount = (this.averageMoveCount + moveUserCount) / 2;
        // console.log(this.averageMoveCount);
    }

    sendEndMove = (user: User) => {
        // #809 ドラッグ中に話しかけられた場合、ドロップしても会話継続させる
        if(this.hittedonDrag === true) {
            const logger: Logger = Logger.getInstance();
            logger.info("hittedonDrag: skip sendEndMove my=["+user.displayName+"]");
            this.beforeMove = new Date().getTime();
            this.hittedonDrag = false;  
            return;
        }
        // 階段にヒット下か判定、ヒットしたら、text1に保存されたfloorIdに応じるフロアへ遷移する
        const stairs = this.state.floorObjectList.filter(obj => obj.objectMaster?.type === 3000006 && this.isUserHitObject(user.x, user.y, user.width, user.height, obj.offsetLeft, obj.offsetTop, obj.objectMaster?.width, obj.objectMaster?.height))[0];
        if (stairs && stairs.text1 && !isNaN(parseInt(stairs.text1))) {
            this.setState({openMoveFloor: true, moveFloor: {floorId: parseInt(stairs.text1), floorName: stairs.text2}});
        }
        // 自習席対応（サインイン時の自席移動の場合は自習ダイアログを表示しない）
        if (!this.isSigninMySeat) {
            this.isOpenStudyDialog = true;
        }
        this.isSigninMySeat = false;
        // this.sendWebSocket("MY_END_MOVE", user);
        try {
            this.wsClient.sendMyUserEndMove(user);
        } catch (error) {
            console.error(error);            
        }
        this.beforeMove = new Date().getTime();
    }

    // オブジェクトにヒットするか判定
    isUserHitObject = (x: number, y: number, w:number, h:number, objX:number, objY:number, objW: number, objH: number) => {
        return x + w/2 >= objX && x + w/2 <= objX + objW && y + h/2 >= objY && y + h/2 <= objY + objH;
    }

    sendTatehudaText = (id: number, text: string, isPlayAudio: boolean) => {
        // this.sendWebSocket("TATEHUDA_TEXT", { id, text2: text, isPlayAudio: isPlayAudio });
        try {
            this.wsClient.sendTatehudaText(id, text, isPlayAudio);
        } catch (error) {
            console.error(error);            
        }
    }

    sendTatehuda2Text = (id: number, text1: string, text2: string, isPlayAudio: boolean) => {
        // this.sendWebSocket("TATEHUDA2_TEXT", { id, text1: text1, text2: text2, isPlayAudio: isPlayAudio });
        try {
            this.wsClient.sendTatehuda2Text(id, text1, text2, isPlayAudio);
        } catch (error) {
            console.error(error);            
        }
    }

    sendText1KanbanText = (id: number, text: string, isPlayAudio: boolean) => {
        // this.sendWebSocket("TATEHUDA_TEXT_TEXT1", { id, text1: text, isPlayAudio: isPlayAudio });
        try {
            this.wsClient.sendTatehudaTextText1(id, text, isPlayAudio);
        } catch (error) {
            console.error(error);            
        }
    }

    sendVideoURLText = (id: number, text: string, isPlayAudio: boolean, isResetURL: boolean) => {
        const popupUrl: string = text;
        // this.sendWebSocket("VIDEOURL_TEXT", { id, videoURL: text, isPlayAudio: isPlayAudio, isResetURL: isResetURL });
        try {
            this.wsClient.sendVideoURLText(id, text, isPlayAudio, isResetURL);
        } catch (error) {
            console.error(error);            
        }
        
        if(popupUrl.indexOf("http") === 0){
            this.handleEnableWebRtcRoom(id, false, text);
        } else {
            this.handleEnableWebRtcRoom(id, true, text);
        }       
    }

    sendCountdownText = (id: number, text: string, date: string, font: string, color: string ) => {
        // this.sendWebSocket("COUNTDOWN_TEXT", { id, eventName: text, eventDate: date,  font, color});  
        try {
            this.wsClient.sendCountdownText(id, text, date, font, color);
        } catch (error) {
            console.error(error);            
        }  
    }

    sendBannerText = (id: number, text: string, font: string, color: string) => {
        // this.sendWebSocket("BANNER_TEXT", { id, message: text, font, color});
        try {
            this.wsClient.sendBannerText(id, text, font, color);
        } catch (error) {
            console.error(error);            
        }
    }

    sendRoomText = (id: number, textId: number, text: string, isPlayAudio: boolean, type: number) => {

        try{
            this.wsClient.sendRoomText(id,textId,text,isPlayAudio);
        } catch (error) {
            console.error(error);
        }

        // 外部ビデオ連携の仕組みは従来の仕組みで管理するように修正
        // if(type === 4){ // 外部ビデオ連携の設定の場合、WebRTCの同一ルームに開始か終了を送る
        //     let jf: chkJsonFormat = new chkJsonFormat();
        //     let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

        //     // Json形式の場合
        //     if(jsonCheck[0]==true){
        //         // JSONからurlのみ取得
        //         let intextJson = jsonCheck[2];
        //         const popupUrl : string = intextJson.url;
        //         if(popupUrl.indexOf("http") === 0){
        //             this.handleEnableWebRtcRoom(id, false, popupUrl);
        //         } else {
        //             this.handleEnableWebRtcRoom(id, true, popupUrl);
        //         }
        //     }
        // }


    }
    
    sendPrivacyState = (id: number, state: boolean) => {
        // this.sendWebSocket("PRIVACY_STATE", { id, state: state });
        try {
            this.wsClient.sendPrivacyState(id, state);
        } catch (error) {
            console.error(error);            
        }
    }

    // WebRTCを使用する際の識別用のID（Skywayのサーバに接続に行った際に生成される）をサーバに送る
    sendWebRtcPeerId = (user: User) => {
        // this.sendWebSocket("MY_WEB_RTC_PEER_ID", { webRtcPeerId: user.webRtcPeerId });
        try {
            this.wsClient.sendMyWebRTCPeerId(user.webRtcPeerId);
        } catch (error) {
            console.error(error);            
        }
    }

    sendSetGhost = (isGhost: boolean) => {
        // #821 調査用ログ
        const logger: Logger = Logger.getInstance();
        logger?.info("sendWebSocket SET_GHOST : "+isGhost);
        // this.sendWebSocket("SET_GHOST", { isGhost: isGhost });
        try {
            this.wsClient.sendSetGhost(isGhost);
        } catch (error) {
            console.error(error);            
        }
    }

    sendDeviceSelectedType = (deviceSelectedType: number) => {
        // this.sendWebSocket("SET_DEVICE_SELECTED_TYPE", { deviceSelectedType: deviceSelectedType });
        try {
            this.wsClient.sendDeviceSelectedType(deviceSelectedType as SelectedDevice);
        } catch (error) {
            console.error(error);            
        }
    }

    sendUserStateChange = (user: User) => {
        // this.sendWebSocket("MY_STATE_CHANGE", { state: user.state, enableMeet: user.enableMeet });
        try {
            this.wsClient.sendMyStateChange(user);
        } catch (error) {
            console.error(error);            
        }
    }

    sendUserStudySettingChange = (user: User) => {
        try {
            this.wsClient.sendMyStudyTimerSetting(user);
        } catch (error) {
            console.error(error);
        }
    }

    sendUserStudyElapsedTimeChange = (user: User) => {
        try {
            this.wsClient.sendMyStudyElapsedTime(user);
        } catch (error) {
            console.error(error);
        }
    }

    sendUserTubuyakiChange = (user: User) => {
        // this.sendWebSocket("MY_TUBUYAKI_CHANGE", { tubuyaki: user.tubuyaki });
        try {
            this.wsClient.sendUserTubuyakiChange(user.tubuyaki);
        } catch (error) {
            console.error(error);            
        }
    }

    sendUserAVMute = (user: User) => {
        // カメラ未選択時の表示対応(isVideoNoSelect追加)
        // this.sendWebSocket("MY_AV_MUTE_CHANGE", { 
        //     isAudioMute: user.isAudioMute, 
        //     isVideoMute: user.isVideoMute, 
        //     isTrafficLimitVideoMute: user.isTrafficLimitVideoMute, 
        //     isVideoNoSelect: user.isVideoNoSelect, 
        //     isMicNoSelect: user.isMicNoSelect });

        try {
            this.wsClient.sendMyAVMute(user);
        } catch (error) {
            console.error(error);            
        }
    }

    sendMicLevel = (id: number, micVolume: number) => {
        // this.sendWebSocket("MY_MIC_VOLUME", { id: id, micVolume: micVolume });
        try {
            this.wsClient.sendMyMicVolume(id, micVolume);
        } catch (error) {
            console.log(error);
        }
    }

    /** 周りの人からの見た目を変更する要求 */
    sendWrJoinStatus = (user: User) => {
        console.log("sendWrJoinStatus isWebrtcJoin=["+user.isWebrtcJoin+"]");
        // this.sendWebSocket("WRJOIN_STATUS_CHANGE", { isWebrtcJoin: user.isWebrtcJoin });
        try {
            this.wsClient.sendWebRTCJoinStatus(user.isWebrtcJoin);
        } catch (error) {
            console.error(error);            
        } 
    }

    /** ビデオ通話前の確認状態送信 */
    sendWrStartConfirmStatus = (user: User) => {
        console.log("sendWrStartConfirmStatus webrtcRoomId=["+user.webRtcRoomId+"] status=["+user.wrStartConfirmStatus+"]");
        // this.sendWebSocket("WR_STARTCONFIRM_STATUS", { wrStartConfirmStatus: user.wrStartConfirmStatus });
        try {
            this.wsClient.sendWrStartConfirmStatus(user.wrStartConfirmStatus);
        } catch (error) {
            console.error(error);            
        }  
    }

    /** 自分の「ビデオ通話前の確認」設定変更を送信 */
    sendWrStartConfirmSettingData = (value: number) => {
        console.log("sendWrStartConfirmSettingData value=["+value+"]");
        // this.sendWebSocket("MY_CONFIRM_SETTING", { avatarConfirmSetting: value });
        try {
            this.wsClient.sendWrStartConfirmSettingData(value as CallStartingSetting);
        } catch (error) {
            console.error(error);            
        } 
    }

    /** 自分の「ログイン通知」設定変更を送信 */
    sendLoginoutNotificationData = (value1: number, value2: number) => {
        console.log("sendLoginoutNotificationData value=["+value1+"]" + ",["+value2+"]");
        // this.sendWebSocket("MY_LOGINOUT_NOTIFICATION_SETTING", { loginoutNotification: value1, loginoutNotificationSound: value2 });
        try {
            this.wsClient.sendLoginoutNotificationData(value1, value2);
        } catch (error) {
            console.error(error);            
        }
    }

    /** webrtcのjoin状態を送信 */
    sendJoinRoomStatusData = (sendDat: JoinRoomStatusData) => {
        // this.sendWebSocket("UPDATE_JOINROOM_STATUS", sendDat);
        try {
            this.wsClient.sendJoinRoomStatusData(sendDat);
        } catch (error) {
            console.error(error);            
        }
    }

    // FAMofficeでは未使用となっていた
    /** webrtcの終了依頼を送信 */ 
    sendDisconnectWebrtc = (user: User) => {
        console.log("sendDisconnectWebrtc webrtcRoomId=["+user.webRtcRoomId+"]");
        this.sendWebSocket("REQ_DISCONNECT_WEBRTC", {});
    }

    sendWebRtcInfo = (info: WebRtcInOutInfo) => {
        // this.sendWebSocket("MY_WEB_RTC_INFO", info);
        try {
            this.wsClient.sendWebRTCInfo(info);
        } catch (error) {
            console.error(error);            
        }
    }

    sendChangeReconnectStatus = (flag: boolean) => {
        // this.sendWebSocket("CHANGE_RECONNECT_STATUS", { reconnectFlag: flag });
        try {
            this.wsClient.sendChangedReconnectionStatus(flag);
        } catch (error) {
            console.error(error);            
        }
    }

    sendScreenShare = (id: number, isScreenShare: boolean, screenShareMode: number, webRtcRoomId: string) => {
        // this.sendWebSocket("MY_SCREEN_SHARE_CHANGE", { id, isScreenShare, screenShareMode, webRtcRoomId });
        try {
            this.wsClient.sendScreenShare(id, isScreenShare, screenShareMode as JfsScreenShareMode, webRtcRoomId);
        } catch (error) {
            console.error(error);            
        }
    }

    // #761 共有ウインド選択のバッティング対応
    sendScreenShareWindowSelected = () => {
        // this.sendWebSocket("MY_SCREEN_SHARE_WINDOW_SELECTED", { });
        try {
            this.wsClient.sendScreenShareWindowSelected();
        } catch (e) {
            console.info(e);
        }
    }

    sendEndMoveAllUser = () => {
        this.setState({
            visibleWebRtcBroadCastButton: true,
            visibleWebRtcBroadCastStopButton: false,
        });

        // this.sendWebSocket("END_MOVE_ALL_USER", {});
        try {
            this.wsClient.sendEndMoveAllUser();
        } catch (e) {
            console.info(e);
        }
    }

    sendDisconnectWebRtcAllUser = () => {
        // this.sendWebSocket("DISCONNECT_WEB_RTC_ALL_USER", {});
        try {
            this.wsClient.sendDisconnectWebRtcAllUser();
        } catch (e) {
            console.info(e);
        }
    }

    sendConnectWebRtcAllUser = (sessionId: string) => {
        const myUser = this.myUserRef.current.getUser() as User;
        if (WebrtcService.getDeviceSelectedType() < 3) {
            this.displayDeviceUnselectedMessage(myUser.id, WebrtcService.getDeviceSelectedType());
            return;
        }

        this.setState({
            visibleWebRtcBroadCastButton: false,
            visibleWebRtcBroadCastStopButton: true,
        });

        // this.sendWebSocket("CONNECT_WEB_RTC_ALL_USER", { id: sessionId });
        try {
            this.wsClient.sendConnectWebRtcAllUser(sessionId);
        } catch (error) {
            console.error(error);            
        }
    }

    sendGetMyRoomFloorData = () => {
        //console.log("sendGetMyRoomFloorData GET_MY_ROOM_FLOOR_DATA");
        // this.sendWebSocket("GET_MY_ROOM_FLOOR_DATA", {});
        try {
            this.wsClient.sendGotMyRoomFloorData();
        } catch (error) {
            
        }
    };

    /**
     *  トレースログ
     * 
     */
     private traceLog = (msg: string) => {
        let now = new Date().toTimeString();
        console.log(now+" "+msg);
    }

    /**
     *  windowイベントハンドラ
     */
    // webSocket自動再接続対応
    handleWindowFocus = () => {
        const logger: Logger = Logger.getInstance();
        logger?.info("ウインドがフォーカス状態になった");
        this.traceLog("[WebSocketTrace] ウインドがフォーカス状態になった");
        if(this.disconnectDialogRef.current.checkOpen() === true) {
            // this.websocket.disconnect(false, "");
            this.wsClient.disconnect(false);
            this.checkReconnectWebSocket();
        }
    }
    handleWindowBlur = () => {
        const logger: Logger = Logger.getInstance();
        logger?.info("ウインドのフォーカスが外れた");
        this.traceLog("[WebSocketTrace] ウインドのフォーカスが外れた");
    }
    handleVisibilitychange = () => {
        const logger: Logger = Logger.getInstance();
        if(document.hidden){
            logger?.info("handlevisibilitychange: 裏に回った（たぶん）");
            this.traceLog("[WebSocketTrace] handlevisibilitychange: 裏に回った（たぶん）");
        } else{
            logger?.info("handlevisibilitychange: 表になった（たぶん）");
            this.traceLog("[WebSocketTrace] handlevisibilitychange: 表になった（たぶん）");
            if(this.disconnectDialogRef.current.checkOpen() === true) {
                // this.websocket.disconnect(false, "");
                this.wsClient.disconnect(false);
                this.checkReconnectWebSocket();
            }
        }
    }

    // webSocket自動再接続対応
    // 自動再接続の（単位当たりの）回数チェック
    checkReconWebSocketActionCount = () => {
        let nowTime = new Date().getTime();
        let beforeTime = this.reconWebSocketBeforeTime;
        beforeTime += (this.getReconWebSocketActionInitTime()*60*1000);   // 1H
        if(nowTime > beforeTime) {
            // 前回の自動再接続から一定時間経過していたら回数をクリア
            this.traceLog("[WebSocketTrace] ActionCount clear before=["+this.reconWebSocketBeforeTime+"] nowTime=["+nowTime+"]");
            this.reconWebSocketActionCount = 0;
        }
    }

    // webSocket自動再接続対応
    // 自動再接続チェック
    checkReconnectWebSocket = () => {
        /*
        if(this.reconWebSocketDisconnect === 0) {
            this.traceLog("[WebSocketTrace] skip reconnect close by server");
            return;
        }
        if(this.websocket.getCloseState() === true) {
            this.traceLog("[WebSocketTrace] skip reconnect close by server");
            return;
        }
        */
        let nowTime = new Date().getTime();
        let waitTime = ((this.state?.floorData?.pingInterval) * 2 * 1000) * 2;  // オフラインになるのを待つため、２倍する
        let openTime = 0;
        openTime = this.disconnectDialogRef?.current?.getOpenStartTime();
        if((openTime + waitTime) > nowTime) {
            this.traceLog("[WebSocketTrace] skip reconnect wait be offline in server : now=["+nowTime+"] open=["+openTime+"]");
            return;
        }
        if(this.reconWebSocketReleaseFloor === 1) {
            this.traceLog("[WebSocketTrace] skip reconnect release request by server");
            return;
        }
        if(window.navigator.onLine) {
            this.checkReconWebSocketActionCount();  // 前回から一定時間離れていたらカウントクリア
            this.traceLog("[WebSocketTrace] online is true  retryCount=["+this.reconWebSocketRetryCount+"] execCount=["+this.reconWebSocketActionCount+"]");
            if(this.reconWebSocketRetryCount < this.getReconWebSocketRetryMax() && this.reconWebSocketActionCount < this.getReconWebSocketActionMax()) {
                this.checkReconnectFloorWebSocket(this.websocketPath);
            } else {
                this.traceLog("[WebSocketTrace] skip reconnect retry over  retry=["+this.reconWebSocketRetryCount+"] exec=["+this.reconWebSocketActionCount+"]");
            }
        } else {
            this.traceLog("[WebSocketTrace] skip reconnect online is false");
        }
    }

    /**
     * WebSocket再接続時に使用されるメソッド
     * フロントエンドが保有しているWebSocket接続先のサーバーとDBが保持しているフロアのWebSocketサーバーが
     * 一致しているか確認する。一致している場合はそのまま処理継続。
     * 違うサーバーの場合は接続先を変えて接続。
     * 起動していないフロアの場合はログイン画面に戻す
     * @param websocket 
     */
    checkReconnectFloorWebSocket = (websocket: string) => {
        var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("websocket_url", websocket);
        // axios.post("/api/user/floor/websocket/check", params)
        //     .then((e: AxiosResponse) => {
        //         if(e.data === "OK") {
        //             // 変更なし
        //         } else if (e.data === "NG") {
        //             let signinPagePath = Utility.getSigninPage();
        //             this.props.history.push(signinPagePath);
        //             return;
        //         } else {
        //             this.websocketPath = e.data;
        //         }
        //         let retval = this.sendReconnectWebSocket();
        //         if(retval === 1) {
        //             this.traceLog("[WebSocketTrace] reconnect Yes");
        //             this.reconWebSocketRetryCount++;
        //         } else {
        //             this.traceLog("[WebSocketTrace] reconnect No");
        //         }
        //     }).catch(err => {
                
        //     });
        
        this.httpClient.checkWebSocket(sessionStorage.getItem("TABID") as string, Number(websocket))
            .then((data: string) => {
                if (data === JFS_ERRORS.OK.code)  {
                    // 変更なし
                } else if (data === "NG") {
                    let signinPagePath = Utility.getSigninPage();
                    this.props.history.push(signinPagePath);
                    return;
                } else {
                    this.websocketPath = data;
                    this.websocketNumber = Number(data);
                }
                let retval = this.sendReconnectWebSocket();
                if(retval === 1) {
                    this.traceLog("[WebSocketTrace] reconnect Yes");
                    this.reconWebSocketRetryCount++;
                } else {
                    this.traceLog("[WebSocketTrace] reconnect No");
                }
            }).catch(err => {
                console.info('check websocket error');
            });
    }

    // webSocket自動再接続対応
    sendReconnectWebSocket = () => {
        this.traceLog("[WebSocketTrace] sendReconnectWebSocket");
        // let retval = this.websocket.reconnect();
        let retval = this.wsClient.reconnect(sessionStorage.getItem("TABID") as string);
        //const checkret = this.websocket.checkConnect();
        //if(checkret === "OK") {
        //    retval = 0;
        //}
        return retval;
    }

    // #284
    // webrtc 終了を設定する
    setDisconnectWebrtc = () => {
        let myUser = this.myUserRef?.current?.getUser() as User;
        if(myUser === undefined || myUser === null) {
            this.traceLog("[WebSocketTrace] skip disconnect webrtc : myUser is null");
            return;
        }
        let tempMyUser = User.copyUser(myUser) as User;
        if(tempMyUser.webRtcCall === true) {
            tempMyUser.webRtcCall = false;
            this.myUserRef.current.setUser(tempMyUser);
            this.traceLog("[WebSocketTrace] set disconnect webrtc");
        }
    }

    // 多重ログイン処理
    sendCheckedLogin = () => {
        // this.sendWebSocket("CHECKED_LOGIN", {});
        try {
            this.wsClient.sendCheckedSignin();
        } catch (error) {
            console.error(error);            
        }
    }

    // 新規メモの送信
    sendNewMemo = (tabId: string, groupId: number, memo: string) => {
        // this.sendWebSocket("NEW_MEMO", { tabId, groupId, memo });
        try {
            this.wsClient.sendNewMemo(tabId, groupId, memo);
        } catch (error) {
            console.error(error);            
        }
    }

    // メモ削除の送信
    sendDeleteMemo = (tabId: string, groupId: number, memoId: number) => {
        // this.sendWebSocket("DELETE_MEMO", { tabId, groupId, memoId });
        try {
            this.wsClient.sendDeleteMemo(tabId, groupId, memoId);
        } catch (error) {
            console.error(error);            
        }
    }

    // メモが未読から既読になったことを送信
    sendReadMemo = (tabId: string, groupId: number, memoId: number) => {
        // this.sendWebSocket("READ_MEMO", { tabId, groupId, memoId })
        try {
            this.wsClient.sendReadMemo(tabId, groupId, memoId);
        } catch (error) {
            console.error(error);            
        }
    }

    sendMeetingRoomLock = (id: number, locked: boolean) => {
        // this.sendWebSocket("MEETING_ROOM_LOCK", { id, locked }); // ない
        try {
            this.wsClient.sendMeetingRoomLock(id, locked);
        } catch (error) {
            console.error(error);            
        }
    }

    sendMeetingRoomLockJudgedFromObjectId = (id: number, locked: boolean) => {
        // this.sendWebSocket("MEETING_ROOM_LOCK", { id, locked }); // ない
        try {
            this.wsClient.sendMeetingRoomLockJudgedFromObjectId(id, locked);
        } catch (error) {
            console.error(error);            
        }
    }

    sendInternalNotice = (message: string, target: string, color: string, checkedRingAlarm: boolean) => {
        try {
            this.wsClient.sendInternalNotice(message, target, color, checkedRingAlarm);
        } catch (error) {
            console.error(error);            
        }
    }

    // FAMcampus用処理
    // 指定したfloorIdのUsersに面談室の入室待ちを知らせる
    sendNewVisitor = (floorId: number, userId:number, targetFloorId:number) => {
        // this.sendWebSocket("NEW_VISITOR",{floorId,userId,targetFloorId});
        try {
            this.wsClient.sendPrivacyRoomNewVisitor(floorId, userId, targetFloorId);
        } catch (error) {
            console.error(error);
        }
    }

    // FAMcampus用処理
    // 指定したfloorIdのUsersに面談室の一斉退室を知らせる
    sendLeaveRoom = (floorId: number) => {
        // this.sendWebSocket("LEAVE_ROOM",{floorId});
        try {
            this.wsClient.sendLeavePrivacyRoom(floorId);
        } catch (error) {
            console.error(error);
        }
    }

    // FAMcampus用処理
    // userIdに対してプライバシールームへの入室の「許可/許可しない」を送信する
    sendPrivacyRoomPermission = (userId: number, floorId: number, permissionPrivacyRoom: boolean) => {
        // this.sendWebSocket("PRIVACY_ROOM_PERMISSION",{userId,floorId,permissionPrivacyRoom});
        try {
            this.wsClient.sendPrivacyRoomPermission(userId, floorId, permissionPrivacyRoom);
        } catch (error) {
            console.error(error);
        }
    }

    // FAMcampus用処理
    // 一般ユーザから管理者ユーザへの許可申請のキャンセルとタイムアウトを知らせる
    sendPrivacyRoomTimeOut = (kind: number, floorId: number) => {
        // this.sendWebSocket("PRIVACY_ROOM_TIME_OUT",{kind,floorId});
        try {
            this.wsClient.sendPrivacyRoomTimeOut(kind, floorId);
        } catch (error) {
            console.error(error);
        }
    }

    // FAMcampus用処理
    // 一般ユーザがフロア移動したことを管理者ユーザに知らせる
    sendEnterPrivacyRoom = (floorId: number) => {
        // this.sendWebSocket("ENTER_PRIVACY_ROOM",{floorId});
        try {
            this.wsClient.sendEnterPrivacyRoom(floorId);
        } catch (error) {
            console.error(error);
        }
    }

    // FAMcampus用処理
    // (共通)プライバシールームに移動したら、他の人にプライバシールームの情報をセットしてもらう
    sendMovePrivacyRoomInfo = (stayPrivacyroom: boolean, preFloorId: number, preFloorName: string) => {
        // this.sendWebSocket("MOVE_PRIVACY_ROOM_INFO",{stayPrivacyroom,preFloorId,preFloorName});
        try {
            this.wsClient.sendMovePrivacyRoomInfo(stayPrivacyroom, preFloorId, preFloorName);
        } catch (error) {
            console.error(error);
        }
    }

    // FAMcampus要処理
    // リロード時にプライバシールームに関するsessionが消えてしまうことの対策
    sendPrivacyRoomInfoCheck = () => {
        // this.sendWebSocket("PRIVACY_ROOM_INFO_CHECK",{});
        try {
            this.wsClient.sendPrivacyRoomInfoCheck();
        } catch (error) {
            console.error(error);
        }
    }

    // 指定したidを持つUserのisZoomSpeakableを、指定した送信する値にする
    sendZoomSpeakable = (id: number, isZoomSpeakable: boolean) => {
        // this.sendWebSocket("UPDATE_ZOOM_SPEAKABLE", { id, isZoomSpeakable });
        try {
            this.wsClient.sendZoomSpeakable(id, isZoomSpeakable);
        } catch (error) {
            console.error(error);
        }
    }

    // Zoom Room へのjoin状態を送信
    sendZoomJoin = (id: number, zoomMeetingId: number) => {
        // this.sendWebSocket("UPDATE_ZOOM_JOIN", { id, zoomMeetingId });
        try {
            this.wsClient.sendZoomJoin(id, zoomMeetingId);
        } catch (error) {
            console.error(error);
        }
    }

    // TODO:ここを消してエラーが発生する部分を修正する必要がある
    sendWebSocket = (action: string, object: object) => {
        let data: SendAction = new SendAction();
        data.action = action;
        data.object = object;
        let json = JSON.stringify(data);
        // this.websocket.send(json);
    }

    /** ここからマウス、タッチのイベント関連 */
    onMouseDown = (event: any) => {
        const { x, y, clientX, clientY } = this.translatePositionForPC(event)
        this.handleOnStart(event, x, y, clientX, clientY, true, false);
    }

    onMouseMove = (event: any) => {
        const { x, y, clientX, clientY } = this.translatePositionForPC(event)
        // // console.log(x + " : " + y + " : " + clientX + " : " + clientY);
        this.handleOnMove(event, x, y, clientX, clientY, true, false);
    };

    onMouseUp = (event: any) => {
        const { x, y, clientX, clientY } = this.translatePositionForPC(event)
        this.handleOnEnd(event, x, y, clientX, clientY, true, false);
    };

    onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        //event.preventDefault();
        // event.stopPropagation();
        const { x, y, clientX, clientY } = this.translatePositionForMobile(event, false)
        this.handleOnStart(event, x, y, clientX, clientY, false, true);
    }

    onTouchMove = (event: any) => {
        //event.preventDefault();
        // event.stopPropagation();
        const { x, y, clientX, clientY } = this.translatePositionForMobile(event, false)
        this.handleOnMove(event, x, y, clientX, clientY, false, true);
    }

    onTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        //event.preventDefault();
        // event.stopPropagation();
        const { x, y, clientX, clientY } = this.translatePositionForMobile(event, true)
        this.handleOnEnd(event, x, y, clientX, clientY, false, true);
    }

    // 右クリックでのメニュー表示
    onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        const { x, y } = this.translatePositionForPC(event)
        this.jumpToPosition = {
            top: this.floorWrapperRef.current.scrollTop,
            left: this.floorWrapperRef.current.scrollLeft,
            x,
            y,
        };
        this.toggleJumpToDialog(true, x, y);
    }

    /**
     *  mouseイベントから補正した位置関連データを作成する
     * @param event
     */
    translatePositionForPC = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { scrollTop, scrollLeft, offsetTop, offsetLeft } = this.floorWrapperRef.current
        if (this.state !== null && this.state.fitScale !== undefined) {
            return ({
                x: (scrollLeft + event.clientX - offsetLeft) / this.state?.fitScale,
                y: (scrollTop + event.clientY - offsetTop) / this.state?.fitScale,
                clientX: (event.clientX - offsetLeft) / this.state?.fitScale,
                clientY: (event.clientY - offsetTop) / this.state?.fitScale,
            })
        } else {
            return ({
                x: scrollLeft + event.clientX - offsetLeft,
                y: scrollTop + event.clientY - offsetTop,
                clientX: event.clientX - offsetLeft,
                clientY: event.clientY - offsetTop
            })
        }
    }

    /**
     *  touchイベントから補正した位置関連データを作成する
     * @param event
     */
    translatePositionForMobile = (event: React.TouchEvent<HTMLDivElement>, isTouchEnd: boolean) => {
        const { scrollTop, scrollLeft, offsetTop, offsetLeft } = this.floorWrapperRef.current
        const clientX = isTouchEnd ? event.changedTouches[0].clientX : event.touches[0].clientX || 0;
        const clientY = isTouchEnd ? event.changedTouches[0].clientY : event.touches[0].clientY || 0;
        if (this.state !== null && this.state.fitScale !== undefined) {
            return ({
                x: (scrollLeft + clientX - offsetLeft) / this.state?.fitScale,
                y: (scrollTop + clientY - offsetTop) / this.state?.fitScale,
                clientX: (clientX - offsetLeft) / this.state?.fitScale,
                clientY: (clientY - offsetTop) / this.state?.fitScale,
            })
        } else {
            return ({
                x: scrollLeft + clientX - offsetLeft,
                y: scrollTop + clientY - offsetTop,
                clientX: clientX - offsetLeft,
                clientY: clientY - offsetTop
            })
        }
    }


    // 自アバターをドラッグ開始する座標。移動開始までの遊びを計算する為に使用している。
    private grabStartX: number = 0;
    private grabStartY: number = 0;
    // ドラッグ開始時間
    private grabStartTime: number = 0;
    /**
     * マウスとタッチのStartイベント
     * @param event 
     * @param x 
     * @param y 
     * @param clientX 
     * @param clientY 
     * @param isMouse 
     * @param isTouch 
     */
    handleOnStart = (event: any, x: number, y: number, clientX: number, clientY: number, isMouse: boolean, isTouch: boolean) => {
        // マウス右クリック時、本イベントは終了する。
        // 右クリック時の処理はonContextMenuで実装している。
        if (event?.which === 3) return;

        // iPad Safariでのタップ長押し対策で入れてみたが、動作改善されず。
        // かつ、パンができなくなった。
        // if (WebrtcService.isIOSDevice()){
        //     event.preventDefault();      
        //     event.stopPropagation();
        // }

        // タッチデバイスでの「ここに移動しますか？」を消す
        if (isTouch && event.targetTouches.length === 1 && event.currentTarget.id === "floorDiv") {
            this.pointerPosition = {
                left: this.floorWrapperRef.current.scrollLeft, // スクロール値
                top: this.floorWrapperRef.current.scrollTop, // スクロール値
                x: clientX,
                y: clientY,
            };
            this.toggleJumpToDialog(false);
        }

        let myUser = this.myUserRef.current.getUser() as User;
        let avatarVideoWidth = myUser.isLargeVideo ? getAvatarVideoSize(myUser.isLargeVideo) : 0;
        let avatarVideoHeight = myUser.isLargeVideo ? getAvatarVideoSize(myUser.isLargeVideo) : 0;
        // waiting時はavatar drag不可
        if (!myUser.isMediaWaiting && myUser.x - avatarVideoWidth / 2 < x && x < myUser.x + myUser.width + avatarVideoWidth / 2 && myUser.y - avatarVideoHeight < y && y < myUser.y + myUser.height) {
            // window外でpointerupを検知するために設定(handleOnEnd内でreleaseする)
            this.floorRef.current.setPointerCapture(event.pointerId);

            event.preventDefault();
            // 自アバターのマウス・タッチ移動開始
            this.isDragMe = true;
            this.grabStartX = x;
            this.grabStartY = y;
            this.isDragFloor = false;
            this.isDraggingFloor = false;

            if (isTouch) {
                // if (this.floorRef.current) this.floorRef.current.style.touchAction = "none"
                const target = document.getElementById("floorDiv") as HTMLDivElement;
                target.addEventListener('touchmove', this.noScroll, { passive: false });
            }

        } else {
            // Floorのドラッグスクロール開始
            if (this.floorRef.current) {
                this.isDragMe = false;
                this.isDragFloor = true;
                this.isDraggingFloor = false;
                this.grabStartX = x;
                this.grabStartY = y;
                this.grabStartTime = new Date().getTime();

                this.floorRef.current.style.cursor = 'grabbing';
                this.floorRef.current.style.userSelect = 'none';

                if (isMouse) {
                    // ドラッグスタート時のマウス情報
                    this.pointerPosition = {
                        //left: this.floorRef.current.scrollLeft, // NG: scrollしても0のまま
                        //top: this.floorRef.current.scrollTop,// NG
                        left: this.floorWrapperRef.current.scrollLeft, // スクロール値
                        top: this.floorWrapperRef.current.scrollTop, // スクロール値
                        x: clientX,
                        y: clientY,
                    };
                }
            }
        }
    }

    handleOnMove = (event: any, x: number, y: number, clientX: number, clientY: number, isMouse: boolean, isTouch: boolean) => {
        // iPad Safariでのタップ長押し対策で入れてみたが、動作改善されず。
        // かつ、パンができなくなった。
        // if (WebrtcService.isIOSDevice()){
        //     event.preventDefault();      
        //     event.stopPropagation();
        // }

        if (this.isDragMe) {
            event.preventDefault();
            // PointerEventで過敏に反応すると、アバターメニューが開けなくなる問題がある為、移動までの遊びを追加
            if (this.grabStartX - x < 10 && x - this.grabStartX < 10) {
                if (this.grabStartY - y < 10 && y - this.grabStartY < 10) {
                    return;
                }
            }
            let myUser = this.myUserRef.current.getUser();
            // setMyUser(temp);
            let temp = User.copyUser(myUser);
            const adjust = this.adjustScreenPosition(x, y);// floor端の場合、座標補正する

            temp.x = adjust.x;
            temp.y = adjust.y;
            // this.setState({
            //     myUser: temp,
            // })
            this.myUserRef.current.setUser(temp);
            this.sendMove(temp);
            this.isDraggingMe = true;

            this.myMoveAutoScroll(x, y);
            if (this.myUserRef.current.getOpenMenu()) this.myUserRef.current.click(); // UserMenu close           
        } else if (this.isDragFloor && this.floorRef.current && !this.isDraggingFloorObject) {
            const margin = 10;
            if (-margin < x - this.grabStartX && x - this.grabStartX < margin) {
                if (-margin < y - this.grabStartY && y - this.grabStartY < margin) {
                    return;
                }
            }
            this.isDraggingFloor = true;

            // How far the mouse has been moved
            if (isMouse) {
                // window外でpointerupを検知するために設定(handleOnEnd内でreleaseする)
                // if文の条件がないとボタンなどが押下できなくなる。
                if (this.pointerId !== event.pointerId && (event.target.id === "floorDiv" || event?.target?.className?.includes(NEED_TO_POINTER_CAPTURE))) {
                    this.pointerId = event.pointerId;
                    this.floorRef.current.setPointerCapture(event.pointerId);
                }

                const dx = ( clientX - this.pointerPosition.x ) * this.state?.fitScale;
                const dy = ( clientY - this.pointerPosition.y ) * this.state?.fitScale;

                // console.log(this.pointerPosition.left - dx)
                this.floorWrapperRef.current.scrollTo({
                    top: this.pointerPosition.top - dy,
                    left: this.pointerPosition.left - dx,
                });
            }
        }
    }

    handleOnEnd = (event: any, x: number, y: number, clientX: number, clientY: number, isMouse: boolean, isTouch: boolean) => {
        // if(event !== null) {
        //     alert(event.which + " : " + event.pointerType + " + " +  (new Date().getTime() - this.grabStartTime) + " : " + event.currentTarget.id + " : " + this.isDraggingFloor + " : " + this.isDragMe);
        // }
        // マウス右クリック時
        if (event?.which === 3) return;

        // iPad Safariでのタップ長押し対策で入れてみたが、動作改善されず。
        // かつ、パンができなくなった。
        // if (WebrtcService.isIOSDevice()){
        //     event.preventDefault();      
        //     event.stopPropagation();
        // }

        // フロアエディター有効、フロアオブジェクト選択中で床をクリックした場合に設置確定
        if(!this.isDraggingFloorObject && this.state?.floorEditMode && this.state?.isFloorObjSelected && !this.isDragMe && !this.isDraggingFloor && this.isDragFloor){
            // this.sendWebSocket("FLOOR_EDIT", this.movedFloorObject);
            try {
                this.wsClient.sendFloorEdit(this.movedFloorObject);
            } catch (error) {
                console.error(error);
            }
            this.resetSelectedFloorObject();
        }

        // タッチデバイスでの「ここに移動しますか？」の処理
        // console.log(new Date().getTime() - this.grabStartTime)
        if (event?.pointerType === "touch" && this.isDraggingFloor === false && this.isDragMe === false) {
            if (event.currentTarget.id === "floorDiv" && (new Date().getTime() - this.grabStartTime > 300)) {
                event.preventDefault();
                this.jumpToPosition = {
                    top: this.floorWrapperRef.current.scrollTop,
                    left: this.floorWrapperRef.current.scrollLeft,
                    x, // floor上の絶対x座標
                    y, // floor上の絶対y座標
                };
                this.grabStartTime = new Date().getTime();
                // ダイアログをタッチ点に表示する
                this.toggleJumpToDialog(true, x, y);
                return;
            }
        }

        // window外でpointerupさせる（handleOnMoveで設定したpointerをreleaseする）
        if (event && this.pointerId === event.pointerId) {
            this.pointerId = null;
            this.floorRef.current.releasePointerCapture(event.pointerId);
        }

        if (this.isDragMe) {
            // if(isTouch) {
            //     if (this.floorRef.current) this.floorRef.current.style.touchAction = "auto"
            // }
            const target = document.getElementById("floorDiv") as HTMLDivElement;
            target.removeEventListener('touchmove', this.noScroll);

            this.isDragMe = false;
            this.isDragFloor = false;

            let myUser = this.myUserRef.current.getUser();
            let temp = User.copyUser(myUser);

            // マウスダウンの場合、クリックを通知
            if (!this.isDraggingMe) {
                this.myUserRef.current?.click();// アバターメニューを開く
            } else {
                if (event !== null) {
                    const adjust = this.adjustScreenPosition(x, y);// floor端の場合、座標補正する
                    temp.x = adjust.x;
                    temp.y = adjust.y;
                }
                // ユーザ契機のイベントのみアニメーションを初期化
                if (event != null) {
                    this.setHitEffectIdOfMyUser(AvatarEffectType.None);
                }
                this.sendEndMove(temp);
            }
            this.isDraggingMe = false;
            this.hittedonDrag = false;  // #809 ドラッグ中に話しかけられたか
            // setMyUser(temp);
            // this.setState({
            //     myUser: temp,
            // })
            this.myUserRef.current.setUser(temp);

        } else if (this.isDragFloor && this.floorRef.current) {
            this.isDragMe = false;
            this.isDragFloor = false;
            this.isDraggingFloor = false;

            this.floorRef.current.style.cursor = 'default';
            this.floorRef.current.style.removeProperty('user-select');

            if (this.myUserRef.current.getOpenMenu()) this.myUserRef.current.click();
        }
    }

    /**
     * ユーザーを指定位置へジャンプ移動させる
     * @param x floor上の絶対x座標
     * @param y floor上の絶対y座標
     */
    jumpToMove = (x: number, y: number) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        let adjust = this.adjustScreenPosition(x, y);
        temp.x = adjust.x;
        temp.y = adjust.y;
        this.sendEndMove(temp);
    }

    /**
     * floorをスクロールさせる
     * @param top floorのtopスクロール量
     * @param left floorのleftスクロール量
     */
    scrollFloor = (top: number, left: number) => {
        this.floorWrapperRef.current.scrollTo({ top, left });
    }

    /**
     * floorWrapper端にavatarがドラッグされた際にwrapperをスクロールさせる
     * @param x floor上での絶対X座標
     * @param y floor上での絶対Y座標
     */
    myMoveAutoScroll = (x: number, y: number) => {
        const target = document.getElementById("floorWrapper") as HTMLDivElement;
        let MARGIN = 10; // avatarと各wrapper端の距離
        let DETECT_WIDTH = 100; // 計算エリア幅
        let offsetHeightTmp = target.offsetHeight;
        let offsetWidthTmp = target.offsetWidth;
        let scrollTopTmp = target.scrollTop;
        let scrollLeftTmp = target.scrollLeft;
        if( this.state?.fitScale !== undefined ){
            DETECT_WIDTH   /= this.state?.fitScale;
            offsetHeightTmp /= this.state?.fitScale;
            offsetWidthTmp /= this.state?.fitScale;
            scrollTopTmp   /= this.state?.fitScale;
            scrollLeftTmp  /= this.state?.fitScale;
        }

        // iPhoneの場合、画面端っこのスクロールを遅く
        if( this.isiPhoneLayout ){
            MARGIN = 5;
        }

        // 上へ
        if (y - scrollTopTmp < DETECT_WIDTH) {
            //console.log("上端スクロール");
            let calcTop = target.scrollTop - MARGIN;
            if (calcTop <= 0) {
            }else{
                this.scrollFloor(calcTop, target.scrollLeft);
            }
        }
        // 下へ
        if (y - scrollTopTmp > offsetHeightTmp - DETECT_WIDTH) {
            if ( scrollTopTmp >= this.state.floorData.floorHeight - offsetHeightTmp) {
                //console.log("下終端");
            }else{
                let calcTop = target.scrollTop + MARGIN; //* this.state?.fitScale;
                //console.log("下端スクロール", calcTop, this.state.floorData.floorHeight, offsetHeightTmp, this.state.floorData.floorHeight - offsetHeightTmp);
                this.scrollFloor(calcTop, target.scrollLeft);
            }
        }
        // 左へ
        if (x - scrollLeftTmp < DETECT_WIDTH ) {
            let calcLeft = target.scrollLeft - MARGIN;
            if (calcLeft <= 0) {
            }else{
                this.scrollFloor(target.scrollTop, calcLeft);
            }
        }
        // 右へ
        // x                                             : avatarのx座標（絶対座標）（scale影響なし）
        // scrollLeftTmp                                 : floorWrapperのxスクロール量（scale影響なし）
        // x - scrollLeftTmp                             : 画面表示領域上のavatar x座標（scale影響なし）
        // calcLeft                                      : floorWrapperのxスクロール量＋10
        // this.state.floorData.floorWidth - offsetWidth : スクロールの右終端値
        //console.log("右端スクロール？", x, target.scrollLeft, scrollLeftTmp, x - scrollLeftTmp, offsetWidthTmp, target.offsetWidth, offsetWidthTmp - DETECT_WIDTH);
        if (x - scrollLeftTmp > offsetWidthTmp - DETECT_WIDTH) {
            if ( scrollLeftTmp >= this.state.floorData.floorWidth - offsetWidthTmp) {
                //console.log("右終端");
            }else{
                let calcLeft = target.scrollLeft + MARGIN; //* this.state?.fitScale;
                this.scrollFloor(target.scrollTop, calcLeft);
            }
        }
    }

    /**
     * avatar移動時のfloor端での座標変換
     * @param x floor上での絶対X座標
     * @param y floor上での絶対Y座標
     */
    private adjustScreenPosition = (x: number, y: number) => {
        const myUser = this.myUserRef.current.getUser();
        const DISPLAYNAME_HEIGHT = 16; // BaseUser.tsxのclasses.displayNameのheight
        const DISPLAYNAME_MAXWIDTH = 90; // BaseUser.tsxの「ＷＷＷＷＷＷＷＷ」に対するNAME_WIDTH: 87
        let tempX = x;
        let tempY = y;

        let avatarVideoWidth = myUser.isLargeVideo ? 0.5 * getAvatarVideoSize(myUser.isLargeVideo) : 0;  // 0.5は微調整
        let avatarVideoHeight = myUser.isLargeVideo ? 0.5 * getAvatarVideoSize(myUser.isLargeVideo) : 0;
        tempX = x - myUser.width / 2 - avatarVideoWidth / 2;
        tempY = y - myUser.height / 2 + avatarVideoHeight / 2;

        // 上端
        if (tempY < 0) {
            tempY = 0;
        }
        // 下端
        if (tempY > this.state?.floorData.floorHeight - myUser.height - DISPLAYNAME_HEIGHT) {
            tempY = this.state?.floorData.floorHeight - myUser.height - DISPLAYNAME_HEIGHT;
        }
        // 左端
        if (tempX < 0 - myUser.width / 2) {
            tempX =0 - myUser.width / 2;
        }
        // 右端
        if (tempX > this.state?.floorData.floorWidth - myUser.width / 2 - DISPLAYNAME_MAXWIDTH / 2) {
            tempX = this.state?.floorData.floorWidth - myUser.width / 2 - DISPLAYNAME_MAXWIDTH / 2;
        }

        return { x: Math.floor(tempX), y: Math.floor(tempY) };
    }

    /**
     * 「ここへ移動しますか？」で「はい」をクリックしたときの処理
     * @event 
     */
    handleJumpToMoveDialog = (event: any) => {
        this.jumpToMove(this.jumpToPosition.x, this.jumpToPosition.y);
        this.toggleJumpToDialog(false);
    }

    handleJumpToMySeat = () => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        if (temp.mySeat) {
            const { x, y } = temp.mySeat;
            const { offsetWidth, offsetHeight } = this.floorWrapperRef.current;
            // 自席へ移動
            this.jumpToMove(x, y);
            // 自席へスクロール
            this.scrollFloor( y * this.state?.fitScale - offsetHeight / 2, x * this.state?.fitScale - offsetWidth / 2);
        }
    }

    changeShowToolLauncher = () => {
        this.setState({
            showToolLauncher: !this.state.showToolLauncher,
        })
    }

    changeEnabledToolLauncher = () => {
        this.setState({
            enabledToolLauncher: true,   
            showToolLauncher: true,    
        })
    }

    /**
     * tutorialを開く
     *  tutorialは初期起動時とサイドバーボタンから表示可能なため、floorの子に配置している。
     */
    hundleOpenTutorial = () => {
        this.tutorialDialogRef.current?.setTutorialOpen(true);
    }

    /**
     * お知らせを開く
     */
     handleOpenInformation = () => {
        this.InformationDialogRef.current?.setInformationOpen(true);
    }

    /**
     * リリースノートを開く     リリースノート対応
     */
    handleOpenReleaseNote = () => {
        this.ReleaseNoteDialogRef.current?.setReleaseNoteOpen(true);
    }

    /**
     * 入退データを開く
     */
     handleOpenGetAttendance = () => {
        this.GetAttendanceDialogRef.current?.setGetAttendanceOpen(true);
    }

    /**
     * 何も処理をしない場合に使用するメソッド
     */
    dummy = () => {
    }

    /**
     * userが画面中心になるようにfloorをスクロールする
     * @params isEffect 視点移動後にエフェクトを出すかどうか
     */
    centeringViewByMyUser = (isEffect?: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        const { offsetWidth, offsetHeight } = this.floorWrapperRef.current;
        this.scrollFloor(myUser.y * this.state?.fitScale - offsetHeight / 2 + myUser.height/2 * this.state?.fitScale ,
                         myUser.x * this.state?.fitScale - offsetWidth  / 2 + myUser.width /2 * this.state?.fitScale);
        if (isEffect) {
            this.setHitEffectIdOfMyUser(AvatarEffectType.None);// 直前のエフェクトをクリア
            setTimeout(() => this.setHitEffectIdOfMyUser(AvatarEffectType.Login), 10); // setTimeoutを挟まないとエフェクトが出ないことがある
        }
    }

    /**
     * フロア全体が見えるようにスクロールする
     * @params isEffect 視点移動後にエフェクトを出すかどうか
     */
    overlookViewByMyUser = (isEffect?: boolean) => {
        const { offsetWidth, offsetHeight } = this.floorWrapperRef.current;
        this.scrollFloor(offsetHeight - window.innerHeight, offsetWidth - window.innerWidth);
        if (isEffect) {
            this.setHitEffectIdOfMyUser(AvatarEffectType.None);// 直前のエフェクトをクリア
            setTimeout(() => this.setHitEffectIdOfMyUser(AvatarEffectType.Login), 10); // setTimeoutを挟まないとエフェクトが出ないことがある
        }
    }

    /**
     * 指定された位置のユーザを視点中心にする
     * 
     * @params user 視点中心にするユーザ
     */
    centeringViewToUser = (user: User) => {
        const { x, y, width, height } = user;
        const { offsetWidth, offsetHeight } = this.floorWrapperRef.current;
        this.scrollFloor(
            y * this.state?.fitScale - offsetHeight / 2 + height / 2 * this.state?.fitScale,
            x * this.state?.fitScale - offsetWidth / 2 + width / 2 * this.state?.fitScale
        );
    }

    /**
     * ターゲットのユーザーがいるフロアへ移動し、視点中心にする
     */
    handleGoToSee = async (target: User | SearchedUser, isSameFloor: boolean) => {
        if (isSameFloor) {
            // 同じフロアの場合
            // OtherUsers animation change
            const users = this.otherUsersRef.current?.getUsers() as User[];
            const temps = users.map(user => {
                if (user.subId === target.subId) {
                    setTimeout(() => this.setIsGoToSeeOfOtherUser(user.id, false), this.waitingTime);
                    return { ...user, isGoToSee: true }
                } else {
                    return user
                }
            });
            this.otherUsersRef.current?.setUsers(temps);
            // centering
            this.centeringViewToUser(target as User);
        } else {
            // 異なるフロアの場合
            this.subIdOfGoToSeeTarget = target.subId;
            this.handleMoveFloor((target as SearchedUser).floorId);
        }
    } 

    /**
     * 「会いに行く」の対象ユーザ情報を取得する
     *   
     *   MyUserと同じフロアの場合: User型
     *   MyUserと異なるフロアの場合: SearchedUser型
     */
    getTargetOfGoToSee = async (subId: string) => {
        try {
            const users = this.otherUsersRef.current?.getUsers() as User[];
            let target: User | SearchedUser | undefined;
            let isSameFloor = true;
            // MyUserと同じフロアの場合 マルチアバターは除外
            target = users.find(user => user.subId === subId && user.isStayFloor);
            // MyUserと異なるフロアの場合
            if (!target) {
                // var params = new URLSearchParams();
                // params.append("tabId", sessionStorage.getItem("TABID") as string);
                // const res: AxiosResponse<SearchedUser> = await axios.get(`/api/user/users/${subId}`, {
                //     params,
                // });
                // target = res.data;
                target = await this.httpClient.getActiveFloorInfo(sessionStorage.getItem("TABID") as string, subId);
                isSameFloor = false;

                //プライバシールームに入っているユーザを探した場合の分岐
                //プライバシールームに入っている場合、絶対にMyUserと異なるフロアになる
                if(target.preFloorName !== null){
                    //nullじゃないので、プライバシールームに入っている

                    let targetUser = users.find(user => user.subId === subId);

                    if(target.preFloorId === this.floorId){
                        //プライバシールームに入っているユーザの移動前フロアとMyUserのフロアが同じ
                        target = targetUser;
                        isSameFloor = true;
                    }else{
                        target.floorId = target.preFloorId;
                        target.floorName = target.preFloorName;
                        target.officeName = target.preOfficeName;
                    }
                }
            }

            return { target, isSameFloor };
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    // /**
    //  * ユーザ情報コピー処理.
    //  *
    //  * @param user コピー元ユーザ情報
    //  */
    // copyUser = (user: User) => {
    //     let temp = new User();
    //     if (temp != null) {
    //         temp.id = user.id;
    //         temp.x = user.x;
    //         temp.y = user.y;
    //         temp.width = user.width;
    //         temp.height = user.height;
    //         temp.displayName = user.displayName;
    //         temp.state = user.state;
    //         temp.tubuyaki = user.tubuyaki
    //         temp.ping = user.ping;
    //         temp.isSitOnDesk = user.isSitOnDesk;
    //         temp.webRtcRoomId = user.webRtcRoomId;
    //         temp.webRtcPeerId = user.webRtcPeerId;
    //         temp.isAudioMute = user.isAudioMute;
    //         temp.isVideoMute = user.isVideoMute;
    //         temp.avatarId = user.avatarId;
    //         temp.seat = user.seat;
    //         temp.myPc = user.myPc;
    //         temp.mySeat = user.mySeat;
    //         temp.webRtcCall = user.webRtcCall;
    //         temp.webRtcMode = user.webRtcMode;
    //         temp.isScreenShare = user.isScreenShare;
    //         temp.myNameplate = user.myNameplate;
    //         temp.micVolume = user.micVolume;
    //         temp.micVolumeThreshold = user.micVolumeThreshold;
    //         temp.micMode = user.micMode;
    //         temp.isMediaWaiting = user.isMediaWaiting;
    //         temp.profile = user.profile;
    //         temp.isStayFloor = user.isStayFloor;
    //         temp.stayFloorName = user.stayFloorName;
    //         temp.isGenerateMoveEnd = user.isGenerateMoveEnd;
    //         temp.isWebRtcContinue = user.isWebRtcContinue;
    //     }
    //     return temp;
    // }
    // /**
    //  * targetのUserにsourceのUserをコピーする
    //  * @param target 
    //  * @param source 
    //  */
    // copyUserToUser = (target: User, source: User) => {
    //     if (target != null) {
    //         target.id = source.id;
    //         target.x = source.x;
    //         target.y = source.y;
    //         target.width = source.width;
    //         target.height = source.height;
    //         target.displayName = source.displayName;
    //         target.state = source.state;
    //         target.tubuyaki = source.tubuyaki
    //         target.ping = source.ping;
    //         target.isSitOnDesk = source.isSitOnDesk;
    //         target.webRtcRoomId = source.webRtcRoomId;
    //         target.webRtcPeerId = source.webRtcPeerId;
    //         target.isAudioMute = source.isAudioMute;
    //         target.isVideoMute = source.isVideoMute;
    //         target.avatarId = source.avatarId;
    //         target.seat = source.seat;
    //         target.myPc = source.myPc;
    //         target.mySeat = source.mySeat;
    //         target.webRtcCall = source.webRtcCall;
    //         target.myNameplate = source.myNameplate;
    //         target.micVolume = source.micVolume;
    //         target.isMediaWaiting = source.isMediaWaiting;
    //         target.profile = source.profile;
    //         target.isStayFloor = source.isStayFloor;
    //         target.stayFloorName = source.stayFloorName;
    //         target.isGenerateMoveEnd = source.isGenerateMoveEnd;
    //         target.isWebRtcContinue = source.isWebRtcContinue;
    //     }
    // }

    /**
     * 自席設定イベント
     */
    handleMySeatChange = (yes: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let tempUser = User.copyUser(myUser);
        // this.setState({
        //     myUser: tempUser,
        // })
        this.myUserRef.current.setUser(tempUser);
        if (yes) {
            // let sendAction: SendAction = new SendAction();
            // sendAction.action = "SET_MY_SEAT";
            // sendAction.object = myUser.seat;
            // let json = JSON.stringify(sendAction);
            // this.websocket.send(json);

            try {
                this.wsClient.sendSetMySeat(myUser.seat);
            } catch (error) {
                console.error(error);                
            }

            if (this.myUserRef.current) {
                if(this.getMyCommuting() === false){
                    console.log("座席設定:出勤状態にする");
                    
                    // #11421
                    // すぐ出勤状態にしようとしても座席がDBに設定されるまでラグがあるため、出勤状態にならない
                    // -> とりあえず少し間をあける
                    setTimeout(() => this.handleChangeMyCommuting(true), 1000);
                }
                else{
                    console.log("座席設定:出勤状態なのでなにもしない");
                }
            }
        }
    }

    /**
     * 自席解除イベント
     */
    handleMySeatRelease = (yes: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        let tempUser = User.copyUser(myUser);
        this.myUserRef.current.setUser(tempUser);
        if (yes) {
            // let sendAction: SendAction = new SendAction();
            // sendAction.action = "RELEASE_MY_SEAT";
            // sendAction.object = myUser.seat;
            // let json = JSON.stringify(sendAction);
            // this.websocket.send(json);

            try {
                this.wsClient.sendReleaseMySeat(myUser.seat)
            } catch (error) {
                console.error(error);                
            }
        }
    }

    /**
     * 席解放イベント 
     */
    // v1.8_needCheck
    handleOtherSeatRelease = (yes: boolean) => {
        let myUser = this.myUserRef.current.getUser();
        if(yes){
            /*
            let sendAction: SendAction = new SendAction();
            sendAction.action = "RELEASE_OTHER_SEAT";
            sendAction.object = myUser.seat;
            let json = JSON.stringify(sendAction);
            this.websocket.send(json);
            */
            try {
                this.wsClient.sendReleaseOtherSeat(myUser.seat)
            } catch (error) {
                const jfserr = error as JfsError;
                console.error(jfserr);                
            }
        }
    }

    /**
     * 自アバター設定イベント
     */
    handleMyAvatarChange = (yes: boolean, value?: number) => {
        let myUser = this.myUserRef.current.getUser();
        let tempUser = User.copyUser(myUser);
        this.myUserRef.current.setUser(tempUser);
        if (yes && value) {
            // let sendAction: SendAction = new SendAction();
            // sendAction.action = "SET_MY_AVATAR";
            // sendAction.object = { avatarId: value };
            // let json = JSON.stringify(sendAction);
            // this.websocket.send(json);

            try {
                this.wsClient.sendSetMyAvatar(value);
            } catch (error) {
                console.error(error);                
            }
        }
    }

    /**
     * MyUserのvideoがload完了したときにトリガーされるハンドラ
     * @param id sessionId
     */
    handleMyStreamLoaded = (id: number) => {
        // タイマーによるwaiting解除に変更したため、コメントアウト
        //this.setIsWaitingOfMyUser(false);

        // 周りの人からの見た目を大きくする
        const logger: Logger = Logger.getInstance();
        let myUser = this.myUserRef.current?.getUser() as User;
        //let otUser = this.getOtherUserFromId(id) as User;
        if(myUser === undefined || myUser === null) {
            logger.info("[WR_TRACE_JOINSTS] myStreamLoaded myUser undefined or null : id=["+id+"]");
            return;
        }
        // #11313 通話が終わっているのに、アバターが大きいままになることがある
        console.log("sendWrJoinStatus check webRtcCall="+myUser.webRtcCall+"]");
        if(myUser.webRtcCall === true) {
            myUser.isWebrtcJoin = true;
            logger.info("[WR_TRACE] myStreamLoaded sendWrJoinStatus : true  my=["+myUser.displayName+"]");
            this.sendWrJoinStatus(myUser);
        }
        // #369 エラー表示状態をクリア
        this.webrtcDeviceErrorDisp = 0;

        // joinStatusをサーバーへ通知
        let stsDat: JoinRoomStatusData = new JoinRoomStatusData();
        stsDat.joinSts = "L-Loaded";
        stsDat.myId = myUser.id;
        stsDat.roomId = myUser.webRtcRoomId;
        stsDat.OtId = 0;
        stsDat.peerId = "";
        stsDat.data = "";
        this.sendJoinRoomStatusData(stsDat);
    }

    /**
     * OtherUser videoがload開始したときにトリガーされるハンドラ
     * @param id sessionId
     */
    handleRemoteStreamLoading = (id: number) => {
        // receiveMoveUsers2内でwebRtcCallから判定するロジックへ変更したため、コメントアウト
        //this.setIsWaitingOfOtherUser(id, true)
        //setTimeout(() => this.setIsWaitingOfOtherUser(id, false), this.waitingTime)

        const logger: Logger = Logger.getInstance();
        let myUser = this.myUserRef.current?.getUser() as User;
        let otUser = this.getOtherUserFromId(id) as User;
        if(myUser === undefined || myUser === null) {
            logger.info("[WR_TRACE_JOINSTS] remoteStreamLoading myUser undefined or null : id=["+id+"]");
            return;
        }

        // joinStatusをサーバーへ通知
        let stsDat: JoinRoomStatusData = new JoinRoomStatusData();
        stsDat.joinSts = "R-Loading";
        stsDat.myId = myUser.id;
        stsDat.roomId = myUser.webRtcRoomId;
        if(otUser !== null) {
            stsDat.OtId = otUser.id;
        }
        stsDat.peerId = "";
        stsDat.data = "";
        this.sendJoinRoomStatusData(stsDat);
    }

    /**
     * OtherUser videoがload完了したときにトリガーされるハンドラ
     * @param id sessionId
     */
    handleRemoteStreamLoaded = (id: number, dev: number) => {
        // タイマーによるwaiting解除に変更したため、コメントアウト
        //this.setIsWaitingOfOtherUser(id, false)

        const logger: Logger = Logger.getInstance();
        let myUser = this.myUserRef.current?.getUser() as User;
        let otUser = this.getOtherUserFromId(id) as User;
        if(myUser === undefined || myUser === null) {
            logger.info("[WR_TRACE_JOINSTS] remoteStreamLoaded myUser undefined or null : id=["+id+"] dev=["+dev+"]");
            return;
        }
        let event = "V-R-Loaded"
        if(dev === 2) {
            event = "A-R-Loaded"
        }
        
        // 本当はVideoがロードされたらWaiting解除したいけど、相手がVideoあるかわからない？（要調査）
        //if(dev === 1) {
            if(otUser !== null) {
                //logger.info("otherInfoData : waitingTimerId=["+otUser.waitingTimerId+"] otName=["+otUser?.displayName+"] id=["+id+"]");
                if(otUser.waitingTimerId !== 0) {
                    clearTimeout(otUser.waitingTimerId);
                }
            }
            this.setIsWaitingOfOtherUser(id, false);
        //}

        // joinStatusをサーバーへ通知
        let stsDat: JoinRoomStatusData = new JoinRoomStatusData();
        stsDat.joinSts = event;
        stsDat.myId = myUser.id;
        stsDat.roomId = myUser.webRtcRoomId;
        if(otUser !== null) {
            stsDat.OtId = otUser.id;
        }
        stsDat.peerId = "";
        stsDat.data = "";
        this.sendJoinRoomStatusData(stsDat);
    }

    /**
     * joinRoom の状態を通知ハンドラ
     */
     handleUpdateJoinRoomStatus = (sid: string, pid: string, event: string, data: string) => {
        const logger: Logger = Logger.getInstance();
        
        let myUser = this.myUserRef.current?.getUser() as User;
        let otUser = this.getOtherUserFromId(parseInt(sid,10)) as User;
        if(myUser === undefined || myUser === null) {
            logger.info("[WR_TRACE_JOINSTS] JoinRoomStatus myUser undefined or null : event=["+event+"] sid=["+sid+"] pid=["+pid+"] data=["+data+"]");
            if(event === "irregular_close") {
                // #609 意図しないclose対応
                this.irregularCloseRetryTimerId = window.setTimeout(() => this.irregularCloseRetry(0, pid), 1000)
                logger.info("[WR_TRACE_JOINSTS] 意図しないcloseに対するリトライタイマーセット : name=["+"] roomId=["+"] timer=["+this.irregularCloseRetryTimerId+"]");
            }
            return;
        }
        if(pid === myUser.webRtcPeerId) {
            if(event === "peerLeave" || event === "close") {
                // #11313 通話が終わっているのに、アバターが大きいままになることがある
                myUser.isWebrtcJoin = false;
                console.log("[WR_TRACE] sendWrJoinStatus call peerLeave isWebrtcJoin=["+myUser.isWebrtcJoin+"] webRtcCall="+myUser.webRtcCall+"]");
                this.sendWrJoinStatus(myUser);
            } else if(event === "irregular_close") {
                // #609 意図しないclose対応
                this.irregularCloseRetryTimerId = window.setTimeout(() => this.irregularCloseRetry(myUser.id, pid), 1000)
                logger.info("[WR_TRACE_JOINSTS] 意図しないcloseに対するリトライタイマーセット : name=["+myUser.displayName+"] roomId=["+myUser.webRtcRoomId+"] timer=["+this.irregularCloseRetryTimerId+"]");
                return;
            } else if(event === "room") {
                // #250 joinroom 後の openイベント監視を開始
                // webrtc接続リトライ対応
                //let tmotVal = 3 * 1000;    // x秒
                let tmotVal = this.getWebrtcJoinOpenWaitTimeOut();    // mSec
                if(tmotVal > 0) {
                    if(this.webrtcJoinRetryCounter < 3) {
                        let nTime = new Date().getTime();
                        logger.info("set My-R-Timer : name=["+myUser.displayName+"] roomId="+myUser.webRtcRoomId+"] nTime=["+nTime+"] timer=["+tmotVal+"]");
                        this.watchJoinRoomStatusTimerId = window.setTimeout(() => this.watchJoinRoomStatus(myUser.id, event, nTime), tmotVal)
                        this.webrtcJoinRetryCounter++;
                    } else {
                        logger.info("My-R-Timer retry over : name=["+myUser.displayName+"] roomId="+myUser.webRtcRoomId+"]");
                    }
                }
            } else if(event === "open") {
                // #250 joinroom 後の openイベント監視を開始
                // webrtc接続リトライ対応
                if(this.watchJoinRoomStatusTimerId !== 0) {
                    let nTime = new Date().getTime();
                    logger.info("reset My-R-Timer : name=["+myUser.displayName+"] roomId="+myUser.webRtcRoomId+"] nTime=["+nTime+"]");
                    clearTimeout(this.watchJoinRoomStatusTimerId);
                    this.watchJoinRoomStatusTimerId = 0;
                }
                this.webrtcJoinRetryCounter = 0;
                // カメラ＆マイク未選択の時にMyStreamがLoadされないので、ここで周りの人からの見た目を大きくする
                if(myUser.webRtcCall === true && 
                        this.myDeviceNoSelect === 1 && this.myMicDeviceNoSelect === 1) {
                    myUser.isWebrtcJoin = true;
                    logger.info("[WR_TRACE] open event sendWrJoinStatus : true  my=["+myUser.displayName+"]");
                    this.sendWrJoinStatus(myUser);
                }
            }
            // #250 joinroom 後の openイベント監視を開始
            // webrtc接続リトライ対応
            this.webrtcJoinStatusEvent = event;
        } else {
            // #747 peerId 変更があったら通知する
            if(event === "peerChange") {
                let temp = User.copyUser(myUser);
                temp.webRtcPeerId = pid;
                this.sendWebRtcPeerId(temp);
                this.myUserRef.current.setUser(temp);
            }
        }
        // joinStatusをサーバーへ通知
        let stsDat: JoinRoomStatusData = new JoinRoomStatusData();
        stsDat.joinSts = event;
        stsDat.myId = myUser.id;
        stsDat.roomId = myUser.webRtcRoomId;
        if(otUser !== null) {
            stsDat.OtId = otUser.id;
        }
        stsDat.peerId = pid;
        stsDat.data = data;
        this.sendJoinRoomStatusData(stsDat);
    }

    /**
     * webrtc開始のリトライをセットする
     * webrtc接続リトライ対応
     *  */
    watchJoinRoomStatus = async (id: number, event: string, evTime: number) => {
        const logger: Logger = Logger.getInstance();
        let nTime = new Date().getTime();
        let myUser = this.myUserRef.current?.getUser();
        if(myUser !== undefined) {
            logger.info("check My-R-Timer : event=["+event+"]["+this.webrtcJoinStatusEvent+"] nTime=["+nTime+"] diff=["+(nTime-evTime)+"]");
            if(event === this.webrtcJoinStatusEvent) {
                let temp = User.copyUser(myUser);

                logger.info("[WR_TRACE_JOINSTS] destroyPeer call peerId=["+temp.webRtcPeerId+"]");
                await WebrtcService.destroyPeer();
                // await WebrtcService.getPeer();
                await WebrtcService.getWebRTCClient(-1);
                temp.webRtcPeerId = await WebrtcService.getPeerId();
                logger.info("[WR_TRACE_JOINSTS] getPeerId peerId=["+temp.webRtcPeerId+"]");
                this.sendWebRtcPeerId(temp);
        
                logger.info("[WR_TRACE_JOINSTS] リトライ要求セット : name=["+temp.displayName+"] roomId=["+temp.webRtcRoomId+"]");
                temp.webRtcRetryCall = true;
                this.myUserRef.current.setUser(temp);
            }
        } else {
            logger.info("[WR_TRACE_JOINSTS] リトライ要求チェック myUser is null nTime=["+nTime+"]");
        }
        this.watchJoinRoomStatusTimerId = 0;
    }
    
    /**
     * webrtc開始のリトライをセットする
     * #609 意図しないclose対応
     *  */
     irregularCloseRetry = async (id: number, pid: string) => {
        const logger: Logger = Logger.getInstance();
        let myUser = this.myUserRef.current?.getUser();
        if(myUser !== undefined && myUser !== null) {
            if(pid === myUser.webRtcPeerId) {
                let temp = User.copyUser(myUser);
                logger.info("[WR_TRACE_JOINSTS] 意図しないcloseに対するリトライ要求セット : name=["+temp.displayName+"] roomId=["+temp.webRtcRoomId+"]");
                this.props.enqueueSnackbar("ビデオ通話を再接続しています。しばらくお待ちください。");
                temp.webRtcRetryCall = true;
                this.myUserRef.current.setUser(temp);
            } else {
                logger.info("[WR_TRACE_JOINSTS] 意図しないcloseに対するリトライ要求チェック myUser.webRtcPeerId not match ["+myUser.webRtcPeerId+"]["+pid+"]");
            }
        } else {
            logger.info("[WR_TRACE_JOINSTS] 意図しないcloseに対するリトライ要求チェック myUser is null");
        }
        this.irregularCloseRetryTimerId = 0;
    }

    /**
     * デバイス選択通知     カメラ未選択時の表示対応
     */
     handleDeviceSelected = () => {
        // カメラ未選択の場合、カメラミュート表示
        const logger: Logger = Logger.getInstance();
        let tmpVideoNoSelect = false;
        if(this.getEnabledVideoNoSelectDisp() === true) {
            let myUser = this.myUserRef.current?.getUser() as User;
            if(myUser === undefined || myUser === null) {
                logger.info("handleDeviceSelected myUser undefined or null");
                return;
            }
            let videoDeviceId = localStorage.getItem("videoDeviceId");
            console.log("VideoLabel select : video=["+videoDeviceId+"]")
            if(videoDeviceId === null || videoDeviceId === "") {
                if(this.myDeviceNoSelect === 0) {
                    console.log("VideoLabel select : selected --> noSelect")
                    this.myDeviceNoSelect = 1;
                    //localStorage.setItem("videoDeviceNothing_"+myUser.id, "yes");
                    //if(myUser.isVideoMute === true) {
                        // ビデオミュート中なら、一旦ミュート解除
                    //    this.handleClickVideoMuteButton(false);
                    //} else {
                        this.handleClickVideoMuteButton(true);
                    //}
                }
                this.displayMessageNoDevice(1, true);
                tmpVideoNoSelect = true;
            } else {
                if(this.myDeviceNoSelect === 1) {
                    console.log("VideoLabel select : noSelect --> selected")
                    this.myDeviceNoSelect = 0;
                    //localStorage.setItem("videoDeviceNothing_"+myUser.id, "no");
                    this.handleClickVideoMuteButton(false);
                }
                this.displayMessageNoDevice(1, false);
            }
        //}
        // マイク未選択の場合、マイクミュート表示
        // ★★★★★一旦は、カメラミュート表示と同じ判定
        //if(this.getEnabledMicNoSelectDisp() === true) {
            //let myUser = this.myUserRef.current?.getUser() as User;
            //if(myUser === undefined || myUser === null) {
            //    logger.info("handleDeviceSelected myUser undefined or null");
            //    return;
            //}
            let audioInputDeviceId = localStorage.getItem("audioInputDeviceId");
            console.log("AudioLabel select : video=["+audioInputDeviceId+"]")
            if(audioInputDeviceId === null || audioInputDeviceId === "") {
                if(this.myMicDeviceNoSelect === 0) {
                    console.log("AudioLabel select : selected --> noSelect")
                    this.myMicDeviceNoSelect = 1;
                    this.handleClickVideoAudioMuteButton(tmpVideoNoSelect, true);
                }
                this.displayMessageNoDevice(2, true);
            } else {
                if(this.myMicDeviceNoSelect === 1) {
                    console.log("AudioLabel select : noSelect --> selected")
                    this.myMicDeviceNoSelect = 0;
                    this.handleClickVideoAudioMuteButton(tmpVideoNoSelect, false);
                }
                if(tmpVideoNoSelect === false) {
                    this.displayMessageNoDevice(2, false);
                } else {
                    logger.info("handleDeviceSelected ビデオ未選択メッセージ表示したのでクローズしない");
                }
            }
        }
    }

    /**
     * デバイスエラーのスナックバー表示通知     #369
     */
     sendDisplayErrorSnackBarToId = (device: number, toId: number) => {
        const logger: Logger = Logger.getInstance();
        logger.info("sendDisplayErrorSnackBarToId send to server");
        // let sendAction: SendAction = new SendAction();
        // sendAction.action = "DEVICE_ERROR_DISPLAY_ID";
        // sendAction.object = { deviceKind: device, sendId: toId };
        // let json = JSON.stringify(sendAction);
        // this.websocket.send(json);

        try {
            this.wsClient.sendDisplayedDeviceErrorToUser(device as UsedDevice, toId);
        } catch (error) {
            console.error(error);            
        }
    }
    
    /**
     * デバイスエラーのスナックバー表示通知     #369
     */
     handleDisplayErrorSnackBar = (device: number) => {
        const logger: Logger = Logger.getInstance();
        logger.info("handleDisplayErrorSnackBar send to server");
        // this.webrtcDeviceErrorDisp = device;
        // let sendAction: SendAction = new SendAction();
        // sendAction.action = "DEVICE_ERROR_DISPLAY";
        // sendAction.object = { deviceKind: device };
        // let json = JSON.stringify(sendAction);
        // this.websocket.send(json);
        try {
            this.wsClient.sendDisplayedDeviceError(device as UsedDevice);
        } catch (error) {
            console.error(error);            
        }
    }
    
    // #369
    recieveDisplayErrorSnackBar = (data: DevErrDisp) => {
        const logger: Logger = Logger.getInstance();
        logger.info("recieveDisplayErrorSnackBar kind=["+data.deviceKind+"]  name=["+data.otherName+"]");
        let devName = "カメラ";
        if(data.deviceKind === 2)   devName = "マイク";
        else   devName = "カメラまたはマイク";
        let message = ""+data.otherName+"さんの"+devName+"が使用できません";
        logger.info("recieveDisplayErrorSnackBar client disp message:["+message+"]");
        //this.showAttentionSnackBar(true, message);
        this.props.enqueueSnackbar(message);
    }

    /**
     * moreNote Hello 開始イベント.
     * @param window MoreNote Window ハンドル
     */
    handleStartMoteNote = (window: Window | null) => {
        this.moreNoteWindow = window;
    }

    /**
     * 渡されたURLを設定し、インラインフレームを開く
     * 
     * @param url 
     */
    handleStartMoteNoteInline = (url: string) => {
        this.setState({
            inlineFrameUrl: url,
            openInlineFrame: true
        });
    }

    /**
     * インラインフレームを閉じる
     */
    handleCloseMoreNoteInline = () => {
        this.setState({
            inlineFrameUrl: "",
            openInlineFrame: false
        });
    }

    /**
     * インラインフレームを閉じるとともにバックエンドに他のユーザーのインラインフレームを閉じるメッセージを送る
     */
    handleCloseMoreNoteInlineAll = () => {
        this.handleCloseMoreNoteInline();
        // this.websocket.sendCloseMoreNote();
    }

    /**
     * 一斉ミュート状態を変更する
     */
    handleForceMute = (value: boolean) => {
        // let sendAction: SendAction = new SendAction();
        // sendAction.action = "FORCE_MUTE";
        // sendAction.object = { isForceMute: value };
        // let json = JSON.stringify(sendAction);
        // this.websocket.send(json);

        try {
            this.wsClient.sendForceMute(value);
        } catch (error) {
            console.error(error);            
        }
    }

    // 拍手（バクエンドへ送信）
    handleClapping = () => {
        // let sendAction: SendAction = new SendAction();
        // sendAction.action = "CLAPPING";
        // let json = JSON.stringify(sendAction);
        // this.websocket.send(json);

        try {
            this.wsClient.sendClapping();
        } catch (error) {
            console.error(error);            
        }
    }

    // 名刺公開設定
    handleCardDisclose = (isCardDisclose: boolean) => {
        let sendAction: SendAction = new SendAction();
        sendAction.action = "CARD_DISCLOSE";

        let myUser = this.myUserRef.current?.getUser() as User;
        if (myUser !== undefined && myUser !== null) {
            sendAction.object = { isCardDisclose: isCardDisclose, subId: myUser.subId }
            let json = JSON.stringify(sendAction);
            // this.websocket.send(json);
        }
    }

    /** */
    /**
     * YouTubeの再再生ダイアログを閉じた際に、YouTubeが切り替わる完了済みため、youtubeUpdateフラグをリセット必要
     */
    handleResetIsChangeVideo = (flag: boolean) => {
        this.setState({
            youtubeUpdate: flag
        })
    }

    /**
    * WebRTC 各種通知.
    * @param hittedUser ぶつかってきた人
    */
    webRtcNotification = (user: User) => {
        const logger: Logger = Logger.getInstance();
        if (user.hittedUser) {
            // デスクトップ通知
            this.desktopNotification(user.hittedUser + " さんに話しかけられました。", 20000);
            // ページ内通知
            this.appNotification(user.hittedUser + " さんに話しかけられました。");
            // 音(通知)
            if (WebrtcService.isiOS() || WebrtcService.isAndroid()) {
                // 相手の映像および音声を受信したタイミングで音を鳴らす
                // iOS の場合、getUserMedia を実行したタイミングで音声の再生が中断されるようで
                // 正常に効果音を鳴らせない場合があるため。
                // Android の場合も端末によっては同様の制限がある。

                // iPad対応-11 すぐに音を再生する
                //WebrtcService.remoteStreamConnectSoundId = AudioId.Notify;
                logger.info("PLAY_SOUND_TRACE 話しかけられた音再生 iOS : my=["+user.displayName+"]["+user.id+"]");
                this.playSoundFromAudioId(AudioId.Notify);
            } else {
                logger.info("PLAY_SOUND_TRACE 話しかけられた音再生 : my=["+user.displayName+"]["+user.id+"]");
                this.playSoundFromAudioId(AudioId.Notify);
            }
        } else {
            // 音(入室)
            if (WebrtcService.isiOS() || WebrtcService.isAndroid()) {
                // 相手のストリームを受信したタイミングで音を鳴らす
                // iOS の場合、getUserMedia を実行したタイミングで音声の再生が中断されるようで
                // 正常に効果音を鳴らせない場合があるため。
                // Android の場合も端末によっては同様の制限がある。

                // iPad対応-11 すぐに音を再生する
                //WebrtcService.remoteStreamConnectSoundId = AudioId.EnterRoom;
                logger.info("PLAY_SOUND_TRACE 入室音再生 iOS : my=["+user.displayName+"]["+user.id+"]");
                this.playSoundFromAudioId(AudioId.EnterRoom);
            } else {
                logger.info("PLAY_SOUND_TRACE 入室音再生 : my=["+user.displayName+"]["+user.id+"]");
                this.playSoundFromAudioId(AudioId.EnterRoom);
            }
        }
    }

    /**
    * WebRTC ビデオ通話前の確認
    * ビデオ通話前の確認ダイアログを表示するかチェック
    * @param hittedUser ぶつかってきた人
    */
    checkWrStartingConfirm = (user: User, myUser: User) => {
        let retval:number = 0;
        const logger: Logger = Logger.getInstance();
        console.log("checkWrStartingConfirm call user=["+user.displayName+"] hittedUser=["+user.hittedUser+"] webRtcCall=["+user.webRtcCall+"] join="+user.isWebrtcJoin+"]");

        // 使用目的＆フロアー設定による確認可否
        let purposeOfUse = this.getPurposeOfUse();
        let floorStartConfirmSetting = this.getEnabledWrStartConfirmSetting();
        console.log("checkWrStartingConfirm purposeOfUse=["+purposeOfUse+"] floorStartConfirmSetting=["+floorStartConfirmSetting+"]");
        if(purposeOfUse !== 1) {
            // 使用目的がオフィスではない
            console.log("checkWrStartingConfirm  not confirm by purposeOfUse=["+purposeOfUse+"]");
            retval = 0;
        } else {
            if(floorStartConfirmSetting === 10) {
                // 「オフ」固定フロア
                console.log("checkWrStartingConfirm  not confirm by floorStartConfirmSetting=["+floorStartConfirmSetting+"]");
                retval = 0;
            } else if(floorStartConfirmSetting === 11) {
                // 「オン」固定フロア
                console.log("checkWrStartingConfirm  exec confirm by floorStartConfirmSetting=["+floorStartConfirmSetting+"]");
                retval = 1;
            } else if(floorStartConfirmSetting === 0) {
                // 個別設定フロア
                if(user.avatarConfirmSetting === 0) {
                    // 個別設定が「オフ」
                    console.log("checkWrStartingConfirm  not confirm by user.avatarConfirmSetting=["+user.avatarConfirmSetting+"]");
                    retval = 0;
                } else if(user.avatarConfirmSetting === 1) {
                    // 個別設定が「オン」
                    console.log("checkWrStartingConfirm  exec confirm by user.avatarConfirmSetting=["+user.avatarConfirmSetting+"]");
                    retval = 1;
                } else {
                    // 値が変？？
                    console.log("checkWrStartingConfirm  not confirm by user.avatarConfirmSetting=["+user.avatarConfirmSetting+"]");
                    retval = 0;
                }
            } else {
                // 値が変？？
                console.log("checkWrStartingConfirm  not confirm by floorStartConfirmSetting=["+floorStartConfirmSetting+"]");
                retval = 0;
            }
        }

        // 確認実施の場合は、重ねられてwebrtc開始しようとしているかをチェック
        if(retval === 1) {
            logger.info("receive: checkOpen wrStartingConfirm name=["+user.displayName+"] sts=["+user.wrStartConfirmStatus+"] hited=["+user.hittedUser+"]["+user.hittedUserId+
                    "] join=["+user.isWebrtcJoin+"] call=["+user.webRtcCall+"] room=["+user.webRtcRoomId+"] mode=["+user.webRtcMode+"] Continue=["+user.isWebRtcContinue+
                    "] mute1=["+user.isAudioMute+"] mute2=["+user.isNoVideo+
                    "] myUser=["+myUser.displayName+"] room=["+myUser.webRtcRoomId+"] join=["+myUser.isWebrtcJoin+"]")
            if (user.hittedUser && user.isWebrtcJoin === false && myUser.webRtcRoomId === "") {
                // #809 ドラッグ中に話しかけられたかチェック
                if(this.isDragMe === true) {
                    logger.info("hittedonDrag: 確認あり hittedUser=["+user.hittedUser+"] my=["+myUser.displayName+"]");
                    this.hittedonDrag = true;
                }
                // ビデオ通話前の確認ダイアログ表示
                retval = 1;
            } else if (user.hittedUser === "" && user.webRtcRoomId === "" && myUser.webRtcRoomId !== "" && user.wrStartConfirmStatus === 1) {
                // ビデオ通話前の確認中に相手が離れた
                retval = 2;
            } else {
                retval = 0;
            }
        } else {
            // ドラッグ中に話しかけられたかチェック
            if (user.hittedUser && user.isWebrtcJoin === false && myUser.webRtcRoomId === "") {
                if(this.isDragMe === true) {
                    logger.info("hittedonDrag: 確認なし hittedUser=["+user.hittedUser+"] my=["+myUser.displayName+"]");
                    this.hittedonDrag = true;
                }
            }
        }
        return retval;
    }

    /**
    * WebRTC ビデオ通話前の確認
    * ビデオ通話前の確認ダイアログ表示のための設定
    * @param hittedUser ぶつかってきた人
    */
    openWrStartingConfirm = (user: User) => {
        console.log("openWrStartingConfirm");
        let temp = User.copyUser(user) as User;
        
        // 通知ウインド表示
        if (user.webRtcRoomId !== "") {
            if (this.isWebRtcConnecting === false) {
                this.webRtcNotification(user)
            }
        }

        // ダイアログオープンセット
        temp.wrStartConfirmStatus = 1;
        temp.hittedUser = user.hittedUser;
        temp.hittedUserId = user.hittedUserId;
        //console.log("hitUser ["+temp.hittedUser+"]["+temp.hittedUserId+"]");
        this.myUserRef.current.setUser(temp);
        this.sendWrStartConfirmStatus(temp);    // 04141100 //ビデオ通話前ダイアログが表示されないことがあろ

        // 確認結果受信（handleStartConfirmResult）用に 「User」 を保存
        User.copyUserToUser(this.startingConfirmUser, user);
        this.startingConfirmUser.hittedUser = user.hittedUser;
        this.startingConfirmUser.hittedUserId = user.hittedUserId;
        return;
    }

    /**
     * デスクトップ通知.
     * @param text 通知メッセージ
     */
    private localNotification: any = null;
    desktopNotification = (text: string, displayTime: number) => {
        if (WebrtcService.isiOS() || WebrtcService.isAndroid()){
            this.props.enqueueSnackbar(text);
            return;
        } 
        const { floorName, office: { officeName } } = this.state.floorData;

        if(this.localNotification !== null) this.localNotification.close();

        this.localNotification = new Notification(`${officeName} - ${floorName}`, { body: text, silent: false, requireInteraction: true});
        this.localNotification.onclick = function() {
            window.focus();
        };

        // setTimeout(() => this.closeNotification(), displayTime);
    }
    closeNotification = () => {
        if(this.localNotification !== null) {
            this.localNotification.close();
            this.localNotification = null;
        }
    }

    /**
     * アプリ内通知.
     * @param text 通知メッセージ
     */
    appNotification = (text: string) => {
        if (WebrtcService.isiOS() || WebrtcService.isAndroid()) return;
        const { floorName, office: { officeName } } = this.state.floorData;
        const { elementId, width, height, top, left, backgroundColor } = this.notifyWindowSetting;
        const serviceName = `${officeName} - ${floorName}`;

        if ((this.notifyWindow == null) || (this.notifyWindow.closed)) {
            this.notifyWindow = window.open("", elementId, `width=${width},height=${height},top=${top},left=${left}`);
            if (this.notifyWindow !== null) {
                this.notifyWindow.document.write("<HTML><HEAD>");
                this.notifyWindow.document.write("<TITLE>" + serviceName + " 通知</TITLE>");
                this.notifyWindow.document.write("<BODY bgcolor=" + backgroundColor + ">");
                this.notifyWindow.document.write("<b>" + serviceName + "からの通知</b><br/>");
            }
        } else {
            this.notifyWindow.focus();
        }
        if (this.notifyWindow != null) {
            this.notifyWindow.focus();
            const now = new Date();
            let _text = now.getHours() + ":" + ('00' + now.getMinutes()).slice(-2) + ":" + ('00' + now.getSeconds()).slice(-2) + "　" + text;
            this.notifyWindow.document.write("　" + _text + "<br>");
            this.notifyWindow.document.write("</BODY></HTML>");
        }
    }

    /**
     * デスクの上に表示する名札の描画
     */
    drawSeatObject = () => {
        if (this.state === null) return;
        if (this.state.floorSeatList === undefined) return;
        //alert("now renderling");
        const users = this.otherUsersRef.current.getUsers() as User[];

        return (this.state.floorSeatList.map((obj: Seat) => {
            // 座った場合の処理
            // if (obj.isSit) return <div key={obj.floorObjectId + "-" + obj.seatNo} style={{ position: "absolute", left: obj.x, top: obj.y }}><img src={"/api/user/object/picture/4"} alt="" /></div>;
            // return <div key={obj.floorObjectId + "-" + obj.seatNo} style={{ position: "absolute", left: obj.offsetLeft, top: obj.offsetTop, backgroundSize: "contain", backgroundImage: "url(./api/user/object/picture/" + obj.objectMaster.id + ")", width: obj.objectMaster.width, height: obj.objectMaster.height }}></div>
            if (!obj.isViewName) return "";
            if (obj.viewText === "") return "";
            if (obj.myNameplate === null) return "";
            if (obj.myNameplateSignOut === null) return "";
            //alert("updated seat data");
            // return <div key={obj.floorObjectId + "-" + obj.seatNo} style={{ position: "absolute", left: obj.x + obj.deskOffsetLeft, top: obj.y + obj.deskOffsetTop }}>{obj.viewText}</div>
            const user = users.find((u) => {return u.mySeat?.seatNo === obj.seatNo});
            if(user){
                if (user.privacyRoomInfo !== null && user.privacyRoomInfo.stayPrivacyroom === true && user.privacyRoomInfo.preFloorName === this.state.floorData.floorName) return "";
            }

            let viewTextList = obj.viewText2.split("#");
            if(viewTextList.length === 2) {
                obj.viewText2Tooltip = viewTextList[1];
            }

            if (obj.viewText2.match("(ﾛｸﾞｲﾝ中)")) { // 暫定
                

                // サインイン状態(名札の色)を判別するために、'(ﾛｸﾞｲﾝ中)'をサーバーでは入れているが、frontでは表示しない
                let viewText2:string = obj.viewText2.substring(0, obj.viewText2.indexOf('('));

                return (
                    <div
                        key={obj.floorObjectId + "-" + obj.seatNo}
                        style={{
                            ...SELECT_NONE,
                            position: "absolute",
                            left: obj.x + obj.deskOffsetLeft - (obj.myNameplate.width / 2),
                            top: obj.y + obj.deskOffsetTop - (obj.myNameplate.height / 2),
                            zIndex: ZIndex.seatObject,
                            pointerEvents: "none",
                        }}
                    >
                        <div style={{ ...SELECT_NONE, position: "relative", width: obj.myNameplate.width, height: obj.myNameplate.height }}>
                            {/* <img src={"/api/user/object/picture/" + obj.myNameplate.id} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplate.width, height: obj.myNameplate.height, left: 0, top: 0 }} alt="name-plate" /> */}
                            <img src={this.httpClient.createObjectImgUrl(obj.myNameplate.id)} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplate.width, height: obj.myNameplate.height, left: 0, top: 0 }} alt="name-plate" />
                            <div style={{ ...SELECT_NONE, position: "absolute", left: 0, top: 0, width: obj.myNameplate.width, fontSize: "10px" }}>{obj.viewText}<br/>{viewText2}</div>
                        </div>
                    </div>)
            }
            else {
                //alert("updated seat data else");
                return (
                    <div
                        key={obj.floorObjectId + "-" + obj.seatNo}
                        style={{
                            ...SELECT_NONE,
                            position: "absolute",
                            left: obj.x + obj.deskOffsetLeft - (obj.myNameplateSignOut.width / 2),
                            top: obj.y + obj.deskOffsetTop - (obj.myNameplateSignOut.height / 2),
                            zIndex: ZIndex.seatObject,
                            pointerEvents: "none",
                        }}
                    >
                        <div style={{ ...SELECT_NONE, position: "relative", width: obj.myNameplateSignOut.width, height: obj.myNameplateSignOut.height }}>
                            {/* <img src={"/api/user/object/picture/" + obj.myNameplateSignOut.id} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplateSignOut.width, height: obj.myNameplateSignOut.height, left: 0, top: 0 }} alt="name-plate" /> */}
                            <img src={this.httpClient.createObjectImgUrl(obj.myNameplateSignOut.id)} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplateSignOut.width, height: obj.myNameplateSignOut.height, left: 0, top: 0 }} alt="name-plate" />
                            <div style={{ ...SELECT_NONE, position: "absolute", left: 0, top: 0, width: obj.myNameplateSignOut.width, fontSize: "10px" }}>{obj.viewText}<br/>{viewTextList[0]}</div>
                        </div>
                    </div>)
            }
        })
        )
    }

    /**
     * フロアのオブジェクトを描画
     */
    drawObject = () => {
        if (this.state === null) return;
        if (this.state.floorObjectList === undefined) return;

        let index = -1;
        return (this.state.floorObjectList.map((obj: FloorObject) => {
            if (obj.objectMaster.type === 2000103) {
                // プライバシールームのRef設定
                index++;
                this.privacyRoomListRef[index] = React.createRef();
            }
            /**
             * 2000004, 2000100, 2000101, 2000102は元々VideoConferenceRoomコンポーネントを呼び出していたが、ダミーオブジェクト対応のためMeetingRoomWithTextInfoを呼び出すように変更
             * また、1000000もテキスト情報を持つためMeetingRoomWithTextInfoを呼び出す形に変更
             */
            const className = NEED_TO_POINTER_CAPTURE; // 各オブジェクト起点でwindow外へドラッグした際の制御に用いるクラス名
            if (obj.objectMaster.type === 1000000) return <MeetingRoomWithTextInfo key={obj.id} className={className} floorObject={obj} sendRoomText={this.sendRoomText} sendBannerText={this.sendBannerText} sendKanbanText={this.sendTatehudaText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId} updateStudyObjectSetting={this.state.updateStudyObjectSetting}/>
            if (obj.objectMaster.type === 1000100) return <StudySeat className={className} floorObject={obj} role={this.getMyRole()} updateStudyObjectSetting={this.state.updateStudyObjectSetting}></StudySeat>
            if (obj.objectMaster.type === 2000000) return <MeetingRoom key={obj.id} className={className} floorObject={obj} sendText1KanbanText={this.sendText1KanbanText} sendKanbanText={this.sendTatehudaText} sendBannerText={this.sendBannerText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId}/>
            if (obj.objectMaster.type === 2000003) return <MeetingRoomNoText key={obj.id} className={className} floorObject={obj} isDrawText={false} sendText1KanbanText={this.sendText1KanbanText} sendKanbanText={this.sendTatehudaText} sendBannerText={this.sendBannerText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId}/>
            if (obj.objectMaster.type === 2000004) return <MeetingRoomWithTextInfo key={obj.id} className={className} floorObject={obj} sendRoomText={this.sendRoomText} sendBannerText={this.sendBannerText} sendKanbanText={this.sendTatehudaText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId} updateStudyObjectSetting={this.state.updateStudyObjectSetting}/>
            if (obj.objectMaster.type === 2000100) return <MeetingRoomWithTextInfo key={obj.id} className={className} floorObject={obj} sendRoomText={this.sendRoomText} sendBannerText={this.sendBannerText} sendKanbanText={this.sendTatehudaText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId} updateStudyObjectSetting={this.state.updateStudyObjectSetting}/>
            if (obj.objectMaster.type === 2000101) return <MeetingRoomWithTextInfo key={obj.id} className={className} floorObject={obj} sendRoomText={this.sendRoomText} sendBannerText={this.sendBannerText} sendKanbanText={this.sendTatehudaText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId} updateStudyObjectSetting={this.state.updateStudyObjectSetting}/>
            if (obj.objectMaster.type === 2000102) return <MeetingRoomWithTextInfo key={obj.id} className={className} floorObject={obj} sendRoomText={this.sendRoomText} sendBannerText={this.sendBannerText} sendKanbanText={this.sendTatehudaText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId} updateStudyObjectSetting={this.state.updateStudyObjectSetting}/>            
            if (obj.objectMaster.type === 2000103) return <PrivacyRoom key={obj.id} ref={this.privacyRoomListRef[index]} className={className} floorObject={obj} sendPrivacyState={this.sendPrivacyState} role={this.getMyRole()} gimmickObject={this.getPrivacyRoomGimmick()} getMyUser={this.myUserRef.current?.getUser} />
            if (obj.objectMaster.type === 2000104) return <MeetingRoomWithBanner key={obj.id} className={className} floorObject={obj} sendBannerText={this.sendBannerText} sendKanbanText={this.sendTatehudaText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()}/>
            if (obj.objectMaster.type === 2000105) return <VideoConferenceRoom key={obj.id} className={className} floorObject={obj} sendText1KanbanText={this.sendText1KanbanText} sendKanbanText={this.sendTatehudaText} sendBannerText={this.sendBannerText} sendVideoURLText={this.sendVideoURLText} role={this.getMyRole()} sendMeetingRoomLock={this.sendMeetingRoomLockJudgedFromObjectId}/>
            if (obj.objectMaster.type === 3000000) return <Tatehuda key={obj.id} className={className} floorObject={obj} sendTatehuda2Text={this.sendTatehuda2Text} />
            if (obj.objectMaster.type === 3000001) return <StaticKanban key={obj.id} floorObject={obj} />
            if (obj.objectMaster.type === 3000002) return <DynamicKanban key={obj.id} className={className} floorObject={obj} sendKanbanText={this.sendTatehudaText} />
            if (obj.objectMaster.type === 8000000) return <Entrance key={obj.id} className={className} floorObject={obj} sendText1KanbanText={this.sendText1KanbanText}/>
            if (obj.objectMaster.type === 3000004) return <WhiteBoard key={obj.id} className={className} floorObject={obj} sendKanbanText={this.sendTatehudaText} />
            if (obj.objectMaster.type === 3000005) return <StaticKanbanNoBorder key={obj.id} floorObject={obj} noBorder />
            if (obj.objectMaster.type === 3000007) return <AdminMessageBoard key={obj.id} className={className} floorObject={obj} />
            if (obj.objectMaster.type === 3000008) return <StaticKanbanCenterAligned key={obj.id} floorObject={obj} noBorder />
            if (obj.objectMaster.type === 3000009) return <WebpagePopupSignboard key={obj.id} floorId={this.floorId} floorObject={obj} openPage={this.openSignboardPage} sendWebSocket={this.sendWebSocket} setWebpageUrl={this.setWebpageUrl} userName={this.myUserRef.current.getUser().displayName} role={this.getMyRole()}/> //クリックするとwebページが見れるポップアップ看板対応
            if (obj.objectMaster.type === 3000010) return <NoticeBoard key={obj.id} floorObject={obj} openPage={this.openSignboardPage} sendWebSocket={this.sendWebSocket} setWebpageUrl={this.setWebpageUrl} userName={this.myUserRef.current.getUser().displayName} role={this.getMyRole()}/> //掲示板対応
            if (obj.objectMaster.type === 3000011) return <BookShelf key={obj.id} className={className} floorObject={obj} sendBookShelfUrl={this.sendTatehudaText} role={this.getMyRole()}/>
            if (obj.objectMaster.type === 3000012) return <DorakidsNoticeBoard key={obj.id} floorObject={obj} openPage={this.openSignboardPage} sendWebSocket={this.sendWebSocket} setWebpageUrl={this.setWebpageUrl} userName={this.myUserRef.current.getUser().displayName} role={this.getMyRole()}/> //ドラキッズ掲示板対応
            if (obj.objectMaster.type === 3000102) return <CountdownBoard key={obj.id} className={className} floorObject={obj} sendCountdownText={this.sendCountdownText} role={this.getMyRole()}/>
            if (obj.objectMaster.type === 3000103) return <Banner key={obj.id} className={className} floorObject={obj} sendBannerText={this.sendBannerText} role={this.getMyRole()} />
            if (obj.objectMaster.type === 3000104) return <DynamicKanbanNoBorderRole key={obj.id} className={className} floorObject={obj} sendKanbanText={this.sendTatehudaText} role={this.getMyRole()} />
            if (obj.objectMaster.type === 3000105) return <DynamicKanbanOneLineRoleMaxLength key={obj.id} className={className} floorObject={obj} sendKanbanText={this.sendTatehudaText} role={this.getMyRole()} length={12} />
            if (obj.objectMaster.type === 3000106) return <OriginalCharacterObject key={obj.id} className={className} floorObject={obj} role={this.getMyRole()} ref={this.characterObjectRef}/>
            if (obj.objectMaster.type === 3001001) return <FloorUsersAmount ref={this.floorUsersAmountRef} key={obj.id} className={className} floorObject={obj} />
            if (obj.objectMaster.type === 7000000) {
                this.youtubeProps = {} as YouTubeProps;
                return <YouTube isSitOnTable={true} videoId={this.state.floorData.videoId} width={obj.objectMaster.width} height={obj.objectMaster.height} left={obj.offsetLeft} top={obj.offsetTop} zIndex={ZIndex.floorObject} isChangeVideo={this.state.youtubeUpdate} resetIsChangeVideo={this.handleResetIsChangeVideo}/>
            }
            // MyUserの普通のテーブルに座る状態によって描画するかの理由で、ここは一旦描画しない、必要なPropsが初期化だけでいい（MyUser内で全部のプロパティが完備したら描画を実施する）
            if (obj.objectMaster.type === 7000001) {
                this.youtubeProps.videoId = this.state.floorData?.videoId;
                this.youtubeProps.width = obj.objectMaster.width;
                this.youtubeProps.height = obj.objectMaster.height;
                this.youtubeProps.left = obj.offsetLeft;
                this.youtubeProps.top = obj.offsetTop;
                this.youtubeProps.isChangeVideo = this.state.youtubeUpdate;
                // eslint-disable-next-line array-callback-return
                return;
            }
            if (obj.objectMaster.type === 7000100) {
                this.otherWebsiteProps = {} as OtherWebsiteProps;
                return (
                  <OtherWebsite
                    otherWebsiteUrl={this.state.floorData.otherWebsiteUrl}
                    width={obj.objectMaster.width}
                    height={obj.objectMaster.height}
                    left={obj.offsetLeft}
                    top={obj.offsetTop}
                    zIndex={ZIndex.floorObject}
                  />
                );
            }
            if (obj.objectMaster.type === 7000200) {
                const {id, displayName} = this.myUserRef.current?.getUser();
                const zoomInfoId = this.state.floorData.zoomInfo?.id;
                // Zoom表示オブジェクトにFloorに設定されているzoomInfoIdをセットする
                if (zoomInfoId && this.zoomViewRef.current?.getZoomInfoId() !== zoomInfoId) {
                    this.zoomViewRef.current?.setZoomInfoId(zoomInfoId);
                }

                return (
                    !!displayName &&
                    <ZoomView
                        key={`zoomView${obj.id}`}
                        ref={this.zoomViewRef}
                        id={id}
                        displayName={displayName}
                        width={obj.objectMaster.width}
                        height={obj.objectMaster.height}
                        left={obj.offsetLeft}
                        top={obj.offsetTop}
                        zIndex={ZIndex.floorObject}
                        sendZoomJoin={this.sendZoomJoin}
                    />
                );
            }
            if(obj.objectMaster.type === 3000100){
                // #203　観葉植物等の装飾は「zIndexを背景の中では高い」にしたい
                return (
                    <div
                        key={obj.id}
                        style={{
                            ...SELECT_NONE,
                            zIndex: ZIndex.floorDecoration,
                            position: "absolute",
                            left: obj.offsetLeft,
                            top: obj.offsetTop,
                            backgroundSize: "contain",
                            backgroundImage: `url(${this.httpClient.createObjectImgUrl(obj.objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                            width: obj.objectMaster.width,
                            height: obj.objectMaster.height,
                            pointerEvents: "none",
                            backgroundRepeat: "no-repeat",
                        }}
                    />)
            }
            if(obj.objectMaster.type === 3000101){
                // 床材
                return (
                    <div
                        key={obj.id}
                        style={{
                            ...SELECT_NONE,
                            zIndex: ZIndex.yuka,
                            position: "absolute",
                            left: obj.offsetLeft,
                            top: obj.offsetTop,
                            backgroundSize: "contain",
                            backgroundImage: `url(${this.httpClient.createObjectImgUrl(obj.objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                            width: obj.objectMaster.width,
                            height: obj.objectMaster.height,
                            pointerEvents: "none",
                            backgroundRepeat: "no-repeat",
                        }}
                    />)
            }
            if (obj.objectMaster.type === 9000000) { return; }
            return (
                <div
                    key={obj.id}
                    style={{
                        ...SELECT_NONE,
                        zIndex: ZIndex.floorSection,
                        position: "absolute",
                        left: obj.offsetLeft,
                        top: obj.offsetTop,
                        backgroundSize: "contain",
                        // backgroundImage: "url(./api/user/object/picture/" + obj.objectMaster.id + ")",
                        backgroundImage: `url(${this.httpClient.createObjectImgUrl(obj.objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                        backgroundRepeat: "no-repeat",
                        width: obj.objectMaster.width,
                        height: obj.objectMaster.height,
                        pointerEvents: "none",
                    }}
                />)
        })
        )
    }

    getMyUserPrivacy = () => {
        
        if (this.myUserRef.current) {
            const myUser = this.myUserRef.current.getUser();
            return myUser.isVideoMute && myUser.isAudioMute;
        }

        return false;
    }

    getMyUserAudioMute = () => {
        if (this.myUserRef.current) {
            const myUser = this.myUserRef.current.getUser();
            return myUser.isAudioMute;
        }

        return false;
    }

    getMyUserVideoMute = () => {
        if (this.myUserRef.current) {
            const myUser = this.myUserRef.current.getUser();
            return myUser.isVideoMute;
        }

        return false;
    }

    // 通信量カメラ制限状況
    getMyUserTrafficLimitVideoMute = () => {
        if (this.myUserRef.current) {
            let myUser: User = this.myUserRef.current.getUser();
            return myUser.isTrafficLimitVideoMute;
        }
        return false;
    }

    getMyUserForceMute = () => {
        if (this.myUserRef.current) {
            const myUser = this.myUserRef.current.getUser();
            return myUser.isForceMute;
        }
        return false;
    }

    getWebRtcReceiveMode = () => {
        if (this.myUserRef.current) {
            const myUser = this.myUserRef.current.getUser() as User;
            if (myUser.webRtcRoomId.replace("-receive", "") === (myUser.id + "")) {
                return false;
            }

            return myUser.webRtcRoomId.indexOf("-receive") > -1;
        }

        return false;
    }

    getEnabledTutorialButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledTutorialButton;
    }

    getPurposeOfUse = () => {
        if (this.state === null) {
            return 0;
        }
        return this.state.floorData.purposeOfUse;
    }

    // フロア単位での「ビデオ通話前の確認」設定値を取得
    getEnabledWrStartConfirmSetting = () => {
        if (this.state === null) {
            return 10;
        }
        return this.state.floorData.enabledWrStartConfirmSetting;
    }

    // フロア単位での「ビデオ通話前の確認」タイムアウト値を取得
    getwrStartConfirmWaitTime = () => {
        if (this.state === null) {
            return 20;
        }
        return this.state.floorData.wrStartConfirmWaitTime;
    }

    // 「自動再接続」切断後、再接続成功するまでのリトライ回数を取得
    getReconWebSocketRetryMax = () => {
        if (this.state === null) {
            return 6;
        }
        return this.state.floorData.reconWebSocketRetryMax;
    }
    // 「自動再接続」一定期間での再接続実行の最大回数
    getReconWebSocketActionMax = () => {
        if (this.state === null) {
            return 3;
        }
        return this.state.floorData.reconWebSocketActionMax;
    }
    // 「自動再接続」一定期間での再接続実行の最大回数
    getReconWebSocketActionInitTime = () => {
        if (this.state === null) {
            return 60;
        }
        return this.state.floorData.reconWebSocketActionInitTime;
    }

    // 「webrtc接続リトライ対応」joinRoomしてからpeer.on(open)イベント受信タイムアウト 単位：msec
    getWebrtcJoinOpenWaitTimeOut = () => {
        if (this.state === null) {
            return 0;
        }
        return this.state.floorData.webrtcJoinOpenWaitTimeOut;
    }

    // フロア単位での「moreNOTEへ招待するかの確認実施有無」を取得
    getEnabledMorenoteInvitedConfirm = () => {
        if (this.state === null) {
            return true;
        }
        return this.state.floorData.enabledMorenoteInvitedConfirm;
    }

    getMyCommuting = () => {
        if (this.myUserRef.current) {
            const myUser = this.myUserRef.current.getUser() as User;
            console.log("myUser.isCommuting", myUser.isCommuting);
            return myUser.isCommuting;
        }

        console.log("myUser.isCommuting false");
        return false;
    }

    getMyRole = () => {
        if(this.myUserRef.current){
            const myUser = this.myUserRef.current.getUser() as User;
            return myUser.role;
        }

        return "";
    }

    getEnabledSettingSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledSettingSideMenu;
    }

    getEnabledTutorialSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledTutorialSideMenu;
    }

    getEnabledManualSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledManualSideMenu;
    }

    getEnabledInformationSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledInformationSideMenu;
    }

    // リリースノート対応
    getEnabledReleaseNoteSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledReleaseNoteSideMenu;
    }

    // ＦＡＱ対応
    getEnabledFAQSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledFAQSideMenu;
    }

    // カメラ未選択時の表示対応
    getEnabledVideoNoSelectDisp = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledVideoNoSelectDisp;
    }

    // マイク未選択時の表示対応
    /*
    getEnabledMicNoSelectDisp = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledMicNoSelectDisp;
    }
    */

    getEnabledReconnectSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledReconnectSideMenu;
    }

    getEnabledMicVolumeIntervalSideMenu = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledMicVolumeIntervalSideMenu;
    }

    getEnabledWhiteBoardButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledWhiteBoardButton;
    }

    getEnabledReconnectHelloButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.openInlineFrame;
    }

    getEnabledMemoButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledMemoButton;
    }

    getEnabledMemoOtherUserMenu = () => {
        if(this.state === null){
            //console.log("memootheruser return false ");
            return false;
        }
        //console.log("memootheruser return ", this.state.floorData.enabledMemoOtherUserMenu);
        return this.state.floorData.enabledMemoOtherUserMenu;
    }

    getEnabledScreenShareButton = () => {
        if (this.state === null) {
            return false;
        }
        // iPadで画面共有できないのでボタン表示しないようにする
        if(WebrtcService.isiOS() === true){
            return false;
        }
        return this.state.floorData.enabledScreenShareButton;
    }

    getEnabledOverlookButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledOverlookButton;
    }

    getEnabledZoomMicButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledZoomMicButton;
    }

    getEnabledZoomCamButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledZoomCamButton;
    }

    // !見本市対応で作成したが不使用
    /* getEnabledZoomFullScreenButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledZoomFullScreenButton;
    } */

    // !見本市対応で作成したが不使用
    /* getEnabledZoomScreenShareButton = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledZoomScreenShareButton;
    } */

    getEnabledBusinessCard = () => {
        if(this.state === null){
            return false;
        }
        return this.state.floorData.enabledBusinessCard;
    }

    getEnabledMyRoom = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.enabledMyRoom;
    }

    // getEnabledFloorEditor = () => {
    //     if (this.state === null) {
    //         return false;
    //     }
    //     return this.state.floorData.enabledFloorEditor;
    // }

    getPrivacyRoom = () => {
        if (this.state === null) {
            return false;
        }
        return this.state.floorData.office.privacyRoom;
    }

    getPrivacyRoomGimmick = () => {
        let gimmickObjList = this.state.floorObjectList.filter((obj) => {
            return obj.objectMaster.type == 9000000;
        });
        if (gimmickObjList.length == 0) {
            return undefined;
        } else {
            return gimmickObjList[0].objectMaster;
        }
    }

    visibleWebRtcBroadCastButton = (webRtcMode: number, userId: number, webRtcRoomId: string) => {
        if (webRtcMode === undefined || webRtcMode === 0) {
            return false;
        }

        return webRtcRoomId.replace("-receive", "") !== (userId + "");
    }

    visibleWebRtcBroadCastStopButton = (webRtcMode: number, userId: number, webRtcRoomId: string) => {
        if (webRtcMode === undefined || webRtcMode === 0 || webRtcRoomId.indexOf("-receive") === -1) {
            return false;
        }

        return webRtcRoomId.replace("-receive", "") === (userId + "");
    }

    /**
     * フロアメニューの描画処理
     */
    drawFloorMenu = () => {
        let message = "";
        if (this.state !== null && this.state.floorData !== null) {
            message = this.state.floorData.systemMessage;
        }

        return <FloorMenu
            ref={this.floorMenuRef}
            isWebRtcConnecting={this.isWebRtcConnecting && this.getWebRtcReceiveMode() === false}
            isAudioMute={this.getMyUserAudioMute()}
            isForceMute={this.getMyUserForceMute()}
            systemMessage={message}
            onClickMuteButton={this.handleClickMuteButton}
            onClickFitButton={this.handleClickFitButton}
            onClickOverlookButton={this.handleClickOverlookButton}
            onSelectedSignout={this.handleSelectedSignout}
            clearScreenShare={this.clearScreenShare}
            enabledTutorialButton={this.getEnabledTutorialButton()}
            openTutorial={this.hundleOpenTutorial}
            scale={this.state?.fitScale}
            isCommuting={this.state?.myCommuting}
            onChangeCommuting={this.handleChangeMyCommuting}
            purposeOfUse={this.getPurposeOfUse()}
            isVideoMute={this.getMyUserVideoMute()}
            isTrafficLimitVideoMute={this.getMyUserTrafficLimitVideoMute()}
            onClickVideoMuteButton={this.handleClickVideoMuteButton}
            onMoveFloor={this.handleMoveFloor}
            floorId={this.floorId}
            centeringView={this.centeringViewByMyUser}
            handleClickMoreNote={this.handleClickMoreNote}
            enabledWhiteBoardButton={this.getEnabledWhiteBoardButton()}
            enableReconnectHelloButton={this.getEnabledReconnectHelloButton()}
            enabledMemoButton={this.getEnabledMemoButton()}
            openMemoGroupList={this.handleOpenMemoGroupList}
            openUserSearch={this.handleOpenUserSearch}
            subId={this.myUserRef.current?.getUser().subId}
            myDeviceNoSelect={this.myDeviceNoSelect}    // カメラ未選択時の表示対応
            myMicDeviceNoSelect={this.myMicDeviceNoSelect}    // マイク未選択時の表示対応
            enabledScreenShareButton={this.getEnabledScreenShareButton()}
            onChangeScreenShare={this.onChangeScreenShare}
            enabledFloorEditMode={this.state?.floorEditMode}
            enabledGridMode={this.state?.gridMode}
            gridRange={this.state?.gridRange}
            toggleGrid={this.changeGridRange}
            openNewFloorObjectWindow={this.handleOpenNewFloorObjWindow}
            handleDeleteFloorObject={this.handleDeleteFloorObject}
            isFloorObjSelected={this.state?.isFloorObjSelected}
            floorEditorRef={this.floorEditorRef}
            enabledOverlookButton={this.getEnabledOverlookButton()}
            isiPhoneLayout={this.isiPhoneLayout}
            enabledZoomMicButton={this.getEnabledZoomMicButton()}
            enabledZoomCamButton={this.getEnabledZoomCamButton()}
            //enabledZoomScreenShareButton={this.getEnabledZoomScreenShareButton()}
            //enabledZoomFullScreenButton={this.getEnabledZoomFullScreenButton()}
            syncZoomMicButton={this.syncZoomMicButton}
            syncZoomCamButton={this.syncZoomCamButton}
            //syncZoomScreenShareButton={this.syncZoomScreenShareButton}
            //syncZoomFullScreenButton={this.syncZoomFullScreenButton}
            onClickMyRoomButton={this.handleClickMyRoomButton}
            enabledMyRoomButton={this.getEnabledMyRoom()}
            openGroupChat={this.handleGroupChat}
            enabledExternalUrl={this.getEnabledExternalUrl()}
            externalUrl={this.getExternalUrl()}
            myUserPrivacyRoomInfo={this.myUserRef.current?.getUser().privacyRoomInfo}
            myRole={this.getMyRole()}
            myState={this.myUserRef.current?.getUser().state}
            onJumptoMySeat={this.handleJumpToMySeat}
            onStateChange={this.handleMyStateChange}
            requestPrivacyRoom={this.sendNewVisitor}
            leavePrivacyRoom={this.sendLeaveRoom}
            sendPrivacyRoomTimeOut={this.sendPrivacyRoomTimeOut}
            sendMovePrivacyRoomInfo={this.sendMovePrivacyRoomInfo}
            privacyRoom={this.getPrivacyRoom()}
            enabledToolLauncherButton={this.state?.enabledToolLauncher}
            changeShowToolLauncher={this.changeShowToolLauncher}
            isSelfStudyTable={this.isSelfStudyTable}
            isStudy={this.isStudy}
            openSelfStudyChangeDialog={this.handleSelfStudyChangeDialog}
            openSelfStudyFinishDialog={this.handleSelfStudyFinishDialog}
            startStudyTimer={this.handleMyStudyTimerStart}
            changeStudyTimerState={this.handleMyStudyTimerState}
        />;
    }

    getEnabledExternalUrl = () =>{
        if (this.state === null || this.state.floorData === null) {
            return false;
        }

        return this.state.floorData.enabledExternalUrl;
    }

    getExternalUrl = () =>{
        if (this.state === null || this.state.floorData === null) {
            return "";
        }

        return this.state.floorData.externalUrl;
    }

    handleGroupChat = (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[], open: boolean) => {
        if (open) {
            this.handleOpenChat(groupId, groupName, groupMemberNumber, unreadCount, userSubId);
        } else {
            this.groupChatComponentRef.current?.close();
        }
    }

    handleOpenChat = (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => {
        const parent = document.getElementById("floorContainer") as HTMLDivElement;
        const { offsetWidth } = parent;
        // memo
        const w = Utility.getWidth(360, 720, 360, 540);
        const h = Utility.getWidth(250, 720, 500, 250);
        const x = offsetWidth - w;
        this.groupChatComponentRef.current?.open(groupId, groupName, groupMemberNumber, unreadCount, userSubId, { x, y: 0 }, w ,h);
        this.setToTop("GroupChat");
        this.handleGroupChatMenu(true);
    }

    // FloorMenu以外からのグループチャットのウィンドウ操作 -> FloorMenu側も状態を設定する
    handleGroupChatMenu = (open: boolean) => {
        this.floorMenuRef.current?.setOpenGroupChat(open);
    }

    // 自習開始ダイアログの開閉制御
    handleSelfStudyStartDialog = (open :boolean) => {
        this.SelfStudyStartDialogRef.current?.openSelfStudyStartDialog(open);
    }

    // 自習計画変更ダイアログの開閉制御
    handleSelfStudyChangeDialog = (open :boolean) => {
        this.SelfStudyChangeDialogRef.current?.openSelfStudyStartDialog(open);
    }

    // 自習振り返りダイアログの開閉制御
    handleSelfStudyFinishDialog = (open :boolean) => {
        this.SelfStudyFinishDialogRef.current?.openSelfStudyLookBackDialog(open);
    }

    // 自習完了ダイアログの開閉制御
    handleSelfStudyCompleteDialog = (open :boolean) => {
        this.SelfStudyCompleteDialogRef.current?.openSelfStudyCompleteDialog(open);
    }

    // 自習タイマースタート
    handleStartTimer = (time: number) => {
        this.SelfStudyTimerRef.current?.startTimer(time);
    }

    // 自習タイマー終了
    handleStopTimer = () => {
        this.SelfStudyTimerRef.current?.stopTimer();
    }

    // 自習タイマー中断
    handleStopTimerInProgress = (selfStudyStartTime: number, timer: number) => {
        return this.SelfStudyTimerRef.current?.stopTimerInProgress(selfStudyStartTime,timer);
    }

    // 自習席に着いた際の外部ビデオの開閉タイミング制御
    handleSelfStudyTableVideo = (open: boolean) => {
        this.myUserRef.current?.handleSelfStudyTableVideo(open);
    }

    // 自身の状態を返す
    isMyState = () => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        return temp.state;
    }

    /**
     * 強制サインアウトのメッセージダイヤログを閉じる
     */
     closeForceExitMsgDialog = (no:number) => {
        this.isOpenForceExitMsgDialog = false;
    }

    drawMicVolumeIntervalDialog = () => {
        if (this.state === null || this.state.openMicVolumeInterval === undefined) {
            return "";
        }

        return <EditTextDialog
            className={"micvolume_interval"}
            isOpen={this.state.openMicVolumeInterval}
            floorObjectId={0}
            title={`マイクボリューム検知間隔（ミリ秒）`}
            text={this.state.micVolumeInterval ? this.state.micVolumeInterval + "" : "1000"}
            maxLength={4}
            onClose={this.handleCloseMicVolumeInterval}
            sendText={this.handleMicVolumeInterval}
        />;
    }

    drawMoveFloorDialog = () => {
        if (this.state === null || !this.state.openMoveFloor) {
            return "";
        }

        return <MoveFloorDialog isOpen={this.state.openMoveFloor} floorId={this.state.moveFloor?.floorId} floorName={this.state.moveFloor?.floorName} onClose={this.handleCloseMoveFloor} onOK={this.handleOKMoveFloor}/>
    }

    /**
     * ページ内リダイレクト処理.
     */
    redirectComponents = () => {
        if (!this.state) return;
        return (
            <React.Fragment>
                {this.state.signout === true ?
                    <Redirect to="/signout" />
                    :
                    <React.Fragment />
                }
            </React.Fragment>
        );
    }

    receiveUpdateSessionData = (result: string) => {
        alert(result); // 正式機能ではない為、Result表示の UI は手抜きで。。
    }

    private lastMicLevel = 0;
    private beforeExecuteMicLevel = 0;

    /**
     * 自デバイスの音声入力レベルを元に
     * サーバ側へ音声入力レベルを通知する
     * 
     * @param micLevel 
     */
    public sendUserMicLevel = (micLevel: number) => {
        let micVolumeInterval = this.state.micVolumeInterval;
        if (micVolumeInterval) {
        } else {
            micVolumeInterval = 1000;
            this.setState({ micVolumeInterval: micVolumeInterval });
        }

        // メニューからフロントエンド側で間引きする間隔を調整できるようにする
        if (new Date().getTime() - this.beforeExecuteMicLevel < micVolumeInterval) {
            return;
        }
        this.beforeExecuteMicLevel = new Date().getTime();

        let sendflg = false;
        let micVolumeThreshold = 25;
        if (this.state && this.state.floorData) {
            micVolumeThreshold = this.state.floorData.micVolumeThreshold;
        }

        //console.log(this.lastMicLevel, " : ", micLevel, " : ", micVolumeThreshold);
        if (this.lastMicLevel < micVolumeThreshold) {
            // 吹き出しを出してないとき

            // 閾値を超えた場合に送信
            if ((micLevel > micVolumeThreshold)) {
                sendflg = true;
            }
        } else {
            if (Math.abs(this.lastMicLevel - micLevel ) > micVolumeThreshold) 
            {// それなりに変動があったら送信（負荷低減）
                sendflg = true;
            }
            if (this.lastMicLevel > micLevel) 
            {// 下がる場合は、送信（吹き出しが出っぱなしになる対策）
                sendflg = true;
            }
        }

        if (sendflg === true) {
            if (this.myUserRef.current !== null) {
                this.sendMicLevel(this.myUserRef.current.getUser().id, micLevel);
            }
            this.lastMicLevel = micLevel;
        }
    }

    handleSendCommand = (isYes: boolean) => {
        // #11682 フロア移動ボタンで、自席が存在するフロアに戻ってきたとき、自席ではない場所に表示されることがある
        // この対応のため、"NO"でも送信する
        //if (!isYes) return;
        // let jsonAction = new JsonAction();
        // jsonAction.action = "HIT_OTHER_FLOOR_USER";
        // let avatarCommand = new AvatarCommand();
        // avatarCommand.message = isYes ? "YES" : "NO";
        // jsonAction.object = avatarCommand;
        // this.websocket.send(JSON.stringify(jsonAction));
        try {
            this.wsClient.sendAvatarCommand(isYes);
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * WebRTCの時間切れに対する継続か終了かを送る
     */
    handleContinueWebRtcResult = (isContinue: boolean) => {
        // let data: SendAction = new SendAction();
        // data.action = "CONTINUE_WEBRTC_RESULT";
        // let avatarCommand: AvatarCommand = new AvatarCommand();
        // avatarCommand.message = isContinue ? "continue" : "end";
        // data.object = avatarCommand;
        // let json = JSON.stringify(data);
        // this.websocket.send(json);

        try {
            this.wsClient.sendContinuationWebRTCResult(isContinue);
        } catch (error) {
            console.error(error);
        }
    }
    
    /**
     * ビデオ通話前の確認ダイアログの結果を受け取る
     * 
     * @param result 
     */
    private handleStartConfirmResult = (result: number) => {
        const logger: Logger = Logger.getInstance();
        console.log("WrStarting_CHK close event");
        let users = this.otherUsersRef.current.getUsers() as User[];
        let isChangeUsers = true;
        let isChangeMyUser = false;
        let myUser = this.myUserRef.current.getUser() as User;
        let isWebRtcCall = myUser.webRtcCall;
        let temp = User.copyUser(myUser) as User;
        if(result === 1) {
            // ======= 確認ＯＫ =======
            console.log("WrStarting_CHK OK");
            temp = User.copyUser(this.startingConfirmUser);
            isChangeMyUser = true;
            temp.wrStartConfirmStatus = 0;
            temp.x = myUser.x;  // #809 移動しているかもしれないので
            temp.y = myUser.y;  // #809 移動しているかもしれないので
            this.hittedonDrag = false;  // #809 ドラッグ中に話しかけられたか
            this.sendWrStartConfirmStatus(temp);

            // WebRtcの接続処理
            temp.webRtcCall = this.connectingWebRtc(this.startingConfirmUser, isWebRtcCall);
            console.log("★★ WrStarting_CHK : isWebRtcCall=["+isWebRtcCall+"] req.webRtcCall=["+temp.webRtcCall+"]")
            console.log("★★ WrStarting_CHK : myUser.webRtcRoomId=["+myUser.webRtcRoomId+"] req.webRtcRoomId=["+this.startingConfirmUser.webRtcRoomId+"]")

            // #3744 peerId取得できないことがある対応
            if(myUser.webRtcCall !== true && temp.webRtcCall === true) {
                let newPeerId = WebrtcService.getPeerIdSync();
                if(newPeerId !== undefined && newPeerId !== "") {
                    logger.info("[WR_PEER_TRACE] floor.handleStartConfirmResult GetAndSet peerId OK newPeerId=["+newPeerId+"] myUser.webRtcPeerId=["+myUser.webRtcPeerId+"]");
                    if (myUser.webRtcPeerId !== newPeerId) {
                        logger.info("[WR_PEER_TRACE] floor.handleStartConfirmResult GetAndSet newPeerId=["+newPeerId+"] myUser.webRtcPeerId=["+myUser.webRtcPeerId+"]");
                        temp.webRtcPeerId = newPeerId;
                        this.sendWebRtcPeerId(temp);
                    }
                } else {
                    // 表示
                    logger.info("[WR_PEER_TRACE] floor.handleStartConfirmResult GetAndSet peerId error newPeerId=["+newPeerId+"] myUser.webRtcPeerId=["+myUser.webRtcPeerId+"]");
                }
            }

            // WebRtc開始時、または再接続時に通知を行う。また、MyUserのwaitingを設定する。
            if ((!isWebRtcCall && temp.webRtcCall)
                || (temp.webRtcCall && (myUser.webRtcRoomId !== this.startingConfirmUser.webRtcRoomId))
                || (myUser.webRtcRoomId === this.startingConfirmUser.webRtcRoomId && this.startingConfirmUser.isReconnectWebRtc)) {
                console.log("★★ WrStarting_CHK : Notification call =["+this.startingConfirmUser.hittedUser+"]");
                // MyUser
                const videoDeviceId = localStorage.getItem("videoDeviceId");
                const audioDeviceId = localStorage.getItem("audioInputDeviceId");
                temp.isMediaWaiting = videoDeviceId !== "" || audioDeviceId !== "";
                console.log("★★ WrStarting_CHK : MyUser temp.isMediaWaiting=["+temp.isMediaWaiting+"]");
                if (temp.isMediaWaiting) {
                    //setTimeout(() => this.setIsWaitingOfMyUser(false), this.waitingTime);// 指定時間後にwaitingを解除
                    setTimeout(() => this.setIsWaitingOfMyUser(false), 10);// 指定時間後にwaitingを解除
                }
                // OtherUsers
                users = users.map(e => {
                    const isWebRtcCall = this.startingConfirmUser.webRtcRoomId !== "" && this.startingConfirmUser.webRtcRoomId === e.webRtcRoomId;
                    console.log("★★ WrStarting_CHK : OtherUsers isWebRtcCall=["+isWebRtcCall+"]");
                    if (isWebRtcCall) {
                        //setTimeout(() => this.setIsWaitingOfOtherUser(e.id, false), this.waitingTime)
                        setTimeout(() => this.setIsWaitingOfOtherUser(e.id, false), 50)
                    }
                    return {
                        ...e,
                        isMediaWaiting: isWebRtcCall,
                        webRtcCall: isWebRtcCall
                    }
                })
                temp.micVolume = this.startingConfirmUser.micVolume;
                temp.micMode = this.state !== null ? this.state.floorData.micMode : 0;
                temp.micVolumeThreshold = this.state !== null ? this.state.floorData.micVolumeThreshold : 25;
                temp.webRtcMode = this.state !== null ? this.state.floorData.webRtcMode : 0;
                temp.webRtcInfoInterval = this.state !== null ? this.state.floorData.webRtcInfoInterval : 60;
            }
        } else {
            // ======= 確認ＮＧ =======
            console.log("WrStarting_CHK NG or close");
            // 接続キャンセル＆相手にメッセージ？？
            // OtherUserのwebRtcCallをfalseにする。
            if (myUser.webRtcRoomId !== this.startingConfirmUser.webRtcRoomId) {
                isChangeUsers = true;
                users = users.map(e => {
                    return {
                        ...e,
                        webRtcCall: this.startingConfirmUser.webRtcRoomId !== "" && this.startingConfirmUser.webRtcRoomId === e.webRtcRoomId,
                    }
                })
            }
            // 相手に伝える
            if(result === 100) {
                // タイムアウト
                temp.wrStartConfirmStatus = result;
            } else {
                // 応答できない
                temp.wrStartConfirmStatus = 91;
            }
            console.log("WrStarting_CHK NG or close and send status roomId=["+temp.webRtcRoomId+"]");
            this.sendWrStartConfirmStatus(temp);
            this.hittedonDrag = false;  // #809 ドラッグ中に話しかけられたか
        }
        if (isChangeMyUser) {
            this.myUserRef.current.setUser(temp);
        }
        if (isChangeUsers) {
            this.otherUsersRef.current.setUsers(users);
        }
    }

    /**
     * 自分の「ビデオ通話前の確認」設定変更イベント
     */
     handleMyConfirmSettingChange = (value?: number)  => {
        let myUser = this.myUserRef.current.getUser();
        let tempUser = User.copyUser(myUser);
        if (value !== undefined) {
            // tempUser.avatarConfirmSetting = value;
            tempUser.avatarConfirmSetting = value as CallStartingSetting;
            this.myUserRef.current.setUser(tempUser);
            this.sendWrStartConfirmSettingData(value);
        }
    }

    /**
     * 自分の「ログイン通知」設定変更イベント
     */
     handleMyLoginoutNotificationChange = (value1?: number, value2?: number)  => {
        let myUser = this.myUserRef.current.getUser();
        let tempUser = User.copyUser(myUser);
        if (value1 !== undefined && value2 !== undefined) {
            tempUser.avatarLoginoutNotification = value1;
            tempUser.avatarLoginoutNotificationSound = value2;
            this.myUserRef.current.setUser(tempUser);
            this.sendLoginoutNotificationData(value1, value2);
        }
    }

    /**
     * WebRTCの同一ルームに開始か終了を送る
     */
    handleEnableWebRtcRoom = (id: number, enableRoom: boolean, url: string) => {
        // let data: SendAction = new SendAction();
        // data.action = "ENABLE_WEBRTC_ROOM";
        // let enableWebrtcRoomCommand: EnableWebrtcRoomCommand = new EnableWebrtcRoomCommand();
        // enableWebrtcRoomCommand.id = id;
        // enableWebrtcRoomCommand.message = enableRoom ? "enable" : "disable";
        // enableWebrtcRoomCommand.videoURL = url;
        // data.object = enableWebrtcRoomCommand;
        // let json = JSON.stringify(data);
        // this.websocket.send(json);

        const enableWebrtcRoomCommand = enableRoom ? "enable" : "disable";
        try {
            this.wsClient.sendEnableWebRtcRoom(id, enableWebrtcRoomCommand as webRTCStartEndMessage, url);
        } catch (error) {
            console.error(error);            
        }
    }
    
    /**
     * 指定idのuserdataを返す
     */
     getOtherUserFromId = (id: number) => {
        let users = this.otherUsersRef.current?.getUsers() as User[];
        if (users !== undefined) {
            let retUser = users.filter(e => e.id === id);
            if (retUser.length !== 0) {
                return retUser[0];
            }
        }
        return null;
    }

    /**
     * floorObjectListから指定idのfloorObjectを返す
     */
    getFloorObjectFromId = (id: number) => {
        return this.state.floorObjectList.find(obj => obj.id === id);
    }

    /**
     * zoomボタンの状態を合わせる
     */
    syncZoomButton = async (isZoomSpeakable: boolean, msec: number) => {
        console.log('syncZoomButton', isZoomSpeakable);
        const zoomAudioButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("zm-btn join-audio-container__btn zm-btn--default zm-btn__outline--blue zm-btn-icon")[0] as HTMLElement;
        const zoomVideoButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("zm-btn send-video-container__btn zm-btn--default zm-btn__outline--blue zm-btn-icon")[0] as HTMLElement;
        const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));

        if (zoomVideoButton && zoomAudioButton) {
            await sleep(msec);
            if (isZoomSpeakable) {
                // zoomLive有効状態でzoomのボタンを合わせる
                if (zoomAudioButton.title === 'Unmute') zoomAudioButton.click();
                if (zoomVideoButton.title === 'Start Video') zoomVideoButton.click();
            } else {
                // zoomLive無効状態でzoomのボタンを合わせる
                if (zoomAudioButton.title === 'Mute') zoomAudioButton.click();
                if (zoomVideoButton.title === 'Stop Video') zoomVideoButton.click();
            }
        }
    }

    /**
     * zoom側のSelectDeviceを選択
     */
    selectZoomDevice = async (retryCount: number = 0) => {
        const audioInputDeviceLabel = localStorage.getItem("audioInputDeviceLabel");
        const audioOutputDeviceLabel = localStorage.getItem("audioOutputDeviceLabel");
        
        const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));

        // デバイス選択をskipしてサインインすると、要素の取得ができないので、リトライをいれる
        const retry = async () => {
            if (retryCount < 40) {
                await sleep(500);
                this.selectZoomDevice(++retryCount);
            }
        }

        // get zoom viewer elements 
        const zoomDoc = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document;
        if (!zoomDoc) {
            retry();
            return;
        }

        // get ▲btn
        const optionBtn = zoomDoc.getElementById("audioOptionMenu") as HTMLElement;
        if (!optionBtn) {
            retry();
            return;
        }

        // 選択可能なデバイス一覧をとる
        const menus = zoomDoc.getElementsByClassName('audio-option-menu__pop-menu dropdown-menu');
        if (menus.length === 0) {
            retry();
            return;
        } else {
            const alist = menus[0].getElementsByTagName('a');
            // 早すぎるとだめ...
            await sleep(500)

            for (let i = 0; i < alist.length; i++) {
                // Fam選択のマイクと一致してたらclick
                if (alist[i].innerText === audioInputDeviceLabel) {
                    alist[i].click();
                }

                // Fam選択のスピーカーと一致してたらclick
                if (alist[i].innerText === audioOutputDeviceLabel) {
                    alist[i].click();
                }
            }
        }
    }

    /**
     * Zoom の camera, mic の mute 切替
     */
    handleZoomSpeak = (enable: boolean) => {
        console.log('handleZoomSpeak', enable);
        const zoomAudioButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("zm-btn join-audio-container__btn zm-btn--default zm-btn__outline--blue zm-btn-icon")[0] as HTMLElement;
        const zoomVideoButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("zm-btn send-video-container__btn zm-btn--default zm-btn__outline--blue zm-btn-icon")[0] as HTMLElement;
        const zoomScreenShareButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("footer-button__button ax-outline")[1] as HTMLElement;
        const zoomScreenShareStopButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("sharer-button sharer-button--pattern1")[0] as HTMLElement;

        if (zoomAudioButton && zoomVideoButton && zoomScreenShareButton) {
            // Zoom側 select device 
            if (enable) this.selectZoomDevice();

            // Zoom UI内のaudio, video ボタンをclick
            if (enable) {
                if (zoomAudioButton.title === 'Unmute') zoomAudioButton.click();
                if (zoomVideoButton.title === 'Start Video') zoomVideoButton.click();
            } else {
                if (zoomAudioButton.title === 'Mute') zoomAudioButton.click();
                if (zoomVideoButton.title === 'Stop Video') zoomVideoButton.click();
            }

            // Zoom開始部屋から抜けた時、画面共有をOFFにする
            if (!enable && zoomScreenShareStopButton) {
                zoomScreenShareStopButton.click();
            }
            // enable と ボタン状態がずれていた場合、揃える
            this.syncZoomButton(enable, 400);

            // Zoom UI内のscreen share ボタンを表示・非表示
            zoomScreenShareButton.style.display = enable ? "block" : "none";
            const handler = () => this.syncZoomButton(true, 500);
            zoomScreenShareButton.removeEventListener("click", handler);
            zoomScreenShareButton.addEventListener("click", handler, false);
        }
    }

    /**
     * Zoom SDK の mute 呼び出し
     */
    muteZoom = (isMute: boolean) => {
        this.zoomViewRef.current?.mute(isMute);
    }

    /**
     * zoom mic ボタンの状態を合わせる
     */
    syncZoomMicButton = async (isZoomMicMute: boolean, msec: number) => {
        const zoomAudioButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("zm-btn join-audio-container__btn zm-btn--default zm-btn__outline--blue zm-btn-icon")[0] as HTMLElement;
        const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));
        const click = (elm: HTMLElement, isMute: boolean) => {
            if (elm) {
                if (isMute) { // zoomLive有効状態でzoom ボタンを合わせる
                    if (elm.title === 'Unmute') elm.click();
                } else {      // zoomLive無効状態でzoom ボタンを合わせる
                    if (elm.title === 'Mute') elm.click();
                }
            }
        }

        click(zoomAudioButton, isZoomMicMute);
        await sleep(msec);
        click(zoomAudioButton, isZoomMicMute);
    }

    /**
     * zoom cam ボタンの状態を合わせる
     */
    syncZoomCamButton = async (isZoomCamMute: boolean, msec: number) => {
        const zoomVideoButton = (document.getElementById("zoomViewer") as HTMLIFrameElement)?.contentWindow?.document.getElementsByClassName("zm-btn send-video-container__btn zm-btn--default zm-btn__outline--blue zm-btn-icon")[0] as HTMLElement;
        const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));
        const click = (elm: HTMLElement, isMute: boolean) => {
            if (elm) {
                if (isMute) { // zoomLive有効状態でzoom ボタンを合わせる
                    if (elm.title === 'Start Video') elm.click();
                } else {      // zoomLive無効状態でzoom ボタンを合わせる
                    if (elm.title === 'Stop Video') elm.click();
                }
            }
        }

        click(zoomVideoButton, isZoomCamMute);
        await sleep(msec);
        click(zoomVideoButton, isZoomCamMute);
    }

    /**
     * zoom screen share ボタンの状態を合わせる
     * !syncZoomScreenShareButtonは未完成
     * !現状、Zoom UI内の画面共有ボタン押下のみ実装してある
     * !見本市対応で作成したが未使用
     */
    /*syncZoomScreenShareButton = async (isZoomScreenShare: boolean, msec: number) => {
        const zoomScreenShareButton = isZoomScreenShare 
            ? (document.getElementById("zoomViewer") as HTMLIFrameElement).contentWindow?.document.querySelectorAll('[aria-label="Share Screen"]')[0] as HTMLElement
            : (document.getElementById("zoomViewer") as HTMLIFrameElement).contentWindow?.document.getElementsByClassName("sharer-button sharer-button--pattern1")[0] as HTMLElement;

        const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));

        if (zoomScreenShareButton) {
            zoomScreenShareButton.click();
            await sleep(msec);
            if (isZoomScreenShare) {
                // zoomLive有効状態でzoom screen share ボタンを合わせる
                zoomScreenShareButton.click();
            } else {
                // zoomLive無効状態でzoom screen share ボタンを合わせる
                zoomScreenShareButton.click();
            }
        }
    } */

    /**
     * zoom full screen ボタンの状態を合わせる
     * !見本市対応で作成したが未使用
     */
    /* syncZoomFullScreenButton = async () => {
        const container = (document.getElementById("zoomViewer") as HTMLIFrameElement).contentWindow?.document.getElementsByClassName("gallery-video-container__wrap")[0] as HTMLElement;
        if (container) container.dispatchEvent(new MouseEvent('dblclick', { 'bubbles': true }));
    } */

    /**
     * テストコード
     */
    addUsers = () => {
        let data: SendAction = new SendAction();
        data.action = "TEST_ADD_USERS";
        let json = JSON.stringify(data);
        // this.websocket.send(json);
    }
    removeUsers = () => {
        let data: SendAction = new SendAction();
        data.action = "TEST_REMOVE_USERS";
        let json = JSON.stringify(data);
        // this.websocket.send(json);
    }
    handleTestMoveUser = () => {
        let data: SendAction = new SendAction();
        data.action = "TEST_MOVE_USER";
        let json = JSON.stringify(data);
        // this.websocket.send(json);
    }
    handleTestLoginLogoutUser = () => {
        let data: SendAction = new SendAction();
        data.action = "TEST_LOGIN_LOGOUT_USER";
        let json = JSON.stringify(data);
        // this.websocket.send(json);
    }
    handleTestStopVideo = () => {
        const userDataList = WebrtcService.getUserDataList();

        userDataList.forEach(userData => {
            const video: HTMLVideoElement = document.getElementById(userData.id + "-video") as HTMLVideoElement;
            if (video) {
                video.srcObject = null;
            }
        });

        let myUser = this.myUserRef.current.getUser();
        const video: HTMLVideoElement = document.getElementById(myUser.id + "-video") as HTMLVideoElement;
        if (video) {
            video.srcObject = null;
        }
    }
    handleTestDiffData = () => {
        let myUser = this.myUserRef.current.getUser() as User;
        let users = this.otherUsersRef.current.getUsers() as User[];
        let tempUsers = [...users, myUser];
        let sendData = tempUsers.sort((user1, user2) => {
            if (user1.id > user2.id) return 1;
            if (user1.id < user2.id) return -1;
            return 0;
        });

        var params = new URLSearchParams();
        params.append("floor_id", this.floorId.toString());
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        params.append("data", JSON.stringify(sendData));

        axios.post('/api/user/testapi/diffdata', params)
            .then((e: AxiosResponse) => {
                let d = new Date(Number(e.data));
                let dateText = 'yyyy/MM/dd HH:mm:ss'
                    .replace(/yyyy/g, String(d.getFullYear()))
                    .replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
                    .replace(/dd/g, ('0' + d.getDate()).slice(-2))
                    .replace(/HH/g, ('0' + d.getHours()).slice(-2))
                    .replace(/mm/g, ('0' + d.getMinutes()).slice(-2))
                    .replace(/ss/g, ('0' + d.getSeconds()).slice(-2));
                this.props.enqueueSnackbar("比較完了しました。バックエンドのログからレポートを確認してください。(比較日時: " + dateText + ")");
            }).catch(err => {
                console.error(err);
            });
    }
    getUseTurnServer = () => {
        return this.myUserRef.current?.getUseTurnServer();
    }

    /**
     * 画面共有を開始/解除したユーザー情報をサーバーへ送信する
     * 
     * @param isScreenShare
     * @param mode 1: 全画面モード 2: フローティングモード
     */
    handleScreenShare = (isScreenShare: boolean, mode: number, webRtcRoomId: string) => {
        this.sendScreenShare(this.myUserRef.current?.getUser().id, isScreenShare, mode, webRtcRoomId);
        this.floorMenuRef.current?.setScreenShare(isScreenShare);
    }

    /**
     * 画面共有を解除する。
     *   F5、更新、サインアウト時に使用している。
     */
    clearScreenShare = () => {
        const { isScreenShare, webRtcRoomId } = this.myUserRef.current?.getUser();
        isScreenShare && this.handleScreenShare(false, ScreenShareMode.FullScreen, webRtcRoomId);
    }

    /**
     * MyUser内の画面共有処理をFloorMenuから呼び出すためのメソッド
     *   【補足】
     *   FloorMenuのpropsに this.myUserRef.current?.onChangeScreenShare を直接渡すと
     *   onChangeScreenShare内の user.webRtcRoomId が 意図せず空になるケースが発生した。、
     *   下記のようにラップすると回避できた。 
     */
    onChangeScreenShare = (isScreenShare: boolean) => {
        this.myUserRef.current?.onChangeScreenShare(isScreenShare);
    }

    // 画面共有ボタン
    setVisibleScreenShareButton = (value: boolean) => {
        // ユーザ情報の取得
        const myUser = this.myUserRef.current.getUser() as User;
        if(myUser.isTrafficLimitVideoMute){
            // 通信量制限によるカメラ使用制限中の場合は常にOFF
            this.setState({ visibleScreenShareButton: false });
        } else {
            this.setState({ visibleScreenShareButton: value });
        }
    }

    /**
     * 画面共有要素にvideo要素のアスペクト比をセットする。
     * 
     * @param 
     */
    onScreenShareVideoLoaded = (isLoaded: boolean, screenShareSessionId: string, userList?: Map<string, User>) => {
        const logger: Logger = Logger.getInstance();
        logger.info("[SS_WindowSelect] onScreenShareVideoLoaded : ["+isLoaded+"]  displayMessage=["+this.displayShareScreenMessage+"]");
        // #923 画面共有起動時のメッセージを二重に表示しない
        // SnackBarのpreventDuplicateの設定が変更されているので、ここで判定入れる
        if(this.displayShareScreenMessage !== isLoaded) {
            let message = "";
            // メッセージ表示したままフロア移動（メッセージ設定なしフロア）移動したら消えないので・・・
            if (isLoaded === true && this.state !== null && this.state.floorData !== null) {
                // isLoadedがtrueの時にメッセージ必要
                message = this.state.floorData.startScreenShareMessage;
            }
            if(isLoaded === false || (message !== null && message !== "")) {
                // isLoadedがfalseの時はメッセージ設定に関係なく消す（表示しないフロアへ移動した時対応）
                console.log("startScreenShare onScreenShareVideoLoaded : msg=["+message+"]")
                this.showAttentionSnackBar(isLoaded, message);
                this.displayShareScreenMessage = isLoaded;  // #923
            }
        }
        this.shareScreenRef.current?.onScreenShareVideoLoaded(isLoaded, screenShareSessionId, userList);

        // #761 共有ウインド選択のバッティング対応
        // 選択終了をサーバーに送信＆ボタンを押せるようにする
        logger.info("[SS_WindowSelect] set Selecting : false");
        this.sendScreenShareWindowSelected();
        this.floorMenuRef.current?.setScreenShareWindowSelecting(false);
    }

    /**
     * 共有画面表示要素の表示・非表示切替
     * 
     * @param isOpen true: open, false: close
     */
    handleShareScreenOpen = (isOpen: boolean) => {
        this.shareScreenRef.current?.open(isOpen);

        // #761 共有ウインド選択のバッティング対応
        const logger: Logger = Logger.getInstance();
        logger.info("[SS_WindowSelect] handleShareScreenOpen set Selecting : ["+isOpen+"]");
        if(isOpen === false) {
            // 選択終了（キャンセル？）をサーバーに送信
            this.sendScreenShareWindowSelected();
        } else {
            // 別の人が起動した場合は、画面共有ボタンを元に戻す
            // 同時押しした場合に、画面共有起動していないが共有停止ボタンにならないようにする
            const myUser = this.myUserRef.current.getUser() as User;
            if(myUser !== undefined && myUser !== null) {
                logger.info("[SS_WindowSelect] handleShareScreenOpen 画面共有ボタンcheck selecting=["+myUser.isScreenShareWindowSelecting+"] ["+myUser.displayName+"]");
                if(myUser.isScreenShareWindowSelecting === false) {
                    this.floorMenuRef.current?.setScreenShare(false);
                    logger.info("[SS_WindowSelect] handleShareScreenOpen 画面共有ボタンを元に戻す ["+myUser.displayName+"]");
                }
            } else {
                logger.info("[SS_WindowSelect] handleShareScreenOpen 画面共有ボタンcheck myUser is null");
            }
        }
        this.floorMenuRef.current?.setScreenShareWindowSelecting(isOpen);
    }

    /**
     * カメラなしでサインインした人へメッセージ表示する。
     * 
     * @param 
     */
    /*
     displayMessageNoCamera = (isDisp: boolean) => {
        console.log("displayMessageNoCamera : isDisp=["+isDisp+"]")
        let message = "";
        if (this.state !== null && this.state.floorData !== null) {
            message = this.state.floorData.signinNoCameraMessage;
        }
        if(message !== null && message !== "") {
            console.log("displayMessageNoCamera : msg=["+message+"]")
            this.showAttentionSnackBar(isDisp, message);
        }
    }
    */

    /**
     * カメラやマイクなしでサインインした人へメッセージ表示する。
     * 
     * @param 
     */
    displayMessageNoDevice = (device: number, isDisp: boolean) => {
        console.log("displayMessageNoDevice : isDisp=["+isDisp+"]  device=["+device+"]")
        let message1 = "";
        let message2 = "";
        if(device === 1) {
            if (this.state !== null && this.state.floorData !== null) {
                message1 = this.state.floorData.signinNoCameraMessage;
            }
            if(message1 !== null && message1 !== "") {
                console.log("displayMessageNoDevice : msg=["+message1+"]")
                this.showAttentionSnackBar(isDisp, message1);
            }
        } else if(device === 2) {
            if (this.state !== null && this.state.floorData !== null) {
                message2 = this.state.floorData.signinNoCameraMessage;
            }
            if(message2 !== null && message2 !== "") {
                console.log("displayMessageNoDevice : msg=["+message2+"]")
                this.showAttentionSnackBar(isDisp, message2);
            }
        } else {
            console.log("displayMessageNoDevice : device not match")
        }
    }

    // for Debug (webrtc retry : skyway close 対応)
    handleDebugSend = async (id: number) => {
        //alert('handleDebugSend:'+id)
        /***************************
        const myUser = this.myUserRef.current.getUser() as User;
        if(myUser !== undefined && myUser !== null) {
            const tempMyUser = User.copyUser(myUser) as User;
            tempMyUser.debugSend = id;
            this.myUserRef.current.setUser(tempMyUser);
        }
        *********************************/
        if(id === 2) {
            let pid = await WebrtcService.getPeerId();
            WebrtcService.destroyPeer();
            const logger: Logger = Logger.getInstance();
            logger.info("[WR_TRACE_JOINSTS] peer destroy for test pre_pid=["+pid+"]");
        }
    }

    /**
     * 全体放送
     */
    hanldleBroadcast = (isBroadcast: boolean, userId: number) => {
        isBroadcast ? this.sendConnectWebRtcAllUser(userId + "") : this.sendEndMoveAllUser();
    }

    /**
     * アンロード前処理
     * 
     * F5押下・更新ボタン押下・ウィンドウを閉じる・タブを閉じる でアンロードされる前に処理される。
     */
    handleBeforeunload = () => {
        const { id } = this.myUserRef.current?.getUser() as User;
        this.clearScreenShare();
        if (id) this.sendZoomJoin(id, 0);
    }

    // handleChangeFloor = (selectFloor?: SelectFloor) => {
    //     this.websocket.sendStartChageFloor(this.floorId);
    //     var params = new URLSearchParams();
    //     if (selectFloor == null) {
    //         params.append("floor_id", this.floorId.toString());
    //         this.floorId = this.floorId === 1 ? 2 : 1;
    //     } else {
    //         params.append("floor_id", selectFloor.floorId.toString());
    //     }
    //     params.append("tab_id", sessionStorage.getItem("TABID") as string);
    //     axios.post('/api/user/floor/change', params)
    //         .then((e: AxiosResponse) => {
    //             this.websocket.disconnect();
    //             this.websocket.connect(this, this.websocketPath);
    //             console.log(e);
    //         }).catch(err => {
    //             console.log(err);
    //         });

    // }
    getTestMode = async () => {
        try {
            // this.isDrawTestNav = (await axios.get('/api/test/istestmode')).data;
            this.isDrawTestNav = await this.httpClient.getTestMode();
            //this.isDrawTestNav = true;  // for Debug
        } catch (e) {
            console.error(e);
        }
    }

    handleGetAvatarColors = async () => {
        var params = new URLSearchParams();
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        try {
            // this.avatarDatas = (await axios.post('/api/user/avatar/allavatars', params)).data;
            this.avatarDatas = await this.httpClient.getAllAvatarsInfo(sessionStorage.getItem("TABID") as string);
        } catch (e) {
            console.error(e);
        }
    }

    // 名札のデータ取得
    handleGetNamePlateColors = async () => {
        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        try {
            // this.namePlateColors = (await axios.post('/api/user/flexibleNamePlate/allcolors', params)).data;
            this.namePlateColors = await this.httpClient.getNamePlateColors(sessionStorage.getItem("TABID") as string);
        } catch (e) {
            console.error(e);
        }
    }

    checkMediaDevices = async (requireCamera: boolean, requireMic: boolean) => {
        await WebrtcService.checkMediaDevices();
        // console.log("Camera : " + WebrtcService.hasCamera());
        // console.log("Mic : " + WebrtcService.hasMic());

        let openDeviceSelect = false;
        let message = "";
        if (requireCamera && requireMic) {
            // カメラ、マイクが必須
            if (WebrtcService.hasCamera() && WebrtcService.hasMic()) {
                openDeviceSelect = true;
            } else {
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
            }
        } else if (requireCamera) {
            // カメラが必須
            if (WebrtcService.hasCamera()) {
                openDeviceSelect = true;
            } else {
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラの使用が許可されているか";
            }
        } else if (requireMic) {
            // マイクが必須
            if (WebrtcService.hasMic()) {
                openDeviceSelect = true;
            } else {
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でマイクの使用が許可されているか";
            }
        } else {
            // 必須ではない場合もカメラ、マイク選択画面を表示する(ただし、事前に選択済みのデバイスが存在する場合には表示されない場合もある)
            openDeviceSelect = true;
        }

        if (openDeviceSelect) {
            if (this.myUserRef.current) {
                // WebRtcComponentのcheckMediaDevicesをフロア移動後に呼び出すために
                // falseに設定してからtrueを設定している
                this.myUserRef.current.setCheckMediaDevices(false);
                this.myUserRef.current.setCheckMediaDevices(true);
            }
        } else {
            const myUser = this.myUserRef.current.getUser() as User;
            const tempMyUser = User.copyUser(myUser) as User;
            const signinpage = Utility.getSigninPage();
            tempMyUser.notifyTitle = "デバイスが見つかりませんでした";
            tempMyUser.notifyMessage = message;
            tempMyUser.notifyRedirectUrl = signinpage;
            tempMyUser.notifiRedirectCaption = "ログアウト";
            tempMyUser.dispSignout = false;
            /* */
            /* ちゃんとした仕様にすべく、再検討。一旦やめる。（2021/12/6）
            tempMyUser.notifyTitle = "デバイスが見つかりませんでした";
            tempMyUser.notifyMessage = message;
            tempMyUser.notifyRedirectUrl = '/top';
            tempMyUser.notifiRedirectCaption = "フロア選択";
            tempMyUser.dispSignout = true;
            */
            this.myUserRef.current.setUser(tempMyUser);
        }
    }

    /**
     * 画面の上部、中央に警告用のSnackbarを表示する
     * 
     * @param message 
     */
    showAttentionSnackBar = (disp:boolean, msg: string) => {
        var key: string = "attentionMessage";
        if(disp === true) {
            let message: React.ReactNode = 
                <span>
                {msg}
                <br/>
                </span>;
            this.props.enqueueSnackbar(
                message,
                {
                    key: key,
                    variant: "info",
                    //variant: "warning",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    persist: true,
                    //preventDuplicate: true,
                    action: key => (
                        <Button onClick={() => this.props.closeSnackbar(key)}>✕</Button>
                    ),
                }
            );
        } else {
            this.props.closeSnackbar(key);
        }
    }

    /**
     * 音声の再生に失敗した場合、WebRTC通話の音声を再生できるようにダイアログを表示している。
     * 
     * @param id 
     */
    playSoundFromAudioId = (id: number) => {
        const logger: Logger = Logger.getInstance();
        AudioPlayer.play(id).then((result: boolean) => {
            if (result === false) {
                const myUser = this.myUserRef.current.getUser() as User;
                const tempMyUser = User.copyUser(myUser) as User;
                tempMyUser.notifyTitle = "音声の再生ができませんでした";
                tempMyUser.notifyMessage = "本メッセージを閉じた後、音声を再生します。(1)";
                this.myUserRef.current.setUser(tempMyUser);
            }
            // iPad対応-11 for play_test
            if(id === AudioId.Notify) {
                logger.info("PLAY_SOUND_TRACE 話しかけられた音再生終了");
            }
        });
    }

    drawTestNav = () =>
        this.isDrawTestNav &&
        <TestDebugMenu
            myUserId={this.myUserRef.current.getUser().id}
            usersCount={this.otherUsersRef.current?.getUsersCount()}
            floorId={this.floorId}
            webRtcMode={this.state.floorData?.webRtcMode}
            webRtcRoomId={this.myUserRef.current?.getUser().webRtcRoomId}
            isScreenShare={this.state && this.state.visibleScreenShareButton && WebrtcService.hasScreenShareFunctionCheck(navigator)}
            screenShareMode={this.myUserRef.current?.getUser().screenShareMode}
            isVisibleWebRtcBroadCastButton={this.state && this.state.visibleWebRtcBroadCastButton}
            isVisibleWebRtcBroadCastStopButton={this.state && this.state.visibleWebRtcBroadCastStopButton}
            addUsers={this.addUsers}
            removeUsers={this.removeUsers}
            handleTestMoveUser={this.handleTestMoveUser}
            handleTestLoginLogoutUser={this.handleTestLoginLogoutUser}
            handleTestStopVideo={this.handleTestStopVideo}
            handleTestDiffData={this.handleTestDiffData}
            handleScreenShare={this.handleScreenShare}
            sendConnectWebRtcAllUser={this.sendConnectWebRtcAllUser}
            sendEndMoveAllUser={this.sendEndMoveAllUser}
            sendWebSocket={this.sendWebSocket}
            getUseTurnServer={this.getUseTurnServer}
            handleTestSnackBar={this.handleTestSnackBar}
            handleDebugSend={this.handleDebugSend}      // for Debug (webrtc retry : skyway close 対応)
        />

    /**
     * Memo要素を開く
     *   ・未読メモリスト要素が開いている場合
     *     ・メモ要素を画面右上に表示する
     *     ・メモ要素の左側に未読メモリスト要素を移動させる
     *   ・未読メモリスト要素が開いていない場合
     *     ・メモ要素を画面右上に表示する
     * @param groupId
     * @param groupName
     */
    handleOpenMemo = (groupId: number, groupName: string) => {
        //this.chatComponentRef.current.open();
        const parent = document.getElementById("floorContainer") as HTMLDivElement;
        const { offsetWidth } = parent;
        // memo
        const x = offsetWidth - this.memoComponentSize.width;
        this.memoComponentRef.current?.open(groupId, groupName, { x, y: 0 });
        // memo group list
        const isMemoGroupListOpen = this.memoGroupListRef.current.getOpen();
        if (isMemoGroupListOpen) this.moveMemoGroupList(1);
        this.setToTop("Memo");
    }
    private dummyChat : Chat[] = [{userId:0,chat:"1行目自分の発言",name:"",myData:true},{userId:0,chat:"2行目他人の発言",name:"",myData:false}];

    /**
     * 未読メモリスト要素を開く
     *   ・メモ要素が開いている場合、メモ要素の左側に表示する
     *   ・メモ要素が開いていない場合、画面右上に表示する
     */ 
    handleOpenMemoGroupList = () => {
        const parent = document.getElementById("floorContainer") as HTMLDivElement;
        const { offsetWidth } = parent;
        const isMemoOpen = this.memoComponentRef.current.getOpen();
        const posX = offsetWidth - this.memoGroupListSize.width
        const x = isMemoOpen ? posX - this.memoComponentSize.width : posX;
        this.memoGroupListRef.current?.open({ x, y: 0 });
        this.setToTop("MemoList");
    }

    /**
     * ユーザー検索要素を開く
     */
    handleOpenUserSearch = (open: boolean) => {
        if (open) {
            this.userSearchRef.current?.open({ x: 10, y: 50 });
            this.setToTop("UserSearch");
        } else {
            this.userSearchRef.current?.close();
        }
    }

    // ユーザ検索のウィンドウ側から閉じられた -> FloorMenu側も状態を設定する
    handleCloseUserSearch = () => {
        this.floorMenuRef.current?.setOpenSearch(false);
    }

    /**
     * TODO: V2で不要になるかもしれない
     * Window Resize時処理
     */
    handleWindowResize = () => {
        const isMemoOpen = this.memoComponentRef.current.getOpen();
        const isMemoGroupListOpen = this.memoGroupListRef.current.getOpen();
        if (isMemoOpen) {
            this.moveMemo();
        }
        if (isMemoGroupListOpen) {
            isMemoOpen ? this.moveMemoGroupList(1) : this.moveMemoGroupList();
        }

        const isChatOpen = this.groupChatComponentRef.current.getOpen();
        if(isChatOpen){
            const parent = document.getElementById("floorContainer") as HTMLDivElement;
            const { offsetWidth } = parent;
            const w = Utility.getWidth(360, 720, 360, 540);
            const h = Utility.getWidth(250, 720, 500, 250);
            const x = offsetWidth - w;
            this.groupChatComponentRef.current.setSize(x, w, h);
        }

        const isNoticeBoardDetailOpen = this.draggableWebpageRef.current.getOpen();
        if(isNoticeBoardDetailOpen){
            const {url, boardName, enableDownload, floorId, objectId, noticeNo} = this.draggableWebpageRef?.current.getParam();
            //this.draggableWebpageRef?.current.close();
            this.draggableWebpageRef?.current.open(url, boardName, enableDownload, floorId, objectId, noticeNo);
        }
    }

    /**
     * 
     */
    moveMemo = () => {
        const parent = document.getElementById("floorContainer") as HTMLDivElement;
        const { offsetWidth } = parent;
        const x = offsetWidth - this.memoComponentSize.width;
        this.memoComponentRef.current?.setCoord({ x, y: 0 });
    }

    /**
     * 未読メモリスト要素の位置を移動する。
     * @param mode undefined: 画面右上, 1: メモ要素左側
     */
    moveMemoGroupList = (mode?: number) => {
        const parent = document.getElementById("floorContainer") as HTMLDivElement;
        const { offsetWidth } = parent;
        let x = offsetWidth - this.memoGroupListSize.width;
        if (mode === 1) {
            x = x - this.memoComponentSize.width;
        }
        this.memoGroupListRef.current?.setCoord({ x, y: 0 });
    }
    
    /**
     * FloorMenuコンポーネントに未読メモ数をセットし、通知バッジを再描画する。
     * @params count MyUserの未読メモ数
     */
    setUnreadMemoCount = (count: number) => {
        this.floorMenuRef.current.setUnreadMemoCount(count);
    }

    /**
     * フロア移動が出来なかった時のダイアログを表示する
     * フロア移動で、以前のセッションの解放が終わっていない時に使用される
     */
    openMoveFloorFailedDialog = () => {
        // ダイアログ自動クローズ対応
        if(this.isOpenMoveFloorFailedDialog === false) {
            this.isOpenMoveFloorFailedDialog = true;
            this.moveFloorFailedDialogRef?.current.open();
        }
    }

    /**
     * フロア移動が出来なかった時のダイアログから、リトライの処理を実行する
     */
    closeMoveFloorFailedDialog = (no:number) => {
        // ダイアログ自動クローズ対応
        if(no === 1) {
            // this.isOpenMoveFloorFailedDialog = false;
            // this.websocket.retryMoveFloor();

            try {
                this.isOpenMoveFloorFailedDialog = false;
                // this.websocket.retryMoveFloor();
                this.wsClient.retryMoveFloor(sessionStorage.getItem("TABID") as string, this.openMoveFloorFailedDialog);
            } catch (error) {
                console.error(error);            
            }
        } else {
            // サインアウト できないので仕方なくリトライ
            //this.handleSelectedSignout();
            // this.websocket.retryMoveFloor();

            try {
                this.wsClient.retryMoveFloor(sessionStorage.getItem("TABID") as string, this.openMoveFloorFailedDialog);
            } catch (error) {
                console.error(error);            
            }
        }
    }

    /**
     * フロア移動のチェックリトライアウト　出来なかった時のダイアログを表示する
     */
    openMoveFloorRetryOutDialog = () => {
        // ダイアログ自動クローズ対応
        this.moveFloorFailedDialogRef?.current.retryout();
    }

    receiveOpenedHello = (json: any) => {
        console.log(json);
        this.myUserRef?.current.openedHello(json);
    }

    receiveFloorEditer = (json: boolean) => {
        if(json){
            this.changeFloorEditMode();
        }else{
            //alert("他のユーザーが編集中です。");
            this.newFloorObjectWindowRef?.current.noticeDialog("他のユーザーが編集中です。");
        }
    }

    setFloorEditMode = (mode: boolean) => {
        if(!mode && !this.state?.floorEditMode){this.newFloorObjectWindowRef?.current.noticeDialog("他のユーザーが編集中です。")}
        this.resetSelectedFloorObject();
        this.setState({
            gridRange: 1,
            floorEditMode: mode,
        })
    }

    //floor編集切替え
    changeFloorEditMode = () => {
        this.resetSelectedFloorObject();
        if(!(this.state?.floorEditMode)){
            this.setState({
                gridRange: 1,
            })
        }
        this.setState({
            floorEditMode: !(this.state.floorEditMode)
        })
        //alert(this.state.floorEditMode);
    }

    startDragg = () => {
        this.setState({
            isFloorObjDragging: true
        })
        this.isDraggingFloorObject = true;
    }

    private movedFloorObject = new FloorObject();
    endDragg = () => {
        this.setState({
            isFloorObjDragging: false
        })
        this.isDraggingFloorObject = false;
        //
        // let editedFloorObjectList = this.state?.floorObjectList.filter((obj) => {
        //     return (obj.id != this.movedFloorObject.id);
        // });
        // this.sendWebSocket("FLOOR_EDIT", this.movedFloorObject);
    }

    getCurrentObjData = (object: FloorObject) => {
        // alert(object.id);
        //stateに保存
        this.movedFloorObject = object;
    }

    changeGridRange = () => {
        //gridRangeが1以上の場合グリッドが有効なので1を入れて無効化
        var range = this.state?.gridRange > 1 ? 1 : this.GRID_RANGE;
        this.setState({
            gridRange: range
        })
    }

    //スナップポイントへスナップ
    selectedFloorObjSnap = (x:number, y:number) => {

        const snapObject = this.state?.selectedFloorObj;
        snapObject.offsetLeft = x;
        snapObject.offsetTop = y;

        // alert('snap');
        this.setState({
            selectedFloorObj: snapObject,
        });
    }

    //オブジェクト移動時のエラー
    handleObjectMoveError = (message: string) => {
        this.newFloorObjectWindowRef?.current.noticeDialog(message);
        this.resetSelectedFloorObject();
    }

    //新規オブジェクト選択画面描画
    handleOpenNewFloorObjWindow = () => {
        this.newFloorObjectWindowRef?.current.open();
    }

    // フロア編集中アラート
    floorEditorSnackBar = (data: EditorSnackBar) => {
        // this.newFloorObjectWindowRef?.current.editFloorSnackBar(data);
        this.floorEditorRef?.current.editFloorSnackBar(data);
    }

    // エントランスがないアラート
    noEntranceAlert = (data: EditorSnackBar) => {
        this.floorEditorRef?.current.entranceAlert(data);
    }

    handleTestSnackBar = () => {
        this.showAttentionSnackBar(true, "testMessage");
    }
    
    //選択状態のオブジェクト削除
    handleDeleteFloorObject = () => {
        this.newFloorObjectWindowRef?.current.deleteFloorObject();
    }

    deleteObjectOk = () => {
        this.resetSelectedFloorObject();
        // this.sendWebSocket("DELETE_FLOOR_OBJECT", this.state.selectedFloorObj);
        try {
            this.wsClient.sendDeleteFloorObject(this.state.selectedFloorObj);
        } catch (error) {
            console.error(error);
        }
    }

    checkIsSit = () => {
        // this.sendWebSocket("CHECK_IS_SIT", this.state?.selectedFloorObj);
        try {
            this.wsClient.sendCheckIsSit(this.state?.selectedFloorObj);
        } catch (error) {
            console.error(error);
        }
    }

    resetSelectedFloorObject = () => {
        this.setState({
            selectedFloorObj: new FloorObject(),
            isFloorObjSelected: false,
        })
        this.movedFloorObject = new FloorObject();
    }

    //選択されたオブジェクトのデータを取得
    selectedFloorObject = () => {
        if(this.state?.floorEditMode){
            var floorOfPointerX = this.pointerPosition.left + this.pointerPosition.x;
            var floorOfPointerY = this.pointerPosition.top + this.pointerPosition.y;
            
            //ポインタ座標から選択されたオブジェクト特定
            for(var object of this.state?.floorObjectList){
                if(object.offsetLeft <= floorOfPointerX && 
                object.offsetLeft+object.objectMaster.width >= floorOfPointerX &&
                object.offsetTop <= floorOfPointerY &&
                object.offsetTop+object.objectMaster.height >= floorOfPointerY &&
                this.state?.selectedFloorObj != object){
                    this.changeSelectedFloorObject(object);
                }
            }
        }
    }

    // 選択されたオブジェクトを変更
    changeSelectedFloorObject = (object: FloorObject) => {
        this.setState({
            selectedFloorObj: object,
            isFloorObjSelected: true,
        });
    }

    floorReSize = (floorHeight:number, floorWidth:number) => {
        // 変更前
        var beforeHeight = this.state?.floorData.floorHeight;
        var beforeWidth = this.state?.floorData.floorWidth;

        var editedFloor = this.state?.floorData;
        editedFloor.floorHeight = floorHeight;
        editedFloor.floorWidth = floorWidth;
        
        this.setState({
            floorData: editedFloor,
        })
        this.floorEditorRef.current?.reSizeSnackbar(beforeHeight, beforeWidth, floorHeight, floorWidth);
    }

    //床変更
    // changeFloorBackground = (id: number) => {
    //     var editedFloor = this.state?.floorData;
    //     editedFloor.backgroundObject.id = id;

    //     this.setState({
    //         floorData: editedFloor,
    //     })
    // }
    changeFloorBackground = (floorData: FloorData) => {
        this.setState({
            floorData: floorData,
        })
    }

    receiveUpdateZoomInfo = (info: UpdateZoomInfo) => {
        this.zoomViewRef.current?.reload();
    }

    //連絡ダイアログ
    openInternalNotice = () => {
        // 会議中かどうかの判断のため
        const isInMeet: boolean = !(this.myUserRef.current?.getUser().stayObjectId===0);
        this.InternalNoticeDialogRef.current?.open(isInMeet);
    }

    receiveInternalNotice = (json: InternalNotice) => {
        if( json.doRingAlarm ){
            this.playSoundFromAudioId(AudioId.RecvMessage);
        }
        this.InternalNoticeDialogRef.current?.showNotice(json.message, json.color, json.user, json.target);
    }

    receiveNewVisitor = (json: NewVisitor) => {
        this.PrivacyRoomPermissionDialogRef.current?.close();
        this.playSoundFromAudioId(AudioId.Notify);
        this.PrivacyRoomPermissionDialogRef.current?.open(json.floorId,json.userName,json.userId);
    }

    receiveExtendPrivacyRoomPermission = (json: ExtendPrivacyRoomPermission) => {
        this.floorMenuRef.current?.extendPermission();
    }

    receiveLeaveRoom = async (json: LeaveRoom) => {
        let myUser = this.myUserRef.current.getUser() as User;
        let preFloorId = myUser.privacyRoomInfo.preFloorId;
        //alert("return to floor.  " + preFloorId + " : " + myUser.displayName);
        this.floorMenuRef.current?.leaveRoom(preFloorId);
    }

    receivePrivacyRoomPermison = (json: PrivacyRoomPermission) => {
        let preFloorId = this.floorId;
        let preFloorName = this.state.floorData.floorName;

        if(json.permissionPrivacyRoom){
            //許可されたため、許可待ちのダイアログを閉じる
            this.floorMenuRef.current?.permission();

            //フロア移動する前に自席に移動し、ステータスは離籍中にする
            this.handleJumpToMySeat();

            //現在、「離籍中」ステータスなのか確認する
            let myUser = this.myUserRef.current.getUser();
            let temp = User.copyUser(myUser);
            if(temp.state !== 12){
                this.handleMyStateChange(12,false); //離籍中の決め打ち
            }
            
            //管理者にフロア移動を行う合図を知らせ、その後フロア移動する
            this.sendEnterPrivacyRoom(json.floorId);
            this.handleMoveFloor(json.floorId);

        }else{
            this.floorMenuRef.current?.notPermission();
        }
    }

    receivePrivacyRoomTimeOut = (json: PrivacyRoomTimeOut) => {
        if(json.kind === 10){
            //キャンセル
            this.PrivacyRoomPermissionDialogRef.current?.close();
        }else if(json.kind === 20){
            //タイムアウト
            this.PrivacyRoomPermissionDialogRef.current?.close();
        }
    }

    receiveEnterPrivacyRoom = (json:EnterPrivacyRoom) => {
        this.PrivacyRoomPermissionDialogRef.current?.closeWaitingForStudentDialog();
    }

    handleChangeConcentrationMode = (flag: boolean) => {
        this.isConcentration = flag;
    }

    handleChangeStudyAdditionalTime = (additonalTime: number) => {
        this.studyAdditionalTime = additonalTime;
    }

    handleChangeIsStudy = (flag: boolean) => {
        this.isStudy = flag;
    }

    /**
     * 自習中の時間計測を開始する
     * タイマーを設定せずに時間計測した場合も本関数を利用
     * 
     * @param time 自習時間(分単位)
     */
    handleMyStudyTimerStart = (time: number) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isStudy = true; // 自習が開始されたことを合図するフラグ、フラグを立てる箇所はここでよいか検討する必要がある
        temp.studyTime = time; // 自習時間の設定
        temp.isTimer = true; // 時間計測開始フラグ
        this.floorMenuRef.current?.changeStudyTimerState(true); // タイマー一時停止/再開の制御する 本来はisTimerで制御したいが、isTimerでは反映が遅いので
        this.myUserRef.current.setUser(temp);
        this.sendUserStudySettingChange(temp);
        this.isStudy = true;
    }

    /**
     * StudyTimer.tsxが提供するstartCountを外部で使わせるために実装
     * StudyTimer.tsxのcount(第一引数に),timeEnd(第二引数に),isTimerEnd(falseに),timerFlag(trueに)変更するだけ
     * ※state.userのタイマーに関する値は変更しない
     * ※なのでMyUser.tsxにあるタイマー描画には基本影響を与えない
     * ※フロントで管理されるタイマーに影響するので秒単位
     * 
     * @param startTime タイマー開始時間(秒単位)
     * @param endTime タイマー終了時間(秒単位)
     */
    handleStudyTimerStart = (startTime: number, endTime: number) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.studyTime = Math.floor(endTime / 60); // 自習時間の設定
        this.myUserRef.current.handleTimerStart(startTime, endTime);
        this.myUserRef.current.setUser(temp);
        this.sendUserStudySettingChange(temp);
    }

    /**
     * タイマーの一時停止、再開を制御する
     * 再開時は第二引数の分、終了時間を延長しタイマーを再開させる
     * ※一時停止時は第二引数は見ない
     * ※延長なしで再開させる場合は第二引数は0
     * false: 一時停止
     * true: 再開
     * 
     * @param state タイマーの状態
     * @param additionalTime 延長時間(分単位)
     */
    handleMyStudyTimerState = (state: boolean, additionalTime: number) => {
        if(!state){ // 一時停止の場合、additionalTimeは0に初期化
            this.handleChangeStudyAdditionalTime(0);
        }else{ // 再開時の場合、第二引数をadditionalTimeにセット
            this.handleChangeStudyAdditionalTime(additionalTime);
        }
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isTimer = state;
        temp.studyTime += additionalTime; // 自習時間の設定
        this.floorMenuRef.current?.changeStudyTimerState(state); // タイマー一時停止/再開の制御する 本来はisTimerで制御したいが、isTimerでは反映が遅いので
        this.myUserRef.current.setUser(temp);
        this.sendUserStudySettingChange(temp);
    }

    /**
     * タイマーを終了（リセットする）
     * 
     * @param state タイマーの状態
     */
    handleMyStudyTimerReset = () => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.isStudy = false; // 自習が開始されたことを合図するフラグ、フラグを立てる箇所はここでよいか検討する必要がある
        temp.studyTime = 0; // 自習時間の設定
        temp.isTimer = false; // 時間計測開始フラグ
        this.floorMenuRef.current?.changeStudyTimerState(false); // タイマー一時停止/再開の制御する 本来はisTimerで制御したいが、isTimerでは反映が遅いので
        this.myUserRef.current.setUser(temp);
        this.sendUserStudySettingChange(temp);
        this.isStudy = false;
    }

    /**
     * 進行中の自習タイマーを更新する
     * 
     * @param elapsedTime 自習経過時間(時間の単位は指定しない、MyUserのuseEffectのレンダーのタイミングを調整し、時間単位を決定して下さい)
     */
    handleMyStudyElapsedTime = (elapsedTime: number) => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        temp.studyElapsedTime = elapsedTime;
        this.myUserRef.current.setUser(temp);
        this.sendUserStudyElapsedTimeChange(temp);
    }

    handleSetStudyCount = (count: number) => {
        this.studyCount = count;
        // console.info(count); // 自習経過時間（秒）
    }

    handleGetStudyCount = () => {
        return this.studyCount;
    }

    handleGetStudyTime = () => {
        let myUser = this.myUserRef.current.getUser();
        let temp = User.copyUser(myUser);
        return temp.studyTime;
    }

    handleSetStudyEnd = (state: boolean) => {
        this.studyEnd = state;
    }

    handleGetStudyEnd = () => {
        return this.studyEnd;
    }

    drawStudyPopup = () =>
        this.isStudy &&
        <Fragment>
            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }} 
                style={{ top: 10, left: 65, zIndex: 1200 }} 
                open={this.isStudy}
            >
                <SnackbarContent
                    message={<div style={{ padding: "0 10px 0 20px" }}>
                        <Grid container style={{ width: 240 }}>
                            <Grid item xs={12} style={{ marginLeft: '50px', textAlign: "left" }}><p>ただいま自習中です。</p></Grid>
                        </Grid>
                    </div>}
                    style={{
                        backgroundColor: '#f8b62b',
                        fontSize: 15,
                        padding: 0,
                        fontWeight: 'bold'
                    }}
                />
            </Snackbar>
        </Fragment>

    /**
     * 自習終了デスクトップ通知で利用
     * 通常のデスクトップと異なり、フロア名がタイトルとして表示されない
     * @param text 通知メッセージ
     */
    desktopNotificationWithoutFloorName = (text: string) => {
        if (WebrtcService.isiOS() || WebrtcService.isAndroid()){
            this.props.enqueueSnackbar(text);
            return;
        } 

        if(this.localNotification !== null) this.localNotification.close();

        this.localNotification = new Notification( "",{ body: text, silent: false, requireInteraction: true});
        this.localNotification.onclick = function() {
            window.focus();
        };

    }

    // 自習席に着席
    receiveHitSelfStudyTable = (json: CheckMySelfStudyPlan) => {
        this.isSelfStudyTable = true;
        this.setState({
            hitStudyObjectSetting: json.objectSetting
        })
        if(json.notCompleted){
            this.handleMyStudyTimerState(true, 0);
            // v2.4では離籍中はタイマーを停止するため、離籍中に自習が終了するケースは想定する必要がない
            // if(!this.isFinishSelfStudy()){ // 離籍中に自習が終了したか
            //     //離籍中に自習が終了していないので、自習状況確認ダイアログを開く
                // this.handleSelfStudyCheckDialog(true);
                // this.handleMyStateChange(10,false);
            // }else{
            //     // 離籍中に自習が終了した
            //     this.handleSelfStudyLookBackDialog(true);
            // }

            if(this.isSigninMySeatWithNotCompletedStudy){ // サインイン時、フロア移動時、リロード時の自習自席着席
                let myUser = this.myUserRef.current.getUser();
                if (myUser.studyTime !== -1) {
                    this.handleStudyTimerStart(myUser.studyElapsedTime*60,myUser.studyTime*60);
                } else {
                    this.handleStudyTimerStart(myUser.studyElapsedTime*60,myUser.studyTime);
                }
                this.isSigninMySeatWithNotCompletedStudy = false; // サインイン時、フロア移動時、リロード時の最初の一回のみtrue
            }
        } else if (this.isOpenStudyDialog) {
            //自習開始ダイアログを開く
            this.handleSelfStudyStartDialog(true);
        }
    }

    // 自習席から離籍
    receiveLeaveSelfStudyTable = () => {
        this.isSelfStudyTable = false;
        this.setState({
            hitStudyObjectSetting: {} as StudyObjectSetting,
        })
        this.handleMyStudyTimerState(false, 0);
        // v2.4の自習機能では自習席への離席・着席でステータス変更しない
        // let myUser = this.myUserRef.current.getUser();
        // let temp = User.copyUser(myUser);
        // if(temp.state === 10){ // 自習席から直接フロア移動した際に「自習中」のステータスを消す
        //     this.handleMyStateChange(0,true); // 自習中のステータスを外す
        // }
        this.handleSelfStudyTableVideo(false); // 外部ビデオの状態をオフにする

    }

    // エントランスに移動したことを通知
    receiveMoveEntrance = () => {
        // 自習席情報をクリア
        this.isSelfStudyTable = false;
        this.setState({
            hitStudyObjectSetting: {} as StudyObjectSetting,
        })
        this.centeringViewByMyUser();
    }
    
    receiveUpdateAdminMessage = (json: AdminMessage) => {
        if (this.state === null) return;
        if (this.state.floorObjectList === undefined) return;

        json?.floorObjectIdList?.forEach((jsonObjId: number) => {
            const updatedList = this.state.floorObjectList.map(obj =>
                (obj.id === jsonObjId) ? { ...obj, text1: json.message } : obj
            );
            this.setState({
                floorObjectList: updatedList
            })
        });
    }

    /*
    * ポップアップ看板のiframeを表示
    */
    openSignboardPage = (url: string, boardName: string, enableDownload: boolean, floorId: number, objectId: number, noticeNo: number) => {
        this.draggableWebpageRef?.current.open(url, boardName, enableDownload, floorId, objectId, noticeNo);
    }

    /*
    * ポップアップ看板の更新通知
    */
    updatePopupSignboard = (boardName: string) => {
        this.draggableWebpageRef?.current.editPopupSignboard(boardName);
    }

    recieveLoginoutNotice = (data: LoginoutNoticeData) => {
        let myUser = this.myUserRef.current.getUser() as User;
        if(myUser.avatarLoginoutNotification === 1){

            let str = data.user + "さんが";
            if(data.type == 0){
                str +="ログアウトしました。"
            }else{
                str +="ログインしました。"
            }
            this.props.enqueueSnackbar(str);
            if(myUser.avatarLoginoutNotificationSound === 1){
                this.playSoundFromAudioId(AudioId.Loginout);
            }
        }
    }

    /*
    * ポップアップ看板のサイトURLを更新
    */
    setWebpageUrl = (url: string) => {
        this.draggableWebpageRef?.current.setURL(url);
    }

    handleOpenUsersControl = () => {
        this.usersControlRef.current?.open({ x: 10, y: 50 });
    }

    setDataToUsersControl = (data: User[]) => {
        const ref = this.usersControlRef.current;
        if (ref && ref.getShow()) ref.setData(data);
    }

    receiveUpdateZoomSpeakable = (data: UpdateZoomSpeakable) => {
        this.props.enqueueSnackbar(`あなたのZoomのマイクとカメラが${data.isZoomSpeakable ? 'ON' : 'OFF'}になりました。)`);
    }

    /**
     * 画面共有、HelloインラインフレームのZIndexを設定し、どちらが前面に表示されるかを設定する
     * 
     * @param type 1 が 画面共有のクリック、2 が Helloインラインフレームのクリック
     */
     handleClickFrame = (type: number) => {
        if (type === 1) {
            this.setState({
                zIndexScreenShare: ZIndex.userDocument + 1,
                zIndexHelloInlineFrame: ZIndex.userDocument,
            });
        } else if (type === 2) {
            this.setState({
                zIndexScreenShare: ZIndex.userDocument,
                zIndexHelloInlineFrame: ZIndex.userDocument + 1,
            });
        }
    }

     /**
     * 自アバター名札カラー設定イベント
     */
    handleChangeMyNamePlate = (value: number) => {
        // this.sendWebSocket('CHANGE_NAMEPLATE', {'nameplate_id' : value});
        try {
            this.wsClient.sendChangeMyNamePlate(value);
        } catch (error) {
            console.error(error);
        }
        
    }

    receiveMyRoomSeatData = (seatData: Seat[]) => {
        this.myRoom2Ref.current.setFloorSeatList(seatData);
    }

    receiveMyRoomOtherUsers = (otherUsers: User[]) => {
        let tempUsers = [...otherUsers];
        console.log(tempUsers);
        this.myRoom2Ref.current.setUsers(tempUsers);

        // これが最後に来る（今のところ)ので、受け取ったら更新フラグをfalseに
        this.setState({myRoomUpdate: false});
    }

    handleAddMyRoomUser = (id:number, subId:string) => {
        console.log("handleAddMyRoomUser");

        // let data: SendAction = new SendAction();
        // data.action = "ADD_MY_ROOM_USER";
        // let changeMyRoomUser: ChangeMyRoomUser = new ChangeMyRoomUser();
        // changeMyRoomUser.id = id;
        // changeMyRoomUser.subId = subId;
        // data.object = changeMyRoomUser;
        // let json = JSON.stringify(data);
        // this.websocket.send(json);

        try {
            // campusのpaas化時にコメントアウト groupIdを指定しなくてもよいようにSDKを変更する必要あり
            // this.wsClient.sendAddedUserMyRoom(id, subId, groupId);
        } catch (error) {
            console.error(error);            
        }
        
    }

    handleDeleteMyRoomUser = (id:number, subId:string, seatNo:number) => {
        console.log("handleDeleteMyRoomUser");

        // let data: SendAction = new SendAction();
        // data.action = "DELETE_MY_ROOM_USER";
        // let changeMyRoomUser: ChangeMyRoomUser = new ChangeMyRoomUser();
        // changeMyRoomUser.id = id;
        // changeMyRoomUser.subId = subId;
        // changeMyRoomUser.seatNo = seatNo;
        // data.object = changeMyRoomUser;
        // let json = JSON.stringify(data);
        // this.websocket.send(json);

        try {
            // handleAddMyRoomUserと同様
            // this.wsClient.sendDeletedUserMyRoom(id, subId, seatNo, groupId);		// v1.8_needCheck
        } catch (error) {
            console.error(error);            
        }
    }

    handleClickMyRoomUserInfo = (user:User) => {
        console.log("handleClickMyRoomUserInfo");

        let tempUser = User.copyUser(user) as User;
        const floorWarpper = document.getElementById("floorWrapper") as HTMLDivElement;
        const myRoomRnd = document.getElementById("myRoomRnd") as HTMLDivElement;

        // マイルームの場合は左に出す
        tempUser.x = floorWarpper.offsetWidth - (this.scrollbarWidth + 5) - this.myRoomSize.width - 345; // UserInfoCardの最大幅が345
        if(tempUser.x < 0){
            tempUser.x = 0;
        }
        tempUser.y = tempUser.y - myRoomRnd.scrollTop;
        if(tempUser.y < 0){
            tempUser.y = 0;
        }

        this.myRoomUserInfoCardRef?.current.setUser(tempUser);
        this.myRoomUserInfoCardRef?.current.setOpen(true);
    }

    handleClickGoToSee = (subId:string) => {
        this.userSearchRef.current?.goToSee(subId);
    }

    receiveMyRoomMessage = (message: string) => {
        this.myRoomMessageRef?.current.setMessage(message);
        this.myRoomMessageRef?.current.setOpen(true);
    }

    receiveUpdateMyRoom = (data: UpdateMyRoom) => {
        // dataで更新があったユーザーIDをもらっているが
        // 今はマイルーム内のユーザー個別のデータ書き換えはしていない
        this.updateMyRoom();
    }

    updateMyRoom = () => {
        if(this.myRoom2Ref?.current.getOpen() === true){
            // マイルームオープン時は即取りに行く
            this.sendGetMyRoomFloorData();
        }
        else{
            // オープンしてない時はフラグを立てる
            this.setState({myRoomUpdate: true});
        }
    }

    setToTop = (name: string) => {

        var maxZindex = Math.max(this.userSearchRef.current.getZindex(), this.memoComponentRef.current.getZindex());
        switch(name){
            case "UserSearch":
                this.userSearchRef.current.setZindex(maxZindex + 1);
                break;
            case "Memo":
                this.memoComponentRef.current.setZindex(maxZindex + 1);
                break;
            case "GroupChat":
                this.groupChatComponentRef.current.setZindex(maxZindex + 1);
                break;
            case "MemoList":
                this.memoGroupListRef.current.setZindex(maxZindex + 1);
                break;
            default:
                break;
        }
    }

    handleClickMyRoomMemo = (user:User) => {

        let tempUser = User.copyUser(user) as User;
        const floorWarpper = document.getElementById("floorWrapper") as HTMLDivElement;
        const myRoomRnd = document.getElementById("myRoomRnd") as HTMLDivElement;

        // マイルームの場合は左に出す
        tempUser.x = floorWarpper.offsetWidth - (this.scrollbarWidth + 5) - this.myRoomSize.width - 345; // MemoGroupCardの最大幅が345
        if(tempUser.x < 0){
            tempUser.x = 0;
        }
        tempUser.y = tempUser.y - myRoomRnd.scrollTop;
        if(tempUser.y < 0){
            tempUser.y = 0;
        }

        this.myRoomMemoGroupCardRef?.current.setUser(tempUser);
        this.myRoomMemoGroupCardRef?.current.setOpen(true);

    }

    handleClickMyRoomChat = (user:User) => {

        let tempUser = User.copyUser(user) as User;
        const floorWarpper = document.getElementById("floorWrapper") as HTMLDivElement;
        const myRoomRnd = document.getElementById("myRoomRnd") as HTMLDivElement;

        // マイルームの場合は左に出す
        tempUser.x = floorWarpper.offsetWidth - (this.scrollbarWidth + 5) - this.myRoomSize.width - 345; // MemoGroupCardの最大幅が345
        if(tempUser.x < 0){
            tempUser.x = 0;
        }
        tempUser.y = tempUser.y - myRoomRnd.scrollTop;
        if(tempUser.y < 0){
            tempUser.y = 0;
        }

        this.myRoomChatGroupCardRef?.current.setUser(tempUser);
        this.myRoomChatGroupCardRef?.current.setOpen(true);

    }

    isPrivacyGimmickOn = (floorObjectId: number) => {
        let filteredRef = this.privacyRoomListRef.filter(ref => {
            return ref.current?.isIt(floorObjectId);
        });
        if (filteredRef.length == 0) {
            return false;
        }
        return filteredRef[0].current?.isPrivacyGimmickOn();
    }
    
    render() {
        //console.log("Floor::render")

        // let myUser: User;
        // if (this.myUserRef.current !== null) myUser = this.myUserRef.current.getUser();
        return (
            <React.Fragment>
                {this.drawTestNav()}
                <SideMenu
                    openDeviceSelect={this.handleOpenDeviceSelect}
                    openMicVolumeInterval={this.handleOpenMicVolumeInterval}
                    centeringView={this.centeringViewByMyUser}
                    openTutorial={this.hundleOpenTutorial}
                    purposeOfUse={this.getPurposeOfUse()}
                    handleClickSetting={this.setShowSetMyAvatar}
                    role={this.getMyRole()}
                    openInformation={this.handleOpenInformation}
                    enabledSettingSideMenu={this.getEnabledSettingSideMenu()}
                    enabledTutorialSideMenu={this.getEnabledTutorialSideMenu()}
                    enabledManualSideMenu={this.getEnabledManualSideMenu()}
                    enabledInformationSideMenu={this.getEnabledInformationSideMenu()}
                    enabledReleaseNoteSideMenu={this.getEnabledReleaseNoteSideMenu()}   // リリースノート対応
                    openReleaseNote={this.handleOpenReleaseNote}    // リリースノート対応
                    releaseNoteURL={this.myServiceInfoData?.releaseNoteURL}  // リリースノート対応
                    enabledFAQSideMenu={this.getEnabledFAQSideMenu()}   // ＦＡＱ対応
                    pageFAQURL={this.myServiceInfoData?.pageFAQURL}  // ＦＡＱ対応
                    usermanualURL={this.myServiceInfoData?.usermanualURL}   // マニュアルURLをDBから取得
                    enabledReconnectSideMenu={this.getEnabledReconnectSideMenu()}
                    enabledMicVolumeIntervalSideMenu={this.getEnabledMicVolumeIntervalSideMenu()}
                    openInternalNotice={this.openInternalNotice}
                    // enabledFloorEditorSideMenu={this.getEnabledFloorEditor()}
                    floorEditorRef={this.floorEditorRef}
                    openGetAttendance={this.handleOpenGetAttendance}
                />
                <TutorialDialogRef tutorialId={1} ref={this.tutorialDialogRef} />
                <FloorClosedDialogRef ref={this.floorClosedDialogRef} />
                <InformationDialogRef ref={this.InformationDialogRef} />
                <ForceExitAlertDialogRef ref={this.ForceExitAlertDialogRef} />
                {/* <ForceExitAlertDialogRef websocket={this.websocket} ref={this.ForceExitAlertDialogRef} /> */}
                <ReleaseNoteDialogRef ref={this.ReleaseNoteDialogRef} />    {/* リリースノート対応 */}
                <InternalNoticeDialogRef ref={this.InternalNoticeDialogRef} websocket={this.sendInternalNotice} user={this.myUserRef.current?.getUser().displayName} />
                <PrivacyRoomPermissionDialogRef ref={this.PrivacyRoomPermissionDialogRef} startingConfirmTimeoutValue={this.state?.floorData.wrStartConfirmWaitTime} sendPrivacyRoomPermission={this.sendPrivacyRoomPermission} />
                <SelfStudyStartDialogRef ref={this.SelfStudyStartDialogRef} handleMyStudyTimerStart={this.handleMyStudyTimerStart} studyObjectSetting={this.state?.hitStudyObjectSetting} changeConcentrationMode={this.handleChangeConcentrationMode} changeIsStudy={this.handleChangeIsStudy} getMyUser={this.myUserRef.current?.getUser} onJumpToMySeat={this.handleJumpToMySeat} />
                <SelfStudyChangeDialogRef ref={this.SelfStudyChangeDialogRef} handleMyStudyTimerStart={this.handleMyStudyTimerStart} handleStudyTimerStart={this.handleStudyTimerStart} handleGetStudyCount={this.handleGetStudyCount} handleGetStudyTime={this.handleGetStudyTime} handleMyStudyTimerState={this.handleMyStudyTimerState} studyObjectSetting={this.state?.hitStudyObjectSetting} changeConcentrationMode={this.handleChangeConcentrationMode} handleGetStudyEnd={this.handleGetStudyEnd} openSelfStudyFinishDialog={this.handleSelfStudyFinishDialog}/>
                <SelfStudyFinishDialogRef ref={this.SelfStudyFinishDialogRef} handleMyStudyTimerState={this.handleMyStudyTimerState} handleGetStudyCount={this.handleGetStudyCount} handleMyStudyTimerReset={this.handleMyStudyTimerReset} handleChangeConcentrationMode={this.handleChangeConcentrationMode} handleGetStudyEnd={this.handleGetStudyEnd} openSelfStudyChangeDialog={this.handleSelfStudyChangeDialog} openSelfStudyCompleteDialog={this.handleSelfStudyCompleteDialog}/>
                <SelfStudyCompleteDialogRef ref={this.SelfStudyCompleteDialogRef} openSelfStudyStartDialog={this.handleSelfStudyStartDialog} getMyUser={this.myUserRef.current?.getUser} onJumpToMySeat={this.handleJumpToMySeat} />
                {/*<SelfStudyTimerRef ref={this.SelfStudyTimerRef} openSelfStudyLookBackDialog={this.handleSelfStudyLookBackDialog} isSelfStudyCheckDialog={this.isSelfStudyCheckDialog} changeLimitOver={this.changeLimitOver} checkIsSelfStudyTable={this.checkIsSelfStudyTable} />*/}
                <GetAttendanceDialogRef attendanceId={1} ref={this.GetAttendanceDialogRef} />
                <div id="floorContainer" style={{ position: "absolute", height: "100%", width: "100%" }}>
                    <div id="floorWrapper" ref={this.floorWrapperRef} style={{ position: "relative", overflow: "auto", height: "100%", zIndex: ZIndex.base }}>
                        <div id="floorDiv" ref={this.floorRef}
                            // onPointerDown={this.onMouseDown} onPointerMove={this.onMouseMove} onPointerUp={this.onMouseUp}
                            // onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove} onTouchEnd={this.onTouchEnd}
                            // onMouseDown={this.onMouseDown} onMouseMove={this.onMouseMove} onMouseUp={this.onMouseUp} 
                            onContextMenu={this.onContextMenu}
                            style={{
                                position: "absolute", width: this.state?.floorData?.floorWidth, height: this.state?.floorData?.floorHeight,
                                // backgroundImage: "url(./api/user/object/picture/" + this.state?.floorData?.backgroundObject?.id + ")",
                                backgroundImage: `url(${this.httpClient.createObjectImgUrl(this.state?.floorData?.backgroundObject?.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                                backgroundSize: 500, zIndex: ZIndex.base,
                                transform: "scale(" + this.state?.fitScale + ")", transformOrigin: "0% 0%",
                            }}
                            //onClick={this.selectedFloorObject}
                            onDoubleClick={this.selectedFloorObject}>
                            {/* <TestGridLine floor={this.state?.floorData} floorObjectList={this.state?.floorObjectList} webSocket={this.sendWebSocket} /> */}
                            <SelectedFloorObject
                                object={this.state?.selectedFloorObj}
                                mode={this.state?.floorEditMode}
                                sDragg={this.startDragg}
                                eDragg={this.endDragg}
                                status={this.state?.isFloorObjDragging}
                                getCurrentObj={this.getCurrentObjData}
                                gridMode={this.state?.gridMode}
                                gridRange={this.state?.gridRange}
                                isFloorObjSelected={this.state?.isFloorObjSelected}
                                checkIsSit={this.checkIsSit}
                                isSittingError={this.handleObjectMoveError}
                                isDragging={this.isDraggingFloorObject}
                                floor={this.state?.floorData} 
                                floorObjectList={this.state?.floorObjectList} 
                                webSocket={this.sendWebSocket}
                                myMoveAutoScroll={this.myMoveAutoScroll}
                            />
                            {this.drawObject()}
                            {this.drawSeatObject()}
                            <OtherUsers 
                                ref={this.otherUsersRef}
                                scale={this.state?.fitScale}
                                myUserSubId={this.myUserRef.current?.getUser().subId}
                                handleOpenMemo={this.handleOpenMemo}
                                enabledMemoOtherUserMenu={this.getEnabledMemoOtherUserMenu()}
                                myUser={this.myUserRef.current?.getUser()}
                                myFloorName={this.state?.floorData.floorName}
                                getEnabledBusinessCard={this.getEnabledBusinessCard}
                                kind='otheruser'
                                handleAddMyRoomUser={this.handleAddMyRoomUser}
                                handleDeleteMyRoomUser={this.handleDeleteMyRoomUser}
                                handleGoToSee={this.handleClickGoToSee}
                                handleClickMyRoomUserInfo={this.handleClickMyRoomUserInfo} // 本体側には渡したくない...
                                enabledMyRoom={this.getEnabledMyRoom()}
                                enabledChat={this.getEnabledMemoButton()}
                                handleOpenChat={this.handleOpenChat}
                                handleClickMyRoomMemo={this.handleClickMyRoomMemo}
                                handleClickMyRoomChat={this.handleClickMyRoomChat}
                                isPrivacyGimmickOn={this.isPrivacyGimmickOn}
                                floorSize={{width:this.state?.floorData?.floorWidth, height:this.state?.floorData?.floorHeight}}
                            />
                            <MyUser
                                ref={this.myUserRef}
                                floorData={this.state?.floorData}
                                scale={this.state?.fitScale}
                                avatarDatas={this.avatarDatas}
                                onStateChange={this.handleMyStateChange}
                                onTubuyakiChange={this.hanldeTubuyakiChange}
                                onMoveFloor={this.handleMoveFloor}
                                onMySeatChange={this.handleMySeatChange}
                                onMySeatRelease={this.handleMySeatRelease}
                                onOtherSeatRelease={this.handleOtherSeatRelease}
                                onMyAvatarChange={this.handleMyAvatarChange}
                                onJumpToMySeat={this.handleJumpToMySeat}
                                sendUserMicLevel={this.sendUserMicLevel}
                                sendWebRtcInfo={this.sendWebRtcInfo}
                                setVisibleScreenShareButton={this.setVisibleScreenShareButton}
                                handleScreenShare={this.handleScreenShare}
                                hanldleBroadcast={this.hanldleBroadcast}
                                handleSendCommand={this.handleSendCommand}
                                onMyStreamLoaded={this.handleMyStreamLoaded}
                                onRemoteStreamLoading={this.handleRemoteStreamLoading}
                                onRemoteStreamLoaded={this.handleRemoteStreamLoaded}
                                onUpdateJoinRoomStatus={this.handleUpdateJoinRoomStatus}     // joinRoom の状態を通知
                                onStartMoteNote={this.handleStartMoteNote}
                                onStartMoteNoteInlineFrame={this.handleStartMoteNoteInline}
                                onForceMute={this.handleForceMute}
                                onClapping={this.handleClapping}
                                onOpenUserSearch={this.handleOpenUserSearch}
                                onCardDisclose={this.handleCardDisclose}
                                onReceiveForceMute={this.handleReceiveForceMute}
                                onReceiveVideoURLText={this.handleReceiveVideoURLText}
                                handleContinueWebRtcResult={this.handleContinueWebRtcResult}
                                handleStartConfirmResult={this.handleStartConfirmResult}
                                onMyConfirmSettingChange={this.handleMyConfirmSettingChange}
                                onMyLoginoutNotificationChange={this.handleMyLoginoutNotificationChange}
                                handleShareScreenOpen={this.handleShareScreenOpen}
                                onScreenShareVideoLoaded={this.onScreenShareVideoLoaded}
                                sendSetGhost={this.sendSetGhost}
                                sendDeviceSelectedType={this.sendDeviceSelectedType}
                                handleCloseMessageDialog={this.handleCloseMessageDialog}
                                isCommuting={this.state?.myCommuting}
                                onChangeMyCommuting={this.handleChangeMyCommuting}
                                youtubeProps={this.youtubeProps}
                                resetIsChangeVideo={this.handleResetIsChangeVideo}
                                handleCheckOpenedHello={this.wsClient.checkOpenedHello}
                                handleRegistCallbackOpenedHello={this.wsClient.registCallbackOpenedHello}
                                displayErrorSnackBar={this.handleDisplayErrorSnackBar}   // #369
                                onDeviceSelected={this.handleDeviceSelected}
                                onPictograph={this.handlePictograph}
                                onOpenUsersControl={this.handleOpenUsersControl}
                                getFloorObjectFromId={this.getFloorObjectFromId}
                                getEnabledBusinessCard={this.getEnabledBusinessCard}
                                namePlateColors={this.namePlateColors}
                                onChangeNamePlate={this.handleChangeMyNamePlate}
                                kind='myuser'
                                usermanualURL={usermanualURL}   // マニュアルURLをDBから取得
                                requestPrivacyRoom={this.sendNewVisitor}
                                sendPrivacyRoomTimeOut={this.sendPrivacyRoomTimeOut}
                                sendMovePrivacyRoomInfo={this.sendMovePrivacyRoomInfo}
                                studyAdditionalTime={this.studyAdditionalTime}
                                setStudyCount={this.handleSetStudyCount}
                                setStudyEnd={this.handleSetStudyEnd}
                                openSelfStudyFinishDialog={this.handleSelfStudyFinishDialog}
                                handleMyStudyElapsedTime={this.handleMyStudyElapsedTime}
                                desktopNotificationWithoutFloorName={this.desktopNotificationWithoutFloorName}
                            />
                            <JumpToMoveDialogRef
                                ref={this.jumpToDialogRef}
                                onOk={this.handleJumpToMoveDialog}
                                onCancel={() => this.toggleJumpToDialog(false)}
                                zIndex={ZIndex.fixedUiLayer}
                            />

                        </div>
                    </div>
                </div>
                {this.drawFloorMenu()}
                {this.redirectComponents()}
                {this.drawMicVolumeIntervalDialog()}
                {this.drawMoveFloorDialog()}
                <DisconnectDialog ref={this.disconnectDialogRef} />
                <CheckLoginDialog ref={this.checkLoginDialogRef} sendCheckedLogin={this.sendCheckedLogin} />
                <ShareScreenRef
                    ref={this.shareScreenRef}
                    parentElementId="floorContainer"
                    zIndex={this.state ? this.state.zIndexScreenShare : ZIndex.userDocument}
                    iPhoneLayout={this.isiPhoneLayout}
                    getMyUser={this.myUserRef.current?.getUser}
                    handleScreenShare={this.handleScreenShare}
                    handleClickWindow={this.handleClickFrame}
                />
                {this.state && this.state.openInlineFrame 
                    // 課題管理 #1695 一時的にiphone AndroidでmoreNoteHelloをふさぐ
                    // iPhone/Androidの時は、
                    // 通話相手がmoreNoteHelloを起動しても、Helloのウィンドウを開かない
                    // && !WebrtcService.isAndroid() && !WebrtcService.isiOS()
                    ? 
                    <HelloFrame openUrl={this.state.inlineFrameUrl} 
                        parentElementId="floorContainer"
                        zIndex={this.state ? this.state.zIndexHelloInlineFrame : ZIndex.userDocument}
                        iPhoneLayout={this.isiPhoneLayout}
                        handleClickReloadIcon={this.handleClickMoreNote} 
                        close={this.handleCloseMoreNoteInlineAll} 
                        handleClickFrame={this.handleClickFrame}
                    />
                     : ""}
                <ChatComponent userId={0} chatList={this.dummyChat} viewName={""} ref={this.chatComponentRef} />
                <MemoComponent
                    ref={this.memoComponentRef}
                    subId={this.myUserRef.current?.getUser().subId}
                    webSocketUrl={this.state?.floorData.webSocketUrl}
                    scrollbarWidth={this.scrollbarWidth}
                    defaultSize={this.memoComponentSize}
                    moveMemoGroupList={this.moveMemoGroupList}
                    sendNewMemo={this.sendNewMemo}
                    sendReadMemo={this.sendReadMemo}
                    getMyUser={this.myUserRef.current?.getUser}
                    setToTop={this.setToTop}
                />
                <MemoGroupList
                    ref={this.memoGroupListRef}
                    handleOpenMemo={this.handleOpenMemo}
                    setUnreadMemoCount={this.setUnreadMemoCount}
                    scrollbarWidth={this.scrollbarWidth}
                    defaultSize={this.memoGroupListSize}
                    setToTop={this.setToTop}
                />
                <GroupChatComponent
                    ref={this.groupChatComponentRef}
                    subId={this.myUserRef.current?.getUser().subId}
                    webSocketUrl={this.state?.floorData.webSocketUrl}
                    scrollbarWidth={this.scrollbarWidth}
                    defaultSize={this.groupChatComponentSize}
                    moveMemoGroupList={this.moveMemoGroupList}
                    sendNewMemo={this.sendNewMemo}
                    sendDeleteMemo={this.sendDeleteMemo}
                    sendReadMemo={this.sendReadMemo}
                    sendChatGroupName={this.sendChatGroupName}
                    sendChatGroupAdd={this.sendChatGroupAdd}
                    sendChatGroupQuit={this.sendChatGroupQuit}
                    getMyUser={this.myUserRef.current?.getUser}
                    setToTop={this.setToTop}
                    handleGroupChatMenu={this.handleGroupChatMenu}
                />
                <UserSearch
                    ref={this.userSearchRef}
                    getTargetOfGoToSee={this.getTargetOfGoToSee}
                    handleGoToSee={this.handleGoToSee}
                    handleOpenMemo={this.handleOpenMemo}
                    scrollbarWidth={this.scrollbarWidth}
                    defaultSize={this.userSearchSize}
                    myUserSubId={this.myUserRef.current?.getUser().subId}
                    enabledMyRoom={this.getEnabledMyRoom()}
                    handleAddMyRoomUser={this.handleAddMyRoomUser}
                    handleOpenChat={this.handleOpenChat}
                    setToTop={this.setToTop}
                    handleCloseUserSearch={this.handleCloseUserSearch}
                    privacyRoom={this.getPrivacyRoom()}
                    otherUsers={this.otherUsersRef.current?.getUsers()}
                />
                <FloorEditor
                    ref={this.floorEditorRef}
                    floorEditMode={this.state?.floorEditMode}
                    sendWebSocket={this.sendWebSocket}
                    officeId={this.state?.floorData.office.id}
                    floorId={this.state?.floorData.id}
                />
                <NewFloorObjectWindow
                    ref={this.newFloorObjectWindowRef}
                    floorEditMode={this.state?.floorEditMode}
                    floorId={this.state?.floorData.id}
                    floorData={this.state?.floorData}
                    sendWebSocket={this.sendWebSocket}
                    deleteObj={this.deleteObjectOk}
                    floorResize={this.floorReSize}
                    isFloorObjSelected={this.state?.isFloorObjSelected}
                    object={this.state?.selectedFloorObj} //selectedFloorObj
                />
                {/* const { offsetWidth, offsetHeight } = this.floorWrapperRef.current;
                <OkOnlyDialog ref={this.moveFloorFailedDialogRef} closeCallback={this.closeMoveFloorFailedDialog} title="フロア移動" message="サーバーが混雑している為、フロア移動に時間が掛かっています。" closeButtonName="閉じる" /> 
                */}
                <OkOnlyDialog ref={this.moveFloorFailedDialogRef} closeCallback={this.closeMoveFloorFailedDialog} title="フロア移動" message="サーバーが混雑している為、フロア移動に時間が掛かっています。" closeButtonName="閉じる" waitTimer={1500} />
                <DraggableWebpageRef ref={this.draggableWebpageRef}/>
                <DraggableWebpageRef ref={this.draggableWebpageRef}/>
                <UsersControl 
                    ref={this.usersControlRef}
                    scrollbarWidth={this.scrollbarWidth} 
                    defaultSize={this.usersControlSize}
                    getData={() => this.otherUsersRef.current?.getUsers()}
                    sendZoomSpeakable={this.sendZoomSpeakable}
                />
                <div id="myRoomWrapper">
                    <MyRoom2
                        ref={this.myRoom2Ref}
                        parentElementId="floorContainer"
                        // bgImage={"url(./api/user/object/picture/" + this.state?.myRoomFloorData?.backgroundObject?.id + ")"}
                        bgImage={`url(${this.httpClient.createObjectImgUrl(this.state?.myRoomFloorData?.backgroundObject?.id)})`}

                        scale={this.state?.fitScale}
                        myUserSubId={this.myUserRef.current?.getUser().subId}
                        handleOpenMemo={this.handleOpenMemo}
                        enabledMemoOtherUserMenu={this.getEnabledMemoOtherUserMenu()}
                        myUser={this.myUserRef.current?.getUser()}
                        myFloorName={this.state?.floorData.floorName}
                        getEnabledBusinessCard={this.getEnabledBusinessCard}
                        handleAddMyRoomUser={this.handleAddMyRoomUser}
                        handleDeleteMyRoomUser={this.handleDeleteMyRoomUser}
                        handleGoToSee={this.handleClickGoToSee}

                        scrollbarWidth={this.scrollbarWidth}
                        handleMyRoomWindowsClose={this.handleMyRoomWindowsClose}
                        iPhoneLayout={this.isiPhoneLayout}
                        handleClickMyRoomUserInfo={this.handleClickMyRoomUserInfo}
                        enabledMyRoom={this.getEnabledMyRoom()}
                        enabledChat={this.getEnabledMemoButton()}
                        handleOpenChat={this.handleOpenChat}
                        handleClickMyRoomMemo={this.handleClickMyRoomMemo}
                        handleClickMyRoomChat={this.handleClickMyRoomChat}
                    />
                </div>
                <MyRoomMessage
                    ref={this.myRoomMessageRef}
                />
                <MyRoomUserInfoCard
                    ref={this.myRoomUserInfoCardRef}
                />
                <MyRoomMemoGroupCard
                    ref={this.myRoomMemoGroupCardRef}
                    handleOpenMemo={this.handleOpenMemo}
                />                
                <MyRoomChatGroupCard
                    ref={this.myRoomChatGroupCardRef}
                    handleOpenChat={this.handleOpenChat}
                />  
                <TenantLauncher changeEnabled={this.changeEnabledToolLauncher} show={this.state?.showToolLauncher} ref={this.tenantLauncherRef} />
                <GeneralMessage
                    ref={this.GeneralMessageRef}
                />              
                <OkCancelDialog ref={this.migratingMaintenanceDlgRef} title='フロア移動' message='メンテナンス処理中です。しばらくしてからもう一度やり直してください。' okCallback={this.dummy} closeCallback={this.dummy} okButtonName={"閉じる"} closeButtonName={"閉じる"} enableCloseButton={false} />
                <OkCancelDialog ref={this.okCancelDialogRef} title='フロア移動' message='移動先のフロアがメンテナンス中の為、フロア移動を中止しました。' okCallback={this.dummy} closeCallback={this.dummy} okButtonName={"閉じる"} closeButtonName={"閉じる"} enableCloseButton={false} />
                <OkCancelDialog ref={this.entryRestrictDlgRef} title='フロアに入室できません' message='移動先のフロアに入室する権限がありません。' okCallback={this.dummy} closeCallback={this.dummy} okButtonName={"閉じる"} closeButtonName={"閉じる"} enableCloseButton={false} />
                {this.drawStudyPopup()}
            </React.Fragment>
        )
        // ShareScreenのタップ・マウス操作がfloor操作に影響を与えないよう、floorConteinerと同階層に配置した
    }

    /**
     *  トレースログ:サーバーへ送信
     * 
     */
     private traceSend = (msg: string) => {
        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append('tracemsg', msg);
        // axios.post('/api/user/log/tracelog', params)
        // .then((e: AxiosResponse) => {
        // }).catch((e) => {
        //     this.traceLog("traceSend error:"+e)
        // });

        this.httpClient.sendTraceLog(sessionStorage.getItem("TABID") as string, msg)
            .then((e) => {
            }).catch((e) => {
                this.traceLog("traceSend error:"+e);
            });
    }
}

export default withStyles(styles)(withSnackbar(Floor));
import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';

import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/styles';

import { userListConst } from './UserListConst';
import JfsClient from '@fsi/jfs-sdk';

const Message = {
    FAILED_GET_DATA: 'データの取得に失敗しました。',
    FAILED_UPDATE_DATA: 'データの更新に失敗しました。画面を再読み込みし、再度更新を行ってください。',
    UNEXPECTED: userListConst.unexpectedMessage,
    REQUEST_ERROR: userListConst.requestError.message,
} as const;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '50%',
        minWidth: 300,
        maxWidth: 450,
    },
    cardContent: {
        padding: '0 40px',
    },
    errorMessage: {
        ...theme.typography.caption,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingCard: {
        width: 150,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface ResForceMuteStatus {
    enabledForceMute: boolean;
}

/**
 * API Request
 */
// const getEnabledForceMute = async (tabId: string): Promise<AxiosResponse<ResForceMuteStatus>> => {
//     try {
//         const res: AxiosResponse<ResForceMuteStatus> = await axios.get(
//             '/api/v1/admin/tenant/forcemute',
//             { params: { tabId } }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

// const updateEnabledForceMute = async (tabId: string, enabledForceMute: boolean): Promise<AxiosResponse<ResForceMuteStatus>> => {
//     try {
//         const res: AxiosResponse<ResForceMuteStatus> = await axios.patch(
//             '/api/v1/admin/tenant/forcemute',
//             { tabId, enabledForceMute }
//         );
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

/**
 * Utility
 */
export default function ForceMute() {
    const tabId = sessionStorage.getItem('TABID') || '';
    const classes = useStyles();
    const { history } = useReactRouter();

    const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
    const [isLoading, setLoading] = useState(false);
    const [enabledForceMute, setEnabledForceMute] = useState(false);
    const [enabledForceMuteErrMsg, setEnabledForceMuteErrMsg] = useState<ReactNode>(null);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    /**
     * error handle
     */
    const handleEnabledForceMuteError = (err: any, type: number) => {
        const msgList: string[] = [];

        if (err.response) {
            if (err.response.status >= 500) {
                if (type === 0) {
                    msgList.push(Message.UNEXPECTED);
                } else {
                    msgList.push(Message.FAILED_UPDATE_DATA);
                }
            } else if (err.response.status === 403) {
                history.push({ pathname: '/' });
                return;
            } else {
                if (type === 0) {
                    msgList.push(Message.FAILED_GET_DATA);
                } else {
                    msgList.push(Message.FAILED_UPDATE_DATA);
                }
            }
            setEnabledForceMuteErrMsg(drawError(msgList, []));
            return;
        } else if (err.request) {
            setEnabledForceMuteErrMsg(Message.REQUEST_ERROR);
            return;
        }
    }

    const getEnabledForceMute = async (tabId: string): Promise<ResForceMuteStatus> => {
        try {
            const res: ResForceMuteStatus = await httpClient.getEnabledForceMute(tabId);
            return res;
        } catch (err) {
            throw err;
        }
    }
    
    const updateEnabledForceMute = async (tabId: string, enabledForceMute: boolean): Promise<ResForceMuteStatus> => {
        try {
            const res: ResForceMuteStatus = await httpClient.updateEnabledForceMute(tabId, enabledForceMute);
            return res;
        } catch (err) {
            throw err;
        }
    }

    const drawError = useCallback((messageList: string[] = [], invalidIpList: string[] = []) => {
        return (
            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                {
                    messageList.length > 0 &&
                    <ul style={{ margin: 5, paddingLeft: 10 }}>
                        {messageList.map(msg => <li>{msg}</li>)}
                    </ul>
                }
                {
                    invalidIpList.length > 0 &&
                    <ul style={{ margin: 0, paddingLeft: 30 }}>
                        {invalidIpList.map(ip => <li>{ip}</li>)}
                    </ul>
                }
            </div>
        )
    }, []);

    /**
     * handle data
     */
    const fetchEnabledForceMute = async () => {
        const newCrudType = 0;
        try {
            setCrudType(newCrudType);
            setLoading(true);
            const res = await getEnabledForceMute(tabId);
            const flag = res?.enabledForceMute;
            setEnabledForceMute(flag);
            setEnabledForceMuteErrMsg(null);
        } catch (err) {
            console.error(err);
            handleEnabledForceMuteError(err, newCrudType);
        } finally {
            setLoading(false);
        }
    }

    /**
     * useEffect
     */
    useEffect(() => {
        fetchEnabledForceMute();
    }, []);

    /**
     * event handlers
     */
    const handleChangeForceMute = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCrudType = 1;
        try {
            setCrudType(newCrudType);
            setLoading(true);
            const res = await updateEnabledForceMute(tabId, !enabledForceMute);
            const flag = res.enabledForceMute;
            setEnabledForceMute(flag);
            setEnabledForceMuteErrMsg(null);
        } catch (err) {
            console.error(err);
            handleEnabledForceMuteError(err, newCrudType);
        } finally {
            setLoading(false);
        }
    }

    /**
     * parts draw
     */
    const drawEnabledForceMute = useMemo(() => {
        return (
            <Tooltip title='強制ミュートのON・OFF'>
                <Switch
                    checked={enabledForceMute}
                    disabled={enabledForceMuteErrMsg !== null}
                    onChange={handleChangeForceMute}
                    color='primary'
                    name='enabledWhiteList'
                    inputProps={{ 'aria-label': 'enabledWhiteList' }}
                />
            </Tooltip>
        )
    }, [enabledForceMute, enabledForceMuteErrMsg]);

    const drawLoading = useMemo(() => {
        const operation = crudType === 0 ? '取得' : '更新';

        return (
            <Backdrop className={classes.backdrop} open={isLoading} >
                <Slide direction="up" in={isLoading}>
                    <Card className={classes.loadingCard}>
                        <CardContent>
                            <ThemeProvider theme={circularTheme}>
                                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
                            </ThemeProvider>
                            <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
                        </CardContent>
                    </Card>
                </Slide>
            </Backdrop>
        );
    }, [crudType, isLoading]);

    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Card className={classes.root}>
                <CardHeader
                    action={drawEnabledForceMute}
                    title='強制ミュート設定'
                    titleTypographyProps={{
                        align: 'left',
                        variant: 'h6',
                    }}
                />
                {
                    enabledForceMuteErrMsg &&
                    <CardContent classes={{ root: classes.cardContent }}>
                        <Typography align='left' variant='caption' color='error'>
                            {enabledForceMuteErrMsg}
                        </Typography>
                    </CardContent>
                }
            </Card>
            {drawLoading}
        </Grid>
    )
}
import React, { Fragment, useRef, useState, useEffect, useMemo } from 'react'
import axios, { AxiosResponse } from 'axios';
import { withStyles, makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import {Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Paper, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import BaseDialog from '../user/BaseDialog';

import { userListConst } from './UserListConst';
import { PointAutoSettingDialog, PointAutoSettingDialogHandler } from './PointAutoSettingDialog';
import JfsClient, {PointSettingInfo } from "@fsi/jfs-sdk";

class FinishInfo {
  open: boolean = true;
  message: string = "";
  password: null | string = null;

  constructor(init: Partial<FinishInfo>) {
    Object.assign(this, init);
  }
}

export default function PointAutoSetting() {
  const tabId = sessionStorage.getItem('TABID') || '';
  const classes = useStyles();

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);

  const [isShowSwitchConfirmDialog, setIsShowSwitchConfirmDialog] = React.useState(false);
  const [pointSettingList, setPointSettingList] = React.useState<PointSettingData[]>([]);
  const [pointSettingListOrg, setPointSettingListOrg] = React.useState<PointSettingData[]>([]);

  const [currentRowNo, setCurrentRowNo] = React.useState(0);

  const ruleType = ["","回","分","時間", "日"];
  const limitType = ["","日","週","月","年","分","時間"];

  const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);
  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;
  
  const refPointAutoSettingDialog = useRef({} as PointAutoSettingDialogHandler);

  // const getPointSettingList = async (tabId: string): Promise<AxiosResponse<PointSettingInfo[]>> => {
  const getPointSettingList = async (tabId: string) => {
    try {
      // const res: AxiosResponse<PointSettingInfo[]> = await axios.get(
      // '/api/v1/admin/pointsetting/list',
      // { params: { tabId } }
      // );
      const res : PointSettingInfo[] = await httpClient.getPointSettingList(tabId);
      return res;
    } catch (err) {
      throw err;
    }
  }

  const fetchPointSettingList = async () => {

    try {
      const res = await getPointSettingList(tabId);

      const _pointSettingList = res
      ? res.map((v: any) => {
        const pointSetting: PointSettingData = new PointSettingData();
        pointSetting.id = v.id;
        pointSetting.pointSettingName = v.pointSettingName;
        pointSetting.tenantId = v.tenantId;
        pointSetting.ruleCount = v.ruleCount;
        pointSetting.ruleFrequency = v.ruleFrequency;
        pointSetting.ruleFrequencyName = ruleType[v.ruleFrequency];
        pointSetting.point = v.point;
        pointSetting.limitCount = v.limitCount;
        pointSetting.limitFrequency = v.limitFrequency;
        pointSetting.limitFrequencyName = limitType[v.limitFrequency];
        pointSetting.startDate = v.startDate;
        pointSetting.endDate = v.endDate;
        pointSetting.dispSetting = v.dispSetting;
        pointSetting.enable = v.enable;
        return pointSetting;
      }) : [] as PointSettingData[];

      setPointSettingList((prev) => _pointSettingList);
      setPointSettingListOrg(JSON.parse(JSON.stringify(_pointSettingList)));
      setLoading(false);
    } catch (err) {
      console.error(err);
      assignErrorData(err);
    }
  }

  /**
  * useEffect
  */
  useEffect(() => {
    setCrudType(0);
    setLoading(true);
    fetchPointSettingList();
  }, []);

  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  const handleReloadPointSetting = () => {
    setLoading(true);
    fetchPointSettingList();
  }

  const handlePointSwitch= (e: React.ChangeEvent<HTMLInputElement>, rowIdx:number)=> {
    const arr = [...pointSettingList];
    setCurrentRowNo(rowIdx);
    arr[rowIdx].enable = e.target.checked;
    setPointSettingList([...arr]);
    setIsShowSwitchConfirmDialog(true);
  }

  const handleSwitchConfirmDialogOkCancel = (mode:boolean) =>{
    const arr = [...pointSettingList];
    if(mode){
      // enable更新処理
      // const params = new URLSearchParams();
      // params.append("tab_id", tabId);
      // params.append("point_setting_id", arr[currentRowNo].id.toString());
      // params.append("enable", arr[currentRowNo].enable ? "true" : "false");
      // axios.post('/api/v1/admin/pointsetting/enable', params)
      httpClient.updatePointSettingEnable(tabId, arr[currentRowNo].id, arr[currentRowNo].enable)
        .then((e: string) => {
          fetchPointSettingList();
          setFinishInfo(new FinishInfo({
            message: arr[currentRowNo].pointSettingName + "の更新が完了しました。"
          }));
        })
        .catch(err => {
          assignErrorData(err);
        })
        .finally(() => {
          setLoading(false);
        });

    }else{
      arr[currentRowNo].enable = arr[currentRowNo].enable ? false : true;
    }
    setIsShowSwitchConfirmDialog(false);
  }

  const handleEditSetting = (rowIdx:number) =>{
    if(pointSettingList[rowIdx] != null){
      setCurrentRowNo(pointSettingList[rowIdx].id);
      // alert("id:" + pointSettingList[rowIdx].id);
      refPointAutoSettingDialog.current.open(rowIdx);
    }
  }

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  return (
    <Fragment>
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{marginTop: -20}}
      >
      <Card className={classes.root}>
        <CardHeader
        title='ポイント自動付与設定'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      <Fragment key="point-setting-table">
        <Paper style={{ width: '100%' }}>
          <TableContainer style={{ height: `calc(100vh - 180px)` }}>
            <Table stickyHeader aria-label="customized table" style={{tableLayout:'fixed',}}>
              <TableHead>
                <TableRow>
                    <StyledTableCell key = 'pointSettingNo' style={{textAlign: "center",minWidth: 80,maxWidth: 80,width: 80,}}>No.</StyledTableCell>
                    <StyledTableCell key = 'switch' style={{textAlign: "center",minWidth: 160,maxWidth: 160,width: 160,}}>オン/オフ</StyledTableCell>
                    <StyledTableCell key = 'pointSettingName' style={{minWidth: 300,maxWidth: 300,width: 300,}}>ポイント設定名</StyledTableCell>
                    <StyledTableCell key = 'pointSettingRule' style={{minWidth: 200,maxWidth: 200,width: 200,}}>ポイント付与ルール</StyledTableCell>
                    <StyledTableCell key = 'pointSettingLimit' style={{minWidth: 200,maxWidth: 200 ,width: 200,}}>ポイント付与上限</StyledTableCell>
                    <StyledTableCell key = 'startDate' style={{minWidth: 200,maxWidth: 200,width: 200,}}>ポイント付与開始日時</StyledTableCell>
                    <StyledTableCell key = 'endDate' style={{minWidth: 200,maxWidth: 200,width: 200,}}>ポイント付与終了日時</StyledTableCell>
                    <StyledTableCell style={{width: `calc(100% - 1160px)`,}}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pointSettingList.map((pointSetting: PointSettingData, rowIdx) => {
                  return (
                    <StyledTableRow>
                      <StyledTableCell style={{textAlign: "center"}}>
                        {rowIdx + 1}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "center"}}>
                        <Switch checked={pointSetting.enable} onChange={(e) => handlePointSwitch(e, rowIdx)}></Switch>
                      </StyledTableCell>
                      <StyledTableCell>
                        {pointSetting.pointSettingName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {pointSetting.ruleCount}{pointSetting.ruleFrequencyName}ごとに{pointSetting.point}ポイント
                      </StyledTableCell>
                      <StyledTableCell>
                        {pointSetting.limitFrequencyName}に{pointSetting.limitCount}回まで
                      </StyledTableCell>
                      <StyledTableCell>
                        {pointSetting.startDate === null ? "" : new Date(pointSetting.startDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {pointSetting.endDate === null ? "" : new Date(pointSetting.endDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button variant="contained" color="primary" onClick={() => handleEditSetting(rowIdx)}>編集</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Fragment>

      {drawLoading}

      <Dialog open={isShowSwitchConfirmDialog}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle id="dialog-registUser">オンオフ更新</DialogTitle>
        <DialogContent>
          <DialogContentText>
            対象ポイント設定のオン／オフを更新します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" onClick={() => { handleSwitchConfirmDialogOkCancel(true) }}>OK</Button>&nbsp;
            <Button variant="contained" color="primary" onClick={() => { handleSwitchConfirmDialogOkCancel(false) }}>キャンセル</Button>
          </div>
        </DialogActions>
      </Dialog>
    </Grid>
    <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
      <DialogContent>
        <DialogContentText>{finishInfo.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
      </DialogActions>
    </BaseDialog>
    <PointAutoSettingDialog ref={refPointAutoSettingDialog} currentRowNo={currentRowNo} handleReloadPointSetting={handleReloadPointSetting} />
    </Fragment>
  )
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
  
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
      maxWidth: 450,
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    tableLayout:'fixed',
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

// interface PointSettingInfo{
//   id: number;
//   pointSettingName: string;
//   ruleCount: number;
//   ruleFrequency: number;
//   point: number;
//   limitCount: number;
//   limitFrequency: number;
//   startDate: string;
//   endDate: string;
//   dispSetting: boolean;
//   enable: boolean;
//   tenantId: number;
// }

// UI表示用
class PointSettingData {
  id: number = 0;
  pointSettingName: string = "";
  ruleCount: number = 0;
  ruleFrequency: number = 1;
  ruleFrequencyName: string = "日";
  point: number = 0;
  limitCount: number = 0;
  limitFrequency: number = 1;
  limitFrequencyName: string = "日";
  startDate: string = "";
  endDate: string = "";
  dispSetting: boolean = true;
  enable: boolean = true;
  tenantId: number = 0;
}

import React, { Fragment, useState, useMemo, useEffect } from 'react';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient, { FloorObject, StudyObjectSetting } from '@fsi/jfs-sdk';

// icons
import CreateIcon from '../img/edit_B.png';
import QueuePlayNextIconON from '../img/monitor_act_B.png';
import { Utility } from '../common/Utility';
import SettingsIcon from '@material-ui/icons/Settings';
import StudySeatSettingDialog from './StudySeatSettingDialog';

const dynamicKanbanStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    top: 5,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 15,
    lineHeight: '15px',
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

export interface Props {
    className?: string,
    floorObject: FloorObject,
    role?: string
    updateStudyObjectSetting: StudyObjectSetting,
}

export default function StudySeat(props: Props) {

    const { className, role } = props;
    const { id, offsetLeft, offsetTop, objectMaster } = props.floorObject;

    const [isStudySeatSetting, setIsStudySeatSetting] = useState(false);
    const [isOpenStudySeatSettingDialog, setIsOpenStudySeatSettingDialog] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useEffect(() => {
    },[])

    const isEditable = useMemo(() =>
        role ? (role.match(/ROLE_ADMIN/) != null) : true
    , [role]);

    const studySeatSetting = useMemo(() => {
        return (
            isStudySeatSetting === true
                ? <div style={{ position: "relative" }}>
                    <img src={QueuePlayNextIconON} style={{ width: 24, height: 24, position: "absolute", left: 0 }} />
                    <div style={{ height: "20px", width: "20px", background: "#41BDCE", borderRadius: "50%", position: "absolute", left: 2, top: 2 }} />
                    <SettingsIcon style={{ width: 18, height: 18, color: '#ffffff', position: "absolute", left: 3, top: 3 }} />
                </div>
                : <div style={{position: "relative"}}>
                    <img src={CreateIcon} style={{ width: 24, height: 24, position: "absolute", left: 0}}/>
                    <div style={{ height: "20px", width: "20px" , background: "white" , borderRadius: "50%", position: "absolute", left: 2, top: 2}}/>
                    <SettingsIcon style={{ width: 18, height: 18, color: 'rgba(0, 0, 0, 0.65)', position: "absolute", left: 3, top: 3 }} />
                </div>
        )
    }, [isStudySeatSetting]);

    const drawStudySeatSetting = useMemo(() => (
        ( Utility.isStudySeat(props.floorObject.objectMaster.type, props.floorObject.objectMaster.paletteText) ) ?
            <Fragment>
                <div
                    key={`meetingRoomStudySeatSetting${id}`}
                    id={`meetingRoomStudySeatSetting${id}`}
                    className={className}
                    onClick={() => { if (isEditable) { setIsOpenStudySeatSettingDialog(true) } }}
                    style={{
                        ...dynamicKanbanStyle,
                        left: (objectMaster.width - objectMaster.editMinusOffsetLeft) + 20,
                        marginTop: -8,
                        height: 24,
                        width: 24,
                        pointerEvents: "auto",
                        border: "none",
                        backgroundColor: "rgba(0,0,0,0)",
                    }}
                >
                    {studySeatSetting}
                </div>
                <StudySeatSettingDialog
                    className={className}
                    id={props.floorObject.id}
                    isOpen={isOpenStudySeatSettingDialog && isEditable}
                    onClose={() => setIsOpenStudySeatSettingDialog(false)}
                    isEnable={isStudySeatSetting}
                    setIsEnable={setIsStudySeatSetting}
                    updateStudyObjectSetting={props.updateStudyObjectSetting}
                />
            </Fragment>
        :
            <Fragment/>
    ), [objectMaster.width, objectMaster.editMinusOffsetLeft, isOpenStudySeatSettingDialog, isStudySeatSetting, isEditable, props.updateStudyObjectSetting]);

    const draw = useMemo(() => (
        <div
            key={`meetingRoom${id}`}
            id={`meetingRoom${id}`}
            className={className}
            style={{
                ...SELECT_NONE,
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorSection,
                pointerEvents: "none",
            }}
        >
            {drawStudySeatSetting}
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [id, offsetLeft, offsetTop, objectMaster, isEditable, isOpenStudySeatSettingDialog, isStudySeatSetting, props.updateStudyObjectSetting]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    );
}
import React, { useEffect, useState,useMemo, Fragment, forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { makeStyles, Theme, createStyles, IconButton,} from '@material-ui/core';
import ChatBubbleOutlineSharpIcon from '@material-ui/icons/ChatBubbleOutlineSharp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { CharacterMessageEditDialogRef } from './CharacterMessageEditDialog';
import { CharacterDisplayEditDialogRef } from './CharacterDisplayEditDialog';
import JfsClient, { CharacterMessageSetting } from '@fsi/jfs-sdk';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fukidasi:{
            width: 165,
            //height: 50,
            position: 'absolute',
            // bottom: 28,
            // left: -140
        },
        frame: {
            padding: 0,
            width: 150,
            //height: 100,
            margin: 0,
            border: '2px solid green',
            borderRadius: 10,
            background: 'white',
            zIndex: 401, // otherUser関連より上、myUserより下
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            left: 0,
            boxSizing: 'border-box',
            whiteSpace: 'pre-wrap',
        },
        triangle1:  {
            position: 'absolute',
            width: 0,
            height: 0,
            borderTop: '4px solid transparent',
            borderLeft: '15px solid green',
            borderBottom: '7px solid transparent',
            bottom: 10,
            right: 0,
            zIndex: 402,
        },
        triangle2:  {
            position: 'absolute',
            width: 0,
            height: 0,
            borderTop: '3px solid transparent',
            borderLeft: '15px solid green',
            borderBottom: '6px solid transparent',
            bottom: 11,
            right: 1,
            zIndex: 403,
        },
    }),
);

interface Props {
    role: string, 
    className: string,
    floorObject: FloorObject,
}

export interface ClickCharacterChangeHandler {
    /** ツールランチャー設定画面を開く */
    clickCharacterChange(id:number): void;
    characterSetting(): void;
}

const CharacterObject: ForwardRefRenderFunction<ClickCharacterChangeHandler, Props> = (props, ref) => {
    const { className } = props;
    const classes = useStyles();
    const { id, offsetLeft, offsetTop, objectMaster } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDisplay, setIsOpenDisplay] = useState(false);
    const [characterOpenMouthId, setCharacterOpenMouthId] = useState(0);
    const [characterChange, setCharacterChange] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState("");
    const [openChatBubble, setOpenChatBubble] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const [isTrueTime, setIsTrueTime] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editAfter, setEditAfter] = useState(false);
    const [beforeCharacter, setBeforeCharacter] = useState(0);
    const [afterCharacter, setAfterCharacter] = useState(0);

    useImperativeHandle(ref, () => ({
        clickCharacterChange: async (id:number) => {
            setEditAfter(true);
            setAfterCharacter(id);
            setEdit(!edit);
        },
        characterSetting: async () => {
            await getCharacterDisplayTrueTime();
        },
    }))

    useEffect(() => {
        let lastMinute = new Date().getMinutes();

        const checkTime = async () => {
            const currentMinute = new Date().getMinutes();

            if (lastMinute !== currentMinute) {
                await getCharacterDisplayTrueTime();
                lastMinute = currentMinute;
            }
        };

        // 初回レンダリング call
        getCharacterDisplayTrueTime();

        const interval = setInterval(checkTime, 1000);

        return () => clearInterval(interval);

    }, []);


    const getCharacterDisplayTrueTime = async () => {
        httpClient.getCharacterDisplayTrueTime(sessionStorage.getItem("TABID") as string, props.floorObject.id)
        .then(response => {
            let booleanValue : boolean = response; 
            setIsTrueTime(booleanValue);
            if (!booleanValue) {
                setOpenChatBubble(false);
            }
        })
        .catch((error: Error) => console.error('Error:', error));
    }

    // 最初の一度だけ実行
    useEffect(() => {
        async function get() {
            await getCharacterOpenMouthInfo();
        }

        get();
        if(!edit){
            setEditAfter(true);
        }
    }, [edit])

    const getCharacterOpenMouthInfo = async () => {//クリック切替画像
        
        httpClient.getCharacterOpenMouthInfo(sessionStorage.getItem("TABID") as string, id, new Date().getTime().toString())
            .then(response => {
            let res = response as number;

            // 送られた口が開いたキャラクターのオブジェクトマスターIDを保存する
            setCharacterOpenMouthId(res);
            setBeforeCharacter(res);

        }).catch(err => {
            console.log(err);
        });
    }

    const getCharacterTalk = () => {
        
        httpClient.getCharacterTalk(sessionStorage.getItem("TABID") as string, id)
            .then(response => {
            let res = response as CharacterMessageSetting[];

            if(res.length === 0){
                return;
            }
            setCharacterChange(true);
            let message = selectMessage(res);
            setSelectedMessage(message);
            setOpenChatBubble(true);

            console.info(characterOpenMouthId.toString()+':'+beforeCharacter.toString()+ '?=' +afterCharacter.toString());

            if(afterCharacter==0){
                if(editAfter){
                    setEdit(!edit);
                }else{
                    return;
                }
            } 
            if(beforeCharacter == afterCharacter) {
                return;
            }else{
                setEdit(!edit);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const insertLineBreaks = (message:string, lineLengthLimit: number) => {
        const result = [];
        let line = '';
    
        for (let i = 0; i < message.length; i++) {
            line += message[i];
            if ((i + 1) % lineLengthLimit === 0 && i !== message.length - 1) {
                result.push(line);
                line = '';
            }
        }
    
        if (line) {
            result.push(line);
        }
    
        return result;
    };
    
    const judgeMessageHeight = (message:string) => {
        const lineLengthLimit = 10;
        const firstHeight = 46;
        const lineHeight = 23;
        const lines = insertLineBreaks(message, lineLengthLimit);
        return firstHeight + (lines.length - 2) * lineHeight;
    };

    // メッセージ候補の中から確率でメッセージを取得
    const selectMessage = (characterMessageList: CharacterMessageSetting[]) => {

        // メッセージの候補を「日付指定・毎日・毎週・毎年」で仕分ける
        const dateAndTime = characterMessageList.filter((characterMessage) => characterMessage.cycle === 0);
        const everyDay = characterMessageList.filter((characterMessage) => characterMessage.cycle === 1);
        const everyWeek = characterMessageList.filter((characterMessage) => characterMessage.cycle === 2);
        const everyYear = characterMessageList.filter((characterMessage) => characterMessage.cycle === 3);
        
        //  メッセージリストを配列で管理
        const messageList = [dateAndTime,everyDay,everyWeek,everyYear];
        // 「日付指定・毎日・毎週・毎年」のサイズを管理する配列作成
        const manageLength = [dateAndTime.length, everyDay.length, everyWeek.length, everyYear.length];
        // 「日付指定・毎日・毎週・毎年」の重みを管理する配列
        const manageWeight = [2.5, 1.0, 1.5, 2.0];

        // 「日付指定・毎日・毎週・毎年」の重み(確率)を決定
        let total = 0;
        for(let i = 0; i<4; i++){
            total += manageLength[i]*manageWeight[i];
        }
        const dateAndTimeWeight = manageLength[0] * manageWeight[0] / total;
        const everyDayWeight = manageLength[1] * manageWeight[1] / total;
        const everyWeekWeight = manageLength[2] * manageWeight[2] / total;
        const everyYearWeight = manageLength[3] * manageWeight[3] / total;

        // 確率を管理するオブジェクト作成
        let prob = [{weight: dateAndTimeWeight, num: 0},
                    {weight: everyDayWeight, num: 1},
                    {weight: everyWeekWeight, num: 2},
                    {weight: everyYearWeight, num: 3}];

        // 確率を管理するオブジェクトの中身の整理(ソートと削除)
        let probFixed = prob.filter(e => e.weight !== 0);
        probFixed.sort((a,b) => a.weight - b.weight);

        // 「日付指定・毎日・毎週・毎年」の確率的選択
        let randomNum = Math.random();
        let result = 0;
        let rate = 0;
        for(let i = 0; i<probFixed.length; i++){
            rate += probFixed[i].weight;
            if(randomNum <= rate){
                result = probFixed[i].num;
                break;
            }
        }

        let selectedMessage = messageList[result][getRandomInt(0,manageLength[result])].message;

        return selectedMessage;
    }

    // min以上max未満の整数をランダムで返す
    const getRandomInt = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }
    
    const handleClickChatBubble = () => {
        setCharacterChange(false);
        setOpenChatBubble(false);
    }

    const generateBackgroundImageUrl = () => {
        let imageId = characterChange ? characterOpenMouthId : objectMaster.id; 
        let imageUrl = httpClient.createObjectImgUrl(imageId, undefined, sessionStorage.getItem("TABID") as string); 

        // imageUrlがnullまたはundefinedの場合、代替のIDで再度URLを生成
        if (!imageUrl && characterChange) {
            // characterChangeがtrueだった場合のみ、代替としてobjectMaster.id
            imageUrl = httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string);
            setEdit(!edit);
        }

        return `url(${imageUrl})`; 
    }

    const drawChatBubble = useMemo(() => {
        const messageLines = insertLineBreaks(selectedMessage, 10).map((line, index) => (
            <Fragment key={index}>
                {line}
                <br/>
            </Fragment>
        ));

        if(openChatBubble){
            return (
                <div className={classes.fukidasi} 
                    style={{
                        height: judgeMessageHeight(selectedMessage),
                        top: (objectMaster.textOffsetTop) * (objectMaster.height / 80),
                        left: -155 + (objectMaster.textOffsetLeft) * (objectMaster.height / 80),
                    }}>
                    <div className={classes.frame} onClick={() => handleClickChatBubble()} style={{height: judgeMessageHeight(selectedMessage)}}>{messageLines}</div>
                </div>
            )
        }
    }, 
    [openChatBubble,selectedMessage])


    /*
    * メッセージ編集アイコンの表示
    */
    const drawMessageSettingIcon = useMemo(() => {

        let allowSetting = props.role !== undefined && props.role.indexOf("ROLE_ADMIN") > -1;
        
        if(allowSetting) {
            return (
                <Fragment>
                    <IconButton onClick={() => setIsOpen(true)} disableTouchRipple={true} 
                        style={{
                            position: 'absolute',
                            right: objectMaster.editMinusOffsetLeft-26,
                            top: 0,
                            zIndex: ZIndex.fixedUiLayer,
                            backgroundColor: 'white',
                            border: '1px solid black',
                            width: 11,
                            height: 11,
                        }}>
                        <ChatBubbleOutlineSharpIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </Fragment>
            )
        }
    }, 
    [props.role])

    /*
    * 表示時間編集アイコンの表示
    */
    const drawDisplaySettingIcon = useMemo(() => {

        let allowSetting = props.role !== undefined && props.role.indexOf("ROLE_ADMIN") > -1;
        
        if(allowSetting) {
            return (
                <Fragment>
                    <IconButton onClick={() => setIsOpenDisplay(true)} disableTouchRipple={true} 
                        style={{
                            position: 'absolute',
                            right: objectMaster.editMinusOffsetLeft,
                            top:0,
                            zIndex: ZIndex.fixedUiLayer,
                            backgroundColor: 'white',
                            border: '1px solid black',
                            width: 11,
                            height: 11,
                        }}>
                        <AccessTimeIcon style={{width: 20, height: 20}} />
                    </IconButton>
                </Fragment>
            )
        }
    }, 
    [props.role])
    

    const draw = useMemo(() =>{
        if(isTrueTime){
            return(
                <Fragment>
                <div
                    key={`CharacterObject${id}`}
                    id={`CharacterObject${id}`}
                    className={className}
                    style={{
                        position: "absolute",
                        left: offsetLeft,
                        top: offsetTop,
                        backgroundSize: "contain",
                        //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                        backgroundImage: generateBackgroundImageUrl(),
                        backgroundRepeat: "no-repeat",
                        width: objectMaster.width,
                        height: objectMaster.height,
                        zIndex: ZIndex.originalObject,
                    }}
                >
                    <div
                        key={`CharacterObjectTalkArea`}
                        id={`CharacterObjectTalkArea`}
                        style={{
                            top: 0,
                            left: 0,
                            height: objectMaster.textHeight,
                            width: objectMaster.textWidth,
                            zIndex: ZIndex.floorObject,
                        }}
                        onClick={characterChange ? () => handleClickChatBubble() : () => getCharacterTalk()}
                    >
                    </div>
                    {drawMessageSettingIcon}
                    {drawDisplaySettingIcon}
                    {drawChatBubble}
                    <CharacterMessageEditDialogRef
                        floorObjectId={id}
                        open={isOpen}
                        onClickClose={() => setIsOpen(false)}
                        maxLength={props.floorObject.objectMaster.textLength}
                    />
                    <CharacterDisplayEditDialogRef
                                floorObjectId={id}
                                open={isOpenDisplay}
                                onClickClose={() => setIsOpenDisplay(false)}
                                maxLength={props.floorObject.objectMaster.textLength}
                                //onButtonClick={handleEditButtonClick}
                    />
                </div>
                </Fragment>
            )
        } else if(!isTrueTime){
            return(
                <Fragment>
                <div
                    key={`CharacterObject${id}`}
                    id={`CharacterObject${id}`}
                    className={className}
                    style={{
                        position: "absolute",
                        left: offsetLeft,
                        top: offsetTop,
                        backgroundSize: "contain",
                        //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                        //backgroundImage: characterChange ? `url(${httpClient.createObjectImgUrl(characterOpenMouthId)})` : `url(${httpClient.createObjectImgUrl(objectMaster.id)})`,
                        backgroundRepeat: "no-repeat",
                        width: objectMaster.width,
                        height: objectMaster.height,
                        zIndex: ZIndex.floorObject,
                    }}
                >
                    {/*
                    <div
                        key={`CharacterObjectTalkArea`}
                        id={`CharacterObjectTalkArea`}
                        style={{
                            top: 0,
                            left: 0,
                            height: objectMaster.textHeight,
                            width: objectMaster.textWidth,
                            zIndex: ZIndex.floorObject,
                        }}
                        onClick={() => getCharacterTalk()}
                    >
                    </div>
                    */}
                    {drawMessageSettingIcon}
                    {drawDisplaySettingIcon}
                    {drawChatBubble}
                    <CharacterMessageEditDialogRef
                        floorObjectId={id}
                        open={isOpen}
                        onClickClose={() => setIsOpen(false)}
                        maxLength={props.floorObject.objectMaster.textLength}
                    />
                    <CharacterDisplayEditDialogRef
                                floorObjectId={id}
                                open={isOpenDisplay}
                                onClickClose={() => setIsOpenDisplay(false)}
                                maxLength={props.floorObject.objectMaster.textLength}
                                //onButtonClick={handleEditButtonClick}
                    />
                </div>
                </Fragment>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [offsetLeft, offsetTop, objectMaster, isOpen, isOpenDisplay, props.role, openChatBubble, characterChange, selectedMessage, isTrueTime,characterOpenMouthId]);
    
    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const OriginalCharacterObject = forwardRef(CharacterObject);
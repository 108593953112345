import React, { useState, useMemo, Fragment } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import Typography from '@material-ui/core/Typography';
import EditTextDialog from './EditTextDialog';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    className: string,
    floorObject: FloorObject,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    role: string
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    width: 57,
    height: 21,
    fontSize: 10,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

export default function DynamicKanban(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const { role } = props;

    const [isOpen, setIsOpen] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const draw = useMemo(() =>
        <Fragment>
            <div
                key={`dynamicKanban${id}`}
                id={`dynamicKanban${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    left: offsetLeft,
                    top: offsetTop,
                    width: objectMaster.width,
                    height: objectMaster.height,
                    backgroundSize: "contain",
                    //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                    backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                    backgroundRepeat: "no-repeat",
                    zIndex: ZIndex.floorObject,
                }}
            >
                <div
                    onClick={() => setIsOpen(true)}
                    className={className}
                    style={{ ...SELECT_NONE, position: "absolute", width: 57, zIndex: ZIndex.floorObject, left:objectMaster.textOffsetLeft, top:objectMaster.textOffsetTop }}
                >
                    <Typography className={className} style={kanbanTextStyle} variant="body2" color="textSecondary">
                        {text2}
                    </Typography>
                </div>
            </div>

            <EditTextDialog
                className={className}
                isOpen={isOpen && (role.match(/ROLE_ADMIN/) != null)}
                floorObjectId={id}
                title={text1 ? `${text1}: 表示内容` : '表示内容'}
                text={text2}
                maxLength={10}
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [text1, text2, isOpen])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, ForwardRefRenderFunction, useEffect, useRef, useCallback } from 'react';
import BaseUser, { UserState } from './BaseUser';
import UserMenu from './UserMenu';
import PcMenu from './PcMenu';
import HitPersonEffect, { AvatarEffectType } from './effect/Index';
import { AvatarData, AvatarMenuData, FloorData, FloorObject, InviteMoreNoteSAML, WebRtcInOutInfo, FlexibleNamePlate } from '../common/JsonClass';
import WebrtcComponent, { ScreenShareMode } from '../webrtc/WebrtcComponent';
import BaseDialog from './BaseDialog';
import StudyTimer from "./StudyTimer";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ZIndex from "../common/ZIndex";
import { INNER_RATIO, AVATAR_WIDTH_GAIN, AVATAR_HEIGHT_GAIN } from "../common/AvatarSize";
import { calcAvatarWidth, calcAvatarHeight, calcWebRtcAreaWrapperSize, calcWebRtcVideoSize } from "../common/AvatarSize";
import { CheckContinueWebRtcDialog, CheckContinueWebRtcDialogHandler } from './dialog/CheckContinueWebRtcDialog';
import { WebrtcStartConfirmDialog, WebrtcStartConfirmDialogHandler } from './dialog/WebrtcStartConfirmDialog';
import { VideoURLStartConfirmDialog, VideoURLStartConfirmDialogHandler } from './dialog/VideoURLStartConfirmDialog';    // iPad対応-12
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import WebrtcService from '../webrtc/WebrtcService';
import SetMyAvatar from './SetMyAvatar';
import { Logger } from '../common/Logger';
import YouTube, { Props as YouTubeProps} from './YouTube';
import { MoreNote, MoreNoteHandler } from './MoreNote';
import { MyUserStatusTimer, statusTimerHandler } from './MyUserStatusTimer';
import { Utility } from '../common/Utility';
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';

// MyUserを示すマーカー
//import {Arrow, AnimBar, AnimEllipse, Ellipse } from "../test/MyUserMarker";
//const tempAnm: number = 4; //1.Arrow, 2.AnimBar, 3.AnimEllipse, 4.Ellipse

interface Props {
    floorData: FloorData,
    avatarDatas: AvatarData[],
    scale: number,
    onStateChange: (state: number, enableMeet: boolean) => void,
    onTubuyakiChange: (text: string) => void,
    onMoveFloor: (id: number) => void,
    onMyAvatarChange: (yes: boolean, value?: number) => void,
    onMySeatChange: (yes: boolean) => void,
    onMySeatRelease: (yes: boolean) => void,
    onOtherSeatRelease: (yes: boolean) => void,
    onJumpToMySeat: () => void,
    sendUserMicLevel: (micVolume: number) => void,
    sendWebRtcInfo: (info: WebRtcInOutInfo) => void,
    setVisibleScreenShareButton: (value: boolean) => void,
    handleScreenShare: (value: boolean, mode: number, webRtcRoomId: string) => void,
    hanldleBroadcast: (value: boolean, userId: number) => void,
    handleSendCommand: (isYes: boolean) => void,
    onMyStreamLoaded: (id: number) => void,
    onRemoteStreamLoading: (id: number) => void,
    onRemoteStreamLoaded: (id: number, dev: number) => void,
    onUpdateJoinRoomStatus: (sid: string, pid: string, event: string, data: string) => void,    // joinRoom の状態を通知
    onStartMoteNote: (window: Window | null) => void,
    onStartMoteNoteInlineFrame: (url: string) => void,
    onForceMute: (isForceMute: boolean) => void,
    onClapping: () => void,
    onOpenUserSearch: (open: boolean) => void,
    onCardDisclose: (isShow: boolean) => void,
    onReceiveForceMute: (on: boolean) => void,
    onReceiveVideoURLText: (url: string) => void,
    handleContinueWebRtcResult: (isContinue: boolean) => void,
    handleStartConfirmResult: (result: number) => void,
    onMyConfirmSettingChange: (value?: number) => void,
    onMyLoginoutNotificationChange: (value1?: number, value2?: number) => void,
    handleShareScreenOpen: (isOpen: boolean) => void,
    onScreenShareVideoLoaded: (isLoaded: boolean, screenShareSessionId: string, userList?: Map<string, User>) => void,
    sendSetGhost: (isGhost: boolean) => void,
    sendDeviceSelectedType: (deviceSelectedType: number) => void,
    handleCloseMessageDialog: () => void,
    isCommuting: boolean;
    onChangeMyCommuting: (commuting: boolean) => void,
    youtubeProps: YouTubeProps,
    resetIsChangeVideo: (flag: boolean) => void,
    handleCheckOpenedHello: () => void,
    handleRegistCallbackOpenedHello: () => void,
    displayErrorSnackBar: (device: number) => void,
    onDeviceSelected: () => void,      // カメラ未選択時の表示対応
    onPictograph: (state: number) => void, // 絵文字表示
    onOpenUsersControl: (isOpen: boolean) => void,
    getFloorObjectFromId: (id: number) => FloorObject | undefined
    getEnabledBusinessCard:() => boolean,
    namePlateColors: FlexibleNamePlate[],
    onChangeNamePlate:(value: number) => void,
    kind: null | string,
    usermanualURL: string;  // マニュアルURLをDBから取得
    requestPrivacyRoom: (floorId: number, userId: number, targetFloorId:number) => void;
    sendPrivacyRoomTimeOut: (kind: number, floorId: number) => void;
    sendMovePrivacyRoomInfo: (stayPrivacyroom: boolean, preFloorId: number, preFloorName: string) => void;
    studyAdditionalTime: number,
    setStudyCount: (count: number) => void,
    setStudyEnd: (state: boolean) => void,
    openSelfStudyFinishDialog: (open: boolean) => void;
    handleMyStudyElapsedTime: (elapsedTime: number) => void,
    desktopNotificationWithoutFloorName: (text: string) => void,
}

// 公開したいメソッドの定義
export interface ChildHandler {
    setHitEffectId: (hitEffectId: number) => void;
    getUser: () => void;
    setUser: (user: User) => void;
    setAvatarMenuDataList: (avatarMenuDataList: AvatarMenuData[]) => void;
    openDeviceSelect: () => void;
    click: () => void;
    getOpenMenu: () => boolean;
    setOpenProfile: (isOpen: boolean) => void;
    setScreenShareSessionId: (value: string) => void;
    getUseTurnServer: () => boolean | null;
    setCheckMediaDevices: (value: boolean) => void;
    handleClickMoreNote: (iswhiteBoard: boolean) => void;
    setShowSetMyAvatar: () => void;
    openedHello: (json: any) => void;
    onChangeScreenShare: (isScreenShare: boolean) => void;
    clearStatusTimer: () => void;
    handleSelfStudyTableVideo: (open: boolean) => void;
    handleTimerStart: (startTime: number, endTime: number) => void;
}

// 子要素を自身の中心に配置するstyle
const centeringChild: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
// avatar image 共通のstyle
const avatarImageStyle: React.CSSProperties = {
    ...SELECT_NONE,
    width: "100%",
    height: "100%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
};
// webRTC area 共通のstyle
const webRtcAreaStyle: React.CSSProperties = {
    ...SELECT_NONE,
    ...centeringChild,
    borderRadius: "50%", // 円形
};

const apiBase = "/api/user";
const zIndex = ZIndex.myUser;

const MyUserComponent: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const { enqueueSnackbar } = useSnackbar();

    const [hitEffectId, setHitEffectId] = useState(0);
    const [floorId, setFloorId] = useState(0);
    const [state, setState] = useState({
        user: new User(),
        avartarMenuDataList: [] as AvatarMenuData[],
    })
    const { user } = state;
    const [openDeviceSelect, setOpenDeviceSelect] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openPcMenu, setOpenPcMenu] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openCommandDialog, setOpenCommandDialog] = useState(false);
    const [openMesssageDialog, setOpenMesssageDialog] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [checkMediaDevices, setCheckMediaDevices] = useState(false);

    const [moreNote, setMoreNote] = useState(false);
    const [moreNoteConfirm, setMoreNoteConfirm] = useState(false);
    const [moreNoteConfirmHost, setMoreNoteConfirmHost] = useState(false);
    const [moreNoteConfirmUrl, setMoreNoteConfirmUrl] = useState("");
    const [moreNoteConfirmTitle, setMoreNoteConfirmTitle] = useState("");
    const [moreNoteConfirmText, setMoreNoteConfirmText] = useState("");
    const [moreNoteIviteUser, setMoreNoteIviteUser] = useState(new User());
    const [moreNoteWhiteBoard, setMoreNoteWhiteBoard] = useState(false);
    const [showSetMyAvatarDialog, setShowSetMyAvatarDialog] = useState(false);    
    const [moreNoteGuestUrl, setMoreNoteGuestUrl] = useState("");
    const [selfStudyTableVideo, setSelfStudyTableVideo] = useState(false);
    const {count, timeEnd, isTimerStop, timerFlag, isTimerEnd, startCount, stopAndRestartCount, setEnd, initCount} = StudyTimer();
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const checkContinueRef = useRef({} as CheckContinueWebRtcDialogHandler);
    // ビデオ通話前の確認ダイアログ
    const startConfirmRef = useRef({} as WebrtcStartConfirmDialogHandler);
    // iPad対応-12 外部ビデオ通話連携前の確認ダイアログ
    const videoURLStartConfirmRef = useRef({} as VideoURLStartConfirmDialogHandler);
    // MoreNoteコンポーネントの参照
    const moreNoteRef = useRef({} as MoreNoteHandler);
    // ステータスタイマーコンポーネントの参照
    const statusTimerRef = useRef({} as statusTimerHandler);

    const PURPOSE_OF_USE_OFFICE: number = 1;    // フロアの使用目的(オフィス)
    const logger: Logger = Logger.getInstance();

    // avatar の large サイズフラグ
    const isLarge = useMemo(() => {
        return (!user.isMediaWaiting && user.webRtcCall)
    }, [user.isMediaWaiting, user.webRtcCall])

    // avatar要素のtop
    const avatarTop = useMemo(() => {
        return user.y - (isLarge ? AVATAR_HEIGHT_GAIN / 2 : 0)
    }, [user.y, isLarge]);

    // avatar要素のleft
    const avatarLeft = useMemo(() => {
        return user.x - (isLarge ? AVATAR_WIDTH_GAIN / 2 : 0)
    }, [user.x, isLarge]);

    // avatar要素の幅
    const avatarWidth = useMemo(() => {
        return calcAvatarWidth(user.width, isLarge);
    }, [user.width, isLarge]);

    // avatar要素の高さ
    const avatarHeight = useMemo(() => {
        return calcAvatarHeight(user.height, isLarge);
    }, [user.height, isLarge]);

    // webRtcAreaWrapperのサイズ（正方形の一辺）
    const webRtcAreaWrapperSize = useMemo(() => {
        return calcWebRtcAreaWrapperSize(user.width, isLarge, user.isLargeVideo);
    }, [user.width, isLarge, user.isLargeVideo]);

    // フロアの使用目的
    const purposeOfUse = useMemo(() => {
        if (props.floorData?.purposeOfUse !== null && props.floorData?.purposeOfUse != undefined) {
            return props.floorData.purposeOfUse;
        }
        return 0;
    }, [props.floorData]);

    // floor login時のアニメーション起動
    useEffect(() => {
        setTimeout(() => setHitEffectId(AvatarEffectType.Login), 1500);
    }, []);

    // waiting時のアニメーション切替
    useEffect(() => {
        if (user.isMediaWaiting === true) {
            setHitEffectId(AvatarEffectType.Waiting)
        } else if (user.isMediaWaiting === false && hitEffectId > 0) {
            setHitEffectId(AvatarEffectType.None)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.isMediaWaiting])

    // 種々の size 変更時にvideo要素のサイズを変更する
    useEffect(() => {
        const video = document.getElementById(`${user.id}-video`) as HTMLVideoElement;
        if (video) {
            const _width = user.isLargeVideo ? webRtcAreaWrapperSize : avatarWidth;
            const size = calcWebRtcVideoSize(_width, user.isLargeVideo);
            video.width = size;
            video.height = size;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarWidth, webRtcAreaWrapperSize, user.isLargeVideo])

    // 着座時のPCアニメーション定義
    useEffect(() => {
        if (user.myPc.height === 0) {
            return;
        }

        const animationName = `openPc-${user.myPc.height}px-${user.myPc.frames}f`;
        const headElement = document.getElementsByTagName("head")[0];
        for (let i = headElement.childNodes.length - 1; i >= 0; i--) {
            const node = headElement.childNodes[i];
            if (node.nodeType === 1 && node.textContent && node.textContent.indexOf(animationName) >= 0) {
                // すでに定義済みの場合
                return;
            }
        }

        // 着座時のCSSスプライト定義
        const cssAnimation = document.createElement('style');
        const rules = document.createTextNode(`@keyframes ${animationName} { to { background-position: 0 -${user.myPc.height * (user.myPc.frames - 1)}px; } }`);
        cssAnimation.appendChild(rules);
        document.getElementsByTagName("head")[0].appendChild(cssAnimation);

    }, [user.myPc.height])
    // 共有画面表示要素の表示・非表示切替 
    useEffect(() => {
        if (user.screenShareMode.valueOf() === ScreenShareMode.FullScreen) {
            props.handleShareScreenOpen(false);
        } else if (user.screenShareMode.valueOf() === ScreenShareMode.FloatingElement) {
            props.handleShareScreenOpen(user.isScreenSharing);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.isScreenShare, user.isScreenSharing, user.screenShareMode, user.screenShareSessionId]);

    // 一斉ミュート状態にされた場合の処理
    useEffect(() => {
        props.onReceiveForceMute(user.isForceMute);
    }, [user.isForceMute])

    // 外部ビデオ通話システムを通知された場合の処理
    useEffect(() => {
        // iPad対応-12 iPadの場合、FAMがアクティブでなくなり切断されるのでメッセージを出す
        if(WebrtcService.isiOS() === true){
            if(user.videoURL !== undefined && user.videoURL !== null && user.videoURL.indexOf('http') === 0) {
                videoURLStartConfirmRef.current.open();
            }
        } else {
            props.onReceiveVideoURLText(user.videoURL);
        }
    }, [user.videoURL])

    // 自習席にて、外部ビデオ通話システムを通知された場合の処理
    useEffect(() => {
        // 自習開始ダイアログでキャンセルボタンを押した際は、動作させない
        if(!selfStudyTableVideo) return;

        // iPad対応-12 iPadの場合、FAMがアクティブでなくなり切断されるのでメッセージを出す
        if(user.videoURL !== undefined && user.videoURL !== null){
            if(WebrtcService.isiOS() === true){
                if(user.videoURL !== undefined && user.videoURL !== null && user.videoURL.indexOf('http') === 0) {
                    videoURLStartConfirmRef.current.open();
                }
            } else {
                props.onReceiveVideoURLText(user.videoURL);
            }
        }
    }, [selfStudyTableVideo])

    /**
     * WebRTC通話開始後にHelloを起動するための処理
     */
    useEffect(() => {
        if (user.isWebrtcJoin && moreNoteGuestUrl !== "") {
            console.log("Hello roomId {}", moreNoteGuestUrl);
            requestHelloInvitee(moreNoteGuestUrl);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.isWebrtcJoin, moreNoteGuestUrl])
    
    // 退勤時にステータスタイマーを設定していたらストップする
    useEffect(() => {
        if(props.isCommuting === false && user.state !== 0){
            statusTimerRef.current.stopTimer();
        }
    }, [props.isCommuting])

    // 自習開始時にタイマーを動作させる
    useEffect(() => {
        if(state.user.isStudy){
            if(state.user.studyTime === -1){
                handleTimerStart(0,state.user.studyTime); // studyTime.tsxが提供するタイマーがタイマー上限なしで稼働し始める
            }else{
                handleTimerStart(0,state.user.studyTime*60); // studyTime.tsxが提供するタイマーがタイマー上限ありで稼働し始める
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.user.isStudy])

    //稼働させているタイマー値を取得し,Floor.tsxに流し、別ファイルで利用できるようにしている
    useEffect(() => {
        props.setStudyCount(count);
        // デバッグ用に以下のconsole.infoはコメントアウトにとどめる
        // console.info("MyUser count(秒単位):"+count);
        if(count%60 === 0){ // 60秒で割り切れる瞬間になったらstate.user.studyElapsedTimeを更新し、websocketで更新を通知
            // デバッグ用に以下のconsole.infoはコメントアウトにとどめる
            // console.info("MyUser countが分が変動:"+Math.floor(count/60)+"分");
            props.handleMyStudyElapsedTime(Math.floor(count/60));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[count])

    // タイマーの一時停止・再開
    useEffect(() => {
        if(state.user.isStudy){ // isStudyがtrue(自習中)であることが大前提
            if(state.user.isTimer){ // 「タイマー再開時」
                if(props.studyAdditionalTime !== 0){ // 延長時間が設定されていた場合
                    if(timeEnd === -1){ // タイマーの設定がなされていない場合
                        // console.info("タイマーなし→延長(timeEnd):"+timeEnd);
                        setEnd(count+props.studyAdditionalTime*60); // タイマーが-1で設定されているので打消し、延長時間も追加
                    }else{ // タイマーの設定がなされている場合
                        // console.info("タイマーあり→延長(timeEnd):"+timeEnd);
                        setEnd(timeEnd+props.studyAdditionalTime*60);
                    }
                }
                stopAndRestartCount(false);
            }else{ // 「タイマー一時停止時」
                stopAndRestartCount(true);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.user.isTimer, props.studyAdditionalTime])

    // タイマーが終わったことを検知し、振り返りダイアログを出す
    useEffect(() => {
        props.setStudyEnd(isTimerEnd); // タイマーの終了フラグをFloor.tsxに渡し、別ファイルで使用できるようにしている
        if(isTimerEnd){ // 設定した自習時間に達した
            // console.info("MyUser タイマーが終了した:"+isTimerEnd);
            props.openSelfStudyFinishDialog(true);
            props.desktopNotificationWithoutFloorName("自習終了の時間です!\n振り返り登録をしてください")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTimerEnd])

    useImperativeHandle(ref, () => ({
        setHitEffectId: (newHitEffectId: number) => {
            // 初期化状態(0)の時のみアニメーションを変更
            if (
                newHitEffectId === AvatarEffectType.None ||
                (newHitEffectId !== AvatarEffectType.None && hitEffectId === AvatarEffectType.None)
            ) {
                setHitEffectId(newHitEffectId);
            }
        },
        getUser: () => {
            return state.user;  // Floorで呼んでるUser.State
        },
        setUser: (user: User) => {
            setState({
                ...state,
                user: user,
            })
        },
        setAvatarMenuDataList: (avatarMenuDataList: AvatarMenuData[]) => {
            setState({
                ...state,
                avartarMenuDataList: avatarMenuDataList,
            })
        },
        setFoorId: (id: number) => {
            setFloorId(id);
        },
        openDeviceSelect: () => {
            // booleanの値を変化させることで、ダイアログを表示させている
            setOpenDeviceSelect(openDeviceSelect ? false : true);
        },
        click: () => {
            // メニューの開閉を切り替える
            setOpenMenu(!openMenu);
            setOpenPcMenu(false);
        },
        getOpenMenu: () => {
            return openMenu;
        },
        setMoreNoteGuestUrl: (url: string) => {
            logger.info("setMoreNoteGuestUrl url:" + url);
            // WebRTC通話開始後にHelloを起動するための対応
            // インラインフレームで開くURLをいったん保持し、WebRTC通話開始後にインラインフレームを開く
            setMoreNoteGuestUrl(url);
        },
        closeMoreNote: () => {
            setMoreNote(false);
        },
        invitedJoinUserToMoreNote: (joinUser: User) => {
            const logger: Logger = Logger.getInstance();
            console.log("joinUser:" + joinUser);
            logger.info("[HELLO_TRACE] 招待ダイアログ表示処理実行 : roomId=["+joinUser.webRtcRoomId+"] user=["+joinUser.displayName+"]["+joinUser.id+"] preUser=["+moreNoteIviteUser.displayName+"]");
            setMoreNoteIviteUser(joinUser);
            openMoreNoteConfirm("", true, "資料・ホワイトボードの共有確認", joinUser.displayName + "さんが入室しました。資料・ホワイトボードの共有に招待しますか？");
        },
        setOpenProfile: (isOpen: boolean) => {
            setOpenProfile(isOpen);
        },
        setScreenShareSessionId: (screenShareSessionId: string) => {
            setState({
                ...state,
                user: { ...user, screenShareSessionId }
            });
        },
        // TODO: 現状、取得できない
        getUseTurnServer: () => {
            console.log("WebrtcComponent", WebrtcComponent);
            console.dir(WebrtcComponent);
            const isUse = (WebrtcComponent as any).Naked?.isUseTurnServer;
            return isUse !== undefined ? isUse : null;
        },
        setCheckMediaDevices: (value: boolean) => {
            setCheckMediaDevices(value);
        },
        handleClickMoreNote: (iswhiteBoard: boolean) => {
            // FloorMenu -> Floor というルートでmoreNOTE起動する
            // FloorはrefからhandleClickMoreNoteを呼ぶ
            console.log("handleClickMoreNote");
            handleClickMoreNote(iswhiteBoard);
        },
        setShowSetMyAvatar: () => {
            setShowSetMyAvatarDialog(!showSetMyAvatarDialog);
        },
        openedHello: (json: any) => {
            moreNoteRef?.current.openedHello(json);
        },
        onChangeScreenShare: (isScreenShare: boolean) => {
            onChangeScreenShare(isScreenShare);
        },
        clearStatusTimer: () => {
            // 管理画面に遷移したとき、ステータスタイマーをストップする
            // stopTimer()を呼ぶのはステータスを設定していた時のみ
            if(user.state !== 0){
                statusTimerRef.current.stopTimer();
            }
        },
        handleSelfStudyTableVideo: (open: boolean) => {
            setSelfStudyTableVideo(open);
        },
        handleTimerStart: (startTime: number, endTime: number) => {
            handleTimerStart(startTime,endTime);
        },
    }))

    /**
     * moreNote Hello 確認ダイアログオープン.
     * @param url 
     * @param isHost 
     * @param title 
     * @param text 
     */
    const openMoreNoteConfirm = (url: string, isHost: boolean, title: string, text: string) => {
        setMoreNoteConfirmUrl(url);
        setMoreNoteConfirmHost(isHost);
        setMoreNoteConfirmTitle(title);
        setMoreNoteConfirmText(text);
        setMoreNoteConfirm(true);
    }

    /**
     * PC 押下イベント
     */
    const handleClickPc = () => {
        setOpenMenu(false);
        setOpenPcMenu(!openPcMenu);
    }

    /**
     * ユーザ状態変更イベント.
     *
     * @param state 
     * @param enableMeet 
     */
    const handleStateChange = (state: number, enableMeet: boolean) => {
        setOpenMenu(false);
        props.onStateChange(state, enableMeet);
        // state.userと比較 変わるかつステータスが0以外の時はタイマーを設定
        if(state !== user.state && state !== 0){
            statusTimerRef.current.setTimer(state);
        } else{
            statusTimerRef.current.stopTimer();
        }
    }
    const handlePictograph = (state: number) =>{
        setOpenMenu(false);
        props.onPictograph(state);
    }
    /**
     * ユーザ状態クリックイベント
     */
    const handleClickUserState = () => {
        // ユーザ状態を初期化状態へ遷移
        handleStateChange(UserState.None, false);
    }

    /**
     * つぶやくイベント.
     *
     * @param text 
     */
    const hanldeTubuyakiChange = (text: string) => {
        setOpenMenu(false);
        props.onTubuyakiChange(text);
    }

    /**
     * つぶやきクリックイベント.
     */
    const hanldeClickTubuyaki = () => {
        hanldeTubuyakiChange("");
    }

    /**
     * moreNoteクリックイベント.
     */
    const handleClickMoreNote = (iswhiteBoard: boolean) => {
        console.log("handleClickMoreNote : isWhiteBoard ", iswhiteBoard);
        setOpenMenu(false);
        setOpenPcMenu(false);
        setMoreNoteWhiteBoard(iswhiteBoard);

        

        setMoreNote(true);
    }

    /**
     * 自席へ移動イベント
     */
    const handleJumpToMySeat = () => {
        setOpenMenu(false);
        props.onJumpToMySeat();
    }

    /**
     * フロア移動イベント
     * @param id フロアID (-1 : 移動なし)
     */
    const handleMoveFloor = (id: number) => {
        setOpenMenu(false);
        if (id !== -1) {
            props.onMoveFloor(id);
        }
    }

    /**
     * 自アバター設定イベント
     */
    const handleMyAvatarChange = (yes: boolean, value?: number) => {
        setOpenMenu(false);
        props.onMyAvatarChange(yes, value);
    }

    /**
     * 自分の「ビデオ通話前の確認」設定変更イベント
     */
     const handleMyConfirmSettingChange = (value?: number) => {
        setOpenMenu(false);
        props.onMyConfirmSettingChange(value);
    }

    /**
     * 自分の「ログイン通知」設定変更イベント
     */
     const handleMyChangeLoginoutNotification = (value1?: number, value2?: number) => {
        setOpenMenu(false);
        props.onMyLoginoutNotificationChange(value1, value2);
    }
    
    /**
     * 自席設定イベント
     */
    const handleMyDeskChange = (yes: boolean) => {
        setOpenMenu(false);
        props.onMySeatChange(yes);
    }

    /**
     * 自席解除イベント
     */
    const handleMyDeskRelease = (yes: boolean) => {
        setOpenMenu(false);
        props.onMySeatRelease(yes);
    }

    /**
     * 席解放イベント
     */
    const handleOtherDeskRelease = (yes: boolean) => {
        setOpenMenu(false);
        props.onOtherSeatRelease(yes);
    }

    /**
     * 状態表示カードopen時イベント
     */
    const handleClickUserInfo = () => {
        setOpenMenu(false);
        setOpenProfile(!openProfile);
    }

    /**
     * 出勤と退勤の切り替え処理
     * @param isWork 
     */
    const handleChangeWork = (isWork: boolean) => {
        setOpenMenu(false);
        props.onChangeMyCommuting(isWork);
    }

    /**
     * 画面共有
     * 
     * @param isScreenShare true: 画面共有開始 false: 画面共有停止 
     */
    const onChangeScreenShare = (isScreenShare: boolean) => {
        setOpenMenu(false);
        props.handleShareScreenOpen(isScreenShare);
        props.handleScreenShare(isScreenShare, ScreenShareMode.FloatingElement, user.webRtcRoomId);
    }

    /**
     * 全体放送開閉
     */
    const onChangeBroadcast = (isBroadcast: boolean, userId: number) => {
        setOpenMenu(false);
        props.hanldleBroadcast(isBroadcast, userId);
    }

    /**
     * 一斉ミュート処理
     */
    const handleForceMute = (isForceMute: boolean) => {
        setOpenMenu(false);
        props.onForceMute(isForceMute);
    }

    // 拍手
    const hanldeClapping = () => {
        setOpenMenu(false);
        props.onClapping();
    }

    // 探す
    const handleOpenUserSearch = () => {
        setOpenMenu(false);
        props.onOpenUserSearch(true);
    }

    // ユーザーコントロールUIを開く
    const handleOpenUsersControl = () => {
        setOpenMenu(false);
        props.onOpenUsersControl(true);
    }

    // 名刺公開設定
    const handleCardDisclose = (isCardDisclose: boolean) => {
        setOpenMenu(false);
        props.onCardDisclose(isCardDisclose);
    }

    const handleMyStreamLoaded = (id: number) => {
        // タイマーによるwaiting解除に変更したため、親で処理は行っていない。
        props.onMyStreamLoaded(id);
    }

    const handleRemoteStreamLoading = (id: number) => {
        props.onRemoteStreamLoading(id);
    }

    // joinRoom の状態を通知
    const handleUpdateJoinRoomStatus = (sid: string, pid: string, event: string, data: string) => {
        props.onUpdateJoinRoomStatus(sid, pid, event, data);

        // WebRTC通話開始後にHelloを起動するための対応
        // WebRTCの通話から抜けた場合、Helloを表示しているインラインフレームは閉じるためここで保持しているURLをクリアしている
        if ("close" === event) {
            setMoreNoteGuestUrl("");
        }
    }

    const handleMoreNoteLogin = (url: string) => {
        setMoreNote(false);
        if (url !== "") {
            if (url.indexOf("pdfviewer") >= 0) {
                console.log("openMoreNoteWindow:" + url);
                props.onStartMoteNoteInlineFrame(url);
            } else if (url.indexOf("userName=") >= 0) {
                const split = url.split("=");
                console.log("同時起動したユーザー : " + split[1]);
                showMessage("通知", split[1] + "さんが起動したルームに参加します。", "", "", false);
            } else {
                // ログイン処理中に別のユーザーがすでに起動済みのHelloがあった場合、そのユーザーのHelloに参加する
                requestHelloInvitee(url);
            }
        } else {
            // 同時起動の場合ここに到達し、Hello起動後のルーム参加処理が実行され、他のユーザーが起動したHelloに参加する
            console.log("handleMoreNoteLogin: urlの設定がなかった");
        }
    }

    /**
     * moreNote Hello ログインエラー.
     */
    const handleMoreNoteError = () => {
        setMoreNote(false);
    }

    /**
     * 既存のHelloルームに参加するためのリクエストを行う
     * 
     * @param roomId 
     */
    const requestHelloInvitee = (roomId: string) => {

        console.log(props.floorData.webSocketUrl);
        moreNoteRef?.current.requestHelloInvitee(roomId, state.user.loginId, props.floorData.webSocketUrl);
    }

    const handleRemoteStreamLoaded = (id: number, dev: number) => {
        // タイマーによるwaiting解除に変更したため、親で処理は行っていない。
        props.onRemoteStreamLoaded(id, dev);
    }

    /**
     * WebRTCの切断
     */
    const handleDisconnectWebRtc = (isContinue: boolean) => {
        props.handleContinueWebRtcResult(isContinue);
    }

    /**
     * ビデオ通話前の確認
     */
     const handleStartConfirmResult = (result: number) => {
        props.handleStartConfirmResult(result);
    }
    
    /**
     * iPad対応-12 外部ビデオ通話連携前の確認結果
     */
     const handleVideoURLConfirmResult = (result: number, url: string) => {
        // iPad（iOS）しか確認ダイアログを開かないので、ここにくるのはiPad（iOS）だけのはず
        if(result === 1) {
            props.onReceiveVideoURLText(url);
        }
    }

    /**
     * StudyTimer.tsxが提供するstartCountを外部で使わせるために実装
     * StudyTimer.tsxのcount(第一引数に),timeEnd(第二引数に),isTimerEnd(falseに),timerFlag(trueに)変更するだけ
     * ※state.userのタイマーに関する値は変更しない
     * ※なのでMyUser.tsxにあるタイマー描画には基本影響を与えない
     * ※フロントで管理されるタイマーに影響するので秒単位
     * 
     * @param startTime タイマー開始時間(秒単位)
     * @param endTime タイマー終了時間(秒単位)
     */
    const handleTimerStart = (startTime:number, endTime: number) => {
        startCount(startTime,endTime);
        console.info("MyUser.tsx タイマー開始時に走る。");
    }
    
    /**
     * PCの描画
     *  ・iPad Safariでのタップ長押しで画像が選択されないようにするため、
     *    imgをdivで囲っている
     */
    const drawPc = () => {
        // if (user.isSitOnDesk && user.isDrawPc && user.myPc?.picturePath) {
        if (user.isSitOnDesk && user.isDrawPc) {
            const divDrawPc = document.getElementById("drawPcId-" + user.id);
            if (divDrawPc) {
                if (Number.parseFloat(divDrawPc.style.top) === (user.seat.y + user.seat.deskOffsetTop - (user.myPc.height / 2))
                    && Number.parseFloat(divDrawPc.style.left) === (user.seat.x + user.seat.deskOffsetLeft - (user.myPc.width / 2))
                ) {
                    // PCの表示座標が変更されていない場合には、何もしない
                } else {
                    // CSSアニメーションを再度実行するため、一定時間経過後に再設定している
                    const animation = divDrawPc.style.animation;
                    divDrawPc.style.animation = "";
                    setTimeout(() => {divDrawPc.style.animation = animation}, 100);
                }
            }
        }

        // return (user.isSitOnDesk && user.isDrawPc && user.myPc?.picturePath) &&
        return (user.isSitOnDesk && user.isDrawPc) &&
        <div style={{...SELECT_NONE}}>
            <div id={"drawPcId-" + user.id}
                className={NEED_TO_POINTER_CAPTURE}
                onMouseDown={e => e.preventDefault()} // window外へのgif画像コピー禁止
                onMouseMove={e => e.preventDefault()} // window外へのgif画像コピー禁止
                onClick={() => handleClickPc()}
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    top: user.seat.y + user.seat.deskOffsetTop - (user.myPc.height / 2),
                    left: user.seat.x + user.seat.deskOffsetLeft - (user.myPc.width / 2),
                    width: user.myPc.width,
                    height: user.myPc.height,
                    zIndex: ZIndex.seatObject, // 机上の名札と同じZIndex
                    // backgroundImage: `url(${apiBase}/object/picture/${user.myPc.id}?angle=${user.seat.deskAngle})`,
                    backgroundImage: `url(${httpClient.createObjectImgUrl(user.myPc.id, user.seat.deskAngle)})`,
                    backgroundSize: `${user.myPc.width}px ${(user.myPc.frames === 0 ? user.myPc.height : user.myPc.height * user.myPc.frames)}px`,
                    backgroundRepeat: "no-repeat",
                    animation: `openPc-${user.myPc.height}px-${user.myPc.frames}f ${1 / user.myPc.fps}s steps(${user.myPc.frames - 1}) forwards`,
                    // cursor: `${(user.myPc?.picturePath ? 'pointer' : 'default')}`
                    cursor: "pointer"
                }}
            />
        </div>
    }

    /**
     * 画面共有中表示要素の描画
     */
    const drawShare = () => {
        return (user.isScreenShare && user.webRtcCall &&
            <div
                style={{
                    ...SELECT_NONE,
                    ...webRtcAreaStyle,
                    position: "absolute",
                    top: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize - avatarWidth) : 0,
                    left: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize / 2 - avatarWidth / 2) : 0,
                    width: webRtcAreaWrapperSize,
                    height: webRtcAreaWrapperSize,
                    backgroundColor: `rgba(19, 146, 133, ${user.isLargeVideo ? 1.0 : 0.7})`,
                    color: "rgb(255,255,255)",
                    fontSize: user.isLargeVideo ? "20px" : "12px",
                    zIndex: zIndex + 4,
                }}
            >
                {user.isLargeVideo ? "画面共有中" : "共有中"}
        </div>)
    }

    const drawIsCommuting = () => {
        if(!user.isCommuting && purposeOfUse === PURPOSE_OF_USE_OFFICE){
            let isNotCommuting = "勤務外";
            let fsize = "10px"

            return (
                <div style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    fontSize: fsize,
                    zIndex: zIndex + 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                    color: 'rgb(0,0,0)',
                    top:'8px',
                    left: '10%',
                    width: "80%",
                    paddingTop: 5,
                    borderRadius: '20%',
                }}>{isNotCommuting}</div>
            )
        }
    }

    const drawZoomSpeaking = () => {
        return (user.isZoomSpeakable &&
            <div
                className={NEED_TO_POINTER_CAPTURE}
                style={{
                    ...SELECT_NONE,
                    ...webRtcAreaStyle,
                    position: "absolute",
                    top: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize - avatarWidth) : 0,
                    left: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize / 2 - avatarWidth / 2) : 0,
                    width: webRtcAreaWrapperSize,
                    height: webRtcAreaWrapperSize,
                    backgroundColor: `rgba(19, 146, 133, ${user.isLargeVideo ? 1.0 : 0.7})`,
                    color: "rgb(255,255,255)",
                    fontSize: user.isLargeVideo ? "20px" : "12px",
                    zIndex: zIndex + 4,
                }}
            >
                {user.isLargeVideo ? "Zoom登壇中" : "登壇中"}
            </div>)
    }

    const draw = useMemo(() =>
        <Fragment>
            <div onClick={() => console.log("myUser:", state.user)} id="myUser" style={{
                ...avatarImageStyle,
                position: "absolute",
                top: avatarTop,
                left: avatarLeft,
                width: avatarWidth,
                height: avatarHeight,
                zIndex: zIndex
            }}>
                {!user.isMediaWaiting && !user.webRtcCall &&
                    <div id={`avator-image-${user.id}`}
                        style={{
                            ...avatarImageStyle,
                            // backgroundImage: `url("${apiBase}/avatar/picture/${user.avatarId}")`,
                            backgroundImage: `url(${httpClient.createAvatarImgUrl(user.avatarId)})`,
                            opacity: user.isGhost ? 0.3 : 1,
                            zIndex: zIndex
                        }}
                    />
                }
                {user.isMediaWaiting && user.webRtcCall &&
                    <div id={`avator-waiting-image-${user.id}`}
                        style={{
                            ...avatarImageStyle,
                            // backgroundImage: `url("${apiBase}/avatar/waitingpicture/${user.avatarId}")`,
                            backgroundImage: `url(${httpClient.createAvatarWaitingImgUrl(user.avatarId)})`,
                            opacity: user.isGhost ? 0.3 : 1,
                            zIndex: zIndex + 1
                        }}
                    />}
                <HitPersonEffect 
                    effectId={hitEffectId}
                    width={avatarWidth}
                    style={{ 
                        ...SELECT_NONE,
                        zIndex: zIndex + 2 
                    }}
                />
                {isLarge &&
                    <div id={`avator-large-image-${user.id}`}
                        style={{
                            ...avatarImageStyle,
                            // backgroundImage: `url("${apiBase}/avatar/largepicture/${user.avatarId}")`,
                            backgroundImage: `url(${httpClient.createAvatarLargeImgUrl(user.avatarId)})`,
                            opacity: user.isGhost ? 0.3 : 1,
                            zIndex: zIndex + 3
                        }}
                    />}
                <div id={`webrtc-area-wrapper-${user.id}`}
                    style={{
                        ...webRtcAreaStyle,
                        position: "absolute",
                        top: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize - avatarWidth) : 0,
                        left: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize / 2 - avatarWidth / 2) : 0,
                        width: webRtcAreaWrapperSize,
                        height: webRtcAreaWrapperSize,
                        visibility: (isLarge && !user.isMediaWaiting && !user.isNoVideo && !user.isScreenShare) ? "visible" : "hidden",
                        zIndex: zIndex + 4
                    }}
                >
                    <div id={`webrtc-area-${user.id}`}
                        style={{
                            ...webRtcAreaStyle,
                            width: webRtcAreaWrapperSize * INNER_RATIO,
                            height: webRtcAreaWrapperSize * INNER_RATIO,
                            WebkitMaskImage: "-webkit-radial-gradient(circle, white 100%, black 100%)",
                            background: (isLarge && !user.isMediaWaiting && !user.isNoVideo) ? "black" : "none",
                            zIndex: zIndex + 5
                        }}
                        data-islargevideo={user.isLargeVideo}
                    >
                        {/** ココにWebrtcComponent.tsxからvideo, audio要素が挿入される */}
                    </div>
                </div>
                {drawShare()}
                {drawZoomSpeaking()}
                {/* MyUserを示すマーカーの実験 */}
                {/* {(tempAnm===1) && <Arrow fill={"red"} style={{pointerEvents:"none", position: "absolute", width: avatarWidth-15, top: -20, left: 7.5, zIndex: zIndex-1}} />}
                {(tempAnm===2) && <AnimBar animate={true} style={{pointerEvents:"none", position: "absolute", width: avatarWidth, top: avatarHeight+8, zIndex: zIndex-1}}/>}
                {(tempAnm===3) && <AnimEllipse animate={true} style={{pointerEvents:"none", position: "absolute", width: avatarWidth+40, left: -20, top: avatarHeight-10, zIndex: zIndex-1}}/>}
                {(tempAnm===4) && <Ellipse style={{pointerEvents:"none", position: "absolute", width: avatarWidth+40, left: -20, top: avatarHeight-10, zIndex: zIndex-1}}/>} */}
            </div>
            {drawPc()}
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user.x, user.y, user.isSitOnDesk, user.avatarId, user.webRtcCall,
            user.isMediaWaiting, isLarge, avatarWidth, webRtcAreaWrapperSize,
            user.isNoVideo, user.isLargeVideo, user.isVideoMute, user.isAudioMute,
            openPcMenu, hitEffectId, user.isScreenShare, user.isGhost,
            user.isCommuting, purposeOfUse, user.isZoomSpeakable])

    const drawMenu = useMemo(() => {
        if (!openMenu) return;

        return <UserMenu
            top={user.y}
            // アバターの中心を基準とする
            left={avatarLeft + (avatarWidth / 2)}
            tubuyaki={user.tubuyaki}
            user={state.user}
            avatarMenuDataList={state.avartarMenuDataList}
            floorId={floorId}
            onStateChange={handleStateChange}
            onTubuyakiChange={hanldeTubuyakiChange}
            onClickMoreNote={handleClickMoreNote}
            onJumpToMySeat={handleJumpToMySeat}
            onMoveFloor={handleMoveFloor}
            onMyAvatarChange={handleMyAvatarChange}
            onMySeatChange={handleMyDeskChange}
            onMySeatRelease={handleMyDeskRelease}
            onOtherSeatRelease={handleOtherDeskRelease}
            onClickUserInfo={handleClickUserInfo}
            onChangeWork={handleChangeWork}
            onChangeScreenShare={onChangeScreenShare}
            onChangeWebRtcAllUsers={onChangeBroadcast}
            onForceMute={handleForceMute}
            onClapping={hanldeClapping}
            onClickSearch={handleOpenUserSearch}
            onCardDisclose={handleCardDisclose}
            zIndex={zIndex + 6}
            isLarge={isLarge}
            avatarDatas={props.avatarDatas}
            scale={props.scale}
            onMyConfirmSettingChange={handleMyConfirmSettingChange}
            onChangeLoginoutNotification={handleMyChangeLoginoutNotification}
            purposeOfUse={props.floorData.purposeOfUse}
            floorStartConfirmSetting={props.floorData.enabledWrStartConfirmSetting}
            onPictograph={handlePictograph}
            onOpenUsersControl={handleOpenUsersControl}
            getFloorObjectFromId={props.getFloorObjectFromId}
            enabledTubuyaki={props.floorData.enabledTubuyaki}
            enabledMeet={props.floorData.enabledMeet}
            enabledBusinessCard={props.floorData.enabledBusinessCard}
            enabledChat={props.floorData.enabledMemoButton}
            floorData={props.floorData}
            namePlateColors={props.namePlateColors}
            onChangeNamePlate={props.onChangeNamePlate}
            requestPrivacyRoom={props.requestPrivacyRoom}
            sendPrivacyRoomTimeOut={props.sendPrivacyRoomTimeOut}
            sendMovePrivacyRoomInfo={props.sendMovePrivacyRoomInfo}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user.x, state.user.y, state.avartarMenuDataList, openMenu, state.user.isScreenShare, state.user.isBroadcast, state.user.isLargeVideo, state.user.isBroadcastSender, isLarge, avatarWidth, avatarLeft]);

    const drawPcMenu = useMemo(() => {
        if (!openPcMenu) return;

        if (!user.isSitOnDesk) {
            setOpenPcMenu(false);
            return;
        }

        return <PcMenu
            open={openPcMenu}
            // PC の座標、幅、高さを基準にする
            top={user.seat.y + user.seat.deskOffsetTop - (user.myPc.height / 2)}
            left={user.seat.x + user.seat.deskOffsetLeft - (user.myPc.width / 2)}
            pcHeight={user.myPc.height}
            pcWidth={user.myPc.width}
            onClickMoreNote={handleClickMoreNote}
            zIndex={zIndex}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user.x, state.user.y, user.isSitOnDesk, openPcMenu])

    /**
     * コマンドの変更があった時、コマンド用のダイアログを表示する処理
     */
    useEffect(() => {
        if (state.user.command !== undefined && state.user.command !== "") {
            setOpenCommandDialog(true);
        }
    }, [state.user.command])

    /**
     * コマンド用のダイアログを閉じる
     */
    const handleCloseCommandDialog = (isYes: boolean) => {
        setOpenCommandDialog(false);
        props.handleSendCommand(isYes);
    }

    /**
     * コマンド用ダイアログの表示
     */
    const drawCommandDialog = useMemo(() => {
        return (
            <BaseDialog
                id="commandDialog"
                open={openCommandDialog}
                onClose={() => handleCloseCommandDialog(false)}
                aria-labelledby="commandDialogTitle"
                maxWidth={"sm"}
                fullWidth={true}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="commandDialogTitle" style={{padding: '7px 25px 9px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>{"フロア移動"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {state.user.cMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseCommandDialog(true)} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }}>OK</Button>
                    <Button onClick={() => handleCloseCommandDialog(false)} color='primary' style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 100 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openCommandDialog])

    /**
     * 通知メッセージの変更があった時、メッセージダイアログを表示する処理
     */
    // iPad対応-27 空のメッセージが表示される
    // --> 表示のタイミングで、state.user.notifyTitleとnotifyMessageが空に変更されているようなので、表示用のデータを新設
    // useStateをまとめる(12/22 レビュー指摘)
    //const [msgNotifyTitle, setMsgNotifyTitle] = useState("");
    //const [msgNotifyMessage, setMsgNotifyMessage] = useState("");
    const [msgNotifyData, setMsgNotifyData] = React.useState({
        title: '',
        message: '',
    });
    useEffect(() => {
        if ((state.user.notifyTitle !== undefined && state.user.notifyTitle !== "") &&
            (state.user.notifyMessage !== undefined && state.user.notifyMessage !== "")) {
            console.log("SELECT_DEV_TRACE setOpenMesssageDialog ["+state.user.notifyTitle+"]["+state.user.notifyMessage+"]")
            // iPad対応-27 空のメッセージが表示される対応で、表示用データに設定する処理追加
            logger.info("SELECT_DEV_TRACE PLAY_SOUND_TRACE setOpenMesssageDialog ["+state.user.notifyTitle+"]["+state.user.notifyMessage+"]");
            //setMsgNotifyTitle(state.user.notifyTitle);
            //setMsgNotifyMessage(state.user.notifyMessage);
            const msgData = {
                title: state.user.notifyTitle,
                message: state.user.notifyMessage,
            }
            setMsgNotifyData(msgData);
            setOpenMesssageDialog(true);
        }
    }, [state.user.notifyTitle, state.user.notifyMessage])

    /**
     * メッセージ用のダイアログを閉じる
     */
    const handleCloseMessageDialog = (isYes: boolean) => {
        // if (state.user.notifyTitle.indexOf("デバイスが見つかりませんでした") >= 0) {
        //     WebrtcService.playAudioAllWebRtc();
        // }
        WebrtcService.playAudioAllWebRtc();
        setOpenMesssageDialog(false);
        if (state.user.notifyRedirectUrl !== "") {
            setRedirect(true);
            setRedirectUrl(state.user.notifyRedirectUrl);
        //} else if (state.user.dispSignout === true && isYes === true) {
        //    setRedirect(true);
        //    const buf: any = sessionStorage.getItem("SIGNINPAGE");
        //    const signinpage = (buf !== null) ? buf : '/';
        //    setRedirectUrl(signinpage);
        }

        // ダイアログを再度、開けるように呼び出し元側で、notifyTitle, notifyMessage に空を設定している
        // 本クラスのshowMessageで設定すると、userデータが古いデータで上書きされてしまったため。
        props.handleCloseMessageDialog();        
    }
    const handleCloseMessageDialogSignout = (isYes: boolean) => {
        WebrtcService.playAudioAllWebRtc();
        setOpenMesssageDialog(false);
        if (state.user.dispSignout === true && isYes === true) {
            setRedirect(true);
            const signinpage = Utility.getSigninPage();
            setRedirectUrl(signinpage);
        }

        // ダイアログを再度、開けるように呼び出し元側で、notifyTitle, notifyMessage に空を設定している
        // 本クラスのshowMessageで設定すると、userデータが古いデータで上書きされてしまったため。
        props.handleCloseMessageDialog();        
    }
    const handleCloseMessageDialogSelFloor = (isYes: boolean) => {
        // if (state.user.notifyTitle.indexOf("デバイスが見つかりませんでした") >= 0) {
        //     WebrtcService.playAudioAllWebRtc();
        // }
        WebrtcService.playAudioAllWebRtc();
        setOpenMesssageDialog(false);
        setRedirect(true);
        const topage = '/top';
        setRedirectUrl(topage);

        // ダイアログを再度、開けるように呼び出し元側で、notifyTitle, notifyMessage に空を設定している
        // 本クラスのshowMessageで設定すると、userデータが古いデータで上書きされてしまったため。
        props.handleCloseMessageDialog();        
    }

    /**
     * 指定されたタイトル、本文でメッセージダイアログを表示する
     * @param title 
     * @param message 
     */
    const showMessage = (title: string, message: string, redirectUrl: string, redirectCaption: string, dispSignout: boolean) => {
        const tempUser = User.copyUser(user);
        console.log("SELECT_DEV_TRACE showMessage ["+title+"]["+message+"]["+redirectUrl+"]["+redirectCaption+"]")
        tempUser.notifyTitle = title;
        tempUser.notifyMessage = message;
        tempUser.notifyRedirectUrl = redirectUrl;
        tempUser.notifiRedirectCaption = redirectCaption;
        tempUser.dispSignout = dispSignout;
        
        setState({
            ...state,
            user: tempUser,
        });
    }

    /**
     * メッセージダイアログの表示
     */
    const drawMesssageDialog = useMemo(() => {
        return (
            <BaseDialog
                id="messageDialog"
                open={openMesssageDialog}
                onClose={() => handleCloseMessageDialog(false)}
                aria-labelledby="messageDialogTitle"
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                {/* iPad対応-27 空のメッセージが表示される対応でメッセージデータの参照先を変更
                    state.user.notifyTitle を msgNotifyTitle、state.user.notifyMessage を msgNotifyMessage */}
                <DialogTitle id="messageDialogTitle" style={{padding: '7px 25px 9px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>{msgNotifyData.title}</DialogTitle>
                <DialogContent>
                {msgNotifyData.message.split("\n").map((text, index) => {
                    return <div key={index}>{text}</div>
                })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseMessageDialog(false)} color='primary' style={{ pointerEvents: 'auto', color: '#006FBC' }}>
                        {state.user.notifyRedirectUrl === "" ? "閉じる" : state.user.notifiRedirectCaption}
                    </Button>
                    {/* ちゃんとした仕様にすべく、再検討。一旦やめる。（2021/12/6）
                    {state.user.notifiRedirectCaption !== "フロア選択" ?
                        <Button onClick={() => handleCloseMessageDialogSelFloor(false)} color='primary' style={{ pointerEvents: 'auto' }}>
                            フロア選択
                        </Button>
                    :
                        <React.Fragment />
                    }
                    */}
                    {state.user.dispSignout ?
                        <Button onClick={() => handleCloseMessageDialogSignout(true)} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }}>
                            ログアウト
                        </Button>
                    :
                        <React.Fragment />
                    }
                </DialogActions>
            </BaseDialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMesssageDialog])

    const redirectComponents = useMemo(() => {
        if (state.user) {
            return (
                <React.Fragment>
                    {redirect ?
                        <Redirect to={redirectUrl} />
                        :
                        <React.Fragment />
                    }
                </React.Fragment>
            );
        }
    }, [redirect])

    
    const openReOpenHello = (url: string) => {
        console.log('openReOpenHello url:' + url);
        requestHelloInvitee(url);
    }

    const drawMoreNote = useMemo(() => {
        return (
            <Fragment>
                <MoreNote ref={moreNoteRef} open={moreNote} account={state.user.displayName} isWhiteBoard={moreNoteWhiteBoard} user={state.user} floorData={props.floorData} onLogin={handleMoreNoteLogin} 
                    onError={handleMoreNoteError} handleCheckOpenedHello={props.handleCheckOpenedHello} setMoreNoteGuestUrl={openReOpenHello} reConnectHello={props.floorData?.reConnectHello} />
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moreNote]);

    useEffect(() => {
        if (state.user.isWebRtcContinue) {
            checkContinueRef.current.open();
        } else {
            checkContinueRef.current.close();
        }
    }, [state.user.isWebRtcContinue]);

    // ビデオ通話前の確認ダイアログの表示・クローズ
    const [preChecTM, setPreChecTM] = useState(state.user.wrStartConfirmStatusTM);
    useEffect(() => {
        let ntm = new Date();
        if((preChecTM+100) < ntm.getTime()) {
            if (state.user.wrStartConfirmStatus === 1) {
                console.log("test_preTM : open name=["+state.user.displayName+"] pre=["+preChecTM+"] now=["+ntm.getTime()+"] diff=["+(ntm.getTime()-preChecTM)+"]")
                startConfirmRef.current.open();
            } else if(state.user.wrStartConfirmStatus === 999) {
                console.log("test_preTM : close name=["+state.user.displayName+"] pre=["+preChecTM+"] now=["+ntm.getTime()+"] diff=["+(ntm.getTime()-preChecTM)+"]")
                startConfirmRef.current.close();
            }
        }
        else {
            console.log("test_preTM : skip  name=["+state.user.displayName+"] pre=["+preChecTM+"] now=["+ntm.getTime()+"]")
        }
        setPreChecTM(ntm.getTime());
        console.log("test_preTM : set  pre=["+preChecTM+"] now=["+ntm.getTime()+"]")
    }, [state.user.wrStartConfirmStatus]);


    const handleClickSetMyAvatar = (yes: boolean, value?: number) => {
        setShowSetMyAvatarDialog(false);
        handleMyAvatarChange(yes, value);
    }

    // ビデオ通話前の確認の設定変更ハンドラ
    const handleChangeSetting = (value?: number) => {
        setShowSetMyAvatarDialog(false);
        handleMyConfirmSettingChange(value);
    }

    // ログイン通知の設定変更ハンドラ
    const handleChangeLoginoutNotification = (value1?: number, value2?: number) => {
        setShowSetMyAvatarDialog(false);
        handleMyChangeLoginoutNotification(value1, value2);
    }

    // 設定がSideMenuから行う仕様になったため、SetMyAvatarの実体をここに持つ
    const drawSetMyAvatarDialog = useMemo(() => {
        if(state.user.avatarId === 0){  // 0のときはまだuserが設定されてない
            return(<div></div>);
        }
        
        // ビデオ通話前の確認の設定用
        let myStartConfirmSetting: number = user.avatarConfirmSetting;
        if(props.floorData?.enabledWrStartConfirmSetting === 10) {
            myStartConfirmSetting = 0;
        } else if(props.floorData?.enabledWrStartConfirmSetting === 11) {
            myStartConfirmSetting = 1;
        }

        let myLoginoutNotificationSetting = user.avatarLoginoutNotification;
        let myLoginoutNotificationSettingSound = user.avatarLoginoutNotificationSound;
        // console.log("MyUser.tsx aaaaaaaaaaaaaaaaaa");
        return (
            <SetMyAvatar open={showSetMyAvatarDialog} onClick={handleClickSetMyAvatar} avatarId={user.avatarId} avatarDatas={props.avatarDatas}
                onChangeSetting={handleChangeSetting}
                onChangeLoginoutNotification={handleChangeLoginoutNotification}
                purposeOfUse={props.floorData?.purposeOfUse}
                floorStartConfirmSetting={props.floorData?.enabledWrStartConfirmSetting}
                myStartConfirmSetting={myStartConfirmSetting}
                myLoginoutNotificationSetting={myLoginoutNotificationSetting}
                myLoginoutNotificationSoundSetting={myLoginoutNotificationSettingSound}
                user={state.user}
                avatarMenuDataList={state.avartarMenuDataList}
                floorData={props.floorData}
                namePlateColors={props.namePlateColors}
                onChangeNamePlate={props.onChangeNamePlate}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSetMyAvatarDialog, user.avatarId, user.avatarConfirmSetting, props.floorData?.enabledWrStartConfirmSetting, props.avatarDatas])

    const handleResetIsChangeVideo = (flag: boolean) => {
        props.resetIsChangeVideo(flag);
    }

    const drawYouTube = useMemo(() => {
        return <YouTube isSitOnTable={state.user.seat?.isSit} videoId={props.youtubeProps.videoId} width={props.youtubeProps.width} height={props.youtubeProps.height} left={props.youtubeProps.left} top={props.youtubeProps.top} zIndex={ZIndex.floorObject} isChangeVideo={props.youtubeProps.isChangeVideo} resetIsChangeVideo={handleResetIsChangeVideo}/>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user.seat?.isSit, props.youtubeProps.videoId, props.youtubeProps.width, props.youtubeProps.height, props.youtubeProps.left, props.youtubeProps.top, props.youtubeProps.isChangeVideo])

    return (
        <Fragment>
            {drawMenu}
            {/* {drawPcMenu} */}
            <BaseUser
                avatarMenuDataList={state.avartarMenuDataList}
                user={state.user}
                openProfile={openProfile}
                onClickUserState={handleClickUserState}
                onClickTubuyaki={hanldeClickTubuyaki}
                onClickProfile={() => setOpenProfile(false)}
                zIndex={ZIndex.myUser + 5}
                isLarge={isLarge}
                isMyUser={true}
                openSelectMemoGroup={false}
                onClickSelectMemoGroup={() => { }}
                handleOpenMemo={(id: number) => { }}
                enabledBusinessCard={props.getEnabledBusinessCard()}
                kind={props.kind}
                openSelectChatGroup={false}
                onClickSelectChatGroup={() => { }}
                handleOpenChat={(id: number) => { }}
                floorSize={{width:props.floorData?.floorWidth, height:props.floorData?.floorHeight}}
            />
            {draw}
            {drawYouTube}
            <WebrtcComponent
                roomId={user.webRtcRoomId}
                sessionId={'' + user.id}
                isCall={user.webRtcCall}
                isScreenShare={user.isScreenShare}
                isScreenSharing={user.isScreenSharing}
                isShow={true}
                isRetryCall={user.webRtcRetryCall}
                //debugSend={user.debugSend}     // for Debug (webrtc retry : skyway close 対応)
                videoMute={user.isVideoMute}
                audioMute={user.isAudioMute}
                screenScale={1}
                openDeviceSelect={openDeviceSelect}
                sendUserMicLevel={props.sendUserMicLevel}
                sendWebRtcInfo={props.sendWebRtcInfo}
                setVisibleScreenShareButton={props.setVisibleScreenShareButton}
                handleScreenShare={props.handleScreenShare}
                analyzeMicMode={user.micMode}
                webRtcMode={user.webRtcMode}
                webRtcInfoInterval={user.webRtcInfoInterval}
                isNoVideo={user.isNoVideo}
                isLargeVideo={user.isLargeVideo}
                onMyStreamLoaded={handleMyStreamLoaded}
                onRemoteStreamLoading={handleRemoteStreamLoading}
                onRemoteStreamLoaded={handleRemoteStreamLoaded}
                onUpdateJoinRoomStatus={handleUpdateJoinRoomStatus}     // joinRoom の状態を通知
                showMessage={showMessage}
                screenShareSessionId={user.screenShareSessionId}
                screenShareMode={user.screenShareMode}
                screenShareFps={props.floorData?.screenShareFps}
                screenShareResolutionWidth={props.floorData?.screenShareResolutionWidth}
                screenShareResolutionHeight={props.floorData?.screenShareResolutionHeight}
                onScreenShareVideoLoaded={props.onScreenShareVideoLoaded}
                onShareScreenOpen={props.handleShareScreenOpen}
                checkMediaDevices={checkMediaDevices}
                requireCamera={props.floorData ? props.floorData.requireCamera : false}
                requireMic={props.floorData ? props.floorData.requireMic : false}
                sendSetGhost={props.sendSetGhost}
                sendDeviceSelectedType={props.sendDeviceSelectedType}
                displayErrorSnackBar={props.displayErrorSnackBar}   // #369
                onDeviceSelected={props.onDeviceSelected}   // カメラ未選択時の表示対応
                usermanualURL={props.usermanualURL}   // マニュアルURLをDBから取得
            />
            {drawCommandDialog}
            {drawMesssageDialog}
            {drawMoreNote}
            {redirectComponents}
            <CheckContinueWebRtcDialog ref={checkContinueRef} continueWebRtcShowDialogTime={props.floorData?.continueWebRtcShowDialogTime} handleDisconnectWebRtc={handleDisconnectWebRtc} />
            {/*ビデオ通話前の確認ダイアログ*/}
            <WebrtcStartConfirmDialog ref={startConfirmRef} hitUser={user.hittedUser} startingConfirmTimeoutValue={props.floorData?.wrStartConfirmWaitTime} handleConfirmResult={handleStartConfirmResult} />
            {/*iPad対応-12 iPadの場合、FAMがアクティブでなくなり切断されるのでメッセージを出す*/}
            <VideoURLStartConfirmDialog ref={videoURLStartConfirmRef} videoURL={user.videoURL} handleVideoURLConfirmResult={handleVideoURLConfirmResult} />
            {drawSetMyAvatarDialog}
            <MyUserStatusTimer ref={statusTimerRef} user={state.user} />
        </Fragment>
    )
    // drawする要素よりBaseUserが表示で上となるように、BaseUserのzIndexには「ZIndex.myUser + 5」を渡している。
}

export const MyUser = forwardRef(MyUserComponent);
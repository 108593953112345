import React, { Fragment, useMemo, useRef, useEffect, useState, ForwardRefRenderFunction, forwardRef, useImperativeHandle, } from 'react';
import useReactRouter from 'use-react-router';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { DraggableData, DraggableEvent } from "react-draggable";
import { Rnd } from "react-rnd";
import ZIndex from "../common/ZIndex";
import { MyRoomFloorObject, Seat } from '../common/JsonClass';
import { AvatarMenuData } from '../common/JsonClass';
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import { MyRoomOtherUsers } from './OtherUsers';
import { Utility } from '../common/Utility';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';
import { MEMO_FRAME_BG_COLOR } from '../common/Constants';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import useWindowSize from '../common/useWindowSize';
import JfsClient, { JfsError, ObjectMaster, User } from '@fsi/jfs-sdk';

const dotColor = '#222'
const useStyles = makeStyles(() => ({
    memoWindow: {
        width: '100%',//Utility.isLowScreenResolution() ? '360px' : '360px',
        height: '100%',//Utility.isLowScreenResolution() ? '300px' : '472px',
        position: 'relative',
        userSelect: 'text',
        background: MEMO_FRAME_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: '4px',
    },
    memoWindowHeaderDiv: {
        height: '40px',
        background: MEMO_FRAME_BG_COLOR, //'rgba(250,250,250,1)',
        boxShadow: '0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    memoWindowHeaderItem: {
        height: '24px',
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    memoWindowDiv: {
        width: '100%',
        height: `calc(100% - ${Utility.isLowScreenResolution() ? 110 : 132}px)`,// この値を変えるとき、inputMemoDiv, sendMemoButtonの調整も必要
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column-reverse',// TLを下から上に
    },
    memoInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    inputMemoDiv: {
        height: 90, // memoWindowDivのheightと関連あり
        width: '100%',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'hidden',
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    inputMemo: {
        width: 'calc(100% - 50px - 24px)',
        height: 55,
        background: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: 4,
        color: '#A39F98',
        marginTop: 8,
        marginLeft: 8,
        padding: 0,
    },
    sendButton: {
        position: 'absolute',
        padding: 0,
        bottom: 30,// inputMemoDivのheight調整時、こちらの調整も必要
        right: 0,
        width: 50,
        height: 50,
        marginRight: 8,

        background: '#1B74E7',
        '&:hover': {
            background: '#2B84F7',
        },
        boxShadow: '0 0 6px 0 rgba(0,0,0,0.24)',
        color: '#FFFFFF',
    },
    inputMemoBorder: {
        padding: 0,
        '&:after': {
            borderWidth: '0px',
        },
        '&:before': {
            borderWidth: '0px',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderWidth: '0px',
        }
    },
    inputMultiline: {
        padding: 8,
    },
    memoUserNickname: {
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
    },
    avatar: {
        color: '#4F4F4F',
        marginLeft: 8,
        marginRight: 8,
    },
    // メモ吹き出し
    balloon1Left: {
        position: 'relative',
        display: 'inline-block',
        padding: '7px 10px',
        minWidth: '120px',
        maxWidth: '80%',
        background: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '0 10px 10px 10px',
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
    },
    balloon1Leftp: {
        margin: 0,
        padding: 0,
        wordBreak: 'break-all',
        textAlign: 'left',
    },
    balloon1Right: {
        position: 'relative',
        display: 'inline-block',
        padding: '7px 10px',
        minWidth: '120px',
        maxWidth: '80%',
        background: '#1B74E7',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '10px 0 10px 10px',
        fontSize: 14,
        color: '#FFFFFF',
        letterSpacing: 0,
        textAlign: 'right',
    },
    // イベント用
    balloon1Center: {
        position: 'relative',
        display: 'inline-block',
        margin: '0.2em',
        padding: '7px 10px',
        minWidth: '70%',
        maxWidth: '70%',
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#555',
        fontSize: '12px',
        background: '#D4EAF4',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '4px',
    },
    rgbIconDiv: {
        backgroundColor: 'red',
        marginTop: '3px',
        height: '20px',
        width: '20px',
        borderRadius: '5px',
        border: '1px solid #555',
    },
    endMessage: {
        color: dotColor,
        alignSelf: 'center',
    },
    loading: {
        color: dotColor,
        alignSelf: 'center',
        '&::after': {
            content: '" ."',
            animation: '$dots 1s steps(5, end) infinite;'
        }
    },
    // loading dot definition
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0,0,0,0)',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '40%': {
            color: dotColor,
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '60%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 rgba(0,0,0,0)`,
        },
        '80%,100%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 ${dotColor}`,
        },
    },
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    alignRight: {
        textAlign: 'right',
    }
}));

interface Props {
    parentElementId: string; // 親要素のID
    bgImage: string;

    scale: number;
    myUserSubId: string | null;
    handleOpenMemo: (groupId: number, groupName: string) => void;
    enabledMemoOtherUserMenu: boolean;
    myUser: User;
    myFloorName: string;
    getEnabledBusinessCard:() => boolean;
    handleAddMyRoomUser: (id: number, subId:string) => void,
    handleDeleteMyRoomUser: (id: number, subId:string, seatNo:number) => void,
    handleGoToSee: (subId:string) => void,
    scrollbarWidth: number,
    handleMyRoomWindowsClose: () => void,
    iPhoneLayout: boolean,
    handleClickMyRoomUserInfo: (user:User) => void,
    enabledMyRoom: boolean,
    enabledChat: boolean,
    handleOpenChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => void;
    handleClickMyRoomMemo: (user:User) => void,
    handleClickMyRoomChat: (user:User) => void,
}

export interface MyRoom2Handler {
    open: (isOpen: boolean) => void;
    setUsers: (users: User[]) => void;
    setAvatarMenuDataList: (avatarMenuDataList: AvatarMenuData[]) => void;
    updateSize: (width: number, height: number) => void;
    updatePosition: (x: number, y: number) => void;
    setFloorObjectList: (floorObjectList: MyRoomFloorObject[]) => void;
    setFloorSeatList: (seatList: Seat[]) => void;
    getOpen: () => boolean;
}



const MyRoom2Function: ForwardRefRenderFunction<MyRoom2Handler, Props> = (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [curX, setCurX] = useState(1);
    const [curY, setCurY] = useState(1);
    const [curWidth, setCurWidth] = useState(1);
    const [curHeight, setCurHeight] = useState(1);
    const [floorObjectList, setFloorObjectList] = useState([] as MyRoomFloorObject[]);
    const [floorSeatList, setFloorSeatList] = useState([] as Seat[]);
    const classes = useStyles();
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // props
    const { parentElementId, bgImage, scrollbarWidth } = props;

    const rndRef = useRef({} as Rnd | null);
    const otherUserRef = useRef({} as any);
    const windowSize = useWindowSize();

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open(isOpen: boolean) {
            calcInitPosion();   // open時右上に
            setIsOpen(isOpen);
        },
        setUsers(users: User[]) {
            otherUserRef?.current.setUsers(users);
        },
        setAvatarMenuDataList(avatarMenuDataList: AvatarMenuData[]) {
            otherUserRef?.current.setAvatarMenuDataList(avatarMenuDataList);
        },
        updateSize(w: number, h: number) {
            if(props.iPhoneLayout === true){
                rndRef.current?.updateSize({ width: w, height: h/4 });
                setCurHeight(h/4);
            }
            else{
                rndRef.current?.updateSize({ width: w, height: h/2 });
                setCurHeight(h/2);
            }
            setCurWidth(w);
            calcInitPosionWithWidth(w);
        },
        updatePosition(x: number, y: number){
            rndRef.current?.updatePosition({ x: x, y: y });
            setCurX(x);
            setCurY(y);
        },
        setFloorObjectList(floorObjectList: MyRoomFloorObject[]){
            setFloorObjectList(floorObjectList);
        },
        setFloorSeatList(seatList: Seat[]){
            setFloorSeatList(seatList);
        },
        getOpen() {
            return isOpen;
        },
    }));

    //useEffect(() => {
    //    // 最初に何かやる時はここで
    //    // parent = document.getElementById(parentElementId) as HTMLDivElement;
    //}, []);

    // window resize時の処理
    useEffect(() => {
        calcInitPosion();   // ウィンドウリサイズ時に右上に持っていく
    }, [windowSize]);

    // 右上に位置を調整
    const calcInitPosion = () => { 
        const parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { offsetWidth } = parent;
        let x = offsetWidth - (scrollbarWidth + 2) - curWidth;
        console.log("x:" + x);
        if(x < 0){
            x = 0;
        }

        rndRef.current?.updatePosition({x:x, y:0});
        setCurX(x);
    };

    const calcInitPosionWithWidth = (w:number) => { 
        const parent = document.getElementById(parentElementId) as HTMLDivElement;
        const { offsetWidth } = parent;
        let x = offsetWidth - (scrollbarWidth + 5) - w;
        console.log("x:" + x);
        if(x < 0){
            x = 0;
        }

        rndRef.current?.updatePosition({x:x, y:0});
        setCurX(x);
    };

    /*
    * マイルームのオブジェクトを描画(FloorObject描画処理から抜粋)
    */
    const drawObject = useMemo(() => {

        if (floorObjectList === undefined || floorObjectList === null) return;

        return (floorObjectList.map((obj: MyRoomFloorObject) => {
            // 描画するだけ
            return (
                <div
                    key={'MyRoom-' + obj.id}
                    style={{
                        ...SELECT_NONE,
                        zIndex: ZIndex.floorSection,
                        position: "absolute",
                        left: obj.offsetLeft,
                        top: obj.offsetTop,
                        backgroundSize: "contain",
                        // backgroundImage: "url(./api/user/object/picture/" + obj.objectMaster.id + ")",
                        backgroundImage: `url(${httpClient.createObjectImgUrl(obj.objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                        width: obj.objectMaster.width,
                        height: obj.objectMaster.height,
                        pointerEvents: "none",
                    }}
                />)
        })
        )

    }, [floorObjectList]);

    /*
    * 席札を描画(Floor.tsxから引用)
    */
    const drawSeatObject = useMemo(() => {

        if (floorSeatList === undefined || floorSeatList === null) return;

        return (floorSeatList.map((obj: Seat) => {
            if (!obj.isViewName) return "";
            if (obj.viewText === "") return "";
            if (obj.myNameplate === null) return "";
            if (obj.myNameplateSignOut === null) return "";

            if (obj.viewText2.match("(ﾛｸﾞｲﾝ中)")) { // 暫定

                // サインイン状態(名札の色)を判別するために、'(ﾛｸﾞｲﾝ中)'をサーバーでは入れているが、frontでは表示しない
                let viewText2: string = obj.viewText2.substring(0, obj.viewText2.indexOf('('));

                return (
                    <div
                        key={'MyRoom-object-' + obj.floorObjectId + "-" + obj.seatNo}
                        style={{
                            ...SELECT_NONE,
                            position: "absolute",
                            left: obj.x + obj.deskOffsetLeft - (obj.myNameplate.width / 2),
                            top: obj.y + obj.deskOffsetTop - (obj.myNameplate.height / 2),
                            zIndex: ZIndex.seatObject,
                            pointerEvents: "none",
                        }}
                    >
                        <div style={{ ...SELECT_NONE, position: "relative", width: obj.myNameplate.width, height: obj.myNameplate.height }}>
                            {/*<img src={"/api/user/object/picture/" + obj.myNameplate.id} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplate.width, height: obj.myNameplate.height, left: 0, top: 0 }} alt="name-plate" />*/}
                            <img src={`${httpClient.createObjectImgUrl(obj.myNameplate.id, undefined, sessionStorage.getItem("TABID") as string)}`} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplate.width, height: obj.myNameplate.height, left: 0, top: 0 }} alt="name-plate" />
                            <div style={{ ...SELECT_NONE, position: "absolute", left: 0, top: 0, width: obj.myNameplate.width, fontSize: "10px" }}>{obj.viewText}<br />{viewText2}</div>
                        </div>
                    </div>)
            }
            else {
                return (
                    <div
                        key={'MyRoom-object-' + obj.floorObjectId + "-" + obj.seatNo}
                        style={{
                            ...SELECT_NONE,
                            position: "absolute",
                            left: obj.x + obj.deskOffsetLeft - (obj.myNameplateSignOut.width / 2),
                            top: obj.y + obj.deskOffsetTop - (obj.myNameplateSignOut.height / 2),
                            zIndex: ZIndex.seatObject,
                            pointerEvents: "none",
                        }}
                    >
                        <div style={{ ...SELECT_NONE, position: "relative", width: obj.myNameplateSignOut.width, height: obj.myNameplateSignOut.height }}>
                            {/*<img src={"/api/user/object/picture/" + obj.myNameplateSignOut.id} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplateSignOut.width, height: obj.myNameplateSignOut.height, left: 0, top: 0 }} alt="name-plate" />*/}
                            <img src={`${httpClient.createObjectImgUrl(obj.myNameplateSignOut.id, undefined, sessionStorage.getItem("TABID") as string)}`} style={{ ...SELECT_NONE, position: "absolute", width: obj.myNameplateSignOut.width, height: obj.myNameplateSignOut.height, left: 0, top: 0 }} alt="name-plate" />
                            <div style={{ ...SELECT_NONE, position: "absolute", left: 0, top: 0, width: obj.myNameplateSignOut.width, fontSize: "10px" }}>{obj.viewText}<br />{obj.viewText2}</div>
                        </div>
                    </div>)
            }
        })
        )
    }, [floorSeatList]);


    /*
    * マイルームを描画
    */
    const draw = useMemo(() => {
        return (
            <Rnd
                id='myRoomRnd'
                key='MyRoomRnd'
                ref={rndRef}
                enableResizing={false}  // リサイズ不可
                bounds={`#${parentElementId}`} // 移動可能範囲をCSSセレクターで指定 
                //minHeight={minHeight}
                //minWidth={minWidth}
                disableDragging={true}  // ドラッグ不可.(ドラッグ有効だとスクロールバーへのイベントが持っていかれる)
                default={{
                    x: 0,
                    y: 0,
                    width: 1,
                    height: 1,
                }}
                style={{
                    visibility: isOpen ? "visible" : "hidden",
                    backgroundImage: bgImage,
                    borderRadius: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: '#000000',
                }}
            >
                <div className={classes.memoWindowHeaderDiv}>
                    {/* <Tooltip title={groupName.length > textTrimLength ? groupName : ''} placement="bottom-start" arrow> */}
                    <div style={{ fontSize: 12, marginLeft: 8 }}>フォローリスト</div>
                    {/* </Tooltip> */}
                    <div>
                        <IconButton className={classes.memoWindowHeaderItem} onClick={() => {setIsOpen(false); props.handleMyRoomWindowsClose();} } style={{ width: 22 }}>
                            <CloseIcon viewBox='0 0 16 16' />
                        </IconButton>
                    </div>
                </div>
                <div style={{position:"relative"}}>
                    {drawObject}
                    {drawSeatObject}
                    <MyRoomOtherUsers
                        ref={otherUserRef}
                        scale={props.scale}
                        myUserSubId={props.myUserSubId}
                        handleOpenMemo={props.handleOpenMemo}
                        enabledMemoOtherUserMenu={props.enabledMemoOtherUserMenu}
                        myUser={props.myUser}
                        myFloorName={props.myFloorName}
                        getEnabledBusinessCard={props.getEnabledBusinessCard}
                        kind='myroom'
                        handleAddMyRoomUser={props.handleAddMyRoomUser}
                        handleDeleteMyRoomUser={props.handleDeleteMyRoomUser}
                        handleGoToSee={props.handleGoToSee}
                        handleClickMyRoomUserInfo={props.handleClickMyRoomUserInfo}
                        enabledMyRoom={props.enabledMyRoom}
                        enabledChat={props.enabledChat}
                        handleOpenChat={props.handleOpenChat}
                        handleClickMyRoomMemo={props.handleClickMyRoomMemo}
                        handleClickMyRoomChat={props.handleClickMyRoomChat}
                        floorSize={{width:1, height:1}}
                            />
                </div>
            </Rnd>
        )
    }, [parentElementId, isOpen, bgImage, floorObjectList, floorSeatList, curX, curY, curWidth, curHeight, props.scale, props.myUserSubId, props.enabledMemoOtherUserMenu, props.enabledMyRoom, props.enabledChat])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const MyRoom2 = forwardRef(MyRoom2Function);
import React, { forwardRef, useImperativeHandle, useState, useMemo, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone';
import { SELECT_NONE } from "../common/Constants";

interface Props {
    onOk: (event: any) => void,
    onCancel: () => void,
    zIndex: number,
}

// 公開したいメソッドの定義
export interface ChildHandler {
    toggle: (isShow: boolean, x?: number, y?: number) => void
}

const JumpToMoveDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const { onOk, onCancel } = props
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const zIndex = props.zIndex;

    useImperativeHandle(ref, () => ({
        toggle: (isShow: boolean, x?: number, y?: number) => {
            if (x !== undefined) setX(x);
            if (y !== undefined) setY(y);
            setIsShow(isShow);
        }
    }))

    const onDialogOk = (event: any) => {
        event.stopPropagation();
        onOk(false);
    }
    const onDialogCancel = (event: any) => {
        event.stopPropagation();
        onCancel();
    }

    const onClickAway = (event: any) => {
        event.stopPropagation();
        const e = event as TouchEvent;
        if(e.type != "touchend"){
            onCancel();
        }
    }

    const draw = useMemo(() =>
        isShow &&
        <ClickAwayListener onClickAway={onClickAway}>
            <div>
                <LocationOnTwoToneIcon
                    style={{
                        ...SELECT_NONE,
                        position: "absolute",
                        top: y - 35,
                        left: x - 20,
                        width: 40,
                        height: 40,
                        zIndex: zIndex,
                    }}
                />
                <div
                    style={{
                        ...SELECT_NONE,
                        pointerEvents: 'auto',
                        position: "absolute",
                        top: y + 30,
                        left: x - 85,
                        width: 250,
                        height: 110,
                        margin: 0,
                        padding: 0,
                        backgroundColor: '#FFF',
                        zIndex: zIndex,
                        lineHeight: 2.1,
                        borderRadius: '25px',
                        boxSizing: 'border-box',
                        boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
                        fontSize: '14px',
                        border: '6px solid #57BBFF',
                    }}

                >
                    <div style={{marginTop: '5px', marginBottom: '8px', marginLeft: '30px', float: 'left'}}>ここに移動しますか？</div>
                    <div style={{marginLeft: '20px', marginBottom: '10px'}}>
                    <Button
                        id="jumpToMoveYesButton"
                        onClick={onDialogOk}
                        onTouchStart={onDialogOk}
                        color="primary"
                        style={{ ...SELECT_NONE, pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 95 }}
                    >
                        はい
                    </Button>&nbsp;
                    <Button
                        id="jumpToMoveCancelButton"
                        onClick={onDialogCancel}
                        onTouchStart={onDialogCancel}
                        color="primary"
                        style={{ ...SELECT_NONE, pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 95 }}
                    >
                        キャンセル
                    </Button>
                    </div>
                </div>
            </div >
        </ClickAwayListener>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isShow, x, y])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const JumpToMoveDialogRef = forwardRef(JumpToMoveDialog);
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

//import tutorial1 from '../img/tutorial/0924_チュートリアル画面_01.png'
//import tutorial2 from '../img/tutorial/0924_チュートリアル画面_02.png'
//import tutorial3 from '../img/tutorial/0924_チュートリアル画面_03.png'
import tutorial1 from '../img/tutorial/FAMcampus/チュートリアル画面_01.png'
import tutorial2 from '../img/tutorial/FAMcampus/チュートリアル画面_02.png'
import tutorial3 from '../img/tutorial/FAMcampus/チュートリアル画面_03.png'

import Cookies from 'universal-cookie';

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const tutorialSteps = [
    [
        {
            label: 'dummy',
            imgPath: 'dummy',
            label2: 'dummy',
        }
    ],
    [
        {
            label: '① 自分のアバターを動かす​​',
            label2: '青色で名前が表示されているアバターがあなたです。ドラッグ操作でフロア内を自由に移動できます。\r\n',
            imgPath: tutorial1
        },
        {
            label: '② 自分の座席を設定する​',
            label2: '自分のアバターを座席に置き、アバターをクリックして表示されるメニューから「自席設定」を選択すると、その場所を自分の席に設定できます。\r\n',
            imgPath: tutorial2
        },
        {
            label: '③ 誰かとビデオ通話する​',
            label2: '話しかけたい相手のアバターに自分のアバターを重ねるとビデオ通話が始まります。\r\n',
            imgPath: tutorial3
        },
    ],
    [
        {
            label: 'San Francisco – Oakland Bay Bridge, United States',
            imgPath:
                'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
            label2: '',
        }
    ],
];

const tutorialFont = 'UD デジタル 教科書体 NK-B';
const tutorialWidth = '396px';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: tutorialWidth,
            flexGrow: 1,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            height: 50,
            paddingLeft: theme.spacing(4),
            backgroundColor: theme.palette.background.default,
        },
        img: {
            maxWidth: tutorialWidth,
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
        checkBoxLabel: {
            fontSize: 13,
        },
        tutorialFont: {
            //fontFamily: tutorialFont,
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        },
        tutorialNextFont: {
            paddingLeft: '15px',
            fontFamily: 'Hiragino Maru Gothic StdN',
            border: '3px solid #006FBC',
            borderRadius: '25px',
            color: '#006FBC',
        },
        tutorialBackFont: {
            paddingRight: '15px',
            fontFamily: 'Hiragino Maru Gothic StdN',
            border: '3px solid #006FBC',
            borderRadius: '25px',
            color: '#006FBC',
        },
    }),
);

const mobileStepperTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
    },
})

interface Props {
    // このIDによってコンテンツの中身が切り替わる。tutorialSteps配列のindexに対応している。
    tutorialId: number,
}

// 公開したいメソッドの定義
export interface ChildHandler {
    setTutorialOpen: (open: boolean) => void
}

const TutorialDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    //const cookies = new Cookies();

    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const [checkedDontShowTutorialAgain, setCheckedDontShowTutorialAgain] = React.useState(false);

    useImperativeHandle(ref, () => ({
        setTutorialOpen: (open: boolean) => {
            setOpen(open);
            setCheckedDontShowTutorialAgain(getValueOfCheckedDontShowTutorialAgain()); // 明示的にチュートリアルを開いたときは、チェックボックスをlocalStorageに設定されている値にする
        }
    }));

    /*
    // コンテンツの入れ替えが行なわれたとき、tutorialを表示 
    useEffect(() => {
        setOpen(props.tutorialId === 0 ? false : true);
    }, [props.tutorialId]);
    */

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);

        // localStorageに「次回から表示しない」のチェック状態を保存する
        // 保存期間は1週間
        //let d = new Date();
        //d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
        let strSaveCookie = "false";
        if(checkedDontShowTutorialAgain === true){
            strSaveCookie = "true";
        }
        //cookies.set('checkedDontShowTutorialAgain', strSaveCookie, { path: '/', expires: d });
        try{
            localStorage.setItem('checkedDontShowTutorialAgain', strSaveCookie);
        }
        catch(error){
            // ストレージが満杯
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeChecked = (event:React.ChangeEvent<HTMLInputElement>) => {
        setCheckedDontShowTutorialAgain(event.target.checked);
    };

    const getValueOfCheckedDontShowTutorialAgain = () => {
        if(localStorage.getItem('checkedDontShowTutorialAgain') !== "true") {
            return false;
        }
        else{
            return true;
        }
    }

    const draw = useMemo(() =>
        <BaseDialog
            id="tutorialDialogTitle"
            open={open}
            onClose={handleClose}
            aria-labelledby="tutorialDialogTitle"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            {/* */}
            <DialogTitle id="tutorialDialogTitle" className={classes.dialogTitle}>
                FAMcampus の使い方
            </DialogTitle>
            {/* */}
            <DialogContent>
                <Typography variant="body1" className={classes.tutorialFont}>
                    {tutorialSteps[props.tutorialId][activeStep].label}
                </Typography>
                <img
                    className={classes.img + " " + classes.tutorialFont}
                    src={tutorialSteps[props.tutorialId][activeStep].imgPath}
                    alt={tutorialSteps[props.tutorialId][activeStep].label}
                    style={{ pointerEvents: 'none', borderRadius: '25px' }}
                />
                <br />
                <Typography variant="body2" className={classes.tutorialFont} style={{ height: '65px', width: '100%' }}>
                    {tutorialSteps[props.tutorialId][activeStep].label2}
                </Typography>

                <ThemeProvider theme={mobileStepperTheme}>
                    <MobileStepper
                        variant="dots"
                        steps={tutorialSteps[props.tutorialId].length}
                        position="static"
                        activeStep={activeStep}
                        className={classes.root}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === tutorialSteps[props.tutorialId].length - 1} className={classes.tutorialNextFont}>
                                Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.tutorialBackFont}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                    </Button>
                        }
                    />
                </ThemeProvider>
                <FormControlLabel
                    control={
                        <ThemeProvider theme={checkBoxTheme}>
                            <Checkbox checked={checkedDontShowTutorialAgain} onChange={handleChangeChecked} color="primary"/>
                        </ThemeProvider>
                    }
                    label={<Box component="div" className={classes.checkBoxLabel + " " + classes.tutorialFont}>次回から表示しない</Box>}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [open, activeStep, checkedDontShowTutorialAgain]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const TutorialDialogRef = forwardRef(TutorialDialog);

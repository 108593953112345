import { Button, Typography } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { Utility } from '../common/Utility';
import WebrtcService from '../webrtc/WebrtcService';

interface Props extends RouteComponentProps {
}

export default function TabId(props: Props) {
    const { history } = useReactRouter();

    useEffect(() => {
        console.log(props.location.search);
        let query: string = props.location.search;
        if (query === "") return;

        // iPad対応-26 SSOの時更新ボタンがダウンロードになる。（URLの更新はできる）
        // １回更新すると回避できるので、仮対応として強制リロードを入れた
        if(WebrtcService.isiOS() === true){
            const reloadCheck = localStorage.getItem("reloadCheckValue");
            const reloadCheck_ss = sessionStorage.getItem("ss_reloadCheckValue");
            if(reloadCheck !== "OK" && reloadCheck_ss !== "OK") {
                //alert('null');
                localStorage.setItem("reloadCheckValue", "OK");
                sessionStorage.setItem("ss_reloadCheckValue", "OK");
                // 書き込めたかチェック
                if(localStorage.getItem("reloadCheckValue") === "OK" && sessionStorage.getItem("ss_reloadCheckValue") === "OK") {
                    document.location.reload();
                    return;
                }
            } else {
                //alert('OK');
                localStorage.setItem("reloadCheckValue", "");
                sessionStorage.setItem("ss_reloadCheckValue", "");
            }
        }
        
        let tabId = Utility.getQueryParam(query, "tabid");
        let lastFloorId = Utility.getQueryParam(query, "lastfloorid");	// SAML対応(FSI)
        let registrationId = Utility.getQueryParam(query, "registrationid");	// SAML対応(FSI)
        sessionStorage.setItem("TABID", tabId);
        sessionStorage.setItem("ISLOGIN", "true");
        if(registrationId != null && registrationId.length > 0){
            Utility.setSigninPage("/signin/" + registrationId);
        }
		// SAML対応(FSi) ここから
        if(lastFloorId === ""){
            history.push("/top");
        } else {
            history.push({
                pathname: "/selectdevice",
                state: { 
                  nextpage: "/floor", 
                  //path: loginJson.webSocketUrl, 
                  id: lastFloorId,
                  //requireCamera: loginJson.requireCamera,
                  //requireMic: loginJson.requireMic,
                }
            });
        }
        // SAML対応(FSI) ここまで
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = () => {
        history.push("/top");
    }

    return(
        <Fragment>
            <Typography variant="body1">自動的に遷移できない場合は下記のボタンをクリックしてください。</Typography>
            <Button variant="contained" color="primary" onClick={handleClick}>トップ</Button>
        </Fragment>
    )
}
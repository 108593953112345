import React, { Fragment, useEffect, useMemo, useState } from 'react';
import YouTube from 'react-youtube';
import { YouTubePlayer } from 'youtube-player/dist/types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface Props {
    isSitOnTable: boolean;
    videoId: string;
    zIndex: number;
    left: number;
    top: number;
    width: number;
    height: number;
    isChangeVideo: boolean;
    resetIsChangeVideo: (flag: boolean) => void;
}

export default function YouTubeVideo(props: Props){
    const {videoId, zIndex, left, top, width, height, isSitOnTable, isChangeVideo} = props;
    const [open, setOpen] = useState(false);
    const [player, setPlayer] = useState({} as YouTubePlayer);

    useEffect(() => {
        if (isChangeVideo) {
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoId])
    
    const onReady = (e:any) => {
        setPlayer(e.target);
        setOpen(true);
    }

    // ダイアログのはいボタンを押すとビデオを再生する
    const handlePlay = () => {
        setOpen(false);
        if (isChangeVideo) {
            props.resetIsChangeVideo(false);
        }
        try {
            player.playVideo();
        } catch(e){
            console.error(e);
        }
    }

    const handleClose = () => {
        setOpen(false);
        if (isChangeVideo) {
            props.resetIsChangeVideo(false);
        }
    }

    const drawYouTubeVideo = React.useMemo(() => {
        return videoId && isSitOnTable && <div style={{
            position: 'absolute', top: top, left: left, zIndex: zIndex, width: width, height: height, pointerEvents: "none", boxSizing: "content-box", border: '5px solid #000000'
        }}>
            <YouTube
            id="youtube" 
            className="youtube" 
            containerClassName="youtubeContainer" 
            videoId={videoId} 
            opts={{height: height + '', width: width + '', playerVars: {autoplay: 0, controls: 0, modestbranding: 1}}} 
            onReady={onReady}
            />
            <div className="youtubeCover" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}></div>
        </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSitOnTable, videoId, left, top, width, height, isChangeVideo]);

    const drawStartVideoDialog = useMemo(() => {
        return open && <Dialog open={open}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <DialogContentText>このサイトでは音声が再生されます。</DialogContentText>
                    <br />
                    <DialogContentText>
                        よろしいですか？
                    </DialogContentText>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    いいえ
                </Button>
                <Button onClick={handlePlay} color="primary" autoFocus>
                    はい
                </Button>
            </DialogActions>
        </Dialog>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, videoId, isChangeVideo])

    return (
            <Fragment>
                {drawYouTubeVideo}
                {drawStartVideoDialog}
            </Fragment>
        )
}
import React, { Fragment, useState, useEffect, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, } from 'react';
import { EditorSnackBar } from '../common/JsonClass';
import Grid from '@material-ui/core/Grid';
import BaseDialog from './BaseDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import ZIndex from "../common/ZIndex";
import { useSnackbar } from 'notistack';
import JfsClient, { Office, TenantOriginalObjectManagerInfo } from '@fsi/jfs-sdk';
import { OriginalObjectManagementDialogRef } from './OriginalObjectManagementDialog';

const useStyles = makeStyles((theme) => createStyles({
    modes: {
        minHeight: '80%',
        maxHeight: '80%',
        maxWidth: '80%',
        minWidth: '100%',
    },
    dialog: {
// フロアエディター改修 comming soonを削除
        minHeight: 355,
        maxHeight: '70vh',
        minWidth: 735,
        maxWidth: '40vw',
    },
    row: {
        // width: '100%',
        // backgroundColor: 'red',
        minHeight: 78,
        maxHeight: '10vh',
    },
    detail:{
        minWidth: '90%',
        maxWidth: '90%',
        // backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    floorAlert: {
        zIndex: ZIndex.experiment,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
}));

interface Props {
    floorEditMode: boolean,
    sendWebSocket: (action: string, object: object) => void,
    officeId: number,
    floorId: number
}

export interface WindowHandler {
    open: () => void;
    close: () => void;
    requestFloorEditUnLock: () => void;
    reSizeSnackbar: (beforeHeight:number, beforeWidth:number, floorHeight:number, floorWidth:number) => void;
    toggleFloorEdit: () => void;
    testsnack:(message: string) => void;
    editFloorSnackBar: (data: EditorSnackBar, message: string) => void;
    entranceAlert: (data: EditorSnackBar, message: string) => void;
}

const FloorEditorComponent: ForwardRefRenderFunction<WindowHandler, Props> = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [isDispNoticeAlert, setDsispNoticeAlert] = useState(false);
    const [alertMessage, setMessage] = useState("");
    const [dispName, setDispName] = useState("");
    const [isDisplayEntranceAlert, setDisplayEntranceAlert] = useState(false);
    const [entranceAlertMessage, setEntranceAlertMessage] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient} = jfsClient;
    const classes = useStyles();
    const [isOriginalObjectManageOpen, setIsOriginalObjectManageOpen] = useState(false);
    const [maxResist,setMaxResist] = useState(0);

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
        close() {
            setOpen(false);
        },
        requestFloorEditUnLock(){
            requestFloorEditUnLock();
        },
        reSizeSnackbar(beforeHeight:number, beforeWidth:number, floorHeight:number, floorWidth:number){
            enqueueSnackbar("フロアサイズ変更 "+beforeHeight+"x"+beforeWidth+" => "+floorHeight+"x"+floorWidth, { variant: "success" });
        },
        toggleFloorEdit() {
            startDirectEdit();
        },
        testsnack(message:string){
            enqueueSnackbar(message);
        },
        editFloorSnackBar: (data: EditorSnackBar) => {
            editAlert(data);
        },
        entranceAlert: (data: EditorSnackBar) => {
            noEntranceAlert(data);
        },

    }));

    useEffect(() => {
        async function getInfo() {
            await getTenantOriginalObjectManagerInfo();
        }
       
        getInfo();
    }, [open])

    const getTenantOriginalObjectManagerInfo = async () => {
        httpClient.getTenantOriginalObjectManagerInfo(sessionStorage.getItem("TABID") as string)   //エラーにはなっているがhttpclientでは定義済み
            .then(response => {
                let res = response as TenantOriginalObjectManagerInfo;
                setMaxResist(res.registPossible); 
        }).catch(err => {
            console.log(err);
        });
    }

    //フロア編集状態ロック
    const requestFloorEditLock = () => {
        // props.sendWebSocket("FLOOREDIT_LOCK", {});

        try {
            wsClient.sendFloorEditLock();
        } catch (error) {
            console.error(error);            
        }
    }
    
    //フロア編集状態ロック解放
    const requestFloorEditUnLock = () => {
        // props.sendWebSocket("FLOOREDIT_UNLOCK", {});
    
        try {
            wsClient.sendFloorEditUnlock();
        } catch (error) {
            console.error(error);            
        }
    }

    // 直接編集モード開始
    const startDirectEdit = () => {
        if(props.floorEditMode){
            requestFloorEditUnLock();
        }else{
            requestFloorEditLock();
        }
        setOpen(false);
    }

    // オリジナルオブジェクト管理開始
    const startOriginalObjectManage = () => {
        setIsOriginalObjectManageOpen(true);
    }

    // 編集中アラート
    const editAlert = (data: EditorSnackBar) => {
        if(data.type){
            setDsispNoticeAlert(true);
            setDispName(data.editor);
            setMessage(data.editor+"さんがフロアを編集中です");
        }else{
            setDsispNoticeAlert(false);
            setDispName("");
            setMessage("");
        }
    }

    // エントランスないアラート
    const noEntranceAlert = (data: EditorSnackBar) => {
        if(data.type){
            setDisplayEntranceAlert(true);
            setEntranceAlertMessage(data.editor);
        }else{
            setDisplayEntranceAlert(false);
            setEntranceAlertMessage("");
        }
    }

    // フロア編集中に真ん中上に出るアラート
    const drawNoticeAlert = useMemo(() => {
        // let centerWidth = window.innerWidth / 2 - 150;left: centerWidth,left: centerWidth + 30, 
           return (
                <Fragment>
                    <Collapse in={isDispNoticeAlert} className={classes.floorAlert} style={{ top: 20}}>
                        <Alert severity="warning">{alertMessage}</Alert>
                    </Collapse>
                    <Collapse in={isDisplayEntranceAlert} className={classes.floorAlert} style={{ top: 78 }}>
                        <Alert severity="error">{entranceAlertMessage}</Alert>
                    </Collapse>
                </Fragment>
            ) //エントランスの警告は赤
    }, [isDispNoticeAlert, dispName, alertMessage, isDisplayEntranceAlert, entranceAlertMessage]);

    // メニュー選択後最初に表示されるダイアログ
    const startDialog = useMemo(() => {
        return (
            <Dialog
                open={open}
                aria-labelledby='floorEditStart'
                classes={{paper: classes.dialog}}
            >
                <DialogTitle>フロアエディター</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        フロアエディターを開始します。
                        操作したい項目を選択してください。
                    </DialogContentText>
                    <br/>
                    <Grid container direction="column">
                        <Grid container item xs={12} className={classes.row}>
                            <Grid container item xs={4} alignContent="center" justify="center">
                                 <Button className={classes.modes} onClick={startDirectEdit} variant="outlined" color='primary' size='large'>
                                    フロア編集
                                </Button>
                            </Grid>
                            <Grid container item xs={8} alignContent="center" justify="center" >
                                <DialogContentText className={classes.detail}>
                                    <br/>
                                    <p>現在ログインしているフロアを編集できます。</p>
                                </DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid container item xs={12} className={classes.row}>
                             <Grid container item xs={4} alignContent="center" justify="center">
                                    <Button className={classes.modes} onClick={startOriginalObjectManage} variant="outlined" color='primary' size='large'>
                                        オリジナルオブジェクト<br/>管理
                                    </Button>
                                </Grid>
                                <Grid container item xs={8} alignContent="center" justify="center" >
                                    <DialogContentText className={classes.detail}>
                                        <br/>
                                        <p>お客様独自のオブジェクトを追加できます。<br/>追加したオブジェクトはフロア編集で使用できます。</p>
                                    </DialogContentText>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* // フロアエディター改修 comming soonを削除
                        <Grid container item xs={12} className={classes.row}>
                            <Grid container item xs={4} alignContent="center" justify="center">
                                <Button className={classes.modes} onClick={()=>false} variant="outlined" color='primary' size='large'>
                                    レイアウトテンプレート編集モード
                                </Button>
                            </Grid>
                            <Grid container item xs={8} alignContent="center" justify="center">
                                <DialogContentText className={classes.detail}>
                                    <br/>
                                    <p style={{margin:3}}>Comming soon...</p>
                                </DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} className={classes.row}>
                            <Grid container item xs={4} alignContent="center" justify="center">
                                 <Button className={classes.modes} onClick={()=>false} variant="outlined" color='primary' size='large'>
                                    レイアウトテンプレート反映
                                </Button>
                            </Grid>
                            <Grid container item xs={8} alignContent="center" justify="center">
                                <DialogContentText className={classes.detail}>
                                    <br/>
                                    <p style={{margin:3}}>Comming soon...</p>
                                </DialogContentText>
                            </Grid>
                        </Grid>
                        */}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> setOpen(false)} color="primary">閉じる</Button>
                </DialogActions>
            </Dialog>
        )
    }, [open]);

    return(
        <div>
            {drawNoticeAlert}
            {startDialog}
            <OriginalObjectManagementDialogRef
                officeId={props.officeId}
                floorId={props.floorId}
                open={isOriginalObjectManageOpen}
                onClickClose={() => setIsOriginalObjectManageOpen(false)}
            />
        </div>
    )

}

export const FloorEditor = forwardRef(FloorEditorComponent);

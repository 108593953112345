import * as React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { StyleRules, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent, FormControl, Select, DialogActions, Button } from '@material-ui/core';
import BaseDialog from '../user/BaseDialog';
import WebrtcService from './WebrtcService';

import { setTimeout } from 'timers';

import { Utility } from './Utility';

import Peer from 'skyway-js';
// import { WebRtcInOutInfo, WebRtcStats, WebRtcStatsStream } from '../common/JsonClass';
import { VIDEO_ADDJUST_GAIN, LARGE_VIDEO_ADDJUST_GAIN } from '../common/AvatarSize';
import { SHARE_SCREEN_BG_COLOR } from '../common/Constants';
import AudioPlayer, { AudioId } from '../user/AudioPlayer';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Logger } from '../common/Logger';
import { printable } from '../log/LogSetting'
// import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';
import JfsClient, { User, WebRTCClient,  WebRTCInOutInfo as WebRtcInOutInfo, WebRTCStats as WebRtcStats, WebRTCStatsStream as WebRtcStatsStream } from '@fsi/jfs-sdk';


if(printable === 0){
  console.log = function(){}
  console.warn = function(){}
  console.error = function(){}
}

const logger: Logger = Logger.getInstance();
const getErrCode = (error: any) => {
    var code: string = "";
    try {
        code = error.code.toString();
    } catch (e) {
    }
    return code;
}
const getErrMessage = (error: any) => {
    var message: string = "";
    try {
        message = error.message;
    } catch (e) {
    }
    return message;
}
const getErrName = (error: any) => {
    var name: string = "";
    try {
        name = error.name;
    } catch (e) {
    }
    return name;
}
const logInfo = (logString: string) => {
    logger.info("[WEBRTC]," + logString);
}
const logWarn = (logString: string) => {
    logger.warn("[WEBRTC]," + logString);
}
const logErr = (logString: string) => {
    logger.error("[WEBRTC]," + logString);
}
const logException = (error: any) => {
    const code: string = getErrCode(error);
    const message: string = getErrMessage(error);
    const name: string = getErrName(error);
    logErr(code + "," + message + "," + name);
}

const controlVideoWidth : number = 80;
const controlVideoHeight : number = 80;

// Chromeの画面共有の場合は下記の値が有効となるが、
// Firefoxは元の解像度を基準にアスペクト比が変わらない値を設定する必要がある。
// 上記のように指定しない場合、
// 内部的に何らかの計算をしているようで全く効かないわけではなく、
// 元の解像度よりは小さいが指定した値とはかけ離れた値になる。
// フレームレートも指定した値よりも大きくなる。
// ウィンドウ指定の場合には、逆にChromeが余計な処理をしているようで、
// ウィンドウのサイズを変更すると、一部分がズームした状態になる場合がある。
// resizeMode をデフォルト crop-and-scale から none に変更しても挙動は変わらない。
// カメラについては異なる挙動となるようで実機での検証および調整が必要。
var constrainstsResolutionList = [
    {width: 80, height: 80, frameRate: 5, resizeMode: "none"},
    {width: 180, height: 180, frameRate: 5, resizeMode: "none"},
    // {width: 640, height: 480, frameRate: 15, resizeMode: "none"},
    // {width: 1920, height: 1080, frameRate: 30, resizeMode: "none"}, 
];

// iPadのカメラを使用した場合、以下の2種類の解像度のみ有効となる。
// iPad OS 13.5.1 で確認したところ、他の解像度も設定可能になっている様子。
// ただし、任意の値が設定できるわけではなさそう。
// フレームレートは任意の値が適用できる。
var constrainstsResolutionIPadList = [
    {width: 80, height: 80, frameRate: 5, resizeMode: "none"},
    {width: 80, height: 80, frameRate: 5, resizeMode: "none"},
    {width: 80, height: 80, frameRate: 5, resizeMode: "none"},
    {width: 80, height: 80, frameRate: 5, resizeMode: "none"},
    // {width: 640, height: 480, frameRate: 15},   // 縦画面用の標準解像度
    // {width: 960, height: 540, frameRate: 30},   // 縦画面用の高解像度
    // {width: 640, height: 480, frameRate: 15},   // 横画面用の標準解像度
    // {width: 1280, height: 720, frameRate: 30},  // 横画面用の高解像度
];
const offsetLandscapeConstrainstsResolutionIPadList = 2;

const styles = (theme: Theme): StyleRules => createStyles({
    rootDiv: {
        width: '100%',
    },

    rootDivHidden: {
        display:"none",
    }, 
    
    controlButton: {
        display: 'inline-block',
    },
})

interface Props extends WithStyles<typeof styles>, WithSnackbarProps {
    roomId: string,
    sessionId: string,
    isCall: boolean,
    isScreenShare : boolean,
    isScreenSharing: boolean,
    isShow : boolean,
    isRetryCall: boolean,   // webrtc start retry request
    videoMute: boolean,
    audioMute: boolean,
    screenScale: number,
    openDeviceSelect: boolean,
    analyzeMicMode: number,
    webRtcMode: number,
    webRtcInfoInterval: number,
    isNoVideo: boolean,
    isLargeVideo: boolean,
    screenShareSessionId: string,
    screenShareMode: number,
    screenShareFps: number,
    screenShareResolutionWidth: number,
    screenShareResolutionHeight: number,
    checkMediaDevices: boolean,
    requireCamera: boolean,
    requireMic: boolean,
    //debugSend: number,      // for Debug (webrtc retry : skyway close 対応)
    sendUserMicLevel: (micVolume: number) => void,
    sendWebRtcInfo: (info: WebRtcInOutInfo) => void,    
    setVisibleScreenShareButton: (value: boolean) => void,
    handleScreenShare: (value: boolean, mode: number, webRtcRoomId: string) => void,
    onMyStreamLoaded: (id: number) => void,
    onRemoteStreamLoading:  (id: number) => void,
    onRemoteStreamLoaded: (id: number, dev: number) => void,
    onUpdateJoinRoomStatus: (sid: string, pid: string, event: string, data: string) => void,    // joinRoom の状態を通知
    onScreenShareVideoLoaded: (isLoaded: boolean, screenShareSessionId: string, userList?: Map<string, User>) => void,
    onShareScreenOpen: (isOpen: boolean) => void,
    showMessage: (title: string, message: string, redirectUrl: string, redirectCaption: string, dispSignout: boolean) => void,
    sendSetGhost: (isGhost: boolean) => void,
    sendDeviceSelectedType: (deviceSelectedType: number) => void,
    displayErrorSnackBar: (device: number) => void,   // #369
    onDeviceSelected: () => void,
    usermanualURL: string;  // マニュアルURLをDBから取得
}

interface State {
    sessionId: string,
    isCall: boolean,
    remotePeerIdList: { [key: string]: string; },
    remoteStreamList: { [key: string]: MediaStream; },
    remoteVideoUpdateTimeList: number[],
    videoEnable : boolean,
    audioEnable : boolean,
    hasAudioDevice : boolean,
    hasVideoDevice : boolean,
    width : number,
    height : number,
    localStreamResolutionMode : number,
    openMicSelect : boolean,
    audioInputDeviceId : string,
    isShow : boolean,
    windowModeList: { [key: string]: number; },
    isOpenDeviceSelect: boolean,
    audioOutputDeviceId: string,
    videoDeviceId: string,
}

export interface HTMLVideoElementMod extends HTMLVideoElement { 
    playsInline : boolean;
} 

export const ScreenShareMode = {
    FullScreen: 1,// 画面上のデバッグメニュー内にある全画面での画面共有機能
    FloatingElement: 2,// アバターメニューから起動するフローティング要素での画面共有機能
} as const;

class WebrtcComponent extends React.Component<Props, State>{
    WINDOW_MARGINE_WIDTH = 100;
    WINDOW_MARGINE_HEIGHT = 250;

    isIOSScrollFix : boolean = false;
    videoDeviceIdList : string[] = [];
    currentVideoDeviceIdIndex : number = 0;
    currentAudioInputDeviceIdIndex : number = 0;
    currentAudioOutputDeviceIdIndex : number = 0;
    audioInputDeviceInfoList : MediaDeviceInfo[] = [];
    audioOutputDeviceInfoList: MediaDeviceInfo[] = [];
    videoInputDeviceInfoList: MediaDeviceInfo[] = [];

    // peer : Peer|undefined = undefined;
    closing_connectRoom : any = undefined; 
    connectRoom : any = undefined;
    isLandscape : boolean = true;
    // ローカル映像の解像度変更監視を始めた時間を保持し、
    // 処理の負荷を軽減するため一定時間経過後、監視をやめる
    startWatchTimeVideoTimeUpdate : number = 0;
    mediaStream: MediaStream|undefined = undefined;
    screenShareStream: MediaStream|undefined = undefined;
    useVideo: boolean = false;
    isLoadedStream: boolean = false;
    isCaptureFailed: boolean = false;
    isCheckMediaDevices: boolean = false;
    selectedVideo: boolean = false;
    selectedAudio: boolean = false;
    isAnalyzeMicLevel: boolean = false;

    screenScale: number = 1.0;

    // マイク入力レベル取得用の音声ストリーム
    micVolumeStream: MediaStream|undefined = undefined;
    // 入力レベルを計算するためのProcessor
    micVolumeProcessor: any = undefined;

    // リモート側映像のカーソル描画用メソッドを呼び出すための参照を保持する
    refRemoteVideoRedrawCursorMethod: {[key: string]: any} = {};

    // #920 startCall中
    callStartingId: number = 0;

    // #409
    // ビデオ通話設定前の情報保存用
    pre_videoDeviceId: string = "";
    pre_audioInputDeviceId : string = "";
    pre_audioOutputDeviceId: string = "";

    //TODO 親からうまく取得できない
    public get isUseTurnServer():boolean {
        return this.useTurnServer;
    }

    constructor(props: Props) {
        super(props);

        this.screenScale = props.screenScale;

        const iOSVersion = WebrtcService.getiOSVersion();
        if (iOSVersion !== -1 && (iOSVersion < 13)) {
            this.isIOSScrollFix = true;
        }

        if (iOSVersion > 0) {
            // カメラの縦、横切り替えにより指定する解像度を変更するための判別用のフラグ
            if (window.innerWidth < window.innerHeight) {
                this.isLandscape = false;
            } else {
                this.isLandscape = true;
            }
        }

        // const displayNameList:{[key: string]: string} = {};
        // props.roomUserList.forEach((roomUser) => {
        //     displayNameList[roomUser.wrId] = roomUser.name;
        // });

        this.state = {
            sessionId: props.sessionId,
            isCall: props.isCall,
            remotePeerIdList: {},
            remoteStreamList: {},
            remoteVideoUpdateTimeList: [],
            videoEnable : true,
            audioEnable : true,
            hasVideoDevice : false,
            hasAudioDevice : false,
            width : controlVideoWidth,
            height : controlVideoHeight,
            localStreamResolutionMode : 0,
            openMicSelect : false,
            audioInputDeviceId : "",
            isShow : props.isShow,
            windowModeList: {},
            isOpenDeviceSelect: false,
            audioOutputDeviceId: "",
            videoDeviceId: "",
        }
    }

    private jfsClient = JfsClient.getInstance();
    private webRTCClient =  this.jfsClient.webRTCClient;

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.sessionId !== this.props.sessionId) {
            logInfo("componentDidUpdate: sessionId:" + prevProps.sessionId + " -> " + this.props.sessionId);
            this.setState({
                sessionId: this.props.sessionId,
            });
        }

        if (prevProps.isCall !== this.props.isCall) {
            logInfo("componentDidUpdate: isCall:" + prevProps.isCall + " -> " + this.props.isCall);
            if (this.props.isCall) {
                logInfo("componentDidUpdate: startCall()")
                this.startCall();
            } else {
                logInfo("componentDidUpdate: stopCall()")
                this.stopCall();
            }

            this.setState({
                isCall: this.props.isCall,
            });
        }

        if (prevProps.isShow !== this.props.isShow) {
            logInfo("componentDidUpdate: isShow:" + prevProps.isShow + " -> " + this.props.isShow);
            this.setState({
                isShow: this.props.isShow,
            });
        }

        if (prevProps.screenScale !== this.props.screenScale) {
            logInfo("componentDidUpdate: screenScale:" + prevProps.screenScale + " -> " + this.props.screenScale);
            this.screenScale = this.props.screenScale;
            const video = document.getElementById(this.props.sessionId + "-video") as HTMLVideoElement;
            if (video) {
                logInfo("componentDidUpdate: fitScaleWindow(" + this.props.sessionId + "-video, true)");
                this.fitScaleWindow(video, true);
            }

            Object.keys(this.state.remotePeerIdList).forEach(key => {
                try {
                    const sessionId = this.getSessionIdFromPeerId(key);
                    const video = document.getElementById(sessionId + "-video") as HTMLVideoElement;
                    if (video) {
                        logInfo("componentDidUpdate: fitScaleWindow(" + sessionId + "-video, true)");
                        this.fitScaleWindow(video, true);
                    }
                } catch(e) {
                    logErr('componentDidUpdate: error:');
                    logException(e);
                }
            });
        }
        
        if (prevProps.roomId !== "" && this.props.roomId !== "") {
            if (prevProps.roomId !== this.props.roomId) {
                logInfo("componentDidUpdate: roomId:" + prevProps.roomId + " -> " + this.props.roomId);
                logInfo("componentDidUpdate: stopCall()");
                this.stopCall();
                logInfo("componentDidUpdate: startCall()");
                this.startCall();
            }
        }

        if (prevProps.isRetryCall === false && this.props.isRetryCall === true) {
            logInfo("componentDidUpdate: retryCall : " + this.props.roomId);
            logInfo("componentDidUpdate: stopCall()");
            this.stopCall();
            logInfo("componentDidUpdate: startRetryCall()");
            this.startRetryCall();
        }

        /*******************************************
        // for Debug (webrtc retry : skyway close 対応)
        //if (prevProps.debugSend === 0 && this.props.debugSend !== 0) {
        if (this.props.debugSend !== 0 && this.props.debugSend !== prevProps.debugSend) {
            logInfo("componentDidUpdate: debugSend : " + this.props.debugSend);
            if(this.props.debugSend === 1) {
                if(this.connectRoom !== undefined) {
                    logInfo("componentDidUpdate: debugSend start : " + this.props.roomId);
                    this.connectRoom.send("irregular_close_debug");

                    // 自分用
                    if (this.peer === undefined || this.peer === null) {
                        logInfo('WR_TRACE_JOINSTS ： 意図しないclose_debug発生失敗（自分用） this.peer is null');
                    } else {
                        logInfo('WR_TRACE_JOINSTS ： 意図しないclose_debug発生（自分用） sid=[' + this.state.sessionId+"] wid=["+this.peer.id+"]");
                        this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "irregular_close", this.props.roomId);
                    }
                }
            }
        }
        **********************************************/

        if (prevProps.openDeviceSelect !== this.props.openDeviceSelect){
            logInfo("componentDidUpdate: openDeviceSelect:" + prevProps.openDeviceSelect + " -> " + this.props.openDeviceSelect);
            // 呼び出し元で、値を変更させれた場合には、常にデバイス選択画面を表示する。
            // 呼び出し元から閉じる操作は行わないことが前提。
            // デバイス一覧を更新するため、setMediaList 内で isOpenDeviceSelect: true を行うように変更
            // #409
            if(this.props.openDeviceSelect) {
                this.pre_videoDeviceId = this.state.videoDeviceId;
                this.pre_audioInputDeviceId = this.state.audioInputDeviceId;
                this.pre_audioOutputDeviceId = this.state.audioOutputDeviceId;
                logInfo("componentDidUpdate: prqData ["+this.pre_videoDeviceId+"] ["+this.pre_audioInputDeviceId+"] ["+this.pre_audioOutputDeviceId+"]");              
            }
            this.setMediaList();
        }

        if (prevState.isOpenDeviceSelect !== this.state.isOpenDeviceSelect) {
            logInfo("componentDidUpdate: isOpenDeviceSelect:" + prevState.isOpenDeviceSelect + " -> " + this.state.isOpenDeviceSelect);
            WebrtcService.isOpenDeviceSelect = this.state.isOpenDeviceSelect;
            this.props.sendSetGhost(this.state.isOpenDeviceSelect);
            if (this.state.isOpenDeviceSelect) {
                this.setMediaList();
            } else {
                this.props.sendDeviceSelectedType(WebrtcService.getDeviceSelectedType());
            }
        }

        if( this.props.isNoVideo ){
            if (prevProps.isNoVideo !== this.props.isNoVideo) {
                logInfo("componentDidUpdate: isNoVideo:" + prevProps.isNoVideo + " -> " + this.props.isNoVideo);
                this.setVideoStreamEnable(false);
            }
        }
        else {
            if (prevProps.videoMute !== this.props.videoMute) {
                logInfo("componentDidUpdate: videoMute:" + prevProps.videoMute + " -> " + this.props.videoMute);
                this.setVideoStreamEnable(this.props.videoMute ? false : true);
            }
        }

        if (prevProps.audioMute !== this.props.audioMute) {
            logInfo("componentDidUpdate: audioMute:" + prevProps.audioMute + " -> " + this.props.audioMute);
            if(this.mediaStream === null || this.mediaStream === undefined){
                logInfo("componentDidUpdate: this.mediaStream:" + this.mediaStream === null ? "null" : "undefined");
            } else {
                if(this.mediaStream?.getAudioTracks() === null){
                    logInfo("componentDidUpdate: audioTracks() is null");
                } else {
                    if(this.mediaStream?.getAudioTracks().length !== 1){
                        logInfo("componentDidUpdate: audioTracks().length:" + this.mediaStream?.getAudioTracks().length);
                    }
                }
            }
            this.setAudioStreamEnable(this.props.audioMute ? false : true);
        }

        if (prevProps.analyzeMicMode !== this.props.analyzeMicMode) {
            logInfo("componentDidUpdate: analyzeMicMode:" + prevProps.analyzeMicMode + " -> " + this.props.analyzeMicMode);
            if (this.props.analyzeMicMode === 0) {
                if (this.isAnalyzeMicLevel) {
                    logInfo("componentDidUpdate: stopMicVolumeAudio()");
                    this.stopMicVolumeAudio();
                }
            } else if (this.props.analyzeMicMode === 1 && this.isCheckMediaDevices) {
                // デバイスチェック後のみ開始する
                if (this.isAnalyzeMicLevel === false) {
                    // iPad実機で確認したところ、
                    // 音声状態を取得するための下記処理を実行後、
                    // 通話時にマイクから音声が拾えなくなったためiOSの場合、無効化している。
                    if (WebrtcService.isiOS() === false) {
                        logInfo("componentDidUpdate: startAnalyzeMic()");
                        this.startAnalyzeMic();
                    }
                }
            }
        }

        if (prevProps.isScreenShare !== this.props.isScreenShare) {
            logInfo("componentDidUpdate: isScreenShare:" + prevProps.isScreenShare + " -> " + this.props.isScreenShare);
            if (this.props.isScreenShare) {
                if ( this.props.screenShareMode === ScreenShareMode.FullScreen) {
                    logInfo("componentDidUpdate: 画面共有開始 in FullScreen");
                    this.createScreenShareVideoElement(this.state.sessionId);
                    this.setScreenShareStream();
                } else if ( this.props.screenShareMode === ScreenShareMode.FloatingElement) {
                    logInfo("componentDidUpdate: 画面共有開始 in FloatingElement");
                    this.props.onScreenShareVideoLoaded(false, this.state.sessionId);
                    this.createScreenShareVideoElement(this.state.sessionId);
                    this.setScreenShareStream();
                }
            } else {
                logInfo("componentDidUpdate: 画面共有終了");
                this.props.onScreenShareVideoLoaded(false, this.props.screenShareSessionId);
                if (this.mediaStream) {
                    const useVideoDevice = this.state.hasVideoDevice && this.state.videoDeviceId !== "";
                    if(useVideoDevice) {
                        logInfo("componentDidUpdate: replaceStream(this.mediaStream)");
                        this.connectRoom.replaceStream(this.mediaStream);
                    } else {
                        logInfo("componentDidUpdate: not use VideoDevice : audio length="+this.mediaStream.getAudioTracks().length+" video length="+this.mediaStream.getVideoTracks().length);
                        this.stopCallKeepScreenShareStream(false);
                        //this.setMediaStream(false);
                        //this.joinRoom(this.mediaStream);
                        this.startCall();
                    }
                }

                // 相手側に画面共有停止後に、画面共有の映像が表示され続けないようにするため
                // いったん画面ミュートの状態にしている
                if (this.screenShareStream) {
                    logInfo("componentDidUpdate: this.screenShareStream: track.enabled:false");
                    this.screenShareStream.getVideoTracks().forEach(track => track.enabled = false);
                }

                // 画面ミュート状態で少し処理を待たないと、画面ミュートに変わらない場合があったため
                // setTimeoutを使用している
                setTimeout(() => {
                    this.removeScreenShareVideoElementFromSessionId(this.state.sessionId);    
                }, 100);
            }
        }

        if (prevProps.screenShareSessionId !== this.props.screenShareSessionId) {   
            logInfo("componentDidUpdate: screenShareSessionId:" + prevProps.screenShareSessionId + " -> " + this.props.screenShareSessionId);
            this.removeScreenShareVideoElementFromSessionId(prevProps.screenShareSessionId);
            logInfo("componentDidUpdate: onScreenShareVideoLoaded()")
            this.props.onScreenShareVideoLoaded(false, this.props.screenShareSessionId);
        }

        if (prevProps.checkMediaDevices !== this.props.checkMediaDevices) {
            logInfo("componentDidUpdate: checkMediaDevices:" + prevProps.checkMediaDevices + " -> " + this.props.checkMediaDevices);
            if (this.props.checkMediaDevices) {
                this.checkMediaDevices().then(() => {
                    if (this.props.analyzeMicMode === 1 && this.isAnalyzeMicLevel === false) {
                        // iPad実機で確認したところ、
                        // 音声状態を取得するための下記処理を実行後、
                        // 通話時にマイクから音声が拾えなくなったためiOSの場合、無効化している。
                        if (WebrtcService.isiOS() === false) {
                            logInfo("componentDidUpdate: startAnalyzeMic()")
                            this.startAnalyzeMic();
                        }
                    }
                });
            }
        }

        if (prevState.remotePeerIdList !== this.state.remotePeerIdList) {
            logInfo("componentDidUpdate: remotePeerIdList: changed");
            this.setRemoteStream(prevState);
        }

        // if (prevProps.roomUserList !== this.props.roomUserList) {
        //     const displayNameList:{[key: string]: string} = {};
        //     this.props.roomUserList.forEach((roomUser) => {
        //         displayNameList[roomUser.wrId] = roomUser.name;
        //     });
        // }

        // #11007
        // ・ビデオ通話設定ダイアログを表示させたときにプレビューも開始する
        // ・デバイスを変更した際、プレビューも変更されたデバイスで再開始する
        // (this.props.openDeviceSelect は2回表示させると1回しか true にならない？(トグル的に使用している？)ので
        //  this.state.isOpenDeviceSelect で表示状態を見る)
        if (prevState.isOpenDeviceSelect !== this.state.isOpenDeviceSelect && this.state.isOpenDeviceSelect === true) {
            logInfo("componentDidUpdate: isOpenDeviceSelect:" + prevState.isOpenDeviceSelect + " -> " + this.state.isOpenDeviceSelect + " && isOpenDeviceSelect:true");

            // ダイアログ表示時
            logInfo("componentDidUpdate: プレビュー開始 : ダイアログ表示時 : this.isCheckMediaDevices:" + this.isCheckMediaDevices);
            
            // CheckMediaDevice 済のときに開始する
            if (this.isCheckMediaDevices === true) {
                this.handlePreviewDeviceSelect();
            }
        }
        else if (prevState.videoDeviceId !== this.state.videoDeviceId ||
            prevState.audioInputDeviceId !== this.state.audioInputDeviceId ||
            prevState.audioOutputDeviceId !== this.state.audioOutputDeviceId) {

            // デバイス変更時
            logInfo("componentDidUpdate: プレビュー開始 : デバイス変更時 : this.props.openDeviceSelect:" + this.props.openDeviceSelect + " this.state.isOpenDeviceSelect:" + this.state.isOpenDeviceSelect + " this.isCheckMediaDevices:" + this.isCheckMediaDevices);
            if(prevState.videoDeviceId !== this.state.videoDeviceId){
                logInfo("componentDidUpdate: videoDeviceId:" + prevState.videoDeviceId + " -> " + this.state.videoDeviceId);
            }
            if(prevState.audioInputDeviceId !== this.state.audioInputDeviceId){
                logInfo("componentDidUpdate: audioInputDeviceId:" + prevState.audioInputDeviceId + " -> " + this.state.audioInputDeviceId);
            }
            if(prevState.audioOutputDeviceId !== this.state.audioOutputDeviceId){
                logInfo("componentDidUpdate: audioOutputDeviceId:" + prevState.audioOutputDeviceId + " -> " + this.state.audioOutputDeviceId);
                if (this.checkSetSinkIdMethod()) {
                    AudioPlayer.setSinkId(this.state.audioOutputDeviceId);
                    WebrtcService.setWebRtcAudioSinkId(this.state.audioOutputDeviceId);
                }
            }

            // デバイス選択画面が表示されていて CheckMediaDevice 済のときに開始する
            if (this.state.isOpenDeviceSelect === true && this.isCheckMediaDevices === true) {
                this.handlePreviewDeviceSelect();
            }
        }
    }

    // 画面共有の動作確認用コード
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            // ESCキー押下時の処理
            if (this.props.isScreenShare) {
                logInfo("escFunction: removeScreenShareVideoElement()");
                Object.keys(this.state.remotePeerIdList).forEach(key => {
                    try {
                        this.removeScreenShareVideoElement(key);
                    } catch(e) {
                        logErr('escFunction: error:');
                        logException(e);
                    }
                });
                this.props.handleScreenShare(false, ScreenShareMode.FullScreen, this.props.roomId);
            }
            logInfo('escFunction: onShareScreenOpen()');
            this.props.onShareScreenOpen(false);
        }
    };

    componentDidMount() {
        // if (WebrtcService.isDeviceSelected()) {
        //     this.initCall();
        // }

        if (this.state.isCall && WebrtcService.isDeviceSelected()) {
            logInfo('componentDidMount: startCall()');
            this.startCall();
        }

        if (this.props.checkMediaDevices) {
            this.checkMediaDevices().then(() => {
                if (this.props.analyzeMicMode === 1 && this.isAnalyzeMicLevel === false) {
                    // iPad実機で確認したところ、
                    // 音声状態を取得するための下記処理を実行後、
                    // 通話時にマイクから音声が拾えなくなったためiOSの場合、無効化している。
                    if (WebrtcService.isiOS() === false) {
                        logInfo('componentDidMount: startAnalyzeMic()');
                        this.startAnalyzeMic();
                    }
                }
            }).catch((error) => {
                logErr('componentDidMount: checkMediaDevices() error:');
                logException(error);
            });
        }

        window.addEventListener('orientationchange', this.handleOrientationChange);

        // 画面共有の動作確認用コード
        //document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.handleOrientationChange);
        //document.removeEventListener("keydown", this.escFunction);
        logInfo('componentWillUnmount: stopCall()');
        this.stopCall();
        if (this.isAnalyzeMicLevel) {
            logInfo('componentWillUnmount: stopMicVolumeAudio()');
            this.stopMicVolumeAudio();
        }
    }

    getSessionIdFromPeerId = (peerId: string) => {
        const userDataList = WebrtcService.getUserDataList();
        let ret = null;
        
        userDataList.forEach(userData => {
            if (userData.webRtcPeerId === peerId) {
                ret = userData.id;
                return;
            }
        });

        return ret;
    }

    setRemoteStream = async (prevState: State) => {
        const peerIdListKeys = Object.keys(this.state.remotePeerIdList);
        const prePeerIdListKeys = Object.keys(prevState.remotePeerIdList);
        const peerIdListSize = peerIdListKeys.length;
        const prePeerIdListSize = prePeerIdListKeys.length;
        if (prevState.remotePeerIdList !== this.state.remotePeerIdList) {
            logInfo("setRemoteStream: remote peer id list change.");

            if (peerIdListSize <= prePeerIdListSize) {
                logInfo("setRemoteStream: id list down");
            } else {
                const remotePeerId = peerIdListKeys[peerIdListSize - 1];
                const stream = this.state.remoteStreamList[remotePeerId];

                let sessionId = this.getSessionIdFromPeerId(remotePeerId);
                const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
                let loopCount = 0;
                while(sessionId === null) {
                    // logInfo("wait sessionId " + loopCount);
                    await sleep(100);
                    sessionId = this.getSessionIdFromPeerId(remotePeerId);
                    if (sessionId) {
                        break;
                    }

                    loopCount++;
                    if (loopCount > 30) {
                        break;
                    }
                }
    
                if (sessionId === null) {
                    logWarn("setRemoteStream: sessionId === null ");
                    return;
                }

                logInfo("setRemoteStream: createVideoAudioElement:sessionId:" + sessionId);

                // ビデオ通話用のvideo、audioエレメントを作成する
                this.createVideoAudioElement('' + sessionId);

                const remoteVideo = document.getElementById(sessionId + "-video");
                // audioをvideoより先に設定するのと、onloadedmetadataをvideo, audio で分離することで
                // audio再生が失敗することを少しでも避けるようにしている
                try {
                    const remoteAudio = document.getElementById(sessionId + "-audio");
                    const newAudio : HTMLAudioElement = (remoteAudio as HTMLAudioElement);
                    if (newAudio.srcObject !== stream) {
                        newAudio.srcObject = new MediaStream(stream.getAudioTracks());
                        if (this.state.audioOutputDeviceId !== "") {
                            logInfo("setRemoteStream: setSinkId:audioOutputDeviceId:" + this.state.audioOutputDeviceId) ;
                            (newAudio as any).setSinkId(this.state.audioOutputDeviceId).catch((e: any) => {
                                if (e.message && e.message.toLowerCase().indexOf("not found") >= 0) {
                                    logErr("setRemoteStream: setSinkId error:");
                                    logException(e);
                                }
                            });
                        }
                    }
                } catch(e) {
                    logErr('setRemoteStream: newAudio error:');
                    logException(e);
                }

                try {
                    const newVideo : HTMLVideoElementMod = (remoteVideo as HTMLVideoElementMod);
                    if (stream.getVideoTracks().length > 0) {
                        newVideo.srcObject = new MediaStream(stream.getVideoTracks());
                        newVideo.playsInline = true;
                        // ここで下記メソッドを実行するとWebrtcVideoComponentでエラーが発生する場合がある。
                        // newVideo.play();
                    }
                } catch(e) {
                    logErr('setRemoteStream: newVideo error:');
                    logException(e);
                }

                logInfo('setRemoteStream: onRemoteStreamLoading()');
                this.props.onRemoteStreamLoading(sessionId);
            }
        }
    }

    // openPeer = async (receiveMode : boolean) => {
    //     logInfo('openPeer: getPeer()');
    //     await WebrtcService.getPeer().then((peer) => {
    //         this.peer = peer;
    //     }).catch((error) => {
    //         logErr('openPeer: getPeer() error:');
    //         logException(error);
    //     });
    // }

    setMediaList = async () => {
        try {
            // iOS Safari13.1 で enumerateDevices()でデバイス情報を取得するため、1回 getUserMediaを呼び出している。
            // Safari13.1未満、Chromeでは、getUserMediaを呼び出す前からデバイス情報は取得できる。
            if (this.isCheckMediaDevices === false) {
                const constraints : any = {video: true, audio: true};
                await navigator.mediaDevices.getUserMedia(constraints)
                        .then((stream) => {
                    logInfo("setMediaList: stream:track.stop()");
                    stream.getTracks().forEach(track => track.stop());
                }).catch((error: DOMException) => {
                    logErr('setMediaList: mediaDevices.getUserMedia() error:');
                    logException(error);
                });
            }

            await navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => { // 成功時
                let videoIdIndex = 0;
                let audioInputIdIndex = 0;
                let audioOutputIdIndex = 0;

                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if (deviceInfo.deviceId === "" && deviceInfo.label === "") {
                        continue;
                    }
            
                    if (deviceInfo.kind === 'audioinput') {
                        logInfo("setMediaList: audioInputDeviceInfoList["+audioInputIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId)
                        this.audioInputDeviceInfoList[audioInputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioInputIdIndex++;
                    } else if (deviceInfo.kind === 'audiooutput') {
                        logInfo("setMediaList: audioOutputDeviceInfoList["+audioOutputIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId)
                        this.audioOutputDeviceInfoList[audioOutputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioOutputIdIndex++;
                    } else if (deviceInfo.kind === 'videoinput') {
                        if (deviceInfo.label.match(" IR ") && !deviceInfo.label.match(" HD IR UVC ")) {
                            continue;
                        }
                        logInfo("setMediaList: videoInputDeviceInfoList["+videoIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId)
                        this.videoInputDeviceInfoList[videoIdIndex] = deviceInfo as MediaDeviceInfo;
                        videoIdIndex++;
                    }
                }
            }).catch((err) => { // エラー発生時
                logErr('setMediaList: mediaDevices.enumerateDevices() error:');
                logException(err);
            });

            // #821 調査用ログ
            logInfo("setMediaList: set state isOpenDeviceSelect true");
            this.setState({isOpenDeviceSelect: true});
        } catch(err) {
            logErr('setMediaList: error:');
            logException(err);
        }        
    }

    checkMediaDevices = async () => {
        // if (this.isCheckMediaDevices) {
        //     return;
        // }

        try {
            if (WebrtcService.isCalledGetUserMedia === false) {
                let hasDeviceInfos = false;
                await navigator.mediaDevices.enumerateDevices()
                .then((deviceInfos) => { // 成功時
                    if (deviceInfos.length > 0) {
                        if (deviceInfos[0].deviceId !== "") {
                            hasDeviceInfos = true;
                        }
                    }
                }).catch((err) => { // エラー発生時
                    logErr('checkMediaDevices: mediaDevice.enumerateDevices() error:');
                    logException(err);
                });

                if (hasDeviceInfos === false) {
                    // iOS Safari13.1 で enumerateDevices()でデバイス情報を取得するため、1回 getUserMediaを呼び出している。
                    // Safari13.1未満、Chromeでは、getUserMediaを呼び出す前からデバイス情報は取得できる。
                    const constraints : any = {video: true, audio: true};
                    await navigator.mediaDevices.getUserMedia(constraints)
                            .then((stream) => {
                        // Success
                        logInfo("checkMediaDevices: stream:track.stop()");
                        stream.getTracks().forEach(track => track.stop());
                    }).catch((error: DOMException) => {
                        logErr('checkMediaDevices: mediaDevice.getUserMedia() error:');
                        logException(error);
                   });
    
                    WebrtcService.isCalledGetUserMedia = true;
                }
            }

            await navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => { // 成功時
                let videoIdIndex = 0;
                let audioInputIdIndex = 0;
                let audioOutputIdIndex = 0;
                let hasVideoDevice = false;
                let hasAudioDevice = false;
                let hasAudioOutputDevice = false;

                // 過去に保存したカメラ、マイクデバイスが
                // 存在する場合、デフォルトでそのデバイスを使用し、
                // デバイス選択画面を表示しない
                let videoDeviceId = localStorage.getItem("videoDeviceId");
                let audioDeviceId = localStorage.getItem("audioInputDeviceId");
                let audioOutputDeviceId = localStorage.getItem("audioOutputDeviceId");
                // 未選択を選んだ場合も過去に選択したものとして扱っている
                let hasSaveVideoDevice = videoDeviceId === "" ? true : false;
                let hasSaveAudioInputDevice = audioDeviceId === "" ? true : false;
                let hasSaveAudioOutputDevice = audioOutputDeviceId === "" ? true : false;
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if (deviceInfo.deviceId === "" && deviceInfo.label === "") {
                        continue;
                    }
                    // logInfo(deviceInfo.kind + " : " + deviceInfo.label + " : " + deviceInfo.deviceId);
            
                    if (deviceInfo.kind === 'audioinput') {
                        // logInfo("audio device found");

                        // 保存されていたデバイスと一致した場合には、そのデバイスをデフォルトで選択状態とするため
                        // デバイスのインデックスを保持する
                        if (audioDeviceId) {
                            if (deviceInfo.deviceId === audioDeviceId) {
                                this.currentAudioInputDeviceIdIndex = audioInputIdIndex;
                                hasSaveAudioInputDevice = true;
                                logInfo("checkMediaDevices: select audioInputDevice["+audioInputIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId);
                                localStorage.setItem("audioInputDeviceLabel", deviceInfo.label);
                            }
                        }

                        this.audioInputDeviceInfoList[audioInputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioInputIdIndex++;
                        hasAudioDevice = true;
                    } else if (deviceInfo.kind === 'audiooutput') {
                        //logInfo("audioOutputDeviceInfoList["+audioOutputIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId);
                        if (audioOutputDeviceId) {
                            if (deviceInfo.deviceId === audioOutputDeviceId) {
                                this.currentAudioOutputDeviceIdIndex = audioOutputIdIndex;
                                hasSaveAudioOutputDevice = true;
                                logInfo("checkMediaDevices: select audioOutputDevice["+audioOutputIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId);
                                localStorage.setItem("audioOutputDeviceLabel", deviceInfo.label);
                            }
                        }

                        this.audioOutputDeviceInfoList[audioOutputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioOutputIdIndex++;
                        hasAudioOutputDevice = true;
                    } else if (deviceInfo.kind === 'videoinput') {
                        // logInfo(deviceInfo.label);
                        
                        // Surfaceに搭載されている赤外線カメラを選択項目に入れないようにしている
                        if (deviceInfo.label.match(" IR ") && !deviceInfo.label.match(" HD IR UVC ")) {
                            continue;
                        }

                        // 保存されていたデバイスと一致した場合には、そのデバイスをデフォルトで選択状態とするため
                        // デバイスのインデックスを保持する
                        if (videoDeviceId) {
                            if (deviceInfo.deviceId === videoDeviceId) {
                                this.currentVideoDeviceIdIndex = videoIdIndex;
                                hasSaveVideoDevice = true;
                                logInfo("checkMediaDevices: select videoDeviceIdList["+videoIdIndex.toString()+"]:"+deviceInfo.label+":"+deviceInfo.deviceId);
                                localStorage.setItem("videoDeviceLabel", deviceInfo.label);
                            }
                        }

                        this.videoDeviceIdList[videoIdIndex] = deviceInfo.deviceId;
                        this.videoInputDeviceInfoList[videoIdIndex] = deviceInfo as MediaDeviceInfo;
                        videoIdIndex++;
                        hasVideoDevice = true;
                    }
                }

                // iPad SafariなどデバイスIDがブラウザ起動ごとに変更されるものの対応
                // 保存済みのデバイスIDが一致しなかった場合、
                // ラベルで検索する
                if (hasVideoDevice && (hasSaveVideoDevice === false)) {
                    const index = this.getDeviceLabelIndex("videoDeviceLabel", this.videoInputDeviceInfoList);
                    if (index > -1) {
                        videoDeviceId = this.videoInputDeviceInfoList[index].deviceId;
                        logInfo("checkMediaDevices: select videoInputDeviceInfoList["+index.toString()+"]:"+this.videoInputDeviceInfoList[index].label+":"+videoDeviceId);
                        localStorage.setItem("videoDeviceId", videoDeviceId);
                        this.currentVideoDeviceIdIndex = index;
                        hasSaveVideoDevice = true;
                    }
                }

                if (hasAudioDevice && (hasSaveAudioInputDevice === false)) {
                    const index = this.getDeviceLabelIndex("audioInputDeviceLabel", this.audioInputDeviceInfoList);
                    if (index > -1) {
                        audioDeviceId = this.audioInputDeviceInfoList[index].deviceId;
                        logInfo("checkMediaDevices: select audioInputDeviceInfoList["+index.toString()+"]:"+this.audioInputDeviceInfoList[index].label+":"+audioDeviceId);
                        localStorage.setItem("audioInputDeviceId", audioDeviceId);
                        this.currentAudioInputDeviceIdIndex = index;
                        hasSaveAudioInputDevice = true;
                    }
                }

                if (hasAudioOutputDevice && (hasSaveAudioOutputDevice === false)) {
                    const index = this.getDeviceLabelIndex("audioOutputDeviceLabel", this.audioOutputDeviceInfoList);
                    if (index > -1) {
                        audioOutputDeviceId = this.audioOutputDeviceInfoList[index].deviceId;
                        logInfo("checkMediaDevices: select audioOutputDeviceLabel["+index.toString()+"]:"+this.audioOutputDeviceInfoList[index].label+":"+audioOutputDeviceId);
                        localStorage.setItem("audioOutputDeviceId", audioOutputDeviceId);
                        this.currentAudioOutputDeviceIdIndex = index;
                        hasSaveAudioOutputDevice = true;
                    }
                }

                let isOpenDeviceSelect = false;
                if (hasVideoDevice || hasAudioDevice || hasAudioOutputDevice) {
                    // カメラかマイクのどちらかのデバイスを持っている
                    if (hasSaveVideoDevice && hasSaveAudioInputDevice && hasSaveAudioOutputDevice) {
                        // 保存済みのデバイスIDが一致した場合
                    } else {
                        // 保存済みのデバイスIDが一致しなかった場合
                        logWarn("checkMediaDevices: 保存済みのデバイスIDと一致しない isOpenDeviceSelect:true");
                        isOpenDeviceSelect = true;
                    }
                } else {
                    logWarn("checkMediaDevices: カメラ無&&マイク無");
                }

                // #821 調査用ログ
                logInfo("checkMediaDevices: set state isOpenDeviceSelect "+isOpenDeviceSelect);
                this.setState({
                    hasAudioDevice : hasAudioDevice,
                    hasVideoDevice : hasVideoDevice,
                    audioInputDeviceId : audioDeviceId && hasSaveAudioInputDevice ? audioDeviceId : "",
                    audioOutputDeviceId: audioOutputDeviceId && hasSaveAudioOutputDevice ? audioOutputDeviceId : "",
                    videoDeviceId : videoDeviceId && hasSaveVideoDevice ? videoDeviceId : "",
                    isOpenDeviceSelect : isOpenDeviceSelect,
                });

                // 効果音の出力先設定
                AudioPlayer.setSinkId(audioOutputDeviceId && hasSaveAudioOutputDevice ? audioOutputDeviceId : "");

                WebrtcService.setDeviceSelected(true);
                if (isOpenDeviceSelect === false) {
                    this.checkDeviceStream(videoDeviceId, audioDeviceId, 
                        () => {
                            this.props.sendSetGhost(false);
                            this.props.sendDeviceSelectedType(WebrtcService.getDeviceSelectedType());
                        }, 
                        () => {this.setState({isOpenDeviceSelect : true})});
                }
            }).catch((err) => { // エラー発生時
                logErr('checkMediaDevices: mediaDevices.enumerateDevices() error:');
                logException(err);
            });
        } catch(err) {
            logErr('checkMediaDevices: error:');
            logException(err);
        }        

        this.isCheckMediaDevices = true;
    }

    getDeviceLabelIndex = (storageKey: string, deviceInfoList: MediaDeviceInfo[]) => {
        const deviceLabel = localStorage.getItem(storageKey);
        if (deviceLabel) {
            for (let i = 0; i < deviceInfoList.length; i++) {
                if (deviceLabel === deviceInfoList[i].label) {
                    return i;
                }
            }
        }

        return -1;
    }

    startCall = async () => {
        // CheckMediaDevices でデバイス有無判定が完了するまで、最大10秒間待たせる
        const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
        // let loopCount = 0;
        while (this.isCheckMediaDevices === false || WebrtcService.isOpenDeviceSelect || WebrtcService.isDeviceSelected() === false) {
            await sleep(1000);
            // loopCount++;
        }

        // #920
        // setMediaStream(getUserMedia)中に別のstartCallを受け付けたかチェックするために、時間とroomIdを保存
        let startedId = new Date().getTime();
        this.callStartingId = startedId;
        let startingRoomId: string = "";
        startingRoomId = this.props.roomId;
        let isStarted = this.connectRoom !== undefined ? true : false;
        logInfo("[WR_TRACE_JOINSTS] S_CALL startCall check connectRoom=["+isStarted+"] props.roomId=["+this.props.roomId+"] callStartingId=["+this.callStartingId+"] startedId=["+startedId+"]");

        // iPad対応-11 効果音再生中ならば再生完了を待つ
        if (WebrtcService.isiOS() || WebrtcService.isAndroid()) {
            let playingAudio: HTMLAudioElement|null = AudioPlayer.getPlayingSound();
            let lcnt = 0;
            while (playingAudio !== null) {
                logWarn("PLAY_SOUND_TRACE startCall: 音再生中wait");
                await sleep(1000);
                playingAudio = AudioPlayer.getPlayingSound();
                if(++lcnt >= 5) {
                    logWarn("PLAY_SOUND_TRACE startCall: 音再生中wait retry over");
                    break;
                }
                // #920
                if(this.callStartingId !== startedId) {
                    break;
                }
            }
            // #920
            if(this.callStartingId !== startedId) {
                logInfo("[WR_TRACE_JOINSTS] S_CALL skip startCall. by startedId iOS startingRoomId canceled. startingRoomId=["+startingRoomId+"] props.roomId=["+this.props.roomId+"] startedId=["+startedId+"] this.callStartingId=["+this.callStartingId+"]");
                return;
            }
        }

        if (this.connectRoom !== undefined && this.connectRoom.getVideoTracks !== undefined) {
            logWarn("startCall: this.connectRoom !== undefined && this.connectRoom.getVideoTracks !== undefined");
            return;
        }

        let isReceiveMode = false;
        if (this.props.roomId.indexOf("-") >= 0) {
            let split = this.props.roomId.split("-");
            const index = split.length - 1;
            if (split[index]) {
                if (split[index] === "receive" 
                    && this.props.roomId.replace("-receive", "") !== this.state.sessionId) {
                    logInfo("startCall: isReceiveMode:true");
                    isReceiveMode = true;
                }
            }
        }

        if (isReceiveMode === false) {
            // ビデオ通話用のvideo、audioエレメントを作成する
            logInfo("startCall: createVideoAudioElement("+this.state.sessionId+")");
            this.createVideoAudioElement(this.state.sessionId);

            if (this.state.hasVideoDevice || this.state.hasAudioDevice) {
                await this.setMediaStream(false);
            }
        }

        // setMediaStream(getUserMedia)中に別のstartCallを受け付けたかチェック
        //logInfo("[WR_TRACE_JOINSTS] S_CALL check startingRoomId ["+startingRoomId+"] props.roomId=["+this.props.roomId+"] startedId=["+startedId+"] this.callStartingId=["+this.callStartingId+"]");
        if(startingRoomId !== this.props.roomId || this.callStartingId !== startedId) {
            logInfo("[WR_TRACE_JOINSTS] S_CALL skip startCall. startingRoomId canceled. startingRoomId=["+startingRoomId+"] props.roomId=["+this.props.roomId+"] startedId=["+startedId+"] this.callStartingId=["+this.callStartingId+"]");
            // #920 MediaStream 開放
            if(this.props.roomId === "") {
                // if (this.peer) {
                if (this.webRTCClient.isConnectedSignalingServer()) {
                    logInfo("[WR_TRACE_JOINSTS] S_CALL startCall release MediaStream this.state.sessionId=["+this.state.sessionId+"]");
                } else {
                    logInfo("[WR_TRACE_JOINSTS] S_CALL startCall this.peer undefined and release MediaStream this.state.sessionId=["+this.state.sessionId+"]");
                }
                this.initVideoKeepScreenShareStream(this.state.sessionId, false);
                this.removeVideoAudioElementFromSessionId(this.state.sessionId);
                this.removeScreenShareVideoElementFromSessionId(this.state.sessionId);
            }
            return;
        }

        try {
            // const video: HTMLVideoElement = (document.getElementById(this.state.sessionId) as HTMLVideoElement);
            // video.play();

            let b_peerId = "";  // #747 追加対応：不要な配信をやめる
            // if (this.peer === undefined || this.peer.open === false) {
            if (this.webRTCClient === undefined || this.webRTCClient.checkConnectedSignalingServer() === false) {
                // if(this.peer !== undefined) b_peerId = this.peer.id;    // #747 追加対応：不要な配信をやめる
                if (this.webRTCClient !== undefined) b_peerId = this.webRTCClient.getPeerId();
                // ネットワーク切断後に復旧した場合の対応
                // await WebrtcService.reconnect();
                // await WebrtcService.getPeer().then((peer) => {
                //     this.peer = peer;
                // }).catch((err) => { // エラー発生時
                //     logErr('startCall: getPeer() error:');
                //     logException(err);
                // });
                await WebrtcService.destroyPeer();
                await WebrtcService.getWebRTCClient(-1);

                // if (this.peer) {
                if (this.webRTCClient.isConnectedSignalingServer()) {
                    // #747 追加対応：不要な配信をやめる
                    if (this.webRTCClient.getPeerId() !== b_peerId) {
                        // #747 myUserのwebrtcPeerIdが変更された可能性が高いので全員に通知
                        this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "peerChange", "");
                    }
                    //logInfo('startCall: joinRoom() after WebrtcService.reconnect  b_peerId=['+b_peerId+'] new=['+this.peer.id+']');
                    logInfo("[WR_TRACE_JOINSTS] S_CALL joinRoom() after WebrtcService.reconnect  b_peerId=["+b_peerId+"] new=["+this.webRTCClient.getPeerId()+"] startingRoomId ["+startingRoomId+"] props.roomId=["+this.props.roomId+"] startedId=["+startedId+"] this.callStartingId=["+this.callStartingId+"]");
                    this.joinRoom(this.mediaStream);
                }
            } else {
                //logInfo('startCall: joinRoom()');
                // logInfo("[WR_TRACE_JOINSTS] S_CALL joinRoom() startingRoomId ["+startingRoomId+"] props.roomId=["+this.props.roomId+"] startedId=["+startedId+"] this.callStartingId=["+this.callStartingId+"]");
                // this.joinRoom(this.mediaStream);
                const peerId = this.webRTCClient.getPeerId();
                //logInfo('startCall: joinRoom()');
                logInfo("[WR_TRACE_JOINSTS] S_CALL joinRoom() startingRoomId ["+startingRoomId+"] props.roomId=["+this.props.roomId+"] startedId=["+startedId+"] this.callStartingId=["+this.callStartingId+"] peerId=["+peerId+"]");
                this.joinRoom(this.mediaStream);
            }
        } catch(e) {
            logErr('startCall: error:');
            logException(e);
        }
    }

    // webrtc接続リトライ対応
    startRetryCall = async () => {
        if (this.webRTCClient.isConnectedSignalingServer() === true) {
            logInfo('startRetryCall: destroyPeer call peerId=['+this.webRTCClient.getPeerId()+"]");
            /* ここでやったら相手に伝わらない？？？ user.webRtcPeerIdにセット必要？？？
            await WebrtcService.destroyPeer();
            logInfo('startRetryCall: destroyPeer ret');
            this.peer = await WebrtcService.getPeer();
            logInfo('startRetryCall: destroyPeer getPeer peerId=['+this.peer.id+"]");
            */
            // this.peer = undefined;
            this.webRTCClient.disconnectSignalingServer();
        }
        this.startCall();
    }

    createVideoAudioElement = (elementId: string) => {
        logInfo('createVideoAudioElement start : id=['+elementId+']');
        const divElement = document.getElementById("webrtc-area-" + elementId);
        if (divElement) {
            // 既存のvideo, audio タグを消しておく
            const videoElements = divElement.getElementsByTagName("video");
            for (let i = 0; i < videoElements.length; i++) {
                if ((elementId + "-video") !== videoElements[i].id){
                    logInfo('removeChild videoElements');
                    divElement.removeChild(videoElements[i]);
                }
            }
            const audioElements = divElement.getElementsByTagName("audio");
            for (let i = 0; i < audioElements.length; i++) {
                if ((elementId + "-audio") !== audioElements[i].id){
                    logInfo('removeChild audioElements');
                    divElement.removeChild(audioElements[i]);
                }
            }

            let video: HTMLVideoElement = (document.getElementById(elementId + "-video") as HTMLVideoElement);
            if (video === undefined || video === null) {
                video = document.createElement("video");
                video.setAttribute("id", elementId + "-video");
                video.setAttribute("autoPlay", "");
                video.muted = true;
                video.setAttribute("playsInline", "");
                video.setAttribute("disablePictureInPicture", "");
                // 映像の中心部を拡大したように見せるための調整

                if( divElement.getAttribute("data-islargevideo") === "true" ){
                    video.width = parseInt(divElement.style.width.replace("px", "")) + LARGE_VIDEO_ADDJUST_GAIN;
                    video.height = parseInt(divElement.style.height.replace("px", "")) + LARGE_VIDEO_ADDJUST_GAIN;
                    video.style.zIndex = `${Number(divElement.style.zIndex) + 1}`;
                } else {
                    video.width = parseInt(divElement.style.width.replace("px", "")) + VIDEO_ADDJUST_GAIN;
                    video.height = parseInt(divElement.style.height.replace("px", "")) + VIDEO_ADDJUST_GAIN;
                    video.style.zIndex = `${Number(divElement.style.zIndex) + 1}`;
                }
                // video.setAttribute("style", "position: absolute; top:0; width: 100vw; height: 100vh; background: #FFF; z-index: 9999999999;");

                divElement.appendChild(video);

                this.setVideoOnloadedMetaData(video);
                logInfo('videoElements setting end');
            } else {
                // 再接続時に映像が大きくなった場合
                if( divElement.getAttribute("data-islargevideo") === "true" ){
                    video.width = parseInt(divElement.style.width.replace("px", "")) + LARGE_VIDEO_ADDJUST_GAIN;
                    video.height = parseInt(divElement.style.height.replace("px", "")) + LARGE_VIDEO_ADDJUST_GAIN;
                    video.style.zIndex = `${Number(divElement.style.zIndex) + 1}`;
                } else {
                    video.width = parseInt(divElement.style.width.replace("px", "")) + VIDEO_ADDJUST_GAIN;
                    video.height = parseInt(divElement.style.height.replace("px", "")) + VIDEO_ADDJUST_GAIN;
                    video.style.zIndex = `${Number(divElement.style.zIndex) + 1}`;
                }
                logInfo('videoElements width,height update');
            }

            // 自分の音声は再生不要
            if (elementId !== this.state.sessionId) {
                let audio: HTMLAudioElement = (document.getElementById(elementId + "-audio") as HTMLAudioElement);
                if (audio === undefined || audio === null) {
                    audio = document.createElement("audio");
                    audio.setAttribute("id", elementId + "-audio");
                    if (WebrtcService.isiOS() || WebrtcService.isAndroid()) {
                        // iOSの場合、効果音を途切れずに再生するため効果音を再生した後、
                        // 通話の音声を再生する必要があるため自動再生しない。
                        // Android の場合も端末によっては同様の制限がある。
                    } else {
                        // audio.autoplay = true;
                    }
                    divElement.appendChild(audio);

                    this.setAudioOnloadedMetaData(audio, elementId)
                    logInfo('audioElements setting end');
                } else {
                    logInfo('audioElements setting skip already setting');
                }
            } else {
                logInfo('audioElements setting skip self audio');
            }
        } else {
            logInfo('divElement is null : webrtc-area-'+elementId);
        }
        logInfo('createVideoAudioElement end : id=['+elementId+']');
    }

    removeVideoAudioElement = (peerId: string) => {
        const sessionId = this.getSessionIdFromPeerId(peerId);
        if (sessionId === undefined || sessionId === null) {
            return;
        }

        this.removeVideoAudioElementFromSessionId(sessionId);
    }

    removeVideoAudioElementFromSessionId = (sessionId: string) => {
        const divElement = document.getElementById("webrtc-area-" + sessionId);
        if (divElement) {
            const video: HTMLVideoElement = (document.getElementById(sessionId + "-video") as HTMLVideoElement);
            if (video) {
                divElement.removeChild(video);
            }

            const audio: HTMLAudioElement = (document.getElementById(sessionId + "-audio") as HTMLAudioElement);
            if (audio) {
                divElement.removeChild(audio);
            }
        }
    }

    createScreenShareVideoElement = (elementId: string) => {
        if (this.props.screenShareMode === ScreenShareMode.FullScreen) {
            logInfo("createScreenShareVideoElement: FullScreen:"+elementId+"-video-screen-share");
            const divElement = document.getElementById("root");
            if (divElement) {
                const prevVideo = document.querySelector("#root > video");
                if (prevVideo) divElement.removeChild(prevVideo);
                let video = (document.getElementById(`${elementId}-video-screen-share`) as HTMLVideoElement);
                if (video === undefined || video === null) {
                    video = document.createElement("video");
                    video.setAttribute("id", elementId + "-video-screen-share");
                    video.setAttribute("autoPlay", "");
                    video.muted = true;
                    video.setAttribute("disablePictureInPicture", "");
                    video.setAttribute("playsInline", "");
                    // 映像の中心部を拡大したように見せるための調整
                    // video.width = parseInt(divElement.style.width);
                    // video.height = parseInt(divElement.style.height);
                    video.setAttribute("style", "position: absolute; top:0; left:0; width:100vw; height:100vh; background: #000; z-index: 1000;");
                    // video.srcObject = videoSource.srcObject;

                    divElement.appendChild(video);
                }
            }
        } else if (this.props.screenShareMode === ScreenShareMode.FloatingElement) {
            logInfo("createScreenShareVideoElement: FloatingElement:"+elementId+"-video-screen-share");
            const divElement = document.getElementById("videoShareScreen");
            if (divElement) {
                const prevVideo = document.querySelector("#videoShareScreen > video");
                if (prevVideo) divElement.removeChild(prevVideo);
                let video = (document.getElementById(`${elementId}-video-screen-share`) as HTMLVideoElement);
                if (video === undefined || video === null) {
                    video = document.createElement("video");
                    video.setAttribute("id", `${elementId}-video-screen-share`);
                    video.setAttribute("autoPlay", "");
                    video.muted = true;
                    video.setAttribute("disablePictureInPicture", "");
                    video.setAttribute("playsInline", "");
                    Object.assign(video.style, { width: "100%", height: "auto", background: SHARE_SCREEN_BG_COLOR});

                    video.onplay = () => {
                        logInfo("screen share video onplay sessionId:" + this.props.sessionId + "screenShareMode:" + this.props.screenShareMode);
                        logInfo("createScreenShareVideoElement: onScreenShareVideoLoaded()")
                        this.props.onScreenShareVideoLoaded(true, this.props.screenShareSessionId, WebrtcService.getUserDataList());
                    }

                    divElement.appendChild(video);
                }
            }
        }
    }

    removeScreenShareVideoElement = (peerId: string) => {
        const sessionId = this.getSessionIdFromPeerId(peerId);
        if (sessionId === undefined || sessionId === null) {
            return;
        }

        logInfo("removeScreenShareVideoElement: removeScreenShareVideoElementFromSessionId("+sessionId+")");
        this.removeScreenShareVideoElementFromSessionId(sessionId);
    }

    removeScreenShareVideoElementFromSessionId = (sessionId: string) => {
        if (this.props.screenShareMode === ScreenShareMode.FullScreen) {
            const divElement = document.getElementById("root");
            if (divElement) {
                const video: HTMLVideoElement = (document.getElementById(sessionId + "-video-screen-share") as HTMLVideoElement);
                if (video) {
                    if (this.screenShareStream) {
                        logInfo("removeScreenShareVideoElementFromSessionId: FullScreen: screenShareStream:track.stop()");
                        this.screenShareStream.getVideoTracks().forEach(track => track.stop());
                        this.screenShareStream = undefined;
                    }
                    video.srcObject = null;
                    if (video.parentElement?.id === "root") {
                        divElement.removeChild(video);
                    }
                }
            }
        } else if (this.props.screenShareMode === ScreenShareMode.FloatingElement) {
            const divElement = document.getElementById("videoShareScreen");
            if (divElement) {
                const video = (document.getElementById(sessionId + "-video-screen-share") as HTMLVideoElement);
                if (video) {
                    if (this.screenShareStream) {
                        logInfo("removeScreenShareVideoElementFromSessionId: FloatingElement: screenShareStream:track.stop()");
                        this.screenShareStream.getVideoTracks().forEach(track => track.stop());
                        this.screenShareStream = undefined;
                    }
                    video.srcObject = null;
                    if (video.parentElement?.id === "videoShareScreen") {
                        divElement.removeChild(video);
                    }
                }
            }
        }
    }

    setMediaStream = async (isReplaceStream: boolean) => {
        const useVideoDevice = this.state.hasVideoDevice && this.state.videoDeviceId !== "";
        const useAudioDevice = this.state.hasAudioDevice && this.state.audioInputDeviceId !== "";
        const constraints : any = {video: useVideoDevice, audio: useAudioDevice};
        if (useVideoDevice) {
            constraints.video = {
                deviceId : {exact: this.videoDeviceIdList[this.currentVideoDeviceIdIndex]},
                width: 80, height: 80, frameRate: 5, resizeMode: "none"
            };
            localStorage.setItem("videoDeviceId", this.videoDeviceIdList[this.currentVideoDeviceIdIndex]);
        }

        if (isReplaceStream && this.mediaStream && useVideoDevice) {
            // ビデオ通話と画面共有で音声ストリームを維持するため、音声ストリームは既存のものを使用する
            constraints.audio = false;
        } else {
            if (useAudioDevice) {
                constraints.audio = this.getAudioConstraints(null);
            }
        }

        this.isLoadedStream = false;
        let result = false;
        // console.log(constraints);
        logInfo("setMediaStream: audio:echoCancellation:" + constraints.audio.echoCancellation);
        logInfo("setMediaStream: audio:autoGainControl:" + constraints.audio.autoGainControl);
        logInfo("setMediaStream: audio:noiseSuppression:" + constraints.audio.noiseSuppression);
//        logInfo("setMediaStream: audio:deviceId:" + constraints.audio.deviceId.exact);
        logInfo("setMediaStream: video:width:" + constraints.video.width);
        logInfo("setMediaStream: video:height:" + constraints.video.height);
        logInfo("setMediaStream: video:frameRate:" + constraints.video.frameRate);
//        logInfo("setMediaStream: video:deviceId:" + constraints.video.deviceId.exact);

        logInfo("setMediaStream: getUserMedia call :" + this.props.roomId);
        // joinRoom の状態を通知
        let nTime = new Date().getTime();
        // let peerId = this.peer ? this.peer.id : "";
        let peerId = this.webRTCClient.getPeerId();
        //this.props.onUpdateJoinRoomStatus(this.state.sessionId, peerId, "getUserMedia_S", "");

        await navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            //logInfo("setMediaStream: getUserMedia ret :" + this.props.roomId);
            let diff = new Date().getTime() - nTime;
            let dataStr: string = "diff="+diff+"ms";
            this.props.onUpdateJoinRoomStatus(this.state.sessionId, peerId, "getUserMedia_E", dataStr);
            if (isReplaceStream && this.mediaStream && useAudioDevice) {
                // 映像ストリームのみ停止し、音声ストリームはそのまま使用する
                logInfo("setMediaStream: this.mediaStream:track.stop()");
                this.mediaStream.getVideoTracks().forEach(track => track.stop());
                stream.addTrack(this.mediaStream.getAudioTracks()[0]);
            }

            if (stream.getVideoTracks().length === 0) {
                console.log("VideoDeviceError: setMediaStream")
                this.showVideoErrorSnackBar();
            }

            if (stream.getAudioTracks().length === 0) {
                this.showAudioErrorSnackBar();
            }

            if (stream.getVideoTracks().length === 0 && WebrtcService.isiOS() === false) {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = 160;
                    canvas.height = 120;
                    const dummyStream = (canvas as any).captureStream(10) as MediaStream;  // 10FPS
                    const vTrack = dummyStream.getVideoTracks()[0];
                    vTrack.enabled = false;
                    stream.addTrack(vTrack);
                } catch(e) {
                    logErr('setMediaStream: error:');
                    logException(e);
                }
            }

            if (this.mediaStream) {
                const oldStream = this.mediaStream;
                logInfo("setMediaStream: oldStream:track.stop()");
                oldStream.getTracks().forEach(track => track.stop());
            }

            this.mediaStream = stream;

            const video: HTMLVideoElement = (document.getElementById(this.state.sessionId + "-video") as HTMLVideoElement);
            // this.setVideoOnloadedMetaData(video);            
            video.srcObject = stream;
            logInfo("setMediaStream: video.play()");
            video.play();
            result = true;
        }).catch((error: DOMException) => {
            // Error
            logErr("[WR_TRACE_JOINSTS] setMediaStream: mediaDevice.getUserMedia() error :" + this.props.roomId);
            logException(error);
            this.showGetUserMediaErrorSnackBar(error.message);

            const video: HTMLVideoElement = (document.getElementById(this.state.sessionId + "-video") as HTMLVideoElement);
            if (video) {
                video.srcObject = null;
                // video.poster = Setting.imagePath + "guest-icon.png";
            }

            if (this.mediaStream) {
                logInfo("setMediaStream: this.mediaStream:track.stop()");
                this.mediaStream.getTracks().forEach(track => track.stop());
                this.mediaStream = undefined;
            }

            if (isReplaceStream) {
                // nullを渡すと実行時にエラーとなるため、ダミーの音声ストリームと置き換える
                // this.connectRoom.replaceStream(new AudioContext().createMediaStreamDestination().stream);

                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = 160;
                    canvas.height = 120;
                    const dummyStream = (canvas as any).captureStream(10) as MediaStream;  // 10FPS
                    const vTrack = dummyStream.getVideoTracks()[0];
                    vTrack.enabled = false;
                    logInfo("setMediaStream: replaceStream(dummyStream)");
                    // this.connectRoom.replaceStream(dummyStream);
                    this.webRTCClient.chgBroadcastStream(dummyStream);
                } catch(e) {
                    logErr("setMediaStream: error:")
                    logException(e);
                }
            }

            logInfo("setMediaStream: SHARE_VIDEO_WINDOW_OFF");
            this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_OFF);

            // デバイスが使用中でエラーになった場合に、解放後にgetUserMediaが呼ばれるように
            // hasVideoDevice, hasAudioDevice は変更しない
            this.setState({
                // hasVideoDevice: false, 
                videoEnable: false,
                // hasAudioDevice: false, 
                audioEnable: false,
            });
        });

        if (result) {
            await this.checkMediaDevices();

            // streamの再生(onloadedmetadata)が完了するまで待たせて、正しいstreamか確認して処理を行うようにする
            const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
            let loopCount = 0;
            while(this.isLoadedStream === false) {
                await sleep(100);
                // logInfo("isLoadedStream " + loopCount);
                loopCount++;
                if (loopCount > 30) {
                    break;
                }
            }

            if (isReplaceStream) {
                logInfo("setMediaStream: replaceStream(this.mediaStream)");
                // this.connectRoom.replaceStream(this.mediaStream);
                this.webRTCClient.chgBroadcastStream(this.mediaStream as MediaStream);
                if (this.mediaStream 
                    && this.mediaStream.getVideoTracks().length > 0
                    && this.mediaStream.getVideoTracks()[0].enabled) {
                    logInfo("setMediaStream: SHARE_VIDEO_WINDOW_MAX_OFF");
                    this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_MAX_OFF);
                    this.setState({videoEnable: true});
                } else {
                    logInfo("setMediaStream: SHARE_VIDEO_WINDOW_OFF");
                    this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_OFF);
                }
            }
        }
    }

    setVideoOnloadedMetaData = (video : HTMLVideoElement) => {
        // // 全体放送を受信した場合に、自身のカメラ、マイクを使用していない状態では、
        // // PC以外のブラウザは、映像、音声を自動再生されない場合があり、
        // // それを回避するために全体放送を再生後、カメラ、マイクを停止している。
        // let stopMyVideoAudio = false;
        // if (this.props.roomId.indexOf("-") >= 0) {
        //     let split = this.props.roomId.split("-");
        //     const index = split.length - 1;
        //     if (split[index]) {
        //         if (split[index] === "receive" 
        //             && this.props.roomId.replace("-receive", "") !== this.state.sessionId) {
        //             stopMyVideoAudio = true;
        //         }
        //     }
        // }

        video.onresize = () => {
            logInfo("video.onresize screenShareMode:" + this.props.screenShareMode.toString());
            const sessionId = video.id.replace("-video", "");
            logInfo("video.onresize video.id=["+video.id+"] sessionId=["+sessionId+"] this.state.sessionId=["+this.state.sessionId+"] this.props.screenShareSessionId=["+this.props.screenShareSessionId+"] video.videoWidth=["+video.videoWidth+"]");
            // 画面共有の場合の判定を、簡易的に幅200以上の場合としている
            // => お立ち台対応のため、判定用の幅を400に変更(暫定値)
            if (sessionId !== this.state.sessionId) {
                logInfo("[SS_WindowSelect] video.onresize video.id=["+video.id+"] sessionId=["+sessionId+"] this.state.sessionId=["+this.state.sessionId+"] this.props.screenShareSessionId=["+this.props.screenShareSessionId+"] video.videoWidth=["+video.videoWidth+"]");
                if (video.videoWidth > 400 && sessionId === this.props.screenShareSessionId) {
                    logInfo("[SS_WindowSelect] createSSVideoElement video.id=["+video.id+"] sessionId=["+sessionId+"] this.state.sessionId=["+this.state.sessionId+"] this.props.screenShareSessionId=["+this.props.screenShareSessionId+"] video.videoWidth=["+video.videoWidth+"]");
                    this.createScreenShareVideoElement(sessionId);
                    const videoScreenShare = (document.getElementById(sessionId + "-video-screen-share") as HTMLVideoElement);
                    if (videoScreenShare) {
                        videoScreenShare.srcObject = video.srcObject;
                        logInfo("setVideoOnloadedMetaData: onScreenShareVideoLoaded()")
                        this.props.onScreenShareVideoLoaded(true, this.props.screenShareSessionId, WebrtcService.getUserDataList());
                    }
                } else {
                    logInfo("[SS_WindowSelect] removeSSVideoElement video.id=["+video.id+"] sessionId=["+sessionId+"] this.state.sessionId=["+this.state.sessionId+"] this.props.screenShareSessionId=["+this.props.screenShareSessionId+"] video.videoWidth=["+video.videoWidth+"]");
                    this.removeScreenShareVideoElementFromSessionId(sessionId);
                }
            }
        }

        video.onloadedmetadata = () => {
            // logInfo("WebrtcComponent video.onloadedmetadata this.state.sessionId = " + this.state.sessionId);

            // const video: HTMLVideoElement = (document.getElementById(this.state.sessionId + "-video") as HTMLVideoElement);
            // オリジナルの解像度を確認
            // logInfo(video.videoWidth + " x " + video.videoHeight);
            
            // WebRTCの映像が再生されたタイミングでアバター画像を差し替える
            const sessionId = video.id.replace("-video", "");
            if (sessionId !== this.state.sessionId) {
                // OtherUserコンポーネント側でpropsによる切替を実装したため、コメントアウト
                // 他者の映像の場合
                //this.changeAvatorImage(sessionId, true, video);
                this.checkWebRTCTurnServer();
                // if (stopMyVideoAudio) {
                //     this.initVideo(this.state.sessionId);
                //     stopMyVideoAudio = false;
                // }
            } else {
                // 自分の映像の場合
                if( this.props.isNoVideo ){
                    // isNoVideoがtrueなら、videoMuteの値にかかわらずfalse
                    this.setVideoStreamEnable(false);
                } else{
                    this.setVideoStreamEnable(this.props.videoMute ? false : true);
                }
                logInfo("onloadedmetadata: audioMute:" + this.props.audioMute);
                if(this.mediaStream === null || this.mediaStream === undefined){
                    logInfo("onloadedmetadata: this.mediaStream:" + this.mediaStream === null ? "null" : "undefined");
                } else {
                    if(this.mediaStream?.getAudioTracks() === null){
                        logInfo("onloadedmetadata: audioTracks() is null");
                    } else {
                        if(this.mediaStream?.getAudioTracks().length !== 1){
                            logInfo("onloadedmetadata: audioTracks().length:" + this.mediaStream?.getAudioTracks().length);
                        }
                    }
                }
                this.setAudioStreamEnable(this.props.audioMute ? false : true);
            }

            // 音声のみの場合の対応
            if (video.videoWidth === 0) {
                this.useVideo = false;
                this.isLoadedStream = true;
                logInfo("setVideoOnloadedMetaData: SHARE_VIDEO_WINDOW_MAX_OFF");
                this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_MAX_OFF);
                logInfo("setVideoOnloadedMetaData: onMyStreamLoaded() videoWidth === 0")
                this.props.onMyStreamLoaded(Number(sessionId));
                return;
            }

            this.useVideo = true;
            this.isLoadedStream = true;

            // this.fitScaleWindow(video, false);

            if (video.srcObject !== undefined && sessionId === this.state.sessionId) {
                const videoStream = (video.srcObject as MediaStream);
                if (videoStream.getVideoTracks().length > 0) {
                    const track = videoStream.getVideoTracks()[0];
                    if (track.enabled === false) {
                        video.srcObject = null;
                        video.poster = "";
                    }

                    try {
                        this.setState({
                            localStreamResolutionMode : this.props.isLargeVideo ? 1 : 0
                        });

                        if (WebrtcService.isIOSDevice()) {
                            if (this.isLandscape) {
                                track.applyConstraints(constrainstsResolutionIPadList[this.state.localStreamResolutionMode + offsetLandscapeConstrainstsResolutionIPadList]);
                            } else {
                                track.applyConstraints(constrainstsResolutionIPadList[this.state.localStreamResolutionMode]);
                            }
                        } else {
                            track.applyConstraints(constrainstsResolutionList[this.state.localStreamResolutionMode]);
                            // logInfo(constrainstsResolutionList[this.state.localStreamResolutionMode]);
                        }
                    } catch(e) {
                        // ブラウザごとに依存していて解像度が変更できないこともあるためエラーを捨てている
                        logErr("setVideoOnloadedMetaData: error:")
                        logException(e);
                    }
                }
            }

            if (sessionId === this.state.sessionId) {
                // MyUser audio, videoの再生開始を親へ伝える
                // タイマーによるwaiting解除に変更したため、親（Floor.tsx）で処理は行っていない。
                logInfo("setVideoOnloadedMetaData: onMyStreamLoaded()")
                //logInfo("[WR_TRACE_JOINSTS] setVideoOnloadedMetaData: onMyStreamLoaded() : "+this.props.roomId)
                this.props.onMyStreamLoaded(Number(sessionId));
            } else {
                // OtherUser
                // タイマーによるwaiting解除に変更したため、親（Floor.tsx）で処理は行っていない。
                logInfo("setVideoOnloadedMetaData: onRemoteStreamLoaded()")
                this.props.onRemoteStreamLoaded(Number(sessionId), 1);
            }
        }
    }

    setAudioOnloadedMetaData = async (audio : HTMLAudioElement, elementId : string) => {
        audio.onloadedmetadata = () => {
            if (elementId !== this.state.sessionId) {
                if (WebrtcService.isiOS() || WebrtcService.isAndroid()) {
                    // iOS の場合、getUserMedia を実行したタイミングで効果音の再生が中断されるようで
                    // 正常に効果音を鳴らせない場合があるため、このタイミングで効果音を鳴らしている
                    // 通話の音声開始と重なっても影響をうけることにも配慮が必要。
                    // Android の場合も端末によっては同様の制限がある。
                    if (WebrtcService.remoteStreamConnectSoundId !== AudioId.None) {
                        AudioPlayer.playAfterCall(WebrtcService.remoteStreamConnectSoundId, () => {
                            this.playSoundFromAudio(audio);
                        }).then((result) => {
                            WebrtcService.remoteStreamConnectSoundId = AudioId.None;
                            if (result === false) {
                                this.props.showMessage("音声の再生ができませんでした", "本メッセージを閉じた後、音声を再生します。", '', '', false);
                            }
                        });
                    } else {
                        // iOSの効果音を正常にならすため、効果音の再生が終わってから通話の音声を再生する
                        const playingAudio = AudioPlayer.getPlayingSound();
                        if (playingAudio) {
                            playingAudio.addEventListener('ended', () => {
                                this.playSoundFromAudio(audio);
                            }, {once: true});
                        } else {
                            logInfo("PLAY_SOUND_TRACE setAudioOnloadedMetaData: iOS playSoundFromAudio call")
                            this.playSoundFromAudio(audio);
                        }
                    }
                } else {
                    logInfo("PLAY_SOUND_TRACE setAudioOnloadedMetaData: playSoundFromAudio call")
                    this.playSoundFromAudio(audio);

                    // 再生できない場合に下記の処理でユーザートリガーによる再生を行えるようにしている。
                    // ただし、端末によっては、再生できていない場合にも paused = true になっていない可能性もあるため 完全な対応ではない。
                    setTimeout(() => {
                        WebrtcService.checkAudioPaused((message: string) => {
                            this.props.showMessage("音声の再生ができませんでした", "本メッセージを閉じた後、音声を再生します。(3)"
                                + "\n" + message, '', '', false);
                        }, audio.id);
                    }, 500 * WebrtcService.countAudioAllWebRtc());
                }

                const video: HTMLVideoElement = (document.getElementById(elementId + "-video") as HTMLVideoElement);
                // OtherUserでデバイスにマイクのみを指定している場合
                if (video && video.videoWidth === 0) {
                    // タイマーによるwaiting解除に変更したため、親（Floor.tsx）で処理は行っていない。
                    logInfo("setAudioOnloadedMetaData: onRemoteStreamLoaded()")
                    this.props.onRemoteStreamLoaded(Number(elementId), 2);
                }
            }
        }
    }

    getAudioConstraints = (audioInputDeviceId: string|null) => {
        let audioConstraints : any = {"echoCancellation" : true};
        if (WebrtcService.isiOS() === false) {
            audioConstraints["autoGainControl"] = true;
            audioConstraints["noiseSuppression"] = true;
        }
        //audioConstraints["echoCancellationType"] = 'system';

        if (audioInputDeviceId) {
            audioConstraints.deviceId = {exact: audioInputDeviceId};
        } else {
            audioConstraints.deviceId = {exact: this.state.audioInputDeviceId};
        }

        return audioConstraints;
    }

    getMediaStream = async (constraints : any) : Promise<MediaStream|undefined> => {
        var mediaStream = undefined;

        await navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            // Success
            // logInfo(navigator.mediaDevices.getSupportedConstraints());
            mediaStream = stream;
        }).catch((error: DOMException) => {
            logErr("setMediaStream: mediaDevice.getUserMedia() error:")
            logException(error);
            this.showGetUserMediaErrorMessage(error.message);
        });

        return mediaStream;
    }

    setScreenShareStream = async (createVideoElement?: (sessionId: string) => void) => {
        const constraints = {
            video: {
                // 対象フロアの解像度・fpsをfloorテーブルより取得
                width: { ideal: this.props.screenShareResolutionWidth || 1280 },
                height: { ideal: this.props.screenShareResolutionHeight || 720 },
                frameRate: this.props.screenShareFps || 5,
                // HD
                // width: { ideal: 1280 },
                // height: { ideal: 720 },
                // Full-HD
                //width: { ideal: 1920 },
                //height: { ideal: 1080 },
                // originalサイズをそのまま
                //resizeMode : "none"
            }
            //画面共有で音も共有する場合、ここのコメントアウトを外す
            //,
            //audio: true
        }
        await (navigator.mediaDevices as any).getDisplayMedia(constraints)
        .then((stream: MediaStream) => {
            if (createVideoElement) {
                // callbackが渡されている場合
                // 共有したいstream元を画面で選択後、HTMLVideoElementを生成する
                createVideoElement(this.state.sessionId);
            }

            // logInfo(navigator.mediaDevices.getSupportedConstraints());
            this.screenShareStream = stream;

            if (this.mediaStream) {
                //画面共有で音も共有する場合、ここをコメントアウトする
                this.screenShareStream.addTrack(this.mediaStream.getAudioTracks()[0]);
            }

            const video: HTMLVideoElement = (document.getElementById(this.state.sessionId + "-video-screen-share") as HTMLVideoElement);
            if (video) {
                video.srcObject = this.screenShareStream;
                // chrome標準の「共有を停止」ボタンで画面共有を終了する
                this.screenShareStream.getVideoTracks()[0].addEventListener("ended", () => {
                    this.props.handleScreenShare(false, ScreenShareMode.FullScreen, this.props.roomId);
                });
            }

            if (this.state.hasVideoDevice === false && this.state.hasAudioDevice === false) {
                this.stopCallKeepScreenShareStream(true);
                this.joinRoom(this.screenShareStream);
            } else {
                logInfo("setScreenShareStream: replaceStream(this.mediaStream)");
                // this.connectRoom.replaceStream(this.screenShareStream);
                this.webRTCClient.chgBroadcastStream(this.screenShareStream);
            }
        }).catch((error: DOMException) => {
            logErr("setScreenShareStream: mediaDevice.getDisplayMedia() error:")
            logException(error);
            this.removeScreenShareVideoElementFromSessionId(this.state.sessionId);
            // #295 画面共有キャンセル
            if(error.code !== 0) {
                this.showGetDisplayMediaErrorMessage(error.message);
            }
            this.props.handleScreenShare(false, ScreenShareMode.FullScreen, this.props.roomId);
        });
    }

    /**
     * videoタグの縦、横と映像ソースの縦、横の余白をなくすための処理
     */
    fitScaleWindow = (video : HTMLVideoElement, isForce: boolean) : boolean => {
        if (video.videoWidth === 0) {
            return false;
        }

        let calcWidth = video.width;
        // let calcHeight = video.height;
        
        // 映像とvideoタグとのアスペクト比が合わなくなることの対応
        const controlAspectRatio = video.width / video.height;
        let videoSrcAspectRatio = 0.0;
        videoSrcAspectRatio = video.videoWidth / video.videoHeight;

        if (Math.abs(controlAspectRatio - videoSrcAspectRatio) < 0.01 && isForce === false) {
            // アスペクト比がほぼ変わらない場合には処理しない
            return false;
        }

        const divElement = video.parentElement;
        let baseWidth = divElement?.style.width;
        if (baseWidth === undefined) {
            baseWidth = '' + controlVideoWidth;
        }

        const baseSize = Math.round(parseInt(baseWidth) * this.screenScale);
        let offset = 0;
        if (video.videoWidth > video.videoHeight) {
            // 横長の場合
            video.width = baseSize * (video.videoWidth / video.videoHeight);
            video.height = baseSize;
            offset = Math.round((video.width - baseSize) / 2);
            video.setAttribute("style", "transform: translate(-" + offset + "px, 0%);");
        } else {
            // 縦長の場合
            video.width = baseSize;
            video.height = baseSize * (video.videoHeight / video.videoWidth);
            offset = Math.round((video.height - baseSize) / 2);
            video.setAttribute("style", "transform: translate(0%, -" + offset + "px);");
        }


        // logInfo("fitScaleWindow video.width = " + video.width);
        // logInfo("fitScaleWindow video.height = " + video.height);
        // logInfo("fitScaleWindow video.videoWidth = " + video.videoWidth);
        // logInfo("fitScaleWindow video.videoHeight = " + video.videoHeight);

        // 高さ基準
        calcWidth = controlVideoHeight * videoSrcAspectRatio;

        // logInfo("fitScaleWindow calcWidth = " + calcWidth);
        this.setState({
            width : calcWidth,
            height : controlVideoHeight,
        });

        // if (this.connectRoom) {
        if (this.webRTCClient.isEnteredMeetroom()) {
            logInfo('fitScaleWindow: connectRoom.send(video window update)');
            // this.connectRoom.send("video window update");
            try {
                this.webRTCClient.sendSameMeetroomUser("video window update");
            } catch (error) {
                logInfo('webRTC room.send() error.');
            }
        }

        return true;
    }

    joinRoom = (stream : any) => {
        if (this.isCaptureFailed) {
            logInfo("[WR_TRACE] skip joinRoom isCaptureFailed");
            return;
        }

        if (this.connectRoom !== undefined
            || this.props.roomId === "") {
            logInfo("[WR_TRACE] S_CALL skip joinRoom already connect ??? props.roomId=["+this.props.roomId+"]");
            // #920 MediaStream 開放
            if(this.props.roomId === "") {
                // if (this.peer) {
                if (this.webRTCClient.isConnectedSignalingServer()) {
                    logInfo("[WR_TRACE_JOINSTS] S_CALL joinRoom release MediaStream this.state.sessionId=["+this.state.sessionId+"]");
                    this.initVideoKeepScreenShareStream(this.state.sessionId, false);
                    this.removeVideoAudioElementFromSessionId(this.state.sessionId);
                    this.removeScreenShareVideoElementFromSessionId(this.state.sessionId);
                } else {
                    logInfo("[WR_TRACE_JOINSTS] S_CALL joinRoom this.peer undefined and not_release MediaStream this.state.sessionId=["+this.state.sessionId+"]");
                }
            }
            return;
        }

        // if (this.peer === undefined || this.peer === null) {
        if (!this.webRTCClient.isConnectedSignalingServer()) {
            logInfo("[WR_TRACE] skip joinRoom peer is undefined or null");
            return;
        }

        try {
            if (stream !== undefined) {
                // 音声トラックがない場合に、相手側の音声も聞こえない状態を回避するために
                // ダミーの音声トラックを設定
                if (stream.getAudioTracks().length > 0) {
                    if(stream.getAudioTracks().length > 1){
                        logInfo("audio track found length:" + stream.getAudioTracks().length);
                    }
                } else {
                    logInfo("audio track not found");
                    const aStream = new AudioContext().createMediaStreamDestination().stream;
                    stream.addTrack(aStream.getAudioTracks()[0]);
                    // logInfo("dummy audio track add");
                }
            } else {
                // 動作確認用コード
                // ダミーの映像ストリームを各デバイスで生成して使用できるか
                // const canvas = document.createElement('canvas');
                // canvas.width = 160;
                // canvas.height = 120;
                // const dummyStream = (canvas as any).captureStream(10) as MediaStream;  // 10FPS
                // const vTrack = dummyStream.getVideoTracks()[0];
                // vTrack.enabled = false;
                // stream = dummyStream;

                // 受信モードの動作確認のためにnullを設定している
                stream = null;
            }
        } catch(e) {
            logErr("joinRoom: error:")
            logException(e);
            // console.error(e);
            // this.setState({
            //     messageValue: e
            // });
        }

        const isReceiveMode = WebrtcService.getWebRtcReceiveMode(this.props.sessionId, this.props.roomId);
        const roomId = this.props.roomId;
        let conMode = WebrtcService.getConnectMode(this.props.roomId, this.props.webRtcMode);
        logInfo("[WR_TRACE] joinRoom start roomiD=["+roomId+"] mode=["+conMode+"] isReceiveMode=["+isReceiveMode+"]");
        // const room = this.peer.joinRoom(roomId, {
        //     mode: WebrtcService.getConnectMode(this.props.roomId, this.props.webRtcMode),
        //     stream: isReceiveMode ? null : stream,
        //     videoReceiveEnabled: true,
        //     audioReceiveEnabled: true,
        //     videoCodec: "VP8",
        // }); 

        try {
            this.webRTCClient.enterMeetroom(this.props.roomId, stream);
        } catch (error) {
            logger.error('webRtcClient enterMeetRoom error.');
        }

        logInfo("[WR_TRACE] joinRoom ret roomiD=["+roomId+"] mode=["+conMode+"] isReceiveMode=["+isReceiveMode+"]");
        // if(room === undefined || room === null) {
        if (!this.webRTCClient.isEnteredMeetroom()) {
            logInfo("[WR_TRACE] joinRoom ret room instance is null");
        } else {
            // joinRoom を通知
            // this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "room", "");
            this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "room", "");
        }

        // this.connectRoom = room;
        this.connectRoom = this.webRTCClient.getRoom();
        // room.on('open', () => {
        this.webRTCClient.on('meetroomOpen', () => {
            // if (this.peer === undefined || this.peer === null) {
            if (!this.webRTCClient.isConnectedSignalingServer()) {
                logInfo("[WR_TRACE] room.on open this.peer is undefined or null");
                return;
            }

            if(this.closing_connectRoom !== undefined && this.closing_connectRoom.name === this.webRTCClient.getMeetroomId()) {
                // if(this.connectRoom !== undefined && this.connectRoom.name === room.name) {
                if(this.connectRoom !== undefined && this.connectRoom.name === this.webRTCClient.getMeetroomId()) {
                    // closeイベント受信前に同じ会議室に入りなおした？
                    logInfo('SKYWAY_EVENT room.on open for closing room. not closing (connectRoom.name === room.name) : '+this.webRTCClient.getMeetroomId());
                } else {
                    logInfo('SKYWAY_EVENT room.on open for closing room and closing_connectRoom.close() : '+this.webRTCClient.getMeetroomId());
                    this.closing_connectRoom.close();
                    this.closing_connectRoom = undefined;
                    return;
                }
            } else {
                if(this.connectRoom !== undefined && this.connectRoom.name === this.webRTCClient.getMeetroomId()) {
                    // 通常パス
                    logInfo("SKYWAY_EVENT room.on open room.name:" + this.webRTCClient.getMeetroomId());
                } else {
                    // closing中に保存されていないが、現在のconnectRoomではないのでcloseする
                    logInfo('SKYWAY_EVENT room.on open for not_closing room and closing_connectRoom.close() : '+this.webRTCClient.getMeetroomId());
                    // room.close();
                    this.webRTCClient.outMeetroom();
                    return;
                }
            }

            if (WebrtcService.isiOS() || WebrtcService.isAndroid()) {
                // iOS の場合、getUserMedia を実行したタイミングで音声の再生が中断されるようで
                // 正常に効果音を鳴らせない場合があったため、このタイミングで効果音を鳴らしている。
                // Android の場合も端末によっては同様の制限がある。
                if (WebrtcService.remoteStreamConnectSoundId === AudioId.EnterRoom) {
                    this.playSoundFromAudioId(WebrtcService.remoteStreamConnectSoundId);
                    WebrtcService.remoteStreamConnectSoundId = AudioId.None;
                }
            }

            // logInfo("room.on open room.name:" + room.name);
            if (this.mediaStream 
                && this.mediaStream.getVideoTracks().length > 0
                && this.mediaStream.getVideoTracks()[0].enabled) {
                logInfo("joinRoom: SHARE_VIDEO_WINDOW_MAX_OFF");
                this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_MAX_OFF);
            } else {
                logInfo("joinRoom: SHARE_VIDEO_WINDOW_OFF");
                this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_OFF);
            }

            // WebRTCの入出力データを取得するため、定期実行するタイマーを設定
            if (this.timerGetWebRTCInOutBytes === null) {
                this.timerGetWebRTCInOutBytesCount = 0;
                this.webRtcInOutInfo = new WebRtcInOutInfo();
                logInfo("[ST] 計測開始");
                logInfo("[ST] inputBytes:"+this.webRtcInOutInfo.inputBytes);
                logInfo("[ST] outputBytes:"+this.webRtcInOutInfo.outputBytes);

                this.timerGetWebRTCInOutBytes = setInterval(this.getWebRTCInOutBytes, 1000);
            }
            // joinRoom の状態を通知
            // this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "open", "");
            this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "open", "");


            setTimeout(this.checkWebRTCTurnServer, 1000);
        });
    
        // room.on('peerJoin', (peerId : string) => {
        this.webRTCClient.on('meetroomPeerJoin', (peerId : string) => {
            logInfo("room.on peerJoin peerId:" + peerId);
            // joinRoom の状態を通知
            const sessionId = this.getSessionIdFromPeerId(peerId);
            let sid: string = "";
            if(sessionId !== null) { sid = sessionId; }
            this.props.onUpdateJoinRoomStatus(sid, peerId, "peerJoin", "");
            setTimeout(this.checkWebRTCTurnServer, 1000);
        });

        // room.on('peerLeave', (peerId : string) => {
        this.webRTCClient.on('meetroomPeerLeave', (peerId : string) => {
            logInfo("room.on peerLeave peerId:" + peerId);

            try {
                this.stopVideoAudio(peerId);
                this.removeVideoAudioElement(peerId);
                this.removeScreenShareVideoElement(peerId);
            } catch(e) {
                // リモートとすでに切断されている場合がある
                logErr("joinRoom: peerLeave error:")
                logException(e);
            }

            if (this.state.remotePeerIdList[peerId]){
                const copyPeerIdList = Object.assign({}, this.state.remotePeerIdList);
                const copyStreamList = Object.assign({}, this.state.remoteStreamList);
    
                delete copyPeerIdList[peerId];
                delete copyStreamList[peerId];

                this.setState({
                    remotePeerIdList: copyPeerIdList,
                    remoteStreamList: copyStreamList,
                });
            }
            // joinRoom の状態を通知
            const sessionId = this.getSessionIdFromPeerId(peerId);
            let sid: string = "";
            if(sessionId !== null) { sid = sessionId; }
            this.props.onUpdateJoinRoomStatus(sid, peerId, "peerLeave", "");

            setTimeout(this.checkWebRTCTurnServer, 1000);
        });

        // room.on('stream', (stream: any) => {
        this.webRTCClient.on('meetroomStream', (stream: any) => {
            logInfo('room.on remote stream connect peerId:' + stream.peerId);

            const copyPeerIdList = Object.assign({}, this.state.remotePeerIdList);
            const copyStreamList = Object.assign({}, this.state.remoteStreamList);

            copyPeerIdList[String(stream.peerId)] = stream.peerId;
            copyStreamList[String(stream.peerId)] = stream;

            this.setState({
                remotePeerIdList: copyPeerIdList,
                remoteStreamList: copyStreamList,
            });
            // joinRoom の状態を通知
            const sessionId = this.getSessionIdFromPeerId(stream.peerId);
            let sid: string = "";
            if(sessionId !== null) { sid = sessionId; }
            this.props.onUpdateJoinRoomStatus(sid, stream.peerId, "stream", "");
        });

        // room.on('data', (data: {data: any, src: any}) => {
        this.webRTCClient.on('meetroomData', (data: {data: any, src: any}) => {
            logInfo('room.on data data:' + data.data);
            if (data.data === "video window update") {
                setTimeout(this.reconnectVideoRemote.bind(data.src), 500);
            }
            // joinRoom の状態を通知
            const sessionId = this.getSessionIdFromPeerId(data.src);
            let sid: string = "";
            if(sessionId !== null) { sid = sessionId; }
            this.props.onUpdateJoinRoomStatus(sid, data.src, "data", data.data);

            //////////////////////////////////////////////////////
            // debug用：無理やりcloseイベント遅れないので、sendで代用
            // #609 意図しないclose対応
            // if (this.peer === undefined || this.peer === null) {
            if (!this.webRTCClient.isConnectedSignalingServer()) {
                logInfo('WR_TRACE_JOINSTS ： 意図しないclose_debug発生 this.peer is null');
            } else {
                if (data.data === "irregular_close_debug") {
                    // logInfo('WR_TRACE_JOINSTS ： 意図しないclose_debug発生 sid=[' + this.state.sessionId+"] wid=["+this.peer.id+"]");
                    logInfo('WR_TRACE_JOINSTS ： 意図しないclose_debug発生 sid=[' + this.state.sessionId+"] wid=["+this.webRTCClient.getPeerId()+"]");
                    // this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "irregular_close", this.props.roomId);
                    this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "irregular_close", this.props.roomId);
                }
            }
        });

        // room.on('error', (error: any) => {
        this.webRTCClient.on('meetroomError', (error: any) => {
            // joinRoom の状態を通知
            // if (this.peer === undefined || this.peer === null) {
            if (!this.webRTCClient.isConnectedSignalingServer()) {
                return;
            }
            // this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "error", error.message);
            this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "error", error.message);
        });

        // room.on("close", () => {
        this.webRTCClient.on('meetroomClose', () => {
            // joinRoom の状態を通知
            // if (this.peer === undefined || this.peer === null) {
            if (!this.webRTCClient.isConnectedSignalingServer()) {
                console.log("room.on JoinRoomStatus peerId error");
                return;
            }

            // #1580 room.close()が無視されるパターンの回避
            // close中のjoinroomをクリアする
            if(this.closing_connectRoom !== undefined && this.closing_connectRoom.name === this.webRTCClient.getMeetroomId()) {
                logInfo('SKYWAY_EVENT room.on close for closing room room.name:' + this.webRTCClient.getMeetroomId());
                this.closing_connectRoom = undefined;
            } else {
                logInfo("SKYWAY_EVENT room.on close room.name:" + this.webRTCClient.getMeetroomId());
            }
            
            // #609 意図しないclose対応
            // if(this.props.roomId !== "" && this.props.roomId === roomId && this.props.isCall === true) {
            //     if(this.props.isRetryCall === false) {
            //         logInfo('WR_TRACE_JOINSTS ： 意図しないclose発生 sid=[' + this.state.sessionId+"] wid=["+this.peer.id+"]");
            //         //alert('意図しないclose発生 ['+this.props.roomId+']['+roomId+']');
            //         this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "irregular_close", this.props.roomId);
            //     } else {
            //         logInfo('WR_TRACE_JOINSTS ： 意図しないclose発生 リトライ中なので無視 sid=[' + this.state.sessionId+"] wid=["+this.peer.id+"]");
            //     }
            // }
            // this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.peer.id, "close", "");
            if(this.props.roomId !== "" && this.props.roomId === roomId && this.props.isCall === true) {
                if(this.props.isRetryCall === false) {
                    logInfo('WR_TRACE_JOINSTS ： 意図しないclose発生 sid=[' + this.state.sessionId+"] wid=["+this.webRTCClient.getPeerId()+"]");
                    //alert('意図しないclose発生 ['+this.props.roomId+']['+roomId+']');
                    this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "irregular_close", this.props.roomId);
                } else {
                    logInfo('WR_TRACE_JOINSTS ： 意図しないclose発生 リトライ中なので無視 sid=[' + this.state.sessionId+"] wid=["+this.webRTCClient.getPeerId()+"]");
                }
            }
            this.props.onUpdateJoinRoomStatus(this.state.sessionId, this.webRTCClient.getPeerId(), "close", "");
        });
    }

    stopVideoAudio = (peerId: string) => {
        logInfo('stopVideoAudio: peerId:' + peerId);
        const sessionId = this.getSessionIdFromPeerId(peerId);
        if (sessionId) {
            this.stopVideoAudioFromSessionId(sessionId);
        }
    }

    stopVideoAudioFromSessionId = (sessionId: string) => {
        logInfo('stopVideoAudioFromSessionId: sessionId:' + sessionId);
        const remoteVideo = (document.getElementById(sessionId + "-video") as HTMLVideoElement);
        const mediaStream = (remoteVideo.srcObject as MediaStream);
        if (mediaStream !== null && mediaStream !== undefined) {
            logInfo("stopVideoAudioFromSessionId: mediaStream:track.stop()");
            mediaStream.getTracks().forEach(track => track.stop());
            remoteVideo.srcObject = null;
        }

        const remoteAudio = (document.getElementById(sessionId + "-audio") as HTMLAudioElement);
        const mediaAudioStream = (remoteAudio.srcObject as MediaStream);
        logInfo("stopVideoAudioFromSessionId: mediaAudioStream:track.stop()");
        mediaAudioStream.getTracks().forEach(track => track.stop());
        remoteAudio.srcObject = null;
    }

    /**
     * WebRTC通話時と通話していない状態でのアバター画像を変更する
     * 
     * @param sessionId 
     * @param webRtcCall 
     * @param remoteVideo 
     */
    changeAvatorImage = (sessionId: string, webRtcCall: boolean, remoteVideo: HTMLVideoElement) => {
        const avatorImage = document.getElementById("avator-image-" + sessionId);
        if (avatorImage) {
            avatorImage.style.display = webRtcCall ? "none" : "block";
        }

        const avatorLargeImage = document.getElementById("avator-large-image-" + sessionId);
        if (avatorLargeImage) {
            avatorLargeImage.style.display = webRtcCall ? "block" : "none";
        }

        const parentElement = remoteVideo?.parentElement?.parentElement;
        if (parentElement) {
            const offsetWidth = webRtcCall ? 10 : -10;
            const offsetHeight = webRtcCall ? 2 : -2;
            parentElement.style.width = (parseInt(parentElement.style.width) + offsetWidth) + "px";
            parentElement.style.height = (parseInt(parentElement.style.height) + offsetHeight) + "px";
        }
    }

    /**
     * HTML videoのソースを再接続することで以下の効果がある。
     * ・ウィンドウサイズと映像の一致
     * ・映像が回転していた場合、正しい向きで表示
     */
    reconnectVideoRemote = (peerId: string) => {
        logInfo('reconnectVideoRemote: peerId:' + peerId);
        // console.trace();
        const sessionId = this.getSessionIdFromPeerId(peerId);
        if (sessionId === null) {
            return;
        }
        const video: HTMLVideoElementMod = (document.getElementById(sessionId + "-video") as HTMLVideoElementMod);
        if (video.srcObject === undefined || video.srcObject === null) {
            return;
        }
        const mediaStream = (video.srcObject as MediaStream);
        if (mediaStream !== undefined && mediaStream !== null) {
            // 再生完了する前に中断するとエラーが発生するため、映像の幅が取得できるかどうかで判断している
            if (video.videoWidth !== 0) {
                video.srcObject = null;
                video.srcObject = mediaStream;
                video.playsInline = true;
                logInfo("reconnectVideoRemote: video.play()");
                video.play();
            }
        }
    }

    stopCall = () => {
        this.stopCallKeepScreenShareStream(false);
    }

    stopCallKeepScreenShareStream = (keepScreenShareStream: boolean) => {
        logInfo('stopCallKeepScreenShareStream: keepScreenShareStream:' + keepScreenShareStream);
        if (this.connectRoom !== undefined) {

            logInfo('stopCallKeepScreenShareStream: stopVideoAudio()');
            Object.keys(this.state.remotePeerIdList).forEach(key => {
                try {
                    this.stopVideoAudio(key);
                    this.removeVideoAudioElement(key);
                    this.removeScreenShareVideoElement(key);
                } catch(e) {
                    logErr("stopCallKeepScreenShareStream: error:")
                    logException(e);
                }
            });

            this.setState({
                remotePeerIdList: {},
                remoteStreamList: {},
            });

            // if (this.peer) {
            if (!WebrtcService.isPeerIdIsNull()) {
                this.initVideoKeepScreenShareStream(this.state.sessionId, keepScreenShareStream);
                this.removeVideoAudioElementFromSessionId(this.state.sessionId);
                if (keepScreenShareStream === false) {
                    this.removeScreenShareVideoElementFromSessionId(this.state.sessionId);
                }

                // 通話終了時にWebRTCの送受信バイト数をサーバに送り保存
                if (this.timerGetWebRTCInOutBytes) {
                    logInfo("[ST] 計測終了");
                    clearInterval(this.timerGetWebRTCInOutBytes);
                    this.timerGetWebRTCInOutBytes = null;
                    this.sendWebRtcInOutInfo("disconnect");
                }
            }

            logInfo('stopCallKeepScreenShareStream: connectRoom.close()');
            this.closing_connectRoom = this.connectRoom;
            this.connectRoom.close();
            this.connectRoom = undefined;
        } else {
            // #920
            // if(this.peer && this.props.roomId === "") {
            if(this.webRTCClient.isConnectedSignalingServer() && this.props.roomId === "") {
                logInfo('S_CALL stopCallKeepScreenShareStream: startCall中にstop要求を受けたか？ this.props.roomId=['+this.props.roomId+']');
            }
        }
    }

    sendWebRtcInOutInfo = (type: string) => {
        if (this.webRtcInOutInfo.sessionId !== "") {
            this.webRtcInOutInfo.endUnixTime = Math.floor(new Date().getTime() / 1000);
            // logInfo(this.webRtcInOutInfo);

            let isTurnList : {[key: string]: boolean} = {};
            // データ通信量が増えすぎないようにストリームの情報数が10以下のみ送信する
            let outputStreamCount = 0;
            let outputBytes = 0;
            let outputBytesP2P = 0;
            let isTurn: boolean = false;
            Object.keys(this.webRtcInOutInfo.outputStats).forEach((key) => {
                if (this.webRtcInOutInfo.outputStats[key].candidateType === "relay") {
                    isTurnList[key] = true;
                } else {
                    isTurnList[key] = false;
                }
                isTurn = isTurn || isTurnList[key];

                if (this.webRtcInOutInfo.outputStats[key].bytesTotal) {
                    outputBytes += this.getCalcBytes(this.webRtcInOutInfo.outputStats[key].bytesTotal, isTurnList[key]);  
                    if (isTurnList[key] === false && this.webRtcInOutInfo.connectType === 1) {
                        // MeshかつTURNを使用していない場合は、P2P接続
                        outputBytesP2P += this.webRtcInOutInfo.outputStats[key].bytesTotal;
                    }
                }

                Object.keys(this.webRtcInOutInfo.outputStats[key].streamInfos).forEach((keyStream) => {
                    outputStreamCount++;
                });

                if (outputStreamCount > 10) {
                    this.webRtcInOutInfo.outputStats[key].streamInfos = {};
                }
            });
            
            // データ通信量が増えすぎないようにストリームの情報数が10以下のみ送信する
            let inputStreamCount = 0;
            let inputBytes = 0;
            let inputBytesP2P = 0;
            Object.keys(this.webRtcInOutInfo.inputStats).forEach((key) => {
                if (this.webRtcInOutInfo.inputStats[key].bytesTotal) {
                    inputBytes += this.getCalcBytes(this.webRtcInOutInfo.inputStats[key].bytesTotal, isTurnList[this.webRtcInOutInfo.inputStats[key].pairId]);  
                    if (isTurnList[this.webRtcInOutInfo.inputStats[key].pairId] === false && this.webRtcInOutInfo.connectType === 1) {
                        // MeshかつTURNを使用していない場合は、P2P接続
                        inputBytesP2P += this.webRtcInOutInfo.inputStats[key].bytesTotal;
                    }
                }

                Object.keys(this.webRtcInOutInfo.inputStats[key].streamInfos).forEach((keyStream) => {
                    inputStreamCount++;
                });

                if (inputStreamCount > 10) {
                    this.webRtcInOutInfo.inputStats[key].streamInfos = {};
                }
            });

            this.webRtcInOutInfo.inputBytes = inputBytes;
            this.webRtcInOutInfo.outputBytes = outputBytes;
            this.webRtcInOutInfo.inputBytesP2P = inputBytesP2P;
            this.webRtcInOutInfo.outputBytesP2P = outputBytesP2P;
            this.webRtcInOutInfo.type = type;
            this.webRtcInOutInfo.isTurn = isTurn;

            logInfo("[ST] inputBytes:"+this.webRtcInOutInfo.inputBytes+" outputBytes:"+this.webRtcInOutInfo.outputBytes+" isTurn:"+this.webRtcInOutInfo.isTurn);
            this.props.sendWebRtcInfo(this.webRtcInOutInfo);
        }
    }

    getCalcBytes = (bytes: number, isTurn: boolean) => {
        let byteRate = 1;
        if (isTurn) {
            if (this.webRtcInOutInfo.connectType === 1) {
                // MeshでTURN接続の場合
                byteRate = 2;
            } else {
                // SFUでTURN利用の場合
                byteRate = 3;    
            }
        }

        // TURNの場合、Skyway側の通信量との誤差を補正するため、TURN通信量を5%(bytes * 2 * 0.05)増やしている
        return bytes * byteRate + (isTurn ? Math.ceil(bytes * 0.1) : 0);
    }

    initVideo = (elementId : string) => {
        this.initVideoKeepScreenShareStream(elementId, false);
    }

    initVideoKeepScreenShareStream = (elementId : string, keepScreenShareStream: boolean) => {
        logInfo('initVideoKeepScreenShareStream: elementId:' + elementId + ' keepScreenShareStream:' + keepScreenShareStream);
        const video: HTMLVideoElement = (document.getElementById(elementId + "-video") as HTMLVideoElement);
        if (video) {
            video.srcObject = null;
        }

        if (this.mediaStream !== undefined) {
            logInfo("initVideoKeepScreenShareStream: this.mediaStream:track.stop()");
            this.mediaStream.getTracks().forEach(track => track.stop());
        }

        this.mediaStream = undefined;

        if (keepScreenShareStream === false) {
            if (this.screenShareStream !== undefined) {
                logInfo("initVideoKeepScreenShareStream: screenShareStream:track.stop()");
                this.screenShareStream.getVideoTracks().forEach(track => track.stop());
            }

            this.screenShareStream = undefined;
        }
    }

    /**
     * ウィンドウの回転を判別するための処理
     */
    handleOrientationChange = () => {
        if (window.innerWidth < window.innerHeight) {
            this.isLandscape = false;
        } else {
            this.isLandscape = true;
        }

        setTimeout(this.reconnectVideoLocal, 1000);    
    }

    /**
     * HTML videoのソースを再接続することで以下の効果がある。
     * ・ウィンドウサイズと映像の一致
     * ・映像が回転していた場合、正しい向きで表示
     */
    reconnectVideoLocal = () => {
        // if (this.peer === null || this.peer === undefined) {
        if (!this.webRTCClient.isConnectedSignalingServer()) {
            return;
        }
        const video: HTMLVideoElementMod = (document.getElementById(this.state.sessionId + "-video") as HTMLVideoElementMod);
        if (video !== undefined && video !== null) {
            const mediaStream = (video.srcObject as MediaStream);
            video.srcObject = null;
            video.srcObject = mediaStream;
            video.playsInline = true;
            logInfo("reconnectVideoLocal: video.play()");
            video.play();
            if (this.connectRoom) {
                logInfo('reconnectVideoLocal: connectRoom.send(video window update)');
                this.connectRoom.send("video window update");
            }
        }
    }

    preventDefault = (e : any) => {
        e.preventDefault();
    }

    handleOpenDeviceSelect = () => {
        // #821 調査用ログ
        logInfo("handleOpenDeviceSelect: set state isOpenDeviceSelect true");
        this.setState({isOpenDeviceSelect: true});
    }

    handleCloseDeviceSelect = () => {
        // 次回以降、デバイス選択画面を表示しないようにするため
        // 選択されたデバイスをlocalstorage に保存する

        if (this.videoInputDeviceInfoList[this.currentVideoDeviceIdIndex]) {
            localStorage.setItem("videoDeviceId", this.state.videoDeviceId);
            localStorage.setItem("videoDeviceLabel", this.videoInputDeviceInfoList[this.currentVideoDeviceIdIndex].label);
        } else {
            localStorage.setItem("videoDeviceId", "");
            localStorage.setItem("videoDeviceLabel", "");
        }
        if (this.audioInputDeviceInfoList[this.currentAudioInputDeviceIdIndex]) {
            localStorage.setItem("audioInputDeviceId", this.state.audioInputDeviceId);
            localStorage.setItem("audioInputDeviceLabel", this.audioInputDeviceInfoList[this.currentAudioInputDeviceIdIndex].label);
        } else {
            localStorage.setItem("audioInputDeviceId", "");
            localStorage.setItem("audioInputDeviceLabel", "");
        }

        if (this.audioOutputDeviceInfoList[this.currentAudioOutputDeviceIdIndex]) {
            localStorage.setItem("audioOutputDeviceId", this.state.audioOutputDeviceId);
            localStorage.setItem("audioOutputDeviceLabel", this.audioOutputDeviceInfoList[this.currentAudioOutputDeviceIdIndex].label);
        } else {
            localStorage.setItem("audioOutputDeviceId", "");
            localStorage.setItem("audioOutputDeviceLabel", "");
        }

        if (this.isAnalyzeMicLevel) {
            logInfo("handleCloseDeviceSelect: stopMicVolumeAudio()")
            this.stopMicVolumeAudio();
        }

        if (this.props.analyzeMicMode === 1) {
            if (WebrtcService.isiOS() === false) {
                logInfo("handleCloseDeviceSelect: startAnalyzeMic()")
                this.startAnalyzeMic();
            }
        }

        this.selectedVideo = this.state.videoDeviceId !== "";
        this.selectedAudio = this.state.audioInputDeviceId !== "";
        // #821 調査用ログ
        logInfo("handleCloseDeviceSelect: set state isOpenDeviceSelect false");
        this.setState({
            hasVideoDevice: this.state.videoDeviceId !== "",
            hasAudioDevice: this.state.audioInputDeviceId !== "",
            isOpenDeviceSelect: false,
        });
        WebrtcService.setDeviceSelected(true);
        this.stopPreviewAudio();
        this.stopPreviewVideo();
        if (this.state.isCall) {
            let keepConnection = true;
            // 映像なしから映像ありに変更する場合には再接続が必要
            if (this.mediaStream && this.mediaStream.getVideoTracks().length === 0 && this.state.videoDeviceId !== "") {
                keepConnection = false;
            }

            // 音声なしから音声ありに変更する場合には再接続が必要
            if (keepConnection && this.mediaStream && this.mediaStream.getAudioTracks().length === 0 && this.state.audioInputDeviceId !== "") {
                keepConnection = false;
            }

            // 受信者モードで接続済みの場合
            if (keepConnection && this.connectRoom && (this.mediaStream === null || this.mediaStream === undefined)) {
                keepConnection = false;
            }

            // カメラ、マイクを未選択状態の場合、replaceStreamに渡すstreamがnullになりエラーとなるため、再接続する
            if (keepConnection && this.state.videoDeviceId === "" && this.state.audioInputDeviceId === "") {
                keepConnection = false;
            }

            // 相手に映像の最後が表示され続けないようにするための処理
            if (this.state.videoDeviceId === "" && this.mediaStream) {
                logInfo("handleCloseDeviceSelect: this.mediaStream:track.enabled:false");
                this.mediaStream.getVideoTracks().forEach(track => track.enabled = false);
            }

            if (keepConnection) {
                setTimeout(() => {
                    if (this.mediaStream) {
                        logInfo("handleCloseDeviceSelect: this.mediaStream:track.stop()");
                        this.mediaStream.getTracks().forEach(track => track.stop());
                    }
                    this.startCallKeepConnection();    
                }, 200);
            } else {
                logInfo("handleCloseDeviceSelect: stopCall()");
                this.stopCall();
                logInfo("handleCloseDeviceSelect: startCall()");
                this.startCall();
            }
        }
        // カメラ未選択時の表示対応
        // デバイス選択画面クローズをfloorへ通知
        this.props.onDeviceSelected();
    }

    startCallKeepConnection = async () => {
        // ルーム参加中にstartCallを呼び出した場合、
        // ルーム参加処理は実行されず、デバイスが再設定される
        await this.startCall();
        if (this.mediaStream) {
            logInfo("startCallKeepConnection: replaceStream(this.mediaStream)");
            this.connectRoom.replaceStream(this.mediaStream);
        }

        if (this.mediaStream 
            && this.mediaStream.getVideoTracks().length > 0
            && this.mediaStream.getVideoTracks()[0].enabled) {
            logInfo("startCallKeepConnection: SHARE_VIDEO_WINDOW_MAX_OFF");
            this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_MAX_OFF);
            this.setState({videoEnable: true});
        } else {
            logInfo("startCallKeepConnection: SHARE_VIDEO_WINDOW_OFF");
            this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_OFF);
        }
    }

    stopPreviewVideo = () => {
        try {
            const previewVideo = (document.getElementById("previewVideo") as HTMLVideoElement);
            if (previewVideo === null || previewVideo === undefined) {
                // 要素がない時なにもしない
                return;
            }
            const mediaStream = (previewVideo.srcObject as MediaStream);
            if (mediaStream !== null && mediaStream !== undefined) {
                logInfo("stopPreviewVideo: mediaStream:track.stop()");
                mediaStream.getTracks().forEach(track => track.stop());
                previewVideo.srcObject = null;
            }
        } catch(e) {
            logErr("stopPreviewVideo: error:")
            logException(e);
        }
    }

    stopPreviewAudio = () => {
        try {
            const previewAudio = (document.getElementById("previewAudio") as HTMLAudioElement);
            if (previewAudio === null || previewAudio === undefined) {
                // 要素がない時なにもしない
                return;
            }
            const mediaStream = (previewAudio.srcObject as MediaStream);
            if (mediaStream !== null && mediaStream !== undefined) {
                logInfo("stopPreviewAudio: mediaStream:track.stop()");
                mediaStream.getTracks().forEach(track => track.stop());
                previewAudio.srcObject = null;
            }
        } catch(e) {
            logErr("stopPreviewAudio: error:")
            logException(e);
        }
    }

    /**
     * マイク入力レベル取得用のストリームおよびProcessorを切断する処理
     */
    stopMicVolumeAudio = () => {
        try {
            if (this.micVolumeStream !== null && this.micVolumeStream !== undefined) {
                logInfo("stopMicVolumeAudio: this.micVolumeStream:track.stop()");
                this.micVolumeStream.getTracks().forEach(track => track.stop());
                this.micVolumeProcessor.disconnect();
                this.micVolumeProcessor.onaudioprocess = null;
                this.isAnalyzeMicLevel = false;
                // マイク入力レベル 0 をすぐに送信しても吹き出しが消えなかったため、
                // 1秒後に送信するようにしている。もっと短くてもいいかも。
                setTimeout(this.props.sendUserMicLevel.bind(0), 1000);
            }
        } catch(e) {
            logErr("stopMicVolumeAudio: error:")
            logException(e);
        }
    }

    handleCloseDeviceCheck = () => {
        this.stopPreviewAudio();
        this.stopPreviewVideo();

        // #409
        // デバイス情報が変更されていなければ何もしない
        logInfo("handleCloseDeviceCheck: prqData ["+this.pre_videoDeviceId+"] ["+this.pre_audioInputDeviceId+"] ["+this.pre_audioOutputDeviceId+"]");
        logInfo("handleCloseDeviceCheck: stsData ["+this.state.videoDeviceId+"] ["+this.state.audioInputDeviceId+"] ["+this.state.audioOutputDeviceId+"]");
        /* #554 マイクミュートが効かないことがあるのでやめる
        if(this.pre_videoDeviceId === this.state.videoDeviceId &&
                this.pre_audioInputDeviceId === this.state.audioInputDeviceId &&
                this.pre_audioOutputDeviceId === this.state.audioOutputDeviceId ) {
            logInfo("handleCloseDeviceCheck: prqData === stsData skip setting");
            this.setState({
                isOpenDeviceSelect: false,
            });
            this.props.onDeviceSelected();
            return;
        }
        */

        this.checkDeviceStream(this.state.videoDeviceId, this.state.audioInputDeviceId,
            () => {this.handleCloseDeviceSelect()},
            () => {
                this.showRequireDeviceMessage(this.props.requireCamera, this.props.requireMic);
                this.handlePreviewDeviceSelect();
            }
        );
    }

    checkDeviceStream = (videoDeviceId: string|null, audioInputDeviceId: string|null, successFunc: any, failFunc: any) => {
        if (videoDeviceId === null) {
            videoDeviceId = "";
        }
        if (audioInputDeviceId === null) {
            audioInputDeviceId = "";
        }

        const hasVideoDeviceId: boolean = videoDeviceId !== "";
        const hasAudioDeviceId: boolean = audioInputDeviceId !== "";
        // 必須設定のデバイスが未選択の場合
        if ((hasVideoDeviceId === false && this.props.requireCamera) || (hasAudioDeviceId === false && this.props.requireMic)) {
            failFunc();
            return;
        }

        // カメラとマイクがどちらも必須設定でなく未選択の場合は、getUserMediaを実行しない。
        // 通話中の場合もここではgetUserMediaを実行しない。
        if ((hasVideoDeviceId === false && hasAudioDeviceId === false) || this.state.isCall) {
            successFunc();
            return;
        }

        const constraints : any = {video: hasVideoDeviceId, audio: hasAudioDeviceId};
        if (hasVideoDeviceId) {
            constraints.video = {deviceId : this.state.videoDeviceId};
        }

        if (hasAudioDeviceId) {
            constraints.audio = this.getAudioConstraints(audioInputDeviceId);
        }

        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            let checkStream = true;
            if (stream.getVideoTracks().length === 0 && this.props.requireCamera) {
                checkStream = false;
            }

            if (stream.getAudioTracks().length === 0 && this.props.requireMic) {
                checkStream = false;
            }

            logInfo("handleCloseDeviceCheck: stream:track.stop()");
            stream.getTracks().forEach(track => track.stop());

            if (checkStream) {
                successFunc();
            } else {
                failFunc();
            }
        }).catch((error: DOMException) => {
            // Error
            logErr("handleCloseDeviceCheck: mediaDevice.getUserMedia() error:");
            logException(error);
            if (this.checkErrorRequireDevice(error.message)) {
                successFunc();
            } else {
                //this.showGetUserMediaErrorMessage(error.message);
                failFunc();
            }
        });
    }

    /**
     * 必須デバイス設定かどうかを確認し、
     * エラーが発生した原因が必須デバイスでない場合、trueを返す
     * 
     * @param errorMessage 
     */
     private checkErrorRequireDevice = (errorMessage: string) : boolean => {
        if (this.props.requireCamera === false && this.props.requireMic === false ) {
            return true;
        }

        if (errorMessage && errorMessage.indexOf) {
            if (this.props.requireCamera === false 
                    && (errorMessage.indexOf("Could not start video source") >= 0 
                    || errorMessage.indexOf("Failed to allocate videosource") >= 0)) {
                // カメラが必須ではなく
                // カメラが別のアプリで使用されている場合など
                return true;
            } else if ( this.props.requireMic === false
                    && (errorMessage.indexOf("Could not start audio source") >= 0 
                    || errorMessage.indexOf("Failed to allocate audiosource") >= 0)) {
                // マイクが必須ではなく
                // マイクが別のアプリで使用されている場合など
                // マイクは、別アプリと共用できる可能性があるため、このエラーは発生しないかも
                return true;
            }
        }

        return false;
    }

    handlePreviewDeviceSelect = () => {
        this.stopPreviewAudio();
        this.stopPreviewVideo();
        const hasVideoDeviceId: boolean = this.state.videoDeviceId !== "";
        const hasAudioDeviceId: boolean = this.state.audioInputDeviceId !== "";
        const constraints : any = {video: hasVideoDeviceId, audio: hasAudioDeviceId};

        if (hasVideoDeviceId === false && hasAudioDeviceId === false) {
            return;
        }

        if (hasVideoDeviceId) {
            constraints.video = {deviceId : {exact: this.state.videoDeviceId}};
        }

        if (hasAudioDeviceId) {
            constraints.audio = this.getAudioConstraints(null);
        }

        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            // Success
            this.mediaStream = stream;

            try {
                const audio: HTMLAudioElement = (document.getElementById('previewAudio') as HTMLVideoElement);
                audio.srcObject = stream;            
                if (this.state.audioOutputDeviceId !== "") {
                    (audio as any).setSinkId(this.state.audioOutputDeviceId).then(() => {
                        logInfo("handlePreviewDeviceSelect: audio.play()");
                        audio.play();
                    }).catch((e: any) => {
                        if (e.message && e.message.toLowerCase().indexOf("not found") >= 0) {
                            console.log("handlePreviewDeviceSelect setSinkId error : " + e.message);
                            // AudioPlayer.setDefaultSinkId();
                        }
                    });
                }
            } catch(e) {
                logErr("handlePreviewDeviceSelect: audio error:");
                logException(e);

                // ここに来るということは多分プレビューに失敗している
                // -> streamを解放して終了する
                logInfo("handlePreviewDeviceSelect: this.mediaStream:track.stop()");
                this.mediaStream.getTracks().forEach(track => track.stop());
                return;
            }

            try {
                const video: HTMLVideoElement = (document.getElementById('previewVideo') as HTMLVideoElement);
                video.srcObject = stream;
                logInfo("handlePreviewDeviceSelect: video.play()");
                video.play();
            } catch(e) {
                logErr("handlePreviewDeviceSelect: video error:");
                logException(e);

                // ここに来るということは多分プレビューに失敗している
                // -> streamを解放して終了する
                logInfo("handlePreviewDeviceSelect: this.mediaStream:track.stop()");
                this.mediaStream.getTracks().forEach(track => track.stop());
                return;
            }
        }).catch((error: DOMException) => {
            // Error
            logErr('handlePreviewDeviceSelect: mediaDevice.getUserMedia() error:');
            console.log("SELECT_DEV_TRACE handlePreviewDeviceSelect: mediaDevice.getUserMedia() error:["+error.message+"]")
            logException(error);
            this.showGetUserMediaErrorMessage(error.message);
            console.log("SELECT_DEV_TRACE handlePreviewDeviceSelect: display message")
        });
    }

    handleChangeVideoSelect = (event: React.ChangeEvent<{value : unknown}>) => {
        const deviceId = String(event.target.value);
        localStorage.setItem("videoDeviceId", deviceId);

        for (let i = 0; i < this.videoInputDeviceInfoList.length; i++) {
            if (this.videoInputDeviceInfoList[i].deviceId === deviceId) {
                this.currentVideoDeviceIdIndex = i;
                localStorage.setItem("videoDeviceLabel", this.videoInputDeviceInfoList[i].label);
                break;
            }
        }

        this.setState({
            videoDeviceId: deviceId,
            hasVideoDevice: deviceId !== "",
        });
    }

    handleChangeAudioInputSelect = (event: React.ChangeEvent<{value : unknown}>) => {
        const audioDeviceId = String(event.target.value);
        localStorage.setItem("audioInputDeviceId", audioDeviceId);

        for (let i = 0; i < this.audioInputDeviceInfoList.length; i++) {
            if (this.audioInputDeviceInfoList[i].deviceId === audioDeviceId) {
                this.currentAudioInputDeviceIdIndex = i;
                localStorage.setItem("audioInputDeviceLabel", this.audioInputDeviceInfoList[i].label);
                break;
            }
        }

        this.setState({
            audioInputDeviceId: audioDeviceId,
            hasAudioDevice: audioDeviceId !== "",
        });
    }

    handleChangeAudioOutputSelect = (event: React.ChangeEvent<{value : unknown}>) => {
        const audioDeviceId = String(event.target.value);
        localStorage.setItem("audioOutputDeviceId", audioDeviceId);

        for (let i = 0; i < this.audioOutputDeviceInfoList.length; i++) {
            if (this.audioOutputDeviceInfoList[i].deviceId === audioDeviceId) {
                this.currentAudioOutputDeviceIdIndex = i;
                localStorage.setItem("audioOutputDeviceLabel", this.audioOutputDeviceInfoList[i].label);
                break;
            }
        }

        this.setState({audioOutputDeviceId: audioDeviceId});
    }

    switchVideo = () => {
        if (this.state.hasVideoDevice === false) {
            return;
        }

        // logInfo("switchVideo, this.state.windowModeList[this.state.sessionId]: " + this.state.windowModeList[this.state.sessionId]);
        if (this.state.windowModeList[this.state.sessionId] !== undefined && this.state.windowModeList[this.state.sessionId] !== null) {
            if (this.state.videoEnable) {
                logInfo("switchVideo: SHARE_VIDEO_WINDOW_MAX_OFF");
                this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_OFF);
            } else {
                logInfo("switchVideo: SHARE_VIDEO_WINDOW_OFF");
                this.setStateWindowModeList(this.state.sessionId, WebrtcService.SHARE_VIDEO_WINDOW_MAX_OFF);
            }

            this.setState({
                videoEnable : this.state.videoEnable ? false : true,
            });
        } else {
            this.setState({
                videoEnable : false,
            });
        }
    }

    handleSwitchAudioClick = () => {
        if (this.state.hasAudioDevice === false || !this.webRTCClient.isConnectedSignalingServer()) {
            // || this.peer === undefined || this.peer === null) {
            return;
        }

        const audio = document.getElementById(this.state.sessionId + "-audio") as HTMLAudioElement;
        if (audio === undefined || audio === null) {
            return;
        }
        
        const stream = (audio.srcObject as MediaStream);
        if (stream === undefined || stream === null) {
            return;
        }

        const audioTrack = stream.getAudioTracks()[0];
        if (audioTrack === undefined || audioTrack === null) {
            return;
        }

        if (stream) {
            const audioTrack = stream.getAudioTracks()[0];
            if (audioTrack.enabled) {
                audioTrack.enabled = false;
            } else {
                audioTrack.enabled = true;
            }
        }

        this.setState({
            audioEnable : audioTrack.enabled,
        });
    }

    setStateWindowModeList = (id: string, mode: number) => {
        // logInfo("id : " + id + ", mode : " + mode);
        const copyList = Object.assign({}, this.state.windowModeList);
        copyList[id] = mode;
        this.setState({
            windowModeList: copyList,
        });
    }

    checkedSetSinkIdMethod = false;
    hasSetSinkIdMethod = false;
    checkSetSinkIdMethod = () => {
        if (this.checkedSetSinkIdMethod) {
            return this.hasSetSinkIdMethod;
        }

        const video = document.getElementById("dummyVideo") as any;
        if (video) {
            this.checkedSetSinkIdMethod = true;

            if (video.setSinkId) {
                this.hasSetSinkIdMethod = true;
            }
        }

        return this.hasSetSinkIdMethod;
    }

    /**
     * 映像ストリームの有効、無効を設定する
     * 
     * @param enable 
     */
    setVideoStreamEnable = (enable:boolean) => {
        // logInfo("setVideoStreamEnable");
        const video = document.getElementById(this.state.sessionId + "-video") as HTMLVideoElement;
        if (video === undefined || video === null) {
            return;
        }
        
        let stream = (video.srcObject as MediaStream);
        if (stream === undefined || stream === null) {
            if (this.mediaStream === undefined || this.mediaStream === null) {
                return;
            }
            
            // 通話開始時にプライバシーモードの場合、
            // video.srcObject に MediaStreamが設定されていない
            stream = this.mediaStream as MediaStream;
            video.srcObject = this.mediaStream;
        }
    
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack === undefined || videoTrack === null) {
            return;
        }
    
        videoTrack.enabled = enable;
        logInfo("setVideoStreamEnable video_size:" + video.videoWidth + " x " + video.videoHeight);
    }

    /**
     * 音声ストリームの有効、無効を設定する
     * 
     * @param enable 
     */
    setAudioStreamEnable = (enable:boolean) => {
        // logInfo("setAudioStreamEnable");
        if (this.mediaStream === undefined || this.mediaStream === null) {
            logInfo("setAudioStreamEnable: this.mediaStream:" + (this.mediaStream === undefined) ? "undefined" : "null");
            return;
        }
        
        // 通話開始時にプライバシーモードの場合、
        // audio.srcObject に MediaStreamが設定されていない
        const stream = this.mediaStream as MediaStream;
        const audioTrack = stream.getAudioTracks()[0];

        if (audioTrack === undefined || audioTrack === null) {
            logInfo("setAudioStreamEnable: audioTrack()[0]:" + (audioTrack === null) ? "null" : "undefined");
            return;
        }
    	logInfo("setAudioStreamEnable: audioTrack()[0].enabled:" + enable);
        audioTrack.enabled = enable;
		/*
		//ミュートをすべてのトラックを対象に
        const stream = this.mediaStream as MediaStream;
        stream.getAudioTracks().forEach((audioTrack, i) => {
            if (audioTrack === null || audioTrack === undefined) {
                logInfo("setAudioStreamEnable: audioTrack[" + i.toString() + "]:" + (audioTrack === null) ? "null" : "undefined");
                return;
            }
            audioTrack.enabled = enable;
        });
		*/    
    }

    /**
     * マイクデバイスのIDを取得
     * SafariでデバイスIDがブラウザを起動しなおすたびに代わることに対する対応用の処理
     * 
     * @param deviceId 
     */
    private getAudioDeviceId = (deviceId: string|null) => {
        if (deviceId) {
            for (let i = 0; i < this.audioInputDeviceInfoList.length; i++) {
                if (deviceId === this.audioInputDeviceInfoList[i].deviceId) {
                    return deviceId;
                }
            }
    
            const audioDeviceLabel = localStorage.getItem("audioInputDeviceLabel");
            if (audioDeviceLabel && audioDeviceLabel !== "") {
                for (let i = 0; i < this.audioInputDeviceInfoList.length; i++) {
                    if (audioDeviceLabel === this.audioInputDeviceInfoList[i].label) {
                        const audioDeviceId = this.audioInputDeviceInfoList[i].deviceId;
                        localStorage.setItem("audioInputDeviceId", audioDeviceId);
                        this.currentAudioInputDeviceIdIndex = i;
                        return audioDeviceId;
                    }
                }
            }
        }
        
        return "";
    }

    private startAnalyzeMic = () => {
        const audioDeviceId = this.getAudioDeviceId(localStorage.getItem("audioInputDeviceId"));
        const hasAudioDeviceId: boolean = audioDeviceId !== "";
        const constraints : any = {video: false, audio: hasAudioDeviceId};

        if (hasAudioDeviceId === false) {
            return;
        }

        if (hasAudioDeviceId) {
            constraints.audio = this.getAudioConstraints(audioDeviceId);
        }

        const handleUserMicLevel: any = this.props.sendUserMicLevel;
        const showGetUserMediaErrorMessage: any = this.showGetUserMediaErrorMessage;

        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                // このストリームを後から停止するために保持しておく
                this.micVolumeStream = stream;

                let audioContext: any = new AudioContext();

                let analyser: any = audioContext.createAnalyser();
                let microphone: any = audioContext.createMediaStreamSource(stream);
                this.micVolumeProcessor = audioContext.createScriptProcessor(1024, 1, 1);

                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 512;

                microphone.connect(analyser);
                analyser.connect(this.micVolumeProcessor);
                this.micVolumeProcessor.connect(audioContext.destination);
                this.micVolumeProcessor.onaudioprocess = function () {
                    const array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    let values = 0;

                    const length = array.length;
                    for (var i = 0; i < length; i++) {
                        values += (array[i]);
                    }

                    const currentMicLevel = Math.round(values / length);
                    handleUserMicLevel(currentMicLevel);
                }
            })
            .catch(function (err) {
                logErr('startAnalyzeMic: mediaDevice.getUserMedia() error:');
                logException(err);
                showGetUserMediaErrorMessage(err.message);
            });

        this.isAnalyzeMicLevel = true;
    }

    // getWebRTCInOutBytes のタイマー停止用
    private timerGetWebRTCInOutBytes : any = null;
    // getWebRTCInOutBytes で取得した情報をサーバに送るまで一時的に保存するための変数
    private webRtcInOutInfo : WebRtcInOutInfo = new WebRtcInOutInfo();
    // 定期的にサーバにデータを送るためのカウンター
    private timerGetWebRTCInOutBytesCount : number = 0;

    /**
     * WebRTCの送受信バイト数などの情報を取得する処理
     */
    private getWebRTCInOutBytes = async () => {
        this.timerGetWebRTCInOutBytesCount++;
        // console.log("this.timerGetWebRTCInOutBytesCount = " + this.timerGetWebRTCInOutBytesCount);
        if (this.connectRoom && this.connectRoom.getPeerConnections) {
            // mesh接続の場合の各種情報取得用の処理
            this.webRtcInOutInfo.connectType = 1;
            //logInfo("[ST] MESH: connectionType:"+this.webRtcInOutInfo.connectType);
            const pcs = this.connectRoom.getPeerConnections();
            // console.log("this.connectRoom.getPeerConnections()");
            // console.log(pcs);
            let pc;
            for ([, pc] of Object.entries(pcs)) {
                if (pc) {
                    await (pc as RTCPeerConnection).getStats().then((stats) => {
                        let localCandidateId: string;
                        let remoteCandidateId: string;
                        stats.forEach((stat) => {
                            // logInfo(stat);
                            if (stat.type === "candidate-pair" && stat.nominated === true && stat.state === "succeeded") {
                                localCandidateId = stat.localCandidateId;
                                remoteCandidateId = stat.remoteCandidateId;
                            }

                            if (localCandidateId && remoteCandidateId) {
                                this.setWebRTCStatsInfo(stat, localCandidateId, remoteCandidateId);
                            }
                        });
                    });
                }
            }
        } else if (this.connectRoom && this.connectRoom.getPeerConnection) {
            // sfu接続の場合の各種情報取得用の処理
            this.webRtcInOutInfo.connectType = 2;
            //logInfo("[ST] SFU: connectionType:"+this.webRtcInOutInfo.connectType);
            const pc = this.connectRoom.getPeerConnection();
            // console.log("this.connectRoom.getPeerConnection()");
            // console.log(pc);
            if (pc) {
                await (pc as RTCPeerConnection).getStats().then((stats) => {
                    let localCandidateId: string;
                    let remoteCandidateId: string;
                    stats.forEach((stat) => {
                        // logInfo(stat);
                        if (stat.type === "candidate-pair" && stat.nominated === true && stat.state === "succeeded") {
                            localCandidateId = stat.localCandidateId;
                            remoteCandidateId = stat.remoteCandidateId;
                        }
    
                        if (localCandidateId && remoteCandidateId) {
                            this.setWebRTCStatsInfo(stat, localCandidateId, remoteCandidateId);
                        }
                    });
                });
            }
        } else {
            logInfo("getStats()が呼び出せない");
        }

        if (this.timerGetWebRTCInOutBytesCount >= this.props.webRtcInfoInterval) {
            this.sendWebRtcInOutInfo("");
            this.timerGetWebRTCInOutBytesCount = 0;
        }
        /*
        1秒ごとにミュートの確認・補正を行うならここで。
        if(this.props.audioMute === true){
            const stream = this.mediaStream as MediaStream;
            stream.getAudioTracks().forEach((audioTrack, i) => {
                logInfo("getWebRTCInOutBytes: audioMute:" + this.props.audioMute);
                if(this.mediaStream === null || this.mediaStream === undefined){
                    logInfo("getWebRTCInOutBytes: this.mediaStream:" + this.mediaStream === null ? "null" : "undefined");
                } else {
                    if(this.mediaStream?.getAudioTracks() === null){
                        logInfo("getWebRTCInOutBytes: audioTracks() is null");
                    } else {
                        if(this.mediaStream?.getAudioTracks()[i].enabled === true){
                            logInfo("getWebRTCInOutBytes: audioTracks()[" + i.toString() + "].enabled:" + this.mediaStream?.getAudioTracks()[i].enabled ? "true" : "false");
                            this.setAudioStreamEnable(this.props.audioMute ? false :true);
                        }
                    }
                }
            });
        }
        */
    }

    /**
     * WebRTCのstats 情報を元にデータ入出力バイト数などの情報を webRtcInOutInfo に設定する
     * 
     * @param stat 
     * @param localCandidateId 
     * @param remoteCandidateId 
     */
    private setWebRTCStatsInfo = (stat: any, localCandidateId: string, remoteCandidateId: string) => {
        // logInfo(stat);
        let diffBytesSent = 0;
        let diffBytesReceived = 0;
        let local_remote_pair: string = localCandidateId + "_" + remoteCandidateId;
        let remote_local_pair: string = remoteCandidateId + "_" + localCandidateId;

        if (stat.type === "candidate-pair" && stat.nominated === true && stat.state === "succeeded") {
            if (this.webRtcInOutInfo.outputStats[localCandidateId] === undefined) {
                this.webRtcInOutInfo.sessionId = this.state.sessionId;
    
                if (this.connectRoom) {
                    this.webRtcInOutInfo.roomId = this.connectRoom.name;
                }
    
                this.webRtcInOutInfo.browser = window.navigator.userAgent;
                // mesh接続の場合、CandidatePairが複数存在する場合があるため
                // 後から接続したユーザーの接続したタイミングで開始日時が上書きされないようにしている
                if (this.webRtcInOutInfo.startUnixTime === 0) {
                    this.webRtcInOutInfo.startUnixTime = Math.floor(new Date().getTime() / 1000);
                }
                
                this.webRtcInOutInfo.outputStats[localCandidateId] = new WebRtcStats();
                this.webRtcInOutInfo.outputStats[localCandidateId].id = localCandidateId;
                this.webRtcInOutInfo.outputStats[localCandidateId].pairId = remoteCandidateId;
                this.webRtcInOutInfo.prevOutputStats[local_remote_pair] = new WebRtcStats();
                this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal = 0;
                //logInfo("[ST] create WebStats prevOuputStats["+local_remote_pair+"].bytesTotal:"+this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal);
            } else {
                //logInfo("[ST] exist WebStats prevOuputStats["+local_remote_pair+"].bytesTotal:"+this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal);
            }
        
            //if(stat.bytesSent > 0){
                if(stat.bytesSent - this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal < 0){
                    logWarn("[ST] localCandidateId:"+localCandidateId+" stat.bytesSent-prevBytesSent,"+(stat.bytesSent-this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal).toString()+",stat.bytesSent,"+stat.bytesSent.toString()+",prevSent,"+this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal.toString());
                    this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal = 0;    
                }
                diffBytesSent = stat.bytesSent - this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal;
        
                this.webRtcInOutInfo.outputStats[localCandidateId].bytesTotal = this.webRtcInOutInfo.outputStats[localCandidateId].bytesTotal + diffBytesSent;
                this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal = stat.bytesSent;
                //logInfo("[ST] ouyputStats["+localCandidateId+"].bytesTotal:"+this.webRtcInOutInfo.outputStats[localCandidateId].bytesTotal+" prevOutputStats["+local_remote_pair+"].bytesTotal:"+this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal);
                //logInfo("[ST] webRtcInOutInfo:1:sent:"+this.webRtcInOutInfo.outputStats[localCandidateId].bytesTotal+":"+stat.bytesSent+":"+diffBytesSent);
            //}
    
            if (this.webRtcInOutInfo.inputStats[remoteCandidateId] === undefined) {
                this.webRtcInOutInfo.inputStats[remoteCandidateId] = new WebRtcStats();
                this.webRtcInOutInfo.inputStats[remoteCandidateId].id = remoteCandidateId;
                this.webRtcInOutInfo.inputStats[remoteCandidateId].pairId = localCandidateId;
                this.webRtcInOutInfo.prevInputStats[remote_local_pair] = new WebRtcStats();
                this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal = 0;
                //logInfo("[ST] create WebStats prevInputStats["+remote_local_pair+"].bytesTotal:"+this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal);
            } else {
                //logInfo("[ST] exist WebStats prevInputStats["+remote_local_pair+"].bytesTotal:"+this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal);
            }

            //if(stat.bytesReceived > 0){
                if(stat.bytesReceived - this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal < 0){
                    logWarn("[ST] remoteCandidateId:"+remoteCandidateId+" stat.bytesReceived-prevBytesReceived,"+(stat.bytesReceived-this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal).toString()+",stat.bytesReceived,"+stat.bytesReceived.toString()+",prevBytesReceived,"+this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal.toString());
                    this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal = 0;
                }
                diffBytesReceived = stat.bytesReceived - this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal;
        
                this.webRtcInOutInfo.inputStats[remoteCandidateId].bytesTotal = this.webRtcInOutInfo.inputStats[remoteCandidateId].bytesTotal + diffBytesReceived;
                this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal = stat.bytesReceived;
                //logInfo("[ST] inputStats["+remoteCandidateId+"].bytesTotal:"+this.webRtcInOutInfo.inputStats[remoteCandidateId].bytesTotal+" prevInputStats["+remote_local_pair+"].bytesTotal:"+this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal);
                //logInfo("[ST] webRtcInOutInfo:2:received:"+this.webRtcInOutInfo.inputStats[remoteCandidateId].bytesTotal+":"+stat.bytesReceived+":"+diffBytesReceived);
            //}
        } else if (stat.type === "inbound-rtp") {
            if (this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id] === undefined) {
                this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id] = new WebRtcStatsStream();
                this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id].id = stat.id;
                this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id].kind = stat.kind;
            }
            diffBytesReceived = (stat.bytesReceived - this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal < 0) ? stat.bytesReceived : stat.bytesReceived - this.webRtcInOutInfo.prevInputStats[remote_local_pair].bytesTotal;
    
            // headerBytesReceived は、現状 Chromeのみで取得可能
            this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id].bytes = this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id].bytes + diffBytesReceived + (stat.headerBytesReceived ? stat.headerBytesReceived : 0);
            //logInfo("[ST] inputStats["+remoteCandidateId+"].streamInfos["+stat.id+"]="+prevBytesReceived.toString()+"+"+diffBytesReceived.toString()+"+"+(stat.headerBytesReceived ? stat.headerBytesReceived : 0).toString()+"="+this.webRtcInOutInfo.inputStats[remoteCandidateId].streamInfos[stat.id].bytes.toString());
            //logInfo("[ST] webRtcInOutInfo:3:received:"+stat.bytesReceived+":"+diffBytesReceived);
        } else if (stat.type === "outbound-rtp") {
            if (this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id] === undefined) {
                this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id] = new WebRtcStatsStream();
                this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id].id = stat.id;
                this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id].kind = stat.kind;
            }
            diffBytesSent = (stat.bytesSent - this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal < 0) ? stat.bytesSent : stat.bytesSent - this.webRtcInOutInfo.prevOutputStats[local_remote_pair].bytesTotal;

            // headerBytesSent は、現状 Chromeのみで取得可能
            this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id].bytes =this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id].bytes + diffBytesSent + (stat.headerBytesSent ? stat.headerBytesSent : 0);
            //logInfo("[ST] outputStats["+localCandidateId+"].streamInfos["+stat.id+"]="+prevBytesSent.toString()+"+"+diffBytesSent.toString()+"+"+(stat.headerBytesSent ? stat.headerBytesSent : 0).toString()+"="+this.webRtcInOutInfo.outputStats[localCandidateId].streamInfos[stat.id].bytes.toString());
            //logInfo("[ST] webRtcInOutInfo:4:sent:"+stat.bytesSent+":"+diffBytesSent);
        }

        if (stat.id === localCandidateId) {
            this.webRtcInOutInfo.outputStats[localCandidateId].candidateType = stat.candidateType;
        }

        if (stat.id === remoteCandidateId) {
            this.webRtcInOutInfo.inputStats[remoteCandidateId].candidateType = stat.candidateType;
        }
    }

    // await 内で変数を書き換える場合に、checkWebRTCTurnServer メソッド内で定義した変数を
    // 使用すると警告がでるためここで定義している
    private useTurnServer = false;
    private webRtcStatsChecked = false;
    /**
     * TURNサーバ利用判定処理
     * 
     * 画面共有判定で使用するため、判定するための情報が得られなかった場合、
     * TURNサーバを使用しているものとしている
     */
    private checkWebRTCTurnServer = async () => {
        this.useTurnServer = false;
        this.webRtcStatsChecked = false;
        this.props.setVisibleScreenShareButton(false);
        // statのcandidateType でTURNを使用しているかどうかを判別できる
        // Chrome: host/srflx/prflx/relay
        // Firefox: host/serverreflexive/peerreflexive/relayed-udp,relayed-tcp
        // host: ローカルネットワーク内でつながっている
        // srlfx: STUNサーバを利用して入手したIPアドレス情報、ポート番号を元に接続している
        // prflx: UDPホールパンチング中に発見された接続候補のIPアドレス、ポート番号を元に接続している
        // relay: TURNサーバを経由して接続している
        if (this.connectRoom && this.connectRoom.getPeerConnections) {
            // mesh接続の場合の各種情報取得用の処理
            const pcs = this.connectRoom.getPeerConnections();
            let pc;
            for ([, pc] of Object.entries(pcs)) {
                // logInfo(peerId);
                // logInfo((pc as RTCPeerConnection).getStats());
                await (pc as RTCPeerConnection).getStats().then((stats) => {
                    let localCandidateId: string;
                    let remoteCandidateId: string;
                    stats.forEach((stat) => {
                        // logInfo(stat);
                        if (stat.type === "candidate-pair" && stat.nominated === true && stat.state === "succeeded") {
                            localCandidateId = stat.localCandidateId;
                            remoteCandidateId = stat.remoteCandidateId;
                            this.webRtcStatsChecked = true;
                            // logInfo(stat.id);
                        }

                        this.setWebRTCTurnServerInfo(stat, localCandidateId, remoteCandidateId);

                        if (this.useTurnServer) {
                            return;
                        }
                    });

                    if (this.useTurnServer) {
                        return;
                    }
                });
            }
        } else if (this.connectRoom && this.connectRoom.getPeerConnection) {
            // sfu接続の場合の各種情報取得用の処理
            const pc = this.connectRoom.getPeerConnection();
            if (pc) {
                await (pc as RTCPeerConnection).getStats().then((stats) => {
                    let localCandidateId: string;
                    let remoteCandidateId: string;
                    stats.forEach((stat) => {
                        // logInfo(stat);
                        if (stat.type === "candidate-pair" && stat.nominated === true && stat.state === "succeeded") {
                            localCandidateId = stat.localCandidateId;
                            remoteCandidateId = stat.remoteCandidateId;
                            this.webRtcStatsChecked = true;
                            // logInfo(stat);
                        }
    
                        this.setWebRTCTurnServerInfo(stat, localCandidateId, remoteCandidateId);
    
                        if (this.useTurnServer) {
                            return;
                        }
                    });
    
                    if (this.useTurnServer) {
                        return;
                    }
                });    
            }
        } else {
            logInfo("getStats()が呼び出せない");
            // throw new Error('getStats()が呼び出せない');
        }

        // MeshかつTURNサーバを使用していない場合、画面共有ボタンを表示する
        // 全体放送の受信者の場合も表示しない
        const webRtcConnectMode = WebrtcService.getConnectMode(this.props.roomId, this.props.webRtcMode);
        if (this.props.screenShareMode === ScreenShareMode.FullScreen
                && webRtcConnectMode === "mesh"
                && WebrtcService.getWebRtcReceiveMode(this.props.sessionId, this.props.roomId) === false) {
            // 判定するための情報があった場合のみ、画面共有を有効にする
            if (this.webRtcStatsChecked && this.useTurnServer === false && !this.props.isScreenShare) {
                this.props.setVisibleScreenShareButton(true);
            }
        }

        // 後から接続してきたユーザーがTURN経由の場合に切断する処理
        if (this.props.screenShareMode === ScreenShareMode.FullScreen
                && this.props.isScreenShare
                && (webRtcConnectMode === "sfu" || this.useTurnServer)) {
            this.props.handleScreenShare(false, ScreenShareMode.FullScreen, this.props.roomId);
        }
    }

    private setWebRTCTurnServerInfo = (stat: any, localCandidateId: string, remoteCandidateId: string) => {
        if (stat.id === localCandidateId) {
            if (stat.candidateType === "relay" || stat.candidateType === "relayed-udp" || stat.candidateType === "relayed-tcp" ) {
                // logInfo("ローカル側 TURNを使用している。 : " + stat.candidateType);
                this.useTurnServer = true;
            } else {
                // logInfo("ローカル側 : " + stat.candidateType);
            }
        } else if (stat.id === remoteCandidateId) {
            if (stat.candidateType === "relay" || stat.candidateType === "relayed-udp" || stat.candidateType === "relayed-tcp" ) {
                // logInfo("リモート側 TURNを使用している。 : " + stat.candidateType);
                this.useTurnServer = true;
            } else {
                // logInfo("リモート側 : " + stat.candidateType);
            }
        }
    }

    private showGetUserMediaErrorSnackBar = (errorMessage: string) => {
        if (errorMessage && errorMessage.indexOf) {
            if (errorMessage.indexOf("Could not start video source") >= 0 || errorMessage.indexOf("Failed to allocate videosource") >= 0) {
                // カメラが別のアプリで使用されている場合など
                console.log("VideoDeviceError: showGetUserMediaErrorSnackBar")
                this.showVideoErrorSnackBar();
            } else if (errorMessage.indexOf("Could not start audio source") >= 0 || errorMessage.indexOf("Failed to allocate audiosource") >= 0) {
                // マイクが別のアプリで使用されている場合など
                // マイクは、別アプリと共用できる可能性があるため、このエラーは発生しないかも
                this.showAudioErrorSnackBar();
            } else if (errorMessage.indexOf("device not found") >= 0 || errorMessage.indexOf("Permission denied") >= 0) {
                // 指定されたデバイスが見つからない場合
                this.showVideoAndAudioErrorSnackBar();
            } else {
                this.showVideoAndAudioErrorSnackBar();
                logInfo(errorMessage);
            }
        } else {
            this.showVideoAndAudioErrorSnackBar();
            logInfo(errorMessage);
        }
    }

    private showGetUserMediaErrorMessage = (errorMessage: string) => {
        console.log("SELECT_DEV_TRACE showGetUserMediaErrorMessage start error:["+errorMessage+"]")
        this.props.showMessage('', '', '', '', false);
        let title = "デバイスが使用中です";
        let message = "";
        if (errorMessage && errorMessage.indexOf) {
            if (errorMessage.indexOf("Could not start video source") >= 0 || errorMessage.indexOf("Failed to allocate videosource") >= 0) {
                // カメラが別のアプリで使用されている場合など
                message = "カメラを使用しているアプリケーションを終了後、再度やり直してください。";
            } else if (errorMessage.indexOf("Could not start audio source") >= 0 || errorMessage.indexOf("Failed to allocate audiosource") >= 0) {
                // マイクが別のアプリで使用されている場合など
                // マイクは、別アプリと共用できる可能性があるため、このエラーは発生しないかも
                message = "マイクを使用しているアプリケーションを終了後、再度やり直してください。";
            } else if (errorMessage.indexOf("device not found") >= 0 || errorMessage.indexOf("Permission denied") >= 0) {
                // 指定されたデバイスが見つからない場合
                title = "デバイスが見つかりませんでした";
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
            } else {
                title = "デバイスが見つかりませんでした";
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
                logInfo(errorMessage);
            }
        } else {
            title = "デバイスが見つかりませんでした";
            message = "以下の点を確認してください。"
                + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
            logInfo(errorMessage);
        }

        console.log("SELECT_DEV_TRACE showGetUserMediaErrorMessage show ["+title+"]["+message+"]")
        this.props.showMessage(title, message, '', '', true);
    }

    private showRequireDeviceMessage = (requireCamera: boolean, requireMic: boolean) => {
        console.log("SELECT_DEV_TRACE showRequireDeviceMessage start")
        this.props.showMessage("", "", "", "", false);
        const title = "デバイスが見つかりませんでした";
        let message = "";
        if (requireCamera && requireMic) {
            // カメラ、マイクが必須
            message = "以下の点を確認してください。"
                + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
        } else if (requireCamera) {
            // カメラが必須
            message = "以下の点を確認してください。"
                + "\n・ご使用の機器にカメラが搭載されているか"
                + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                + "\n・ブラウザの設定でカメラの使用が許可されているか";
        } else if (requireMic) {
            // マイクが必須
            message = "以下の点を確認してください。"
                + "\n・ご使用の機器にマイクが搭載されているか"
                + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                + "\n・ブラウザの設定でマイクの使用が許可されているか";
        } else {
            message = "以下の点を確認してください。"
                + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
        }

        console.log("SELECT_DEV_TRACE showRequireDeviceMessage show ["+title+"]["+message+"]")
        this.props.showMessage(title, message, "", "", true);
    }

    private showGetDisplayMediaErrorMessage = (errorMessage: string) => {
        this.props.showMessage('', '', '', '', false);
        let title = "画面共有が行えませんでした";
        let message = "";
        if (errorMessage.indexOf("Permission denied") >= 0) {
            message = "以下の点を確認してください。"
                + "\n・ブラウザの設定で画面共有が許可されているか";
        } else {
            message = "以下の点を確認してください。"
                + "\n・ブラウザの設定で画面共有が許可されているか";
            logInfo(errorMessage);
        }

        this.props.showMessage(title, message, '', '', false);
    }

    /**
     * 再生に失敗した場合、ダイアログを表示し
     * ダイアログを閉じるタイミングでWebRTC通話の音声を全てチェックし、
     * pause状態のものに対してplayを行うことで再生失敗した際、
     * ユーザートリガーによる音声再生処理としている
     * 
     * @param id 
     */
    playSoundFromAudioId = (id: number) => {
        AudioPlayer.play(id).then((result: boolean) => {
            if (result === false) {
                this.props.showMessage("音声の再生ができませんでした", "本メッセージを閉じた後、音声を再生します。(4)", '', '', false);
            }
        });
    }

    /**
     * 再生に失敗した場合、ダイアログを表示し
     * ダイアログを閉じるタイミングでWebRTC通話の音声を全てチェックし、
     * pause状態のものに対してplayを行うことで再生失敗した際、
     * ユーザートリガーによる音声再生処理としている
     * 
     * @param audio 
     */
    playSoundFromAudio = async (audio: HTMLAudioElement) => {
        try {
            logInfo("playSoundFromAudio: audio.play() audio:"+audio.id);
            AudioPlayer.playAudioRetry(audio).then(() => {
                if (audio.paused) {
                    this.props.showMessage("音声の再生ができませんでした", "本メッセージを閉じた後、音声を再生します。(5)", '', '', false);
                    logWarn('playSoundFromAudio: play() paused:');
                }
            }).catch(() => {
                this.props.showMessage("音声の再生ができませんでした", "本メッセージを閉じた後、音声を再生します。(6-1)", '', '', false);
                logErr('playSoundFromAudio: play() error:');
            });
        } catch(e)  {
            this.props.showMessage("音声の再生ができませんでした", "本メッセージを閉じた後、音声を再生します。(6-2)", '', '', false);
            logErr('playSoundFromAudio: play() error:');
            logException(e);
        }
    }

    /**
     * 画面の上部、中央に警告用のSnackbarを表示する
     * 
     * @param message 
     */
    showErrorSnackBar = (message: React.ReactNode) => {
        this.props.enqueueSnackbar(
            message,
            {
                key: new Date().getTime() + Math.random(),
                variant: "warning",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                persist: true,
                //preventDuplicate: true,
                action: key => (
                    <Button onClick={() => this.props.closeSnackbar(key)}>✕</Button>
                ),
            }
        );
    }

    /**
     * 画面の上部、中央にカメラが使用できない場合の警告用のSnackbarを表示する
     */
     showVideoErrorSnackBar = () => {
        // カメラ未選択時の表示対応
        if(this.state.videoDeviceId === "") {
            console.log("VideoDeviceError : Video Device 未選択のためエラー表示スキップ")
            return;
        }
        this.showErrorSnackBar(
            <span>
            <br/> <br/>
            カメラが他のアプリケーションで使用されている可能性があります。 <br/>
            <br/>
            カメラを使用しているアプリケーションを終了後、再度やり直してください。<br/>
            <br/>
            </span>            
        );
        this.props.displayErrorSnackBar(1);     // #369
    }

    /**
     * 画面の上部、中央にマイクが使用できない場合の警告用のSnackbarを表示する
     */
     showAudioErrorSnackBar = () => {
        // マイク未選択時の表示対応
        if(this.state.audioInputDeviceId === "") {
            console.log("AudioInputDeviceError : AudioInput Device 未選択のためエラー表示スキップ")
            return;
        }
        this.showErrorSnackBar(
            <span>
            <br/> <br/>
            マイクが他のアプリケーションで使用されている可能性があります。 <br/>
            <br/>
            マイクを使用しているアプリケーションを終了後、再度やり直してください。<br/>
            <br/>
            </span>            
        );
        this.props.displayErrorSnackBar(2);     // #369
    }

    /**
     * 画面の上部、中央にカメラかマイクが使用できない場合の警告用のSnackbarを表示する
     */
     showVideoAndAudioErrorSnackBar = () => {
        // カメラ・マイク未選択時の表示対応
        if(this.state.videoDeviceId === "" && this.state.audioInputDeviceId === "") {
            console.log("VideoAudioInputDeviceError : VideoAudioInput Device 未選択のためエラー表示スキップ")
            return;
        }
        this.showErrorSnackBar(
            <span>
            <br/> <br/>
            カメラまたはマイクが他のアプリケーションで使用されている可能性があります。 <br/>
            <br/>
            カメラまたはマイクを使用しているアプリケーションを終了後、再度やり直してください。<br/>
            <br/>
            </span>            
        );
        this.props.displayErrorSnackBar(3);     // #369
    }

    renderOptionAudioInputSelect() {
        const items = this.audioInputDeviceInfoList.map((target) => 
            <option key={target.deviceId} value={target.deviceId}>{target.label}</option>
        );

        return items;
    }

    renderOptionAudioOutputSelect() {
        const items = this.audioOutputDeviceInfoList.map((target, index) => 
            <option key={target.deviceId} value={target.deviceId}>{target.label === "" ? "スピーカー" + (index + 1) : target.label}</option>
        );

        return items;
    }

    renderOptionVideoSelect() {
        const items = this.videoInputDeviceInfoList.map((target) => 
            <option key={target.deviceId} value={target.deviceId}>{target.label}</option>
        );

        return items;
    }

    // #11563 カメラ/マイク必須フロアは「未選択」を出さない
    renderOptionNoSelect(dev:number) {
        console.log("renderOptionNoSelect : dev=["+dev+"] requireCamera=["+this.props.requireCamera+"] requireMic=["+this.props.requireMic+"]")
        let noSelect: JSX.Element[] = [];
        if((dev === 1 && !this.props.requireCamera) || (dev === 2 && !this.props.requireMic)) {
            //noSelect[0] = <option key="" value="">未選択</option>
            noSelect[0] = <option key="" value="">なし</option>
        } else {
            if(dev === 1) {
                if(this.state.videoDeviceId === "" && this.state.hasVideoDevice) {
                    localStorage.setItem("videoDeviceId", this.videoInputDeviceInfoList[0].deviceId);
                    localStorage.setItem("videoDeviceLabel", this.videoInputDeviceInfoList[0].label);
                    this.currentVideoDeviceIdIndex = 0;
                    this.setState({
                        videoDeviceId: this.videoInputDeviceInfoList[0].deviceId,
                    });
                }
            } else if(dev === 2) {
                if(this.state.audioInputDeviceId === "" && this.state.hasAudioDevice) {
                    localStorage.setItem("audioInputDeviceId", this.audioInputDeviceInfoList[0].deviceId);
                    localStorage.setItem("audioInputDeviceLabel", this.audioInputDeviceInfoList[0].label);
                    this.currentAudioInputDeviceIdIndex = 0;
                    this.setState({
                        audioInputDeviceId: this.audioInputDeviceInfoList[0].deviceId,
                    });
                }
            }
        }
        console.log("renderOptionNoSelect ret: ["+noSelect+"]")
        return noSelect;
    }

    renderDeviceDialog() {
        const { classes } = this.props;

        const item = 
            <BaseDialog
                disableBackdropClick
                disableEscapeKeyDown 
                open={this.state.isOpenDeviceSelect}
                onClose={this.handleCloseDeviceSelect}
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle style={{background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>カメラマイク設定</DialogTitle>
                <DialogContent>
                ビデオ通話を使う場合は、カメラ・マイク・スピーカーを「なし」以外に設定してください。<br/><br/>
                ビデオプレビュー
                    <div>
                    <video id="previewVideo"
                        width={controlVideoWidth} 
                        height={controlVideoHeight} 
                        playsInline
                        autoPlay 
                        muted
                        disablePictureInPicture
                        style={{
                            boxSizing: 'border-box',
                            borderRadius: '50%',
                            border:"solid 1px #000", 
                            boxShadow: '1px 1px 2px 2px rgba(0,0,0,0.2)',
                            objectFit: 'cover',
                        }}
                    />
                    <audio id="previewAudio" />
                    </div>
                    <div>
                    <br/>
                    <FormControl className={classes.formControl}>
                        カメラ選択
                        <Select
                            className={classes.selectMenu}
                            native
                            value={this.state.videoDeviceId}
                            onChange={this.handleChangeVideoSelect}
                            onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                            onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                            variant="outlined"
                        >
                            {/*// #11563 カメラ必須フロアは「未選択」を出さない
                            <option key="" value="">未選択</option>
                            */}
                            {this.renderOptionNoSelect(1)}
                            {this.renderOptionVideoSelect()}
                        </Select>
                    </FormControl>
                    </div>
                    <div>
                    <br/>
                    <FormControl className={classes.formControl}>
                        マイク選択
                        <Select
                            native
                            value={this.state.audioInputDeviceId}
                            onChange={this.handleChangeAudioInputSelect}
                            onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                            onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                            variant="outlined"
                        >
                            {/*// #11563 マイク必須フロアは「未選択」を出さない
                            <option key="" value="">未選択</option>
                            */}
                            {this.renderOptionNoSelect(2)}
                            {this.renderOptionAudioInputSelect()}
                        </Select>
                    </FormControl>
                    </div>
                    {this.checkSetSinkIdMethod() ?
                    <div>
                    <br/>
                    <FormControl className={classes.formControl}>
                        スピーカー選択
                        <Select
                            native
                            value={this.state.audioOutputDeviceId}
                            onChange={this.handleChangeAudioOutputSelect}
                            onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                            onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                            variant="outlined"
                        >
                        {Object.keys(this.audioOutputDeviceInfoList).length > 0 ? 
                            this.renderOptionAudioOutputSelect()
                            : <option key="" value="">デフォルト</option>}
                        </Select>
                    </FormControl>
                    </div>
                    : ""}
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCloseDeviceCheck} 
                        onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                        onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                        color="primary" style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 90}}>
                        OK
                    </Button>
                </DialogActions>
                <DialogContent style={{color: 'rgb(0,0,0,0.54)'}}>
                ※動作環境については<a href={this.props.usermanualURL} target="_blank" rel="noopener noreferrer" style={{color: '#006FBC'}}>「FAMcampusユーザーマニュアル」</a>を参照ください。
                </DialogContent>
            </BaseDialog>

        return item;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={this.state.isShow ? classes.rootDiv : classes.rootDivHidden}>
                {this.state.isOpenDeviceSelect ? this.renderDeviceDialog() : ""}
                <video id="dummyVideo" hidden={true}></video>
            </div>
        )
    }
}

export default withStyles(styles)(withSnackbar(WebrtcComponent));


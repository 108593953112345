import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, Switch, TextField, Theme } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
// import { PointSettingInfo } from '../common/JsonClass';
import BaseDialog from '../user/BaseDialog';
import { userListConst } from './UserListConst';
import JfsClient, { PointSettingInfo } from "@fsi/jfs-sdk";

class ConfirmInfo {
    open: boolean = true;
    message: string = "";
    isOk: boolean = false;

    constructor(init: Partial<ConfirmInfo>) {
        Object.assign(this, init);
    }
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;
    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
    }),
);

interface Props {
    currentRowNo: number;
    handleReloadPointSetting: () => void;
}

export interface PointAutoSettingDialogHandler {
    open: (id: number) => void;
}

// export default function OtherUsers() {
const PointAutoSettingDialogComponent: ForwardRefRenderFunction<PointAutoSettingDialogHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const { history } = useReactRouter();
    const [open, setOpen] = useState(false);
    const [pointSettingIdx, setPointSettingIdx] = useState(0);
    const [pointSettingInfo, setPointSettingInfo] = React.useState({} as PointSettingInfo);

    const [isLoading, setLoading] = useState(false);
    const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
      /** エラー画面用 */
    const [errorOpened, setErrorOpened] = React.useState(false);
    const [errorData, setErrorData] = React.useState({
        code: '',
        message: '',
        errorDetail: [],
    });

    const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());
    
    const ruleInfoList = [
        {id: 0, value: ""},
        {id: 1, value: "回"},
        {id: 2, value: "分"},
        {id: 3, value: "時間"},
        {id: 4, value: "日"},
    ]
    const ruleInfoListCount = [
        {id: 0, value: ""},
        {id: 1, value: "回"},
//        {id: 2, value: "分"},
//        {id: 3, value: "時間"},
//        {id: 4, value: "日"},
    ]
    const limitInfoList = [
        {id: 0, value: ""},
        {id: 1, value: "日"},
        {id: 2, value: "週"},
        {id: 3, value: "月"},
        {id: 4, value: "年"},
//        {id: 5, value: "分"},
//        {id: 6, value: "時間"},
    ]

    const [ state, setState ] = useState({
        pointSettingName: '',
        ruleCount: 0,
        ruleFrequency: 0,
        ruleFrequencyName: '',
        point: 0,
        limitCount: 0,
        limitFrequency: 0,
        limitFrequencyName: '',
        startDate: new Date(),
        endDate: new Date(),
        strStartDate: '',
        strEndDate: '',
        today: new Date(),
        limitDate: new Date(),
        strToday: '',
        strLimitDate: '',
        enable: true,
    });

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // テナント追加の入力データをクリア
    const clearState = () => {
        setPointSettingIdx(0);
        setState({...state, 
            pointSettingName: '',
            ruleCount: 0,
            ruleFrequency: 0,
            ruleFrequencyName: '',
            point: 0,
            limitCount: 0,
            limitFrequency: 0,
            limitFrequencyName: '',
            startDate: new Date(),
            endDate: new Date(),
            strStartDate: '',
            strEndDate: '',
            today: new Date(),
            limitDate: new Date(),
            strToday: '',
            strLimitDate: '',
            enable: true,
        })
    }

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id.toString())
            handleToday();
            setPointSettingIdx(props.currentRowNo);
            setOpen(true);
        },
    }))

    useEffect(() => {
        setPointSettingIdx(props.currentRowNo);
    },[props.currentRowNo])

    useEffect(() => {
        if(pointSettingIdx > 0){
            handleGetPointSetting();
        }
    },[pointSettingIdx])

    useEffect(() => {
        if(props.currentRowNo > 0){
            let startDate = state.startDate === null ? null : new Date(state.startDate);
            let strStartDate = startDate === null ? "" : ConvertDatePickerFormat(startDate);
            // alert("useEffect start --- " + strStartDate);
            setState({...state, strStartDate: strStartDate});
        }
    },[state.startDate])
    
    useEffect(() => {
        if(props.currentRowNo > 0){
            let endDate = state.endDate === null ? null : new Date(state.endDate);
            let strEndDate = endDate === null ? "" : ConvertDatePickerFormat(endDate);
            // alert("useEffect end --- " + strEndDate);
            // if(state.strEndDate !== "") setState({...state, strEndDate: strEndDate});
            let limitDate = ConvertDatePickerFormat(new Date(state.limitDate));
            if((strEndDate === '' || strEndDate >= limitDate) ){
                setState({...state, strEndDate: strEndDate});
            }
        }
    },[state.endDate])
    
    useEffect(() => {
        const endDate = state.startDate === null ? null : new Date(state.startDate);
        let strLimitDate = "";
        if(endDate != null){
            endDate.setMinutes(endDate.getMinutes() + 1);
            strLimitDate = ConvertDatePickerFormat(endDate);

            // alert("handleEndDate endDate:" + endDate + " limitDate:" + strLimitDate);
            setState({...state, limitDate: endDate});
            setState({...state, strLimitDate: strLimitDate});
        }
    },[state.strStartDate])

    useEffect(() => {
        if(props.currentRowNo > 0){
            setState({...state, ruleFrequencyName: ruleInfoList[state.ruleFrequency].value});
            setState({...state, limitFrequencyName: limitInfoList[state.limitFrequency].value});
        }
    },[state.ruleFrequency, state.limitFrequency])

    useEffect(() => {
        if(props.currentRowNo > 0){
            if(confirmInfo.isOk){
                setLoading(true);
                handleUpdatePointSetting();
            }
        }
    }, [confirmInfo.isOk])

    const handleClose = () => {
        clearState();
        setOpen(false);
    };

    const handleToday = () => {
        const today = new Date();
        let strToday = today === null ? "" : ConvertDatePickerFormat(today);
        // alert("handleToday today --- " + strToday);
        setState({...state, today: today});
        setState({...state, strToday: strToday});
    }

    const ConvertDatePickerFormat = (date: Date) => {
        let convertDate = "";
        if (date != null && !isInvalidDate(date)) {
            convertDate = date.getFullYear().toString() +
            "-" +
            ("00" + (date.getMonth()+1).toString()).slice(-2) +
            "-" +
            ("00" + date.getDate().toString()).slice(-2) +
            "T" +
            ("00" + date.getHours().toString()).slice(-2) +
            ":" +
            ("00" + date.getMinutes().toString()).slice(-2);
        }
        return convertDate;
    }
    const ConvertTimestampFormat = (date: Date) => {
        let convertDate = "";
        if (date != null && !isInvalidDate(date)) {
            convertDate =
                date.getFullYear().toString() +
                "/" +
                ("00" + (date.getMonth() + 1).toString()).slice(-2) +
                "/" +
                ("00" + date.getDate().toString()).slice(-2) +
                " " +
                ("00" + date.getHours().toString()).slice(-2) +
                ":" +
                ("00" + date.getMinutes().toString()).slice(-2) +
                ":" +
                ("00" + date.getSeconds().toString()).slice(-2);
        }
        return convertDate;
    };

    const handleGetPointSetting = () => {
        if(props.currentRowNo > 0){
            // alert("handleGetPointSetting idx:" + props.currentRowNo.toString());
            let pointSettingId = props.currentRowNo;
            // axios
            //     .create({
            //         withCredentials: true,
            //     })
            //     .post('/api/v1/admin/pointsetting/info', new URLSearchParams({
            //         tab_id: sessionStorage.getItem("TABID") as string,
            //         point_setting_id: pointSettingId as string,
            //     }))
            const tabId = sessionStorage.getItem("TABID") as string;
            httpClient.getPointSettingInfo(tabId, pointSettingId)
                .then((response: PointSettingInfo) => {
                    let result = response;
                    setPointSettingInfo(result);
                    let startDate = new Date(result.startDate);
                    let endDate = new Date(result.endDate);
                    let strStartDate = result.startDate === null ? "" : ConvertDatePickerFormat(startDate);
                    let strEndDate = result.endDate === null ? "" : ConvertDatePickerFormat(endDate);
                    // alert("strStartDate:" + strStartDate + " - strEndDate:" + strEndDate);
                    setState({
                        ...state,
                        pointSettingName: result.pointSettingName,
                        ruleCount: result.ruleCount,
                        ruleFrequency: result.ruleFrequency,
                        ruleFrequencyName: result.ruleFrequency.toString(),
                        point: result.point,
                        limitCount: result.limitCount,
                        limitFrequency: result.limitFrequency,
                        limitFrequencyName: result.limitFrequency.toString(),
                        startDate: startDate,
                        endDate: endDate,
                        strStartDate: strStartDate,
                        strEndDate: strEndDate,
                        enable: result.enable,
                    });
                })
                .catch((err) => {
                    if (err.response.status === 403) { // 403 = Forbidden
                        // SDKにない systemtop外なので要対応
                        // axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                        httpClient.sendSystemSignout().finally(() => { history.push("/signout"); });
                    } else {
                        console.log("TenantSearch error.");
                        console.log(err.response);
                    }
                });
        }
    }

    const checkPointSetting = () => {
        let errMessage = "";
        let startDate = new Date(state.startDate);
        let endDate = new Date(state.endDate);
        let today = new Date(state.today);
        if(Number(state.ruleCount) <= 0 || state.ruleFrequency === 0 || Number(state.point) === 0 || 
            !Number.isInteger(Number(state.ruleCount)) || !Number.isInteger(Number(state.point)) ){
            errMessage = "ポイント付与ルールが不正です";
        }else if(state.point > 100 || state.point < -100){
            errMessage = "付与ポイントは-100～100で指定してください";
        }else if(state.limitCount <= 0 || state.limitFrequency === 0 || !Number.isInteger(Number(state.limitCount))){
            errMessage = "ポイント入力上限が不正です";
        // }else if(startDate < today && state.startDate !== null){
            // alert("startDate:" + startDate + " - today:" + today);
            // errMessage = "ポイント付与期間－開始日時が不正です";
        }else if(state.strEndDate !== ''){
            if(endDate < today){
                errMessage = "ポイント付与期間－終了日時が現在より過去の日時です";
            }else if(startDate > endDate){
                errMessage = "ポイント付与期間が不正です";
            }
        }
        return errMessage;
    }

    const confirmUpdate = () => {
        // alert("enable:" + state.enable);
        setConfirmInfo(new ConfirmInfo({
            message: "ポイント付与情報「" + state.pointSettingName + "」 を更新します。よろしいですか？"
        }));
    }

    // ポイント自動付与設定チェック処理
    const handleCheckPointSetting = () => {
        // alert("index:" + props.currentRowNo);
        let message = checkPointSetting();
        if( message === ""){
            confirmUpdate();
        }else{
            setFinishInfo(new FinishInfo({message: "[" + message + "]"}));
        }
    }
    
    // ポイント自動付与設定更新処理
    const handleUpdatePointSetting = () => {
        let strStartDate = state.startDate === null ? "" : state.strStartDate === "" ? "" : ConvertTimestampFormat(new Date(state.startDate));
        let strEndDate = state.endDate === null ? "" : state.strEndDate === "" ? "" : ConvertTimestampFormat(new Date(state.endDate));
        const tabId = sessionStorage.getItem("TABID") as string;
        // const params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("point_setting_id", props.currentRowNo.toString());
        // params.append("point_setting_name", state.pointSettingName.toString());
        // params.append("rule_count", state.ruleCount.toString());
        // params.append("rule_frequency", state.ruleFrequency.toString());
        // params.append("point", state.point.toString());
        // params.append("limit_count", state.limitCount.toString());
        // params.append("limit_frequency", state.limitFrequency.toString());
        // params.append("start_date", strStartDate);
        // params.append("end_date", strEndDate);
        // params.append("enable", state.enable ? "true" : "false");

        // axios.post('/api/v1/admin/pointsetting/update', params)
        httpClient.updatePointSettingInfo(
            tabId,
            props.currentRowNo,
            state.pointSettingName.toString(),
            state.ruleCount,
            state.ruleFrequency,
            state.point,
            state.limitCount,
            state.limitFrequency,
            strStartDate,
            strEndDate,
            state.enable
        )
        .then((e: string) => {
            setFinishInfo(new FinishInfo({message: "更新が完了しました"}));
            props.handleReloadPointSetting();
        })
        .catch(err => {
            assignErrorData(err);
        })
        .finally(() => {
            setLoading(false);
        });
        clearState();
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        // alert(name + ":" + target.value);
        setState(() => {
            return {...state, [name]: target.value };
        });
    }

    const handleChangeSelect = React.useCallback((value: any):void => {
        // alert("target " + value.target.name + ":" +value.target.value);
        setState(() => {
            return {...state, [value.target.name]: value.target.value };
        });
    }, [state]);

    const handlePointSwitch= (e: React.ChangeEvent<HTMLInputElement>)=> {
        setState({...state, enable: e.target.checked});
    }

    /**
     * APIエラーハンドル 共通
     * @param err エラーオブジェクト
     */
    const assignErrorData = (err: any) => {
    const errorData = {
        code: '',
        message: userListConst.unexpectedMessage,
        errorDetail: [],
    }

    if (err.response) {
        if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
        } else if (err.response.status >= 500) {
        // errorDataを書き換えない
        } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
        } else {
        Object.assign(errorData, err.response.data);
        }
        setErrorData(errorData);
        setErrorOpened(true);
        return;
    } else if (err.request) {
        Object.assign(errorData, userListConst.requestError);
        setErrorData(errorData);
        setErrorOpened(true);
        return;
    }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={"md"}>
                <DialogTitle id="form-dialog-title">ポイント自動付与情報</DialogTitle>
                <DialogContent>
                    <Grid container style={{marginBottom: "24px"}}>
                        <Grid item xs={7} style={{marginLeft: "20px", fontSize: "14pt"}}>
                            <b>ポイント設定名：　{state.pointSettingName}</b>
                        </Grid>
                        <Grid item style={{marginLeft: "30px", fontSize: "14pt"}}>オン/オフ</Grid>
                        <Grid item xs={1} style={{marginTop: "-6px", marginLeft: "16px"}}>
                            <Switch checked={state.enable} onChange={(e) => handlePointSwitch(e)}></Switch>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2} style={{marginTop: "20px", marginLeft: "40px", marginRight: "50px"}}>ポイント付与ルール</Grid>
                        <Grid item xs={1}>
                            <TextField
                                margin="dense"
                                id="ruleCount"
                                name="ruleCount"
                                label="回数"
                                variant={'outlined'}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.ruleCount}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label" style={{marginLeft: 10}}>頻度</InputLabel>
                                <Select
                                    autoFocus
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="ruleFrequency"
                                    onChange={handleChangeSelect}
                                    label="Age"
                                    input={<OutlinedInput margin='dense' aria-label={"Age"} label={"頻度"} color="primary" />}
                                    style={{left: "10px"}}
                                    value={state.ruleFrequency}
                                >
                                    {props.currentRowNo === 2 ? 
                                        ruleInfoListCount.map((ruleInfo) => (
                                            <MenuItem value={ruleInfo.id}>{ruleInfo.value}</MenuItem>
                                        ))
                                        :
                                        ruleInfoList.map((ruleInfo) => (
                                            <MenuItem value={ruleInfo.id}>{ruleInfo.value}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} style={{marginTop: "20px", marginLeft: "50px", textAlign: "center"}}>ごとに</Grid>
                        <Grid item xs={1}>
                            <TextField
                                margin="dense"
                                id="point"
                                name="point"
                                label="ポイント"
                                variant={'outlined'}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.point}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={1} style={{marginTop: "20px", marginLeft: "20px"}}>ポイント</Grid>
                    </Grid>

                    <br></br>
                    <Grid container>
                        <Grid item xs={2} style={{marginTop: "10px", marginLeft: "40px", marginRight: "50px"}}>ポイント付与上限</Grid>
                        <Grid item xs={1}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label" style={{marginTop: -10}}>上限頻度</InputLabel>
                                <Select
                                    autoFocus
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="limitFrequency"
                                    onChange={handleChangeSelect}
                                    label="Age"
                                    style={{top: "-10px"}}
                                    input={<OutlinedInput margin='dense' aria-label={"Age"} label={"上限頻度"} color="primary" />}
                                    value={state.limitFrequency}
                                >
                                {limitInfoList.map((limitInfo) => (
                                    <MenuItem value={limitInfo.id}>{limitInfo.value}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} style={{marginTop: "10px", marginLeft: "30px", textAlign: "center"}}>に</Grid>
                        <Grid item xs={1}>
                            <TextField
                                margin="dense"
                                id="limitCount"
                                name="limitCount"
                                label="上限回数"
                                variant={'outlined'}
                                fullWidth
                                style={{top: "-10px", left: "-10px"}}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.limitCount}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={1} style={{marginTop: "10px", marginLeft: "10px"}}>回まで</Grid>
                    </Grid>

                    <br></br>
                    <Grid container>
                        <Grid item xs={2} style={{marginTop: "10px", marginLeft: "40px", marginRight: "50px"}}>ポイント付与期間</Grid>
                        <Grid item xs={3}>
                            <TextField
                                margin="dense"
                                id="startDate"
                                name="startDate"
                                label="開始日時"
                                variant={'outlined'}
                                fullWidth
                                style={{top: "-10px"}}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.strStartDate}
                                type="datetime-local"
                                defaultValue={new Date()}
                                inputProps={{
                                    min: state.strToday
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} style={{marginTop: "10px", marginLeft: "-10px", textAlign: "center"}}>～</Grid>
                        <Grid item xs={3}>
                            <TextField
                                margin="dense"
                                id="endDate"
                                name="endDate"
                                label="終了日時"
                                variant={'outlined'}
                                fullWidth
                                style={{top: "-10px", left: "-10px"}}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.strEndDate}
                                type="datetime-local"
                                defaultValue={new Date()}
                                inputProps={{
                                    min: state.strLimitDate
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{marginBottom: "15px", marginRight: "10px"}}>
                    <Button id="submit" onClick={handleCheckPointSetting} color="primary">　登録　</Button>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                </DialogActions>
            </Dialog>
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText>{confirmInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true }) }}>OK</Button>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
                <DialogContent>
                <DialogContentText>{finishInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
                </DialogActions>
            </BaseDialog>
        </div>
    )
}

export const PointAutoSettingDialog = forwardRef(PointAutoSettingDialogComponent);
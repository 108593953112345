import { FC, useState, useCallback, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PanToolIcon from '@material-ui/icons/PanTool';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import MuseumIcon from '@material-ui/icons/Museum';

import WebrtcService from '../../webrtc/WebrtcService';
import { DisplayUserInfo, RAISED_HAND_STATE_NUMBER } from './specifics';
import ListItemIcon from '@material-ui/core/ListItemIcon';

interface Props extends DisplayUserInfo {
    sendZoomSpeakable: (id: number, isZoomSpeakable: boolean) => void;
}

const decideMeetIconAndText = (webRtcRoomId: string): [OverridableComponent<SvgIconTypeMap<{}, 'svg'>>, string] | null => {
    const type = !!webRtcRoomId ? WebrtcService.getWebRtcRoomType(webRtcRoomId) : 0;
    switch (type) {
        case 1:
            return [CompareArrowsIcon, 'ミート'];
        case 2:
            return [MeetingRoomIcon, '会議'];
        case 4:
            return [MuseumIcon, '全体放送'];
        default:
            return null;
    }
}

// Parts
const DiagonalLine: FC<{ color: string, style: React.CSSProperties }> = ({
    color = 'red',
    style = {}
}) => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            ...style,
            background: `linear-gradient(to right top, transparent 45%, ${color} 45%, ${color} 55%, transparent 55%)`,
        }} />
    )
}

const HoverItem: FC<{ isOn: boolean, onClick: () => void }> = ({
    isOn = false,
    onClick
}) => {
    return (
        <Grid
            container
            justify='flex-end'
            alignItems='center'
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                padding: '0 7%',
                background: 'transeparent',
                zIndex: 100,
            }}
        >
            <Button
                variant='contained'
                size='small'
                color='primary'
                onClick={onClick}
            >
                {`ミュート${isOn ? '' : '解除'}`}
            </Button>
        </Grid>
    )
}

const DeviceIcon: FC<{ Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>, isSpeakable: boolean, disabled: boolean }> = ({
    Icon,
    isSpeakable = false,
    disabled = false }
) => {
    return (
        <IconButton
            edge='end'
            size='small'
            disabled={disabled}
            disableRipple
            aria-label='mic'
            style={{ position: 'relative' }}
        >
            <Icon fontSize='small' style={{ color: isSpeakable ? 'grey' : 'silver' }} />
            {!isSpeakable && <DiagonalLine color='red' style={{ position: 'absolute', width: '45%', height: '45%' }} />}
        </IconButton>
    )
}

const MeetIcon: FC<{ roomId: string }> = ({ roomId = '' }) => {
    const data = decideMeetIconAndText(roomId);
    if (data) {
        const [Icon, text] = data;
        return (
            <Tooltip title={`${text}中`}>
                <Icon fontSize='small' color='primary' />
            </Tooltip>
        );
    } else {
        return null;
    }
}

const UserListItem: FC<Props> = ({
    id = 0,
    displayName = '',
    state = 0,
    webRtcRoomId = '',
    isZoomSpeakable = false,
    zoomMeetingId = 0,
    sendZoomSpeakable
}) => {
    const [isEnterItem, setEnterItem] = useState(false);
    const processing = useRef(false);

    const handleBtnClick = useCallback(() => {
        if (processing.current) return;

        processing.current = true;
        setTimeout(() => {
            sendZoomSpeakable(id, !isZoomSpeakable);
            // 処理中フラグを下げる
            processing.current = false;
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isZoomSpeakable]);

    return (
        <ListItem
            style={{ position: 'relative' }}
            onPointerEnter={() => setEnterItem(true)}
            onPointerLeave={() => setEnterItem(false)}
        >
            {isEnterItem && !!!webRtcRoomId && !!zoomMeetingId && <HoverItem isOn={isZoomSpeakable} onClick={handleBtnClick} />}
            <ListItemAvatar>
                <Avatar style={{ background: 'transparent' }}>
                    <Tooltip title={!!zoomMeetingId ? 'Zoom Joined' : 'Zoom not Joined'}>
                        <FiberManualRecordIcon fontSize='small' color={!!zoomMeetingId ? 'primary' : 'disabled'} />
                    </Tooltip>
                </Avatar>
            </ListItemAvatar>
            <Tooltip title={displayName}>
                <ListItemText
                    primaryTypographyProps={{
                        style: {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }
                    }}
                    primary={displayName}
                />
            </Tooltip>
            <ListItemIcon>
                {state === RAISED_HAND_STATE_NUMBER &&
                    <Tooltip title='挙手'>
                        <PanToolIcon fontSize='small' style={{ color: 'orange' }} />
                    </Tooltip>
                }
            </ListItemIcon>
            <ListItemIcon>
                <MeetIcon roomId={webRtcRoomId} />
            </ListItemIcon>
            <ListItemSecondaryAction>
                {!!zoomMeetingId &&
                    <>
                        <DeviceIcon Icon={MicIcon} isSpeakable={isZoomSpeakable} disabled={!!webRtcRoomId || !!!zoomMeetingId} />
                        <DeviceIcon Icon={VideocamIcon} isSpeakable={isZoomSpeakable} disabled={!!webRtcRoomId || !!!zoomMeetingId} />
                    </>
                }
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default UserListItem;
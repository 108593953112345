import React, { forwardRef, Fragment, useCallback, useImperativeHandle, useState, useMemo, useEffect, useRef } from 'react';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Hidden, InputLabel, MenuItem, OutlinedInput, Select, Slide, Typography } from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { Floor } from '../common/JsonClass';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import axios, { AxiosResponse } from 'axios';
import ja from 'date-fns/locale/ja';
import { Utility } from '../common/Utility';
import JfsClient from '@fsi/jfs-sdk';

registerLocale('ja', ja);

export interface ChildHandler {
    setGetAttendanceOpen: (open: boolean) => void
}

interface Props {
    // このIDによってコンテンツの中身が切り替わる。tutorialSteps配列のindexに対応している。
    attendanceId: number,
}

const useStyles = makeStyles((theme) => ({
    content: {
        //    flexShrink: 0,
        marginTop: '-10px',
        height: 200,
        width: Utility.getType() == 2 ? 280 : 420,
        // height: '100%',
        // width: `100%`,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    picker: {
        width: Utility.getType() == 2 ? 160 : 300,
        height: 40,
        fontSize: 24,
        textAlign: 'center',
        margin: 10,
        marginLeft: 0,
    },
    grid: {
        margin: "auto",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
      card: {
        width: 150,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

class ConfirmInfo {
    open: boolean = true;
    message: string = "";
    isOk: boolean = false;

    constructor(init: Partial<ConfirmInfo>) {
        Object.assign(this, init);
    }
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;

    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}

const GetAttendanceDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [showBackdropForDowndating, setShowBackdropForDowndating] = React.useState(false);
    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [floorList, setFloorList] = React.useState([] as Floor[]);
    const [floor, setFloor] = useState("");
    const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
    const [state, setState] = useState({
        selectDate: new Date,
        releaseDate: new Date,
    });
    const FUNCTION_ATTENDANCE_REPORT = "attendanceReport";      // 入退データ出力
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const clearState = () => {
        setState({
            ...state,
            selectDate: new Date(),
            releaseDate: new Date(),
        })
        setStartDate(new Date());
        setFloor("");
        setFloorList([] as Floor[]);
    }

    useImperativeHandle(ref, () => ({
        setGetAttendanceOpen: (open: boolean) => {
            setOpen(open);
        }
    }));

    // CSVファイル名取得
    const getFileName = (contentDisposition: string) => {
        let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
            contentDisposition.length
        );
        //デコードするとスペースが"+"になるのでスペースへ置換します
        fileName = decodeURI(fileName).replace(/\+/g, " ");
        return fileName;
    }

    const onDownload = () => {
        setConfirmInfo(new ConfirmInfo({
            message: "入退データを出力します。よろしいですか？"
        }));
    }

    const getReleaseDate = (releaseName: string) => {
        // リリース日を取得
        httpClient.getReleaseDate(sessionStorage.getItem("TABID") as string, releaseName)
            .then((response: string) => {
                let retDate = new Date(response);
                setState(() => {
                    return { ...state, ["releaseDate"]: retDate };
                });
            })
            .catch((err) => {
                let retDate = new Date(1900, 0, 1, 0, 0, 0);
                setState(() => {
                    return { ...state, ["releaseDate"]: retDate };
                });
            });
    }

    const downloadUsageData = () => {

        let year = state.selectDate.getFullYear();
        let month = state.selectDate.getMonth() + 1;
        let date = 1;
        let paramDate = year + '/' + month + '/' + date;

        let currentdate = new Date();
        currentdate.setMonth(currentdate.getMonth() + 1);
        currentdate.setDate(1);
        currentdate.setHours(0);
        currentdate.setMinutes(0);
        currentdate.setSeconds(0);
        let permitdate = new Date();
        permitdate.setFullYear(permitdate.getFullYear() - 2);
        permitdate.setDate(1);
        permitdate.setHours(0);
        permitdate.setMinutes(0);
        permitdate.setSeconds(0);

        // リリース日、選択日の比較用
        let releaseYear = state.releaseDate.getFullYear();
        let releaseMonth = state.releaseDate.getMonth() + 1;
        let releaseDate = state.releaseDate.getDate();
        let strReleaseDate = releaseYear + '/' + releaseMonth + '/' + releaseDate;
        let selectYear = state.selectDate.getFullYear();
        let selectMonth = state.selectDate.getMonth() + 1;
    
        if (state.releaseDate > state.selectDate 
                && !(releaseYear === selectYear && releaseMonth === selectMonth ) && (releaseDate > 1)) {
            setFinishInfo(new FinishInfo({
                message: "この機能は" + releaseYear + "年" +  releaseMonth + "月" + releaseDate + "日以降のデータに対して有効です。　　　　　　　"
                + releaseYear + "年" +  releaseMonth + "月" + "以降のデータを選択してください。"
            }));

        } else {
            if (permitdate > state.selectDate || currentdate < state.selectDate) {
                // 過去2年以内にリリース日が存在する場合、出力可能な範囲はリリース日から現在までとなる
                if (permitdate < state.releaseDate) {
                    setFinishInfo(new FinishInfo({
                        message: "対象年月は" + releaseYear + "年" +  releaseMonth + "月～現在で指定してください。"
                    }));
                } else {
                    setFinishInfo(new FinishInfo({
                        message: "対象年月は" + permitdate.getFullYear() + "年" +  (permitdate.getMonth()+1) + "月～現在で指定してください。"
                    }));
                }
            } else {
                setShowBackdropForDowndating(true);
                httpClient.outputAttendanceData(
                    sessionStorage.getItem("TABID") as string,
                    paramDate, 
                    // releaseDate
                    strReleaseDate
                ).then((e) => {
                    if(e.data.toString() === ""){
                        setFinishInfo(new FinishInfo({
                            message: "入退データをダウンロードできません。"
                        }));
                    }else{
                        //レスポンスヘッダからファイル名を取得
                        // const contentDisposition = e.headers["content-disposition"];
                        const fileName = e.fileName;
                        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                        const blob = new Blob([bom, e.data], { type: 'text/csv' });
                        const url = window.URL.createObjectURL(blob);

                        //ダウンロード
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();

                        // 選択月がリリース月の場合、リリース日より前のデータは出力されない
                        if ( (releaseYear === selectYear) && (releaseMonth === selectMonth) ) {
                            setFinishInfo(new FinishInfo({
                                message: "入退データのダウンロードが完了しました。　　　　　　　　　　　　　　" 
                                + "この機能は" + releaseYear + "年" +  releaseMonth + "月" + releaseDate + "日以降のデータに対して有効です。　　　　　　　"
                                + "それ以前のデータは出力されていません。"
                            }));
                        }else{
                            setFinishInfo(new FinishInfo({
                                message: "入退データのダウンロードが完了しました。"
                            }));
                        }
                    }
                }).catch((err) => {
                    switch(err.code){
                        case 102005:    // NO DATA
                            setFinishInfo(new FinishInfo({
                                message: "入退履歴はありません。"
                            }));
                            break;
                        default:
                            setFinishInfo(new FinishInfo({
                                message: "入退データのダウンロードに失敗しました。"
                            }));
                    }
                }).finally(() => {
                    setShowBackdropForDowndating(false);
                });
            setOpen(false);
            // }
            }
        }
    }
    const onCancel = () => {
        clearState();
        setOpen(false);
    }

    // UI open時の初期処理
    useEffect(() => {
        handleGetFloorList();
    }, []);

    useEffect(() => {
        clearState();
        if(open){
            handleGetFloorList();
            getReleaseDate(FUNCTION_ATTENDANCE_REPORT)
        }
    }, [open]);

    useEffect(() => {
        if (confirmInfo.isOk) {
            downloadUsageData();
        }
    }, [confirmInfo.isOk]);

    // セレクトボックス用のフロア情報取得
    const handleGetFloorList = () => {
        // axios
        //     .create({
        //         withCredentials: true,
        //     })
        //     .post('/api/v1/admin/floorlist', new URLSearchParams({
        //         tab_id: sessionStorage.getItem("TABID") as string
        //     }))
        httpClient.getAdminFloorList(sessionStorage.getItem("TABID") as string)
            .then((response) => {
                // 全フロア選択用のselect追加
                let allFloors = [] as Floor[];
                let allFloor = new Floor;
                allFloor.id = 0;
                allFloor.floorName = 'すべて';
                allFloors.push(allFloor);

                let result = response as Floor[];
                // alert(result.length);
                if (result.length > 0) {
                    allFloors = allFloors.concat(result);
                    setFloorList(allFloors);
                }
            })
            .catch((err) => {
                console.log(err.response);
                return;
            });
    }

    // 日付変更・セット
    const handleChangeDate = (date: Date) => {
        setState(() => {
            return { ...state, ["selectDate"]: date };
        });
        // alert('date:' + state.selectDate);
    }

    const drawGetAttendance = useMemo(() => {
        return (
            <Box margin="0px 0px 0px 10px" fontSize="18px">
                <Grid container justify="flex-start">
                    <Box style={{ width: "100%" }}>
                        <Grid>対象年月</Grid>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item className={classes.grid} style={{ display: 'inline-block', marginBottom: "15px" }}>
                                <DatePicker
                                    className={classes.picker}
                                    locale="ja"
                                    selected={state.selectDate}
                                    onChange={handleChangeDate}
                                    dateFormat="yyyy/MM"
                                    showMonthYearPicker
                                    onCalendarOpen={() => setCalendarOpen(true)}
                                    onCalendarClose={() => setCalendarOpen(false)}
                                />
                            </Grid>
                        </div>
                    </Box>
                </Grid>
            </Box>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, state.selectDate, calendarOpen]);

    const draw = useMemo(() => {
        return (
            <BaseDialog
                id="getAttendanceDialog"
                open={open}
                onClose={onCancel}
                aria-labelledby='getAttendanceDialogTitle'
                maxWidth={'xs'}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="getAttendanceDialogTitle" style={{padding: '7px 25px 9px 25px' , background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>入退データダウンロード</DialogTitle>
                {Utility.getType() == 2 && <DialogContentText style={{paddingTop: '5px'}}>　　自分の対象年月の入退データ<br/>　　　をダウンロードします。</DialogContentText>}
                {Utility.getType() != 2 && <DialogContentText style={{paddingTop: '5px'}}>　自分の対象年月の入退データをダウンロードします。</DialogContentText>}
                <DialogContent className={classes.content}>
                    {drawGetAttendance}
                </DialogContent>
                {!calendarOpen ? 
                    <DialogActions style={{ marginRight: 20, marginBottom: 20 }}>
                        <Button onClick={onDownload} color="primary" style={{ pointerEvents: 'auto', color: '#FFFFFF',background: '#006FBC', borderRadius: '31px', width: 110 }} >ダウンロード</Button>
                        <Button onClick={onCancel} color="primary" style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }} >キャンセル</Button>
                    </DialogActions> :
                    <DialogActions style={{ marginRight: 20, marginBottom: 20, visibility: 'hidden' }}>
                        <Button style={{color: '#FFFFFF',background: '#006FBC', borderRadius: '31px', width: 110}}>ダウンロード</Button>
                        <Button style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
                    </DialogActions>
                }
            </BaseDialog>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, drawGetAttendance, confirmInfo.isOk, calendarOpen]);

    return (
        <Fragment>
            {draw}
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} 
                onClose={() => { setConfirmInfo({ ...confirmInfo, open: false }) }}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogContent>
                    <DialogContentText>{confirmInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true }) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
            <Backdrop className={classes.backdrop} open={showBackdropForDowndating} >
                <Slide direction="up" in={showBackdropForDowndating}>
                <Card className={classes.card}>
                    <CardContent>
                    <ThemeProvider theme={circularTheme}>
                        <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
                    </ThemeProvider>
                    <Typography variant="subtitle2" >データ取得中</Typography>
                    </CardContent>
                </Card>
                </Slide>
            </Backdrop>
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} 
                onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogContent>
                    <DialogContentText>{finishInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }} style={{color: '#006FBC'}}>閉じる</Button>
                </DialogActions>
            </BaseDialog>
        </Fragment>
    );
}

export const GetAttendanceDialogRef = forwardRef(GetAttendanceDialog);
import { CSSProperties } from "react";

/**
 * CSSスタイルを踏まえたテキストの画面表示時のpixel幅を取得する。
 *  
 * @param text  画面に表示するテキスト
 * @param style テキスト表示時のCSSスタイル
 */
export function textRuler(text: string, style: CSSProperties): number {
    let pixel: number;

    // pixel計測用div作成
    const div = document.createElement("div");

    // 計測対象のstyleとtextを計測用divへマージ
    Object.assign(
        div.style,
        {
            position: "fixed",
            bottom: 0,
            visibility: "hidden",// display: "none" では計測できない。
            margin: 0,
            padding: 0
        },
        style
    );
    div.innerText = text;

    // 計測
    const parent = document.getElementById("floorWrapper");
    if (parent) {
        // parent上に非表示でpixel計測用divを配置
        parent.appendChild(div)
        // 計測値取得
        pixel = div.offsetWidth;
        // pixel計測用divを消去
        parent.removeChild(div);

        return pixel;
    } else {
        return 0;
    }
}
import React, { Fragment } from 'react';
import useReactRouter from 'use-react-router';
import classNames from 'classnames';
import { CssBaseline, AppBar, Toolbar, IconButton, makeStyles, Typography, Menu, MenuItem, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import SyncAltRoundedIcon from '@material-ui/icons/SyncAltRounded';
import LayersIcon from '@material-ui/icons/Layers';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FunctionsIcon from '@material-ui/icons/Functions';

const drawerWidth = 240;

const useStyles = makeStyles(Theme => ({
    appBar: {
        zIndex: Theme.zIndex.drawer + 1,
        transition: Theme.transitions.create(['width', 'margin'], {
            easing: Theme.transitions.easing.sharp,
            duration: Theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: Theme.transitions.create(['width', 'margin'], {
            easing: Theme.transitions.easing.sharp,
            duration: Theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: Theme.transitions.create('width', {
            easing: Theme.transitions.easing.sharp,
            duration: Theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: Theme.transitions.create('width', {
            easing: Theme.transitions.easing.sharp,
            duration: Theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: Theme.spacing(1) * 7 + 1,
        [Theme.breakpoints.up('sm')]: {
            width: Theme.spacing(1) * 9 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...Theme.mixins.toolbar,
    },
    grow: {
        flexGrow: 1,
    },
}));

export default function SystemFrame() {
    const classes = useStyles();
    const { history } = useReactRouter();
    const [t] = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(true);
    const openMenu = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleAccount = () => {
        history.push("/profile");
    }

    const handleLogout = () => {
        axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
    }

    const handleClickDashboard = () => {
        history.push("/systemtop");
    }

    const handleClickSyncServerInfo = () => {
        history.push("/syncserverroominfo")
    }

    const handleClickTenant = () => {
        history.push("systemtenant");
    }

    const handleClickEditFloorList = () => {
        history.push("systemEditFloorList");
    }

    const handleClickFloor = () => {
        history.push("systemfloor");
    }

    const handleClickTest = () => {
        history.push("systemdeployfunction");
    }

    const handleClickServerLog = () => {

    }

    const handleClickYouTubeVideo = () => {
        history.push("systemyoutube")
    }

    return (
        <Fragment>
            <CssBaseline />
            <AppBar
                color="primary"
                position="fixed"
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar disableGutters={!open}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={classNames(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" color="inherit" className={classes.grow} noWrap>
                        {t("システムコンソール")}
                    </Typography>

                    <div>
                        <IconButton
                            aria-owns={open ? 'menu-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openMenu}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLogout}>{"ログアウト"}</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={classNames(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: classNames({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {open === false ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button onClick={handleClickDashboard}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary={t("ダッシュボード")} />
                    </ListItem>
                    <ListItem button onClick={handleClickTenant}>
                        <ListItemIcon><ApartmentIcon /></ListItemIcon>
                        <ListItemText primary={t("テナント")} />
                    </ListItem>
                    <ListItem button onClick={handleClickEditFloorList}>
                        <ListItemIcon><LayersOutlinedIcon /></ListItemIcon>
                        <ListItemText primary={t("フロア")} />
                    </ListItem>
                    <ListItem button onClick={handleClickFloor}>
                        <ListItemIcon><LayersIcon /></ListItemIcon>
                        <ListItemText primary={t("稼働フロア")} />
                    </ListItem>
                    <ListItem button onClick={handleClickSyncServerInfo}>
                        <ListItemIcon><SyncAltRoundedIcon /></ListItemIcon>
                        <ListItemText primary={t("同期サーバ")} />
                    </ListItem>
                    <ListItem button onClick={handleClickServerLog}>
                        <ListItemIcon><DescriptionIcon /></ListItemIcon>
                        <ListItemText primary={t("システムログ")} />
                    </ListItem>
                    <ListItem button onClick={handleClickYouTubeVideo}>
                        <ListItemIcon><YouTubeIcon /></ListItemIcon>
                        <ListItemText primary={t("YouTubeビデオ")} />
                    </ListItem>
                    <ListItem button onClick={handleClickTest}>
                        <ListItemIcon><FunctionsIcon /></ListItemIcon>
                        <ListItemText primary={"デプロイの補足機能"} />
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
        </Fragment>
    );
}
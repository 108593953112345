import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

interface Props {
    //open: boolean,
}

// 公開したいメソッドの定義
export interface FloorClosedDialogHandler {
    setDialogOpen: (open: boolean) => void
}

const FloorClosedDialog: React.ForwardRefRenderFunction<FloorClosedDialogHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    //const cookies = new Cookies();

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        setDialogOpen: (open: boolean) => {
            setOpen(open);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const draw = useMemo(() =>
        <BaseDialog
            id="floorClosedDialogTitle"
            open={open}
            onClose={handleClose}
            aria-labelledby="floorClosedDialogTitle"
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{
                style:{
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id='editTtextDialogTitle' style={{ padding: "5px 25px 5px 25px", background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }} >お知らせ</DialogTitle>
            <DialogContent style={{ padding: "10px 25px 0px 25px" }}>
                <DialogContentText style={{ marginBottom: "0px" }} >
                    ただいまの時間は、選択されたフロアには入れません。<br/>
                    立ち入り可能な時間帯は、管理者にお問い合わせください。
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }}>OK</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [open]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const FloorClosedDialogRef = forwardRef(FloorClosedDialog);

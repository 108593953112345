import { Box, Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Theme, Typography } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { TenantInfo, AdditionalContract } from '../common/JsonClass';

import RefreshIcon from '@material-ui/icons/Refresh';
import { SystemContractUserSize, SystemContractUserSizeHandler } from './SystemContractUserSize';
import { SystemContractByteSize, SystemContractByteSizeHandler } from './SystemContractByteSize';

const useStyles = makeStyles((theme: Theme) =>  
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
    }),
);

interface Props {
    masterData: { [index: string]: [{ id: number, name: string }] };
}

export interface SystemTenantInfoHandler {
    open: (id: number) => void;
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    email: string = "";
    password: null | string = null;

    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}

// export default function OtherUsers() {
const SystemTenantInfoComponent: ForwardRefRenderFunction<SystemTenantInfoHandler, Props> = (props, ref) => {
    const [dispType, setDispType] = useState("追加");
    const [dispDelBtn, setDispDelBtn] = useState("none");
    const [dispUpdate, setDispUpdate] = useState("none");
    const [disableSize, setDisableSize] = useState(false);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectTenantId, setSelectTenantId] = useState(0);
    const [state, setState] = useState({
        tenantName: '',
        businessStartTime: '05:00',
        webRtcBasicLimitSize: 1,
        webRtcLimitSize: 1,
        webRtcLimitType: 0,
        webrtcApiKey: '',
        adminEmail: '',
        isMorenoteDifferentPassword: false,
        moreNoteMasterId: 0,
        moreNoteHelloMasterId: 0,
        moreNoteUrl: '',
        moreNoteHelloUrl: '',
        dataLimitPerPerson: 2,
        maxUserNumber: 32,
        usedWebRtcSize: 0,
        enabledWhiteList: false,
        enabledTenant: true,
        maintenance: false,
        enabledExternalUrl: false,
        externalUrl: '',
        enabledPrivacyRoom: false,
        privacyRoomNumber: 5,
    });
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
    const refSystemContractUserSize = useRef({} as SystemContractUserSizeHandler);
    const refSystemContractByteSize = useRef({} as SystemContractByteSizeHandler);
    const [additionalContract, setAdditionalContract] = React.useState([] as AdditionalContract[]);

    // テナント追加の入力データをクリア
    const clearState = () => {
        setState({
            ...state,
            tenantName: "",
            businessStartTime: '05:00',
            webRtcBasicLimitSize: 1,
            webRtcLimitSize: 1,
            webRtcLimitType: 0,
            webrtcApiKey: '',
            adminEmail: '',
            isMorenoteDifferentPassword: false,
            moreNoteMasterId: 0,
            moreNoteHelloMasterId: 0,
            moreNoteUrl: '',
            moreNoteHelloUrl: '',
            dataLimitPerPerson: 2,
            maxUserNumber: 32,
            usedWebRtcSize: 0,
            enabledWhiteList: false,
            enabledTenant: true,
            maintenance: false,
            enabledExternalUrl: false,
            externalUrl: '',
            enabledPrivacyRoom: false,
            privacyRoomNumber: 5,
            })
    }

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id.toString())
            let moreNoteMasterId = state.moreNoteMasterId;
            let moreNoteHelloMasterId = state.moreNoteHelloMasterId;
            if (moreNoteMasterId === 0 && 'morenote' in props.masterData && props.masterData['morenote'].length > 0) {
                moreNoteMasterId = props.masterData['morenote'][0].id;
            }
            if (moreNoteHelloMasterId === 0 && 'morenoteHello' in props.masterData && props.masterData['morenoteHello'].length > 0) {
                moreNoteHelloMasterId = props.masterData['morenoteHello'][0].id;
            }
            setState({ ...state, moreNoteMasterId: moreNoteMasterId, moreNoteHelloMasterId: moreNoteHelloMasterId });

            setSelectTenantId(id);
            setOpen(true);
        },
    }))

    useEffect(() => {
        // サーバーからデータを取得して表示する
        if (selectTenantId > 0) {
            //alert("selId:" + selectTenantId);
            setDispType("更新");

            // WebRtc通信量は編集可
            setDisableSize(false);
            
            // 削除処理は現状非表示
            setDispDelBtn("none");
            //setDispDelBtn("");

            // 更新処理用ボタン表示
            setDispUpdate("");

            handleGetTenantInfoById();
        } else {
            setDispType("追加");

            // WebRtc通信量編集可
            setDisableSize(false);

            // 削除処理は非表示
            setDispDelBtn("none");

            // 更新処理用ボタン非表示
            setDispUpdate("none");
            clearState();
        }
    }, [selectTenantId])

        // サーバーからデータを取得して表示する
    const handleGetTenantInfoById = () => {
        clearState();
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/tenant/info', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                tenantId: selectTenantId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as TenantInfo;
                let summary = 0 as number;
                axios
                .create({
                    withCredentials: true,
                })
                .post('/api/system/addcontract/summary', new URLSearchParams({
                    tab_id: sessionStorage.getItem("TABID") as string,
                    tenantId: selectTenantId.toString() as string,
                    contractYearMonth: "" as string,
                }))
                .then((response: AxiosResponse) => {
                    let retSummary = response.data as AdditionalContract[];
                    if(retSummary[0].tenantName != ""){
                        // 既存契約がある場合
                        setAdditionalContract(retSummary);
                        summary = retSummary[0].webRtcAdditionalBytes;
                    }
                    setState({
                        ...state,
                        tenantName: result.tenantName,
                        businessStartTime: result.businessStartTime,
                        webRtcBasicLimitSize: Number(result.webRtcLimitSize) - summary,
                        webRtcLimitSize: Number(result.webRtcLimitSize),
                        webRtcLimitType: Number(result.webRtcLimitType),
                        webrtcApiKey: result.webrtcApiKey,
                        adminEmail: '',
                        isMorenoteDifferentPassword: result.isMorenoteDifferentPassword,
                        moreNoteMasterId: 0,
                        moreNoteHelloMasterId: 0,
                        moreNoteUrl: result.moreNoteUrl + '/' + result.tenantId,
                        moreNoteHelloUrl: result.moreNoteHelloUrl,
                        dataLimitPerPerson: result.dataLimitPerPerson,
                        maxUserNumber: result.maxUserNumber,
                        usedWebRtcSize: result.usedWebRtcSize,
                        enabledWhiteList: result.enabledWhiteList,
                        enabledTenant: result.enabledTenant,
                        maintenance: result.maintenance,
                        enabledExternalUrl: result.enabledExternalUrl,
                        externalUrl: result.externalUrl === null ? '' : result.externalUrl,
                        enabledPrivacyRoom: result.enabledPrivacyRoom,
                        privacyRoomNumber: result.privacyRoomNumber,
                    });
                    })
                .catch(() => {
                    console.log("login error.");
                });
            })
            .catch(() => {
                console.log("login error.");
            });
    }

    const handleClose = () => {
        setSelectTenantId(0);
        clearState();
        setOpen(false);
    };

    const handleOpenContractByteSize = (event: React.MouseEvent<unknown>, id: number) => {
        //alert("selectTenantId:" + id);
        clearState();
        setSelectTenantId(0);
        setOpen(false);
        refSystemContractByteSize.current.open(id);
    }

    const handleOpenContractUserSize = (event: React.MouseEvent<unknown>, id: number) => {
        //alert("selectTenantId:" + id);
        clearState();
        setSelectTenantId(0);
        setOpen(false);
        refSystemContractUserSize.current.open(id);
    }

    const handleAddTenant = () => {
        if (state.tenantName === "") {
            alert("テナント情報：テナント名が空です。");
        } else {
            if (dispType === "追加") {
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/tenant/add', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        tenantName: state.tenantName.trimEnd(),
                        businessStartTime: state.businessStartTime.toString(),
                        webRtcLimitSize: state.webRtcBasicLimitSize.toString().trimEnd(),
                        webRtcLimitType: state.webRtcLimitType.toString(),
                        webrtcApiKey: state.webrtcApiKey.toString().trimEnd(),
                        adminEmail: state.adminEmail.trim(),
                        isMorenoteDifferentPassword: state.isMorenoteDifferentPassword.toString(),
                        moreNoteMasterId: state.moreNoteMasterId.toString(),
                        moreNoteHelloMasterId: state.moreNoteHelloMasterId.toString(),
                        dataLimitPerPerson: state.dataLimitPerPerson.toString(),
                        maxUserNumber: state.maxUserNumber.toString(),
                        enabledWhiteList: state.enabledWhiteList.toString(),
                        enabledTenant: state.enabledTenant.toString(),
                        maintenance: state.maintenance.toString(),
                        enabledExternalUrl: state.enabledExternalUrl.toString(),
                        externalUrl: state.externalUrl.trim(),
                        enabledPrivacyRoom: state.enabledPrivacyRoom.toString(),
                        privacyRoomNumber: state.privacyRoomNumber.toString(),
                    }))
                    .then((response: AxiosResponse) => {
                        let msg = response.data as string;
                        // alert("result:" + msg);
                        if (msg.startsWith("ERR") || msg === "NG") {
                            if (msg === "ERR01") {
                                setFinishInfo(new FinishInfo({
                                    message: "テナント " + state.tenantName + " は既に登録されています。"
                                }));
                            } else if (msg === "ERR02") {
                                setFinishInfo(new FinishInfo({
                                    message: "申請者 " + state.adminEmail + " は既に登録されています。別のメールアドレスを指定してください。"
                                }));
                            } else if (msg === "ERR03") {
                                setFinishInfo(new FinishInfo({
                                    message: "申請者のメールアドレスは、4 ～ 60文字で指定してください。"
                                }));
                            } else if (msg === "ERR04") {
                                setFinishInfo(new FinishInfo({
                                    message: "申請者のメールアドレスの書式が間違っています。メールアドレスを入力してください。"
                                }));
                            } else {
                                setFinishInfo(new FinishInfo({
                                    message: "テナント " + state.tenantName + " の登録に失敗しました。"
                                }));
                            }
                        } else {
                            // alert("テナント " + state.tenantName + " の" + dispType + "が完了しました。");
                            setFinishInfo(new FinishInfo({
                                message: "テナント " + state.tenantName + " の登録が完了しました。", password: msg, email: state.adminEmail
                            }));
                        }
                    })
                    .catch(() => {
                        // alert("テナント " + state.tenantName + " の" + dispType + "に失敗しました。");
                        setFinishInfo(new FinishInfo({
                            message: "テナント " + state.tenantName + " の登録に失敗しました。"
                        }));
                    });
            } else if (dispType === "更新") {
                let newLimitSize = Number(state.webRtcBasicLimitSize) + Number(additionalContract[0].webRtcAdditionalBytes);
                // alert("newLimitSize:" + newLimitSize);
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/tenant/update', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        id: selectTenantId.toString().trimEnd(),
                        tenantName: state.tenantName.trimEnd(),
                        businessStartTime: state.businessStartTime.toString(),
                        webRtcLimitSize: newLimitSize.toString().trimEnd(),
                        webRtcLimitType: state.webRtcLimitType.toString(),
                        webrtcApiKey: state.webrtcApiKey.toString().trimEnd(),
                        isMorenoteDifferentPassword: state.isMorenoteDifferentPassword.toString(),
                        moreNoteMasterId: state.moreNoteMasterId.toString(),
                        moreNoteHelloMasterId: state.moreNoteHelloMasterId.toString(),
                        dataLimitPerPerson: state.dataLimitPerPerson.toString(),
                        maxUserNumber: state.maxUserNumber.toString(),
                        enabledWhiteList: state.enabledWhiteList.toString(),
                        enabledTenant: state.enabledTenant.toString(),
                        maintenance: state.maintenance.toString(),
                        enabledExternalUrl: state.enabledExternalUrl.toString(),
                        externalUrl: state.externalUrl.trim(),
                        enabledPrivacyRoom: state.enabledPrivacyRoom.toString(),
                        privacyRoomNumber: state.privacyRoomNumber.toString(),
                    }))
                    .then((response: AxiosResponse) => {
                        let msg = response.data as String;
                        // alert("result:" + msg);
                        if (msg === "OK") {
                            // alert("テナント " + state.tenantName + " の" + dispType + "が完了しました。");
                            setFinishInfo(new FinishInfo({ message: "テナント " + state.tenantName + " の" + dispType + "が完了しました。" }));
                        }else if (msg.startsWith("ERR") || msg === "NG") {
                            if (msg === "ERR05") {
                                setFinishInfo(new FinishInfo({
                                    message: "契約内容更新期日（毎月27日0時まで）を過ぎているため、Skyway通信量制限は変更できません。"
                                }));
                            } else if (msg === "ERR06") {
                                setFinishInfo(new FinishInfo({
                                    message: "契約内容更新期日（毎月27日0時まで）を過ぎているため、契約ユーザ数は変更できません。"
                                }));
                            }
                        } else {
                            // alert("テナント " + state.tenantName + " の登録に失敗しました。");
                            setFinishInfo(new FinishInfo({ message: "テナント " + state.tenantName + " の" + dispType + "に失敗しました。" }));
                        }
                    })
                    .catch(() => {
                        // alert("テナント " + state.tenantName + " の" + dispType + "に失敗しました。");
                        setFinishInfo(new FinishInfo({ message: "テナント " + state.tenantName + " の" + dispType + "に失敗しました。" }));
                    });
            }
            clearState();
            setSelectTenantId(0);
            setOpen(false);
        }
    };

    const handleDeleteTenant = () => {
        if (window.confirm("テナント " + state.tenantName + " を削除します。よろしいですか？")) {
            axios
                .create({
                    withCredentials: true,
                })
                .post('/api/system/tenant/delete', new URLSearchParams({
                    tab_id: sessionStorage.getItem("TABID") as string,
                    id: selectTenantId.toString().trimEnd(),
                }))
                .then((response: AxiosResponse) => {
                    // alert("result:" + msg);
                    alert("テナント " + state.tenantName + " の削除が完了しました。");
                    setSelectTenantId(0);
                    setOpen(false);
                })
                .catch(() => {
                    alert("テナント " + state.tenantName + " の削除に失敗しました。");
                    setSelectTenantId(0);
                    setOpen(false);
                });
        } else {
            alert("削除をキャンセルしました。");
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        //alert(name + ":" + target.value);

        setState(() => {
            return { ...state, [name]: target.value };
        });
    }
    const handleChangeSelect = React.useCallback((value: any): void => {
        // alert("select " + value.target.name + ":" +value.target.value);
        setState(() => {
            return { ...state, [value.target.name]: value.target.value };
        });
    }, [state]);

    const handleCheck = (value: any): void => {
        // alert("check " + value.target.name + ":" +value.target.checked);
        setState(() => {
            return { ...state, [value.target.name]: value.target.checked };
        });
    };

    const moreNoteController = React.useMemo(() => {
        if (selectTenantId === 0) {
            if ('morenote' in props.masterData) {
                return (
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel id="select-morenote-server">moreNOTE 接続先サーバ</InputLabel>
                        <Select
                            id="select-morenote-server"
                            input={<OutlinedInput margin='dense' aria-label={"morenoteServer"} label={"moreNOTE 接続先サーバ"} color="primary" />}
                            name="moreNoteMasterId"
                            value={state.moreNoteMasterId}
                            onChange={handleChangeSelect}
                            required
                        >
                            {props.masterData['morenote'].map((e: { id: number, name: string }, index: number) => (
                                <MenuItem key={'morenote-master-' + e.id} value={e.id}>{e.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            } else {
                return (
                    <TextField
                        id="moreNoteUrl"
                        margin="dense"
                        label="moreNOTE 接続先サーバ"
                        variant={'outlined'}
                        fullWidth
                        value={"マスターデータ未設定"}
                        InputProps={{ readOnly: true }}
                        error
                    />
                );
            }
        } else {
            return (
                <TextField
                    id="moreNoteUrl"
                    margin="dense"
                    label="moreNOTE 接続先サーバ (編集不可)"
                    variant={'outlined'}
                    fullWidth
                    value={state.moreNoteUrl}
                    InputProps={{ readOnly: true }}
                />
            );
        }
    }, [handleChangeSelect, props.masterData, classes.formControl, selectTenantId, state.moreNoteMasterId, state.moreNoteUrl]);

    const moreNoteHelloController = React.useMemo(() => {
        if (selectTenantId === 0) {
            if ('morenoteHello' in props.masterData) {
                return (
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel id="select-morenote-hello-server">moreNOTE Hello! 接続先サーバ</InputLabel>
                        <Select
                            id="select-morenote-server"
                            input={<OutlinedInput margin='dense' aria-label={"morenoteHelloServer"} label={"moreNOTE Hello! 接続先サーバ"} color="primary" />}
                            name="moreNoteHelloMasterId"
                            value={state.moreNoteHelloMasterId}
                            onChange={handleChangeSelect}
                            hidden={selectTenantId !== 0}
                            required
                        >
                            {props.masterData['morenoteHello'].map((e: { id: number, name: string }, index: number) => (
                                <MenuItem key={'morenote-hello-master-' + e.id} value={e.id}>{e.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            } else {
                return (
                    <TextField
                        id="moreNoteHelloUrl"
                        margin="dense"
                        label="moreNOTE Hello! 接続先サーバ"
                        variant={'outlined'}
                        fullWidth
                        value={"マスターデータ未設定"}
                        InputProps={{ readOnly: true }}
                        error
                    />
                );
            }
        } else {
            return (
                <TextField
                    id="moreNoteHelloUrl"
                    margin="dense"
                    label="moreNOTE Hello! 接続先サーバ (編集不可)"
                    variant={'outlined'}
                    fullWidth
                    value={state.moreNoteHelloUrl}
                    InputProps={{ readOnly: true }}
                />
            );
        }
    }, [handleChangeSelect, props.masterData, classes.formControl, selectTenantId, state.moreNoteHelloMasterId, state.moreNoteHelloUrl]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>テナント情報</DialogTitle>
                <DialogContent style={{ padding: "20px 20px" }}>
                    <Box fontSize="18px"><b>テナント情報</b></Box>
                    <Box m={1}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="tenant-name"
                            name="tenantName"
                            label="テナント名"
                            variant={'outlined'}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.tenantName}
                            required
                        />
                        <Grid container>
                            <Grid item xs={8}>
                                <TextField
                                    margin="dense"
                                    id="business-start-time"
                                    name="businessStartTime"
                                    label="1日の開始時間"
                                    variant={'outlined'}
                                    type={"time"}
                                    //defaultValue={"05:00"}
                                    fullWidth
                                    style={{ top: "10px", width: "200px" }}
                                    onChange={handleChange}
                                    value={state.businessStartTime}
                                    required
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    margin="dense"
                                    id="webrtc-limit-size"
                                    name="webRtcBasicLimitSize"
                                    label="Skyway通信量制限の基本契約(GB/月)"
                                    variant={'outlined'}
                                    fullWidth
                                    style={{ top: "10px" }}
                                    type={"number"}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleChange}
                                    value={state.webRtcBasicLimitSize}
                                    disabled={disableSize}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box id="btnAdd" display={dispUpdate}>
                                    <Button variant="contained" style={{position: "relative", top: 20, left: 20}} color="primary" onClick={(event) => handleOpenContractByteSize(event, selectTenantId)} startIcon={<RefreshIcon />}>　　追加契約　　</Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={10}>
                            <Typography variant="body2" style={{position: "relative", top: 15, fontSize: "16px"}}>　今月の通信量合計（追加契約分含む）：{state.webRtcLimitSize}GB</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                            <Typography variant="body2" style={{position: "relative", top: 17, fontSize: "16px"}}>　今月の残り通信量：{(state.webRtcLimitSize - (state.usedWebRtcSize / 1024 / 1024 / 1024)).toFixed(4)}GB</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{position: "relative", top: 17, fontSize: "16px"}}>　今月の使用量：{(state.usedWebRtcSize / 1024 / 1024 / 1024).toFixed(4)}GB</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box m={1}>
                        <FormControl variant="outlined" className={classes.formControl} style={{top:15}}>
                            <InputLabel id="demo-simple-select-outlined-label">WebRTCの通信量上限種別</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="webRtcLimitType"
                                onChange={handleChangeSelect}
                                label="Age"
                                input={<OutlinedInput margin='dense' aria-label={"Age"} label={"WebRTCの通信量上限種別"} color="primary" />}
                                style={{ width: 530 }}
                                value={state.webRtcLimitType}
                                required
                            >
                                <MenuItem value={0}>無制限</MenuItem>
                                <MenuItem value={1}>テナント上限(ビデオ通話制限)</MenuItem>
                                <MenuItem value={2}>ユーザー上限　※使用不可（未検証）</MenuItem>
                                <MenuItem value={3}>テナントの上限(P2P除く)　※使用不可（未検証）</MenuItem>
                                <MenuItem value={4}>ユーザーの上限(P2P除く)　※使用不可（未検証）</MenuItem>
                                <MenuItem value={5}>テナント上限(ビデオ通話可能／カメラ使用制限あり)</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="skyway-key"
                            name="webrtcApiKey"
                            label="Skyway接続用のAPIキー"
                            variant={'outlined'}
                            fullWidth
                            style={{ top: "10px"}}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.webrtcApiKey}
                        />
                        <Grid container>
                            <Grid item xs={5}>
                                <TextField
                                    margin="dense"
                                    id="dataLimitPerPerson"
                                    name="dataLimitPerPerson"
                                    label="1人あたりの通信量(GB)"
                                    variant={'outlined'}
                                    fullWidth
                                    style={{ top: "10px" }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleChange}
                                    value={state.dataLimitPerPerson}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="maxUserNumber"
                                    name="maxUserNumber"
                                    label="契約ユーザー数"
                                    variant={'outlined'}
                                    fullWidth
                                    style={{ top:"10px", left:"40px" }}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleChange}
                                    value={state.maxUserNumber}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box id="btnAdd" display={dispUpdate}>
                                    <Button variant="contained" style={{position: "relative", top: 20, left: 20}} color="primary" onClick={(event) => handleOpenContractUserSize(event, selectTenantId)} startIcon={<RefreshIcon />}>翌月ユーザ数更新</Button>
                                </Box>
                            </Grid> 
                        </Grid>
                        <br />
                        {/* <Box margin="15 0" textAlign="right" fontSize="15px">【注意】翌月の契約ユーザ数の更新期日は、毎月27日0時までです。</Box> */}
                        {(selectTenantId === 0)
                            ? <TextField
                                margin="dense"
                                id="admin-email"
                                name="adminEmail"
                                label="管理者 (申込者) メールアドレス (重複不可)"
                                variant={'outlined'}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                                value={state.adminEmail}
                                required
                            />
                            : ''}
                    </Box>
                    <Box m={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isMorenoteDifferentPassword}
                                    onChange={handleCheck}
                                    name="isMorenoteDifferentPassword"
                                    color="primary"
                                    value={state.isMorenoteDifferentPassword}
                                />
                            }
                            label="moreNOTEのパスワードとFAMのパスワードが違う"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.enabledWhiteList}
                                    onChange={handleCheck}
                                    name="enabledWhiteList"
                                    color="primary"
                                    value={state.enabledWhiteList}
                                />
                            }
                            label="ホワイトリスト機能"
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.enabledTenant}
                                    onChange={handleCheck}
                                    name="enabledTenant"
                                    color="primary"
                                    value={state.enabledTenant}
                                />
                            }
                            label="有効テナント"
                        />　　　
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.maintenance}
                                    onChange={handleCheck}
                                    name="maintenance"
                                    color="primary"
                                    value={state.maintenance}
                                />
                            }
                            label="テナントメンテナンス中"
                        />
                    </Box>
                    <hr></hr>
                    <Box margin="20px 0px 0px 0px" fontSize="18px"><b>外部システム連携情報</b></Box>
                    <Box m={1}>
                        {moreNoteController}
                        {moreNoteHelloController}
                        <Box margin="5px 0px" textAlign="right" fontSize="15px">＊：必須項目　</Box>
                    </Box>
                    <hr></hr>
                    <Box margin="20px 0px 0px 0px" fontSize="18px"><b>外部教材URLボタン</b></Box>
                    <Box m={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.enabledExternalUrl}
                                    onChange={handleCheck}
                                    name="enabledExternalUrl"
                                    color="primary"
                                    value={state.enabledExternalUrl}
                                />
                            }
                            label="表示"
                        />
                    </Box>
                    <Box m={1}>
                        <TextField
                            disabled={!state.enabledExternalUrl}
                            margin="dense"
                            id="externalUrl"
                            name="externalUrl"
                            label="外部教材URL"
                            variant={'outlined'}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.externalUrl}
                        />
                    </Box>
                    <hr></hr>
                    <Box margin="20px 0px 0px 0px" fontSize="18px"><b>プライバシールーム設定</b></Box>
                    <Box margin="15px 0px 10px 0px">
                    <Box mx={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.enabledPrivacyRoom}
                                    onChange={handleCheck}
                                    name="enabledPrivacyRoom"
                                    color="primary"
                                    value={state.enabledPrivacyRoom}
                                />
                            }
                            label="プライバシールーム有効"
                        />
                        <TextField
                            margin="dense"
                            id="privacyRoomNumber"
                            name="privacyRoomNumber"
                            label="面接室数"
                            variant={'outlined'}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={state.enabledPrivacyRoom === true && (state.privacyRoomNumber)}
                            type="number"
                            style={{ top:"-10px", left:"20px"}}
                            inputProps={{ min: 1 }}
                            disabled={!state.enabledPrivacyRoom}
                        />
                    </Box>
                    </Box>
                </DialogContent>
                <DialogActions >
                    <Box id="btnDel" display={dispDelBtn}>
                        <Button id="delete" onClick={handleDeleteTenant} color="primary">テナント削除</Button>
                    </Box>
                    <Box margin="-10px 0px 10px 0px">
                        <Button onClick={handleClose} color="primary"><Box fontSize="17px">キャンセル</Box></Button>　
                        <Button id="submit" onClick={handleAddTenant} color="primary" ><Box fontSize="17px">テナント{dispType}</Box></Button>　
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText>{finishInfo.message}</DialogContentText>
                    <DialogContentText>{finishInfo.password === null ? "" :
                        <React.Fragment>
                            <span><b>下記の内容を申請者に連絡してください。</b></span>
                            <TextField label="管理者ログインID" InputProps={{ readOnly: true }} fullWidth defaultValue={finishInfo.email} />
                            <TextField label="管理者パスワード" InputProps={{ readOnly: true }} fullWidth defaultValue={finishInfo.password} />
                        </React.Fragment>
                    }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
                </DialogActions>
            </Dialog>
            <SystemContractUserSize ref={refSystemContractUserSize} masterData={props.masterData} />
            <SystemContractByteSize ref={refSystemContractByteSize} masterData={props.masterData} />
        </div>
    )
}

export const SystemTenantInfo = forwardRef(SystemTenantInfoComponent);

export class Utility {

    /**
     * 親へのイベント通知を阻止する
     */
    public static forbiddenTattle = (event: any): boolean => {
        event.stopPropagation();
        return false;
    }

}
import { Logger } from "../common/Logger";
import WebrtcService from "../webrtc/WebrtcService";
//import NoSleep from 'nosleep.js'  //iPad対応-3 floorと重複しているので削除

/**
 * ユーザ状態.
 */
export enum AudioId {
    None,           // 状態なし
    EnterRoom,      // 会議室に入った、ぶつかった
    Notify,         // 通知（ぶつかられた）
    MeetingStart,   // 会議開始（誰かが会議室に入ってきた）
    Alert,          // アラート
    WriteText,      // テキストを書き込んだ
    TimeLimit,      // 会議の制限時間告知
    Clapping,       // 拍手
    RecvMemo,       // メモ受信
    RecvMessage,    // 連絡受信
    Chime,          // チャイム受信
    Loginout,       // ログインアウト通知
}

export class AudioPlayer {
    private static audioList: {[key: number]: HTMLAudioElement} = {};
    private static sinkId: string = "";
    private static logger: Logger = Logger.getInstance();
    //private static noSleep:any = new NoSleep();   //iPad対応-3 floorと重複しているので削除

    private constructor() {
    }

    /**
     * iOSの自動再生対応を行うため、事前に読み込んで
     * ユーザートリガーでprePlay を呼び出し、ミュート状態で再生しておく必要がある
     */
    public static loadData(): void {
        // console.log("AudioPlayer loadData() start");
        if (Object.keys(this.audioList).length > 0) {
            return;
        }

        this.audioList[AudioId.EnterRoom] = this.createAudioElement("../raw/enter_room.mp3");
        // iPad対応-11 頭が切れるので、苦肉の策で、頭空白の音ファイルを使う
        if(this.isiOS() === true){
            this.audioList[AudioId.Notify] = this.createAudioElement("../raw/notify_iOS.mp3");
        } else {
            this.audioList[AudioId.Notify] = this.createAudioElement("../raw/notify.mp3");
        }
        this.audioList[AudioId.MeetingStart] = this.createAudioElement("../raw/meeting_start.mp3");
        this.audioList[AudioId.Alert] = this.createAudioElement("../raw/alert.mp3");
        this.audioList[AudioId.WriteText] = this.createAudioElement("../raw/write_text.mp3");
        this.audioList[AudioId.TimeLimit] = this.createAudioElement("../raw/time_limit.mp3");
        this.audioList[AudioId.Clapping] = this.createAudioElement("../raw/clapping.mp3");
        this.audioList[AudioId.RecvMemo] = this.createAudioElement("../raw/recv_memo.mp3");
        this.audioList[AudioId.RecvMessage] = this.createAudioElement("../raw/recv_message.mp3");
        this.audioList[AudioId.Chime] = this.createAudioElement("../raw/Japanese_School_Bell02-02.mp3");
        this.audioList[AudioId.Loginout] = this.createAudioElement("../raw/loginout_notify.mp3");
        
        // console.log("AudioPlayer loadData() end");
        
        // iPad対応-21 iPadでSSOサインイン後ボタン操作するまで音が出ないのでタッチスタートに変える
        // この対応でも、サインイン後何も操作しない場合は回避できない
        // windowsPCなど（モバイル端末以外？）はこの処理不要かも
        if(this.isiOS() === false){
            document.addEventListener("click",() => {this.prePlay()}, {once: true});
        } else {
            document.addEventListener("touchstart",() => {this.prePlay()}, {once: true});
        }
    }

    private static createAudioElement(src: string): HTMLAudioElement {
        const audio = new Audio();
        audio.preload = "none";
        audio.src = src;
        return audio;
    }

    public static prePlay(): void {
        if (this.audioList) {
            Object.keys(this.audioList).forEach((audioId) => {
                this.audioList[Number.parseInt(audioId)].muted = true;
                this.playAudioRetry(this.audioList[Number.parseInt(audioId)]);
                this.logger.info('PLAY_SOUND_TRACE prePlay src : ' +  this.audioList[Number.parseInt(audioId)].getAttribute('src'));
            });
            // console.log("AudioPlayer prePlay()");
        }

        /* iPad対応-3 floorと重複しているので削除
        if(this.isiOS() === true){
            this.noSleep.enable();
        }
        */
    }

    public static async play(id: AudioId): Promise<boolean> {
        let ret = false;
        const audio: HTMLAudioElement = this.audioList[id];
        if (audio) {
            audio.muted = false;
            audio.currentTime = 0;

            try {
                await this.playAudioRetry(audio);
                ret = audio.paused === false;
            } catch(e: any) {
                this.logger.error('play Error: ' + e.message);
            }
        }

        return Promise.resolve(ret);
    }

    public static async playAfterCall(id: AudioId, callMethod: any): Promise<boolean> {
        let ret = false;
        const audio: HTMLAudioElement = this.audioList[id];
        if (audio) {
            audio.muted = false;
            audio.currentTime = 0;

            try {
                audio.addEventListener('ended', () => {callMethod()}, {once: true});
                await this.playAudioRetry(audio);
                ret = audio.paused === false;
            } catch(e: any) {
                this.logger.error('playAfterCall Error: ' + e.message);
            }
        }

        return Promise.resolve(ret);
    }

    public static getPlayingSound(): HTMLAudioElement | null {
        if (this.audioList) {
            const keys = Object.keys(this.audioList);
            for (let i = 0; i < keys.length; i++) {
                if (this.audioList[Number.parseInt(keys[i])].paused === false) {
                    return this.audioList[Number.parseInt(keys[i])];
                }
            }
            // console.log("AudioPlayer prePlay()");
        }

        return null;
    }

    /**
     * デバイス選択画面で音声の出力先を選択された場合に、
     * 選択したデバイスから再生されるよう、効果音再生用のaudioに設定する処理
     * 
     * @param sinkId 
     */
     public static setSinkId(sinkId: string) {
        this.sinkId = sinkId;
        //this.logger.info('PLAY_SOUND_TRACE setSinkId : ' +  sinkId);
        if (this.audioList) {
            const keys = Object.keys(this.audioList);
            for (let i = 0; i < keys.length; i++) {
                const audio = this.audioList[Number.parseInt(keys[i])] as any;
                if (audio.setSinkId) {
                    audio.setSinkId(sinkId);
                    //this.logger.info('PLAY_SOUND_TRACE set src : ' +  audio.getAttribute('src'));
                } else {
                    //this.logger.info('PLAY_SOUND_TRACE audio.setSinkId is false src : ' +  audio.getAttribute('src'));
                }            
            }
        }
    }

    /**
     * setSinkIdメソッドが存在する環境ではエラー発生後、
     * デフォルトの出力先で再生をリトライする。
     * ブルートゥースデバイスなど設定されていた出力先が接続されていない状況を想定した処理。
     * 
     * @param audio 
     */
    public static async playAudioRetry(audio: HTMLAudioElement): Promise<void> {
        let ret = false;
        let error : any;
        try {
            // 音声の出力先が存在するかの確認処理
            // 音声出力先設定が可能かつデフォルトデバイス以外の場合に実行
            if ((audio as any).setSinkId && (this.sinkId === "" || this.sinkId === "default") === false) {
                // 設定済みのデバイスIDを再設定しても
                // デバイスが存在しない場合にdevice not found エラーが発生しないため
                // 初期化状態に戻してからデバイスIDを設定しなおしている
                await (audio as any).setSinkId("");
                await (audio as any).setSinkId(this.sinkId);
            }

            // OSやブラウザ依存の可能性があるが、
            // デバイス切断後、音声の再生ができなくてもエラーは発生しない。
            // 検証環境、Windows10 20H2、Chrome 90.0.4430.93
            await audio.play();
            ret = true;
        } catch(e: any) {
            error = e;
            this.logger.error('playAudioRetry Error: ' +  e.message);
            if ((audio as any).setSinkId) {
                this.logger.info("playAudioRetry setSinkId default retry");
                await (audio as any).setSinkId("").then(async () => {
                    ret = true;
                    this.logger.info('playAudioRetry setSinkID default retry Success');
                }).catch((err: any) => {
                    this.logger.error('playAudioRetry setSinkID default retry Error: ' + err.message);
                });

                await audio.play().then(() => {
                    this.logger.info('playAudioRetry play default retry Success');
                }).catch((e) => {
                    ret = false;
                    this.logger.error('playAudioRetry play default retry Error: ' + e.message);
                });
            }
        }

        if (ret) {
            return Promise.resolve();
        } else {
            return Promise.reject(error);
        }
    }

    // WebrtcComponent.tsxよりコピー
    public static isiOS(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);
    }
}

export default AudioPlayer;
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios, { AxiosResponse } from 'axios';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
        notify: {
            //marginTop: '50px',
            overflow: 'auto',
            //height: '250px',,
            maxHeight: '400px',
            textAlign: 'left',
          },
          paper2: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
          paper3: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
    }),
);

interface Props {

}

// 公開したいメソッドの定義
export interface ChildHandler {
    setOpen: (open: boolean) => void
    setMessage: (message: string) => void
    setTitle: (title: string) => void
}

const GeneralMessageFunction: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    useImperativeHandle(ref, () => ({
        setOpen: (open: boolean) => {
            setOpen(open);
        },
        setMessage: (message: string) => {
            setMessage(replaceCampusMessage(message));
        },
        setTitle: (title: string) => {
            setTitle(replaceCampusTitle(title));
        },
    }));

    useEffect(() => {

    }, [])

    const handleClickClose = () =>{
        setOpen(false);
    }

    const replaceCampusMessage = (message: string) => {
        switch (message) {
            case "会議室内のユーザーのみが変更できます。":
                return "部屋の外から施錠/解錠することはできません。";
            case "この会議室はロックされているため、会議に参加することはできません。":
                return "部屋に鍵がかかっているため入れません。部屋に入って会話に参加したい場合は、先生にチャットで連絡してください。";
            default:
                return message;
        }
    }

    const replaceCampusTitle = (title: string) => {
        switch (title) {
            case "ロックを変更できません":
                return "施錠/解錠できません";
            case "ロックされた会議室です":
                return "この部屋は鍵がかかっているため入れません"
            default:
                return title;
        }
    }
    
    const draw = useMemo(() => {
        if(open === false) return;

        return (
            <BaseDialog
                id="general-message-dialog"
                open={open}
                aria-labelledby="genaral-message-title"
                maxWidth="sm"
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="genaral-message-title" className={classes.dialogTitle}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose} color="primary" autoFocus style={{ color: '#006FBC' }}>
                        閉じる
                    </Button>
                </DialogActions>
            </BaseDialog>
        );

    }, [open, message]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const GeneralMessage = forwardRef(GeneralMessageFunction);

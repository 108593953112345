/** 
 * Material-UIのDialogに下記の機能を追加した拡張コンポーネント
 * 
 * ・ダイアログ上でドラッグしたとき、floorがドラッグされないようにする。（pointerEvents: 'auto'） 
 * ・ダイアログ上で右クリックしたとき、floorに「ここへ移動しますか？」が表示されないようにする。（onContextMenu: handleStop）
*/

import React, { useCallback } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';

export default function BaseDialog(props: DialogProps) {
    const handleStop = useCallback((event: any) => {
        event.preventDefault();
        event.stopPropagation();
    }, [])

    const _props = {
        ...props,
        style: { pointerEvents: 'auto', ...props.style },
        onContextMenu: handleStop
    } as DialogProps;

    return (
        <Dialog {..._props}>
            {props.children}
        </Dialog>
    );
}


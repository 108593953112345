import React, { Fragment, useMemo, useRef, useEffect, useState, ForwardRefRenderFunction, forwardRef, useImperativeHandle, } from 'react';
import useReactRouter from 'use-react-router';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { DraggableData, DraggableEvent } from "react-draggable";
import { Rnd } from "react-rnd";
import ZIndex from "../common/ZIndex";
// import { User, MyRoomFloorObject, Seat } from '../common/JsonClass';
import { AvatarMenuData } from '../common/JsonClass';
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import { MyRoomOtherUsers } from './OtherUsers';
import { Utility } from '../common/Utility';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';
import { MEMO_FRAME_BG_COLOR } from '../common/Constants';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import useWindowSize from '../common/useWindowSize';
import UserInfoCard from "./UserInfoCard";
import JfsClient, { JfsError, User} from '@fsi/jfs-sdk';


interface Props {

}

export interface MyRoomUserInfoCardHandler {
    setOpen: (isOpen: boolean) => void;
    setUser: (user: User) => void;
}

const MyRoomUserInfoCardFunction: ForwardRefRenderFunction<MyRoomUserInfoCardHandler, Props> = (props, ref) => {

    const [user, setUser] = useState(new User());
    const [open, setOpen] = useState(false);

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        setOpen(isOpen: boolean) {
            setOpen(isOpen);
        },
        setUser(user: User) {
            setUser(user);
        },
    }));

    /*
    * マイルームのオブジェクトを描画(FloorObject描画処理から抜粋)
    */
    const draw = useMemo(() => {
        if(user){
            return (
                <UserInfoCard
                    className={NEED_TO_POINTER_CAPTURE}
                    open={open}
                    user={user}
                    ping={-1}
                    onClickRefresh={() => {}}
                    onClickClose={() => {setOpen(false)}}
                    zIndex={ZIndex.userInfoCard}
                    isLarge={false}
                    enabledBusinessCard={false}
                    kind='myroom'
                    isMyUser={false}
                    floorSize={{width:1, height:1}}
                    />
            );
        }
        else {
            return;
        }

    }, [user, open]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const MyRoomUserInfoCard = forwardRef(MyRoomUserInfoCardFunction);
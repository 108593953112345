import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect } from 'react';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios, { AxiosResponse } from 'axios';
import Grid from '@material-ui/core/Grid';
import JfsClient from '@fsi/jfs-sdk';
import { informationURL } from '../common/DocumentURL';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notify: {
            //marginTop: '50px',
            overflow: 'auto',
            //height: '250px',,
            maxHeight: '400px',
            textAlign: 'left',
          },
          paper2: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
          paper3: {
            marginTop: '0px',
            padding: '0px',
            backgroundColor:'rgba(255,255,255,0.8)',
            // margin: theme.spacing(1, 2),
            display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'center',
          },
    }),
);

interface Props {

}

// 公開したいメソッドの定義
export interface ChildHandler {
    setInformationOpen: (open: boolean) => void
}

const InformationDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [information, setInformation] = useState('');
    const [contact, setContact] = useState('');
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        setInformationOpen: (open: boolean) => {
            setOpen(open);
        }
    }));

    useEffect(() => {
        getInformation();
        getContact();

        function getInformation() {
            let ddd = new Date();
            let rURL = informationURL + "?" + ddd.getTime();
            setInformation(rURL);
/*
        //   var params = new URLSearchParams();
        //   axios.post('/api/service/information', params)
            httpClient.getInformation()
            .then((e) => {
              setInformation(e);
            }).catch(() => {
            });
*/
        }

        function getContact() {
            // var params = new URLSearchParams();
            // axios.post('/api/service/contact', params)
            httpClient.getServiceContact()
              .then((e) => {
                // console.log("お問い合わせ先取得", e.data);
                setContact(e);
              }).catch(() => {
              });
          }
      }, [])

    const handleClickClose = () =>{
        setOpen(false);
    }
    
    const drawDialog = useMemo(() => {

        // バージョン取得
        // (今は出してない)
        //let p = require('../../package.json');

        return (
            <BaseDialog
                open={open} onClose={handleClickClose}
                aria-labelledby="information-dialog-title"
                aria-describedby="information-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="information-dialog-title" style={{ textAlign: "center" }}>{"お知らせ"}</DialogTitle>
                <DialogContent>
                    <Divider/>
                    <iframe src={information}
                            frameBorder='0'
                            title='information'
                            style={{width:'100%', height:'400px'}}
                        />
                    <Divider/>
                    {/*
                    <DialogContentText id="alert-dialog-description"  style={{textAlign: "right"}}>
                    更新日：{p.updatedate}　バージョン：{p.version}<br/>
                    </DialogContentText>
                    <Fragment>
                        <Grid className={classes.notify}>
                            <div className={classes.paper2}>
                                <div dangerouslySetInnerHTML={{ __html: information }}></div>
                            </div>
                        </Grid>
                    </Fragment>
                    */}
                </DialogContent>

                <DialogContent>
                    <Fragment>
                        <Grid className={classes.notify}>
                            <div className={classes.paper3}>
                                <div dangerouslySetInnerHTML={{ __html: contact }}></div>
                            </div>
                        </Grid>
                    </Fragment>
                </DialogContent>

                <DialogActions>
                    <Button id="news-dialog-OK-Button" onClick={handleClickClose} color="primary" autoFocus style={{color: '#006FBC'}}>
                        閉じる
                    </Button>
                </DialogActions>
            </BaseDialog>
        );

    }, [open, information, contact]);

    return (
        <Fragment>
            {drawDialog}
        </Fragment>
    )
}

export const InformationDialogRef = forwardRef(InformationDialog);

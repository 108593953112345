import React, { useState, useMemo, Fragment } from 'react';
// import { FloorObject } from '../common/JsonClass';
import EditTextDialog from './EditTextDialog';
import ZIndex from "../common/ZIndex";
import JfsClient, { FloorObject } from '@fsi/jfs-sdk';

interface Props {
    className: string,
    floorObject: FloorObject,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void
}

export default function WhiteBoard(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const drawDynamicKanban = useMemo(() => {
        return (
        <div 
            onClick={() => setIsOpen(true)}
            style={{
                position: 'absolute',
                background: 'transparent',
                padding: '15px 20px',
                margin: '0',
                boxSizing: 'border-box',
                width: '100%',
                height: '100%',
                textAlign: 'left',
                whiteSpace: 'pre-line',
                overflow: 'auto',
                cursor: 'pointer',
                zIndex: ZIndex.floorObject + 1,
            }}
        >
            {text2}
        </div>)
        }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [text2])

    const draw = useMemo(() => 
        <Fragment>
        <div
            key={`whiteBoard${id}`}
            id={`whiteBoard${id}`}
            className={className}
            style={{
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                // backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorObject,
            }}
        >
            {drawDynamicKanban}
            <EditTextDialog 
                className={className}
                floorObjectId={id}
                isOpen={isOpen}
                title={text1 ? `${text1}: 表示内容` : '表示内容'}
                text={text2}
                maxLength={500}
                isPlayAudio
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </div>
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [offsetLeft, offsetTop, objectMaster, text1, text2, isOpen]);
    
    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
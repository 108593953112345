import { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(() => ({
    startIcon: {
        marginLeft: 16,
    },
    title: {
        marginLeft: 8,
    },
    endIcon: {
        marginLeft: 'auto',
        marginRight: 5,
    },
}));

interface Props {
    className?: string;
    title: string;
    handleFrameDoubleClick: () => void;
    handleRefresh: () => void;
    handleClose: () => void;
}

const Header: FC<Props> = ({
    className = undefined,
    title = 'title',
    handleFrameDoubleClick,
    handleRefresh,
    handleClose,
}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            className={className}
            justify='flex-start'
            alignItems='center'
            onDoubleClick={handleFrameDoubleClick}
        >
            <SupervisorAccountIcon className={classes.startIcon} />
            <Typography className={classes.title} variant='subtitle1'>{title}</Typography>
            <div className={classes.endIcon}>
                <IconButton size="small" onClick={handleRefresh}><RefreshIcon /></IconButton>
                <IconButton size="small" onClick={handleClose}><CloseIcon /></IconButton>
            </div>
        </Grid>
    );
}

export default Header;
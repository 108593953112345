import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { User } from '../common/JsonClass';

interface Props {
    dummy: string,
}

// 公開したいメソッドの定義
export interface ChildHandler {
    plus(): void;
    getUsers(): User[];
    updateUsers(users: User[]): void;
}

// function ChildTest(props: Props, ref: any) {
const ChildTest: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {

    const [param, setParam] = useState("");
    const [users, setUsers] = useState([] as User[]);

    class Test {
        x: number = 10;
        y: number = 0;
    }

    useEffect(() => {
        let name = "x";
        let test = new Test();
        Reflect.set(test, name , 1);
        
        console.log(test.x)

        let temp: User[] = [...users];
        for(let i = 0; i<10; i++) {
            let user: User = new User();
            user.ping = 10;
            temp.push(user);
        }
        setUsers(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useImperativeHandle(ref, () => ({
        plus: () => {
          console.log("child plus")
          setParam("test" + Math.random());
        },
        getUsers: () => {
            return [...users];
        },
        updateUsers: (users: User[]) => {
            // setUsers(users);
        }
    }))

    return (
        <React.Fragment>
            {console.log("ChildTest::render")}
            <div>{param}</div>
            <div>ChildTest</div>
            <div>{users[0]?.ping}</div>
        </React.Fragment>
    );
}

export const ChildTestRef = forwardRef(ChildTest);
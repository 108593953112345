import { Backdrop, Card, CardContent, CircularProgress, Slide, Typography, Button, DialogActions, DialogContent, DialogTitle, createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import BaseDialog from './BaseDialog';
import SelectDeviceDialog from './SelectDeviceDialog';
import { Utility } from '../common/Utility';

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

export class Office {
    id: number = 0;
    officeName: string = "";
    privacyRoom: boolean = false;
}

export class Floor {
    id: number = 0;
    floorName: string = "";
}

export default function SelectDevice() {
    const { history } = useReactRouter();
    const state = history.location.state as any;
    const [openDeviceSelect, setOpenDeviceSelect] = useState(false);    
    const [openMesssageDialog, setOpenMesssageDialog] = useState(false);    
    const [openTmotMesssageDialog, setOpenTmotMesssageDialog] = useState(false);    // #287 タイムアウトメッセージ
    const [notifyTitle, setNotifyTitle] = useState("");
    const [notifyMessage, setNotifyMessage] = useState("");
    const [showBackdrop, setShowBackdrop] = React.useState(false);      // #827 waiting表示
    const [backdropMessage, setBackdropMessage] = useState("");     // #827 waiting表示

    useEffect(() => {
        Utility.requestOfficeList()
            .then(() => {
                console.log("デバイス選択ページが開かれました。");
                localStorage.setItem("timeoutSignout_mode", "0");
            }).catch(err => {
                // signinなしで "/selectdevice" を指定した場合、"/" へ
                Utility.checkErrorResponseOfOffice(err, handleOpenSignin);
            });
    }, []);

    const handleOpenFloor = () => {
        history.push({
            pathname: "/floor",
            state: { path: state.path, id: state.id }
        });
    }

    const handleOpenTop = () => {
        history.push({
            pathname: "/top",
        });
    }

    const handleOpenSignin = () => {
        const signinpage = Utility.getSigninPage();
        history.push({
            pathname: signinpage
        });
    }

    const showMessage = (title: string, message: string) => {
        setNotifyTitle(title);
        setNotifyMessage(message);
        setOpenMesssageDialog(true);
    }

    const handleCloseMessageDialog = () => {
        setOpenMesssageDialog(false);
        setOpenDeviceSelect(true);
    }

    // #287 タイムアウトメッセージ
    const showTmotMessage = (title: string, message: string) => {
        setNotifyTitle(title);
        setNotifyMessage(message);
        setOpenTmotMesssageDialog(true);
    }

    // #827 waiting表示
    const showWaiting = (mode: number, disp: boolean, message: string) => {
        if(mode === 1) {
            setBackdropMessage(message);
            setShowBackdrop(disp);
        }
    }

    const handleCloseMessageDialogSignout = () => {
        setOpenMesssageDialog(false);
        setOpenTmotMesssageDialog(false);   // #287 タイムアウトメッセージ
        localStorage.setItem("timeoutSignout_mode", "1");
        handleOpenSignin();
    }

    const handleCloseMessageDialogSelFloor = () => {
        setOpenMesssageDialog(false);
        handleOpenTop();
    }

    const handleDeviceSelectEnd = (success: boolean) => {
        setOpenDeviceSelect(false);
        if (success) {
            if(localStorage.getItem("timeoutSignout_mode") !== "1") {
                if (state?.nextpage === "/floor") {
                    handleOpenFloor();
                } else {
                    handleOpenTop();
                }
            }
        }
    }

    return (
        <Fragment>
            <BaseDialog
                id="messageDialog"
                open={openMesssageDialog}
                onClose={() => handleCloseMessageDialog()}
                aria-labelledby="messageDialogTitle"
            >
                <DialogTitle id="messageDialogTitle">{notifyTitle}</DialogTitle>
                <DialogContent>
                {notifyMessage.split("\n").map((text, index) => {
                    return <div key={index}>{text}</div>
                })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMessageDialog} color='primary' style={{ pointerEvents: 'auto' }}>
                        {"カメラマイク設定"}
                    </Button>
                    {/* ちゃんとした仕様にすべく、再検討。一旦やめる。（2021/12/6）
                    <Button onClick={() => handleCloseMessageDialogSelFloor()} color='primary' style={{ pointerEvents: 'auto' }}>
                        フロア選択
                    </Button>
                    */}
                    <Button onClick={() => handleCloseMessageDialogSignout()} color='primary' style={{ pointerEvents: 'auto' }}>
                        ログアウト
                    </Button>
                </DialogActions>
            </BaseDialog>            
            {/* #287 タイムアウトメッセージ */}
            <BaseDialog
                id="messageTmotDialog"
                open={openTmotMesssageDialog}
                onClose={() => handleCloseMessageDialog()}
                aria-labelledby="messageTmotDialogTitle"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="messageTmotDialogTitle">{notifyTitle}</DialogTitle>
                <DialogContent>
                {notifyMessage.split("\n").map((text, index) => {
                    return <div key={index}>{text}</div>
                })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseMessageDialogSignout()} color='primary' style={{ pointerEvents: 'auto' }}>
                        ログアウト
                    </Button>
                </DialogActions>
            </BaseDialog>            
            <SelectDeviceDialog
                checkMediaDevices={true}
                openDeviceSelect={openDeviceSelect}
                handleDeviceSelectEnd={handleDeviceSelectEnd}
                requireCamera={state?.requireCamera}
                requireMic={state?.requireMic}
                showMessage={showMessage}
                showTmotMessage={showTmotMessage}   // #287 タイムアウトメッセージ
                showWaiting={showWaiting}   // #827 waiting表示

            />
            {/* #827 waiting表示 */}
            <Backdrop open={showBackdrop} >
                <Slide direction="up" in={showBackdrop}>
                <Card >
                    <CardContent>
                    <ThemeProvider theme={circularTheme}>
                        <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
                    </ThemeProvider>
                    <Typography variant="subtitle2" >{backdropMessage}</Typography>
                    </CardContent>
                </Card>
                </Slide>
            </Backdrop>
        </Fragment>
    )
}
import * as React from 'react';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { StyleRules, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent, FormControl, Select, DialogActions, Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import WebrtcService from '../webrtc/WebrtcService';
import { Utility } from '../webrtc/Utility';
import { printable } from '../log/LogSetting'
import axios, { AxiosResponse } from 'axios';
import JfsClient from '@fsi/jfs-sdk';

if(printable == 0){
  console.log = function(){}
  console.warn = function(){}
  console.error = function(){}
}

const controlVideoWidth : number = 80;
const controlVideoHeight : number = 80;
const mediaWaitTimeoutVal: number = (10*1000);  // #827 タイムアウト時間変更（60秒 -> 10秒）
//const mediaWaitTimeoutVal: number = (1*1000);
const getErrCode = (error: any) => {
    var code: string = "";
    try {
        code = error.code.toString();
    } catch (e) {
    }
    return code;
}
const getErrMessage = (error: any) => {
    var message: string = "";
    try {
        message = error.message;
    } catch (e) {
    }
    return message;
}
const getErrName = (error: any) => {
    var name: string = "";
    try {
        name = error.name;
    } catch (e) {
    }
    return name;
}
const getExceptionMsg = (error: any) => {
    const code: string = getErrCode(error);
    const message: string = getErrMessage(error);
    const name: string = getErrName(error);
    return(code + "," + message + "," + name);
}

const styles = (theme: Theme): StyleRules => createStyles({
    rootDiv: {
        width: '100%',
    },

    rootDivHidden: {
        display:"none",
    }, 
})

interface Props extends WithStyles<typeof styles> {
    openDeviceSelect: boolean,
    checkMediaDevices: boolean,
    requireCamera: boolean,
    requireMic: boolean,
    showMessage: (title: string, message: string) => void,
    showTmotMessage: (title: string, message: string) => void,      // #287 タイムアウトメッセージ
    showWaiting: (mode: number, disp: boolean, message: string) => void,     // #827 waiting表示
    handleDeviceSelectEnd: (success: boolean) => void,
}

interface State {
    isOpenDeviceSelect: boolean,
    videoDeviceId: string,
    audioInputDeviceId : string,
    audioOutputDeviceId: string,
    usermanualURL: string,
}

class SelectDeviceDialog extends React.Component<Props, State>{
    currentVideoDeviceIdIndex : number = 0;
    currentAudioInputDeviceIdIndex : number = 0;
    currentAudioOutputDeviceIdIndex : number = 0;
    audioInputDeviceInfoList : MediaDeviceInfo[] = [];
    audioOutputDeviceInfoList: MediaDeviceInfo[] = [];
    videoInputDeviceInfoList: MediaDeviceInfo[] = [];

    mediaStream: MediaStream|undefined = undefined;
    useVideo: boolean = false;
    isLoadedStream: boolean = false;
    isCheckMediaDevices: boolean = false;

    mediaWaitTimerId: number = 0;
    mediaWaitStartDate: Date = new Date();
    mediaWaitStartItem: String = "";

    private jfsClient = JfsClient.getInstance();
    private httpClient =  this.jfsClient.httpClient;

    constructor(props: Props) {
        super(props);

        this.state = {
            isOpenDeviceSelect: false,
            videoDeviceId: "",
            audioInputDeviceId : "",
            audioOutputDeviceId: "",
            usermanualURL:"",
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        this.asyncComponentDidUpdate(prevProps, prevState);
    }

    asyncComponentDidUpdate = async (prevProps: Props, prevState: State) => {
        if (prevProps.openDeviceSelect !== this.props.openDeviceSelect){
            // 呼び出し元で、値を変更させれた場合には、常にデバイス選択画面を表示する。
            // 呼び出し元から閉じる操作は行わないことが前提。
            // デバイス一覧を更新するため、setMediaList 内で isOpenDeviceSelect: true を行うように変更
            if (this.props.openDeviceSelect) {
                await this.setMediaList();
            }
        }

        if (prevState.isOpenDeviceSelect !== this.state.isOpenDeviceSelect) {
            WebrtcService.isOpenDeviceSelect = this.state.isOpenDeviceSelect;
        }

        if (prevState.isOpenDeviceSelect !== this.state.isOpenDeviceSelect && this.state.isOpenDeviceSelect) {
            // ダイアログ表示時
            console.log("プレビュー開始 : ダイアログ表示時 : this.isCheckMediaDevices", this.isCheckMediaDevices);
            
            // CheckMediaDevice 済のときに開始する
            if (this.isCheckMediaDevices === true) {
                this.handlePreviewDeviceSelect();
            } else {
                await this.setMediaList();
            }
        }
        else if (prevState.videoDeviceId !== this.state.videoDeviceId ||
            prevState.audioInputDeviceId !== this.state.audioInputDeviceId ||
            prevState.audioOutputDeviceId !== this.state.audioOutputDeviceId) {

            // デバイス変更時
            console.log("プレビュー開始 : デバイス変更時 : this.props.openDeviceSelect : ", this.props.openDeviceSelect, "this.state.isOpenDeviceSelect ", this.state.isOpenDeviceSelect, " this.isCheckMediaDevices, ", this.isCheckMediaDevices);
            
            // デバイス選択画面が表示されていて CheckMediaDevice 済のときに開始する
            if (this.state.isOpenDeviceSelect === true && this.isCheckMediaDevices === true) {
                this.handlePreviewDeviceSelect();
            }
        }
    }

    componentDidMount() {
        // var params = new URLSearchParams();
        // axios.post('/api/service/get', params)
        //     .then((e: AxiosResponse) => {
        //         let ddd = new Date();
        //         let rURL = e.data.usermanualURL + "?" + ddd.getTime();
        //         this.setState({usermanualURL: rURL});
        //       }).catch(() => {
        //     });

        //     if (this.props.checkMediaDevices) {
        //     this.checkMediaDevices();
        // }
        if (this.props.checkMediaDevices) {
            this.checkMediaDevices();
        }
    }

    componentWillUnmount() {
    }

    setMediaList = async () => {
        if (this.props.checkMediaDevices === false) {
            await this.checkMediaDevices();
        }

        try {
            // iOS Safari13.1 で enumerateDevices()でデバイス情報を取得するため、1回 getUserMediaを呼び出している。
            // Safari13.1未満、Chromeでは、getUserMediaを呼び出す前からデバイス情報は取得できる。
            // #670 iPad対応-25 iPad,iphoneの場合は、意図しないタイミングでデバイス使用許可が外れるので、getUserMediaを叩き直す必要がある。
            if ((this.isCheckMediaDevices === false && WebrtcService.isCalledGetUserMedia === false)
                || (WebrtcService.isiOS()) === true) {
                const constraints : any = {video: true, audio: true};
                this.setMediaWaitTimer(mediaWaitTimeoutVal, "getUserMedia-01");
                this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
                await navigator.mediaDevices.getUserMedia(constraints)
                        .then((stream) => {
                    this.clearMediaWaitTimer();
                    this.props.showWaiting(1, false, "");    // #827 waiting表示解除
                    stream.getTracks().forEach(track => track.stop());
                }).catch((error: DOMException) => {
                    this.clearMediaWaitTimer();
                    this.props.showWaiting(1, false, "");    // #827 waiting表示解除
                    this.traceSend("getUserMedia-01 error : "+getExceptionMsg(error));
                });
            }

            this.setMediaWaitTimer(mediaWaitTimeoutVal, "enumerateDevices-01");
            this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
            await navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => { // 成功時
                this.clearMediaWaitTimer();
                this.props.showWaiting(1, false, "");    // #827 waiting表示解除
                let videoIdIndex = 0;
                let audioInputIdIndex = 0;
                let audioOutputIdIndex = 0;

                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if (deviceInfo.deviceId === "" && deviceInfo.label === "") {
                        continue;
                    }
            
                    if (deviceInfo.kind === 'audioinput') {
                        this.audioInputDeviceInfoList[audioInputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioInputIdIndex++;
                    } else if (deviceInfo.kind === 'audiooutput') {
                        this.audioOutputDeviceInfoList[audioOutputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioOutputIdIndex++;
                    } else if (deviceInfo.kind === 'videoinput') {
                        if (deviceInfo.label.match(" IR ")) {
                            continue;
                        }

                        this.videoInputDeviceInfoList[videoIdIndex] = deviceInfo as MediaDeviceInfo;
                        videoIdIndex++;
                    }
                }
            }).catch((err) => { // エラー発生時
                this.clearMediaWaitTimer();
                this.props.showWaiting(1, false, "");    // #827 waiting表示解除
                this.traceSend("enumerateDevices-01 error : "+getExceptionMsg(err));
            });

            this.setState({isOpenDeviceSelect: true});
        } catch(err) {
            this.traceSend("setMediaList exception");
        }        
    }

    checkMediaDevices = async () => {
        this.traceLog("SELDEV_DIALOG : checkMediaDevices start")
        if (this.isCheckMediaDevices) {
            this.traceLog("SELDEV_DIALOG : return isCheckMediaDevices=true")
            return;
        }

        try {
            // #670 iPad対応-25 iPad,iphoneの場合は、意図しないタイミングでデバイス使用許可が外れるので、getUserMediaを叩き直す必要がある。
            if (WebrtcService.isCalledGetUserMedia === false || (WebrtcService.isiOS()) === true) {
                let hasDeviceInfos = false;
                this.traceLog("SELDEV_DIALOG : enumerateDevices call 1")
                this.setMediaWaitTimer(mediaWaitTimeoutVal, "enumerateDevices-02");
                this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
                //// for Debug
                //const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
                //await sleep(1000*20);
                //// for Debug
                await navigator.mediaDevices.enumerateDevices()
                .then((deviceInfos) => { // 成功時
                    this.clearMediaWaitTimer();
                    this.props.showWaiting(1, false, "");   // #827 waiting表示解除
                    this.traceLog("SELDEV_DIALOG : enumerateDevices ret devCnt=["+deviceInfos.length+"]")
                    if (deviceInfos.length > 0) {
                        if (deviceInfos[0].deviceId !== "") {
                            hasDeviceInfos = true;
                        }
                    }
                    //this.traceSend("enumerateDevices-02 get signout = "+localStorage.getItem("timeoutSignout_mode"));
                    if(localStorage.getItem("timeoutSignout_mode") === "1"){
                        this.traceSend("enumerateDevices-02 signout");
                        this.isCheckMediaDevices = true;
                        return;
                    }
                }).catch((err) => { // エラー発生時
                    this.clearMediaWaitTimer();
                    this.props.showWaiting(1, false, "");   // #827 waiting表示解除
                    this.traceSend("enumerateDevices-02 error : "+getExceptionMsg(err));
                });
                this.traceLog("SELDEV_DIALOG : hasDeviceInfos=["+hasDeviceInfos+"]")

                // #670 iPad対応-25 iPad,iphoneの場合は、意図しないタイミングでデバイス使用許可が外れるので、getUserMediaを叩き直す必要がある。
                if (hasDeviceInfos === false || (WebrtcService.isiOS()) === true) {
                    // iOS Safari13.1 で enumerateDevices()でデバイス情報を取得するため、1回 getUserMediaを呼び出している。
                    // Safari13.1未満、Chromeでは、getUserMediaを呼び出す前からデバイス情報は取得できる。
                    const constraints : any = {video: true, audio: true};
                    this.traceLog("SELDEV_DIALOG : getUserMedia call 1")
                    this.setMediaWaitTimer(mediaWaitTimeoutVal, "getUserMedia-02");
                    this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
                    await navigator.mediaDevices.getUserMedia(constraints)
                            .then((stream) => {
                        this.clearMediaWaitTimer();
                        this.props.showWaiting(1, false, "");   // #827 waiting表示解除
                        this.traceLog("SELDEV_DIALOG : getUserMedia ret")
                        // Success
                        // console.log("checkMediaDevices audio stream");
                        stream.getTracks().forEach(track => track.stop());
                    }).catch((error: DOMException) => {
                        this.clearMediaWaitTimer();
                        this.props.showWaiting(1, false, "");   // #827 waiting表示解除
                        // console.error('checkMediaDevices mediaDevice.getUserMedia() error:', error);
                        this.traceSend("getUserMedia-02 error : "+getExceptionMsg(error));
                        this.traceLog("SELDEV_DIALOG : getUserMedia DOMException ["+getExceptionMsg(error)+"]")
                    });

                    WebrtcService.isCalledGetUserMedia = true;
                }
            }

            this.traceLog("SELDEV_DIALOG : enumerateDevices call 2")
            this.setMediaWaitTimer(mediaWaitTimeoutVal, "enumerateDevices-03");
            this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
            await navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => { // 成功時
                this.clearMediaWaitTimer();
                this.props.showWaiting(1, false, "");   // #827 waiting表示解除
                this.traceLog("SELDEV_DIALOG : enumerateDevices ret devCnt=["+deviceInfos.length+"]")
                let videoIdIndex = 0;
                let audioInputIdIndex = 0;
                let audioOutputIdIndex = 0;
                let hasVideoDevice = false;
                let hasAudioDevice = false;
                let hasAudioOutputDevice = false;

                // 過去に保存したカメラ、マイクデバイスが
                // 存在する場合、デフォルトでそのデバイスを使用し、
                // デバイス選択画面を表示しない
                let videoDeviceId = localStorage.getItem("videoDeviceId");
                let audioDeviceId = localStorage.getItem("audioInputDeviceId");
                let audioOutputDeviceId = localStorage.getItem("audioOutputDeviceId");                
                // 未選択を選んだ場合も過去に選択したものとして扱っている
                let hasSaveVideoDevice = videoDeviceId === "" ? true : false;
                let hasSaveAudioInputDevice = audioDeviceId === "" ? true : false;
                let hasSaveAudioOutputDevice = audioOutputDeviceId === "" ? true : false;                
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if (deviceInfo.deviceId === "" && deviceInfo.label === "") {
                        continue;
                    }
                    // console.log(deviceInfo.kind + " : " + deviceInfo.label + " : " + deviceInfo.deviceId);
            
                    if (deviceInfo.kind === 'audioinput') {
                        // console.log("audio device found");

                        // 保存されていたデバイスと一致した場合には、そのデバイスをデフォルトで選択状態とするため
                        // デバイスのインデックスを保持する
                        if (audioDeviceId) {
                            if (deviceInfo.deviceId === audioDeviceId) {
                                this.currentAudioInputDeviceIdIndex = audioInputIdIndex;
                                hasSaveAudioInputDevice = true;
                                localStorage.setItem("audioInputDeviceLabel", deviceInfo.label);
                            }
                        }

                        this.audioInputDeviceInfoList[audioInputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioInputIdIndex++;
                        hasAudioDevice = true;
                    } else if (deviceInfo.kind === 'audiooutput') {
                        if (audioOutputDeviceId) {
                            if (deviceInfo.deviceId === audioOutputDeviceId) {
                                this.currentAudioOutputDeviceIdIndex = audioOutputIdIndex;
                                hasSaveAudioOutputDevice = true;
                                localStorage.setItem("audioOutputDeviceLabel", deviceInfo.label);
                            }
                        }

                        this.audioOutputDeviceInfoList[audioOutputIdIndex] = deviceInfo as MediaDeviceInfo;
                        audioOutputIdIndex++;
                        hasAudioOutputDevice = true;
                    } else if (deviceInfo.kind === 'videoinput') {
                        // console.log(deviceInfo.label);
                        
                        // Surfaceに搭載されている赤外線カメラを選択項目に入れないようにしている
                        if (deviceInfo.label.match(" IR ")) {
                            continue;
                        }

                        // 保存されていたデバイスと一致した場合には、そのデバイスをデフォルトで選択状態とするため
                        // デバイスのインデックスを保持する
                        if (videoDeviceId) {
                            if (deviceInfo.deviceId === videoDeviceId) {
                                this.currentVideoDeviceIdIndex = videoIdIndex;
                                hasSaveVideoDevice = true;
                                localStorage.setItem("videoDeviceLabel", deviceInfo.label);
                            }
                        }

                        this.videoInputDeviceInfoList[videoIdIndex] = deviceInfo as MediaDeviceInfo;
                        videoIdIndex++;
                        hasVideoDevice = true;
                    }
                }

                // iPad SafariなどデバイスIDがブラウザ起動ごとに変更されるものの対応
                // 保存済みのデバイスIDが一致しなかった場合、
                // ラベルで検索する
                if (hasVideoDevice && (hasSaveVideoDevice === false)) {
                    const index = this.getDeviceLabelIndex("videoDeviceLabel", this.videoInputDeviceInfoList);
                    if (index > -1) {
                        videoDeviceId = this.videoInputDeviceInfoList[index].deviceId;
                        localStorage.setItem("videoDeviceId", videoDeviceId);
                        this.currentVideoDeviceIdIndex = index;
                        hasSaveVideoDevice = true;
                    }
                }

                if (hasAudioDevice && (hasSaveAudioInputDevice === false)) {
                    const index = this.getDeviceLabelIndex("audioInputDeviceLabel", this.audioInputDeviceInfoList);
                    if (index > -1) {
                        audioDeviceId = this.audioInputDeviceInfoList[index].deviceId;
                        localStorage.setItem("audioInputDeviceId", audioDeviceId);
                        this.currentAudioInputDeviceIdIndex = index;
                        hasSaveAudioInputDevice = true;
                    }
                }

                if (hasAudioOutputDevice && (hasSaveAudioOutputDevice === false)) {
                    const index = this.getDeviceLabelIndex("audioOutputDeviceLabel", this.audioOutputDeviceInfoList);
                    if (index > -1) {
                        audioOutputDeviceId = this.audioOutputDeviceInfoList[index].deviceId;
                        localStorage.setItem("audioOutputDeviceId", audioOutputDeviceId);
                        this.currentAudioOutputDeviceIdIndex = index;
                        hasSaveAudioOutputDevice = true;
                    }
                }

                let isOpenDeviceSelect = false;
                if (hasVideoDevice || hasAudioDevice || hasAudioOutputDevice) {
                    // カメラかマイクのどちらかのデバイスを持っている
                    if (hasSaveVideoDevice && hasSaveAudioInputDevice && hasSaveAudioOutputDevice) {
                        // 保存済みのデバイスIDが一致した場合
                        this.traceLog("SELDEV_DIALOG : 保存済みのデバイスIDが一致")
                    } else {
                        // 保存済みのデバイスIDが一致しなかった場合
                        this.traceLog("SELDEV_DIALOG : 保存済みのデバイスIDが一致しなかった")
                        isOpenDeviceSelect = true;

                        if (hasVideoDevice) {
                            videoDeviceId = this.videoInputDeviceInfoList[0].deviceId;
                            localStorage.setItem("videoDeviceId", videoDeviceId);
                            this.currentVideoDeviceIdIndex = 0;
                            hasSaveVideoDevice = true;
                        }

                        if (hasAudioDevice) {
                            audioDeviceId = this.audioInputDeviceInfoList[0].deviceId;
                            localStorage.setItem("audioInputDeviceId", audioDeviceId);
                            this.currentAudioInputDeviceIdIndex = 0;
                            hasSaveAudioInputDevice = true;
                        }
                    }
                }

                this.setState({
                    videoDeviceId : videoDeviceId && hasSaveVideoDevice ? videoDeviceId : "",
                    audioInputDeviceId : audioDeviceId && hasSaveAudioInputDevice ? audioDeviceId : "",
                    audioOutputDeviceId : audioOutputDeviceId && hasSaveAudioOutputDevice ? audioOutputDeviceId : "",
                    isOpenDeviceSelect : isOpenDeviceSelect,
                });

                WebrtcService.setDeviceSelected(true);
                if (isOpenDeviceSelect === false) {
                    this.traceLog("SELDEV_DIALOG : handleCloseDeviceCheck call")
                    this.handleCloseDeviceCheck();
                    // this.props.handleDeviceSelectEnd(true);
                }

                if (isOpenDeviceSelect) {
                    this.traceSend("DeviceSelect call");
                    this.handlePreviewDeviceSelect();
                }
            }).catch((err) => { // エラー発生時
                // console.error('enumerateDevices ERROR:', err);
                this.clearMediaWaitTimer();
                this.props.showWaiting(1, false, "");   // #827 waiting表示解除
                this.traceSend("enumerateDevices-03 error : "+getExceptionMsg(err));
                //this.traceLog("SELDEV_DIALOG : enumerateDevices err ["+err+"]")
            });
        } catch(err) {
            // console.error('enumerateDevices ERROR:', err);
            this.traceLog("SELDEV_DIALOG : err ["+err+"]")
            this.traceSend("checkMediaDevices exception");
        }        

        this.isCheckMediaDevices = true;
    }

    getDeviceLabelIndex = (storageKey: string, deviceInfoList: MediaDeviceInfo[]) => {
        const deviceLabel = localStorage.getItem(storageKey);
        if (deviceLabel) {
            for (let i = 0; i < deviceInfoList.length; i++) {
                if (deviceLabel === deviceInfoList[i].label) {
                    return i;
                }
            }
        }

        return -1;
    }

    getAudioConstraints = () => {
        let audioConstraints : any = {"echoCancellation" : true};
        if (WebrtcService.isiOS() === false) {
            audioConstraints["autoGainControl"] = true;
            audioConstraints["noiseSuppression"] = true;
        }

        audioConstraints.deviceId = {exact: this.state.audioInputDeviceId};

        return audioConstraints;
    }

    handleCloseDeviceSelect = () => {
        // 次回以降、デバイス選択画面を表示しないようにするため
        // 選択されたデバイスをlocalstorage に保存する
        if (this.videoInputDeviceInfoList[this.currentVideoDeviceIdIndex]) {
            localStorage.setItem("videoDeviceId", this.state.videoDeviceId);
            localStorage.setItem("videoDeviceLabel", this.videoInputDeviceInfoList[this.currentVideoDeviceIdIndex].label);
        } else {
            localStorage.setItem("videoDeviceId", "");
            localStorage.setItem("videoDeviceLabel", "");
        }
        if (this.audioInputDeviceInfoList[this.currentAudioInputDeviceIdIndex]) {
            localStorage.setItem("audioInputDeviceId", this.state.audioInputDeviceId);
            localStorage.setItem("audioInputDeviceLabel", this.audioInputDeviceInfoList[this.currentAudioInputDeviceIdIndex].label);
        } else {
            localStorage.setItem("audioInputDeviceId", "");
            localStorage.setItem("audioInputDeviceLabel", "");
        }
        if (this.audioOutputDeviceInfoList[this.currentAudioOutputDeviceIdIndex]) {
            localStorage.setItem("audioOutputDeviceId", this.state.audioOutputDeviceId);
            localStorage.setItem("audioOutputDeviceLabel", this.audioOutputDeviceInfoList[this.currentAudioOutputDeviceIdIndex].label);
        } else {
            localStorage.setItem("audioOutputDeviceId", "");
            localStorage.setItem("audioOutputDeviceLabel", "");
        }

        this.setState({
            isOpenDeviceSelect: false,
        });
        WebrtcService.setDeviceSelected(true);
        this.stopPreviewAudio();
        this.stopPreviewVideo();
    }

    stopPreviewVideo = () => {
        try {
            const previewVideo = (document.getElementById("previewVideo") as HTMLVideoElement);
            if (previewVideo === null || previewVideo === undefined) {
                // 要素がない時なにもしない
                return;
            }
            const mediaStream = (previewVideo.srcObject as MediaStream);
            if (mediaStream !== null && mediaStream !== undefined) {
                mediaStream.getTracks().forEach(track => track.stop());
                previewVideo.srcObject = null;
            }
        } catch(e) {
            console.log(e);
            this.traceSend("stopPreviewVideo exception");
        }
    }

    stopPreviewAudio = () => {
        try {
            const previewAudio = (document.getElementById("previewAudio") as HTMLAudioElement);
            if (previewAudio === null || previewAudio === undefined) {
                // 要素がない時なにもしない
                return;
            }
            const mediaStream = (previewAudio.srcObject as MediaStream);
            if (mediaStream !== null && mediaStream !== undefined) {
                mediaStream.getTracks().forEach(track => track.stop());
                previewAudio.srcObject = null;
            }
        } catch(e) {
            console.log(e);
            this.traceSend("stopPreviewAudio exception");
        }
    }

    handleCloseDeviceCheck = () => {
        this.stopPreviewAudio();
        this.stopPreviewVideo();
        if(localStorage.getItem("timeoutSignout_mode") === "1") {
            this.traceSend("handleCloseDeviceCheck skip signout");
            return;
        }
        const hasVideoDeviceId: boolean = this.state.videoDeviceId !== "";
        const hasAudioDeviceId: boolean = this.state.audioInputDeviceId !== "";
        const constraints : any = {video: hasVideoDeviceId, audio: hasAudioDeviceId};

        this.traceLog("SELDEV_DIALOG : handleCloseDeviceCheck start hasVideoDevice=["+hasVideoDeviceId+"] hasAudioDevice=["+hasAudioDeviceId+"]")

        if (hasVideoDeviceId === false && hasAudioDeviceId === false) {
            this.handleCloseDeviceSelect();
            if (this.props.requireCamera || this.props.requireMic) {
                this.props.handleDeviceSelectEnd(false);
                this.traceSend("handleCloseDeviceCheck : showRequireDeviceMessage require=["+this.props.requireCamera+"]["+this.props.requireMic+"]");
                this.traceLog("SELDEV_DIALOG : showRequireDeviceMessage call noDevice ["+this.props.requireCamera+"]["+this.props.requireMic+"]")
                this.showRequireDeviceMessage(this.props.requireCamera, this.props.requireMic);
            } else {
                this.props.handleDeviceSelectEnd(true);
            }
            return;
        }

        if (hasVideoDeviceId) {
            constraints.video = {deviceId : {exact: this.state.videoDeviceId}};
        }

        if (hasAudioDeviceId) {
            constraints.audio = this.getAudioConstraints();
        }

        this.traceLog("SELDEV_DIALOG : getUserMedia call")
        this.setMediaWaitTimer(mediaWaitTimeoutVal, "getUserMedia-03");
        this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            this.clearMediaWaitTimer();
            this.props.showWaiting(1, false, "");    // #827 waiting表示解除
            this.traceLog("SELDEV_DIALOG : getUserMedia end")
            let checkStream = true;
            if (stream.getVideoTracks().length === 0 && this.props.requireCamera) {
                checkStream = false;
            }

            if (stream.getAudioTracks().length === 0 && this.props.requireMic) {
                checkStream = false;
            }
            this.traceLog("SELDEV_DIALOG : checkStream=["+checkStream+"]")

            stream.getTracks().forEach(track => track.stop());
            this.handleCloseDeviceSelect();
            this.props.handleDeviceSelectEnd(checkStream);
            this.traceSend("DeviceSelectEnd checkStream=["+checkStream+"]");
            this.traceSendDeviceData();
            if (checkStream === false) {
                this.traceSend("handleCloseDeviceCheck checkStream false : showRequireDeviceMessage require=["+this.props.requireCamera+"]["+this.props.requireMic+"]");
                this.traceLog("SELDEV_DIALOG : showRequireDeviceMessage call checkStream ["+this.props.requireCamera+"]["+this.props.requireMic+"]")
                this.showRequireDeviceMessage(this.props.requireCamera, this.props.requireMic);    
            }
        }).catch((error: DOMException) => {
            // Error
            this.clearMediaWaitTimer();
            this.props.showWaiting(1, false, "");    // #827 waiting表示解除
            this.traceSend("getUserMedia-03 error : "+getExceptionMsg(error));
            this.traceLog("SELDEV_DIALOG : getUserMedia error ["+error+"]")
            console.error('handleCloseDeviceCheck mediaDevice.getUserMedia() error:', error);
            const requireCheck = this.checkErrorRequireDevice(error.message);
            if (requireCheck) {
                this.traceSend("handleCloseDeviceCheck : Not RequireDevice and error skip");
                this.handleCloseDeviceSelect();
                this.props.handleDeviceSelectEnd(true);
            } else {
                // #294 カメラ使用中などのメッセージ出し方変更
                //this.traceSend("handleCloseDeviceCheck : showGetUserMediaErrorMessage");
                //this.showGetUserMediaErrorMessage(error.message);
                this.handleCloseDeviceSelect();
                this.props.handleDeviceSelectEnd(false);
                if (this.props.requireCamera || this.props.requireMic) {
                    // #294 カメラ使用中などのメッセージ出し方変更
                    //this.traceSend("handleCloseDeviceCheck getUserMedia error : showRequireDeviceMessage require=["+this.props.requireCamera+"]["+this.props.requireMic+"]");
                    //this.traceLog("SELDEV_DIALOG : showRequireDeviceMessage call ["+this.props.requireCamera+"]["+this.props.requireMic+"]")
                    //this.showRequireDeviceMessage(this.props.requireCamera, this.props.requireMic);
                }
                // #294 カメラ使用中などのメッセージ出し方変更
                // デバイス選択画面で出す
                if(this.state.isOpenDeviceSelect === false){
                    this.setState({isOpenDeviceSelect: true});
                }
            }
        });

    }

    handlePreviewDeviceSelect = () => {
        this.stopPreviewAudio();
        this.stopPreviewVideo();
        const hasVideoDeviceId: boolean = this.state.videoDeviceId !== "";
        const hasAudioDeviceId: boolean = this.state.audioInputDeviceId !== "";
        const constraints : any = {video: hasVideoDeviceId, audio: hasAudioDeviceId};

        this.traceLog("SELDEV_DIALOG : handlePreviewDeviceSelect start hasVideoDeviceId=["+hasVideoDeviceId+"] hasAudioDeviceId=["+hasAudioDeviceId+"]")

        if (hasVideoDeviceId === false && hasAudioDeviceId === false) {
            this.traceLog("SELDEV_DIALOG : handlePreviewDeviceSelect ret all false")
            return;
        }

        if (hasVideoDeviceId) {
            constraints.video = {deviceId : {exact: this.state.videoDeviceId}};
        }

        if (hasAudioDeviceId) {
            constraints.audio = this.getAudioConstraints();
        }

        this.traceLog("SELDEV_DIALOG : handlePreviewDeviceSelect getUserMedia call")
        this.setMediaWaitTimer(mediaWaitTimeoutVal, "getUserMedia-04");
        this.props.showWaiting(1, true, "ログイン中");    // #827 waiting表示
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            this.clearMediaWaitTimer();
            this.props.showWaiting(1, false, "");    // #827 waiting表示解除
            this.traceLog("SELDEV_DIALOG : handlePreviewDeviceSelect getUserMedia ret")
            // Success
            this.mediaStream = stream;

            try {
                const audio: HTMLAudioElement = (document.getElementById('previewAudio') as HTMLVideoElement);
                audio.srcObject = stream;            
                if (this.state.audioOutputDeviceId !== "") {
                    (audio as any).setSinkId(this.state.audioOutputDeviceId);
                }
                audio.play();
            } catch(e) {
                console.log(e);
                this.traceSend("handlePreviewDeviceSelect audio.play error : "+getExceptionMsg(e));

                // ここに来るということは多分プレビューに失敗している
                // -> streamを解放して終了する
                this.mediaStream.getTracks().forEach(track => track.stop());
                return;
            }

            try {
                const video: HTMLVideoElement = (document.getElementById('previewVideo') as HTMLVideoElement);
                video.srcObject = stream;
                video.play();
            } catch(e) {
                console.log(e);
                this.traceSend("handlePreviewDeviceSelect video.play error : "+getExceptionMsg(e));

                // ここに来るということは多分プレビューに失敗している
                // -> streamを解放して終了する
                this.mediaStream.getTracks().forEach(track => track.stop());
                return;
            }
        }).catch((error: DOMException) => {
            // Error
            this.clearMediaWaitTimer();
            this.props.showWaiting(1, false, "");    // #827 waiting表示解除
            this.traceSend("getUserMedia-04 error : "+getExceptionMsg(error));
            this.traceLog("SELDEV_DIALOG : handlePreviewDeviceSelect getUserMedia error:["+error.message+"]")
            console.error('handlePreviewDeviceSelect mediaDevice.getUserMedia() error:', error);
            const requireCheck = this.checkErrorRequireDevice(error.message);
            if (requireCheck) {
                this.traceSend("handlePreviewDeviceSelect : Not RequireDevice and error skip");
                this.traceLog("SELDEV_DIALOG : Not requireDevuce error then error skip")
            } else {
                this.traceSend("handlePreviewDeviceSelect : showGetUserMediaErrorMessage");
                this.showGetUserMediaErrorMessage(error.message);
            }
        });
    }

    handleChangeVideoSelect = (event: React.ChangeEvent<{value : unknown}>) => {
        const deviceId = String(event.target.value);
        localStorage.setItem("videoDeviceId", deviceId);

        for (let i = 0; i < this.videoInputDeviceInfoList.length; i++) {
            if (this.videoInputDeviceInfoList[i].deviceId === deviceId) {
                this.currentVideoDeviceIdIndex = i;
                localStorage.setItem("videoDeviceLabel", this.videoInputDeviceInfoList[i].label);
                break;
            }
        }

        this.setState({
            videoDeviceId: deviceId,
        });
    }

    handleChangeAudioInputSelect = (event: React.ChangeEvent<{value : unknown}>) => {
        const audioDeviceId = String(event.target.value);
        localStorage.setItem("audioInputDeviceId", audioDeviceId);

        for (let i = 0; i < this.audioInputDeviceInfoList.length; i++) {
            if (this.audioInputDeviceInfoList[i].deviceId === audioDeviceId) {
                this.currentAudioInputDeviceIdIndex = i;
                localStorage.setItem("audioInputDeviceLabel", this.audioInputDeviceInfoList[i].label);
                break;
            }
        }

        this.setState({
            audioInputDeviceId: audioDeviceId,
        });
    }

    handleChangeAudioOutputSelect = (event: React.ChangeEvent<{value : unknown}>) => {
        const audioDeviceId = String(event.target.value);
        localStorage.setItem("audioOutputDeviceId", audioDeviceId);
        this.setState({audioOutputDeviceId: audioDeviceId});
    }

    checkedSetSinkIdMethod = false;
    hasSetSinkIdMethod = false;
    checkSetSinkIdMethod = () => {
        if (this.checkedSetSinkIdMethod) {
            return this.hasSetSinkIdMethod;
        }

        const video = document.getElementById("dummyVideo") as any;
        if (video) {
            this.checkedSetSinkIdMethod = true;

            if (video.setSinkId) {
                this.hasSetSinkIdMethod = true;
            }
        }

        return this.hasSetSinkIdMethod;
    }

    /**
     * マイクデバイスのIDを取得
     * SafariでデバイスIDがブラウザを起動しなおすたびに代わることに対する対応用の処理
     * 
     * @param deviceId 
     */
    private getAudioDeviceId = (deviceId: string|null) => {
        if (deviceId) {
            for (let i = 0; i < this.audioInputDeviceInfoList.length; i++) {
                if (deviceId === this.audioInputDeviceInfoList[i].deviceId) {
                    return deviceId;
                }
            }
    
            const audioDeviceLabel = localStorage.getItem("audioInputDeviceLabel");
            if (audioDeviceLabel && audioDeviceLabel !== "") {
                for (let i = 0; i < this.audioInputDeviceInfoList.length; i++) {
                    if (audioDeviceLabel === this.audioInputDeviceInfoList[i].label) {
                        const audioDeviceId = this.audioInputDeviceInfoList[i].deviceId;
                        localStorage.setItem("audioInputDeviceId", audioDeviceId);
                        this.currentAudioInputDeviceIdIndex = i;
                        return audioDeviceId;
                    }
                }
            }
        }
        
        return "";
    }

    /**
     *  トレースログ
     * 
     */
    private traceLog = (msg: string) => {
        let now = new Date().toTimeString();
        console.log(now+" "+msg);
    }

    /**
     *  トレースログ:サーバーへ送信
     * 
     */
    private traceSend = (msg: string) => {
        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append('tracemsg', msg);
        // axios.post('/api/user/log/tracelog', params)
        // .then((e: AxiosResponse) => {
        // }).catch((e) => {
        //     this.traceLog("traceSend error:"+e)
        // });

        this.httpClient.sendTraceLog(sessionStorage.getItem("TABID") as string, msg)
            .then((e) => {
            }).catch((e) => {
                this.traceLog("traceSend error:"+e);
            });
    }

    /**
     *  waitタイマーセット
     */
    private setMediaWaitTimer = (val: number, item: String) => {
        this.mediaWaitTimerId = window.setTimeout(() => this.handleWaitTimeout(), val);
        this.mediaWaitStartItem = item;
        this.mediaWaitStartDate = new Date();
    }

    /**
     *  waitタイマークリア
     */
     private clearMediaWaitTimer = () => {
        if(this.mediaWaitTimerId != 0) {
            clearTimeout(this.mediaWaitTimerId);
            this.mediaWaitTimerId = 0;

            // かかった時間をログ出力（そのうち消す？）
            let now: Date = new Date();
            let diff = now.getTime() - this.mediaWaitStartDate.getTime();
            this.traceSend("mediaWaitTime : time=["+diff+"] item=["+this.mediaWaitStartItem+"]");
        }
    }

    /**
     *  waitタイムアウト
     */
    private handleWaitTimeout = () => {
        if(this.mediaWaitTimerId != 0) {
            this.mediaWaitTimerId = 0;
            this.traceSend("mediaWaitTimeout : item=["+this.mediaWaitStartItem+"]");
            // #287 デバイス検索・ストリーム取得のタイムアウトメッセージ
            let kind = 0;
            if(this.mediaWaitStartItem.indexOf("enumerateDevices") !== -1) {
                kind = 1;
            } else if(this.mediaWaitStartItem.indexOf("getUserMedia") !== -1) {
                kind = 2;
            }
            this.props.showWaiting(1, false, "");   // #827 waiting表示解除
            this.showDeviceTimeoutMessage(kind);
            this.mediaWaitStartItem = "";
        }
    }

    /**
     *  選択デバイスデータをサーバーへ送信
     */
     private traceSendDeviceData = () => {
        let vDevId = localStorage.getItem("videoDeviceId");
        let vDevNm = localStorage.getItem("videoDeviceLabel");
        let iDevId = localStorage.getItem("audioInputDeviceId");
        let iDevNm = localStorage.getItem("audioInputDeviceLabel");
        let oDevId = localStorage.getItem("audioOutputDeviceId");
        let oDevNm = localStorage.getItem("audioOutputDeviceLabel");
        this.traceSend("--- videoDevice id=["+vDevId+"] label=["+vDevNm+"] : audioDevice id=["+iDevId+"] label=["+iDevNm+"] : outputDevice id=["+oDevId+"] label=["+oDevNm+"]");
    }

    /**
     * 必須デバイス設定かどうかを確認し、
     * エラーが発生した原因が必須デバイスでない場合、trueを返す
     * 
     * @param errorMessage 
     */
    private checkErrorRequireDevice = (errorMessage: string) : boolean => {
        this.traceLog("SELDEV_DIALOG : checkErrorRequireDevice msg=["+errorMessage+"] requireCamera=["+this.props.requireCamera+"] requireMic=["+this.props.requireMic+"]")
        if (this.props.requireCamera !== true && this.props.requireMic !== true ) {
            return true;
        }

        if (errorMessage && errorMessage.indexOf) {
            if (this.props.requireCamera !== true 
                    && (errorMessage.indexOf("Could not start video source") >= 0 
                    || errorMessage.indexOf("Failed to allocate videosource") >= 0)) {
                // カメラが必須ではなく
                // カメラが別のアプリで使用されている場合など
                return true;
            } else if ( this.props.requireMic !== true
                    && errorMessage.indexOf("Could not start audio source") >= 0 
                    || errorMessage.indexOf("Failed to allocate audiosource") >= 0) {
                // マイクが必須ではなく
                // マイクが別のアプリで使用されている場合など
                // マイクは、別アプリと共用できる可能性があるため、このエラーは発生しないかも
                return true;
            }
        }

        return false;
    }

    private showRequireDeviceMessage = (requireCamera: boolean, requireMic: boolean) => {
        let title = "デバイスが見つかりませんでした";
        let message = "";
        // #11563 カメラ/マイク必須フロアは「未選択」を出さない
        //367
        //if(this.state.videoDeviceId === "") {
        //    title = "デバイスが選択されていません";
        //    // #294 カメラ使用中などのメッセージ出し方変更
        //    //message = "デバイスを選択してください。";
        //    message = "以下の点を確認してください。"
        //        + "\n・ご使用の機器にカメラとマイクが搭載されているか"
        //        + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
        //        + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
        //    console.log(title);
        //} else {
            if (requireCamera && requireMic) {
                // カメラ、マイクが必須
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
            } else if (requireCamera) {
                // カメラが必須
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラの使用が許可されているか";
            } else if (requireMic) {
                // マイクが必須
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でマイクの使用が許可されているか";
            } else {
                this.traceLog("SELDEV_DIALOG : showRequireDeviceMessage msg null")
            }
        //}
        this.traceSend("showRequireDeviceMessage title=["+title+"] message=["+message+"]");
        this.props.showMessage(title, message);        
    }

    private showGetUserMediaErrorMessage = (errorMessage: string) => {
        let title = "デバイスが使用中です";
        let message = "";
        if (errorMessage && errorMessage.indexOf) {
            if (errorMessage.indexOf("Could not start video source") >= 0 || errorMessage.indexOf("Failed to allocate videosource") >= 0) {
                // カメラが別のアプリで使用されている場合など
                message = "カメラを使用しているアプリケーションを終了後、再度やり直してください。";
            } else if (errorMessage.indexOf("Could not start audio source") >= 0 || errorMessage.indexOf("Failed to allocate audiosource") >= 0) {
                // マイクが別のアプリで使用されている場合など
                // マイクは、別アプリと共用できる可能性があるため、このエラーは発生しないかも
                message = "マイクを使用しているアプリケーションを終了後、再度やり直してください。";
            } else if (errorMessage.indexOf("device not found") >= 0 || errorMessage.indexOf("Permission denied") >= 0) {
                // 指定されたデバイスが見つからない場合
                title = "デバイスが見つかりませんでした";
                // 367
                if(this.props.requireCamera && this.props.requireMic) {
                    message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
                } else if(this.props.requireCamera) {
                    message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラの使用が許可されているか";
                } else if(this.props.requireMic) {
                    message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でマイクの使用が許可されているか";
                } else {
                    message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
                }
            } else {
                title = "デバイスが見つかりませんでした";
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
                console.log(errorMessage);
            }
        } else {
            // #11563 カメラ/マイク必須フロアは「未選択」を出さない
            //367
            //if(this.state.videoDeviceId === "") {
            //    title = "デバイスが選択されていません";
            //    // #294 カメラ使用中などのメッセージ出し方変更
            //    //message = "デバイスを選択してください。";
            //    message = "以下の点を確認してください。"
            //        + "\n・ご使用の機器にカメラとマイクが搭載されているか"
            //        + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
            //        + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
            //    console.log(errorMessage);
            //} else {
                title = "デバイスが見つかりませんでした";
                message = "以下の点を確認してください。"
                    + "\n・ご使用の機器にカメラとマイクが搭載されているか"
                    + "\n・外付けのデバイスをご使用の場合は、正常に接続されているか"
                    + "\n・ブラウザの設定でカメラとマイクの使用が許可されているか";
                console.log(errorMessage);
            //}
        }

        this.traceSend("showGetUserMediaErrorMessage title=["+title+"] message=["+message+"]");
        this.traceLog("SELDEV_DIALOG : showGetUserMediaErrorMessage showMessage call title=["+title+"] msg=["+message+"]")
        this.props.showMessage(title, message);
    }

    //
    // #287 デバイス検索・ストリーム取得のタイムアウトメッセージ
    // kind = 1 : enumerateDevices
    // kind = 2 : getUserMedia
    private showDeviceTimeoutMessage = (kind: number) => {
        let title = "デバイスの情報を取得できません";
        if(kind === 2) {
            title = "デバイスの読み込みができません";
        }
        let message = "この症状が続く場合は、ご使用の端末を再起動してください";
        this.traceSend("showDeviceTimeoutMessage title=["+title+"] message=["+message+"]");
        this.props.showTmotMessage(title, message);        
    }

    renderOptionAudioInputSelect() {
        const items = this.audioInputDeviceInfoList.map((target) => 
            <option key={target.deviceId} value={target.deviceId}>{target.label}</option>
        );

        return items;
    }

    renderOptionAudioOutputSelect() {
        const items = this.audioOutputDeviceInfoList.map((target, index) => 
            <option key={target.deviceId} value={target.deviceId}>{target.label === "" ? "スピーカー" + (index + 1) : target.label}</option>
        );

        return items;
    }

    renderOptionVideoSelect() {
        const items = this.videoInputDeviceInfoList.map((target) => 
            <option key={target.deviceId} value={target.deviceId}>{target.label}</option>
        );

        return items;
    }

    // #11563 カメラ/マイク必須フロアは「未選択」を出さない
    renderOptionNoSelect(dev:number) {
        console.log("renderOptionNoSelect : dev=["+dev+"] requireCamera=["+this.props.requireCamera+"] requireMic=["+this.props.requireMic+"]")
        let noSelect: JSX.Element[] = [];
        if((dev === 1 && !this.props.requireCamera) || (dev === 2 && !this.props.requireMic)) {
            //noSelect[0] = <option key="" value="">未選択</option>
            noSelect[0] = <option key="" value="">なし</option>
        } else {
            if(dev === 1) {
                if(this.state.videoDeviceId === "" && this.videoInputDeviceInfoList.length > 0) {
                    localStorage.setItem("videoDeviceId", this.videoInputDeviceInfoList[0].deviceId);
                    localStorage.setItem("videoDeviceLabel", this.videoInputDeviceInfoList[0].label);
                    this.currentVideoDeviceIdIndex = 0;
                    this.setState({
                        videoDeviceId: this.videoInputDeviceInfoList[0].deviceId,
                    });
                }
            } else if(dev === 2) {
                if(this.state.audioInputDeviceId === "" && this.audioInputDeviceInfoList.length > 0) {
                    localStorage.setItem("audioInputDeviceId", this.audioInputDeviceInfoList[0].deviceId);
                    localStorage.setItem("audioInputDeviceLabel", this.audioInputDeviceInfoList[0].label);
                    this.currentAudioInputDeviceIdIndex = 0;
                    this.setState({
                        audioInputDeviceId: this.audioInputDeviceInfoList[0].deviceId,
                    });
                }
            }
        }
        console.log("renderOptionNoSelect ret: ["+noSelect+"]")
        return noSelect;
    }

    renderDeviceDialog() {
        const { classes } = this.props;

        const item = 
            <BaseDialog
                disableBackdropClick
                disableEscapeKeyDown 
                open={this.state.isOpenDeviceSelect}
                onClose={this.handleCloseDeviceSelect}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle style={{background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>カメラマイク設定</DialogTitle>
                <DialogContent>
                    <div>
                    <video id="previewVideo"
                        width={controlVideoWidth} 
                        height={controlVideoHeight} 
                        playsInline
                        autoPlay 
                        muted
                        disablePictureInPicture
                        style={{
                            boxSizing: 'border-box',
                            borderRadius: '50%',
                            border:"solid 1px #000", 
                            boxShadow: '1px 1px 2px 2px rgba(0,0,0,0.2)',
                            objectFit: 'cover',
                        }}
                    />
                    <audio id="previewAudio" />
                    </div>
                    <div>
                    <br/>
                    <FormControl className={classes.formControl}>
                        カメラ選択
                        <Select
                            native
                            value={this.state.videoDeviceId}
                            onChange={this.handleChangeVideoSelect}
                            onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                            onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                            variant="outlined"
                        >
                            {/*// #11563 カメラ必須フロアは「未選択」を出さない
                            <option key="" value="">未選択</option>
                            */}
                            {this.renderOptionNoSelect(1)}
                            {this.renderOptionVideoSelect()}
                        </Select>
                    </FormControl>
                    </div>
                    <div>
                    <br/>
                    <FormControl className={classes.formControl}>
                        マイク選択
                        <Select
                            native
                            value={this.state.audioInputDeviceId}
                            onChange={this.handleChangeAudioInputSelect}
                            onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                            onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                            variant="outlined"
                        >
                            {/*// #11563 マイク必須フロアは「未選択」を出さない
                            <option key="" value="">未選択</option>
                            */}
                            {this.renderOptionNoSelect(2)}
                            {this.renderOptionAudioInputSelect()}
                        </Select>
                    </FormControl>
                    </div>
                    {this.checkSetSinkIdMethod() ?
                    <div>
                    <br/>
                    <FormControl className={classes.formControl}>
                        スピーカー選択
                        <Select
                            native
                            value={this.state.audioOutputDeviceId}
                            onChange={this.handleChangeAudioOutputSelect}
                            onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                            onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                            variant="outlined"
                        >
                        {Object.keys(this.audioOutputDeviceInfoList).length > 0 ? 
                            this.renderOptionAudioOutputSelect()
                            : <option key="" value="">デフォルト</option>}
                        </Select>
                    </FormControl>
                    </div>
                    : ""}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDeviceCheck} 
                        onMouseDown={Utility.forbiddenTattle} onMouseUp={Utility.forbiddenTattle}
                        onTouchStart={Utility.forbiddenTattle} onTouchEnd={Utility.forbiddenTattle}
                        color="primary" style={{color: '#006FBC'}}>
                        閉じる
                    </Button>
                </DialogActions>
                <DialogContent style={{color: 'rgb(0,0,0,0.54)'}}>
                    ※動作環境については<a href={this.state.usermanualURL} target="_blank" rel="noopener noreferrer" style={{color: '#006FBC'}}>「FAMcampusユーザーマニュアル」</a>を参照ください。
                </DialogContent>
            </BaseDialog>

        return item;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.rootDiv}>
                {this.state.isOpenDeviceSelect ? this.renderDeviceDialog() : ""}
                <video id="dummyVideo" hidden={true}></video>
            </div>
        )
    }
}

export default withStyles(styles)(SelectDeviceDialog);


/** 定数定義 */
export var userListConst = {
  /** ログインID */
  loginId: {
    minLength: 4,
    maxLength: 60,
    format: "^([a-zA-Z0-9])+([a-zA-Z0-9\\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\\._-]+)+$",
    format1: "^([a-zA-Z0-9]).+$",
    format2: "^(?=.*?@).+$",
    format3: "^.+@(?=.*?\\.).+$",
    format4: "^.+@([a-zA-Z0-9_-]).+$",
    format5: "^([a-zA-Z0-9\\._-@])+$",
  },
  /** 姓 */
  lastName: {
    minLength: 1,
    maxLength: 50,
    format: "",
  },
  /** 名 */
  firstName: {
    minLength: 1,
    maxLength: 50,
    format: "",
  },
  /** 表示名 */
  displayName: {
    minLength: 1,
    maxLength: 8,
    format: "",
  },
  /** パスワード */
  password: {
    minLength: 8,
    maxLength: 32,
    //format: "^(?=.*?[a-z])(?=.*?\\d)(?=.*?[!-\\/:-@[-`{-~])[!-~]{8,32}$",
    format: "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\\d)[a-zA-Z\\d]{8,32}$",
    format1: "^(?=.*?[a-z]).{8,32}$",
    format2: "^(?=.*?[A-Z]).{8,32}$",
    format3: "^(?=.*?\\d).{8,32}$",
  },
  /** タグ */
  tag: {
    /** key */
    key: {
      minLength: 1,
      maxLength: 50,
      format: "",
    },
    /** value */
    value: {
      minLength: 0,
      maxLength: 255,
      format: "",
    },
    /** order */
    order: {
      minLength: 1,
      maxLength: 9,
      format: "^([1-9][0-9]*)$",
    },
    /** max number */
    maxNumber: 10,
  },
  /** 出退勤時連絡先 */
  contactAddress: {
    /** name */
    name: {
      minLength: 1,
      maxLength: 50,
      format: "",
    },
    /** value */
    value: {
      minLength: 4,
      maxLength: 60,
      format: "^([a-zA-Z0-9])+([a-zA-Z0-9\\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\\._-]+)+$",
      format1: "^([a-zA-Z0-9]).+$",
      format2: "^(?=.*?@).+$",
      format3: "^.+@(?=.*?\\.).+$",
      format4: "^.+@([a-zA-Z0-9_-]).+$",
      format5: "^([a-zA-Z0-9\\._-@])+$",
    },
    /** max number */
    maxNumber: 10,
  },
  /** テーブル表示 */
  table: {
    rowsPerPage: 25,
  },
  /** upload */
  upload: {
    errorCount: 1000,
    div: "C",
    div2: "U"
  },
  /** リクエストエラー時のメッセージ定義 */
  requestError: {
    code: "0098", 
    message: "通信エラーが発生しました。ネットワーク環境を確認して下さい。", 
    errorDetail: []
  },
  responseError403: {
    code: "0097", 
    message: "セッションが無効になりました。サインインし直して下さい。", 
    errorDetail: []
  },
  unexpectedMessage: "予期せぬエラーが発生しました。画面を再読み込みして下さい。", 
  csvDataOverLimitMessage: { 
    code: "",
    message: "ユーザCSVのデータ件数が上限を超過しています。(データ件数=1,001,上限件数=1,000)",
    errorDetail: []
  },

  csvDataValidateErrorMessage: {
    code: "",
    message: "サインインIDの重複エラーです。",
    errorDetail: []
  },
  
  csvDataConflictMessage: {
    code: "",
    message: "ユーザCSVの入力/データエラーです。",
    errorDetail: []
  },

  response0607Message: {
    code: "",
    message: "システム管理者は削除できません。",
    errorDetail: []
  },

  responseEmptyMessage: { 
    code: "",
    message: "",
    errorDetail: []
  },
};

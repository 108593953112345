import ZIndex from '../../common/ZIndex';

export interface DisplayUserInfo {
    id: number;
    displayName: string;
    state: number;
    webRtcRoomId: string;
    isZoomSpeakable: boolean;
    zoomMeetingId: number;
}

export const frameSettingBase = {
    parentElementId: 'floorContainer',
    zIndex: ZIndex.experiment,
    enableResizing: true,//false,
    disableDragging: false,//true,
}

/**
 * For Sort
 */
export enum SortType {
    NAME_ASC,
    NAME_DEC,
    RAISED_HAND_ASC,
    RAISED_HAND_DEC,
    FAM_MEET_ASC,
    FAM_MEET_DEC,
    ZOOM_DEVICE_ASC,
    ZOOM_DEVICE_DEC,
}

export const sortOptions = [
    { text: '名前↑', value: SortType.NAME_ASC },
    { text: '名前↓', value: SortType.NAME_DEC },
    { text: '挙手↑', value: SortType.RAISED_HAND_ASC },
    { text: '挙手↓', value: SortType.RAISED_HAND_DEC },
    { text: 'ミート↑', value: SortType.FAM_MEET_ASC },
    { text: 'ミート↓', value: SortType.FAM_MEET_DEC },
    { text: 'デバイス↑', value: SortType.ZOOM_DEVICE_ASC },
    { text: 'デバイス↓', value: SortType.ZOOM_DEVICE_DEC },
];


/**
 * For Filter
 */
export const RAISED_HAND_STATE_NUMBER = 30;

export enum RaisedHand { ALL, YES, NO }

export const raisedHandOptions = [
    { text: 'all', value: RaisedHand.ALL },
    { text: 'あり', value: RaisedHand.YES },
    { text: 'なし', value: RaisedHand.NO },
];

export enum FamMeet { ALL, OPEN, TELE, WHOLE }

export const famMeetOptions = [
    { text: 'all', value: FamMeet.ALL },
    { text: 'ミート', value: FamMeet.OPEN },
    { text: '会議', value: FamMeet.TELE },
    //{ text: '全体放送', value: FamMeet.WHOLE },
]

export enum ZoomDevice { ALL, YES, NO }

export const zoomDeviceOptions = [
    { text: 'all', value: ZoomDevice.ALL },
    { text: 'on', value: ZoomDevice.YES },
    { text: 'off', value: ZoomDevice.NO },
];




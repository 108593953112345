import { Box, Button, TextField } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import React, { Fragment, useState } from 'react';
import JfsClient, {UserInfoUpdateFormData} from '@fsi/jfs-sdk';


export default function NewUpdateUser() {
    const { history } = useReactRouter();

    const [addButton, setAddButton] = useState(true);

    const [state, setState] = useState({
        subId: ""as string,
        id: "" as string,
        password: "" as string,
        lastName: "" as string,
        firstName: "" as string,
        nickname: "" as string,
        confirmPassword : "" as string,
        item : "" as string,
        value : "" as string,
        isResetPassword: false as boolean
    })

    const jfsClient = JfsClient.getInstance();
    const { httpClient, JFS_ERRORS } = jfsClient;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        setState({ ...state, [name]: event.target.value });
    }

    const registUser = () => {
        setAddButton(false);
        // axios.put('/api/v1/admin/users/' + state.subId, {
        //     loginId : state.id,
        //     firstName : state.firstName,
        //     lastName : state.lastName,
        //     displayName : state.nickname,
        //     password : state.password,
        //     confirmPassword : state.confirmPassword,
        //     tabId : sessionStorage.getItem("TABID") as string,
        //     isResetPassword : state.isResetPassword,
        //     tags : [{
        //         item : state.item,
        //         value: state.value
        //     }]
        // })
        //     .then((e: AxiosResponse) => {
        //         history.push({
        //             pathname: "/top",
        //         });
        //     }).catch(err => {
        //         alert(err.response.data)
        //         setAddButton(true);
        //     });

        const data = new UserInfoUpdateFormData();
        data.loginId = state.id;
        data.firstName = state.firstName;
        data.lastName = state.lastName;
        data.displayName = state.nickname;
        data.password = state.password;
        data.confirmPassword = state.confirmPassword;
        data.tabId = sessionStorage.getItem("TABID") as string;
        data.isResetPassword = state.isResetPassword;
        data.tags = [{
            item : state.item,
            value: state.value,
            profileOrder: 0
        }];

        httpClient.updateUserInfo(data)
            .then((e: string) => {
                history.push({
                    pathname: "/top",
                });
            }).catch(err => {
                alert(err.response.data)
                setAddButton(true);
            });
    }

    return (
        <Fragment>

            <div style={{ padding: 10, textAlign: "left" }}>
                <form noValidate autoComplete="off">
                    <div>
                        <TextField
                            variant="standard"
                            id="subId"
                            label="subId"
                            name="subId"
                            value={state.subId}
                            onChange={handleChange}
                            inputProps={{
                                autocomplete: 'new-regist-id',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="id"
                            label="メールアドレス"
                            name="id"
                            value={state.id}
                            onChange={handleChange}
                            inputProps={{
                                autocomplete: 'new-regist-id',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="password"
                            label="パスワード"
                            name="password"
                            value={state.password}
                            type="password"
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="confirmPassword"
                            label="確認用パスワード"
                            name="confirmPassword"
                            value={state.confirmPassword}
                            type="password"
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="lastName"
                            label="姓"
                            name="lastName"
                            value={state.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="firstName"
                            label="名"
                            name="firstName"
                            value={state.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="nickname"
                            label="表示名"
                            name="nickname"
                            value={state.nickname}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="item"
                            label="キー"
                            name="item"
                            value={state.item}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="value"
                            label="値"
                            name="value"
                            value={state.value}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                </form>
                <div>
                    <Button disabled={!addButton} onClick={registUser} variant="contained" color="primary">登録</Button>
                    <p>※管理者権限を持っているユーザーのみ更新可能です</p>
                </div>
            </div>
        </Fragment>
    )
}


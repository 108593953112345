import React, { Fragment, useMemo, useEffect, useRef } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';

// import { User } from '../common/JsonClass';
import { calcAvatarWidth, getAvatarVideoSize } from '../common/AvatarSize';
import { SELECT_NONE } from "../common/Constants";
import { SelectMemoGroup, SelectMemoGroupHandler } from './SelectMemoGroup';
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';

interface Props {
    className?: string;
    open: boolean;
    user: User;// otherUserの想定
    zIndex: number;
    isLarge: boolean;
    onClickClose: () => void;
    handleOpenMemo: (groupId: number, groupName: string) => void;
    kind: null | string;
}

const SelectMemoGroupCard: React.FC<Props> = (props) => {
    const selectMemoGroupRef = useRef({} as SelectMemoGroupHandler);

    // avatar要素の幅
    const avatarWidth = useMemo(() => {
        return calcAvatarWidth(props.user.width, props.isLarge);
    }, [props.user.width, props.isLarge]);

    // avatarの映像サイズ
    const avatarVideoHeight = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.9 * getAvatarVideoSize(props.user.isLargeVideo)  // 0.9は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    const avatarVideoWidth = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.4 * getAvatarVideoSize(props.user.isLargeVideo) // 0.4は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    /**
     * useEffect
     */
    // open時データ取得
    useEffect(() => {
        if (props.open) {
            selectMemoGroupRef.current?.getDataAndDisplay();
        }
    }, [props.open])

    // ユーザーが移動したら閉じる
    useEffect(() => {
        if(props.kind != 'myroom'){
            props.onClickClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.x, props.user.y])

    /**
     * event handlers
     */
    const handleClickRefresh = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.open) {
            selectMemoGroupRef.current?.getDataAndDisplay();
        }
    }

    const handleClickClose = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClickClose();
    }

    /**
     * draw elements
     */
    const drawCardHeader = useMemo(() => {
        return (
            <CardHeader
                className={props.className}
                title="メモ"
                titleTypographyProps={{ variant: "body1", component: "h2" }}
                style={{ padding: 4, textAlign: "left" }}
                action={
                    <Fragment>
                        <IconButton size="small" onClick={handleClickRefresh}>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton size="small" onClick={handleClickClose}>
                            <CloseIcon />
                        </IconButton>
                    </Fragment>
                }
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const drawCardContent = useMemo(() => {
        return (
            <CardContent
                className={props.className}
                style={{
                    padding: 0,
                    width: 150,
                    minHeight: 100,
                    maxHeight: 200,
                    maxWidth: 345,
                    overflow: "auto"
                }}
            >
                <SelectMemoGroup
                    ref={selectMemoGroupRef}
                    subId={props.user.subId}
                    handleCloseParent={props.onClickClose}
                    handleOpenMemo={props.handleOpenMemo}
                />
            </CardContent>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.subId]);

    const component = useMemo(() =>
        props.open &&
        <ClickAwayListener onClickAway={handleClickClose}>
            <div>
                <Card
                    className={props.className}
                    style={{
                        ...SELECT_NONE,
                        position: 'absolute',
                        top: props.user.y - avatarVideoHeight,
                        left: props.user.x + avatarWidth + 10 + avatarVideoWidth,
                        opacity: 0.90,
                        zIndex: props.zIndex,
                    }}
                >
                    {drawCardHeader}
                    {drawCardContent}
                </Card >
            </div>
        </ClickAwayListener>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.open, props.user.x, props.user.y, avatarWidth]
    );

    return (
        <Fragment>
            {component}
        </Fragment>
    )
}

export default SelectMemoGroupCard;
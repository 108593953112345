import React, { Fragment, useMemo } from 'react';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import ClearIcon from '@material-ui/icons/Clear';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SELECT_NONE } from "../common/Constants";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

interface Props {
    open: boolean,
    tubuyaki: string,
    onChange: any
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    backgroundColor: 'rgba(0, 0, 255, 1)',
};

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC243",
        },
    },
})

export default function Tubuyaki(props: Props) {
    const MAX_LENGTH = 35;
    const [tubuyaki, setTubuyaki] = React.useState(props.tubuyaki);
    const [tempTubuyaki, setTempTubuyaki] = React.useState(props.tubuyaki);
    //const [composing, setComposing] = React.useState(false); // 日本語変換中フラグ

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
        
        return len
    };
    // const textLength = useMemo(() => tubuyaki.trim().replace(/\n/g, "").length, [tubuyaki]);
    const textLength = judgehz( tubuyaki.trim().replace(/\n/g, "") );

    const handleChange = (event: any) => {
        const _text = textLength >= MAX_LENGTH ? event.target.value.trim() : event.target.value;
        let subStr1 = _text.substring(0, MAX_LENGTH*2);     // すべて半角だった場合、最大文字数は35*2の70文字
        setTubuyaki(subStr1);
    }

    const handleOK = () => {
        const _text = tubuyaki.trim();
        if (textLength > MAX_LENGTH) {
            setTubuyaki(_text);
            return;
        }
        props.onChange(true, _text);
    }

    const handleDelete = () => {
        props.onChange(false, tubuyaki.trim());
    };

    const handleCansel = () => {
        props.onChange(true, tempTubuyaki.trim());
    };

    const handleClear = () => {
        setTubuyaki('');
    };

    const handleKeyDownTextField = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!event.nativeEvent.isComposing && event.code.includes("Enter")) {
            handleOK();
        }
    };

    const handleRequestClose = (event: React.MouseEvent | React.KeyboardEvent) => {
        // TODO: ダイアログ外をクリックした際、キーによって制御を変えたい場合はこの中を修正する
        handleDelete()
    }

    const textLengthCheck = useMemo(() => {
        if (textLength > MAX_LENGTH) {
            return (
                <div style={{ color:"red" }}>入力した文字列が長すぎます。</div>
            );
        }
    }, [textLength]);

    const progressBar = useMemo(() => {
        let parsent = (textLength/MAX_LENGTH) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';

        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLengthCheck}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[tubuyaki])

    const draw = useMemo(() => {
        return (
            <BaseDialog
                id="tubuyakiDialog"
                open={props.open}
                onClose={handleRequestClose}
                aria-labelledby="tubuyakiDialogTitle"
                fullWidth={true}
                maxWidth={'xs'}
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="tubuyakiDialogTitle" style={{ padding: "7px 25px 9px 25px", userSelect: 'none' , background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}} >つぶやく</DialogTitle>
                <DialogContent style={{ padding: "5px 25px 0px 25px", }} onClick={() => { (document.getElementById('tubuyakiTextField') as HTMLTextAreaElement).focus() }} >
                    <DialogContentText style={{ marginBottom: "0px" }} >
                        {progressBar}
                    </DialogContentText>
                    <div style={{color: '#707070'}}>内容</div>
                    <TextField
                        id="tubuyakiTextField"
                        //label="内容"
                        variant="outlined"
                        multiline
                        rowsMax={4}
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={tubuyaki}
                        autoComplete={"off"}
                        onChange={handleChange}
                        //onCompositionStart = {() => setComposing(true)} // × 末尾に改行が入り、ダイアログが閉じてしまう
                        //onCompositionEnd = {() => setComposing(false)}  // × 同上
                        //onKeyUp={handleKeyUpTextField}                  // × 変換後、すぐにダイアログが閉じてしまう
                        onKeyDown={handleKeyDownTextField}
                        style={{ pointerEvents: 'auto' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleDelete} color="primary" style={{ pointerEvents: 'auto' }}>消去</Button> */}
                    <Button onClick={handleOK} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110 }}>OK</Button>
                    <Button onClick={handleCansel} color="primary" style={{ pointerEvents: 'auto' , color: '#676767', border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, tubuyaki])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

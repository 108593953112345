import React, { Fragment, useEffect, useState, useMemo, KeyboardEvent, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, useCallback, ReactNode } from 'react'
import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';

import Cookie from 'js-cookie';
import { Utility } from '../common/Utility';
import { DraggableFrame, DraggableFrameHandler } from '../common/DraggableFrame'
import ZIndex from '../common/ZIndex';
//import { ChatGroupData, MemoGroup, Memo as IMemo, Coord, Size, ReadMemoInfo, User, SearchedUser, chatStampInfo } from '../common/JsonClass';
import { ChatGroupData, Memo as IMemo, Coord, Size, ReadMemoInfo, User, AvatarMenuData } from '../common/JsonClass';
import { MEMO_FRAME_BG_COLOR } from '../common/Constants';
import InputAdornment from '@material-ui/core/InputAdornment';//import Typography from '@material-ui/core/Typography';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import Chip from '@material-ui/core/Chip';
import { Button, Menu, MenuItem } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ListAltIcon from '@material-ui/icons/ListAlt';
import WebrtcService from '../webrtc/WebrtcService';
import { JsxEmit } from 'typescript';
import JfsClient, { JfsError, MemoGroup, IMemoGroup, FollowUserStatus, SearchedUser, ChatStampInfo } from '@fsi/jfs-sdk';

const dotColor = '#222'
const useStyles = makeStyles((theme) => ({
    on: {
        color: theme.palette.primary.main,
    },
    off: {
        color: theme.palette.common.white,
    },
    userSearchButtonDiv: {
        height: 60, // memoWindowDivのheightと関連あり
        width: '35%',
        position: 'absolute',
        left: '63%',
        bottom: '0px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign:'center',
    },
    userSearchHeader: {
        height: 40,
        width: 682,
        backgroundColor: '#57BBFF',
        boxShadow: '0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: -15,
        marginLeft: -15,
    },
    userSearchHeaderItem: {
        height: '24px',
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    userSearchBody: {
        width: '100%',
        //height: `calc(100vh - 110px)`,// 80px = header + textbox
        //background: 'rgba(250,250,250,0.36)',
        //position: 'absolute',
        overflow: 'hidden',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column',// 上が最新
    },
    userSelectBody: {
        width: '100%',
        //background: 'rgba(250,250,250,0.36)',
        //position: 'absolute',
        overflow: 'hidden',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column',// 上が最新
    },
    userSearchBodyInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    userSelectedBodyInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 15,
    },
    userGroupListBodyInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 15,
    },
    unClicked: {
        fontWeight: 900,
    },
    clicked: {
        fontWeight: 'normal',
    },
    userWindow: {
        width: '30%',//Utility.isLowScreenResolution() ? '360px' : '360px',
        height: '100%',//Utility.isLowScreenResolution() ? '300px' : '472px',
        position: 'relative',
        userSelect: 'text',
        background: MEMO_FRAME_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: '4px',
    },
    memoWindow: {
        width: '100%',//Utility.isLowScreenResolution() ? '360px' : '360px',
        height: '100%',//Utility.isLowScreenResolution() ? '300px' : '472px',
        position: 'relative',
        userSelect: 'text',
        background: MEMO_FRAME_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: '4px',
    },
    memoWindowHeaderDiv: {
        height: '40px',
        background: 'rgba(250,250,250,0.36)',
        boxShadow: '0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    memoWindowHeaderItem: {
        height: '24px',
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    groupListBodyDiv: {
        width: '30%',
        height: `calc(100% - 84px)`,// この値を変えるとき、inputMemoDiv, sendMemoButtonの調整も必要
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column',// TLを下から上に
    },
    memoWindowDiv: {
        width: '70%',
        height: `calc(100% - ${Utility.isLowScreenResolution() ? 110 : 132}px)`,// この値を変えるとき、inputMemoDiv, sendMemoButtonの調整も必要
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column-reverse',// TLを下から上に
    },
    memoWindowDivForStamp: {
        width: '70%',
        height: `calc(80% - ${Utility.isLowScreenResolution() ? 110 : 132}px)`,// この値を変えるとき、inputMemoDiv, sendMemoButtonの調整も必要
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column-reverse',// TLを下から上に
    },
    memoInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    inputMemoDiv: {
        height: 90, // memoWindowDivのheightと関連あり
        width: '70%',
        position: 'absolute',
        left: '30%',
        bottom: '0px',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'hidden',
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    inputMemoDivForStamp: {
        height: `calc(14.25% + ${Utility.isLowScreenResolution() ? 110 : 132}px)`,
        width: '70%',
        position: 'absolute',
        left: '30%',
        bottom: '0px',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'hidden',
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    stampListDialogDiv: {
        height: '64%',
        width: '97%',
        // position: 'absolute',
        bottom: '0px',
        marginTop: '20px',
        marginLeft: '8px',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column',
    },
    stampListDialogInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    chatStampInStampListDialog: {
        marginLeft: '14px',
        '&:hover': {
            background: '#E9E9E9',
        },
    },
    chatStampPreviewCloseButton: {
        top: 10,
        right: 5,
        position: 'absolute',
        height: '24px',
        padding: 0,
    },
    inputMemo: {
        width: 'calc(100% - 122px)',
        height: 55,
        background: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: 4,
        color: '#A39F98',
        marginTop: 8,
        marginLeft: 8,
        padding: 0,
    },
    sendButton: {
        position: 'absolute',
        padding: 0,
        bottom: 30,// inputMemoDivのheight調整時、こちらの調整も必要
        right: 0,
        width: 50,
        height: 50,
        marginRight: 8,

        background: '#006FBC',
        '&:hover': {
            background: '#107FCC',
        },
        boxShadow: '0 0 6px 0 rgba(0,0,0,0.24)',
        color: '#FFFFFF',
    },
    sendButtonForStamp: {
        position: 'absolute',
        padding: 0,
        top: '4.5%',
        right: 0,
        width: 50,
        height: 50,
        marginRight: 8,

        background: '#006FBC',
        '&:hover': {
            background: '#107FCC',
        },
        boxShadow: '0 0 6px 0 rgba(0,0,0,0.24)',
        color: '#FFFFFF',
    },
    stampButton: {
        position: 'absolute',
        padding: 2,
        bottom: 33,
        right: 65,
        width: 42,
        height: 42,
        borderRadius: '9999px',
        '&:hover': {
            background: '#CCCCCC',
        },
    },
    stampButtonForStamp: {
        position: 'absolute',
        padding: 2,
        top: '7.2%',
        right: 67,
        width: 42,
        height: 42,
        borderRadius: '9999px',
        '&:hover': {
            background: '#CCCCCC',
        },
    },
    inputMemoBorder: {
        padding: 0,
        '&:after': {
            borderWidth: '0px',
        },
        '&:before': {
            borderWidth: '0px',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderWidth: '0px',
        }
    },
    inputMultiline: {
        padding: 8,
    },
    memoUserNickname: {
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
    },
    avatar: {
        color: '#4F4F4F',
        marginLeft: 8,
        marginRight: 8,
    },
    // チャット吹き出し
    balloon1Left: {
        position: 'relative',
        display: 'inline-block',
        padding: '7px 10px',
        minWidth: '120px',
        maxWidth: '80%',
        background: '#F2EDED',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '0 10px 10px 10px',
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
    },
    balloon1Leftp: {
        margin: 0,
        padding: 0,
        wordBreak: 'break-all',
        textAlign: 'left',
    },
    balloon1Right: {
        position: 'relative',
        display: 'inline-block',
        padding: '7px 10px',
        minWidth: '120px',
        maxWidth: '80%',
        background: '#BCF7FF',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '10px 0 10px 10px',
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
        textAlign: 'right',
    },
    // イベント用
    balloon1Center: {
        position: 'relative',
        display: 'inline-block',
        margin: '0.2em',
        padding: '7px 10px',
        minWidth: '70%',
        maxWidth: '70%',
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#555',
        fontSize: '12px',
        background: '#D4EAF4',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '4px',
    },
    rgbIconDiv: {
        backgroundColor: 'red',
        marginTop: '3px',
        height: '20px',
        width: '20px',
        borderRadius: '5px',
        border: '1px solid #555',
    },
    endMessage: {
        color: dotColor,
        alignSelf: 'center',
    },
    loading: {
        color: dotColor,
        alignSelf: 'center',
        '&::after': {
            content: '" ."',
            animation: '$dots 1s steps(5, end) infinite;'
        }
    },
    // loading dot definition
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0,0,0,0)',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '40%': {
            color: dotColor,
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '60%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 rgba(0,0,0,0)`,
        },
        '80%,100%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 ${dotColor}`,
        },
    },
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    alignRight: {
        textAlign: 'right',
    },
    badge: {
        minWidth: '100%',
        maxWidth: '200%',
        height: '100%',
        backgroundColor: '#FF0000',
        borderRadius: '9999px',
        border: '1px solid #ffffff',
        textAlign: 'center',
        color: '#ffffff',
        display: 'inline-block',
        fontSize: '1',
    },
    btnNewChat: {
        maxWidth: '90%',
        height: '98%',
        margin: 'auto',
        backgroundColor: '#006FBC',
        '&:hover': {
            background: '#107FCC',
        },
        borderRadius: '9999px',
        border: '1px solid #ffffff',
        textAlign: 'center',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    btnNewChatPlus: {
        color: '#ffffff',
        fontSize: '20px',
        fontWeight: 'bold',
        marginRight: '5%',
    },
    btnFunction: {
        width: '70%',
        height: '100%',
        backgroundColor: '#EBE9E9',
        '&:hover': {
            background: '#FBF9F9',
        },
        borderRadius: '9999px',
        border: '1px solid #ffffff',
        textAlign: 'center',
        color: '#000000',
    },
    btnFunctionIcon: {
        color: '#000000',
        marginRight: '3%',
    },
    btnSearch: {
        backgroundColor: '#006FBC',
        borderRadius: '9999px',
        border: '1px solid #ffffff',
        textAlign: 'center',
        color: '#ffffff',
        fontSize: '14px',
    },
    btnOk: {
        pointerEvents: 'auto',
        background: '#006FBC',
        borderRadius: '31px',
        '&:hover': {
            background: '#107FCC',
        },
        '&:disabled': {
            background: '#EBE9E9',
            border: '1px solid #ffffff',
        },
    },
    hoverMenu: {
        // border: '1px solid #000000',
        '&:hover': {
            background: 'rgb(87, 187, 255)',
        },
    },
}));

// interface IMemoGroup extends MemoGroup {
//     groupChatFlag: boolean;
//     isClicked: boolean;
// }

interface MemoSetting {
    isNotify?: boolean;
}

const radioButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface Props {
    subId: string | null;
    webSocketUrl: string;
    scrollbarWidth: number;
    defaultSize: Size;
    moveMemoGroupList: (mode?: number) => void;
    sendNewMemo: (tabId: string, groupId: number, memo: string) => void;
    sendDeleteMemo: (tabId: string, groupId: number, memoId: number) => void;
    sendReadMemo: (tabId: string, groupId: number, memoId: number) => void;
    sendChatGroupName: (groupName: string, tabId: string, groupId: number) => void;
    sendChatGroupQuit: (tabId: string, groupId: number) => void;
    sendChatGroupAdd: (userIds: string[], tabId: string, groupId: number) => void;
    getMyUser: () => User;
    setToTop:(name: string) => void;
    handleGroupChatMenu: (open: boolean) => void;
    //frameSetting?: DraggableFrameProps;
}

export interface MemoHandler {
    open: (userId: number, subId:string, groupMemberNumber: number, unreadCount: number | null, userSubId: string[], defaultCoord: Coord, w:number, h:number) => void;
    openChat: (userId: number, subId:string, groupMemberNumber: number, unreadCount: number | null, userSubId: string[], defaultCoord: Coord, w:number, h:number) => void;
    close: () => void;
    getOpen: () => boolean;
    setCoord: (coord: Coord) => void;
    setNewMemo: (memo: IMemo) => void;
    setDeleteMemo: (memo: IMemo) => void;
    receiveReadMemoInfo: (readMemoInfo: ReadMemoInfo) => void;
}

// request
const getMemoList = async (tabId: string, groupId: number, page: number, limit: number, memoIdAt: number | null) => {
    try {
        // const res: AxiosResponse<IMemo[]> = await axios.post("/api/user/memo/history", {
        //     tabId,
        //     groupId,
        //     limit,
        //     page,
        //     memoIdAt
        // });
        const jfsClient = JfsClient.getInstance();
        const jfs_httpClient = jfsClient.httpClient;
        const res: IMemo[] = await jfs_httpClient.getMemoHistory(tabId, groupId, page, limit, (memoIdAt === null ? undefined : memoIdAt));
        return res;
    } catch (err) {
        const jfserr = err as JfsError;
        console.error('httpClient.getMemoHistory error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
        return err;
    }
}

const getUserGroupList = async (tabId: string) => {
    try {
    //     const res: AxiosResponse<IMemoGroup[]> = await axios.post('/api/user/memo/unread', {
    //         tabId,
    //     });
        const jfsClient = JfsClient.getInstance();
        const jfs_httpClient = jfsClient.httpClient;
        const res = await jfs_httpClient.getUnreadMemoGroupList(tabId);
        return res;
    } catch (err) {
        const jfserr = err as JfsError;
        console.log('httpClient.getUserGroupList error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
        return jfserr;
    }
}

// request
const getUsers = async (tabId: string, keyword: string): Promise<SearchedUser[]> => {
    try {
        // const res: AxiosResponse<SearchedUser[]> = await axios.post('/api/user/users', {
        //     tabId,
        //     keyword
        // });
        const jfs_Client = JfsClient.getInstance();
        const jfs_httpClient =  jfs_Client.httpClient;
        const res: SearchedUser[] = await jfs_httpClient.searchUserName(tabId, keyword);
        return res;
    } catch (err) {
        throw err;
    }
}

const sendSettingData = (setting: MemoSetting, webSocketUrl: string) => {
    // return axios.post(`/api/user/${webSocketUrl}/memo/setting`, {
    //     tabId: sessionStorage.getItem('TABID'),
    //     isNotify: setting.isNotify
    // })
    const jfs_Client = JfsClient.getInstance();
    const jfs_httpClient =  jfs_Client.httpClient;
    return jfs_httpClient.editMemoSetting(sessionStorage.getItem('TABID') as string, Number(webSocketUrl), setting);
}

const GroupChatComponent: ForwardRefRenderFunction<MemoHandler, Props> = ({
    subId = "",
    webSocketUrl = "",
    scrollbarWidth = 0,
    defaultSize,
    moveMemoGroupList,
    sendNewMemo,
    sendDeleteMemo,
    sendReadMemo,
    sendChatGroupName,
    sendChatGroupQuit,
    sendChatGroupAdd,
    getMyUser,
    setToTop,
    handleGroupChatMenu,
    //frameSetting = {},
}, ref) => {
    const tabId = sessionStorage.getItem('TABID') as string;
    const classes = useStyles();
    const frameRef = useRef({} as DraggableFrameHandler);
    const limit = 30; // 1回のリクエストで取得するチャット件数
    const maxMemoText = 255; // 1チャットの制限文字数
    const textTrimLength = 17;
    const TEXT_TRIM_LENGTH = 50;

    // state
    const [inputMemo, setInputMemo] = useState('');
    const [isShowSetting, setShowSetting] = useState(false);
    const [isShowNotificationSetting, setShowNotificationSetting] = useState(false);
    const [isShowUserSearch, setShowUserSearch] = useState(false);
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isMemoSending, setMemoSending] = useState(false);
    const [groupId, setGroupId] = useState(0);
    const [groupName, setGroupName] = useState('');
    const [groupMemberCount, setGroupMemberCount] = useState(0);
    const [page, setPage] = useState(1);
    const [memoList, setMemoList] = useState([] as IMemo[]);
    const [isEnterContent, setEnterContent] = useState(false);
    const [setting, setSetting] = useState({} as MemoSetting);
    const [settingOrg, setSettingOrg] = useState({} as MemoSetting);
    const [defaultCoord, setDefaultCoord] = useState({ x: 0, y: 0 });
    const [message, setMessage] = useState("");
    const [time, setTime] = useState(0);
    const [ctrlEnter, setCtrlEnter] = useState(Cookie.get('MEMO_SEND_KEY')?.toString() === undefined ? 'Enter' : Cookie.get('MEMO_SEND_KEY')?.toString());
    const [memoGroupList, setMemoGroupList] = useState([] as IMemoGroup[]);
    const [newMemoGroupList, setNewMemoGroupList] = useState([] as IMemoGroup[]);
    const [isShowConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [isShowGroupNameEditDialog, setShowGroupNameEditDialog] = React.useState(false);
    const [isShowGroupTool, setShowGroupTool] = useState(false);
    const [isShowGroupUserListDialog, setShowGroupUserListDialog] = React.useState(false);
    const [isShowStampListDialog, setShowStampListDialog] = React.useState(false);
    const [chatStampList, setChatStampList] = useState([] as ChatStampInfo[]);
    const [chatStampPreviewOpen, setChatStampPreviewOpen] = useState(false);
    const [chatStampPreview, setChatStampPreview] = useState({} as ChatStampInfo);
    const [anchorMemoMenu, setAnchorMemoMenu] = useState({      // メモのメニュー用 
        target: null as null | HTMLElement,                     // 対象のDOM
        memo: null as null | IMemo,                             // 対象のメモデータ
    })

    const [isAdd, setAdd] = useState(false);
    const [initGroup, setInitGroup] = useState({} as IMemoGroup);

    //userSearch
    const [keyword, setKeyword] = useState('');
    const [userList, setUserList] = useState([] as SearchedUser[]);
    const [existUserList, setExistUserList] = useState([] as SearchedUser[]);
    const [usMessage, setUsMessage] = useState<string | ReactNode>('');
    const [selectedUser, setSelectedUser] = useState([] as SearchedUser[]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [groupNameInput, setGroupNameInput] = useState('');

    const [beforeUnreadMemoId, setBeforeUnreadMemoId] = useState(0);

    const openTimestamp = useRef(null as null | Date);
    const userSearchRef = useRef<HTMLInputElement>(null);
    const messageInputRef = useRef<HTMLInputElement>(null);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const isMaxMemoText = useMemo(() => {
        return inputMemo.length > maxMemoText
    }, [inputMemo]);

    const isSubIdEmpty = useMemo(() => {
        return subId === "" || subId === null;
    }, [subId]);

    const memoWindowStyle = {
        backgroundColor: 'rgba(255,255,255,0.5)',
    };
    const memoFontStyle = {
        fontSize: '14px',
    };

    // TODO: V2でFloor.tsx側へ移動するかを検討
    const frameSetting = {
        parentElementId: 'floorContainer',
        zIndex: ZIndex.experiment,
        defaultCoord,
        //スマホ縦持ちorPCの場合のサイズ
        defaultSize:  
            {
                width:  WebrtcService.isAndroid() || WebrtcService.isiOS() ? 360 : 720, 
                height: WebrtcService.isAndroid() || WebrtcService.isiOS() ? 500 : 720, 
            },
        //スマホ横持ち場合のサイズ
        defaultSize2:
            {
                width:  WebrtcService.isAndroid() || WebrtcService.isiOS() ? 540 : 720, 
                height: WebrtcService.isAndroid() || WebrtcService.isiOS() ? 250 : 720, 
            },
        scrollbarWidth,
        enableResizing: true,
        disableDragging: false,
    }
    const _frameSetting = {
        ...frameSetting,
        enableResizing: isEnterContent ? false : frameSetting.enableResizing,
        disableDragging: isEnterContent ? true : frameSetting.disableDragging,
    }

    const fetchMoreData = async () => {
        try {
            const data = await getMemoList(tabId, groupId, page, limit, memoList[0]?.memoId) as IMemo[];
            if (data) {
                setMemoList([...memoList, ...data]);
                setPage(page + 1);
            }

            const ugData = await getUserGroupList(tabId) as IMemoGroup[];
            if (ugData) {
                setMemoGroupList([...ugData]);
            }
        } catch (err) {
            console.error(err);
            setMessage("データの取得に失敗しました。")
        }
    }

    // 開くたびにunreadをして負荷が上がる現象の修正でgetUserGroupList関係を外に出す
    const getUserGroupListFromTabId = () => {
        httpClient.getUnreadMemoGroupList(tabId)
            .then((response: MemoGroup[]) => {
                let temp = response as IMemoGroup[];

                if (temp.length === 0) {
                    // setGroupListMessage("チャットグループがありません。");
                } else {
                    // setGroupListMessage("");
                }
                temp.forEach(e => {
                    e.isClicked = false;
                })
                setMemoGroupList(temp);
            })
            .catch((err) => {
                const jfserr = err as JfsError;
                console.log('httpClient.getUnreadMemoGroupList error httpStatusCode=[' + jfserr.httpStatusCode + '] code=[' + jfserr.code + '] detail=[' + jfserr.detail + ']');
            });
    }

    const getInitData = async () => {
        try {
            setLoading(true);
            let ugData = memoGroupList;
            if (memoGroupList.length <= 0) {
                ugData = await getUserGroupList(tabId) as IMemoGroup[];
            }

            let tmpList = newMemoGroupList;
            newMemoGroupList.forEach(gp => {
                if(ugData.find(ug => ug.groupId == gp.groupId) == undefined){
                    gp.isClicked = false;
                    tmpList.push(gp);
                }
            });

            // 初期表示時、チャットユーザーあるいはグループを決める
            if(initGroup.groupId > -1){
                // ユーザー検索やavatarのチャットボタンの場合
                let index = ugData.findIndex(ug => ug.groupId == initGroup.groupId);

                if(index > -1){
                    // チャット履歴が存在する場合、選択にする
                    ugData[index].isClicked = true;
                    setChatHistory(ugData[index], true);
                }else{
                    // チャット履歴が存在しない場合、一時グループとして選択する
                    let tmpIndex = tmpList.findIndex(ug => ug.groupId == initGroup.groupId);
                    if(tmpIndex > -1){
                        //既に一時グループリストに存在する場合、選択する
                        tmpList[tmpIndex].isClicked = true;
                        setChatHistory(tmpList[tmpIndex], false);
                    }else{
                        //既に一時グループリストに存在しない場合、リストに追加し、選択する
                        tmpList = [initGroup, ...tmpList];
                        tmpList[0].isClicked = true;
                        setChatHistory(tmpList[0], false);
                    }
                }
            }else{
                // FloorMenuのチャットボタンの場合
                if(ugData.length > 0){
                    // グループリストが0件でない場合、先頭を表示
                    ugData[0].isClicked = true;
                    setChatHistory(ugData[0], true);
                }else if(tmpList.length > 0){
                    // 一時グループリストが0件でない場合、先頭を表示
                    setChatHistory(tmpList[0], false);
                }else{
                    setGroupId(-1);
                    setGroupName("");
                    setShowGroupTool(false);
                    setMemoList([]);
                    setMessage("表示するチャットはありません");
                }
            }

            setMemoGroupList(ugData);
            setNewMemoGroupList(tmpList);
            // }
        } catch (err) {
            console.error(err);
            setMessage("データの取得に失敗しました。")
        } finally {
            setLoading(false);
        }
    }

    const refreshMemoGroupList = () =>{

        setLoading(true);
        // axios.post('/api/user/memo/unread', {tabId,})
        httpClient.getUnreadMemoGroupList(tabId)
        .then((e: MemoGroup[]) => {
            const ugData = e as IMemoGroup[];

            var tmpList = [] as IMemoGroup[];
            newMemoGroupList.forEach(gp => {
                if(ugData.find(ug => ug.groupId == gp.groupId) == undefined){
                    tmpList.push(gp);
                }
            });

            var index = ugData.findIndex(ug => ug.groupId == groupId);

            // チャット会話の相手を決める
            if(index > -1){
                // 更新前のグループが存在する場合
                ugData[index].isClicked = true;
                // 表示中のグループの場合、メンバー人数更新
                setGroupMemberCount(ugData[index].groupMemberNumber);
                setChatHistory(ugData[index], true);
            }else if(ugData.length > 0){
                // 更新前のグループが存在しない、且つグループ件数 = 0の場合
                ugData[0].isClicked = true;
                setChatHistory(ugData[0], true);
            }else if(tmpList.length > 0){
                //一時グループの先頭を選択
                tmpList[0].isClicked = true;
                setChatHistory(tmpList[0], false);
            }else{
                //一時グループが空の場合
                setGroupId(-1);
                setGroupName("");
                setShowGroupTool(false);
                setMemoList([]);
                setMessage("表示するチャットはありません");
            }


            setNewMemoGroupList(tmpList);
            setMemoGroupList([...ugData]);
        })
        .catch(err => {})
        .finally(() => {
            setLoading(false);
        });
    }

    const adjustText = (text: string) => {
        return adjustTextLength(text, textTrimLength);
    }
    const adjustTextLength = (text: string, length: number) => {
        return text.length > length ? `${text.slice(0, length)}...` : text;
    }

    const checkChatStampState = (text: string) => {
        let start = text.substring(0,1);
        let end = text.substring(text.length -1);
        if(start === '[' && end === ']') return true;
        return false;
    }

    const checkChatStamp = (text: string) => {
        let res = chatStampList.find((e) => e.stampKey === text);
        return res;
    }

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number | null, userSubId: string[], defaultCoord: Coord, w:number, h:number) => {

            if(groupId > -1){
                setInitGroup({groupId, groupName, groupMemberNumber, unreadCount, userSubId} as IMemoGroup);
            }
            // 既に表示中の場合、なにもしない
            if(isShow){
                return;
            } else {
                if (openTimestamp.current === null) {
                    openTimestamp.current = new Date();
                } else {
                    openTimestamp.current = new Date();
                    getUserGroupListFromTabId();
                }
            }

            setDefaultCoord(defaultCoord);
            setGroupId(groupId);
            setGroupName(groupName);
            setMessage("表示するチャットはありません");
            setTime(new Date().getTime());
            setTimeout(() => {
                document.getElementById('inputMemoField')?.focus();
            }, 500);
            setShow(true);
            frameRef.current?.open();
            setWidth(w);
            setHeight(h);
            //frameRef.current?.updateSize({width:w, height:h});
        },
        openChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number | null, userSubId: string[], defaultCoord: Coord,w:number, h:number) => {
            setDefaultCoord(defaultCoord);
            setInitGroup({groupId, groupName, groupMemberNumber, unreadCount, userSubId} as IMemoGroup);
            setShow(true);
            setTime(new Date().getTime());
            setTimeout(() => {
                document.getElementById('inputMemoField')?.focus();
            }, 500);
            frameRef.current?.open();
            setWidth(w);
            setHeight(h);
            handleGroupChatMenu(true);
        },
        close() {
            closeUI();
        },
        getOpen() {
            return isShow;
        },
        setCoord(coord: Coord) {
            setDefaultCoord(coord);
        },
        setNewMemo(memo: IMemo) {
            // TODO: 画面での表示要求によって処理追加が必要になる
            // 新規チャットをチャット要素内に追加
            if (isShow) {
                // 一旦メモグループの再取得をやめてみる
                // refreshMemoGroupList();
                if (memo.groupId === groupId) {
                    setMemoList([memo, ...memoList]);
                    setMessage("");
                    // 既読送信
                    //console.info(tabId, memo.groupId, memo.memoId, "setNewMemo");
                    sendReadMemo(tabId, memo.groupId, memo.memoId);
                } else {
                    // 開いているメモグループではないグループのメモが届いた場合にメモグループをリフレッシュ
                    refreshMemoGroupList();
                }
                // setMemoSending(false);
                // setTimeout(() => settingMemoSending(false), 1500); // x秒後にsend終了状態にする（連打送信対応）
                setTimeout(() => settingMemoSending(false), 500); // x秒後にsend終了状態にする（連打送信対応）
            }
        },
        setDeleteMemo(memo: IMemo) {
            if (memo.groupId === groupId) {
                let tempMemoList = [...memoList];
                tempMemoList.forEach(e => {
                    if (e.memoId === memo.memoId) {
                        e.memo = memo.memo;
                        e.removed = true;
                    }
                })
                setMemoList([...tempMemoList]);
                // setMessage("");
            }

        },
        setChatGroupName(data: ChatGroupData) {
            var list = [...memoGroupList];
            var tmpList = [...newMemoGroupList];
            list.forEach(ug =>{
                if(ug.groupId == data.groupId){
                    ug.groupName = data.groupName;
                }
                if(groupId == data.groupId){
                    setGroupName(data.groupName);
                }
            });
            tmpList.forEach(ug =>{
                if(ug.groupId == data.groupId){
                    ug.groupName = data.groupName;
                }
                if(groupId == data.groupId){
                    setGroupName(data.groupName);
                }
            });
            setMemoGroupList(list);
            setNewMemoGroupList(tmpList);
            refreshMemoGroupList();
        },
        setChatGroupAddQuit(data: ChatGroupData) {
            refreshMemoGroupList();
        },
        receiveReadMemoInfo(readMemoInfo: ReadMemoInfo) {
         if (isShow && readMemoInfo.groupId === groupId) {
                // TODO: 画面での表示要求によって処理追加が必要になる
                // 既読情報を反映
                const { memoId, groupId, latestReadTime, readCount } = readMemoInfo;
                //console.info(subId, readMemoInfo);
                const _list = memoList.map(
                    d => (d.memoId === memoId && d.groupId === groupId)
                        ? { ...d, latestReadTime, readCount }
                        : d
                );
                setMemoList(_list);
            }

            if(isShow && readMemoInfo.subId == subId){
                //console.info("get unreadCount in receiveReadMemoInfo");
                // axios.post("/api/user/memo/groupinfo", {
                //     tabId: sessionStorage.getItem("TABID") as string,
                //     groupId: readMemoInfo.groupId
                // })
                httpClient.getMemoGroupInfo(sessionStorage.getItem("TABID") as string, readMemoInfo.groupId)
                 .then((e: MemoGroup)=>{
                    let res = e as MemoGroup;
                    const index = memoGroupList.findIndex(item => item.groupId == readMemoInfo.groupId);
                    if(index > -1 && memoGroupList[index].unreadCount != res.unreadCount){
                        memoGroupList[index].unreadCount = res.unreadCount;
                        setMemoGroupList([...memoGroupList]);
                    }
                }).catch((err) =>{
    
                }).finally(()=>{});            
            }
        },
        setMemoSetting(setting: MemoSetting) {
            setSetting(setting);
            setSettingOrg(setting);
        },
        getZindex(){
            return zindex;
        },
        setZindex(z: number){
            setZindex(z);
        },
        setSize(x:number, w:number, h:number){
            setDefaultCoord({x:x, y:0});
            setWidth(w);
            setHeight(h);
        }
    }));

    const [zindex, setZindex] = React.useState(ZIndex.experiment);
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);

    const closeUI = () => {
        moveMemoGroupList();// 未読リストを画面右上に移動
        setGroupId(0);
        setMessage("");
        setShow(false);
        setShowSetting(false);
        setTime(0);
        frameRef.current?.close();
        handleGroupChatMenu(false);
        setShowStampListDialog(false);
        setChatStampPreviewOpen(false);
    }   

    /**
     * useEffect
     */
    // UI open時の要素内スクロール位置移動
    useEffect(() => {
        const element = document.getElementById('memoWindow');
        if (element === null) return;
        const bottom = element.scrollHeight - element.clientHeight;
        if (element.scroll !== undefined) {
            element.scroll(0, bottom);
        } else {
            element.scrollTop = bottom;
        }
    }, [isShow]);

    // UI open時の初期データ取得 
    useEffect(() => {
        if (!isShow || !groupId) return;
        async function init() {
            await getInitData();
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, initGroup]);
    //}, [isShow, groupId]);

    // UI open時の既読送信
    useEffect(() => {
        // TODO: 送信条件が不十分かもしれない 
        // 未読を既読へ変更
        const latestMemo = memoList[0];
        if (isShow && latestMemo && latestMemo.subId !== subId 
            && groupId !== -1 && latestMemo.memoId !== beforeUnreadMemoId) {
            const latestReadTime = new Date(memoList[0].latestReadTime).getTime();
            const isNeedToSend = latestReadTime < time;
            if (isNeedToSend ) {
                //console.info(tabId, latestMemo.groupId, latestMemo.memoId, "useEffect");
                //console.info(new Date(memoList[0].latestReadTime).toLocaleString(), new Date(time).toLocaleString());
                sendReadMemo(tabId, latestMemo.groupId, latestMemo.memoId);
                setBeforeUnreadMemoId(latestMemo.memoId);
            }
        }

        // 上のsendReadMemoを1回のみ送るように変更したい
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoList, groupId]); // isShow, memoList, groupId, time

    // UI open時のsetting情報をstateへ反映
    useEffect(() => {
        if (isShow && !Object.keys(setting).length) {
            const setting = {
                isNotify: getMyUser().isMemoNotify,
            }
            setSetting(setting);
            setSettingOrg(setting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    // // 設定を閉じる際に設定情報を送信する
    // useEffect(() => {
    //     if (!isShowSetting && setting.isNotify !== undefined) {
    //         sendSettingData(setting, webSocketUrl).catch(err => {
    //             console.error(err);
    //             setSetting({ ...setting, isNotify: !setting.isNotify });
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isShowSetting, webSocketUrl]);

    useEffect(() => {
        frameRef.current?.changeToAtOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        messageInputRef.current?.focus();
    }, [defaultCoord]);

    useEffect(() => {
        function getChatStampList() {
            // let params = new URLSearchParams();
            // params.append('tab_id', sessionStorage.getItem("TABID") as string);
            // axios.post('/api/user/chatstamp/picture/list', params)
            httpClient.getChatStampInfoList(sessionStorage.getItem("TABID") as string)
                .then(response => {
                console.log(response);
                setChatStampList(response as ChatStampInfo[])
            }).catch(err => {
                console.log(err);
            });
        }
        getChatStampList();
    }, [isShowStampListDialog])

    useEffect(() => {
        if(isShowUserSearch && userSearchRef.current){
            userSearchRef.current.focus();
        }
    }, [isShowUserSearch]);

    /**
     * メモ送信中状態を更新
     * 
     * @param isSending
     *  */
    const settingMemoSending = (isSending: boolean) => {
        setMemoSending(isSending);
        if(isSending === false) {
            setLoading(true);
            document.getElementById('inputMemoField')?.focus();
            setLoading(false);
        }
    }

    const setChatHistory = (data: IMemoGroup, mode: boolean) =>{

        setGroupId(data.groupId);
        setGroupName(data.groupName);
        setGroupMemberCount(data.groupMemberNumber);
        setShowGroupTool(data.groupMemberNumber > 2 || data.groupChatFlag);

        if(mode){
            // axios.post("/api/user/memo/history", {
            //     tabId: tabId,
            //     groupId: data.groupId,
            //     limit: limit,
            //     page: 1,
            //     memoIdAt: null
            // })
            httpClient.getMemoHistory(
                tabId,
                data.groupId,
                // limit,
                // 1,
                1,
                limit,
                undefined,
            )
            .then((e: IMemo[]) => {
                const ret = e as IMemo[];
                if (ret) {
                    setMemoList([...ret]);
                    setPage(2);
                    setMessage("");

                    // 未読がる場合、既読のメッセージのwebsocket送信を行う
                    if(ret.length > 0 && data.unreadCount != 0){
                        // console.info(tabId, data.groupId, ret[0].memoId, "setChatHistory");
                        sendReadMemo(tabId, data.groupId, ret[0].memoId);
                    }
                }
            })
            .catch(err => {})
            .finally(() => {});
        }else{
            setMessage("表示するチャットはありません");
            setMemoList([]);
        }
    }
    /**
     * event handlers
     */
    const handleChangeInputMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 1 && event.target.value === '\n') {
            return false;
        }
        setInputMemo(event.target.value);
    }

    const handleClickSendButton = () => {
        if(chatStampPreviewOpen){
            sendNewMemo(tabId, groupId, chatStampPreview.stampKey);
            setMemoSending(true);
            setChatStampPreview({} as ChatStampInfo);
            setChatStampPreviewOpen(false)
        }

        if (inputMemo.replace('\n', '').trim().length === 0) {
            setInputMemo('');
            return;
        }
        if (isMaxMemoText) return;
        // websocketで送信
        sendNewMemo(tabId, groupId, inputMemo);
        setMemoSending(true);
        setInputMemo('');
    }

    const handleCloseMemoMenu = () => {
        setAnchorMemoMenu({
            target: null,
            memo: null,
        });
    }

    const handleOpenMemoMenu = (event: React.MouseEvent<HTMLDivElement>, memo: IMemo) => {
        // GroupListのクリックイベントが発生しないように伝搬を破棄
        event.stopPropagation();
        event.preventDefault();
        setAnchorMemoMenu({
            target: event.currentTarget,
            memo: memo,
        });
    };

    /**
     * メモごとのメニューを表示する
     * 現状は削除のみ
     */
    const drawMemoMenu = useMemo(() => {
        if (!Boolean(anchorMemoMenu.target)) return;

        return (
            <Menu
                id="simple-menu" anchorEl={anchorMemoMenu.target} keepMounted
                style={{ left: -100 }}
                open={Boolean(anchorMemoMenu.target)} onClose={handleCloseMemoMenu}>
                <MenuItem className={classes.hoverMenu} onClick={() => handleClickDeleteMemoButton(anchorMemoMenu.memo?.memoId as number)}>取り消し</MenuItem>
            </Menu>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorMemoMenu])

    /**
    * メモを削除する処理
    * @param memoId 
    */
    const handleClickDeleteMemoButton = (memoId: number) => {
        sendDeleteMemo(tabId, groupId, memoId);
        setAnchorMemoMenu({
            target: null,
            memo: null,
        });
    }

    const handleClickInputTextField = () => {
        setShowStampListDialog(false);
        setChatStampPreviewOpen(false);
    }

    const handleClickStampButton = () => {
        if(isMemoSending && !isShowStampListDialog) return;
        setShowStampListDialog(!isShowStampListDialog);
        setChatStampPreviewOpen(false);
    }

    const handleClickChatStampPreview = (stampKey: string) => {
        if(isMemoSending) return;
        sendNewMemo(tabId, groupId, stampKey);
        setMemoSending(true);
        setChatStampPreviewOpen(false);
        setChatStampPreview({} as ChatStampInfo);
    }

    const handleClickChatStamp = (stamp: ChatStampInfo) => {
        if(isMemoSending) return;
        setChatStampPreviewOpen(true);
        if(Object.keys(chatStampPreview).length !== 0 && chatStampPreview === stamp){
            sendNewMemo(tabId, groupId, stamp.stampKey);
            setMemoSending(true);
            setChatStampPreviewOpen(false);
            setChatStampPreview({} as ChatStampInfo);
        }else{
            setChatStampPreview(stamp);
        }
    }

    const handleClickChatStampPreviewClose = () => {
        setChatStampPreviewOpen(false);
        setChatStampPreview({} as ChatStampInfo);
    }

    const handleSelectNotification = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const isNotify = event.target.value === 'ON';
        setSetting({ ...setting, isNotify });
    }

    const handleClose = () => {
        setNewMemoGroupList([]);
        closeUI();
    }

    const handleShowSetting = () => {
        setShowSetting(!isShowSetting);
    }

    var TimeFn = null as any;

    const onFrameDoubleClick = () => {
        clearTimeout(TimeFn);
        frameRef.current?.onFrameDoubleClick();
    }

    const setZIndex = () =>{
        clearTimeout(TimeFn);

        TimeFn = setTimeout(function(){
            setToTop("GroupChat");
        }, 200);
    };

    /**
     * キーボード操作によるチャットの送信
     */
    const memoKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        if (ctrlEnter === 'Ctrl+Enter') {
            if ((event.ctrlKey && event.key === '\n') || (event.ctrlKey && event.key === 'Enter')) {
                if (inputMemo.replace('\n', '').trim().length === 0) return;
                if (isMaxMemoText) return;
                // websocketで送信
                sendNewMemo(tabId, groupId, inputMemo);
                setMemoSending(true);
                setInputMemo('');
            }
        } else {
            if ((event.shiftKey && event.key === '\n') || (event.shiftKey && event.key === 'Enter')) {
                return false;
            }
            if ((event.key === '\n') || (event.key === 'Enter')) {
                if (inputMemo.replace('\n', '').trim().length === 0) {
                    setInputMemo('');
                    return false;
                }
                if (isMaxMemoText) return;
                // websocketで送信
                sendNewMemo(tabId, groupId, inputMemo);
                setMemoSending(true);
                setInputMemo('');
            }
        }
    }

    const handleSelectSendKey = (event: React.ChangeEvent<{}>, checked: boolean) => {
        let key = (event.target as HTMLInputElement).value;
        setCtrlEnter(key);
        Cookie.set('MEMO_SEND_KEY', key);
    }

    const changeGroupName = (userName: string, message: string) => {
        let parts = message.split(',');
        let oldGroupName = parts[0]; 
        let newGroupName = parts[1];

        return `${userName}はグループ名を「${oldGroupName}」から「${newGroupName}」に変更しました。`;

    }

    const drawMemoLine2 = useCallback((index: number, splitMemo: string[], memo: IMemo) => {
        
        const chatstamp = checkChatStamp(memo.memo);
        if(checkChatStampState(memo.memo) && chatstamp !== undefined){
                return(
                    <div onClick={(event) => handleOpenMemoMenu(event, memo)} >
                        {/*<img src={`/api/user/chatstamp/picture/${chatstamp.id}`} alt="" width="210" height="210" />*/}
                        <img src={`${httpClient.createChatStampImgUrl(chatstamp.id)}`} alt="" width="210" height="210" />
                    </div>
                )
        }else {
            return(
                <div onClick={(event) => handleOpenMemoMenu(event, memo)} className={classes.balloon1Right}>
                    {splitMemo.map((memoPart: string, index: number) => {
                        let strs: string[] = Utility.getUrlList(memoPart);
                        if (strs.length === 0) {
                            return(                                 
                                    <p key={index} className={classes.balloon1Leftp} style={memoFontStyle}>{memoPart}</p>
                            )
                        } else {
                            let parts: string[] = memoPart.split(strs[0]);
                            return(
                                <div className={classes.balloon1Right}>
                                    <Fragment key={index}>
                                        <p className={classes.balloon1Leftp} style={memoFontStyle}>{parts[0]}
                                            <a href={strs[0]} target="_blank" rel="noreferrer">{strs[0]}</a>
                                        {parts[1]}</p>
                                    </Fragment>
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
        
    }, [isSubIdEmpty, memoFontStyle]);

    const drawMemoLine3 = useCallback((index: number, splitMemo: string[], memo: IMemo) => {
        
        const chatstamp = checkChatStamp(memo.memo);
        if(checkChatStampState(memo.memo) && chatstamp !== undefined){
                return(
                    <div>
                        {/*<img src={`/api/user/chatstamp/picture/${chatstamp.id}`} alt="" width="210" height="210" />*/}
                        <img src={`${httpClient.createChatStampImgUrl(chatstamp.id)}`} alt="" width="210" height="210" />
                    </div>
                )
        }else {
            return(
                <div className={classes.balloon1Left}>
                    {splitMemo.map((memoPart: string, index: number) => {
                        let strs: string[] = Utility.getUrlList(memoPart);
                        if (strs.length === 0) {
                            return(                                 
                                    <p key={index} className={classes.balloon1Leftp} style={memoFontStyle}>{memoPart}</p>
                            )
                        } else {
                            let parts: string[] = memoPart.split(strs[0]);
                            return(
                                <div className={classes.balloon1Right}>
                                    <Fragment key={index}>
                                        <p className={classes.balloon1Leftp} style={memoFontStyle}>{parts[0]}
                                            <a href={strs[0]} target="_blank" rel="noreferrer">{strs[0]}</a>
                                        {parts[1]}</p>
                                    </Fragment>
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
        
    }, [isSubIdEmpty, memoFontStyle]);

    /**
     * draw elements
     */
    const drawMemoLine = useCallback((index: number, splitMemo: string[], memo: IMemo) => {
        if (memo.removed) {
            return(
                <Grid
                    key={`memo-item-${index}`}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ paddingTop: 4, paddingBottom: 4 }}
                >
                    <p className={classes.endMessage}>{new Date(memo.memoTime).toLocaleString()}<br />送信者によってメッセージが取り消されました</p>
                </Grid>                
            )
        }
        else if (!isSubIdEmpty && memo.memoType !== 0) {
            return(
                <Grid
                    key={`memo-item-${index}`}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ paddingTop: 4, paddingBottom: 4 }}
                >
                    {memo.memoType === 1 && <p className={classes.endMessage}>{new Date(memo.memoTime).toLocaleString()}<br />{memo.userName}さんが{memo.memo}を追加しました</p>}
                    {memo.memoType === 2 && <p className={classes.endMessage}>{new Date(memo.memoTime).toLocaleString()}<br />{memo.memo}が退室しました</p>}
                    {memo.memoType === 3 && <p className={classes.endMessage}>{new Date(memo.memoTime).toLocaleString()}<br />{memo.userName}さんが{memo.memo}を削除しました</p>}
                    {memo.memoType === 4 && <p className={classes.endMessage}>{new Date(memo.memoTime).toLocaleString()}<br />{changeGroupName(memo.userName, memo.memo)}</p>}
                </Grid>                
            )
        } else if ((!isSubIdEmpty && memo.subId === subId)) {
            // ゲスト以外のユーザかつ自身の発言は右寄せ
            return (
                <Grid
                    key={`memo-item-${index}`}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                    style={{ paddingTop: 4, paddingBottom: 12 }}
                >
                    <Typography variant="caption" style={{ marginRight: 40, fontWeight: "bold" }} className={classes.memoUserNickname}>
                        {adjustText(memo.userName)}
                    </Typography>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        {drawMemoLine2(index, splitMemo, memo)}
                        <AccountCircleIcon className={classes.avatar} />
                    </Grid>
                    <Typography variant="caption" style={{ marginRight: 40 }} className={classes.memoUserNickname}>
                        {new Date(memo.memoTime).toLocaleString()}
                    </Typography>
                    {index === 0 &&
                        <Typography variant="caption" style={{ marginRight: 40 }} className={classes.memoUserNickname}>
                            {memo.readCount === 0
                                ? <div style={{color: '#FF0000'}}>未読</div>
                                : `既読: ${new Date(memo.latestReadTime).toLocaleString()} ${memo.readCount}人`
                            }
                        </Typography>
                    }
                </Grid>
            )
        } else {
            // 自分以外のチャットは左寄せ
            return (
                <Grid
                    key={index}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    style={{ paddingTop: 4, paddingBottom: 12 }}
                >
                    <Typography variant="caption" style={{ marginLeft: 40, fontWeight: "bold" }} className={classes.memoUserNickname}>
                        {adjustText(memo.userName)}
                    </Typography>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <AccountCircleIcon className={classes.avatar} />
                        {drawMemoLine3(index, splitMemo, memo)}
                    </Grid>
                    <Typography variant="caption" style={{ marginLeft: 40 }} className={classes.memoUserNickname}>
                        {new Date(memo.memoTime).toLocaleString()}
                    </Typography>
                    {index === 0 &&
                        <Typography variant="caption" style={{ marginLeft: 40 }} className={classes.memoUserNickname}>
                            {memo.readCount === 0
                                ? <div style={{color: '#FF0000'}}>未読</div>
                                : `既読: ${new Date(memo.latestReadTime).toLocaleString()} ${memo.readCount}人`
                            }
                        </Typography>
                    }
                </Grid>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubIdEmpty, memoFontStyle]);

    const drawMemo = useCallback((memo: IMemo, index: number) => {
        const splitMemo = memo.memo.split('\n');
        return drawMemoLine(index, splitMemo, memo);
    }, [drawMemoLine]);

    const redrawMemo = useMemo(() => {
        return memoList.length === 0
            ? <Typography align="center">表示するチャットはありません</Typography>
            : memoList.map((memo: IMemo, index: number) => drawMemo(memo, index));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoList, isLoading, drawMemoLine, drawMemo]);

    const drawSetting = useMemo(() => {
        return (
            <Fragment>
                <Typography variant='body2' id='continuous-slider' gutterBottom align='left'>
                    通知
                </Typography>
                <RadioGroup row name='isNotify' value={setting.isNotify ? 'ON' : 'OFF'}>
                    <FormControlLabel value='ON' control={<Radio color='primary' onChange={handleSelectNotification} />} label='ON' />
                    <FormControlLabel value='OFF' control={<Radio color='primary' onChange={handleSelectNotification} />} label='OFF' />
                </RadioGroup>
                { ctrlEnter !== 'Enter' && // この条件を外すとUI上に表示される
                    <>
                        <Typography variant="body2" id="continuous-slider" gutterBottom>
                            {"送信キーの設定"}
                        </Typography>
                        <RadioGroup row name="send_key" defaultValue={ctrlEnter}>
                            <FormControlLabel value="Ctrl+Enter" control={<Radio color="primary" onChange={handleSelectSendKey} />} label="Ctrl+Enter" />
                            <FormControlLabel value="Enter" control={<Radio color="primary" onChange={handleSelectSendKey} />} label="Enter" />
                        </RadioGroup>
                    </>
                }
            </Fragment>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setting.isNotify]);

    const drawChatMenu = useMemo(() => {
        return (
            <Fragment>
                {isShowGroupTool && <div
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                    style={{textAlign:"start"}}
                >                
                    <div style={{position:'relative', marginBottom:10, cursor:'pointer'}} onClick={() => {setShowSetting(false); handleOpenGroupUserList()}}>
                        <ListAltIcon/>
                        <div style={{position:'absolute', top:3, left:25,}}>メンバー一覧</div>
                    </div>
                    <div style={{position:'relative', marginBottom:10, cursor:'pointer'}} onClick={() => {setShowSetting(false); handleOpenUserSearch(true)}}>
                        <PersonAddIcon/>
                        <div style={{position:'absolute', top:3, left:25,}}>メンバー編集</div>
                    </div>
                    <div style={{position:'relative', marginBottom:10, cursor:'pointer'}} onClick={() => {setShowSetting(false); handleOpenGroupNameEdit()}}>
                        <EditIcon/>
                        <div style={{position:'absolute', top:3, left:25,}}>グループ名変更</div>
                    </div>
                    <div style={{position:'relative', marginBottom:10, cursor:'pointer'}} onClick={() => {setShowSetting(false); handleOpenQuitConfirm()}}>
                        <ExitToAppIcon/>
                        <div style={{position:'absolute', top:3, left:25,}}>グループから退室</div>
                    </div>
                </div>}
                <div
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                    style={{textAlign:"start", verticalAlign:"middle"}}
                >                
                    <div 
                        style={{position:'relative', cursor:'pointer'}}
                        onClick={() => {setShowSetting(false); setShowNotificationSetting(true)}}>
                        <SettingsIcon/>
                        <div style={{position:'absolute', top:3, left:25,}}>通知設定</div>
                    </div>
                </div>
            </Fragment>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, isShowGroupTool, groupName]);

    const drawLoading = useMemo(() => {
        let dispMessage: string = "データ取得中";
        if(isMemoSending) {
            dispMessage = "更新中";
        }
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color='primary' />
                    <Typography variant="subtitle2" >　{dispMessage}</Typography>
                </ThemeProvider>
            </Grid>
        )
    }, [isMemoSending]);

    const drawMessage = useMemo(() => {
        return (
            <div className={classes.messageContainer}>
                {message}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const drawMaxMemoMessage = useMemo(() => {
        return isMaxMemoText
            ? `${maxMemoText}文字を超えています。${inputMemo.length}/${maxMemoText}文字`
            : `${inputMemo.length}/${maxMemoText}文字`
    }, [inputMemo, isMaxMemoText]);

    const onUserGroupClick = async (ug:IMemoGroup, index:number) => {

        var list = [...memoGroupList];
        var listNew = [...newMemoGroupList];

        var idx = list.findIndex(i => i == ug);
        var idxNew = listNew.findIndex(i => i == ug);

        setLoading(true);
        list.forEach((item:IMemoGroup) => item.isClicked = false);
        listNew.forEach((item:IMemoGroup) => item.isClicked = false);

        const data = await getMemoList(tabId, ug.groupId, 1, limit, null) as IMemo[];
        if (data) {
            setMemoList([...data]);
            setPage(2);
            setMessage(data.length > 0 ? "":message);

            // 未読がる場合、既読のメッセージのwebsocket送信を行う
            if(data.length > 0 && ug.unreadCount != 0){
                // console.info(tabId, ug.groupId, data[0].memoId, "onUserGroupClick");
                sendReadMemo(tabId, ug.groupId, data[0].memoId);
            }
        }

        // 新しいのチャットグループ
        if(idx > -1){
            list[idx].isClicked = true;
            list[idx].unreadCount = 0;
        }
        if(idxNew > -1){
            listNew[idxNew].isClicked = true;
            listNew[idxNew].unreadCount = 0;
        }
        
        if(ug.groupId !== groupId){    
        setChatStampPreviewOpen(false);
        setChatStampPreview({} as ChatStampInfo);
        }

        setMemoGroupList([...list]);
        setNewMemoGroupList([...listNew]);
        setGroupId(ug.groupId);
        setGroupName(ug.groupName);
        setGroupMemberCount(ug.groupMemberNumber);
        setShowGroupTool(ug.groupMemberNumber > 2 || ug.groupChatFlag);
        setLoading(false);
    }

    // 
    const handleDelete = (user: SearchedUser) => {
        var arr = selectedUser.filter((item:SearchedUser) => item.subId !== user.subId );
        setSelectedUser([...arr]);
    }

    // 新しいチャットまたはメンバー招待ボタン押下時
    // ユーザー検索画面の表示処理
    const handleOpenUserSearch = async(mode: boolean) => {

        if(mode){
            // メンバー追加モード
            // const res: AxiosResponse<SearchedUser[]> = await axios.post("/api/user/memo/userlist", {
            //     tabId,
            //     groupId
            // });
            try {
                const res: SearchedUser[] = await httpClient.getMemoGroupUserList(tabId, groupId);
                setExistUserList(res);
                setSelectedUser(res);
            } catch (error) {
                console.error(error);
            }
            
            // setExistUserList(res);
            // setSelectedUser(res);
        }else{
            setExistUserList([]);
            setSelectedUser([]);
        }

        setUserList([]);
        setKeyword("");
        setShowUserSearch(!isShowUserSearch);
        const m3 = <p>キーワードを入力して<br />「検索」ボタンを押してください</p>
        setUsMessage(m3);
        setAdd(mode);
    }

    // ユーザー検索画面のキャンセルボタン押下
    const handleCloseUserSearch = () => {
        setShowUserSearch(false);
        setExistUserList([]);
        setSelectedUser([]);
    }
    
    // ユーザー検索画面のokボタン押下
    const handleOkUserSearch = async() => {
        setShowUserSearch(false);
        setLoading(true);

        if(isAdd){
            var addSubIds = [] as string[];
            selectedUser.forEach(e => addSubIds.push(e.subId));
            sendChatGroupAdd(addSubIds, tabId, groupId);
            setLoading(false);
            return;
        }
        var users = [] as string[];
        selectedUser.forEach(e => users.push(e.subId));
        var sortedUsers = JSON.stringify(users.sort());
        
        var list = [...memoGroupList];
        var idx = list.findIndex(gp => JSON.stringify(gp.userSubId) == sortedUsers);

        var listNew = [...newMemoGroupList];
        var idxNew = listNew.findIndex(gp => JSON.stringify(gp.userSubId) == sortedUsers);
        var ug = {} as IMemoGroup;

        list.forEach(e => e.isClicked = false);
        listNew.forEach(e => e.isClicked = false);

        if(idx == -1 && idxNew == -1){
            //新しいチャットの場合、新しいグループ作成
            // const res: AxiosResponse<IMemoGroup> = await axios.post('/api/user/chat/addChatGroups', {
            //     tabId: sessionStorage.getItem('TABID'),
            //     otherSubId: users
            // });

            const res: IMemoGroup = await httpClient.getChatMemoGroupInfo(sessionStorage.getItem('TABID') as string, users);

            ug = res;
            listNew = [res, ...newMemoGroupList];
            listNew[0].isClicked = true;

            const data = await getMemoList(tabId, ug.groupId, 1, limit, null) as IMemo[];
            if (data) {
                setMemoList([...data]);
                setPage(2);
            }
        }
        
        //既存グループに切り替え
        if(idx != -1){
            ug = list[idx];
            list[idx].isClicked = true;
            setChatHistory(ug, true);
        }
        if(idxNew != -1){
            ug = listNew[idxNew];
            listNew[idxNew].isClicked = true;
            setChatHistory(ug, true);
        }

        setGroupId(ug.groupId);
        setGroupName(ug.groupName);
        setGroupMemberCount(ug.groupMemberNumber);
        setShowGroupTool(ug.groupMemberNumber > 2 || ug.groupChatFlag);
        setMemoGroupList(list);
        setNewMemoGroupList(listNew);
        setLoading(false);
    }

    // ユーザー検索を行う
    const searchUser = async () => {

        if (keyword !== '') {
            try {
                setLoading(true);
                const data = await getUsers(tabId, keyword);
                if (data.length === 0) {
                    const m1 = <p><b>"{keyword}"</b>を含む &nbsp;&nbsp;<br />ユーザーは0件です。</p>
                    setUsMessage(m1);
                } else if (data.length > 0) {
                    setUserList([...data]);
                    setUsMessage("");
                }
            } catch (err) {
                console.error(err);
                const m2 = <p>データ取得に&nbsp;&nbsp;<br />失敗しました。</p>
                setUsMessage(m2);
            } finally {
                setLoading(false);
            }
        } else {
            const m3 = <p>キーワードを入力して<br />「検索」ボタンを押してください</p>
            setUsMessage(m3);
        }
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchUser();
        }
    }

    const onSearchClick = () => {
        searchUser();
        // setWriteMemoElm(null)
        // setUserItemElm(null)
    }

    const drawUsMessage = useMemo(() => {
        return (
            <div className={classes.messageContainer}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent:  'center',
                    alignItems: 'center',
                    height: height - 140,
                }}
            >
                {usMessage}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usMessage]);

    // 検索結果の描画
    // myUserは表示しない）
    const drawUserSearchResult = useMemo(() => {
        return (
            <List
                component='nav'
                aria-label='searched user list'
                style={{ width: '100%', paddingTop: 0 }}
            >
                {
                    userList.map((user: SearchedUser, index: number) => {
                        if (user.subId === "" || user.subId === null) console.log(`「${user.displayName}」のsubIdが空のため、「会いに行く」「チャットを残す」を使用できません。`);
                        if(user.subId == getMyUser().subId) return null;

                        const { displayName, isOnline } = user;
                        return (
                            <ListItem
                                className='searched-user-item'
                                key={`searched-user-item-${index}`}
                                button
                                dense
                                selected={selectedIndex === index}
                                onClick={(event) => onUserItemClick(event, user, index)}
                            >
                                <ListItemIcon className='searched-user-item' style={{ minWidth: 35 }}>
                                    <FiberManualRecordIcon className={`searched-user-item ${isOnline? classes.on : classes.off}`} />
                                </ListItemIcon>
                                <Tooltip title={displayName.length > TEXT_TRIM_LENGTH ? displayName : ''} placement="bottom-start" arrow>
                                    <ListItemText className='searched-user-item' primary={adjustText(displayName)} disableTypography />
                                </Tooltip>
                            </ListItem>
                        )                        
                    })
                }
            </List>
        )
    }, [keyword, usMessage, userList, selectedUser]);

    // ユーザー検索結果のリストをクリック時の操作
    const onUserItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, user: SearchedUser, index: number) => {
        setSelectedIndex(index);
        // 未選択のユーザーをクリックした場合
        if(selectedUser.find(item => item.subId == user.subId) == undefined){
            setSelectedUser([...selectedUser, user]);
        }
    }

    //グループ名編集のダイヤログの表示
    const handleOpenGroupNameEdit = () => {
        setShowGroupNameEditDialog(true);
        setGroupNameInput(groupName);
    }
    
    // グループ離脱時の確認ダイヤログの表示
    const handleOpenQuitConfirm = () => {
        setShowConfirmDialog(true);
    }

    //グループ名編集のダイヤログのボタン押下
    const handleGroupNameEditDialogOkCancel = (mode:boolean) =>{
        if(mode){
            sendChatGroupName(groupNameInput, tabId, groupId);
            var listNew = [...newMemoGroupList];
            var index = newMemoGroupList.findIndex((item:IMemoGroup) => item.groupId == groupId );
            if(index > -1){
                listNew[index].groupName = groupNameInput;
                setNewMemoGroupList(listNew);
            }
        }
        setShowGroupNameEditDialog(false);
    }

    //グループ離脱のダイヤログのボタン押下
    const handleConfirmDialogOkCancel = (mode:boolean) =>{
        if(mode){
            sendChatGroupQuit(tabId, groupId);

            var list = [...memoGroupList];
            var list = memoGroupList.filter((item:IMemoGroup) => item.groupId !== groupId );
            var listNew = [...newMemoGroupList];
            var listNew = newMemoGroupList.filter((item:IMemoGroup) => item.groupId !== groupId );

            setMemoGroupList(list);
            setNewMemoGroupList(listNew);
        }
        setShowConfirmDialog(false);
    }

    const handelGroupNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupNameInput(event.target.value);
    }

    const onClearGroupNameInput = () => {
        setGroupNameInput("");
    }

    // アイコンダブルクリック時はウィンドウサイズは変えない
    const onDummyDoubleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }

    function isAddUserError(list1: SearchedUser[], list2: SearchedUser[], ){

        // メンバー0名の場合、OKボタン押下不可
        if (list1.length == 0) return true;
        var sub1 = list1.map(e => {return e.subId}) as string[];
        var sub2 = list2.map(e => {return e.subId}) as string[];
        if(JSON.stringify(sub1.sort()) == JSON.stringify(sub2.sort())){
            return true;
        }

        // 以外の場合、押下可(１名も？)
        return false;
    }

    const drawUserSearchDialog = useMemo(() => {
        return(
            <Fragment>
            <Paper
                hidden={!isShowUserSearch}
                style={{
                    position: 'absolute',
                    top: '2%',
                    right: '2%',
                    width: '96%',
                    height: '96%',
                    padding: '2%',
                    overflow: 'auto',
                    fontFamily: 'Hiragino Maru Gothic StdN',
                    background: '#efefef'
                }}
            >
                <div className={classes.userSearchHeader}>
                    <div style={{ marginLeft: 8 }}>{isAdd ? "グループメンバー編集" : "新しいチャット"}</div>
                    <div 
                        onPointerEnter={() => setEnterContent(true)}
                        onPointerLeave={() => setEnterContent(false)}
                    >
                        <Button className={classes.btnOk} disabled={isAddUserError(selectedUser, existUserList) === true} variant="contained" size="small" color='primary' onClick={handleOkUserSearch}>OK</Button>&nbsp;
                        <IconButton className={classes.userSearchHeaderItem} style={{ width: 22 }} onClick={handleCloseUserSearch}>
                            <CloseIcon viewBox='0 0 16 16' />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div style={{width: '60%', display:'inline', float:'left', borderRight: 'groove', }}>
                        <div style={{ padding: 5, height:50, }}
                            onPointerEnter={() => setEnterContent(true)}
                            onPointerLeave={() => setEnterContent(false)}
                        >
                            <TextField
                                id='userSearchTextField'
                                inputRef = {userSearchRef}
                                variant='outlined'
                                size='small'
                                placeholder='キーワード'
                                style={{ width: '100%', background: '#FFFFFF'}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment onClick={onSearchClick} position='end'>
                                            <Button className={classes.btnOk} variant="contained" size="small" color='primary' >検索</Button>
                                        </InputAdornment>
                                    ),
                                }}
                                //autoFocus
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={onKeyDown}
                            />
                        </div>
                        <div
                            id='userListBody'
                            className={'customScrollBar ' + classes.userSearchBody}
                            onPointerEnter={() => setEnterContent(true)}
                            onPointerLeave={() => setEnterContent(false)}
                        >
                            {isLoading
                                ? drawLoading
                                : usMessage !== ''
                                    ? drawUsMessage
                                    : <InfiniteScroll
                                        className={classes.userSearchBodyInnerFrame}
                                        scrollableTarget='userListBody'
                                        dataLength={userList.length}
                                        next={() => { }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent:  'flex-start',
                                            alignItems: 'flex-start',
                                            height: height - 140,
                                        }}
                                        inverse={false}// 上から下
                                        hasMore={false}// scrollによる追加取得なし
                                        loader={<h4 className={classes.loading}>loading</h4>}
                                        endMessage={!isLoading && <p className={classes.endMessage}></p>}
                                    >
                                        {drawUserSearchResult}
                                    </InfiniteScroll>
                            }
                        </div>
                    </div>

                    <div style={{width: '39%', display:'inline', float:'right', overflow: 'hidden', overflowX: 'hidden',}}>
                        <div style={{position: 'relative', height: 50, }}>
                            <div style={{ textAlign: 'left', position: 'absolute', bottom: 0, left: 0 }}>
                                グループメンバー
                            </div>
                        </div>
                        <div
                            id='selectedUserListBody'
                            className={'customScrollBar ' + classes.userSelectBody}
                            onPointerEnter={() => setEnterContent(true)}
                            onPointerLeave={() => setEnterContent(false)}
                            >
                            <InfiniteScroll
                                className={classes.userSelectedBodyInnerFrame}
                                scrollableTarget='selectedUserListBody'
                                dataLength={selectedUser.length}
                                next={() => {}}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:  'flex-start',
                                    alignItems: 'flex-start',
                                    height: height - 140,
                                }}
                                inverse={false}// 上から下
                                hasMore={false}// scrollによる追加取得なし
                                loader={<h4 className={classes.loading}>loading</h4>}
                            >
                                {/*
                                    existUserList.map((user:SearchedUser, index:number)=>{
                                        return(
                                            <Fragment>
                                                <Chip
                                                    size='medium'
                                                    color="primary"
                                                    label={user.displayName}
                                                    onDelete={() => handleDelete(user)}
                                                />
                                            </Fragment>
                                        );
                                    })
                                */}
                                {
                                    selectedUser.map((user:SearchedUser, index:number)=>{
                                        return(
                                            (user.subId === getMyUser().subId) ? 
                                            <Fragment>
                                                <Chip
                                                    size='medium'
                                                    color="default"
                                                    label={user.displayName + '（自分）'}
                                                />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <Chip
                                                    size='medium'
                                                    color="primary"
                                                    style={{background: '#006FBC'}}
                                                    label={user.displayName}
                                                    onDelete={() => handleDelete(user)}
                                                />
                                            </Fragment>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </Paper>
            </Fragment>
        )
    }, [keyword, usMessage, userList, selectedUser, isShowUserSearch, isLoading, width, height, isAdd, existUserList]);

    const handleOpenGroupUserList = async() =>{
        try {
            // const res: AxiosResponse<SearchedUser[]> = await axios.post("/api/user/memo/userlist", {
            //     tabId,
            //     groupId
            // });

            const res: SearchedUser[] = await httpClient.getMemoGroupUserList(tabId, groupId);
            
            setExistUserList(res);
            setShowGroupUserListDialog(true);
        } catch (err) {
            console.error(err);
            return err;
        }
    }
    
    const handleResize = (size: Size) =>{
        setWidth(size.width);
        setHeight(size.height);
    }

    const handleNotificationSettingOkCancel = (mode: boolean) => {
        if(mode){
            sendSettingData(setting, webSocketUrl).catch(err => {
                console.error(err);
                setSetting({ ...setting, isNotify: !setting.isNotify });
            });
        }else{
            setSetting(settingOrg);
        }

        setShowNotificationSetting(false);
    }

    return (
        <Fragment>
        <DraggableFrame ref={frameRef} {..._frameSetting} zIndex={zindex} 
            minWidth={370} minHeight={250} handleResize={handleResize}>
            <CssBaseline />
            <Paper elevation={5} className={classes.memoWindow}>
                <div onClick={setZIndex}>
                    <div style={{width: '30%', display:'inline', float:'left'}} onClick={setZIndex}>
                        <div className={classes.memoWindowHeaderDiv} onDoubleClick={onFrameDoubleClick}>
                            <div style={{ marginLeft: 8, fontSize: '14px', fontWeight: 'bold', fontFamily: 'Hiragino Maru Gothic StdN', color: '#676767'}}>チャット</div>
                        </div>
{/**
                             <div 
                                onPointerEnter={() => setEnterContent(true)}
                                onPointerLeave={() => setEnterContent(false)}
                                onDoubleClick={onDummyDoubleClick}>
                                <IconButton onClick={() => handleOpenUserSearch(false)}>
                                    <GroupAddIcon/>
                                </IconButton>
                            </div>

 */}
                        <div className={classes.memoWindowHeaderDiv} style={{height: width < 382 ? 80 : 40 }}>
                            <Button className={classes.btnNewChat} onClick={() => handleOpenUserSearch(false)} 
                                onPointerEnter={() => setEnterContent(true)}
                                onPointerLeave={() => setEnterContent(false)}
                                variant="outlined" size="small" color='default' 
                                style={{ pointerEvents: 'auto', width: '100%' }}>
                                <div className={classes.btnNewChatPlus}>＋</div>新しいチャット
                            </Button>
                        </div>

                        <div id='memoGroupListBody' 
                            onPointerEnter={() => setEnterContent(true)}
                            onPointerLeave={() => setEnterContent(false)}
                            className={classes.groupListBodyDiv}
                            style={{
                                height: width < 382 ? `calc(100% - 104px)` : `calc(100% - 84px)`
                            }}
                            >
                            <InfiniteScroll
                                className={classes.userGroupListBodyInnerFrame}
                                scrollableTarget='memoGroupListBody'
                                dataLength={memoGroupList.length + newMemoGroupList.length}
                                next={() => { }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: userList.length === 0 ? 'center' : 'flex-start',
                                    alignItems: userList.length === 0 ? 'center' : 'flex-start',
                                }}
                                inverse={false}// 上から下
                                hasMore={false}// scrollによる追加取得なし
                                loader={<h4 className={classes.loading}>loading</h4>}
                                endMessage={!isLoading && <p className={classes.endMessage}></p>}
                            >
                                <List
                                    component='nav'
                                    aria-label='memo group list'
                                    style={{ width: '100%' }}
                                >
                                    {
                                        memoGroupList.map((ug:IMemoGroup, index:number) =>{
                                            return(
                                                <Fragment>
                                                    <ListItem button dense 
                                                        selected={ug.isClicked} 
                                                        onClick={() => onUserGroupClick(ug, index)}>
                                                        <Tooltip title={ug.groupName.length > textTrimLength ? ug.groupName : ''} placement="bottom-start" arrow>
                                                            <ListItemText className={ug.unreadCount == 0?classes.clicked:classes.unClicked}
                                                                primary={adjustTextLength(ug.groupName, Utility.getType() == 2 ? 7 : Utility.getType() == 1 ? 15 : textTrimLength)}
                                                                disableTypography />
                                                        </Tooltip>                                                        
                                                        {ug.unreadCount != 0 && <div style={{width:20,}}>
                                                            <div className={classes.badge} onClick={() => onUserGroupClick(ug, index)}>
                                                                {ug.unreadCount != null && ug.unreadCount > 100 ? '99+' : ug.unreadCount}
                                                            </div>
                                                        </div>}
                                                    </ListItem>
                                                </Fragment>
                                            )
                                        })
                                    }
                                    {
                                        newMemoGroupList.map((ug:IMemoGroup, index:number) =>{
                                            return(
                                                <Fragment>
                                                    <ListItem button dense 
                                                        selected={ug.isClicked} 
                                                        onClick={() => onUserGroupClick(ug, index)}
                                                        >
                                                        <ListItemText className={ug.unreadCount == 0?classes.clicked:classes.unClicked}
                                                            primary={adjustText(ug.groupName)}
                                                            disableTypography />
                                                    </ListItem>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </List>
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div style={{width: '70%', display:'inline', float:'left', backgroundColor: '#57BBFF'}}>
                        <div className={classes.memoWindowHeaderDiv} onDoubleClick={onFrameDoubleClick}>
                            <div style={{ marginLeft: '3%', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>
                                {Utility.getType() === 2 || width < 520 ? "":adjustText(groupName)}
                                {Utility.getType() === 2 || width < 520 || !isShowGroupTool ? "" : "("+ groupMemberCount +"人)"}
                            </div>
                            <div style={{width: '190px'}}
                                onPointerEnter={() => setEnterContent(true)}
                                onPointerLeave={() => setEnterContent(false)}
                                onDoubleClick={onDummyDoubleClick}>
                                {/*
                                    isShowGroupTool && 
                                    <Fragment>
                                        <Tooltip title={"メンバー一覧"} placement="bottom-start" arrow>
                                            <IconButton className={classes.memoWindowHeaderItem} onClick={handleOpenGroupUserList}>
                                                <ListAltIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"メンバー招待"} placement="bottom-start" arrow>
                                            <IconButton className={classes.memoWindowHeaderItem} onClick={() => handleOpenUserSearch(true)}>
                                                <PersonAddIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"グループ名編集"} placement="bottom-start" arrow>
                                            <IconButton className={classes.memoWindowHeaderItem} onClick={handleOpenGroupNameEdit}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"グループ離脱"} placement="bottom-start" arrow>
                                            <IconButton className={classes.memoWindowHeaderItem} onClick={handleOpenQuitConfirm}>
                                                <ExitToAppIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Fragment>
                                */}
                                <Button className={classes.btnFunction} variant="outlined" size="small" color='default' style={{ pointerEvents: 'auto' }} onClick={handleShowSetting}>
                                    {isShowSetting ? "閉じる" : 
                                        <div style={{ display:"contents" }}>
                                            <div className={classes.btnFunctionIcon} style={{ display:"grid" }}>
                                                <SettingsIcon/>
                                            </div>
                                            <div style={{fontFamily: 'Hiragino Maru Gothic StdN', color: '#676767'}}>
                                                チャット設定
                                            </div>
                                        </div>
                                    }
                                </Button>
                                <IconButton className={classes.memoWindowHeaderItem} onClick={handleClose} style={{ height: '100%', width: 22 }}>
                                    <CloseIcon viewBox='0 0 16 16' />
                                </IconButton>
                            </div>
                        </div>
                        <div
                            id='chatWindow'
                            className={!isShowStampListDialog ? 'customScrollBar ' + classes.memoWindowDiv : 'customScrollBar ' + classes.memoWindowDivForStamp}
                            style={memoWindowStyle}
                            onPointerEnter={() => setEnterContent(true)}
                            onPointerLeave={() => setEnterContent(false)}
                        >
                        {(isLoading || isMemoSending)
                            ? drawLoading
                            : message !== ""
                                ? drawMessage
                                : <InfiniteScroll
                                    className={classes.memoInnerFrame}
                                    scrollableTarget="chatWindow"
                                    dataLength={memoList.length}
                                    next={page === 1 ? getInitData : fetchMoreData}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        display: "flex",
                                        flexDirection: memoList.length === 0 ? "column" : "column-reverse",
                                        justifyContent: memoList.length === 0 ? "center" : "flex-start",
                                        alignItems: memoList.length === 0 ? "center" : "flex-start",
                                    }}
                                    inverse={true}
                                    hasMore={memoList.length === limit * (page - 1)}
                                    loader={<h4 className={classes.loading}>loading</h4>}
                                    endMessage={!isLoading && memoList.length > 0 && <h4 className={classes.endMessage}>チャット開始</h4>}
                                >
                                    {redrawMemo}
                                </InfiniteScroll>
                        }

                        </div>
                        {(chatStampPreviewOpen) &&
                            <div style={{
                                        bottom: `calc(14.25% + ${Utility.isLowScreenResolution() ? 110 : 132}px)`,
                                        width: '70%',
                                        height: 150,
                                        // height: `calc(40% - ${Utility.isLowScreenResolution() ? 110 : 132}px)`,
                                        background: 'rgba(230,230,230,0.7)',    
                                        position: 'absolute',
                                        overflowX: 'hidden',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        zIndex: 0,}}>
                                <IconButton 
                                    className={classes.chatStampPreviewCloseButton} 
                                    style={{ 
                                            cursor: 'pointer', 
                                            width: 22, 
                                            height: 22, 
                                            marginTop: 2, 
                                            marginRight: 10 }} 
                                    onClick={() => handleClickChatStampPreviewClose()}
                                    onTouchEnd={() => handleClickChatStampPreviewClose()}
                                    >
                                    <CloseIcon viewBox='0 0 16 16' />
                                </IconButton>
                                {/*<img src={`/api/user/chatstamp/picture/${chatStampPreview.id}`} */}
                                <img src={`${httpClient.createChatStampImgUrl(chatStampPreview.id)}`} 
                                    alt="" 
                                    width="150" 
                                    height="150" 
                                    onClick={() => handleClickChatStampPreview(chatStampPreview.stampKey)}
                                    onTouchEnd={() => handleClickChatStampPreview(chatStampPreview.stampKey)}
                                    />
                            </div>
                        }
                        <div
                            className={!isShowStampListDialog ? classes.inputMemoDiv : classes.inputMemoDivForStamp}
                            style={memoWindowStyle}
                            onPointerEnter={() => setEnterContent(true)}
                            onPointerLeave={() => setEnterContent(false)}
                        >
                            <TextField
                                className={`customScrollBar ${classes.inputMemo}`}
                                id='inputMemoField'
                                inputRef = {messageInputRef}
                                multiline
                                rowsMax='2'
                                rows='2'
                                value={inputMemo}
                                onChange={handleChangeInputMemo}
                                onClick={handleClickInputTextField}
                                variant='standard'
                                placeholder={'メッセージを入力'}
                                onKeyPress={memoKeyPress}
                                InputProps={{
                                    classes: {
                                        underline: classes.inputMemoBorder,
                                        inputMultiline: classes.inputMultiline,
                                    },
                                }}
                                FormHelperTextProps={{
                                    classes: {
                                        root: classes.alignRight,
                                    }
                                }}
                                error={isMaxMemoText}
                                helperText={drawMaxMemoMessage}
                                // disabled={[...newMemoGroupList, ...memoGroupList].find((ug:IMemoGroup) => ug.isClicked) === undefined}
                                disabled={([...newMemoGroupList, ...memoGroupList].find((ug:IMemoGroup) => ug.isClicked) === undefined) || isMemoSending}
                                
                            />
                            <SentimentVerySatisfiedIcon 
                                className={!isShowStampListDialog ? classes.stampButton : classes.stampButtonForStamp} 
                                onClick={handleClickStampButton} 
                            />
                        <IconButton
                            // disabled={[...newMemoGroupList, ...memoGroupList].find((ug:IMemoGroup) => ug.isClicked) === undefined || isMaxMemoText}
                            disabled={([...newMemoGroupList, ...memoGroupList].find((ug:IMemoGroup) => ug.isClicked) === undefined) || isMaxMemoText || isMemoSending}
                            onClick={handleClickSendButton}
                            className={!isShowStampListDialog ? classes.sendButton : classes.sendButtonForStamp}
                            color='secondary'
                            aria-label='send memo'
                            style={{textAlign: 'center'}}
                        >
                            <div style={{marginTop: 5, marginLeft: 5}}>
                                <SendIcon />
                            </div>
                        </IconButton>
                            {isShowStampListDialog &&
                                <div
                                    id='chatStampWindow'
                                    className={classes.stampListDialogDiv}
                                    style={memoWindowStyle}
                                >
                                    <InfiniteScroll
                                        className={classes.stampListDialogInnerFrame}
                                        scrollableTarget="chatStampWindow"
                                        dataLength={chatStampList.length}
                                        next={() => { }}
                                        style={{
                                            /*display: "flex",*/
                                            flexDirection: 'column',
                                            justifyContent: chatStampList.length === 0 ? "center" : "flex-start",
                                            alignItems: chatStampList.length === 0 ? "center" : "flex-start",
                                        }}
                                        inverse={false}
                                        hasMore={false}// scrollによる追加取得なし
                                        loader={<h4 className={classes.loading}>loading</h4>}
                                    >
                                        {chatStampList.map((e) => {
                                            return(
                                                <img src={`${httpClient.createChatStampImgUrl(e.id)}`} id={e.stampKey} className={classes.chatStampInStampListDialog} alt="" width="103" height="103" onClick={() => handleClickChatStamp(e)} />
                                            )
                                        })}
                                    </InfiniteScroll>
                                </div>
                            }
                        </div>
                        {drawMemoMenu}
                    </div>
                </div>
            </Paper>
            <Paper
                hidden={!isShowSetting}
                style={{
                    position: 'absolute',
                    top: 40,
                    right: 40,
                    width: 200,
                    minHeight: 50,
                    maxHeight: 220,
                    padding: 15,
                    overflow: 'auto'
                }}
            >
                {drawChatMenu}
                {/*drawSetting*/}
            </Paper>
            {drawUserSearchDialog}
        </DraggableFrame >
        <Dialog open={isShowConfirmDialog}
            style={{ pointerEvents: 'auto' }}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
                style:{
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}>
            <DialogTitle id="dialog-registUser" style={{padding: '7px 25px 9px 25px' , background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>確認</DialogTitle>
            <DialogContent>
            <DialogContentText>
                このチャットグループから退室します。
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <div>
                <Button color="primary" onClick={() => { handleConfirmDialogOkCancel(true) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>&nbsp;
                <Button color="primary" onClick={() => { handleConfirmDialogOkCancel(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
            </div>
            </DialogActions>
        </Dialog>
        <Dialog open={isShowGroupNameEditDialog}
            style={{ pointerEvents: 'auto' }}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
                style:{
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}>
            <DialogTitle id="dialog-registUser" style={{padding: '7px 25px 9px 25px' ,background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>グループ名変更</DialogTitle>
            <DialogContent>
                <div style={{color: '#707070'}}>チャットグループ名</div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="groupNameInput"
                    name="groupNameInput"
                    //label="チャットグループ名"
                    variant={'outlined'}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    onChange={handelGroupNameInputChange}
                    value={groupNameInput}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onClearGroupNameInput}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
            <div>
                <Button disabled={groupNameInput == ""} color="primary" onClick={() => { handleGroupNameEditDialogOkCancel(true) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>&nbsp;
                <Button color="primary" onClick={() => { handleGroupNameEditDialogOkCancel(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
            </div>
            </DialogActions>
        </Dialog>
        <Dialog open={isShowGroupUserListDialog}
            style={{ pointerEvents: 'auto' }}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
                style:{
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}>
            <DialogTitle id="dialog-registUser" style={{padding: '7px 25px 9px 25px' , background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>グループメンバー一覧</DialogTitle>
            <DialogContent>
                {
                    existUserList.map(usr => {
                        return (
                            (usr.subId === getMyUser().subId) ? 
                            <Fragment>{usr.displayName + '（自分）'}<br/></Fragment>
                            :
                            <Fragment>{usr.displayName}<br/></Fragment>
                        )
                    })
                }
            </DialogContent>
            <DialogActions>
            <div>
                <Button color="primary" onClick={() => {setShowGroupUserListDialog(false); handleOpenUserSearch(true);}} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 120}}>メンバーを編集</Button>&nbsp;
                <Button color="primary" onClick={() => {setShowGroupUserListDialog(false)}} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>閉じる</Button>
            </div>
            </DialogActions>
        </Dialog>
        <Dialog open={isShowNotificationSetting}
            style={{ pointerEvents: 'auto' }}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
                style:{
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}>
            <DialogTitle id="dialog-registUser" style={{ padding: '7px 25px 9px 25px' , background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>チャット通知設定</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    新しいメッセージが届いた時に通知を表示します。<br/>
                    この設定は他のチャットグループの通知にも適用されます。
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <ThemeProvider theme={radioButtonTheme}>
                    <RadioGroup row name='isNotify' value={setting.isNotify ? 'ON' : 'OFF'}>
                        <FormControlLabel value='ON' control={<Radio color='primary' onChange={handleSelectNotification} />} label='オン' />
                        <FormControlLabel value='OFF' control={<Radio color='primary' onChange={handleSelectNotification} />} label='オフ' />
                    </RadioGroup>
                </ThemeProvider>
            </DialogContent>
            <DialogActions>
            <div>
                <Button color="primary" onClick={() => { handleNotificationSettingOkCancel(true) }} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>&nbsp;
                <Button color="primary" onClick={() => { handleNotificationSettingOkCancel(false) }} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
            </div>
            </DialogActions>
        </Dialog>
        </Fragment>
    )
}

export const GroupChat = forwardRef(GroupChatComponent);
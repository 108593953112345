import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles, Theme, createStyles, useTheme, Typography, Button, DialogTitle, DialogContent, DialogActions, Tab, Tabs, Grid, Box, } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { AvatarPartsInfo } from '../common/JsonClass';
import JfsClient from '@fsi/jfs-sdk';


const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
        tutorialFont: {
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        },
        avatarPartsListDialogDiv: {
            height: '240px',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            cursor: 'default', // add
            //display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ffffe0',
            marginTop: 2,
        },
        avatarPartsColorWindowDiv: {
            height: '40px',
            width: '100%',
            backgroundColor: '#ffffff'
        },
        avatarPartsImg: {
            margin: '5px',
            border: '1px solid #000000',
            borderRadius: '25px',
            width: '80px',
            height: '80px',
            objectFit: 'contain',
            background: '#ffffff',
            '&:hover': {
                background: '#E9E9E9',
            },
        },
        avatarPartsImgSelceted: {
            margin: '5px',
            border: '5px solid #57BBFF',
            borderRadius: '25px',
            width: '80px',
            height: '80px',
            objectFit: 'contain',
            background: '#ffffff',
            '&:hover': {
                background: '#E9E9E9',
            },
        },
        avatarPartsNone: {
            margin: '5px',
            border: '1px solid #000000',
            borderRadius: '25px',
            width: '80px',
            height: '80px',
            background: '#ffffff',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                background: '#E9E9E9',
            },
        },
        avatarPartsNoneSelected: {
            margin: '5px',
            border: '5px solid #57BBFF',
            borderRadius: '25px',
            width: '80px',
            height: '80px',
            background: '#ffffff',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                background: '#E9E9E9',
            },
        },
        avatarPreview: {
            position: "absolute",
            width: 65,
            height: 105,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat"
        },
        loading: {
            color: '#222',
            alignSelf: 'center',
            '&::after': {
                content: '" ."',
                animation: '$dots 1s steps(5, end) infinite;'
            }
        },
    }),
);

const mobileStepperTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
    },
})

interface Props {
    open: boolean,
    onClickClose: () => void,
    onMyAvatarChange: (yes: boolean, value?: number) => void;
    backGroundObjectId: number,
    avatarId: number,
}

// 公開したいメソッドの定義
export interface ChildHandler {
    //setTutorialOpen: (open: boolean) => void
}

const AvatarCustomizeDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const [hairParts, setHairParts] = useState([] as AvatarPartsInfo[]);
    const [faceParts, setFaceParts] = useState([] as AvatarPartsInfo[]);
    const [bodyParts, setBodyParts] = useState([] as AvatarPartsInfo[]);
    const [hairOrnamentsParts, setHairOrnamentsParts] = useState([] as AvatarPartsInfo[]);
    const [belongingsParts, setBelongingsParts] = useState([] as AvatarPartsInfo[]);
    const [tabIndex, setTabIndex] = useState(0);
    const indexParts = [hairParts ,faceParts, bodyParts, hairOrnamentsParts, belongingsParts];
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        /*setTutorialOpen: (open: boolean) => {
        }*/
    }));

    // アバター設定画面を開いたとき、利用可能なアバターパーツの情報(リスト)を取得
    useEffect(() => {
        async function get() {
            await getAvatarPartsList();
        }

        if(!props.open){
            return;
        }
        get();
    }, [props.open])

    const getAvatarPartsList = async () => {
        // let params = new URLSearchParams();
        // params.append('tab_id', sessionStorage.getItem("TABID") as string);
        // await axios.post('/api/user/avatar/parts/list', params)
        httpClient.getAvatarPartsList(sessionStorage.getItem("TABID") as string)
            .then(response => {
            console.log(response);
            let res = response as AvatarPartsInfo[];

            // 送られたアバターパーツ情報のリストからパーツ種類ごとのリストを作成する
            let hairList = res.filter(function(parts) {
                return parts.partsType === 0;
            });
            setHairParts(hairList);
            let faceList = res.filter(function(parts) {
                return parts.partsType === 1;
            });
            setFaceParts(faceList);
            let bodyList = res.filter(function(parts) {
                return parts.partsType === 2;
            });
            setBodyParts(bodyList);
            let hairOrnamentsList = res.filter(function(parts) {
                return parts.partsType === 3;
            });
            setHairOrnamentsParts(hairOrnamentsList);
            let belongingsList = res.filter(function(parts) {
                return parts.partsType === 4;
            });
            setBelongingsParts(belongingsList);

        }).catch(err => {
            console.log(err);
        });
    }

    const createAndSaveAvatar = async () => {
        let hairId = hairParts[hairParts.findIndex(e => e.selected === true)].id;
        let faceId = faceParts[faceParts.findIndex(e => e.selected === true)].id;
        let bodyId = bodyParts[bodyParts.findIndex(e => e.selected === true)].id;
        let hairOrnamentsId = hairOrnamentsParts[hairOrnamentsParts.findIndex(e => e.selected === true)].id;
        let belongingsId = belongingsParts[belongingsParts.findIndex(e => e.selected === true)].id;

        // let params = new URLSearchParams();
        // params.append('tab_id', sessionStorage.getItem("TABID") as string);
        // params.append('hair_id', hairId.toString());
        // params.append('face_id', faceId.toString());
        // params.append('body_id', bodyId.toString());
        // params.append('hair_ornaments_id', hairOrnamentsId.toString());
        // params.append('belongings_id', belongingsId.toString());
        
        // await axios.post('/api/user/avatar/customize', params)
        httpClient.createAvatarImage(sessionStorage.getItem("TABID") as string, hairId, faceId, bodyId, hairOrnamentsId, belongingsId)
            .then(response => {
            console.log(response);
            // 生成したアバターのIDもしくは、生成済みのアバターのIDが返ってくる
            let res = response as number;
            // 生成したアバターのIDもしくは、生成済みのアバターのIDを新たなアバターとしてセットする
            props.onMyAvatarChange(true, res);

        }).catch(err => {
            console.log(err);
        });
    }

    const handleClose = () => {
        props.onClickClose();
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
        setTabIndex(newTabIndex);
    };

    const handleClickAvatarParts = (id: number, index: number) => {
        if(index === 0){
            setHairParts((prevState) => prevState.map((obj) => (obj.id === id ? {...obj, selected: true} : {...obj, selected: false})));
        }else if(index === 1){
            setFaceParts((prevState) => prevState.map((obj) => (obj.id === id ? {...obj, selected: true} : {...obj, selected: false})));
        }else if(index === 2){
            setBodyParts((prevState) => prevState.map((obj) => (obj.id === id ? {...obj, selected: true} : {...obj, selected: false})));
        }else if(index === 3){
            setHairOrnamentsParts((prevState) => prevState.map((obj) => (obj.id === id ? {...obj, selected: true} : {...obj, selected: false})));
        }else if(index === 4){
            setBelongingsParts((prevState) => prevState.map((obj) => (obj.id === id ? {...obj, selected: true} : {...obj, selected: false})));
        }
    };

    const onOk = () => {
        async function create() {
            await createAndSaveAvatar();
        }

        if(hairParts.findIndex(e => e.selected === true) === -1 || faceParts.findIndex(e => e.selected === true) === -1 || bodyParts.findIndex(e => e.selected === true) === -1 || hairOrnamentsParts.findIndex(e => e.selected === true) === -1 || belongingsParts.findIndex(e => e.selected === true) === -1){
            // 髪、顔、体、髪飾り、持ち物で選んでないものが1つでもあればOKボタンはクリックできない
            return;
        }

        create();
    }

    const onAutoCreate = () => {
        // 髪パーツのランダム選択
        let representativeHairPartsList = hairParts.filter(e => e.id === e.examplePartsId);
        // 形による髪パーツリストの中から髪の形を決定
        let representativeHair = representativeHairPartsList[createRandomNum(representativeHairPartsList.length)];
        let sameTypeHairPartsList = hairParts.filter(e => e.examplePartsId === representativeHair.examplePartsId);
        // 色による髪パーツリストの中から髪の色を決定
        let hairRandomId = sameTypeHairPartsList[createRandomNum(sameTypeHairPartsList.length)].id;
        setHairParts((prevState) => prevState.map((obj) => (obj.id === hairRandomId ? {...obj, selected: true} : {...obj, selected: false})));


        // 顔パーツのランダム選択
        let representativeFacePartsList = faceParts.filter(e => e.id === e.examplePartsId);
        // 形による顔パーツリストの中から顔の形を決定
        let representativeFace = representativeFacePartsList[createRandomNum(representativeFacePartsList.length)];
        let sameTypeFacePartsList = faceParts.filter(e => e.examplePartsId === representativeFace.examplePartsId);
        // 色による顔パーツリストの中から顔の色を決定
        let faceRandomId = sameTypeFacePartsList[createRandomNum(sameTypeFacePartsList.length)].id;
        setFaceParts((prevState) => prevState.map((obj) => (obj.id === faceRandomId ? {...obj, selected: true} : {...obj, selected: false})));
        

        // 体パーツのランダム選択
        let representativeBodyPartsList = bodyParts.filter(e => e.id === e.examplePartsId);
        // 形による体パーツリストの中から体の形を決定
        let representativeBody = representativeBodyPartsList[createRandomNum(representativeBodyPartsList.length)];
        let sameTypeBodyPartsList = bodyParts.filter(e => e.examplePartsId === representativeBody.examplePartsId);
        // 色による体パーツリストの中から体の色を決定
        let bodyRandomId = sameTypeBodyPartsList[createRandomNum(sameTypeBodyPartsList.length)].id;
        setBodyParts((prevState) => prevState.map((obj) => (obj.id === bodyRandomId ? {...obj, selected: true} : {...obj, selected: false})));
        

        // 髪かざりパーツのランダム選択
        let representativeHairOrnamentsPartsList = hairOrnamentsParts.filter(e => e.id === e.examplePartsId);
        // 形による髪かざりパーツリストの中から髪かざりの形を決定
        let representativeHairOrnaments = representativeHairOrnamentsPartsList[createRandomNum(representativeHairOrnamentsPartsList.length)];
        let sameTypeHairOrnamentsPartsList = hairOrnamentsParts.filter(e => e.examplePartsId === representativeHairOrnaments.examplePartsId);
        // 色による髪かざりパーツリストの中から髪かざりの色を決定
        let hairOrnamentsRandomId = sameTypeHairOrnamentsPartsList[createRandomNum(sameTypeHairOrnamentsPartsList.length)].id;
        setHairOrnamentsParts((prevState) => prevState.map((obj) => (obj.id === hairOrnamentsRandomId ? {...obj, selected: true} : {...obj, selected: false})));
        

        // 持ち物パーツのランダム選択
        let representativeBelongingsPartsList = belongingsParts.filter(e => e.id === e.examplePartsId);
        // 形による持ち物パーツリストの中から持ち物の形を決定
        let representativeBelongigs = representativeBelongingsPartsList[createRandomNum(representativeBelongingsPartsList.length)];
        let sameTypeBelongingsPartsList = belongingsParts.filter(e => e.examplePartsId === representativeBelongigs.examplePartsId);
        // 色による持ち物パーツリストの中から髪の色を決定
        let belongingsRandomId = sameTypeBelongingsPartsList[createRandomNum(sameTypeBelongingsPartsList.length)].id;
        setBelongingsParts((prevState) => prevState.map((obj) => (obj.id === belongingsRandomId ? {...obj, selected: true} : {...obj, selected: false})));

    }

    const createRandomNum = (max: number) => {
        return Math.floor(Math.random()*max);
    }

    const drawAvatarPartsColor = (index: number) => {
        return(indexParts[index].findIndex(res => res.selected === true) !== -1 ?
            <Grid container direction='column' alignContent='center' spacing={1} style={{paddingBottom: 5, paddingTop: 10 ,width: '100%', height: '100%', justifyContent: 'center', overflowX: 'auto', overflowY: 'hidden'}} >
                {
                    indexParts[index].filter(e => e.examplePartsId === indexParts[index][indexParts[index].findIndex(res => res.selected === true)].examplePartsId).map((e) => {
                        return(
                            <Grid item>
                                {!e.selected ?
                                    <div style={{width: 20, height:20, background: e.colorCode, border: '1px solid #000000', borderRadius: '3px', }} onClick={() => handleClickAvatarParts(e.id,index)}></div>
                                    :
                                    <div style={{width: 20, height:20, background: e.colorCode, border: '2px solid #57BBFF', borderRadius: '3px', }} onClick={() => handleClickAvatarParts(e.id,index)}></div>
                                }
                            </Grid>
                        )
                    })  
                }
            </Grid>
            :
            <></>
        );
    }

    const drawAvatarPartsList = (index: number) => {
        return(
            <InfiniteScroll
                scrollableTarget="avatarPartsWindow"
                dataLength={indexParts[index].length}
                next={() => { }}
                style={{
                    flexDirection: 'row',
                    justifyContent: indexParts[index].length === 0 ? "center" : "flex-start",
                    alignItems: indexParts[index].length === 0 ? "center" : "flex-start",
                    paddingLeft: 12,
                }}
                inverse={false}
                hasMore={false}// scrollによる追加取得なし
                loader={<h4 className={classes.loading}>loading</h4>}
            >
                <Grid container>
                {indexParts[index].filter(e => e.id === e.examplePartsId).map((e) => {
                    // filterにより各パーツの代表しか表示しない
                    return(
                        e.none ?
                            (!e.selected ?
                                <Grid item>
                                    <div className={classes.avatarPartsNone} onClick={() => handleClickAvatarParts(e.id, index)} >なし</div>
                                </Grid>
                                :
                                <Grid item>
                                    <div className={classes.avatarPartsNoneSelected} onClick={() => handleClickAvatarParts(e.id, index)} >なし</div>
                                </Grid>
                            )
                            :
                            indexParts[index].filter(res => res.examplePartsId === e.examplePartsId).findIndex(res => res.selected === true) === -1 ?
                                <Grid item>
                                    {/*<img src={`/api/user/avatar/parts/${e.id}`} className={classes.avatarPartsImg} alt="" onClick={() => handleClickAvatarParts(e.id,index)} />*/}
                                    <img src={`${httpClient.createAvatarPartsImgUrl(e.id)}`} className={classes.avatarPartsImg} alt="" onClick={() => handleClickAvatarParts(e.id,index)} />
                                </Grid>
                                :
                                <Grid item>
                                    {/*<img src={`/api/user/avatar/parts/${e.id}`} className={classes.avatarPartsImgSelceted} alt="" onClick={() => handleClickAvatarParts(e.id,index)} />*/}
                                    <img src={`${httpClient.createAvatarPartsImgUrl(e.id)}`} className={classes.avatarPartsImgSelceted} alt="" onClick={() => handleClickAvatarParts(e.id,index)} />
                                </Grid>
                        )
                })}
                </Grid>
            </InfiniteScroll>
        );
    }

    const draw = useMemo(() =>
        <BaseDialog
            id="AvatarCustomizeDialog"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="AvatarCustomizeDialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                アバターきせかえ
            </DialogTitle>
            <DialogContent style={{overflowY: 'hidden'}}>
                <div style={{
                    height: 'auto',
                    width: '100%',
                    //backgroundImage: "url(./api/user/object/picture/" + props.backGroundObjectId + ")",
                    backgroundImage: `url(${httpClient.createObjectImgUrl(props.backGroundObjectId, undefined, sessionStorage.getItem("TABID") as string)})`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', 
                }}>
                    {hairParts.findIndex(e => e.selected === true) === -1 && faceParts.findIndex(e => e.selected === true) === -1 && bodyParts.findIndex(e => e.selected === true) === -1 && hairOrnamentsParts.findIndex(e => e.selected === true) === -1 && belongingsParts.findIndex(e => e.selected === true) === -1 ?
                        //<img src={`/api/user/avatar/picture/${props.avatarId}`} alt='' style={{width: 60, height: 'auto' }} />
                        <img src={`${httpClient.createAvatarImgUrl(props.avatarId)}`} alt='' style={{width: 60, height: 'auto' }} />
                        :
                        <div style={{width: 60, height: 105}}>
                            <div id={`avator-image-hairOrnaments`}
                                className={classes.avatarPreview}
                                style={{
                                    //backgroundImage: hairOrnamentsParts.findIndex(e => e.selected === true) === -1 ? "" : `url("/api/user/avatar/parts/${hairOrnamentsParts[hairOrnamentsParts.findIndex(e => e.selected === true)].id}")`,
                                    backgroundImage: hairOrnamentsParts.findIndex(e => e.selected === true) === -1 ? "" : `url(${httpClient.createAvatarPartsImgUrl(hairOrnamentsParts[hairOrnamentsParts.findIndex(e => e.selected === true)].id)})`,
                                    zIndex: 510,
                                }}
                            />
                            <div id={`avator-image-hair`}
                                className={classes.avatarPreview}
                                style={{
                                    //backgroundImage: hairParts.findIndex(e => e.selected === true) === -1 ? "" : `url("/api/user/avatar/parts/${hairParts[hairParts.findIndex(e => e.selected === true)].id}")`,
                                    backgroundImage: hairParts.findIndex(e => e.selected === true) === -1 ? "" : `url(${httpClient.createAvatarPartsImgUrl(hairParts[hairParts.findIndex(e => e.selected === true)].id)})`,
                                    zIndex: 500,
                                    marginTop: 0,
                                }}
                            />
                            <div id={`avator-image-face`}
                                className={classes.avatarPreview}
                                style={{
                                    //backgroundImage: faceParts.findIndex(e => e.selected === true) === -1 ? "" : `url("/api/user/avatar/parts/${faceParts[faceParts.findIndex(e => e.selected === true)].id}")`,
                                    backgroundImage: faceParts.findIndex(e => e.selected === true) === -1 ? "" : `url(${httpClient.createAvatarPartsImgUrl(faceParts[faceParts.findIndex(e => e.selected === true)].id)})`,
                                    zIndex: 420,
                                    marginTop: 0,
                                }}
                            />
                            <div id={`avator-image-belongings`}
                                className={classes.avatarPreview}
                                style={{
                                    //backgroundImage: belongingsParts.findIndex(e => e.selected === true) === -1 ? "" : `url("/api/user/avatar/parts/${belongingsParts[belongingsParts.findIndex(e => e.selected === true)].id}")`,
                                    backgroundImage: belongingsParts.findIndex(e => e.selected === true) === -1 ? "" : `url(${httpClient.createAvatarPartsImgUrl(belongingsParts[belongingsParts.findIndex(e => e.selected === true)].id)})`,
                                    zIndex: 515,
                                    marginTop: 0,
                                }}
                            />
                            <div id={`avator-image-body`}
                                className={classes.avatarPreview}
                                style={{
                                    //backgroundImage: bodyParts.findIndex(e => e.selected === true) === -1 ? "" : `url("/api/user/avatar/parts/${bodyParts[bodyParts.findIndex(e => e.selected === true)].id}")`,
                                    backgroundImage: bodyParts.findIndex(e => e.selected === true) === -1 ? "" : `url(${httpClient.createAvatarPartsImgUrl(bodyParts[bodyParts.findIndex(e => e.selected === true)].id)})`,
                                    zIndex: 410,
                                    marginTop: 0
                                }}
                            />
                        </div>
                    }
                </div>
                <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' >
                    <Tab label="かみがた" style={{minWidth: '18%'}}/>
                    <Tab label="かお" style={{minWidth: '18%'}}/>        
                    <Tab label="ふく" style={{minWidth: '18%'}}/>                
                    <Tab label="かざり" style={{minWidth: '18%'}}/>                
                    <Tab label="もちもの" style={{minWidth: '18%'}}/>                        
                </Tabs>
                <div 
                    id = 'avatarPartsColorWindow'
                    className={classes.avatarPartsColorWindowDiv}
                >
                    {drawAvatarPartsColor(tabIndex)}
                </div>
                <div 
                    id = 'avatarPartsWindow'
                    className={classes.avatarPartsListDialogDiv}
                >
                    {tabIndex === 0 && drawAvatarPartsList(tabIndex)}
                    {tabIndex === 1 && drawAvatarPartsList(tabIndex)}
                    {tabIndex === 2 && drawAvatarPartsList(tabIndex)}
                    {tabIndex === 3 && drawAvatarPartsList(tabIndex)}
                    {tabIndex === 4 && drawAvatarPartsList(tabIndex)}
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item sm={6}>
                        <Button onClick={onAutoCreate} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100}}>おまかせ</Button>
                    </Grid>
                    <Grid item sm={3} style={{marginTop: 3}}>
                        <Button onClick={onOk} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                    </Grid>
                    <Grid item sm={3}>
                        <Button onClick={handleClose} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.open, hairParts, faceParts, bodyParts, hairOrnamentsParts, belongingsParts ,tabIndex]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const AvatarCustomizeDialogRef = forwardRef(AvatarCustomizeDialog);

import { NoEncryption } from "@material-ui/icons";
import React, { Fragment } from "react";

export interface Props {
  otherWebsiteUrl: string;
  zIndex: number;
  left: number;
  top: number;
  width: number;
  height: number;
}

export default function OtherWebsite(props: Props) {
  const { otherWebsiteUrl, zIndex, left, top, width, height } =
    props;

  const drawOtherWebsite = React.useMemo(() => {
    return (
        <iframe
          style={{
            position: "absolute",
            top: top,
            left: left,
            width: width,
            height: height,
            transformOrigin: "0 0",
            transform: "scale(1)",
            zIndex: zIndex,
            border: "none",
          }}
          id="otherwebsite"
          title="OtherWebsiteObject"
          src={otherWebsiteUrl}
          frameBorder="0"
          allow="accelerometer; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherWebsiteUrl, left, top, width, height]);

  return <Fragment>{drawOtherWebsite}</Fragment>;
}

import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, makeStyles, MenuItem, OutlinedInput, Select, Switch, TextField, Theme } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useCallback, useEffect, useMemo, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import BaseDialog from '../user/BaseDialog';
import { userListConst } from './UserListConst';
import { SELECT_NONE } from "../common/Constants";
import ClearIcon from '@material-ui/icons/Clear';
import JfsClient from "@fsi/jfs-sdk";

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    backgroundColor: 'rgba(0, 0, 255, 1)',
};

class ConfirmInfo {
    open: boolean = true;
    message: string = "";
    isOk: boolean = false;

    constructor(init: Partial<ConfirmInfo>) {
        Object.assign(this, init);
    }
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;
    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
    }),
);

interface Props {
    selectedRowIds: number[];
    updateAll: boolean;
    handleReloadPointManager: (page: number) => void;
}

export interface PointManagerDialogHandler {
    open: (ids: number[], all: boolean) => void;
}

// export default function OtherUsers() {
const PointManagerDialogComponent: ForwardRefRenderFunction<PointManagerDialogHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const { history } = useReactRouter();
    const [open, setOpen] = useState(false);

    const memoMaxLength = 256;
    const [selectedIds, setSelectedIds] = useState([] as number[]);
    const [updateAll, setUpdateAll] = useState(false);
    const [memo, setMemo] = useState("");
    const [tempMemo, setTempMemo] = useState("");

    const [isLoading, setLoading] = useState(false);
    const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
      /** エラー画面用 */
    const [errorOpened, setErrorOpened] = React.useState(false);
    const [errorData, setErrorData] = React.useState({
        code: '',
        message: '',
        errorDetail: [],
    });

    const [ state, setState ] = useState({
        selectedIds: [],
        point: 0,
    });

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // ポイント更新の入力データをクリア
    const clearState = () => {
        setState({...state, 
            selectedIds: [],
            point: 0,
        })
        onClearMemo();
    }

    useImperativeHandle(ref, () => ({
        open: (ids: number[], all: boolean) => {
            setSelectedIds(ids);
            setUpdateAll(all);
            setOpen(true);
        },
    }))

    useEffect(() => {
        setSelectedIds(props.selectedRowIds);
        setUpdateAll(props.updateAll);
    },[props.selectedRowIds, props.updateAll])

    useEffect(() => {
        if(props.selectedRowIds.length > 0 || updateAll){
            if(confirmInfo.isOk){
                setLoading(true);
                handleUpdatePointSetting();
            }
        }
    }, [confirmInfo.isOk])

    const handleClose = () => {
        clearState();
        setOpen(false);
    };

    const checkPointSetting = () => {
        let errMessage = "";
        if(Number(state.point) === 0 || !Number.isInteger(Number(state.point))){
            errMessage = "ポイント入力ルールが不正です";
        }else if(Number(state.point) > 100 || Number(state.point) < -100){
            errMessage = "増やす または 減らすポイントは-100～100で指定してください";
        }else if(memo.length === 0){
            errMessage = "ポイント変更理由が未入力です";
        }
        return errMessage;
    }

    const confirmUpdate = () => {
        // alert("enable:" + state.enable);
        let selectUser = "";
        if(updateAll){
            selectUser = "すべてのユーザー";
        }else{
            selectUser = "選択したユーザー";
        }
        setConfirmInfo(new ConfirmInfo({
            message: selectUser + "のポイントを更新します。よろしいですか？"
        }));
    }

    // ポイント自動付与設定チェック処理
    const handleCheckPointSetting = () => {
        // alert("index:" + props.currentRowNo);
        let message = checkPointSetting();
        if( message === ""){
            confirmUpdate();
        }else{
            setFinishInfo(new FinishInfo({message: message}));
        }
    }
    
    // ポイント自動付与設定更新処理
    const handleUpdatePointSetting = () => {        
        // const params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("selectedIds", selectedIds.toString());
        // params.append("updateAll", updateAll ? "true" : "false");
        // params.append("point", state.point.toString());
        // params.append("memo", memo);

        // axios.post('/api/v1/admin/pointmanager/update', params)
        const tabId = sessionStorage.getItem("TABID") as string;
        httpClient.updatePointSetting(
            tabId,
            selectedIds,
            updateAll,
            state.point,
            memo,
        )
        .then((e: string) => {
            setFinishInfo(new FinishInfo({message: "更新が完了しました"}));
            props.handleReloadPointManager(1);
        })
        .catch(err => {
            assignErrorData(err);
        })
        .finally(() => {
            setLoading(false);
        });
        clearState();
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        // alert(name + ":" + target.value);
        setState(() => {
            return {...state, [name]: target.value };
        });
    }

    const handlePointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        const value = target.value;
    
        // 入力値がマイナス符号を含む整数かどうかをチェック
        if (/^-?\d+$/.test(value)) {
            setState(prevState => {
                return {...prevState, [name]: value};
            });
        }
    }

    /**
     * APIエラーハンドル 共通
     * @param err エラーオブジェクト
     */
    const assignErrorData = (err: any) => {
        const errorData = {
            code: '',
            message: userListConst.unexpectedMessage,
            errorDetail: [],
        }

        if (err.response) {
            if (err.response.status === 403) {
            Object.assign(errorData, userListConst.responseError403);
            } else if (err.response.status >= 500) {
            // errorDataを書き換えない
            } else if (!err.response.data?.hasOwnProperty('message')) {
            // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
            // 格納されて送信されてくるので、オブジェクト形式へ整形
            Object.assign(errorData, { message: err.response.data });
            } else {
            Object.assign(errorData, err.response.data);
            }
            setErrorData(errorData);
            setErrorOpened(true);
            return;
        } else if (err.request) {
            Object.assign(errorData, userListConst.requestError);
            setErrorData(errorData);
            setErrorOpened(true);
            return;
        }
    }

    const onClearMemo = useCallback(() => {
        setMemo('');
        setTempMemo('');
    }, []);

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
        return len
    };

    // ポイント変更理由の文字数制御
    const memoLength = useMemo(() => {
        let len = judgehz(memo)
        if (len === 0){
            return (
                <div style={{ color: "red", }}>ポイント変更理由は必須入力項目です。</div>
            );
        }else if (memoMaxLength < len) {
            return (
                <div style={{ color: "red" }}>入力した文字列が長すぎます。</div>
            );
        }
    }, [memo]);

    const progressBarMemo = useMemo(() => {
        let parsent = (judgehz(memo) / memoMaxLength) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = 'rgba(0, 0, 255, 0.3)';
        }

        return (
            <Grid container>
                <div style={{ flexGrow: 1 }}></div>
                {memoLength}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <LinearProgress
                        variant="determinate"
                        value={parsent}
                        style={{
                            ...LinearProgressStyle,
                            backgroundColor: progressColor,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }, [memo])


    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={"md"}>
                <DialogTitle id="form-dialog-title">ポイント変更</DialogTitle>
                <DialogContent>
                    {updateAll ? 
                        <Grid container style={{marginBottom: "24px"}}>
                            <Grid item xs={10} style={{marginLeft: "20px", fontSize: "12pt", color: "red"}}>
                                <b>【※注意】全ての一般ユーザーのポイントが更新されます。</b>
                            </Grid>
                        </Grid>
                     : ""
                    }
                    <Grid container>
                        <Grid item xs={6} style={{marginTop: "8px", marginLeft: "40px", marginRight: "40px"}}>
                            <div>増やす　または　減らすポイント</div>
                            <div>※利用可能ポイントの累計は、0より小さくなりません</div>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                margin="dense"
                                id="point"
                                name="point"
                                label="ポイント"
                                variant={'outlined'}
                                fullWidth
                                autoFocus
                                InputLabelProps={{ shrink: true }}
                                onChange={handlePointChange}
                                value={state.point}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={2} style={{marginTop: "20px", marginLeft: "20px"}}>ポイント</Grid>
                    </Grid>
                    <br></br>
                    <Grid container>
                        <Grid item xs={3} style={{marginTop: "10px", marginLeft: "40px"}}>ポイント変更理由</Grid>
                    </Grid>
                    <Grid container>
                        <DialogContent style={{ padding: "0px 25px 0px 25px", overflow: 'hidden'}}>
                            <DialogContentText>
                                {progressBarMemo}
                            </DialogContentText>
                            <TextField
                                id="outlined-basic"
                                label="ポイント変更理由"
                                variant="outlined"
                                multiline
                                // autoFocus
                                margin='dense'
                                fullWidth
                                value={memo}
                                autoComplete={'off'}
                                onChange={e => setMemo(e.target.value)}
                                style={{ marginTop: "-3px", marginLeft: "15px", pointerEvents: 'auto' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onClearMemo}>
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </DialogContent>
                    </Grid>
                </DialogContent>
                <DialogActions style={{marginTop: "30px", marginBottom: "15px", marginRight: "10px"}}>
                    <Button id="submit" onClick={handleCheckPointSetting} color="primary">　更新　</Button>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                </DialogActions>
            </Dialog>
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText>{confirmInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true }) }}>OK</Button>
                    <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
            <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
                <DialogContent>
                <DialogContentText>{finishInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
                </DialogActions>
            </BaseDialog>
        </div>
    )
}

export const PointManagerDialog = forwardRef(PointManagerDialogComponent);
import { Redirect, Route, RouteProps } from 'react-router';
import { Utility } from './Utility';

export default function PrivateRoute(props: RouteProps) {
    const signinpage = Utility.getSigninPage();

    //���̃R�[�h����ISLOGIN��false�������Ă��Ă��AisLogin��true�ɂȂ��Ă��܂��A�A�A
    //const  isLogin = Boolean(sessionStorage.getItem("ISLOGIN"));
    const buf2: any = sessionStorage.getItem("ISLOGIN");
    const  isLogin = (buf2 !== null) ? JSON.parse(buf2.toLowerCase()) : false;
    return isLogin ? <Route {...props} /> : <Redirect to={signinpage}/>;
};
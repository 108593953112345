import React, { Fragment, useRef, useState, useEffect, useMemo } from 'react'
import axios, { AxiosResponse } from 'axios';
import { withStyles, makeStyles, createStyles, Theme, useTheme, createMuiTheme } from '@material-ui/core/styles';
import {Backdrop, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, IconButton, Paper, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import BaseDialog from '../user/BaseDialog';

import { userListConst } from './UserListConst';
import { PointManagerDialog, PointManagerDialogHandler } from './PointManagerDialog';

import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import JfsClient from "@fsi/jfs-sdk";

export type HeadCell = {
  id: string;
  label: string;
};

type SelectableTableHeadProps = {
  onLabelClick: () => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  headCells: HeadCell[];
  checked: boolean;
  indeterminate: boolean;
};

export const SelectableTableHead: React.VFC<SelectableTableHeadProps> = ({
  onLabelClick,
  onSelectAllClick,
  headCells,
  checked,
  indeterminate,
}) => {
  return (
    <TableHead style={{width: 140, marginTop: -5,float: 'left'}}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={indeterminate}
            checked={checked}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} padding={"none"} onClick={onLabelClick}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useRowSelect = (
  rowIds: number[],
  initialSelectedRowIds: number[] = []
): {
  selectedRowIds: number[];
  isSelected: (rowId: number) => boolean;
  isSelectedAll: boolean;
  isIndeterminate: boolean;
  toggleSelected: (id: number) => void;
  toggleSelectedAll: () => void;
  toggleSelectedAllClear: () => void;
} => {
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>(
    initialSelectedRowIds
  );

  const isSelected = (rowId: number) => selectedRowIds.includes(rowId);
  const isSelectedAll =
    rowIds.length > 0 && selectedRowIds.length === rowIds.length;
  const isIndeterminate =
    selectedRowIds.length > 0 && selectedRowIds.length < rowIds.length;

  const toggleSelected = (rowId: number) => {
    isSelected(rowId)
      ? setSelectedRowIds(
          selectedRowIds.filter((selectedId) => selectedId !== rowId)
        )
      : setSelectedRowIds([...selectedRowIds, rowId]);
  };
  const toggleSelectedAll = () => {
    isSelectedAll ? setSelectedRowIds([]) : setSelectedRowIds(rowIds);
  };
  const toggleSelectedAllClear = () => {
    setSelectedRowIds([]);
  };

  return {
    selectedRowIds,
    isSelected,
    isSelectedAll,
    isIndeterminate,
    toggleSelected,
    toggleSelectedAll,
    toggleSelectedAllClear,
  };
};

class FinishInfo {
  open: boolean = true;
  message: string = "";
  password: null | string = null;

  constructor(init: Partial<FinishInfo>) {
    Object.assign(this, init);
  }
}

class Tag {
  item: string = '';
  value: string = '';
  profileOrder: number = 0;
}

class SearchData {
  public subId: string = '';
  public loginId: string = '';
  public displayName: string = '';
  public tag1: string = '';
  public tag2: string = '';
  public tag3: string = '';
  public tags: Tag[] = [];
}

export default function PointManager() {
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;
  const tabId = sessionStorage.getItem('TABID') || '';
  const classes = useStyles();

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);

  const [pointSummaryList, setPointManagerList] = React.useState<PointSummaryData[]>([]);
  const [pointSummaryListOrg, setPointManagerListOrg] = React.useState<PointSummaryData[]>([]);
  const [updateAll, setUpdateAll] = React.useState(false);

  /** pagenation */
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(userListConst.table.rowsPerPage);
  /** message */
  const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);
  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });
    const clearErrorData = () => {
    errorData.code = '';
    errorData.message = '';
    if (errorData.errorDetail === null || errorData.errorDetail === undefined) {
      errorData.errorDetail = [];
    } else {
      errorData.errorDetail.length = 0;
    }
    return errorData;
  }
  
  /** 検索用 */
  const [searchData, setSearchData] = React.useState(new SearchData());
  const clearSearchData = () => {
    // stateへの直接書き込みはReactのanti paternなので修正を行ったが、修正後動作確認で、
    // 「全ユーザーリストダウンロード」の挙動が変わってしまったため、修正前のロジックへ戻した。
    searchData.subId = '';
    // searchData.loginId = '';
    searchData.displayName = '';
    searchData.tag1 = '';
    searchData.tag2 = '';
    searchData.tag3 = '';
    searchData.tags.length = 0;
    setSearchData(Object.assign({}, searchData));
    return searchData;
  }
  const clearSearchElement = () => {
    /*
    let loginId_search = document.getElementById('loginId_search') as HTMLInputElement;
    if (loginId_search != null) {
      loginId_search.value = '';
    }
    */
    let displayName_search = document.getElementById('displayName_search') as HTMLInputElement;
    if (displayName_search != null) {
      displayName_search.value = '';
    }
    let tag1_search = document.getElementById('tag1_search') as HTMLInputElement;
    if (tag1_search != null) {
      tag1_search.value = '';
    }
    let tag2_search = document.getElementById('tag2_search') as HTMLInputElement;
    if (tag2_search != null) {
      tag2_search.value = '';
    }
    let tag3_search = document.getElementById('tag3_search') as HTMLInputElement;
    if (tag3_search != null) {
      tag3_search.value = '';
    }
  }

  /** TablePaginationActions で使用 */
  const handleChangePage = (event: any, newPage: number) => {
    executeSearchByPage(newPage + 1); // pagenationのpageは0が先頭, APIのpageは1が先頭
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  const refPointManagerDialog = useRef({} as PointManagerDialogHandler);

  // ポイント管理一覧表示
  const fetchPointManagerList = async (page: number) => {
    // axios.get('/api/v1/admin/pointmanager/list', {
    //   params: {
    //     loginId: searchData.loginId,
    //     name: searchData.displayName,
    //     page: page,
    //     count: rowsPerPage,
    //     tabId: tabId,
    //   }
    // })
    httpClient.getPointManagerList(
      tabId,
      // searchData.loginId,
      searchData.displayName,
      searchData.tag1,
      searchData.tag2,
      searchData.tag3,
      page,
      rowsPerPage,
    )
    .then((res) => {
      const _pointSummaryList = res.pointSummary
        ? res.pointSummary.map((v: any, i: number, d: any) => {
          const pointSummary: PointSummaryData = new PointSummaryData();
          pointSummary.userId = v.userId;
          pointSummary.loginId = v.loginId;
          pointSummary.displayName = v.displayName;
          pointSummary.summaryPoint = v.additionalSummary + v.deductionalSummary;
          pointSummary.additionalSummary = v.additionalSummary;
          pointSummary.monthlySummary = v.monthlySummary;
          pointSummary.tag1 = v.tag1;
          pointSummary.tag1Value = v.tag1Value;
          pointSummary.tag2 = v.tag2;
          pointSummary.tag2Value = v.tag2Value;
          pointSummary.tag3 = v.tag3;
          pointSummary.tag3Value = v.tag3Value;
          pointSummary.tag4 = v.tag4;
          pointSummary.tag4Value = v.tag4Value;
          pointSummary.tag5 = v.tag5;
          pointSummary.tag5Value = v.tag5Value;
          pointSummary.tag6 = v.tag6;
          pointSummary.tag6Value = v.tag6Value;
          pointSummary.tag7 = v.tag7;
          pointSummary.tag7Value = v.tag7Value;
          pointSummary.tag8 = v.tag8;
          pointSummary.tag8Value = v.tag8Value;
          pointSummary.tag9 = v.tag9;
          pointSummary.tag9Value = v.tag9Value;
          pointSummary.tag10 = v.tag10;
          pointSummary.tag10Value = v.tag10Value;
          return pointSummary;
        })
      : [] as PointSummaryData[];
      if (res.pageInfo) setTotalCount(res.pageInfo.totalCount);

      setPointManagerList((prev) => _pointSummaryList);
      setPointManagerListOrg(JSON.parse(JSON.stringify(_pointSummaryList)));
      setLoading(false);

    }).catch(err => {
      assignErrorData(err);
    })
  }

  /**
  * useEffect
  */
  useEffect(() => {
    setCrudType(0);
    setLoading(true);
    executeSearchByPage(1);
  }, []);

  useEffect(() => {
    executeSearchByPage(1);
  }, [rowsPerPage]);

  // 処理中表示
  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  // ポイント管理一覧のリロード
  const handleReloadPointManager = (page: number) => {
    setLoading(true);
    fetchPointManagerList(page);
    setPage(page - 1);
    toggleSelectedAllClear();
  }

  // 選択したユーザーへのポイント更新
  const handlePoint = () =>{
    if(selectedRowIds.length > 0){
      setUpdateAll(false);
      refPointManagerDialog.current.open(selectedRowIds, false);
    }else{
      setFinishInfo(new FinishInfo({message: "変更対象のユーザーが選択されていません"}));
    }
  }

  // 全ユーザーへのポイント更新
  const handlePointAll = () =>{
    setUpdateAll(true);
    refPointManagerDialog.current.open(selectedRowIds, true);
  }

  
  /**
   * 検索ボタンのハンドラ
   * 新規検索を行い、検索結果の先頭ページを表示する
   */
  const handleSearchBtn = () => executeSearchByPage(1);
  const handleClearBtn = () => clearSearchText();

  /** 詳細画面の機能名、ボタンのラベルに使う */
  const [detailLabel, setDetailLabel] = React.useState("");
  const setDetailName = (detailFuncNum: number) => {
    switch (detailFuncNum) {
      case 0:
        setDetailLabel("追加");
        break;
      case 1:
        setDetailLabel("編集");
        break;
      case 2:
        setDetailLabel("削除");
        break;
      case 3:
        setDetailLabel("詳細");
        break;
      default:
        setDetailLabel("");
        break;
    }
  }

  /** ユーザー検索を実行し、ページを設定する
   * @param page
   */
  const executeSearchByPage = async (page: number) => {
    clearErrorData();
    clearSearchData();
    setSearchData(Object.assign(searchData, {
      // loginId: (document.getElementById('loginId_search') as HTMLInputElement)?.value,
      displayName: (document.getElementById('displayName_search') as HTMLInputElement)?.value,
      tag1: (document.getElementById('tag1_search') as HTMLInputElement)?.value,
      tag2: (document.getElementById('tag2_search') as HTMLInputElement)?.value,
      tag3: (document.getElementById('tag3_search') as HTMLInputElement)?.value,
    }));
    await handleReloadPointManager(page);
    setPage(page - 1);
    toggleSelectedAllClear();
    return true;
  }

  // 検索項目クリア後にデータ全表示
  const clearSearchText = async () => {
    clearSearchElement();
    executeSearchByPage(1);
    return true;
  }

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  const {
    selectedRowIds,
    isSelected,
    isSelectedAll,
    isIndeterminate,
    toggleSelected,
    toggleSelectedAll,
    toggleSelectedAllClear,
  } = useRowSelect(pointSummaryList.map((row) => row.userId));

  return (
    <Fragment>
    <div style={{marginTop: -20}}>
      <Card className={classes.root} style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10}}>
        <CardHeader
        title='ポイント管理'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      <Fragment>
          <div className={classes.search}>
            {/*<TextField id="loginId_search" style={{float: 'left', minWidth: 45}} size="small" label="ID" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;*/}
            <TextField id="displayName_search" style={{minWidth: 70}} size="small" label="表示名" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <TextField id="tag1_search" style={{float: 'left', minWidth: 70}} size="small" label="タグ１" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <TextField id="tag2_search" style={{float: 'left', minWidth: 70}} size="small" label="タグ２" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <TextField id="tag3_search" style={{float: 'left', minWidth: 70}} size="small" label="タグ３" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <Button id="search" style={{marginLeft: 2, float: 'left'}} variant="contained" color="primary" onClick={handleClearBtn}>クリア</Button>
            <Button id="search" style={{marginLeft: 2, float: 'left'}} variant="contained" color="primary" onClick={handleSearchBtn}>検索</Button>
          </div><br/>
          <SelectableTableHead
              onLabelClick={toggleSelectedAll}
              onSelectAllClick={toggleSelectedAll}
              headCells={headCells}
              checked={isSelectedAll}
              indeterminate={isIndeterminate}
          />
          <Button id="search" style={{marginRight: 10, marginTop: -5, float: 'right'}} variant="contained" color="primary" onClick={handlePoint}>ポイント変更</Button>
          <Button id="search" style={{marginRight: 10, marginTop: -5, float: 'right'}} variant="contained" color="primary" onClick={handlePointAll}>全ユーザー一括ポイント変更</Button>
          <TableContainer style={{ height: `calc(97vh - 280px)`}}>
            <Table stickyHeader aria-label="customized table" style={{tableLayout:'fixed'}}>
                <TableHead>
                <TableRow>
                    <StyledTableCell key = 'check' style={{minWidth: 80,maxWidth: 80,width: 80,}}></StyledTableCell>
                    {/*<StyledTableCell key = 'userId' style={{minWidth: 300,maxWidth: 300,width: 300}}>ユーザーID</StyledTableCell>*/}
                    <StyledTableCell key = 'displayName' style={{minWidth: 150,maxWidth: 150,width: 150,}}>表示名</StyledTableCell>
                    <StyledTableCell key = 'tag1' style={{minWidth: 100,maxWidth: 100,width: 100,}}>タグ１</StyledTableCell>
                    <StyledTableCell key = 'tag2' style={{minWidth: 100,maxWidth: 100,width: 100,}}>タグ２</StyledTableCell>
                    <StyledTableCell key = 'tag3' style={{minWidth: 100,maxWidth: 100,width: 100,}}>タグ３</StyledTableCell>
                    <StyledTableCell key = 'AdditionalSummary' style={{minWidth: 150,maxWidth: 150,width: 150,textAlign: "center"}}>今月のポイント</StyledTableCell>
                    <StyledTableCell key = 'summaryPoint' style={{minWidth: 150,maxWidth: 150,width: 150,textAlign: "center"}}>利用可能ポイント</StyledTableCell>
                    <StyledTableCell key = 'AdditionalSummary' style={{minWidth: 150,maxWidth: 150,width: 150,textAlign: "center"}}>累計獲得ポイント</StyledTableCell>
                    <StyledTableCell key = 'temp1' style={{minWidth: 100,maxWidth: 100,width: 100,}}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pointSummaryList.map((pointManager: PointSummaryData, rowIdx) => {
                  const isItemSelected = isSelected(pointManager.userId);

                  return (
                    <StyledTableRow 
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={pointManager.userId}
                      onClick={() => toggleSelected(pointManager.userId)}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      {/*
                      <StyledTableCell style={{marginTop: 300}}>
                        {pointManager.loginId}
                      </StyledTableCell>
                      */}
                      <StyledTableCell style={{textAlign: "left"}}>
                        {pointManager.displayName}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "left"}}>
                        {pointManager.tag1Value}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "left"}}>
                        {pointManager.tag2Value}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "left"}}>
                        {pointManager.tag3Value}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "center"}}>
                        {pointManager.monthlySummary}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "center"}}>
                        {pointManager.summaryPoint}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "center"}}>
                        {pointManager.additionalSummary}
                      </StyledTableCell>
                      <StyledTableCell>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"1ページの表示人数"}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
      </Fragment>
      {drawLoading}
    </div>
    <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
      <DialogContent>
        <DialogContentText>{finishInfo.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
      </DialogActions>
    </BaseDialog>
    <PointManagerDialog ref={refPointManagerDialog} selectedRowIds={selectedRowIds} updateAll={updateAll} handleReloadPointManager={() => {handleReloadPointManager(1)}} />
    </Fragment>
  )
}

const headCells: HeadCell[] = [
  {
    id: "check",
    label: "画面内全選択",
  },
];

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    overflowWrap: 'break-word',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
  },
  content: {
    //    flexShrink: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    tableLayout:'fixed',
  },
  search: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

// UI表示用
class PointSummaryData {
  userId: number = 0;
  loginId: string = "";
  displayName: string = "";
  summaryPoint: number = 0;
  additionalSummary: number = 0;
  deductionalSummary: number = 0;
  monthlySummary: number = 0;
  tag1: string = "";
  tag1Value: string = "";
  tag2: string = "";
  tag2Value: string = "";
  tag3: string = "";
  tag3Value: string = "";
  tag4: string = "";
  tag4Value: string = "";
  tag5: string = "";
  tag5Value: string = "";
  tag6: string = "";
  tag6Value: string = "";
  tag7: string = "";
  tag7Value: string = "";
  tag8: string = "";
  tag8Value: string = "";
  tag9: string = "";
  tag9Value: string = "";
  tag10: string = "";
  tag10Value: string = "";
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
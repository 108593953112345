/**
 * フロアをコピーする機能を提供するページ
 */
import { Button, Grid, TextField } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import React, { Fragment, useState } from 'react';

export default function CopyFloor() {
    const { history } = useReactRouter();

    const [state, setState] = useState({
        sourceFloorId: "",
        targetOfficeId: "",
        floorName: "",
        tatefudaFloorName: "",
        targetObjectText: "",
        replaceObjectText: "",
    })

    const handleCopyFloor = () => {
        var params = new URLSearchParams();
        params.append("source_floor_id", state.sourceFloorId.toString());
        params.append("target_office_id", state.targetOfficeId.toString());
        params.append("floor_name", state.floorName);
        params.append("target_object_text", state.targetObjectText);
        params.append("replace_object_text", state.replaceObjectText);
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        axios.post('/api/system/floor/copy', params)
            .then((e: AxiosResponse) => {
                history.push({
                    pathname: "/top",
                });
            }).catch(err => {
                alert(err.response.data)
            });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        setState({
            ...state,
            [name]: event.target.value,
        })
    }

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField onChange={handleChange} value={state.sourceFloorId} label="コピー元フロアID" name="sourceFloorId" type="number" required variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={handleChange} value={state.targetOfficeId} label="コピー先オフィスID" name="targetOfficeId" type="number" required variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={handleChange} value={state.floorName} label="フロア名" name="floorName" required variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={handleChange} value={state.targetObjectText} label="オブジェクテキスト１ 対象文字列" name="targetObjectText" required variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={handleChange} value={state.replaceObjectText} label="オブジェクトテキスト１ 置換文字列" name="replaceObjectText" required variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={handleCopyFloor} variant="contained" color="primary">フロアをコピーする</Button>
                </Grid>
            </Grid>
        </Fragment>
    )
}


import React, { useEffect, useState, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, ReactNode } from 'react'
import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import { createStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';

//import { Utility } from '../common/Utility';
import { DraggableFrame, DraggableFrameHandler } from '../common/DraggableFrame'
import ZIndex from '../common/ZIndex';
import { MemoGroup, Coord, Size } from '../common/JsonClass';
import { MEMO_FRAME_BG_COLOR } from '../common/Constants';
import JfsClient from '@fsi/jfs-sdk';

const dotColor = '#222'
const useStyles = makeStyles((theme) => createStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        userSelect: 'text',
        background: MEMO_FRAME_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: 4,
    },
    header: {
        height: 40,
        background: 'rgba(250,250,250,0.36)',
        boxShadow: '0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerItem: {
        height: '24px',
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    body: {
        width: '100%',
        height: `calc(100% - 40px)`,
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column',// 上が最新
    },
    bodyInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    endMessage: {
        color: dotColor,
        alignSelf: 'center',
    },
    loading: {
        color: dotColor,
        alignSelf: 'center',
        '&::after': {
            content: '" ."',
            animation: '$dots 1s steps(5, end) infinite;'
        }
    },
    // loading dot definition
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0,0,0,0)',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '40%': {
            color: dotColor,
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '60%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 rgba(0,0,0,0)`,
        },
        '80%,100%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 ${dotColor}`,
        },
    },
    unClicked: {
        fontWeight: 900,
    },
    clicked: {
        fontWeight: 'normal',
    },
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface IMemoGroup extends MemoGroup {
    isClicked: boolean;
}

interface Props {
    handleOpenMemo: (groupId: number, groupName: string) => void;
    setUnreadMemoCount: (count: number) => void;
    scrollbarWidth: number;
    defaultSize: Size;
    //frameSetting?: DraggableFrameProps;
    setToTop:(name: string) => void;
}

export interface MemoGroupListHandler {
    open: (defaultCoord: Coord) => void;
    close: () => void;
    getOpen: () => boolean;
    setCoord: (coord: Coord) => void;
}

// request
// const getMemoGroupList = async (tabId: string) => {
//     try {
//         const res: AxiosResponse<MemoGroup[]> = await axios.post('/api/user/memo/unread', {
//             tabId,
//         });
//         const list = res.data
//             .filter(d => d.unreadCount && d.unreadCount > 0)
//             .map(d => ({ ...d, isClicked: false }));
//         return list;
//     } catch (err) {
//         console.error(err);
//         throw err;
//     }
// }

const MemoGroupListComponent: ForwardRefRenderFunction<MemoGroupListHandler, Props> = ({
    handleOpenMemo,
    setUnreadMemoCount,
    scrollbarWidth = 0,
    defaultSize,
    setToTop,
    //frameSetting = {},
}, ref) => {
    const tabId = sessionStorage.getItem('TABID') as string;
    const classes = useStyles();
    const frameRef = useRef({} as DraggableFrameHandler);
    const TEXT_TRIM_LENGTH = 11;

    // state
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [memoGroupList, setMemoGroupList] = useState([] as IMemoGroup[]);
    const [isEnterContent, setEnterContent] = useState(false);
    const [defaultCoord, setDefaultCoord] = useState({ x: 0, y: 0 });
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [message, setMessage] = useState<string | ReactNode>('');
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // TODO: V2でFloor.tsx側へ移動するかを検討
    const frameSetting = {
        parentElementId: 'floorContainer',
        zIndex: ZIndex.experiment,
        defaultCoord,
        defaultSize,
        scrollbarWidth,
        enableResizing: false,
        disableDragging: true,
    }
    const _frameSetting = {
        ...frameSetting,
        minWidth: defaultSize.width,
        minHeight: defaultSize.height,
        enableResizing: isEnterContent ? false : frameSetting.enableResizing,
        disableDragging: isEnterContent ? true : frameSetting.disableDragging,
    }

// request
const getMemoGroupList = async (tabId: string) => {
    try {
        // const res: AxiosResponse<MemoGroup[]> = await axios.post('/api/user/memo/unread', {
        //     tabId,
        // });

        const res = await httpClient.getUnreadMemoGroupList(tabId);
        const list = res
            .filter(d => d.unreadCount && d.unreadCount > 0)
            .map(d => ({ ...d, isClicked: false }));
        return list;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

    const fetchMoreData = async () => {
        try {
            const data = await getMemoGroupList(tabId);
            if (data) {
                setMemoGroupList([...memoGroupList, ...data]);
                setPage(page + 1);
            }
        } catch (err) {
            console.error(err)
            const m1 = <p>データ取得に&nbsp;&nbsp;<br />失敗しました。</p>
            setMessage(m1);
        }
    }

    const getInitData = async () => {
        try {
            setLoading(true);
            const data = await getMemoGroupList(tabId);
            if (data.length === 0) {
                setMessage('未読メモはありません。');
                setUnreadMemoCount(0); // 未読通知バッジを非表示へ
            } else if (data.length > 0) {
                setMemoGroupList([...data]);
                setPage(1);
            }
        } catch (err) {
            console.error(err);
            const m1 = <p>データ取得に&nbsp;&nbsp;<br />失敗しました。</p>
            setMessage(m1);
        } finally {
            setLoading(false);
        }
    }

    const adjustText = (text: string) => {
        return text.length > TEXT_TRIM_LENGTH ? `${text.slice(0, TEXT_TRIM_LENGTH)}...` : text;
    }

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open: (defaultCoord: Coord) => {
            openUI(defaultCoord);
        },
        close() {
            closeUI();
        },
        getOpen() {
            return isShow
        },
        setCoord(coord: Coord) {
            setDefaultCoord(coord);
        },
        getZindex(){
            return zindex;
        },
        setZindex(z: number){
            setZindex(z);
        }
    }));

    const [zindex, setZindex] = React.useState(ZIndex.experiment);

    const openUI = (coord: Coord) => {
        setMessage('');
        setDefaultCoord(coord);
        if (isShow) {
            getInitData();
        } else {
            setShow(true);
        }
        frameRef.current?.open();
    }

    const closeUI = () => {
        setShow(false);
        frameRef.current?.close();
    }

    /**
     * useEffect
     */
    // open時のスクロール位置
    useEffect(() => {
        const element = document.getElementById('memoGroupListBody');
        if (element === null) return;
        const bottom = element.scrollHeight - element.clientHeight;
        if (element.scroll !== undefined) {
            element.scroll(0, bottom);
        } else {
            element.scrollTop = bottom;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    // open時データ取得
    useEffect(() => {
        if (isShow) {
            getInitData();
        } else {
            setMemoGroupList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    useEffect(() => {
        frameRef.current?.changeToAtOpen();
    }, [defaultCoord])

    /**
     * event handlers
     */
    const onRefresh = () => {
        getInitData();
        setMessage('');
    }

    const onClose = () => {
        closeUI();
    }

    var TimeFn = null as any;

    const onFrameDoubleClick = () => {
        clearTimeout(TimeFn);
        frameRef.current?.onFrameDoubleClick();
    }

    const setZIndex = () =>{
        clearTimeout(TimeFn);

        TimeFn = setTimeout(function(){
            setToTop("MemoList");
        }, 200);
    };

    const onMemoGroupClick = (groupId: number, groupName: string, index: number) => {
        handleOpenMemo(groupId, groupName);
        const list = memoGroupList.map(d => {
            if (d.isClicked) {
                return d;
            } else {
                return d.groupId === groupId ? { ...d, isClicked: true } : d;
            }
        });
        setMemoGroupList(list);
        setSelectedIndex(index);
    }

    // draw elements
    const drawMemoGroup = (memoGroup: IMemoGroup, index: number) => {
        const { groupId, groupName, isClicked } = memoGroup;
        return (
            <ListItem
                key={`memo-group-${index}`}
                button
                dense
                selected={selectedIndex === index}
                onClick={() => onMemoGroupClick(groupId, groupName, index)}
            >
                {/* <ListItemIcon></ListItemIcon> */}
                <Tooltip title={groupName.length > TEXT_TRIM_LENGTH ? groupName : ''} placement="bottom-start" arrow>
                    <ListItemText
                        className={isClicked ? classes.clicked : classes.unClicked}
                        primary={adjustText(groupName)}
                        disableTypography
                    />
                </Tooltip>
            </ListItem>
        )
    }

    const drawMemoGroupList = useMemo(() => {
        return memoGroupList.length > 0 &&
            <List
                component='nav'
                aria-label='memo group list'
                style={{ width: '100%' }}
            >
                {memoGroupList.map((memoGroup: IMemoGroup, index: number) => drawMemoGroup(memoGroup, index))}
            </List>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoGroupList, isLoading, selectedIndex]);

    const drawLoading = useMemo(() => {
        return (
            <Grid container justify='center' alignItems='center' style={{ height: '100%' }}>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color='primary' />
                </ThemeProvider>
            </Grid>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const drawMessage = useMemo(() => {
        return (
            <div className={classes.messageContainer}>
                {message}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <DraggableFrame ref={frameRef} {..._frameSetting} zIndex={zindex} handleResize={() => {}}>
            <CssBaseline />
            <Paper elevation={5} className={classes.root}>
                <div className={classes.header} onDoubleClick={onFrameDoubleClick} onClick={setZIndex}>
                    <div style={{ marginLeft: 8 }}>未読メモ</div>
                    <div>
                        <IconButton className={classes.headerItem} onClick={onRefresh}>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton className={classes.headerItem} onClick={onClose} style={{ width: 22 }}>
                            <CloseIcon viewBox='0 0 16 16' />
                        </IconButton>
                    </div>
                </div>
                <div
                    id='memoGroupListBody'
                    className={'customScrollBar ' + classes.body}
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                >
                    {isLoading
                        ? drawLoading
                        : message !== ''
                            ? drawMessage
                            : <InfiniteScroll
                                className={classes.bodyInnerFrame}
                                scrollableTarget='memoGroupListBody'
                                dataLength={memoGroupList.length}
                                next={fetchMoreData}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: memoGroupList.length === 0 ? 'center' : 'flex-start',
                                    alignItems: memoGroupList.length === 0 ? 'center' : 'flex-start',
                                }}
                                inverse={false}// 上から下
                                hasMore={false}// scrollによる追加取得なし
                                loader={<h4 className={classes.loading}>loading</h4>}
                                endMessage={!isLoading && <p className={classes.endMessage}></p>}
                            >
                                {drawMemoGroupList}
                            </InfiniteScroll>
                    }
                </div>
            </Paper>
        </DraggableFrame >
    )
}

export const MemoGroupList = forwardRef(MemoGroupListComponent);
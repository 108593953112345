import React, { forwardRef, ForwardRefRenderFunction, Fragment, useImperativeHandle, useMemo, useState, useEffect, useCallback, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { Button, Checkbox, FormControlLabel, Grid, InputAdornment, List, ListItem, ListItemText, Paper, Switch, TableCell, TableContainer, TextField, Tooltip, Typography } from '@material-ui/core';
import { Table, TableHead, TableRow, TableBody } from '@material-ui/core';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import theme from '../test/Thema';

import { JsonFloor } from '../admin/FloorList';
import { Utility } from '../common/Utility';
import JfsClient, { SearchedUser, Profile, JfsError } from '@fsi/jfs-sdk';

// 定数
const MAX_LENGTH = 100;          // 検索で指定できる最大文字数

const useStyles = makeStyles((theme) => createStyles({
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    search: {
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
    },
    HeadCell: {
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        backgroundColor: 'rgba(210, 210, 210, 224)'
    },
    BodyCell: {
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        height: 20,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        overflowWrap: 'break-word'
    }
}));

interface Props {
    jsonFloor: JsonFloor,
}

interface ResEnabledFloorLock {
    tenantId: number;
    enabledFloorLock: boolean;
}

// 公開したいメソッドの定義
export interface FloorEntryRestrictHandler {
    open(): void;
}

// 文字列を比較する（昇順）
const compareDisplayName = (name_first: string, name_second: string) => {
    if (name_first > name_second) return 1;
    else if (name_first < name_second) return -1;
    else return 0;
}

// フロア名を「:」で分解する
const splitFloorName = (floorName: string, index: number) => {
    if (floorName.length > 0) {
        let temps = floorName.split("：", 2);
        return temps[index];
    }
    return "";
}

// ユーザーを検索
const getUsers = async (tabId: string, displayName: string, tag: string): Promise<SearchedUser[]> => {
    try {
        const jfs_Client = JfsClient.getInstance();
        const jfs_httpClient =  jfs_Client.httpClient;
        const res: SearchedUser[] = await jfs_httpClient.searchUserNameWithTag(tabId, displayName, tag);
        return res;
    } catch (err) {
        const jfserr = err as JfsError;
        console.log('httpClient.searchUserName error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
        throw jfserr;
    }
}

// 入室制限の設定を更新
const updateEntryList = async (tabId: string, floorId: number, enabledRestricted: boolean, userSubIdList: string[]): Promise<string> => {
    try {
        const jfs_Client = JfsClient.getInstance();
        const jfs_httpClient =  jfs_Client.httpClient;
        const res: string = await jfs_httpClient.updateEntryList(tabId, floorId, enabledRestricted, userSubIdList);
        return res;
    } catch (err) {
        const jfserr = err as JfsError;
        console.log('httpClient.updateEntryList error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
        throw jfserr;
    }
}

const FloorEntryRestrictComponent: ForwardRefRenderFunction<FloorEntryRestrictHandler, Props> = (props, ref) => {

    const [open, setOpen] = useState(false);
    const [userList, setUserList] = useState([] as SearchedUser[]);
    const [candidateSubIds, setCandidateSubIds] = useState([] as string[]);     // 検索結果でチェックしてあるユーザー
    const [reverseSubIds,setReverseSubIds] = useState([] as string[]);          // 入室可能ユーザでチェックしてあるユーザー
    const [entryList, setEntryList] = useState([] as SearchedUser[]);
    const [errMsg, setErrMsg] = useState('');
    const [searchMsg, setSearchMsg] = useState<string | ReactNode>('');
    const [isUserLoading, setUserLoading] = useState(false);
    const [isEntryLoading, setEntryLoading] = useState(false);
    const [isCheckedRestrict, setCheckedRestrict] = useState(false);
    const [enabledFloorLock, setEnabledFloorLock] = useState(true);
    // 選択状態かどうか（すべてのユーザー）
    const [isChecked,setChecked] = useState(false);
    // 全選択状態かどうか（すべてのユーザー）
    const [isCheckedAll,setCheckedAll] = useState(false);

    // 選択状態かどうか（入室可能なユーザー）
    const [isReverseChecked, setReverseChecked] = useState(false);
    // 全選択状態かどうか（入室可能なユーザー）
    const [isReverseCheckedAll, setReverseCheckedAll] = useState(false);
    // 入室可能ユーザーの検索（フィルター）
    const [filterDisplayName, setFilterDisplayName] = useState('');
    const [filterTag, setFilterTag] = useState('');

    const classes = useStyles();
    const tabId = sessionStorage.getItem("TABID") as string;
    const jfs_Client = JfsClient.getInstance();
    const { httpClient } = jfs_Client;

    useImperativeHandle(ref, () => ({
        open: () => {
            const getEnabledFloorLock=()=>{
                httpClient.getEnabledFloorLock(sessionStorage.getItem("TABID") as string)
                .then((response: ResEnabledFloorLock) => {
                    console.log(response);
                    setEnabledFloorLock(response.enabledFloorLock)
                })
                .catch((err)=>{
                    console.log("error")
                })};
            getEnabledFloorLock();
            openUI();
            setOpen(true);
        },
    }))

    const openUI = () => {
        setChecked(false);
        setCheckedAll(false);
        setReverseChecked(false);
        setReverseCheckedAll(false);
        setUserList([] as SearchedUser[]);
        setCandidateSubIds([] as string[]);
        setReverseSubIds([] as string[]);
        setEntryList([] as SearchedUser[]);
        setErrMsg('');
        setSearchMsg('');
    }

    useEffect(() => {
        const getIsFloorRestricted = async () => {

            const jfs_Client = JfsClient.getInstance();
            const jfs_httpClient =  jfs_Client.httpClient;
            await jfs_httpClient.getIsFloorRestrected(sessionStorage.getItem("TABID") as string, props.jsonFloor.id)
                .then((response: string) => {
                    console.log(response);
                    const isRestricted = response as unknown;
                    setCheckedRestrict(isRestricted as boolean);
                })
                .catch((err) => {
                    const jfserr = err as JfsError;
                    console.error('httpClient.getIsFloorRestrected error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
                });
        }

        const getEntryList =  async () => {

            const jfs_Client = JfsClient.getInstance();
            const jfs_httpClient =  jfs_Client.httpClient;
            await jfs_httpClient.getEntryList(sessionStorage.getItem("TABID") as string, props.jsonFloor.id)
                .then((response: SearchedUser[]) => {
                    console.log(response);
                    const temp = response as SearchedUser[];
                    temp.sort((a, b) => compareDisplayName(a.displayName, b.displayName));
                    setEntryList(temp);
                })
                .catch((err) => {
                    const jfserr = err as JfsError;
                    console.error('httpClient.getEntryList error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
                });
        }

        if(open === true) {
            try{
                setEntryLoading(true);
                getIsFloorRestricted();
                setFilterDisplayName('');
                setFilterTag('');
                getEntryList();
                searchUser();
            }
            finally{
                setEntryLoading(false);
            }
        }
    }, [open, props.jsonFloor.id]);

    const handleRestrictChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isCheckedRestrict !== event.target.checked) {
            setCheckedRestrict(event.target.checked);
        }
        if(errMsg) {
            setErrMsg('');      // 切り替えの際にエラーメッセージをクリア
        }
    }

    const handleUserChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === true){
            let temp = [...candidateSubIds];
            temp.push(event.target.value);
            setCandidateSubIds(temp);
            // 全員チェック済みで最後の一人だったら
            if (temp.length == userList.filter(u => entryList.filter(m => m.subId === u.subId).length === 0).length) {
                setCheckedAll(true);
                setChecked(false);
            } else {
                setChecked(true);
            }
        }
        else if (event.target.checked === false){
            let temp = candidateSubIds.filter(id => id !== event.target.value).map(id => id);   // filter()はシャローコピー
            setCandidateSubIds(temp);
            // 最後の一人だったら
            if (temp.length == 0) {
                setCheckedAll(false);
                setChecked(false);
            } else {
                setCheckedAll(false);
                setChecked(true);
            }
        }
    }

    const handleAllChecked = () => {
        if (isCheckedAll || isChecked) {
            setCandidateSubIds([] as string[]);
            setCheckedAll(false);
            setChecked(false);
        } else {
            let temp = [] as string[];
            userList.filter(u => entryList.filter(m => m.subId === u.subId).length === 0)
                    .map((user: SearchedUser) => {
                        const { subId } = user;
                        temp.push(subId);
                    })
            setCandidateSubIds(temp);
            setCheckedAll(true);
            setChecked(false);
        }

    }


    const handleReverseUserChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === true){
            let temp = [...reverseSubIds];
            temp.push(event.target.value);
            setReverseSubIds(temp);
            // 全員チェック済みで最後の一人だったら
            if (temp.length == entryList.filter(u => filterUser(u)).length) {
                setReverseCheckedAll(true);
                setReverseChecked(false);
            } else {
                setReverseChecked(true);
            }
        }
        else if (event.target.checked === false){
            let temp = reverseSubIds.filter(id => id !== event.target.value).map(id => id);   // filter()はシャローコピー
            setReverseSubIds(temp);
            // 最後の一人だったら
            if (temp.length == 0) {
                setReverseCheckedAll(false);
                setReverseChecked(false);
            } else {
                setReverseCheckedAll(false);
                setReverseChecked(true);
            }
        }
    }

    const handleReverseAllChecked = () => {
        if (isReverseCheckedAll || isReverseChecked) {
            setReverseSubIds([] as string[]);
            setReverseCheckedAll(false);
            setReverseChecked(false);
        } else {
            let temp = [] as string[];
            entryList.filter(u => filterUser(u))
                    .map((user: SearchedUser) => {
                        const { subId } = user;
                        temp.push(subId);
                    })
            setReverseSubIds(temp);
            setReverseCheckedAll(true);
            setReverseChecked(false);
        }

    }

    const handleAddEntry = () => {

        if (candidateSubIds.length === 0){
            setErrMsg('追加するユーザーを選択してください。');
            return;
        }

        const addList = userList.filter(u => candidateSubIds.includes(u.subId)).map(u => u);
        const newEntryList = entryList.concat(addList).sort((a, b) => compareDisplayName(a.displayName, b.displayName));
        setEntryList(newEntryList);
        setCandidateSubIds([] as string[]);
        setChecked(false);
        setCheckedAll(false);
        if (reverseSubIds.length !== 0) {
            setReverseChecked(true);
            setReverseCheckedAll(false);
        }
    }

    const handleDeleteEntry = () => {
        if (reverseSubIds.length === 0){
            setErrMsg('削除するユーザーを選択してください。')
            return;
        }
        const newEntryList = entryList.filter(u => !reverseSubIds.includes(u.subId)).map(u => u);
        setEntryList(newEntryList);
        setReverseSubIds([] as string[]);
        setReverseChecked(false);
        setReverseCheckedAll(false);
        if (candidateSubIds.length !== 0) {
            setChecked(true);
            setCheckedAll(false);
        }
    }

    const handleUpdate = async () => {
        try {
            //setLoading(true);
            const userSubIdList = entryList.map(entry => entry.subId);      // 入室できるユーザーのsubIDリスト
            const res = await updateEntryList(tabId, props.jsonFloor.id, isCheckedRestrict, userSubIdList);
            console.log(res);
        } catch (err: any) {
            const jfserr = err as JfsError;
            console.error(jfserr);
            setErrMsg(jfserr.detail);
        } finally {
            //setLoading(false);
            handleClose();
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const searchUser = async () => {
        setChecked(false);
        setCheckedAll(false);
        setUserList([]);
        setErrMsg('');
        setSearchMsg('');
        setCandidateSubIds([]);

        const displayName = (document.getElementById('displayName_search') as HTMLInputElement)?.value ? (document.getElementById('displayName_search') as HTMLInputElement)?.value : '';
        const tag = (document.getElementById('tag_search') as HTMLInputElement)?.value ? (document.getElementById('tag_search') as HTMLInputElement)?.value : '';
        try {
            const data = await getUsers(tabId, displayName, tag);
            if (data.length === 0) {
            } else if (data.length > 0) {
                const temp = data.concat().sort((a, b) => compareDisplayName(a.displayName, b.displayName))
                setUserList([...temp]);
            }
        } catch (err) {
            const jfserr = err as JfsError;
            console.error(jfserr);
        }
    }

    const onSearchClick = () => {
        if(isUserLoading === false) {
            // ボタンの表示がdisableになってもクリックイベントが走ったため、isLoadingで通信中か判定する
            searchUser();
        }
    }

    const onFilterClick = () => {
        setFilterDisplayName((document.getElementById('displayName_filter') as HTMLInputElement)?.value);
        setFilterTag((document.getElementById('tag_filter') as HTMLInputElement)?.value);
        setReverseChecked(false);
        setReverseCheckedAll(false);
        setReverseSubIds([] as string[]);
    }

    const filterUser = (searchedUser :SearchedUser) => {
        const { displayName, userProfileList } = searchedUser;
        const isIncludesDisplayName = displayName.includes(filterDisplayName);
        let isIncludesTag = false;
        // タグが含まれているか
        if (userProfileList) {
            userProfileList.map((u) => {
                if (u.profileOrder === 1 || u.profileOrder === 2 || u.profileOrder === 3) {
                    if (u.value.includes(filterTag)) {
                        isIncludesTag = true;
                    }
                }
            })
        } else {
            if (filterTag === '') {
                isIncludesTag = true;
            }
        }
        return isIncludesDisplayName && isIncludesTag;
    }

    const draw = useMemo(() => {
        if (open === false) return;

        return (
            <Dialog open={open} onClose={handleClose} disableBackdropClick fullWidth maxWidth="lg" >
                <DialogTitle id="floor-entry-restrict-dialog-title">{Utility.changeLongFloorName(props.jsonFloor.floorName) + "　"} フロア入室制限設定</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {!enabledFloorLock ? <span style={{ color: 'red' }}>フロア入室制限の切り替えが、無効になっているため設定できません。<br/></span> :<span/>}
                        このフロアに入室するユーザを制限することができます。
                        <br/>「フロア入室制限を有効化する」をオンにし、入室可能なユーザーを設定してください。
                        <br/>※入室可能なユーザーが設定されていても、「フロア入室制限を有効化する」をオフにすると全員入室可能にできます。
                        <br/>※管理者ユーザーは制限の影響を受けず常に入室できます。
                        <br/>※現在フロアに滞在中のユーザーは、次回以降の入室時に制限が有効になります。今すぐ退室させたい場合は
                        <br/>　強制ログアウト機能をご利用ください。
                    </DialogContentText>
                    <DialogContentText color='textPrimary'>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isCheckedRestrict}
                                    onChange={handleRestrictChecked}
                                    name="checkedFloorEntryRestrict"
                                    color="primary"/>
                            }
                            label="フロア入室制限を有効化する"
                        />
                    </DialogContentText>
                        <Fragment>
                            <div>
                                <Grid
                                    container
                                    spacing={2}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography style={{ padding: 5, marginLeft: -5 }} variant='body2'>すべてのユーザー</Typography>
                                        <div className={classes.search} style={{ marginLeft: -160 }}>
                                            <TextField id="displayName_search" style={{ maxWidth: 150 }} size="small" label="表示名" variant="outlined" inputProps={{ autoComplete: "off", maxLength: MAX_LENGTH}} disabled={!isCheckedRestrict || isUserLoading}/>&nbsp;
                                            <TextField id="tag_search" style={{ float: 'left', maxWidth: 150 }} size="small" label="タグ" variant="outlined" inputProps={{ autoComplete: "off", maxLength: MAX_LENGTH}} disabled={!isCheckedRestrict || isUserLoading}/>&nbsp;
                                            <Button id="search" style={{ marginLeft: 2, float: 'left' }} onClick={onSearchClick} variant="contained" color="primary" disabled={!isCheckedRestrict || isUserLoading}>検索</Button>
                                        </div>
                                    <Paper style={{ marginTop:10, width: 540, height: 450 ,overflow: 'auto'}} >
                                            <Fragment>
                                            <Table stickyHeader aria-label="customized table" style={{tableLayout:'fixed'}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox" style={{backgroundColor: 'rgba(210, 210, 210, 224)'}}>
                                                            <Checkbox
                                                                checked={isCheckedAll}
                                                                indeterminate={isChecked}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                onChange={handleAllChecked}
                                                                disabled={!isCheckedRestrict || userList.filter(u => entryList.filter(m => m.subId === u.subId).length === 0).length === 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.HeadCell}>表示名</TableCell>
                                                        <TableCell className={classes.HeadCell}>タグ1</TableCell>
                                                        <TableCell className={classes.HeadCell}>タグ2</TableCell>
                                                        <TableCell className={classes.HeadCell}>タグ3</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        userList.filter(u => entryList.filter(m => m.subId === u.subId).length === 0) //入室可能なユーザーのリストにあるものは検索結果に含めない
                                                            .map((user: SearchedUser, index: number) => {
                                                                const { subId, displayName, userProfileList } = user;
                                                                let tag1 = '';
                                                                let tag2 = '';
                                                                let tag3 = '';
                                                                if (userProfileList) {
                                                                    const existTag1 = userProfileList.find(e => e.profileOrder === 1)?.value;
                                                                    if (existTag1) {
                                                                        tag1 = existTag1;
                                                                    }
                                                                    const existTag2 = userProfileList.find(e => e.profileOrder === 2)?.value;
                                                                    if (existTag2) {
                                                                        tag2 = existTag2;
                                                                    }
                                                                    const existTag3 = userProfileList.find(e => e.profileOrder === 3)?.value;
                                                                    if (existTag3) {
                                                                        tag3 = existTag3;
                                                                    }
                                                                }
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={candidateSubIds.includes(subId)}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                onChange={handleUserChecked}
                                                                                value={subId}
                                                                                disabled={!isCheckedRestrict}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={classes.BodyCell}>{displayName}</TableCell>
                                                                        <TableCell className={classes.BodyCell}>{tag1}</TableCell>
                                                                        <TableCell className={classes.BodyCell}>{tag2}</TableCell>
                                                                        <TableCell className={classes.BodyCell}>{tag3}</TableCell>
                                                                    </TableRow>
                                                                  );
                                                            })
                                                    }
                                                </TableBody>
                                            </Table>
                                            </Fragment>
                                        </Paper>                                    
                                        </Grid>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                aria-label="move selected right"
                                                style={{margin: theme.spacing(0.5, 0)}}
                                                onClick={handleAddEntry}
                                                disabled={!isCheckedRestrict}
                                            >
                                                &gt;
                                            </Button>
                                        </Grid>
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                aria-label="move selected left"
                                                style={{margin: theme.spacing(0.5, 0)}}
                                                onClick={handleDeleteEntry}
                                                disabled={!isCheckedRestrict}
                                            >
                                                &lt;
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ padding: 5, marginLeft: -5 }} variant='body2'>{splitFloorName(props.jsonFloor.floorName, 0)} に入室可能なユーザー</Typography>
                                        <div className={classes.search} style={{ marginLeft: -160 }}>
                                            <TextField id="displayName_filter" style={{ maxWidth: 150 }} size="small" label="表示名" variant="outlined" inputProps={{ autoComplete: "off", maxLength: MAX_LENGTH}} disabled={!isCheckedRestrict || isUserLoading}/>&nbsp;
                                            <TextField id="tag_filter" style={{ float: 'left', maxWidth: 150 }} size="small" label="タグ" variant="outlined" inputProps={{ autoComplete: "off", maxLength: MAX_LENGTH}} disabled={!isCheckedRestrict || isUserLoading}/>&nbsp;
                                            <Button id="filter" style={{ marginLeft: 2, float: 'left' }} onClick={onFilterClick} variant="contained" color="primary" disabled={!isCheckedRestrict || isUserLoading}>検索</Button>
                                        </div>
                                        <Paper style={{ marginTop:10, width: 540, height: 450, overflow: 'auto' }}>
                                        <Fragment>
                                            <Table stickyHeader aria-label="customized table" style={{tableLayout:'fixed'}}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox" style={{backgroundColor: 'rgba(210, 210, 210, 224)'}}>
                                                            <Checkbox
                                                                checked={isReverseCheckedAll}
                                                                indeterminate={isReverseChecked}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                onChange={handleReverseAllChecked}
                                                                disabled={!isCheckedRestrict || entryList.filter(u => filterUser(u)).length === 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.HeadCell}>表示名</TableCell>
                                                        <TableCell className={classes.HeadCell}>タグ1</TableCell>
                                                        <TableCell className={classes.HeadCell}>タグ2</TableCell>
                                                        <TableCell className={classes.HeadCell}>タグ3</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        entryList.filter(u => filterUser(u))
                                                                 .map((user: SearchedUser, index: number) => {
                                                                const { subId, displayName, userProfileList } = user;
                                                                let tag1 = '';
                                                                let tag2 = '';
                                                                let tag3 = '';
                                                                if (userProfileList) {
                                                                    const existTag1 = userProfileList.find(e => e.profileOrder === 1)?.value;
                                                                    if (existTag1) {
                                                                        tag1 = existTag1;
                                                                    }
                                                                    const existTag2 = userProfileList.find(e => e.profileOrder === 2)?.value;
                                                                    if (existTag2) {
                                                                        tag2 = existTag2;
                                                                    }
                                                                    const existTag3 = userProfileList.find(e => e.profileOrder === 3)?.value;
                                                                    if (existTag3) {
                                                                        tag3 = existTag3;
                                                                    }
                                                                }
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox
                                                                                checked={reverseSubIds.includes(subId)}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                onChange={handleReverseUserChecked}
                                                                                value={subId}
                                                                                disabled={!isCheckedRestrict}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={classes.BodyCell}>{displayName}</TableCell>
                                                                        <TableCell className={classes.BodyCell}>{tag1}</TableCell>
                                                                        <TableCell className={classes.BodyCell}>{tag2}</TableCell>
                                                                        <TableCell className={classes.BodyCell}>{tag3}</TableCell>
                                                                    </TableRow>
                                                                  );
                                                            })
                                                    }
                                                </TableBody>
                                            </Table>
                                            </Fragment>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </Fragment>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate} color="primary" disabled={!enabledFloorLock}>
                        OK
                    </Button>
                    <Button onClick={handleClose} color="primary" >
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        )
}, [open, userList, entryList, candidateSubIds, reverseSubIds, errMsg, searchMsg, isUserLoading, isEntryLoading, isCheckedRestrict, isChecked, isCheckedAll, isReverseChecked, isReverseCheckedAll, filterDisplayName, filterTag])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const FloorEntryRestrict = forwardRef(FloorEntryRestrictComponent);
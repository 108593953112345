import { Button, Typography } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

class AvatarStatus {
    isCommuting: boolean = false;
    webSocketUrl: string = "";
}

export default function TestApi() {

    const [result, setResult] = useState("結果");
    const [avatarStatus, setAvatarStatus] = useState(new AvatarStatus());

    const handleAvatarStatus = () => {
        var params = new URLSearchParams();
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        axios.post('/api/user/avatar/status', params)
            .then((e: AxiosResponse) => {
                let temp = e.data as AvatarStatus;
                setResult(JSON.stringify(e.data));
                setAvatarStatus(temp);
            }).catch(err => {
                console.log(err);
            });
    }

    const handleAvatarStatus2 = () => {
        var params = new URLSearchParams();
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        axios.post('/api/user/' + avatarStatus.webSocketUrl + '/avatar/status', params)
            .then((e: AxiosResponse) => {
                let temp = e.data as AvatarStatus;
                setResult(JSON.stringify(e.data));
                setAvatarStatus(temp);
            }).catch(err => {
                console.log(err);
            });
    }

    return (
        <Fragment>
            <Button onClick={handleAvatarStatus} variant="contained" color="primary">アバターステータス</Button>
            <Button onClick={handleAvatarStatus2} variant="contained" color="primary">アバターステータス その２</Button>
            <Typography variant="body2">{result}</Typography>
        </Fragment>
    );
}
import React, { useState, useMemo, Fragment } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import Typography from '@material-ui/core/Typography';
import EditTextDialog from './EditTextDialog';
import { FloorObject, chkJsonFormat } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import EditBanner from './EditBanner';

interface Props {
    className: string,
    floorObject: FloorObject,
    //sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    sendBannerText: (id: number, text: string, font: string, color: string) => void,
    role: string
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //fontSize: 16,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    //overflow: 'hidden',
    overflowY: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

export default function Banner(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const { role } = props;

    const [isOpen, setIsOpen] = useState(false);

    function GetInText2(text:string, elm:string ) {
        // 横断幕用のJsonパース
        
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT1_BANNER, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            if (elm === 'message') {
                text = intextJson.message;
            } else if (elm === 'font') {
                text = intextJson.font;
            } else if (elm === 'color') {
                text = intextJson.color;
            } else {
                text = "";
            }
        }else{
            // Json形式ではない場合
            if (elm === 'message') {
                text = "";
            } else if (elm === 'font') {
                // urlはまだ設定されていない
                text = "";
            } else if (elm === 'color') {
                text = "";
            } else {
                text = "";
            }
        }
        
        return text;
    }

    // 改行が入っていた場合のホワイトボードの上下位置調整
    const kanbanOffsetTop = useMemo(
      () =>
        objectMaster.textWidth > 120
          ? GetInText2(text1, "message").length > 16
            ? 4.2
            : (text1.match(/\\n/g) || []).length > 0 ? (text1.match(/\\n/g) || []).length * 4 : 13
          : GetInText2(text1, "message").length > 8
          ? 4.2
          : (text1.match(/\\n/g) || []).length > 0 ? (text1.match(/\\n/g) || []).length * 4 : 13,
      [text1]
    );

    const setFont = (fontName: string) => {
        let font = fontName.split("　");
        if(font.length > 1){
            return font[1];
        }else{
            return "sans-serif";
        }
    }

    const content = useMemo(() => {
        return (
            <>
                <div style={{display: 'inline-block', 
                    fontFamily: setFont(GetInText2(text1, "font")),
                    //fontSize: objectMaster.width > 120 ? (GetInText2(text1,'message').length > 9 ? 15 : 24) : (GetInText2(text1,'message').length > 4 ? 15 : 24), 
                    color: GetInText2(text1,'color'), 
                    textAlign: 'center', 
                    paddingTop: kanbanOffsetTop, 
                }}>
                    {GetInText2(text1,'message')}
                </div>
            </>
        )
    }, [text1]);

    const drawBanner = useMemo(() => {
        return (
            <Fragment>
                <div
                    key={`banner${id}`}
                    id={`banner${id}`}
                    onClick={() => setIsOpen(true)}
                    className={className}
                    style={{
                        ...SELECT_NONE,
                        position: "absolute",
                        left: offsetLeft,
                        top: offsetTop,
                        width: objectMaster.width,
                        height: objectMaster.height,
                        backgroundSize: "contain",
                        //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                        backgroundRepeat: "no-repeat",
                        zIndex: ZIndex.floorObject,
                        textAlign: "center",
                    }}
                >
                    <Typography className={className} 
                        style={{ ...kanbanTextStyle,  
                                    width:  objectMaster.textWidth, 
                                    height: objectMaster.height }} 
                        variant="body2" 
                        color="textSecondary">
                        {content}
                    </Typography>
                </div>
                <EditBanner
                    className={className}
                    isOpen={isOpen && (role.match(/ROLE_ADMIN/) != null)}
                    floorObjectId={id}
                    type={objectMaster.type}
                    title={'表示内容'}
                    text={text1}
                    maxLength={objectMaster.width > 120 ? 32 : 16}
                    onClose={() => setIsOpen(false)}
                    sendText={props.sendBannerText}
                />
            </Fragment>
        )
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text1, isOpen, objectMaster.width]);

    return (
        <Fragment>
            {drawBanner}
        </Fragment>
    )
}
import React, { useState, useMemo, Fragment } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import EditTextDialog from './EditTextDialog';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";

interface Props {
    className: string,
    floorObject: FloorObject,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 12,
    whiteSpace: 'nowrap',
    height: 15,
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

export default function DynamicKanban(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, text1, text2 } = props.floorObject

    const [isOpen, setIsOpen] = useState(false);

    const kanbanWidth = useMemo(
        () => text2.length === 0 ? 15 : text2.length * 12 + 2
        , [text2]
    );

    const kanbanContent = useMemo(
        () => text2.length === 0
            ? <CreateIcon style={{ width: 15, height: 15 }} />
            : text2
        , [text2]);

    const draw = useMemo(() =>
        <Fragment>
            <div
                key={`dynamicKanban${id}`}
                id={`dynamicKanban${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...kanbanTextStyle,
                    left: offsetLeft,
                    top: offsetTop,
                    width: kanbanWidth,
                }}
            >
                {kanbanContent}
            </div>

            <EditTextDialog
                className={className}
                isOpen={isOpen}
                floorObjectId={id}
                title={text1 ? `${text1}: 表示内容` : '表示内容'}
                text={text2}
                maxLength={28}
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [text1, text2, isOpen])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
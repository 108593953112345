/**
 * ビデオ通話前の確認ダイアログ
 * 別のアバターに重ねられてwebrtc接続確認を行うダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import AudioPlayer, { AudioId } from '../AudioPlayer';

interface Props {
    hitUser: string;
    startingConfirmTimeoutValue: number;
    handleConfirmResult: (result: number) => void;
}

export interface WebrtcStartConfirmDialogHandler {
    open: () => void;
    close: () => void;
}

const WebrtcStartConfirmDialogComponent: ForwardRefRenderFunction<WebrtcStartConfirmDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [hitUserName, setHitUserName] = React.useState("");

    useImperativeHandle(ref, () => ({
        open: () => {
            setCount(props.startingConfirmTimeoutValue);
            setOpen(true);
        },
        close: () => {
            setOpen(false);
        },
    }))

    const handleClose = (result: number) => {
        setOpen(false);
        props.handleConfirmResult(result);
    };

    useEffect(() => {
        if (!open) return;
        if(props.hitUser !== null && props.hitUser !== "") {
            setHitUserName(props.hitUser+"さんに"); 
        }
        let temp = props.startingConfirmTimeoutValue;
        console.log("応答確認のカウントダウン開始（"+temp+"秒）");
        const interval = setInterval(() => {
            temp--;
            setCount(temp);
            if (temp === 0) {
                clearInterval(interval);
                handleClose(100);   // タイムアウト
            }
            AudioPlayer.play(AudioId.TimeLimit);
        }, 1000)
        return function cleanUp() {
            clearInterval(interval);
        }
    }, [open]);

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                //onClose={handleClose}
                aria-labelledby="startconfirm-dialog-title"
                aria-describedby="startconfirm-dialog-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="startconfirm-dialog-title">{"ビデオ通話"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="startconfirm-dialog-description">
                        {/*<Typography>{props.hitUser+" さんに話しかけられました。応答しますか？"}</Typography>*/}
                        <Typography>{hitUserName+" 話しかけられました。応答しますか？"}</Typography>
                        <Typography>{"「応答する」を選択した場合は、ビデオ通話を開始します。"}</Typography>
                        <Typography>{"「応答できない」を選択した場合は、ビデオ通話キャンセルします。"}</Typography>
                        <Typography variant="body2">{"※" + props.startingConfirmTimeoutValue + "秒以内に選択されない場合は、応答せずに終了します。"}</Typography>
                        
                        <div style={{width: "100%", textAlign: "right"}}>{"残り " + count + "秒"}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(1)} color="primary">
                        {"応答する"}
                    </Button>
                    <Button onClick={() => handleClose(0)} color="primary">
                        {"応答できない"}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [open, count, hitUserName])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const WebrtcStartConfirmDialog = forwardRef(WebrtcStartConfirmDialogComponent);
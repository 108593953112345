/**
 * 多重ログイン時に表示するダイアログ
 * ログインするかどうかをダイアログで表示して、閉じるとLoginに遷移させる
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useReactRouter from 'use-react-router';
import { Utility } from '../../common/Utility';

interface Props {
    sendCheckedLogin: () => void;
}

export interface CheckLoginDialogHandler {
    open: () => void;
}

const CheckLoginDialogComponent: ForwardRefRenderFunction<CheckLoginDialogHandler, Props> = (props, ref) => {
    const { history } = useReactRouter();
    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
    }))

    const handleClose = () => {
        setOpen(false);
        const signinpage = Utility.getSigninPage();
        history.push({
            pathname: signinpage
        });
    };
    const handleLogin = () => {
        setOpen(false);
        props.sendCheckedLogin();
    };

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="disconnect-dialog-title"
                aria-describedby="disconnect-dialog-description"
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="disconnect-dialog-title">{"多重ログイン"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="disconnect-dialog-description">
                        {"既にログインされています。強制的にログインを行いますか？"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogin} color="primary">
                        {"OK"}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {"キャンセル"}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const CheckLoginDialog = forwardRef(CheckLoginDialogComponent);
import React, { Fragment, useState, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect } from 'react';
import useReactRouter from 'use-react-router';

import { Grid } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';

// icons
import AppsIcon from '@material-ui/icons/Apps';
import RefreshIcon from '@material-ui/icons/Refresh';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import DuoIcon from '@material-ui/icons/Duo';
import SettingsIcon from '@material-ui/icons/Settings';
import VideocamIcon from '@material-ui/icons/Videocam';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';     // ＦＡＱ対応
import NoteIcon from '@material-ui/icons/Note';     // リリースノート対応
import NewReleasesIcon from '@material-ui/icons/NewReleases';   // リリースノート対応
import AccountTree from '@material-ui/icons/AccountTree';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreateIcon from '@material-ui/icons/Create';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import WebrtcService from '../webrtc/WebrtcService';
import Update from '@material-ui/icons/Update';
import ViewListIcon from '@material-ui/icons/ViewList';
import { usermanualURL, adminmanualURL, faqURL } from '../common/DocumentURL';

import ZIndex from "../common/ZIndex";
import axios, { AxiosResponse } from 'axios';

interface Props {
    // カメラ、マイクデバイス選択ダイアログを表示
    openDeviceSelect: () => void;
    // マイク入力の状態検知用のインターバル設定用ダイアログを表示
    openMicVolumeInterval: () => void;
    centeringView: (isEffect?: boolean) => void;
    openTutorial: () => void;
    purposeOfUse: number;
    handleClickSetting: () => void;
    openGetAttendance: () => void;
    role: string;
    openInformation: () => void;
    enabledSettingSideMenu: boolean;
    enabledTutorialSideMenu: boolean;
    enabledManualSideMenu: boolean;
    enabledInformationSideMenu: boolean;
    enabledReleaseNoteSideMenu: boolean;    // リリースノート対応
    openReleaseNote: () => void;    // リリースノート対応
    releaseNoteURL: string;     // リリースノート対応
    enabledFAQSideMenu: boolean;    // ＦＡＱ対応
    pageFAQURL: string;     // ＦＡＱ対応
    usermanualURL: string;  // マニュアルURLをDBから取得
    enabledReconnectSideMenu: boolean;
    openInternalNotice: () => void;
    enabledMicVolumeIntervalSideMenu: boolean;
    floorEditorRef: any;
    // enabledFloorEditorSideMenu: boolean;
}

export interface SideMenuHandler {
}

const SideMenuComponent: ForwardRefRenderFunction<SideMenuHandler, Props> = (props: Props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const { history } = useReactRouter();
    const [manualUrl, setManualUrl] = useState("https://doc.famoffice.jp/FAM_v1.5trialManual.pdf");
    const state = history.location.state as any;

    const PURPOSE_OF_USE_OFFICE: number = 1;    // フロアの使用目的(オフィス)
    const famcampusColor : string = '#57BBFF';
    
    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
    }));
    
    useEffect(() => {
        function getManualUrl() {
            // var params = new URLSearchParams();
            // axios.post('/api/user/manualurl', params)
            //     .then((e: AxiosResponse) => {
            //         // setManualUrl(e.data);
            //         let ddd = new Date();
            //         let rURL = e.data + "?" + ddd.getTime();
            //         setManualUrl(rURL);
            //     }).catch(() => {
            //     });
        }

        //getManualUrl();   // マニュアルURLをDBから取得するようにした
    }, []);

    const handleReconnectMenu = () => window.alert("Need to implement !")
    // const handleBroadcastNotificationMenu = () => window.alert("Need to implement !")
    const handleMylocationMenu = () => {
        setIsOpen(false);
        props.centeringView(true);
    }
    const handleOpenTutorialMenu = () => {
        setIsOpen(false);
        props.openTutorial();
    }

    const handleSelectDeviceMenu = () => {
        setIsOpen(false);
        if (props.openDeviceSelect) {
            props.openDeviceSelect();
        }
    }

    const handleOpenMicVolumeInterval = () => {
        setIsOpen(false);
        if (props.openMicVolumeInterval) {
            props.openMicVolumeInterval();
        }
    }

    // サイドメニューの「設定」
    // アバターメニューと同じ動きをする
    const handleSettings = () => {
        setIsOpen(false);
        props.handleClickSetting();
    }

    // サイドメニューの「お知らせ」
    const handleOpenInformationDialog = () => {
        setIsOpen(false);
        props.openInformation();
    }

    // サイドメニューの「リリースノート」
    const handleOpenReleaseNoteDialog = () => {
        setIsOpen(false);
        props.openReleaseNote();
    }

    // サイドメニューの「サインアウト」
    const handleSignoutDialog = () => {
        setIsOpen(false);
        console.log("サインアウト");    // 未実装
    }

    // サイドメニューの「マニュアル」
    const handleViewManual = () => {
        setIsOpen(false);
        console.log("マニュアル");  // 未実装
    }

    // サイドメニューの「ＦＡＱ」
    const handleViewFAQ = () => {
        setIsOpen(false);
        console.log("ＦＡＱ");  // 未実装
    }

    // サイドメニューの「管理機能」
    const handleOpenTenantManager = () => {
        setIsOpen(false);
        history.push(
            {
                pathname: "/userlist", 
                state: {role: props.role}
            }
        );
    }

    // サイドメニューの「先生機能」
    const handleOpenTeacherManager = () => {
        setIsOpen(false);
        history.push(
            {
                pathname: "/teacher", 
                state: {role: props.role}
            }
        );
    }

    // サイドメニューの「入退データ」
    const handleAttendanceData = () => {
        setIsOpen(false);
        props.openGetAttendance();
    }

    // サイドメニューの「連絡」
    const handleOpenInternalNotice = () => {
        setIsOpen(false);
        props.openInternalNotice();
    }

    const enableFloorEditMode　= () => {
        setIsOpen(false);
        props.floorEditorRef.current?.open();
    }

    const drawerList = useMemo(() => {
        let allow:boolean = false;
        let allowFloorOperation:boolean = false
        if(props.role !== undefined){
            if(props.role.indexOf('ROLE_ADMIN') !== -1){
                allow = true;
            }
            if(props.role.indexOf('ROLE_FLOOR_OPERATOR') !== -1){
                allowFloorOperation = true;
            }
        }
        let nowDate = new Date();

        return (
            <List>
                {props.enabledSettingSideMenu ?
                    <ListItem button key='Settings' onClick={handleSettings} >
                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                        <ListItemText primary="全般設定" />
                    </ListItem>
                    :
                    ''
                }
                <ListItem button key='selectDevice' onClick={handleSelectDeviceMenu} >
                    <ListItemIcon><VideocamIcon /></ListItemIcon>
                    <ListItemText primary="カメラマイク設定" />
                </ListItem>
                <Divider />
                {props.enabledSettingSideMenu ?
                    <ListItem button key='Settings' onClick={handleAttendanceData} >
                        <ListItemIcon><Update /></ListItemIcon>
                        <ListItemText primary="入退データ" />
                    </ListItem>
                    :
                    ''
                }
                <Divider />
                {props.enabledTutorialSideMenu ?
                    <ListItem button key='viewTutorial' onClick={handleOpenTutorialMenu} >
                        <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                        <ListItemText primary="チュートリアル" />
                    </ListItem>
                    :
                    ''
                }
                {props.enabledManualSideMenu ?
                    <ListItem button key='viewManual' onClick={handleViewManual} component="a" /** href={"../" + usermanualURL + "?"} */ href={usermanualURL + "?" + nowDate.getDate()} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon><MenuBookIcon /></ListItemIcon>
                        <ListItemText primary="マニュアル" />
                    </ListItem>
                    :
                    ''
                }
                {props.enabledFAQSideMenu ?
                    <ListItem button key='viewFAQ' onClick={handleViewFAQ} component="a" /** href={"../" + faqURL + "?"} */ href={faqURL + "?" + nowDate.getDate()} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon><LiveHelpIcon /></ListItemIcon>
                        <ListItemText primary="よくある質問" />
                    </ListItem>
                    :
                    ''
                }
                {props.enabledInformationSideMenu ?
                    <Fragment>
                        <Divider />
                        <ListItem button key='information' onClick={handleOpenInformationDialog} >
                            <ListItemIcon><NotificationsIcon /></ListItemIcon>
                            <ListItemText primary="お知らせ" />
                        </ListItem>
                    </Fragment>
                    :
                    ''
                }
                {props.enabledReleaseNoteSideMenu ?
                    <Fragment>
                        <Divider />
                        <ListItem button key='releaseNote' onClick={handleOpenReleaseNoteDialog}>
                            <ListItemIcon><NoteIcon /></ListItemIcon>
                            <ListItemText primary="リリースノート" />
                        </ListItem>
                    </Fragment>
                    :
                    ''
                }
                {allow ? 
                    <Fragment>
                        <br/>
                        <Divider />
                        <br/>
                        <ListItem button key='openInternalNotice' onClick={handleOpenInternalNotice} >
                            <ListItemIcon><RecordVoiceOverIcon /></ListItemIcon>
                            <ListItemText primary="一斉連絡" />
                        </ListItem>
                    </Fragment>
                :
                ""
                }
                {allow ? 
                <ListItem button key='openTeacherManager' onClick={handleOpenTeacherManager} disabled={WebrtcService.isiOS() || WebrtcService.isAndroid()} >
                    <ListItemIcon><AccountTree /></ListItemIcon>
                    <ListItemText primary="先生機能" />
                </ListItem>
                :
                ""
                }
                {allow ? 
                <ListItem button key='openTenantManager' onClick={handleOpenTenantManager} disabled={WebrtcService.isiOS() || WebrtcService.isAndroid()} >
                    <ListItemIcon><AccountTree /></ListItemIcon>
                    <ListItemText primary="管理機能" />
                </ListItem>
                :
                ""
                }
                {
                allowFloorOperation ? 
                <ListItem button key='enableFloorEditMode' onClick={enableFloorEditMode} disabled={WebrtcService.isiOS() || WebrtcService.isAndroid()}>
                    <ListItemIcon><ViewQuiltIcon /></ListItemIcon>
                    <ListItemText primary="フロアエディター" />
                </ListItem>
                :
                ""
                }
                {props.enabledReconnectSideMenu ?
                    <ListItem button key='reconnect' onClick={handleReconnectMenu} >
                        <ListItemIcon><RefreshIcon /></ListItemIcon>
                        <ListItemText primary="テレミートの再接続" />
                    </ListItem>
                    :
                    ''
                }
                {props.enabledMicVolumeIntervalSideMenu ?
                <ListItem button key='micVolumeInterval' onClick={handleOpenMicVolumeInterval} >
                    <ListItemIcon><DuoIcon /></ListItemIcon>
                    <ListItemText primary="マイクボリューム検知間隔設定" />
                </ListItem>
                :
                ''
                }
                {allow && props.enabledManualSideMenu ?
                    <ListItem button key='viewManual' onClick={handleViewManual} component="a" href={adminmanualURL + "?" + nowDate.getDate()} target="_blank" rel="noopener noreferrer">
                        <ListItemIcon /*style={{marginTop:-30}}*/><MenuBookIcon /></ListItemIcon>
                        <ListItemText primary="管理者マニュアル" />
                        {/*
                        <Grid>
                            <ListItemText primary="マニュアル" />
                            <ListItemText primary="(管理者向け)" />
                        </Grid>
                        */}
                    </ListItem>
                    :
                    ''
                }
            </List>
        );
        
    }, [props.purposeOfUse, props.role,
        props.enabledSettingSideMenu, props.enabledTutorialSideMenu, props.enabledManualSideMenu,
        props.enabledInformationSideMenu, props.enabledReconnectSideMenu, props.enabledMicVolumeIntervalSideMenu, props.releaseNoteURL, props.usermanualURL]);

    return (
        <Fragment>
            <IconButton
                id="sideMenuOpenBtn"
                style={{ position: 'fixed', top: 5, left: 5, zIndex: ZIndex.fixedUiLayer, 
                         color: 'rgba(255,255,255,0.9)', backgroundColor: famcampusColor
                }}
                onClick={() => setIsOpen(true)}
                color="primary"
                aria-label="open side menu"
                size="medium"
            >
                <AppsIcon />
            </IconButton>

            <Drawer
                anchor={'left'}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                {drawerList}
            </Drawer>
        </Fragment>
    );
}

export const SideMenu = forwardRef(SideMenuComponent);
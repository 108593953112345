import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination, TextField, Theme, Typography } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { AdditionalContract, AdditionalContractSummary } from '../common/JsonClass';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof AdditionalContract;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'contractYearMonth', numeric: false, disablePadding: true, label: '契約年月' },  
    { id: 'webRtcAdditionalBytes', numeric: false, disablePadding: true, label: '追加契約（GB）' },
    { id: 'updateDatetime', numeric: false, disablePadding: true, label: '更新日' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof AdditionalContract) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof AdditionalContract) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1) * 3,
            overflow: 'scroll',
            height: '100vh',
        },
        media: {
            height: 140,
        },
        dividerMargin: {
            marginBottom: '20px',
        },
        alert: {
            background: "#f44336",
            color: "#fff",
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 500,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            backgroundColor: 'aliceblue',
        },
    }),
);

interface Props {
    masterData: { [index: string]: [{ id: number, name: string }] };
}

export interface SystemContractByteSizeHandler {
    open: (id: number) => void;
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;

    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}

// export default function OtherUsers() {
const SystemContractByteSizeComponent: ForwardRefRenderFunction<SystemContractByteSizeHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const { history } = useReactRouter();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof AdditionalContract>('id');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);
    const [selectTenantId, setSelectTenantId] = useState(0);
    const [selectYearMonth, setSelectYearMonth] = useState(0);
    const [disableYearMonth, setDisableYearMonth] = useState(true);
    const [additionalContractSummary, setAdditionalContractSummary] = React.useState([] as AdditionalContractSummary[]);
    const [additionalContract, setAdditionalContract] = React.useState([] as AdditionalContract[]);

    const [state, setState] = useState({
        tenantId: 0,
        tenantName: '',
        webRtcLimitSize: 0,
        contractYearMonth: '',
        updateDatetime: '',
        webRtcAdditionalBytes: 0,
        webRtcCarryOverBytes: 0,
        updateYearMonth: 0,
        currentDays: 0, 
        sumWebRtcAdditionalBytes: 0,
        sumWebRtcCarryOverBytes: 0,
    });
    const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));

    // テナント追加の入力データをクリア
    const clearState = () => {
        let clear = [] as AdditionalContract[];
        setAdditionalContract(clear); 

        setState({
            ...state,
            tenantId: 0,
            tenantName: '',
            webRtcLimitSize: 0,
            contractYearMonth: '',
            updateDatetime: '',
            webRtcAdditionalBytes: 0,
            webRtcCarryOverBytes: 0,
            updateYearMonth: 0,
            currentDays: 0, 
            sumWebRtcAdditionalBytes: 0,
            sumWebRtcCarryOverBytes: 0,
        })
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, additionalContract.length - page * rowsPerPage);

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id.toString())
            setSelectTenantId(id);
            setOpen(true);
        },
    }))

    const setSelectState = (yearMonth: number) => {
        for (let i = 0; i < additionalContractSummary.length; i++) {
            if(yearMonth === additionalContractSummary[i].contractYearMonth){ 
                // alert("webRtcAdditionalGigaBytes:" + additionalContractSummary[i].webRtcAdditionalGigaBytes);             
                setState({
                ...state,
                tenantId: additionalContractSummary[i].tenantId,
                tenantName: additionalContractSummary[i].tenantName,
                webRtcLimitSize: additionalContractSummary[i].webRtcLimitSize,
                contractYearMonth: additionalContractSummary[i].contractYearMonth.toString(),
                updateYearMonth: additionalContractSummary[i].currentYearMonth,
                sumWebRtcAdditionalBytes: additionalContractSummary[i].webRtcAdditionalGigaBytes, 
                sumWebRtcCarryOverBytes: additionalContractSummary[i].webRtcCarryOverGigaBytes, 
                currentDays: additionalContractSummary[i].currentDays,
            });
            }
        }
    }

    const handleChangeSelect = React.useCallback((value: any):void => {
        // alert("target " + value.target.name + ":" +value.target.value);
        setSelectYearMonth(value.target.value);
        handleGetContractByteSizeByYearMonth(value.target.value);
        setSelectState(value.target.value);
    }, [state]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AdditionalContract) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = additionalContract.map((n) => n.id.toString());
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        // refSystemFloorInfo.current.open(id);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // サーバーからデータを取得して表示する
        if (selectTenantId > 0) {
            // alert("selId:" + selectTenantId);
            handleGetAdditionalYearMonth();
        } else {
            clearState();
        }
    }, [selectTenantId])

    // セレクトボックス用の追加契約履歴
    const handleGetAdditionalYearMonth = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/addcontract/yearmonth', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                tenantId: selectTenantId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as AdditionalContractSummary[];
                setAdditionalContractSummary(result);       
                // alert("tenantName:" + result[0].tenantName); 
                // alert("webRtcAdditionalGigaBytes:" + result[0].webRtcAdditionalGigaBytes);  
                if(result[0].contractYearMonth !== 0){
                    setSelectYearMonth(result[0].contractYearMonth);    
                    handleGetContractByteSizeByYearMonth(result[0].contractYearMonth);           
                    setState({
                        ...state,
                        tenantId: result[0].tenantId,
                        tenantName: result[0].tenantName,
                        webRtcLimitSize: result[0].webRtcLimitSize,
                        contractYearMonth: result[0].contractYearMonth.toString(),
                        updateYearMonth: result[0].currentYearMonth,
                        sumWebRtcAdditionalBytes: result[0].webRtcAdditionalGigaBytes, 
                        sumWebRtcCarryOverBytes: result[0].webRtcCarryOverGigaBytes, 
                        currentDays: result[0].currentDays,
                    });
                    setDisableYearMonth(false);
                }else{
                    setState({
                        ...state,
                        tenantId: result[0].tenantId,
                        tenantName: result[0].tenantName,
                        webRtcLimitSize: result[0].webRtcLimitSize,
                        updateYearMonth: result[0].currentYearMonth,
                        sumWebRtcAdditionalBytes: result[0].webRtcAdditionalGigaBytes, 
                        sumWebRtcCarryOverBytes: result[0].webRtcCarryOverGigaBytes, 
                        currentDays: result[0].currentDays,
                    });
                    setDisableYearMonth(true);
                }
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("TenantSearch error.");
                    console.log(err.response);
                }
            });
    }

    const handleGetContractByteSizeByYearMonth = (selYearMonth: number) => {
        // alert("selYearMonth:" + selYearMonth);
        clearState();
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/addcontract/select', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                tenantId: selectTenantId.toString() as string,
                contractYearMonth: selYearMonth.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as AdditionalContract[];
                // alert("tenantId:" + result[0].tenantId + "  tenantName:" + result[0].tenantName);
                if(result[0].tenantName != ""){
                    // 既存契約がある場合
                    setAdditionalContract(result); 
                }
                setOrder('desc');
                setOrderBy('contractYearMonth');
            })
            .catch(() => {
                console.log("login error.");
            });
    }

    // 指定年月の翌月を算出
    const getNextYearMonth = (yearMonth: number):number => {
        let nextYearMonth = 0 as number;
        if(yearMonth % 100 === 12){
            nextYearMonth = yearMonth/100;
            nextYearMonth = parseInt(nextYearMonth.toString(), 10) + 1;
            nextYearMonth = nextYearMonth * 100 + 1;
        }else{
            nextYearMonth = yearMonth + 1;
        }
        return nextYearMonth;
    }

    const handleClose = () => {
        setSelectTenantId(0);
        setOpen(false);
    };

    // 通信量上限を更新
    const handleUpdateUserSize = () => {

        if (state.webRtcAdditionalBytes === 0) {
            alert("追加通信量を入力してください。");

        // 20210617 delete（運用で対処するので一旦不要）
        // }else if( (state.webRtcAdditionalBytes < 100) || (state.webRtcAdditionalBytes % 100 > 0) ){
        //     alert("追加通信量は100GB単位で入力してください。");

        } else {
            // 追加処理
            if (window.confirm("テナント " + state.tenantName + " の当月の通信量上限を追加します。よろしいですか？")) {
                let addWebRtcAdditionalBytes = state.webRtcAdditionalBytes * 1024 * 1024 * 1024 as number;
                // alert("updateWebRtcBasicContractBytes:" + updateWebRtcBasicContractBytes);
                axios
                    .create({
                        withCredentials: true,
                    })
                    .post('/api/system/addcontracts/update', new URLSearchParams({
                        tab_id: sessionStorage.getItem("TABID") as string,
                        tenantId: state.tenantId.toString(),
                        updateYearMonth: state.updateYearMonth.toString(),
                        webRtcAdditionalBytes: addWebRtcAdditionalBytes.toString(),
                        webRtcCarryOverBytes: state.webRtcCarryOverBytes.toString(),
                    }))
                    .then((response: AxiosResponse) => {
                        let msg = response.data as string;
                        // alert("result:" + msg);
                        // alert("テナント " + state.tenantName + " の契約ユーザ数の更新が完了しました。");
                        setFinishInfo(new FinishInfo({
                            message: "テナント " + state.tenantName + " の当月通信量上限を追加が完了しました。", password: msg
                        }));
                    })
                    .catch(() => {
                        // alert("テナント " + state.tenantName + " の契約ユーザ数の更新に失敗しました。");
                        setFinishInfo(new FinishInfo({
                            message: "テナント " + state.tenantName + " の当月通信量上限を追加に失敗しました。"
                        }));
                    });
                    handleClose();
            } else {
                alert("更新をキャンセルしました。");
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        //alert(name + ":" + target.value);

        setState(() => {
            return { ...state, [name]: target.value };
        });
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>通信量追加契約</DialogTitle>
                <DialogContent style={{ padding: "20px 20px" }}>
                    <Box fontSize="18px"><b>テナントID：{state.tenantId}　　テナント名：{state.tenantName}</b></Box>
                    <br></br>
                    <Box>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">追加契約年月 (YYYYMM)</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="contractYearMonth"
                                onChange={handleChangeSelect}
                                label="Age"
                                input={<OutlinedInput margin='dense' aria-label={"Age"} label={"追加契約年月 (YYYYMM)"} color="primary" />}
                                style={{ width: 560 }}
                                value={selectYearMonth}
                                disabled={disableYearMonth}
                            >
                                {additionalContractSummary.map((target) => (
                                    <MenuItem value={target.contractYearMonth}>{target.contractYearMonth}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <br></br>
                    <Box fontSize="16px"><b>　通信量の追加契約履歴　　　　　　　　　追加通信量合計：{state.sumWebRtcAdditionalBytes}　GB</b></Box>
                    <Paper className={classes.paper} elevation={3} style={{ padding: "10px" }}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={additionalContract.length}
                                />
                                <TableBody>
                                        {stableSort(additionalContract, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id.toString());
                                            // const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell component="th" scope="row" padding="none">{row.contractYearMonth}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">{row.webRtcAdditionalBytes/1024/1024/1024}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">{row.updateDatetime}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 23 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={additionalContract.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>

                    <TextField
                        margin="dense"
                        id="currentYearMonth"
                        name="currentYearMonth"
                        label="追加年月 (YYYYMM)"
                        variant={'outlined'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        value={state.updateYearMonth}
                        type="number"
                        disabled={true}
                    />
                    <TextField
                        margin="dense"
                        id="webRtcAdditionalBytes"
                        name="webRtcAdditionalBytes"
                        label="追加通信量（GB）"
                        variant={'outlined'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        value={state.webRtcAdditionalBytes}
                        type={"number"}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                    <Button id="submit" onClick={handleUpdateUserSize} color="primary">　　更新　　</Button>
                </DialogActions>
            </Dialog>

            <Dialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
                <DialogContent>
                    <DialogContentText>{finishInfo.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export const SystemContractByteSize = forwardRef(SystemContractByteSizeComponent);
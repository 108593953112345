import { Fragment } from 'react';
import EditTextDialog from "./EditTextDialog";

interface Props {
    className?: string,
    isOpen: boolean,
    floorObjectId: number,
    title: string,
    text: string,
    maxLength: number,
    isPlayAudio?: boolean,
    isResetURL?: boolean,
    onClose: () => void,
    sendText: (id: number, text: string, isPlayAudio: boolean, isResetURL: boolean) => void
}

export default function EditVideoURLDialog(props: Props) {
    return (
        <Fragment>
            <EditTextDialog {...props}/>
        </Fragment>
    );
}
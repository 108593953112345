import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import SystemFrame from './SystemFrame';
import axios, { AxiosResponse } from 'axios';
import { Utility } from '../common/Utility';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
    },
    media: {
        height: 140,
    },
    dividerMargin: {
        marginBottom: '20px',
    },
    alert: {
        background: "#f44336",
        color: "#fff",
    },

}));

export default function SystemDeployFunction() {
    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = React.useState({
        open: false,
        message: "",
    });

    useEffect(() => {
        setOpen({
            open: true,
            message: "本画面の機能はデプロイ後に実行する必要のある機能です。通常時に使用しないように注意してください。",
        });
    }, [])

    const handleClose = () => {
        setOpen({
            open: false,
            message: "",
        });
    };

    const handleHelloKey = () => {
        const params = new URLSearchParams();
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        axios.post('/api/system/hello/apikey/complement', params)
            .then((response: AxiosResponse) => {
                // 302 を検知したらログアウト
                if (Utility.checkRedirectAndLogout(response, history)) {
                    return;
                }
                setOpen({
                    open: true,
                    message: "Helloテナントの追加が実行されました。実行結果はサーバーログまたはDB.tenant.moreNoteHelloApiKey1,moreNoteHelloApiKey2が登録されているか確認してください。",
                });
            })
            .catch(err => {
                console.error(err);
                // 管理者権限が無くなっている等の理由でデータが取れなかった場合は、念の為 強制的にログアウト
                Utility.logout(history);
            });
    }

    const drawAlert = useMemo(() => {
        if (open.open === false) return;
        return (
            <Dialog
                open={open.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown
                disableBackdropClick>
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "red", color: "white" }}>{"注意"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {open.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open.open, open.message])

    const draw = () => {
        return (
            <Fragment>
                <Paper elevation={1} style={{ padding: 10, width: 800, textAlign: "left" }}>
                    <Typography variant="body1">Hello連携に必要な情報を保管します。</Typography>
                    <Typography variant="body1">V1.6を起動するとdb.tenantテーブルにmoreNoteHelloApiKey1,moreNoteHelloApiKey2が追加されますが、デフォルト値のNullのままだとHello連携に失敗します。下記のボタンを押下するとこれらのフィールドを保管します。</Typography>
                    <Typography variant="body1">対象となるテナントはmoreNoteHelloApiKey1,moreNoteHelloApiKey2が空の全テナントになります。1件ずつHelloサーバーとHTTP通信を行う為、1分以上かかる場合があります。タイムアウトしてエラーが表示された場合は、DBを確認して全テナントのフィールドが埋まったことを確認してください。</Typography>
                    <Button variant="contained" color="secondary" onClick={handleHelloKey}>実行</Button>
                </Paper>
            </Fragment>
        )
    }

    return (
        <div className={classes.root}>
            <SystemFrame />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {draw()}
            </main>
            {drawAlert}
        </div>
    )
}
import { Box, Button, TextField } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import React, { Fragment, useState } from 'react';
import JfsClient from '@fsi/jfs-sdk';

export default function NewDeleteUser() {
    const { history } = useReactRouter();

    const [addButton, setAddButton] = useState(true);

    const [state, setState] = useState({
        subId: "" as string,
    })

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        setState({ ...state, [name]: event.target.value });
    }

    const registUser = () => {
        setAddButton(false);
        axios.delete('/api/v1/admin/users/' + state.subId)
            .then((e: AxiosResponse) => {
                history.push({
                    pathname: "/top",
                });
            }).catch(err => {
                alert(err.response.data)
                setAddButton(true);
            });
        // SDKではTabid必須になっているが、上ではsubidのみでリクエストを送っている
        // httpClient.deleteUserInfo()
    }

    return (
        <Fragment>

            <div style={{ padding: 10, textAlign: "left" }}>
                <form noValidate autoComplete="off">
                    <div>
                        <TextField
                            variant="standard"
                            id="subId"
                            label="subId"
                            name="subId"
                            value={state.subId}
                            onChange={handleChange}
                            inputProps={{
                                autocomplete: 'new-regist-id',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                    </div>
                    <Box m={2}></Box>
                </form>
                <div>
                    <Button disabled={!addButton} onClick={registUser} variant="contained" color="primary">登録</Button>
                    <p>※管理者権限を持っているユーザーのみ削除可能です</p>
                </div>
            </div>
        </Fragment>
    )
}


import React, { useMemo, useEffect, useState } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { Fab, Tooltip } from '@material-ui/core';
import { SELECT_NONE } from "../common/Constants";
import DynamicIcon from '../common/DynamicIcon';
import ZIndex from "../common/ZIndex";
import { calcAvatarHeight, getAvatarVideoSize } from "../common/AvatarSize";
import { AvatarMenuData } from '../common/JsonClass';
import { Utility } from '../webrtc/Utility';
import { ThemeProvider } from '@material-ui/styles';
import ForcedSignout from './ForcedSignout';
import SignoutIcon from '../img/ic_signout.svg'
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(-0.1), // = 8 * -0.1 = -0.8
        },
        position: "absolute",
    },
    fab: {
        "&$disabled": {
            backgroundColor: "#999999",
            color: "white"
        }
    },
    disabled: {},
    svgIconClass: {
        ...SELECT_NONE,
        position: 'relative',
        marginBottom: '13px',
        color: 'white',
    },
    iconNoName: {
        ...SELECT_NONE,
        position: 'relative',
    },
    svgIcon: {
        ...SELECT_NONE,
        position: 'absolute',
        width: 30,
        height: 30,
        top: -5,
        left: -3
    },
    avatarName: {
        ...SELECT_NONE,
        position: "absolute",
        fontSize: 10,
        top: 30,
        left: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        padding: "0 2px",
        width: "100%",
        color: "white",
    },
    avatarNameWrap: {
        ...SELECT_NONE,
        position: "absolute",
        fontSize: 10,
        top: 33,
        left: 0,
        // overflow: "hidden",
        textOverflow: "ellipsis",
        // whiteSpace: "nowrap",
        boxSizing: "border-box",
        padding: "0 2px",
        width: "100%",
        lineHeight: 1.0,
        color: "white",
    }
}));

const fabTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
    overrides: {
        MuiFab: {
            primary: {
                "&:hover": {
                    backgroundColor: "#0095FA",
                },
            },
            secondary: {
                "&:hover": {
                    backgroundColor: "#0095FA",
                },
            }
        },
    },
})

interface Props {
    isOpen: boolean;    // アバターメニューを開いたか
    top: number; // user中心のfloorに対する絶対Y座標
    left: number; // user中心のfloorに対する絶対X座標
    user: User;
    zIndex: number;
    isLarge: boolean;
    scale: number;
    myUserSubId: string | null;
    onClickMemo: () => void;   // メモ要素を開く・閉じる
    onClickUserInfo: () => void; // profileを開く・閉じる
    onClickAddMyRoomUser: () => void; // マイルームに追加
    onClickDeleteMyRoomUser: () => void; // マイルームから削除
    onClickGoToSee: () => void; // 会いに行く（マイルーム専用)
    onClickFlorcedSignoutUser: (yes:boolean) => void;  // 強制退出 - officeからの移植(v2.4追加開発)
    enabledMemoOtherUserMenu: boolean;  // メモメニューを表示するか (floorテーブルのenabledmemootherusermenuの値)
    myUser: User;
    enabledBusinessCard: boolean;
    kind: string | null;    // 現状 'myroom' とそれ以外で切り分け
    enabledMyRoom: boolean; 
    enabledChat: boolean;
    onClickChat: () => void;   // チャット要素を開く・閉じる
}

class FunctionIds {
    public static Memo: number = 1;
    public static ClickUserInfo: number = 2;
    public static GoToSee: number = 3;
    public static AddMyRoomUser: number = 4;
    public static DeleteMyRoomUser: number = 5;
    public static Chat: number = 6;
    public static ForcedSignoutUser: number = 7; // officeでは6番で設定されている。。6番はchatが使っているため、7番に。本数字はバックエンドで利用するものではないため問題ないはず
}

export default function OtherUserMenu(props: Props) {
    const [showForcedSignoutDialog, setShowForcedSignoutDialog] = useState(false);
    const [isMyUserFloorManager, setIsFloorManager] = useState(false);
    const classes = useStyles();
    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient, JFS_ERRORS } = jfsClient;

    const isMyUserSubIdEmpty = useMemo(() => {
        return props.myUserSubId === "" || props.myUserSubId === null;
    }, [props.myUserSubId]);

    const isMyUserAdmin = useMemo(() => {
        return props.myUser.role !== undefined && props.myUser.role.indexOf('ROLE_ADMIN') !== -1;
    }, [props.myUser])

    useEffect(() => {
        const checkIsFloorManager = () => {
            /*
            const params = new URLSearchParams();
            params.append("tab_id", sessionStorage.getItem("TABID") as string);
            axios.post('/api/user/floor/manager/permission/exist', params)
            .then((res: AxiosResponse) => {
                const isManager = res.data as boolean;
                if(isManager === true) setIsFloorManager(true);
                else setIsFloorManager(false);
            })
            .catch(err => {
                console.log(err);
                setIsFloorManager(false);
            })
            */
            httpClient.checkIsFloorManager(sessionStorage.getItem('TABID') as string)
                .then((res: string) => {
                    if(res === JFS_ERRORS.OK.code) setIsFloorManager(true);
                    else setIsFloorManager(false);
                }).catch((err: JfsError) => {
                    console.log('httpClient.checkIsFloorManager error httpStatusCode=['+err.httpStatusCode+'] code=['+err.code+'] detail=['+err.detail+']');
                    setIsFloorManager(false);
                });
        }
        if(props.isOpen === true) checkIsFloorManager();
    }, [props.isOpen])

    const isSubIdEmpty = useMemo(() => {
        return props.user.subId === "" || props.user.subId === null;
    }, [props.user.subId]);

    const isShowProfile = useMemo(() => {
        return props.enabledBusinessCard && (props.user.webRtcRoomId === "" || props.myUser.webRtcRoomId === "" || props.user.webRtcRoomId !== props.myUser.webRtcRoomId);
    }, [props.user.webRtcRoomId, props.myUser.webRtcRoomId, props.enabledBusinessCard])

    const isMyRoomUser = useMemo(() => {
        return props.kind === 'myroom';
    }, [props.kind]);

    const isOfflineUser = useMemo(() => {
        if(props.user.stayFloorName === "オフライン") return true;
        if(props.kind === 'myroom' && props.user.isCommuting === false && props.user.validWebSocketHostMaster === false) return true;
        return false;
    }, [props.user.stayFloorName, props.user.isCommuting, props.user.validWebSocketHostMaster]);

    // menuのデータ形式はUserMenuと合わせている
    // ! OtherUserMenu内のボタン情報をDBで保持する仕組みは作成していない
    const menu = useMemo(() => [
        // {
        //     avatarMenuId: 1,
        //     avatarMenuMasterId: 1,
        //     functionId: 1,
        //     orderBy: 1,
        //     avatarName: "メモ",
        //     color: "secondary", // "secondary" | "inherit" | "default" | "primary" | undefined
        //     disabled: false,
        //     hidden: isMyUserSubIdEmpty || isSubIdEmpty || !props.enabledMemoOtherUserMenu,
        //     iconPath: "Note",
        //     authorityLevel: 0,
        //     parentId: 0,
        //     floorId: 0,
        //     pictograph: "",
        // },
        {
            avatarMenuId: 2,
            avatarMenuMasterId: 2,
            functionId: 2,
            orderBy: 2,
            avatarName: props.enabledBusinessCard ? "名刺" : "情報",
            color: "primary", // "secondary" | "inherit" | "default" | "primary" | undefined
            disabled: false,
            hidden: isShowProfile,
            iconPath: "InsertChart",
            authorityLevel: 0,
            parentId: 0,
            floorId: 0,
            pictograph: "",
        },
        {
            avatarMenuId: FunctionIds.GoToSee,
            avatarMenuMasterId: FunctionIds.GoToSee,
            functionId: FunctionIds.GoToSee,
            orderBy: FunctionIds.GoToSee,
            avatarName: "会いに行く",
            color: "primary", // "secondary" | "inherit" | "default" | "primary" | undefined
            disabled: isOfflineUser,
            hidden: !isMyRoomUser || !props.enabledMyRoom,
            iconPath: "DirectionsRun",
            authorityLevel: 0,
            parentId: 0,
            floorId: 0,
            pictograph: "",
        },
        {
            avatarMenuId: FunctionIds.AddMyRoomUser,
            avatarMenuMasterId: FunctionIds.AddMyRoomUser,
            functionId: FunctionIds.AddMyRoomUser,
            orderBy: FunctionIds.AddMyRoomUser,
            avatarName: "フォロー する",
            color: "primary", // "secondary" | "inherit" | "default" | "primary" | undefined
            disabled: false,
            hidden: isMyRoomUser || !props.enabledMyRoom,
            iconPath: "PersonAdd",
            authorityLevel: 0,
            parentId: 0,
            floorId: 0,
            pictograph: "",
        },
        {
            avatarMenuId: FunctionIds.DeleteMyRoomUser,
            avatarMenuMasterId: FunctionIds.DeleteMyRoomUser,
            functionId: FunctionIds.DeleteMyRoomUser,
            orderBy: FunctionIds.DeleteMyRoomUser,
            avatarName: "フォロー しない",
            color: "primary", // "secondary" | "inherit" | "default" | "primary" | undefined
            disabled: false,
            hidden: !isMyRoomUser || !props.enabledMyRoom,
            iconPath: "PersonAddDisabled",
            authorityLevel: 0,
            parentId: 0,
            floorId: 0,
            pictograph: "",
        },
        {
            avatarMenuId: FunctionIds.Chat,
            avatarMenuMasterId: FunctionIds.Chat,
            functionId: FunctionIds.Chat,
            orderBy: FunctionIds.Chat,
            avatarName: "チャット",
            color: "primary", // "secondary" | "inherit" | "default" | "primary" | undefined
            disabled: false,
            // hidden: isMyUserSubIdEmpty || isSubIdEmpty || !props.enabledMemoOtherUserMenu,
            hidden: isMyUserSubIdEmpty || isSubIdEmpty || !props.enabledMemoOtherUserMenu || !props.enabledChat,
            iconPath: "Chat",
            authorityLevel: 0,
            parentId: 0,
            floorId: 0,
            pictograph: "",
        },
        {
            avatarMenuId: FunctionIds.ForcedSignoutUser,
            avatarMenuMasterId: FunctionIds.ForcedSignoutUser,
            functionId: FunctionIds.ForcedSignoutUser,
            orderBy: FunctionIds.ForcedSignoutUser,
            avatarName: "強制ログアウト",
            color: "primary", // "secondary" | "inherit" | "default" | "primary" | undefined
            disabled: false,
            hidden: !isMyUserAdmin && !isMyUserFloorManager,
            iconPath: "SignoutIcon", //"ExitToApp",
            authorityLevel: 0,
            parentId: 0,
            floorId: 0,
            pictograph: "",
        },
    ], [isMyUserSubIdEmpty, isSubIdEmpty, props.enabledMemoOtherUserMenu, isShowProfile, props.enabledBusinessCard, isMyRoomUser, props.enabledMyRoom, props.enabledChat]);

    // avatar要素の高さ
    const avatarHeight = useMemo(() => {
        return calcAvatarHeight(props.user.height, props.isLarge);
    }, [props.user.height, props.isLarge]);

    // avatarの映像サイズ
    const avatarVideoHeight = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.9 * getAvatarVideoSize(props.user.isLargeVideo) // 0.9は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    // 表示されているメニュー項目の数
    const visibleMenuLength = useMemo(() => {
        return menu.filter(m => m.hidden === false).length;
    }, [menu]);

    // 親要素名
    const wrapperName = useMemo(() => {
        if(isMyRoomUser){
            return "myRoomWrapper";
        }
        else{
            return "floorWrapper";
        }
    }, [isMyRoomUser]);

    const TOP = React.useMemo(() => {
        const wrapper = document.getElementById(wrapperName) as HTMLDivElement;
        const myRoomEnd = document.getElementById("myRoomRnd") as HTMLDivElement;
        const nameHeight = 20; // 名前要素の高さ

        if(props.kind !== 'myroom'){
            let calcTop = props.top - wrapper.scrollTop / props.scale;
            if (calcTop < 110) {
                calcTop = calcTop + avatarHeight + nameHeight; // 名前要素の下に配置
            } else {
                calcTop = calcTop - 75 - avatarVideoHeight; // 状態表示要素の上に配置
            }
            return calcTop + wrapper.scrollTop / props.scale;
        }
        else{
            let calcTop = props.top;
            if (calcTop < myRoomEnd.scrollTop + 110) {
                calcTop = calcTop + avatarHeight + nameHeight; // 名前要素の下に配置
            } else {
                calcTop = calcTop - 75 - avatarVideoHeight - avatarHeight - 10; // 状態表示要素の上に配置
            }
            return calcTop + wrapper.scrollTop / props.scale;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.top, avatarHeight, avatarVideoHeight]);

    const LEFT = React.useMemo(() => {
        const wrapper = document.getElementById(wrapperName) as HTMLDivElement;
        const LEFT_MARGIN = 5;
        const RIGHT_MARGIN = 7;

        // メニュー1個当たり幅
        // 8 * 0.1 は、classes.rootの「theme.spacing(-0.1)」の値
        const UNIT_WIDTH = 56 - 8 * 0.1 * 2;

        // メニュー全体幅
        const menuWidth = UNIT_WIDTH * (visibleMenuLength);

        // 基準位置（prop.left - wrapper.scrollLeft）から、メニュー全体幅の半分を引いた位置
        let calcLeft = props.left - wrapper.scrollLeft / props.scale - menuWidth / 2;

        // 左端
        if (calcLeft < 0) {
            calcLeft = LEFT_MARGIN;
        }
        // 右端
        if (calcLeft > (wrapper.offsetWidth / props.scale - visibleMenuLength * UNIT_WIDTH - RIGHT_MARGIN)) {
            calcLeft = wrapper.offsetWidth / props.scale - visibleMenuLength * UNIT_WIDTH - RIGHT_MARGIN;
        }
        return calcLeft + wrapper.scrollLeft / props.scale;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.left, visibleMenuLength]);

    useEffect(() => {
        const target = document.getElementById("OtherUserMenuDiv") as HTMLDivElement;
        target.addEventListener('pointerdown', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('touchmove', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('pointermove', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('pointerup', (e) => Utility.forbiddenTattle(e), false);

        return function cleanup() {
            target.removeEventListener('pointerdown', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('touchmove', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('pointermove', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('pointerup', (e) => Utility.forbiddenTattle(e), false);
        }
    }, [])

    useEffect(() => {
        if (isSubIdEmpty){
            console.log(`「${props.user.displayName}」のsubIdが空のため、メモ機能を使用できません。OtherUserMenu内の「メモ」ボタンを非表示にしました。`);
        }
        //console.log("props.enabledMemoOtherUserMenu ", props.enabledMemoOtherUserMenu);
        //if (!props.enabledMemoOtherUserMenu){
        //    console.log('メモメニュー表示が無効であるため、OtherUserMenu内の「メモ」ボタンを非表示にしました。');
        //}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubIdEmpty, props.enabledMemoOtherUserMenu, props.enabledChat])

    // UserMenu.tsx内のdrawIconメソッドを持ってきた。
    // ! OtherUserMenu内のボタン情報をDBで保持する仕組みは作成していない
    const drawIcon = ({ iconPath, avatarMenuId, avatarMenuMasterId, avatarName }: AvatarMenuData) => {
        if (!iconPath) {
            //return console.error(`icon path cannot be null, avatar_menu_id: ${avatarMenuId}`);
            return console.error(`icon path cannot be null, other_user_menu: ${avatarName}`);
        }
        // SVGアイコン 
        // if (iconPath.endsWith(".svg")) {
        //     return (
        //         <div className={avatarName ? classes.svgIconClass : classes.iconNoName} style={{ width: '18px', height: '18px' }}>
        //             <img className={classes.svgIcon} src={`${apiBase}/avatarmenu/picture/${avatarMenuMasterId}`} alt="" />
        //         </div>
        //     )
        // }
        // Materialアイコン
        // 強制退出のアイコン
        // 退勤と同じアイコンで、通常のアバターメニューアイコンとは別のパスに保存されている
        if (iconPath === "SignoutIcon") {
            return(
                <div className={avatarName ? classes.svgIconClass : classes.iconNoName} style={{ width: '18px', height: '18px' }}>
                    <img src={SignoutIcon} className={classes.svgIcon} style={{width: '20px', height: '20px', marginLeft: '2px'}} alt="" />
                </div>
            );
        }
        return (
            <div className={avatarName ? classes.svgIconClass : classes.iconNoName}>
                <DynamicIcon iconName={iconPath} />
            </div>
        )
    }

    const handleFunctionIds = ((id: FunctionIds, menuId: number) => {
        switch (id) {
            // case FunctionIds.Memo:
            //     return props.onClickMemo();
            case FunctionIds.ClickUserInfo:
                return props.onClickUserInfo();
            case FunctionIds.AddMyRoomUser:
                return props.onClickAddMyRoomUser();
            case FunctionIds.DeleteMyRoomUser:
                return props.onClickDeleteMyRoomUser();
            case FunctionIds.GoToSee:
                return props.onClickGoToSee();
            case FunctionIds.Chat:
                return props.onClickChat();
            case FunctionIds.ForcedSignoutUser:
                return setShowForcedSignoutDialog(true);
                default:
                return;
        }
    })

    // アバターメニューアイコンをクリックイベント
    const handleClickAvatarMenu = (avatarMenu: AvatarMenuData) => {
        // const subMenuList = getAvatarMenuList(avatarMenu.avatarMenuId);
        // if (subMenuList.length) {
        //     setMenu(subMenuList)
        //     return;
        // }
        switch (avatarMenu.functionId) {
            // case 1:  // 状況設定
            //     handleFunctionIds(FunctionIds.Memo, avatarMenu.avatarMenuMasterId);
            //     break;
            case 2:   // 状態表示
                handleFunctionIds(FunctionIds.ClickUserInfo, avatarMenu.avatarMenuMasterId);
                break;
            case FunctionIds.AddMyRoomUser:   // マイルームに追加
                handleFunctionIds(FunctionIds.AddMyRoomUser, avatarMenu.avatarMenuMasterId);
                break;
            case FunctionIds.DeleteMyRoomUser:  // マイルームから削除
                handleFunctionIds(FunctionIds.DeleteMyRoomUser, avatarMenu.avatarMenuMasterId);
                break;
            case FunctionIds.GoToSee: // 会いに行く
                handleFunctionIds(FunctionIds.GoToSee, avatarMenu.avatarMenuMasterId);
                break;
            case FunctionIds.Chat:   // 状態表示
                handleFunctionIds(FunctionIds.Chat, avatarMenu.avatarMenuMasterId);
                break;
            case FunctionIds.ForcedSignoutUser: // 強制退出
                handleFunctionIds(FunctionIds.ForcedSignoutUser, avatarMenu.avatarMenuMasterId);
                break;
            default:
                console.log(`functionId: ${avatarMenu.functionId} is null`);
        }
    }

    const draw = useMemo(() => {
        return (
            <div className={classes.root} style={{ top: TOP, left: LEFT, zIndex: ZIndex.myUser + 6 }}>
                {menu.map((spaction) => (
                    <ThemeProvider theme={fabTheme}>
                        <Fab
                            key={spaction.avatarMenuId}
                            variant="round"
                            size="large"
                            color={spaction.color as "secondary" | "inherit" | "default" | "primary" | undefined}
                            disabled={spaction.disabled}
                            onClick={() => handleClickAvatarMenu(spaction)}
                            classes={{ root: classes.fab, disabled: classes.disabled }}
                            style={{ display: spaction.hidden ? "none" : "" }}
                        >
                            <Tooltip title={spaction.avatarName}>
                                <div>
                                    {drawIcon(spaction)}
                                    <div className={spaction.avatarName.length > 5 ? classes.avatarNameWrap : classes.avatarName}>
                                        {spaction.avatarName}
                                    </div>
                                </div>
                            </Tooltip>
                        </Fab>
                    </ThemeProvider>
                ))}
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.top, props.left, menu, TOP, LEFT, visibleMenuLength]);

    const handleClickForcedSignout = (yes: boolean) => {
        setShowForcedSignoutDialog(false);
        props.onClickFlorcedSignoutUser(yes);
    }

    const drawForcedSignoutDialog = useMemo(() => {
        return (
            <ForcedSignout open={showForcedSignoutDialog} onClick={handleClickForcedSignout} />
        );
    }, [showForcedSignoutDialog])

    return (
        <div id="OtherUserMenuDiv">
            {draw}
            {drawForcedSignoutDialog}
        </div>
    )
}
import React, { useEffect } from 'react';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";

export interface Props {
    className?: string,
    floorObject: FloorObject,
    noBorder?: boolean,
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    pointerEvents: 'none',
}

export default function StaticKanbanCenterAligned(props: Props) {
    const { className, noBorder } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1 } = props.floorObject;
    const kanbanHeight = document.getElementById(`kanbanText${id}`)?.clientHeight;
    const texts = text1.split("\\n").map((item, index) => {
        return (
          <React.Fragment key={index}>{item}<br /></React.Fragment>
        );
      });

    return (
        <div
            key={`kanban${id}`}
            id={`kanban${id}`}
            className={className}
            style={{
                ...kanbanTextStyle,
                display: 'flex',
                left: offsetLeft,
                top: offsetTop,
                border: noBorder ? '0' : '1px solid #AAA',
                borderRadius: noBorder ? '' : '2%',
                boxShadow: noBorder ? '' : '1px 1px 1px 1px rgba(0,0,0,0.1)',
                backgroundColor: noBorder ? '' : 'rgba(255, 255, 255, 0.9)',
                height: objectMaster.height,
                width: objectMaster.width,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}
        >
            <div
                id={`kanbanText${id}`}
                style={{
                    height: kanbanHeight? kanbanHeight >= objectMaster.height? objectMaster.height : '' : '',
                    width: objectMaster.width,
                }}
            >   
                {text1.length === 0
                    ? <div style={{ width: 15, height: 15 }} />
                    : texts}
                    </div>
        </div>
    )
}
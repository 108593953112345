import React, { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { MyUserStatusTimerMenu, statusTimerMenuHandler } from './MyUserStatusTimerMenu';
import { AvatarData, AvatarMenuData, FloorData, FlexibleNamePlate } from '../common/JsonClass';
import { Typography } from '@material-ui/core';
import FlexibleNamePlateComponent from './FlexibleNamePlate';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Utility } from '../common/Utility';
import JfsClient, { User } from '@fsi/jfs-sdk';

interface Props {
    open: boolean,
    onClick: any,
    avatarId: number,
    avatarDatas: AvatarData[],
    onChangeSetting: any,
    onChangeLoginoutNotification: any,
    purposeOfUse: number,
    floorStartConfirmSetting: number,
    myStartConfirmSetting: number,
    myLoginoutNotificationSetting: number,
    myLoginoutNotificationSoundSetting: number,
    user: User,
    avatarMenuDataList: AvatarMenuData[],
    floorData: FloorData,
    namePlateColors: FlexibleNamePlate[],
    onChangeNamePlate:(value: number) => void,
}

const radioButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

export default function SetMyAvatar(props: Props) {
    const [value, setValue] = useState(props.avatarId);
    // ステータスタイマーコンポーネントの参照
    const statusTimerMenuRef = useRef({} as statusTimerMenuHandler);
    const [namePlateColorValue, setNamePlateColorValue] = useState(props.user.flexibleNamePlate?.id);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    //const AVATER_AREA_HEIGHT = 120;
    const AVATER_AREA_HEIGHT = 100;

    const onOk = () => {
        if (statusTimerMenuRef.current.checkErr() === true) {
            // ステータスタイマーでエラーが表示されていたらOKを押せなくする
            return;
        }
        if (value !== props.avatarId || startConfirmSettingValue !== props.myStartConfirmSetting) {
            if (value !== props.avatarId)
                props.onClick(true, value)
            if (startConfirmSettingValue !== props.myStartConfirmSetting) {
                if (props.floorStartConfirmSetting < 10) {
                    props.onChangeSetting(startConfirmSettingValue);
                }
            }
        } else {
            props.onClick(false)
        }

        if (value !== props.avatarId || isLoginoutNotification !== props.myLoginoutNotificationSetting || isLoginoutNotificationSound !== props.myLoginoutNotificationSoundSetting) {
            if (value !== props.avatarId)
                props.onClick(true, value)
            if (isLoginoutNotification !== props.myLoginoutNotificationSetting || isLoginoutNotificationSound !== props.myLoginoutNotificationSoundSetting) {
                if (props.floorStartConfirmSetting < 10) {
                    props.onChangeLoginoutNotification(isLoginoutNotification, isLoginoutNotificationSound);
                }
            }
        } else {
            props.onClick(false)
        }

        statusTimerMenuRef.current.setStatusTimer();
        if(namePlateColorValue != props.user.flexibleNamePlate?.id){
            props.onChangeNamePlate(namePlateColorValue);
        }
    }
    const onClose = () => {
        setValue(props.avatarId);
        setStartConfirmSettingValue(props.myStartConfirmSetting);
        setNamePlateColorValue(props.user.flexibleNamePlate?.id);
        setLoginoutNotification(props.myLoginoutNotificationSetting);
        setLoginoutNotificationSound(props.myLoginoutNotificationSoundSetting);
        props.onClick(false)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter") {
            onOk();
        }
    };

    const initScroll = () => {
        const list = document.getElementById('avatarSelectList');
        const item = document.getElementById(`avatarColor${value}`);
        if (!list || !item) return;
        let top = item.offsetTop - list.offsetTop + 3 * item.clientHeight;
        top = top > 0 ? top : 0;
        if (list.scroll !== undefined) {
            list.scrollTo(0, top);
        } else {
            list.scrollTop = top;
        }
    }

    // UI open時の要素内スクロール位置移動
    useEffect(() => {
        setTimeout(() => initScroll(), 100);
    }, [props.open]);

    const avatarImgUrl = useMemo(() => {
        // return `/api/user/avatar/picture/${value}`
        return `${httpClient.createAvatarImgUrl(value)}`
    }, [value]);

    const colorName = useMemo(() => {
        const avatar = props.avatarDatas.find(avatar => avatar.id === value);
        return avatar ? avatar.colorName : '';
    }, [props.avatarDatas, value]);

    /*const drawAvatarSelect = useMemo(() => {
        return (
            <Fragment>
                <hr />
                <DialogContentText id="setMyAvatarDialog-description">
                    <Typography variant="h6">アバターの設定</Typography>
                </DialogContentText>
                <Grid container justify="center" alignItems="center" style={{ maxHeight: 200, overflowY: "auto" }}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{
                            width: 250,
                            height: 170,
                            marginBottom: 20,
                        }}
                    >
                        <img src={avatarImgUrl} style={{ width: 85, height: "auto" }} alt="avatar image" />
                        <div style={{ width: "100%", marginTop: 10, textAlign: "center", overflowWrap: "break-word" }}>
                            <b>{colorName}</b>
                        </div>
                    </Grid>
                </Grid>
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, props.avatarDatas, avatarImgUrl, colorName]);*/

    const selectedNamePlateColor = useMemo(() => {
        const namePlate = props.namePlateColors.find(nameplate => nameplate.id == namePlateColorValue);
        return namePlate;
    }, [props.namePlateColors, namePlateColorValue]);

    const handleChange = (event:any) => {
        setNamePlateColorValue(event.target.value);
    };
    

    const drawFlexibleNamePlateSetting = useMemo(() => {
        // プレビューのアバターを縦左、右にラジオボタン
        // TODO: アバターサイズ2/3, 背景画像はfulwidthにしない
        return(
            props.namePlateColors.length != 0 ?
            <Fragment>
                {/* <hr /> */}
                <DialogContentText id="setMyAvatarDialog-description">
                    <Typography variant="h6">名札</Typography>
                    <Typography variant="body2">　※自分のアバターの名札色を選択してください</Typography>
                </DialogContentText>
                
                <Grid container
                    justify="center" 
                    alignItems="center" 
                    //justify="flex-start" 
                    //alignItems="flex-start" 
                    spacing={2}
                >
                    {/* アバター 自分の画面*/}
                    <Grid container 
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{
                            width: 110,
                            height: 140,
                            // marginBottom: 10,
                            // position: 'relative',
                            maxHeight: 140,
                            // overflowY: "auto", 
                            // backgroundImage: "url(./api/user/object/picture/" + props.floorData?.backgroundObject?.id + ")",
                        }}
                    >
                        <Grid container item xs={6}
                            justify="center"
                            alignItems="center"
                            style={{
                                position: 'relative',
                                //backgroundImage: "url(./api/user/object/picture/" + props.floorData?.backgroundObject?.id + ")",
                                maxHeight: AVATER_AREA_HEIGHT,
                                // minWidth:'100%',
                            }}
                        >
                                <img src={avatarImgUrl} style={{ width: 46, height: "auto" }} alt="avatar image" />
                                <FlexibleNamePlateComponent
                                    user={props.user}
                                    isMyUser={true}
                                    zIndex={100}
                                    rgba={selectedNamePlateColor?.rgba}
                                    hex={selectedNamePlateColor?.hex}
                                    textHex={selectedNamePlateColor?.textColor}
                                />
                        </Grid>
                                
                        <div style={{ fontWeight: 600, fontSize:14, alignItems:'center'}}>
                            <br/>【自分の画面】
                        </div>
                    </Grid>

                    {/* アバター 他人の画面*/}
                    <Grid container 
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{
                            width: 120,
                            height: 140,
                            // marginBottom: 10,
                            // position: 'relative',
                            maxHeight: 140,
                            // overflowY: "auto", 
                            // backgroundImage: "url(./api/user/object/picture/" + props.floorData?.backgroundObject?.id + ")",
                        }}
                    >
                        <Grid container item xs={6}
                            justify="center"
                            alignItems="center"
                            style={{
                                position: 'relative',
                                //backgroundImage: "url(./api/user/object/picture/" + props.floorData?.backgroundObject?.id + ")",
                                maxHeight: AVATER_AREA_HEIGHT,
                                // minWidth: '100%',
                            }}
                        >
                            <img src={avatarImgUrl} style={{ width: 46, height: "auto" }} alt="avatar image" />
                            <FlexibleNamePlateComponent
                                user={props.user}
                                isMyUser={false}
                                zIndex={100}
                                rgba={selectedNamePlateColor?.rgba}
                                hex={selectedNamePlateColor?.hex}
                                textHex={selectedNamePlateColor?.textColor}
                            />
                        </Grid>
                        <div style={{ fontWeight: 600, fontSize:14, alignItems:'center'}}>
                            <br/>【他の人の画面】
                        </div>
                    </Grid>
                    {/* ラジオボタン */}
                    <Grid item xs={7}>
                        <FormControl
                            id="namePlate_colorselect"
                            component="fieldset"
                            style={{
                                left: 5,
                                width:'100%',
                                height: 180,
                                // height: '100%',
                                overflowY: "auto",
                                fontSize: 20,
                                // marginBottom: 20,
                            }}
                        >
                            <ThemeProvider theme={radioButtonTheme}>
                                <RadioGroup aria-label="名札の色" name="namePlate_color" value={namePlateColorValue} onChange={handleChange} >
                                    {props.namePlateColors.map((plateColor: FlexibleNamePlate) =>
                                        <FormControlLabel
                                            id={`namePlateColor${plateColor.id}`}
                                            key={`namePlateColor${plateColor.id}`}
                                            value={`${plateColor.id}`}
                                            control={<Radio color="primary" />}
                                            checked={namePlateColorValue == plateColor.id}
                                            label={plateColor.discription == '' || plateColor.discription == null ? `${plateColor.colorName}` : `${plateColor.colorName} : ${plateColor.discription}`}
                                            // onChange={() => setNamePlateColorValue(plateColor.id)}
                                        />
                                    )}
                                </RadioGroup>
                            </ThemeProvider>
                        </FormControl>
                    </Grid>

                </Grid>
            </Fragment>
            :
            <div></div>
        )
    }, [namePlateColorValue, props.namePlateColors, selectedNamePlateColor, value]);

    // 「ビデオ通話前の確認」のオン・オフ設定
    const [startConfirmSettingValue, setStartConfirmSettingValue] = useState(props.myStartConfirmSetting);
    const handleStartConfirmSettingChange = (val: number) => {
        setStartConfirmSettingValue(val);
    }

    // 「ビデオ通話前の確認」設定データ表示
    const drawConfirmSetting = useMemo(() => {
        let cap_str: string = "";
        let disable: boolean = false;

        // オフィスじゃなければ表示しない
        if (props.purposeOfUse !== 1) {
            return ("");
        }

        // 設定変更不可の場合、デフォルトをセットしてdisableにする
        if (props.floorStartConfirmSetting >= 10) {
            cap_str = "　※この機能はシステム管理者によって制限されています";
            disable = true;
        }

        return (
            <Fragment>
                <hr />
                <DialogContentText id="startconfirmsetting-dialog-description">
                    <Typography variant="h6">ビデオ通話前の確認</Typography>
                    <Typography variant="body2">
                        ビデオ通話を始める前に、通話を開始してよいか毎回確認します。
                    </Typography>
                    <Typography variant="caption">{cap_str}</Typography>
                </DialogContentText>
                <FormControl disabled={disable} component="fieldset" style={{ fontSize: 20, padding: "0px 0px 0px 15px" }}>
                    <ThemeProvider theme={radioButtonTheme}>
                        <RadioGroup aria-label="確認の実施" name="confirmSetting" value={startConfirmSettingValue} style={{ width: Utility.getType() == 2 ? '150px' : '550px', height: '50px' }} >
                            <FormControlLabel value="1" control={<Radio color="primary" />} checked={startConfirmSettingValue === 1} label="オン" onChange={() => handleStartConfirmSettingChange(1)} />
                            <FormControlLabel value="0" control={<Radio color="primary" />} checked={startConfirmSettingValue === 0} label="オフ" onChange={() => handleStartConfirmSettingChange(0)} />
                        </RadioGroup>
                    </ThemeProvider>
                </FormControl>
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.purposeOfUse, props.floorStartConfirmSetting, startConfirmSettingValue])

    // 「ログイン通知」のオン・オフ設定
    const [isLoginoutNotification, setLoginoutNotification] = useState(props.myLoginoutNotificationSetting);
    // 「ログイン通知音声」のオン・オフ設定
    const [isLoginoutNotificationSound, setLoginoutNotificationSound] = useState(props.myLoginoutNotificationSoundSetting);
    const handleLoginoutNotificationChange = (val: number) => {
        setLoginoutNotification(val);
    }

    const handleLoginoutNotificationSoundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setLoginoutNotificationSound(1);
        }else{
            setLoginoutNotificationSound(0);
        }
    }
    // 「ログインアウト通知」設定データ表示
    const drawLoginoutNotificationSetting = useMemo(() => {
        let cap_str: string = "";
        let disable: boolean = false;

        // オフィスじゃなければ表示しない
        if (props.purposeOfUse !== 1) {
            return ("");
        }

        // 設定変更不可の場合、デフォルトをセットしてdisableにする
        if (props.floorStartConfirmSetting >= 10) {
            cap_str = "　※この機能はシステム管理者によって制限されています";
            disable = true;
        }

        return (
            <Fragment>
                <DialogContentText id="startconfirmsetting-dialog-description">
                    <Typography variant="h6">ログイン/ログアウト通知</Typography>
                    <Typography variant="body2">
                        他のユーザーがログイン/ログアウトしたことを画面上のメッセージでお知らせします。
                    </Typography>
                    <Typography variant="caption">{cap_str}</Typography>
                </DialogContentText>
                <FormControl disabled={disable} component="fieldset" style={{ fontSize: 20, padding: "0px 0px 0px 15px" }}>
                    <ThemeProvider theme={radioButtonTheme}>
                        <RadioGroup aria-label="確認の実施" name="confirmSetting" value={isLoginoutNotification} style={{ width: Utility.getType() == 2 ? '150px' : '550px', height: '50px' }} >
                            <FormControlLabel value="1" control={<Radio color="primary" />} checked={isLoginoutNotification === 1} label="オン" onChange={() => handleLoginoutNotificationChange(1)} />
                            <FormControlLabel value="0" control={<Radio color="primary" />} checked={isLoginoutNotification === 0} label="オフ" onChange={() => handleLoginoutNotificationChange(0)} />
                        </RadioGroup>
                    </ThemeProvider>
                </FormControl>
                <br/>
                <FormControlLabel
                    control={
                        <ThemeProvider theme={checkBoxTheme}>
                            <Checkbox
                                color="primary" 
                                disabled={disable || isLoginoutNotification === 0}
                                checked={isLoginoutNotificationSound === 1}
                                onChange={handleLoginoutNotificationSoundChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </ThemeProvider>
                    }
                    label={<Box style={{fontSize: 14}}>通知音を鳴らす</Box>}
                    style={{marginLeft: 1}}
                />
                <hr />
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.purposeOfUse, props.floorStartConfirmSetting, isLoginoutNotification, isLoginoutNotificationSound])

    const draw = useMemo(() => {
        return (
            <BaseDialog
                id="setMyAvatarDialog"
                open={props.open}
                onClose={onClose}
                aria-labelledby='setMyAvatarDialogTitle'
                onKeyDown={handleKeyDown}
                PaperProps={{
                    style: {
                        border: '5px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="setMyAvatarDialogTitle" style={{background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>全般設定</DialogTitle>
                    <DialogContent style={{position: 'relative', overflowX: 'hidden'}}>
                        {drawConfirmSetting}
                        <MyUserStatusTimerMenu
                            user={props.user}
                            ref={statusTimerMenuRef}
                        />
                        <hr></hr>
                        {/*drawFlexibleNamePlateSetting*/}
                        {drawLoginoutNotificationSetting}
                    </DialogContent>
                <DialogActions>
                    <Button onClick={onOk} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110 }} >OK</Button>
                    <Button onClick={onClose} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 110  }} >キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, drawConfirmSetting, namePlateColorValue, props.namePlateColors, selectedNamePlateColor, isLoginoutNotification, isLoginoutNotificationSound]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    );
}

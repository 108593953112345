import React from 'react';
import * as Icons from '@material-ui/icons/';

type IconType = typeof import('@material-ui/icons/index');

interface DynamicIconProps {
    iconName: string,
    className?: string
}
export default class DynamicIcon extends React.Component<DynamicIconProps> {
    render() {
        return React.createElement(Icons[this.props.iconName as keyof IconType], {className: this.props.className});
    }
}
import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect, useRef } from 'react';
import { makeStyles, Theme, createStyles, useTheme, Typography, Button, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid, FormControl, FormHelperText, LinearProgress, InputAdornment, createMuiTheme, TextField, IconButton, Table, TableContainer, TableBody, TableHead, TableRow, TableCell, Paper} from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import BaseDialog from './BaseDialog';
import JfsClient, { TenantOriginalObjectManagerInfo } from '@fsi/jfs-sdk';
import { OriginalObjectTableSetting,ObjectMaster } from '@fsi/jfs-sdk';
import { ThemeProvider } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { SELECT_NONE } from "../common/Constants";
import { Utility } from '../common/Utility';
import { JfsError } from '@fsi/jfs-sdk';
import underFloorImage from '../img/original_object_dialog/オリジナルオブジェクト管理ダイアログ-床画像.png'
import avatarImage from '../img/original_object_dialog/オリジナルオブジェクト管理ダイアログ-比較アバター.png'

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const textFieldTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //backgroundColor: 'rgba(0, 0, 255, 1)',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
        settingTitle:{
            fontWeight:'bold', 
            fontSize: 16,
            fontFamily: 'Hiragino Maru Gothic StdN',
        },
        tutorialFont: {
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        },
        headCell: {
            minHeight: 30,
            padding: 3,
            fontSize: 13,
            backgroundColor: '#F2F2F2',
            textAlign: 'center'
        },
        cell: {
            minHeight: 30,
            padding: 3,
            fontSize: 13,
            textAlign: 'center'
        },
    }),
);

export interface ChildHandler {
    //setTutorialOpen: (open: boolean) => void
}


interface Props {
    officeId: number,
    floorId: number,
    open: boolean,
    onClickClose: () => void,
}

const OriginalObjectManagementDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient, JFS_ERRORS } = jfsClient;
    const size = ['小（アバターの半分のサイズ）','中（アバターと同じサイズ）','大（アバターの約1.5倍のサイズ）','特大（アバターの約2倍のサイズ）'];
    const [selectedSize, setSelectedSize] = useState(size[1]);
    const [selectedCharacterSize,setSelectedCharacterSize] = useState(0);
    const [openAddSetting, setOpenAddSetting] = useState(false);
    const [originalObjectList, setOriginalObjectList] = useState([] as OriginalObjectTableSetting[]);
    const [openEditSetting, setOpenEditSetting] = useState(false);
    const [inputText, setInputText] = useState('');
    const [overObject, setOverObject] = useState(false);
    const [requiredImageSrc, setRequiredImageSrc] = useState('');
    const [optionalImageSrc, setOptionalImageSrc] = useState('');
    const [objectCount, setObjectCount] = useState(0);
    const [selectRequiredFile, setSelectRequiredFile] = useState('');
    const [selectOptionalFile, setSelectOptionalFile] = useState('');
    const [check, setCheck] = useState(false);
    const [errorRequiredFileCapa, setErrorRequiredFileCapa] = useState(false);
    const [errorRequiredFilePx, setErrorRequiredFilePx] = useState(false);
    const [errorRequiredNotPng, setErrorRequiredNotPng] = useState(false);
    const [errorOptionalFileCapa, setErrorOptionalFileCapa] = useState(false);
    const [errorOptionalFilePx, setErrorOptionalFilePx] = useState(false);
    const [errorOptionalNotPng, setErrorOptionalNotPng] = useState(false);
    const [requiredImageWidth, setRequiredImageWidth] = useState(0);
    const [requiredImageHeight, setRequiredImageHeight] = useState(0);
    const [optionalImageWidth, setOptionalImageWidth] = useState(0);
    const [optionalImageHeight, setOptionalImageHeight] = useState(0);
    const [closeMouthObjectId, setCloseMouthObjectId] = useState(0);
    const [openMouthObjectId, setOpenMouthObjectId] = useState(0);
    const [characterClosemouthId, setCharacterClosemouthId] = useState(0);
    const [clear, setClear] = useState(false);
    const [editObjectHeight, setEditObjectHeight] = useState(0);
    const [editobjectWidth, setEditObjectWidth] = useState(0);
    const [deleteObject, setDeleteObject] = useState(false);
    const [isDeleteErrorOpened, setDeleteErrorOpened] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUploadAfterExplainDialog, setOpenUploadAfterExplainDialog] = useState(false);
    const [openUploadErrorDialog, setOpenUploadErrorDialog] = useState(false);
    const [openDeleteStopDialog, setOpenDeleteStopDialog] = useState(false);
    const [placeObjectCount, setPlaceObjectCount] = useState(0);
    const [uploadErrorSentence,setUploadErrorSentence] = useState("");
    const [deleteObjectId, setDeleteObjectId] = useState(0);
    const [deleteObjectName, setDeleteObjectName] = useState("");
    const [openAddStopDialog, setOpenAddStopDaialog] = useState(false);
    const [maxRegist, setMaxRegist] = useState(0);
    const [maxFileSize, setMaxFileSize] = useState(0);
    const [maxPictureWidth, setMaxPictureWidth] = useState(0);
    const [maxPictuteHeight, setMaxPictureHeight] =useState(0);

    useEffect(() => {
        async function getInfo() {
            await getTenantOriginalObjectManagerInfo();
        }
       
        getInfo();
    }, [props.open])

    useEffect(() => {
        async function getList() {
            await getOriginalObjectSettingList();
        }
        if(!props.open){
            return;
        }
        getList();
    }, [props.open, deleteObject,openAddSetting,selectedSize])


    useEffect(() => {
        requiredCheck();
    }, [inputText, selectRequiredFile, openEditSetting, closeMouthObjectId, errorRequiredFileCapa, errorOptionalFileCapa, errorRequiredFilePx, errorOptionalFilePx,errorRequiredNotPng,errorOptionalNotPng])

    useEffect(() => {
        if(selectedSize == size[0]){
            setSelectedCharacterSize(40);
        }else if(selectedSize == size[1]){
            setSelectedCharacterSize(80);
        }else if(selectedSize == size[2]){
            setSelectedCharacterSize(120);
        }else if(selectedSize == size[3]){
            setSelectedCharacterSize(160);
        }
    }, [selectedSize])

    /**
     * テナントごとのオリジナルオブジェクト管理制御情報取得
     */
    const getTenantOriginalObjectManagerInfo = async () => {
        httpClient.getTenantOriginalObjectManagerInfo(sessionStorage.getItem("TABID") as string)   //エラーにはなっているがhttpclientでは定義済み
            .then(response => {
            let res = response as TenantOriginalObjectManagerInfo;
            setMaxRegist(res.registPossible);
            setMaxFileSize(res.fileSize);
            setMaxPictureWidth(res.pictureWidth);
            setMaxPictureHeight(res.pictureHeight);   

        }).catch(err => {
            console.log(err);
        });
    }


    /**
     * リストに表示するオリジナルオブジェクトの登録情報を取得
     */
    const getOriginalObjectSettingList = async () => { 
        httpClient.getOriginalObjectTableList(sessionStorage.getItem("TABID") as string, props.officeId)   //エラーにはなっているがhttpclientでは定義済み
            .then(response => {
            let res = response as OriginalObjectTableSetting[];

            // 送られたオリジナルオブジェクトの設定情報を保存する
            setOriginalObjectList(res);
            setObjectCount(res.length);
            if (res.length >= maxRegist) { //後から個数制御できるようにする？
                setOverObject(true);
            }else{
                setOverObject(false);
            }

        }).catch(err => {
            console.log(err);
        });
    }


    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定　→　半角全角の区別をつけない　理由：半角の@やwは横幅が広く表示からはみ出てしまうため
        for (let i = 0; i < intext.length; i++) {
            /*if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }*/
            len = len + 1;
        }

        return len
    };

    const requiredCheck = () => {
        //オブジェクト名入力必須チェック
        if(judgehz(inputText) == 0){
            return setCheck(true);
        }
        //オブジェクト名入力超過
        if(judgehz(inputText) > 12){
            return setCheck(true);
        }
        //オブジェクト追加-通常画像必須チェック
        if(judgehz(selectRequiredFile) == 0){
            if(!openEditSetting){
                return setCheck(true);
            }   
        }
        //通常画像-容量オーバー
        if(errorRequiredFileCapa){
            return setCheck(true);
        }
        if(errorRequiredNotPng){
            return setCheck(true)
        }
        //クリック切替画像-容量オーバー
        if(errorOptionalFileCapa){
            return setCheck(true);
        }
        //通常画像-サイズオーバー
        if(errorRequiredFilePx){
            return setCheck(true);
        }
         //クリック切替画像-サイズオーバー
        if(errorOptionalFilePx){
            return setCheck(true);
        }
        if(errorOptionalNotPng){
            return setCheck(true)
        }
        if(closeMouthObjectId != 0){
            return setCheck(false);
        }
        return setCheck(false);
    }

    const onClearText = () => {
        setInputText('');
    };

    const handleClose = () => {
        props.onClickClose();
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
        setDeleteObjectId(0);
        setDeleteObjectName("");
    };

    const handleDeleteOpen = (characterClosemouthId: number, objectName: string) => {
        setOpenDeleteDialog(true);
        setDeleteObjectId(characterClosemouthId);
        setDeleteObjectName(objectName);
    }

    const handleDeleteStop = () =>{
        setOpenDeleteStopDialog(true);
    }

    const handleAddStopOpen = () => {
        setOpenAddStopDaialog(true);
    }

    const handleAddStopClose = () => {
        setOpenAddStopDaialog(false);
    }

    const handleAddOpen = () => {
        setInputText('');
        setSelectRequiredFile('');
        setRequiredImageSrc('');
        setSelectOptionalFile('');
        setOptionalImageSrc('');
        setSelectedSize(size[1]);
        setCloseMouthObjectId(0);
        setOpenMouthObjectId(0);
        setCharacterClosemouthId(0);
        setClear(false);

        setOpenAddSetting(true);
    }

    const handleCloseEdit = () => {
        setCloseMouthObjectId(0);
        setOpenMouthObjectId(0);
        setCharacterClosemouthId(0);
        setEditObjectHeight(0);
        setEditObjectWidth(0);
        setRequiredImageHeight(0);
        setRequiredImageWidth(0);
        setOptionalImageHeight(0);
        setOptionalImageWidth(0);
        setSelectedCharacterSize(0);
        setSelectRequiredFile('');
        setRequiredImageSrc('');
        setSelectOptionalFile('');
        setOptionalImageSrc('');
        setSelectedCharacterSize(0);
        setSelectedSize('');
        selectRequiredFiles.current = [];
        selectOptionalFiles.current = [];
        setClear(false)

        clearError();
        setOpenAddSetting(false);
        setOpenEditSetting(false);
    }

     // 編集ボタン押下時 -> 押下時に選択したメッセージの内容で初期化
    const handleEditOpen = (targetObject: OriginalObjectTableSetting) => {
        setOpenEditSetting(true);
        setInputText(targetObject.paletteText);
        setCloseMouthObjectId(targetObject.mouthCloseObjectId);
        setOpenMouthObjectId(targetObject.mouthOpenObjectId);
        setCharacterClosemouthId(targetObject.characterClosemouthId);
        setEditObjectHeight(targetObject.height);
        setEditObjectWidth(targetObject.width);
        if(targetObject.mouthCloseObjectId == targetObject.mouthOpenObjectId){
            setClear(true);
        }
        
        if(targetObject.height >= 40 && targetObject.height < 80){
            setSelectedSize(size[0]);
        }else if(targetObject.height >= 80 && targetObject.height < 120){
            setSelectedSize(size[1]);
        }else if(targetObject.height >= 120 && targetObject.height < 160){
            setSelectedSize(size[2]);
        }else if(targetObject.height >= 160){
            setSelectedSize(size[3]);
        }else{
            setSelectedSize(size[0]);
        }
       
        setOpenAddSetting(true);
    }

    const handleChangeSize = (value :string) => {
        setSelectedSize(value);
    }

    const handleObjectSize = (value : number) => {
        if(value >= 40 && value < 80){
            return "小";
        }else if(value >= 80 && value < 120){
            return "中";
        }else if(value >= 120 && value < 160){
            return "大";
        }else if(value >= 160){
            return "特大";
        }else{
            return "小";
        }
    }

    const handleOkAdd = () => {
        if(inputText.length === 0) {
            //alert( "オリジナルオブジェクトの名前が入力されていないためアップロードできません。");
            setUploadErrorSentence("オリジナルオブジェクトの名前が入力されていないためアップロードできません。");
            setOpenUploadErrorDialog(true);
            return;
        }
        
        if (selectRequiredFiles.current.length === 0) {
            //alert( "アップロードファイルが選択されていないためアップロードできません。");
            setUploadErrorSentence("アップロードファイルが選択されていないためアップロードできません。");
            setOpenUploadErrorDialog(true);
            return;
        }

        let objectHeight = 0;
        let objectWidth = 0;
        if(selectedSize == size[0]){
            objectHeight = 40;
            const magnification = objectHeight / requiredImageHeight;
            objectWidth = Math.ceil(requiredImageWidth * magnification);
        }else if(selectedSize == size[1]){
            objectHeight = 80;
            const magnification = objectHeight / requiredImageHeight;
            objectWidth = Math.ceil(requiredImageWidth * magnification);
        }else if(selectedSize == size[2]){
            objectHeight = 120;
            const magnification = objectHeight / requiredImageHeight;
            objectWidth = Math.ceil(requiredImageWidth * magnification);
        }else if(selectedSize == size[3]){
            objectHeight = 160;
            const magnification = objectHeight / requiredImageHeight;
            objectWidth = Math.ceil(requiredImageWidth * magnification);
        }
        
        if(selectOptionalFiles.current[0] == undefined){
            httpClient.registOriginalObject(sessionStorage.getItem("TABID") as string, inputText, objectHeight.toString(), objectWidth.toString(), selectRequiredFiles.current[0])
            .then((response: string) => {
                //alert("オリジナルオブジェクトのアップロードに成功しました。フロアエディターのパレットからご利用ください。");
                handleCloseEdit();
                setOpenUploadAfterExplainDialog(true);
                return;
            })
            .catch((err: JfsError) => {
                //alert("neko");
                console.log('httpClient.registTenantObject error httpStatusCode=['+err.httpStatusCode+'] code=['+err.code+'] detail=['+err.detail+']');
                if (err.code === JFS_ERRORS.REST_INVALID_INPUT_WORDCOUNT.code) {    // 102002
                    //alert( `アップロードファイルが${maxFileSize}KBを超えるためアップロードできません。`);
                    setUploadErrorSentence(`アップロードファイルが${maxFileSize}KBを超えるためアップロードできません。`);
                    setOpenUploadErrorDialog(true);
                } else if (err.code === JFS_ERRORS.REST_LIMIT_OVER.code) {    // 102007
                    //alert( "アップロード数が" + maxRegist + "件を超えるためアップロードできません。");
                    setUploadErrorSentence("アップロード数が" + maxRegist + "件を超えるためアップロードできません。");
                    setOpenUploadErrorDialog(true);
                } else if (err.code === JFS_ERRORS.REST_INVALID_INPUT_FORMAT.code) {    // 102003
                   // alert( "アップロードファイルがPNG画像でないためアップロードできません。");
                   setUploadErrorSentence("アップロードファイルがPNG画像でないためアップロードできません。");
                    setOpenUploadErrorDialog(true);
                } else if (err.code === JFS_ERRORS.REST_UNAUTH_ACCESS.code) {    // 103005
                    //alert( "管理者でないためアップロードできません。");
                    setUploadErrorSentence("管理者でないためアップロードできません。");
                    setOpenUploadErrorDialog(true);
                } else {
                    //alert( "アップロード中に問題が発生しました。");
                    setUploadErrorSentence( "アップロード中に問題が発生しました。");
                    setOpenUploadErrorDialog(true);
                }
                return;
            });
        }else{
            httpClient.registOriginalObjects(sessionStorage.getItem("TABID") as string, inputText, objectHeight.toString(), objectWidth.toString(), selectRequiredFiles.current[0], selectOptionalFiles.current[0])
            .then((response: string) => {
                //alert("オリジナルオブジェクトのアップロードに成功しました。フロアエディターのパレットからご利用ください。");
                handleCloseEdit();
                setOpenUploadAfterExplainDialog(true);
                return;
            })
            .catch((err: JfsError) => {
                //alert("neko");
                console.log('httpClient.registTenantObject error httpStatusCode=['+err.httpStatusCode+'] code=['+err.code+'] detail=['+err.detail+']');
                if (err.code === JFS_ERRORS.REST_INVALID_INPUT_WORDCOUNT.code) {    // 102002
                    //alert( `アップロードファイルが${maxFileSize}KBを超えるためアップロードできません。`);
                    setUploadErrorSentence(`アップロードファイルが${maxFileSize}KBを超えるためアップロードできません。`);
                    setOpenUploadErrorDialog(true);
                } else if (err.code === JFS_ERRORS.REST_LIMIT_OVER.code) {    // 102007
                    //alert( "アップロード数が" + maxRegist + "件を超えるためアップロードできません。");
                    setUploadErrorSentence("アップロード数が" + maxRegist + "件を超えるためアップロードできません。");
                    setOpenUploadErrorDialog(true);
                } else if (err.code === JFS_ERRORS.REST_INVALID_INPUT_FORMAT.code) {    // 102003
                   // alert( "アップロードファイルがPNG画像でないためアップロードできません。");
                   setUploadErrorSentence("アップロードファイルがPNG画像でないためアップロードできません。");
                    setOpenUploadErrorDialog(true);
                } else if (err.code === JFS_ERRORS.REST_UNAUTH_ACCESS.code) {    // 103005
                    //alert( "管理者でないためアップロードできません。");
                    setUploadErrorSentence("管理者でないためアップロードできません。");
                    setOpenUploadErrorDialog(true);
                } else {
                    //alert( "アップロード中に問題が発生しました。");
                    setUploadErrorSentence( "アップロード中に問題が発生しました。");
                    setOpenUploadErrorDialog(true);
                }
                return;
            });
        }
    }

    const handleOkEdit = async () => {
        if(inputText.length === 0) {
            //alert( "オリジナルオブジェクトの名前が入力されていないためアップロードできません。");
            setUploadErrorSentence("オリジナルオブジェクトの名前が入力されていないためアップロードできません。");
            setOpenUploadErrorDialog(true);
            return;
        }

        let objectHeight = 0;
        let objectWidth = 0;

        if(selectRequiredFiles.current[0] != undefined ){
            if(selectedSize == size[0]){
                objectHeight = 40;
                objectWidth = Math.ceil(requiredImageHeight * (objectHeight / requiredImageHeight));
            }else if(selectedSize == size[1]){
                objectHeight = 80;
                objectWidth = Math.ceil(requiredImageHeight * (objectHeight / requiredImageHeight));
            }else if(selectedSize == size[2]){
                objectHeight = 120;
                objectWidth = Math.ceil(requiredImageHeight * (objectHeight / requiredImageHeight));
            }else if(selectedSize == size[3]){
                objectHeight = 160;
                objectWidth = Math.ceil(requiredImageHeight * (objectHeight / requiredImageHeight));
            }
        }else{
            if(selectedSize == size[0]){
                objectHeight = 40;
                objectWidth = Math.ceil(editobjectWidth * (40 / editObjectHeight));
            }else if(selectedSize == size[1]){
                objectHeight = 80;
                objectWidth = Math.ceil(editobjectWidth * (80 / editObjectHeight));
            }else if(selectedSize == size[2]){
                objectHeight = 120;
                objectWidth = Math.ceil(editobjectWidth * (120 / editObjectHeight));
            }else if(selectedSize == size[3]){
                objectHeight = 160;
                objectWidth = Math.ceil(editobjectWidth * (160 / editObjectHeight));
            } 
        }

        try {
            if (selectRequiredFiles.current[0]) {
                await httpClient.editNormalOriginalObject(sessionStorage.getItem("TABID") as string, inputText, selectRequiredFiles.current[0], characterClosemouthId.toString(), objectHeight.toString(), objectWidth.toString())
                .then((response: number) => {
                    wsNormalCharacterChange(closeMouthObjectId,response);
                });
            }else{
                await httpClient.editOriginalObjectnameAndSize(sessionStorage.getItem("TABID") as string, inputText, objectHeight.toString(),objectWidth.toString(), characterClosemouthId.toString())
                .then((response: string) => {
                    wsResize(closeMouthObjectId);
                });
            }

            if (selectOptionalFiles.current[0]) {
                await httpClient.editClickOriginalObject(sessionStorage.getItem("TABID") as string, inputText, selectOptionalFiles.current[0], characterClosemouthId.toString(), objectHeight.toString(), objectWidth.toString())
                .then((response: number) => {
                   wsClickCharacterChange(response);
                });
            }else if(openMouthObjectId == closeMouthObjectId){
                await httpClient.editClickOriginalObjectClear(sessionStorage.getItem("TABID") as string,characterClosemouthId.toString())
                .then((response: number) => {
                wsClickCharacterChange(response);
                });
            }else if(selectOptionalFiles.current[0] && !selectOptionalFiles.current[0] && clear){
                await httpClient.editClickOriginalObject(sessionStorage.getItem("TABID") as string, inputText, selectRequiredFiles.current[0], characterClosemouthId.toString(), objectHeight.toString(), objectWidth.toString())
                .then((response: number) => {
                   wsClickCharacterChange(response);
                });
            }
           
            handleCloseEdit();
        } catch (err) {
            if (err instanceof JfsError) {
                console.log('httpClient.registTenantObject error httpStatusCode=[' + err.httpStatusCode + '] code=[' + err.code + '] detail=[' + err.detail + ']');
                handleEditError(err);
            } else {
                //alert("問題が発生し、正常に処理されませんでした");
                setUploadErrorSentence("問題が発生し、正常に処理されませんでした。");
                setOpenUploadErrorDialog(true);
            }
        }
    }

    /*
    * websocketで設定送信
    */
    const wsNormalCharacterChange = (closeMouthObjectId:number,newCloseMouthObjectId:number) => {
        try {
            wsClient.sendNormalCharacterSetting(
                closeMouthObjectId,
                newCloseMouthObjectId
                );
             } catch (error) {
                 console.error(error);
             }
        }
    const wsClickCharacterChange = (openMouthId:number) => {
        try {
                wsClient.sendClickCharacterSetting(openMouthId);
            } catch (error) {
                 console.error(error);
            }
        }
   const wsResize = (closeMouthObjectId:number) => {
    try {
        wsClient.sendCharacterSizeSetting(
            closeMouthObjectId
            );
         } catch (error) {
             console.error(error);
         }
    }

    const handleClickCharacterClear = () => {
        selectOptionalFiles.current = [];
        setOptionalImageSrc("");
        setSelectOptionalFile('');
        setOpenMouthObjectId(closeMouthObjectId);
        setClear(true);
    }
       
    const handleEditError = (err: JfsError) => {
        switch (err.code) {
            case JFS_ERRORS.REST_INVALID_INPUT_WORDCOUNT.code:
                //alert( `アップロードファイルが${maxFileSize}KBを超えるためアップロードできません。`);
                setUploadErrorSentence(`アップロードファイルが${maxFileSize}KBを超えるためアップロードできません。`);
                setOpenUploadErrorDialog(true);
                break;
            case JFS_ERRORS.REST_LIMIT_OVER.code:
                //alert("アップロード数が" + maxRegist + "件を超えるためアップロードできません。");
                setUploadErrorSentence("アップロード数が" + maxRegist + "件を超えるためアップロードできません。");
                setOpenUploadErrorDialog(true);
                break;
            case JFS_ERRORS.REST_INVALID_INPUT_FORMAT.code:
               // alert("アップロードファイルがPNG画像でないためアップロードできません。");
               setUploadErrorSentence("アップロードファイルがPNG画像でないためアップロードできません。");
               setOpenUploadErrorDialog(true);
                break;
            case JFS_ERRORS.REST_UNAUTH_ACCESS.code:
                //alert("管理者でないためアップロードできません。");
                setUploadErrorSentence("管理者でないためアップロードできません。");
                setOpenUploadErrorDialog(true);
                break;
            default:
                //alert("アップロード中に問題が発生しました。");
                setUploadErrorSentence("アップロード中に問題が発生しました。");
                setOpenUploadErrorDialog(true);
        }
    }


    const handleDeleteObject = (characterClosemouthId: number, objectName: string) => {
       
        httpClient.deleteOriginalObject(sessionStorage.getItem("TABID") as string, characterClosemouthId)
        .then((response: string) => {
           setDeleteObject(!deleteObject);
           setDeleteObjectId(0);
           setDeleteObjectName("");
           setOpenDeleteDialog(false);
        })
        .catch((err: JfsError) => {
            console.log('httpClient.deleteOriginalObject error httpStatusCode=['+err.httpStatusCode+'] code=['+err.code+'] detail=['+err.detail+']');
            if (err.code === JFS_ERRORS.REST_STOP_ACCESS.code) {    // 103006
                //alert( "オリジナルオブジェクト「" + objectName + "」は使用されているため削除できません。");
                setUploadErrorSentence("オリジナルオブジェクト「" + objectName + "」は使用されているため削除できません。");
                handleDeleteClose();
                setOpenDeleteStopDialog(true);
            }
            else if (err.code === JFS_ERRORS.REST_UNAUTH_ACCESS.code) {    // 103005
                //alert( "管理者でないため削除できません。");
                setUploadErrorSentence( "管理者でないため削除できません。");
                handleDeleteClose();
                setOpenDeleteStopDialog(true);
            }
            else {
                setUploadErrorSentence("アップロード中に問題が発生しました。");
                handleDeleteClose();
                setDeleteErrorOpened(true);
                setOpenDeleteStopDialog(true);
            }
        });
    }

    const handleCloseErrorDialog = () => {
        setOpenDeleteStopDialog(false);
        setDeleteErrorOpened(false);
    }

    const selectRequiredFiles = useRef([] as any);
    const selectOptionalFiles = useRef([] as any);

    const handleSelectRequiredFile = (e: any) => {
        if (typeof e.target.files[0] === 'undefined') return;
        setErrorRequiredNotPng(false);
        setErrorRequiredFilePx(false);
        setErrorRequiredFileCapa(false);
        selectRequiredFiles.current = e.target.files;
        var file = selectRequiredFiles.current[0];
        setSelectRequiredFile(file.name);

        if (file && file.type === 'image/png') {

            if (file && file.size < maxFileSize * 1024) { // KBをバイト単位に変換

                if (file) {
                    const img = new Image();
                    img.onerror = function() {
                        //alert('画像の読み込みに失敗しました。');
                        setUploadErrorSentence('画像の読み込みに失敗しました。');
                        setOpenUploadErrorDialog(true);
                    };
                    img.onload = function() {
                        setRequiredImageWidth(img.width);
                        setRequiredImageHeight(img.height);

                        if(img.width <= maxPictureWidth && img.height <= maxPictuteHeight){
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                if (typeof reader.result === 'string') {
                                    setRequiredImageSrc(reader.result);
                                }
                            }
                            reader.readAsDataURL(file);
                        }else{
                            //alert(`ファイルのpxサイズが${maxPictureWidth}✕${maxPictuteHeight}pxを超えています。`);
                            setErrorRequiredFilePx(true);
                        }
                        
                    };
                    img.src = URL.createObjectURL(file);
                }
            }else{
                //alert(`ファイルのサイズが${maxFileSize}KBを超えています。`);
                setErrorRequiredFileCapa(true);
            }

        } else {
            //alert('PNGファイルのみアップロードしてください。');
            setErrorRequiredNotPng(true)
        }

    }

    const handleSelectOptionalFile = (e: any) => {
        if (typeof e.target.files[0] === 'undefined') return;
        setErrorOptionalNotPng(false);
        setErrorOptionalFilePx(false);
        setErrorOptionalFileCapa(false);
        selectOptionalFiles.current = e.target.files;
        var file = selectOptionalFiles.current[0];
        setSelectOptionalFile(file.name);

        if (file && file.type === 'image/png') {

            if (file && file.size < maxFileSize * 1024) { // KBをバイト単位に変換

                if (file) {
                    const img = new Image();
                    img.onerror = function() {
                        //alert('画像の読み込みに失敗しました。');
                        setUploadErrorSentence('画像の読み込みに失敗しました。');
                        setOpenUploadErrorDialog(true);
                    };
                    img.onload = function() {
                        setOptionalImageWidth(img.width);
                        setOptionalImageHeight(img.height);

                        if(img.width <= maxPictureWidth && img.height <= maxPictuteHeight){
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                if (typeof reader.result === 'string') {
                                    setOptionalImageSrc(reader.result);
                                }
                            }
                            reader.readAsDataURL(file);
                        }else{
                            //alert(`ファイルのpxサイズが${maxPictureWidth}✕${maxPictuteHeight}pxを超えています。`);
                            setErrorOptionalFilePx(true);
                        }
                        
                    };
                    img.src = URL.createObjectURL(file);
                }
            }else{
                //alert(`ファイルのサイズが${maxFileSize}KBを超えています。`);
                setErrorOptionalFileCapa(true);
            }

        } else {
            //alert('PNGファイルのみアップロードしてください。');
            setErrorOptionalNotPng(true)
        }
    }

    const drawAddSetting = () => {
        return(
            <Grid container style={{paddingTop: 15}}>
                <Grid item xs={12} className={classes.settingTitle}>
                    オリジナルオブジェクト一覧
                </Grid>
                <Grid item xs={12} style={{paddingTop: 5}}>
                    お客様独自のオリジナルオブジェクトを追加できます。（最大{maxRegist}件まで）
                    <br/>
                    追加したオブジェクトはフロア編集で使用できます。
                </Grid>
                <Grid item xs={12} style={{paddingTop: 5}}>
                    <Button 
                        onClick={() => overObject ? handleAddStopOpen() : handleAddOpen()} 
                        color="primary" 
                        style={{ 
                            pointerEvents: 'auto', 
                            backgroundColor: overObject ? '#999999' : '#006FBC', 
                            color: '#FFFFFF', 
                            borderRadius: '31px', 
                            width: 200 
                        }} 
                    >
                        オブジェクトを追加
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const drawOriginalObjectTable = () => {
        return(
            <Grid container style={{paddingTop: 25}}>
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{maxHeight: 300}}>
                        <Table stickyHeader aria-label="original object table" style={{tableLayout:'fixed'}}>
                            <TableHead>
                                <TableRow >
                                    <TableCell key = 'objectname' className={classes.headCell} style={{ maxWidth: 60, minWidth: 60, width: 60 }} >オブジェクト名</TableCell>
                                    <TableCell key = 'objectImg' className={classes.headCell} style={{ maxWidth: 60, minWidth: 60, width: 60 }} >画像プレビュー</TableCell>
                                    <TableCell key = 'objectSize' className={classes.headCell} style={{ maxWidth: 60, minWidth: 60, width: 60 }} >サイズ</TableCell>
                                    <TableCell key = 'edit' className={classes.headCell} style={{ maxWidth: 40, minWidth: 40, width: 40 }}>編集</TableCell>
                                    <TableCell key = 'delete' className={classes.headCell} style={{maxWidth: 40, minWidth: 40, width: 40 }}>削除</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {originalObjectList.map((originalObject : OriginalObjectTableSetting, index) => {
                                return (
                                    <TableRow key={index} >
                                    <TableCell className={classes.cell}>
                                        {originalObject.paletteText}
                                    </TableCell >  
                                    <TableCell className={classes.cell} style={{textAlign: 'center'}} >
                                     <img src={`${httpClient.createObjectImgUrl(originalObject.mouthCloseObjectId, undefined, sessionStorage.getItem("TABID") as string)}`}  height="80" />
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        {handleObjectSize(originalObject.height)}
                                    </TableCell >
                                    <TableCell className={classes.cell}>
                                        <Button onClick={() => handleEditOpen(originalObject)} variant='contained' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '15px', width: 50 }} >編集</Button>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Button onClick={() => handleDeleteOpen(originalObject.characterClosemouthId, originalObject.paletteText )} variant='contained' style={{ pointerEvents:'auto',  backgroundColor: '#006FBC' , color: '#FFFFFF', borderRadius: '15px', width: 50 }} >削除</Button>
                                    </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }

    const textLength = useMemo(() => {
        // inputTextが取得できない場合の対応
        if(inputText === null || inputText === undefined){
            return (
                <div> </div>
            );
        }
        let len = judgehz(inputText)
        if(len === 0){
            return (
                <div style={{ color: "red", marginTop: 10, fontSize: 12 }}>入力必須です。 </div>
            );
        }else if (12 < len) {
            return (
                <div style={{ color: "red", marginTop: 10, fontSize: 12 }}>入力した文字列が長すぎます。 </div>
            );
        }
    }, [inputText]);

    const choiceFile = useMemo(() => {
        // inputTextが取得できない場合の対応
        if(selectRequiredFile === null || selectRequiredFile === undefined || openEditSetting){
            return (
                <span></span>
            );
        }
        let len = judgehz(selectRequiredFile)
        if(len === 0 && !openEditSetting){
            return (
                <span style={{ color: "red", fontSize: 12}}>　入力必須です。</span>
            );
        }
    }, [selectRequiredFile, openEditSetting]);

    const overRequiredFileCapa = useMemo(() => {
        if(!errorRequiredFileCapa && !errorRequiredFilePx && !errorRequiredNotPng){
            return (
                <span></span>
            );
        }
        
        if(errorRequiredNotPng){
            return (
                <span style={{ color: "red", fontSize: 12}}>　PNGファイルのみアップロードしてください。</span>
            );
        }
        
        if(errorRequiredFileCapa){
            return (
                <span style={{ color: "red", fontSize: 12}}>　ファイルのサイズが{maxFileSize}KBを超えています。</span>
            );
        }

        if(errorRequiredFilePx){
            return (
                <span style={{ color: "red", fontSize: 12}}>　ファイルのpxサイズが{maxPictureWidth}✕{maxPictuteHeight}pxを超えています。</span>
            );
        }

    }, [errorRequiredFileCapa, errorRequiredFilePx, errorRequiredNotPng]);

    const overOptionalFileCapa = useMemo(() => {
        // inputTextが取得できない場合の対応
        if(!errorOptionalFileCapa && !errorOptionalFilePx && !errorOptionalNotPng){
            return (
                <span></span>
            );
        }

        if(errorOptionalNotPng){
            return (
                <span style={{ color: "red", fontSize: 12}}>　PNGファイルのみアップロードしてください。</span>
            );
        }
        
        if(errorOptionalFileCapa){
            return (
                <span style={{ color: "red", fontSize: 12}}>　ファイルのサイズが{maxFileSize}KBを超えています。</span>
            );
        }

        if(errorOptionalFilePx){
            return (
                <span style={{ color: "red", fontSize: 12}}>　ファイルのpxサイズが{maxPictureWidth}✕{maxPictuteHeight}pxを超えています。</span>
            );
        }
    }, [errorOptionalFileCapa, errorOptionalFilePx, errorOptionalNotPng]);

    // エラー表示クリア処理
    const clearError = () => {
        // 通常画像のエラーハンドリング
        setErrorRequiredNotPng(false);
        setErrorRequiredFilePx(false);
        setErrorRequiredFileCapa(false);

        // クリック画像のエラーハンドリング
        setErrorOptionalNotPng(false);
        setErrorOptionalFilePx(false);
        setErrorOptionalFileCapa(false);

    }

    const progressBar = useMemo(() => {
        let parsent = (judgehz(inputText) / 12) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                        </ThemeProvider>
                </Grid>
            </Grid>
        );
    }, [inputText])


    const drawEditOriginalObjectDialog = useMemo(() =>
        <BaseDialog
            id="EditOriginalObjectDialog"
            open={openAddSetting}
            aria-labelledby="EditOriginalObject"
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                {openEditSetting ? "オリジナルオブジェクト編集" : "オリジナルオブジェクト追加"}                
            </DialogTitle>
            <DialogContent>
            <Grid container justify='center' alignItems='center' style={{paddingTop: 10}}>
                <Grid item xs={12} style={{paddingTop: 5, marginBottom: 5}}>
                   <span style={{fontSize:17}}>オブジェクト名<span style={{color: 'red'}}>(必須)</span></span>
                </Grid>
                <Grid item xs={12}>
                    　オブジェクトの名前を12文字以内で入力してください。
                    <br/>
                    　※オブジェクトの名前はフロアエディターのパレットに表示されます。  
                </Grid>
                <Grid item xs={3} style={{marginBottom: -12}}>
                    <div style={{color: '#00000061', fontSize: 11, paddingBottom: 5}}></div>
                </Grid>
                <Grid item xs={9}>
                    <DialogContentText style={{ marginBottom: "0px" }} >
                        {progressBar}
                    </DialogContentText>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 10}}>
                    <ThemeProvider theme={textFieldTheme}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                autoFocus
                                margin='dense'
                                value={inputText}
                                autoComplete={'off'}
                                onChange={e => setInputText(e.target.value)}
                                style={{ pointerEvents: 'auto', flexGrow: 1, width: 'calc(100% - 300px)' }} // 必要に応じて width を調整
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onClearText}>
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 5}}>
                    <label>
                        <span style={{fontSize:17}}>通常画像<span style={{color: 'red'}}>(必須)</span></span>
                        {choiceFile}
                        {overRequiredFileCapa}
                    </label>
                </Grid>
                <Grid item xs={12}　style={{marginBottom: 5}}>
                    　オブジェクトの画像をアップロードしてください。
                    <br/>
                    　※.png形式、{maxPictureWidth}✕{maxPictuteHeight}pxまで、{maxFileSize}KBまで
                </Grid>
                <Grid item xs={12} style={{marginBottom: 10}}>
                    <FormControl>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <input
                            accept=".png"
                            style={{ display: 'none'}}
                            id="required-button-file"
                            multiple
                            type="file"
                            onChange={handleSelectRequiredFile}
                        />
                        <label htmlFor="required-button-file">
                            <Button variant="contained" color="primary" component="span" 
                                style={{
                                    pointerEvents: 'auto', 
                                    backgroundColor: '#006FBC', 
                                    color: '#FFFFFF', 
                                    borderRadius: '31px', 
                                    width: 150,
                                    marginRight: '40px'
                                }}
                            >
                                アップロード
                            </Button>
                        </label>
                        <div style={{ position: "relative", width: "160px", height: "160px" }}>
                            <img src={underFloorImage} style={{ position: "absolute", top: 0, left: 0, height: '160px', zIndex: 1 }}/>
                            {!requiredImageSrc && <img src={`${httpClient.createObjectImgUrl(closeMouthObjectId, undefined, sessionStorage.getItem("TABID") as string)}`}   style={{position: "absolute", bottom: 0, left: '50%', height: `${selectedCharacterSize}px`, zIndex: 2  }}/>}
                            {requiredImageSrc && <img src={requiredImageSrc} alt="アップロードした画像" style={{position: "absolute",bottom: 0, left: '50%', height: `${selectedCharacterSize}px`, zIndex: 2  }} />}
                            <img src={avatarImage}  style={{position: "absolute", bottom: 0, left: '250px', height: '80px', zIndex: 3  }}/>
                        </div>
                    </div>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 5}}>
                    <label>
                        <span style={{fontSize:17}}>クリック切替画像(任意) </span>
                        {overOptionalFileCapa}
                    </label>
                </Grid>
                <Grid item xs={12}　style={{marginBottom: 5}}>
                    　切替画像を追加すると、オブジェクトがクリックされた時に画像が
                    <br/>
                    　切り替わるようになります。設定しない場合は通常画像のまま表示されます。
                    <br/>
                    　※.png形式、{maxPictureWidth}✕{maxPictuteHeight}pxまで、{maxFileSize}KBまで
                </Grid>
                <Grid item xs={12} style={{marginBottom: 10}}>
                    <FormControl>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <input
                                accept=".png"
                                style={{ display: 'none' }}
                                id="optional-button-file"
                                multiple
                                type="file"
                                onChange={handleSelectOptionalFile}
                            />
                            <label htmlFor="optional-button-file">
                                <Button variant="contained" color="primary" component="span"
                                    style={{
                                        pointerEvents: 'auto',
                                        backgroundColor: '#006FBC',
                                        color: '#FFFFFF',
                                        borderRadius: '31px',
                                        width: 150,
                                        marginBottom: '10px' // これによりアップロードボタンとクリアボタンの間に間隔ができます
                                    }}
                                >
                                    アップロード
                                </Button>
                            </label>
                            {openEditSetting &&
                                <Button
                                onClick={() =>handleClickCharacterClear()}
                                style={{
                                    pointerEvents: 'auto',
                                    backgroundColor: '#006FBC',
                                    color: '#FFFFFF',
                                    borderRadius: '31px',
                                    width: 150
                                }}
                            >
                                クリア
                            </Button>
                            } 
                        </div>
                        <div style={{ position: "relative", marginLeft: '40px',width: "160px", height: "160px" }}>
                            <img src={underFloorImage} style={{ position: "absolute", top: 0, left: 0, height: '160px', zIndex: 1 }} />
                            {!optionalImageSrc && openMouthObjectId != closeMouthObjectId && <img src={`${httpClient.createObjectImgUrl(openMouthObjectId, undefined, sessionStorage.getItem("TABID") as string)}`} style={{ position: "absolute", bottom: 0, left: '50%', height: `${selectedCharacterSize}px`, zIndex: 2 }} />}
                            {optionalImageSrc && <img src={optionalImageSrc} alt="アップロードした画像" style={{ position: "absolute", bottom: 0, left: '50%', height: `${selectedCharacterSize}px`, zIndex: 2 }} />}
                            <img src={avatarImage}  style={{position: "absolute", bottom: 0, left: '250px', height: '80px', zIndex: 3  }}/>
                        </div>
                    </div>


                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 10}}>
                    <Grid item xs={12} style={{marginBottom: 5}}>
                    <span style={{fontSize:17}}>サイズ設定<span style={{color: 'red'}}>(必須)</span></span>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                        　アバターのサイズを基準にオブジェクトのサイズを設定できます。
                    </Grid>
                    <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label>
                            <select 
                                style={{fontSize:18, width: 300, textAlign: 'center',}} 
                                onChange={(e) => handleChangeSize(e.target.value as string)} 
                                value={selectedSize}>
                                {size.map(arrayItem =>
                                    <option value={arrayItem}>{arrayItem}</option>
                                )}
                            </select>
                        </label>
                    </div>
                    </Grid>
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button  onClick={openEditSetting ? handleOkEdit : handleOkAdd } color="primary" style={{ pointerEvents: check ? 'none' : 'auto',  backgroundColor: check ? '#999999' : '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                <Button onClick={handleCloseEdit} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [openAddSetting, inputText, requiredImageSrc, optionalImageSrc, selectRequiredFile, selectOptionalFile, selectedSize, objectCount, check, errorRequiredFileCapa, errorOptionalFileCapa, openEditSetting, openMouthObjectId, closeMouthObjectId, requiredImageHeight,optionalImageHeight,optionalImageWidth,selectedCharacterSize,errorRequiredFilePx,errorOptionalFilePx, errorRequiredNotPng,errorOptionalNotPng]);  

    const drawDeleteDialog = useMemo(() => 
        <BaseDialog
            id="DeleteDialog"
            open={openDeleteDialog}
            onClose={handleDeleteClose}
            aria-labelledby="DeleteConfirmDialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="deleteDialogTitle" className={classes.dialogTitle}>
                オリジナルオブジェクト削除
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                        選択したオリジナルオブジェクトを削除します。
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDeleteObject(deleteObjectId,deleteObjectName)} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                <Button onClick={handleDeleteClose} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [openDeleteDialog]);

    //オブジェクト追加完了後の説明ダイアログ
    const drawUploadAfterExplainDialog = useMemo(() => 
        <BaseDialog
            id="ExpalainDialog"
            open={openUploadAfterExplainDialog}
            onClose={() => setOpenUploadAfterExplainDialog(false)}
            aria-labelledby="ExplainConfirmDialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="explainDialogTitle" className={classes.dialogTitle}>
                オリジナルオブジェクト追加完了
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                    オリジナルオブジェクトのアップロードに成功しました。
                    <br/>
                    <br/>
                    以下の手順でご利用ください。
                    <br/>
                    「フロアエディター」→「フロア編集」→「パレット」→「オリジナルオブジェクト」
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenUploadAfterExplainDialog(false)} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
    , [openUploadAfterExplainDialog]);

     
    //削除する際にオブジェクトが設置されていた場合
    const drawDeleteStopDialog = useMemo(() => 
    <BaseDialog
        id="DeleteStopDialog"
        open={openDeleteStopDialog}
        onClose={() => setOpenDeleteStopDialog(false)}
        aria-labelledby="DeleteStoprConfirmDialog"
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
            style: {
                border: '6px solid #57BBFF',
                borderRadius: '25px',
            }
        }}
    >
        <DialogTitle id="explainDialogTitle" className={classes.dialogTitle}>
            {isDeleteErrorOpened ? "オリジナルオブジェクト削除エラー" : "オリジナルオブジェクト削除不可"} 
        </DialogTitle>
        <DialogContent>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12} style={{paddingTop: 5}}>
                   {uploadErrorSentence}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => handleCloseErrorDialog()} color="primary" autoFocus style={{color: '#006FBC'}}>
                閉じる
            </Button>
        </DialogActions>
    </BaseDialog>
    , [openDeleteStopDialog]);

    //オブジェクト追加完了後の説明ダイアログ
    const drawUploadErrorDialog = useMemo(() => 
    <BaseDialog
        id="UploadErrorDialog"
        open={openUploadErrorDialog}
        onClose={() => setOpenUploadErrorDialog(false)}
        aria-labelledby="UploadErrorConfirmDialog"
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
            style: {
                border: '6px solid #57BBFF',
                borderRadius: '25px',
            }
        }}
    >
        <DialogTitle id="explainDialogTitle" className={classes.dialogTitle}>
            {openEditSetting ? "オリジナルオブジェクト編集エラー" : "オリジナルオブジェクト追加エラー"} 
        </DialogTitle>
        <DialogContent>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12} style={{paddingTop: 5}}>
                    {uploadErrorSentence}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => setOpenUploadErrorDialog(false)} color="primary" autoFocus style={{color: '#006FBC'}}>
                閉じる
            </Button>
        </DialogActions>
    </BaseDialog>
    , [openUploadErrorDialog]);



    const drawAddStopDialog = useMemo(() => 
        <BaseDialog
            id="AddStopDialog"
            open={openAddStopDialog}
            onClose={handleAddStopClose}
            aria-labelledby="AddStopDialog"
            maxWidth='sm'
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                オブジェクトを追加できません
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                        オブジェクトは既に登録上限数まで登録されています。
                        <br/>
                        新規追加したい場合は不要なオブジェクトを削除してください。
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddStopClose} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
    , [openAddStopDialog]);

    const draw = useMemo(() =>
        <BaseDialog
            id="OriginalObjectManagementDialog"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="OriginalObjectManagementDialog"
            maxWidth="md"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                オリジナルオブジェクト管理
            </DialogTitle>
            <DialogContent>
                {drawAddSetting()}
                {drawOriginalObjectTable()}
                <div>
                    <Typography variant='body2' style={{ position: "absolute", top: 18, right: 15 }}>アップロード数 {objectCount}/{maxRegist}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [props.open, originalObjectList, objectCount, overObject, deleteObject]);

    return (
        <Fragment>
            {draw}
            {drawEditOriginalObjectDialog}
            {drawDeleteDialog}
            {drawUploadAfterExplainDialog}
            {drawAddStopDialog}
            {drawUploadErrorDialog}
            {drawDeleteStopDialog}
        </Fragment>
    )
}

export const OriginalObjectManagementDialogRef = forwardRef(OriginalObjectManagementDialog);
import React, { Fragment, useMemo, useState, useEffect, useRef,  useImperativeHandle, forwardRef, ForwardRefRenderFunction  } from 'react';
import { makeStyles, Theme, createStyles, createMuiTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LockIcon from '@material-ui/icons/Lock';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BaseDialog from './BaseDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { Office as resOffice } from '../common/JsonClass';
import { Utility } from '../common/Utility';
import { PrivacyRoomDialogRef, PrivacyRoomDialogHandler } from './PrivacyRoomDialog';
import Tooltip from '@material-ui/core/Tooltip';
import { ThemeProvider } from '@material-ui/styles';
import useReactRouter from 'use-react-router';
import JfsClient from '@fsi/jfs-sdk';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 500,
            //maxWidth: 830,
            //maxWidth: 'md',
            height: '100%',
            maxHeight: 480,
            backgroundColor: theme.palette.background.paper,
            paddingLeft: theme.spacing(0),
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

// API Response
interface resFloor {
    id: number;
    floorName: string;
    floorOrder: number;
    enabledFloor: boolean;
    closed: boolean;
}

// UI表示用
class Office {
    id: number = 0;
    officeName: string = "";
    stay: boolean = false;
    open: boolean = false;
    floorList: Floor[] = [];
    privacyRoom: boolean = false;
}

class Floor {
    id: number = 0;
    floorName: string = "";
    floorOrder: number = 0;
    stay: boolean = false;
    closed: boolean = false;
    participants: number = 0;
}
interface privacyRoomInfo {
    id: number;
    subId: string;
    preFloorId: number;
    preFloorName: string;
    floorId: number;
    stayPrivacyroom: boolean;
    waitForPermit: boolean;
    targetFloorId: number;
    targetUserId: number;
}
interface Props {
    open: boolean,
    floorId: number,
    onMoveFloor: (id: number) => void,
    myRole: string,
    myState: number,
    onJumpToMySeat: () => void,
    onStateChange: (state: number, enableMeet: boolean) => void;
    requestPrivacyRoom: (floorId: number, userId: number, targetFloorId:number) => void;
    sendPrivacyRoomTimeOut: (kind: number, floorId : number) => void;
    sendMovePrivacyRoomInfo: (stayPrivacyroom: boolean, preFloorId: number, preFloorName: string) => void;
}

export interface MoveFloorHandler {
    permission: () => void;
    notPermission: () => void;
    leaveRoom: (preFloor: number) => void;
    extendPermission: () => void;
}

const MoveFloor: ForwardRefRenderFunction<MoveFloorHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const { history } = useReactRouter();
    const [officeList, setOfficeList] = useState([] as Office[]);
    const [errorMessage, setErrorMessage] = useState("");
    const [windowMessage, setWindowMessage] = useState("フロアを選択してください");
    const [showPrivacyRoomDialog, setShowPrivacyRoomDialog] = React.useState(false);
    const [showTopPrivacyRoomDialog, setShowTopPrivacyRoomDialog] = React.useState(false);
    const [floorId, setFloorId] = React.useState(-1);
    const privacyRoomDialogRef = useRef({} as PrivacyRoomDialogHandler);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        permission: () => {
            privacyRoomDialogRef.current.close();
        },
        notPermission: () => {
            privacyRoomDialogRef.current.open();
        },
        leaveRoom: (preFloor: number) => {
            privacyRoomDialogRef.current.leave(preFloor);
        },
        extendPermission: () => {
            privacyRoomDialogRef.current.extend();
        },
    }))

    const getFloorList = (id: number) => {
        // const params = new URLSearchParams();
        // params.append("office_id", id.toString());
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // return axios.post('/api/user/floor/list/get', params)

        return httpClient.getFloorList(id, sessionStorage.getItem("TABID") as string);
    }

    const getNumber = (floorId: number) => {
        // const params = new URLSearchParams();
        // params.append("floor_id", `${floorId}`);
        // return axios.post('/api/user/floor/participants/number/get', params);
        return httpClient.getParticipantsNumber(floorId);
        /*.then((response: AxiosResponse) => {
            let result = response.data;
            setFloorNum((prevState) =>
                prevState.map((obj) => (obj.id === floorId ? {id: obj.id, name: result} : obj))
            );
        })
        .catch(() => {
            console.log("GetFloorNum error.");
        });*/
    }


    /**
     * webAPIから取得したオフィス・フロアデータから、UI表示用のofficeデータを生成する。
     * 
     * @param resOffice webAPIから取得したオフィスデータ
     * @param floorList webAPIから取得したフロアデータ
     */
    async function setFloorToOffice(resOffice: resOffice, floorList: resFloor[]) {
        const office = new Office();
        office.id = resOffice.id;
        office.officeName = resOffice.officeName;
        office.privacyRoom = resOffice.privacyRoom;

        if (floorList.length > 0) {
            const promiseArray = floorList.map(async (floor) => {
                const res = await getNumber(floor.id);
                return { ...floor, participants: res }
            });
            const _floorList = await Promise.all(promiseArray);

            // id の昇順で並びかえ
            office.floorList = _floorList.map(floor => ({ ...floor, stay: false }))
                .sort((floor1, floor2) => {
                    if (floor1.floorOrder > floor2.floorOrder) return 1;
                    if (floor1.floorOrder < floor2.floorOrder) return -1;
                    return 0;
                });

            // 自身がいるビル, フロアは太字にする
            const myFloor = office.floorList.find(floor => floor.id === props.floorId);
            if (myFloor) {
                myFloor.stay = true;
                office.stay = true;
                office.open = true;
                setWindowMessage("フロア移動");
            //} else {
            //    setWindowMessage("フロアを選択してください");
            }
        }

        return office;
    }

    useEffect(() => {
        async function getData(){
            try {
                const res1 = await Utility.requestOfficeList();
                const officeListData = res1 as resOffice[];

                // UI表示用のofficeデータを生成
                const promiseArray = officeListData.map(async (office) => {
                    // const res = await getFloorList(office.id);
                    // const floorListData = res.data as resFloor[];
                    let floorListData: resFloor[];
                    try {
                        const res = await getFloorList(office.id);
                        floorListData = res as resFloor[];
                    } catch (error) {
                        floorListData = [];
                    }
                    const enabledFloorListData = floorListData.filter(x => x.enabledFloor === true);
                    return setFloorToOffice(office, enabledFloorListData);
                });
                // フロア０件のオフィスは選択データに入れない
                const _officeList_tmp = await Promise.all(promiseArray);
                const _officeList = _officeList_tmp.filter(x => x.floorList.length > 0);

                // setState
                _officeList.sort((a, b) => a.id > b.id ? 1 : -1);
                setOfficeList([..._officeList]);
            } catch (err) {
                console.log(err);
                setErrorMessage("データ取得に失敗しました。");
            }
        }

        if (props.open) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    const handleClick = (id: number) => {
        let office = officeList.find(e => e.id === id);
        if (!office || !office.floorList) return;

        office.open = !office.open;
        setOfficeList([...officeList]);
    };

    const drawOfficeList = () => {
        return officeList.map((office) => {
            return (
                <Fragment key={office.id}>
                    <ListItem button onClick={() => handleClick(office.id)}>
                        <ListItemText primary={office.officeName}
                            primaryTypographyProps={{ style: { fontWeight: office.stay ? "bold" : "normal", fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'  } }} />
                        {office.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={office.open} timeout="auto" unmountOnExit>

                        { /* TODO kikutaka  面談室である条件で分岐 office名の判定*/ }
                        {office.privacyRoom ?
                            drawPrivacyRoomList(office.floorList)
                            :
                            drawFloorList(office.floorList)
                        }
                    </Collapse>
                </Fragment>
            );
        })
    }

    const drawFloorList = (floorList: Floor[]) => {
        if (!floorList) return;
        return floorList.map((floor) => {
            return (
                <List key={floor.id} component="div" disablePadding>
                    <ListItem button className={classes.nested} onClick={() => props.onMoveFloor(floor.id)}>
                        <div style={{width:40,}}>
                        {floor.floorName.indexOf('(メンテナンス中)') === -1 && floor.closed && <Tooltip title='利用時間外'><ListItemIcon><LockIcon /></ListItemIcon></Tooltip>}
                        {!floor.closed && floor.floorName.indexOf('(入室制限中)') !== -1 && <Tooltip title='入室制限'><ListItemIcon><LockIcon /></ListItemIcon></Tooltip>}
                        </div>
                        <ListItemText primary={drawRestrictedFloorName(floor.floorName)}
                            primaryTypographyProps={{ style: { fontWeight: floor.stay ? "bold" : "normal", fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' } }} />
                    </ListItem>
                </List>
            );
        });
    }

    // フロア名に(入室制限中)がついていた場合は取り除く
    const drawRestrictedFloorName = (floorName: string) => {
        if(!floorName) return;
        let ret = floorName;
        if(floorName.indexOf("(入室制限中)") !== -1){
            ret = floorName.substring(0, floorName.lastIndexOf("(入室制限中)"))
        }
        return ret;
    }

    const drawPrivacyRoomList = (floorList: Floor[]) => {
        if (!floorList) return;
        return floorList.map((floor) => {
            return (
                props.myRole.indexOf('ROLE_ADMIN') !== -1 ?
                    <List key={floor.id} component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={() => movePrivacyFloorForAdmin(floor.id)}>
                            <div style={{width:40,}}>
                            {floor.floorName.indexOf('(メンテナンス中)') === -1 && floor.closed && <Tooltip title='利用時間外'><ListItemIcon><LockIcon /></ListItemIcon></Tooltip>}
                            {!floor.closed && floor.floorName.indexOf('(入室制限中)') !== -1 && <Tooltip title='入室制限'><ListItemIcon><LockIcon /></ListItemIcon></Tooltip>}
                            </div>
                            <ListItemText primary={drawRestrictedFloorName(floor.floorName)}/>
                            {floor.participants >= 1 ?
                                <ListItemText primary='使用中' primaryTypographyProps={{ style: { color: 'red' } }}/>
                                :
                                <ListItemText primary='空室' />
                            }
                        </ListItem>
                    </List>
                :
                    <List key={floor.id} component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={() => openPrivacyRoomDialog(floor.id)}>
                            <div style={{width:40,}}>
                            {floor.floorName.indexOf('(メンテナンス中)') === -1 && floor.closed && <Tooltip title='利用時間外'><ListItemIcon><LockIcon /></ListItemIcon></Tooltip>}
                            {!floor.closed && floor.floorName.indexOf('(入室制限中)') !== -1 && <Tooltip title='入室制限'><ListItemIcon><LockIcon /></ListItemIcon></Tooltip>}
                            </div>
                            <ListItemText primary={drawRestrictedFloorName(floor.floorName)}
                                primaryTypographyProps={{ style: { fontWeight: floor.stay ? "bold" : "normal" } }}/>
                        </ListItem>
                    </List>
            );
        });
    }

    const movePrivacyFloorForAdmin = (floorId: number) => {
        if(props.myState !== 12){
            props.onStateChange(12,false);
        }
        props.onMoveFloor(floorId);
    }

    const openPrivacyRoomDialog = (floorId: number) => {
        //setShowPrivacyRoomDialog(true);
        if(props.myRole.indexOf('TOP') === -1){
            setFloorId(floorId);
            props.onMoveFloor(-1);
            privacyRoomDialogRef.current.permissionRequest(floorId);
        }else{
            setShowTopPrivacyRoomDialog(true);
        }
    }

    const handlePrivacyRoom = (on: boolean) => {
        setShowPrivacyRoomDialog(on);
    }

    const handleTopPrivacyRoom = (on: boolean) => {
        setShowTopPrivacyRoomDialog(on);
    }

    const drawPrivacyRoomDialog = useMemo(() => {
        return (
            <PrivacyRoomDialogRef ref={privacyRoomDialogRef} requestDialogOpen={showPrivacyRoomDialog} handlePrivacyRoomDialog={handlePrivacyRoom} requestTopDialogOpen={showTopPrivacyRoomDialog} handleTopPrivacyRoomDialog={handleTopPrivacyRoom} floorId={floorId} onMoveFloor={props.onMoveFloor} onJumpToMySeat={props.onJumpToMySeat} onStateChange={props.onStateChange} requestPrivacyRoom={props.requestPrivacyRoom} sendPrivacyRoomTimeOut={props.sendPrivacyRoomTimeOut} sendMovePrivacyRoomInfo={props.sendMovePrivacyRoomInfo} ></PrivacyRoomDialogRef>
        );
    }, [showPrivacyRoomDialog,showTopPrivacyRoomDialog,floorId])

    const drawOffice = useMemo(() => {
        if (officeList.length === 0 && errorMessage.length === 0) return <ThemeProvider theme={circularTheme}><CircularProgress color='primary' /></ThemeProvider>;
        return (
            <List component="nav" className={classes.root}>
                {drawOfficeList()}
            </List>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeList, errorMessage]);

    const handleSignout= () =>{

        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // axios.post('/api/user/logout', params)
        httpClient.signout(sessionStorage.getItem("TABID") as string).catch((err) => {
            console.log("/api/user/logout error : ", err.response);
        });
        
        history.push("/signout");
    }

    const draw = useMemo(() => {
        return (
            <BaseDialog
                id="moveFloorDialog"
                open={props.open}
                onClose={() => props.onMoveFloor(-1)}
                aria-labelledby="moveFloorDialogTitle"
                maxWidth={'xs'}
                fullWidth={true}
                PaperProps={{
                    style: {
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                {/*<DialogTitle id="moveFloorDialogTitle" style={{ textAlign: "center" }}>フロアを移動</DialogTitle>*/}
                <DialogTitle id="moveFloorDialogTitle" style={{ textAlign: "left", background: '#57BBFF', fontFamily: 'Hiragino Maru Gothic StdN' }}>{windowMessage}</DialogTitle>
                <DialogContent style={{ padding: "8px 40px" }}>
                    {errorMessage.length > 0
                        ? <div style={{ color: 'red' }}>{errorMessage}</div>
                        : null
                    }
                    {drawOffice}
                </DialogContent>
                {props.floorId == 0 && <DialogActions
                    style={{
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}>
                    <Button variant="contained" color="primary" onClick={() => {handleSignout()}}>ログアウト</Button>
                </DialogActions>}
                {props.floorId != 0 && <DialogActions
                    style={{
                        alignContent: "end",
                        alignItems: "end",
                        textAlign: "end",
                    }}>
                    <Button color="primary" style={{color: '#006FBC'}} onClick={() => props.onMoveFloor(-1)}>キャンセル</Button>
                </DialogActions>}
            </BaseDialog>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, officeList, errorMessage, windowMessage]);

    return (
        <Fragment>
            {draw}
            {drawPrivacyRoomDialog}
        </Fragment>
    );
}

export const MoveFloorRef = forwardRef(MoveFloor);

/**
 * 「会いに行く」用ダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useState, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import JfsClient, { JfsError, User, SearchedUser } from '@fsi/jfs-sdk';

interface Props {
    handleGoToSee: (target: User | SearchedUser, isSameFloor: boolean) => void;
}

export const dialogType = {
    Confirm: 1,
    Notify: 2,
} as const;

export interface GoToSeeDialogHandler {
    open: (message: string | ReactNode, type: number, target: SearchedUser) => void;
    close: () => void;
}

const GoToSeeDialogComponent: ForwardRefRenderFunction<GoToSeeDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('' as string | ReactNode);
    const [type, setType] = useState(1);
    const [target, setTarget] = useState({} as SearchedUser);

    useImperativeHandle(ref, () => ({
        open: (message: string | ReactNode, type: number, target: SearchedUser) => {
            setMessage(message);
            setType(type);
            setTarget(target);
            setOpen(true);
        },
        close: () => {
            setOpen(false);
            setType(1);
            setTarget({} as SearchedUser);
            setMessage('');
        },
    }));

    const handleClose = () => {
        setOpen(false);
    };
    const handleYes = () => {
        try {
            props.handleGoToSee(target, false);
            setOpen(false);
        } catch (err) {
            console.error(err);
            setType(dialogType.Notify);
            setMessage('移動に失敗しました。');
        }
    }
    const handleNo = () => {
        setOpen(false);
    }

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                onClose={handleClose}
                aria-labelledby='goToSee-dialog-title'
                aria-describedby='goToSee-dialog-description'
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id='goToSee-dialog-title' style={{padding: '7px 25px 9px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>会いに行く</DialogTitle>
                <DialogContent>
                    <DialogContentText id='goToSee-dialog-description'>
                        {message}
                    </DialogContentText>
                </DialogContent>
                {type === dialogType.Confirm &&
                    <DialogActions>
                        <Button onClick={handleYes} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100}}>はい</Button>
                        <Button onClick={handleNo} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 100}}>いいえ</Button>
                    </DialogActions>
                }
            </BaseDialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, message, type, target]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const GoToSeeDialog = forwardRef(GoToSeeDialogComponent);
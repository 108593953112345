import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SELECT_NONE } from "../common/Constants";
//import { User } from '../common/JsonClass';
import { User } from '@fsi/jfs-sdk';
import { textRuler } from "../common/TextRuler";

const useStyles = makeStyles((theme) => ({
    displayName: {
        ...SELECT_NONE,
        whiteSpace: 'nowrap',
        height: 20,
        userSelect: 'none',
        borderRadius: '15%',
        pointerEvents: 'none',
    },


}));

interface Props {
    user: User,
    isMyUser:boolean,
    zIndex: number,
    rgba: string | undefined,
    hex: string | undefined,
    textHex: string | undefined,

}

export default function FlexibleNamePlateComponent(props: Props) {
    const classes = useStyles();

    // 名前
    const NAME_FONTSIZE = useMemo(() => {
        const name = props.user.displayName;
        if (name.length <= 6) {
            return 15;
        } else if (name.length <= 7) {
            return 16;
        } else {
            return 11;
        }
    }, [props.user.displayName]);

    const NAME_WIDTH = useMemo(() => {
        const name = props.user.displayName;
        const padding = 5; // 位置計算で用いるため、CSS側には定義していない。
        return name.length > 0 ? textRuler(name, { fontSize: `${NAME_FONTSIZE}px`, padding: `0 ${padding}px` }) : 0;
    }, [props.user.displayName, NAME_FONTSIZE]);

    const draw = useMemo(() => {
        return (
            // props.user.flexibleNamePlate != null && props.rgba == '' ?
            <div
                className={classes.displayName}
                style={{
                    // top: props.avatarTop + props.avatarHeight, // avatar画像下部に配置
                    // left: props.avatarLeft + props.avatarWidth / 2 - NAME_WIDTH / 2, // 中心揃え
                    textAlign:'center',
                    width: NAME_WIDTH,
                    fontSize: NAME_FONTSIZE,
                    color: props.isMyUser ? "#fff" : `${props.textHex}`,
                    backgroundColor: props.isMyUser ? "#556cd6" : `rgba(${props.rgba == undefined ? '255, 255, 255, 1' :  props.rgba})`,
                    zIndex: props.zIndex,
                    // outline: props.isMyUser ? `2px solid ${props.hex}` : '',
                    border: props.isMyUser ? `2px solid ${props.hex}` : '',
                    lineHeight: props.isMyUser ? 1.1 : '',
                    position: 'absolute',
                    //top: 90,
                    top: 60,
                    // boxShadow: props.isMyUser ? "3px 3px 2px 1px rgba(0, 0, 255, 0.3)" : ""
                }}
            >
                {props.user.displayName}
            </div>
        );
    },[ props.hex, props.rgba, NAME_WIDTH, NAME_FONTSIZE, props.user.flexibleNamePlate, props.textHex])

    return (
        <Fragment>
            {draw}
        </Fragment>
    );
}
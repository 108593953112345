import React, { Fragment } from 'react';
import MeetingRoom from "./MeetingRoom";
import {Props as MeetingRoomProps} from "./MeetingRoom";

interface Props extends MeetingRoomProps{
    className: string,
}

export default function VideoConferneceRoom(props: Props) {
    return (
        <Fragment>
            <MeetingRoom {...props}/>
        </Fragment>
    );
}
import { Fragment, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface Props {
    isOpen: boolean,
    floorId: number,
    floorName: string,
    onClose: () => void,
    onOK: (id: number) => void,
}

export default function MoveFloorDialog(props: Props){
    const {isOpen, floorId, floorName} = props;
    const [open, setOpen] = useState(isOpen);

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    }

    const handleOK = () => {
        setOpen(false);
        props.onOK(floorId);
    }

    const draw = useMemo(() => {
        return open && <Dialog open={open} >
            <DialogTitle>フロア移動</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {floorName ? `${floorName}に移動します。` : 'フロアを移動します。'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    いいえ
                </Button>
                <Button onClick={handleOK} color="primary">
                    はい
                </Button>
            </DialogActions>
        </Dialog>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, floorName])

    return (
            <Fragment>
                {draw}
            </Fragment>
        )
}
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    title: string;
    Component: React.FC | React.ComponentClass
}

const PageComponent: React.FC<Props> = ({ title, Component }) => {
    const [serviceName, setServiceName] = useState("");
    const SERVICE_NAME = useMemo(() => serviceName, [serviceName]);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useEffect(() => {
        let unmounted = false;

        async function getServiceName() {
            try {
                // const x = await axios.post('/api/service/get');
                const x = await httpClient.getServiceInfo();
                if (!unmounted) setServiceName(x.name);
            } catch (err) {
                console.error(err)
                if (!unmounted) setServiceName("failed");
            }
        }
        getServiceName();
        return () => { unmounted = true }; // cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const sufix = (title || title !== "") ? ` - ${title}` : "";
        let _title = "";
        if (SERVICE_NAME === "failed") _title = title;
        else if (SERVICE_NAME === "") _title = "loading...";
        else if (SERVICE_NAME !== "") _title = `${SERVICE_NAME}${sufix}`;

        document.title = _title
    }, [title, SERVICE_NAME]);

    return <Component />;
};

const Page = (title: string, component: React.FC | React.ComponentClass) => <PageComponent title={title} Component={component} />

export default Page;
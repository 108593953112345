import React, { Fragment, forwardRef, useImperativeHandle, useState, useMemo, useEffect, useRef } from 'react';
import { Rnd, RndResizeCallback, RndResizeStartCallback } from "react-rnd";
import { DraggableEventHandler } from "react-draggable";

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Crop169Icon from '@material-ui/icons/Crop169';
import Zoom from '@material-ui/core/Zoom';

import { SHARE_SCREEN_BG_COLOR, DEFAULT_SCREEN_SHARE_VIDEO_ASPECT_RATIO } from '../common/Constants';
import WebrtcService from '../webrtc/WebrtcService';
import { ScreenShareMode } from '../webrtc/WebrtcComponent';
// import { User } from '../common/JsonClass';
import useWindowSize from "../common/useWindowSize";
import ZIndex from '../common/ZIndex';
import JfsClient, { JfsError, User} from '@fsi/jfs-sdk';

// styles
const centeringChildren: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    ...centeringChildren,
    width: "100%",
    height: "100%",
    borderRadius: 5,
    background: SHARE_SCREEN_BG_COLOR,
  },
  loading: {
    ...centeringChildren,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderRadius: 5,
  },
  overlayTop: {
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 40,
    background: "rgba(0,0,0,0.5)"
  },
  overlayBottom: {
    position: "absolute",
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: 40,
    background: "rgba(0,0,0,0.5)"
  },
  overlayText: {
    color: "#fff",
    paddingLeft: 10
  },
  icon: {
    color: "white"
  }
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

interface Props {
  parentElementId: string; // ShareScreeの親要素のID
  zIndex: number;
  iPhoneLayout: boolean;
  getMyUser: () => User;
  handleScreenShare: (value: boolean, mode: number, webRtcRoomId: string) => void;
  handleClickWindow: (type: number) => void,
};

// 公開したいメソッドの定義
export interface ShareScreenHandler {
  open: (isOpen: boolean) => void;
  onScreenShareVideoLoaded: (isLoaded: boolean, screenShareSessionId: string, userList?: Map<string, User>) => void;
};

const ShareScreen: React.ForwardRefRenderFunction<ShareScreenHandler, Props> = (props, ref) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const scrollbarWidth = 16;
  const minWidth = 300;
  const minHeight = 300;
  const bottomButtonHeight = props.iPhoneLayout ? 49 : 98;

  // props
  const { parentElementId, zIndex } = props;

  // state
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [screenShareSessionId, setScreenShareSessionId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  // refs
  const rndRef = useRef({} as Rnd | null);
  const screenRef = useRef({} as HTMLDivElement);

  let parent: HTMLDivElement;

  useImperativeHandle(ref, () => ({
    open(isOpen: boolean) {
      setIsOpen(isOpen);
    },
    onScreenShareVideoLoaded(isLoaded: boolean, screenShareSessionId: string, userList?: Map<string, User>) {
      console.log("ScreeShareVideo Loaded", isLoaded, screenShareSessionId);
      setIsLoading(!isLoaded);
      setScreenShareSessionId(screenShareSessionId);
      adjustVideoSize(screenRef.current);
    },
  }));

  // useMemo ===========================================================
  const screenShareNumber = useMemo(() => {
    if (props.getMyUser) {
      const myUser = props.getMyUser();
      const sameRoom = Array.from(WebrtcService.getUserDataList()).filter(v => v[1].webRtcRoomId === myUser.webRtcRoomId);
      return sameRoom.length;
    } else {
      return 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, screenShareSessionId]);

  const screenShareUser = useMemo(() => {
    const userDataList = new Map(WebrtcService.getUserDataList());
    if (props.getMyUser) {
      const myUser = props.getMyUser();
      userDataList.set(`${myUser.id}`, myUser);
    }
    return userDataList.get(screenShareSessionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenShareSessionId]);

  const isScreenShare = useMemo(() => {
    if (props.getMyUser) {
      const myUser = props.getMyUser();
      return `${myUser.id}` === screenShareSessionId;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenShareSessionId]);

  // useEffect =========================================================
  // mount時の処理
  useEffect(() => {
    calcInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UI open時の処理
  useEffect(() => {
    if (isFullScreen) setIsFullScreen(false);
    if (isOpen) {
      calcAtOpen();
    } else {
      setIsLoading(true);
      calcInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // video loading・loaded切替時の処理
  useEffect(() => {
    if (!isLoading) {
      adjustVideoSize(screenRef.current);
      // adjustが効かない場合のために、タイマーでもう一度呼び出す
      setTimeout(() => adjustVideoSize(screenRef.current), 500);
      setTimeout(() => adjustVideoSize(screenRef.current), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // window resize時の処理
  useEffect(() => {
    if (!isFullScreen) calcAtRightAndBottom();
    adjustVideoSize(screenRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  // size adjust =========================================================
  /**
   * screenのサイズ・位置のリセット
   *   画面左上に非表示で最小化する
   */
  const calcInit = () => {
    parent = document.getElementById(parentElementId) as HTMLDivElement;
    const { scrollTop, scrollLeft } = parent;
    // screenサイズ決定後、videoサイズを調整する
    rndRef.current?.updateSize({ width: 1, height: 1 });
    rndRef.current?.updatePosition({ x: scrollLeft - scrollbarWidth, y: scrollTop });
    rndRef.current?.forceUpdate(() => adjustVideoSize(screenRef.current));
    setCoord({ x: scrollLeft - scrollbarWidth, y: scrollTop });
    setSize({ width: 1, height: 1 });
  };

  /**
   * 共有要素表示時のサイズ・位置
   *   画面右上にwidth, heightをwindowの半分で表示する
   */
  const calcAtOpen = () => {
    parent = document.getElementById(parentElementId) as HTMLDivElement;
    const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = parent;

    let videoWidth = 0;
    const video = document.querySelectorAll("#videoShareScreen")[0] as HTMLVideoElement;
    if (video) {
      videoWidth = video.clientWidth;
    }
    videoWidth = Math.max(videoWidth, offsetWidth / 2);
    let xx = Math.min(scrollLeft + videoWidth - scrollbarWidth, window.innerWidth - videoWidth - scrollbarWidth);
    // screenサイズ決定後、videoサイズを調整する
    rndRef.current?.updateSize({ width: videoWidth, height: offsetHeight / 2 });
    rndRef.current?.updatePosition({ x: xx, y: scrollTop });
    rndRef.current?.forceUpdate(() => adjustVideoSize(screenRef.current));
    // ×
    //const frame = rndRef.current?.getSelfElement();
    //if (frame) adjustVideoSize(frame);
    // 画面共有要素の座標とサイズをstateに格納
    setCoord({ x: xx, y: scrollTop });
    setSize({ width: videoWidth, height: offsetHeight / 2 });
  };

  /**
   * 共有要素がWindow外へ出たとき
   */
  const calcAtRightAndBottom = () => {
    parent = document.getElementById(parentElementId) as HTMLDivElement;
    const { offsetWidth, offsetHeight, scrollTop, scrollLeft } = parent;
    const { x, y } = coord;
    const { width, height } = size;
    let newX = x;
    let newY = y;
    let isChangeCoord = false;
    let isChangeSize = false;

    // resizeで共有要素がwindow外となったとき、位置を更新
    if (x + width > scrollLeft + offsetWidth - scrollbarWidth) {
      isChangeCoord = true;
      newX = offsetWidth - width - scrollbarWidth;
      if (newX < scrollLeft) {
        isChangeSize = true;
        newX = scrollLeft;
      }
    }
    if (y + height > scrollTop + offsetHeight - scrollbarWidth) {
      isChangeCoord = true;
      newY = offsetHeight - height - scrollbarWidth;
      if (newY < scrollTop) {
        isChangeSize = true;
        newY = scrollTop;
      }
    }
    if (isChangeCoord) {
      setCoord({ x: newX, y: newY });
      rndRef.current?.updatePosition({ x: newX, y: newY });
      if (isChangeSize) {
        adjustVideoSize(screenRef.current);
      }
      isChangeCoord = false;
    }
  };

  /**
   * screenとvideoのサイズ・位置を全画面表示用に計算する
   */
  const culcFullScreen = () => {
    parent = document.getElementById(parentElementId) as HTMLDivElement;
    const { scrollTop, scrollLeft, /* offsetWidth, offsetHeight */ } = parent;
    //rndRef.current?.updateSize({ width: offsetWidth - scrollbarWidth, height: offsetHeight - scrollbarWidth });
    rndRef.current?.updateSize({ width: "100%", height: windowSize.height - bottomButtonHeight + "px" });
    rndRef.current?.updatePosition({ x: scrollLeft, y: scrollTop });
    rndRef.current?.forceUpdate(() => adjustVideoSize(screenRef.current));
    // ×
    //adjustVideoSize(screenRef.current);
    //const frame = rndRef.current?.getSelfElement();
    //if (frame) adjustVideoSize(frame);

    // const video = document.querySelectorAll("#videoShareScreen > video")[0] as HTMLVideoElement;
    // video.requestFullscreen()
  };

  /**
   * screenに合わせてvideo要素サイズを計算する
   * 
   * @param frame videoの親要素
   */
  const adjustVideoSize = (frame: HTMLElement | HTMLDivElement) => {
    const { offsetWidth, offsetHeight } = frame;
    const frameAspectRatio = offsetWidth / offsetHeight;
    const video = document.querySelectorAll("#videoShareScreen > video")[0] as HTMLVideoElement;
    if (video) {
      const videoSrcAspectRatio = video.videoHeight > 0 ? video.videoWidth / video.videoHeight : DEFAULT_SCREEN_SHARE_VIDEO_ASPECT_RATIO;
      const videoSizeStyle = {
        width: `${frameAspectRatio >= videoSrcAspectRatio ? offsetHeight * videoSrcAspectRatio : offsetWidth}px`,
        height: `${frameAspectRatio >= videoSrcAspectRatio ? offsetHeight : offsetWidth / videoSrcAspectRatio}px`
      }
      Object.assign(video.style, videoSizeStyle);
      console.log(`screen share video => videoWidth: ${video.videoWidth}, videoHeight: ${video.videoHeight}`);
    }
  };

  /**
   * open時の初期表示状態にする
   */
  const changeToAtOpen = () => {
    calcAtOpen();
    setIsFullScreen(false);
  };

  /**
   * 全画面表示にする
   */
  const changeToFullScreen = () => {
    culcFullScreen();
    setIsFullScreen(true);
  };

  // event handlers ==============================================
  const onDrag: DraggableEventHandler = (e, data) => {
    const { x, y } = data;
    setCoord({ x, y });

    // zIndex をHelloのインラインフレームと画面共有で切り替えるための処理
    if (zIndex !== (ZIndex.userDocument + 1)) {
      props.handleClickWindow(1);
    }
  };

  const onDragStop: DraggableEventHandler = (e, data) => {
    const { x, y } = data;
    setCoord({ x, y });
    // iPad対応-16 全画面ボタン対応
    //if(WebrtcService.isiOS() === true){
    //  setIsHover(false);
    //  console.log("SS_WIN_LOG : isiOS onDragStop setIsHover false")
    //}

    // zIndex をHelloのインラインフレームと画面共有で切り替えるための処理
    if (zIndex !== (ZIndex.userDocument + 1)) {
      props.handleClickWindow(1);
    }
  };

  const onResizeStart: RndResizeStartCallback = (e, dir, refToElement) => {
    adjustVideoSize(refToElement);
  };

  const onResize: RndResizeCallback = (e, dir, refToElement, delta, position) => {
    adjustVideoSize(refToElement);
  };

  const onResizeStop: RndResizeCallback = (e, dir, refToElement, delta, position,) => {
    const { x, y } = position;
    const { width, height } = refToElement.style;
    const toNumber = (str: string) => Number(str.replace("px", ""));
    adjustVideoSize(refToElement);
    setCoord({ x, y });
    setSize({ width: toNumber(width), height: toNumber(height) });
    // iPad対応-16 全画面ボタン対応
    if(WebrtcService.isiOS() === true || WebrtcService.isAndroid() === true){
      setIsHover(false);
      console.log("SS_WIN_LOG : isiOS onResizeStop setIsHover false")
    }
  };

  const onCloseClick = () => {
    if (props.getMyUser) {
      const myUser = props.getMyUser();
      props.handleScreenShare(false, ScreenShareMode.FullScreen, myUser.webRtcRoomId);
      setIsOpen(false);
    }
  };

  const onFullScreenClick = () => {
    changeToFullScreen();
    // iPad対応-16 全画面ボタン対応
    if(WebrtcService.isiOS() === true || WebrtcService.isAndroid() === true){
      setIsHover(false);
      console.log("SS_WIN_LOG : isiOS onFullScreenClick setIsHover false")
    }
  };

  const onResetClick = () => {
    changeToAtOpen();
    // iPad対応-16 全画面ボタン対応
    if(WebrtcService.isiOS() === true || WebrtcService.isAndroid() === true){
      //setIsHover(false);
      console.log("SS_WIN_LOG : isiOS onResetClick setIsHover false")
    }
  };

  const onScreenDoubleClick = () => {
    if (isFullScreen) {
      changeToAtOpen();
    } else {
      changeToFullScreen();
    }
  };

  // iPad対応-16 全画面ボタン対応
  // iPadはタップされたらhover表示状態にする（全画面とか押せるように）
  // もう一回タップされたら消す（全画面とか押した時とか移動させた時にも消す）
  // --> 消したらボタン押せなくなったので消さない
  const setHoverdisp = (disp: boolean) => {
    //alert("SS_WIN_LOG : setHoverdisp ["+disp+"]");
    if(WebrtcService.isiOS() !== true && WebrtcService.isAndroid() !== true ){
      setIsHover(disp);
      console.log("SS_WIN_LOG : isiOS false setHover="+disp)
    } else {
      if(disp === true) {
        if(isHover === true){
          // hoverを消すと、ボタン押せなくなるので・・・
          //setIsHover(false);
          console.log("SS_WIN_LOG : isiOS true setHover="+false)
        } else {
          setIsHover(true);
          console.log("SS_WIN_LOG : isiOS true setHover="+true)
        }
      } else {
        // nop
      }
    }
  };

  // components =========================================
  const Overlay = () => {
    return (
      <Fragment>
        <div className={classes.overlayTop}>
          <span className={classes.overlayText}>
            共有者: {screenShareUser?.displayName}
          </span>
          {isScreenShare &&
            <Tooltip title="共有停止" TransitionComponent={Zoom} aria-label="stop screen share">
              <IconButton
                className={classes.icon}
                aria-label="reset"
                onClick={onCloseClick}
              >
                <StopScreenShareIcon />
              </IconButton>
            </Tooltip>}
        </div>

        <div className={classes.overlayBottom}>
          {/* <span className={classes.overlayText}>
            共有人数: {screenShareNumber}人
          </span> */}
          <div>
            <Tooltip title="通常表示" TransitionComponent={Zoom} aria-label="init screen">
              <IconButton
                className={classes.icon}
                aria-label="reset"
                onClick={onResetClick}
                onTouchEnd={onResetClick}   // iPad対応-16 全画面ボタン対応
              >
                <Crop169Icon />
              </IconButton>
            </Tooltip>
            <Tooltip title="全画面表示" TransitionComponent={Zoom} aria-label="full screen">
              <IconButton
                className={classes.icon}
                aria-label="full screen"
                onClick={onFullScreenClick}
                onTouchEnd={onFullScreenClick}    // iPad対応-16 全画面ボタン対応
              >
                <CropFreeIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Fragment>
    )
  }

  const Loading = () => {
    return (
      <div className={classes.loading}>
        <ThemeProvider theme={circularTheme}>
          <CircularProgress color='primary' />
        </ThemeProvider>
      </div>
    )
  }

  const component = useMemo(() => {
    return (
      <Rnd
        ref={rndRef}
        enableResizing={!isFullScreen}
        disableDragging={isFullScreen}
        bounds={`#${parentElementId}`} // 移動可能範囲をCSSセレクターで指定
        maxWidth="100vw"
        maxHeight="100vh"
        minWidth={isOpen ? minWidth : 1}
        minHeight={isOpen ? minHeight : 1}
        onDrag={onDrag}
        onDragStop={onDragStop}
        onResizeStart={onResizeStart}
        onResize={onResize}
        onResizeStop={onResizeStop}
        // {iPad対応-16 全画面ボタン対応
        //onPointerEnter={() => setIsHover(true)}
        //onPointerLeave={() => setIsHover(false)}
        onPointerEnter={() => setHoverdisp(true)}
        onPointerLeave={() => setHoverdisp(false)}
        // }
        onDoubleClick={onScreenDoubleClick}
        style={{
          border: 'double 2px',   // {iPad対応-16 枠が目に付くように
          borderRadius: 5,
          background: SHARE_SCREEN_BG_COLOR,
          zIndex,
          visibility: isOpen ? "visible" : "hidden",
        }}
        default={{
          x: 0,
          y: 0,
          width: "50vw",
          height: "50vh",
        }}
      >
        <div
          ref={screenRef}
          id="videoShareScreen"
          className={classes.screen}
        >
          {isLoading && <Loading />}
        </div>
        {isHover && <Overlay />}
      </Rnd>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isOpen, isHover, isFullScreen, screenShareSessionId,
    screenShareUser, screenShareNumber, isScreenShare, zIndex])

  return component;
};

export const ShareScreenRef = forwardRef(ShareScreen);
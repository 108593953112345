import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// material-ui
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { LoginJson } from '../common/JsonClass';
import AudioPlayer from './AudioPlayer';
import ReCAPTCHA from "react-google-recaptcha";
import { siteKeyV2 } from '..';
import { Utility } from '../common/Utility';
import JfsClient from '@fsi/jfs-sdk';

const Copyright: React.FC<{ serviceName: string }> = (props) => {
  const link = <Link color="inherit" href="https://material-ui.com/">{props.serviceName}</Link>;
  const copyRight = <> Copyright © {link} {new Date().getFullYear()}.</>;
  const version = "Ver 0.0.83";
  return (
    <Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        {copyRight}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {version}
      </Typography>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(../service_info/famcampus_login_image.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    marginTop: '80px',
    padding: '20px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    // margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  card: {
    width: 150,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

export const eventIdLengthMax = 7;
export default function EventLogin() {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { history } = useReactRouter();
  const displayNameLengthMax = 10;

  const recaptcha = React.useRef(0);
  const recaptchaTokenV3 = React.useRef("");
  const ref = useRef("" as any);

  const [isShowReCaptchav2, setShowReCaptchav2] = useState(false);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState("");

  const [loginState, setLoginState] = useState({
    eventId: "",
    password: "",
    displayName: ""
  })
  const [serviceName, setServiceName] = useState('');
  const [information, setInformation] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [errMsgOnEventId, setErrMsgOnEventId] = useState('');
  const [errMsgOnPassword, setErrMsgOnPassword] = useState('');
  const [errMsgOnDisplayName, setErrMsgOnDisplayName] = useState('');
  const [errMsgOnAuth, setErrMsgOnAuth] = useState('');

  const errorMessageBox = {
    authFailed: '認証処理に失敗しました。システム管理者に連絡してください。',
    eventIdNull: 'イベントIDを入力してください。',
    eventIdOver: `イベントIDが${eventIdLengthMax}文字を超えています。`,
    passwordNull: 'パスワードを入力してください。',
    displayNameNull: '表示名を入力してください。',
    displayNameOver: `表示名が${displayNameLengthMax}文字を超えています。`
  }
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;
  //sessionStorage.setItem("SIGNINPAGE", "/eventlogin")
  Utility.setSigninPage("/eventlogin");

  useEffect(() => {
    function preLogin() {
      const params = new URLSearchParams();
      axios.post('/login/prelogin', params)
        .then((e: AxiosResponse) => {
          // console.log("prelogin OK!");
          getRecaptcha();
          getInformation();
          getServiceName();
        }).catch(err => {
          console.log(err);
        });
    }
    function getRecaptcha() {
      // const params = new URLSearchParams();
      // axios.post('/login/recaptcha', params)
      //   .then((e: AxiosResponse) => {
      //     recaptcha.current = e.data;
      //   }).catch((err) => {
      //   });
      httpClient.getRecaptcha('testapikey')
        .then((e) => {
          recaptcha.current = e;
        }).catch((err) => {
        });
    }
    function getInformation() {
      // const params = new URLSearchParams();
      // axios.post('/api/service/information', params)
      httpClient.getInformation()
        .then((e) => {
          setInformation(e);
        }).catch((err) => {
        });
    }
    function getServiceName() {
      // const params = new URLSearchParams();
      // axios.post('/api/service/get', params)
      httpClient.getServiceInfo()
        .then((e) => {
          setServiceName(e.name);
        }).catch((err) => {
        });
    }

    // iOS 音声の自動再生対応
    AudioPlayer.loadData();
    preLogin();
  }, [])

  // onChange
  const handleChangeEventId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      eventId: event.target.value,
    })
  }
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      password: event.target.value,
    })
  }
  const handleChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({
      ...loginState,
      displayName: event.target.value,
    })
  }

  // validate
  const validateEventId = () => {
    let valid = false;
    if (!loginState.eventId) {
      setErrMsgOnEventId(errorMessageBox.eventIdNull);
      valid = false;
    } else if (loginState.eventId.length > eventIdLengthMax) {
      setErrMsgOnEventId(errorMessageBox.eventIdOver);
    } else {
      setErrMsgOnEventId('');
      valid = true;
    }
    return valid;
  }
  const validatePassword = () => {
    let valid = false;
    if (!loginState.password) {
      setErrMsgOnPassword(errorMessageBox.passwordNull);
    } else {
      setErrMsgOnPassword('');
      valid = true;
    }
    return valid;
  }
  const validateDispalayName = () => {
    let valid = false;
    if (!loginState.displayName) {
      setErrMsgOnDisplayName(errorMessageBox.displayNameNull);
    } else if (loginState.displayName.length > displayNameLengthMax) {
      setErrMsgOnDisplayName(errorMessageBox.displayNameOver);
    } else {
      setErrMsgOnDisplayName('');
      valid = true;
    }
    return valid;
  }
  const execValidation = () => {
    setErrMsgOnAuth('');
    const a = validateEventId();
    const b = validatePassword();
    const c = validateDispalayName()
    return a && b && c;
  }

  // login
  const handleLogin = async () => {

    function login() {
      setShowBackdrop(true);

      // validation NG の場合はAPIサーバと通信しない
      if (!execValidation()) {
        setShowBackdrop(false);
        return;
      }

      const tabId = new Date().getTime();
      sessionStorage.setItem("TABID", tabId.toString());
      // const params = new URLSearchParams();
      // params.append('id', loginState.eventId);
      // params.append('pass', loginState.password);
      // params.append('displayname', loginState.displayName);
      // if (!isShowReCaptchav2) {
      //   // v3の判定
      //   params.append('token', "v3-" + recaptchaTokenV3.current);
      // } else {
      //   // v2の判定
      //   params.append('token', "v2-" + recaptchaTokenV2);
      // }
      // params.append('tabid', tabId.toString());
      let token = '';
      if (!isShowReCaptchav2) {
        // v3の判定
        token = "v3-" + recaptchaTokenV3.current;
      } else {
        // v2の判定
        token = "v2-" + recaptchaTokenV2;
      }
      // axios.post('/api/eventlogin', params)
      httpClient.eventSignin(
        loginState.eventId,
        loginState.password,
        loginState.displayName,
        tabId.toString(),
        token,
      )
        .then(response => {
          const loginJson = response as LoginJson;
          sessionStorage.setItem("TABID", loginJson.tabId);
          sessionStorage.setItem("ISLOGIN", "true");
          if (loginJson.action === "TOP") {
            history.push("/top");
          } else if (loginJson.action === "FLOOR") {
            history.push({
              pathname: "/floor",
              state: { path: loginJson.webSocketUrl, id: loginJson.floorId }
            });
          }
        }).catch(err => {
          if (err.response.data.message === 'BOTまたは攻撃の可能性があります。') {
            setShowReCaptchav2(true);
          }
          if (err.response.status === 403) {
            setErrMsgOnAuth(err.response.data.message);
          } else {
            setErrMsgOnAuth(errorMessageBox.authFailed);
          }
        }).finally(() => {
          setShowBackdrop(false);
        });
    }

    if (!executeRecaptcha) {
      return;
    }

    recaptchaTokenV3.current = await executeRecaptcha("tinysolution");

    login();
  }

  const handleUserKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 /* 13=Enter Key */) {
      handleLogin();
    }
  }

  const handleChangeReCaptchav2 = (value: any) => {
    console.log(value);
    setRecaptchaTokenV2(value);
  }

  const reCaptchaV2Form = useMemo(() => {
    if (!isShowReCaptchav2) return;
    return (
      <Fragment>
        <Typography variant="body1">
          不正ログイン対策ご協力のお願い
        </Typography>
        <Typography variant="body2">
          下記のチェックボックスにチェックを入れてからログインボタンをクリックしてください。
        </Typography>
        <ReCAPTCHA
          theme="dark"
          ref={ref}
          sitekey={siteKeyV2()}
          onChange={handleChangeReCaptchav2}
        />
      </Fragment>
    )
  }, [isShowReCaptchav2])

  const submitButton = useMemo(() => {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleLogin}
        disabled={isShowReCaptchav2 ? (recaptchaTokenV2 === "" ? true : false) : false}
      >
        ログイン
      </Button>
    )
  }, [loginState.eventId, loginState.password, loginState.displayName, isShowReCaptchav2, recaptchaTokenV2])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.image}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {serviceName}
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                id="eventId"
                label="イベントID"
                name="eventId"
                value={loginState.eventId}
                onChange={handleChangeEventId}
                onKeyDown={handleUserKeyDown}
                error={errMsgOnEventId.length > 0}
                helperText={errMsgOnEventId}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={loginState.password}
                onChange={handleChangePassword}
                onKeyDown={handleUserKeyDown}
                error={errMsgOnPassword.length > 0}
                helperText={errMsgOnPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="displayName"
                label="表示名"
                id="displayName"
                autoComplete="current-password"
                value={loginState.displayName}
                onChange={handleChangeDisplayName}
                onKeyDown={handleUserKeyDown}
                error={errMsgOnDisplayName.length > 0}
                helperText={errMsgOnDisplayName}
              />
              { // Auth Error Message
                errMsgOnAuth.length > 0
                  ? <div style={{ padding: 5, color: 'red', fontWeight: 'bold' }}>{errMsgOnAuth}</div>
                  : ''
              }
              {reCaptchaV2Form}
              {submitButton}
              {/* reCAPTCHA */}
              <p className="recaptcha_policy">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.</p>
              <Box mt={1}>
                <Copyright serviceName={serviceName} />
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={showBackdrop} >
        <Slide direction="up" in={showBackdrop}>
          <Card className={classes.card}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >認証処理中</Typography>
            </ CardContent>
          </ Card>
        </Slide>
      </Backdrop>
    </Grid >
  );
}
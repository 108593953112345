/**
 * テナント管理者画面のツールランチャー設定画面
 */
import React, { forwardRef, ForwardRefRenderFunction, Fragment, useImperativeHandle, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
// import { TenantLauncherJson } from '../user/TenantLauncher';
import JfsClient, { TenantLauncherSetting as TenantLauncherJson } from '@fsi/jfs-sdk';

interface Props {
}

// 公開したいメソッドの定義
export interface TenantLauncherHandler {
    /** ツールランチャー設定画面を開く */
    open(): void;
}

const TenantLauncherComponent: ForwardRefRenderFunction<TenantLauncherHandler, Props> = (props, ref) => {
    /** ツールランチャー設定画面の表示状態 */
    const [open, setOpen] = useState(false);
    /** 設定リスト */
    const [launcherList, setLauncherList] = useState([] as TenantLauncherJson[]);
    /** エラーリスト */
    const [errors, setErrors] = useState([] as string[]);

    const TOOL_LAUNCHER_NUM = 15;

    // PaaS SDK
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        open: () => {
            // setOpen(true);
            // var params = new URLSearchParams();
            // params.append("tab_id", sessionStorage.getItem("TABID") as string);
            // SDKにない
            // axios.post("/api/admin/launcher/list/get", params)
            //     .then((e: AxiosResponse) => {
            //         let copyList = [...launcherList];
            //         copyList = e.data as TenantLauncherJson[];
            //         setLauncherList(copyList);
            //         // console.log(e.data);
            //         setOpen(true);
            //     }).catch(err => {

            //     });

            httpClient.getAdminTenantLancherList(sessionStorage.getItem("TABID") as string)
                .then((e: TenantLauncherJson[]) => {
                    let copyList = [...launcherList];
                    copyList = e;
                    setLauncherList(copyList);
                    // console.log(e.data);
                    setOpen(true);
                }).catch(err => {

                });
        },
    }))

    /**
     * 画面を閉じる
     */
    const handleClose = () => {
        setOpen(false);
    }

    /**
     * 設定リストを描画する
     */
    const draw = useMemo(() => {
        return (
            <Fragment>
                {[...Array(TOOL_LAUNCHER_NUM)].map((e: number, index: number) => {
                    let result = launcherList.filter(e => e.sortNo === index);
                    return (
                        <Fragment key={e}>
                            <div style={{ width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={"view-" + index}
                                            defaultChecked={result.length === 0 ? false : result[0].enableView}
                                            // onChange={handleChangeView}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="表示する"
                                />
                                <TextField id={"url-" + index} defaultValue={result.length === 0 ? "" : result[0].url} style={{ marginRight: 10 }} variant='outlined' size='small' label={"リンクするURL " + (index + 1)}  placeholder="例：https://example.com" InputLabelProps={{ shrink: true }} />
                                <TextField id={"picture-url-" + index} defaultValue={result.length === 0 ? "" : result[0].picturUrl} style={{ marginRight: 10 }} variant='outlined' size='small' label={"ボタン画像のURL※省略可 " + (index + 1)} placeholder="例：https://example.com" InputLabelProps={{ shrink: true }} />
                                <TextField id={"display-string-" + index} defaultValue={result.length === 0 ? "" : result[0].displayString} style={{ marginRight: 10 }} variant='outlined' size='small' label={"ボタン文字 " + (index + 1)} placeholder="例：社内ポータルなど" InputLabelProps={{ shrink: true }} />
                            </div>
                            <Box m={1} />
                        </Fragment>
                    )
                })}
            </Fragment>
        )
    }, [launcherList])

    /**
     * 設定リストをサーバーに送信する
     * @returns 
     */
    const handleUpdate = () => {
        let errorMessage: string[] = [];
        let updateData: TenantLauncherJson[] = [];
        for (let i = 0; i < TOOL_LAUNCHER_NUM; i++) {
            let data = new TenantLauncherJson();
            data.sortNo = i;
            data.enableView = (document.getElementById("view-" + i) as HTMLInputElement).checked;
            data.url = (document.getElementById("url-" + i) as HTMLInputElement).value;
            if(data.url.length > 512) errorMessage.push("【リンクするURL-" + (i+1) + "】は512文字以内で入力してください。")
            data.picturUrl = (document.getElementById("picture-url-" + i) as HTMLInputElement).value;
            if(data.picturUrl.length > 512) errorMessage.push("【ボタン画像のURL※省略可-" + (i+1) + "】は512文字以内で入力してください。")
            data.displayString = (document.getElementById("display-string-" + i) as HTMLInputElement).value;
            if(data.displayString.length > 32) errorMessage.push("【ボタン文字-" + (i+1) + "】は32文字以内で入力してください。")
            console.log(data.enableView + " " + data.url + " " + data.picturUrl + " " + data.displayString);
            updateData.push(data);
        }
        if(errorMessage.length !== 0) {
            setErrors(errorMessage);
            return;
        } else {
            setErrors([]);
        }
        // let json = JSON.stringify(updateData);
        // console.log(json);

        // var params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("data", json);
        // // SDKにない
        // axios.post("/api/admin/launcher/list/update", params)
        //     .then((e: AxiosResponse) => {
        //         setLauncherList([]);
        //         setOpen(false);
        //     }).catch(err => {

        //     });

        httpClient.updateTenantLancherList(sessionStorage.getItem("TABID") as string, updateData)
            .then((e) => {
                setLauncherList([]);
                setOpen(false);
            }).catch(err => {

            });
        
    }

    /**
     * エラーリストを描画する
     */
    const drawErrors = useMemo(() => {
        return(
            <Fragment>
                {errors.map(e => {
                    return <div style={{color: "red"}}>{e}</div>
                })}
                <Box m={2}/>
            </Fragment>
        )
    }, [errors])

    return (
        <Fragment>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth disableBackdropClick>
                <DialogTitle id="tenant-launcher-dialog-title">リンク設定</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant='body1'>ユーザー全員のフロア画面に、外部Webにリンクするボタンを配置することが出来ます。</Typography>
                        <Typography variant='body2'>※ボタン画像を省略するとボタン文字がボタンに表示されます。</Typography>
                        <Typography variant='body2'>※設定した内容は、各ユーザーのサインイン時に反映されます。</Typography>
                    </DialogContentText>
                    <div style={{ marginBottom: 10 }} />
                    {drawErrors}
                    {draw}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={handleUpdate} color="primary">
                        設定
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export const TenantLauncher = forwardRef(TenantLauncherComponent);
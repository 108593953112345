import React, { Fragment, useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { chkJsonFormat } from '../common/JsonClass';
import EditTextDialog from './EditTextDialog';
import EditVideoURLDialog from './EditVideoURLDialog';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient, { FloorObject } from '@fsi/jfs-sdk';

// icons
import CreateIcon from '../img/edit_B.png';
import QueuePlayNextIconOFF from '../img/monitor_B.png';
import QueuePlayNextIconON from '../img/monitor_act_B.png';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
//import CastIcon from '@material-ui/icons/Cast';
//import CastConnectedIcon from '@material-ui/icons/CastConnected';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { Utility } from '../common/Utility';
import { table } from 'console';
import EditBanner from './EditBanner';
import BaseDialog from './BaseDialog';

const staticKanbanStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    pointerEvents: 'auto',
}

const dynamicKanbanStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    top: 5,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 15,
    lineHeight: '15px',
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    fontSize: 16,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
    }),
);

export interface Props {
    className?: string,
    floorObject: FloorObject,
    sendText1KanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    sendBannerText: (id: number, text: string, font: string, color: string) => void,
    sendVideoURLText: (
        id: number, text: string, isPlayAudio: boolean, isResetURL: boolean
    ) => void,
    isDrawText?: boolean,
    role?: string
    sendMeetingRoomLock: (id: number, locked: boolean) => void,
}

export default function MeetingRoom(props: Props) {
    // 表示するメッセージの取得
    // elm message:会議室コメント
    //     url:外部会議url
    function GetInText(text:string, objecttype:number, elm:string ) {
        // 外部会議部屋の場合、JSONから取得
        if( Utility.isVideoConference(objecttype) ){
            // Jsonフォーマットチェック
            let jf: chkJsonFormat = new chkJsonFormat();
            let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

            if(jsonCheck[0]){
                // Jsonから取得
                let intextJson = jsonCheck[2];
                if (elm === 'message') {
                    text = intextJson.message;
                } else if (elm === 'url') {
                    text = intextJson.url;
                } else {
                    // 処理なし(textをそのまま使用)
                }
            }else{
                // Json形式ではない場合
                if (elm === 'message') {
                    // 処理なし（textをそのまま使用）
                } else if (elm === 'url') {
                    // urlはまだ設定されていない
                    text = "";
                } else {
                    // 処理なし(textをそのまま使用)
                }
            }
        }
        return text;
    }

    /**
     * staticKanban(ホワイトボード)用のパース
     */
    function GetInText2(text:string, elm:string ) {
        
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT1_BANNER, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            if (elm === 'message') {
                text = intextJson.message;
            } else if (elm === 'font') {
                text = intextJson.font;
            } else if (elm === 'color') {
                text = intextJson.color;
            } else {
                text = "";
            }
        }else{
            // Json形式ではない場合
            if (elm === 'message') {
                text = "";
            } else if (elm === 'font') {
                // urlはまだ設定されていない
                text = "";
            } else if (elm === 'color') {
                text = "";
            } else {
                text = "";
            }
        }
        
        return text;
    }

    // チェック状態の取得
    function GetCehckStat(text: string) {
        let isChcked = false;

        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            isChcked = intextJson.isResetURL;
        } else {
            // 処理なし(Json未対応の場合は揮発性の項目となるため)
        }
        return isChcked;
    }

    const defaultProps = {
        isDrawText: true
    }
    const classes = useStyles();
    const { className, role } = props;
    const isDrawText  = props.isDrawText ?? defaultProps.isDrawText;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2, videoURL, isResetURL, lockedMeetingRoom } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const [isStaticKanbanOpen, setIsStaticKanbanOpen] = useState(false);
    const [isVideoURLOpen, setIsVideoURLOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // const text1Width = useMemo(() => calcWidth(text1), [text1]);
    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
        // 小数点を繰り上げ
        let strLength = Math.ceil(len)

        return strLength
    };

    const kanbanMaxWidth = useMemo(
        () => ( Utility.isVideoConference(objectMaster.type) )
            ? (objectMaster.width - objectMaster.editMinusOffsetLeft) -  20 - 50 - 20
            : (objectMaster.width - objectMaster.editMinusOffsetLeft) - 50
        , [objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);

    const calcLengthWidth = (text: string, maxWidth: number) => {
        if(text === null) text = '';
        return (
            text.length === 0 
                ? 24
                : Math.min(judgehz(text) * 13 + 2, maxWidth)
        )
    }
    
    // 看板の幅
    const text2Width = useMemo(() => 
        calcLengthWidth(GetInText(text2, objectMaster.type, 'message'), kanbanMaxWidth)
    , [text2, objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);

    const isEditable = useMemo(() =>
        role ? (role.match(/ROLE_ADMIN/) != null) : true
    , [role]);

    // 外部連携会議室の会議URL設定アイコン
    const dynamicSwitch = useMemo(() =>
        // httpのアドレスが設定されているときは背景色を変える
        GetInText(text2, objectMaster.type, 'url')?.indexOf('http') === 0
            ? <img src={QueuePlayNextIconON} style={{ width: 24, height: 24 }} />
            : <img src={QueuePlayNextIconOFF} style={{ width: 24, height: 24 }} />
        , [videoURL, text2, objectMaster.type]);

    // 会議室の看板
    const dynamicContent = useMemo(() => {
        let intext = ''
        if (text2 === null) {
            intext = GetInText('', objectMaster.type, 'message');
        } else {
            // 表示するメッセージを取得
            intext = GetInText(text2, objectMaster.type, 'message');
        }
        
        return (
            intext.length === 0
                ? <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                : intext
        )
    }, [text2, objectMaster.type]);

    const setFont = (fontName: string) => {
        let font = fontName.split("　");
        if(font.length > 1){
            return font[1];
        }else{
            return "sans-serif";
        }
    }

    const content = useMemo(() => {
        return (
            <>
                <div style={{display: 'inline-block', 
                    fontFamily: setFont(GetInText2(text1,'font')), 
                    color: GetInText2(text1,'color'),
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',  
                    //paddingTop: objectMaster.width > 120 ? (GetInText2(text1,'message').length > 16 ? 4.2 : 13) : (GetInText2(text1,'message').length > 8 ? 4.2 : 13),
                }}>
                    {GetInText2(text1,'message')}
                </div>
            </>
        )
    }, [text1]);

    // 改行が入っていた場合のホワイトボードの上下位置調整
    const kanbanOffsetTop = useMemo(
        () => (text1.match(/\\n/g) || []).length > 1 ? (text1.match(/\\n/g) || []).length * -6 : 0
        , [text1]
    );

    const drawStaticKanban = useMemo(() => {
        return (objectMaster.type === 2000100 || objectMaster.type === 2000102 || objectMaster.type === 2000105) && (
            <Fragment>
                <div
                    key={`meetingRoomStaticKanban${id}`}
                    id={`meetingRoomStaticKanban${id}`}
                    onClick={() => setIsStaticKanbanOpen(true)}
                    className={className}
                    style={{
                        ...staticKanbanStyle,
                        left: objectMaster.textOffsetLeft,
                        top: objectMaster.textOffsetTop,
                        height: 70,
                        
                    }}
                >
                    <Typography className={className} 
                                style={{ ...kanbanTextStyle,
                                        marginTop: kanbanOffsetTop,
                                        width: objectMaster.textWidth, 
                                        
                                }}
                                variant="body2" 
                                color="textSecondary">
                        {content}
                    </Typography>
                </div>
                <EditBanner
                    className={className}
                    isOpen={isStaticKanbanOpen && isEditable}
                    floorObjectId={id}
                    type={objectMaster.type}
                    title="表示内容"
                    text={text1}
                    maxLength={objectMaster.textLength}
                    onClose={() => setIsStaticKanbanOpen(false)}
                    sendText={props.sendBannerText}
                />

            </Fragment>

            // eslint-disable-next-line react-hooks/exhaustive-deps
        )
    }, [text1, isEditable, isStaticKanbanOpen, objectMaster.type, objectMaster.textWidth, objectMaster.textLength]);

    const kanbanLength = useMemo(
        () => 26
        , [text1]);

    const videoURLLength = useMemo(
        () => 512
        , [videoURL, text2]);
   
    const kanbanLeft = useMemo(() => {
        return kanbanMaxWidth - text2Width + 48;
    }, [text2Width, objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);
    
    const drawDynamicKanban = useMemo(() => isDrawText && (
        <Fragment>
            <div
                key={`meetingRoomDynamicKanban${id}`}
                id={`meetingRoomDynamicKanban${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...dynamicKanbanStyle,
                    left: kanbanLeft,
                    minWidth: text2Width,
                    maxWidth: text2Width,
                    pointerEvents: "auto",
                    minHeight: GetInText(text2, objectMaster.type, 'message').length === 0 ? 24 : 15,
                    marginTop: GetInText(text2, objectMaster.type, 'message').length === 0 ? -8 : -3,
                    marginLeft: GetInText(text2, objectMaster.type, 'message').length === 0 ? 10 : 10,
                    border: GetInText(text2, objectMaster.type, 'message').length === 0 ? "none" : "1px solid #AAA",
                    backgroundColor: GetInText(text2, objectMaster.type, 'message').length === 0 ? "rgba(0,0,0,0)" : "#ffffff",
                }}
            >
                {dynamicContent}
            </div>

            <EditTextDialog
                className={className}
                isOpen={isOpen && isEditable}
                floorObjectId={id}
                title={'表示内容'}
                text={text2}
                maxLength={kanbanLength}
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </Fragment>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text1, text2, isOpen, isEditable, objectMaster.width, isDrawText, text2Width])

    const drawDynamicSwitch = useMemo(() => (
        ( Utility.isVideoConference(props.floorObject.objectMaster.type) ) ?
            <Fragment>
                <div
                    key={`meetingRoomDynamicSwitch${id}`}
                    id={`meetingRoomDynamicSwitch${id}`}
                    className={className}
                    onClick={() => setIsVideoURLOpen(true)}
                    style={{
                        ...dynamicKanbanStyle,
                        left: (objectMaster.width - objectMaster.editMinusOffsetLeft) - 32,
                        marginTop: -8,
                        height: 24,
                        width: 24,
                        pointerEvents: "auto",
                        border: "none",
                        backgroundColor: "rgba(0,0,0,0)",
                    }}
                >
                    {dynamicSwitch}
                </div>
                <EditVideoURLDialog
                    className={className}
                    isOpen={isVideoURLOpen && isEditable}
                    floorObjectId={id}
                    title="外部Web会議ツール設定"
                    text={text2}
                    maxLength={videoURLLength}
                    onClose={() => setIsVideoURLOpen(false)}
                    isResetURL={GetCehckStat(text2)}
                    sendText={props.sendVideoURLText}
                />
            </Fragment>
        :
            <Fragment/>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text2, videoURL, isResetURL, isVideoURLOpen, isEditable, objectMaster.width, objectMaster.editMinusOffsetLeft])

    // 会議室のロック状態アイコン
    const lockStatus = useMemo(() => {
        return (
            lockedMeetingRoom === true
                ? <div style={{ position: "relative" }}>
                    <img src={QueuePlayNextIconON} style={{ width: 24, height: 24, position: "absolute", left: 0 }} />
                    <div style={{ height: "20px", width: "20px", background: "#41BDCE", borderRadius: "50%", position: "absolute", left: 2, top: 2 }} />
                    <LockIcon style={{ width: 18, height: 18, color: '#ffffff', position: "absolute", left: 3, top: 3 }} />
                </div>
                : <div style={{position: "relative"}}>
                    <img src={CreateIcon} style={{ width: 24, height: 24, position: "absolute", left: 0}}/>
                    <div style={{ height: "20px", width: "20px" , background: "white" , borderRadius: "50%", position: "absolute", left: 2, top: 2}}/>
                    <LockOpenIcon style={{ width: 18, height: 18, color: 'rgba(0, 0, 0, 0.65)', position: "absolute", left: 3, top: 3 }} />
                </div>
                // ? <LockIcon style={{ width: 24, height: 24, color: '#ffffff', background: '#2196f3' }} />
                // : <LockOpenIcon style={{ width: 24, height: 24 }} />
        )
    }, [props.floorObject.objectMaster.openmeetMemberMax, lockedMeetingRoom]);

    const drawlockStatus = useMemo(() => isDrawText && (
        <Fragment>
            <div
                key={`meetingRoomLockStatus${id}`}
                id={`meetingRoomLockStatus${id}`}
                className={className}
                onClick={() => { if (isEditable) { setOpenDialog(true) } }}
                style={{
                    ...dynamicKanbanStyle,
                    left: (objectMaster.width - objectMaster.editMinusOffsetLeft) - 8,
                    marginTop: -8,
                    height: 24,
                    width: 24,
                    pointerEvents: "auto",
                    border: "none",
                    backgroundColor: "rgba(0,0,0,0)",
                }}
            >
                {lockStatus}
            </div>
        </Fragment>
    ), [lockedMeetingRoom, objectMaster.iconOffsetRight, isEditable]);

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        props.sendMeetingRoomLock(id, !lockedMeetingRoom);
    }

    const drawDialog = useMemo(() => 
        <BaseDialog
            id="Dialog"
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="Dialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="DialogTitle" className={classes.dialogTitle}>
                確認
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    {lockedMeetingRoom === true
                        ? <Grid item xs={12} style={{ paddingTop: 5 }}>
                            この部屋を解錠します。
                            <br/>
                            ※解錠すると新たなユーザーが入室できるようになります。
                        </Grid>
                        : <Grid item xs={12} style={{ paddingTop: 5 }}>
                            この部屋を施錠します。
                            <br/>
                            ※施錠すると新たに入室できなくなります。
                            <br/>
                            ※管理者はこの制限を受けません。
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOk} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                <Button onClick={handleDialogClose} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [openDialog]);

    const draw = useMemo(() => (
        <div
            key={`meetingRoom${id}`}
            id={`meetingRoom${id}`}
            className={className}
            style={{
                ...SELECT_NONE,
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                //backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id)})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id,undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorSection,
                pointerEvents: "none",
            }}
        >
            {drawStaticKanban}
            {drawDynamicKanban}
            {drawDynamicSwitch}
            {objectMaster.openmeetMemberMax !== 0 ? drawlockStatus : ''}
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [id, offsetLeft, offsetTop, objectMaster, text1, text2, videoURL, isResetURL, isOpen, isStaticKanbanOpen, isVideoURLOpen, isDrawText, lockedMeetingRoom]);

    return (
        <Fragment>
            {draw}
            {drawDialog}
        </Fragment>
    );
}
import React, { useRef } from 'react';
import { Button, Tooltip } from '@material-ui/core';

import ZIndex from "../common/ZIndex";
import TestUpdateSessionDataDialog from '../test/TestUpdateSessionDataDialog';
import { TestDisplayWebRtcModeDialog, TestDisplayWebRtcModeDialogHandler } from '../test/TestDisplayWebRtcModeDialog';
import { ScreenShareMode } from '../webrtc/WebrtcComponent';
import { TestParticipantsDialog, TestParticipantsDialogHandler } from "../test/TestParticipantsDialog"
import useReactRouter from 'use-react-router';

interface Props {
    myUserId: number,
    usersCount: number,
    floorId: number,
    webRtcMode: number,
    webRtcRoomId: string,
    isScreenShare: boolean,
    screenShareMode: number,
    isVisibleWebRtcBroadCastButton: boolean,
    isVisibleWebRtcBroadCastStopButton: boolean,
    addUsers: () => void,
    removeUsers: () => void,
    handleTestMoveUser: () => void,
    handleTestLoginLogoutUser: () => void,
    handleTestStopVideo: () => void,
    handleTestDiffData: () => void,
    handleScreenShare: (value: boolean, mode: number, webRtcRoomId: string) => void,
    sendConnectWebRtcAllUser: (id: string) => void,
    sendEndMoveAllUser: () => void,
    sendWebSocket: (action: string, object: object) => void,
    getUseTurnServer: () => boolean | null,
    handleTestSnackBar: () => void,
    handleDebugSend: (id:number) => void,
}

export default function TestDebugMenu (props: Props) {
    const { history } = useReactRouter();
    const [isOpen, setIsOpen] = React.useState(false);
    const [updateSessionDataType, setUpdateSessionDataType] = React.useState(0);
    const testDisplayWebRtcModeDialogRef = useRef({} as TestDisplayWebRtcModeDialogHandler);
    const testParticipantsDialogRef = useRef({} as TestParticipantsDialogHandler);

    const handleTestSendSessionData = () => {
        setIsOpen(true);
        setUpdateSessionDataType(0);
    }

    const handleTestUpdateSessionData = () => {
        setIsOpen(true);
        setUpdateSessionDataType(1);
    }

    const handleTestDisplayWebRtcMode = () => {
        props.getUseTurnServer()
        testDisplayWebRtcModeDialogRef.current.open();
    }

    const handleTestDisplayPartcipants = () => {
        testParticipantsDialogRef.current.open();
    }

    /************
    const debugSendIrregularClose = () => {
        props.handleDebugSend(1);
    }
    const debugSendClear = () => {
        props.handleDebugSend(0);
    }
    *********************/
    const debugSendPeerClose = () => {
        props.handleDebugSend(2);
    }

    const drawTestDebugMenu = React.useMemo(() => {
        return (
            <div style={{ position: "absolute", left: 100, zIndex: ZIndex.experiment }}>
                <Button onClick={props.addUsers} variant="contained" color="primary">ダミーユーザ追加(+10){props.usersCount}</Button>
                <Button onClick={props.removeUsers} variant="contained" color="primary">ダミーユーザ削除</Button>
                <Button onClick={props.handleTestMoveUser} variant="contained" color="primary">移動開始</Button>
                <Button onClick={props.handleTestLoginLogoutUser} variant="contained" color="primary">入退室開始</Button>
                <Button onClick={props.handleTestStopVideo} variant="contained" color="primary">ビデオ停止</Button>
                <Button onClick={props.handleTestDiffData} variant="contained" color="primary">データ比較</Button>
                <Tooltip title="SessionDataの更新対象と値をJson形式で指定し、フロア内の全ユーザに適用する" aria-label="send session data">
                    <Button onClick={handleTestSendSessionData} variant="contained" color="primary">指定値送信</Button>
                </Tooltip>
                <Tooltip title="指定した列 (SessionDataのフィールド) のみを、フロア内の全ユーザ分 配信する" aria-label="update session data">
                    <Button onClick={handleTestUpdateSessionData} variant="contained" color="primary">指定列更新</Button>
                </Tooltip>
                {props.isScreenShare && props.screenShareMode === ScreenShareMode.FullScreen?
                    <Button onClick={() => {props.handleScreenShare(true, ScreenShareMode.FullScreen, props.webRtcRoomId)}} variant="contained" color="primary">画面共有</Button>
                    : ""}
                {props.isVisibleWebRtcBroadCastButton ?
                    <Button onClick={() => {props.sendConnectWebRtcAllUser(props.myUserId.toString())}} variant="contained" color="primary">全体放送開始</Button>
                    : ""}
                {props.isVisibleWebRtcBroadCastStopButton ?
                    <Button onClick={props.sendEndMoveAllUser} variant="contained" color="primary">全体放送終了</Button>
                    : ""}
                <Tooltip title="現在のWebRtcの通信方式情報を表示する" aria-label="display webRtcMode">
                    <Button onClick={handleTestDisplayWebRtcMode} variant="contained" color="primary">WebRtC情報表示</Button>
                </Tooltip>
                <Tooltip title="現在のフロア内にいる人数を表示する" aria-label="display participants">
                    <Button onClick={handleTestDisplayPartcipants} variant="contained" color="primary">フロア内人数</Button>
                </Tooltip>
                <Button onClick={() => history.push({pathname: "/top"})} variant="contained" color="primary">TOPへ</Button>
                <Button onClick={props.handleTestSnackBar} variant="contained" color="primary">スナックバーテスト</Button>
                {/************
                <Button onClick={debugSendIrregularClose} variant="contained" color="primary">irregular_close</Button>
                <Button onClick={debugSendClear} variant="contained" color="primary">sendClear</Button>
                *******************/}
                <Button onClick={debugSendPeerClose} variant="contained" color="primary">peer_close</Button>
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.usersCount, props.isScreenShare, props.myUserId, props.isVisibleWebRtcBroadCastButton, props.isVisibleWebRtcBroadCastStopButton])

    const drawUpdateSessionDialog = React.useMemo(() => 
        <TestUpdateSessionDataDialog
            open={isOpen}
            type={updateSessionDataType}
            floorId={props.floorId}
            onClose={() => setIsOpen(false)}
            onSendSocket={props.sendWebSocket}/>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [isOpen, updateSessionDataType, props.floorId])

    return (
        <React.Fragment>
            {drawTestDebugMenu}
            {drawUpdateSessionDialog}
            <TestDisplayWebRtcModeDialog
                ref={testDisplayWebRtcModeDialogRef}
                getUseTurnServer={props.getUseTurnServer}
                webRtcMode={props.webRtcMode} />
            <TestParticipantsDialog ref={testParticipantsDialogRef} />
        </React.Fragment>
    )
}
/**
 * FloorでWebSocketが切断されたときに表示するダイアログ
 * 切断された事をダイアログで表示して、閉じるとTopに遷移させる
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

interface Props {
    title: string,
    message: string,
    closeButtonName: string,
    closeCallback: (no:number) => void,
    waitTimer: number,
}

export interface DisconnectDialogHandler {
    open: () => void;
    retryout: () => void;
}

const OkOnlyDialogComponect: ForwardRefRenderFunction<DisconnectDialogHandler, Props> = (props, ref) => {
    const { history } = useReactRouter();
    const [open, setOpen] = React.useState(false);
    const [rout, setRout] = React.useState(false);  // #169 ダイアログ自動クローズ
    const [t] = useTranslation();

    let timerId = 0;    // #169 ダイアログ自動クローズ
    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
            // #169 ダイアログ自動クローズ
            console.log("MoveFloorWait set")
            timerId = window.setTimeout(() => handleClose(), props.waitTimer);
            console.log("MoveFloorWait timerId="+timerId)
        },
        // #169 ダイアログ自動クローズ
        retryout: () => {
            console.log("MoveFloorWait retryout set")
            setRout(true);
        },
    }))

    const handleClose = () => {
        setOpen(false);
        clearTimeout(timerId);  // #169 ダイアログ自動クローズ
        console.log("MoveFloorWait close reset timer="+timerId)
        props.closeCallback(1);
    };

    const handleCloseRetryOut = () => {
        setRout(false);
        props.closeCallback(2);
    };

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                //onClose={handleClose}
                aria-labelledby="ok-only-dialog-title"
                aria-describedby="ok-only-dialog-description"
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="disconnect-dialog-title">{t(props.title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ok-only-dialog-description">
                        <Typography variant="body2">{t(props.message)}</Typography>
                    </DialogContentText>
                    {/* #169 ダイアログ自動クローズ */}
                    <DialogContentText id="ok-only-dialog-description">
                        <Typography variant="body2">{t('そのままお待ちください。')}</Typography>
                    </DialogContentText>
                </DialogContent>
                {/* #169 ダイアログ自動クローズ
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t(props.closeButtonName)}
                    </Button>
                </DialogActions>
                */}
            </BaseDialog>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    /* #169 ダイアログ自動クローズ */
    const draw_rout = useMemo(() => {
        return (
            <BaseDialog
                open={rout}
                onClose={handleCloseRetryOut}
                aria-labelledby="ok-only-dialog2-title"
                aria-describedby="ok-only-dialog2-description"
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="disconnect-dialog-title">{t(props.title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ok-only-dialog2-description">
                        <Typography variant="body2">{t(props.message)}</Typography>
                    </DialogContentText>
                    <DialogContentText id="ok-only-dialog2-description">
                        <Typography variant="body2">{t('再度実行します')}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRetryOut} color="primary">
                        {t('閉じる')}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [rout])

    return (
        <Fragment>
            {draw}
            {draw_rout}
        </Fragment>
    )
}

export const OkOnlyDialog = forwardRef(OkOnlyDialogComponect);
import React, { Fragment, useRef } from 'react';
import { Paper, makeStyles, Typography, Button, Grid, InputLabel, Backdrop, CircularProgress, TextField, Input, Snackbar, SnackbarContent, IconButton, FormControl, InputAdornment, Tooltip, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, RadioGroup, FormControlLabel, Radio, FormHelperText, DialogActions, Chip, Link, createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import axios, { AxiosResponse } from 'axios';
import Cookie from 'js-cookie';
import { SamlIdpSetting } from '../common/AjaxJsonClasses';
import { useHistory } from 'react-router';
import { Utility } from '../common/Utility';
import Cookies from 'universal-cookie';
import JfsClient, { SAMLIdpSettingInfo, SamlCertificateKeyPair, CertType, JfsError } from "@fsi/jfs-sdk";
//import RecaptchaSettingGridItem from './RecaptchaSettingGridItem';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: `calc(100% - 220px)`,
        padding: theme.spacing(1) * 3,
    },
    demoTitle: {
        color: "#888"
    },
    paper: {
//        maxWidth: "800px",
        padding: "15px",
        margin: "0 auto",
    },
    paperIcon: {
        float: "left",
        padding: "15px",
        marginTop: "-30px",
        marginRight: "15px",
        borderRadius: "3px",
        background: "linear-gradient(60deg, #ec407a, #d81b60)",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4)",
    },
    itemGroup: {
        marginTop: 12,
        '&:first-child': {
            marginTop: 4,
        },
    },
    itemLabel: {
        fontSize: 18,
    },
    itemContent: {
        fontSize: 16,
    },
    updateButtonDiv: {
        textAlign: "end",
        marginRight: 20,
    },
    idpSettingManualDiv: {
        textAlign: "end",
        marginRight: 20,
        fontSize: 16,
        marginBottom: 10,
    },
    paperHeader: {
        height: "50px",
    },
    paperHeaderMessage: {
        paddingTop: "5px",
    },
    icon: {
        color: "white",
    },
    success: {
        background: "#4caf50",
        color: "#fff",
    },
    alert: {
        background: "#f44336",
        color: "#fff",
    },
    filename: {
        marginLeft: 12,
        fontSize: "12px",
    },
    filenameButton: {
        paddingLeft: 16,
        paddingRight: 16,
        whiteSpace: "nowrap",
    },
    inputFileBtnHide: {
        opacity: 0,
        appearance: "none",
        position: "absolute",
        width: 0,
        height: 0,
    },
    gridCell: {
        paddingLeft: 6,
        paddingRight: 6,
        marginBottom: 12,
    },
    gridGroup: {
        padding: 0,
        margin: 0,
        marginLeft: 24,
        marginRight: 12,
    },
    gridHeaderCell: {
        padding: 0,
        marginTop: 48,
        marginBottom: 24,
    },
    caption: {
        color: "#888",
    },
    radioGroup: {
        float: "left",
        flexDirection: "row",
    }
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

export default function TenantSettingSamlPaper() {
    const classes = useStyles();
    const [t] = useTranslation();

    const history = useHistory();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isWaiting, setWaiting] = React.useState(false);
    const [infoStatus, setInfoStatus] = React.useState("hide" as "hide" | "success" | "error");
    const [infoMessage, setInfoMessage] = React.useState("");
    const [inputErrors, setInputErrors] = React.useState([] as ('registrationId' | 'entityId' | 'signOnUrl' | 'logoutUrl' | 'signingCertificateFile')[]);
    const [openCertificateDialog, setOpenCertificateDialog] = React.useState(false);
    const [openConfirmUpdate, setOpenConfirmUpdate] = React.useState(false);

    //const [samlIdpSettingBase, setSamlIdpSettingBase] = React.useState(new SamlIdpSetting());
    const [samlIdpSettingBase, setSamlIdpSettingBase] = React.useState({} as SAMLIdpSettingInfo);
    //const [samlIdpSetting, setSamlIdpSetting] = React.useState(new SamlIdpSetting());
    const [samlIdpSetting, setSamlIdpSetting] = React.useState({} as SAMLIdpSettingInfo);
    const [idpCertFile, setIdpCertFile] = React.useState(null as File|null);
    const [updateKeyPairs, setUpdateKeyPairs] = React.useState(new Date());
    const [addPairPurpose, setAddPairPurpose] = React.useState("Signing" as "Signing" | "Encryption");
    const [addCertFile, setAddCertFile] = React.useState(null as File|null);
    const [addKeyFile, setAddKeyFile] = React.useState(null as File|null);
    const [loginUrl, setLoginUrl] = React.useState("");
    const [idpSettingManualURL, setIdpSettingManualURL] = React.useState("");
    const [serviceName, setServiceName] = React.useState("");
    const cookies = new Cookies();
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;


    React.useLayoutEffect(() => {
        // サーバからテナント設定を取得し、内容を画面へ反映する
        loadSamlTenantSetting();
        getServiceName();
        getIdpSettingManualURL();
        // Mount時の為、第二引数は指定しない (ESLintコメントで Warning 削除)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // IdP設定マニュアル取得
    const getIdpSettingManualURL = () => {
        var params = new URLSearchParams();
        axios.post('/api/service/idpsettingmanual', params)
          .then((e: AxiosResponse) => {
            let ddd = new Date();
            let rURL = e.data + "?" + ddd.getTime();
            setIdpSettingManualURL(rURL);
          }).catch(() => {
          });
      }

    // サービス名取得
    const getServiceName = () => {
        //var params = new URLSearchParams();
        //axios.post('/api/service/get', params)
        httpClient.getServiceInfo()
        .then((e) => {
            setServiceName(e.name);
          }).catch(() => {
          });
    }

    /**
     * サーバからテナント設定を取得し、内容を画面へ反映する
     */
    const loadSamlTenantSetting = () => {
        // const params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // axios.post('/api/admin/samltenant/get', params)
        httpClient.getSamlTenantSetting(sessionStorage.getItem("TABID") as string)
            .then((response: SAMLIdpSettingInfo) => {
                // 302 を検知したらログアウト
                // if (Utility.checkRedirectAndLogout(response, history)) {
                //     return;
                // }
                //deleteAllCertificateKeyPair();
                const newKeyPairList: SamlCertificateKeyPair[] = [];
                response.certificateKeyPairList.forEach((keyPair: SamlCertificateKeyPair) => {
                    keyPair.active = true;
                    newKeyPairList.push(keyPair);
                });
                response.certificateKeyPairList = newKeyPairList;
                setSamlIdpSettingBase(response);
                setSamlIdpSetting(response);
                setUpdateKeyPairs(new Date());
            })
            .catch(err => {
                console.error(err);
                // テナント管理者権限が無くなっている等の理由でデータが取れなかった場合は、念の為 強制的にログアウト
                Utility.logout(history);
            });
    }

    /**
     * 設定ボタン押下
     */
    const handleSave = () => {
        setOpenConfirmUpdate(false);

        let errors = [] as ('registrationId' | 'entityId' | 'signOnUrl' | 'logoutUrl' | 'signingCertificateFile')[];
        setInputErrors(errors);
        if (samlIdpSetting.registrationId === null || samlIdpSetting.registrationId.length === 0) {
            errors.push('registrationId');
        }
        if (samlIdpSetting.entityId === null || samlIdpSetting.entityId.length === 0) {
            errors.push('entityId');
        }
        if (samlIdpSetting.signOnUrl === null || samlIdpSetting.signOnUrl.length === 0) {
            errors.push('signOnUrl');
        }
        if (samlIdpSetting.logoutUrl === null || samlIdpSetting.logoutUrl.length === 0) {
            errors.push('logoutUrl');
        }
        const idpCertFileName: string = idpCertFile ? idpCertFile.name : samlIdpSetting.signingCertificateOriginalName;
        if (idpCertFileName === null || idpCertFileName.length === 0) {
            errors.push('signingCertificateFile');
        }
        if (errors.length > 0) {
            setInputErrors(errors);
            enqueueSnackbar(t("必須項目が未入力です。"), { variant: "error" });
            return;
        }
        if(samlIdpSetting.registrationId.length < 3){
            errors.push('registrationId');
            setInputErrors(errors);
            enqueueSnackbar(t("レジストレーションIDは最低3文字必要です。"), { variant: "error" });
            return;
        }
        //const result:any = samlIdpSetting.registrationId.match(/^[A-Za-z0-9]+$/);
        if(!samlIdpSetting.registrationId.match(/^[A-Za-z0-9]+$/)){
            errors.push('registrationId');
            setInputErrors(errors);
            enqueueSnackbar(t("レジストレーションIDに使用できる文字は半角英数字です。"), { variant: "error" });
            return;
        }

        setWaiting(true);
        uploadCertPairs();
    }

    /**
     * 証明書ペアのアップロード後、設定の更新リクエストを実施
     */
    const uploadCertPairs = () => {
        if (!samlIdpSetting.certificateKeyPairList || samlIdpSetting.certificateKeyPairList.length === 0) {
            saveSamlSetting();

        } else {
            //let results: Promise<AxiosResponse>[] = [];
            let results: Promise<string>[] = [];

            // 証明書ペアを非同期アップロード
            samlIdpSetting.certificateKeyPairList.forEach((keyPair: SamlCertificateKeyPair) => {
                if (!keyPair || !keyPair.certificateFile || !keyPair.privateKeyFile) {
                    return; // continue
                }

                const headers = {
                    headers: {
                        'X-XSRF-TOKEN': Cookie.get("XSRF-TOKEN") as string,
                        "content-type": "multipart/form-data"
                    }
                };

                // let params = new FormData();
                // params.append("id", String(samlIdpSetting.id));
                // params.append("purpose", keyPair.purpose);
                // params.append("certFile", keyPair.certificateFile);
                // params.append("keyFile", keyPair.privateKeyFile);
                // params.append("tab_id", sessionStorage.getItem("TABID") as string);

                // results.push(axios.post('/api/admin/samltenant/uploadcerts', params, headers));
                results.push(httpClient.uploadSamlTenantCerts(samlIdpSetting.id, 
                        sessionStorage.getItem("TABID") as string, 
                        keyPair.purpose as CertType, 
                        keyPair.certificateFile, 
                        keyPair.privateKeyFile));
            });

            // 全ての /api/tenantadmin/samltenant/uploadcerts 完了を待って then を処理
            Promise.all(results).then((responses: string[]) => {
                // console.log(responses);
                for (const idx in responses) {
                    // 302 を検知したらログアウト
                    // if (Utility.checkRedirectAndLogout(responses[idx], history)) {
                    //     return;
                    // }
                    if (responses[idx] !== '000000') {
                        return;
                    }
                }

                // 全ての /uploadcerts が正常に完了していたら、/api/admin/samltenant/set
                saveSamlSetting();
            });
        }
    }

    /**
     * 設定の更新リクエストを実施
     */
    const saveSamlSetting = async () => {
        const headers = {
            headers: {
                'X-XSRF-TOKEN': Cookie.get("XSRF-TOKEN") as string,
                "content-type": "multipart/form-data"
            }
        };

        let params = new FormData();
        params.append("data", JSON.stringify(samlIdpSetting));
        if (idpCertFile !== null) {
            params.append('idpCertFile', idpCertFile);
        }
        params.append("tab_id", sessionStorage.getItem("TABID") as string);

        //await axios.post('/api/admin/samltenant/set', params, headers)
        httpClient.setSamlTenantSetting(sessionStorage.getItem("TABID") as string, samlIdpSetting, idpCertFile)
            .then((response: SAMLIdpSettingInfo) => {
                // 302 を検知したらログアウト
                // if (Utility.checkRedirectAndLogout(response, history)) {
                //     return;
                // }
                // console.log(response);
                /*
                setSamlIdpSetting(response.data);
                setUpdateKeyPairs(new Date());
                setWaiting(false);
                //エラー表示項目の初期化
                let errors = [] as ('registrationId' | 'entityId' | 'signOnUrl' | 'logoutUrl' | 'signingCertificateFile')[];
                setInputErrors(errors);
                */
                loadSamlTenantSetting();
                setWaiting(false);
                enqueueSnackbar(t("設定内容を保存しました。設定が反映されるまで、60秒程お待ちください。"), { variant: "success" });
                closeSnackbar("attentionMessage");
                if(samlIdpSetting.authType !== samlIdpSettingBase.authType){
                    Utility.logout(history);
                }
            })
            .catch((err : JfsError) => {
                // console.error(err)
                // setWaiting(false);
                // if(err.response?.data === "入力したレジストレーションIDは既に使用されています。"){
                //     const errors = [] as ('registrationId' | 'entityId' | 'signOnUrl' | 'logoutUrl' | 'signingCertificateFile')[];
                //     errors.push('registrationId');
                //     setInputErrors(errors);
                // }
                // enqueueSnackbar(err.response.data, { variant: "error" });
                const errors = [] as ('registrationId' | 'entityId' | 'signOnUrl' | 'logoutUrl' | 'signingCertificateFile')[];
                let message = "予期せぬエラーが発生しました。";
                // if(err.response?.data === "入力したレジストレーションIDは既に使用されています。"){
                if (err.code === 102004) {
                    errors.push('registrationId');
                    message = "入力したレジストレーションIDは既に使用されています。";
                } else if (err.code === 102002) {
                    errors.push('signingCertificateFile');
                    message = "IDプロバイダの署名証明書に、正しい書式の証明書を指定してください。";
                }
                setInputErrors(errors);
                enqueueSnackbar(message, { variant: "error" });
            });
    }

    const handleCancel = () => {
        // サーバからテナント設定を取得し、内容を画面へ反映する
        setIdpCertFile(null);
        loadSamlTenantSetting();
    }

    /**
     * アラートのSnackbarを閉じる
     */
     const handleCloseAlert = () => {
        setInfoStatus("hide");
    }

    /**
     * サインインURLの更新
     */
    const handleLoginUrl = React.useMemo(() => {
        setLoginUrl(document.location.protocol + '//' + document.location.host);
        if(samlIdpSetting.authType === 1){
            setLoginUrl(document.location.protocol + '//' + document.location.host + '/signin/' + samlIdpSetting.registrationId);
        }
    }, [samlIdpSetting.registrationId, samlIdpSetting.authType]);

    /**
     * サインインURL のコピー
     */
    const handleUrlCopy = () => {
        //クリップボードAPIでコピーするように変更
        var text = (document.getElementById('loginUrl') as HTMLInputElement).value;
        navigator.clipboard.writeText(text).then(e => {
            enqueueSnackbar(t("サインインURLをコピーしました。"), { variant: "success" });
        });
/*
        let obj = document.getElementById("loginUrl") as HTMLInputElement;
        var textarea = document.createElement('textarea');
        textarea.textContent = obj.value;
        document.body.appendChild(textarea);
    
        var selection = document.getSelection();
        var range = document.createRange();
        range.selectNode(textarea);
        if(selection !== null){
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            enqueueSnackbar(t("サインインURLをコピーしました。"), { variant: "success" });
            selection.removeAllRanges()
        }
        document.body.removeChild(textarea)
*/
    }

    /**
     * registrationID 更新
     */
     const handleRegistrationId = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 32) {
            setSamlIdpSetting({ ...samlIdpSetting, registrationId: event.target.value });
        }
    }

    /**
     * IDプロバイダのEntityID 更新
     */
    const handleChangeEntityId = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 255) {
            setSamlIdpSetting({ ...samlIdpSetting, entityId: event.target.value });
        }
    }

    /**
     * IDプロバイダのログインURL 更新
     */
    const handleChangeSignOnUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 255) {
            setSamlIdpSetting({ ...samlIdpSetting, signOnUrl: event.target.value });
        }
    }

    /**
     * IDプロバイダのログアウトURL 更新
     */
    const handleChangeLogoutUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 255) {
            setSamlIdpSetting({ ...samlIdpSetting, logoutUrl: event.target.value });
        }
    }

    /**
     * 証明書ファイルを指定 (File - onChange)
     */
    const handleChangeIdpCertFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        if (event.target.files.item.length === 0) {
            return;
        }
        setIdpCertFile(event.target.files.item(0));
        //setSamlIdpSetting({ ...samlIdpSetting, signingCertificateFile: event.target.value });
    }

    /**
     * 指定の証明書を削除
     */
    const deleteCertificateKeyPair = (id: number) => {
        samlIdpSetting.certificateKeyPairList?.forEach((certificateKeyPair) => {
            if (certificateKeyPair.id === id) {
                certificateKeyPair.active = !certificateKeyPair.active;
                setUpdateKeyPairs(new Date());
                return;
            }
        });
    }

    const certificateKeyPairComponents = React.useMemo(() => {
        return (
            <List dense={true}>
                {samlIdpSetting.certificateKeyPairList?.map((e) => (
                    <React.Fragment>
                        <ListItem key={e.id} style={{display:'inline-block'}}>
                            {/*<ListItemText>{"active:" + (e.active ? "true" : "false")}</ListItemText>*/}
                            <ListItemText hidden={e.active}>
                                <Chip size='small' label={t("設定を更新して削除")} color="secondary" />
                            </ListItemText>
                            <ListItemText
                                primary={
                                    (e.purpose === 'Signing' ? t('署名証明書') : t('暗号化証明書'))
                                    + ' (' + (e.issuerName
                                        ? e.issuerName
                                        : e.certificateOriginalName + ', ' + e.privateKeyOriginalName)
                                    + ')'}
                                secondary={e.expireDate
                                    ? (t("有効期限：") + Utility.dateToFormatString(new Date(e.expireDate))).replaceAll("-", "/")
                                    : undefined}
                            />
                            <ListItemSecondaryAction>
                                <Tooltip title={<span>{e.active ? t("証明書を削除") : t("削除を取り消し")}</span>} placement={"left"}>
                                    <IconButton edge="end" aria-label="delete" onClick={() => deleteCertificateKeyPair(e.id)}>
                                        {e.active ? <DeleteIcon /> : <RestoreFromTrashIcon />}
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        );
        // 更新のタイミングは、updateKeyPairs で集中管理
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateKeyPairs]);

    const handleOpenCertification = () => {
        setAddCertFile(null);
        setAddKeyFile(null);
        setOpenCertificateDialog(true);
    }

    /**
     * 証明書タイプの切り替え
     */
    const handleChangePurpose = () => {
        if (addPairPurpose === "Signing") {
            setAddPairPurpose("Encryption");
        } else {
            setAddPairPurpose("Signing");
        }
    }

    /**
     * 証明書ファイルを指定 (File - onChange)
     */
    const handleChangeClientCert = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        if (event.target.files.item.length === 0) {
            return;
        }
        setAddCertFile(event.target.files.item(0));
    }

    /**
     * 証明書ファイルを指定 (File - onChange)
     */
    const handleChangeClientKey = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        if (event.target.files.item.length === 0) {
            return;
        }
        setAddKeyFile(event.target.files.item(0));
    }

    /**
     * 証明書ペアの追加
     */
    const addCertificationPair = () => {
        setOpenCertificateDialog(false);

        if (!addCertFile || !addKeyFile) {
            setInfoStatus("error");
            setInfoMessage(t("公開鍵を含む X.509 形式証明書と、公開鍵に対応する秘密鍵をペアで設定してください。"));
            return;
        }

        if (!samlIdpSetting.certificateKeyPairList) {
            samlIdpSetting.certificateKeyPairList = [];
        }

        //let newPair = new SamlCertificateKeyPair();
        let newPair = {} as SamlCertificateKeyPair;
        newPair.id = 0; // id = 0 ... 追加
        newPair.purpose = addPairPurpose;
        newPair.samlIdpSettingId = samlIdpSetting.id;
        newPair.certificateFile = addCertFile;
        newPair.certificateOriginalName = addCertFile.name;
        newPair.privateKeyFile = addKeyFile;
        newPair.privateKeyOriginalName = addKeyFile.name;
        newPair.active = true;
        samlIdpSetting.certificateKeyPairList.push(newPair);
        setUpdateKeyPairs(new Date());
    }

    const handleChangeAuthType = (val: number) => {
        setSamlIdpSetting({ ...samlIdpSetting, authType: val});
    }
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <Grid style={{maxHeight: `calc(100vh - 220px)`, overflow: 'auto'}} container>
                    <Grid item className={classes.gridCell} xs={12}>
                        <FormControl fullWidth>
                            <RadioGroup aria-label="認証方式" name="authType" defaultValue={samlIdpSetting.authType} value={samlIdpSetting.authType} style={{ width: '550px', height: '50px' }} >
                                <FormControlLabel value="0" control={<Radio />} checked={samlIdpSetting.authType === 0 ? true : false} label="パスワード" onChange={() => handleChangeAuthType(0)} />
                                <FormControlLabel value="1" control={<Radio />} checked={samlIdpSetting.authType === 1 ? true : false} label="シングルサインオン(SAML)" onChange={() => handleChangeAuthType(1)} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {samlIdpSetting.authType === 1
                    ?
                        <Fragment>
                            <Grid item className={classes.gridCell} xs={12}>
                                <Typography variant="h6" align='left'>
                                    {t("シングルサインオン設定は、IDプロバイダー側の設定も必要となります。")}<br/>{t("詳細はマニュアルを参照ください。")}
                                    <Grid container className={classes.gridGroup}>
                                        <div className={classes.idpSettingManualDiv}>
                                            <Link id={"link_manual"} href={idpSettingManualURL} target="_blank" color="primary" underline="always">
                                                マニュアル
                                            </Link>
                                        </div>
                                    </Grid>
                                </Typography>
                            </Grid>

                            <Grid item className={classes.gridCell} xs={12}>
                                <TextField fullWidth id="registrationId" required
                                    label={"レジストレーションID"}
                                    value={samlIdpSetting.registrationId}
                                    onChange={handleRegistrationId}
                                    error={inputErrors.filter(e => e === 'registrationId').length > 0}
                                />
                                <FormHelperText id="signing-cert-public-helper-text">{t("お客様を識別するためのIDです。サインインURLの一部として使用されます。")}</FormHelperText>

                            </Grid>

                            <Grid item className={classes.gridCell} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="loginUrl">{t("サインインURL")}</InputLabel>
                                    <Input
                                        id="loginUrl"
                                        type={'text'}
                                        value={loginUrl}
                                        readOnly
                                        disabled
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Tooltip title={"URLをコピー"} placement={"bottom"}>
                                                    <IconButton onClick={handleUrlCopy}>
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.gridHeaderCell} xs={12}>
                                <Typography variant="h4">
                                    <SettingsIcon />&nbsp;{t("IDプロバイダ設定")}
                                </Typography>
                            </Grid>

                            <Grid container className={classes.gridGroup}>
                                <Grid item className={classes.gridCell} xs={12}>
                                    <TextField fullWidth id="idpEntityId" required
                                        label={"IDプロバイダのEntityID"}
                                        value={samlIdpSetting.entityId}
                                        onChange={handleChangeEntityId}
                                        error={inputErrors.filter(e => e === 'entityId').length > 0}
                                    />
                                </Grid>
                                <Grid item className={classes.gridCell} xs={12}>
                                    <TextField fullWidth id="idpSignOnUrl" required
                                        label={"IDプロバイダのログインURL"}
                                        value={samlIdpSetting.signOnUrl}
                                        onChange={handleChangeSignOnUrl}
                                        error={inputErrors.filter(e => e === 'signOnUrl').length > 0}
                                    />
                                </Grid>
                                <Grid item className={classes.gridCell} xs={12}>
                                    <TextField fullWidth id="idpLogoutUrl" required
                                        label={"IDプロバイダのログアウトURL"}
                                        value={samlIdpSetting.logoutUrl}
                                        onChange={handleChangeLogoutUrl}
                                        error={inputErrors.filter(e => e === 'logoutUrl').length > 0}
                                    />
                                </Grid>
                                <Grid item className={classes.gridCell} xs={12}>
                                    <FormControl fullWidth required error={inputErrors.filter(e => e === 'signingCertificateFile').length > 0}>
                                        <InputLabel htmlFor="idpSigningCert">{t("IDプロバイダの署名証明書")}</InputLabel>
                                        <Input
                                            id="idpSigningCert"
                                            type={"text"}
                                            value={idpCertFile ? idpCertFile.name : samlIdpSetting.signingCertificateOriginalName}
                                            required
                                            readOnly
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Tooltip title={"証明書ファイルを指定"} placement={"bottom"}>
                                                        <label htmlFor="idp-cert-private-button">
                                                            <input  accept=".crt, .cer" id="idp-cert-private-button"
                                                                onClick={(event:any) => {
                                                                    event.target.value = null; //キャンセル後、同じファイルを選ぶとonchangeが発火しないためリセットしておく
                                                                    // console.info(event.target.files);
                                                                }}
                                                                type="file" onChange={handleChangeIdpCertFile} hidden />
                                                            <IconButton color="primary" aria-label="open certification file" component="span">
                                                                <FolderOpenIcon />
                                                            </IconButton>
                                                        </label>
                                                    </Tooltip>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.gridHeaderCell} xs={12}>
                                <Typography variant="h4">
                                    <SettingsIcon />&nbsp;{t("アプリケーション設定")}
                                </Typography>
                            </Grid>
                            <Grid container className={classes.gridGroup}>
                                <Grid item className={classes.gridCell} xs={12}>
                                    <Typography variant="h6">
                                        {t("証明書 (X.509 形式)")}
                                        <Tooltip title={"証明書を追加"} placement={"right"}>
                                            <IconButton edge="end" aria-label="add" onClick={handleOpenCertification}>
                                                <AddCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    <Typography variant="caption" className={classes.caption}>
                                        {t("署名証明書を任意で設定する事ができます。")}
                                        {t("署名証明書を登録する場合、公開鍵を埋め込んだ X.509 形式の証明書と秘密鍵を登録してください。")}
                                        {t("X.509 証明書・秘密鍵を作成するには、openssl コマンドを使用して RSA アルゴリズムで生成してください。")}
                                    </Typography>
                                    {certificateKeyPairComponents}
                                </Grid>
                            </Grid>
                            <Grid item className={classes.gridHeaderCell} xs={12}>
                                <div className={classes.updateButtonDiv}>
                                    <Button onClick={handleCancel} color="primary">{t("キャンセル")}</Button>
                                    <Button onClick={() => setOpenConfirmUpdate(true)} variant="text" color="primary">{t("設定を更新する")}</Button>
                                </div>
                            </Grid>

                        </Fragment>
                    :
                        <Fragment>
                            <Grid item className={classes.gridCell} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="loginUrl">{t("サインインURL")}</InputLabel>
                                    <Input
                                        id="loginUrl"
                                        type={'text'}
                                        value={loginUrl}
                                        readOnly
                                        disabled
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Tooltip title={"URLをコピー"} placement={"bottom"}>
                                                    <IconButton onClick={handleUrlCopy}>
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item className={classes.gridHeaderCell} xs={12}>
                                <div className={classes.updateButtonDiv}>
                                    <Button onClick={handleCancel} color="primary">{t("キャンセル")}</Button>
                                    <Button onClick={() => setOpenConfirmUpdate(true)} variant="text" color="primary">{t("設定を更新する")}</Button>
                                </div>
                            </Grid>
                        </Fragment>
                    }
                </Grid>
            </Paper>

            <Backdrop open={isWaiting} style={{zIndex:2000, color:"white"}}>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color="primary" />
                </ThemeProvider>
            </Backdrop>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={infoStatus !== "hide"} onClose={() => { console.log("close") }} >
                <SnackbarContent className={infoStatus === "success" ? classes.success : classes.alert} message={<div>{infoMessage}</div>}
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleCloseAlert}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </Snackbar>

            <Dialog open={openCertificateDialog} fullWidth={false} onClose={() => {setOpenCertificateDialog(false)}}>
                <DialogTitle>
                    <Typography>{t("証明書を追加")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1">{t("公開鍵を含む X.509 形式証明書と、公開鍵に対応する秘密鍵をペアで設定してください。")}</Typography>
                    </DialogContentText>

                    <Grid container>
                        <Grid item className={classes.gridCell} xs={12}>
                            <RadioGroup className={classes.radioGroup} name="purpose"
                                value={addPairPurpose}
                                onChange={handleChangePurpose}
                            >
                                <FormControlLabel value="Signing" control={<Radio />} label={t("署名証明書")} />
                                <FormControlLabel value="Encryption" control={<Radio />} label={t("暗号化証明書")} />
                            </RadioGroup>
                        </Grid>
                        <Grid item className={classes.gridCell} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="signing-cert-public">{t("証明書 (X.509 形式)")}</InputLabel>
                                <Input
                                    id="signing-cert-public"
                                    type={"text"}
                                    value={addCertFile ? addCertFile.name : ""}
                                    required
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <label htmlFor="signing-cert-public-button">
                                                <input accept=".crt, .cer" id="signing-cert-public-button" type="file"
                                                       onInputCapture={() => console.log("onInputCapture")}
                                                       onInvalid={() => console.log("onInvalid")}
                                                       onInvalidCapture={() => console.log("onInvalidCapture")}
                                                       onWaiting={() => console.log("onWaiting")}
                                                       onWaitingCapture={() => console.log("onWaitingCapture")}
                                                       onChange={handleChangeClientCert} hidden />
                                                <IconButton id="select-cert-file" color="primary"
                                                            aria-label="open certification file" component="span">
                                                    <FolderOpenIcon />
                                                </IconButton>
                                            </label>
                                        </InputAdornment>
                                    }
                                    aria-describedby="signing-cert-public-helper-text"
                                />
                                <FormHelperText id="signing-cert-public-helper-text">{t("公開鍵を含む X.509 形式証明書")}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.gridCell} xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="signing-cert-private">{t("秘密鍵")}</InputLabel>
                                <Input
                                    id="signing-cert-private"
                                    type={'text'}
                                    value={addKeyFile ? addKeyFile.name : ""}
                                    required
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <label htmlFor="signing-cert-private-button">
                                                <input accept=".key" id="signing-cert-private-button" type="file"
                                                       onChange={handleChangeClientKey} hidden />
                                                <IconButton id="select-key-file" color="primary"
                                                            aria-label="open private key file" component="span">
                                                    <FolderOpenIcon />
                                                </IconButton>
                                            </label>
                                        </InputAdornment>
                                    }
                                    aria-describedby="signing-cert-private-helper-text"
                                />
                                <FormHelperText id="signing-cert-private-helper-text">{t("公開鍵に対応する秘密鍵")}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={addCertificationPair} color="primary">
                        {t("追加")}
                    </Button>
                    <Button onClick={() => {setOpenCertificateDialog(false)}} color="primary">
                        {t("閉じる")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={false} fullWidth={false} onClose={() => {setOpenCertificateDialog(false)}}>
                <DialogTitle>
                    <Typography>{t("設定更新")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1">{t("設定内容に誤りがあった場合、IDプロバイダのユーザーがサインインできなくなる可能性があります。")}</Typography>
                        <Typography variant="body1">&nbsp;</Typography>
                        <Typography variant="body1">{t("設定内容を更新しますか？")}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        {t("設定を更新する")}
                    </Button>
                    <Button onClick={() => {setOpenConfirmUpdate(false)}} color="primary">
                        {t("キャンセル")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmUpdate} fullWidth={false} maxWidth = {'md'} onClose={() => {setOpenCertificateDialog(false)}}>
                <DialogTitle>
                    <Typography>{t("設定更新")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {(samlIdpSettingBase.authType === samlIdpSetting.authType) /*認証方式変更なし*/
                    ?
                        <Fragment>
                            <div className="Warn" style={{ color: "red" }}>
                                <Typography variant="body2">{t("・設定内容に誤りがあった場合、IDプロバイダのユーザーがサインインできなくなる可能性があります。")}</Typography>
                                <Typography variant="body2">{t("・設定内容に誤りがあった場合、システム管理者がパスワード認証でサインインの上、設定の見直しを行ってください。")}</Typography>
                            </div>
                        </Fragment>
					: 
                        (samlIdpSetting.authType === 1) /*シングルサインオン（SAML）へ変更*/
                        ?
                            <Fragment>
                                <Typography variant="body1">{t("認証方式がシングルサインオン（SAML）へ変更されています。")}</Typography>
                                <div className="Warn" style={{ color: "red" }}>
                                    <Typography variant="body2">{t("・FAMcampusに登録されているシステム管理者以外のユーザーがすべて削除されます。")}</Typography>
                                    <Typography variant="body2">{t("・現在、FAMcampusにサインインしているユーザーはすべてサインアウトされます。")}</Typography>
                                    <Typography variant="body2">{t("・設定内容に誤りがあった場合、IDプロバイダのユーザーがサインインできなくなる可能性があります。")}</Typography>
                                    <Typography variant="body2">{t("・設定内容に誤りがあった場合、システム管理者がパスワード認証でサインインの上、設定の見直しを行ってください。")}</Typography>
                                </div>
                            </Fragment>
                        :
                            /*パスワードへ変更*/
                            <Fragment>
                                <Typography variant="body1">{t("認証方式がパスワードへ変更されています。")}</Typography>
                                <div className="Warn" style={{ color: "red" }}>
                                    <Typography variant="body2">{t("・FAMcampusに登録されているシステム管理者以外のユーザーがすべて削除されます。")}</Typography>
                                    <Typography variant="body2">{t("・現在、FAMcampusにサインインしているユーザーはすべてサインアウトされます。")}</Typography>
                                    <Typography variant="body2">{t("・ユーザー追加もしくはユーザー一括登録でユーザーを再登録する必要があります。")}</Typography>
                                    <Typography variant="body2">{t("・設定内容に誤りがあった場合、システム管理者がパスワード認証でサインインの上、設定の見直しを行ってください。")}</Typography>
                                </div>
                            </Fragment>
                    }
                    <Typography variant="body1">{t("")}</Typography>
                        <Typography variant="body1">&nbsp;</Typography>
                        <Typography variant="body1">{t("設定内容を更新しますか？")}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        {t("設定を更新する")}
                    </Button>
                    <Button onClick={() => {setOpenConfirmUpdate(false)}} color="primary">
                        {t("キャンセル")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
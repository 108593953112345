import React, { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState, useMemo, Fragment, useEffect } from 'react';
import { createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import { SELECT_NONE } from "../common/Constants";

import theme from '../test/Thema';

import { useSnackbar } from 'notistack';

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

const radioButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const useStyles = makeStyles((theme: Theme) => createStyles({
    bold: {
        fontWeight: 'bold'
    },
}));
interface Props {
    websocket: (message: string, target: string, color: string, checkedRingAlarm: boolean) => void,
    user: string,
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    backgroundColor: 'rgba(0, 0, 255, 1)',
};

// 公開したいメソッドの定義
export interface ChildHandler {
    open:( isInMeet:boolean ) => void,
    showNotice:(message:string, color:string, user:string, target:string)=> void,
    allCloseINSnackbar:()　=>　void,
}

const InternalNoticeDialog: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [isOpenConf, setOpenConf] = useState(false);
    const [isOpenWarning, setOpenWarning] = useState(false);
    const [isInRoom, setIsInRoom] = useState(false);
    const [target, setTarget] = useState('Floor');
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('info');
    const [checkedRingAlarm, setCheckedRingAlarm] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [errorMsg, setErrorMsg] = useState('');
    const [disableSendButton, setDissableSend] = useState(false);
    const classes = useStyles();

    const viewMsgRows = 1;                 // snackの表示領域を常に確保する
    const tenantAllSelectable = true;     // テナント全体を選択できるかどうか
    const MeetingroomSelectable = false;    // 会議室を選択できるかどうか
    const MaxRows = 10;                   //最大改行数
    const MaxChars = 100;                 //最大文字数

    useImperativeHandle(ref, () => ({
        // 設定画面オープン
        open: ( isInMeet:boolean ) => {
            // 会議中かどうか
            setIsInRoom(isInMeet);
            setOpen(true);
        },
        // snackbar 表示
        showNotice: (message, color, user, target) => {
            drawSnackbar(message, color, user, target);
        },
        // snackbar をすべて閉じる
        allCloseINSnackbar: () => {
            closeSnackbar();
        }
    }))

    // 連絡の送信
    const sendInternalNotice = () => {
        // props.websocket(
        //     'INTERNAL_NOTICE', {
        //         'message':message, 
        //         'target': target, 
        //         'color': color,
        //         'doRingAlarm': checkedRingAlarm    // 通知音を鳴らすかどうか
        // });
        props.websocket(message, target, color, checkedRingAlarm);
        clearAll();
    }

    useEffect(() => {
        if(message.length === 0 || message.split("\n").length > MaxRows || MaxChars < judgehz(message)){
            setDissableSend(true);
        }else{
            setDissableSend(false);
        }

      }, [message]);

    // 確認後の送信
    const handleSendBeforeCheck = () => {
        // 内容が空の連絡は許可しない
        message.length === 0 ? setOpenWarning(true) : setOpenConf(true);
    }

    // 送信キャンセル
    const handleSendCancel = () => {
        setOpenWarning(false);
        setOpenConf(false);
        setErrorMsg('');
    }

    // 内容の更新
    const handleMsgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    }

    // 送信ターゲットの変更
    const handleRadioChange = (event:any) => {
        setTarget(event.target.value);
    };

    // 通知音のON/OFF変更
    const handleChkboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedRingAlarm(event.target.checked);
    }

    // 初期化（破棄）
    const clearAll = () => {
        setTarget('Floor');
        setMessage('');
        setColor('info');
        setCheckedRingAlarm(false);
        setOpen(false);
        setOpenConf(false);
        setOpenWarning(false);
        setErrorMsg('');
    }

    //messageを作成
    // 連絡の上に名前を出したいとの要望を反映、viewMsgでもprops.userで表示するだけなのでやっていることはあまり変わらない
    const createSnackbarMessage = (message:string, user:string, target:string):JSX.Element[] => {
        const msgAry = message.split(/\n/);
        const htmlmessage = msgAry.map((line) => <Fragment>{line}<br/></Fragment>);

        // 表示領域のサイズを固定する対応
        for(let i=0; i < viewMsgRows-msgAry.length; i++ ){
            htmlmessage.push(<br />);
        }
        // 送信元を追加
        htmlmessage.unshift(<div className={classes.bold}>{user} から {target == 'Floor' ? 'このフロアへ' : '全てのフロアへ'}連絡<br/></div>);
        return htmlmessage;
    }

    //uiルール
    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')
 
        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
 
        return len
    };
 
    const textLength = useMemo(() => {
//        if(inputText === null || inputText === undefined){
//            return String('');
//        }
        let len = judgehz(message)
        if( MaxChars < len ){
            // setDissableSend(true);
            return (
                <div style={{ color:"red" }}>入力した文字列が長すぎます。 </div>
            );
        }else if(message.split("\n").length > MaxRows){
            return (
                <div style={{ color:"red" }}>改行は10行までです。 </div>
            );
        }else if(message.length === 0){
            return (
                <div style={{ color:"red" }}>内容は入力必須です。</div>
            );
        }
    }, [MaxChars, message]);
 
    const progressBar = useMemo(() => {
        let parsent = (judgehz(message)/MaxChars) * 100;
        let progressColor = '#FFE1A2';
 
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
 
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}>連絡内容</div>
                {textLength}
                <Grid style={{ padding: "20px 0px 15px 0px", width: "20%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[message])

    //severityがうまく指定できなかったためこのような処理で妥協
    // 設定画面におけるsnackbarの描画
    const drawAlert = () => {
        switch(color){
            case 'info':
                return(
                    <Alert 
                        variant='filled' 
                        severity='info'
                        icon={false}
                    >
                        {createSnackbarMessage(message, props.user, target)}
                    </Alert>
                )
            case 'warning':
                return(
                    <Alert 
                        variant='filled' 
                        severity='warning'
                        icon={false}
                    >
                        {createSnackbarMessage(message, props.user, target)}
                    </Alert>
                )
            case 'error':
                return(
                    <Alert 
                        variant='filled' 
                        severity='error'
                        icon={false}
                    >
                        {createSnackbarMessage(message, props.user, target)}
                    </Alert>
                )
            case 'success':
                return(
                    <Alert 
                        variant='filled' 
                        severity='success'
                        icon={false}
                    >
                        {createSnackbarMessage(message, props.user, target)}
                    </Alert>
                )
        }

    }

    // 画面上へのsnackbarの描画
    const drawSnackbar = (mess:string, col:string, user: string, target: string) => {
        const htmlMessage = createSnackbarMessage(mess, user, target);
        switch(col){
            case 'info':
                enqueueSnackbar(
                    <span>{htmlMessage}</span>, { 
                        variant: 'info', 
                        persist: true, 
                        action: key => (<IconButton onClick={()=>{closeSnackbar(key)}}><CloseIcon/></IconButton>)});
                break;
            case 'warning':
                enqueueSnackbar(
                    <span>{htmlMessage}</span>, {
                        variant: 'warning', 
                        persist: true, 
                        action: key => (<IconButton onClick={()=>{closeSnackbar(key)}}><CloseIcon/></IconButton>) });
                break;
            case 'error':
                enqueueSnackbar(
                    <span>{htmlMessage}</span>, { 
                        variant: 'error', 
                        persist: true, 
                        action: key => (<IconButton onClick={()=>{closeSnackbar(key)}}><CloseIcon/></IconButton>) });
                break;
            case 'success':
                enqueueSnackbar(
                    <span>{htmlMessage}</span>, { 
                        variant: 'success', 
                        persist: true, 
                        action: key => (<IconButton onClick={()=>{closeSnackbar(key)}}><CloseIcon/></IconButton>) });
                break;
        }
    }

    // 送信先テナントの表示有無
    const drawSendToTenant = useMemo(() => {
        if( tenantAllSelectable ){
            return(<FormControlLabel value='Internal' control={<Radio color='primary' />} label='全てのフロア' />);
        }else{
            return;
        }
    // eslint-disable-next-line
    },[]); 
    
    // 送信先会議室の表示有無
    const drawSendToMeetingRoom = useMemo(() => {
        if( MeetingroomSelectable ){
            // 会議室にいるかどうかで有効無効を設定
            return(<FormControlLabel value='Meeting' disabled={!isInRoom} control={<Radio color='primary' />} label='会議室' />);
        }else{
            return;
        }
    // eslint-disable-next-line
    },[isInRoom]);

    // 設定ダイアログ
    const drawDialog = useMemo(() =>{
        return(
            <Dialog 
                open={isOpen}
                onClose={clearAll}
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle>一斉連絡</DialogTitle>
                <DialogContent>
            
                    <DialogContentText style={{ marginBottom: "0px" }} >
                        {progressBar}
                    </DialogContentText>

                    <TextField 
                        id='notice-message' 
                        type='text'
                        value={message}
                        label="連絡内容"
                        onChange={handleMsgChange}
                        multiline
                        rows={5}
                        rowsMax={10}
                        fullWidth
                        variant='outlined'
                    />
                    <br/>
                    <Divider variant='middle' />
                    <br/>
                    <Fragment>
                        <DialogContentText>連絡対象フロア</DialogContentText>
                        <FormControl component='fieldset'>
                            <ThemeProvider theme={radioButtonTheme}>
                                <RadioGroup aria-label='target' name='target' value={target} onChange={handleRadioChange}>
                                    {drawSendToTenant}
                                    <FormControlLabel defaultChecked value='Floor' control={<Radio color='primary' />} label='このフロア' />
                                    {drawSendToMeetingRoom}
                                </RadioGroup>
                            </ThemeProvider>
                        </FormControl>
                        <br/>
                    </Fragment>
                    <br/>
                    <div>
                        <DialogContentText>背景色</DialogContentText>
                        <IconButton onClick={()=>{setColor('info');}}>
                            <Brightness1Icon style={{color: theme.palette.info.main}}/>
                        </IconButton>
                        <IconButton onClick={()=>{setColor('success');}}>
                            <Brightness1Icon style={{color: theme.palette.success.main}}/>
                        </IconButton>
                        {/* 青と緑のみに変更*/}
                    </div>
                    <DialogContentText>通知のプレビュー</DialogContentText>
                    {drawAlert()}
                    <br/>
                    <div>
                        <FormControlLabel
                            control={
                                <ThemeProvider theme={checkBoxTheme}>
                                    <Checkbox checked={checkedRingAlarm} onChange={handleChkboxChange} color='primary'/>
                                </ThemeProvider>
                            }
                            label={<Box component='div' >通知音を鳴らす</Box>}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleSendBeforeCheck()}} disabled={disableSendButton} color='primary'>
                        送信　
                    </Button>
                    <Button onClick={()=>{
                        clearAll();
                        setOpen(false);}} color='primary'>
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        )
    // eslint-disable-next-line
    }, [isOpen, message, target, color, checkedRingAlarm, disableSendButton])

    // 送信先確認メッセージ
    const drawSendTargetMsg = useMemo(() => {
        if( target==='Internal' ){
            return('対象:全てのフロア');
        }else if(target==='Floor'){
            return('対象:このフロア');
        }else{
            return('対象:会議室');
        }
    }, [target]);

    // 通知音確認メッセージ
    const drawSendRingChimeMsg = useMemo(() => {
        if( checkedRingAlarm ){
            return '連絡時に通知音を鳴らす';
        }else{
            return;
        }
    },[checkedRingAlarm]);

    // 送信前確認ダイアログ
    const drawConfirmationDialog = useMemo(() =>{
        return(
            <Dialog 
                open={isOpenConf}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>一斉連絡</DialogTitle>
                <DialogContent>
                    <DialogContentText>以下の内容で送信します。よろしいですか？</DialogContentText>
                    {drawAlert()}<br/>
                    {drawSendTargetMsg}<br/>
                    {drawSendRingChimeMsg}<br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={sendInternalNotice} color='primary'>
                        送信
                    </Button>
                    <Button onClick={()=>{handleSendCancel()}} color='primary'>
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        )
    // eslint-disable-next-line
    },[isOpenConf])

    // 送信warningダイアログ
    const drawWaringDialog = useMemo(() =>{
        return(
            <Dialog 
                open={isOpenWarning}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>一斉連絡</DialogTitle>
                <DialogContent>
                    <DialogContentText>内容は入力必須です。</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleSendCancel()}} color='primary'>
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        )
    },[isOpenWarning])

    return (
        <Fragment>
            {drawConfirmationDialog}
            {drawWaringDialog}
            {drawDialog}
        </Fragment>
    )
}

export const InternalNoticeDialogRef = forwardRef(InternalNoticeDialog);
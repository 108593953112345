/**
 * 「フロア内人数」ボタン押下時点のフロア内人数を表示するダイアログ
 */

import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useState, useMemo, useEffect } from 'react';
import { makeStyles, Theme, createStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import BaseDialog from '../user/BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import axios from 'axios';
import { Office as resOffice } from '../common/JsonClass';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Utility } from '../common/Utility';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            height: '100%',
            maxHeight: 480,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

// API Response
interface resFloor {
    id: number;
    floorName: string;
}

// UI表示用
class Office {
    id: number = 0;
    officeName: string = "";
    floorList: Floor[] = [];
    open: boolean = true;
}

class Floor {
    id: number = 0;
    floorName: string = "";
    participants?: number = 0;
}

interface Props {
}

export interface TestParticipantsDialogHandler {
    open: () => void;
}

const TestParticipantsDialogComponent: ForwardRefRenderFunction<TestParticipantsDialogHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [officeList, setOfficeList] = useState([] as Office[]);
    const [errorMessage, setErrorMessage] = useState("");

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
    }))

    const handleClick = (id: number) => {
        let office = officeList.find(e => e.id === id);
        if (!office || !office.floorList) return;

        office.open = !office.open;
        setOfficeList([...officeList]);
    };

    const handleClickRefresh = () => {
        setOfficeList([]);
        getData()
            .then(res => {
                const _officeList = res.sort((a, b) => a.id > b.id ? 1 : -1);
                setOfficeList([..._officeList]);
            }).catch(err => {
                console.log(err);
                setErrorMessage("データ取得に失敗しました。");
                setOfficeList([]);
            })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getFloorList = (officeId: number) => {
        const params = new URLSearchParams();
        params.append("office_id", officeId.toString());
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        return axios.post('/api/user/floor/list/get', params)
    }

    const getNumber = (floorId: number) => {
        const params = new URLSearchParams();
        params.append("floor_id", `${floorId}`);
        return axios.post('/api/user/floor/participants/number/get', params);
    }

    const getData = async () => {
        try {
            const res1 = await Utility.requestOfficeList();
            const officeListData = res1 as resOffice[];

            // UI表示用のofficeデータを生成
            const promiseArray = officeListData.map(async (office) => {
                const res = await getFloorList(office.id);
                const floorListData = res.data as resFloor[];
                return setFloorToOffice(office, floorListData);
            });
            return Promise.all(promiseArray) as Promise<Office[]>;
        } catch (err) {
            return Promise.reject(err);
        }
    }


    /**
     * webAPIから取得したオフィス・フロアデータから、UI表示用のofficeデータを生成する。
     * 
     * @param resOffice webAPIから取得したオフィスデータ
     * @param floorList webAPIから取得したフロアデータ
     */
    async function setFloorToOffice(resOffice: resOffice, floorList: resFloor[]): Promise<Office> {
        const office = new Office();
        office.id = resOffice.id;
        office.officeName = resOffice.officeName;

        if (floorList.length > 0) {
            const promiseArray = floorList.map(async (floor) => {
                const res = await getNumber(floor.id);
                return { ...floor, participants: Number(res.data) }
            });
            const _floorList = await Promise.all(promiseArray);
            office.floorList = _floorList.sort((floor1, floor2) => {
                if (floor1.id > floor2.id) return 1;
                if (floor1.id < floor2.id) return -1;
                return 0;
            });
        }
        return office;
    }

    useEffect(() => {
        let unmounted = false;
        setErrorMessage("");

        if (open) {
            getData()
                .then(res => {
                    if (!unmounted) {
                        const _officeList = res.sort((a, b) => a.id > b.id ? 1 : -1);
                        setOfficeList([..._officeList]);
                    }
                }).catch(err => {
                    console.log(err);
                    setErrorMessage("データ取得に失敗しました。");
                    if (!unmounted) setOfficeList([]);
                })
        } else {
            setOfficeList([]);
        }

        return () => { unmounted = true }; // cleanup
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const drawOfficeList = () => {
        return officeList.map((office) => {
            return (
                <Fragment key={`office_${office.id}`} >
                    <ListItem button onClick={() => handleClick(office.id)}>
                        <ListItemText primary={office.officeName} />
                        {office.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={office.open} timeout="auto" unmountOnExit>
                        {drawFloorList(office.floorList)}
                    </Collapse>
                </Fragment>
            );
        })
    }

    const numberText = (number?: number) => {
        return <span><strong style={{ fontSize: "1.2rem" }}>{number}</strong><small>人</small></span>
    }

    const drawFloorList = (floorList: Floor[]) => {
        if (!floorList) return;
        return floorList.map((floor) => {
            return (
                <List key={floor.id} component="div" disablePadding>
                    <ListItem className={classes.nested}>
                        <ListItemText primary={floor.floorName} />
                        <ListItemText primary={numberText(floor.participants)} style={{ textAlign: "right" }} />
                    </ListItem>
                </List>
            );
        });
    }

    const drawOffice = useMemo(() => {
        return officeList.length === 0 && errorMessage.length === 0
            ? (
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color='primary' />
                </ThemeProvider>
            )
            : (
                <List component="nav" className={classes.root}>
                    {drawOfficeList()}
                </List>
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeList, errorMessage]);


    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="display-participants-dialog-title"
                aria-describedby="display-participants-dialog-description"
            >
                <DialogTitle id="display-participants-dialog-title">
                    フロア内人数
                    <IconButton size="small" onClick={handleClickRefresh}>
                        <RefreshIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="display-participants-dialog-description" align="center">
                        {errorMessage.length > 0 && <div style={{ color: 'red' }}>{errorMessage}</div>}
                        {drawOffice}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, officeList, errorMessage])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const TestParticipantsDialog = forwardRef(TestParticipantsDialogComponent);
/**
 * WebRtcModeを表示するダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../user/BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    webRtcMode: number;
    getUseTurnServer: () => boolean | null;
}

export interface TestDisplayWebRtcModeDialogHandler {
    open: () => void;
}

const modes = [
    { mode: 0, text: "Meshを使用せず、すべての通話がSFU" },
    { mode: 1, text: "ユーザー同士の接触がMesh" },
    { mode: 2, text: "会議室がMesh" },
    { mode: 3, text: "ユーザー同士の接触、会議室がMesh" },
    { mode: 4, text: "全体放送がMesh" },
    { mode: 5, text: "ユーザー同士の接触、全体放送がMesh" },
    { mode: 6, text: "会議室、全体放送がMesh" },
    { mode: 7, text: "ユーザー同士の接触、会議室、全体放送がMesh" },
]

const TestDisplayWebRtcModeDialogComponent: ForwardRefRenderFunction<TestDisplayWebRtcModeDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = React.useState(false);
    //const [useTurnSrever, setUseTurnServer] = React.useState<boolean | null>(null);

    const modeInfo = useMemo(() => {
        const mode = modes.find(m => m.mode === props.webRtcMode);
        return mode || { mode: -1, text: "webRtcModeがありません。" }
    }, [props.webRtcMode]);

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
            //setUseTurnServer(props.getUseTurnServer());
        },
    }))

    const handleClose = () => {
        setOpen(false);
    };

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="display-webrtcmode-dialog-title"
                aria-describedby="display-webrtcmode-dialog-description"
            >
                <DialogTitle id="display-webrtcmode-dialog-title">WebRtc情報</DialogTitle>
                <DialogContent>
                    <DialogContentText id="display-webrtcmode-dialog-description">
                        <table>
                            <tbody>
                                <tr><th colSpan={2} align="left">Floor</th></tr>
                                <tr><th align="right">mode:</th><td>{modeInfo.mode}</td></tr>
                                <tr><th align="right">説明:</th><td>{modeInfo.text}</td></tr>
                                {/* <tr><th colSpan={2} align="left">User</th></tr>
                                <tr><th align="right">useTurnServer:</th><td>{useTurnSrever}</td></tr> */}
                            </tbody>
                        </table>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, modeInfo, /* useTurnSrever */])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const TestDisplayWebRtcModeDialog = forwardRef(TestDisplayWebRtcModeDialogComponent);
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import PeopleIcon from '@material-ui/icons/People';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import TimelineIcon from '@material-ui/icons/Timeline';
import { FloorSummary } from '../common/JsonClass';

interface Props {
}

export interface SystemFloorInfoHandler {
    open: (id: number) => void;
}

// export default function OtherUsers() {
const SystemFloorInfoComponent: ForwardRefRenderFunction<SystemFloorInfoHandler, Props> = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [selectFloorId, setSelectFloorId] = useState(0);
    const [floorSummary, setFloorSummary] = useState(new FloorSummary());
    const [showWebRtcTotalBytes, setShowWebRtcTotalBytes] = useState("");
    const [showWebRtcTotalBytesP2P, setShowWebRtcTotalBytesP2P] = useState("");

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id);
            // alert("selId:" + selectFloorId);
            if(selectFloorId === id){
                handleGetFloorSummaryById();
            }else{
                setSelectFloorId(id);
            }
            setOpen(true);
        },
    }))

    useEffect(() => {
        // サーバーからデータを取得して表示する
        // alert("selId:" + selectFloorId);    
        if(selectFloorId > 0){
            // alert("selId:" + selectFloorId);
            handleGetFloorSummaryById();
        }
    },[selectFloorId])

    const handleGetFloorSummaryById = () => {
        // alert("selectfloor:" + selectFloorId);
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floor/summary/select', new URLSearchParams({
            // .post('/api/system/floor/summary', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                floorId: selectFloorId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as FloorSummary;
                setFloorSummary(result);
                handleConvUnit(result.webRtcTotalBytesPerTenant.toString(), result.webRtcTotalBytesP2PPerTenant.toString());
            })
            .catch(() => {
                console.log("GetFloorSummary error.");
            });
    }

    const handleConvUnit = (strChkBytesStr: string, strChkP2PBytesP2PStr: string) => {
        // webRtc通信量の表示単位を桁数によって変更する
        let byteStr = "";
        let byteDecimal = "";
        let byteP2PStr = "";
        let byteP2PDecimal = "";

        // alert("strChkBytesStr:" + strChkBytesStr);
        // alert("strChkP2PBytesP2PStr:" + strChkP2PBytesP2PStr);
        if( strChkBytesStr.length >= 10){
            // 10桁以上ならGBで表示
            byteStr = strChkBytesStr.substring(0, (strChkBytesStr.length-9) );
            byteDecimal = "." + strChkBytesStr.substring((strChkBytesStr.length-9), (strChkBytesStr.length-9)+1 ) + "GB";
        } else if( strChkBytesStr.length >= 7 && strChkBytesStr.length < 10){
            // 7桁以上10桁未満ならMBで表示
            byteStr = strChkBytesStr.substring(0, (strChkBytesStr.length-6) );
            byteDecimal = "." + strChkBytesStr.substring((strChkBytesStr.length-6), (strChkBytesStr.length-6)+1 ) + "MB";
        } else if( strChkBytesStr.length >= 4 && strChkBytesStr.length < 7){
            // 4桁以上7桁未満ならKBで表示
            byteStr = strChkBytesStr.substring(0, (strChkBytesStr.length-3) );
            byteDecimal = "." + strChkBytesStr.substring((strChkBytesStr.length-3), (strChkBytesStr.length-3)+1 ) + "KB";
        } else if( strChkBytesStr.length >= 1){
            // 4桁未満ならByteで表示
            byteStr = strChkBytesStr + "Byte";
        }else{
            byteStr = "0Byte";
        }

        if( strChkP2PBytesP2PStr.length >= 10){
            // 10桁以上ならGBで表示
            byteP2PStr = strChkP2PBytesP2PStr.substring(0, (strChkP2PBytesP2PStr.length-9) );
            byteP2PDecimal = "." + strChkP2PBytesP2PStr.substring((strChkP2PBytesP2PStr.length-9), (strChkP2PBytesP2PStr.length-9)+1 ) + "GB";
        } else if( strChkP2PBytesP2PStr.length >= 7 && strChkP2PBytesP2PStr.length < 10){
            // 7桁以上10桁未満ならMBで表示
            byteP2PStr = strChkP2PBytesP2PStr.substring(0, (strChkP2PBytesP2PStr.length-6) );
            byteP2PDecimal = "." + strChkP2PBytesP2PStr.substring((strChkP2PBytesP2PStr.length-6), (strChkP2PBytesP2PStr.length-6)+1 ) + "MB";
        } else if( strChkP2PBytesP2PStr.length >= 4 && strChkP2PBytesP2PStr.length < 7){
            // 4桁以上7桁未満ならKBで表示
            byteP2PStr = strChkP2PBytesP2PStr.substring(0, (strChkP2PBytesP2PStr.length-3) );
            byteP2PDecimal = "." + strChkP2PBytesP2PStr.substring((strChkP2PBytesP2PStr.length-3), (strChkP2PBytesP2PStr.length-3)+1 ) + "KB";
        } else if( strChkP2PBytesP2PStr.length >= 1){
            // 4桁未満ならByteで表示
            byteP2PStr = strChkP2PBytesP2PStr + "Byte";
        }else{
            byteP2PStr = "0Byte";
        }
        byteStr = byteStr + byteDecimal;
        byteP2PStr = byteP2PStr + byteP2PDecimal;

        setShowWebRtcTotalBytes(byteStr);
        setShowWebRtcTotalBytesP2P(byteP2PStr);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleReleaseFloor = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floor/release', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                floor_id: selectFloorId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                console.log(response.data);
            })
            .catch(() => {
                console.log("GetFloorSummary error.");
            });
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'md'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>テナント情報</DialogTitle>
                <DialogContent style={{ padding: "30px 20px" }}>
                    <Box margin="0px 0px 20px 10px" fontSize="18px"><b>フロアID：{selectFloorId}　　フロア名：{floorSummary.floorName}</b></Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <ScheduleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">計測日時</Typography>
                                        <Typography variant="subtitle1" ><Box fontSize="16px" textAlign="right">{floorSummary.monitoringTime}</Box></Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%" }}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">在室ユーザ数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right">{floorSummary.sessionCount}</Box></Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%" }}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>

                        
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">出勤者数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{floorSummary.commutingCount}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">勤務外者数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{floorSummary.unCommutingCount}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">WebRTC使用数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{floorSummary.webRtcCount}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">WebRTC使用人数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{floorSummary.webRtcAllUserCount}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">使用中の会議室数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{floorSummary.webRtcUsedRoomCount}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <PeopleIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">空き会議室数</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{floorSummary.webRtcFreeRoomCount}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <SwapVertIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">WebRTC通信量</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{showWebRtcTotalBytes}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper style={{ width: "100%", height: "80px" }}>
                                <div style={{ padding: "10px", display: "flex" }}>
                                    <SwapVertIcon color="primary" fontSize="large" style={{ margin: "10px" }} />
                                    <div style={{ marginTop: "10px", width: "1200px" }}>
                                        <Typography variant="subtitle2">WebRTCP2P通信量</Typography>
                                        <Typography variant="subtitle1"><Box fontSize="16px" textAlign="right" >{showWebRtcTotalBytesP2P}</Box>
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: "right", width: "100%"}}>
                                        <IconButton style={{ top: "5px" }}>
                                            <TimelineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="secondary" onClick={handleReleaseFloor}>全員退室</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">閉じる</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export const SystemFloorInfo = forwardRef(SystemFloorInfoComponent);
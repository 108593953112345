import React, { useState, useMemo, Fragment, useEffect, useImperativeHandle, forwardRef, ForwardRefRenderFunction } from 'react';

import axios from 'axios';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Typography } from '@material-ui/core';
import AudioPlayer, { AudioId } from './AudioPlayer';
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    requestDialogOpen :boolean;
    handlePrivacyRoomDialog : (on:boolean) => void;
    requestTopDialogOpen :boolean;
    handleTopPrivacyRoomDialog : (on:boolean) => void;
    floorId :number;
    onMoveFloor : (id:number) => void;
    onJumpToMySeat : () => void;
    onStateChange: (state: number, enableMeet: boolean) => void;
    requestPrivacyRoom: (userId: number, floorId: number, targetFloorId:number) => void;
    sendPrivacyRoomTimeOut: (kind:number, floorId:number) => void;
    sendMovePrivacyRoomInfo: (stayPrivacyroom: boolean, preFloorId: number, preFloorName: string) => void;
    }

// 公開したいメソッドの定義
export interface PrivacyRoomDialogHandler {
    open: () => void;
    close: () => void;
    permissionRequest: (floorId:number) => void;
    leave: (preFloorId: number) => void;
    extend: () => void;
}
interface privacyRoomInfo {
    id: number;
    subId: string;
    preFloorId: number;
    preFloorName: string;
    floorId: number;
    stayPrivacyroom: boolean;
    waitForPermit: boolean;
}
const PrivacyRoomDialog: ForwardRefRenderFunction<PrivacyRoomDialogHandler, Props> = (props, ref) => {
    const [showWaitingForApprovalDialog, setShowWaitingForApprovalDialog] = useState(false);
    const [showNotReadyDialog, setshowNotReadyDialog] = useState(false);
    const [showLeaveRoomDialog, setShowLeaveRoomDialog] = useState(false);
    const [count,setCount] = useState(0);
    const [preFloorId, setPreFloorId] = useState(0);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        open: () => {
            setShowWaitingForApprovalDialog(false);
            setshowNotReadyDialog(true);
        },
        close: () => {
            setShowWaitingForApprovalDialog(false);
        },
        extend: () => {
            setShowWaitingForApprovalDialog(false);
            setShowWaitingForApprovalDialog(true);
        },
        leave: (preFloorId: number) => {
            setPreFloorId(preFloorId);
            setShowLeaveRoomDialog(true);
        },
        permissionRequest: (floorId:number) => {
            openWaitingForApprovalDialog(floorId);
        },
    }))

    const getAdminUserList = (id: number) => {
        // const params = new URLSearchParams();
        // params.append("floor_id", id.toString());
        // return axios.post('/api/user/roleadmin/list/get', params);
        return httpClient.getRoleAdminPrivacyRoomInfo(id, sessionStorage.getItem("TABID") as string);
    }

    const openWaitingForApprovalDialog = async (floorId: number) => {
        props.handlePrivacyRoomDialog(false);

        const res = await getAdminUserList(floorId);
        const privacyInfoList = res as privacyRoomInfo[];

        if(privacyInfoList.length < 1 || privacyInfoList[0]?.waitForPermit){
            setshowNotReadyDialog(true);
        }else{

            //申請を送る
            props.requestPrivacyRoom(floorId,0,0); // wsでバックエンドにアクションを送る -> アクションを受け取った管理者ユーザに申請許可ダイアログが出る
            setShowWaitingForApprovalDialog(true);
        }

    }

    const moveFloor = () => {
        props.onMoveFloor(props.floorId);
        setShowWaitingForApprovalDialog(false);
    }

    const timeOutDialogCancel = () => {
        props.sendPrivacyRoomTimeOut(20,props.floorId);
        setShowWaitingForApprovalDialog(false);
    }
    
    useEffect(() => {
        if (!showWaitingForApprovalDialog) return;
        let temp = 20;
        console.log("応答確認のカウントダウン開始（"+temp+"秒）");
        const interval = setInterval(() => {
            temp--;
            setCount(temp);
            if (temp === 0) {
                clearInterval(interval);
                setShowWaitingForApprovalDialog(false);

                //タイムアウト処理
                //　→申請の取りやめのためのws＆「先生の準備ができていない」旨のダイアログ表示
                props.sendPrivacyRoomTimeOut(10,props.floorId);
                props.onStateChange(0, true);
                setshowNotReadyDialog(true);
            }
            //AudioPlayer.play(AudioId.TimeLimit);
        }, 1000)
        return function cleanUp() {
            clearInterval(interval);
        }
    }, [showWaitingForApprovalDialog]);

    useEffect( () => {
        if (!showLeaveRoomDialog) return;
        let temp = 10;
        console.log("応答確認のカウントダウン開始（"+temp+"秒）");
        const interval = setInterval(() => {
            temp--;
            setCount(temp);
            if (temp === 0) {
                clearInterval(interval);
                //タイムアウト処理
                setShowLeaveRoomDialog(false);
                leavePrivacyRoom();
            }
            AudioPlayer.play(AudioId.TimeLimit);
        }, 1000)
        return function cleanUp() {
            clearInterval(interval);
        }
    }, [showLeaveRoomDialog]);

    // フロア移動(同期)
    const syncHandleMoveFloor = async (id:number) => {
        props.onMoveFloor(id);
        return true;
    }

    const leavePrivacyRoom = async () => {
        await syncHandleMoveFloor(preFloorId);
        setShowLeaveRoomDialog(false);
    }

    /*const drawRequestDialog = useMemo(() => {
        return (
            <BaseDialog
                id="requestDialog"
                open={props.requestDialogOpen}
                aria-labelledby="requestDialogTitle"
            >
                <DialogTitle id="requestDialogTitle"></DialogTitle>
                <DialogContent>入室の許可を求めますか？</DialogContent>
                <DialogActions>
                    <Button onClick={() => { openWaitingForApprovalDialog() }} color="primary" style={{ pointerEvents: 'auto' }} >はい</Button>
                    <Button onClick={() => { props.handlePrivacyRoomDialog(false) }} color="primary" style={{ pointerEvents: 'auto' }} >いいえ</Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [props.requestDialogOpen])*/

    const drawWaitingForApprovalDialog = useMemo(() => {
        return (
            <BaseDialog
                id="waitingForApprovalDialog"
                open={showWaitingForApprovalDialog}
                aria-labelledby="waitingForApprovalDialogTitle"
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="waitingForApprovalDialogTitle"></DialogTitle>
                <DialogContent>先生の入室許可を待っています</DialogContent>
                <DialogActions>
                    <Button onClick={timeOutDialogCancel} color="primary" style={{ pointerEvents: 'auto' }} >キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [showWaitingForApprovalDialog,count])

    const drawNotReadyDialog = useMemo(() => {
        return (
            <BaseDialog
                id="notReadyDialog"
                open={showNotReadyDialog}
                aria-labelledby="notReadyDialogTitle"
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="notReadyDialogTitle"></DialogTitle>
                <DialogContent>先生の準備がまだできていないので入れません</DialogContent>
                <DialogActions>
                    <Button onClick={() => { setshowNotReadyDialog(false) }} color="primary" style={{ pointerEvents: 'auto' }} >閉じる</Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [showNotReadyDialog])

    const drawLeaveRoomDialog = useMemo(() => {
        return (
            <BaseDialog
                id="leaveRoomDialog"
                open={showLeaveRoomDialog}
                aria-labelledby="leaveroom-dialog-title"
                aria-describedby="leaveroom-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="leaveroom-dialog-title">{"一斉退室"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="privacyroompermission-dialog-description">
                        <Typography>{"先生が退室したので、元のフロアに戻ります。"}</Typography>
                        <Typography>{"※" + 10 + "秒で自動的に退室します。"}</Typography>
                        <div style={{width: "100%", textAlign: "right"}}>{"残り " + count + "秒"}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { leavePrivacyRoom() }} color="primary" style={{ pointerEvents: 'auto' }} >今すぐ退室する</Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [count, showLeaveRoomDialog])

    const drawTopDialog = useMemo(() => {
        return (
            <BaseDialog
                id="requestDialog"
                open={props.requestTopDialogOpen}
                aria-labelledby="topDialogTitle"
            >
                <DialogTitle id="topDialogTitle"></DialogTitle>
                <DialogContent>直接、面談室には入れません</DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.handleTopPrivacyRoomDialog(false) }} color="primary" style={{ pointerEvents: 'auto' }} >閉じる</Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [props.requestTopDialogOpen])

    return (
        <Fragment>
            {/*drawRequestDialog*/}
            {drawTopDialog}
            {drawWaitingForApprovalDialog}
            {drawNotReadyDialog}
            {drawLeaveRoomDialog}
        </Fragment>
    )
}

export const PrivacyRoomDialogRef = forwardRef(PrivacyRoomDialog);

import React, { useEffect, useMemo } from 'react';
import { Utility } from '../webrtc/Utility';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial, { SpeedDialProps } from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction, { SpeedDialActionProps } from '@material-ui/lab/SpeedDialAction';

import SidemenuHello from '../img/ic_morenote_grey.svg'
import { SELECT_NONE } from "../common/Constants";

const SidemenuHelloIcon = () => {
    return <img src={SidemenuHello} width="24px" height="24px" alt="" />
}

const useStyles = makeStyles((theme) => ({
    speedDialDiv: {
        ...SELECT_NONE,
        transform: 'translateZ(0px)',
        flexGrow: 1,
        position: 'absolute',
        cursor: 'pointer'
    },
    speedDial: {
        ...SELECT_NONE,
        position: 'fixed',
    }
}));

interface Props {
    open: boolean;
    top: number;
    left: number;
    pcHeight: number;
    pcWidth: number;
    onClickMoreNote: (isWhiteBoard: boolean) => void;
    zIndex: number;
}

export default function PcMenu(props: Props) {

    // メインメニュー
    const mainMenu = [
        {
            key: "pcMenuMoreNoteOpen",
            icon: <SidemenuHelloIcon />,
            text: 'ホワイトボードを共有',
            onClick: () => { props.onClickMoreNote(true) },
            disabled: false
        },
        {
            key: "pcMenuMoreNoteOpen",
            icon: <SidemenuHelloIcon />,
            text: '資料を共有',
            onClick: () => { props.onClickMoreNote(false) },
            disabled: false
        }
    ];

    const classes = useStyles();
    const [menu] = React.useState(mainMenu);
    const [direction, setDirection] = React.useState<SpeedDialProps['direction']>('up');
    const [placement, setPlacement] = React.useState<SpeedDialActionProps['tooltipPlacement']>('right');

    const TOP = React.useMemo(() => {
        const wrapper = document.getElementById("floorWrapper") as HTMLDivElement;
        const ICON_SIZE = 56;
        const MARGIN = (props.pcHeight - ICON_SIZE) / 2;
        const MENU_HEIGHT = (ICON_SIZE * (menu.length - 1));

        // 上部の余白
        let topSpace = props.top - wrapper.scrollTop;

        // メニューが上部に入りきらない場合、下に出す
        if (topSpace < MENU_HEIGHT) {
            topSpace = props.top - (topSpace);
            setDirection('down');
            return topSpace;
        }
        return props.top + MARGIN - MENU_HEIGHT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.top]);

    const LEFT = React.useMemo(() => {
        const wrapper = document.getElementById("floorWrapper") as HTMLDivElement;
        const MARGIN = 5;
        const rightSpace = wrapper.offsetWidth - props.left;
        const leftSpace = props.left - wrapper.scrollLeft;

        // 右の方がスペースがあれば右に出す
        if (rightSpace > leftSpace) {
            return props.left + props.pcWidth;
        }
        // そうじゃければ左に出す
        setPlacement("left");
        return props.left - MARGIN - props.pcWidth / 2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.left, menu]);

    useEffect(() => {
        const target = document.getElementById("PcMenuDiv") as HTMLDivElement;
        target.addEventListener('pointerdown', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('touchmove', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('pointermove', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('pointerup', (e) => Utility.forbiddenTattle(e), false);

        return function cleanup() {
            target.removeEventListener('pointerdown', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('touchmove', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('pointermove', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('pointerup', (e) => Utility.forbiddenTattle(e), false);
        }
    }, [])

    const draw = useMemo(() => {
        if (menu == null) return;
        return (
            <div id="PcMenuContent" className={classes.speedDialDiv} style={{ zIndex: props.zIndex }}>
                <SpeedDial
                    style={{ left: LEFT, top: TOP }}
                    ariaLabel="SpeedDial tooltip"
                    className={classes.speedDial}
                    hidden={true}
                    icon={<SpeedDialIcon />}
                    open={props.open}
                    direction={direction}
                >
                    {menu.map((action) => (
                        <SpeedDialAction
                            key={action.key}
                            icon={action.icon}
                            tooltipTitle={action.text}
                            tooltipOpen={true}
                            tooltipPlacement={placement}
                            // tooltipPlacement="left"
                            onClick={action.onClick}
                            style={{ ...SELECT_NONE }}
                        />
                    ))}
                </SpeedDial>
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.top, props.left, direction, placement, menu, TOP, LEFT]);

    return (
        <div id="PcMenuDiv">
            {draw}
        </div>
    )
}

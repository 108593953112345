/**
 * 自習変更ダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { SELECT_NONE } from "../common/Constants";
import BaseDialog from './BaseDialog';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, InputAdornment, LinearProgress, IconButton, Switch, createMuiTheme, Divider } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Utility } from '../common/Utility';
import JfsClient, { StudyObjectSetting, StudyPlan, StudyPlanDetail, StudyPurposeMasterInfo, StudySubjectMasterInfo } from '@fsi/jfs-sdk';

interface Props {
    handleMyStudyTimerStart: (timerMinutes: number) => void;
    handleStudyTimerStart: (startTime: number, endTime: number) => void; // 唯一秒単位
    handleMyStudyTimerState: (state: boolean, additionalTime: number) => void;
    handleGetStudyCount: () => number;
    handleGetStudyTime: () => number;
    handleGetStudyEnd: () => boolean;
    studyObjectSetting: StudyObjectSetting;
    changeConcentrationMode: (isConcentration: boolean) => void;
    openSelfStudyFinishDialog: (open: boolean) => void;
}

class subjectState {
    name : string = '';
    selected : boolean = false;
}

class contentState {
    name : string = '';
    selected : boolean = false;
}

class timerState {
    checked : boolean = false;
    errorMsgFlag : boolean = false;
    hour : number = 0;
    minute : number = 0;

}

class time {
    hour : number = 0;
    minute : number = 0;
}

class rateState {
    name : string = '';
    selected : boolean = false;
}

class todaysSelfStudyPlan {
    selfStudyStartTime : number = 0;
    selfStudyEndTime : number = 0;
    materialName : string = "";
    subjectName : string = "";
    studyMinutes : number = 0;
}

export interface ChildHandler {
    openSelfStudyStartDialog: (open : boolean) => void;
    changeLimitOver: (change : boolean) => void;
}

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFc1A2",
        },
    },
})

const switchTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const SelfStudyChangeDialog: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    // 自習科目（入力テキスト）
    const [inputSubject, setInputSubject] = React.useState('');
    // 自習内容（入力テキスト）
    const [inputContent, setInputContent] = React.useState('');
    const [isShowSelfStudyChangeDialog, setShowSelfStudyChangeDialog] = React.useState(false);
    const [subjectList, setSubjectList] = React.useState([] as subjectState[]);
    const [contentList, setContentList] = React.useState([] as contentState[]);
    // 科目,内容リストを取得後に最新の情報を取得するためのフラグ
    const [listFlag, setListFlag] = React.useState(false);
    // ダイアログを開いた時間
    const [openDialogDate, setOpenDialogDate] = React.useState(new Date());
    const [isTimerMinutes, setIsTimerMinutes] = React.useState(false);
    // 目標時間の設定（新規）
    const [timer, setTimer] = React.useState(new timerState());
    // 目標時間（既存）
    const [targetTime, setTargetTime] = React.useState(new time());
    // 学習実績
    const [totalMinutes, setTotalMinutes] = React.useState(0);
    // 直近の自習の目標時間
    const [latestTimerMinutes, setLatestTimerMinutes] = React.useState(0);    
    // 現在の自習時間
    const [progressTime, setProgressTime] = React.useState(new time());
    const [concentrationSwitch, setConcentrationSwitch] = React.useState(false);
    const [limitOver, setLimitOver] = React.useState(false); // 状況確認ダイアログ表示中にタイマーが切れたかどうか
    // count
    const [count, setCount] = React.useState(0);
    // 設定済みの時間
    const [studyTime, setStudyTime] = React.useState(0);
    // 直近の自習データ
    const [latestStudyPlanDetail, setLatestStudyPlanDetail] = React.useState(new StudyPlanDetail());

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // 時間の上限値
    const MAX_TIMER_HOUR = 99;
    // 分の上限値
    const MAX_TIMER_MINUTE = 59;
    // 自習科目・内容の文字数上限
    const MAX_INPUT_TEXT_LENGTH = 20;


    useImperativeHandle(ref, () => ({
        openSelfStudyStartDialog: (open : boolean) => {
            setShowSelfStudyChangeDialog(open);
        },
        changeLimitOver: (change : boolean) => {
            setLimitOver(change);
        }
    }))

    useEffect(() => {
        async function get() {
            await getSubjectList();
            await getContentList();
        }

        if (props.studyObjectSetting && isShowSelfStudyChangeDialog) {
            get();
            setOpenDialogDate(new Date());
            // タイマーを一時停止
            props.handleMyStudyTimerState(false, 0);
            console.info('StudyDialog' + props.handleGetStudyCount())
            console.info('StudyTime' + props.handleGetStudyTime())
            setCount(props.handleGetStudyCount());
            setStudyTime(props.handleGetStudyTime());
        }
    }, [props.studyObjectSetting, isShowSelfStudyChangeDialog])

    useEffect(() => {
        async function get() {
            await getStudyPlan();
        }
        if (listFlag) {
            get();
        }
    }, [listFlag])

    useEffect(() => {
        const countMinutes = Math.floor(count / 60) % 60;
        const countHours = Math.floor(Math.floor(count / 60) / 60);
        setProgressTime({minute: countMinutes, hour: countHours})
    },[count])

    // 自習科目を取ってくる処理(async)
    const getSubjectList = async() => {
        await httpClient.getStudySubjectMaster(sessionStorage.getItem("TABID") as string)
        .then((e: StudySubjectMasterInfo[]) => {
            const subjectNameList: string[] = [];
            const subjectIds = props.studyObjectSetting.subjectList.split(",");
            subjectIds.map(u => {
                if (u != "0") {
                    const subject = e.find(v => (v.id == Number(u)));
                    if (subject) {
                        subjectNameList.push(subject.subjectName);
                    }
                }
            });
            // その他を追加
            subjectNameList.push("その他");
            for(let i=0;i<subjectNameList.length;i++){
                let index = subjectList.findIndex(e => e.name === subjectNameList[i]);
                if(index === -1){
                    setSubjectList((prevState) => [...prevState, {name: subjectNameList[i], selected: false}]);
                }
            }
            for(let i=0;i<subjectList.length;i++){
                let index = subjectNameList.findIndex(e => e === subjectList[i].name); 
                if(index === -1){
                    setSubjectList((prevState) => prevState.filter((subject) => subject.name !== subjectList[i].name));
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    // ジャンルを取ってくる処理(async)
    const getContentList = async () => {
        await httpClient.getStudyPurposeMaster(sessionStorage.getItem("TABID") as string)
        .then((e: StudyPurposeMasterInfo[]) => {
            const purposeNameList: string[] = [];
            const purposeIds = props.studyObjectSetting.purposeList.split(",");
            purposeIds.map(u => {
                if (u != "0") {
                    const purpose = e.find(v => (v.id == Number(u)));
                    if (purpose) {
                        purposeNameList.push(purpose.purposeName);
                    }
                }
            });
            // その他を追加
            purposeNameList.push("その他");
            for(let i=0;i<purposeNameList.length;i++){
                let index = contentList.findIndex(e => e.name === purposeNameList[i]);
                if(index === -1){
                    setContentList((prevState) => [...prevState, {name: purposeNameList[i], selected: false}]);
                }
            }
            for(let i=0;i<contentList.length;i++){
                let index = purposeNameList.findIndex(e => e === contentList[i].name); 
                if(index === -1){
                    setContentList((prevState) => prevState.filter((purpose) => purpose.name !== contentList[i].name));
                }
            }
            setListFlag(true);
        }).catch(err => {
            console.log(err);
        });
    }

    // 自習計画を取ってくる処理(async)
    const getStudyPlan = async() => {
        await httpClient.getStudyPlan(sessionStorage.getItem("TABID") as string)
        .then((e: StudyPlanDetail[]) => {
            const latestPlan = e[e.length - 1];
            setLatestStudyPlanDetail(latestPlan);
            setSubjectList((preveState) =>
                preveState.map((obj) => (obj.name === latestPlan.studySubjectMaster.subjectName ? { name: obj.name, selected: true } : { name: obj.name, selected: false })));
            setContentList((preveState) =>
                preveState.map((obj) => (obj.name === latestPlan.studyPurposeMaster.purposeName ? { name: obj.name, selected: true } : { name: obj.name, selected: false })));
            setInputSubject(latestPlan.subjectName);
            setInputContent(latestPlan.purposeName);

            // 別の自習席に移動した場合の対応
            let index = subjectList.findIndex(e => e.name === latestPlan.studySubjectMaster.subjectName);
            if (index === -1) {
                setSubjectList((preveState) =>
                    preveState.map((obj) => (obj.name === 'その他' ? { name: obj.name, selected: true } : { name: obj.name, selected: false })));
                setInputSubject(latestPlan.studySubjectMaster.subjectName);
            }
            index = contentList.findIndex(e => e.name === latestPlan.studyPurposeMaster.purposeName);
            if (index === -1) {
                setContentList((preveState) =>
                    preveState.map((obj) => (obj.name === 'その他' ? { name: obj.name, selected: true } : { name: obj.name, selected: false })));
                setInputContent(latestPlan.studyPurposeMaster.purposeName);
            }

            // DBのtimerMinutesは各自習の目標時間
            if (latestPlan.timerMinutes === 0) {
                // 直近でタイマーを設定していない場合
                setIsTimerMinutes(false);
            } else {
                setIsTimerMinutes(true);
            }
            // 現在の目標時間の計算
            let targetMinutes = latestPlan.timerMinutes;
            let totalStudyMinutes = 0;
            e.map((obj, index) => {
                if (obj.studyMinutes != null) {
                    if (index != e.length - 1 && obj.timerMinutes != 0) {
                        targetMinutes += obj.studyMinutes;
                    }
                    totalStudyMinutes += obj.studyMinutes;
                }
            });

            setLatestTimerMinutes(latestPlan.timerMinutes);
            setTotalMinutes(totalStudyMinutes);
            setTargetTime({minute: targetMinutes % 60, hour: Math.floor(targetMinutes / 60)})
            setConcentrationSwitch(latestPlan.studyPlan.concentration);
        }).catch(err => {
            console.log(err);
        });
    }

    /*
     * 全角・半角を考慮した文字数チェック
     */
    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')
 
        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
 
        return len
    };

    const judgeButtonSize = (targetButtonList: {name: string, selected: boolean}[]) => {
        let maxLength = 0;
        for(let i=0;i<targetButtonList.length;i++){
            if(maxLength < targetButtonList[i].name.length){
                maxLength = targetButtonList[i].name.length;
            }
        }

        return maxLength;
    }

    const subjectButtonWidth = useMemo(() => {
        if(subjectList.length >0){
            return judgeButtonSize(subjectList)*22;
        }
    }, [subjectList]);

    const contentButtonWidth = useMemo(() => {
        if(contentList.length > 0){
            return judgeButtonSize(contentList)*22;
        }
    }, [contentList]);

    /*
    * 自習科目についてのエラーメッセージを出力
    */
    const subjectRequired = () => {
        let selectedSubjectIndex = subjectList.findIndex(e => e.selected === true);
        if(selectedSubjectIndex === -1){
            return (
                <label style={{ marginLeft: '40px', color:"red" }}>※どれか１つ選択してください。 </label>
            );
        }
    };

    /*
    * 自習内容－ジャンルについてのエラーメッセージを出力
    */
    const contentRequired = () => {
        let selectedContentIndex = contentList.findIndex(e => e.selected === true);
        if(selectedContentIndex === -1){
            return (
                <label style={{ marginLeft: '40px', color:"red" }}>※どれか１つ選択してください。 </label>
            );
        }
    };

    /*
    * テキストボックスについてのエラーメッセージを出力
    */
    const textLength = (maxLength: number, inputText: string, distinctionHz: boolean = false, required: boolean = false) => {
        /* let len = judgehz(inputText) */
        let len = 0
        if(distinctionHz) {
            len = judgehz(inputText)
        } else {
            len = inputText.length
        }
        if( maxLength < len ){
            return (
                <div style={{ marginTop: "6px", color:"red" }}>入力した文字列が長すぎます。 </div>
            );
        }

        if( required && len === 0){
            return (
                <div style={{ marginTop: "6px", color:"red" }}>入力必須です。 </div>
            );
        }
    };

    // 自習科目のテキストフィールドのクリア処理
    const onClearInputSubject = useCallback(() => {
        setInputSubject('');
    }, [inputSubject]);

    // 自習内容のテキストフィールドのクリア処理
    const onClearInputContent = useCallback(() => {
        setInputContent('');
    }, [inputContent]);

    /*
    * テキストボックス用のハンドラ
    */
    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        switch (event.target.name) {
            case 'inputSubject':
                setInputSubject(event.target.value);
                break;
            case 'inputContent':
                setInputContent(event.target.value);
                break;
            default:
                break;
        }
    }

    /**
     * 自習科目ボタンクリック用
     */
    const handleClickSubjectButton = (name : string) => {

        setSubjectList((preveState) => 
            preveState.map((obj) => (obj.name === name ? {name: obj.name, selected: true} : {name: obj.name, selected: false })));
    }

    /**
     * 自習ジャンルボタンクリック用
     */
    const handleClickContentButton = (name : string) => {

        setContentList((preveState) => 
            preveState.map((obj) => (obj.name === name ? {name: obj.name, selected: true} : {name: obj.name, selected: false })));
    }

    /**
     * タイマースイッチのクリック用
     */
    const handleTimerSwitch = () => {
        if (timer.checked) {
            setTimer({...timer, checked: !timer.checked, errorMsgFlag: false});
        } else {
            setTimer({...timer, checked: !timer.checked});          
        }
    }

    /**
     * 集中モードスイッチのクリック用
     */
    const handleConcentrationSwitch = () => {
        setConcentrationSwitch(!concentrationSwitch);
    }

    /**
     * タイマー値(時間)のテキストフィールド用
     * @param value 
     */
    const handleChangeHour = (value: string) => {
        // 空にした場合、0に戻す
        if (value == '') {
            setTimer({ ...timer, hour: 0 })
        } else if (Number(value) > MAX_TIMER_HOUR) {
        } else {
            const a = value.replace(/^0+/, '');
            setTimer({ ...timer, errorMsgFlag: false, hour: Number(a) });
        }
    }

    /**
     * タイマー値(分)のテキストフィールド用
     * @param value 
     */
    const handleChangeMinute = (value: string) => {
        // 空にした場合、0に戻す
        if (value == '') {
            setTimer({ ...timer, minute: 0 })
        } else if (Number(value) > MAX_TIMER_MINUTE) {
        } else {
            const a = value.replace(/^0+/, '');
            setTimer({ ...timer, errorMsgFlag: false, minute: Number(a) });
        }
    }

    // 自習開始ダイアログにおけるDBに値を登録、更新
    const handleClickStartButton = async () => {

        // 自習科目・ジャンル・教材の入力エラー対策
        let selectedSubjectIndex = subjectList.findIndex(e => e.selected === true);
        let selectedContentIndex = contentList.findIndex(e => e.selected === true);
        if(selectedSubjectIndex === -1 || selectedContentIndex === -1){
            return;
        }

        let subjectText = '';
        // 自習科目に「その他」を選択時の入力エラー対策
        if (subjectList[selectedSubjectIndex].name == "その他") {
            if (inputSubject === null || inputSubject === undefined || inputSubject === '') {
                return;
            } else {
                if (judgehz(inputSubject) > MAX_INPUT_TEXT_LENGTH) {
                    return;
                }
            }
            subjectText = inputSubject;
        }
        let contentText = '';
        // 自習内容に「その他」を選択時の入力エラー対策
        if (contentList[selectedContentIndex].name == "その他") {
            if (inputContent === null || inputContent === undefined || inputContent === '') {
                return;
            } else {
                if (judgehz(inputContent) > MAX_INPUT_TEXT_LENGTH) {
                    return;
                }
            }
            contentText = inputContent;
        }

        // タイマースイッチがオンの時はタイマーの値の入力エラー対策
        if(timer.checked){
            if(timer.hour > MAX_TIMER_HOUR || timer.minute > MAX_TIMER_MINUTE){
                return;
            }
            if (timer.hour < 1 && timer.minute < 1){
                setTimer({...timer, errorMsgFlag: true});
                return;
            }
        }

        setShowSelfStudyChangeDialog(false);

        // studyMinutesは自習単位の自習時間（現在の自習カウント-以前の自習時間の合計）
        const studyMinutes = Math.floor(count / 60) - totalMinutes;

        /**
         * timerMinutesは自習単位の目標時間
         * 科目or内容の変更があった場合、目標時間 = timerの設定時間 + (既存の目標時間 - 既存の自習時間)
         */
        let timerMinutes = -1;
        if(timer.checked){ // タイマースイッチがオンかオフかでタイマー値の登録内容を変える
            if (latestStudyPlanDetail.studySubjectMaster.subjectName == subjectList[selectedSubjectIndex].name
                && latestStudyPlanDetail.subjectName == subjectText
                && latestStudyPlanDetail.studyPurposeMaster.purposeName == contentList[selectedContentIndex].name
                && latestStudyPlanDetail.purposeName == contentText) {
                timerMinutes = timer.minute + timer.hour * 60;
            } else {
                // 直前の目標時間設定がない場合は、設定値を入力
                if (latestTimerMinutes != 0) {
                    timerMinutes = timer.minute + timer.hour * 60 + (latestTimerMinutes - studyMinutes);
                } else {
                    timerMinutes = timer.minute + timer.hour * 60;
                }
            }
        } else {
            if (latestStudyPlanDetail.studySubjectMaster.subjectName == subjectList[selectedSubjectIndex].name
                && latestStudyPlanDetail.subjectName == subjectText
                && latestStudyPlanDetail.studyPurposeMaster.purposeName == contentList[selectedContentIndex].name
                && latestStudyPlanDetail.purposeName == contentText) {
                timerMinutes = 0;
            } else {
                // 直前の目標時間設定がない場合は、設定値を入力
                if (latestTimerMinutes != 0) {
                    timerMinutes = latestTimerMinutes - studyMinutes;
                } else {
                    timerMinutes = 0;
                }
            }
        }

        const startDate = new Date();

        await httpClient.changeStudyPlan(sessionStorage.getItem("TABID") as string, subjectList[selectedSubjectIndex].name, subjectText, contentList[selectedContentIndex].name, contentText, timerMinutes, studyMinutes, concentrationSwitch, openDialogDate.getTime(), startDate.getTime())
            .then((e: String) => {
                if (timer.checked) {
                    if(props.handleGetStudyEnd()){ // タイマー終了→振り返りダイアログ→キャンセルの流れで分岐に入る
                        // console.info("Changeダイアログ タイマー終了した:"+props.handleGetStudyEnd()+", 開始時間:"+props.handleGetStudyCount()+", 終了時間:"+props.handleGetStudyCount()+timerMinutes*60);
                        props.handleStudyTimerStart(props.handleGetStudyCount(),props.handleGetStudyCount()+(timer.minute + timer.hour * 60) * 60);
                        props.handleMyStudyTimerState(true, 0); // タイマーを再開

                    }else{
                        // console.info("Changeダイアログ タイマー終了していない(タイマー延長):"+props.handleGetStudyEnd());
                        // 直前の目標時間設定がない場合は、設定値を入力
                        if (latestTimerMinutes != 0) {
                            props.handleMyStudyTimerState(true, timer.minute + timer.hour * 60); // タイマースタート
                        } else {
                            props.handleStudyTimerStart(props.handleGetStudyCount(),props.handleGetStudyCount()+(timer.minute + timer.hour * 60) * 60);
                            props.handleMyStudyTimerState(true, 0); // タイマーを再開
                        }

                    }
                } else {
                    if(props.handleGetStudyEnd()){ // タイマー終了→振り返りダイアログ→キャンセルの流れで分岐に入る
                        // console.info("Changeダイアログ タイマー終了した:"+props.handleGetStudyEnd()+", 開始時間:"+props.handleGetStudyCount()+", 終了時間:設定なし");
                        props.handleStudyTimerStart(props.handleGetStudyCount(),-1);
                        props.handleMyStudyTimerState(true, 0); // タイマーを再開

                    }else{
                        // console.info("Changeダイアログ タイマー終了していない(タイマー延長しない):"+props.handleGetStudyEnd());
                        props.handleMyStudyTimerState(true, 0); // タイマーを再開
                    }
                    
                }
                if (concentrationSwitch) {
                    props.changeConcentrationMode(true);
                }else{
                    props.changeConcentrationMode(false);
                }

                // 入力値をデフォルトに戻す
                setSubjectList([] as subjectState[]);
                setContentList([] as contentState[]);
                setInputSubject('');
                setInputContent('');
                setListFlag(false);
                setIsTimerMinutes(false);
                setTimer(new timerState());
                setLatestTimerMinutes(0);
                setTotalMinutes(0);
                setTargetTime({minute: 0, hour: 0});
                setLatestStudyPlanDetail(new StudyPlanDetail());
            }).catch(err => {
                console.log(err);
            });
    }

    // 自習開始ダイアログのキャンセルボタン用
    const handleClickStartCancel = () => {
        setShowSelfStudyChangeDialog(false);
        if(props.handleGetStudyEnd()){ // タイマーが終了している
            props.openSelfStudyFinishDialog(true);
        }else{ // タイマーが終了していないので、通常のタイマー再開
            props.handleMyStudyTimerState(true, 0);
        }

        // 入力値をデフォルトに戻す
        setSubjectList([] as subjectState[]);
        setContentList([] as contentState[]);
        setInputSubject('');
        setInputContent('');
        setListFlag(false);
        setIsTimerMinutes(false);
        setTimer(new timerState());
        setLatestTimerMinutes(0);
        setTotalMinutes(0);
        setTargetTime({minute: 0, hour: 0});
        setLatestStudyPlanDetail(new StudyPlanDetail());

    }

    /**
     * 自習科目（入力テキスト）用のプログレスバー描画
     */
    const progressBarInputSubject = useMemo(() => {
        let parsent = (judgehz(inputSubject) / MAX_INPUT_TEXT_LENGTH) * 100;
        let progressColor = '#FFE1A2';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_INPUT_TEXT_LENGTH, inputSubject, true, true)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...SELECT_NONE,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    }, [inputSubject])

    /**
     * 自習内容（入力テキスト）用のプログレスバー描画
     */
    const progressBarInputContent = useMemo(() => {
        let parsent = (judgehz(inputContent) / MAX_INPUT_TEXT_LENGTH) * 100;
        let progressColor = '#FFE1A2';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_INPUT_TEXT_LENGTH, inputContent, true, true)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...SELECT_NONE,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    }, [inputContent])

    /**
     * 自習科目ボタンの描画
     * isEditでボタンをクリックできるか分岐
     */
    const subjectDraw  = useMemo(() => {
        return (
            <Grid container justify='center' alignItems='center' style={{paddingTop: 15, paddingBottom: 15}}>
                <Grid item xs={12}>
                    <div style={{color: '#707070', paddingBottom: 5, fontWeight: 'bold'}}>自習科目{subjectRequired()}</div>
                        <Grid container spacing={(subjectButtonWidth !== undefined) && subjectButtonWidth/22 >= 4 ? 2 : 1}>
                        {subjectList.map((subject) => {
                            return(
                                (subject.selected ?
                                <Grid item>
                                    <Button color="primary" onClick={() => handleClickSubjectButton(subject.name) } style={{backgroundColor: '#006FBC', color: '#FFFFFF', border: '3px solid #006FBC', borderRadius: '31px', width: subjectButtonWidth }}>{subject.name}</Button>
                                </Grid>
                                :
                                <Grid item>
                                    <Button color='primary' onClick={() => handleClickSubjectButton(subject.name) } style={{ pointerEvents: 'auto', color: '#676767', border: '3px solid #A7A7A7', borderRadius: '31px', width: subjectButtonWidth }}>{subject.name}</Button>
                                </Grid>)
                            )
                        })}
                        </Grid>
                </Grid>
                {subjectList.map((subject) => {
                    if (subject.selected && subject.name == "その他") {
                        return(
                            <Grid container justify='center' alignItems='center'>
                                <Grid item xs={12}>
                                    <DialogContentText>
                                        {progressBarInputSubject}
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="inputSubject"
                                        name="inputSubject"
                                        variant={'outlined'}
                                        fullWidth
                                        multiline
                                        rows={1}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleTextFieldChange}
                                        value={inputSubject}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={onClearInputSubject}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }} 
                                        style={{marginTop: -10}}/>
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </Grid>
        );
    }, [subjectList, props.studyObjectSetting, inputSubject])

    /**
     * 自習ジャンルボタンの描画
     * isEditでボタンをクリックできるか分岐
     */
    const contentDraw  = useMemo(() => {
        return (
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12}>
                    <div style={{ color: '#707070', paddingBottom: 2, fontWeight: 'bold' }}>自習内容{contentRequired()}</div>
                    <Grid container spacing={(contentButtonWidth !== undefined) && contentButtonWidth / 22 >= 4 ? 2 : 1}>
                        {contentList.map((content) => {
                            return (
                                (content.selected ?
                                    <Grid item>
                                        <Button color="primary" onClick={() => handleClickContentButton(content.name)} style={{ backgroundColor: '#006FBC', color: '#FFFFFF', border: '3px solid #006FBC', borderRadius: '31px', width: contentButtonWidth }}>{content.name}</Button>
                                    </Grid>
                                    :
                                    <Grid item>
                                        <Button color='primary' onClick={() => handleClickContentButton(content.name)} style={{ pointerEvents: 'auto', color: '#676767', border: '3px solid #A7A7A7', borderRadius: '31px', width: contentButtonWidth }}>{content.name}</Button>
                                    </Grid>)
                            )
                        })}
                    </Grid>
                </Grid>
                {contentList.map((content) => {
                    if (content.selected && content.name == "その他") {
                        return(
                            <Grid container justify='center' alignItems='center'>
                                <Grid item xs={12}>
                                    <DialogContentText>
                                        {progressBarInputContent}
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="inputContent"
                                        name="inputContent"
                                        variant={'outlined'}
                                        fullWidth
                                        multiline
                                        rows={1}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleTextFieldChange}
                                        value={inputContent}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={onClearInputContent}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }} 
                                        style={{marginTop: -10}}/>
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </Grid>
        );
    }, [contentList, props.studyObjectSetting, inputContent])

    /**
     * 目標時間設定のエラーメッセージ描画
     */
    const ErrMsg = useMemo(() => {
        // 要確認　エラーメッセージの文言、表示条件
        return (
            <div>
                <Typography variant="h6" style={{ color: "red", padding: "3px 0px 3px 17px", fontSize: "17px" }}>
                    目標時間を設定してください。
                </Typography>
            </div>
        );
    }, [timer])

    /**
     * 目標時間設定の描画
     */
    const timerDraw = useMemo(() => {
        return (
            <>
                <Grid container spacing={2} style={{paddingTop: "8px"}}>
                    <Grid item xs={3}>
                        <div style={{paddingTop: "10px", color: '#707070', fontWeight: 'bold'}}>目標時間の設定</div>
                    </Grid>
                    <Grid item xs={Utility.getType() === 2 ? 3 : 2}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={timer.checked}
                                onChange={() => handleTimerSwitch()}
                                color="primary"
                                name="timerSwitch"
                                inputProps={{ 'aria-label': 'timerSwitch' }}
                            />
                        </ThemeProvider>
                    </Grid>
                    {isTimerMinutes &&
                        <Grid item>
                            <div style={{ paddingTop: "10px", color: '#707070' }}>延長</div>
                        </Grid>
                    }
                    <Grid item>
                            <TextField
                                id="loginId_search"
                                size="small"
                                variant="outlined"
                                type={"number"}
                                InputProps={{ inputProps: { min: 0, max: 99 } }}
                                disabled={!timer.checked}
                                onChange={(event) => handleChangeHour(event.target.value)}
                                value={timer.hour}
                                style={{maxWidth: 80}}
                                onFocus={(event) => {event.target.select()}}
                            />
                    </Grid>
                    <Grid item>
                        <div style={{color: '#707070', paddingTop: '10px'}}>時間</div>
                    </Grid>
                    <Grid item>
                            <TextField
                                id="loginId_search"
                                size="small"
                                variant="outlined"
                                type={"number"}
                                InputProps={{ inputProps: { min: 0, max: 59 } }}
                                disabled={!timer.checked}
                                onChange={(event) => handleChangeMinute(event.target.value)}
                                value={timer.minute}
                                style={{maxWidth: 80}}
                                onFocus={(event) => {event.target.select()}}
                            />
                    </Grid>
                    <Grid item>
                        <div style={{color: '#707070', paddingTop: '10px'}}>分</div>
                    </Grid>
                </Grid>
                <Grid container style={{paddingTop:0,paddingLeft:130}}>
                    <Grid item xs={12}>
                        {timer.errorMsgFlag ? 
                            ErrMsg
                            :
                            ""
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <div style={{paddingTop: "10px", color: '#707070', fontWeight: 'bold'}}>集中モード</div>
                    </Grid>
                    <Grid item xs={Utility.getType() === 2 ? 3 : 2}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={concentrationSwitch}
                                onChange={() => handleConcentrationSwitch()}
                                color="primary"
                                name="concentrationSwitch"
                                inputProps={{ 'aria-label': 'concentrationSwitch' }}
                            />
                        </ThemeProvider>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <div style={{paddingLeft: "5px", color: '#707070', fontSize: '15px'}}>※自習中にチャット通知をミュートします。</div>
                    </Grid>
                </Grid>
            </>
        )
    }, [timer,concentrationSwitch, isTimerMinutes])

    const drawTime = useMemo(() => {
        // 目標時間のテキスト表示
        // const targetTimeText: string = targetTime.hour != 0 ? targetTime.hour + '時間' + targetTime.minute + '分' : targetTime.minute + '分';
        const targetTimeText: string = targetTime.hour + '時間' + targetTime.minute + '分';

        // 現在の自習時間のテキスト表示
        const progressTimeText: string = progressTime.hour + "時間" + progressTime.minute + "分";

        const progressMinutes = (progressTime.minute) + (progressTime.hour) * 60;
        const remainMinutes = studyTime - progressMinutes > 0 ? studyTime - progressMinutes : 0;
        // 残り時間のテキスト表示
        const remainTimeText =  Math.floor(remainMinutes / 60) + "時間" + (remainMinutes % 60) + "分";
        return (
            <>
                {isTimerMinutes ?
                    <>
                        <Grid container>
                            <Grid item>
                                <div style={{ paddingTop: 20, color: '#707070' }}>{"目標時間：" + targetTimeText}</div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <div style={{ color: '#707070', fontWeight: 'bold' }}>{"現在の自習時間：" + progressTimeText}</div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <div style={{ color: '#707070' }}>{"あと" + remainTimeText + "で自習終了となりますが延長しますか？"}</div>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container>
                            <Grid item>
                                <div style={{ paddingTop: 20, color: '#707070', fontWeight: 'bold' }}>{"現在の自習時間：" + progressTime.hour + "時間" + progressTime.minute + "分"}</div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <div style={{ color: '#707070' }}>{"目標時間を今から設定しますか？"}</div>
                            </Grid>
                        </Grid>
                    </>
                }

            </>
        )
    }, [timer, progressTime, isTimerMinutes, targetTime, studyTime])

    // 自習状況変更ダイアログ 描画用
    const drawSelfStudyChangeDialog = useMemo(() => {
        return (
            <BaseDialog
                open={isShowSelfStudyChangeDialog}
                aria-labelledby="selfstudystart-dialog-title"
                aria-describedby="selfstudystart-description"
                fullWidth={true}
                PaperProps={{
                    style:{
                        border: '5px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="selfstudystart-dialog-title" style={{ userSelect: 'none', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>目標の確認/変更</DialogTitle>
                <DialogContent>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            <div style={{fontSize:16}}>次の自習目標を入力してください。</div>
                        </Grid>
                    </Grid>
                    {subjectDraw}
                    {contentDraw}
                    {drawTime}
                    {timerDraw}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleClickStartButton() } style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>自習を始める</Button>
                    <Button color='primary' onClick={() => handleClickStartCancel() } style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [ isShowSelfStudyChangeDialog, inputSubject, inputContent, subjectList, contentList, timer, progressTime, limitOver, concentrationSwitch, props.studyObjectSetting, isTimerMinutes, targetTime])

    return (
        <Fragment>
            {drawSelfStudyChangeDialog}
        </Fragment>
    )
}

export const SelfStudyChangeDialogRef = forwardRef(SelfStudyChangeDialog);
/**
 * プライバシールームの許可申請ダイアログ
 * 一般ユーザの入室を許可するかどうか
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import AudioPlayer, { AudioId } from './AudioPlayer';

interface Props {
    startingConfirmTimeoutValue: number;
    sendPrivacyRoomPermission: (userId: number, floorId: number, permissionPrivacyRoom: boolean) => void
}

export interface ChildHandler {
    open: (floorId:number, userName:string, userId:number) => void;
    close: () => void;
    closeWaitingForStudentDialog: () => void;
}

const PrivacyRoomPermissionDialog: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [call, setCall] = React.useState(0);
    const [floorId, setFloorId] = React.useState(-1);
    const [userName, setUserName] = React.useState("");
    const [userId, setUserId] = React.useState(0);
    const [showWaitingForStudentDialog, setShowWaitingForStudentDialog] = React.useState(false);

    useImperativeHandle(ref, () => ({
        open: (floorId, userName, userId) => {
            //setCount(props.startingConfirmTimeoutValue);
            setFloorId(floorId);
            setUserName(userName);
            setUserId(userId);
            setOpen(true);
            setCall(call+1);
        },
        close: () => {
            setOpen(false);
        },
        closeWaitingForStudentDialog: () => {
            setShowWaitingForStudentDialog(false);
        },
    }))

    useEffect(() => {
        if (!showWaitingForStudentDialog) return;
        let temp = 10;
        console.log("応答確認のカウントダウン開始（"+temp+"秒）");
        const interval = setInterval(() => {
            temp--;
            setCount(temp);
            if (temp === 0) {
                clearInterval(interval);
                //入室タイムアウト処理
                setShowWaitingForStudentDialog(false);
            }
            //AudioPlayer.play(AudioId.TimeLimit);
        }, 1000)
        return function cleanUp() {
            clearInterval(interval);
        }
    }, [showWaitingForStudentDialog]);

    useEffect(() => {
        if (!open) return;
        let temp = props.startingConfirmTimeoutValue;
        console.log("プライバシールーム：応答確認のカウントダウン開始（"+temp+"秒）");
        const interval = setInterval(() => {
            temp--;
            setCount(temp);
            if (temp === 0) {
                clearInterval(interval);
                setOpen(false);
            }
            AudioPlayer.play(AudioId.TimeLimit);
        }, 1000)
        return function cleanUp() {
            clearInterval(interval);
        }
    }, [open,call]);

    const handlePrivacyRoomPermission = (permissionPrivacyRoom: boolean) => {
        props.sendPrivacyRoomPermission(userId,floorId,permissionPrivacyRoom);
        setOpen(false);
        if(permissionPrivacyRoom){
            setShowWaitingForStudentDialog(true);
        }
    }

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                aria-labelledby="privacyroompermission-dialog-title"
                aria-describedby="privacyroompermission-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="privacyroompermission-dialog-title">{userName + "からの入室の許可申請"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="privacyroompermission-dialog-description">
                        <Typography>{"このフロアに一般ユーザーを入室させる際は、一般ユーザーからの"}</Typography>
                        <Typography>{"入室申請を許可する必要があります。"}</Typography>
                        <Typography>{"「許可する」を選択した場合は、" + userName + "が自動入室します。"}</Typography>
                        <Typography>{"「許可しない」を選択した場合は、申請をキャンセルします。"}</Typography>
                        <Typography variant="body2">{"※" + props.startingConfirmTimeoutValue + "秒以内に選択されない場合は、許可せずに終了します。"}</Typography>
                        <div style={{width: "100%", textAlign: "right"}}>{"残り " + count + "秒"}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handlePrivacyRoomPermission(true)} color="primary">
                        {"許可する"}
                    </Button>
                    <Button onClick={() => handlePrivacyRoomPermission(false)} color="primary">
                        {"許可しない"}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [open, count, userName])

    const drawWaitingForStudentDialog = useMemo(() => {
        return (
            <BaseDialog
                id="waitingForStudentDialog"
                open={showWaitingForStudentDialog}
                aria-labelledby="waitingForStudentDialogTitle"
            >
                <DialogTitle id="waitingForStudentDialogTitle"></DialogTitle>
                <DialogContent>{userName + "の入室を承諾しました。しばらくお待ちください。"}</DialogContent>
            </BaseDialog>
        );
    }, [showWaitingForStudentDialog,userName])

    return (
        <Fragment>
            {draw}
            {drawWaitingForStudentDialog}
        </Fragment>
    )
}

export const PrivacyRoomPermissionDialogRef = forwardRef(PrivacyRoomPermissionDialog);
/**
 * WebRTC関連の画面描画に関係しない部分のクラス
 */

import Peer from 'skyway-js';
// import { User } from '../common/JsonClass';
import JfsClient, { User, WebRTCClient } from '@fsi/jfs-sdk';
import { AudioId } from '../user/AudioPlayer';

export class WebrtcService {
    private static WEB_RTC_KEY = 'f5f5dad0-1618-4359-8429-0b6c81d996e3';
    
    public static SHARE_VIDEO_WINDOW_OFF = 0;
    public static SHARE_VIDEO_WINDOW_MAX_OFF = 1;
    public static SHARE_VIDEO_WINDOW_MAX_ON = 2;
    
    private static peer: Peer;
    private static deviceSelected: boolean = false;

    private static userDataList: Map<string, User> = new Map();
    private static hasScreenShareChecked: boolean = false;
    private static hasScreenShare: boolean = false;

    private static hasCameraDevice: boolean = false;
    private static hasMicDevice: boolean = false;

    // getUserMediaを呼び出してからでないとenumratedevicesでデバイス情報が返されないため、
    // getUserMediaを呼び出し済みか保持している
    public static isCalledGetUserMedia: boolean = false;

    // Skywayを使用したWebRTC接続を行うためのAPIキー
    private static webRtcKey = "";

    // iOSで効果音とgetUserMedia、WebRTC通話の開始が重なると効果音が途切れ途切れになるため、
    // WebRTC通話開始時の効果音を、通話相手の音声を開始する直前に再生するために使用している
    public static remoteStreamConnectSoundId: number = AudioId.None;

    // この値がtrueの場合、カメラ、マイクデバイス選択画面が表示中のため
    // user.isGhostの値を変更しないようにしている
    public static isOpenDeviceSelect: boolean = false;

    private static jfsClient: JfsClient;
    private static webRTCClient: WebRTCClient;

    /** プライベートコンストラクタ */
    private constructor() {
    }

    public static getUserDataList(): Map<string, User> {
        return this.userDataList;
    }

    public static addUserDataList(userData: User) {
        this.userDataList.set('' + userData.id, userData);
    }

    public static removeUserDataList(sessionId: string) {
        this.userDataList.delete(sessionId);
    }

    public static clearUserDataList() {
        this.userDataList.clear();
    }

    public static getiOSVersion(): number {
        var version = -1;
        const ua = window.navigator.userAgent.toLowerCase();
        const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);
        if (isiOS) {
            let temp = ua.split('os ')[1].split(' ')[0];
            if (temp.indexOf('_') >= 0) {
                version = parseInt(temp.split('_')[0]);
            }
        }

        return version;
    }

    public static isiOS(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);
    }

    public static isiPhone(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('iphone') > -1;
    }

    public static isAndroid(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('android') > -1;
    }

    public static setDeviceSelected(value: boolean) {
        this.deviceSelected = value;
    }

    public static isDeviceSelected() : boolean {
        return this.deviceSelected;
    }

    public static isPeerIdIsNull(): boolean {
        let result = false;
        try {
            // この関数がJfsErrorを投げるならpeerIdはundefined
            // JfsErrorを投げないようにしたので空文字判定
            let pid = this.webRTCClient.getPeerId();
            if(pid === "")  result = true;
        } catch (error) {
            result = true;
        }
        return result;
    }

    /**
     * WebRTCが使用できるかどうかを判定
     */
    public static checkWebrtc() : boolean {
        // try {
        //     if (this.peer.open) {
        //         return true;
        //     }
        // } catch(e) {
        //     // 判定のみのためエラー内容は捨てる
        // }

        // return false;
        return this.webRTCClient.isConnectedSignalingServer();
    }

    /**
     * Skywayを使用したWebRTC接続を行うためのAPIキーの設定
     * 
     * @param key 
     */
    public static setWebrtcApiKey(key: string) {
        // console.log("setWebrtcApiKey : " + key);
        if (key === "") {
            this.webRtcKey = this.WEB_RTC_KEY;
        } else {
            this.webRtcKey = key;
        }

        // if (this.peer && this.peer.open) {
        //     // 新しいkeyを設定された場合には、古い接続は閉じておく
        //     this.peer.destroy();
        // }
        if (this.webRTCClient.isConnectedSignalingServer()) {
            /** 新しいkeyを設定された場合には、古い接続は閉じておく */
            this.webRTCClient.disconnectSignalingServer();
        }
    }

    /**
     * WebRTCの再接続処理
     */
    // public static async reconnect() {
    //     try {
    //         if (this.peer === undefined || this.peer.open === false) {
    //             // console.log("reconnect : " + this.webRtcKey);
    //             this.peer = new Peer({key: this.webRtcKey});
    //             const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
    //             let loopCount = 0;
    //             while(this.peer.open === false) {
    //                 await sleep(100);
    //                 loopCount++;
    //                 if (loopCount > 30) {
    //                     break;
    //                 }
    //             }
    //         }
    //     } catch(e) {
    //         // console.log(e);
    //     }
    // }

    /**
     * WebRTCのpeer開放
     */
     public static async destroyPeer() {
        // try {
        //     if (this.peer !== undefined && this.peer.open === true) {
        //         this.peer.destroy();
        //     }
        // } catch(e) {
        //     // console.log(e);
        // }

        try {
            this.webRTCClient.disconnectSignalingServer();
        } catch (error) {
            console.info('destroy peer error');
        }
    }

    public static async getPeerId() {
        // if (this.peer === undefined || this.peer === null || this.peer.open === false) {
        //     return "";
        // }
        // return this.peer.id;
        return this.webRTCClient.getPeerId();
    }

    public static getPeerIdSync() {
        return this.webRTCClient.getPeerId();
    }

    public static isIOSDevice() : boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);
        return isiOS;
    }

    public static isIOSChrome() : boolean {
        return /CriOS/.test(navigator.userAgent);
    }

    // public static async getPeer() : Promise<Peer> {
    //     if (this.peer === undefined || this.peer === null || this.peer.open === false) {
    //         await this.openPeer();
    //     }
        
    //     const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
    //     let loopCount = 0;
    //     while(this.peer.open === false) {
    //         await sleep(100);
    //         if (loopCount > 30) {
    //             break;
    //         }
    //         loopCount++;
    //     }

    //     return this.peer;
    // }

    /**
   * webRTCClientを取得する。
   * まだシグナリングサーバー接続できなければ接続する。
   */
    public static async getWebRTCClient(waitCounter: number): Promise<WebRTCClient> {
        if (!this.webRTCClient.isConnectedSignalingServer()) {
            await this.openPeer(waitCounter);
        }

        return this.webRTCClient;
    }

    /** シグナリングサーバーに接続する。 */
    private static async openPeer(waitCounter: number) {
        await this.webRTCClient
        .connectSignalingServer(this.webRtcKey, waitCounter)
        .catch((err: unknown) => console.error(err));
    }

    // private static async openPeer() {
    //     try {
    //         // console.log("openPeer : " + this.webRtcKey);
    //         this.peer = new Peer({key: this.webRtcKey});

    //         const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
    //         let loopCount = 0;
    //         while(this.peer.open === false) {
    //             await sleep(100);
    //             if (loopCount > 30) {
    //                 break;
    //             }
    //         }
    //     } catch(e) {
    //         // 判定のみのためエラー内容は捨てる
    //     }
    // }

    public static hasScreenShareFunctionCheck(navigator : Navigator) : boolean {
        if (this.hasScreenShareChecked) {
            return this.hasScreenShare;
        }

        try {
            if (navigator.mediaDevices !== undefined) {
                for (const methodOrProp in navigator.mediaDevices) {
                    if ("getDisplayMedia" === methodOrProp) {
                        this.hasScreenShare = true;
                        break;
                    }
                }
            }
        } catch(e) {
            this.hasScreenShare = false;
        }

        this.hasScreenShareChecked = true;
        return this.hasScreenShare;
    }

    /**
     * WebRTCの接続時にMesh、SFUのどちらのモードを使用するか取得する
     * 
     * @param roomId 
     * @param webRtcMode 
     */
    public static getConnectMode(roomId: string, webRtcMode: number) : "mesh" | "sfu" {
        let mode: "mesh" | "sfu" = "sfu";

        if (webRtcMode & this.getWebRtcRoomType(roomId)) {
            mode = "mesh";
        }

        return mode;
    }

    /**
     * webRtcRoomIdからユーザー同士の接触、会議室、全体放送の種類を判別する
     * 
     * @param roomId 
     *  "-receive" が含まれる場合、全体放送
     *  上記の条件にあてはまらず "-" が含まれる場合、ユーザー同士
     *  上記のどれにも当てはまらない場合、会議室
     *  として判定している。
     * 
     * @return 
     *  1: ユーザー同士の接触
     *  2: 会議室
     *  4: 全体放送
     */
    public static getWebRtcRoomType(roomId: string) : number {
        let type: number = 1;
        if (roomId.indexOf("-") >= 0) {
            let split = roomId.split("-");
            const index = split.length - 1;
            if (split[index]) {
                if (split[index] === "receive") {
                    // 全体放送の場合
                    type = 4;
                }
            }
        } else {
            // 会議室の場合
            type = 2;
        }

        return type;
    }

    /**
     * WebRTC接続を受信者モードにするかどうかの判定を取得する
     * 
     * @param sessionId 
     * 　roomId内にこの値が含まれている場合、全体放送の配信者と判定し、falseを返す
     * @param roomId 
     * 　"-receive"が含まれているかどうかで受信者モードを判定
     */
    public static getWebRtcReceiveMode(sessionId: string, roomId: string) : boolean {
        let isReceiveMode = false;
        if (roomId.indexOf("-") >= 0) {
            let split = roomId.split("-");
            const index = split.length - 1;
            if (split[index]) {
                if (split[index] === "receive" 
                    && roomId.replace("-receive", "") !== sessionId) {
                    isReceiveMode = true;
                }
            }
        }

        return isReceiveMode;
    }

    /**
     * カメラ、マイクデバイスを持つかどうかを判定する
     */
    public static async checkMediaDevices() {
        this.hasCameraDevice = false;
        this.hasMicDevice = false;
        try {
            // #670 iPad対応-25 iPad,iphoneの場合は、意図しないタイミングでデバイス使用許可が外れるので、getUserMediaを叩き直す必要がある。
            if (this.isCalledGetUserMedia === false || (this.isiOS()) === true ) {
                let hasDeviceInfos = false;
                await navigator.mediaDevices.enumerateDevices()
                .then((deviceInfos) => { // 成功時
                    if (deviceInfos.length > 0) {
                        if (deviceInfos[0].deviceId !== "") {
                            hasDeviceInfos = true;
                        }
                    }
                });

                // #670 iPad対応-25 iPad,iphoneの場合は、意図しないタイミングでデバイス使用許可が外れるので、getUserMediaを叩き直す必要がある。
                if (hasDeviceInfos === false || (this.isiOS()) === true) {
                    // iOS Safari13.1 で enumerateDevices()でデバイス情報を取得するため、1回 getUserMediaを呼び出している。
                    // Safari13.1未満、Chromeでは、getUserMediaを呼び出す前からデバイス情報は取得できる。
                    const constraints : any = {video: true, audio: true};
                    await navigator.mediaDevices.getUserMedia(constraints)
                            .then((stream) => {
                        stream.getTracks().forEach(track => track.stop());
                    }).catch((error: DOMException) => {
                        console.error('checkMediaDevices mediaDevice.getUserMedia() error:', error);
                    });

                    this.isCalledGetUserMedia = true;
                }
            }

            await navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => { // 成功時
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if (deviceInfo.deviceId === "" && deviceInfo.label === "") {
                        continue;
                    }
            
                    if (deviceInfo.kind === 'audioinput') {
                        this.hasMicDevice = true;
                    } else if (deviceInfo.kind === 'videoinput') {
                        // Surfaceに搭載されている赤外線カメラを選択項目に入れないようにしている
                        if (deviceInfo.label.match(" IR ")) {
                            continue;
                        }

                        this.hasCameraDevice = true;
                    }
                }

            }).catch((err) => { // エラー発生時
                console.error('enumerateDevices ERROR:', err);
            });
        } catch(err) {
            console.error('checkMediaDevices ERROR:', err);
        }        
    }

    public static hasCamera() : boolean {
        return this.hasCameraDevice;
    }

    public static hasMic() : boolean {
        return this.hasMicDevice;
    }

    public static playAudioAllWebRtc() {
        const audioList = document.getElementsByTagName("audio");
        for (let i = 0; i < audioList.length; i++) {
            if (audioList[i].id && audioList[i].id.indexOf("-audio") >= 0) {
                if (audioList[i].paused) {
                    audioList[i].play();
                }
            }
        }
    }

    /**
     * WebRTCの音声再生用の全ての audio について pause状態かどうかをチェックする
     * 
     * @param callMethod pause状態の場合に、呼び出されるメソッド
     */
     public static checkAudioPausedAllWebRtc(callMethod: any) {
        const audioList = document.getElementsByTagName("audio");
        for (let i = 0; i < audioList.length; i++) {
            if (audioList[i].id && audioList[i].id.indexOf("-audio") >= 0) {
                if (audioList[i].paused) {
                    // callMethod((audioList.length + 1) + "人参加中、再生に失敗したaudio.id = " + audioList[i].id);
                    callMethod((audioList.length + 1) + "人参加中、再生失敗");
                    return;
                }
            }
        }
    }

    /**
     * 指定された id の audio が pause状態かどうかをチェックする
     * 
     * @param callMethod pause状態の場合に、呼び出されるメソッド
     * @param audioId チェック対象のaudioのid
     */
    public static checkAudioPaused(callMethod: any, audioId: string) {
        const audioList = document.getElementsByTagName("audio");
        for (let i = 0; i < audioList.length; i++) {
            if (audioList[i].id && audioList[i].id === audioId) {
                if (audioList[i].paused) {
                    // callMethod((audioList.length + 1) + "人参加中、再生に失敗したaudio.id = " + audioList[i].id);
                    callMethod((audioList.length + 1) + "人参加中、再生失敗");
                }

                return;
            }
        }
    }

    public static countAudioAllWebRtc() : number {
        const audioList = document.getElementsByTagName("audio");
        return audioList.length;
    }

    /**
     * デバイス選択状態を取得
     * 
     * 0: カメラ、マイク未選択
     * 1: カメラ選択済み
     * 2: マイク選択済み
     * 3: カメラ、マイク選択済み
     */
    public static getDeviceSelectedType() {
        const videoDeviceId = localStorage.getItem("videoDeviceId");
        const audioDeviceId = localStorage.getItem("audioInputDeviceId");
        if (videoDeviceId && audioDeviceId && videoDeviceId !== "" && audioDeviceId !== "") {
            return 3;
        }

        if (videoDeviceId && videoDeviceId !== "") {
            return 1;
        }

        if (audioDeviceId && audioDeviceId !== "") {
            return 2;
        }
        
        return 0;
    }

    public static setWebRtcAudioSinkId(sinkId: string) {
        const audioList = document.getElementsByTagName("audio");
        for (let i = 0; i < audioList.length; i++) {
            if (audioList[i].id && audioList[i].id.indexOf("-audio") >= 0) {
                if ((audioList[i] as any).setSinkId) {
                    (audioList[i] as any).setSinkId(sinkId);
                }
            }
        }
    }

    /**
   * WebRTCサービス初期処理
   */
    public static init() {
        try {
            this.jfsClient = JfsClient.getInstance();
            this.webRTCClient = this.jfsClient.webRTCClient;
        } catch (err: unknown) {
            console.error(err);
            throw new Error('WebRTCService init failed');
        }
    }
}

export default WebrtcService;
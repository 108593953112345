import { Box, Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Theme, Typography } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { FloorInfo } from '../common/JsonClass';
import { FloorEdit } from '../common/JsonClass';
import useRouter from 'use-react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
    }),
);

export interface SystemEditFloorInfoHandler {
    open: (id: number) => void;
}

class FinishInfo {
    open: boolean = true;
    message: string = "";
    password: null | string = null;

    constructor(init: Partial<FinishInfo>) {
        Object.assign(this, init);
    }
}



// export default function OtherUsers() {
const SystemEditFloorInfoComponent: ForwardRefRenderFunction<SystemEditFloorInfoHandler> = (props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectFloorId, setSelectFloorId] = useState(0);
    const [state, setState] = useState({
        tenantName: "",
        officeName: "",
        floorName:  "",
        floorHeight: 0,
        floorWidth: 0,
        webRtcMode: 0,
        isMultiAvatar: false,
        hitAvatarWebRtcTime: 0,
        meetingRoomWebRtcTime: 0,
        telemeetMemberMax: 0,
        requireCamera: true,
        requireMic: true,
        enabledMemo: true,
        enabledScreenShare: false,
        webRtcApiKey: "",
        enabledTutorialButton: true,
        wrStartConfirmWaitTime: 0,
        purposeOfUse: 1,
        enabledFloor: true,
    });

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id);
            setSelectFloorId(id);
            setOpen(true);
        },
    }))

    const handleClose = () => {
        setSelectFloorId(0);
        setOpen(false);
    };

    useEffect(() => {
        if(selectFloorId > 0){
            handleGetFloorInfoById();
        }
        
    }, [selectFloorId])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        //alert(name + ":" + target.value);

        setState(() => {
            return { ...state, [name]: target.value };
        });
    }

    const handleChangeSelect = React.useCallback((value: any): void => {
        //alert("select " + value.target.name + ":" +value.target.value);
        setState(() => {
            return { ...state, [value.target.name]: value.target.value };
        });
    }, [state]);

    const handleCheck = (value: any): void => {
        // alert("check " + value.target.name + ":" +value.target.checked);
        setState(() => {
            return { ...state, [value.target.name]: value.target.checked };
        });
    };

    const handleGetFloorInfoById = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floorEdit/info', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                floorId: selectFloorId.toString() as string,
            }))
            .then((response: AxiosResponse) => {
                let res = response.data as FloorEdit;
                setState({
                    tenantName: res.tenantName,
                    officeName: res.officeName,
                    floorName: res.floorName,
                    floorHeight: res.floorHeight,
                    floorWidth: res.floorWidth,
                    webRtcMode: res.webRtcMode,
                    isMultiAvatar: res.isMultiAvatar,
                    hitAvatarWebRtcTime: res.hitAvatarWebRtcTime,
                    meetingRoomWebRtcTime: res.meetingRoomWebRtcTime,
                    telemeetMemberMax: res.telemeetMemberMax,
                    requireCamera: res.requireCamera,
                    requireMic: res.requireMic,
                    enabledMemo: res.enabledMemo,
                    enabledScreenShare: res.enabledScreenShare,
                    webRtcApiKey: res.webRtcApiKey,
                    enabledTutorialButton: res.enabledTutorialButton,
                    wrStartConfirmWaitTime: res.wrStartConfirmWaitTime,
                    purposeOfUse: res.purposeOfUse,
                    enabledFloor: res.enabledFloor,
                });
            })
            .catch(() => {
                console.log("login error.");
            });
    }

    const handleUpdateFloor = () =>{
        var errormsg = "";

        if(state.floorName === ""){
            errormsg += "フロア名が空です\n";
        }
        if(state.floorHeight.toString() === "" || state.floorWidth.toString() === ""){
            errormsg += "フロアの高さ、または幅が空です\n";
        }
        if(state.hitAvatarWebRtcTime.toString() === ""){
            errormsg += "アバター接触時のビデオ通話確認時間が空です\n";
        }
        if(state.meetingRoomWebRtcTime.toString() === ""){
            errormsg += "ミーティングルームのビデオ通話確認時間が空です\n";
        }
        if(state.telemeetMemberMax.toString() === ""){
            errormsg += "立ち話の参加上限数が空です\n";
        }
        if(state.wrStartConfirmWaitTime.toString() === ""){
            errormsg += "ビデオ通話前の応答タイムアウト時間が空です\n";
        }

        if(errormsg.length > 0){
            alert(errormsg);
        }else{
            axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/floorEdit/update', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                id: selectFloorId.toString(),
                floorName: state.floorName.trimEnd(),
                floorHeight: state.floorHeight.toString(),
                floorWidth: state.floorWidth.toString(),
                webRtcMode: state.webRtcMode.toString(),
                isMultiAvatar: state.isMultiAvatar.toString(),
                hitAvatarWebRtcTime: state.hitAvatarWebRtcTime.toString(),
                meetingRoomWebRtcTime: state.meetingRoomWebRtcTime.toString(),
                telemeetMemberMax: state.telemeetMemberMax.toString(),
                requireCamera: state.requireCamera.toString(),
                requireMic: state.requireMic.toString(),
                enabledMemo: state.enabledMemo.toString(),
                enabledScreenShare: state.enabledScreenShare.toString(),
                webRtcApiKey: state.webRtcApiKey,
                enabledTutorialButton: state.enabledTutorialButton.toString(),
                wrStartConfirmWaitTime: state.wrStartConfirmWaitTime.toString(),
                purposeOfUse: state.purposeOfUse.toString(),
                enabledFloor: state.enabledFloor.toString(),
            }))
            .then((response: AxiosResponse) =>{
                let msg = response.data as string;
                //alert(msg);
                if(msg === "NG"){
                    alert("error");
                } else if(msg === "ERROR01"){
                    alert("フロア名が255文字を超過しています。255文字以内のフロア名にしてください。");
                } else if(msg === "ERROR02"){
                    alert("APIキーが255文字を超過しています。255文字以内のAPIキーにしてください。");
                } else if(msg === "ERROR03"){
                    alert("そのフロア名は既に登録されています。別のフロア名に変更してください。");
                } else if(msg === "ERROR04"){
                    alert("0以下の値が使われています。1以上の値に変更してください。");
                }else if(msg === "OK"){
                    alert("フロア情報の更新が完了しました。");
                    setSelectFloorId(0);
                    setOpen(false);
                }
            })
        }
    }
    
    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>フロア情報</DialogTitle>
                <DialogContent style={{ padding: "20px 20px" }}>
                    <Box margin="0px 0px 10px 10px" fontSize="16px"><b>テナント名：{state.tenantName}</b></Box>
                    <Box margin="0px 0px 10px 10px" fontSize="16px"><b>オフィス名：{state.officeName}</b></Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="floor-name"
                        name="floorName"
                        label="フロア名"
                        variant={'outlined'}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        onChange={handleChange}
                        value={state.floorName}
                        required
                    />
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="floor_height"
                                name="floorHeight"
                                label="フロアの縦幅"
                                type={"number"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                value={state.floorHeight}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="floor_width"
                                name="floorWidth"
                                label="フロアの横幅"
                                type={"number"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                value={state.floorWidth}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.isMultiAvatar}
                                        name="isMultiAvatar"
                                        color="primary"
                                        value={state.isMultiAvatar}
                                        onChange={handleCheck}
                                    />
                                }
                                label="マルチアバターを対象とするか"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.enabledTutorialButton}
                                        name="enabledTutorialButton"
                                        color="primary"
                                        value={state.enabledTutorialButton}
                                        onChange={handleCheck}
                                    />
                                }
                                label="チュートリアルボタン表示"
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.requireCamera}
                                        name="requireCamera"
                                        color="primary"
                                        value={state.requireCamera}
                                        onChange={handleCheck}
                                    />
                                }
                                label="カメラ必須"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.requireMic}
                                        name="requireMic"
                                        color="primary"
                                        value={state.requireMic}
                                        onChange={handleCheck}
                                    />
                                }
                                label="マイク必須"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.enabledMemo}
                                        name="enabledMemo"
                                        color="primary"
                                        value={state.enabledMemo}
                                        onChange={handleCheck}
                                    />
                                }
                                label="メモ機能"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.enabledScreenShare}
                                        name="enabledScreenShare"
                                        color="primary"
                                        value={state.enabledScreenShare}
                                        onChange={handleCheck}
                                    />
                                }
                                label="画面共有機能"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.enabledFloor}
                                        name="enabledFloor"
                                        color="primary"
                                        value={state.enabledFloor}
                                        onChange={handleCheck}
                                    />
                                }
                                label="有効フロア"
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        margin="dense"
                        id="hitAvatarWebRtcTime"
                        name="hitAvatarWebRtcTime"
                        label="アバター接触時のビデオ通話継続確認時間"
                        variant={'outlined'}
                        InputLabelProps={{shrink: true}}
                        value={state.hitAvatarWebRtcTime}
                        type={"number"}
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                    <TextField
                        margin="dense"
                        id="meetingRoomWebRtcTime"
                        name="meetingRoomWebRtcTime"
                        label="ミーティングルームのビデオ通話継続確認時間"
                        variant={'outlined'}
                        InputLabelProps={{shrink: true}}
                        value={state.meetingRoomWebRtcTime}
                        type={"number"}
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="telemeetMember_Max"
                                name="telemeetMemberMax"
                                label="立ち話への参加上限数"
                                type={"number"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                value={state.telemeetMemberMax}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="wrStartConfirmWait_Time"
                                name="wrStartConfirmWaitTime"
                                label="ビデオ通話前のタイムアウト時間"
                                type={"number"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                value={state.wrStartConfirmWaitTime}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">WebRTC 接続モード</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="webRtcMode"
                            name="webRtcMode"
                            label="WebRTC 接続モード"
                            input={<OutlinedInput fullWidth margin='dense' aria-label={"WebRTC 接続モード"} label={"WebRTC 接続モード"} color="primary" />}
                            value={state.webRtcMode}
                            onChange={handleChangeSelect}
                            fullWidth
                            required
                        >
                            <MenuItem value={0}>Meshを使用せず、すべての通話がSFU</MenuItem>
                            <MenuItem value={1}>ユーザー同士の接触がMesh</MenuItem>
                            <MenuItem value={2}>会議室がMesh</MenuItem>
                            <MenuItem value={3}>ユーザー同士の接触、会議室がMesh</MenuItem>
                            <MenuItem value={4}>全体放送がMesh</MenuItem>
                            <MenuItem value={5}>ユーザー同士の接触、全体放送がMesh</MenuItem>
                            <MenuItem value={6}>会議室、全体放送がMesh</MenuItem>
                            <MenuItem value={7}>ユーザー同士の接触、会議室、全体放送がMesh</MenuItem>
                        </Select>
                    </FormControl>
                    <br></br>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">フロアの使用目的</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="purposeOf_Use"
                            name="purposeOfUse"
                            label="フロアの使用目的"
                            input={<OutlinedInput fullWidth margin='dense' aria-label={"フロアの使用目的"} label={"フロアの使用目的"} color="primary" />}
                            value={state.purposeOfUse}
                            onChange={handleChangeSelect}
                            fullWidth
                            required
                        >
                            <MenuItem value={1}>オフィス</MenuItem>
                            <MenuItem value={2}>セミナー</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="webRtcApiKey"
                        name="webRtcApiKey"
                        label="Skyway接続用のAPIキー"
                        variant={'outlined'}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        value={state.webRtcApiKey}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                    <Button id="submit" onClick={handleUpdateFloor} color="primary">登録</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export const SystemEditFloorInfo = forwardRef(SystemEditFloorInfoComponent);
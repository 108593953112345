import React, { Fragment, useMemo, useEffect, useRef } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';

// import { User } from '../common/JsonClass';
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';
import { calcAvatarWidth, getAvatarVideoSize } from '../common/AvatarSize';
import { SELECT_NONE } from "../common/Constants";
import { SelectChatGroup, SelectChatGroupHandler } from './SelectChatGroup';


interface Props {
    className?: string;
    open: boolean;
    user: User;// otherUserの想定
    zIndex: number;
    isLarge: boolean;
    onClickClose: () => void;
    handleOpenChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => void;
    kind: null | string;
}

const SelectChatGroupCard: React.FC<Props> = (props) => {
    const selectChatGroupRef = useRef({} as SelectChatGroupHandler);

    // avatar要素の幅
    const avatarWidth = useMemo(() => {
        return calcAvatarWidth(props.user.width, props.isLarge);
    }, [props.user.width, props.isLarge]);

    // avatarの映像サイズ
    const avatarVideoHeight = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.9 * getAvatarVideoSize(props.user.isLargeVideo)  // 0.9は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    const avatarVideoWidth = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.4 * getAvatarVideoSize(props.user.isLargeVideo) // 0.4は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    /**
     * useEffect
     */
    // open時データ取得
    useEffect(() => {
        if (props.open) {
            selectChatGroupRef.current?.getDataAndDisplay();
        }
    }, [props.open])

    // ユーザーが移動したら閉じる
    useEffect(() => {
        if(props.kind != 'myroom'){
            props.onClickClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.x, props.user.y])

    /**
     * event handlers
     */
    const handleClickRefresh = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.open) {
            selectChatGroupRef.current?.getDataAndDisplay();
        }
    }

    const handleClickClose = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClickClose();
    }

    /**
     * draw elements
     */
    const drawCardHeader = useMemo(() => {
        return (
            <CardHeader
                className={props.className}
                title="チャット一覧"
                titleTypographyProps={{ variant: "body1", component: "h2" }}
                style={{ marginLeft: "10px", marginRight: "15px", marginTop: "4px", padding: 0, textAlign: "left" }}
                action={
                    <Fragment>
                        <IconButton size="small" onClick={handleClickRefresh}>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton size="small" onClick={handleClickClose}>
                            <CloseIcon />
                        </IconButton>
                    </Fragment>
                }
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const drawCardContent = useMemo(() => {
        return (
            <CardContent
                className={props.className}
                style={{
                    padding: 0,
                    width: 200,
                    minHeight: 100,
                    maxHeight: 200,
                    maxWidth: 345,
                    overflow: "auto"
                }}
            >
                <SelectChatGroup
                    ref={selectChatGroupRef}
                    subId={props.user.subId}
                    handleCloseParent={props.onClickClose}
                    handleOpenChat={props.handleOpenChat}
                />
            </CardContent>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.subId]);

    const component = useMemo(() =>
        props.open &&
        <ClickAwayListener onClickAway={handleClickClose}>
            <div>
                <Card
                    className={props.className}
                    style={{
                        ...SELECT_NONE,
                        position: 'absolute',
                        top: props.user.y - avatarVideoHeight,
                        left: props.user.x + avatarWidth + 10 + avatarVideoWidth,
                        opacity: 0.90,
                        zIndex: props.zIndex,
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                        width: 220,
                    }}
                >
                    <DialogTitle id='editChatGroupDialogTitle' style={{ padding: "2px 0px 6px 0px", background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }} >
                        {drawCardHeader}
                    </DialogTitle>
                    <DialogContent style={{padding:0}}>
                        {drawCardContent}
                    </DialogContent>
                </Card >
            </div>
        </ClickAwayListener>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [props.open, props.user.x, props.user.y, avatarWidth]
    );

    return (
        <Fragment>
            {component}
        </Fragment>
    )
}

export default SelectChatGroupCard;
import React, { Fragment } from 'react';
import MeetingRoom from "./MeetingRoom";
import {Props as MeetingRoomProps} from "./MeetingRoom";

interface Props extends MeetingRoomProps{
    isDrawText: boolean,
}

export default function MeetingRoomNoText(props: Props) {
    return (
        <Fragment>
            <MeetingRoom {...props}/>
        </Fragment>
    );
}
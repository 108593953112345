import React, { Fragment, useEffect, useMemo, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Slide, TextField, Typography, ListItem, createMuiTheme, Box, colors, IconButton, ListItemIcon, ListItemText, Icon, LinearProgress, InputAdornment } from '@material-ui/core';
import { Inbox, ModeCommentTwoTone } from '@material-ui/icons';
import { timeStamp } from 'console';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import { ThemeProvider } from '@material-ui/styles';
import { purple } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import DialogContentText from '@material-ui/core/DialogContentText';
import theme from './../../test/Thema';
import { Utility } from '../../common/Utility';
import { SELECT_NONE } from "../../common/Constants";
import { AvatarMenuData } from '../../common/JsonClass';
import JfsClient from '@fsi/jfs-sdk';

export class Office {
    id: number = 0;
    officeName: string = "";
    privacyRoom: boolean = false;
}

export class Floor {
    id: number = 0;
    floorName: string = "";
}

export class AvatarStatus {
  id: number = 0;
  avatarName: string = "";
  functionId: number = 0;
  authorityLevel: number = 0;
  color: string = "";
}

const LinearProgressStyle: React.CSSProperties = {
  ...SELECT_NONE,
  backgroundColor: 'rgba(0, 0, 255, 1)',
};

export default function MobileTop() {
    const { history } = useReactRouter();

    const [statusMessage, setStatusMessage] = useState("");
    const mobileAppCodeComponent = require('./mobileAppCode');
    const [tweetString, setTweetString] = useState("");
    const [tweetedString, setTweetedString] = useState("");
    const [goWorktime, setgoWorktime] = useState("");
    const [goLeavetime, setgoLeavetime] = useState("");
    const [avaterStatus, setavaterStatus] =useState("");
    const [statusList, setStatusList] = useState([] as AvatarStatus[]);
    const [userName, setUserName] = useState("");
    const [goWorkbuttonText, setGoWorkbuttonText] = useState("");
    const [leaveButtonText, setLeavebuttonText] = useState("");
    const [noMySeatMessage, setNoMySeatMessage] = useState("");
    const [networkStateMessage, setNetworkStateMessage] = useState("");

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#4caf50',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#f44336',
          contrastText: '#ffffff',
        },
      },
    });

    const commutingTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#757575',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#f44336',
          contrastText: '#ffffff',
        },
      },
    });
    const noCommutingTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#4caf50',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#757575',
          contrastText: '#ffffff',
        },
      },
    });

    useEffect(() => {
        async function handleIsSignin() {
          try {
            await Utility.requestOfficeList();
          } catch (err) {
            // signinなしで "/mobiletop" を指定した場合、"/" へ
            const signinpage = Utility.getSigninPage();
            Utility.checkErrorResponseOfOffice(err, () => history.push({ pathname: signinpage }));
          }
        }
        function getServerTime() {
          let params = new URLSearchParams();
            axios.post('/api/user/server/time', params)
              .then(response => {
                // setServerTime(response.data);
                const element = (
                  <Moment format="HH:mm:ss">
                    {response.data}
                  </Moment>
                );
                const element_day = (
                  <Moment format="YYYY/MM/DD">
                    {response.data}
                  </Moment>
                );
                const elementsrc = (
                  <div>
                    {response.data}
                  </div>
                );
                ReactDOM.render(elementsrc, document.getElementById('serverTime_ctn'));
                ReactDOM.render(element, document.getElementById('serverTime'));
                ReactDOM.render(element_day, document.getElementById('serverTime_day'));
              }).catch(err => {
                console.log(err);
              });
        }
        function viewDidloadTop(){
          mobileAppCodeComponent.viewDidload();
        }
        handleIsSignin();
        getServerTime();
        handleStatus();
        setTimeout(viewDidloadTop,1);
        const timer = setInterval(serverTimecounter,1000);

        function getAllAvaterStatus() {
          let tabID = sessionStorage.getItem("TABID") as string || "";
          let params = new URLSearchParams();
          params.append('tab_id', tabID.toString());
            axios.post('/api/user/avatar/status/list', params)
              .then(response => {
                console.log(response.data);
                setStatusList(response.data as AvatarStatus[])
              }).catch(err => {
                console.log(err);
                mobileAppCodeComponent.sessionTimeout(err);
              });
        }
        getAllAvaterStatus();

        window.addEventListener('focus', onWindowActive);
        window.addEventListener('blur', onWindowDeactive);
        window.addEventListener("online", setOnlineMessage);
        window.addEventListener("offline", setOfflineMessage);
        return () => {
          window.removeEventListener('focus', onWindowActive);
          window.removeEventListener('blur', onWindowDeactive);
          window.removeEventListener('online', setOnlineMessage);
          window.removeEventListener('offline', setOfflineMessage);
          clearInterval(timer);
        };
    }, [])

    const drawStatusList = useMemo(() => {
      return statusList.map((e) => {
        return <MenuItem value={e.id } style={{alignItems:'flex'}}>
          <img src={`/api/user/avatarmenu/picture/${e.id}`} 
          alt="" style={{ width: '32px', height: '25px', backgroundColor:'#556cd6',borderRadius:'50%',paddingTop:'7px'}}/>
          　<span style={{verticalAlign:'50%'}}> {e.avatarName}</span>
          </MenuItem>
    })
    },[statusList])

    const onWindowActive = () => {
      axios.post('/api/user/session/extend')
      .then((e: AxiosResponse) => {
        //TODO: setInterval 再開
      }).catch(err => {
        //TODO: セッションタイムアウト => 再サインイン
        mobileAppCodeComponent.sessionTimeout(err);
      });
    }
    const onWindowDeactive = () => {
      //TODO: 時計等の setInterval を停止 (バッテリーの消耗を抑える為)
    }

    const setOnlineMessage = () =>{
      setNetworkStateMessage("");
    }

    const setOfflineMessage = () =>{
      setNetworkStateMessage("ネットワークに接続していません");
    }

    //現在時刻の更新
    const serverTimecounter = () => {
      let servertime = document.getElementById("serverTime")?.innerText;
      let servvertime_ctn = document.getElementById("serverTime_ctn")?.innerText;
      if(servvertime_ctn ===''){
        servvertime_ctn = servertime;
      }
      let i = Date.parse(servvertime_ctn+'');
      i = i + 1000;
      servertime = new Date(i).toString();
      if(servertime!== 'Invalid Date'){
      const element = (
        <Moment format="HH:mm:ss">
          {servertime}
        </Moment>
      );
      const elementsrc = (
        <div>
          {servertime}
        </div>
      );
      ReactDOM.render(element, document.getElementById('serverTime'));
      ReactDOM.render(elementsrc, document.getElementById('serverTime_ctn'));
      }else{
        const element = (
          <div>

          </div>
        );
        ReactDOM.render(element, document.getElementById('serverTime'));
      }

    }
    
    const handleStatus = () => {
        //ステータスを取得
        function getStatus() {
            let tabID = sessionStorage.getItem("TABID") as string || "";
            let params = new URLSearchParams();
            params.append('tab_id', tabID.toString());
            axios.post('/api/user/avatar/status', params)
              .then(response => {
                console.log(response.data);
                //{displayName: '信原(昌)', status: '会議中', tubuyaki: 'プログラム中', commuting: false}
                setTweetedString(response.data.tubuyaki);
                setTweetString(response.data.tubuyaki);
                setavaterStatus(response.data.statusCode);
                setgoWorktime(response.data.startTime);
                setgoLeavetime(response.data.endTime);
                setStatusMessage("表示名：" + response.data.displayName + "　ステータスコード：" + response.data.statusCode + "　ステータス：" + response.data.status + "　つぶやき：" + response.data.tubuyaki + "　出退勤：" + (response.data.commuting ? "出勤中" : "退勤")+"　アバター表示："+response.data.isShowAvatar);
                if(!response.data.mySeat){
                  setNoMySeatMessage("自席設定がないため出退勤操作が行えません");
                }else{
                  setNoMySeatMessage("");
                }

                // if(response.data.stayFloor){
                //   setUserName(response.data.displayName + "さんはオフィスにいます");
                // }else{
                //   setUserName(response.data.displayName + "さんはオフィスにいません");
                // }
                setUserName(response.data.displayName + "さん");

                if(response.data.commuting){
                  const workButtonElement = (
                    <ThemeProvider  theme={commutingTheme}><Button style={{height:'140px',width:'140px', fontSize: '20px'}}
                    onClick={handleGoWork} variant="contained" color="primary">
                      <div>
                      出勤済
                    <div id ="gotimelabel" style={{fontSize: '15px'}}></div></div>
                    </Button></ThemeProvider>
                  );
                  const leaveButtonElement = (
                    <ThemeProvider  theme={commutingTheme}><Button style={{height:'140px',width:'140px', fontSize: '20px'}} 
                    onClick={handleGoLeave} variant="contained" color="secondary">
                  <div>
                  退勤
                  <div id ="leavetimelabel" style={{fontSize: '15px'}}></div></div>
                  </Button></ThemeProvider>
                  );
                  ReactDOM.render(leaveButtonElement, document.getElementById('leaveButton'));
                  ReactDOM.render(workButtonElement, document.getElementById('workButton'));
                  setGoWorkbuttonText("出勤済");
                  setLeavebuttonText("退勤");
                }else{
                  const workButtonElement = (
                    <ThemeProvider  theme={noCommutingTheme}><Button style={{height:'140px',width:'140px', fontSize: '20px'}}
                    onClick={handleGoWork} variant="contained" color="primary">
                      <div>
                      出勤
                    <div id ="gotimelabel" style={{fontSize: '15px'}}></div></div>
                    </Button></ThemeProvider>
                  );
                  const leaveButtonElement = (
                    <ThemeProvider  theme={noCommutingTheme}><Button style={{height:'140px',width:'140px', fontSize: '20px'}} 
                    onClick={handleGoLeave} variant="contained" color="secondary">
                    <div>
                    退勤済
                    <div id ="leavetimelabel" style={{fontSize: '15px'}}></div></div>
                  </Button></ThemeProvider>
                  );
                    ReactDOM.render(leaveButtonElement, document.getElementById('leaveButton'));
                  ReactDOM.render(workButtonElement, document.getElementById('workButton'));
                  setGoWorkbuttonText("出勤");
                  setLeavebuttonText("退勤済");
                }

                if(response.data.startTime === ''){
                  const elementgotime = (
                    <div>
                      --:--
                      </div>
                  );
                  ReactDOM.render(elementgotime, document.getElementById('gotimelabel'));
                }else{
                  const elementgotime = (
                    <Moment format="HH:mm">
                      {response.data.startTime}
                    </Moment>
                  );
                  ReactDOM.render(elementgotime, document.getElementById('gotimelabel'));
                }

                if(response.data.endTime === ''){
                  const elementgotime = (
                    <div>
                    --:--
                    </div>
                  );
                  ReactDOM.render(elementgotime, document.getElementById('leavetimelabel'));
                }else{
                  const elementgotime = (
                    <Moment format="HH:mm">
                      {response.data.endTime}
                    </Moment>
                  );
                  ReactDOM.render(elementgotime, document.getElementById('leavetimelabel'));
                }

              }).catch(err => {
                console.log(err);
              });
        }

        getStatus();
    }

    const handleGoWork = () => {
      //出勤
        function goWork() {
            let tabID = sessionStorage.getItem("TABID") as string;
            let params = new URLSearchParams();
            // params.append('tab_id', tabID.toString());
            // axios.post('/api/user/work/go', params)
            //   .then(response => {
            //     if(response.data === "NOMYSEAT"){
            //       // 自席がないときはNOMYSEATが返ってくる
            //     }
            //     if(response.data.attendanceTime2){
            //     setgoWorktime(response.data.attendanceTime2);
            //     }
            //     handleStatus()
            //   }).catch(err => {
            //     console.log(err);
            //   });
            httpClient.changeMyCommuting(sessionStorage.getItem("TABID") as string)
              .then(response => {
                console.log('######## OK');
                // 302 を検知したらログアウト
                // content-type が取得できずエラーになるので、一旦回避
                try {
                  if (Utility.checkRedirectAndLogout(response, history)) {
                    return;
                  }
                } catch(err) {
                  console.log('######## ERR1');
                  // return;
                }

                if (response === "NOMYSEAT") {
                  // 自席がないときはNOMYSEATが返ってくる
                }
                if (response.attendanceTime2) {
                  // setgoWorktime(response.data.attendanceTime2);
                }
              });
        }
        goWork();
        setTimeout(handleStatus,10);
    }

    const handleGoLeave = () => {
      //退勤
        function goLeave() {
            let tabID = sessionStorage.getItem("TABID") as string;
            let params = new URLSearchParams();
            params.append('tab_id', tabID.toString());
            axios.post('/api/user/work/leave', params)
              .then(response => {
                if(response.data === "NOMYSEAT"){
                  // 自席がないときはNOMYSEATが返ってくる
                }
                if(response.data.attendanceTime2){
                setgoLeavetime(response.data.attendanceTime2)
                }
                handleStatus()
              }).catch(err => {
                console.log(err);
              });
        }

        goLeave();
        setTimeout(handleStatus,10);
    }

    const handleChangeTubuyaki = () => {
      if (textLength > MAX_LENGTH) {
        return;
      }
      //つぶやき変更
        function ChangeTubuyaki() {
            let tabID = sessionStorage.getItem("TABID") as string;
            let params = new URLSearchParams();
            params.append('tab_id', tabID.toString());
            params.append('tubuyaki', tweetString)
            params.append('status', avaterStatus)
            axios.post('/api/user/avatar/status/change', params)
              .then(response => {
                handleClose();
                handleStatus();
              }).catch(err => {
                handleClose();
                console.log(err);
              });
        }
        
        handleStatus();
        ChangeTubuyaki();
        setTimeout(handleStatus,10);
    }

    const useStyles = makeStyles((theme) => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    }));

    const classes = useStyles(); 

    const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    //状況ステータス変更
    function changeStatus(){
      setavaterStatus(event.target.value as string);
      let tabID = sessionStorage.getItem("TABID") as string;
      let params = new URLSearchParams();
      params.append('tab_id', tabID.toString());
      params.append('tubuyaki', tweetString)
      params.append('status', event.target.value as string)
      axios.post('/api/user/avatar/status/change', params)
        .then(response => {
          setavaterStatus(event.target.value as string);
          handleStatus();
        }).catch(err => {
          console.log(err);
        });
      }
      handleStatus();
      setTimeout(changeStatus,1);
    };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setTweetString(tweetedString);
    setOpen(true);
  };
  const handleClose = () => {
    setTimeout(handleStatus,1)
    setOpen(false);
  };
  const handleClear = () => {
    setTweetString("")
  };
  const handleDelete = () =>{
    let tabID = sessionStorage.getItem("TABID") as string;
            setTweetString("");
            let params = new URLSearchParams();
            params.append('tab_id', tabID.toString());
            params.append('tubuyaki', "")
            params.append('status', avaterStatus)
            axios.post('/api/user/avatar/status/change', params)
              .then(response => {
                handleClose();
                handleStatus();
              }).catch(err => {
                handleClose();
                console.log(err);
              });
  }

  const handleLogout = () => {
    mobileAppCodeComponent.didLogout();
  };


  const judgehz = (intext: string) => {
    let len = 0
    if (intext === null) return len;
    let text = intext.split('')
  
    // 半角全角判定
    for (let i = 0; i < intext.length; i++) {
        if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
            len = len + 0.5     // 半角
        } else {
            len = len + 1       // 全角
        }
    }
  
    return len
  };
    
  const textLength = judgehz(tweetString.trim().replace(/\n/g, "") );

  const MAX_LENGTH = 35;
  const handleChange = (event: any) => {
    const _text = textLength >= MAX_LENGTH ? event.target.value.trim() : event.target.value;
    const subStr1 = _text.substring(0, MAX_LENGTH*2);
    setTweetString(subStr1);
}

const textLengthCheck = useMemo(() => {
  let len = judgehz( tweetString.trim().replace(/\n/g, "") );
  if (len > MAX_LENGTH) {
    return (
      <div style={{ color:"red" }}>入力した文字列が長すぎます。</div>
    );
  }
}, [textLength]);

const progressBar = useMemo(() => {
  let parsent = (textLength/MAX_LENGTH) * 100;
  let progressColor = 'rgba(0, 0, 255, 0.3)';

  // 入力文字数超過した場合はバーが赤色になる
  if(parsent > 100){
      parsent = 200;
      progressColor = 'rgba(255, 0, 0, 1)';
  }else{
      progressColor = 'rgba(0, 0, 255, 0.3)';
  }

  return (
      <Grid container >
          <div style={{ flexGrow: 1 }}></div>
          <Grid style={{ padding: "10px 0px", width: "30%" }}>
              <LinearProgress 
                  variant="determinate"
                  value={parsent}
                  style={{
                      ...LinearProgressStyle,
                      backgroundColor: progressColor,
                  }}
              />
          </Grid>
      </Grid>
  );
},[tweetString])


    return (
      
        <Fragment>
          <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography  style={{fontSize: '15px', padding:'5px'}} >オフィスの現在時刻</Typography>
            <Typography style={{fontSize: '15px', padding:'2px'}} id = "serverTime_day">0000/00/00</Typography>
            <Typography  style={{fontSize: '25px'}} id = "serverTime">00:00:00</Typography>
            <Typography style={{fontSize: '15px', padding:'5px', color:"red"}}>{noMySeatMessage}</Typography>
            <Typography style={{fontSize: '15px', padding:'5px', color:"red"}}>{networkStateMessage}</Typography>
            <Typography style={{fontSize: '15px', padding:'10px'}}>ようこそ{userName}</Typography>
            </Grid>
            {/* <ThemeProvider theme={theme}> */}
            <Grid id ="workButton" item xs={6}>
            <Button style={{height:'140px',width:'140px', fontSize: '20px'}}
            onClick={handleGoWork} variant="contained" >
              <div>
              {goWorkbuttonText}
            <div id ="gotimelabel" style={{fontSize: '15px'}}>
            </div>
            </div>
            </Button>
            </Grid>
            <Grid id="leaveButton" item xs={6}>
            <Button style={{height:'140px',width:'140px', fontSize: '20px'}} 
            onClick={handleGoLeave} variant="contained" >
              <div>
              {leaveButtonText}
              <div id ="leavetimelabel" style={{fontSize: '15px'}}>
              </div>
              </div>
            </Button> 
            </Grid>
            {/* </ThemeProvider> */}
          </Grid>
          </div>
          <div>
        <Box m={3}>
        <FormControl className={classes.formControl}
        style={{verticalAlign:'top'}}
        >
        <InputLabel id="demo-simple-select-label">ステータス</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={avaterStatus}
          style={{height:'32px'}}
          onChange={handleChangeStatus}
        >
          <MenuItem value={0}>　</MenuItem>
          {drawStatusList}
        </Select>
      </FormControl>
      </Box>
          </div>
          <TextField
          id="tweetTextfield"
          label="つぶやき内容"
          multiline
          value={tweetedString}
          InputProps={{
            readOnly: true,
          }}
          style={{width:'320px'}}
          onClick={handleClickOpen}
          variant="outlined"
        />
          <div> 
            {/* つぶやきダイアログ表示 */}
      <Dialog 
      disableBackdropClick 
      disableEscapeKeyDown 
      open={open} 
      onClose={handleClose}
      transitionDuration={{ enter: 0 }}
      >
        <DialogTitle  style={{ padding: "15px 25px 5px 25px" }} >つぶやく</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
            <DialogContentText style={{ marginBottom: "0px" }} >
                        {textLengthCheck}
                        {progressBar}
                    </DialogContentText>
              <TextField id="standard-required" 
                          label="つぶやき"
                          multiline
                          value = {tweetString}
                          autoFocus={true}
                          onChange={handleChange}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              // エンターキー押下時の処理
                              e.preventDefault();
                              handleChangeTubuyaki();
                            }
                          }}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                          }} />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            消去
          </Button>
          <Button onClick={handleChangeTubuyaki} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
          </div>
          <div>
            <Button onClick = {handleLogout} id = "signout" color = "primary">サインアウト</Button>
          </div>

            {/* <Typography>以下の情報はデバッグ用です。今後表示を削除する予定です</Typography>
          <Divider component="li" />
          <Grid item xs={12}>
            <Button onClick={handleStatus} variant="contained" color="primary">ステータス</Button>
            </Grid>
          <div>
            <Button onClick = {handleTimeout} id = "timeoutBtn" color = "primary">タイムアウト</Button>
          </div>

          <div>
            <Typography variant="body1">{statusMessage}</Typography>
          </div> */}
          {/* <div>
            <Typography variant="body1">外出先でもFAMofficeに出勤！</Typography>
            <Typography variant="body1">アプリでアバターが出勤します。ステータス設定、つぶやきをして状況をシェアしましょう。作業終了時は忘れずに退勤しましょう。</Typography>
          </div> */}
          
          <Typography id = "serverTime_ctn" style={{fontSize: '0px'}}></Typography>
          <p style={{textAlign:'right',color:'lightgrey'}}>Ver.0.0.2</p>
        </Fragment>
    )
}
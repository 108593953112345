import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { AvatarMenuData, Size } from '../common/JsonClass';
import UserInfoCard from "./UserInfoCard"
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideoOffIcon from '@material-ui/icons/VideocamOff';
import axios, { AxiosResponse } from 'axios';

import TubuyakiImg from '../img/tubuyaki_balloon.png'
import FukidasiEffect from './FukidasiEffect';
import { textRuler } from "../common/TextRuler";
import { AVATAR_WIDTH_GAIN, AVATAR_HEIGHT_GAIN } from "../common/AvatarSize";
import { calcAvatarWidth, calcAvatarHeight, getAvatarVideoSize } from "../common/AvatarSize";
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import ZIndex from "../common/ZIndex";
import SelectMemoGroupCard from "./SelectMemoGroupCard"
import StudyTimer from "./StudyTimer";
import { Tooltip, Typography } from '@material-ui/core';
import SelectChatGroupCard from "./SelectChatGroupCard"
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';

/**
 * ユーザ状態.
 */
export enum UserState {
    None,       // 状態なし
    Eating,     // 食事中
    Meeting,    // 会議中
    Calling,    // 電話中
    OutNow,     // 外出中
    OutSeatNow, // 離席中
    SayGood,    // いいね
    SayBad,     // だめね
}

/**
 * ユーザ状態(文字列変換).
 * @param isDummyTalking webRtcRoomId が空文字ではなく、かつ isStayFloor が false の場合のみ true とする (通話中 + ダミーアバター)
 */
export function toStrUserState(avatarMenuDataList: AvatarMenuData[], state: UserState, isDummyTalking: boolean) {
    if (isDummyTalking) {
        return "会話中";
    }
    let temp = avatarMenuDataList.filter(e => e.avatarMenuMasterId === state);
    if(temp.length > 0) {
        return temp[0].avatarName;
    }else{
        return "";
    }
}

/**
 * 状況設定(絵文字取得).
 * @param isDummyTalking webRtcRoomId が空文字ではなく、かつ isStayFloor が false の場合のみ true とする (通話中 + ダミーアバター)
 */
 export function getPictograph(avatarMenuDataList: AvatarMenuData[], state: UserState, isDummyTalking: boolean) {
    if (isDummyTalking) {
        return "会話中";
    }
    let temp = avatarMenuDataList.filter(e => e.avatarMenuMasterId === state && e.pictograph !== '');
    if(temp.length > 0) {
        return temp[0].pictograph;
    }else{
        return "";
    }
}

const useStyles = makeStyles((theme) => ({
    userStatus: {
        ...SELECT_NONE,
        position: 'absolute',
        borderRadius: '20%',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        color: 'rgb(255,255,255)',
    },
    studyTimer: {
        ...SELECT_NONE,
        position: 'absolute',
        borderRadius: '20%',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        fontWeight: 'bold',
    },
    tubuyakiTextDiv: {
        ...SELECT_NONE,
        position: 'absolute',
        userSelect: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tubuyakiTitle: {
        ...SELECT_NONE,
        padding: "2px 0px 2px 0px",
        marginTop: -10,
        marginLeft: -12,
        background: '#57BBFF 0% 0% no-repeat padding-box',
        borderRadius: '20px 20px 0 0',
        width: 214
    },
    tubuyakiText: {
        ...SELECT_NONE,
        lineHeight: 1.05,
        wordWrap: 'break-word', // 折り返し
        whiteSpace: 'pre-line', // 改行を表示に反映
        textAlign: 'center',
        maxWidth: "100%",
        maxHeight: "100%"
    },
    displayName: {
        ...SELECT_NONE,
        position: 'absolute',
        whiteSpace: 'nowrap',
        height: 16,
        userSelect: 'none',
        borderRadius: '15%',
        pointerEvents: 'none',
    },
    AudioMuteIcon: {
        ...SELECT_NONE,
        backgroundColor: '#00d4be',
        opacity: 0.8,
        borderRadius: '50%',
        boxSizing: 'border-box',
        userSelect: 'none',
        cursor: 'auto',
        //transitionDuration:'1s',
    },
    AVMuteIcon: {
        ...SELECT_NONE,
        backgroundColor: '#006FBC',
        opacity: 0.8,
        borderRadius: '50%',
        boxSizing: 'border-box',
        userSelect: 'none',
        cursor: 'auto',
        //transitionDuration:'1s',
    },
    AVMuteIcon2: {
        ...SELECT_NONE,
        //backgroundColor: '#cc3322',
        //backgroundColor: '#111111',
        backgroundColor: '#331111',
        opacity: 0.8,
        borderRadius: '50%',
        boxSizing: 'border-box',
        userSelect: 'none',
        cursor: 'auto',
        //transitionDuration:'1s',
    },
    blink: {
        animation: '$blink-animation 1s steps(1, end) infinite',
    },
    '@keyframes blink-animation' : {
        '0%': {opacity: 1},
        '49.99%': {opacity: 1},
        '50%': {opacity: 0},
        '100%': {opacity: 0},
    },    
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '6px solid #57BBFF',
      borderRadius: '25px',
    },
}))(Tooltip);

interface Props {
    user: User,
    openProfile: boolean,
    onClickUserState?: () => void,
    onClickTubuyaki: any,
    onClickProfile: any,
    zIndex: number,
    isLarge: boolean,
    isMyUser: boolean,
    avatarMenuDataList: AvatarMenuData[],
    openSelectMemoGroup: boolean,
    openSelectChatGroup: boolean,
    onClickSelectMemoGroup: () => void,
    onClickSelectChatGroup: () => void,
    handleOpenMemo: (groupId: number, groupName: string) => void,
    handleOpenChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => void;
    enabledBusinessCard: boolean,
    kind: null | string,
    floorSize: Size,
}

export default function BaseUser(props: Props) {
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const classes = useStyles();
    const IMG_ASPECT_RATIO = 820/617; // 画像の 幅 ÷ 高さ
    // フロントエンドで動かすタイマーはBaseUserではなく,MyUserに変更
    // →1:タイマーの描画をcountではなく、user.studyElapsedTimeで行うため
    // →2:他人アバターに対してもタイマー稼働させることは非効率(処理が重くなりそうだから)
    // const {count, timeEnd, isTimerStop, isTimerEnd, startCount, stopAndRestartCount, setEnd, initCount} = StudyTimer();

    // avatar要素のtop
    const avatarTop = useMemo(() => {
        return props.user.y - (props.isLarge ? AVATAR_HEIGHT_GAIN / 2 : 0)
    }, [props.user.y, props.isLarge]);

    // avatar要素のleft
    const avatarLeft = useMemo(() => {
        return props.user.x - (props.isLarge ? AVATAR_WIDTH_GAIN / 2 : 0)
    }, [props.user.x, props.isLarge]);

    // avatar要素の幅
    const avatarWidth = useMemo(() => {
        return calcAvatarWidth(props.user.width, props.isLarge);
    }, [props.user.width, props.isLarge]);

    // avatar要素の高さ
    const avatarHeight = useMemo(() => {
        return calcAvatarHeight(props.user.height, props.isLarge);
    }, [props.user.height, props.isLarge]);

    // avatarの映像サイズ
    const avatarVideoHeight = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.9 * getAvatarVideoSize(props.user.isLargeVideo) // 0.9は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    const avatarVideoWidth = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.3 * getAvatarVideoSize(props.user.isLargeVideo) // 0.3は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    // 状態表示
    const isDummyTalking = useMemo(() => {
        if(props.kind === 'myroom'){
            return props.user.webRtcRoomId !== '' && props.user.webRtcRoomId !== null;
        }
        if(props.user.privacyRoomInfo.stayPrivacyroom){
            return false;
        }
        return props.user.isStayFloor === false && props.user.webRtcRoomId !== '';
    }, [props.user.webRtcRoomId, props.user.isStayFloor, props.kind]);

    const usetStateText = useMemo(() => {
        return toStrUserState(props.avatarMenuDataList, props.user.state, isDummyTalking);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.state, isDummyTalking]);

    const STATE_FONTSIZE = useMemo(() => {
        if (usetStateText.length <= 7) {
            return 12;
        } else {
            return 11;
        } 
    }, [usetStateText]);

    const STATE_WIDTH = useMemo(() => {
        const padding = 5; // 位置計算で用いるため、CSS側には定義していない。
        return usetStateText.length > 0 ? textRuler(usetStateText, { fontSize: `${STATE_FONTSIZE}px`, padding: `0 ${padding}px` }) : 0;
    }, [usetStateText, STATE_FONTSIZE]);

    // 絵文字取得
    const usePictograph = useMemo(() => {
        return getPictograph(props.avatarMenuDataList, props.user.state, isDummyTalking);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.state, isDummyTalking]);

    // 絵文字の文字サイズ
    const STATE_PICTOGRAPH_FONTSIZE = useMemo(() => {
        return 35;
    }, [usePictograph]);

    // 絵文字の横幅
    const STATE_PICTOGRAPH_WIDTH = useMemo(() => {
        const padding = 5; // 位置計算で用いるため、CSS側には定義していない。
        return usePictograph.length > 0 ? textRuler(usePictograph, { fontSize: `${STATE_PICTOGRAPH_FONTSIZE}px`, padding: `0 ${padding}px` }) : 0;
    }, [usePictograph, STATE_PICTOGRAPH_FONTSIZE]);

    // 絵文字利用有無判定
    const isPictograph = useMemo(() => {
        let temp = props.avatarMenuDataList.filter(e => e.avatarMenuMasterId === props.user.state && e.pictograph !== '');
        if(temp.length > 0){
            return true;
        }
        return false;
    }, [props.user.state]);

    const drawState = useMemo(() => {
        let bgColor = '';
        const elementHeight = 18; // 状態表示要素の高さ。位置計算で用いるため、CSS側には定義していない。

        // マイルーム内で退勤している人(オフラインではない人)はステータスを出さない
        if(props.kind === 'myroom' && (props.user.isCommuting === false && props.user.validWebSocketHostMaster === false)) {
            return;
        }

        // 絵文字が存在しない場合
        if (!isPictograph){
            if (isDummyTalking) {
                bgColor = 'rgba(237, 125, 49, 0.9)';
            } else {
                let temp = props.avatarMenuDataList.filter(e => e.avatarMenuMasterId === props.user.state);
                if(temp.length > 0) {
                    bgColor = temp[0].color;
                }
            }
            return (
                <div
                    className={`${classes.userStatus} ${NEED_TO_POINTER_CAPTURE}`}
                    style={{
                        top: avatarTop - elementHeight + 2 - avatarVideoHeight, // 2は微調整用
                        left: avatarLeft + avatarWidth/2 - STATE_WIDTH/2, // avatarの中心と揃える
                        width: STATE_WIDTH,
                        height: elementHeight,
                        backgroundColor: bgColor,
                        zIndex: props.zIndex,
                        fontSize: STATE_FONTSIZE
                    }}
                    onClick={props.onClickUserState}
                    hidden={usetStateText === ''}
                >
                    {usetStateText}
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.state, props.user.webRtcRoomId, avatarWidth, avatarTop, avatarLeft, avatarVideoHeight, STATE_FONTSIZE, STATE_WIDTH, props.kind, props.user.isCommuting, props.user.validWebSocketHostMaster])

    // 自習タイマーの描画
    const drawStudyTimer = useMemo(() => {
        const elementHeight = 18; // 状態表示要素の高さ。位置計算で用いるため、CSS側には定義していない。
        // 今後もデバッグで利用するので、以下はコメントアウトでとどめる
        // console.info("isStudy："+props.user.isStudy+", count："+count);
        // console.info("BaseUser props.user.studyElapsedTime(分単位):"+props.user.studyElapsedTime);

        if(props.user.isStudy){
            return (
                <>
                    <div
                        className={`${classes.studyTimer} ${NEED_TO_POINTER_CAPTURE}`}
                        style={{
                            top: props.user.state === 0 ? avatarTop - elementHeight + 2 - avatarVideoHeight : avatarTop - elementHeight + 2 - avatarVideoHeight - 21, // 2は微調整用
                            left: avatarLeft + avatarWidth/2 - 50/2, // avatarの中心と揃える
                            width: 50,
                            height: elementHeight,
                            zIndex: props.zIndex + 2,
                            border: props.user.isTimer ? '2px solid #000000' : '2px solid #DCDCDC',
                            backgroundColor: props.user.isTimer ? '#DCDCDC' : '#FFFFFF',
                            color: props.user.isTimer ? '#000000' : '#DCDCDC',
                            fontSize: 11
                        }}
                    >
                        {/*こちらはstudyElapsedTimeの単位が秒のとき、今は分単位 Math.floor(props.user.studyElapsedTime/3600)+ "：" + (Math.floor((props.user.studyElapsedTime - Math.floor(props.user.studyElapsedTime/3600)*3600)/60)) + "：" + (props.user.studyElapsedTime - Math.floor((props.user.studyElapsedTime - Math.floor(props.user.studyElapsedTime/3600)*3600)/60)*60)*/}
                        {("00"+Math.floor(props.user.studyElapsedTime/60)).slice(-2)}
                        {props.user.isTimer? <span className={classes.blink}>:</span> : <span>:</span>}
                        {("00"+(props.user.studyElapsedTime - Math.floor(props.user.studyElapsedTime/60)*60)).slice(-2) }
                    </div>
                    {!props.user.isTimer && 
                        <div
                            className={`${classes.studyTimer} ${NEED_TO_POINTER_CAPTURE}`}
                            style={{
                                top: props.user.state === 0 ? avatarTop - elementHeight + 2 - avatarVideoHeight : avatarTop - elementHeight + 2 - avatarVideoHeight - 21, // 2は微調整用
                                left: avatarLeft + avatarWidth/2 - 50/2, // avatarの中心と揃える
                                width: 50,
                                height: elementHeight,
                                zIndex: props.zIndex + 2,
                                color: '#000000',
                                fontSize: 13
                            }}
                        >
                            {"| |"}
                        </div>
                    }
                </>
            );
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.studyElapsedTime, props.user.state, props.user.isStudy, props.user.isTimer, avatarWidth, avatarTop, avatarLeft, avatarVideoHeight])

    // 状況設定にて絵文字が存在する場合
    const drawPictograph = useMemo(() => {
        let bgColor = '';
        const elementHeight = 50; // 状態表示要素の高さ。位置計算で用いるため、CSS側には定義していない。

        // 絵文字が存在する場合
        if (isPictograph){
            if (isDummyTalking) {
                bgColor = 'rgba(237, 125, 49, 0.9)';
            } else {
                let temp = props.avatarMenuDataList.filter(e => e.avatarMenuMasterId === props.user.state);
                if(temp.length > 0) {
                    bgColor = temp[0].color;
                }
            }
            return (
                <div
                    className={`${classes.userStatus} ${NEED_TO_POINTER_CAPTURE}`}
                    style={{
                        top: avatarTop - elementHeight + 2 - avatarVideoHeight, // 2は微調整用
                        left: avatarLeft + avatarWidth/2 - STATE_PICTOGRAPH_WIDTH/2, // avatarの中心と揃える
                        width: STATE_PICTOGRAPH_WIDTH,
                        height: elementHeight,
                        backgroundColor: bgColor,
                        zIndex: props.zIndex,
                        fontSize: STATE_PICTOGRAPH_FONTSIZE
                    }}
                    onClick={props.onClickUserState}
                    hidden={usePictograph === ''}
                >
                    {usePictograph}
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.state, props.user.webRtcRoomId, avatarWidth, avatarTop, avatarLeft, avatarVideoHeight, STATE_PICTOGRAPH_FONTSIZE, STATE_PICTOGRAPH_WIDTH])

    // つぶやき
    const tubuyakiTextLength = useMemo(() => {
        const text = props.user.tubuyaki.replace("/n", "");
        return text.length
    }, [props.user.tubuyaki]);

    const tubuyakiFontsize = useMemo(() => {
        if (tubuyakiTextLength <= 1) {
            return 13;
        } else if (tubuyakiTextLength <= 2) {
            return 12;
        } else if (tubuyakiTextLength <= 3) {
            return 11;
        } else {
            return 10;
        }
    }, [tubuyakiTextLength]);

    const tubuyakiWidth = useMemo(() => {
        if (tubuyakiTextLength <= 5) {
            return 60;
        } else if (tubuyakiTextLength <= 10) {
            return 66;
        } else {
            return 80;
        }
    }, [tubuyakiTextLength]);

    const tubuyakiTextDivWidth = useMemo(() => {
        return tubuyakiWidth * 0.73; // 0.73: 画像幅に対するテキスト領域幅の比率
    }, [tubuyakiWidth]);

    const tubuyakiTextDivHeight = useMemo(() => {
        return tubuyakiWidth / IMG_ASPECT_RATIO * 0.47; // 0.47: 画像高さに対するテキスト領域高さの比率
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tubuyakiWidth]);

    const tubuyakiTextDivTop = useMemo(() => {
        return tubuyakiTextDivHeight * 0.22; // 0.22: 画像高さに対するtop位置の比率
    }, [tubuyakiTextDivHeight]);

    const tubuyakiTextDivLeft = useMemo(() => {
        return tubuyakiWidth * 0.125; // 0.125: 画像幅に対するleft位置の比率
    }, [tubuyakiWidth]);

    const getViewMonologyText = useMemo(() => {
        let retstring = props.user.tubuyaki;
        const count = ( retstring.match( /\n/g ) || [] ).length ; // 改行文字の個数 
        const _text = props.user.tubuyaki.replace(/\n/g, ""); // 改行文字なしのテキスト
        //console.log(retstring.length)    
        if (_text.length > 14) {
            const tempCount = retstring.endsWith("\n") ? count -1 : count;
            retstring = `${retstring.substr(0, 14 + tempCount)}...`;
        }

        return retstring;
    }, [props.user.tubuyaki]);

    const drawTubuyaki = useMemo(() => {
        // マイルームで透明状態の時はつぶやきを出さない
        if(props.kind === 'myroom' && (props.user.isCommuting === false && props.user.validWebSocketHostMaster === false)) {
            return;
        }

        if (props.user.tubuyaki === "" || props.user.tubuyaki === undefined) return;
        return (
            <div
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    width: tubuyakiWidth,
                    top: avatarTop - 20 - avatarVideoHeight,
                    left: avatarLeft + 12 - tubuyakiWidth - avatarVideoWidth,
                    zIndex: props.zIndex + 1,
                }}
            >
                <img 
                    src={TubuyakiImg} 
                    className={NEED_TO_POINTER_CAPTURE}
                    onMouseDown={e => e.preventDefault()} // window外へのgif画像コピー禁止
                    onMouseMove={e => e.preventDefault()} // window外へのgif画像コピー禁止
                    style={{ ...SELECT_NONE, width: tubuyakiWidth }}
                    alt="tubuyaki" />
                    <HtmlTooltip disableFocusListener disableTouchListener 
                        title={
                            <Fragment>
                                <DialogTitle id='editChatGroupDialogTitle' className={classes.tubuyakiTitle}>
                                    <div style={{marginTop: '5px', marginBottom: '5px', marginLeft: '15px', fontSize: '16px', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>
                                        つぶやき
                                    </div>
                                </DialogTitle>
                                <div style={{clear: "both", marginBottom: '10px', marginLeft: '5px'}}>
                                    <Typography variant="body2">{props.user.tubuyaki}</Typography>
                                </div>
                            </Fragment>
                        } 
                        placement="top" 
                        style={{backgroundColor: "white"}}
                    >
                <div
                    className={classes.tubuyakiTextDiv}
                    style={{ 
                        position: "absolute",
                        width: tubuyakiTextDivWidth,
                        height: tubuyakiTextDivHeight,
                        top: tubuyakiTextDivTop,
                        left: tubuyakiTextDivLeft,
                        fontSize: tubuyakiFontsize,
                    }}
                    onClick={props.onClickTubuyaki}
                >
                    <div className={classes.tubuyakiText}>
                        {getViewMonologyText}
                    </div>
                </div>
                </HtmlTooltip>
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.tubuyaki, avatarTop, avatarLeft, avatarVideoHeight, avatarVideoWidth, props.kind, props.user.isCommuting, props.user.validWebSocketHostMaster])


    // 名前
    const NAME_FONTSIZE = useMemo(() => {
        const name = props.user.displayName;
        if (name.length <= 6) {
            return 12;
        } else if (name.length <= 7) {
            return 11;
        } else {
            return 10;
        }
    }, [props.user.displayName]);

    const NAME_WIDTH = useMemo(() => {
        const name = props.user.displayName;
        const padding = 5; // 位置計算で用いるため、CSS側には定義していない。
        return name.length > 0 ? textRuler(name, { fontSize: `${NAME_FONTSIZE}px`, padding: `0 ${padding}px` }) : 0;
    }, [props.user.displayName, NAME_FONTSIZE]);

    const drawName = useMemo(() => {
        return (
            <div
                className={classes.displayName}
                style={{
                    top: avatarTop + avatarHeight, // avatar画像下部に配置
                    left: avatarLeft + avatarWidth / 2 - NAME_WIDTH / 2, // 中心揃え
                    width: NAME_WIDTH,
                    fontSize: NAME_FONTSIZE,
                    color: props.isMyUser ? "#fff" : "#000",
                    backgroundColor: props.isMyUser ? "#556cd6" : "rgba(255, 255, 255, 0.8)",
                    zIndex: props.zIndex,
                    // boxShadow: props.isMyUser ? "3px 3px 2px 1px rgba(0, 0, 255, 0.3)" : ""
                }}
            >
                {props.user.displayName}
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarTop, avatarLeft, avatarWidth, avatarHeight, NAME_WIDTH, NAME_FONTSIZE])

    // 名札表示の改修
    const drawNameKai = useMemo(() => {
        // TODO: 他人から見た場合は枠線をなくして自分から見た場合は枠線を残す
        return (
            props.user.flexibleNamePlate != null ?
            <div
                className={classes.displayName}
                style={{
                    top: avatarTop + avatarHeight, // avatar画像下部に配置
                    left: avatarLeft + avatarWidth / 2 - NAME_WIDTH / 2, // 中心揃え
                    width: NAME_WIDTH,
                    fontSize: NAME_FONTSIZE,
                    color: props.isMyUser ? "#fff" : `${props.user.flexibleNamePlate.textColor}`,
                    backgroundColor: props.isMyUser ? "#57BBFF" : `rgba(${props.user.flexibleNamePlate?.rgba})`,
                    zIndex: props.zIndex,
                    // outline: props.isMyUser ? `2px solid ${props.user.flexibleNamePlate?.hex}` : '',
                    // boxShadow: props.isMyUser ? "3px 3px 2px 1px rgba(0, 0, 255, 0.3)" : ""
                    border: props.isMyUser ? `1px solid ${props.user.flexibleNamePlate?.hex}` : '',
                    lineHeight: props.isMyUser ? 1.2 : '',
                }}
            >
                {props.user.displayName}
            </div>
            :
            <div
                className={classes.displayName}
                style={{
                    top: avatarTop + avatarHeight, // avatar画像下部に配置
                    left: avatarLeft + avatarWidth / 2 - NAME_WIDTH / 2, // 中心揃え
                    width: NAME_WIDTH,
                    fontSize: NAME_FONTSIZE,
                    color: props.isMyUser ? "#fff" : "#000",
                    backgroundColor: props.isMyUser ? "#57BBFF" : "rgba(255, 255, 255, 0.8)",
                    zIndex: props.zIndex,
                    // boxShadow: props.isMyUser ? "3px 3px 2px 1px rgba(0, 0, 255, 0.3)" : ""
                }}
            >
                {props.user.displayName}
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarTop, avatarLeft, avatarWidth, avatarHeight, NAME_WIDTH, NAME_FONTSIZE, props.user.flexibleNamePlate])

    // ミュート バッジ
    // #11252 プライバシーボタン削除にともないプライバシーバッジも表示しない
    const drawBatch = useMemo(() => {
        const width = 20;  // batch width。位置計算で用いるため、CSS側には定義していない。
        const height = 20; // batch height。同上

        // マイク未選択時の表示対応
        let clsDef = classes.AVMuteIcon;
        if(props.user.isMicNoSelect === true) {
            clsDef = classes.AVMuteIcon2;
        }

        if (!props.user.isAudioMute) return;
        return (
            <div
                className={clsDef}
                style={{
                    position: "absolute",
                    top: avatarTop + avatarHeight - height,
                    left: avatarLeft + avatarWidth - width,
                    width,
                    height,
                    zIndex: props.zIndex,
                    // 以下、センタリングのためのスタイル
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
            <MicOffIcon style={{ fontSize: '1em', color: 'rgba(255,255,255,1)' }} />    
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarTop, avatarLeft, avatarWidth, avatarHeight, props.user.isAudioMute, props.user.isMicNoSelect])

    // カメラミュート バッジ
    const drawBatchLeft = useMemo(() => {
        const width = 20;  // batch width。位置計算で用いるため、CSS側には定義していない。
        const height = 20; // batch height。同上

        // カメラ未選択時の表示対応
        let clsDef = classes.AVMuteIcon;
        /*
        let videoDevice = localStorage.getItem("videoDeviceNothing_"+props.user.id);
        if(videoDevice !== null && videoDevice === "yes") {
            clsDef = classes.AVMuteIcon2;
        }
        */
        // 非活性のボタンに設定
        if( props.user.isVideoNoSelect || props.user.isTrafficLimitVideoMute ){
            clsDef = classes.AVMuteIcon2;
        }

        if (!props.user.isVideoMute) return;
        return (
            <div
                className={clsDef}
                style={{
                    position: "absolute",
                    top: avatarTop + avatarHeight - height,
                    left: avatarLeft,
                    width,
                    height,
                    zIndex: props.zIndex,
                    // 以下、センタリングのためのスタイル
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <VideoOffIcon style={{ fontSize: '1em', color: 'rgba(255,255,255,1)' }} />
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarTop, avatarLeft, avatarWidth, avatarHeight, props.user.isVideoMute, props.user.isVideoNoSelect, props.user.isTrafficLimitVideoMute ])
    // 依存配列にisTrafficLimitVideoMuteを追加

    // ふきだし
    const drawFukidashi = useMemo(() => {
        //if (props.user.micVolume === undefined || props.user.micVolume < 25)  {
        if (props.user.micVolume === undefined ){   // 上の"25"をFukidasiEffectの中に移動
            //console.log("fukidasi return" , props.user.micVolume);
            return;
        }

        return (
            <Fragment>
                <FukidasiEffect
                    top={avatarTop - avatarVideoHeight}
                    left={avatarLeft + (avatarWidth + avatarVideoWidth)/2}
                    micLevel={props.user.micVolume}
                    micVolumeThreshold={props.user.micVolumeThreshold}
                    zIndex={props.zIndex}
                />
            </Fragment>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarTop, avatarLeft, avatarWidth, props.user.micVolume, avatarVideoHeight, avatarVideoWidth])
    

    // プロフィール
    const [ping, setPing] = useState(props.user.ping);

    useEffect(() => {
        updatePing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.openProfile]);

    const updatePing = () => {
        if (props.openProfile) {
            // var params = new URLSearchParams();
            // params.append("id", props.user.id.toString());
            // axios.post('/api/user/ping', params)
            //     .then((e: AxiosResponse) => {
            //         setPing(e.status === 200 && e.data !== "" ? e.data : 0);
            //     }).catch(err => {
            //         console.log("failed /api/user/ping → set ping 0");
            //         setPing(0);
            //     });
            httpClient.getPing(props.user.id)
                .then((e) => {
                    setPing(e);
                }).catch(err => {
                    console.log("failed /api/user/ping → set ping 0");
                    setPing(0);
                });
        }
    }

    const drawProfile = useMemo(() => {
        return (
            <UserInfoCard
                className={NEED_TO_POINTER_CAPTURE}
                open={props.openProfile}
                user={props.user}
                ping={ping}
                onClickRefresh={updatePing}
                onClickClose={props.onClickProfile}
                //zIndex={props.zIndex}
                zIndex={ZIndex.userInfoCard}
                isLarge={props.isLarge}
                enabledBusinessCard={props.enabledBusinessCard}
                kind={props.kind}
                isMyUser={props.isMyUser}
                floorSize={props.floorSize}
            />
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ping, props.user.x, props.user.y, props.openProfile, props.isLarge, props.user.isCardDisclose, props.enabledBusinessCard])

    const drawSelectMemoGroup = useMemo(() => {
        return !props.isMyUser &&(
            <SelectMemoGroupCard
                className={NEED_TO_POINTER_CAPTURE}
                open={props.openSelectMemoGroup}
                user={props.user}
                onClickClose={props.onClickSelectMemoGroup}
                zIndex={ZIndex.userInfoCard}
                isLarge={props.isLarge}
                handleOpenMemo={props.handleOpenMemo}
                kind={props.kind}
            />
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.id, props.user.x, props.user.y, props.openSelectMemoGroup, props.isLarge, props.isMyUser])


    const drawSelectChatGroup = useMemo(() => {
        return !props.isMyUser &&(
            <SelectChatGroupCard
                className={NEED_TO_POINTER_CAPTURE}
                open={props.openSelectChatGroup}
                user={props.user}
                onClickClose={props.onClickSelectChatGroup}
                zIndex={ZIndex.userInfoCard}
                isLarge={props.isLarge}
                handleOpenChat={props.handleOpenChat}
                kind={props.kind}
            />
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.id, props.user.x, props.user.y, props.openSelectChatGroup, props.isLarge, props.isMyUser])

    return (
        <Fragment>
            {drawPictograph}
            {drawState}
            {drawStudyTimer}
            {drawTubuyaki}
            {drawNameKai}
            {drawBatch}
            {drawBatchLeft}
            {drawFukidashi}
            {drawProfile}
            {/*drawSelectMemoGroup*/}
            {drawSelectChatGroup}
        </Fragment>
    )
}

import React, { forwardRef, ForwardRefRenderFunction, Fragment, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
//import { User } from '../common/JsonClass';
import WebrtcService from '../webrtc/WebrtcService';
import JfsClient, { User, StatusTimerInfo } from '@fsi/jfs-sdk';

interface Props {
    user: User;
}

export interface statusTimerHandler {
    setTimer(statusId: number): void;
    stopTimer(): void;
}


const MyUserStatusTimerCompornent: ForwardRefRenderFunction<statusTimerHandler, Props> = (props, ref) => {
    const [lastStatusName, setLastStatusName] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        setTimer: (statusId: number) => {
            // ステータスの呼び出し、タイマースタート判定
            getMyUserStatusTimerInfo(statusId);
        },
        stopTimer: () => {
            stopTimer();
        }
    }))

    // ステータスタイマー情報を取得するapi
    const getMyUserStatusTimerInfo = (statusId: number) => {
        // axios
        //     .create({
        //         withCredentials: true,
        //     })
        //     .post('/api/user/status/current', new URLSearchParams({
        //         tab_id: sessionStorage.getItem("TABID") as string,
        //         status_id: statusId.toString(),
        //         user_login_id: props.user.loginId.toString(),
        //     }))
        httpClient.getCurrentStatus(
            sessionStorage.getItem("TABID") as string,
            props.user.loginId,
            statusId
        )    
            .then((response: StatusTimerInfo) => {
                let result = response;
                // console.log("result:", result);
                // データが取得できたらタイマー実行するか判定
                if (result.setTimer !== 0) {
                    let avatarIdAndName = result.avatarIdAndName;

                    if (lastStatusName !== '') {
                        // 前回のステータスが残っていたらタイマーをストップ
                        stopTimer();
                    }
                    // ステータスタイマーがオンになっているか判定
                    if (result.timerActive === true) {
                        // ステータスタイマーの時間通りにタイマーをセット
                        startTimer(result.setTimer * 60 * 1000, avatarIdAndName.avaterName);
                    } else {
                        setLastStatusName('');
                    }
                }
            })
            .catch((err) => {
                console.log("getMyUserStatus error.");
                console.log(err.response);
            });
    }

    // スナックバー
    const showSnackBar = (msg: string) => {
        enqueueSnackbar(
            msg,
            {
                key: "stetusTimerMessage",
                anchorOrigin: { horizontal: "left", vertical: "bottom" },
                persist: true,
                preventDuplicate: false,
                action: key => (
                    <Button onClick={() => closeSnackbar(key)} color="inherit" >
                        <ClearIcon fontSize='small' />
                    </Button>
                ),
            }
        );
    }

    // タイマースタート
    const startTimer = useCallback((timeout: number, status: string) => {
        setLastStatusName(status);
        if (timerRef.current !== null) return;
        timerRef.current = setTimeout(() => {
            let msg = String(status) + "に設定して" + (timeout / 1000 / 60) + "分経過しました。";
            // iOSだった時はWindows通知処理を通らない
            if (WebrtcService.isiOS() === false && WebrtcService.isiOS() === true ) {
                // windouws通知を出す
                let localNotification = new Notification(msg);
                localNotification.onclick = function () {
                    window.focus();
                };
            }
            // スナックバーを表示
            showSnackBar(msg);
            setLastStatusName('');
            stopTimer();
        }, timeout)
    }, []);

    // タイマーストップ
    const stopTimer = useCallback(() => {
        if (timerRef.current === null) return;
        clearTimeout(Number(timerRef.current));
        timerRef.current = null;
    }, []);

    return (
        <Fragment></Fragment>
    )

}

export const MyUserStatusTimer = forwardRef(MyUserStatusTimerCompornent);

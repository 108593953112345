import React, { Fragment, useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { chkJsonFormat } from '../common/JsonClass';
import EditTextDialog from './EditTextDialog';
import EditVideoURLDialog from './EditVideoURLDialog';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient, { FloorObject } from '@fsi/jfs-sdk';

// icons
import CreateIcon from '@material-ui/icons/Create';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
//import CastIcon from '@material-ui/icons/Cast';
//import CastConnectedIcon from '@material-ui/icons/CastConnected';
import { Tooltip } from '@material-ui/core';
import { Utility } from '../common/Utility';
import { table } from 'console';
import EditBanner from './EditBanner';

const staticKanbanStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    pointerEvents: 'auto',
}

const dynamicKanbanStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    top: 5,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 15,
    lineHeight: '15px',
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

const kanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    fontSize: 16,
    lineHeight: 1.05,
    textAlign: 'left',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

export interface Props {
    className?: string,
    floorObject: FloorObject,
    //sendText1KanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    sendBannerText: (id: number, text: string, font: string, color: string) => void,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    sendVideoURLText: (
        id: number, text: string, isPlayAudio: boolean, isResetURL: boolean
    ) => void,
    isDrawText?: boolean,
    role?: string
}

export default function MeetingRoomWithBanner(props: Props) {
    // 表示するメッセージの取得
    // elm message:会議室コメント
    //     url:外部会議url
    function GetInText(text:string, objecttype:number, elm:string ) {
        // 外部会議部屋の場合、JSONから取得
        if( Utility.isVideoConference(objecttype) ){
            // Jsonフォーマットチェック
            let jf: chkJsonFormat = new chkJsonFormat();
            let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

            if(jsonCheck[0]){
                // Jsonから取得
                let intextJson = jsonCheck[2];
                if (elm === 'message') {
                    text = intextJson.message;
                } else if (elm === 'url') {
                    text = intextJson.url;
                } else {
                    // 処理なし(textをそのまま使用)
                }
            }else{
                // Json形式ではない場合
                if (elm === 'message') {
                    // 処理なし（textをそのまま使用）
                } else if (elm === 'url') {
                    // urlはまだ設定されていない
                    text = "";
                } else {
                    // 処理なし(textをそのまま使用)
                }
            }
        }
        return text;
    }

    function GetInText2(text:string, elm:string ) {
        // 横断幕用のJsonパース
        
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT1_BANNER, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            if (elm === 'message') {
                text = intextJson.message;
            } else if (elm === 'font') {
                text = intextJson.font;
            } else if (elm === 'color') {
                text = intextJson.color;
            } else {
                text = "";
            }
        }else{
            // Json形式ではない場合
            if (elm === 'message') {
                text = "";
            } else if (elm === 'font') {
                // urlはまだ設定されていない
                text = "";
            } else if (elm === 'color') {
                text = "";
            } else {
                text = "";
            }
        }
        
        return text;
    }

    // チェック状態の取得
    function GetCehckStat(text: string) {
        let isChcked = false;

        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            isChcked = intextJson.isResetURL;
        } else {
            // 処理なし(Json未対応の場合は揮発性の項目となるため)
        }
        return isChcked;
    }

    const defaultProps = {
        isDrawText: true
    }
    const { className, role } = props;
    const isDrawText  = props.isDrawText ?? defaultProps.isDrawText;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2, videoURL, isResetURL } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const [isStaticKanbanOpen, setIsStaticKanbanOpen] = useState(false);
    const [isVideoURLOpen, setIsVideoURLOpen] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    // const text1Width = useMemo(() => calcWidth(text1), [text1]);
    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
        // 小数点を繰り上げ
        let strLength = Math.ceil(len)

        return strLength
    };

    const kanbanMaxWidth = useMemo(
        () => ( Utility.isVideoConference(objectMaster.type) )
            ? (objectMaster.width - objectMaster.editMinusOffsetLeft) -  20 - 50
            : (objectMaster.width - objectMaster.editMinusOffsetLeft) - 50
        , [objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);

    const calcLengthWidth = (text: string, maxWidth: number) => {
        if(text === null) text = '';
        return (
            text.length === 0 
                ? 15 
                : Math.min(judgehz(text) * 13 + 2, maxWidth)
        )
    }
    
    // 看板の幅
    const text2Width = useMemo(() => 
        calcLengthWidth(GetInText(text2, objectMaster.type, 'message'), kanbanMaxWidth)
    , [text2, objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);

    const isEditable = useMemo(() =>
        role ? (role.match(/ROLE_ADMIN/) != null) : true
    , [role]);

    // 外部連携会議室の会議URL設定アイコン
    const dynamicSwitch = useMemo(() =>
        // httpのアドレスが設定されているときは背景色を変える
        GetInText(text2, objectMaster.type, 'url')?.indexOf('http') === 0
            ? <QueuePlayNextIcon style={{ width: 15, height: 15, color: '#ffffff', background: '#2196f3' }} />
            : <QueuePlayNextIcon style={{ width: 15, height: 15 }} />
        , [videoURL, text2, objectMaster.type]);

    // 会議室の看板
    const dynamicContent = useMemo(() => {
        let allowSetting = props.role !== undefined && props.role !== "ROLE_USER";
        let intext = ''
        if (text2 === null) {
            intext = GetInText('', objectMaster.type, 'message');
        } else {
            // 表示するメッセージを取得
            intext = GetInText(text2, objectMaster.type, 'message');
        }
        
        return (
            allowSetting && intext.length === 0
                ? <CreateIcon style={{ width: 15, height: 15 }} />
                : intext
        )
    }, [text2, objectMaster.type]);

    const content = useMemo(() => {
        return (
            <>
                <div style={{display: 'inline-block', fontFamily: GetInText2(text1,'font'), fontSize: 28, color: GetInText2(text1,'color')}}>
                    {GetInText2(text1,'message')}
                </div>
            </>
        )
    }, [text1]);

    const drawStaticKanban = useMemo(() => {
        return (
            <Fragment>
                <div
                    key={`meetingRoomStaticKanban${id}`}
                    id={`meetingRoomStaticKanban${id}`}
                    onClick={() => setIsStaticKanbanOpen(true)}
                    className={className}
                    style={{
                        ...staticKanbanStyle,
                        left: objectMaster.textOffsetLeft,
                        top: objectMaster.textOffsetTop,
                        height: 50,
                    }}
                >
                    <Typography className={className} style={{ ...kanbanTextStyle, width: objectMaster.textWidth, height: objectMaster.height }} variant="body2" color="textSecondary">
                        {content}
                    </Typography>
                </div>
                <EditBanner
                    className={className}
                    isOpen={isStaticKanbanOpen && isEditable}
                    floorObjectId={id}
                    type={objectMaster.type}
                    title={'表示内容'}
                    text={text1}
                    maxLength={objectMaster.textLength}
                    onClose={() => setIsStaticKanbanOpen(false)}
                    sendText={props.sendBannerText}
                />
            </Fragment>
        )
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text1, isEditable, isStaticKanbanOpen, objectMaster.textWidth, objectMaster.textLength]);

    const kanbanLength = useMemo(
        () => 30
        , [text1]);

    const videoURLLength = useMemo(
        () => 512
        , [videoURL, text2]);
   
    const kanbanLeft = useMemo(() => {
        return kanbanMaxWidth - text2Width + 50;
    }, [text2Width, objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);
    
    const drawDynamicKanban = useMemo(() => isDrawText && (
        <Fragment>
            <div
                key={`meetingRoomDynamicKanban${id}`}
                id={`meetingRoomDynamicKanban${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...dynamicKanbanStyle,
                    left: kanbanLeft,
                    minWidth: text2Width,
                    maxWidth: text2Width,
                    pointerEvents: "auto",
                }}
            >
                {dynamicContent}
            </div>

            <EditTextDialog
                className={className}
                isOpen={isOpen && isEditable}
                floorObjectId={id}
                title={'表示内容'}
                text={text2}
                maxLength={kanbanLength}
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </Fragment>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text1, text2, isOpen, isEditable, objectMaster.width, isDrawText, text2Width])

    const drawDynamicSwitch = useMemo(() => (
        ( Utility.isVideoConference(props.floorObject.objectMaster.type) ) ?
            <Fragment>
                <div
                    key={`meetingRoomDynamicSwitch${id}`}
                    id={`meetingRoomDynamicSwitch${id}`}
                    className={className}
                    onClick={() => setIsVideoURLOpen(true)}
                    style={{
                        ...dynamicKanbanStyle,
                        left: (objectMaster.width - objectMaster.editMinusOffsetLeft) - 15,
                        width: 15,
                        pointerEvents: "auto",
                    }}
                >
                    {dynamicSwitch}
                </div>
                <EditVideoURLDialog
                    className={className}
                    isOpen={isVideoURLOpen && isEditable}
                    floorObjectId={id}
                    title="外部Web会議ツール設定"
                    text={text2}
                    maxLength={videoURLLength}
                    onClose={() => setIsVideoURLOpen(false)}
                    isResetURL={GetCehckStat(text2)}
                    sendText={props.sendVideoURLText}
                />
            </Fragment>
        :
            <Fragment/>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text2, videoURL, isResetURL, isVideoURLOpen, isEditable, objectMaster.width, objectMaster.editMinusOffsetLeft])

    const draw = useMemo(() => (
        <div
            key={`meetingRoom${id}`}
            id={`meetingRoom${id}`}
            className={className}
            style={{
                ...SELECT_NONE,
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                // backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorSection,
                pointerEvents: "none",
            }}
        >
            {drawStaticKanban}
            {drawDynamicKanban}
            {drawDynamicSwitch}
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [id, offsetLeft, offsetTop, objectMaster, text1, text2, videoURL, isResetURL, isOpen, isStaticKanbanOpen, isVideoURLOpen, isDrawText]);

    return (
        <Fragment>
            {draw}
        </Fragment>
    );
}
import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import ClearIcon from '@material-ui/icons/Clear';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SELECT_NONE } from "../common/Constants";
import TextField from '@material-ui/core/TextField';
import { chkJsonFormat } from '../common/JsonClass';
import { Utility } from '../common/Utility';

const textFieldTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

interface Props {
    className?: string,
    isOpen: boolean,
    floorObjectId: number,
    title: string,
    text: string,
    maxLength: number,
    onClose: () => void,
    sendText: (
        id: number,
        text: string,
        date: string,
        font: string,
        color: string,
    ) => void
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //backgroundColor: 'rgba(0, 0, 255, 1)',
};

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

export default function EditCountdownDialog(props: Props) {

    const { className, isOpen, floorObjectId, title, text, maxLength } = props;
    const [inputText, setInputText] = useState(text);
    const [tempText, setTempText] = useState(text);
    const [dialogWidth, setDialogWidth] = useState("420px");
    const [eventDate, setEventDate] = useState("");
    const [selectedFont, setSelectedFont] = useState("");
    const [selectedColor, setSelectedColor] = useState("#0000008A");
    const [colorRgba, setColorRgba] = useState("#0000008A");
    const [open, setOpen] = useState(false);
    const [strToday, setStrToday] = useState("");
    const [strMaxDate, setStrMaxDate] = useState("");
    const fontName = ['ゴシック風　Noto Sans JP', '明朝風　Zen Antique', '楷書風　Yuji Boku', 'ポップ風　Mochiy Pop One', '手書き風　Yomogi', '立体風　Rampart One'];
    const [isShowClearConfirmDialog, setShowClearConfirmDialog] = React.useState(false);
    // const font =  ['游ゴシック', '游明朝', 'Meiryo UI', 'HG正楷書体-PRO', 'HG創英角ﾎﾟｯﾌﾟ体'];

    // カウントダウンボード用テキストのuseEffect
    useEffect(() => {
        // 当日以降の日付を入力可能範囲として設定
        const today = new Date();
        const maxDate = new Date();
        maxDate.setDate(today.getDate() + 999);
        setStrToday(today === null ? "" : ConvertDateFormat(today));
        setStrMaxDate(maxDate === null ? "" : ConvertDateFormat(maxDate));
        
        if(title!=='カウントダウン設定') return;
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_COUNTDOWNBOARD, text);

        if( jsonCheck[0] ){
           // Jsonの場合
            const setting = jsonCheck[2];
            setInputText(setting.eventName);
            setEventDate(setting.eventDate);
            setSelectedFont(setting.font !== "" ? setting.font : fontName[0]);
            setSelectedColor(setting.color !== "" ? setting.color : "#0000008A");
            setColorRgba(setting.color !== "" ? setting.color : "#0000008A");
        } else {
            // Jsonでない場合
            setInputText("");
        }
        setTempText(inputText);
        return () => setTempText(inputText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, text]);

    useEffect(() => {
        // ダイアログの幅を設定
        if (maxLength === 12) {
            setDialogWidth("420px")
        } else if (maxLength === 30) {
            setDialogWidth("630px")
        }
    }, [maxLength, dialogWidth])

    // Dateを日付コンポーネント用のフォーマットに変換
    const ConvertDateFormat = (date: Date) => {
        let convertDate = date.getFullYear().toString() +
            "-" +
            ("00" + (date.getMonth()+1).toString()).slice(-2) +
            "-" +
            ("00" + date.getDate().toString()).slice(-2);
        return convertDate;
    }

    // OK
    const onOk = useCallback(() => {
      // 入力値が取得できない場合の対応
      if (inputText === null || inputText === undefined) {
        return;
      }
      if (eventDate === null || eventDate === undefined) {
        return;
      }
      if (selectedFont === null || selectedFont === undefined) {
        return;
      }

      // 文字長チェック
      if (judgehz(inputText) === 0 || judgehz(inputText) > maxLength) {
        return;
      }
      props.sendText(floorObjectId, inputText, eventDate, selectedFont, colorRgba);
      setTempText(inputText);
      props.onClose();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, inputText, eventDate, selectedFont, selectedColor, colorRgba]);

    // Clear（ダイアログそのまま）
    const onClearText = useCallback(() => {
        setInputText('');
        setTempText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, text]);

    // Clear（ダイアログ閉じる）
    const onClear = useCallback(() => {
        props.sendText(floorObjectId, '', '', '', '');
        setInputText('');
        setEventDate('');
        setSelectedFont('');
        setColorRgba('');
        setSelectedColor('');
        setTempText('');
        props.onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setShowClearConfirmDialog(false);
    }, [floorObjectId]);

    // Cancel
    const onCancel = useCallback(() => {
        setInputText(tempText);
        props.onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempText]);

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定　→　半角全角の区別をつけない　理由：半角の@やwは横幅が広く表示からはみ出てしまうため
        for (let i = 0; i < intext.length; i++) {
            /*if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }*/
            len = len + 1;
        }

        return len
    };

    // 入力URL長チェック
    const textLength = useMemo(() => {
        // inputTextが取得できない場合の対応
        if(inputText === null || inputText === undefined){
            return (
                <div> </div>
            );
        }
        let len = judgehz(inputText)
        if(len === 0){
            return (
                <div style={{ color: "red", marginTop: 10 }}>1～{maxLength}文字の入力が必要です。 </div>
            );
        }else if (maxLength < len) {
            return (
                <div style={{ color: "red", marginTop: 10 }}>入力した文字列が長すぎます。 </div>
            );
        }
    }, [maxLength, inputText]);

    const progressBar = useMemo(() => {
        let parsent = (judgehz(inputText) / maxLength) * 100;
        let progressColor = '#FFE1A2';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    }, [inputText])

    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEventDate(e.target.value);
    }

    const handleChangeFont = (value :string) => {
        setSelectedFont(value);
    }

    const handleColorPickerChange = (color: ColorResult) => {
        setColorRgba(color.hex);
    };
    
    const handleClick = () => {
        setOpen(!open);
    }

    const setFont = (fontName: string) => {
        let font = fontName.split("　");
        if(font.length > 1){
            return font[1];
        }else{
            return "sans-serif";
        }
    }

    // 描画
    const draw = useMemo(() =>
        <BaseDialog
            className={className}
            open={isOpen}
            onClose={() => props.onClose()}
            aria-labelledby='editTtextDialogTitle'
            // fullWidth={true}
            maxWidth={Utility.getType() === 2 ? 'xs' : 'sm'}
            PaperProps={{
                style: {
                    minWidth: Utility.getType() === 2 ? 200 : dialogWidth,
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                },
            }}
        >
            <DialogTitle id='editTtextDialogTitle' style={{ padding: "10px 25px 10px 25px", background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }} >{title}</DialogTitle>
            <DialogContent style={{ padding: "0px 25px 5px 25px" }}>
                <DialogContentText style={{ marginBottom: "0px" }} >
                    {progressBar}
                </DialogContentText>
                <div style={{color: '#707070', fontSize: '12px'}}>イベント名</div>
                <ThemeProvider theme={textFieldTheme}>
                <TextField
                    id="outlined-basic"
                    //label={"イベント名"}
                    variant="outlined"
                    multiline
                    autoFocus
                    margin='dense'
                    fullWidth
                    value={inputText}
                    autoComplete={'off'}
                    onChange={e => setInputText(e.target.value)}
                    style={{ pointerEvents: 'auto', paddingBottom: 10}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onClearText}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField 
                    style={{width: 180, marginTop: "10px", marginLeft: "3px"}} 
                    id="date" 
                    label="イベント開催日-当日" 
                    type="date" 
                    onChange={(e) => handleChangeDate(e)} 
                    value={eventDate} 
                    InputLabelProps={{shrink: true,}}
                    inputProps={{min: strToday, max: strMaxDate}}/>
                </ThemeProvider>
                <div style={{display: 'flex', paddingTop: 20, paddingBottom:5, marginLeft: "3px"}}>
                    <div style={{marginRight: "30px"}}>
                    <div style={{color: '#0000008A', fontSize: 12, paddingBottom: 5}}>文字色設定</div>
                    <div
                        style={{
                        width: 80,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: colorRgba,
                        }}
                        onClick={handleClick}
                    />
                    </div>
                    <div style={{marginRight: "30px"}}>
                    <div style={{color: '#0000008A', fontSize: 12, paddingBottom: 5}}>イベント名のフォント設定</div>
                    <label>
                        <select 
                            style={{fontSize:18, width: Utility.getType() === 2 ? 160 : 300}} 
                            onChange={(e) => handleChangeFont(e.target.value as string)} 
                            value={selectedFont}>
                            {fontName.map(arrayItem => 
                                <option value={arrayItem}>{arrayItem}</option>
                            )}
                        </select>
                    </label>
                    <div style={{fontFamily: setFont(selectedFont), fontWeight: "bold", color: colorRgba, fontSize: 18, marginTop: 4, marginLeft: 4}}>サンプル</div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowClearConfirmDialog(true)} color='primary' style={{ pointerEvents: 'auto', color: '#006FBC', fontFamily: 'Hiragino Maru Gothic StdN' }}>クリア</Button>
                <Button onClick={onOk} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110 }}>OK</Button>
                <Button onClick={onCancel} color='primary' style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110 }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [colorRgba, open, isOpen, floorObjectId, title, text, inputText, tempText, eventDate, selectedFont, selectedColor])

        const drawColorPicker = useMemo(() => {
            return (
                <BaseDialog
                    id="colorPickerDialog"
                    open={open}
                    aria-labelledby="colorPickerDialogTitle"
                    PaperProps={{
                        style:{
                            border: '6px solid #57BBFF',
                            borderRadius: '25px',
                        }
                    }}
                >
                    <DialogTitle id="colorPickerDialogTitle" style={{padding:0}}></DialogTitle>
                    <DialogContent style={{padding:0}}>
                        <SketchPicker color={colorRgba} onChange={handleColorPickerChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false) }} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 80 }} >OK</Button>
                        <Button onClick={() => { setOpen(false) }} color="primary" style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 80 }} >閉じる</Button>
                    </DialogActions>
                </BaseDialog>
            );
        }, [open,colorRgba])
    return (
        <Fragment>
            {draw}
            {drawColorPicker}
            <BaseDialog open={isShowClearConfirmDialog}
                style={{ pointerEvents: 'auto' }}
                fullWidth={true}
                maxWidth={Utility.getType() === 2 ? "xs":"sm"}
                PaperProps={{
                    style:{
                        border: '6px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}>
                <DialogTitle id="dialog-registUser" style={{padding: '10px 25px 10px 25px', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555'}}>確認</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    このカウントダウン看板をクリアします。
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <div>
                    <Button color="primary" onClick={ onClear } style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110}}>OK</Button>
                    <Button color="primary" onClick={() => setShowClearConfirmDialog(false)} style={{color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110}}>キャンセル</Button>
                </div>
                </DialogActions>
            </BaseDialog>
        </Fragment>
    )
}
import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { KeyboardEventHandler, KeyboardEvent } from 'react';
import { withStyles, makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import { Divider, Box, FormLabel, DialogTitle, DialogContent, FormControl, Select, DialogActions, Button,Radio, RadioGroup,FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Office as resOffice } from '../common/JsonClass';
import { Utility } from '../common/Utility';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ThemeProvider } from '@material-ui/styles';

import { userListConst } from './UserListConst';
import JfsClient, { FloorListInfo, ResTimeManagement } from '@fsi/jfs-sdk'; 

export default function OpenTimeManagement() {

  const classes = useStyles();
  const tabId = sessionStorage.getItem('TABID') || '';

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);

  const [officeList, setOfficeList] = React.useState([] as Office[]);
  const [openTimeList, setOpenTimeList] = React.useState([] as TimeManagementData[]);
  const [openTimeListOrg, setOpenTimeListOrg] = React.useState([] as TimeManagementData[]);
  const [showBackdropAfterSaving, setBackdropAfterSaving] = React.useState(false);

  const jfsClient = JfsClient.getInstance();
  const { httpClient, JFS_ERRORS } = jfsClient;

  /**
  * useEffect
  */
   useEffect(() => {
    setCrudType(0);
    setLoading(true);
    fetchOfficeFloorList();
  }, []);

  const fetchOfficeFloorList = async () => {
    try {
      const res1 = await Utility.requestOfficeList();
      const officeListData = res1 as resOffice[];

      // UI表示用のofficeデータを生成
      const promiseArray = officeListData.map(async (office) => {
        const res = await getFloorList(office.id);
        const floorListData = res as resFloor[];
        floorListData.forEach((data) => data.checked == false);
        const enabledFloorListData = floorListData.filter(x => x.enabledFloor === true);
        return setFloorToOffice(office, enabledFloorListData);
      });
      // フロア０件のオフィスは選択データに入れない
      const _officeList_tmp = await Promise.all(promiseArray);
      var _officeList = _officeList_tmp.filter(x => x.floorList.length > 0);

      // setState
      _officeList.sort((a, b) => a.id > b.id ? 1 : -1);

      const res = await getTimeManagementList(tabId);

      const list = res
      ? res.map((v: any, i: number, d: any) => {
        const e: TimeManagementData = new TimeManagementData();
        e.id = v.id;
        e.officeId = v.officeId;
        e.tenantId = v.tenantId;
        e.floorId = v.floorId;
        e.weekdaySun = v.weekdaySun;
        e.weekdayMon = v.weekdayMon;
        e.weekdayThur = v.weekdayThur;
        e.weekdayWed = v.weekdayWed;
        e.weekdayTues = v.weekdayTues;
        e.weekdayFri = v.weekdayFri;
        e.weekdaySat = v.weekdaySat;
        e.weekdayMonFrom  = (v.weekdayMonFrom  as String).substring(0, 5);
        e.weekdayMonTo    = (v.weekdayMonTo    as String).substring(0, 5);
        e.weekdayTuesFrom = (v.weekdayTuesFrom as String).substring(0, 5);
        e.weekdayTuesTo   = (v.weekdayTuesTo   as String).substring(0, 5);
        e.weekdayWedFrom  = (v.weekdayWedFrom  as String).substring(0, 5);
        e.weekdayWedTo    = (v.weekdayWedTo    as String).substring(0, 5);
        e.weekdayThurFrom = (v.weekdayThurFrom as String).substring(0, 5);
        e.weekdayThurTo   = (v.weekdayThurTo   as String).substring(0, 5);
        e.weekdayFriFrom  = (v.weekdayFriFrom  as String).substring(0, 5);
        e.weekdayFriTo    = (v.weekdayFriTo    as String).substring(0, 5);
        e.weekdaySatFrom  = (v.weekdaySatFrom  as String).substring(0, 5);
        e.weekdaySatTo    = (v.weekdaySatTo    as String).substring(0, 5);
        e.weekdaySunFrom  = (v.weekdaySunFrom  as String).substring(0, 5);
        e.weekdaySunTo    = (v.weekdaySunTo    as String).substring(0, 5);
  
        return e;
      }) : [] as TimeManagementData[];
  
      _officeList.forEach((office:Office, offceIdx) => {
        office.floorList.forEach((floor:Floor, flooorIdx) =>{
  
          var timeIdx = list.findIndex((item) => item.officeId == office.id && item.floorId == floor.id);
          if(timeIdx == -1){
            timeIdx = list.length;
            const data = new TimeManagementData();
            data.officeId = office.id;
            data.floorId = floor.id;
            data.type = 1;
            list.push(data);
          }
          floor.index = timeIdx;
        })
      });
  
      setOpenTimeListOrg(JSON.parse(JSON.stringify(list)));
      setOpenTimeList(list);
      setOfficeList(_officeList);
      setLoading(false);

    } catch (err) {
      console.info(err);
      setLoading(false);
    }
  }

  const getFloorList = async (id: number) => {
    // const params = new URLSearchParams();
    // params.append("office_id", id.toString());
    // params.append("tab_id", sessionStorage.getItem("TABID") as string);
    // return axios.post('/api/user/floor/list/get', params)
    const tabId = sessionStorage.getItem("TABID") as string;
    const res : FloorListInfo[] = await httpClient.getFloorList(id, tabId);

    return res;
  }

  function setFloorToOffice(resOffice: resOffice, floorList: resFloor[]) {
    const office = new Office();
    office.id = resOffice.id;
    office.officeName = resOffice.officeName;
    office.privacyRoom = resOffice.privacyRoom;

    if (floorList.length > 0) {
      // id の昇順で並びかえ
      office.floorList = floorList.map(floor => ({ ...floor, stay: false }))
      .sort((floor1, floor2) => {
        if (floor1.floorOrder > floor2.floorOrder) return 1;
        if (floor1.floorOrder < floor2.floorOrder) return -1;
        return 0;
      });
    }

    return office;
  }

  // const getTimeManagementList = async (tabId: string): Promise<AxiosResponse<ResTimeManagement[]>> => {
  const getTimeManagementList = async (tabId: string) => {
    try {
      // const res: AxiosResponse<ResTimeManagement[]> = await axios.get(
      // '/api/v1/admin/tenant/openTimeManagementList',
      // { params: { tabId } }
      // );
      const res : ResTimeManagement[] = await httpClient.getOpenTimeList(tabId);
      return res;
    } catch (err) {
      throw err;
    }
  }

  const handleSaveOrCancel = (mode: boolean) =>{
    if(mode){
      setCrudType(1);
      setLoading(true);
      const postTarget = [] as TimeManagementData[];
      //post前の判定
      for(var i = 0; i<openTimeList.length;i++){
        var newData = openTimeList[i];

        if(newData.weekdayMon == true){
          newData.weekdayMonFrom = "00:00";
          newData.weekdayMonTo   = "23:59";
        }
        if(newData.weekdayTues == true){
          newData.weekdayTuesFrom = "00:00";
          newData.weekdayTuesTo   = "23:59";
        }
        if(newData.weekdayWed == true){
          newData.weekdayWedFrom = "00:00";
          newData.weekdayWedTo   = "23:59";
        }
        if(newData.weekdayThur == true){
          newData.weekdayThurFrom = "00:00";
          newData.weekdayThurTo   = "23:59";
        }
        if(newData.weekdayFri == true){
          newData.weekdayFriFrom = "00:00";
          newData.weekdayFriTo   = "23:59";
        }
        if(newData.weekdaySat == true){
          newData.weekdaySatFrom = "00:00";
          newData.weekdaySatTo   = "23:59";
        }
        if(newData.weekdaySun == true){
          newData.weekdaySunFrom = "00:00";
          newData.weekdaySunTo   = "23:59";
        }
        // 新規の場合
        if(newData.id == 0){
          newData.type = 1;
          postTarget.push(newData);
          continue;
        }

        var oldData = openTimeListOrg.find((e) => e.id == newData.id);
        if(oldData == undefined){
          continue;
        }

        const oldStr = JSON.stringify(oldData);
        const newStr = JSON.stringify(newData);

        // 変更の場合
        if(oldStr != newStr){
          newData.type = 1;
          postTarget.push(newData);
          continue;
        }
      }
        // 削除
      for(var i = 0; i<openTimeListOrg.length;i++){
        var item = openTimeListOrg[i];
        if(openTimeList.find(newData => item.id == newData.id) == undefined){
          item.type = 3;
          postTarget.push(item);
        }
      }
      if(postTarget.length == 0){
        alert("変更ありません。");
        setLoading(false);
        return;
      }
  
      // const params = new URLSearchParams();
      // params.append("tab_id", tabId);
      // params.append("data", JSON.stringify(postTarget));
      // axios.post('/api/v1/admin/tenant/openTimeManagementList', params)
      httpClient.setOpenTimeList(tabId, postTarget)
      .then((e: string) => {
        //alert("変更されました。");
        setBackdropAfterSaving(true);
        setTimeout(function(){setBackdropAfterSaving(false)}, 2000);
        //再取得する
        getTimeManagementList(tabId)
        .then((res: ResTimeManagement[]) =>{

          const list = res
          ? res.map((v: any, i: number, d: any) => {
            const e: TimeManagementData = new TimeManagementData();
            e.id = v.id;
            e.officeId = v.officeId;
            e.tenantId = v.tenantId;
            e.floorId = v.floorId;
            e.weekdaySun = v.weekdaySun;
            e.weekdayMon = v.weekdayMon;
            e.weekdayThur = v.weekdayThur;
            e.weekdayWed = v.weekdayWed;
            e.weekdayTues = v.weekdayTues;
            e.weekdayFri = v.weekdayFri;
            e.weekdaySat = v.weekdaySat;
            e.weekdayMonFrom  = (v.weekdayMonFrom  as String).substring(0, 5);
            e.weekdayMonTo    = (v.weekdayMonTo    as String).substring(0, 5);
            e.weekdayTuesFrom = (v.weekdayTuesFrom as String).substring(0, 5);
            e.weekdayTuesTo   = (v.weekdayTuesTo   as String).substring(0, 5);
            e.weekdayWedFrom  = (v.weekdayWedFrom  as String).substring(0, 5);
            e.weekdayWedTo    = (v.weekdayWedTo    as String).substring(0, 5);
            e.weekdayThurFrom = (v.weekdayThurFrom as String).substring(0, 5);
            e.weekdayThurTo   = (v.weekdayThurTo   as String).substring(0, 5);
            e.weekdayFriFrom  = (v.weekdayFriFrom  as String).substring(0, 5);
            e.weekdayFriTo    = (v.weekdayFriTo    as String).substring(0, 5);
            e.weekdaySatFrom  = (v.weekdaySatFrom  as String).substring(0, 5);
            e.weekdaySatTo    = (v.weekdaySatTo    as String).substring(0, 5);
            e.weekdaySunFrom  = (v.weekdaySunFrom  as String).substring(0, 5);
            e.weekdaySunTo    = (v.weekdaySunTo    as String).substring(0, 5);
      
            return e;
          }) : [] as TimeManagementData[];
      
          officeList.forEach((office:Office, offceIdx) => {
            office.floorList.forEach((floor:Floor, flooorIdx) =>{
      
              var timeIdx = list.findIndex((item:TimeManagementData) => item.officeId == office.id && item.floorId == floor.id);
              if(timeIdx == -1){
                timeIdx = list.length;
                const data = new TimeManagementData();
                data.officeId = office.id;
                data.floorId = floor.id;
                data.type = 1;
                list.push(data);
              }
              floor.index = timeIdx;
            })
          });
      
          setOpenTimeListOrg(JSON.parse(JSON.stringify(list)));
          setOpenTimeList(list);
        })
        .catch(err => {})
        .finally(() => {});
      })
      .catch(err => {
      })
      .finally(() => {
        setLoading(false);
      });

    }else{
      setOpenTimeList(JSON.parse(JSON.stringify(openTimeListOrg)));
    }
  }

  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  const handleSwitch= (e: React.ChangeEvent<HTMLInputElement>, index:number, weekday:number)=> {

    const list = [...openTimeList];
    const item = list[index];

    switch(weekday){
      case 1:
        item.weekdayMon = e.target.checked;
        break;
      case 2:
        item.weekdayTues = e.target.checked;
        break;
      case 3:
        item.weekdayWed = e.target.checked;
        break;
      case 4:
        item.weekdayThur = e.target.checked;
        break;
      case 5:
        item.weekdayFri = e.target.checked;
        break;
      case 6:
        item.weekdaySat = e.target.checked;
        break;
      case 7:
        item.weekdaySun = e.target.checked;
        break;
      default:
        break;
    }

    setOpenTimeList([...list]);
  }

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    index: number, weekday:number, mode:number)=> {
    
      const list = [...openTimeList];
      const item = list[index];
  
      switch(weekday){
        case 1:
          if(mode == 0){
            item.weekdayMonFrom = e.target.value;
          }else{
            item.weekdayMonTo   = e.target.value;
          }
          break;
        case 2:
          if(mode == 0){
            item.weekdayTuesFrom = e.target.value;
          }else{
            item.weekdayTuesTo   = e.target.value;
          }
          break;
        case 3:
          if(mode == 0){
            item.weekdayWedFrom = e.target.value;
          }else{
            item.weekdayWedTo   = e.target.value;
          }
          break;
        case 4:
          if(mode == 0){
            item.weekdayThurFrom = e.target.value;
          }else{
            item.weekdayThurTo   = e.target.value;
          }
          break;
        case 5:
          if(mode == 0){
            item.weekdayFriFrom = e.target.value;
          }else{
            item.weekdayFriTo   = e.target.value;
          }
          break;
        case 6:
          if(mode == 0){
            item.weekdaySatFrom = e.target.value;
          }else{
            item.weekdaySatTo   = e.target.value;
          }
          break;
        case 7:
          if(mode == 0){
            item.weekdaySunFrom = e.target.value;
          }else{
            item.weekdaySunTo   = e.target.value;
          }
          break;
        default:
          break;
      }
  
      setOpenTimeList([...list]);
  }

  function inputCheck(){

    return openTimeList.find((item) =>{

      if(!item.weekdayMon  && (item.weekdayMonFrom  == "" || item.weekdayMonTo  == "")) return true;
      if(!item.weekdayTues && (item.weekdayTuesFrom == "" || item.weekdayTuesTo == "")) return true;
      if(!item.weekdayWed  && (item.weekdayWedFrom  == "" || item.weekdayWedTo  == "")) return true;
      if(!item.weekdayThur && (item.weekdayThurFrom == "" || item.weekdayThurTo == "")) return true;
      if(!item.weekdayFri  && (item.weekdayFriFrom  == "" || item.weekdayFriTo  == "")) return true;
      if(!item.weekdaySat  && (item.weekdaySatFrom  == "" || item.weekdaySatTo  == "")) return true;
      if(!item.weekdaySun  && (item.weekdaySunFrom  == "" || item.weekdaySunTo  == "")) return true;

      if(!item.weekdayMon  && (item.weekdayMonFrom  >= item.weekdayMonTo )) return true;
      if(!item.weekdayTues && (item.weekdayTuesFrom >= item.weekdayTuesTo)) return true;
      if(!item.weekdayWed  && (item.weekdayWedFrom  >= item.weekdayWedTo )) return true;
      if(!item.weekdayThur && (item.weekdayThurFrom >= item.weekdayThurTo)) return true;
      if(!item.weekdayFri  && (item.weekdayFriFrom  >= item.weekdayFriTo )) return true;
      if(!item.weekdaySat  && (item.weekdaySatFrom  >= item.weekdaySatTo )) return true;
      if(!item.weekdaySun  && (item.weekdaySunFrom  >= item.weekdaySunTo )) return true;

      return false;
    }) == undefined;
  }

  return (
    <Fragment>
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{marginTop: -20}}
      >
      <Card className={classes.root}>
        <CardHeader
        title='開放時間管理'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      <Fragment key="chime-table">
        <Paper style={{ width: '100%' }}>
          <Box textAlign="left" style={{height: `calc(100vh - 180px)`, overflowY: "scroll" }}>
            {
              officeList.map((office: Office, officeIdx) => {
                return(
                  <Fragment key={office.id} >
                    <label style={{ fontSize:18, fontWeight:'bolder'}}>{office.officeName}</label><br/><br/>
                    {
                      office.floorList.map((floor: Floor, floorIdx) => {

                        const item = openTimeList[floor.index];
                        const weekdayMonFromErr = !item.weekdayMon && item.weekdayMonFrom == "";
                        const weekdayMonToErr   = !item.weekdayMon && item.weekdayMonTo == "";
                        const weekdayTuesFromErr = !item.weekdayTues && item.weekdayTuesFrom == "";
                        const weekdayTuesToErr   = !item.weekdayTues && item.weekdayTuesTo == "";
                        const weekdayWedFromErr = !item.weekdayWed && item.weekdayWedFrom == "";
                        const weekdayWedToErr   = !item.weekdayWed && item.weekdayWedTo == "";
                        const weekdayThurFromErr = !item.weekdayThur && item.weekdayThurFrom == "";
                        const weekdayThurToErr   = !item.weekdayThur && item.weekdayThurTo == "";
                        const weekdayFriFromErr = !item.weekdayFri && item.weekdayFriFrom == "";
                        const weekdayFriToErr   = !item.weekdayFri && item.weekdayFriTo == "";
                        const weekdaySatFromErr = !item.weekdaySat && item.weekdaySatFrom == "";
                        const weekdaySatToErr   = !item.weekdaySat && item.weekdaySatTo == "";
                        const weekdaySunFromErr = !item.weekdaySun && item.weekdaySunFrom == "";
                        const weekdaySunToErr   = !item.weekdaySun && item.weekdaySunTo == "";

                        const weekdayMonErr = (weekdayMonFromErr || weekdayMonToErr) ? false : !item.weekdayMon && (item.weekdayMonFrom >= item.weekdayMonTo);
                        const weekdayTuesErr = (weekdayTuesFromErr || weekdayTuesToErr) ? false : !item.weekdayTues && (item.weekdayTuesFrom >= item.weekdayTuesTo);
                        const weekdayWedErr = (weekdayWedFromErr || weekdayWedToErr) ? false : !item.weekdayWed && (item.weekdayWedFrom >= item.weekdayWedTo);
                        const weekdayThurErr = (weekdayThurFromErr || weekdayThurToErr) ? false : !item.weekdayThur && (item.weekdayThurFrom >= item.weekdayThurTo);
                        const weekdayFriErr = (weekdayFriFromErr || weekdayFriToErr) ? false : !item.weekdayFri && (item.weekdayFriFrom >= item.weekdayFriTo);
                        const weekdaySatErr = (weekdaySatFromErr || weekdaySatToErr) ? false : !item.weekdaySat && (item.weekdaySatFrom >= item.weekdaySatTo);
                        const weekdaySunErr = (weekdaySunFromErr || weekdaySunToErr) ? false : !item.weekdaySun && (item.weekdaySunFrom >= item.weekdaySunTo);

                        return(
                          <Fragment key = "">
                            <label style={{ fontSize:16,fontWeight:'bold'}}>　　{floor.floorName}</label><br/>
                            　　　　　　　　月曜日　　　　　　　　
                                <FormControl error={weekdayMonFromErr || weekdayMonErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 1, 0)}
                                      disabled = {item.weekdayMon}
                                      value={!item.weekdayMon ? item.weekdayMonFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayMonFromErr ? "開館時刻を入力ください": weekdayMonErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdayMonToErr || weekdayMonErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 1, 1)}
                                      disabled = {item.weekdayMon}
                                      value={!item.weekdayMon ? item.weekdayMonTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayMonToErr ? "閉館時刻を入力ください": weekdayMonErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdayMon} onChange={(e) => handleSwitch(e, floor.index, 1)}></Switch>
                                <br/>
                                　　　　　　　　火曜日　　　　　　　　
                                <FormControl error={weekdayTuesFromErr || weekdayTuesErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 2, 0)}
                                      disabled = {item.weekdayTues}
                                      value={!item.weekdayTues ? item.weekdayTuesFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayTuesFromErr ? "開館時刻を入力ください": weekdayTuesErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdayTuesToErr || weekdayTuesErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 2, 1)}
                                      disabled = {item.weekdayTues}
                                      value={!item.weekdayTues ? item.weekdayTuesTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayTuesToErr ? "閉館時刻を入力ください": weekdayTuesErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdayTues} onChange={(e) => handleSwitch(e, floor.index, 2)}></Switch>
                                <br/>
                            　　　　　　　　水曜日　　　　　　　　
                                <FormControl error={weekdayWedFromErr || weekdayWedErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 3, 0)}
                                      disabled = {item.weekdayWed}
                                      value={!item.weekdayWed ? item.weekdayWedFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayWedFromErr ? "開館時刻を入力ください": weekdayWedErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdayWedToErr || weekdayWedErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 3, 1)}
                                      disabled = {item.weekdayWed}
                                      value={!item.weekdayWed ? item.weekdayWedTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayWedToErr ? "閉館時刻を入力ください": weekdayWedErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdayWed} onChange={(e) => handleSwitch(e, floor.index, 3)}></Switch>
                                <br/>
                            　　　　　　　　木曜日　　　　　　　　
                                <FormControl error={weekdayThurFromErr || weekdayThurErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 4, 0)}
                                      disabled = {item.weekdayThur}
                                      value={!item.weekdayThur ? item.weekdayThurFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayThurFromErr ? "開館時刻を入力ください": weekdayThurErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdayThurToErr || weekdayThurErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 4, 1)}
                                      disabled = {item.weekdayThur}
                                      value={!item.weekdayThur ? item.weekdayThurTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayThurToErr ? "閉館時刻を入力ください": weekdayThurErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdayThur} onChange={(e) => handleSwitch(e, floor.index, 4)}></Switch>
                                <br/>
                            　　　　　　　　金曜日　　　　　　　　
                                <FormControl error={weekdayFriFromErr || weekdayFriErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 5, 0)}
                                      disabled = {item.weekdayFri}
                                      value={!item.weekdayFri ? item.weekdayFriFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayFriFromErr ? "開館時刻を入力ください": weekdayFriErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdayFriToErr || weekdayFriErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 5, 1)}
                                      disabled = {item.weekdayFri}
                                      value={!item.weekdayFri ? item.weekdayFriTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdayFriToErr ? "閉館時刻を入力ください": weekdayFriErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdayFri} onChange={(e) => handleSwitch(e, floor.index, 5)}></Switch>
                                <br/>
                            　　　　　　　　土曜日　　　　　　　　
                                <FormControl error={weekdaySatFromErr || weekdaySatErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 6, 0)}
                                      disabled = {item.weekdaySat}
                                      value={!item.weekdaySat ? item.weekdaySatFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdaySatFromErr ? "開館時刻を入力ください": weekdaySatErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdaySatToErr || weekdaySatErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 6, 1)}
                                      disabled = {item.weekdaySat}
                                      value={!item.weekdaySat ? item.weekdaySatTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdaySatToErr ? "閉館時刻を入力ください": weekdaySatErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdaySat} onChange={(e) => handleSwitch(e, floor.index, 6)}></Switch>
                                <br/>
                            　　　　　　　　日曜日　　　　　　　　
                                <FormControl error={weekdaySunFromErr || weekdaySunErr}>
                                    <TextField id="time" label="" type="time" style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 7, 0)}
                                      disabled = {item.weekdaySun}
                                      value={!item.weekdaySun ? item.weekdaySunFrom : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdaySunFromErr ? "開館時刻を入力ください": weekdaySunErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　～　　　　
                                <FormControl error={weekdaySunToErr || weekdaySunErr}>
                                    <TextField id="time" label="" type="time"  style = {{ width:150 }}
                                      onChange={(e) => handleChangeTime(e, floor.index, 7, 1)}
                                      disabled = {item.weekdaySun}
                                      value={!item.weekdaySun ? item.weekdaySunTo : ""} InputLabelProps={{shrink: true,}}/>
                                    <FormHelperText id="loginIdHelperText">{weekdaySunToErr ? "閉館時刻を入力ください": weekdaySunErr ? "この時刻では設定できません" : ""}</FormHelperText>
                                </FormControl>
                                　　　　　　　　
                                終日閉館：<Switch checked={item.weekdaySun} onChange={(e) => handleSwitch(e, floor.index, 7)}></Switch>
                                <br/>
                                <br/>
                                <Divider style={{ borderBottomWidth : 20}}></Divider>
                                <br/>
                          </Fragment>
                        );
                      })
                    }
                  </Fragment>
                );
            })}
          </Box>
        </Paper>
        <div>
          <Button variant="contained" color="primary" 
                  disabled = {JSON.stringify(openTimeList) == JSON.stringify(openTimeListOrg) ? true : inputCheck() == undefined? true: inputCheck() == false}
                  onClick={() => handleSaveOrCancel(true)}
            >保存</Button>&nbsp;
          <Button variant="contained" color="primary" onClick={() => handleSaveOrCancel(false)}>キャンセル</Button>
        </div>
      </Fragment>
      {drawLoading}
    </Grid>
    <Backdrop className={classes.backdrop} open={showBackdropAfterSaving} >
    <Slide direction="up" in={showBackdropAfterSaving}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="subtitle2" >保存しました。</Typography>
        </CardContent>
      </Card>
    </Slide>
    </Backdrop>
    </Fragment>
  )
}














const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);
  
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
      maxWidth: 450,
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

const columns = [
  { id: '', label: 'フロア', minWidth: 40, maxWidth: 40 },
  { id: '', label: '', minWidth: 40, maxWidth: 40 },
  { id: '', label: '', minWidth: 40, maxWidth: 40 },
  { id: '', label: '終日閉館', minWidth: 60 },
];
  
// レスポンス受渡用
interface resFloor {
  id: number;
  floorName: string;
  floorOrder: number;
  enabledFloor: boolean;
  checked: boolean;
  index: number,
}

// interface resTimeManagement{
//   id: number;
//   tenantId: number;
//   floorId: number;
  
//   weekdaySun: boolean;
//   weekdayMon: boolean;
//   weekdayTues: boolean;
//   weekdayWed: boolean;
//   weekdayThur: boolean;
//   weekdayFri: boolean;
//   weekdaySat: boolean;
  
//   weekdaySunFrom: Date;
//   weekdayMonFrom: Date;
//   weekdayTuesFrom: Date;
//   weekdayWedFrom: Date;
//   weekdayThurFrom: Date;
//   weekdayFriFrom: Date;
//   weekdaySatFrom: Date;
  
//   weekdaySunTo: Date;
//   weekdayMonTo: Date;
//   weekdayTuesTo: Date;
//   weekdayWedTo: Date;
//   weekdayThurTo: Date;
//   weekdayFriTo: Date;
//   weekdaySatTo: Date;
// }

// UI表示用
class TimeManagementData {
  id: number = 0;
  officeId: number = 0;
  tenantId: number = 0;
  floorId: number = 0;
  
  weekdaySun: boolean = false;
  weekdayMon: boolean = false;
  weekdayTues: boolean = false;
  weekdayWed: boolean = false;
  weekdayThur: boolean = false;
  weekdayFri: boolean = false;
  weekdaySat: boolean = false;
  
  weekdaySunFrom: string = "00:00";
  weekdayMonFrom: string = "00:00";
  weekdayTuesFrom: string = "00:00";
  weekdayWedFrom: string = "00:00";
  weekdayThurFrom: string = "00:00";
  weekdayFriFrom: string = "00:00";
  weekdaySatFrom: string = "00:00";
  
  weekdaySunTo: string = "23:59";
  weekdayMonTo: string = "23:59";
  weekdayTuesTo: string = "23:59";
  weekdayWedTo: string = "23:59";
  weekdayThurTo: string = "23:59";
  weekdayFriTo: string = "23:59";
  weekdaySatTo: string = "23:59";

  type:number = 0;
}

class Office {
  id: number = 0;
  officeName: string = "";
  stay: boolean = false;
  open: boolean = false;
  floorList: Floor[] = [];
  offset: number = 0;
  privacyRoom: boolean = false;
}

class Floor {
  index: number = 0;
  id: number = 0;
  floorName: string = "";
  floorOrder: number = 0;
  stay: boolean = false;
  checked: boolean = false;
} 

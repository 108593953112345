import { useEffect, useState } from "react";

const useCounter = () => {
    const [timeEnd, setTimeEnd] = useState(0);//終了時間
    const [count, setCount] = useState(0);//カウント情報
    const [timerFlag, setTimerFlag] = useState(false);//カウント開始フラグ
    const [isTimerEnd, setIsTimerEnd] = useState(false);//カウント終了フラグ
    const [isTimerStop, setIsTimerStop] = useState(false);//タイマー一時停止フラグ

    useEffect(() => {
      if (timerFlag && !isTimerStop) {
        const id = setTimeout(() => {
          setCount((beforecount) => {
            // console.info('timer:'+count+', end:'+timeEnd);
            if(beforecount===timeEnd-1) { // beforecountが終了時間の1秒前と等しい⇒終了状態に達した
            setTimerFlag(false);
            setIsTimerEnd(true);
            return (beforecount + 1); // 終了時間をsetCount
            }
            if(beforecount<timeEnd || timeEnd === -1){ // beforecountが終了時間より小さい⇒終了状態に達していない
            return (beforecount + 1); // 現在時間をsetCount
            }
            if(beforecount === timeEnd){ 
              // 自習完了ダイアログ表示中にオフラインになってしまった時のみ走る
              // 本来はbeforecountがtimeEndより1つ小さい状態になったらフラグが変わり終了する
              setTimerFlag(false);
              setIsTimerEnd(true);
              return beforecount;
            }
            return beforecount;
          });
        }, 1000);
        return () => clearTimeout(id);
      }
      return;
    },[count, timerFlag, isTimerStop]);

    //開始時間セット
    const setStart = (startTime:number) => {
      setCount(startTime);
    }

    //終了時間セット
    const setEnd = (endTime:number) => {
      setTimeEnd(endTime);
    }

    //タイマースタート
    const startCount = (startTime: number, endTime: number) => {
      console.log('start count');
      setStart(startTime);//開始時間セット
      setEnd(endTime);//終了時間セット
      setIsTimerEnd(false);//カウント終了フラグをfalse
      setTimerFlag(true);//タイマー開始(カウント開始フラグをtrue)
      // console.info("StudyTimer.tsx 各種状態 count:"+count+"("+startTime+"), timeEnd:"+timeEnd+"("+endTime+"), isTimerEnd:"+isTimerEnd+", timerFlag:"+timerFlag);
    };

    //タイマー一時停止&再開
    const stopAndRestartCount = (state: boolean) => {
      setIsTimerStop(state);
    }

    const initCount = () => {
      console.log('init count!');
      setIsTimerEnd(false);
      setTimerFlag(false);
      setCount(0);
      setTimeEnd(0);
    }

    return { count, timeEnd, isTimerEnd, timerFlag , isTimerStop, initCount, startCount, setEnd, stopAndRestartCount };
};

export default useCounter;
import React, { Fragment, useState, useMemo, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { chkJsonFormat } from '../common/JsonClass';
import EditTextDialogInRoom from './EditTextDialogInRoom';
import EditTextDialog from './EditTextDialog';
import EditVideoURLDialog from './EditVideoURLDialog';
import EditBannerInRoom from './EditBannerInRoom';
import EditBanner from './EditBanner';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient, { FloorObject, StudyObjectSetting, TextInfo } from '@fsi/jfs-sdk';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import BaseDialog from './BaseDialog';

// icons
import CreateIcon from '../img/edit_B.png';
import QueuePlayNextIconOFF from '../img/monitor_B.png';
import QueuePlayNextIconON from '../img/monitor_act_B.png';
import { Utility } from '../common/Utility';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import StudySeatSettingDialog from './StudySeatSettingDialog';

const whiteBoardStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    pointerEvents: 'auto',
}

const dynamicKanbanStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    top: 5,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 15,
    lineHeight: '15px',
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

const whiteBoardTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    fontSize: 16,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

const staticKanbanTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    fontSize: 10,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

const bannerTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //fontSize: 16,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    //overflow: 'hidden',
    overflowY: 'hidden',
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
}

const chairLabelTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    zIndex: ZIndex.floorObject,
    position: 'absolute',
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 15,
    lineHeight: '15px',
    padding: '0 2',
    borderRadius: '2%',
    border: '1px solid #AAA',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
    }),
);

export interface Props {
    className?: string,
    floorObject: FloorObject,
    sendBannerText: (id: number, text: string, font: string, color: string) => void,
    sendRoomText: (id: number, textId: number, text: string, isPlayAudio: boolean, type: number) => void,
    sendKanbanText: (id: number, text: string, isPlayAudio: boolean) => void,
    sendVideoURLText: (
        id: number, text: string, isPlayAudio: boolean, isResetURL: boolean
    ) => void,
    isDrawText?: boolean,
    role?: string
    sendMeetingRoomLock: (id: number, locked: boolean) => void,
    updateStudyObjectSetting: StudyObjectSetting,
}

class ChairOpen {
    textId: number = 0;
    isOpen: boolean = false;
}

type ChairLabelData = {
    [key: string]: {
      text: string;
      top: number;
      left: number;
    };
};

export default function MeetingRoomWithTextInfo(props: Props) {
    // 部屋・会議室にダミーオブジェクトなしでテキスト情報を持たせるためのコンポーネント
    // 外部ビデオ連携(スイッチと会議室コメント)・ホワイトボードは従来通りtext2、text1で管理
    //  → その他の看板、横断幕、座席ラベルなどをオブジェクトタイプごとに判定し描画

    // 表示するメッセージの取得
    // elm message:会議室コメント
    //     url:外部会議url
    function GetInText(text:string, objecttype:number, elm:string ) {
        // 外部会議部屋の場合、JSONから取得
        if( Utility.isVideoConference(objecttype) ){
            // Jsonフォーマットチェック
            let jf: chkJsonFormat = new chkJsonFormat();
            let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

            if(jsonCheck[0]){
                // Jsonから取得
                let intextJson = jsonCheck[2];
                if (elm === 'message') {
                    text = intextJson.message;
                } else if (elm === 'url') {
                    text = intextJson.url;
                } else {
                    // 処理なし(textをそのまま使用)
                }
            }else{
                // Json形式ではない場合
                if (elm === 'message') {
                    // 処理なし（textをそのまま使用）
                } else if (elm === 'url') {
                    // urlはまだ設定されていない
                    text = "";
                } else {
                    // 処理なし(textをそのまま使用)
                }
            }
        }
        return text;
    }

    /**
     * ホワイトボード用のパース
     */
    function GetInText2(text:string, elm:string ) {
        
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT1_BANNER, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            if (elm === 'message') {
                text = intextJson.message;
            } else if (elm === 'font') {
                text = intextJson.font;
            } else if (elm === 'color') {
                text = intextJson.color;
            } else {
                text = "";
            }
        }else{
            // Json形式ではない場合
            if (elm === 'message') {
                text = "";
            } else if (elm === 'font') {
                // urlはまだ設定されていない
                text = "";
            } else if (elm === 'color') {
                text = "";
            } else {
                text = "";
            }
        }
        
        return text;
    }

    // 外部ビデオ連携のチェック状態の取得
    function GetCehckStat(text: string) {
        let isChcked = false;

        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

        if(jsonCheck[0]){
            // Jsonから取得
            let intextJson = jsonCheck[2];
            isChcked = intextJson.isResetURL;
        } else {
            // 処理なし(Json未対応の場合は揮発性の項目となるため)
        }
        return isChcked;
    }

    const defaultProps = {
        isDrawText: true
    }
    const classes = useStyles();
    const { className, role } = props;
    const isDrawText  = props.isDrawText ?? defaultProps.isDrawText;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2, lockedMeetingRoom, videoURL, isResetURL, textList } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const [isStaticKanbanOpen, setIsStaticKanbanOpen] = useState(false);
    const [isWhiteBoardOpen, setIsWhiteBoardOpen] = useState(false);
    const [isBannerOpen, setIsBannerOpen] = useState(false);
    const [isChairOpenList, setIsChairOpenList] = useState([] as ChairOpen[]);
    const [isVideoURLOpen, setIsVideoURLOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isStudySeatSetting, setIsStudySeatSetting] = useState(false);
    const [isOpenStudySeatSettingDialog, setIsOpenStudySeatSettingDialog] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    /**
     * 最初の一度だけ、座席ラベル持ちオブジェクトの場合はisChairLabeInfoListを初期化する
     */
    useEffect(() => {
        if(objectMaster.type !== 2000102 || textList == null){ // 座席ラベル持ちオブジェクトではない or textListがnull であれば終了
            return;
        }
        
        let chairLabelInfoList = textList.filter(text => text.textPositionMaster.textTypeMaster.id === 3);
        if(chairLabelInfoList.length === 0){ // 座席ラベル情報がないのであれば終了
            return;
        }

        // let chairLabelInfoListWithIsOpen: ChairOpen[] = chairLabelInfoList.map(chairLabel => {
        //     let chairLabelInfoWithIsOpen = new ChairOpen();
        //     chairLabelInfoWithIsOpen.isOpen = false;
        //     chairLabelInfoWithIsOpen.textId = chairLabel.id;
        //     return chairLabelInfoWithIsOpen;
        // })
        let chairLabelInfoListWithIsOpen: ChairOpen[] = [];
        for(let i = 0;i < chairLabelInfoList[0].textPositionMaster.editOffsetLeft;i++){
            let chairLabelInfoWithIsOpen = new ChairOpen();
            chairLabelInfoWithIsOpen.isOpen = false;
            chairLabelInfoWithIsOpen.textId = i+1;
            chairLabelInfoListWithIsOpen.push(chairLabelInfoWithIsOpen);
        }

        setIsChairOpenList(chairLabelInfoListWithIsOpen);

    },[])

    // textListの中から、ターゲットのテキスト情報を探し返却する
    const returnTargetTextInfo = (targetTextType: number) => {
        let newText = new TextInfo();

        if(textList === null){ // 起こりえないはずだが、仮にtextListがnullだった場合の処理
            return newText;
        }

        let ret = textList.find(text => text.textPositionMaster.textTypeMaster.id === targetTextType);
        if(ret === undefined){
            return newText;
        }
        return ret;
    }

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
        // 小数点を繰り上げ
        let strLength = Math.ceil(len)

        return strLength
    };

    const kanbanMaxWidth = useMemo(
        () => (Utility.isVideoConference(objectMaster.type))
            ? (Utility.isStudySeat(objectMaster.type, objectMaster.paletteText)) ? (objectMaster.width - objectMaster.editMinusOffsetLeft) - 20 - 50 - 20 - 24 : (objectMaster.width - objectMaster.editMinusOffsetLeft) - 20 - 50 - 20
            : (objectMaster.width - objectMaster.editMinusOffsetLeft) - 50
        , [objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);

    const calcLengthWidth = (text: string, maxWidth: number) => {
        if(text === null) text = '';
        return (
            text.length === 0 
                ? 24
                : Math.min(judgehz(text) * 13 + 2, maxWidth)
        )
    }
    
    // 看板(会議室コメント)の幅
    const text2Width = useMemo(() => 
        calcLengthWidth(GetInText(text2, objectMaster.type, 'message'), kanbanMaxWidth)
    , [text2, objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);

    const isEditable = useMemo(() =>
        role ? (role.match(/ROLE_ADMIN/) != null) : true
    , [role]);

    // 外部連携会議室の会議URL設定アイコン
    const dynamicSwitch = useMemo(() =>
        // httpのアドレスが設定されているときは背景色を変える
        GetInText(text2, objectMaster.type, 'url')?.indexOf('http') === 0
            ? <img src={QueuePlayNextIconON} style={{ width: 24, height: 24 }} />
            : <img src={QueuePlayNextIconOFF} style={{ width: 24, height: 24 }} />
        , [videoURL, text2, objectMaster.type]);

    // 会議室(会議室コメント)の看板
    const dynamicContent = useMemo(() => {
        let intext = ''
        if (text2 === null) {
            intext = GetInText('', objectMaster.type, 'message');
        } else {
            // 表示するメッセージを取得
            intext = GetInText(text2, objectMaster.type, 'message');
        }
        
        return (
            intext.length === 0
                ? <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                : intext
        )
    }, [text2, objectMaster.type]);

    const setFont = (fontName: string) => {
        let font = fontName.split("　");
        if(font.length > 1){
            return font[1];
        }else{
            return "sans-serif";
        }
    }

    const whiteBoardContent = useMemo(() => {
        return (
            <>
                <div style={{display: 'inline-block', 
                    fontFamily: setFont(GetInText2(text1,'font')), 
                    color: GetInText2(text1,'color'),
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',  
                    //paddingTop: objectMaster.width > 120 ? (GetInText2(text1,'message').length > 16 ? 4.2 : 13) : (GetInText2(text1,'message').length > 8 ? 4.2 : 13),
                }}>
                    {GetInText2(text1,'message')}
                </div>
            </>
        )
    }, [text1]);

    // 改行が入っていた場合のホワイトボードの上下位置調整
    const whiteBoardOffsetTop = useMemo(
        () => (text1.match(/\\n/g) || []).length > 1 ? (text1.match(/\\n/g) || []).length * -6 : 0
        , [text1]
    );

    // 改行が入っていた場合の横断幕の上下位置調整
    const bannerOffsetTop = useMemo(
        () =>
        returnTargetTextInfo(2)?.textPositionMaster.width > 120
            ? GetInText2(returnTargetTextInfo(2)?.text, "message").length > 16
              ? 4.2
              : (returnTargetTextInfo(2)?.text.match(/\\n/g) || []).length > 0 ? (returnTargetTextInfo(2)?.text.match(/\\n/g) || []).length * 4 : 13
            : GetInText2(returnTargetTextInfo(2)?.text, "message").length > 8
            ? 4.2
            : (returnTargetTextInfo(2)?.text.match(/\\n/g) || []).length > 0 ? (returnTargetTextInfo(2)?.text.match(/\\n/g) || []).length * 4 : 13,
        [textList]
      );

    const bannerContent = useMemo(() => {
        return (
            <>
                <div style={{display: 'inline-block', 
                    fontFamily: setFont(GetInText2(returnTargetTextInfo(2)?.text,'font')), 
                    color: GetInText2(returnTargetTextInfo(2)?.text,'color'),
                    //flex: 1,
                    //alignItems: 'center',
                    //justifyContent: 'center',  
                    //paddingTop: objectMaster.width > 120 ? (GetInText2(text1,'message').length > 16 ? 4.2 : 13) : (GetInText2(text1,'message').length > 8 ? 4.2 : 13),
                    textAlign: 'center', 
                    paddingTop: bannerOffsetTop,
                }}>
                    {GetInText2(returnTargetTextInfo(2)?.text,'message')}
                </div>
            </>
        )
    }, [textList, bannerOffsetTop]);

    const drawWhiteBoard = useMemo(() => {
        return (objectMaster.type === 2000100 || objectMaster.type === 2000102) && (
            <Fragment>
                <div
                    key={`whiteBoard${id}`}
                    id={`whiteBoard${id}`}
                    onClick={() => setIsWhiteBoardOpen(true)}
                    className={className}
                    style={{
                        ...whiteBoardStyle,
                        left: objectMaster.textOffsetLeft,
                        top: objectMaster.textOffsetTop,
                        height: 70,
                        
                    }}
                >
                    <Typography className={className} 
                                style={{ ...whiteBoardTextStyle,
                                        marginTop: whiteBoardOffsetTop,
                                        width: objectMaster.textWidth, 
                                        
                                }}
                                variant="body2" 
                                color="textSecondary">
                        {whiteBoardContent}
                    </Typography>
                </div>
                <EditBanner
                    className={className}
                    isOpen={isWhiteBoardOpen && isEditable}
                    floorObjectId={id}
                    type={objectMaster.type}
                    title="表示内容"
                    text={text1}
                    maxLength={objectMaster.textLength}
                    onClose={() => setIsWhiteBoardOpen(false)}
                    sendText={props.sendBannerText}
                />

            </Fragment>

            // eslint-disable-next-line react-hooks/exhaustive-deps
        )
    }, [text1, isEditable, isWhiteBoardOpen, objectMaster.type, objectMaster.textWidth, objectMaster.textLength]);

    const drawBanner = useMemo(() => {
        return (objectMaster.type === 2000100 || objectMaster.type === 2000102) && (
            <Fragment>
                <div
                    key={`meetingRoomBanner${id}`}
                    id={`meetingRoomBanner${id}`}
                    onClick={() => setIsBannerOpen(true)}
                    className={className}
                    style={{
                        ...SELECT_NONE,
                        position: "absolute",
                        left: returnTargetTextInfo(2)?.textPositionMaster.textOffsetLeft,
                        top: returnTargetTextInfo(2)?.textPositionMaster.textOffsetTop,
                        width: returnTargetTextInfo(2)?.textPositionMaster.width,
                        height: returnTargetTextInfo(2)?.textPositionMaster.height,
                        zIndex: ZIndex.floorObject,
                        textAlign: "center",
                        pointerEvents: 'auto',
                    }}
                >
                    <Typography className={className} 
                        style={{ ...bannerTextStyle,
                            width: returnTargetTextInfo(2)?.textPositionMaster.width,
                            height: returnTargetTextInfo(2)?.textPositionMaster.height,
                        }} 
                        variant="body2" 
                        color="textSecondary">
                        {bannerContent}
                    </Typography>
                </div>
                <EditBannerInRoom
                    className={className}
                    isOpen={isBannerOpen && isEditable}
                    floorObjectId={id}
                    textId={returnTargetTextInfo(2)?.id}
                    textType={returnTargetTextInfo(2)?.textPositionMaster.textTypeMaster.id}
                    type={objectMaster.type}
                    title="表示内容"
                    text={returnTargetTextInfo(2)?.text}
                    maxLength={returnTargetTextInfo(2)?.textPositionMaster.textLength}
                    onClose={() => setIsBannerOpen(false)}
                    sendText={props.sendRoomText}
                />

            </Fragment>

            // eslint-disable-next-line react-hooks/exhaustive-deps
        )
    }, [isEditable, isBannerOpen, objectMaster.type, bannerContent]);

    const drawStaticKanban = useMemo(() => {
        return ( objectMaster.type === 2000100 || objectMaster.type === 2000101 || objectMaster.type === 2000102 || objectMaster.type === 2000004 || objectMaster.type === 1000000 ) && (
        <Fragment>
            <div
                key={`staticKanban${id}`}
                id={`staticKanban${id}`}
                className={className}
                onClick={() => setIsStaticKanbanOpen(true)}
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    left: returnTargetTextInfo(1)?.textPositionMaster.textOffsetLeft,
                    top: returnTargetTextInfo(1)?.textPositionMaster.textOffsetTop,
                    height: returnTargetTextInfo(1)?.textPositionMaster.height,
                    zIndex: ZIndex.floorObject,
                    pointerEvents: 'auto',
                }}
            >
                <Typography className={className} 
                    style={{ ...staticKanbanTextStyle, 
                                width: returnTargetTextInfo(1)?.textPositionMaster.width,}}
                                variant="body2" 
                                color="textSecondary">
                    {returnTargetTextInfo(1)?.text}
                </Typography>
            </div>

            <EditTextDialogInRoom
                className={className}
                isOpen={isStaticKanbanOpen && isEditable}
                floorObjectId={id}
                textId={returnTargetTextInfo(1)?.id}
                textType={returnTargetTextInfo(1)?.textPositionMaster.textTypeMaster.id}
                title={'表示内容'}
                text={returnTargetTextInfo(1)?.text}
                maxLength={returnTargetTextInfo(1)?.textPositionMaster.textLength}
                onClose={() => setIsStaticKanbanOpen(false)}
                sendText={props.sendRoomText}
            />
        </Fragment>
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        )
    }, [isStaticKanbanOpen, isEditable, textList])
    
    const chairLabelWidth = (chairLabelText: string, chairLabelWidth: number) => {
        return chairLabelText.length === 0 ? 24 : Math.min((judgehz(chairLabelText) + 0.5) * 12 + 2, chairLabelWidth);
    };

    const chairLabelContent = (chairLabelInfo: string) => {
        return chairLabelInfo.length === 0
            ? <img src={CreateIcon} style={{ width: 24, height: 24 }} />
            : chairLabelInfo;
    };

    const setIsChairOpen = (targetChair : number, state : boolean) => {
        setIsChairOpenList((prevState) => prevState.map((obj) => (obj.textId === targetChair ? {...obj, isOpen: state} : {...obj})));
    }

    const drawChairLabel = useMemo(() => {
        if(objectMaster.type !== 2000102 || textList == null){ // 座席ラベル持ちオブジェクトではない or textListがnull であれば終了
            return;
        }
        
        let chairLabelInfoList = textList.filter(text => text.textPositionMaster.textTypeMaster.id === 3);
        if(chairLabelInfoList.length !== 1){ // 座席ラベル情報がないのであれば終了
            return;
        }

        let chairLabelContents: ChairLabelData;
        try {
            chairLabelContents = JSON.parse(chairLabelInfoList[0].text);
        } catch (error) {
            console.log(error);
            return;
        }

        return (
            // chairLabelInfoList.map((chairLabelInfo) => {
            isChairOpenList.map((openInfo) => {
                // let isOpen = isChairOpenList.find(obj => obj.textId === chairLabelInfo.id)?.isOpen ?? false;
                const key = `id${openInfo.textId}`; // パースした情報にアクセスするためのキー作成
                return(
                    <Fragment>
                        <div
                            key={`chairLabel${id}`}
                            id={`chairLabel${id}`}
                            className={className}
                            onClick={() => setIsChairOpen(openInfo.textId,true)}
                            style={{
                                ...chairLabelTextStyle,
                                left: chairLabelContents[key].left,
                                top: chairLabelContents[key].top,
                                minWidth: chairLabelWidth(chairLabelContents[key].text,chairLabelInfoList[0].textPositionMaster.width),
                                maxWidth: chairLabelInfoList[0].textPositionMaster.width,
                                minHeight: chairLabelContents[key].text.length === 0 ? 24 : 15,
                                marginTop: chairLabelContents[key].text.length === 0 ? -4 : 0,
                                border: chairLabelContents[key].text.length === 0 ? "none" : "1px solid #AAA",
                                backgroundColor: chairLabelContents[key].text.length === 0 ? "rgba(0,0,0,0)" : "#ffffff",
                                pointerEvents: 'auto',
                            }}
                        >
                            {chairLabelContent(chairLabelContents[key].text)}
                        </div>

                        <EditTextDialogInRoom
                            className={className}
                            isOpen={openInfo.isOpen && isEditable}
                            floorObjectId={id}
                            textId={chairLabelInfoList[0].id}
                            textType={chairLabelInfoList[0].textPositionMaster.textTypeMaster.id}
                            title={'表示内容'}
                            chairId={openInfo.textId}
                            chairText={chairLabelInfoList[0].text}
                            text={chairLabelContents[key].text}
                            maxLength={chairLabelInfoList[0].textPositionMaster.textLength}
                            onClose={() => setIsChairOpen(openInfo.textId,false)}
                            sendText={props.sendRoomText}
                        />
                    </Fragment>
                // eslint-disable-next-line react-hooks/exhaustive-deps
                )
            })
        );
        }, [objectMaster.type, isChairOpenList, textList])

    const kanbanLength = useMemo(
        () => 26
        , [text1]);

    const videoURLLength = useMemo(
        () => 512
        , [videoURL, text2]);
   
    const kanbanLeft = useMemo(() => {
        return kanbanMaxWidth - text2Width + 48;
    }, [text2Width, objectMaster.type, objectMaster.width, objectMaster.editMinusOffsetLeft]);
    
    const drawDynamicKanban = useMemo(() => (isDrawText && ( objectMaster.type === 2000100 || objectMaster.type === 2000101 || objectMaster.type === 2000102 || objectMaster.type === 2000004 )) && (
        <Fragment>
            <div
                key={`meetingRoomDynamicKanban${id}`}
                id={`meetingRoomDynamicKanban${id}`}
                className={className}
                onClick={() => setIsOpen(true)}
                style={{
                    ...dynamicKanbanStyle,
                    left: kanbanLeft,
                    minWidth: text2Width,
                    maxWidth: text2Width,
                    pointerEvents: "auto",
                    minHeight: GetInText(text2, objectMaster.type, 'message').length === 0 ? 24 : 15,
                    marginTop: GetInText(text2, objectMaster.type, 'message').length === 0 ? -8 : -3,
                    marginLeft: GetInText(text2, objectMaster.type, 'message').length === 0 ? 10 : 10,
                    border: GetInText(text2, objectMaster.type, 'message').length === 0 ? "none" : "1px solid #AAA",
                    backgroundColor: GetInText(text2, objectMaster.type, 'message').length === 0 ? "rgba(0,0,0,0)" : "#ffffff",
                }}
            >
                {dynamicContent}
            </div>

            <EditTextDialog
                className={className}
                isOpen={isOpen && isEditable}
                floorObjectId={id}
                title={'表示内容'}
                text={text2}
                maxLength={kanbanLength}
                onClose={() => setIsOpen(false)}
                sendText={props.sendKanbanText}
            />
        </Fragment>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text1, text2, isOpen, isEditable, objectMaster.width, isDrawText, text2Width])

    const drawDynamicSwitch = useMemo(() => (
        ( Utility.isVideoConference(props.floorObject.objectMaster.type) ) ?
            <Fragment>
                <div
                    key={`meetingRoomDynamicSwitch${id}`}
                    id={`meetingRoomDynamicSwitch${id}`}
                    className={className}
                    onClick={() => setIsVideoURLOpen(true)}
                    style={{
                        ...dynamicKanbanStyle,
                        left: (Utility.isStudySeat(objectMaster.type, objectMaster.paletteText)) 
                                ? (objectMaster.width - objectMaster.editMinusOffsetLeft) - 56 
                                : (objectMaster.width - objectMaster.editMinusOffsetLeft) - 32,
                        marginTop: -8,
                        height: 24,
                        width: 24,
                        pointerEvents: "auto",
                        border: "none",
                        backgroundColor: "rgba(0,0,0,0)",
                    }}
                >
                    {dynamicSwitch}
                </div>
                <EditVideoURLDialog
                    className={className}
                    isOpen={isVideoURLOpen && isEditable}
                    floorObjectId={id}
                    title="外部Web会議ツール設定"
                    text={text2}
                    maxLength={videoURLLength}
                    onClose={() => setIsVideoURLOpen(false)}
                    isResetURL={GetCehckStat(text2)}
                    sendText={props.sendVideoURLText}
                />
            </Fragment>
        :
            <Fragment/>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [text2, videoURL, isResetURL, isVideoURLOpen, isEditable, objectMaster.width, objectMaster.editMinusOffsetLeft])

    // 会議室のロック状態アイコン
    const lockStatus = useMemo(() => {
        return (
            lockedMeetingRoom === true
                ? <div style={{ position: "relative" }}>
                    <img src={QueuePlayNextIconON} style={{ width: 24, height: 24, position: "absolute", left: 0 }} />
                    <div style={{ height: "20px", width: "20px", background: "#41BDCE", borderRadius: "50%", position: "absolute", left: 2, top: 2 }} />
                    <LockIcon style={{ width: 18, height: 18, color: '#ffffff', position: "absolute", left: 3, top: 3 }} />
                </div>
                : <div style={{position: "relative"}}>
                    <img src={CreateIcon} style={{ width: 24, height: 24, position: "absolute", left: 0}}/>
                    <div style={{ height: "20px", width: "20px" , background: "white" , borderRadius: "50%", position: "absolute", left: 2, top: 2}}/>
                    <LockOpenIcon style={{ width: 18, height: 18, color: 'rgba(0, 0, 0, 0.65)', position: "absolute", left: 3, top: 3 }} />
                </div>
                // ? <LockIcon style={{ width: 24, height: 24, color: '#ffffff', background: '#2196f3' }} />
                // : <LockOpenIcon style={{ width: 24, height: 24 }} />
        )
    }, [props.floorObject.objectMaster.openmeetMemberMax, lockedMeetingRoom]);

    const drawlockStatus = useMemo(() => (isDrawText && ( objectMaster.type === 2000004 || objectMaster.type === 2000100 || objectMaster.type === 2000102 )) && (
        <Fragment>
            <div
                key={`meetingRoomLockStatus${id}`}
                id={`meetingRoomLockStatus${id}`}
                className={className}
                onClick={() => { if (isEditable) { setOpenDialog(true) } }}
                style={{
                    ...dynamicKanbanStyle,
                    left: (Utility.isStudySeat(objectMaster.type, objectMaster.paletteText)) 
                            ? (objectMaster.width - objectMaster.editMinusOffsetLeft) - 32
                            : (objectMaster.width - objectMaster.editMinusOffsetLeft) - 8,
                    marginTop: -8,
                    height: 24,
                    width: 24,
                    pointerEvents: "auto",
                    border: "none",
                    backgroundColor: "rgba(0,0,0,0)",
                }}
            >
                {lockStatus}
            </div>
        </Fragment>
    ), [lockedMeetingRoom, objectMaster.iconOffsetRight, isEditable]);

    const studySeatSetting = useMemo(() => {
        return (
            isStudySeatSetting === true
                ? <div style={{ position: "relative" }}>
                    <img src={QueuePlayNextIconON} style={{ width: 24, height: 24, position: "absolute", left: 0 }} />
                    <div style={{ height: "20px", width: "20px", background: "#41BDCE", borderRadius: "50%", position: "absolute", left: 2, top: 2 }} />
                    <SettingsIcon style={{ width: 18, height: 18, color: '#ffffff', position: "absolute", left: 3, top: 3 }} />
                </div>
                : <div style={{position: "relative"}}>
                    <img src={CreateIcon} style={{ width: 24, height: 24, position: "absolute", left: 0}}/>
                    <div style={{ height: "20px", width: "20px" , background: "white" , borderRadius: "50%", position: "absolute", left: 2, top: 2}}/>
                    <SettingsIcon style={{ width: 18, height: 18, color: 'rgba(0, 0, 0, 0.65)', position: "absolute", left: 3, top: 3 }} />
                </div>
        )
    }, [isStudySeatSetting]);

    const drawStudySeatSetting = useMemo(() => (
        ( Utility.isStudySeat(props.floorObject.objectMaster.type, props.floorObject.objectMaster.paletteText) ) ?
            <Fragment>
                <div
                    key={`meetingRoomStudySeatSetting${id}`}
                    id={`meetingRoomStudySeatSetting${id}`}
                    className={className}
                    onClick={() => { if (isEditable) { setIsOpenStudySeatSettingDialog(true) } }}
                    style={{
                        ...dynamicKanbanStyle,
                        left: (objectMaster.width - objectMaster.editMinusOffsetLeft) - 8,
                        marginTop: -8,
                        height: 24,
                        width: 24,
                        pointerEvents: "auto",
                        border: "none",
                        backgroundColor: "rgba(0,0,0,0)",
                    }}
                >
                    {studySeatSetting}
                </div>
                <StudySeatSettingDialog
                    className={className}
                    id={props.floorObject.id}
                    isOpen={isOpenStudySeatSettingDialog && isEditable}
                    onClose={() => setIsOpenStudySeatSettingDialog(false)}
                    isEnable={isStudySeatSetting}
                    setIsEnable={setIsStudySeatSetting}
                    updateStudyObjectSetting={props.updateStudyObjectSetting}
                />
            </Fragment>
        :
            <Fragment/>
    ), [objectMaster.width, objectMaster.editMinusOffsetLeft, isOpenStudySeatSettingDialog, isStudySeatSetting, isEditable, props.updateStudyObjectSetting]);

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        props.sendMeetingRoomLock(id, !lockedMeetingRoom);
    }

    const drawDialog = useMemo(() => 
        <BaseDialog
            id="Dialog"
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="Dialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="DialogTitle" className={classes.dialogTitle}>
                確認
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    {lockedMeetingRoom === true
                        ? <Grid item xs={12} style={{ paddingTop: 5 }}>
                            この部屋を解錠します。
                            <br/>
                            ※解錠すると新たなユーザーが入室できるようになります。
                        </Grid>
                        : <Grid item xs={12} style={{ paddingTop: 5 }}>
                            この部屋を施錠します。
                            <br/>
                            ※施錠すると新たに入室できなくなります。
                            <br/>
                            ※管理者はこの制限を受けません。
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOk} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                <Button onClick={handleDialogClose} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [openDialog]);

    const draw = useMemo(() => (
        <div
            key={`meetingRoom${id}`}
            id={`meetingRoom${id}`}
            className={className}
            style={{
                ...SELECT_NONE,
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorSection,
                pointerEvents: "none",
            }}
        >
            {drawWhiteBoard}
            {drawStaticKanban}
            {drawBanner}
            {drawChairLabel}
            {drawDynamicKanban}
            {drawDynamicSwitch}
            {drawlockStatus}
            {drawStudySeatSetting}
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [id, offsetLeft, offsetTop, objectMaster, text1, text2, videoURL, isResetURL, isOpen, isWhiteBoardOpen, isBannerOpen, isStaticKanbanOpen, isVideoURLOpen, isChairOpenList, isDrawText, lockedMeetingRoom, textList, isEditable, isOpenStudySeatSettingDialog, isStudySeatSetting, props.updateStudyObjectSetting]);

    return (
        <Fragment>
            {draw}
            {drawDialog}
        </Fragment>
    );
}
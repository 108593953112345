import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withStyles, makeStyles, useTheme, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Divider, FormLabel, Grid, Radio, RadioGroup, OutlinedInput, Slide, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import BaseDialog from './../user/BaseDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import { userListConst } from './UserListConst';
import axios, { AxiosResponse } from 'axios';
import { red } from '@material-ui/core/colors';
import useReactRouter from 'use-react-router';
import { printable } from '../log/LogSetting'
// import { AlertWebRtcSize, AlertWebRtcSizeHandler } from '../user/AlertWebRtcSize';
import WhiteList from './WhiteList';
import AdminMessage from './AdminMessage';
import OtherWebsiteSetting from "./OtherWebsiteSetting";
import ForceMute from './ForceMute';
import Clapping from './Clapping';
import TenantSettingPage from './TenantSettingPage';
import { Utility } from '../common/Utility';
import { VpnKey } from '@material-ui/icons';
import { FlexibleNamePlate } from '../common/JsonClass';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import Update from '@material-ui/icons/Update';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccessTimeIcon from '@material-ui/icons/AccessAlarm';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ChimeSetting from './ChimeSetting';
import TimeManagement from './OpenTimeManagement';
import ZoomUrlSetting from './ZoomUrlSetting';
import InputAdornment from '@material-ui/core/InputAdornment';
import JfsClient, {RegisterUserResponse, UserInfoRegisterFormData, UserInfoUpdateFormData, Floor, TenantRegistraionResponse, ResEnabledMenu, UserInfoListResponse, JfsError} from '@fsi/jfs-sdk';
import { usermanualURL } from '../common/DocumentURL';

registerLocale('ja', ja);

if (printable === 0) {
  console.log = function () { }
  console.warn = function () { }
  console.error = function () { }
}

class Tag {
  item: string = '';
  value: string = '';
  profileOrder: number = 0;
}
class ContactAddress {
  name: string = '';
  contactAddress: string = '';
}
class UserData {
  subId: string = '';
  loginId: string = '';
  lastName: string = '';
  firstName: string = '';
  displayName: string = '';
  // accessDate: any = null;
  registeredDate: any = null;
  password: string = '';
  confirmPassword: string = '';
  isResetPassword: boolean = false;
  tags: Tag[] = [] as Tag[];
  contactAddressList: ContactAddress[] = [] as ContactAddress[];
  roleAdmin: boolean = false;
  roleRoot: boolean = false;
  forceDisplayName: boolean = false;
  roleSystem: boolean = false;
  flexibleNamePlate: FlexibleNamePlate = new FlexibleNamePlate();
}

class SearchData {
  public subId: string = '';
  public loginId: string = '';
  public displayName: string = '';
  public tags: Tag[] = [];
}

class ErrorInfo {
  name: string = '';
  no: number = 0;
  code: string = '';
  description: string | ErrorInfo[] = '';
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const columns = [
  { id: 'loginId', label: 'ID', minWidth: 120 },
  { id: 'displayName', label: '表示名', minWidth: 120 },
  { id: 'edit', label: '', minWidth: 20, maxWidth: 20 },
  { id: 'delete', label: '', minWidth: 20, maxWidth: 20 },
  // { id: 'accessDate', label: '最終利用日時', minWidth: 120 },
  { id: 'registeredDate', label: '登録日時', minWidth: 420 },
];

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    //    flexShrink: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  tagButton: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-start',
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  search: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,//`calc(100%)`,// - ${drawerWidth})`,
    justifyContent: 'center',
  },
  table: {
    maxHeight: `calc(100vh - 60px)`
    //    minWidth: 1000,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
  tagKey: {
    width: 100,
  },
  tagValue: {
    width: 280,
  },
  tagOrder: {
    width: 70,
  },
  addressName: {
    width: 100,
  },
  addressValue: {
    width: 350,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff',
  },
  card: {
    width: 150,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  attendance: {
    //    flexShrink: 0,
    marginTop: '-10px',
    height: 360,
    width: 600,
    // height: '100%',
    // width: `100%`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  picker: {
    width: 300,
    height: 40,
    fontSize: 20,
    textAlign: 'center',
    margin: 10,
    marginLeft: 0,
  },
  grid: {
    margin: "auto",
  },
  header: {
    tableLayout:'fixed',
  }
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

class ConfirmInfo {
  open: boolean = true;
  message: string = "";
  isOk: boolean = false;
  type: number = 0;

  constructor(init: Partial<ConfirmInfo>) {
    Object.assign(this, init);
  }
}
class FinishInfo {
  open: boolean = true;
  message: string = "";
  password: null | string = null;

  constructor(init: Partial<FinishInfo>) {
    Object.assign(this, init);
  }
}

function dateToFormatString(date: Date, format: string) {

  format = format.replace(/YYYY/, date.getFullYear().toString());
  format = format.replace(/MM/, (date.getMonth() + 1).toString());
  format = format.replace(/DD/, date.getDate().toString());

  return format;
}

export default function UserList() {
  const { history } = useReactRouter();
  const historyState = history.location.state as any;
  const role = historyState?.role;
  const [authType, setAuthType] = React.useState(0);
  const classes = useStyles();
  /** mainコンテンツ切替 */
  const [isShowWhiteList, setIsShowWhiteList] = React.useState(false);
  const [isShowAdminMessage, setIsShowAdminMessage] = React.useState(false);
  const [isShowClapping, setIsShowClapping] = React.useState(false);
  const [isShowOtherWebsiteSetting, setIsShowOtherWebsiteSetting] =
    React.useState(false);
  const [isShowForceMute, setIsShowForceMute] = React.useState(false);
  const [isShowMenuWebSiteViewer, setIsShowMenuWebSiteViewer] = React.useState(false);
  const [isShowMenuAdminmessage, setIsShowMenuAdminmessage] = React.useState(false);
  const [isShowMenuForceMute, setIsShowMenuForceMute] = React.useState(false);
  const [isShowMenuClap, setIsShowMenuClap] = React.useState(false);
  const [isShowMenuAuth, setIsShowMenuAuth] = React.useState(false);

  const [isShowAuthSetting, setIsShowAuthSetting] = React.useState(false);
  const [isShowChimeSetting, setIsShowChimeSetting] = React.useState(false);
  const [isShowTimeManagement, setIsShowTimeManagement] = React.useState(false);
  const [isShowZoomUrlSetting, setIsShowZoomUrlSetting] = React.useState(false);
  /** pagenation */
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(userListConst.table.rowsPerPage);
  /** 本テナントで使用可能な名札リスト */
  const [flexibleNamePlateList, setFlexibleNamePlateList] = React.useState<FlexibleNamePlate[]>([]);
  const [selectedFlexibleNamePlate, setSelectedFlexibleNamePlate] = React.useState(new FlexibleNamePlate());
  /** TablePaginationActions で使用 */
  const handleChangePage = (event: any, newPage: number) => {
    executeSearchByPage(newPage + 1); // pagenationのpageは0が先頭, APIのpageは1が先頭
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };
  useEffect(() => {
    getEnabledMenu(sessionStorage.getItem("TABID") as string);
    getNamePlateColors();
  }, []);
  useEffect(() => {
    executeSearchByPage(1);
  }, [rowsPerPage]);
  /** CSVアップロード用 */
  const [uploadCSVFile, setUploadCSVFile] = React.useState({});
  /** 検索用 */
  const [searchData, setSearchData] = React.useState(new SearchData());
  const clearSearchData = () => {
    // stateへの直接書き込みはReactのanti paternなので修正を行ったが、修正後動作確認で、
    // 「全ユーザーリストダウンロード」の挙動が変わってしまったため、修正前のロジックへ戻した。
    searchData.subId = '';
    searchData.loginId = '';
    searchData.displayName = '';
    searchData.tags.length = 0;
    setSearchData(Object.assign({}, searchData));
    return searchData;
  }
  const clearSearchElement = () => {
    let loginId_search = document.getElementById('loginId_search') as HTMLInputElement;
    if (loginId_search != null) {
      loginId_search.value = '';
    }
    let displayName_search = document.getElementById('displayName_search') as HTMLInputElement;
    if (displayName_search != null) {
      displayName_search.value = '';
    }
  }

  /** 詳細表示用 */
  const [detailOpened, setDetailOpened] = React.useState(false);
  const [detailFuncNum, setDetailFuncNum] = React.useState(0);
  const [readOnlyId, setReadOnlyId] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [detailData, setDetailData] = React.useState(new UserData());
  const [disableDisplayNameSetting, setDisableDisplayNameSetting] = React.useState(true);
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const clearDetailData = () => {
    const data = new UserData();
    setDetailData(data);
    return data;
  }
  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });
  const clearErrorData = () => {
    errorData.code = '';
    errorData.message = '';
    if (errorData.errorDetail === null || errorData.errorDetail === undefined) {
      errorData.errorDetail = [];
    } else {
      errorData.errorDetail.length = 0;
    }
    return errorData;
  }
  const [searchMessage, setSearchMessage] = React.useState("");

  const jfsClient = JfsClient.getInstance();
  const { httpClient, wsClient, JFS_ERRORS } = jfsClient;

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if ((err as JfsError).code) {
      // if (err.code === 102003) {
      //   Object.assign(errorData, userListConst.csvDataConflictMessage);
      // } else 
      if ((err as JfsError).code === 102003) {
        Object.assign(errorData, userListConst.responseEmptyMessage);
      } else if ((err as JfsError).code === 102004) {
        Object.assign(errorData, userListConst.responseEmptyMessage);
        errorData.message = (err as JfsError).detail;
      }else if ((err as JfsError).code === 102006) {
        Object.assign(errorData, userListConst.responseEmptyMessage);
        errorData.message = (err as JfsError).detail;
      } else if ((err as JfsError).code === 102007) {
        Object.assign(errorData, userListConst.responseEmptyMessage);
        errorData.message = (err as JfsError).detail;
        // Object.assign(errorData, err);
      } else if ((err as JfsError).code === 102009) {
        Object.assign(errorData, userListConst.responseEmptyMessage);
        errorData.message = (err as JfsError).detail;
      } else if ((err as JfsError).code === 104) {
        Object.assign(errorData, userListConst.responseEmptyMessage);
        errorData.message = (err as JfsError).detail;
      } else if ((err as JfsError).code === JFS_ERRORS.REST_UNAUTH_ACCESS.code) {   // 103005
        Object.assign(errorData, userListConst.response0607Message);
      } else {
        Object.assign(errorData, err);
      }

      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }

    if(err.code) {
      if (err.code === 401006) {
        Object.assign(errorData, userListConst.responseError403);
        setErrorData(errorData);
        setErrorOpened(true);
        return;
      }
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  /**
   * 「ユーザー一覧」ボタンクリック時
   */
  const handleUserListBtnClick = () => {
    toggleWhiteList();
    toggleAdminMessage();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
    toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowTimeManagement(false);
    setIsShowZoomUrlSetting(false);
    clearSearchData();
    clearSearchElement();
    getUserList(1);
    setPage(0);
  }

  /** 詳細画面表示 
   * @param opened true:表示 / false:非表示
   * @param funcNum 
   * 0: 追加
   * 1: 編集
   * 2: 削除
   * 3: 詳細
   * @param user
   */
  const handleDetailOpened = async (opened: boolean, funcNum: number, user: any) => {
    toggleWhiteList();
    toggleAdminMessage();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
    toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    if (opened) {
      setDetailFuncNum(funcNum);
      setDetailName(funcNum);
      const readOnly: boolean = (funcNum <= 1) ? false : true;
      setReadOnly(readOnly);
      setReadOnlyId(funcNum > 0);
      if (funcNum === 0) {
        clearDetailData();
      }

      clearErrorData();
      clearHelperText();
      setChecked(false);
      setInputError(false);

      if (funcNum !== 0) {
        const detail = await getUserData(user.subId);
        if (detail) {
          setDetailData(Object.assign(detailData, {
            subId: detail.subId,
            loginId: detail.loginId,
            lastName: detail.lastName,
            firstName: detail.firstName,
            displayName: detail.displayName,
            password: "",
            confirmPassword: "",
            isResetPassword: detail.isResetPassword,
            tags: detail.tags,
            contactAddressList: detail.contactAddressList,
            roleAdmin: detail.roleAdmin,
            roleRoot: detail.roleRoot,
            roleSystem: detail.roleSystem,
            forceDisplayName: detail.forceDisplayName,
          }));
          setSelectedFlexibleNamePlate(detail.flexibleNamePlate);
          setDetailOpened(opened);
          if (authType === 0) {
            setDisableDisplayNameSetting(false);
          } else if (authType === 1) {
            setDisableDisplayNameSetting(!detail.forceDisplayName);
          } else {
            setDisableDisplayNameSetting(false);
          }
        }
      } else {
        setDetailData(Object.assign({}, new UserData()));
        setDetailOpened(opened);
        setDisableDisplayNameSetting(false);
        setSelectedFlexibleNamePlate(new FlexibleNamePlate());
      }
    } else {
      setDetailOpened(opened);
    }
  };

  /**
   * 検索ボタンのハンドラ
   * 新規検索を行い、検索結果の先頭ページを表示する
   */
  const handleSearchBtn = () => executeSearchByPage(1);

  /** 詳細画面の機能名、ボタンのラベルに使う */
  const [detailLabel, setDetailLabel] = React.useState("");
  const setDetailName = (detailFuncNum: number) => {
    switch (detailFuncNum) {
      case 0:
        setDetailLabel("追加");
        break;
      case 1:
        setDetailLabel("編集");
        break;
      case 2:
        setDetailLabel("削除");
        break;
      case 3:
        setDetailLabel("詳細");
        break;
      default:
        setDetailLabel("");
        break;
    }
  }

  /** 詳細画面のYES系ボタンのハンドラ
   * 
   */
  const handleUserListYes = () => {
    if (checkInput()) {
      setInputError(false);
      setdeleteError(false);
      //handleDetailOpened(false, detailFuncNum, null);
      switch (detailFuncNum) {
        case 0:
          handleDetailOpened(false, detailFuncNum, null);
          registUser();
          break;
        case 1:
          handleDetailOpened(false, detailFuncNum, null);
          updateUser();
          break;
        case 2:
          deleteUser();
          break;
        case 3:
          handleDetailOpened(false, detailFuncNum, null);
          break;
        default:
          handleDetailOpened(false, detailFuncNum, null);
          break;
      }
    } else {
      setInputError(true);
    }
  }

  /** 詳細画面のキャンセルボタンのハンドラ
   * 
   */
  const handleUserListCancel = () => {
    handleDetailOpened(false, detailFuncNum, null);
    setdeleteError(false);
  }

  /** 詳細画面のタグ追加ボタンのハンドラ
   * 
   */
  const handleAddTag = () => {
    if (detailData.tags.length < userListConst.tag.maxNumber) {
      //var newDetailData = Object.assign({}, detailData);
      const newTag: Tag = new Tag();
      newTag.item = '';
      newTag.value = '';
      newTag.profileOrder = detailData.tags.length + 1;
      detailData.tags = Object.assign([], [...detailData.tags, newTag]);
      setDetailData(Object.assign({}, detailData));
    }
  }

  /** 詳細画面のタグ削除ボタンのハンドラ
   * 
   */
  const handleDeleteTag = (target: number) => {
    var newTag = detailData.tags.filter((tag: Tag, index: number) => index !== target);
    detailData.tags = Object.assign([], newTag);
    detailData.tags.map((tag: Tag, index: number) => {
      const item = (document.getElementById('tagKey' + index) as HTMLInputElement);
      const value = (document.getElementById('tagValue' + index) as HTMLInputElement);
      const profileOrder = (document.getElementById('tagOrder' + index) as HTMLInputElement);
      item.value = tag.item;
      value.value = tag.value;
      profileOrder.value = tag.profileOrder.toString();
      return tag;
    });
    setDetailData(Object.assign({}, detailData));
  }

  /** 詳細画面の連絡先追加ボタンのハンドラ
   * 
   */
   const handleAddContactAddress = () => {
    if (detailData.contactAddressList.length < userListConst.contactAddress.maxNumber) {
      const newContactAddress: ContactAddress = new ContactAddress();
      newContactAddress.name = '';
      newContactAddress.contactAddress = '';
      detailData.contactAddressList = Object.assign([], [...detailData.contactAddressList, newContactAddress]);
      setDetailData(Object.assign({}, detailData));
    }
  }

  /** 詳細画面の連絡先削除ボタンのハンドラ
   * 
   */
  const handleDeleteContactAddress = (target: number) => {
    var newContactAddress = detailData.contactAddressList.filter((contactAddress: ContactAddress, index: number) => index !== target);
    detailData.contactAddressList = Object.assign([], newContactAddress);
    detailData.contactAddressList.map((contactAddress: ContactAddress, index: number) => {
      const name = (document.getElementById('addressName' + index) as HTMLInputElement);
      const value = (document.getElementById('addressValue' + index) as HTMLInputElement);
      name.value = contactAddress.name;
      value.value = contactAddress.contactAddress;
      return contactAddress;
    });
    setDetailData(Object.assign({}, detailData));
  }

  /** CSV登録画面用 */
  const [registerCSVOpened, setRegisterCSVOpened] = React.useState(false);
  const handleRegisterCSVOpened = async (opened: boolean) => {
    toggleWhiteList();
    toggleAdminMessage();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
    toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    if (opened === true) {
      setUploadCSVFile({});
      await showOpenFileDialog();
    }
    setRegisterCSVOpened(opened);
  }
  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);

  /** 詳細画面HelperText */
  const [loginIdHelperText, setLoginIdHelperText] = React.useState("");
  const [lastNameHelperText, setLastNameHelperText] = React.useState("");
  const [firstNameHelperText, setFirstNameHelperText] = React.useState("");
  const [displayNameHelperText, setDisplayNameHelperText] = React.useState("");
  const [forceDisplayNameHelperText, setForceDisplayNameHelperText] = React.useState("");
  const [passwordHelperText, setPasswordHelperText] = React.useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = React.useState("");
  const [tagsHelperText, setTagsHelperText] = React.useState(["", "", "", "", "", "", "", "", "", ""]);
  const [contactAddressListHelperText, setContactAddressListHelperText] = React.useState(["", "", "", "", "", "", "", "", "", ""]);
  const clearHelperText = () => {
    setLoginIdHelperText("");
    setLastNameHelperText("");
    setFirstNameHelperText("");
    setDisplayNameHelperText("");
    setForceDisplayNameHelperText("");
    setPasswordHelperText("");
    setConfirmPasswordHelperText("");
    setTagsHelperText(["", "", "", "", "", "", "", "", "", ""]);
    setContactAddressListHelperText(["", "", "", "", "", "", "", "", "", ""]);
  }
  const [checkedLoginId, setCheckedLoginId] = React.useState(false);
  const [checkedLastName, setCheckedLastName] = React.useState(false);
  const [checkedFirstName, setCheckedFirstName] = React.useState(false);
  const [checkedDisplayName, setCheckedDisplayName] = React.useState(false);
  const [checkedPassword, setCheckedPassword] = React.useState(false);
  const [checkedConfirmPassword, setCheckedConfirmPassword] = React.useState(false);
  const [checkedTags, setCheckedTags] = React.useState([false, false, false, false, false, false, false, false, false, false,]);
  const [checkedContactAddressList, setCheckedContactAddressList] = React.useState([false, false, false, false, false, false, false, false, false, false,]);
  const setChecked = (checked: boolean) => {
    setCheckedLoginId(checked);
    setCheckedLastName(checked);
    setCheckedFirstName(checked);
    setCheckedDisplayName(checked);
    setCheckedPassword(checked);
    setCheckedConfirmPassword(checked);
    setCheckedTags([checked, checked, checked, checked, checked, checked, checked, checked, checked, checked]);
    setCheckedContactAddressList([checked, checked, checked, checked, checked, checked, checked, checked, checked, checked]);
  }
  const [inputError, setInputError] = React.useState(false);
  const [deleteError, setdeleteError] = React.useState(false);
  const [users, setUsers] = React.useState<UserData[]>([]);
  const [dealType, setDealType] = React.useState(userListConst.upload.div);
  const [showBackdropForDowndating, setShowBackdropForDowndating] = React.useState(false);
  const showBackdrop = React.useRef(false);
  const [showBackdropForUpdatingList, setShowBackdropForUpdatingList] = React.useState(false);
  // const alertWebRtcSizeRef = useRef({} as AlertWebRtcSizeHandler);

  /** 画面初期表示時処理 */
  useEffect(() => {
    executeSearchByPage(1);
    // alertWebRtcSizeRef.current.checkWebRtcSize();
    getTenantRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 一括登録・更新処理のドロップダウンリストの初期値を設定 */
  useEffect(() => {
    if(authType === 0){
      setDealType(userListConst.upload.div);
    } else if(authType === 1){
      setDealType(userListConst.upload.div2);
    }
  }, [authType])

  /**「全ユーザーリストダウンロード」ハンドラ
   *    全ユーザーのcsvをダウンロードする
   */
  const handleDownloadCSVAllUser = () => {

    toggleWhiteList();
    toggleAdminMessage();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
    toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    clearErrorData();
    let currentSearchData = Object.assign({}, searchData);
    clearSearchData();
    downloadCSV();
    setSearchData(currentSearchData);
  }

  /**「検索結果ダウンロード」ハンドラ
   *    ユーザー検索結果のcsvをダウンロードする
   */
  const handleDownloadCSV = () => {
    clearErrorData();
    downloadCSV();
  }

  /**
   * 使用状況ダウンロード
   */
  const [startDate, setStartDate] = useState(new Date());
  const [floorList, setFloorList] = React.useState([] as Floor[]);
  const [floor, setFloor] = useState("");
  const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
  const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
  const [state, setState] = useState({
    selectFloor: '',
    selectDate: new Date,
    selectCSV: 'attendance',
  });

  const [reportCSVList, setReportCSVList] = React.useState(false);
  const [attendanceCSV, setAttendanceCSV] = React.useState(false);
  const [dispSelectFloor, setDispSelectFloor] = React.useState('');
  const [disableSelectFloor, setDisableSelectFloor] = React.useState(false);
  /** ユーザー登録完了画面用 */
  const [registerResultOpened, setRegisterResultOpened] = React.useState(false);

  // ユーザー登録完了ダイアログ制御
  const [registerResultData, setRegisterResultData] = React.useState({
    code: '',
    message: '',
  });
  const clearRegisterResultData = () => {
    registerResultData.code = '';
    registerResultData.message = '';
    return registerResultData;
  }


  // 使用状況クリック時の初期処理
  useEffect(() => {
    handleGetFloorList();
  }, [attendanceCSV]);

  useEffect(() => {
    if (confirmInfo.isOk) {
      //downloadUsageData();
      if(confirmInfo.type === 0){
        handleDownloadCSVAllUser();
      }else if(confirmInfo.type === 1){
        handleDownloadCSV();
      }else if(confirmInfo.type ===2){
        uploadCSV();
        showBackdrop.current = false;
      }
    }
  }, [confirmInfo.isOk]);

  const clearState = () => {
    setState({
      ...state,
      selectFloor: '',
      selectDate: new Date(),
      selectCSV: 'attendance',
    })
    setDispSelectFloor('');
    setDisableSelectFloor(false);
    setStartDate(new Date());
    setFloor("");
  }
  // セレクトボックス用のフロア情報取得
  const handleGetFloorList = () => {
    // axios
    //   .create({
    //     withCredentials: true,
    //   })
    //   .post('/api/v1/admin/floorlist', new URLSearchParams({
    //     tab_id: sessionStorage.getItem("TABID") as string
    //   }))
    httpClient.getAdminFloorList(sessionStorage.getItem("TABID") as string)
      .then((response: Floor[]) => {
        // 全フロア選択用のselect追加
        let allFloors = [] as Floor[];
        let allFloor = new Floor;
        allFloor.id = 0;
        allFloor.floorName = 'すべて';
        allFloors.push(allFloor);

        //let result = response.data as Floor[];
        let result = response;
        // alert("floor count:" + result.length);
        if (result.length > 0) {
          allFloors = allFloors.concat(result);
          setFloorList(allFloors);
        }
      })
      .catch((err) => {
        console.log(err.response);
        return;
      });
  }

  // フロア変更・セット
  const handleChangeFloor = (value: any) => {
    setState(() => {
      return { ...state, ["selectFloor"]: value.target.value.toString() };
    });
  }

  // 日付変更・セット
  const handleChangeDate = (date: Date) => {
    setState(() => {
      return { ...state, ["selectDate"]: date };
    });
  }

  const handleRadioChange = (event: any) => {
    if (event.target.value === 'meet') {
      setState(() => {
        return { ...state, ["selectFloor"]: '' };
      });
      setFloor("");
      setDispSelectFloor('none');
      setDisableSelectFloor(true);
    } else {
      setDispSelectFloor('');
      setDisableSelectFloor(false);
    }
    setState(() => {
      return { ...state, ["selectCSV"]: event.target.value };
      // alert('date:' + state.selectCSV);
    });
  };

  const handleAttendanceReport = () => {
    toggleWhiteList();
    toggleAdminMessage();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
    clearSearchData();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setReportCSVList(false);
    setAttendanceCSV(true);
    setDispSelectFloor('');
    setDisableSelectFloor(false);
    //downloadReport();
  }
  const handleDownloadReport = () => {
    toggleWhiteList();
    toggleAdminMessage();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
    toggleAuthSetting();
    clearSearchData();
    setReportCSVList(true);
    setAttendanceCSV(false);
    setDispSelectFloor('none');
    setDisableSelectFloor(true);
    //downloadReport();
  }
  const renderReportCsvList = () => {
    let list = [];
    let tabId: string = sessionStorage.getItem("TABID") as string;
    let calcDate = new Date();
    // for test
    //calcDate = new Date("1/1/2022 0:0:0");
    let nYear: number = calcDate.getFullYear();
    let nMonth: number = calcDate.getMonth() + 1;
    let meet = "/api/admin/report/getMeetCsv?type=Meet&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
    let online = "/api/admin/report/getMeetCsv?type=Online&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
    list.push(<div> {nYear}年{nMonth}月　 <a href={meet} >ミートデータ</a>　 <a href={online} >サインインデータ</a>　</div>);
    for (let calc_mm = 0; calc_mm < 4; calc_mm++) {
      //let nowDate = new Date();
      //calcDate.setMonth(nowDate.getMonth() - calc_mm);
      calcDate.setMonth(calcDate.getMonth() - 1);
      nYear = calcDate.getFullYear();
      nMonth = calcDate.getMonth() + 1;
      console.log("calcDate  YY=[" + calcDate.getFullYear() + "] MM=[" + (calcDate.getMonth() + 1) + "]");
      let yearmon = nYear * 100 + nMonth;
      if (yearmon >= 202101) {
        meet = "/api/admin/report/getMeetCsv?type=Meet&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
        online = "/api/admin/report/getMeetCsv?type=Online&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
        list.push(<div> {nYear}年{nMonth}月　 <a href={meet} >ミートデータ</a>　 <a href={online} >サインインデータ</a>　</div>);
      }
    }

    const retval = <React.Fragment>{list}</React.Fragment>
    return retval;
  }
  const handleReprtCsvClose = () => {
    clearState();
    setReportCSVList(false);
    setAttendanceCSV(false);
  }

  const handleRegisterResultYes = () => {
    setRegisterResultOpened(false);
    if (registerResultData.code === '0000') {
      executeSearchByPage(1);
    }
  }

  const showOpenFileDialog = () => {
    return new Promise(resolve => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.csv, text/plain';
      input.onchange = (ev) => {
        let eventTarget: HTMLInputElement = (ev.target as HTMLInputElement);
        if (eventTarget !== null && eventTarget.files !== null) {
          setUploadCSVFile(eventTarget.files[0]);
          if (uploadCSVFile != null) {
            resolve(uploadCSVFile);
          }
        }
      };
      input.click();
    });
  };
  const handleRegisterCSVYes = async () => {
    const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
    handleRegisterCSVOpened(false);
    await uploadCSV();
    return true;
  }
  const handleRegisterCSVCancel = () => {
    handleRegisterCSVOpened(false);
  }
  const handleErrorYes = () => {
    setErrorOpened(false);
    if (errorData.code === '0102') {
      executeSearchByPage(1);
    }
  }
  const checkFormat = (text: string, regexpStr: string) => {
    var reg = new RegExp(regexpStr);
    return reg.test(text);
  }
  const checkLoginId = () => {
    var isError: boolean = false;
    if (detailData.loginId.length >= userListConst.loginId.minLength) {
      setLoginIdHelperText("");
    } else {
      isError = true;
      setLoginIdHelperText(userListConst.loginId.minLength + "～" + userListConst.loginId.maxLength + "文字の入力が必要です。");
    }
    if (isError === false && detailData.loginId.length !== 0) {
      if (checkFormat(detailData.loginId, userListConst.loginId.format)) {
        setLoginIdHelperText("");
      } else {
        if (isError === false) {
          if (checkFormat(detailData.loginId, userListConst.loginId.format1)) {
          } else {
            setLoginIdHelperText("先頭に使用できる文字は、半角英大文字、半角英小文字、数字です。");
            isError = true;
          }
        }
        if (isError === false) {
          if (checkFormat(detailData.loginId, userListConst.loginId.format2)) {
          } else {
            setLoginIdHelperText("@を1文字使用してください。");
            isError = true;
          }
        }
        /*
        if (isError === false) {
          if (checkFormat(detailData.loginId, userListConst.loginId.format3)) {
          } else {
            setLoginIdHelperText("@以降に「.」を最低1文字使用してください。");
            isError = true;
          }
        }
        */
        if (isError === false) {
          if (checkFormat(detailData.loginId, userListConst.loginId.format4)) {
          } else {
            setLoginIdHelperText("@の直後に使用できる文字は、半角英大文字、半角英小文字、数字、記号の「-」、「_」です。");
            isError = true;
          }
        }
        if (isError === false) {
          setLoginIdHelperText("使用できる文字は、半角英大文字、半角英小文字、数字、記号の「-」、「_」、「@」です。");
          isError = true;
        }
      }
    }
    setCheckedLoginId(!isError);
    return !isError;
  }
  const checkLastName = () => {
    var isError: boolean = false;
    if (detailData.lastName.length >= userListConst.lastName.minLength) {
      setLastNameHelperText("");
    } else {
      isError = true;
      setLastNameHelperText(userListConst.lastName.minLength + "～" + userListConst.lastName.maxLength + "文字の入力が必要です。");
    }
    setCheckedLastName(!isError);
    return !isError;
  }
  const checkFirstName = () => {
    var isError: boolean = false;
    if (detailData.firstName.length >= userListConst.firstName.minLength) {
      setFirstNameHelperText("");
    } else {
      isError = true;
      setFirstNameHelperText(userListConst.firstName.minLength + "～" + userListConst.firstName.maxLength + "文字の入力が必要です。");
    }
    setCheckedFirstName(!isError);
    return !isError;
  }
  const checkDisplayName = () => {
    var isError: boolean = false;
    if (detailData.displayName.length >= userListConst.displayName.minLength) {
      setDisplayNameHelperText("");
    } else {
      isError = true;
      setDisplayNameHelperText(userListConst.displayName.minLength + "～" + userListConst.displayName.maxLength + "文字の入力が必要です。");
    }
    setCheckedDisplayName(!isError);
    return !isError;
  }
  const checkPassword = () => {
    var isError: boolean = false;
    if (detailFuncNum === 1 && detailData.password.length === 0) {
      setPasswordHelperText("");
      return true;
    }
    if (detailData.password.length >= userListConst.password.minLength) {
      setPasswordHelperText("");
    } else {
      isError = true;
      setPasswordHelperText(userListConst.password.minLength + "～" + userListConst.password.maxLength + "文字の入力が必要です。");
    }
    if (isError === false && detailData.password.length !== 0) {
      if (checkFormat(detailData.password, userListConst.password.format)) {
        setPasswordHelperText("");
      } else {
        if (isError === false) {
          if (checkFormat(detailData.password, userListConst.password.format1)) {
          } else {
            setPasswordHelperText("英小文字を最低1文字使用してください。");
            isError = true;
          }
        }
        if (isError === false) {
          if (checkFormat(detailData.password, userListConst.password.format2)) {
          } else {
            setPasswordHelperText("英大文字を最低1文字使用してください。");
            isError = true;
          }
        }
        if (isError === false) {
          if (checkFormat(detailData.password, userListConst.password.format3)) {
          } else {
            setPasswordHelperText("数字を最低1文字使用してください。");
            isError = true;
          }
        }
        if (isError === false) {
          setPasswordHelperText("使用できる文字は、半角英大文字、半角英小文字、数字です。");
          isError = true;
        }
      }
    }
    if (isError === false && detailData.password.length !== 0) {
      if (detailData.password.length !== 0 && detailData.password === detailData.confirmPassword) {
        setConfirmPasswordHelperText("");
      } else {
        isError = true;
        if (detailData.confirmPassword.length === 0) {
          setConfirmPasswordHelperText("確認のため、もう一度パスワードを入力して下さい。");
        } else {
          setConfirmPasswordHelperText("パスワードが一致していません。");
        }
      }
    }
    setCheckedPassword(!isError);
    return !isError;
  }
  const checkConfirmPassword = () => {
    var isError: boolean = false;
    if (detailFuncNum === 1 && detailData.confirmPassword.length === 0) {
      setConfirmPasswordHelperText("");
      return true;
    }
    if (detailData.confirmPassword.length >= userListConst.password.minLength) {
      setConfirmPasswordHelperText("");
    } else {
      isError = true;
      setConfirmPasswordHelperText(userListConst.password.minLength + "～" + userListConst.password.maxLength + "文字の入力が必要です。");
    }
    if (isError === false && detailData.confirmPassword.length !== 0) {
      if (checkFormat(detailData.confirmPassword, userListConst.password.format)) {
        setConfirmPasswordHelperText("");
      } else {
        if (isError === false) {
          if (checkFormat(detailData.confirmPassword, userListConst.password.format1)) {
          } else {
            setConfirmPasswordHelperText("英小文字を最低1文字使用してください。");
            isError = true;
          }
        }
        if (isError === false) {
          if (checkFormat(detailData.confirmPassword, userListConst.password.format2)) {
          } else {
            setConfirmPasswordHelperText("英大文字を最低1文字使用してください。");
            isError = true;
          }
        }
        if (isError === false) {
          if (checkFormat(detailData.confirmPassword, userListConst.password.format3)) {
          } else {
            setConfirmPasswordHelperText("数字を最低1文字使用してください。");
            isError = true;
          }
        }
        if (isError === false) {
          setConfirmPasswordHelperText("使用できる文字は、半角英大文字、半角英小文字、数字です。");
          isError = true;
        }
      }
    }
    if (isError === false) {
      if (detailData.password.length !== 0 && detailData.password === detailData.confirmPassword) {
        setConfirmPasswordHelperText("");
      } else {
        isError = true;
        if (detailData.confirmPassword.length === 0) {
          setConfirmPasswordHelperText("確認のため、もう一度パスワードを入力して下さい。");
        } else {
          setConfirmPasswordHelperText("パスワードが一致していません。");
        }
      }
    }
    setCheckedConfirmPassword(!isError);
    return !isError;
  }
  const checkTags = () => {
    var isErrorAll: boolean = false;
    for (var i = 0; i < userListConst.tag.maxNumber; ++i) {
      var isError: boolean = false;
      if (document.getElementById('tagKey' + i) != null) {
        const item = (document.getElementById('tagKey' + i) as HTMLInputElement)?.value;
        const value = (document.getElementById('tagValue' + i) as HTMLInputElement)?.value;
        const profileOrder = (document.getElementById('tagOrder' + i) as HTMLInputElement)?.value;

        if (item.length >= userListConst.tag.key.minLength) {
          tagsHelperText[i] = "";
        } else {
          isError = true;
          tagsHelperText[i] = "キーは、" + userListConst.tag.key.minLength + "～" + userListConst.tag.key.maxLength + "文字の入力が必要です。";
        }
        if (isError === false) {
          if (value.length >= userListConst.tag.value.minLength) {
            tagsHelperText[i] = "";
          } else {
            isError = true;
            tagsHelperText[i] = "値は、" + userListConst.tag.value.minLength + "～" + userListConst.tag.value.maxLength + "文字の入力が必要です。";
          }
        }
        if (isError === false) {
          if (profileOrder.length >= userListConst.tag.order.minLength) {
            tagsHelperText[i] = "";
          } else {
            isError = true;
            tagsHelperText[i] = "表示順は、" + userListConst.tag.order.minLength + "～" + userListConst.tag.order.maxLength + "文字の入力が必要です。";
          }
        }
        if (isError === false) {
          if (checkFormat(profileOrder, userListConst.tag.order.format)) {
          } else {
            isError = true;
            tagsHelperText[i] = "表示順は、1以上の整数を入力してください。";
          }
        }
        checkedTags[i] = !isError;
        isErrorAll = isErrorAll || isError;
      }
    }
    setTagsHelperText(Object.assign([], tagsHelperText));
    setCheckedTags(Object.assign([], checkedTags));
    return !isErrorAll;
  }
  const checkContactAddressList = () => {
    var isErrorAll: boolean = false;
    for (var i = 0; i < userListConst.contactAddress.maxNumber; ++i) {
      var isError: boolean = false;
      if (document.getElementById('addressName' + i) != null) {
        const name = (document.getElementById('addressName' + i) as HTMLInputElement)?.value;
        const value = (document.getElementById('addressValue' + i) as HTMLInputElement)?.value;

        if (name.length >= userListConst.contactAddress.name.minLength) {
          contactAddressListHelperText[i] = "";
        } else {
          isError = true;
          contactAddressListHelperText[i] = "識別名は、" + userListConst.contactAddress.name.minLength + "～" + userListConst.contactAddress.name.maxLength + "文字の入力が必要です。";
        }
        if (isError === false) {
          if (value.length >= userListConst.contactAddress.value.minLength) {
            contactAddressListHelperText[i] = "";
          } else {
            isError = true;
            contactAddressListHelperText[i] = "メールアドレスは、" + userListConst.contactAddress.value.minLength + "～" + userListConst.contactAddress.value.maxLength + "文字の入力が必要です。";
          }
          if (isError === false) {
            if (checkFormat(value, userListConst.contactAddress.value.format)) {
              contactAddressListHelperText[i] = "";
            } else {
              if (isError === false) {
                if (checkFormat(value, userListConst.contactAddress.value.format1)) {
                } else {
                  contactAddressListHelperText[i] = "先頭に使用できる文字は、半角英大文字、半角英小文字、数字です。";
                  isError = true;
                }
              }
              if (isError === false) {
                if (checkFormat(value, userListConst.contactAddress.value.format2)) {
                } else {
                  contactAddressListHelperText[i] = "@を1文字使用してください。";
                  isError = true;
                }
              }
              if (isError === false) {
                if (checkFormat(value, userListConst.contactAddress.value.format4)) {
                } else {
                  contactAddressListHelperText[i] = "@の直後に使用できる文字は、半角英大文字、半角英小文字、数字、記号の「-」、「_」です。";
                  isError = true;
                }
              }
              if (isError === false) {
                contactAddressListHelperText[i] = "使用できる文字は、半角英大文字、半角英小文字、数字、記号の「-」、「_」、「@」です。";
                isError = true;
              }
            }  
          }
        }
        checkedContactAddressList[i] = !isError;
        isErrorAll = isErrorAll || isError;
      }
    }
    setContactAddressListHelperText(Object.assign([], contactAddressListHelperText));
    setCheckedContactAddressList(Object.assign([], checkedContactAddressList));
    return !isErrorAll;
  }
  const checkInput = () => {
    var allChecked: boolean = true;
    var retCheckLoginId: boolean = true;
    var retCheckLastName: boolean = true;
    var retCheckFirstName: boolean = true;
    var retCheckDisplayName: boolean = true;
    var retCheckPassword: boolean = true;
    var retCheckConfirmPassword: boolean = true;
    var retCheckTags: boolean = true;
    var retCheckContactAddressList: boolean = true;
    if (detailFuncNum === 0 || detailFuncNum === 1) {
      if (authType !== 1) {
        retCheckLoginId = checkLoginId();
        retCheckLastName = checkLastName();
        retCheckFirstName = checkFirstName();
        retCheckPassword = checkPassword();
        retCheckConfirmPassword = checkConfirmPassword();
        retCheckTags = checkTags();
        retCheckContactAddressList = checkContactAddressList();
      }
      retCheckDisplayName = checkDisplayName();

      if (authType !== 1) {
        allChecked = allChecked && retCheckLoginId;
        allChecked = allChecked && retCheckLastName;
        allChecked = allChecked && retCheckFirstName;
        allChecked = allChecked && retCheckPassword;
        allChecked = allChecked && retCheckConfirmPassword;
        allChecked = allChecked && retCheckTags;
        allChecked = allChecked && retCheckContactAddressList;
      }
      allChecked = allChecked && retCheckDisplayName;
    }
    return allChecked;
  }

  /*
  * 削除するユーザの確認
  */
  const checkDeleteUser = () => {
    if(detailData.roleRoot){
      setdeleteError(true);
      return false;
    }
    handleDetailOpened(false, detailFuncNum, null);
    return true;
  }

  /**  詳細画面の各入力項目のチェック */
  const handleOnInput = (e: any, minLength: number, maxLength: number, format: string) => {
    let target = e.target as HTMLInputElement;
    if (target.id !== 'isResetPassword') {
      target.value = target.value.slice(0, maxLength);
    }
    switch (target.id) {
      case 'loginId':
        detailData.loginId = target.value;
        //        checkLoginId();
        break;
      case 'lastName':
        detailData.lastName = target.value;
        //        checkLastName();
        break;
      case 'firstName':
        detailData.firstName = target.value;
        //        checkFirstName();
        break;
      case 'displayName':
        detailData.displayName = target.value;
        //        checkDisplayName();
        break;
      case 'password':
        detailData.password = target.value;
        //        checkPassword();
        break;
      case 'confirmPassword':
        detailData.confirmPassword = target.value;
        //        checkConfirmPassword();
        break;
      case 'isResetPassword':
        detailData.isResetPassword = target.checked;
        break;
      case 'roleAdmin':
        detailData.roleAdmin = target.checked;
        break;
      case 'forceDisplayName':
        detailData.forceDisplayName = target.checked;
        if(target.checked) {
          setForceDisplayNameHelperText("システムで自動設定されなくなります。ここで編集した表示名がアバター下に表示されます。");
          setDisableDisplayNameSetting(false);
          console.log('forceDisplayName check: ' + target.checked);
        } else {
          setForceDisplayNameHelperText("次回ログインからシステムで自動設定されるようになります。");
          setDisableDisplayNameSetting(true);
          console.log('forceDisplayName check: ' + target.checked);
        }
        break;
      case target.id.startsWith('tag') && target.id:
        const index = parseInt(target.id.replace(/[^0-9]/g, ''), 10);
        var item = (document.getElementById('tagKey' + index) as HTMLInputElement)?.value;
        var value = (document.getElementById('tagValue' + index) as HTMLInputElement)?.value;
        var profileOrder = (document.getElementById('tagOrder' + index) as HTMLInputElement)?.value;
        if (target.id.startsWith('tagKey') && target.id) {
          item = target.value;
        }
        if (target.id.startsWith('tagValue') && target.id) {
          value = target.value;
        }
        if (target.id.startsWith('tagOrder') && target.id) {
          profileOrder = target.value;
        }
        detailData.tags[index].item = item;
        detailData.tags[index].value = value;
        detailData.tags[index].profileOrder = parseInt(profileOrder, 10);
        detailData.tags = Object.assign([], detailData.tags);
        //        checkTags();
        break;
      case target.id.startsWith('address') && target.id:
          const addressIndex = parseInt(target.id.replace(/[^0-9]/g, ''), 10);
          var name = (document.getElementById('addressName' + addressIndex) as HTMLInputElement)?.value;
          var value = (document.getElementById('addressValue' + addressIndex) as HTMLInputElement)?.value;
          if (target.id.startsWith('addressName') && target.id) {
            name = target.value;
          }
          if (target.id.startsWith('addressValue') && target.id) {
            value = target.value;
          }
          detailData.contactAddressList[addressIndex].name = name;
          detailData.contactAddressList[addressIndex].contactAddress = value;
          detailData.contactAddressList = Object.assign([], detailData.contactAddressList);
          break;
    }
    const checked = checkInput();
    if (checked === true) {
      setInputError(!checked)
    }
  }

  // 選択された表示名の保管
  const handleChangeFlexibleNamePlate = (value :string) => {
    let selected : FlexibleNamePlate = flexibleNamePlateList.find(e => e.colorName === value) ?? new FlexibleNamePlate();
    setSelectedFlexibleNamePlate(selected);
  }

  // interface TenantRegistraionResponse {
  //     tenantId: number;
  //     registrationId: string;
  //     authType: number;
  // }

  /**
   * テナントレジストレーション取得
   */
   // const getTenantRegistration = async (): Promise<AxiosResponse<TenantRegistraionResponse>> => {
  const getTenantRegistration = async () => {
    const tabId = sessionStorage.getItem("TABID") as string;
    try {
        // const res: AxiosResponse<TenantRegistraionResponse> = await axios.get(
        //     '/api/v1/admin/tenant/tenantRegistration',
        //     { params: { tabId } }
        // );
        const res : TenantRegistraionResponse = await httpClient.getTenantRegistration(tabId);
        setAuthType(res.authType);
        return res;
    } catch (err) {
        throw err;
    }
  }

  /**  
   * ユーザー登録
   */
  const registUser = () => {
    setDealType("C");
    showBackdrop.current = true;
    //axios.post('/api/v1/admin/users', {
    //  loginId: detailData.loginId,
    //  firstName: detailData.firstName,
    //  lastName: detailData.lastName,
    //  displayName: detailData.displayName,
    //  password: detailData.password,
    //  confirmPassword: detailData.confirmPassword,
    //  tabId: sessionStorage.getItem("TABID") as string,
    //  isResetPassword: detailData.isResetPassword ? "1" : "0",
    //  tags: detailData.tags,
    //  contactAddressList: detailData.contactAddressList,
    //  roleAdmin: detailData.roleAdmin ? "1" : "0",
    //})
    //  .then((e: AxiosResponse) => {
    //    executeSearchByPage(1);
        //        handleDetailOpened(false, detailFuncNum, null);
    //    if ('code' in e.data) {
    //      if (e.data['code'] !== '0000') {
    //        setErrorData(Object.assign({}, e.data));
    //        setErrorOpened(true);
    //      }
    //    }
    //  }).catch(err => {
    //    assignErrorData(err);
    //  }).finally(() => {
    //    setShowBackdrop(false);
    //  });

    const data = new UserInfoRegisterFormData();
    data.loginId = detailData.loginId;
    data.firstName = detailData.firstName;
    data.lastName = detailData.lastName;
    data.displayName = detailData.displayName;
    data.password = detailData.password;
    data.confirmPassword = detailData.confirmPassword;
    data.tabId = sessionStorage.getItem("TABID") as string;
    data.isResetPassword = detailData.isResetPassword ? true : false;
    data.tags = detailData.tags;
    data.contactAddressList = detailData.contactAddressList;
    data.roleAdmin = detailData.roleAdmin ? true : false;
    data.url = window.location.href;
    data.usermanualUrl = usermanualURL;
    data.flexibleNamePlateId = selectedFlexibleNamePlate.id;

    httpClient.registerUserForCampus(data)
      .then((e: RegisterUserResponse) => {
        executeSearchByPage(1)
        if(e.code !== '0000'){
          setErrorData({code: e.code, message: e.message, errorDetail: [] as never[]});
          setErrorOpened(true);
        }else{
          if (e.message !== "") {
            let msg = 'ユーザー登録が完了しました。';
            msg += '\n\n※過去に登録、削除したことのあるサインインIDを再登録しました。問題がありましたら、このサインインIDを削除し、別のサインインIDを登録しなおしてください。\n';
            msg += '(サインインID=' + e.message +')';
            setRegisterResultData({code: '', message: msg});
            setRegisterResultOpened(true);
          }
        }
      }).catch(err => {
        assignErrorData(err);
      }).finally(() => {
        showBackdrop.current = false;
      });
  }

  /** 
   * ユーザー更新 
   */
  const updateUser = () => {
    if (detailData.isResetPassword === null || detailData.isResetPassword === undefined) {
      detailData.isResetPassword = false;
    }
    setDealType("U");
    showBackdrop.current = true;
    // axios.put('/api/v1/admin/users/' + detailData.subId, {
    //   loginId: detailData.loginId,
    //   firstName: detailData.firstName,
    //   lastName: detailData.lastName,
    //   displayName: detailData.displayName,
    //   password: detailData.password,
    //   confirmPassword: detailData.confirmPassword,
    //   tabId: sessionStorage.getItem("TABID") as string,
    //   isResetPassword: detailData.isResetPassword ? "1" : "0",
    //   tags: detailData.tags,
    //   contactAddressList: detailData.contactAddressList,
    //   roleAdmin: detailData.roleAdmin ? "1" : "0",
    //   isForceDisplayName: detailData.forceDisplayName ? "1" : "0",
    // })
    //   .then((e: AxiosResponse) => {
    //     users.forEach((user: UserData) => {
    //       if (user.subId === detailData.subId) {
    //         user.loginId = detailData.loginId;
    //         user.firstName = detailData.firstName;
    //         user.lastName = detailData.lastName;
    //         user.displayName = detailData.displayName;
    //         user.password = detailData.password;
    //         user.confirmPassword = detailData.confirmPassword;
    //         user.isResetPassword = detailData.isResetPassword;
    //         user.tags = Object.assign([], detailData.tags);
    //         user.contactAddressList = Object.assign([], detailData.contactAddressList);
    //         user.roleAdmin = detailData.roleAdmin;
    //         user.forceDisplayName = detailData.forceDisplayName;
    //         return;
    //       }
    //     });
    //     setUsers(Object.assign([], users));
         //        handleDetailOpened(false, detailFuncNum, null);
    //   }).catch(err => {
    //     assignErrorData(err);
    //   }).finally(() => {
    //     setShowBackdrop(false);
    //   });

    const data = new UserInfoUpdateFormData();
    data.subId = detailData.subId;
    data.loginId = detailData.loginId;
    data.firstName = detailData.firstName;
    data.lastName = detailData.lastName;
    data.displayName = detailData.displayName;
    data.password = detailData.password;
    data.confirmPassword =  detailData.confirmPassword;
    data.tabId = sessionStorage.getItem("TABID") as string;
    data.isResetPassword = detailData.isResetPassword;
    data.tags = detailData.tags;
    data.contactAddressList = detailData.contactAddressList;
    data.roleAdmin = detailData.roleAdmin;
    data.isForceDisplayName = detailData.forceDisplayName;
    data.flexibleNamePlateId = selectedFlexibleNamePlate.id;

    httpClient.updateUserInfoForCampus(data)
      .then((e: string) => {
        users.forEach((user: UserData) => {
          if (user.subId === detailData.subId) {
            user.loginId = detailData.loginId;
            user.firstName = detailData.firstName;
            user.lastName = detailData.lastName;
            user.displayName = detailData.displayName;
            user.password = detailData.password;
            user.confirmPassword = detailData.confirmPassword;
            user.isResetPassword = detailData.isResetPassword;
            user.tags = Object.assign([], detailData.tags);
            user.contactAddressList = Object.assign([], detailData.contactAddressList);
            user.roleAdmin = detailData.roleAdmin;
            user.forceDisplayName = detailData.forceDisplayName;
            user.flexibleNamePlate = selectedFlexibleNamePlate;
            return;
          }
        });
        setUsers(Object.assign([], users));
      }).catch(err => {
        console.error(err);
        assignErrorData(err);
      }).finally(() => {
        showBackdrop.current = false;
        setDealType("C");
      });
  }

  /** ユーザー削除 
   * 
   */
  const deleteUser = () => {
    if(!checkDeleteUser()){
      return;
    }
    //deleteはdataを指定
    setDealType("D");
    showBackdrop.current = true;
    // axios.delete('/api/v1/admin/users/' + detailData.subId, {
    //   data: {
    //     tabId: sessionStorage.getItem("TABID") as string,
    //   }
    // })
    //   .then((e: AxiosResponse) => {
    //     const newUsers = users?.filter((user: UserData) => user.subId !== detailData.subId);
    //     executeSearchByPage(newUsers.length > 0 ? page + 1 : 1);
    //     //        handleDetailOpened(false, detailFuncNum, null);
    //   }).catch(err => {
    //     assignErrorData(err);
    //   }).finally(() => {
    //     setShowBackdrop(false);
    //   });

    httpClient.deleteUserInfo(sessionStorage.getItem("TABID") as string, detailData.subId)
      .then((e: string) => {
        const newUsers = users?.filter((user: UserData) => user.subId !== detailData.subId);
        executeSearchByPage(newUsers.length > 0 ? page + 1 : 1);
      }).catch(err => {
        assignErrorData(err);
      }).finally(() => {
        showBackdrop.current = false;
        setDealType("C");
      });
  }

  // interface ResEnabledMenu {
  //   menuValidWebsiteViewer: boolean;
  //   menuValidAdminMessage: boolean;
  //   menuValidForceMute: boolean;
  //   menuValidClap: boolean;
  // }

  /**
   * API Request
   */
  //const getEnabledMenu = async (tabId: string): Promise<AxiosResponse<ResEnabledMenu>> => {
  const getEnabledMenu = async (tabId: string) => {
    try {
      // const res: AxiosResponse<ResEnabledMenu> = await axios.get(
      //   '/api/v1/admin/tenant/menuvalid',
      //   { params: { tabId } }
      // );
      const res: ResEnabledMenu = await httpClient.getEnabledMenu(tabId);

      if (res) {
        setIsShowMenuWebSiteViewer(res.menuValidWebsiteViewer);
        setIsShowMenuAdminmessage(res.menuValidAdminMessage);
        setIsShowMenuForceMute(res.menuValidForceMute);
        setIsShowMenuClap(res.menuValidClap);
        setIsShowMenuAuth(false);
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  // 名札のデータ取得
  const getNamePlateColors = async () => {
    // var params = new URLSearchParams();
    // params.append("tab_id", sessionStorage.getItem("TABID") as string);
    try {
        // this.namePlateColors = (await axios.post('/api/user/flexibleNamePlate/allcolors', params)).data;
        const res : FlexibleNamePlate[] = await httpClient.getNamePlateColors(sessionStorage.getItem("TABID") as string);
        if(res.length>0){
          setFlexibleNamePlateList(res);
          let selected : FlexibleNamePlate = res[0];
          setSelectedFlexibleNamePlate(selected);
        }
    } catch (e) {
        console.error(e);
    }
}

  /** ユーザー取得検索 
   * @param subId: 検索ユーザーのサブID
   */
  const getUserData = async (subId: string): Promise<UserData | null> => {
    try {
      // GETはparamsを指定
      // const res = await axios.get(
      //   '/api/v1/admin/users/' + subId, {
      //   params: {
      //     tabId: sessionStorage.getItem("TABID") as string,
      //   }
      // });

      const res = await httpClient.getUserInfo(sessionStorage.getItem("TABID") as string, subId);

      const user: UserData = new UserData();
      user.subId = res.subId;
      user.loginId = res.loginId;
      user.lastName = res.lastName;
      user.firstName = res.firstName;
      user.displayName = res.displayName;
      user.tags = res.tags === null ? [] : res.tags;
      user.contactAddressList = res.contactAddressList === null ? [] : res.contactAddressList;
      user.password = res.password ? res.password : "";
      user.confirmPassword = res.confirmPassword ? res.confirmPassword : "";
      user.registeredDate = res.registeredDate ? new Date(res.registeredDate) : null;
      // user.accessDate = res.data.user.accessDate ? new Date(res.data.user.accessDate) : null;
      user.isResetPassword = res.resetPassword ? res.resetPassword : false;
      user.tags = res.tags;
      user.roleAdmin = res.roleAdmin ? res.roleAdmin : false;
      user.roleRoot = res.roleRoot ? res.roleRoot : false;
      user.roleSystem = res.roleSystem ? res.roleSystem : false;
      user.forceDisplayName = res.forceDisplayName ? res.forceDisplayName : false;
      user.flexibleNamePlate = res.flexibleNamePlate === null ? new FlexibleNamePlate() : res.flexibleNamePlate;
      return user;
    } catch (err) {
      assignErrorData(err);
      return null;
    }
  }

  /** ユーザー一覧取得 
   * @param page: 全件検索時のページ指定
   * その他のパラメータはsearchDataで設定しておく
   */
  const getUserList = async (page: number) => {
    setShowBackdropForUpdatingList(true);
    // GETではparamsを指定
    // axios.get('/api/v1/admin/users', {
    //   params: {
    //     loginId: searchData.loginId,
    //     name: searchData.displayName,
    //     page: page.toString(),
    //     count: rowsPerPage.toString(),
    //     tabId: sessionStorage.getItem("TABID") as string,
    //   }
    // })
    //   .then((e: AxiosResponse) => {
    //     const _userList = e.data.userList
    //       ? e.data.userList.map((v: any, i: number, d: any) => {
    //         const user: UserData = new UserData();
    //         user.subId = v.subId;
    //         user.loginId = v.loginId;
    //         user.lastName = v.lastName;
    //         user.firstName = v.firstName;
    //         user.displayName = v.displayName;
    //         user.tags = v.tags === null ? [] : v.tags;
    //         user.contactAddressList = v.contactAddressList === null ? [] : v.contactAddressList;
    //         user.password = v.password ? v.password : "";
    //         user.confirmPassword = v.confirmPassword ? v.confirmPassword : "";
    //         user.registeredDate = v.registeredDate ? new Date(v.registeredDate) : null;
    //         // user.accessDate = v.accessDate ? new Date(v.accessDate) : null;
    //         user.isResetPassword = v.isResetPassword ? v.isResetPassword : false;
    //         user.roleAdmin = v.roleAdmin ? v.roleAdmin : false;
    //         user.roleRoot = v.roleRoot ? v.roleRoot : false;
    //         user.roleSystem = v.roleSystem ? v.roleSystem : false;
    //         return user;
    //       })
    //       : [] as UserData[];

    //     setUsers(_userList);
    //     setShowBackdropForUpdatingList(false);
    //     if (e.data.pageInfo) setTotalCount(e.data.pageInfo.totalCount);

    //     return _userList;
    //   }).catch(err => {
    //     setShowBackdropForUpdatingList(false);
    //     assignErrorData(err);
    //   })

    httpClient.getUserList(searchData.loginId,
      searchData.displayName,
      page,
      rowsPerPage,
      sessionStorage.getItem("TABID") as string)
      .then((e: UserInfoListResponse) => {
        const _userList = e
          ? e.userList.map((v: any, i: number, d: any) => {
            const user: UserData = new UserData();
            user.subId = v.subId;
            user.loginId = v.loginId;
            user.lastName = v.lastName;
            user.firstName = v.firstName;
            user.displayName = v.displayName;
            user.tags = v.tags === null ? [] : v.tags;
            user.contactAddressList = v.contactAddressList === null ? [] : v.contactAddressList;
            user.password = v.password ? v.password : "";
            user.confirmPassword = v.confirmPassword ? v.confirmPassword : "";
            user.registeredDate = v.registeredDate ? new Date(v.registeredDate) : null;
            // user.accessDate = v.accessDate ? new Date(v.accessDate) : null;
            user.isResetPassword = v.isResetPassword ? v.isResetPassword : false;
            user.roleAdmin = v.roleAdmin ? v.roleAdmin : false;
            user.roleRoot = v.roleRoot ? v.roleRoot : false;
            user.roleSystem = v.roleSystem ? v.roleSystem : false;
            return user;
          })
          : [] as UserData[];

        setUsers(_userList);
        setShowBackdropForUpdatingList(false);
        if (e.pageInfo) setTotalCount(e.pageInfo.totalCount);

        return _userList;
      }).catch(err => {
        setShowBackdropForUpdatingList(false);
        assignErrorData(err);
      })
    }

  /*
  * ログインIDを取得
  */
  const getLoginId = (loginId: string): string => {
    if(authType === 1){
      // テナントIDを除去したログインIDを取得
      return Utility.getLoginIdRemoveTenantId(loginId);
    }
    return loginId;
  }

  /** ユーザー検索を実行し、ページを設定する
   * @param page
   */
  const executeSearchByPage = async (page: number) => {
    clearErrorData();
    clearSearchData();
    setSearchData(Object.assign(searchData, {
      loginId: (document.getElementById('loginId_search') as HTMLInputElement)?.value,
      displayName: (document.getElementById('displayName_search') as HTMLInputElement)?.value,
    }));
    await getUserList(page);
    setPage(page - 1);
    return true;
  }

  const getFileName = (contentDisposition: string) => {
    let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
      contentDisposition.length
    );
    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
  }

  const checkUploadCSVResult = async (requestId: string, dealType: string) => {
    var completed = false;
    const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));
    var retryLimit = 30;
    var prevExecutedCount = 0;
    for(let i=0; i <= retryLimit && completed == false; i++) {
      // axios.get('/api/v1/admin/users/csv/result', {
      //   params: {
      //     requestId: requestId,
      //     tabId: sessionStorage.getItem("TABID") as string,
      //   },
      // })
      //   .then((e: AxiosResponse) => {
      //     if ('code' in e.data) {
      //       if (e.data['code'] !== '2000') {
      //         if (e.data['code'] !== '0000') {
      //           setErrorData(Object.assign({}, e.data));
      //           setErrorOpened(true);
      //         }
      //         completed = true;
      //       }
      //     }
      //   }).catch(err => {
      //     assignErrorData(err);
      //     completed = true;
      //   });
      httpClient.csvResultCheck(
        requestId,
        dealType,
        sessionStorage.getItem("TABID") as string,
      )
        .then((e) => {
          if (e.code !== '2000') {
            if (e.code === '0000' || e.code === '0102') {
              if (dealType === "C") {
                let msg = 'ユーザー登録が完了しました。';
                // let msg = 'ユーザー登録が完了しました。登録されたユーザーにアカウント発行のお知らせメールを送信しました。';
                if (e.message !== "") {
                  msg += '\n\n※過去に登録、削除したことのあるサインインIDを再登録しました。問題がありましたら、このサインインIDを削除し、別のサインインIDを登録しなおしてください。\n';
                  msg += '(サインインID=' + e.message +')';
                }
                setRegisterResultData({code: '', message: msg});
                setRegisterResultOpened(true);
              }
            } else {
              assignErrorData(e);

              // setErrorData({code: e.code, message: e.message, errorDetail: e.errorDetail});
              // setErrorOpened(true);
            }
            completed = true;
          } else if (e.code === '2000') { // バックエンドの処理中に残件が減っていた場合
            // 処理完了数が帰ってくる場合はタイムアウト回数延長するか判定する
            if (!isNaN(parseInt(e.message))) {
              // タイムアウト回数に到達していて前回の処理数と今回の処理数が変わっていれば延長
              if (i === retryLimit && prevExecutedCount != parseInt(e.message)) {
                retryLimit += 1;
              }
              prevExecutedCount = parseInt(e.message);
            }
          }
        }).catch(err => {
            assignErrorData(err);
            setErrorOpened(true);
          completed = true;
        });
      if (completed == false) {
        await sleep(3*1000);
      }
    }
    return true;
  }

  /** CSVアップロード
   * 
   */
  const uploadCSV = async () => {
    // const params = new FormData();
    // params.append('tabId', (sessionStorage.getItem("TABID") as string));
    // params.append('file', (uploadCSVFile as File));
    // params.append('errorCount', userListConst.upload.errorCount.toString());
    // params.append('div', dealType);
    var requestId = null;
    var axiosFinished = false;
    const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));
    // axios.post('/api/v1/admin/users/csv', params,
    //   {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   }
    // )
    // httpClient.updateUserByCSV(
    //   sessionStorage.getItem("TABID") as string,
    //   uploadCSVFile as File,
    //   userListConst.upload.errorCount,
    //   dealType,
    // )
    //   .then((e) => {
    //     //if ('code' in e.data) {
    //     if (e.code === '2000') {
    //       requestId = e.message;
    //     } else if (e.code !== '0000') {
    //       //setErrorData(Object.assign({}, e.data));
    //       setErrorData({code: e.code, message: e.message, errorDetail: [] as never[]});
    //       setErrorOpened(true);
    //     }
    //     //}
    //   }).catch(err => {
    //     assignErrorData(err);
    //   }).finally(() => {
    //     axiosFinished = true;
    //   });

    switch (dealType) {
      case "U":
        httpClient.updateUserByCSVForCampus(sessionStorage.getItem("TABID") as string, 
          uploadCSVFile as File,
          userListConst.upload.errorCount,
          dealType)
          .then((e) => {
            // if ('code' in e) {
              if (e.code === '0000') {
                requestId = e.message;
              } else {
                setErrorData({code: e.code, message: e.message, errorDetail:[] as never[]});
                setErrorOpened(true);
              }
            // }
          }).catch(err => {
            assignErrorData(err);
          }).finally(() => {
            axiosFinished = true;
          });
        break;
      case "C":
        httpClient.registerUserByCSVForCampus(sessionStorage.getItem("TABID") as string, 
          uploadCSVFile as File,
          userListConst.upload.errorCount,
          dealType,
          window.location.href,
          usermanualURL)
          .then((e) => {
            // if ('code' in e) {
              if (e.code === '0000') {
                requestId = e.message;
              } else {
                setErrorData({code: e.code, message: e.message, errorDetail:[] as never[]});
                setErrorOpened(true);
              }
            // }
          }).catch(err => {
            assignErrorData(err);
          }).finally(() => {
            axiosFinished = true;
          });
        break;
      case "D":
        httpClient.deleteUserByCSV(sessionStorage.getItem("TABID") as string, 
          uploadCSVFile as File,
          userListConst.upload.errorCount)
          .then((e) => {
            // if ('code' in e) {
              if (e.code === '0000') {
                requestId = e.message;
              } else {
                setErrorData({code: e.code, message: e.message, errorDetail:[] as never[]});
                setErrorOpened(true);
              }
            // }
          }).catch(err => {
            assignErrorData(err);
          }).finally(() => {
            axiosFinished = true;
          });
        break;
      default:
        axiosFinished = true;
        break;
    }

    while (axiosFinished == false) {
      await sleep(1000);
    }
    if (requestId != null) {
      await checkUploadCSVResult(requestId, dealType);
    }
    //ちょっと待たないと、リストが更新できない
    await sleep(1500);
    await executeSearchByPage(1);
    //handleRegisterCSVOpened(false);
    setConfirmInfo({ ...confirmInfo, isOk: false })
    setDealType("C");
    return true;
  }

  /** CSVダウンロード
   *  ダウンロード条件はsearchDataで指定
   */
  const downloadCSV = () => {
    //getはparamsを指定
    // axios.get('/api/v1/admin/users/csv', {
    //   params: {
    //     loginId: searchData.loginId,
    //     name: searchData.displayName,
    //     tag: searchData.tags,
    //     tabId: sessionStorage.getItem("TABID") as string,
    //   },
    // })
    setShowBackdropForDowndating(true);

    httpClient.downloadUserListCSVForCampus(
      searchData.loginId,
      searchData.displayName,
      searchData.tags,
      sessionStorage.getItem("TABID") as string,
    )
      .then((e) => {
        //レスポンスヘッダからファイル名を取得
        const contentDisposition = e.headers["content-disposition"];
        const fileName = getFileName(contentDisposition);
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([bom, e.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        //ダウンロード
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setConfirmInfo({...confirmInfo,isOk: false});
      }).catch(err => {
        assignErrorData(err);
      }).finally(() => {
        setShowBackdropForDowndating(false);
      });
  }

  const handleDealType = (e: any) => {
    let target = e.target as HTMLInputElement;
    setDealType(target.value);
  }

  const handleBackToTop = () => {
    history.goBack();
  }
  const convertColsName = (colsName: string) => {
    let convertColsName: string = Object.assign({}, colsName);
    let index: number = 0;
    switch (colsName) {
      case 'loginId':
        convertColsName = 'サインインID';
        break;
      case 'lastName':
        convertColsName = '姓';
        break;
      case 'firstName':
        convertColsName = '名';
        break;
      case 'displayName':
        convertColsName = '表示名';
        break;
      case 'password':
        convertColsName = 'パスワード';
        break;
      case 'confirmPassword':
        convertColsName = 'パスワード確認';
        break;
      case 'isResetPassword':
        convertColsName = 'パスワードの再設定';
        break;
      case colsName.startsWith('item') && colsName:
        index = parseInt(colsName.replace(/[^0-9]/g, ''), 10);
        //const item = (document.getElementById('tagKey' + index) as HTMLInputElement)?.value;
        convertColsName = 'タグ' + index.toString();
        break;
      case colsName.startsWith('value') && colsName:
        index = parseInt(colsName.replace(/[^0-9]/g, ''), 10);
        //const value = (document.getElementById('tagValue' + index) as HTMLInputElement)?.value;
        convertColsName = '値' + index.toString();
        break;
      case colsName.startsWith('profileOrder') && colsName:
        index = parseInt(colsName.replace(/[^0-9]/g, ''), 10);
        //const profileOrder = (document.getElementById('tagOrder' + index) as HTMLInputElement)?.value;
        convertColsName = '表示順' + index.toString();
        break;
      default:
        convertColsName = colsName;
    }
    return convertColsName;
  }

  const handleWhiteList = (isShow: boolean) => {
    setIsShowWhiteList(isShow);
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleForceMute();
    toggleClapping();
  	toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
  }

  const handleClapping = (isShow: boolean) => {
    setIsShowClapping(isShow);
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleForceMute();
  	toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
  }

  const handleOtherWebsiteSetting = (isShow: boolean) => {
    setIsShowOtherWebsiteSetting(isShow);
    toggleWhiteList();
    toggleAdminMessage();
    toggleForceMute();
    toggleClapping();
	  toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
  };

  const handleAdminMessage = (isShow: boolean) => {
    setIsShowAdminMessage(isShow);
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
	  toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
  }

  const handleForceMute = (isShow: boolean) => {
    setIsShowForceMute(isShow);
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleClapping();
	  toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
  }

  const toggleWhiteList = () => {
    setIsShowWhiteList(false);
  }

  const toggleClapping = () => {
    isShowClapping && setIsShowClapping(false);
  }

  const toggleOtherWebsiteSetting = () => {
    isShowOtherWebsiteSetting && setIsShowOtherWebsiteSetting(false);
  };
  const toggleAdminMessage = () => {
    isShowAdminMessage && setIsShowAdminMessage(false);
  }

  const toggleForceMute = () => {
    isShowForceMute && setIsShowForceMute(false);
  }

  const handleAuthSetting = (isShow: boolean) => {
    setIsShowAuthSetting(isShow);
    toggleForceMute();
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleClapping();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
  }

  const toggleAuthSetting = () => {
    setIsShowAuthSetting(false);
  }

  const onDownload = () => {
    let csvName = "";
    if (state.selectCSV === "attendance") {
      csvName = '入退データ'
    } else {
      csvName = 'ビデオ通話データ'
    }
    setConfirmInfo(new ConfirmInfo({
      message: csvName + "を出力します。よろしいですか？"
    }));
  }

  const downloadUsageData = () => {
    if (state.selectCSV === 'meet' || state.selectFloor !== '') {

      let year = state.selectDate.getFullYear();
      let month = state.selectDate.getMonth() + 1;
      let date = 1;
      let paramDate = year + '/' + month + '/' + date;

      let currentdate = new Date();
      currentdate.setMonth(currentdate.getMonth() + 1);
      currentdate.setDate(1);
      currentdate.setHours(0);
      currentdate.setMinutes(0);
      currentdate.setSeconds(0);
      let permitdate = new Date();
      permitdate.setFullYear(permitdate.getFullYear() - 2);
      permitdate.setDate(1);
      permitdate.setHours(0);
      permitdate.setMinutes(0);
      permitdate.setSeconds(0);

      if (permitdate > state.selectDate || currentdate <= state.selectDate) {
        setFinishInfo(new FinishInfo({
          message: "対象年月は" + permitdate.getFullYear() + "年" + (permitdate.getMonth()+1) + "月～現在（過去２年）で指定してください。"
        }));
      } else {
        let csvName = "";
        if (state.selectCSV === "attendance") {
          csvName = '入退データ'
        } else {
          csvName = 'ビデオ通話データ'
        }
        // if(window.confirm(csvName + "を出力します。よろしいですか？")){
        setShowBackdropForDowndating(true);
        if (state.selectCSV === "attendance") {
          // axios
          //   .post('/api/admin/attendance/output', new URLSearchParams({
          //     tab_id: sessionStorage.getItem("TABID") as string,
          //     floorId: state.selectFloor as string,
          //     startMonth: paramDate,
          //   }))
          httpClient.downloadUsageData(
            sessionStorage.getItem("TABID") as string,
            state.selectFloor as string,
            paramDate,
            "//",
          )
            .then((e) => {
              //レスポンスヘッダからファイル名を取得
              //const contentDisposition = e.headers["content-disposition"];
              //const fileName = getFileName(contentDisposition);
              const fileName = e.fileName;
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードが完了しました。"
              }));
            }).catch(() => {
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードに失敗しました。"
              }));
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
        } else {
          // axios
          //   .post('/api/admin/report/getMeetCsv', new URLSearchParams({
          //     type: 'Meet',
          //     year: year.toString(),
          //     month: month.toString(),
          //     tabid: sessionStorage.getItem("TABID") as string,
          //   }))
          httpClient.downloadMeetData(
            'Meet',
            year,
            month,
            sessionStorage.getItem("TABID") as string,
          )
            .then((e) => {
              //レスポンスヘッダからファイル名を取得
              //const contentDisposition = e.headers["content-disposition"];
              //var fileName = getFileName(contentDisposition);
              var fileName = e.fileName;
              var re = /ttachment; filename=/g;
              fileName = fileName.replace(re, '');
              fileName = fileName.replace("MeetReport", "ビデオ通話データ");
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードが完了しました。"
              }));
            }).catch(() => {
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードに失敗しました。"
              }));
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
        }
        clearState();
        setAttendanceCSV(false);
        // }
      }
    } else {
      setFinishInfo(new FinishInfo({
        message: "対象フロアが未入力です。"
      }));
    }
  }

  const drawGetAttendance = useMemo(() => {
    return (
      <Box margin="0px 0px 20px 10px" fontSize="18px">
        <Grid container justify="flex-start">
          <Box>
            <FormControl className={classes.grid}>
              <Grid style={{ marginTop: "10px" }}>　使用状況データ</Grid>
              <Grid style={{ marginLeft: "40px" }}>
                <RadioGroup
                  row
                  value={state.selectCSV}
                  aria-label="csvdata"
                  name="controlled-radio-buttons-group"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel value="attendance" label="入退データ" control={<Radio color="primary" />} style={{ width: 200 }} />
                  {/*<FormControlLabel value="meet" label="ビデオ通話データ" control={<Radio color="primary" />} style={{ width: 200 }} />*/}
                </RadioGroup>
              </Grid>
            </FormControl>
          </Box>
          <Box style={{ width: "100%" }}>
            <Grid style={{ marginTop: "15px" }}>　対象年月</Grid>
            <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "40px", marginBottom: "10px" }}>
              <DatePicker
                className={classes.picker}
                locale="ja"
                selected={state.selectDate}
                onChange={handleChangeDate}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                onCalendarOpen={() => setCalendarOpen(true)}
                onCalendarClose={() => setCalendarOpen(false)}
              />
            </Grid>
          </Box>
          <Box display={dispSelectFloor}>
            <Grid style={{ marginBottom: "20px" }}>　対象フロア</Grid>
            <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "40px" }}>
              <FormControl variant="outlined" style={{ marginTop: -5 }}>
                {!calendarOpen && <InputLabel id="demo-simple-select-outlined-label" style={{ margin: '-6px 5px', textAlign: 'left' }}>フロア</InputLabel>}
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="floor"
                  onChange={handleChangeFloor}
                  label="Age"
                  input={<OutlinedInput margin='dense' aria-label={"Age"} label={"フロア"} color="primary" />}
                  style={{ width: 400, textAlign: 'center' }}
                  value={state.selectFloor}
                  disabled={disableSelectFloor}
                >
                  {floorList.map((floor) => (
                    <MenuItem value={floor.id}>{floor.floorName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceCSV, state, dispSelectFloor, disableSelectFloor, confirmInfo.isOk, calendarOpen]);


  const drawSideMenu = useMemo(() => {
    let disableAddUser = false;
    let disableAuthSetting = true;

    // 認証方式を確認
    if(authType === 1){
      disableAddUser = true;
    }

    if (role!==null && role!== undefined){
      // 権限を確認
      if(role.indexOf('ROLE_ROOT') !== -1){
        // 認証方式のメニューを有効
        disableAuthSetting = false;
      }
    }

    return(
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List>
          <ListItem button key='UserList' onClick={() => handleUserListBtnClick()}>
            <ListItemIcon><ListAltIcon /></ListItemIcon>
            <ListItemText primary='ユーザー一覧' />
          </ListItem>
          <ListItem button key='UserUpload' onClick={() => handleDetailOpened(true, 0, clearDetailData())} disabled={disableAddUser} >
            <ListItemIcon><CloudUploadIcon /></ListItemIcon>
            <ListItemText primary='ユーザー追加' />
          </ListItem>
          <ListItem button key='UserUpload' onClick={() => {setUploadCSVFile({});setBulkUserManagment(true)}}>
            <ListItemIcon><CloudUploadIcon /></ListItemIcon>
            <ListItemText primary='ユーザー一括管理' />
          </ListItem>
          <ListItem button key='UserDownload' onClick={() => setConfirmInfo({...confirmInfo, open: true, message:'全ユーザーリストを出力します。よろしいですか？', type: 0})}>
            <ListItemIcon><CloudDownloadIcon /></ListItemIcon>
            <ListItemText primary='全ユーザーリストダウンロード' />
          </ListItem>
{/*
          <ListItem button key='reportAttendance' onClick={() => handleAttendanceReport()}>
            <ListItemIcon><Update /></ListItemIcon>
            <ListItemText primary='使用状況データダウンロード' />
          </ListItem>
          <Divider/>
          <ListItem button key='ChimeSetting' onClick={() => handleChimeSetting(true)}>
            <ListItemIcon><NotificationsIcon /></ListItemIcon>
            <ListItemText primary='チャイム設定' />
          </ListItem>
          <Divider/>
          <ListItem button key='TimeManagement' onClick={() => handleTimeManagement(true)}>
            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
            <ListItemText primary='開放時間管理' />
          </ListItem>
          <Divider/>
          <ListItem button key='ZoomSetting' onClick={() => handleZoomUrlSetting(true)}>
            <ListItemIcon><OndemandVideoIcon /></ListItemIcon>
            <ListItemText primary='外部Web会議ツール設定' />
          </ListItem>
          <Divider/>
          <ListItem button key='whiteList' onClick={() => handleWhiteList(true)}>
            <ListItemIcon><ListAltIcon /></ListItemIcon>
            <ListItemText primary='ホワイトリスト' />
          </ListItem>
          {isShowMenuWebSiteViewer &&
            <ListItem button key='otherWebsiteSetting' onClick={() => handleOtherWebsiteSetting(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='WebsiteViewer' />
            </ListItem>
          }
          {isShowMenuAdminmessage &&
            <ListItem button key='whiteList' onClick={() => handleAdminMessage(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='管理者メッセージ更新' />
            </ListItem>
          }
          {isShowMenuForceMute &&
            <ListItem button key='forceMute' onClick={() => handleForceMute(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='強制ミュート設定' />
            </ListItem>
          }
          {isShowMenuClap &&
            <ListItem button key='clapping' onClick={() => handleClapping(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='拍手' />
            </ListItem>
          }
          <Divider />
          {isShowMenuAuth &&
            <ListItem button key='authSetting' onClick={() => handleAuthSetting(true)} disabled={disableAuthSetting}>
              <ListItemIcon><VpnKey /></ListItemIcon>
              <ListItemText primary='認証方式' />
            </ ListItem>
          }
          {isShowMenuAuth &&
            <Divider/>
          }
*/}
        <ListItem button key='backToFloor' onClick={() => handleBackToTop()}>
            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
            <ListItemText primary='戻る' />
          </ListItem>
        </List>
      </Drawer>
    ); 
  }, [authType, role])

  /*
  * コンポーネント「表示名を編集する」を描画
  */
  const drawForceDisplayName = useMemo(() => {
    if(authType === 0){
      return;
    }

    let helperText;
    if(forceDisplayNameHelperText !== "" ){
      helperText = <FormHelperText id="forceDisplayNameHelperText" style={{ marginBottom: 10 }}>{forceDisplayNameHelperText}</FormHelperText>;
    }

    return(
      <Fragment>
        <FormControlLabel control={<Checkbox id="forceDisplayName" disabled={readOnly} color="primary" defaultChecked={detailData != null && detailData.forceDisplayName} />} label="表示名を編集する"
          onInput={(e) => handleOnInput(e, 0, 0, "")} />
        {helperText}
      </Fragment>
    )
  }, [authType, readOnly, detailData.forceDisplayName, forceDisplayNameHelperText])

  /*
  * コンポーネント「表示名」を描画
  */
  const drawDisplayNameTextField = useMemo(() => {
    return(
      <FormControl error={!checkedDisplayName} fullWidth>
        <div>
          <TextField id="displayName" fullWidth size="small" label="表示名" variant="outlined" defaultValue={detailData != null ? detailData.displayName : ""} inputProps={{ readOnly: readOnly, autoComplete: "off" }} disabled={readOnly || disableDisplayNameSetting}
            onInput={(e) => handleOnInput(e, userListConst.displayName.minLength, userListConst.displayName.maxLength, userListConst.displayName.format)} />
          <FormHelperText id="displayNameHelperText">{displayNameHelperText}</FormHelperText><br />
      </div>
      </FormControl>
    )

    return;
  }, [readOnly, authType, detailData.displayName, displayNameHelperText, disableDisplayNameSetting])

  const drawRegisterCSVSelect = useMemo(() => {
    if(authType === 0){
      return(
        <Select labelId="label" id="select" 
          input={<OutlinedInput margin='dense' aria-label={"dealType"} label={"処理区分"} color="primary" />}
          defaultValue={dealType} onChange={(e) => handleDealType(e)} >
          <MenuItem value="C">登録</MenuItem>
          <MenuItem value="U">編集</MenuItem>
          <MenuItem value="D">削除</MenuItem>
        </Select>
      );
    } else if(authType === 1){
      return(
        <Select labelId="label" id="select" 
          input={<OutlinedInput margin='dense' aria-label={"dealType"} label={"処理区分"} color="primary" />}
          defaultValue={dealType} onChange={(e) => handleDealType(e)} >
          <MenuItem value="C" disabled={true}>登録</MenuItem>
          <MenuItem value="U" disabled={true}>編集</MenuItem>
          <MenuItem value="D">削除</MenuItem>
        </Select>
      );
    }

  }, [authType, dealType])

  const handleChimeSetting = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(isShow);
  }

  const handleTimeManagement = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowChimeSetting(false);
    setIsShowTimeManagement(isShow);
  }

  const handleZoomUrlSetting = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowChimeSetting(false);
    setIsShowTimeManagement(false);
    setIsShowZoomUrlSetting(isShow);
  }

  const handleConfirmExecution = () => {
    if(bulkUserManagment){
      setConfirmInfo({ ...confirmInfo, open: false, isOk: true });
      setBulkUserManagment(false);
      showBackdrop.current = true;
    }else{
      setConfirmInfo({ ...confirmInfo, open: false, isOk: true });
    }
  }

  const [bulkUserManagment, setBulkUserManagment] = React.useState(false);
  const [manualUrl, setManualUrl] = useState("");

  const handleClickFileSelectButton = async () => {
    await showOpenFileDialog();
  }

  const drawBulkUserManagment = useMemo(() => {
    return (
      <Box margin="0px 0px 20px 10px" fontSize="18px">
        <Grid style={{ marginBottom: "20px" }}>　ファイル名</Grid>
        <Grid container justify="flex-start">
          <Box style={{ width: "100%" }}>
            <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "20px", marginBottom: "10px" }}>
            <TextField
              autoFocus
              margin="dense"
              id="bulkUserManagmentFile"
              name="bulkUserManagmentFile"
              label="ファイル名"
              variant={'outlined'}
              fullWidth
              style={{ width: 500,}}
              InputLabelProps={{shrink: true}}
              value={uploadCSVFile == {} ? "":(uploadCSVFile as File).name}
              InputProps={{
                  readOnly:true,
                  endAdornment: (
                      <InputAdornment position="end">
                          <Button variant="contained" color="primary" size="small" onClick={() => handleClickFileSelectButton()}>ファイル選択</Button>
                      </InputAdornment>
                  )
              }}
            /><br/>
            </Grid>
          </Box>
          <Box>
            <Grid style={{ marginBottom: "20px" }}>　処理区分</Grid>
            <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "20px" }}>
              {drawRegisterCSVSelect}
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCSVFile, dealType, bulkUserManagment, authType]);

  useEffect(() => {
    if(!bulkUserManagment || manualUrl != "") return;
    //var params = new URLSearchParams();
    //axios.post('/api/service/get', params)
      httpClient.getServiceInfo()
        .then((e) => {
            let ddd = new Date();
            let rURL = e.usermanualURL + "?" + ddd.getTime();
            setManualUrl(rURL);
          }).catch(() => {
        });
  }, [bulkUserManagment]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {drawSideMenu}
      <main className={classes.content}>
        {!isShowWhiteList && !isShowOtherWebsiteSetting && !isShowAdminMessage && !isShowForceMute && !isShowClapping && !isShowAuthSetting && !isShowChimeSetting && !isShowTimeManagement && !isShowZoomUrlSetting
        && (
        <Fragment>
          <div className={classes.button}>
            <Button variant="contained" color="primary" onClick={() => { setConfirmInfo({...confirmInfo, open: true, message: '検索ユーザーリストを出力します。よろしいですか？', type: 1}) }}>検索結果ダウンロード</Button>&nbsp;
            {/* <Button variant="contained" color="primary" onClick={() => { handleDetailOpened(true, 0, clearDetailData()) }}>ユーザー追加</Button> */}
          </div><br />
          <div className={classes.search}>
            <TextField id="loginId_search" size="small" label="ID" variant="outlined" inputProps={{ autoComplete: "off" }}
              onInput={(e) => handleOnInput(e, userListConst.loginId.minLength, userListConst.loginId.maxLength, userListConst.loginId.format)} />&nbsp;
            <TextField id="displayName_search" size="small" label="表示名" variant="outlined" inputProps={{ autoComplete: "off" }}
              onInput={(e) => handleOnInput(e, userListConst.displayName.minLength, userListConst.displayName.maxLength, userListConst.displayName.format)} />&nbsp;
            <Button id="search" variant="contained" color="primary" onClick={handleSearchBtn}>検索</Button>
          </div><br />

          <Paper style={{ width: '100%' }}>
            <TableContainer style={{ height: `calc(100vh - 240px)` }}>
              <Table stickyHeader aria-label="customized table" className={classes.header}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell key = 'loginId' style={{width: 320}}>ID</StyledTableCell>
                    <StyledTableCell key = 'displayName' style={{width: 220,}}>表示名</StyledTableCell>
                    <StyledTableCell key = 'edit' style={{width: 170,}}> </StyledTableCell>
                    <StyledTableCell key = 'registeredDate' style={{width: 120,}}>登録日時</StyledTableCell>
                    <StyledTableCell key = 'empty'></StyledTableCell>
                 </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user: UserData) => {
                    return (
                      <StyledTableRow key={user.loginId}>
                        <StyledTableCell component="th" scope="row">
                          <Link className={classes.link} id={"link_" + user.loginId} onClick={() => { handleDetailOpened(true, 3, user) }} color="primary" underline="always">
                            {getLoginId(user.loginId)}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>{user.displayName}</StyledTableCell>
                        <StyledTableCell>
                          <Button disabled={user.roleSystem} id={"edit_" + user.loginId} variant="contained" color="primary" onClick={() => { handleDetailOpened(true, 1, user) }}>編集</Button>&nbsp;
                          <Button disabled={user.roleSystem} id={"delete_" + user.loginId} variant="contained" color="primary" onClick={() => { handleDetailOpened(true, 2, user) }}>削除</Button>
                      </StyledTableCell>
                        {/* <StyledTableCell>{user.accessDate != null ? dateToFormatString(user.accessDate, "YYYY-MM-DD") : ""}</StyledTableCell> */}
                        <StyledTableCell>{user.registeredDate != null ? dateToFormatString(user.registeredDate, "YYYY-MM-DD") : ""}</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    );
                  })}

                  {/*
                    <StyledTableCell>{dateToFormatString(user.accessDate, "YYYY-MM-DD")}</StyledTableCell>
                    <StyledTableCell>{dateToFormatString(user.registeredDate, "YYYY-MM-DD")}</StyledTableCell>
                  */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"1ページの表示人数"}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Fragment>
          )}
        {isShowWhiteList && <WhiteList />}
        {isShowClapping && <Clapping />}
        {isShowOtherWebsiteSetting && <OtherWebsiteSetting />}
        {isShowAdminMessage && <AdminMessage />}
        {isShowForceMute && <ForceMute />}
        {isShowAuthSetting && <TenantSettingPage />}
        {isShowZoomUrlSetting && <ZoomUrlSetting />}
        {isShowChimeSetting && <ChimeSetting />}
        {isShowTimeManagement && <TimeManagement />}
      </main> 
      <Dialog open={detailOpened}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-detailUser">
          {"ユーザー" + detailLabel}<br />
          {inputError ? <span style={{ color: red[500] }}>入力に誤りがあります。</span> : ""}
          {deleteError ? <span style={{ color: red[500] }}>システム管理者は削除できません。</span> : ""}
        </DialogTitle>
        <DialogContent>
          <FormControl error={!checkedLoginId} fullWidth>
            <div>
              <TextField id="loginId" fullWidth size="small" label="ID" variant="outlined" defaultValue={detailData != null ? getLoginId(detailData.loginId) : ""} inputProps={{ readOnly: readOnlyId, autoComplete: "off" }} disabled={readOnlyId}
                onInput={(e) => handleOnInput(e, userListConst.loginId.minLength, userListConst.loginId.maxLength, userListConst.loginId.format)} />
              <FormHelperText id="loginIdHelperText">{loginIdHelperText}</FormHelperText><br />
            </div>
          </FormControl>
          <FormControl error={!checkedLastName} fullWidth>
            <div>
              <TextField id="lastName" fullWidth size="small" label="姓" variant="outlined" defaultValue={detailData != null ? detailData.lastName : ""} inputProps={{ readOnly: readOnly, autoComplete: "off" }} disabled={readOnly || authType === 1? true: false}
                onInput={(e) => handleOnInput(e, userListConst.lastName.minLength, userListConst.lastName.maxLength, userListConst.lastName.format)} />
              <FormHelperText id="lastNameHelperText">{lastNameHelperText}</FormHelperText><br />
            </div>
          </FormControl>
          <FormControl error={!checkedFirstName} fullWidth>
            <div>
              <TextField id="firstName" fullWidth size="small" label="名" variant="outlined" defaultValue={detailData != null ? detailData.firstName : ""} inputProps={{ readOnly: readOnly, autoComplete: "off" }} disabled={readOnly || authType === 1? true: false}
                onInput={(e) => handleOnInput(e, userListConst.firstName.minLength, userListConst.firstName.maxLength, userListConst.firstName.format)} />
              <FormHelperText id="firstNameHelperText">{firstNameHelperText}</FormHelperText><br />
            </div>
          </FormControl>
          {drawForceDisplayName}
          {drawDisplayNameTextField}
          <FormControl error={!checkedPassword} fullWidth>
            <div>
              <TextField id="password" fullWidth size="small" label="パスワード" type="password" variant="outlined" defaultValue={(detailFuncNum !== 0 && detailData !== null && detailData.password !== null) ? detailData.password : ""} inputProps={{ readOnly: readOnly, autoComplete: "off" }} disabled={readOnly || authType === 1? true: false}
                onInput={(e) => handleOnInput(e, userListConst.password.minLength, userListConst.password.maxLength, userListConst.password.format)} />
              <FormHelperText id="passwordHelperText">{passwordHelperText}</FormHelperText><br />
            </div>
          </FormControl>
          <FormControl error={!checkedConfirmPassword} fullWidth>
            <div>
              <TextField id="confirmPassword" fullWidth size="small" label="パスワード確認" type="password" variant="outlined" defaultValue={(detailFuncNum !== 0 && detailData !== null && detailData.confirmPassword !== null) ? detailData.confirmPassword : ""} inputProps={{ readOnly: readOnly, autoComplete: "off" }} disabled={readOnly || authType === 1? true: false}
                onInput={(e) => handleOnInput(e, userListConst.password.minLength, userListConst.password.maxLength, userListConst.password.format)} />
              <FormHelperText id="confirmPasswordHelperText">{confirmPasswordHelperText}</FormHelperText>
            </div>
          </FormControl>
          <FormControlLabel control={<Checkbox id="isResetPassword" disabled={readOnly || authType === 1? true: false} color="primary" defaultChecked={detailData != null && detailData.isResetPassword} />} label="次回ログイン時にパスワードを再設定する"
            onInput={(e) => handleOnInput(e, 0, 0, "")} /><br />
          <FormControlLabel control={<Checkbox id="roleRoot" disabled={true} color="primary" defaultChecked={detailData != null && detailData.roleRoot} />} label="システム管理者権限"
            onInput={(e) => handleOnInput(e, 0, 0, "")} /><br />
          <FormControlLabel control={<Checkbox id="roleAdmin" disabled={readOnly} color="primary" defaultChecked={detailData != null && detailData.roleAdmin} />} label="運用管理者権限"
            onInput={(e) => handleOnInput(e, 0, 0, "")} /><br />
          <label htmlFor="flexibleNamePlate" style={{paddingRight:20}}> 名札の色 </label>
          <select 
              id="flexibleNamePlate"
              style={{fontSize:18, width:120, textAlignLast:'center'}} 
              disabled={readOnly || authType === 1? true: false} 
              onChange={(e) => handleChangeFlexibleNamePlate(e.target.value as string)} 
              value={selectedFlexibleNamePlate.colorName}>
              {flexibleNamePlateList.map(arrayItem => 
                  <option value={arrayItem.colorName}>{arrayItem.colorName}</option>
              )}
          </select>
          <div><br />
            {(readOnly !== true)
              ?
              <div>プロフィール画面の管理&nbsp;<Button variant="contained" disabled={readOnly || authType === 1? true: false} color="primary" onClick={() => { handleAddTag() }}>項目追加</Button></div> 
              :
              <div>プロフィール画面の管理</div>
            }
              <div>ユーザーのプロフィール画面の表示内容を設定できます。設定した内容は</div>
              <div>項目名：内容</div>
              <div>の形式で表示されます</div>
              <br />
            {detailData?.tags?.map((tag: Tag, index: number) => (
              <FormControl id={"tagForm" + index.toString()} error={!checkedTags[index]}>
                <div>
                  <TextField className={classes.tagKey} id={"tagKey" + index.toString()} size="small" label="項目名" variant="outlined" defaultValue={tag.item} InputProps={{ readOnly: readOnly }} disabled={readOnly || authType === 1? true: false}
                    onInput={(e) => handleOnInput(e, userListConst.tag.key.minLength, userListConst.tag.key.maxLength, userListConst.tag.key.format)} />&nbsp;
                  <TextField className={classes.tagValue} id={"tagValue" + index.toString()} size="small" label="内容" variant="outlined" defaultValue={tag.value} InputProps={{ readOnly: readOnly }} disabled={readOnly || authType === 1? true: false}
                    onInput={(e) => handleOnInput(e, userListConst.tag.value.minLength, userListConst.tag.value.maxLength, userListConst.tag.value.format)} />&nbsp;
                  <TextField className={classes.tagOrder} id={"tagOrder" + index.toString()} size="small" label="表示順" variant="outlined" defaultValue={tag.profileOrder} InputProps={{ readOnly: readOnly }} disabled={readOnly || authType === 1? true: false}
                    onInput={(e) => handleOnInput(e, userListConst.tag.order.minLength, userListConst.tag.order.maxLength, userListConst.tag.order.format)} />&nbsp;
                  <Button id={"tagDelete" + index.toString()} variant="contained" color="primary" disabled={readOnly || authType === 1? true: false} onClick={() => { handleDeleteTag(index) }}>削除</Button><br />
                </div>
                <FormHelperText id={"tagsHelperText" + index.toString()}>{tagsHelperText[index]}</FormHelperText><br />
              </FormControl>
            ))}
          </div>
          {/*<div>
            {(readOnly !== true)
              ?
              <div>入退館時連絡先の管理&nbsp;<Button variant="contained" disabled={readOnly || authType === 1? true: false} color="primary" onClick={() => { handleAddContactAddress() }}>連絡先追加</Button></div> 
              :
              <div>入退館時連絡先の管理</div>
            }
            <div>識別名にはメールアドレスの宛先名を入力してください。</div>
            <div>（入力した内容は通知メールのタイトルや本文には使用されません。）</div>
            <div>例：保護者　指導担当者　など</div>
            <br />
            {detailData?.contactAddressList?.map((address: ContactAddress, index: number) => (
              <FormControl id={"contactAddressForm" + index.toString()} error={!checkedContactAddressList[index]}>
                <div>
                  <TextField className={classes.addressName} id={"addressName" + index.toString()} size="small" label="識別名" variant="outlined" defaultValue={address.name} InputProps={{ readOnly: readOnly }} disabled={readOnly || authType === 1? true: false}
                    onInput={(e) => handleOnInput(e, userListConst.contactAddress.name.minLength, userListConst.contactAddress.name.maxLength, userListConst.contactAddress.name.format)} />&nbsp;
                  <TextField className={classes.addressValue} id={"addressValue" + index.toString()} size="small" label="メールアドレス" variant="outlined" defaultValue={address.contactAddress} InputProps={{ readOnly: readOnly }} disabled={readOnly || authType === 1? true: false}
                    onInput={(e) => handleOnInput(e, userListConst.contactAddress.value.minLength, userListConst.contactAddress.value.maxLength, userListConst.contactAddress.value.format)} />&nbsp;
                  <Button id={"contactAddressDelete" + index.toString()} variant="contained" color="primary" disabled={readOnly || authType === 1? true: false} onClick={() => { handleDeleteContactAddress(index) }}>削除</Button><br />
                </div>
                <FormHelperText id={"contactAddressListHelperText" + index.toString()}>{contactAddressListHelperText[index]}</FormHelperText><br />
              </FormControl>
            ))}
            </div>*/}
        </DialogContent>
        <DialogContentText>
        </DialogContentText>
        <DialogActions>
          {detailFuncNum !== 3 ?
            <div>
              <Button variant="contained" color="primary" onClick={() => { handleUserListYes() }}>OK</Button>&nbsp;
              <Button variant="contained" color="primary" onClick={() => { handleUserListCancel() }}>キャンセル</Button>
            </div>
            :
            <Button variant="contained" color="primary" onClick={() => { handleUserListYes() }}>OK</Button>
          }
        </DialogActions>
      </Dialog>
      {/*<Dialog open={registerCSVOpened}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="dialog-registUser">ユーザー登録</DialogTitle>
        <DialogContent>
          <div>
            <TextField id="csvpath" fullWidth size="small" label="ファイル名" variant="outlined" defaultValue={(uploadCSVFile as File).name} InputProps={{ readOnly: true }} />&nbsp;
            <InputLabel id="dealType">処理区分</InputLabel>
            {drawRegisterCSVSelect}
            <br /><br />
          </div>
        </DialogContent>
        <DialogContentText>
        </DialogContentText>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" onClick={() => { handleRegisterCSVYes() }}>登録</Button>&nbsp;
            <Button variant="contained" color="primary" onClick={() => { handleRegisterCSVCancel() }}>キャンセル</Button>
          </div>
        </DialogActions>
        </Dialog>*/}

      <Dialog open={registerResultOpened}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="dialog-register-result">
          {
            registerResultData.message.split("\n").map((row: string, index: number) => {
              if (index === 0) {
                return (<span key={index}>{row}</span>);
              } else {
                return (<span key={index}><br />{row}</span>);
              }
            })
          }
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogContentText>
        </DialogContentText>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" onClick={() => { handleRegisterResultYes() }}>OK</Button>&nbsp;
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={errorOpened}
        style={{ pointerEvents: 'auto' }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="dialog-error">
          {
            errorData.message
              ? errorData.message.split("\n").map((row: string, index: number) => {
                if (index === 0) {
                  return (<span key={index}>{row}</span>);
                } else {
                  return (<span key={index}><br />{row}</span>);
                }
              })
              : userListConst.unexpectedMessage
          }
          {errorData.code && `(code=${errorData.code})`}
        </DialogTitle>
        <DialogContent>
          {errorData?.errorDetail?.map((detail: ErrorInfo, index: number) => (
            <FormControl id={"errorForm" + index.toString()} fullWidth>
              <div>
                {detail.name
                  ? <InputBase id={"error" + index.toString()} defaultValue={detail.name + ":" + detail.description} inputProps={{ 'aria-label': 'naked' }} fullWidth />
                  : <Fragment>
                    <InputBase id={"error" + index.toString()} defaultValue={"no=" + detail.no + ", code=" + detail.code} inputProps={{ 'aria-label': 'naked' }} fullWidth />
                    {(detail.description instanceof Array)
                      ? <ul>
                        {(detail.description as ErrorInfo[]).map((csvCols: ErrorInfo, index2: number) => (
                          <li key={"error-csvcol-" + index + "-" + index2}>{convertColsName(csvCols.name) + ":" + csvCols.description}</li>
                        ))}
                      </ul>
                      : <div>{detail.description}</div>
                    }
                  </Fragment>
                }
              </div>
            </FormControl>
          ))}
          {/*errorData.errorDetail.map((detail: ErrorInfo, index: number) => {
            <TextField id={"error" + index.toString()} fullWidth size="small" label="ファイル名" variant="outlined" defaultValue={detail.name+":"+detail.description} InputProps={{ readOnly: true }} /><br/>
          })*/}
        </DialogContent>
        <DialogContentText>
        </DialogContentText>
        <DialogActions>
          <div>
            <Button variant="contained" color="primary" onClick={() => { handleErrorYes() }}>OK</Button>&nbsp;
          </div>
        </DialogActions>
      </Dialog>

      {attendanceCSV &&
        <BaseDialog
          id="getAttendanceDialog"
          open={attendanceCSV}
          aria-labelledby='getAttendanceDialogTitle'
        >
          <DialogTitle id="getAttendanceDialogTitle">使用状況データダウンロード</DialogTitle>
          <DialogContentText>　　　FAMcampusの使用状況データをダウンロードできます。</DialogContentText>
          <DialogContentText>　　　対象の年月とフロアを指定してダウンロードボタンを押してください。</DialogContentText>
          <DialogContent className={classes.attendance}>
            {drawGetAttendance}
          </DialogContent>
          <DialogActions style={{ marginRight: 20, marginBottom: 20 }}>
            <Button onClick={onDownload} color="primary" style={{ pointerEvents: 'auto' }} >ダウンロード</Button>
            <Button onClick={handleReprtCsvClose} color="primary" style={{ pointerEvents: 'auto' }} >キャンセル</Button>
          </DialogActions>
        </BaseDialog>
      }
      {bulkUserManagment &&
        <BaseDialog
          id="bulkUserManagmentDialog"
          open={bulkUserManagment}
          aria-labelledby='getAttendanceDialogTitle'
        >
          <DialogTitle id="getAttendanceDialogTitle">ユーザー一括管理</DialogTitle>
          <DialogContentText>　　　指定したファイルの内容でユーザーを一括登録/編集/削除できます。</DialogContentText>
          <DialogContentText>　　　設定用ファイルの記載方法は<a href={usermanualURL} target="_blank" rel="noopener noreferrer">ユーザーマニュアル</a>をご覧ください。</DialogContentText>
          <DialogContent className={classes.attendance}>
            {drawBulkUserManagment}
          </DialogContent>
          <DialogActions style={{ marginRight: 20, marginBottom: 20 }}>
            <Button onClick={() => {setConfirmInfo({ ...confirmInfo, open: true, message:`ユーザーの一括${dealType === 'C'? '登録' : dealType ==='D'? '削除' : '編集'}を行います。よろしいですか？`, type:2 })}} color="primary" style={{ pointerEvents: 'auto' }} disabled = {uploadCSVFile instanceof File ? false : true}>OK</Button>
            <Button onClick={() => {setUploadCSVFile({}); setBulkUserManagment(false)}} color="primary" style={{ pointerEvents: 'auto' }} >キャンセル</Button>
          </DialogActions>
        </BaseDialog>
      }
      <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false }) }}>
        <DialogContent>
          <DialogContentText>{confirmInfo.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color="primary" onClick={() => {handleConfirmExecution()}}>OK</Button>
          <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
        </DialogActions>
      </BaseDialog>
      <Backdrop className={classes.backdrop} open={showBackdropForDowndating} >
          <Slide direction="up" in={showBackdropForDowndating}>
          <Card className={classes.card}>
              <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >データ取得中</Typography>
              </CardContent>
          </Card>
          </Slide>
      </Backdrop>
      <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
        <DialogContent>
          <DialogContentText>{finishInfo.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
        </DialogActions>
      </BaseDialog>
      {reportCSVList &&
        <Dialog
          open={reportCSVList}
          style={{ pointerEvents: 'auto' }}
        >
          <DialogTitle id="comrepo-dialog-title">使用状況ダウンロード</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ padding: '10px' }}>
              {renderReportCsvList()}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleReprtCsvClose} color="primary" style={{ pointerEvents: 'auto' }} >
              閉じる
            </Button>
          </DialogActions>

        </Dialog>
      }

      <Backdrop className={classes.backdrop} open={showBackdrop.current} >
        <Slide direction="up" in={showBackdrop.current}>
          <Card className={classes.card}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{(dealType === "C" ? "登録" : dealType === "U" ? "更新" : "削除")}処理中</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    </div>
  );
}

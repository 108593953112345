import React, { Fragment, useEffect, useState, useMemo, KeyboardEvent, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, useCallback } from 'react'
import axios, { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';

import Cookie from 'js-cookie';
import { Utility } from '../common/Utility';
import { DraggableFrame, DraggableFrameHandler } from '../common/DraggableFrame'
import ZIndex from '../common/ZIndex';
import { Memo as IMemo, Coord, Size, ReadMemoInfo, User } from '../common/JsonClass';
import { MEMO_FRAME_BG_COLOR } from '../common/Constants';
import JfsClient from '@fsi/jfs-sdk';

const dotColor = '#222'
const useStyles = makeStyles(() => ({
    memoWindow: {
        width: '100%',//Utility.isLowScreenResolution() ? '360px' : '360px',
        height: '100%',//Utility.isLowScreenResolution() ? '300px' : '472px',
        position: 'relative',
        userSelect: 'text',
        background: MEMO_FRAME_BG_COLOR,
        border: '1px solid #717171',
        borderRadius: '4px',
    },
    memoWindowHeaderDiv: {
        height: '40px',
        background: 'rgba(250,250,250,0.36)',
        boxShadow: '0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    memoWindowHeaderItem: {
        height: '24px',
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    memoWindowDiv: {
        width: '100%',
        height: `calc(100% - ${Utility.isLowScreenResolution() ? 110 : 132}px)`,// この値を変えるとき、inputMemoDiv, sendMemoButtonの調整も必要
        background: 'rgba(250,250,250,0.36)',
        position: 'absolute',
        overflow: 'scroll',
        overflowX: 'hidden',
        cursor: 'default', // add
        display: 'flex',
        flexDirection: 'column-reverse',// TLを下から上に
    },
    memoInnerFrame: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    inputMemoDiv: {
        height: 90, // memoWindowDivのheightと関連あり
        width: '100%',
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'hidden',
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    inputMemo: {
        width: 'calc(100% - 50px - 24px)',
        height: 55,
        background: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: 4,
        color: '#A39F98',
        marginTop: 8,
        marginLeft: 8,
        padding: 0,
    },
    sendButton: {
        position: 'absolute',
        padding: 0,
        bottom: 30,// inputMemoDivのheight調整時、こちらの調整も必要
        right: 0,
        width: 50,
        height: 50,
        marginRight: 8,

        background: '#1B74E7',
        '&:hover': {
            background: '#2B84F7',
        },
        boxShadow: '0 0 6px 0 rgba(0,0,0,0.24)',
        color: '#FFFFFF',
    },
    inputMemoBorder: {
        padding: 0,
        '&:after': {
            borderWidth: '0px',
        },
        '&:before': {
            borderWidth: '0px',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderWidth: '0px',
        }
    },
    inputMultiline: {
        padding: 8,
    },
    memoUserNickname: {
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
    },
    avatar: {
        color: '#4F4F4F',
        marginLeft: 8,
        marginRight: 8,
    },
    // メモ吹き出し
    balloon1Left: {
        position: 'relative',
        display: 'inline-block',
        padding: '7px 10px',
        minWidth: '120px',
        maxWidth: '80%',
        background: '#FFFFFF',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '0 10px 10px 10px',
        fontSize: 14,
        color: '#4F4F4F',
        letterSpacing: 0,
    },
    balloon1Leftp: {
        margin: 0,
        padding: 0,
        wordBreak: 'break-all',
        textAlign: 'left',
    },
    balloon1Right: {
        position: 'relative',
        display: 'inline-block',
        padding: '7px 10px',
        minWidth: '120px',
        maxWidth: '80%',
        background: '#1B74E7',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '10px 0 10px 10px',
        fontSize: 14,
        color: '#FFFFFF',
        letterSpacing: 0,
        textAlign: 'right',
    },
    // イベント用
    balloon1Center: {
        position: 'relative',
        display: 'inline-block',
        margin: '0.2em',
        padding: '7px 10px',
        minWidth: '70%',
        maxWidth: '70%',
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#555',
        fontSize: '12px',
        background: '#D4EAF4',
        boxShadow: '0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98',
        borderRadius: '4px',
    },
    rgbIconDiv: {
        backgroundColor: 'red',
        marginTop: '3px',
        height: '20px',
        width: '20px',
        borderRadius: '5px',
        border: '1px solid #555',
    },
    endMessage: {
        color: dotColor,
        alignSelf: 'center',
    },
    loading: {
        color: dotColor,
        alignSelf: 'center',
        '&::after': {
            content: '" ."',
            animation: '$dots 1s steps(5, end) infinite;'
        }
    },
    // loading dot definition
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0,0,0,0)',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '40%': {
            color: dotColor,
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
        },
        '60%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 rgba(0,0,0,0)`,
        },
        '80%,100%': {
            textShadow: `.25em 0 0 ${dotColor}, .5em 0 0 ${dotColor}`,
        },
    },
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    alignRight: {
        textAlign: 'right',
    }
}));

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})

interface MemoSetting {
    isNotify?: boolean;
}

interface Props {
    subId: string | null;
    webSocketUrl: string;
    scrollbarWidth: number;
    defaultSize: Size;
    moveMemoGroupList: (mode?: number) => void;
    sendNewMemo: (tabId: string, groupId: number, memo: string) => void;
    sendReadMemo: (tabId: string, groupId: number, memoId: number) => void;
    getMyUser: () => User;
    setToTop:(name: string) => void;
    //frameSetting?: DraggableFrameProps;
}

export interface MemoHandler {
    open: (groupId: number, groupName: string, defaultCoord: Coord) => void;
    close: () => void;
    getOpen: () => boolean;
    setCoord: (coord: Coord) => void;
    setNewMemo: (memo: IMemo) => void;
    receiveReadMemoInfo: (readMemoInfo: ReadMemoInfo) => void;
}

// request
// const getMemoList = async (tabId: string, groupId: number, page: number, limit: number, memoIdAt: number | null) => {
//     try {
//         const res: AxiosResponse<IMemo[]> = await axios.post("/api/user/memo/history", {
//             tabId,
//             groupId,
//             limit,
//             page,
//             memoIdAt
//         });
//         return res.data;
//     } catch (err) {
//         console.error(err);
//         return err;
//     }
// }

// const sendSettingData = (setting: MemoSetting, webSocketUrl: string) => {
//     return axios.post(`/api/user/${webSocketUrl}/memo/setting`, {
//         tabId: sessionStorage.getItem('TABID'),
//         isNotify: setting.isNotify
//     })
// }

const MemoComponent: ForwardRefRenderFunction<MemoHandler, Props> = ({
    subId = "",
    webSocketUrl = "",
    scrollbarWidth = 0,
    defaultSize,
    moveMemoGroupList,
    sendNewMemo,
    sendReadMemo,
    getMyUser,
    setToTop,
    //frameSetting = {},
}, ref) => {
    const tabId = sessionStorage.getItem('TABID') as string;
    const classes = useStyles();
    const frameRef = useRef({} as DraggableFrameHandler);
    const limit = 30; // 1回のリクエストで取得するメモ件数
    const maxMemoText = 255; // 1メモの制限文字数
    const textTrimLength = 20;

    // state
    const [inputMemo, setInputMemo] = useState('');
    const [isShowSetting, setShowSetting] = useState(false);
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [groupId, setGroupId] = useState(0);
    const [groupName, setGroupName] = useState('');
    const [page, setPage] = useState(1);
    const [memoList, setMemoList] = useState([] as IMemo[]);
    const [isEnterContent, setEnterContent] = useState(false);
    const [setting, setSetting] = useState({} as MemoSetting);
    const [defaultCoord, setDefaultCoord] = useState({ x: 0, y: 0 });
    const [message, setMessage] = useState("");
    const [time, setTime] = useState(0);
    const [ctrlEnter, setCtrlEnter] = useState(Cookie.get('MEMO_SEND_KEY')?.toString() === undefined ? 'Enter' : Cookie.get('MEMO_SEND_KEY')?.toString());
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const isMaxMemoText = useMemo(() => {
        return inputMemo.length > maxMemoText
    }, [inputMemo]);

    const isSubIdEmpty = useMemo(() => {
        return subId === "" || subId === null;
    }, [subId]);

    const memoWindowStyle = {
        backgroundColor: 'rgba(255,255,255,0.5)',
    };
    const memoFontStyle = {
        fontSize: '14px',
    };

    // TODO: V2でFloor.tsx側へ移動するかを検討
    const frameSetting = {
        parentElementId: 'floorContainer',
        zIndex: ZIndex.experiment,
        defaultCoord,
        defaultSize,
        scrollbarWidth,
        enableResizing: false,
        disableDragging: true,
    }
    const _frameSetting = {
        ...frameSetting,
        minWidth: defaultSize.width,
        minHeight: defaultSize.height,
        enableResizing: isEnterContent ? false : frameSetting.enableResizing,
        disableDragging: isEnterContent ? true : frameSetting.disableDragging,
    }

    // request
    const getMemoList = async (tabId: string, groupId: number, page: number, limit: number, memoIdAt: number | undefined):Promise<IMemo[]> => {
        try {
            // const res: AxiosResponse<IMemo[]> = await axios.post("/api/user/memo/history", {
            //     tabId,
            //     groupId,
            //     limit,
            //     page,
            //     memoIdAt
            // });

            const res: IMemo[] = await httpClient.getMemoHistory(tabId, groupId, page, limit, memoIdAt);
            return res;
        } catch (err: any) {
            console.error(err);
            return err;
        }
    }

    const sendSettingData = (setting: MemoSetting, webSocketUrl: string) => {
        // return axios.post(`/api/user/${webSocketUrl}/memo/setting`, {
        //     tabId: sessionStorage.getItem('TABID'),
        //     isNotify: setting.isNotify
        // })
        return httpClient.editMemoSetting(sessionStorage.getItem('TABID') as string, 
            Number(webSocketUrl), setting);
    }

    const fetchMoreData = async () => {
        try {
            const data = await getMemoList(tabId, groupId, page, limit, memoList[0]?.memoId);
            if (data) {
                setMemoList([...memoList, ...data]);
                setPage(page + 1);
            }
        } catch (err) {
            console.error(err);
            setMessage("データの取得に失敗しました。")
        }
    }
    const getInitData = async () => {
        try {
            setLoading(true);
            const data = await getMemoList(tabId, groupId, 1, limit, undefined);
            if (data) {
                setMemoList([...data]);
                setPage(2);
            }
        } catch (err) {
            console.error(err);
            setMessage("データの取得に失敗しました。")
        } finally {
            setLoading(false);
        }
    }

    const adjustText = (text: string) => {
        return text.length > textTrimLength ? `${text.slice(0, textTrimLength)}...` : text;
    }

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open: (groupId: number, groupName: string, defaultCoord: Coord) => {
            setDefaultCoord(defaultCoord);
            setGroupId(groupId);
            setGroupName(groupName);
            setMessage("");
            setShow(true);
            setTime(new Date().getTime());
            setTimeout(() => {
                document.getElementById('inputMemoField')?.focus();
            }, 500);
            frameRef.current?.open();
        },
        close() {
            closeUI();
        },
        getOpen() {
            return isShow
        },
        setCoord(coord: Coord) {
            setDefaultCoord(coord);
        },
        setNewMemo(memo: IMemo) {
            // TODO: 画面での表示要求によって処理追加が必要になる
            // 新規メモをメモ要素内に追加
            if (isShow && memo.groupId === groupId) {
                setMemoList([memo, ...memoList]);
                // 既読送信
                sendReadMemo(tabId, memo.groupId, memo.memoId);
            }
        },
        receiveReadMemoInfo(readMemoInfo: ReadMemoInfo) {
            if (isShow && readMemoInfo.groupId === groupId) {
                // TODO: 画面での表示要求によって処理追加が必要になる
                // 既読情報を反映
                const { memoId, groupId, latestReadTime, readCount } = readMemoInfo;
                const _list = memoList.map(
                    d => (d.memoId === memoId && d.groupId === groupId)
                        ? { ...d, latestReadTime, readCount }
                        : d
                );
                setMemoList(_list);
            }
        },
        setMemoSetting(setting: MemoSetting) {
            setSetting(setting);
        },
        getZindex(){
            return zindex;
        },
        setZindex(z: number){
            setZindex(z);
        }
    }));

    const [zindex, setZindex] = React.useState(ZIndex.experiment);

    const closeUI = () => {
        moveMemoGroupList();// 未読リストを画面右上に移動
        setGroupId(0);
        setMessage("");
        setShow(false);
        setShowSetting(false);
        setTime(0);
        frameRef.current?.close();
    }

    /**
     * useEffect
     */
    // UI open時の要素内スクロール位置移動
    useEffect(() => {
        const element = document.getElementById('memoWindow');
        if (element === null) return;
        const bottom = element.scrollHeight - element.clientHeight;
        if (element.scroll !== undefined) {
            element.scroll(0, bottom);
        } else {
            element.scrollTop = bottom;
        }
    }, [isShow]);

    // UI open時の初期データ取得 
    useEffect(() => {
        if (!isShow || !groupId) return;
        async function init() {
            await getInitData();
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, groupId]);

    // UI open時の既読送信
    useEffect(() => {
        // TODO: 送信条件が不十分かもしれない 
        // 未読を既読へ変更
        const latestMemo = memoList[0];
        if (isShow && latestMemo && latestMemo.subId !== subId && latestMemo.readCount === 0) {
            const latestReadTime = new Date(memoList[0].latestReadTime).getTime();
            const isNeedToSend = latestReadTime < time;
            if (isNeedToSend) {
                sendReadMemo(tabId, latestMemo.groupId, latestMemo.memoId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, memoList, groupId, time]);

    // UI open時のsetting情報をstateへ反映
    useEffect(() => {
        if (isShow && !Object.keys(setting).length) {
            const setting = {
                isNotify: getMyUser().isMemoNotify,
            }
            setSetting(setting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    // 設定を閉じる際に設定情報を送信する
    useEffect(() => {
        if (!isShowSetting && setting.isNotify !== undefined) {
            sendSettingData(setting, webSocketUrl).catch(err => {
                console.error(err);
                setSetting({ ...setting, isNotify: !setting.isNotify });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowSetting, webSocketUrl]);

    useEffect(() => {
        frameRef.current?.changeToAtOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCoord]);

    /**
     * event handlers
     */
    const handleChangeInputMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 1 && event.target.value === '\n') {
            return false;
        }
        setInputMemo(event.target.value);
    }

    const handleClickSendButton = () => {
        if (inputMemo.replace('\n', '').trim().length === 0) {
            setInputMemo('');
            return;
        }
        if (isMaxMemoText) return;
        // websocketで送信
        sendNewMemo(tabId, groupId, inputMemo);
        setInputMemo('');
    }

    const handleSelectNotification = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const isNotify = event.target.value === 'ON';
        setSetting({ ...setting, isNotify });
    }

    const handleClose = () => {
        closeUI();
    }

    const handleShowSetting = () => {
        setShowSetting(!isShowSetting);
    }

    var TimeFn = null as any;

    const onFrameDoubleClick = () => {
        clearTimeout(TimeFn);
        frameRef.current?.onFrameDoubleClick();
    }

    const setZIndex = () =>{
        clearTimeout(TimeFn);

        TimeFn = setTimeout(function(){
            setToTop("Memo");
        }, 200);
    };

    /**
     * キーボード操作によるメモの送信
     */
    const memoKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        if (ctrlEnter === 'Ctrl+Enter') {
            if ((event.ctrlKey && event.key === '\n') || (event.ctrlKey && event.key === 'Enter')) {
                if (inputMemo.replace('\n', '').trim().length === 0) return;
                if (isMaxMemoText) return;
                // websocketで送信
                sendNewMemo(tabId, groupId, inputMemo);
                setInputMemo('');
            }
        } else {
            if ((event.shiftKey && event.key === '\n') || (event.shiftKey && event.key === 'Enter')) {
                return false;
            }
            if ((event.key === '\n') || (event.key === 'Enter')) {
                if (inputMemo.replace('\n', '').trim().length === 0) {
                    setInputMemo('');
                    return false;
                }
                if (isMaxMemoText) return;
                // websocketで送信
                sendNewMemo(tabId, groupId, inputMemo);
                setInputMemo('');
            }
        }
    }

    const handleSelectSendKey = (event: React.ChangeEvent<{}>, checked: boolean) => {
        let key = (event.target as HTMLInputElement).value;
        setCtrlEnter(key);
        Cookie.set('MEMO_SEND_KEY', key);
    }

    /**
     * draw elements
     */
    const drawMemoLine = useCallback((index: number, splitMemo: string[], memo: IMemo) => {
        if ((!isSubIdEmpty && memo.subId === subId)) {
            // ゲスト以外のユーザかつ自身の発言は右寄せ
            return (
                <Grid
                    key={`memo-item-${index}`}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                    style={{ paddingTop: 4, paddingBottom: 12 }}
                >
                    <Typography variant="caption" style={{ marginRight: 40 }} className={classes.memoUserNickname}>
                        {adjustText(memo.userName)}
                    </Typography>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <div className={classes.balloon1Right}>
                            {splitMemo.map((memoPart: string, index: number) => {
                                let strs: string[] = Utility.getUrlList(memoPart);
                                if (strs.length === 0) {
                                    return <p key={index} className={classes.balloon1Leftp} style={memoFontStyle}>{memoPart}</p>
                                } else {
                                    let parts: string[] = memoPart.split(strs[0]);
                                    return <Fragment key={index}>
                                        <p className={classes.balloon1Leftp} style={memoFontStyle}>{parts[0]}
                                            <a href={strs[0]} target="_blank" rel="noreferrer">{strs[0]}</a>
                                            {parts[1]}</p>
                                    </Fragment>
                                }
                            })}
                        </div>
                        <AccountCircleIcon className={classes.avatar} />
                    </Grid>
                    <Typography variant="caption" style={{ marginRight: 40 }} className={classes.memoUserNickname}>
                        {new Date(memo.memoTime).toLocaleString()}
                    </Typography>
                    {index === 0 &&
                        <Typography variant="caption" style={{ marginRight: 40 }} className={classes.memoUserNickname}>
                            {memo.readCount === 0
                                ? '未読'
                                : `既読: ${new Date(memo.latestReadTime).toLocaleString()} ${memo.readCount}人`
                            }
                        </Typography>
                    }
                </Grid>
            )
        } else {
            // 自分以外のメモは左寄せ
            return (
                <Grid
                    key={index}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    style={{ paddingTop: 4, paddingBottom: 12 }}
                >
                    <Typography variant="caption" style={{ marginLeft: 40 }} className={classes.memoUserNickname}>
                        {adjustText(memo.userName)}
                    </Typography>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <AccountCircleIcon className={classes.avatar} />
                        <div className={classes.balloon1Left}>
                            {splitMemo.map((memoPart: string, index: number) => {
                                let strs: string[] = Utility.getUrlList(memoPart);
                                if (strs.length === 0) {
                                    return <p key={index} className={classes.balloon1Leftp}>{memoPart}</p>
                                } else {
                                    let parts: string[] = memoPart.split(strs[0]);
                                    return <Fragment key={index}>
                                        <p className={classes.balloon1Leftp} style={memoFontStyle}>{parts[0]}
                                            <a href={strs[0]} target="_blank" rel="noreferrer">{strs[0]}</a>
                                            {parts[1]}</p>
                                    </Fragment>
                                }
                            })}
                        </div>
                    </Grid>
                    <Typography variant="caption" style={{ marginLeft: 40 }} className={classes.memoUserNickname}>
                        {new Date(memo.memoTime).toLocaleString()}
                    </Typography>
                    {index === 0 &&
                        <Typography variant="caption" style={{ marginLeft: 40 }} className={classes.memoUserNickname}>
                            {memo.readCount === 0
                                ? '未読'
                                : `既読: ${new Date(memo.latestReadTime).toLocaleString()} ${memo.readCount}人`
                            }
                        </Typography>
                    }
                </Grid>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubIdEmpty, memoFontStyle]);

    const drawMemo = useCallback((memo: IMemo, index: number) => {
        const splitMemo = memo.memo.split('\n');
        return drawMemoLine(index, splitMemo, memo);
    }, [drawMemoLine]);

    const redrawMemo = useMemo(() => {
        return memoList.length === 0
            ? <Typography align="center">表示するメモはありません</Typography>
            : memoList.map((memo: IMemo, index: number) => drawMemo(memo, index));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoList, isLoading, drawMemoLine, drawMemo]);

    const drawSetting = useMemo(() => {
        return (
            <Fragment>
                <Typography variant='body2' id='continuous-slider' gutterBottom align='left'>
                    通知
                </Typography>
                <RadioGroup row name='isNotify' value={setting.isNotify ? 'ON' : 'OFF'}>
                    <FormControlLabel value='ON' control={<Radio color='primary' onChange={handleSelectNotification} />} label='ON' />
                    <FormControlLabel value='OFF' control={<Radio color='primary' onChange={handleSelectNotification} />} label='OFF' />
                </RadioGroup>
                { ctrlEnter !== 'Enter' && // この条件を外すとUI上に表示される
                    <>
                        <Typography variant="body2" id="continuous-slider" gutterBottom>
                            {"送信キーの設定"}
                        </Typography>
                        <RadioGroup row name="send_key" defaultValue={ctrlEnter}>
                            <FormControlLabel value="Ctrl+Enter" control={<Radio color="primary" onChange={handleSelectSendKey} />} label="Ctrl+Enter" />
                            <FormControlLabel value="Enter" control={<Radio color="primary" onChange={handleSelectSendKey} />} label="Enter" />
                        </RadioGroup>
                    </>
                }
            </Fragment>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setting.isNotify]);

    const drawLoading = useMemo(() => {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress color='primary' />
                </ThemeProvider>
            </Grid>
        )
    }, []);

    const drawMessage = useMemo(() => {
        return (
            <div className={classes.messageContainer}>
                {message}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const drawMaxMemoMessage = useMemo(() => {
        return isMaxMemoText
            ? `${maxMemoText}文字を超えています。${inputMemo.length}/${maxMemoText}文字`
            : `${inputMemo.length}/${maxMemoText}文字`
    }, [inputMemo, isMaxMemoText]);

    return (
        <DraggableFrame ref={frameRef} {..._frameSetting} zIndex={zindex} handleResize={() => {}}>
            <CssBaseline />
            <Paper elevation={5} className={classes.memoWindow}>
                <div className={classes.memoWindowHeaderDiv} onDoubleClick={onFrameDoubleClick} onClick={setZIndex}>
                    {/* <Tooltip title={groupName.length > textTrimLength ? groupName : ''} placement="bottom-start" arrow> */}
                    <div style={{ marginLeft: 8 }}>{adjustText(groupName)}</div>
                    {/* </Tooltip> */}
                    <div>
                        <IconButton className={classes.memoWindowHeaderItem} onClick={handleShowSetting}>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton className={classes.memoWindowHeaderItem} onClick={handleClose} style={{ width: 22 }}>
                            <CloseIcon viewBox='0 0 16 16' />
                        </IconButton>
                    </div>
                </div>
                <div
                    id='memoWindow'
                    className={'customScrollBar ' + classes.memoWindowDiv}
                    style={memoWindowStyle}
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                >
                    {isLoading
                        ? drawLoading
                        : message !== ""
                            ? drawMessage
                            : <InfiniteScroll
                                className={classes.memoInnerFrame}
                                scrollableTarget="memoWindow"
                                dataLength={memoList.length}
                                next={page === 1 ? getInitData : fetchMoreData}
                                style={{
                                    display: "flex",
                                    flexDirection: memoList.length === 0 ? "column" : "column-reverse",
                                    justifyContent: memoList.length === 0 ? "center" : "flex-start",
                                    alignItems: memoList.length === 0 ? "center" : "flex-start",
                                }}
                                inverse={true}
                                hasMore={memoList.length === limit * (page - 1)}
                                loader={<h4 className={classes.loading}>loading</h4>}
                                endMessage={!isLoading && memoList.length > 0 && <h4 className={classes.endMessage}>メモ開始</h4>}
                            >
                                {redrawMemo}
                            </InfiniteScroll>
                    }
                </div>

                <div
                    className={classes.inputMemoDiv}
                    style={memoWindowStyle}
                    onPointerEnter={() => setEnterContent(true)}
                    onPointerLeave={() => setEnterContent(false)}
                >
                    <TextField
                        className={`customScrollBar ${classes.inputMemo}`}
                        id='inputMemoField'
                        multiline
                        rowsMax='2'
                        rows='2'
                        value={inputMemo}
                        onChange={handleChangeInputMemo}
                        variant='standard'
                        placeholder={'メッセージを入力'}
                        onKeyPress={memoKeyPress}
                        InputProps={{
                            classes: {
                                underline: classes.inputMemoBorder,
                                inputMultiline: classes.inputMultiline,
                            },
                        }}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.alignRight,
                            }
                        }}
                        error={isMaxMemoText}
                        helperText={drawMaxMemoMessage}
                    />
                </div>
                <IconButton
                    disabled={isMaxMemoText}
                    onClick={handleClickSendButton}
                    className={classes.sendButton}
                    color='primary'
                    aria-label='send memo'
                >
                    <SendIcon />
                </IconButton>
            </Paper>

            <Paper
                hidden={!isShowSetting}
                style={{
                    position: 'absolute',
                    top: 30,
                    right: 40,
                    width: 200,
                    minHeight: 100,
                    maxHeight: 200,
                    padding: 15,
                    overflow: 'auto'
                }}
            >
                {drawSetting}
            </Paper>
        </DraggableFrame >
    )
}

export const Memo = forwardRef(MemoComponent);
import { Fragment, useEffect, useMemo, useState, useCallback, ReactNode } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import axios from "axios";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from '@material-ui/styles';
import { useSnackbar } from "notistack";
import JfsClient from "@fsi/jfs-sdk";

const MAX_URL_LENGTH = 255;
const OVER_MAXTEXTLENGTH_ERROR = (text: string) => {
  return `URLが長すぎます。（${text.length}文字 / ${MAX_URL_LENGTH}文字)`;
}
const URL_FORMAT_ERROR = () => {
  return '正しいURLを入力してください。'
}
const isURL = (str: string) => {
  if (str !== "") {
    if (/^http[s]?:\/\/([\w\-\.]+)+[\w-]*([\w\-\.\/\?%&=]+)?$/ig.test(str)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1) * 2,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    textAlign: "left",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "95%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

export default function OtherWebsiteSetting() {
  const tabId = sessionStorage.getItem("TABID") || "";
  const classes = useStyles();
  const [otherWebsiteBoxMaxHeight, setOtherWebsiteBoxMaxHeight] = useState(0);
  const [otherWebsiteUrl, setOtherWebsiteUrl] = useState("");
  const [otherWebsiteUrlErrMsg, setOtherWebsiteUrlErrMsg] = useState<ReactNode>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const messageSuccess = "WebsiteViewerの変更が成功しました！";
  const messageFailed = "WebsiteViewerの変更に失敗しました！";
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;

  useEffect(() => {
    setOtherWebsiteBoxMaxHeight(window.innerHeight - 100);
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validateInputedUrl(event.target.value);
    setOtherWebsiteUrl(event.target.value);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let value = event.target.value;
    validateInputedUrl(value);
    setOtherWebsiteUrl(value);
  };

  const handleCancel = () => {
    setOtherWebsiteUrl("");
  };

  const handleSubmit = async () => {
      setIsLoading(true);
      try {
        // await axios.post("/api/v1/admin/otherwebsite/update", {
        //   tabId,
        //   otherWebsiteUrl,
        // });
        await httpClient.updateOtherWebsite(tabId, otherWebsiteUrl);
        enqueueSnackbar(messageSuccess, { variant: "success" });
      } catch (e) {
        console.error(e);
        enqueueSnackbar(messageFailed, { variant: "error" });
      } finally {
        setOtherWebsiteUrl("");
        setIsLoading(false);
      }
  };
 
  const validateInputedUrl = (text: string): boolean => {
    let isValidLength = true;
    let msgList: string[] = [];
    let isUrl = isURL(text);

    // 文字数チェック
    isValidLength = text.length <= MAX_URL_LENGTH;
    if (!isValidLength) {
        const errMessage = OVER_MAXTEXTLENGTH_ERROR(text);
        msgList.push(errMessage);
    }

    if (!isUrl) {
        const errMessage = URL_FORMAT_ERROR();
        msgList.push(errMessage);
    }

    // メッセージをセット
    if (isValidLength) {
        if (isUrl) {
          setOtherWebsiteUrlErrMsg(null);
        } else {
          setOtherWebsiteUrlErrMsg(drawError(msgList));
        }
    } else {
        setOtherWebsiteUrlErrMsg(drawError(msgList));
    }

    return isValidLength && isUrl;
  };

  const drawError = useCallback((messageList: string[] = []) => {
    return (
        <div style={{ maxHeight: 150, overflow: 'auto' }}>
            {
                messageList.length > 0 &&
                <ul style={{ margin: 5, paddingLeft: 10 }}>
                    {messageList.map(msg => <li>{msg}</li>)}
                </ul>
            }
        </div>
    )
  }, []);

  const drawInput = useMemo(() => {
    return (
      <TextField
        label="OtherWebsiteURL"
        style={{ margin: 10 }}
        value={otherWebsiteUrl}
        placeholder={"URLを入力してください"}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherWebsiteUrl]);

  const drawLoading = useMemo(
    () => isLoading && <ThemeProvider theme={circularTheme}><CircularProgress color="primary" /></ThemeProvider>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              overflowY: "auto",
              maxHeight: otherWebsiteBoxMaxHeight + "px",
            }}
          >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
              >
                <div className={classes.column}>
                  <Typography className={classes.heading}>
                    WebsiteViewerのURL設定
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.column}>{drawInput}</div>
              </AccordionDetails>
              <Typography align='left' variant='caption' color='error'>
                  {otherWebsiteUrlErrMsg}
              </Typography>
              <AccordionActions>
                <Button size="large" color="primary" onClick={handleCancel}>
                  キャンセル
                </Button>
                <Button 
                    size="large" 
                    disabled={otherWebsiteUrlErrMsg !== null}  
                    color="primary" 
                    onClick={handleSubmit}
                  >
                  変更
                </Button>
              </AccordionActions>
              {drawLoading}
            </Accordion>
          </div>
        </main>
      </div>
    </Fragment>
  );
}

import React, { useEffect, useRef } from 'react';
import useReactRouter from 'use-react-router';
import { makeStyles, TableContainer, Paper, TableHead, TableCell, TableRow, Table, TableBody, TableSortLabel, TablePagination, TextField, Button, Box, Grid } from '@material-ui/core';
import SystemFrame from './SystemFrame';
import { Tenant } from '../common/JsonClass';
import axios, { AxiosResponse } from 'axios';

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { SystemOfficeInfo, SystemOfficeInfoHandler } from './SystemOfficeInfo';
import { SystemTenantInfo, SystemTenantInfoHandler } from './SystemTenantInfo';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Tenant;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
    { id: 'tenantName', numeric: false, disablePadding: false, label: 'テナント名' },
    { id: 'businessStartTime', numeric: false, disablePadding: false, label: '１日の開始時間' },
    { id: 'insertTimestamp', numeric: false, disablePadding: false, label: '登録日' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Tenant) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Tenant) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
        overflow: 'scroll',
        height: '100vh',
    },
    media: {
        height: 140,
    },
    dividerMargin: {
        marginBottom: '20px',
    },
    alert: {
        background: "#f44336",
        color: "#fff",
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHead: {
        backgroundColor: 'aliceblue',
    },
}));

export default function SystemTenantList() {
    const classes = useStyles();
    const { history } = useReactRouter();
    const refSystemOfficeInfo = useRef({} as SystemOfficeInfoHandler);
    const refSystemTenantInfo = useRef({} as SystemTenantInfoHandler);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Tenant>('id');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchCompany, setSearchCompany] = React.useState("");
    const [tenantList, setTenantList] = React.useState([] as Tenant[]);
    const [masterData, setMasterData] = React.useState({} as { [index: string]: [{ id: number, name: string }] });

    useEffect(() => {
        // axios.get('/api/system/login/check')
        // .then((response: AxiosResponse) => {
        //     // ログイン済みかを確認するだけなので何もしない
        // }).catch(() => {
        //     console.log("login error.");
        //     history.push({
        //         pathname: '/',
        //     });
        // });

        // 非同期でテナント編集ダイアログ用のマスターデータを取得
        axios.create({ withCredentials: true })
            .post('/api/system/tenant/master/get', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
            }))
            .then((response: AxiosResponse) => {
                setMasterData(response.data);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("マスターデータ取得エラー");
                    console.log(err.response);
                }
            });
    }, []);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Tenant) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = tenantList.map((n) => n.tenantName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        // handleClickReset();
        refSystemTenantInfo.current.open(id);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tenantList.length - page * rowsPerPage);

    const changeSearchCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCompany(event.target.value);
    }

    const requestTenantList = (keyword: string) => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/tenant/list', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                keyword: keyword,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as Tenant[];
                setTenantList(result);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("TenantSearch error.");
                    console.log(err.response);
                }
            });
    }

    const handleClickSearch = () => {
        requestTenantList("%" + searchCompany + "%" as string);
    }

    const handleClickReset = () => {
        setSearchCompany("");
        requestTenantList("");
    }

    const handleOpenOfficeInfo = () => {
        // handleClickReset();
        refSystemOfficeInfo.current.open(0);
    }

    const handleOpenTenantInfo = () => {
        // handleClickReset();
        refSystemTenantInfo.current.open(0);
    }

    return (
        <div className={classes.root}>
            <SystemFrame />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div>
                    <div style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" onClick={handleOpenOfficeInfo} startIcon={<AddIcon />}>オフィス追加　</Button>　
                        <Button variant="contained" color="primary" onClick={handleOpenTenantInfo} startIcon={<AddIcon />}>テナント追加　</Button>　
                    </div>
                    <Box m={1} />
                    <Paper className={classes.paper} elevation={3} style={{ padding: "10px" }}>
                        <div style={{ textAlign: "left" }}>
                            <TextField label="テナント名" value={searchCompany} onChange={changeSearchCompany} fullWidth></TextField>
                        </div>
                    </Paper>
                    <div style={{ textAlign: "right" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" onClick={handleClickReset} startIcon={<RotateLeftIcon />}>リセット</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" onClick={handleClickSearch} startIcon={<SearchIcon />}>　検索　</Button>
                            </Grid>
                        </Grid>

                    </div>
                    <Box m={3} />
                    <Paper className={classes.paper} elevation={3} style={{ padding: "10px" }}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={tenantList.length}
                                />
                                <TableBody>
                                    {stableSort(tenantList, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.tenantName);
                                            // const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell component="th" scope="row" padding="none">{row.id}</TableCell>    
                                                    <TableCell scope="row" padding="none">{row.tenantName}</TableCell>
                                                    <TableCell align="left">{row.businessStartTime}</TableCell>
                                                    <TableCell align="left">{new Date(row.insertTimestamp).toLocaleString()}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={tenantList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </main>
            <SystemTenantInfo ref={refSystemTenantInfo} masterData={masterData} />
            <SystemOfficeInfo ref={refSystemOfficeInfo} masterData={masterData} />
        </div>
    );
}
import React, { Fragment, useEffect, useState, useMemo, KeyboardEvent, forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react'
import { CssBaseline, Paper, makeStyles, TextField, Grid, IconButton, Typography, Chip, Avatar, Slider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Utility } from '../common/Utility';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import { ReactComponent as CloseIcon } from '../img/icn_close.svg';

const useStyles = makeStyles(() => ({
    chatPosition: {
        position: "absolute",
        bottom: "100px",
        right: "60px",
    },
    chatWindow: {
        width: Utility.isLowScreenResolution() ? '360px' : '360px',
        height: Utility.isLowScreenResolution() ? '300px' : '472px',
        position: 'relative',
        userSelect: "text",
        background: "rgba(223,223,223,0.8)",
        border: "1px solid #717171",
        borderRadius: "4px",
    },
    chatWindowHeaderDiv: {
        height: '40px',
        background: "rgba(250,250,250,0.36)",
        boxShadow: "0 -1px 1px 0 rgba(255,255,255,0.60), 0 1px 1px 0 rgba(0,0,0,0.09)",
    },
    chatWindowHeaderItem: {
        height: "24px",
        marginTop: 8,
        padding: 0,
        marginLeft: 8,
        '&:last-child': {
            marginRight: 8,
        },
    },
    chatWindowDiv: {
        width: "100%",
        height: Utility.isLowScreenResolution() ? '210px' : '360px',
        background: "rgba(250,250,250,0.36)",
        position: 'absolute',
        overflow: 'scroll',
        overflowX: "hidden"
    },
    chatInnerFrame: {
        width: "100%",
        margin: 0,
        padding: 0,
        marginBottom: 8,
    },
    inputChatDiv: {
        height: "70px",
        width: '100%',
        position: "absolute",
        left: '0px',
        bottom: "0px",
        overflow: 'scroll',
        overflowX: "hidden",
        overflowY: "hidden",
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    inputChat: {
        width: "calc(100% - 50px - 24px)",
        height: 50,
        background: "#FFFFFF",
        boxShadow: "0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98",
        borderRadius: 4,
        color: "#A39F98",
        marginTop: 8,
        marginLeft: 8,
        padding: 8,
    },
    sendButton: {
        position: "absolute",
        padding: 0,
        bottom: 12,
        right: 0,
        width: 50,
        height: 50,
        marginRight: 8,

        background: "#1B74E7",
        "&:hover": {
            background: "#2B84F7",
        },
        boxShadow: "0 0 6px 0 rgba(0,0,0,0.24)",
        color: "#FFFFFF",
    },
    inputChatBorder: {
        padding: 0,
        "&:after": {
            borderWidth: "0px",
        },
        "&:before": {
            borderWidth: "0px",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderWidth: "0px",
        }
    },
    chatUserNickname: {
        fontSize: 14,
        color: "#4F4F4F",
        letterSpacing: 0,
    },
    avatar: {
        color: "#4F4F4F",
        marginLeft: 8,
        marginRight: 8,
    },
    // チャット吹き出し
    balloon1Left: {
        position: "relative",
        display: "inline-block",
        padding: "7px 10px",
        minWidth: "120px",
        maxWidth: "80%",
        background: "#FFFFFF",
        boxShadow: "0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98",
        borderRadius: "0 10px 10px 10px",
        fontSize: 14,
        color: "#4F4F4F",
        letterSpacing: 0,
    },
    balloon1Leftp: {
        margin: 0,
        padding: 0,
        wordBreak: "break-all",
        textAlign: "left",
    },
    balloon1Right: {
        position: "relative",
        display: "inline-block",
        padding: "7px 10px",
        minWidth: "120px",
        maxWidth: "80%",
        background: "#1B74E7",
        boxShadow: "0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98",
        borderRadius: "10px 0 10px 10px",
        fontSize: 14,
        color: "#FFFFFF",
        letterSpacing: 0,
        textAlign: "right",
    },
    // イベント用
    balloon1Center: {
        position: "relative",
        display: "inline-block",
        margin: "0.2em",
        padding: "7px 10px",
        minWidth: "70%",
        maxWidth: "70%",
        left: "50%",
        transform: "translateX(-50%)",
        color: "#555",
        fontSize: "12px",
        background: "#D4EAF4",
        boxShadow: "0 1px 1px 0 rgba(164,152,135,0.32), 0 0 1px 0 #A39F98",
        borderRadius: "4px",
    },
    rgbIconDiv: {
        backgroundColor: "red",
        marginTop: "3px",
        height: "20px",
        width: "20px",
        borderRadius: "5px",
        border: "1px solid #555",
    },
}));

export class Chat {
    constructor(public userId: number, public chat: string, public name: string, public myData: boolean = false) {
    }
}

interface Props {
    // addChat: (chat: Chat, isSend: boolean, isShowSnackbar: boolean) => void;
    // handleClose: () => void;
    chatList: Chat[],
    userId: number,
    viewName: string,
}

export interface ChatComponentHandler {
    open: () => void;
}

const ChatComponentFunction: ForwardRefRenderFunction<ChatComponentHandler, Props> = (props, ref) => {
    // export default function ChatComponent(props: Props) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [inputChat, setInputChat] = useState("");
    const [isShowEvent, setShowEvent] = useState(Cookie.get("CHAT_EVENT")?.toString() === "0" ? false : true);
    const [isShowSetting, setShowSetting] = useState(false);
    const [backgroundAlpha, setBackgroundAlpha] = useState(Utility.getCookieNumber("CHAT_BACKGROUND_ALPHA", 80));
    const [backgroundRed, setBackgroundRed] = useState(Utility.getCookieNumber("CHAT_BACKGROUND_RED", 223));
    const [backgroundBlue, setBackgroundBlue] = useState(Utility.getCookieNumber("CHAT_BACKGROUND_BLUE", 223));
    const [backgroundGreen, setBackgroundGreen] = useState(Utility.getCookieNumber("CHAT_BACKGROUND_GREEN", 223));
    const [chatWindowStyle, setChatWindowStyle] = useState({
        backgroundColor: 'rgba(255,255,255,0.5)',
    });
    const [fontSize, setFontSize] = useState(Utility.getCookieNumber("CHAT_FONT_SIZE", 14));
    const [chatFontStyle, setChatFontStyle] = useState({
        fontSize: '14px',
    });
    const [ctrlEnter, setCtrlEnter] = useState(Cookie.get("CHAT_SEND_KEY")?.toString() === undefined ? "Enter" : Cookie.get("CHAT_SEND_KEY")?.toString());
    const [isShow, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => {
            setShow(true);
        },
    }))

    /**
     * 初期処理
     */
    useEffect(() => {
        let chatStyle = {
            backgroundColor: 'rgba(' + backgroundRed + ',' + backgroundGreen + ',' + backgroundBlue + ',' + (backgroundAlpha / 100) + ')',
        };
        setChatWindowStyle(chatStyle);
        let chatFontStyle = {
            fontSize: fontSize + "px",
        }
        setChatFontStyle(chatFontStyle);
    }, [backgroundAlpha, backgroundBlue, backgroundGreen, backgroundRed, fontSize]);

    /**
     * 描画処理の後に実行
     */
    useEffect(() => {
        var element = document.getElementById("chatWindow");
        if (element === null) return;
        var bottom = element.scrollHeight - element.clientHeight;
        if (element.scroll !== undefined) {
            element.scroll(0, bottom);
        } else {
            element.scrollTop = bottom;
        }
    }, [props.chatList, isShow]);

    const redrawChat = useMemo(() => {

        return props.chatList.map((chat: Chat, index: number) => {
            return drawChat(chat, index)
        });

        function drawChat(chat: Chat, index: number) {
            var splitChat = chat.chat.split("\n");

            return drawChatLine(index, splitChat, chat);

        };

        function drawChatLine(index: number, splitChat: string[], chat: Chat) {
            if ((chat.userId === props.userId && props.userId !== 0) || chat.myData === true) {
                // ゲスト以外のユーザかつ自身の発言 (または、ゲストが会議開催中に発言したチャット) は右寄せ
                return <Grid key={index} item xs={12} style={{ textAlign: "right", verticalAlign: "middle", padding: "4 0 12 0" }}>
                    <div style={{ marginRight: 40 }}><Typography variant="caption" className={classes.chatUserNickname}>{chat.name}</Typography></div>
                    <div style={{ float: "right", height: "auto" }}>
                        <div>
                            <AccountCircleIcon className={classes.avatar} />
                        </div>
                    </div>
                    <div>
                        <div style={{ float: "right" }} className={classes.balloon1Right}>
                            {splitChat.map((chatPart: string, index: number) => {
                                let strs: string[] = Utility.getUrlList(chatPart);
                                if (strs.length === 0) {
                                    return <p key={index} className={classes.balloon1Leftp} style={chatFontStyle}>{chatPart}</p>
                                } else {
                                    let parts: string[] = chatPart.split(strs[0]);
                                    return <Fragment key={index}>
                                        <p className={classes.balloon1Leftp} style={chatFontStyle}>{parts[0]}
                                            <a href={strs[0]} target="_blank" >{strs[0]}</a>
                                            {parts[1]}</p>
                                    </Fragment>
                                }
                            })}
                        </div>
                        <div style={{ clear: "right" }}></div>
                    </div>
                </Grid>
            } else {
                // 自分以外のチャットは左寄せ
                return <Grid key={index} item xs={12} style={{ textAlign: "left", verticalAlign: "middle", padding: "4 0 12 0" }}>
                    <div style={{ marginLeft: 40 }}><Typography variant="caption" className={classes.chatUserNickname}>{chat.name}</Typography></div>
                    <div style={{ float: "left", height: "auto" }}>
                        <div>
                            <AccountCircleIcon className={classes.avatar} />
                        </div>
                    </div>
                    <div style={{ float: "left" }} className={classes.balloon1Left}>
                        {splitChat.map((chatPart: string, index: number) => {
                            let strs: string[] = Utility.getUrlList(chatPart);
                            if (strs.length === 0) {
                                return <p key={index} className={classes.balloon1Leftp}>{chatPart}</p>
                            } else {
                                let parts: string[] = chatPart.split(strs[0]);
                                return <Fragment key={index}>
                                    <p className={classes.balloon1Leftp} style={chatFontStyle}>{parts[0]}
                                        <a href={strs[0]} target="_blank" rel="noreferrer">{strs[0]}</a>
                                        {parts[1]}</p>
                                </Fragment>
                            }
                        })}
                    </div>
                    <div style={{ clear: "right" }}></div>
                </Grid>
            }
        }
    }, [t, props.chatList, props.userId, isShowEvent, classes.balloon1Center, classes.balloon1Right, classes.balloon1Left, classes.balloon1Leftp, chatFontStyle, classes.avatar, classes.chatUserNickname]);

    const handleChangeInputChat = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 1 && event.target.value === '\n') {
            return false;
        }
        setInputChat(event.target.value);
    }

    const handleClickSendButton = () => {
        // var newChatList = [...props.chatList];
        if (inputChat.replace("\n", "").trim().length === 0) {
            setInputChat("");
            return;
        }
        // props.addChat(new Chat(props.userId, inputChat, props.userId === 0 ? props.viewName : props.viewName, props.userId === 0 ? true : false), true, true);
        // setChatList(newChatList);
        setInputChat("");
    }

    /**
     * キーボード操作によるチャットの送信
     */
    const chatKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        // console.log(event);
        // console.log(event.key);
        // console.log(event.ctrlKey);
        // console.log(ctrlEnter);
        if (ctrlEnter === "Ctrl+Enter") {
            if ((event.ctrlKey && event.key === '\n') || (event.ctrlKey && event.key === 'Enter')) {
                if (inputChat.replace("\n", "").trim().length === 0) return;
                // props.addChat(new Chat(props.userId, inputChat, props.userId === 0 ? props.viewName : props.viewName, props.userId === 0 ? true : false), true, true);
                setInputChat("");
            }
        } else {
            if ((event.shiftKey && event.key === '\n') || (event.shiftKey && event.key === 'Enter')) {
                return false;
            }
            if ((event.key === '\n') || (event.key === 'Enter')) {
                if (inputChat.replace("\n", "").trim().length === 0) {
                    setInputChat("");
                    return false;
                }
                // props.addChat(new Chat(props.userId, inputChat, props.userId === 0 ? props.viewName : props.viewName, props.userId === 0 ? true : false), true, true);
                setInputChat("");
            }
        }
    }

    /**
     * 透明度の変更
     */
    const handleChangeBackgroundAlpha = (event: any, newValue: number | number[]) => {
        setBackgroundAlpha(newValue as number);
        let chatStyle = {
            backgroundColor: 'rgba(' + backgroundRed + ',' + backgroundGreen + ',' + backgroundBlue + ',' + (newValue as number / 100) + ')',
        };
        setChatWindowStyle(chatStyle);
        Cookie.set("CHAT_BACKGROUND_ALPHA", (newValue as number).toString());
    }
    /**
     * 背景色赤要素の変更
     */
    const handleChangeBackgroundRed = (event: any, newValue: number | number[]) => {
        setBackgroundRed(newValue as number);
        let chatStyle = {
            backgroundColor: 'rgba(' + newValue + ',' + backgroundGreen + ',' + backgroundBlue + ',' + (backgroundAlpha / 100) + ')',
        };
        setChatWindowStyle(chatStyle);
        Cookie.set("CHAT_BACKGROUND_RED", (newValue as number).toString());
    }
    /**
     * 背景色青の変更
     */
    const handleChangeBackgroundBlue = (event: any, newValue: number | number[]) => {
        setBackgroundBlue(newValue as number);
        let chatStyle = {
            backgroundColor: 'rgba(' + backgroundRed + ',' + newValue + ',' + backgroundBlue + ',' + (backgroundAlpha / 100) + ')',
        };
        setChatWindowStyle(chatStyle);
        Cookie.set("CHAT_BACKGROUND_BLUE", (newValue as number).toString());
    }
    /**
     * 背景色緑の変更
     */
    const handleChangeBackgroundGreen = (event: any, newValue: number | number[]) => {
        setBackgroundGreen(newValue as number);
        let chatStyle = {
            backgroundColor: 'rgba(' + backgroundRed + ',' + backgroundGreen + ',' + newValue + ',' + (backgroundAlpha / 100) + ')',
        };
        setChatWindowStyle(chatStyle);
        Cookie.set("CHAT_BACKGROUND_GREEN", (newValue as number).toString());
    }

    /**
     * 背景色緑の変更
     */
    const handleChangeFontSize = (event: any, newValue: number | number[]) => {
        setFontSize(newValue as number);
        let chatStyle = {
            fontSize: newValue + "px",
        };
        setChatFontStyle(chatStyle);
        Cookie.set("CHAT_FONT_SIZE", (newValue as number).toString());
    }

    const handleSelectSendKey = (event: React.ChangeEvent<{}>, checked: boolean) => {
        let key = (event.target as HTMLInputElement).value;
        setCtrlEnter(key);
        Cookie.set("CHAT_SEND_KEY", key);
    }

    const handleClose = () => {
        setShow(false);
    }

    if (!isShow) return (<Fragment></Fragment>);
    return (

        <div className={classes.chatPosition}>
            <CssBaseline />
            <Paper elevation={5} className={classes.chatWindow}>
                <div className={classes.chatWindowHeaderDiv}>
                    <div style={{ float: "right" }}>
                        <IconButton className={classes.chatWindowHeaderItem} onClick={() => setShowSetting(!isShowSetting)}>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton className={classes.chatWindowHeaderItem} onClick={handleClose} style={{ width: 22 }}>
                            <CloseIcon viewBox="0 0 16 16" />
                        </IconButton>
                    </div>
                </div>
                <div id="chatWindow" className={"customScrollBar " + classes.chatWindowDiv} style={chatWindowStyle}>
                    <Grid className={classes.chatInnerFrame} container alignItems="center">
                        {redrawChat}
                    </Grid>
                </div>

                <div className={classes.inputChatDiv} style={chatWindowStyle}>
                    <TextField
                        className={classes.inputChat}
                        id="outlined-multiline-flexible"
                        multiline
                        rowsMax="4"
                        rows="4"
                        value={inputChat}
                        onChange={handleChangeInputChat}
                        variant="standard"
                        placeholder={t("メッセージを入力")}
                        onKeyPress={chatKeyPress}
                        InputProps={{
                            classes: {
                                underline: classes.inputChatBorder,
                                inputMultiline: "customScrollBar",
                            }
                        }}
                    />
                </div>
                <IconButton onClick={handleClickSendButton} className={classes.sendButton} color="primary" aria-label="send chat">
                    <SendIcon />
                </IconButton>
            </Paper>
            <Paper hidden={!isShowSetting} style={{ position: "absolute", top: "50px", left: "25px", width: "300px", height: "300px", padding: "10px", overflow: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="body2" id="continuous-slider" gutterBottom>
                            {t("背景色の設定")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <IconButton onClick={() => { setShowSetting(false); }} style={{ padding: 0 }}>
                            <CloseIcon viewBox="0 0 16 16" style={{ width: 16, height: 16 }} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <FlipToBackIcon />
                    </Grid>
                    <Grid item xs>
                        <Slider value={backgroundAlpha} onChange={handleChangeBackgroundAlpha} max={100} min={0} aria-labelledby="alpha-slider" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <div className={classes.rgbIconDiv} style={{ backgroundColor: "red" }}></div>
                    </Grid>
                    <Grid item xs>
                        <Slider value={backgroundRed} onChange={handleChangeBackgroundRed} max={255} min={0} aria-labelledby="alpha-slider" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <div className={classes.rgbIconDiv} style={{ backgroundColor: "green" }}></div>
                    </Grid>
                    <Grid item xs>
                        <Slider value={backgroundGreen} onChange={handleChangeBackgroundGreen} max={255} min={0} aria-labelledby="alpha-slider" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <div className={classes.rgbIconDiv} style={{ backgroundColor: "blue" }}></div>
                    </Grid>
                    <Grid item xs>
                        <Slider value={backgroundBlue} onChange={handleChangeBackgroundBlue} max={255} min={0} aria-labelledby="alpha-slider" />
                    </Grid>
                </Grid>
                <Typography variant="body2" id="continuous-slider" gutterBottom>
                    {t("フォントサイズの設定")}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormatSizeIcon />
                    </Grid>
                    <Grid item xs>
                        <Slider value={fontSize} onChange={handleChangeFontSize} max={24} min={8} aria-labelledby="alpha-slider" />
                    </Grid>
                </Grid>
                <Typography variant="body2" id="continuous-slider" gutterBottom>
                    {t("送信キーの設定")}
                </Typography>
                <RadioGroup row name="send_key" defaultValue={ctrlEnter}>
                    <FormControlLabel value="Ctrl+Enter" control={<Radio color="primary" onChange={handleSelectSendKey} />} label="Ctrl+Enter" />
                    <FormControlLabel value="Enter" control={<Radio color="primary" onChange={handleSelectSendKey} />} label="Enter" />
                </RadioGroup>
            </Paper>
        </div>
    );
}

export const ChatComponent = forwardRef(ChatComponentFunction);
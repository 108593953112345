/**
 * 自習振り返りダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { SELECT_NONE } from "../common/Constants";
import BaseDialog from './BaseDialog';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, InputAdornment, LinearProgress, IconButton, Switch, createMuiTheme, Divider } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Utility } from '../common/Utility';
import JfsClient, { StudyPlan, StudyPlanDetail, StudyRatingMasterInfo } from '@fsi/jfs-sdk';

interface Props {
    handleMyStudyTimerState: (state: boolean, additionalTime: number) => void;
    handleGetStudyCount: () => number;
    handleGetStudyEnd: () => boolean;
    handleMyStudyTimerReset: () => void;
    handleChangeConcentrationMode: (flag: boolean) => void;
    openSelfStudyChangeDialog: (open: boolean) => void;
    openSelfStudyCompleteDialog: (open: boolean) => void;
}


class timerState {
    checked : boolean = false;
    errorMsgFlag : boolean = false;
    time : number = 0;
}

class ratingState {
    name : string = '';
    selected : boolean = false;
}
export interface ChildHandler {
    openSelfStudyLookBackDialog: (open : boolean) => void;
    changeLimitOver: (change : boolean) => void;
}

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFc1A2",
        },
    },
})

const SelfStudyFinishDialog: ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const [isShowSelfStudyLookBackDialog, setShowSelfStudyLookBackDialog] = React.useState(false);
    const [selfStudyComment, setSelfStudyComment] = React.useState('');
    const [totalStudyTime, setTotalStudyTime] = React.useState(0);
    const [timer, setTimer] = React.useState(new timerState());
    const [studyPlanList, setStudyPlanList] = React.useState([] as StudyPlanDetail[]);
    const [ratingList, setRatingList] = React.useState([] as ratingState[]);
    const [stopDate, setStopDate] = React.useState(0);
    const [startDate, setStartDate] = React.useState(0);
    const [startDateCheck, setStartDateCheck] = React.useState(false);
    //const [reStartDate, setReStartDate] = React.useState(new Date());
    //const [breakTime, setBreakTime] = React.useState(0);
    const [timeLeft, setTimeLeft] = React.useState(0);
    const [dbTimerSwitch, setDbTimerSwitch] = React.useState(false);
    const [limitOver, setLimitOver] = React.useState(false); // 状況確認ダイアログ表示中にタイマーが切れたかどうか
    // ダイアログを開いた時間
    const [openDialogDate, setOpenDialogDate] = React.useState(new Date());
    // count
    const [count, setCount] = React.useState(0);

    const MAX_TEXT_LENGTH = 255;

    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient } = jfsClient;

    useImperativeHandle(ref, () => ({
        openSelfStudyLookBackDialog: (open : boolean) => {
            setShowSelfStudyLookBackDialog(open);
        },
        changeLimitOver: (change : boolean) => {
            setLimitOver(change);
        }
    }))

    /**
     * 振り返り入力ダイアログが開いた際にDBにアクセスし、本日の自習内容のリスト,設定可能な自習計画評価を取得する
     */
    useEffect(() => {
        if(!isShowSelfStudyLookBackDialog){
            return;
        }
        
        getStudyPlanList();
        getRatingList();
        setOpenDialogDate(new Date());
        // タイマーを一時停止
        props.handleMyStudyTimerState(false, 0);
        setCount(props.handleGetStudyCount());

    }, [isShowSelfStudyLookBackDialog])

    // 未完了の自習計画を取ってくる処理(async)
    const getStudyPlanList = async() => {
        await httpClient.getStudyPlan(sessionStorage.getItem("TABID") as string)
        .then((e: StudyPlanDetail[]) => {
            setStudyPlanList(e);
        }).catch(err => {
            console.log(err);
        });
    }

    // 自習科目を取ってくる処理(async)
    const getRatingList = async () => {
        await httpClient.getStudyRatingMaster(sessionStorage.getItem("TABID") as string)
            .then((e: StudyRatingMasterInfo[]) => {
                for (let i = 0; i < e.length; i++) {
                    setRatingList((prevState) => [...prevState, { name: e[i].ratingText, selected: false }]);
                }
            }).catch(err => {
                console.log(err);
            });
    }

    /*
    * 全角・半角を考慮した文字数チェック
     */
    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')
 
        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }
 
        return len
    };

    const judgeButtonSize = (targetButtonList: {name: string, selected: boolean}[]) => {
        let maxLength = 0;
        for(let i=0;i<targetButtonList.length;i++){
            if(maxLength < targetButtonList[i].name.length){
                maxLength = targetButtonList[i].name.length;
            }
        }

        return maxLength;
    }

    const rateButtonWidth = useMemo(() => {
        if(ratingList.length > 0){
            return judgeButtonSize(ratingList)*17;
        }
    }, [ratingList])

    /*
    * 振り返り－目標達成度についてのエラーメッセージを出力
    */
    const rateRequired = () => {
        let selectedRateIndex = ratingList.findIndex(e => e.selected === true);
        if(selectedRateIndex === -1){
            return (
                <label style={{ marginLeft: '20px', color:"red" }}>※どれか１つ選択してください。 </label>
            );
        }
    };

    /*
    * テキストボックスについてのエラーメッセージを出力
    */
    const textLength = (maxLength: number, inputText: string, distinctionHz: boolean = false, required: boolean = false) => {
        /* let len = judgehz(inputText) */
        let len = 0
        if(distinctionHz) {
            len = judgehz(inputText)
        } else {
            len = inputText.length
        }
        if( maxLength < len ){
            return (
                <div style={{ marginTop: "6px", color:"red" }}>入力した文字列が長すぎます。 </div>
            );
        }

        if( required && len === 0){
            return (
                <div style={{ marginTop: "6px", color:"red" }}>入力必須です。 </div>
            );
        }
    };

    /**
     * 「今日を振り返って一言」のテキストフィールドをクリアする
     */
    const onClearSelfStudyComment = useCallback(() => {
        setSelfStudyComment('');
    }, [selfStudyComment]);

    /*
    * テキストボックス用のハンドラ
    */
    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if(event.target.name === 'selfStudyComment'){
            setSelfStudyComment(event.target.value);
        }
    }


    /**
     * 自習評価ボタンクリック用
     */
    const handleClickRateButton = (name : string) => {

        setRatingList((preveState) => 
            preveState.map((obj) => (obj.name === name ? {name: obj.name, selected: true} : {name: obj.name, selected: false })));
    }

    // 振り返りダイアログのキャンセル
    const handleClickLookBackCancel = () => {
        setSelfStudyComment('');
        setRatingList([] as ratingState[]);
        if(props.handleGetStudyEnd()){ // タイマーの終了フラグでキャンセル時の挙動を制御
            // console.info("Finishダイアログキャンセル(タイマーは終了している):"+props.handleGetStudyEnd());
            props.openSelfStudyChangeDialog(true); // タイマーが終了しているので、自習変更ダイアログ表示
        }else{
            // console.info("Finishダイアログキャンセル(タイマーは終了していない):"+props.handleGetStudyEnd());
            props.handleMyStudyTimerState(true, 0); // タイマーを再開
        }
        setShowSelfStudyLookBackDialog(false);
    }

    // 振り返り入力ダイアログにおけるDBへの登録
    const handleClickLookBackButton = async () => {
        // 今日の目標の入力エラー対策
        if(selfStudyComment === null || selfStudyComment === undefined || selfStudyComment === ''){
            return;
        }

        let len = judgehz(selfStudyComment);
        if(len > MAX_TEXT_LENGTH){
            return;
        }

        // 自習評価の入力エラー対策
        let selectedRateIndex = ratingList.findIndex(e => e.selected === true);
        if(selectedRateIndex === -1){
            return;
        }


        setShowSelfStudyLookBackDialog(false);
        let totalStudyMinutes = 0;
        studyPlanList.map((plan) => {
            if (plan.studyMinutes !== null) {
                totalStudyMinutes += plan.studyMinutes;
            }
        })

        await httpClient.updateStudyPlan(sessionStorage.getItem("TABID") as string, (Math.floor(count / 60) - totalStudyMinutes), openDialogDate.getTime())
            .then(async (e: String) => {
                await httpClient.addStudyRating(sessionStorage.getItem("TABID") as string, studyPlanList[0].studyPlan.id, ratingList[selectedRateIndex].name, selfStudyComment)
                    .then((e: String) => {
                        // タイマーをストップ
                        props.handleMyStudyTimerReset();

                        // 科目・ジャンル・教材などの初期化
                        setSelfStudyComment('');
                        setTimer(new timerState());
                        setRatingList([] as ratingState[]);
                        setLimitOver(false);
                        setStartDate(0);
                        setStopDate(0);
                        setTimeLeft(0);

                        // 集中モードをリセット
                        props.handleChangeConcentrationMode(false);

                        // 自習完了ダイアログを開く
                        props.openSelfStudyCompleteDialog(true);

                    }).catch(err => {
                        console.log(err);
                    });

            }).catch(err => {
                console.log(err);
            });
    }

    /**
     * 「今日を振り返って一言」用のプログレスバー描画
     */
    const progressBarSelfStudyComment = useMemo(() => {
        let parsent = (judgehz(selfStudyComment)/MAX_TEXT_LENGTH) * 100;
        let progressColor = '#FFE1A2';
         
        // 入力文字数超過した場合はバーが赤色になる
        if(parsent > 100){
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        }else{
            progressColor = '#FFE1A2';
        }
         
        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength(MAX_TEXT_LENGTH, selfStudyComment, true, true)}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" } }>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress 
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...SELECT_NONE,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    },[selfStudyComment])

    /**
     * 自習評価ボタンの描画
     */
    const rateDraw  = useMemo(() => {
        return (
            <Grid container justify='center' alignItems='center' style={{paddingBottom: 15}}>
                <Grid item xs={12}>
                    <div style={{color: '#707070', paddingBottom: 5, marginBottom: 5}}>目標達成度{rateRequired()}</div>
                    <Grid container spacing={(rateButtonWidth !== undefined) && rateButtonWidth/22 >= 4 ? 2 : 1}>
                    {ratingList.map((rate) => {
                        return(rate.selected ?
                            <Grid item>
                                <Button color="primary" onClick={() => handleClickRateButton(rate.name) } style={{backgroundColor: '#006FBC', color: '#FFFFFF', border: '3px solid #006FBC', borderRadius: '31px', width: rateButtonWidth }}>{rate.name}</Button>
                            </Grid>
                            :
                            <Grid item>
                                <Button color='primary' onClick={() => handleClickRateButton(rate.name) } style={{ pointerEvents: 'auto', color: '#676767', border: '3px solid #A7A7A7', borderRadius: '31px', width: rateButtonWidth }}>{rate.name}</Button>
                            </Grid>
                        )
                    })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [ratingList])

    const drawAccomplishedToday = useMemo(() => {
        const date = new Date();
        let totalStudyMinutes = 0;
        studyPlanList.map((plan) => {
            if (plan.studyMinutes !== null) {
                totalStudyMinutes += plan.studyMinutes;
            }
        })
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div style={{color: '#707070', fontWeight: 'bold', fontSize: 16}}>今回の自習内容</div>
                </Grid>
                <Grid item xs={12} style={{marginLeft: -20, marginTop: 20}}>
                    <Grid container style={{paddingBottom: 20}}>
                        <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>時間</Grid>
                        <Grid item xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>科目</Grid>
                        <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>内容</Grid>
                        <Grid item xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>目標時間</Grid>
                        <Grid item xs={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>実績時間</Grid>
                    </Grid>
                    {studyPlanList.map((plan, index) => {
                        let totalTimerMinutes = 0;
                        studyPlanList.map((plan2, index2) => {
                            if (plan2.studyMinutes !== null && index2 < index) {
                                totalTimerMinutes += plan2.studyMinutes;
                            }
                        })
                        return (
                            <>
                                <Grid container style={{ paddingBottom: 10 }}>
                                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{('00' + new Date(plan.startDate).getHours()).slice(-2)}:{('00' + new Date(plan.startDate).getMinutes()).slice(-2)}~{plan.endDate != null ? ('00' + new Date(plan.endDate).getHours()).slice(-2) : ('00' + date.getHours()).slice(-2)}:{plan.endDate != null ? ('00' + new Date(plan.endDate).getMinutes()).slice(-2) : ('00' + date.getMinutes()).slice(-2)}</Grid>
                                    <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{plan.studySubjectMaster.subjectName}</Grid>
                                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{plan.studyPurposeMaster.purposeName}</Grid>
                                    {plan.timerMinutes !== 0 ?
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{plan.timerMinutes}分</Grid>
                                        :
                                        <Grid item xs={2} />
                                    }
                                    {plan.studyMinutes !== null ?
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{plan.studyMinutes}分</Grid>
                                        :
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{Math.floor(count / 60) - totalStudyMinutes}分</Grid>
                                    }
                                </Grid>
                                {plan.studySubjectMaster.subjectName === 'その他' || plan.studyPurposeMaster.purposeName === 'その他' ?
                                    <Grid container style={{ marginTop: -10 }}>
                                        <Grid item xs={3} />
                                        {plan.subjectName !== '' ?
                                            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>({plan.subjectName})</Grid>
                                            :
                                            <Grid item xs={2} />
                                        }
                                        {plan.purposeName !== '' ?
                                            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>({plan.purposeName})</Grid>
                                            :
                                            <Grid item xs={3} />
                                        }
                                        <Grid item xs={2} />
                                        <Grid item xs={2} />
                                    </Grid>
                                    :
                                    <></>
                                }
                            </>
                        )
                    })}
                </Grid>
                <Grid item xs={12} style={{marginTop: 5}}>
                    <hr></hr>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 5}}>
                    <Grid container>
                        <Grid item xs={9}></Grid>
                        <Grid item xs={2}>合計</Grid>
                        <Grid item xs={1}>{Math.floor(count / 60)}分</Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }, [studyPlanList, totalStudyTime])

    const drawSelfStudyLookBackDialog = useMemo(() => {
        return (
            <BaseDialog
                open={isShowSelfStudyLookBackDialog}
                aria-labelledby="selfstudylookback-dialog-title"
                aria-describedby="selfstudylookback-description"
                fullWidth={true}
                maxWidth={'lg'}
                PaperProps={{
                    style:{
                        border: '5px solid #57BBFF',
                        borderRadius: '25px',
                    }
                }}
            >
                <DialogTitle id="selfstudystart-dialog-title" style={{ userSelect: 'none', background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>自習お疲れさまでした</DialogTitle>
                <DialogContent style={{marginLeft: 10, paddingTop: 12, paddingBottom: 12}}>
                    <Grid container>
                        <Grid item xs={6} style={{paddingRight: 20}}>
                            <Grid container justify='center' alignItems='center' style={{paddingBottom: 20}}>
                                <Grid item xs={12}>
                                    <div style={{ fontSize: 16 }}>お疲れさまでした。<br />自習のふり返りを登録してください。</div>
                                </Grid>
                            </Grid>
                            {drawAccomplishedToday}
                        </Grid>
                        {Utility.getType() === 2 ? "" :
                            <Divider orientation='vertical' flexItem style={{marginTop: 12}}></Divider>
                        }
                        <Grid item xs={5} style={{marginLeft: 20}}>
                            <Grid container style={{paddingBottom: 3}}>
                                <Grid item xs={12}>
                                    <div style={{ color: '#707070', fontWeight: 'bold', fontSize: 16 }}>今回の自習のふり返り</div>
                                </Grid>
                            </Grid>
                            {rateDraw}
                            <Grid container justify='center' alignItems='center' style={{paddingBottom: 15, marginBottom: 5}}>
                                <Grid item xs={12}>
                                    <div style={{marginTop: 20, marginBottom: -10, color: '#707070'}}>今回の自習をふり返って一言</div>
                                    {progressBarSelfStudyComment}
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="selfStudyComment"
                                        name="selfStudyComment"
                                        //label="今日を振り返って一言"
                                        variant={'outlined'}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        InputLabelProps={{shrink: true}}
                                        onChange={handleTextFieldChange}
                                        value={selfStudyComment}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={onClearSelfStudyComment}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleClickLookBackButton()} style={{backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 110, marginBottom: 20}}>登録する</Button>
                    <Button color='primary' onClick={() => handleClickLookBackCancel() } style={{ pointerEvents: 'auto', color: '#676767' ,border: '3px solid #A7A7A7', borderRadius: '31px', width: 110, marginRight: 30, marginBottom: 20 }}>キャンセル</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [isShowSelfStudyLookBackDialog, timer, ratingList, selfStudyComment, limitOver, studyPlanList, totalStudyTime])

    return (
        <Fragment>
            {drawSelfStudyLookBackDialog}
        </Fragment>
    )
}

export const SelfStudyFinishDialogRef = forwardRef(SelfStudyFinishDialog);
import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import Typography from '@material-ui/core/Typography';
import { Utility } from '../common/Utility';
import JfsClient from '@fsi/jfs-sdk';

const useStyles = makeStyles(theme => ({
    rootDiv: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
    },
    signoutDiv: {
        position: 'relative',
        left: 'auto',
        right: 'auto',
        top: '25%',
        transform: 'translateY(-50%)',
    },
    signoutImgDiv: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 387,
        height: 261,
        // backgroundImage: `url(./api/service/picture/logout)`,
    },
    reSigninButton: {
        position: 'relative',
        left: 'auto',
        right: 'auto',
        top: '43%',
        transform: 'translateY(-50%)',
    },
}));

export default function Login() {
    const classes = useStyles();
    const { history } = useReactRouter();
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const handleReSignin = async () => {
        const signinpage = Utility.getSigninPage();
        history.push({
            pathname: signinpage
        });
    }

    return (
        <React.Fragment>
            <div className={classes.rootDiv}>
                <div className={classes.signoutDiv}>
                    <Typography gutterBottom variant="h4" component="h2" style={{ position: 'relative', top: '30%', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }}>ログアウトしました</Typography>
                </div>
                <div className={classes.reSigninButton}>
                    <Button variant="contained" color='primary' size="large" onClick={handleReSignin} style={{ textTransform: 'none', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', }}>ログイン</Button>
                </div>
                <div className={classes.signoutImgDiv} 
                    style={{
                        // backgroundImage: `url(${httpClient.})`,
                    }}
                >
                </div>
            </div>
        </React.Fragment>
    );
}

/**
 * FloorでWebSocketが切断されたときに表示するダイアログ
 * 切断された事をダイアログで表示して、閉じるとTopに遷移させる
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from '../BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Utility } from '../../common/Utility';

interface Props {
}

export interface DisconnectDialogHandler {
    open: () => void;
    close: () => void;
    checkOpen: () => boolean;
    getOpenStartTime: () => number;
}

const DisconnectDialogComponent: ForwardRefRenderFunction<DisconnectDialogHandler, Props> = (props, ref) => {
    const { history } = useReactRouter();
    const [open, setOpen] = React.useState(false);
    const [t, i18n] = useTranslation();
    const [lang] = useState('ja');
    const [openStartTime, setOpenStartTime] = React.useState(0);

    useImperativeHandle(ref, () => ({
        open: () => {
            if(open === false) {
                setOpenStartTime(new Date().getTime());
            }
            //サーバから切断されたので、サインアウトした状態にする
            sessionStorage.setItem("ISLOGIN", "false");
            setOpen(true);
        },
        close: () => {
            traceLog("[WebSocketTrace] close request open=["+open+"]");
            if(open === true) {
                //自動再接続に成功したので、サインインした状態に戻す
                sessionStorage.setItem("ISLOGIN", "true");
                setOpen(false);
                setOpenStartTime(0);
            }
        },
        checkOpen: () => {
            traceLog("[WebSocketTrace] checkOpen request open=["+open+"]");
            return open;
        },
        getOpenStartTime: () => {
            traceLog("[WebSocketTrace] getOpenStartTime request time=["+openStartTime+"]");
            return openStartTime;
        },
    }))

    /**
     *  トレースログ
     * 
     */
     const traceLog = (msg: string) => {
        let now = new Date().toTimeString();
        console.log(now+" "+msg);
    };

    const handleClose = () => {
        traceLog("[WebSocketTrace] close button open=["+open+"]");
        setOpen(false);
        const signinpage = Utility.getSigninPage();
        history.push({
            pathname: signinpage
        });
    };

    const draw = useMemo(() => {
        return (
            <BaseDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="disconnect-dialog-title"
                aria-describedby="disconnect-dialog-description"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle style={{ backgroundColor: "#ef6c00", color: "white" }} id="disconnect-dialog-title">{t("通信エラーが発生しました")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="disconnect-dialog-description">
                        <Typography variant="body2">{t("電波状況が悪いか、お使いのパソコン端末の画面がオフになった可能性があります。")}</Typography>
                        <Typography variant="body2">{t("電波環境を確認して、もう一度ログインしてください。")}</Typography>
                        <Typography variant="body2">{t("　")}</Typography>
                        <Typography variant="body2">{t("それでもこの画面が表示される場合は、次のことをお確かめください。")}</Typography>
                        <Typography variant="body2">{t("　・FAMcampus以外の利用していないアプリケーションを終了する。")}</Typography>
                        <Typography variant="body2">{t("　・FAMcampusを利用するときは、画面がオフにならないようにする")}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {"ログイン画面に戻る"}
                    </Button>
                </DialogActions>
            </BaseDialog>
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const DisconnectDialog = forwardRef(DisconnectDialogComponent);
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Theme } from '@material-ui/core';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { TenantInfo, Floor } from '../common/JsonClass';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            marginLeft: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        MuiInputBase: {
            padding: 0
        },
    }),
);

interface Props {
    masterData: {[index: string]:[{id:number, name:string}]};
}

export interface SystemOfficeInfoHandler {
    open: (id: number) => void;
}

// export default function OtherUsers() {
const SystemOfficeInfoComponent: ForwardRefRenderFunction<SystemOfficeInfoHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const { history } = useReactRouter();
    const [open, setOpen] = useState(false);
    const [selectFloorId, setSelectFloorId] = useState(0);
    const [tenantList, setTenantList] = React.useState([] as TenantInfo[]);
    const [floorInfo, setFloorInfo] = React.useState(new Floor());
    const [ state, setState ] = useState({
        tenantId: '',
        officeName: '',
    });

    // テナント追加の入力データをクリア
    const clearState = () => {
        setState({...state, 
            tenantId: "", 
            officeName: "", 
        })
    }

    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            // alert("id:" + id.toString())
            setSelectFloorId(id);
            handleGetTenantName();
            setOpen(true);
        },
    }))

    useEffect(() => {
        handleGetTenantName();
    },[])

    // セレクトボックス用のテナント名取得
    const handleGetTenantName = () => {
        axios
            .create({
                withCredentials: true,
            })
            .post('/api/system/tenant/list', new URLSearchParams({
                tab_id: sessionStorage.getItem("TABID") as string,
                keyword: "%%" as string,
            }))
            .then((response: AxiosResponse) => {
                let result = response.data as TenantInfo[];
                setTenantList(result);
            })
            .catch((err) => {
                if (err.response.status === 403) { // 403 = Forbidden
                    axios.post('/api/system/logout').finally(() => { history.push("/signout"); });
                } else {
                    console.log("TenantSearch error.");
                    console.log(err.response);
                }
            });
    }

    const handleClose = () => {
        clearState();
        setSelectFloorId(0);
        setOpen(false);
    };

    // オフィス登録処理
    const handleOfficeInfo = () => {

        if(state.officeName !== ""){
            if(window.confirm("オフィス「" + state.officeName + "」 を登録します。よろしいですか？")){
                // 重複チェック
                axios
                .create({
                    withCredentials: true,
                })
                .post('/api/system/office/exist', new URLSearchParams({
                    tab_id: sessionStorage.getItem("TABID") as string,
                    tenantId: state.tenantId.toString().trimEnd(),
                    officeName: state.officeName.toString().trimEnd(),
                }))
                .then((response: AxiosResponse) => {
                    let result = response.data as number;
                    // alert("result:" + result);
                    if(result === 0){
                        axios
                        .create({
                            withCredentials: true,
                        })
                        .post('/api/system/office/add', new URLSearchParams({
                            tab_id: sessionStorage.getItem("TABID") as string,
                            tenantId: state.tenantId.toString().trimEnd(),
                            officeName: state.officeName.toString().trimEnd(),
                        }))
                        .then((response: AxiosResponse) => {
                            let msg = response.data as string;
                            // alert("result:" + msg);
                            if (msg.startsWith("ERR") || msg === "NG") {
                                alert("オフィス " + state.officeName + " の登録に失敗しました。");
                            } else {
                                alert("オフィス " + state.officeName + " の登録が完了しました。");
                            }
                        })
                        .catch(() => {
                            alert("オフィス " + state.officeName + " の登録に失敗しました。");
                        });
                    }else{
                        alert("そのオフィス名は既に登録されているため登録できません。");
                        return;
                    }
                })
                .catch(() => {
                    alert("オフィスの登録に失敗しました。");
                    setSelectFloorId(0);
                    setOpen(false);
                });
            }else{
                alert("キャンセルしました。");
            }
        }else{
            alert("入力必須項目が未入力のため、登録できません。");
            return;
        }
        clearState();
        setSelectFloorId(0);
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const target = event.target;
        const name = target.name;
        //alert(name + ":" + target.value);
        setState(() => {
            return {...state, [name]: target.value };
        });
     }

    const handleChangeSelect = React.useCallback((value: any):void => {
        // alert("target " + value.target.name + ":" +value.target.value);
        setState(() => {
            return {...state, [value.target.name]: value.target.value };
        });
    }, [state]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: "dodgerblue", color: "white" }}>オフィス情報</DialogTitle>
                <DialogContent style={{ padding: "20px 20px" }}>
                    <Box fontSize="18px"><b>オフィス情報入力</b></Box>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">テナント名</InputLabel>
                        <Select
                            autoFocus
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="tenantId"
                            onChange={handleChangeSelect}
                            label="Age"
                            input={<OutlinedInput margin='dense' aria-label={"Age"} label={"テナント名"} color="primary" />}
                            style={{ width: 400 }}
                            value={state.tenantId}
                        >
                            {tenantList.map((tenant) => (
                                <MenuItem value={tenant.id}>{tenant.tenantName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br></br>
                    <TextField
                        margin="dense"
                        id="officeName"
                        name="officeName"
                        label="オフィス名"
                        variant={'outlined'}
                        style={{ width: 400 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        value={state.officeName}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">キャンセル</Button>
                    <Button id="submit" onClick={handleOfficeInfo} color="primary">　登録　</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export const SystemOfficeInfo = forwardRef(SystemOfficeInfoComponent);
import { useEffect, useMemo } from 'react';
import { useSpring, animated } from 'react-spring'

interface Props {
    isAnimate: boolean;
    width?: number | string;
}

export default function Circle002(props: Props) {
    const { isAnimate, width } = props;

    useEffect(() => {
        if (isAnimate) {
            setCssProps({
                from: {
                    opacity: 0.7,
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnimate]);

    // useSpringで変化させたいstyleを定義
    const [cssProps, setCssProps] = useSpring(() => ({
        opacity: 0,
        reset: true,
    }));

    // interpolateの起点を定義
    const { origin } = useSpring({
        origin: 1,
        from: { origin: 0 },
        config: {
            mass: 1,
            tension: 1000,
            friction: 20,
        },
        reset: true,
    });

    const component = useMemo(() =>
        <animated.div
            className='effect-circle-002'
            style={{
                userSelect: 'none',
                pointerEvents: 'none',
                boxSizing: 'border-box',
                border: isAnimate ? '2px solid #EEE' : 'none',
                borderRadius: '50%',
                visibility: isAnimate ? 'visible' : 'hidden',
                width: isAnimate ? (width || 43) : 0,
                height: isAnimate ? (width || 43) : 0,
                ...cssProps,
                transform:
                    origin.interpolate({
                        range: [0, 0.1, 1.0],
                        output: [1, 1.1, 2.0]
                    }).interpolate(origin => `scale(${origin})`),
            }}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isAnimate, width])

    return component;
};


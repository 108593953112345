import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Divider, FormLabel, Grid, Radio, RadioGroup, OutlinedInput, Slide, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BaseDialog from '../user/BaseDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import { userListConst } from './UserListConst';
import axios, { AxiosResponse } from 'axios';
import { red } from '@material-ui/core/colors';
import useReactRouter from 'use-react-router';
import { printable } from '../log/LogSetting'
// import { AlertWebRtcSize, AlertWebRtcSizeHandler } from '../user/AlertWebRtcSize';
import WhiteList from './WhiteList';
import AdminMessage from './AdminMessage';
import OtherWebsiteSetting from "./OtherWebsiteSetting";
import ForceMute from './ForceMute';
import Clapping from './Clapping';
import TenantSettingPage from './TenantSettingPage';
import { VpnKey } from '@material-ui/icons';
// import { Floor } from '../common/JsonClass';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import Update from '@material-ui/icons/Update';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccessTimeIcon from '@material-ui/icons/AccessAlarm';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ImportExport from '@material-ui/icons/ImportExport';
import Equalizer from '@material-ui/icons/Equalizer';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon  from '@material-ui/icons/Settings';
import ChimeSetting from './ChimeSetting';
import TimeManagement from './OpenTimeManagement';
import ZoomUrlSetting from './ZoomUrlSetting';
import PointAutoSetting from './PointAutoSetting';
import PointManager from './PointManager';
import ContractInfo from './ContractInfo';
import AttendanceStatusCheck from './AttendanceStatusCheck';
import JfsClient, { Floor, TenantRegistraionResponse, ResEnabledMenu, CsvDownloadResponse } from "@fsi/jfs-sdk";
import LaunchIcon from '@material-ui/icons/Launch';
import { TenantLauncher, TenantLauncherHandler } from './TenantLauncher';
import { FloorList, FloorListHandler } from './FloorList';

registerLocale('ja', ja);

if (printable === 0) {
  console.log = function () { }
  console.warn = function () { }
  console.error = function () { }
}

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    //    flexShrink: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  tagButton: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-start',
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  search: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,//`calc(100%)`,// - ${drawerWidth})`,
    justifyContent: 'center',
  },
  table: {
    maxHeight: `calc(100vh - 60px)`
    //    minWidth: 1000,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
  tagKey: {
    width: 100,
  },
  tagValue: {
    width: 280,
  },
  tagOrder: {
    width: 70,
  },
  addressName: {
    width: 100,
  },
  addressValue: {
    width: 350,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  card: {
    width: 150,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  attendance: {
    //    flexShrink: 0,
    marginTop: '-10px',
    height: 360,
    width: 600,
    // height: '100%',
    // width: `100%`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  picker: {
    width: 300,
    height: 40,
    fontSize: 20,
    textAlign: 'center',
    margin: 10,
    marginLeft: 0,
  },
  grid: {
    margin: "auto",
  },
  header: {
    tableLayout:'fixed',
  }
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

class ConfirmInfo {
  open: boolean = true;
  message: string = "";
  isOk: boolean = false;

  constructor(init: Partial<ConfirmInfo>) {
    Object.assign(this, init);
  }
}
class FinishInfo {
  open: boolean = true;
  message: string = "";
  password: null | string = null;

  constructor(init: Partial<FinishInfo>) {
    Object.assign(this, init);
  }
}

function dateToFormatString(date: Date, format: string) {

  format = format.replace(/YYYY/, date.getFullYear().toString());
  format = format.replace(/MM/, (date.getMonth() + 1).toString());
  format = format.replace(/DD/, date.getDate().toString());

  return format;
}

export default function UserList() {
  const { history } = useReactRouter();
  const historyState = history.location.state as any;
  const role = historyState?.role;
  const [authType, setAuthType] = React.useState(0);
  const classes = useStyles();
  /** mainコンテンツ切替 */
  const [isShowWhiteList, setIsShowWhiteList] = React.useState(false);
  const [isShowAdminMessage, setIsShowAdminMessage] = React.useState(false);
  const [isShowClapping, setIsShowClapping] = React.useState(false);
  const [isShowOtherWebsiteSetting, setIsShowOtherWebsiteSetting] =
    React.useState(false);
  const [isShowForceMute, setIsShowForceMute] = React.useState(false);
  const [isShowMenuWebSiteViewer, setIsShowMenuWebSiteViewer] = React.useState(false);
  const [isShowMenuAdminmessage, setIsShowMenuAdminmessage] = React.useState(false);
  const [isShowMenuForceMute, setIsShowMenuForceMute] = React.useState(false);
  const [isShowMenuClap, setIsShowMenuClap] = React.useState(false);
  const [isShowMenuAuth, setIsShowMenuAuth] = React.useState(false);

  const [isShowAuthSetting, setIsShowAuthSetting] = React.useState(false);
  const [isShowChimeSetting, setIsShowChimeSetting] = React.useState(false);
  const [isShowTimeManagement, setIsShowTimeManagement] = React.useState(false);
  const [isShowZoomUrlSetting, setIsShowZoomUrlSetting] = React.useState(false);
  const [isShowPointAutoSetting, setIsShowPointAutoSetting] = React.useState(false);
  const [isShowPointManager, setIsShowPointManager] = React.useState(false);
  const [isShowContractInfo, setIsShowContractInfo] = React.useState(false);
  const [isShowAttendanceStatusCheck, setIsShowAttendanceStatusCheck] = React.useState(true);
  const [isShowMenuToolLauncher, setIsShowMenuToolLauncher] = React.useState(false);
  const [isShowFloorSetting, setIsShowFloorSetting] = React.useState(false);

  useEffect(() => {
    getEnabledMenu(sessionStorage.getItem("TABID") as string);
  }, []);

  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });
  const clearErrorData = () => {
    errorData.code = '';
    errorData.message = '';
    if (errorData.errorDetail === null || errorData.errorDetail === undefined) {
      errorData.errorDetail = [];
    } else {
      errorData.errorDetail.length = 0;
    }
    return errorData;
  }

  const jfsClient = JfsClient.getInstance();
  const { httpClient, JFS_ERRORS } = jfsClient;

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);

  const [dealType, setDealType] = React.useState(userListConst.upload.div);
  const [showBackdropForDowndating, setShowBackdropForDowndating] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [showBackdropForUpdatingList, setShowBackdropForUpdatingList] = React.useState(false);
  // const alertWebRtcSizeRef = useRef({} as AlertWebRtcSizeHandler);

  /** 画面初期表示時処理 */
  useEffect(() => {
    // alertWebRtcSizeRef.current.checkWebRtcSize();
    getTenantRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 一括登録・更新処理のドロップダウンリストの初期値を設定 */
  useEffect(() => {
    if(authType === 0){
      setDealType(userListConst.upload.div);
    } else if(authType === 1){
      setDealType(userListConst.upload.div2);
    }
  }, [authType])

  /**
   * 使用状況ダウンロード
   */
  const [startDate, setStartDate] = useState(new Date());
  const [floorList, setFloorList] = React.useState([] as Floor[]);
  const [floor, setFloor] = useState("");
  const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
  const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
  const [state, setState] = useState({
    selectFloor: '',
    selectDate: new Date,
    releaseDate: new Date,
    selectCSV: 'attendance',
  });

  const [reportCSVList, setReportCSVList] = React.useState(false);
  const [attendanceCSV, setAttendanceCSV] = React.useState(false);
  const [dispSelectFloor, setDispSelectFloor] = React.useState('');
  const [disableSelectFloor, setDisableSelectFloor] = React.useState(false);
  const tenantLauncherRef = useRef({} as TenantLauncherHandler);
  const floorListRef = useRef({} as FloorListHandler);

  // 使用状況クリック時の初期処理
  useEffect(() => {
    handleGetFloorList();
  }, [attendanceCSV]);

  useEffect(() => {
    if (confirmInfo.isOk) {
      downloadUsageData();
    }
  }, [confirmInfo.isOk]);

  const clearState = () => {
    setState({
      ...state,
      selectFloor: '',
      selectDate: new Date(),
      selectCSV: 'attendance',
    })
    setDispSelectFloor('');
    setDisableSelectFloor(false);
    setStartDate(new Date());
    setFloor("");
  }
  // セレクトボックス用のフロア情報取得
  const handleGetFloorList = () => {
    // axios
    //   .create({
    //     withCredentials: true,
    //   })
    //   .post('/api/v1/admin/floorlist', new URLSearchParams({
    //     tab_id: sessionStorage.getItem("TABID") as string
    //   }))
    httpClient.getAdminFloorList(sessionStorage.getItem("TABID") as string)
      .then((response: Floor[]) => {
        // 全フロア選択用のselect追加
        let allFloors = [] as Floor[];
        let allFloor = new Floor;
        allFloor.id = 0;
        allFloor.floorName = 'すべて';
        allFloors.push(allFloor);

        //let result = response.data as Floor[];
        let result = response;
        // alert("floor count:" + result.length);
        if (result.length > 0) {
          allFloors = allFloors.concat(result);
          setFloorList(allFloors);
        }
      })
      .catch((err) => {
        console.log(err.response);
        return;
      });
  }

  // フロア変更・セット
  const handleChangeFloor = (value: any) => {
    setState(() => {
      return { ...state, ["selectFloor"]: value.target.value.toString() };
    });
  }

  // 日付変更・セット
  const handleChangeDate = (date: Date) => {
    setState(() => {
      return { ...state, ["selectDate"]: date };
    });
  }

  const handleRadioChange = (event: any) => {
    if (event.target.value === 'meet') {
      setState(() => {
        return { ...state, ["selectFloor"]: '' };
      });
      setFloor("");
      setDispSelectFloor('none');
      setDisableSelectFloor(true);
    } else {
      setDispSelectFloor('');
      setDisableSelectFloor(false);
    }
    setState(() => {
      return { ...state, ["selectCSV"]: event.target.value };
      // alert('date:' + state.selectCSV);
    });
  };

  const handleAttendanceReport = () => {
    setAttendanceCSV(true);
  }

  const renderReportCsvList = () => {
    let list = [];
    let tabId: string = sessionStorage.getItem("TABID") as string;
    let calcDate = new Date();
    // for test
    //calcDate = new Date("1/1/2022 0:0:0");
    let nYear: number = calcDate.getFullYear();
    let nMonth: number = calcDate.getMonth() + 1;
    let meet = "/api/admin/report/getMeetCsv?type=Meet&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
    let online = "/api/admin/report/getMeetCsv?type=Online&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
    list.push(<div> {nYear}年{nMonth}月　 <a href={meet} >ミートデータ</a>　 <a href={online} >サインインデータ</a>　</div>);
    for (let calc_mm = 0; calc_mm < 4; calc_mm++) {
      //let nowDate = new Date();
      //calcDate.setMonth(nowDate.getMonth() - calc_mm);
      calcDate.setMonth(calcDate.getMonth() - 1);
      nYear = calcDate.getFullYear();
      nMonth = calcDate.getMonth() + 1;
      console.log("calcDate  YY=[" + calcDate.getFullYear() + "] MM=[" + (calcDate.getMonth() + 1) + "]");
      let yearmon = nYear * 100 + nMonth;
      if (yearmon >= 202101) {
        meet = "/api/admin/report/getMeetCsv?type=Meet&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
        online = "/api/admin/report/getMeetCsv?type=Online&year=" + nYear + "&month=" + nMonth + "&tabid=" + tabId;
        list.push(<div> {nYear}年{nMonth}月　 <a href={meet} >ミートデータ</a>　 <a href={online} >サインインデータ</a>　</div>);
      }
    }

    const retval = <React.Fragment>{list}</React.Fragment>
    return retval;
  }
  const handleReprtCsvClose = () => {
    clearState();
    setReportCSVList(false);
    setAttendanceCSV(false);
  }

  // interface TenantRegistraionResponse {
  //     tenantId: number;
  //     registrationId: string;
  //     authType: number;
  // }

  /**
   * テナントレジストレーション取得
   */
   //const getTenantRegistration = async (): Promise<AxiosResponse<TenantRegistraionResponse>> => {
  const  getTenantRegistration = async () => {
    const tabId = sessionStorage.getItem("TABID") as string;
    try {
        // const res: AxiosResponse<TenantRegistraionResponse> = await axios.get(
        //     '/api/v1/admin/tenant/tenantRegistration',
        //     { params: { tabId } }
        // );
        const res: TenantRegistraionResponse = await httpClient.getTenantRegistration(tabId);
        setAuthType(res.authType);
        return res;
    } catch (err) {
        throw err;
    }
  }

  // interface ResEnabledMenu {
  //   menuValidWebsiteViewer: boolean;
  //   menuValidAdminMessage: boolean;
  //   menuValidForceMute: boolean;
  //   menuValidClap: boolean;
  // }

  /**
   * API Request
   */
  //const getEnabledMenu = async (tabId: string): Promise<AxiosResponse<ResEnabledMenu>> => {
  const getEnabledMenu = async (tabId: string) => {
    try {
      // const res: AxiosResponse<ResEnabledMenu> = await axios.get(
      //   '/api/v1/admin/tenant/menuvalid',
      //   { params: { tabId } }
      // );
      const res: ResEnabledMenu = await httpClient.getEnabledMenu(tabId);
      if (res) {
        setIsShowMenuWebSiteViewer(res.menuValidWebsiteViewer);
        setIsShowMenuAdminmessage(res.menuValidAdminMessage);
        setIsShowMenuForceMute(res.menuValidForceMute);
        setIsShowMenuClap(res.menuValidClap);
        setIsShowMenuToolLauncher(res.menuValidToolLauncher);
        setIsShowMenuAuth(false);
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  const getFileName = (contentDisposition: string) => {
    let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
      contentDisposition.length
    );
    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
  }

  const handleBackToTop = () => {
    history.goBack();
  }

  const handleWhiteList = (isShow: boolean) => {
    setIsShowWhiteList(isShow);
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleForceMute();
    toggleClapping();
  	toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const handleClapping = (isShow: boolean) => {
    setIsShowClapping(isShow);
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleForceMute();
  	toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const handleOtherWebsiteSetting = (isShow: boolean) => {
    setIsShowOtherWebsiteSetting(isShow);
    toggleWhiteList();
    toggleAdminMessage();
    toggleForceMute();
    toggleClapping();
	  toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  };

  const handleAdminMessage = (isShow: boolean) => {
    setIsShowAdminMessage(isShow);
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleForceMute();
    toggleClapping();
	  toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const handleForceMute = (isShow: boolean) => {
    setIsShowForceMute(isShow);
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleClapping();
	  toggleAuthSetting();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const toggleWhiteList = () => {
    setIsShowWhiteList(false);
  }

  const toggleClapping = () => {
    isShowClapping && setIsShowClapping(false);
  }

  const toggleOtherWebsiteSetting = () => {
    isShowOtherWebsiteSetting && setIsShowOtherWebsiteSetting(false);
  };
  const toggleAdminMessage = () => {
    isShowAdminMessage && setIsShowAdminMessage(false);
  }

  const toggleForceMute = () => {
    isShowForceMute && setIsShowForceMute(false);
  }

  const handleAuthSetting = (isShow: boolean) => {
    setIsShowAuthSetting(isShow);
    toggleForceMute();
    toggleWhiteList();
    toggleOtherWebsiteSetting();
    toggleAdminMessage();
    toggleClapping();
    setIsShowChimeSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const toggleAuthSetting = () => {
    setIsShowAuthSetting(false);
  }

  const handleTenantLauncher = () => {
    tenantLauncherRef.current.open();
  }

  const onDownload = () => {
    let csvName = "";
    if (state.selectCSV === "attendance") {
      csvName = '入退データ'
    } else if (state.selectCSV === "meet") {
      csvName = 'ビデオ通話データ'
    } else if (state.selectCSV === "chat") {
      csvName = 'チャットログデータ'
    } else if (state.selectCSV === "study") {
      csvName = '自習データ'
    }
    setConfirmInfo(new ConfirmInfo({
      message: csvName + "を出力します。よろしいですか？"
    }));
  }

  const downloadUsageData = () => {
    if (state.selectCSV === 'meet' || state.selectFloor !== '') {

      let year = state.selectDate.getFullYear();
      let month = state.selectDate.getMonth() + 1;
      let date = 1;
      let paramDate = year + '/' + month + '/' + date;

      let currentdate = new Date();
      currentdate.setMonth(currentdate.getMonth() + 1);
      currentdate.setDate(1);
      currentdate.setHours(0);
      currentdate.setMinutes(0);
      currentdate.setSeconds(0);
      let permitdate = new Date();
      permitdate.setFullYear(permitdate.getFullYear() - 2);
      permitdate.setDate(1);
      permitdate.setHours(0);
      permitdate.setMinutes(0);
      permitdate.setSeconds(0);

      let releaseYear = state.releaseDate.getFullYear();
      let releaseMonth = state.releaseDate.getMonth() + 1;
      let releaseDate = state.releaseDate.getDate();
      let strReleaseDate = releaseYear + '/' + releaseMonth + '/' + releaseDate;

      if (permitdate > state.selectDate || currentdate <= state.selectDate) {
        setFinishInfo(new FinishInfo({
          message: "対象年月は" + permitdate.getFullYear() + "年" + (permitdate.getMonth()+1) + "月～現在（過去２年）で指定してください。"
        }));
      } else {
        let csvName = "";
        if (state.selectCSV === "attendance") {
          csvName = '入退データ'
        } else if (state.selectCSV === "meet") {
          csvName = 'ビデオ通話データ'
        } else if (state.selectCSV === "chat") {
          csvName = 'チャットログデータ'
        } else if (state.selectCSV === "study") {
          csvName = '自習データ'
        }
        // if(window.confirm(csvName + "を出力します。よろしいですか？")){
        setShowBackdropForDowndating(true);
        if (state.selectCSV === "attendance") {
          // axios
          //   .post('/api/admin/attendance/output', new URLSearchParams({
          //     tab_id: sessionStorage.getItem("TABID") as string,
          //     floorId: state.selectFloor as string,
          //     startMonth: paramDate,
          //   }))
          httpClient.downloadUsageData(
            sessionStorage.getItem("TABID") as string,
            state.selectFloor,
            paramDate,
            // "",
            strReleaseDate
          )
            .then((e: CsvDownloadResponse) => {
              //レスポンスヘッダからファイル名を取得
              //const contentDisposition = e.headers["content-disposition"];
              //const fileName = getFileName(contentDisposition);
              const fileName = e.fileName;
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードが完了しました。"
              }));
            }).catch((err) => {
                switch(err.code){
                    case 102005:
                        setFinishInfo(new FinishInfo({
                            message: "入退履歴はありません。"
                        }));
                        break;
                    default:
                        setFinishInfo(new FinishInfo({
                            message: "入退データのダウンロードに失敗しました。"
                        }));
                }
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
        } else if (state.selectCSV === "meet") {
          // axios
          //   .post('/api/admin/report/getMeetCsv', new URLSearchParams({
          //     type: 'Meet',
          //     year: year.toString(),
          //     month: month.toString(),
          //     tabid: sessionStorage.getItem("TABID") as string,
          //   }))
          httpClient.downloadMeetData(
            'Meet',
            year,
            month,
            sessionStorage.getItem("TABID") as string,
          )
            .then((e: CsvDownloadResponse) => {
              //レスポンスヘッダからファイル名を取得
              // const contentDisposition = e.headers["content-disposition"];
              // var fileName = getFileName(contentDisposition);
              var fileName = e.fileName;
              var re = /ttachment; filename=/g;
              fileName = fileName.replace(re, '');
              fileName = fileName.replace("MeetReport", "ビデオ通話データ");
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードが完了しました。"
              }));
            }).catch(() => {
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードに失敗しました。"
              }));
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
        } else if (state.selectCSV === "chat") {
          httpClient.downloadChatLog(
            sessionStorage.getItem("TABID") as string,
            state.selectFloor,
            paramDate,
            strReleaseDate
          )
            .then((e: CsvDownloadResponse) => {
              //レスポンスヘッダからファイル名を取得
              const fileName = e.fileName;
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードが完了しました。"
              }));
            }).catch((err) => {
                switch(err.code){
                    case 102005:
                        setFinishInfo(new FinishInfo({
                            message: "チャットログはありません。"
                        }));
                        break;
                    default:
                        setFinishInfo(new FinishInfo({
                            message: "チャットログのダウンロードに失敗しました。"
                        }));
                }
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
        } else if (state.selectCSV === "study") {
          httpClient.downloadStudyHistoryAdmin(
            sessionStorage.getItem("TABID") as string,
            state.selectFloor,
            paramDate
          )
            .then((e: CsvDownloadResponse) => {
              //レスポンスヘッダからファイル名を取得
              const fileName = e.fileName;
              const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
              const blob = new Blob([bom, e.data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);

              //ダウンロード
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              setFinishInfo(new FinishInfo({
                message: csvName + "のダウンロードが完了しました。"
              }));
            }).catch((err) => {
              switch (err.code) {
                case 102005:
                  setFinishInfo(new FinishInfo({
                    message: "自習データはありません。"
                  }));
                  break;
                default:
                  setFinishInfo(new FinishInfo({
                    message: "自習データのダウンロードに失敗しました。"
                  }));
              }
            }).finally(() => {
              setShowBackdropForDowndating(false);
            });
        }
        clearState();
        setAttendanceCSV(false);
      }
    } else {
      setFinishInfo(new FinishInfo({
        message: "対象フロアが未入力です。"
      }));
    }
  }

  const drawGetAttendance = useMemo(() => {
    return (
      <Box margin="0px 0px 20px 10px" fontSize="18px">
        <Grid container justify="flex-start">
          <Box>
            <FormControl className={classes.grid}>
              <Grid style={{ marginTop: "10px" }}>　使用状況データ</Grid>
              <Grid style={{ marginLeft: "40px" }}>
                <RadioGroup
                  row
                  value={state.selectCSV}
                  aria-label="csvdata"
                  name="controlled-radio-buttons-group"
                  onChange={handleRadioChange}
                >
                  <FormControlLabel value="attendance" label="入退データ" control={<Radio color="primary" />} style={{ width: 200 }} />
                  <FormControlLabel value="meet" label="ビデオ通話データ" control={<Radio color="primary" />} style={{ width: 200 }} />
                  <FormControlLabel value="chat" label="チャットログデータ" control={<Radio color="primary" />} style={{ width: 200 }} />
                  <FormControlLabel value="study" label="自習データ" control={<Radio color="primary" />} style={{ width: 200 }} />
                </RadioGroup>
              </Grid>
            </FormControl>
          </Box>
          <Box style={{ width: "100%" }}>
            <Grid style={{ marginTop: "15px" }}>　対象年月</Grid>
            <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "40px", marginBottom: "10px" }}>
              <DatePicker
                className={classes.picker}
                locale="ja"
                selected={state.selectDate}
                onChange={handleChangeDate}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                onCalendarOpen={() => setCalendarOpen(true)}
                onCalendarClose={() => setCalendarOpen(false)}
              />
            </Grid>
          </Box>
          <Box display={dispSelectFloor}>
            <Grid style={{ marginBottom: "20px" }}>　対象フロア</Grid>
            <Grid item className={classes.grid} style={{ display: 'inline-block', marginLeft: "40px" }}>
              <FormControl variant="outlined" style={{ marginTop: -5 }}>
                {!calendarOpen && <InputLabel id="demo-simple-select-outlined-label" style={{ margin: '-6px 5px', textAlign: 'left' }}>フロア</InputLabel>}
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="floor"
                  onChange={handleChangeFloor}
                  label="Age"
                  input={<OutlinedInput margin='dense' aria-label={"Age"} label={"フロア"} color="primary" />}
                  style={{ width: 400, textAlign: 'center' }}
                  value={state.selectFloor}
                  disabled={disableSelectFloor}
                >
                  {floorList.map((floor) => (
                    <MenuItem value={floor.id}>{floor.floorName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceCSV, state, dispSelectFloor, disableSelectFloor, confirmInfo.isOk, calendarOpen]);


  const drawSideMenu = useMemo(() => {
    let disableAddUser = false;
    let disableAuthSetting = true;

    // 認証方式を確認
    if(authType === 1){
      disableAddUser = true;
    }

    if (role!==null && role!== undefined){
      // 権限を確認
      if(role.indexOf('ROLE_ROOT') !== -1){
        // 認証方式のメニューを有効
        disableAuthSetting = false;
      }
    }

    return(
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List>
        <ListItem button key='AttendanceStatusCheck' onClick={() => handleAttendanceStatusCheck(true)}>
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText primary='出欠状況' />
          </ListItem>
          <ListItem button key='reportAttendance' onClick={() => handleAttendanceReport()}>
            <ListItemIcon><Update /></ListItemIcon>
            <ListItemText primary='使用状況データダウンロード' />
          </ListItem>
          <ListItem button key='ContractInfo' onClick={() => handleContractInfo(true)}>
            <ListItemIcon><ReceiptIcon /></ListItemIcon>
            <ListItemText primary='利用実績' />
          </ListItem>
          <Divider/>
          <ListItem button key='PointAutoSetting' onClick={() => handlePointAutoSetting(true)}>
            <ListItemIcon><ImportExport /></ListItemIcon>
            <ListItemText primary='ポイント自動付与設定' />
          </ListItem>
          <ListItem button key='PointManager' onClick={() => handlePointManager(true)}>
            <ListItemIcon><Equalizer /></ListItemIcon>
            <ListItemText primary='ポイント管理' />
          </ListItem>
          <Divider/>
          <ListItem button key='floorManage' onClick={() => handleFloorSetting(true)}>
            <ListItemIcon><SettingsIcon/></ListItemIcon>
            <ListItemText primary='フロア基本設定' />
          </ListItem>
          <ListItem button key='ChimeSetting' onClick={() => handleChimeSetting(true)}>
            <ListItemIcon><NotificationsIcon /></ListItemIcon>
            <ListItemText primary='チャイム設定' />
          </ListItem>
          <ListItem button key='TimeManagement' onClick={() => handleTimeManagement(true)}>
            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
            <ListItemText primary='開放時間管理' />
          </ListItem>
          <ListItem button key='ZoomSetting' onClick={() => handleZoomUrlSetting(true)}>
            <ListItemIcon><OndemandVideoIcon /></ListItemIcon>
            <ListItemText primary='外部Web会議ツール設定' />
          </ListItem>
          {isShowMenuToolLauncher &&
            <Fragment>
              <ListItem button key='whiteList' onClick={() => handleTenantLauncher()}>
                <ListItemIcon><LaunchIcon /></ListItemIcon>
                <ListItemText primary='リンク設定' />
              </ListItem>
            </Fragment>
          }
          {/*<ListItem button key='whiteList' onClick={() => handleWhiteList(true)}>
            <ListItemIcon><ListAltIcon /></ListItemIcon>
            <ListItemText primary='ホワイトリスト' />
          </ListItem>*/}
          {isShowMenuWebSiteViewer &&
            <ListItem button key='otherWebsiteSetting' onClick={() => handleOtherWebsiteSetting(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='WebsiteViewer' />
            </ListItem>
          }
          {isShowMenuAdminmessage &&
            <ListItem button key='whiteList' onClick={() => handleAdminMessage(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='管理者メッセージ更新' />
            </ListItem>
          }
          {isShowMenuForceMute &&
            <ListItem button key='forceMute' onClick={() => handleForceMute(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='強制ミュート設定' />
            </ListItem>
          }
          {isShowMenuClap &&
            <ListItem button key='clapping' onClick={() => handleClapping(true)}>
              <ListItemIcon><ListAltIcon /></ListItemIcon>
              <ListItemText primary='拍手' />
            </ListItem>
          }
          {isShowMenuAuth &&
            <ListItem button key='authSetting' onClick={() => handleAuthSetting(true)} disabled={disableAuthSetting}>
              <ListItemIcon><VpnKey /></ListItemIcon>
              <ListItemText primary='認証方式' />
            </ ListItem>
          }
          {isShowMenuAuth &&
            <Divider/>
          }
          <Divider/>
          <ListItem button key='backToFloor' onClick={() => handleBackToTop()}>
            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
            <ListItemText primary='戻る' />
          </ListItem>
        </List>
      </Drawer>
    ); 
  }, [authType, role, isShowMenuToolLauncher])

  const handleChimeSetting = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(isShow);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const handleTimeManagement = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowChimeSetting(false);
    setIsShowTimeManagement(isShow);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const handleZoomUrlSetting = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowChimeSetting(false);
    setIsShowTimeManagement(false);
    setIsShowZoomUrlSetting(isShow);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }
  
  const handlePointAutoSetting = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(false);
    setIsShowPointAutoSetting(isShow);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }
  
  const handlePointManager = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(isShow);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }
  
  const handleContractInfo = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(isShow);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(false);
  }

  const handleAttendanceStatusCheck = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(isShow);
    setIsShowFloorSetting(false);
  }

  const handleFloorSetting = (isShow: boolean) => {
    setIsShowWhiteList(false);
    setIsShowClapping(false);
    setIsShowOtherWebsiteSetting(false);
    setIsShowAdminMessage(false);
    setIsShowForceMute(false);
    setIsShowAuthSetting(false);
    setIsShowZoomUrlSetting(false);
    setIsShowTimeManagement(false);
    setIsShowChimeSetting(false);
    setIsShowPointAutoSetting(false);
    setIsShowPointManager(false);
    setIsShowContractInfo(false);
    setIsShowAttendanceStatusCheck(false);
    setIsShowFloorSetting(isShow);  
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {drawSideMenu}
      <main className={classes.content}>
        {isShowWhiteList && <WhiteList />}
        {isShowClapping && <Clapping />}
        {isShowOtherWebsiteSetting && <OtherWebsiteSetting />}
        {isShowAdminMessage && <AdminMessage />}
        {isShowForceMute && <ForceMute />}
        {isShowAuthSetting && <TenantSettingPage />}
        {isShowZoomUrlSetting && <ZoomUrlSetting />}
        {isShowChimeSetting && <ChimeSetting />}
        {isShowTimeManagement && <TimeManagement />}
        {isShowPointAutoSetting && <PointAutoSetting />}
        {isShowPointManager && <PointManager />}
        {isShowContractInfo && <ContractInfo />}
        {isShowAttendanceStatusCheck && <AttendanceStatusCheck />}
        {isShowFloorSetting && <FloorList/>}
      </main> 
      {attendanceCSV &&
        <BaseDialog
          id="getAttendanceDialog"
          open={attendanceCSV}
          aria-labelledby='getAttendanceDialogTitle'
        >
          <DialogTitle id="getAttendanceDialogTitle">使用状況データダウンロード</DialogTitle>
          <DialogContentText>　　　FAMcampusの使用状況データをダウンロードできます。</DialogContentText>
          <DialogContentText>　　　対象の年月とフロアを指定してダウンロードボタンを押してください。</DialogContentText>
          <DialogContent className={classes.attendance}>
            {drawGetAttendance}
          </DialogContent>
          <DialogActions style={{ marginRight: 20, marginBottom: 20 }}>
            <Button onClick={onDownload} color="primary" style={{ pointerEvents: 'auto' }} >ダウンロード</Button>
            <Button onClick={handleReprtCsvClose} color="primary" style={{ pointerEvents: 'auto' }} >キャンセル</Button>
          </DialogActions>
        </BaseDialog>
      }
      <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false }) }}>
        <DialogContent>
          <DialogContentText>{confirmInfo.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true }) }}>OK</Button>
          <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
        </DialogActions>
      </BaseDialog>
      <Backdrop className={classes.backdrop} open={showBackdropForDowndating} >
          <Slide direction="up" in={showBackdropForDowndating}>
          <Card className={classes.card}>
              <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >データ取得中</Typography>
              </CardContent>
          </Card>
          </Slide>
      </Backdrop>
      <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
        <DialogContent>
          <DialogContentText>{finishInfo.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
        </DialogActions>
      </BaseDialog>
      {reportCSVList &&
        <Dialog
          open={reportCSVList}
          style={{ pointerEvents: 'auto' }}
        >
          <DialogTitle id="comrepo-dialog-title">使用状況ダウンロード</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ padding: '10px' }}>
              {renderReportCsvList()}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleReprtCsvClose} color="primary" style={{ pointerEvents: 'auto' }} >
              閉じる
            </Button>
          </DialogActions>

        </Dialog>
      }
      <Backdrop className={classes.backdrop} open={showBackdrop} >
        <Slide direction="up" in={showBackdrop}>
          <Card className={classes.card}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{(dealType === "C" ? "登録" : dealType === "U" ? "更新" : "削除")}処理中</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
      <Backdrop className={classes.backdrop} open={showBackdropForUpdatingList} >
        <Slide direction="up" in={showBackdropForUpdatingList}>
          <Card className={classes.card}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >データ取得中</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
      {/* <AlertWebRtcSize ref={alertWebRtcSizeRef} /> */}
      <TenantLauncher ref={tenantLauncherRef} />
    </div>
  );
}

import React, { useState, useMemo, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import EditText1Text2Dialog from './EditText1Text2Dialog';
import { FloorObject } from '../common/JsonClass';
import ZIndex from "../common/ZIndex";
import { SELECT_NONE } from "../common/Constants";
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    className: string,
    floorObject: FloorObject,
    sendTatehuda2Text: (id: number, text1: string, text2: string, isPlayAudio: boolean) => void
}

const tatehudaTextStyle: React.CSSProperties = {
    ...SELECT_NONE,
    width: 62,
    height: 41,
    fontSize: 10,
    lineHeight: 1.05,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: "1px 0px",
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
}

export default function Tatehuda(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject
    const [isOpen, setIsOpen] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const draw = useMemo(() =>
        <div
            key={`tatehuda${id}`}
            id={`tatehuda${id}`}
            className={className}
            style={{
                ...SELECT_NONE,
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                width: objectMaster.width,
                height: objectMaster.height,
                backgroundSize: "contain",
                // backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                zIndex: ZIndex.floorObject,
            }}
        >
            <div
                onClick={() => setIsOpen(true)}
                className={className}
                style={{ ...SELECT_NONE, position: "absolute", width: 62, zIndex: ZIndex.floorObject, left: objectMaster.textOffsetLeft, top: objectMaster.textOffsetTop }}
            >
                <Typography className={className} style={tatehudaTextStyle} variant="body2" color="textSecondary">
                    {text1}<br />
                    {text2}
                </Typography>
            </div>

            <EditText1Text2Dialog
                className={className}
                isOpen={isOpen}
                floorObjectId={id}
                title={'看板の編集'}
                text1={text1}
                text2={text2}
                maxLength={18}
                onClose={() => setIsOpen(false)}
                sendText={props.sendTatehuda2Text}
            />
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [text1, text2, isOpen, props.floorObject.offsetLeft, props.floorObject.offsetTop])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
import { Box, Button, TextField } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import React, { Fragment, useState } from 'react';

export default function AddUser() {
    const { history } = useReactRouter();

    const [addButton, setAddButton] = useState(true);

    const [state, setState] = useState({
        id: "" as string,
        password: "" as string,
        lastName: "" as string,
        firstName: "" as string,
        nickname: "" as string,
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        setState({ ...state, [name]: event.target.value });
    }

    const registUser = () => {
        var params = new URLSearchParams();
        params.append("mail", state.id);
        params.append("password", state.password);
        params.append("lastName", state.lastName);
        params.append("firstName", state.firstName);
        params.append("nickname", state.nickname);
        params.append("tab_id", sessionStorage.getItem("TABID") as string);
        setAddButton(false);
        axios.post('/api/admin/user/regist', params)
            .then((e: AxiosResponse) => {
                history.push({
                    pathname: "/top",
                });
            }).catch(err => {
                alert(err.response.data)
                setAddButton(true);
            });
    }

    return (
        <Fragment>

            <div style={{ padding: 10, textAlign: "left" }}>
                <form noValidate autoComplete="off">
                    <div>
                        <TextField
                            variant="standard"
                            id="id"
                            label="メールアドレス"
                            name="id"
                            value={state.id}
                            onChange={handleChange}
                            inputProps={{
                                autocomplete: 'new-regist-id',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="password"
                            label="パスワード"
                            name="password"
                            value={state.password}
                            type="password"
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="lastName"
                            label="姓"
                            name="lastName"
                            value={state.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="firstName"
                            label="名"
                            name="firstName"
                            value={state.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                    <div>
                        <TextField
                            variant="standard"
                            id="nickname"
                            label="表示名"
                            name="nickname"
                            value={state.nickname}
                            onChange={handleChange}
                        />
                    </div>
                    <Box m={2}></Box>
                </form>
                <div>
                    <Button disabled={!addButton} onClick={registUser} variant="contained" color="primary">登録</Button>
                    <p>※管理者権限を持っているユーザーのみ登録可能です</p>
                </div>
            </div>
        </Fragment>
    )
}


import React from "react";
import { useSpring, animated} from "react-spring";
import { makeStyles } from '@material-ui/core';

import SpeechIcon from '../img/fukidasi.png';

const useStyles = makeStyles(theme => ({
  userImg: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    backgroundImage: `url(${SpeechIcon})`,
    backgroundSize: 'cover',
    textAlign: 'center',
  },
}));

interface Props {
  top: number,
  left: number,
  micLevel: number,
  micVolumeThreshold: number,
  zIndex: number,
}


export default function FukidasiEffect(props: Props) {
  //const FukidasiEffect = (props: Props) => {
  const classes = useStyles();

  React.useEffect(() => {
    //console.log("micLevelData " + props.micLevel + " " + props.micVolumeThreshold );

    //if (props.micLevel <= props.micVolumeThreshold) {
    if (props.micLevel <= 25 ) {  // props.micVolumeThresholdは、他人アバターの場合、undefinedになるので
      //console.log("clear?");
      set({ opacity: 0, color:"white", fontSize: "0px", left: props.left + 10, height: 29*15/19, width: 29, paddingTop: -3, top: props.top + 8});
    } else if (props.micLevel <= 30) {
      set({ opacity: 0.9, color:"black", fontSize: "7px", left: props.left + 14, height: 29*15/19, width: 29, paddingTop: 1, top: props.top -1 });
    } else if (props.micLevel <= 50 ) {
      set({ opacity: 0.9, color:"black", fontSize: "7px", left: props.left + 13, height: 32*15/19, width: 32, paddingTop: 3, top: props.top -2 });
    } else if (props.micLevel <= 80 ) {
      set({ opacity: 0.9, color:"black", fontSize: "7px", left: props.left + 12, height: 34*15/19, width: 34, paddingTop: 4, top: props.top -5 });
    } else if (props.micLevel <= 300) {
      set({ opacity: 0.9, color:"black", fontSize: "7px", left: props.left + 10, height: 36*15/19, width: 36, paddingTop: 5, top: props.top -4 });
    } else {
      set({ opacity: 0 });
      // console.log("micLevelがおかしな値:" + props.micLevel);
    }

    //    console.log("micLevel recv " + props.micLevel )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.left, props.top, props.micLevel]);


  // (B)
  const [spring, set] = useSpring(() => ({
    fontSize: "7.5pt",
    color: "black",
    opacity: 0,
    width: 0,
    height: 0,
    bottom: 20,
    left: props.left + 5 + 'px',
    top: props.top + 8 + 'px',
    paddingTop: 0,
    zIndex: props.zIndex,
  }));

  const RandText = React.useMemo(() => {
    //const kigo = ["△", "■", "＄", "×", "∀", "Θ", "￥", "Ψ", "Λ", "∬", "^", "_", "*", "/", "（", "）", "Ο", "Ω", "Д", "Щ"];
    const kigo = ["△", "▽", "〇", "✕", "☐"];
    let rand1 = Math.floor(Math.random() * Math.floor(5));
    let rand2 = Math.floor(Math.random() * Math.floor(5));
    let rand3 = Math.floor(Math.random() * Math.floor(5));

    if (props.micLevel <= 25 ) {
      return " ";
    } else if (props.micLevel <= 55) {
      return kigo[rand1] + kigo[rand2] ;
    }else{
      return kigo[rand1] + kigo[rand2] + kigo[rand3];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.micLevel]);

  return (
    <animated.div
      className={classes.userImg}
      style={spring}
    >
      {RandText}
    </animated.div>
  );
};

//export default FukidasiEffect;
// var isEqual = require('lodash.isequal');
// export const FukidasiEffectMemo = React.memo(FukidasiEffect, (prevProps, nextProps) => isEqual(prevProps, nextProps));
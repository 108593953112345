import React, { Fragment, useState, useEffect, useMemo } from 'react';
import BaseUser from './BaseUser';
import { AvatarMenuData, Size } from '../common/JsonClass';
import HitPersonEffect, { AvatarEffectType } from './effect/Index';
import ZIndex from "../common/ZIndex";
import { INNER_RATIO, AVATAR_WIDTH_GAIN, AVATAR_HEIGHT_GAIN } from "../common/AvatarSize";
import { calcAvatarWidth, calcAvatarHeight, calcWebRtcAreaWrapperSize, calcWebRtcVideoSize } from "../common/AvatarSize";
import { NEED_TO_POINTER_CAPTURE, SELECT_NONE } from "../common/Constants";
import OtherUserMenu from './OtherUserMenu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';

interface Props {
    user: User,
    avatarMenuDataList: AvatarMenuData[],
    scale: number,
    myUserSubId: string | null,
    handleOpenMemo: (groupId: number, groupName: string) => void,
    enabledMemoOtherUserMenu: boolean;
    myUser: User;
    myFloorName: string;
    enabledBusinessCard: boolean;
    handleAddMyRoomUser: (id: number, subId:string) => void,
    handleDeleteMyRoomUser: (id: number, subId:string, seatNo:number) => void,
    handleGoToSee: (subId:string) => void,
    handleClickMyRoomUserInfo: (user:User) => void,
    kind: string | null;
    enabledMyRoom: boolean;
    enabledChat: boolean;
    handleOpenChat: (groupId: number, groupName: string, groupMemberNumber: number, unreadCount: number|null, userSubId: string[]) => void;
    handleClickMyRoomMemo: (user:User) => void,
    handleClickMyRoomChat: (user:User) => void,
    isPrivacyGimmickOn?: (floorObjectId: number) => boolean,
    floorSize: Size,
}

// 子要素を自身の中心に配置するstyle
const centeringChild: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
// avatar image 共通のstyle
const avatarImageStyle: React.CSSProperties = {
    ...SELECT_NONE,
    width: "100%",
    height: "100%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
};
// webRTC area 共通のstyle
const webRtcAreaStyle: React.CSSProperties = {
    ...SELECT_NONE,
    ...centeringChild,
    borderRadius: "50%", // 円形
};

const apiBase = "/api/user";
const zIndex = ZIndex.otherUser;

// ユーザー強制退出
const forcedSignoutUser = async (subId: string) => {
    // axios.post( '/api/user/forced/signout/post', new URLSearchParams({
    //     tab_id: sessionStorage.getItem("TABID") as string,
    //     sub_id: subId,
    // })).then((res: AxiosResponse) => {
    //     console.log(res.data);
    // }).catch((err) => {
    //     console.log(err.response.data);
    // })
    const jfs_Client = JfsClient.getInstance();
    const jfs_httpClient =  jfs_Client.httpClient;
    jfs_httpClient.forcedSignoutUser(sessionStorage.getItem("TABID") as string, subId)
        .then((res: string) => {
            console.log(res);
        }).catch(err => {
            const jfserr = err as JfsError;
            console.log('httpClient.forcedSignoutUser error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
        });
}

export default function OtherUser(props: Props) {
    const { user, myUser } = props
    const [openMenu, setOpenMenu] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openSelectMemoGroup, setOpenSelectMemoGroup] = useState(false);
    const [openSelectChatGroup, setOpenSelectChatGroup] = useState(false);

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const onClickAvatar = () => {
        setOpenMenu(!openMenu);
    }
    const onCloseMenu = () => {
        setOpenMenu(false);
    }

    const onClickMemo = () => {
        setOpenProfile(false);
        if(props.kind === 'myroom'){
            props.handleClickMyRoomMemo(props.user);
        }else{
            setOpenSelectMemoGroup(!openSelectMemoGroup);
        }
        setOpenSelectChatGroup(false);
        setOpenMenu(false);
    }

    const onClickChat = () => {
        setOpenProfile(false);
        setOpenSelectMemoGroup(false);
        if(props.kind === 'myroom'){
            props.handleClickMyRoomChat(props.user);
        }else{
            setOpenSelectChatGroup(!openSelectChatGroup);
        }
        setOpenMenu(false);
    }

    const onClickUserInfo = () => {
        setOpenSelectMemoGroup(false);
        if(props.kind === 'myroom'){
            props.handleClickMyRoomUserInfo(props.user);
        }
        else{
            setOpenProfile(!openProfile);
        }
        setOpenMenu(false);
    }

    const onClickAddMyRoomUser = () => {
        setOpenMenu(false);
        props.handleAddMyRoomUser(props.user.id, props.user.subId);
    }

    const onClickDeleteMyRoomUser = () => {
        setOpenMenu(false);
        props.handleDeleteMyRoomUser(props.user.id, props.user.subId, props.user.seat.seatNo);
    }

    const onClickGoToSee = () => {
        setOpenMenu(false);
        props.handleGoToSee(props.user.subId);
    }

    const onClickFlorcedSignoutUser = (yes: boolean) => {
        setOpenMenu(false);
        if(yes){
            forcedSignoutUser(props.user.subId);
        }
    }

    // avatar の large サイズフラグ
    const isLarge = useMemo(() => {
        return (!user.isMediaWaiting && user.webRtcCall)
    }, [user.isMediaWaiting, user.webRtcCall])

    // 周りの人からみた avatar の large サイズフラグ
    const isLargeOther = useMemo(() => {
        console.log("isLargeOther=["+user.isWebrtcJoin+"]");
        //return (!user.isMediaWaiting && (user.webRtcCall || user.isWebrtcJoin))
        return (!user.isMediaWaiting && (user.isWebrtcJoin))
    }, [user.isMediaWaiting, /*user.webRtcCall,*/ user.isWebrtcJoin])

    // avatar要素のtop
    const avatarTop = useMemo(() => {
        return user.y - ((isLarge||isLargeOther) ? AVATAR_HEIGHT_GAIN / 2 : 0)
    }, [user.y, isLarge, isLargeOther]);

    // avatar要素のleft
    const avatarLeft = useMemo(() => {
        return user.x - ((isLarge||isLargeOther) ? AVATAR_WIDTH_GAIN / 2 : 0)
    }, [user.x, isLarge, isLargeOther]);

    // avatar要素の幅
    const avatarWidth = useMemo(() => {
        return calcAvatarWidth(user.width, (isLarge||isLargeOther));
    }, [user.width, isLarge, isLargeOther]);

    // avatar要素の高さ
    const avatarHeight = useMemo(() => {
        return calcAvatarHeight(user.height, (isLarge||isLargeOther));
    }, [user.height, isLarge, isLargeOther]);

    // webRtcAreaWrapperのサイズ（正方形の一辺）
    const webRtcAreaWrapperSize = useMemo(() => {
        return calcWebRtcAreaWrapperSize(user.width, (isLarge||isLargeOther), user.isLargeVideo);
    }, [user.width, isLarge, user.isLargeVideo, isLargeOther]);

    const isHiddenUser = useMemo(() => {
        if (props.kind === 'myroom') {
            return false;
        }
        if (user.seat == null) {
            return false;
        }
        let userSeatFloorObjectId = user.seat.floorObjectId;
        if (myUser.seat != null && myUser.seat.floorObjectId == userSeatFloorObjectId) {
            return false;
        }
        if (props.isPrivacyGimmickOn) {
            return props.isPrivacyGimmickOn(userSeatFloorObjectId);
        }
        return false;
    }, [user.seat, myUser.seat]);

    // テレミートを開始した場合、プロフィールを自動で開く
    useEffect(() => {
        if (props.enabledBusinessCard && user.webRtcRoomId !== "" && props.myUser.webRtcRoomId !== "" && user.webRtcRoomId === props.myUser.webRtcRoomId
            && user.webRtcRoomId.includes("-")) {
            setOpenProfile(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.webRtcRoomId, props.myUser.webRtcRoomId, props.enabledBusinessCard])

    // 移動したら閉じる
    useEffect(() => {
        setOpenMenu(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.x, user.y])

    // 種々の size 変更時にvideo要素のサイズを変更する
    useEffect(() => {
        const video = document.getElementById(`${user.id}-video`) as HTMLVideoElement;
        if (video) {
            const _width = user.isLargeVideo ? webRtcAreaWrapperSize : avatarWidth;
            const size = calcWebRtcVideoSize(_width, user.isLargeVideo);
            video.width = size;
            video.height = size;
        }
    }, [avatarWidth, webRtcAreaWrapperSize, user.id, user.isLargeVideo])

    // 着座時のPCアニメーション定義
    useEffect(() => {
        if (user.myPc.height === 0) {
            return;
        }

        const animationName = `openPc-${user.myPc.height}px-${user.myPc.frames}f`;
        const headElement = document.getElementsByTagName("head")[0];
        for (let i = headElement.childNodes.length - 1; i >= 0; i--) {
            const node = headElement.childNodes[i];
            if (node.nodeType === 1 && node.textContent && node.textContent.indexOf(animationName) >= 0) {
                // すでに定義済みの場合
                return;
            }
        }

        // 着座時のCSSスプライト定義
        const cssAnimation = document.createElement('style');
        const rules = document.createTextNode(`@keyframes ${animationName} { to { background-position: 0 -${user.myPc.height * (user.myPc.frames - 1)}px; } }`);
        cssAnimation.appendChild(rules);
        document.getElementsByTagName("head")[0].appendChild(cssAnimation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.myPc.height])

    const drawPc = () => {
        if(user.seat === undefined || user.seat === null){
            return;
        }
        // if (user.isSitOnDesk && user.isDrawPc && user.myPc?.picturePath) {
        if (user.isSitOnDesk && user.isDrawPc) {
            const divDrawPc = document.getElementById("drawPcId-" + user.id);
            if (divDrawPc) {
                if (Number.parseFloat(divDrawPc.style.top) === (user.seat.y + user.seat.deskOffsetTop - (user.myPc.height / 2))
                    && Number.parseFloat(divDrawPc.style.left) === (user.seat.x + user.seat.deskOffsetLeft - (user.myPc.width / 2))
                ) {
                    // PCの表示座標が変更されていない場合には、何もしない
                } else {
                    // CSSアニメーションを再度実行するため、一定時間経過後に再設定している
                    const animation = divDrawPc.style.animation;
                    divDrawPc.style.animation = "";
                    setTimeout(() => {divDrawPc.style.animation = animation}, 100);
                }
            }
        }

        // return (user.isSitOnDesk && user.isDrawPc && user.myPc?.picturePath) &&
        return ((user.isSitOnDesk && user.isDrawPc) || (props.user.privacyRoomInfo !== null && props.user.privacyRoomInfo.stayPrivacyroom && props.user.privacyRoomInfo.preFloorName === props.myFloorName)) &&
        <div style={{ ...SELECT_NONE }}>
            <div id={"drawPcId-" + user.id}
                className={NEED_TO_POINTER_CAPTURE}
                onMouseDown={e => e.preventDefault()} // window外へのgif画像コピー禁止
                onMouseMove={e => e.preventDefault()} // window外へのgif画像コピー禁止
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    top: user.seat.y + user.seat.deskOffsetTop - (user.myPc.height / 2),
                    left: user.seat.x + user.seat.deskOffsetLeft - (user.myPc.width / 2),
                    width: user.myPc.width,
                    height: user.myPc.height,
                    zIndex: ZIndex.seatObject, // 机上の名札と同じZIndex
                    //backgroundImage: `url(${apiBase}/object/picture/${user.myPc.id}?angle=${user.seat.deskAngle})`,
                    backgroundImage: `url(${httpClient.createObjectImgUrl(user.myPc.id, user.seat.deskAngle)})`,
                    backgroundSize: `${user.myPc.width}px ${(user.myPc.frames === 0 ? user.myPc.height : user.myPc.height * user.myPc.frames)}px`,
                    backgroundRepeat: "no-repeat",
                    animation: `openPc-${user.myPc.height}px-${user.myPc.frames}f ${1 / user.myPc.fps}s steps(${user.myPc.frames - 1}) forwards`,
                }}
            />
        </div>
    }

    const drawShare = () => {
        return (user.isScreenShare && user.webRtcCall &&
            <div
                className={NEED_TO_POINTER_CAPTURE}
                style={{
                    ...SELECT_NONE,
                    ...webRtcAreaStyle,
                    position: "absolute",
                    top: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize - avatarWidth) : 0,
                    left: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize / 2 - avatarWidth / 2) : 0,
                    width: webRtcAreaWrapperSize,
                    height: webRtcAreaWrapperSize,
                    backgroundColor: `rgba(19, 146, 133, ${user.isLargeVideo ? 1.0 : 0.7})`,
                    color: "rgb(255,255,255)",
                    fontSize: user.isLargeVideo ? "20px" : "12px",
                    zIndex: zIndex + 4,
                }}
            >
                {user.isLargeVideo ? "画面共有中" : "共有中"}
            </div>)
    }

    const drawMultiAvatar = () => {

        // マイルーム内で退勤している人(オフラインではない人)はフロア名を出さない
        if(props.kind === 'myroom' && (user.isCommuting === false && user.validWebSocketHostMaster === false)) {
            return;
        }

        if (!props.user.isStayFloor) {
            let fName:string = props.user.stayFloorName;

            if(props.user.privacyRoomInfo !== null){
                // props.myUser.stayFlooNameでは正しくフロア名が取得できていないことがあるので、Floor.tsxからフロア名を流す
                /*if(props.user.privacyRoomInfo.stayPrivacyroom && props.user.privacyRoomInfo.preFloorName === props.myUser.stayFloorName){
                    return;
                }else if(props.user.privacyRoomInfo.stayPrivacyroom && props.user.privacyRoomInfo.preFloorName !== props.myUser.stayFloorName){
                    fName = props.user.privacyRoomInfo.preFloorName;
                }*/
                if(props.user.privacyRoomInfo.stayPrivacyroom && props.user.privacyRoomInfo.preFloorName === props.myFloorName){
                    return;
                }else if(props.user.privacyRoomInfo.stayPrivacyroom && props.user.privacyRoomInfo.preFloorName !== props.myFloorName){
                    fName = props.user.privacyRoomInfo.preFloorName;
                }
            }
            
            let sp = props.user.stayFloorName.indexOf("：");
            if(sp !== -1) {
                fName = props.user.stayFloorName.substr(0,sp);
            }
            let fsize = "12px"
            let wd = "80%"
            let lf = "10%"
            //let wdcnt = props.user.stayFloorName.length;
            let wdcnt = fName.length;
            if(wdcnt > 3) {
                fsize = "10px";
                wd = "120%";
                lf = "-10%"
            }
            return (
                <div style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    fontSize: fsize,
                    zIndex: zIndex + 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                    color: 'rgb(0,0,0)',
                    //top:'8px',
                    top:'15px',
                    left: lf,
                    width: wd,
                    height: '35%',
                    paddingTop: 5,
                    borderRadius: '20%',
                }}>{/*props.user.stayFloorName*/fName}</div>
            )
        }
    }

    const drawZoomSpeaking = () => {
        return (user.isZoomSpeakable &&
            <div
                className={NEED_TO_POINTER_CAPTURE}
                style={{
                    ...SELECT_NONE,
                    ...webRtcAreaStyle,
                    position: "absolute",
                    top: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize - avatarWidth) : 0,
                    left: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize / 2 - avatarWidth / 2) : 0,
                    width: webRtcAreaWrapperSize,
                    height: webRtcAreaWrapperSize,
                    backgroundColor: `rgba(19, 146, 133, ${user.isLargeVideo ? 1.0 : 0.7})`,
                    color: "rgb(255,255,255)",
                    fontSize: user.isLargeVideo ? "20px" : "12px",
                    zIndex: zIndex + 4,
                }}
            >
                {user.isLargeVideo ? "Zoom登壇中" : "登壇中"}
            </div>)
    }

    const getOpacity = (user: User) => {
        var opacity: number = 1.0;
        if(props.kind === 'myroom' && (user.isCommuting === false && user.validWebSocketHostMaster === false)) {
            opacity = 0.3;
        }
        else if(user.stayFloorName !== "オフライン"){
            opacity = user.isGhost ? 0.3 : 1;
        }
        return opacity;
    }

    const draw = useMemo(() =>
        <Fragment>
            <div
                onClick={onClickAvatar}
                style={{
                    ...SELECT_NONE,
                    position: "absolute",
                    top: avatarTop,
                    left: avatarLeft,
                    width: avatarWidth,
                    height: avatarHeight,
                    zIndex: zIndex,
                    cursor: "pointer"
                }}>
                {!user.isMediaWaiting && !user.webRtcCall &&
                    <div id={`avator-image-${user.id}`}
                        style={{
                            ...avatarImageStyle,
                            // backgroundImage: `url("${apiBase}/avatar/picture/${user.avatarId}")`,
                            backgroundImage: `url(${httpClient.createAvatarImgUrl(user.avatarId)})`,
                            opacity: getOpacity(user),
                            zIndex: zIndex
                        }}
                    />}
                {user.isMediaWaiting &&
                    <div id={`avator-waiting-image-${user.id}`}
                        style={{
                            ...avatarImageStyle,
                            //backgroundImage: `url("${apiBase}/avatar/waitingpicture/${user.avatarId}")`,
                            backgroundImage: `url(${httpClient.createAvatarWaitingImgUrl(user.avatarId)})`,
                            opacity: getOpacity(user),
                            zIndex: zIndex + 1
                        }}
                    />}
                {user.isMediaWaiting &&
                    <HitPersonEffect 
                        effectId={AvatarEffectType.Waiting} 
                        width={avatarWidth}
                        style={{ ...SELECT_NONE, zIndex: zIndex + 2 }}
                    />}
                {user.isGoToSee &&
                    <HitPersonEffect 
                        effectId={AvatarEffectType.GoToSee} 
                        width={avatarWidth}
                        style={{ ...SELECT_NONE, zIndex: zIndex + 3 }}
                    />}
                {isLarge &&
                    <div id={`avator-large-image-${user.id}`}
                        style={{
                            ...avatarImageStyle,
                            //backgroundImage: `url("${apiBase}/avatar/largepicture/${user.avatarId}")`,
                            backgroundImage: `url(${httpClient.createAvatarLargeImgUrl(user.avatarId)})`,
                            opacity: getOpacity(user),
                            zIndex: zIndex + 3
                        }}
                    />}
                <div id={`webrtc-area-wrapper-${user.id}`}
                    style={{
                        ...webRtcAreaStyle,
                        position: "absolute",
                        top: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize - avatarWidth) : 0,
                        left: user.isLargeVideo ? -1 * (webRtcAreaWrapperSize / 2 - avatarWidth / 2) : 0,
                        width: webRtcAreaWrapperSize,
                        height: webRtcAreaWrapperSize,
                        visibility: (isLarge && !user.isMediaWaiting && !user.isNoVideo && !user.isScreenShare) ? "visible" : "hidden",
                        zIndex: zIndex + 4
                    }}
                >
                    <div id={`webrtc-area-${user.id}`}
                        style={{
                            ...webRtcAreaStyle,
                            width: webRtcAreaWrapperSize * INNER_RATIO,
                            height: webRtcAreaWrapperSize * INNER_RATIO,
                            WebkitMaskImage: "-webkit-radial-gradient(circle, white 100%, black 100%)",
                            background: (isLarge && !user.isMediaWaiting && !user.isNoVideo && !user.isGhost) ? "black" : "none",
                            zIndex: zIndex + 5
                        }}
                        data-islargevideo={user.isLargeVideo}
                    />
                </div>
                {drawMultiAvatar()}
                {drawShare()}
                {drawZoomSpeaking()}
            </div>
            {drawPc()}
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user.x, user.y, user.isSitOnDesk, user.avatarId, user.webRtcCall,
            user.isMediaWaiting, isLarge, /*isLargeOther,*/ avatarWidth, webRtcAreaWrapperSize,
            user.isNoVideo, user.isLargeVideo, user.isVideoMute, user.isAudioMute,
            user.isStayFloor, user.stayFloorName, user.isScreenShare, user.isGhost,
            openMenu, openProfile, openSelectMemoGroup, props.scale, user.isGoToSee,
            user.isZoomSpeakable, props.kind, user.isCommuting, user.validWebSocketHostMaster, props.user.privacyRoomInfo])

    const drawMenu = useMemo(() => {
        return openMenu && !isHiddenUser && (
            <ClickAwayListener onClickAway={onCloseMenu}>
                <div>
                    <OtherUserMenu
                        isOpen={openMenu}
                        top={user.y}
                        left={avatarLeft + (avatarWidth / 2)}
                        user={user}
                        zIndex={zIndex + 6}
                        isLarge={isLarge}
                        scale={props.scale}
                        onClickMemo={onClickMemo}
                        onClickChat={onClickChat}
                        onClickUserInfo={onClickUserInfo}
                        onClickAddMyRoomUser={onClickAddMyRoomUser}
                        onClickDeleteMyRoomUser={onClickDeleteMyRoomUser}
                        onClickGoToSee={onClickGoToSee}
                        onClickFlorcedSignoutUser={onClickFlorcedSignoutUser}
                        myUserSubId={props.myUserSubId}
                        enabledMemoOtherUserMenu={props.enabledMemoOtherUserMenu}
                        myUser={props.myUser}
                        enabledBusinessCard={props.enabledBusinessCard}
                        kind={props.kind}
                        enabledMyRoom={props.enabledMyRoom}
                        enabledChat={props.enabledChat}
                    />
                </div>
            </ClickAwayListener>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.y, user.x, avatarLeft, avatarWidth, openMenu, openProfile, openSelectMemoGroup, props.scale])
    
    return (
        <Fragment>
            {drawMenu}
            {draw}
            <BaseUser 
                user={props.user}
                openProfile={openProfile}
                onClickTubuyaki={() => { props.kind === 'myroom' ?  props.handleClickMyRoomUserInfo(props.user) : setOpenProfile(true) }}
                onClickProfile={() => { setOpenProfile(false) }}
                zIndex={ZIndex.otherUser + 5}
                isLarge={isLargeOther}
                isMyUser={false}
                avatarMenuDataList={props.avatarMenuDataList}
                openSelectMemoGroup={openSelectMemoGroup}
                onClickSelectMemoGroup={() => { setOpenSelectMemoGroup(false) }}
                handleOpenMemo={props.handleOpenMemo}
                enabledBusinessCard={props.enabledBusinessCard}
                kind={props.kind}
                openSelectChatGroup={openSelectChatGroup}
                onClickSelectChatGroup={() => { setOpenSelectChatGroup(false) }}
                handleOpenChat={props.handleOpenChat}
                floorSize={props.floorSize}
            />
        </Fragment>
    )

    // drawする要素よりBaseUserが表示で上となるように、zIndexは「ZIndex.otherUser + 5」を渡している。
}
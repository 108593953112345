// ユーザーマニュアルの相対パス
export const usermanualURL:string = "https://famcampus.jp/app/c/themes/famcampus/assets/images/commons/pdf/manual.pdf";
// 管理者マニュアルの相対パス
export const adminmanualURL:string = "https://famcampus.jp/app/c/themes/famcampus/assets/images/commons/pdf/kanrisyamanual.pdf";
// FAQの相対パス
export const faqURL:string = "https://famcampus.jp/app/c/themes/famcampus/assets/images/commons/pdf/faq.pdf";
// export const faqURL:string = "service_info/FAMcampus_FAQ_v1.1.0.pdf";
// リリースノートの相対パス
export const releaseNoteURL:string = "https://famcampus.jp/app/c/themes/famcampus/assets/images/commons/pdf/ReleaseNote.html";
// export const releaseNoteURL:string = "releaseNote/FAMcampus-ReleaseNote.html";
// お知らせの相対パス
export const informationURL:string = "https://famcampus.jp/app/c/themes/famcampus/assets/images/commons/pdf/information.html";
// お試し版
// export const informationURL:string = "https://famcampus.jp/app/c/themes/famcampus/assets/images/commons/pdf/information_tab_test";
// export const informationURL:string = "service_info/information.html";
// お問合せの相対パス
export const contractURL:string = "service_info/contract.html";
// ログアウト画面画像のパス
export const logoutimage: string = "";


import React, { forwardRef, useImperativeHandle, Fragment, useState, useMemo, useEffect, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { makeStyles, Theme, createStyles, useTheme, Typography, Button, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid, FormControl, FormHelperText, LinearProgress, InputAdornment, createMuiTheme, TextField, IconButton, Table, TableContainer, TableBody, TableHead, TableRow, TableCell, Paper, Slide, Card, CardContent, CircularProgress} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import JfsClient, { CharacterMessageSetting } from '@fsi/jfs-sdk';
import BaseDialog from './BaseDialog';
import { Utility } from '../common/Utility';
import { SELECT_NONE } from "../common/Constants";
import { minWidth } from '@material-ui/system';

class ConfirmInfo {
    open: boolean = true;
    message: string = "";
    isOk: boolean = false;
    type: number = 0;
  
    constructor(init: Partial<ConfirmInfo>) {
      Object.assign(this, init);
    }
}

const circularTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
})  

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const textFieldTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    //backgroundColor: 'rgba(0, 0, 255, 1)',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            // padding: "5px 25px 5px 25px",
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
        settingTitle:{
            fontWeight:'bold', 
            fontSize: 16,
            fontFamily: 'Hiragino Maru Gothic StdN',
        },
        tutorialFont: {
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555',
        },
        headCell: {
            minHeight: 30,
            padding: 3,
            fontSize: 13,
            backgroundColor: '#F2F2F2',
            textAlign: 'center'
        },
        cell: {
            minHeight: 30,
            padding: 3,
            fontSize: 13,
            textAlign: 'center'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        card: {
            width: 150,
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);

interface Props {
    floorObjectId: number,
    open: boolean,
    onClickClose: () => void,
    maxLength: number,
}

export interface ChildHandler {
    //setTutorialOpen: (open: boolean) => void
}

const CharacterMessageEditDialog: React.ForwardRefRenderFunction<ChildHandler, Props> = (props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const [characterMessageList, setCharacterMessageList] = useState([] as CharacterMessageSetting[]);
    const [openAddSetting, setOpenAddSetting] = useState(false);
    const [openEditSetting, setOpenEditSetting] = useState(false);
    const [openDeliteConfirm, setOpenDeliteConfirm] = useState(false);
    const cycle = ['日時指定','毎日','毎週','毎年'];
    const dayOfWeek = ['月曜日','火曜日','水曜日','木曜日','金曜日','土曜日','日曜日'];
    const month = [1,2,3,4,5,6,7,8,9,10,11,12];
    const day30 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
    const day31 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    const day28 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];
    const [selectedCycle, setSelectedCycle] = useState(cycle[0]);
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(dayOfWeek[0]);
    const [selectedMonth, setSelectedMonth] = useState(month[0]);
    const [selectedDay, setSelectedDay] = useState(day30[0]);
    const [strToday, setStrToday] = useState("");
    const [strMaxDate, setStrMaxDate] = useState("");
    const [displayDate, setDisplayDate] = useState("");
    const [displayStartTime, setDisplayStartTime] = useState("00:00");
    const [displayEndTime, setDisplayEndTime] = useState("23:59");
    const [selectedMessage, setSelectedMessage] = useState(0);
    const [timeBlank, setTimeBlank] = useState(false);
    const [timeReverse, setTimeReverse] = useState(false);
    const [dateBlank, setDateBlank] = useState(false);
    const [dateReverse, setDateReverse] = useState(false);
    const [notExistDate, setNotExistDate] = useState(false);
    const [inputText, setInputText] = useState("");
    const [addCharacterMessage, setAddCharacterMessage] = useState(false);
    const [csvDownloadError, setCsvDownloadError] = useState("");
    const [csvDownloadErrorDialogOpen, setCsvDownloadErrorDialogOpen] = useState(false);
    const [csvUploadDialogOpen, setCsvUploadDialogOpen] = useState(false);
    const [csvUploadFinishDialogOpen, setCsvUploadFinishDialogOpen] = useState(false);
    const [csvUploadFinishTitle, setCsvUploadFinishTitle] = useState("");
    const [csvUploadFinishContents, setCsvUploadFinishContents] = useState("");
    const [uploadCSVFile, setUploadCSVFile] = React.useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const isProcessing = useRef(false);
    const [confirmInfo, setConfirmInfo] = useState(new ConfirmInfo({ open: false }));
    const [showBackdropForDowndating, setShowBackdropForDowndating] = useState(false);

    // メッセージ編集画面を開いたとき
    useEffect(() => {
        async function get() {
            await getCharacterMessageSettingList();
        }

        if(!props.open){
            return;
        }

        // 当日以降の日付を入力可能範囲として設定
        const today = new Date();
        const maxDate = new Date();
        maxDate.setDate(today.getDate() + 999);
        setStrToday(today === null ? "" : convertDateFormat(today));
        setStrMaxDate(maxDate === null ? "" : convertDateFormat(maxDate));

        get();
    }, [props.open])

    // メッセージを編集・新規追加・削除・一括追加したら、再度メッセージを取ってくる
    useEffect(() => {
        async function get() {
            await getCharacterMessageSettingList();
        }

        get();
    }, [addCharacterMessage])

    // displayStartTimeやdisplayEndTimeの中身が変わったとき、開始・終了時刻の空白でないか、逆転関係になっていないかチェックする
    useEffect(() => {

        if(displayStartTime === '' || displayEndTime === ''){
            setTimeBlank(true);
            return;
        }
        setTimeBlank(false);

        let startTime = new Date(); // 入力された開始時刻(この段階では現在時刻)
        let endTime = new Date(); // 入力された終了時刻(この段階では現在時刻)

        let startTimeArr = displayStartTime.split(':');
        startTime.setHours(Number(startTimeArr[0]));
        startTime.setMinutes(Number(startTimeArr[1])); // 入力された開始時刻

        let endTimeArr = displayEndTime.split(':');
        endTime.setHours(Number(endTimeArr[0]));
        endTime.setMinutes(Number(endTimeArr[1])); // 入力された終了時刻

        if(startTime >= endTime){
            setTimeReverse(true);
            return;
        }
        setTimeReverse(false);

        if(cycle.indexOf(selectedCycle) === 0){ // 日付指定の場合
            let currentDate = new Date();
            let endDate = new Date();
            let inputDate = new Date();
            let displayDateArr = displayDate.split('-');
            if(currentDate.getFullYear() === Number(displayDateArr[0]) && currentDate.getMonth()+1 === Number(displayDateArr[1])
                && currentDate.getDate() === Number(displayDateArr[2])){ // 現在の年月日と入力された年月日が同じの時
                endDate.setHours(Number(endTimeArr[0]));
                endDate.setMinutes(Number(endTimeArr[1]));
                if(currentDate > endDate){ // 現在日時と入力された日時を比較
                    setDateReverse(true);
                    return;
                }
            }
            if(displayDate !== ''){
                inputDate.setFullYear(Number(displayDateArr[0]));
                inputDate.setMonth(Number(displayDateArr[1])-1);
                inputDate.setDate(Number(displayDateArr[2])); // 入力された日付
                if(inputDate >= currentDate){
                    setDateReverse(false);  
                }
            }
        }

    }, [displayStartTime, displayEndTime])

    // displayDateの中身が変わったとき、空白でないか、過去日付になっていないか、存在しない日付でないかチェックする
    useEffect(() => {

        // 存在しない日付でないかチェック
        if(dayjs(displayDate, 'YYYY-MM-DD').format('YYYY-MM-DD') !== displayDate){
            setNotExistDate(true);
            return;
        }
        setNotExistDate(false);

        // 空白がないかチェック
        if(displayDate === ''){
            setDateBlank(true);
            return;
        }
        setDateBlank(false);

        // 過去日付になっていないかチェック(日付のみ確認)
        let currentDate = new Date(); // 現在日付
        let inputDate = new Date(currentDate); // 入力された日付(初期値は現在の日付)

        let dateArr = displayDate.split('-');
        inputDate.setFullYear(Number(dateArr[0]));
        inputDate.setMonth(Number(dateArr[1])-1);
        inputDate.setDate(Number(dateArr[2])); // 入力された日付

        if(currentDate > inputDate){
            setDateReverse(true);
            return;
        }

        // 過去日付になっていないかチェック(終了時刻を含めた日付の確認)
        let endDate = new Date();
        let displayDateArr = displayDate.split('-');
        if( currentDate.getFullYear() === Number(displayDateArr[0]) && currentDate.getMonth()+1 === Number(displayDateArr[1])
            && currentDate.getDate() === Number(displayDateArr[2]) && displayEndTime !== ''){ // 現在の年月日と入力された年月日が同じの時
            let endTimeArr = displayEndTime.split(':');
            endDate.setHours(Number(endTimeArr[0]));
            endDate.setMinutes(Number(endTimeArr[1]));
            if(currentDate > endDate){ // 現在日時と入力された日時を比較
                setDateReverse(true);
                return;
            }
        }
        setDateReverse(false);

    }, [displayDate])

    useEffect(() => {
        if (confirmInfo.isOk) {
          if(confirmInfo.type === 0){
            downloadCharacterMessageSettingCsv();
          }else{
            uploadCSV();
          }
        }
      }, [confirmInfo.isOk]);

    const getCharacterMessageSettingList = async () => {
        
        httpClient.getCharacterMessageSettingList(sessionStorage.getItem("TABID") as string, props.floorObjectId)
            .then(response => {
            let res = response as CharacterMessageSetting[];

            // 送られたキャラクターオブジェクトのメッセージ設定を保存する
            setCharacterMessageList(res);

        }).catch(err => {
            console.log(err);
        });
    }

    const downloadCharacterMessageSettingCsv = () => {
        setShowBackdropForDowndating(true);
        
        httpClient.outputCharacterMessageData(sessionStorage.getItem("TABID") as string, props.floorObjectId)
            .then(response => {
                if(response.data.toString() === ""){
                    setCsvDownloadError("対象のデータが存在しないため、ダウンロードできません。");
                    setCsvDownloadErrorDialogOpen(true);
                }else{
                    //レスポンスヘッダからファイル名を取得
                    const fileName = response.fileName;
                    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                    const blob = new Blob([bom, response.data], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);

                    //ダウンロード
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
            }).catch(err => {
                switch(err.code){
                    case 102005:    // NO DATA
                        setCsvDownloadError("対象のデータが存在しないため、ダウンロードできません。");
                        setCsvDownloadErrorDialogOpen(true);
                        break;
                    default: // ターゲットのキャラクターオブジェクトは別テナントetc
                        setCsvDownloadError("対象のデータが存在しないため、ダウンロードできません。");
                        setCsvDownloadErrorDialogOpen(true);
                }
            }).finally(() => {
                setShowBackdropForDowndating(false);
                setConfirmInfo({...confirmInfo,isOk: false});
            });
    }

    const uploadCSV = async () => {
        setShowBackdropForDowndating(true);

        let axiosFinished = false;
        const sleep = (msec:number) => new Promise(resolve => setTimeout(resolve, msec));

        httpClient.registCharacterMessageByCSV(sessionStorage.getItem("TABID") as string, 
            uploadCSVFile as File,
            props.floorObjectId)
            .then((e) => {
                if (e.code === '0000') {
                    setCsvUploadDialogOpen(false);
                    setCsvUploadFinishTitle("アップロード成功");
                    setCsvUploadFinishContents("アップロードが完了しました。");
                    setCsvUploadFinishDialogOpen(true);
                    setAddCharacterMessage(!addCharacterMessage);
                    setUploadCSVFile({});
                } else {
                    setCsvUploadFinishTitle("アップロード失敗");
                    setCsvUploadFinishContents("アップロードに失敗しました。\n設定ファイルに不備があります。");
                    setCsvUploadFinishDialogOpen(true);
                }
            }).catch(err => {
                setCsvUploadFinishTitle("アップロード失敗");
                setCsvUploadFinishContents("アップロードに失敗しました。\n設定ファイルに不備があります。");
                setCsvUploadFinishDialogOpen(true);
            }).finally(() => {
                axiosFinished = true;
                setShowBackdropForDowndating(false);
                setConfirmInfo({...confirmInfo, isOk: false});
        });
    
        while (!axiosFinished) {
            await sleep(1000);
        }
        //await sleep(1500);
    }

    // Dateを日付コンポーネント用のフォーマットに変換
    const convertDateFormat = (date: Date) => {
        let convertDate = date.getFullYear().toString() +
            "-" +
            ("00" + (date.getMonth()+1).toString()).slice(-2) +
            "-" +
            ("00" + date.getDate().toString()).slice(-2);
        return convertDate;
    }

    // yyyy-MM-ddとhh:mmを引数に取り、yyyy/MM/dd hh:mmのフォーマットに変換する
    const convertDateFormatFull = (date: string , time: string) => {
        let dateArr = date.split('-');
        let convertDate = dateArr[0] + "/" +
            dateArr[1] + "/" + 
            dateArr[2] + " " + time;
        return convertDate; 
    }

    // hh:mmを引数に取り、yyyy/MM/dd hh:mmのフォーマットに変換する(yyyy/MM/ddは現在のものを取得)
    const convertDateFormatFullFromhhmm = (time: string) => {
        let currentDate = new Date();
        let convertDate = currentDate.getFullYear() + 
            "/" +
            ("00" + (currentDate.getMonth()+1)).slice(-2) +
            "/" +
            ("00" + currentDate.getDate()).slice(-2) + " " + time;
        return convertDate;
    }

    // MM dd hh:mmを引数に取り、yyyy/MM/dd hh:mmのフォーマットに変換する(yyyyは現在のものを取得)
    const convertDateFormatFullFromMMddhhmm = (month: number, day: number, time: string) => {
        let currentDate = new Date();
        let convertDate = currentDate.getFullYear() +
            "/" +
            ("00" + month).slice(-2) +
            "/" +
            ("00" + day).slice(-2) + " " + time;
        return convertDate;
    }

    // timestampから年月日(yyyy/MM/dd)を抽出する
    const extractDateFromTimeStamp = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let month = fullDate.getMonth()+1;
        let date = fullDate.getFullYear() + '/' + month + '/' + fullDate.getDate();
        return date;
    }

    // timestampから年月日(0詰めのyyyy-MM-dd)を抽出する -> datepickerに設定する用
    const extractDateFromTimeStampVer2 = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let month = fullDate.getMonth()+1;
        let date = fullDate.getFullYear() + '-' + ('00' + month).slice(-2) + '-' + ('00' + fullDate.getDate()).slice(-2);
        return date;
    }

    // timestampから時間(hh:mm)を抽出する
    const extractTimeFromTimeStamp = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let time = fullDate.getHours() + ':' + ('00' + fullDate.getMinutes()).slice(-2);
        return time;
    }

    // timestampから時間(0詰めのhh:mm)を抽出する
    const extractTimeFromTimeStampVer2 = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let time = ('00' + fullDate.getHours()).slice(-2) + ':' + ('00' + fullDate.getMinutes()).slice(-2);
        return time;
    }

    // timestampから月を抽出する
    const extractMonthFromTimeStamp = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let month = fullDate.getMonth()+1;
        return month;
    }

    // timestampから日を抽出する
    const extractDayFromTimeStamp = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let day = fullDate.getDate();
        return day;
    }

    const extractDayOfWeekFromTimestamp = (timestamp: number) => {
        let fullDate = new Date(timestamp);
        let dayofweekIndex = fullDate.getDay();
        return dayOfWeek[dayofweekIndex];
    }

    const isFileEmptyCheck = (file: File) => {
        return file.name === undefined;
    }

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定　→　半角全角の区別をつけない　理由：半角の@やwは横幅が広く表示からはみ出てしまうため
        for (let i = 0; i < intext.length; i++) {
            /*if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }*/
            len = len + 1;
        }

        return len
    };

    // 入力URL長チェック
    const textLength = useMemo(() => {
        // inputTextが取得できない場合の対応
        if(inputText === null || inputText === undefined){
            return (
                <div> </div>
            );
        }
        let len = judgehz(inputText)
        if(len === 0){
            return (
                <div style={{ color: "red", marginTop: 10, fontSize: 12 }}>1～{props.maxLength}文字の入力が必要です。 </div>
            );
        }else if (props.maxLength < len) {
            return (
                <div style={{ color: "red", marginTop: 10, fontSize: 12 }}>入力した文字列が長すぎます。 </div>
            );
        }
    }, [props.maxLength, inputText]);

    const progressBar = useMemo(() => {
        let parsent = (judgehz(inputText) / props.maxLength) * 100;
        let progressColor = '#FFE1A2';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        );
    }, [inputText])

    const handleChangeCycle = (value :string) => {
        setSelectedCycle(value);
    }

    const handleChangeDayOfWeek = (value :string) => {
        setSelectedDayOfWeek(value);
    }

    const handleChangeMonth = (value :string) => {
        setSelectedMonth(Number(value));
    }

    const handleChangeDay = (value :string) => {
        setSelectedDay(Number(value));
    }

    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDisplayDate(e.target.value);
    }

    const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDisplayStartTime(e.target.value);
    }

    const handleChangeEndTime = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDisplayEndTime(e.target.value);
    }

    // Clear（ダイアログは閉じないで、テキストフィールドのみクリア）
    const onClearText = () => {
        setInputText('');
    };

    const handleClose = () => {
        props.onClickClose();
    };

    const handleCloseMessageEdit = () => {
        setOpenAddSetting(false);
        setOpenEditSetting(false);
    }

    const handleDisabled = () => {
        if (!isDisabled) {
          setIsDisabled(true);
        }
    };

    const handleOkMessageEdit = () => {
        if(isProcessing.current) return;

        if (!isDisabled && !isProcessing.current) {
            isProcessing.current = true; 
            setIsDisabled(true);
        }

        let len = judgehz(inputText);
        if(len === 0 || props.maxLength < len){
            return;
        }

        if(timeBlank || timeReverse){
            return;
        }

        let cycleIndex =  cycle.indexOf(selectedCycle);
        if(cycleIndex === 0 && (dateBlank || notExistDate || dateReverse)){
            return;
        }

        let tabId = sessionStorage.getItem("TABID") as string;
        // 表示サイクル次第で開始日時、終了日時のデータの作成方法が異なる
        let startDate = convertDateFormatFull(displayDate,displayStartTime);
        let endDate = convertDateFormatFull(displayDate,displayEndTime);
        if(cycle.indexOf(selectedCycle) === 1 || cycle.indexOf(selectedCycle) === 2){
            startDate = convertDateFormatFullFromhhmm(displayStartTime);
            endDate = convertDateFormatFullFromhhmm(displayEndTime);
        }else if(cycle.indexOf(selectedCycle) === 3){
            startDate = convertDateFormatFullFromMMddhhmm(selectedMonth,selectedDay,displayStartTime);
            endDate = convertDateFormatFullFromMMddhhmm(selectedMonth,selectedDay,displayEndTime);
        }
        // 表示サイクル次第で曜日のデータの作成方法が異なる
        let dayOfWeekForSend = 0;
        if(cycle.indexOf(selectedCycle) === 2){
            dayOfWeekForSend = dayOfWeek.indexOf(selectedDayOfWeek);
        }

        if(!openEditSetting){
            httpClient.addCharacterMessage(tabId, props.floorObjectId, cycleIndex, startDate, endDate, inputText, dayOfWeekForSend)
                .then(response => {
                    setOpenAddSetting(false);
                    setAddCharacterMessage(!addCharacterMessage);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                isProcessing.current = false; 
                setIsDisabled(false);
            });
        }else{ // 編集のフラグが立っているので、編集用APIを呼ぶ
            httpClient.editCharacterMessage(tabId, props.floorObjectId, cycleIndex, startDate, endDate, inputText, selectedMessage, dayOfWeekForSend)
                .then(response => {
                    setOpenEditSetting(false);
                    setOpenAddSetting(false);
                    setAddCharacterMessage(!addCharacterMessage);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                isProcessing.current = false; 
                setIsDisabled(false);
            });
        }
    }

    const handleDeleteOk = () => {
        let tabId = sessionStorage.getItem("TABID") as string;
        httpClient.deleteCharacterMessage(tabId, props.floorObjectId, selectedMessage)
            .then(response => {
                setOpenDeliteConfirm(false);
                setAddCharacterMessage(!addCharacterMessage);
            }).catch(err => {
                console.log(err);
            });
    }

    // 追加ボタン押下時 -> 押下時にデフォルトで初期化
    const handleAddOpen = () => {
        setSelectedMessage(0);
        setSelectedCycle(cycle[0]);
        setSelectedDayOfWeek(dayOfWeek[0]);
        setSelectedMonth(month[0]);
        setSelectedDay(day30[0]);
        setDisplayDate("");
        setDisplayStartTime("00:00");
        setDisplayEndTime("23:59");
        setInputText("");

        setOpenAddSetting(true);
    }

    // 編集ボタン押下時 -> 押下時に選択したメッセージの内容で初期化
    const handleEditOpen = (targetMessage: CharacterMessageSetting) => {
        setOpenEditSetting(true);
        setSelectedMessage(targetMessage.id);
        setSelectedCycle(cycle[targetMessage.cycle]);
        setSelectedDayOfWeek(dayOfWeek[targetMessage.dayOfWeek]);
        setSelectedMonth(extractMonthFromTimeStamp(targetMessage.startTime));
        setSelectedDay(extractDayFromTimeStamp(targetMessage.startTime));
        setDisplayDate(extractDateFromTimeStampVer2(targetMessage.startTime));
        setDisplayStartTime(extractTimeFromTimeStampVer2(targetMessage.startTime));
        setDisplayEndTime(extractTimeFromTimeStampVer2(targetMessage.endTime));
        setInputText(targetMessage.message);

        setOpenAddSetting(true);
    }

    // 削除ボタン押下時
    const handleDeleteOpen = (targetMessage: CharacterMessageSetting) => {
        setSelectedMessage(targetMessage.id);
        setOpenDeliteConfirm(true);
    }

    // ファイル選択ハンドラー
    const handleClickFileSelectButton = async () => {
        await showOpenFileDialog();
    }

    const handleClickCSVUploadCancel = () => {
        setUploadCSVFile({});
        setCsvUploadDialogOpen(false);
    }

    const showOpenFileDialog = () => {
        return new Promise(resolve => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = '.csv';
          input.onchange = (ev) => {
            let eventTarget: HTMLInputElement = (ev.target as HTMLInputElement);
            if (eventTarget !== null && eventTarget.files !== null) {
              setUploadCSVFile(eventTarget.files[0]);
              if (uploadCSVFile != null) {
                resolve(uploadCSVFile);
              }
            }
          };
          input.click();
        });
    };

    // const createRandomNum = (max: number) => {
    //     return Math.floor(Math.random()*max);
    // }

    const drawMessageSettingList = () => {
        return(
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12} className={classes.settingTitle}>
                    メッセージ設定一覧
                </Grid>
                <Grid item xs={12} style={{paddingTop: 5}}>
                    このオブジェクトに設定されているメッセージの一覧です。
                </Grid>
            </Grid>
        );
    }

    const drawMessageSettingTable = () => {
        return(
            <Grid container style={{paddingTop: 5}}>
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{maxHeight: 300}}>
                        <Table stickyHeader aria-label="character message table" style={{tableLayout:'fixed'}}>
                            <TableHead>
                                <TableRow >
                                    <TableCell key = 'cycle' className={classes.headCell} style={{ maxWidth: 50, minWidth: 50, width: 50 }} >表示サイクル</TableCell>
                                    <TableCell key = 'dayofweek' className={classes.headCell} style={{ maxWidth: 30, minWidth: 30, width: 30 }} >曜日</TableCell>
                                    <TableCell key = 'date' className={classes.headCell} style={{ maxWidth: 50, minWidth: 50, width: 50 }} >表示日付</TableCell>
                                    <TableCell key = 'time' className={classes.headCell} style={{ maxWidth: 60, minWidth: 60, width: 60 }} >表示時間</TableCell>
                                    <TableCell key = 'message' className={classes.headCell} style={{ maxWidth: 130, minWidth: 130, width: 130, textAlign: 'left' }} >メッセージ</TableCell>
                                    <TableCell key = 'edit' className={classes.headCell} style={{ maxWidth: 40, minWidth: 40, width: 40 }}>編集</TableCell>
                                    <TableCell key = 'delete' className={classes.headCell} style={{maxWidth: 40, minWidth: 40, width: 40 }}>削除</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {characterMessageList.map((characterMessage: CharacterMessageSetting, index) => {
                                return (
                                    <TableRow key={index} >
                                    <TableCell className={classes.cell}>
                                        {cycle[characterMessage.cycle]}
                                    </TableCell >
                                    <TableCell className={classes.cell}>
                                        {characterMessage.cycle === 2 ?
                                            dayOfWeek[characterMessage.dayOfWeek]
                                            :
                                            '-'
                                        }
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        {characterMessage.cycle === 1 || characterMessage.cycle === 2 ?
                                            '-'
                                            :
                                            extractDateFromTimeStamp(characterMessage.startTime)
                                        }
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        {extractTimeFromTimeStamp(characterMessage.startTime)}～{extractTimeFromTimeStamp(characterMessage.endTime)}
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                        {characterMessage.message}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Button onClick={() => handleEditOpen(characterMessage)} variant='contained' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '15px', width: 50 }} >編集</Button>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Button onClick={() => handleDeleteOpen(characterMessage)} variant='contained' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '15px', width: 50 }} >削除</Button>
                                    </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }

    const drawAddSetting = () => {
        return(
            <Grid container style={{paddingTop: 15}}>
                <Grid item xs={12} className={classes.settingTitle}>
                    設定追加
                </Grid>
                <Grid item xs={12} style={{paddingTop: 5}}>
                    このオブジェクトが表示するメッセージを追加できます。
                </Grid>
                <Grid item xs={12} style={{paddingTop: 5}}>
                    <Button onClick={() => handleAddOpen()} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 200 }} >メッセージ設定を追加</Button>
                </Grid>
            </Grid>
        );
    }

    const drawCsvSetting = () => {
        return(
            <Grid container style={{paddingTop: 15}}>
                <Grid item xs={12} className={classes.settingTitle}>
                    メッセージ設定一括変更
                </Grid>
                <Grid item xs={12} style={{paddingTop: 5}}>             
                    専用の設定ファイルをアップロードして、メッセージ設定を一括で変更できます。
                </Grid>
                <Grid item xs={5} style={{paddingTop: 5}}>
                    <Button onClick={() => {setConfirmInfo({...confirmInfo, open: true, message: 'メッセージデータを出力します。よろしいですか？', type: 0})}} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 210 }} >現在の設定をダウンロード</Button>
                </Grid>
                <Grid item xs={5} style={{paddingTop: 5}}>
                    <Button onClick={() => setCsvUploadDialogOpen(true)} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 210 }} >設定ファイルをアップロード</Button>
                </Grid>
            </Grid>
        );
    }

    const drawEditMessageDialog = useMemo(() =>
        <BaseDialog
            id="EditMessageDialog"
            open={openAddSetting}
            aria-labelledby="EditMessage"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                {openEditSetting ? "メッセージ設定編集" : "メッセージ設定追加"}                
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} >
                        <div style={{color: '#00000061', fontSize: 11, paddingBottom: 5}}>表示サイクル</div>
                        <label>
                            <select 
                                style={{fontSize:18, width: Utility.getType() === 2 ? 95 : 100}} 
                                onChange={(e) => handleChangeCycle(e.target.value as string)} 
                                value={selectedCycle}>
                                {cycle.map(arrayItem =>
                                    <option value={arrayItem}>{arrayItem}</option>
                                )}
                            </select>
                        </label>
                    </Grid>
                </Grid>
                {cycle.indexOf(selectedCycle) === 0 &&
                    <Grid container justify='center' alignItems='center' style={{paddingTop:20}}>
                        <Grid item xs={3}>
                            <div style={{color: '#00000061', fontSize: 11}}>表示年月日</div>
                        </Grid>
                        <Grid item xs={9} style={{marginBottom: -12}}>
                            <FormControl error={dateBlank || dateReverse || notExistDate}>
                                <FormHelperText id="characterObjectHelperText" style={{color: 'red'}}>{notExistDate ? "存在しない日付です": dateBlank ? "未設定の日付があります" : dateReverse ? "過去日付が設定されています" : ""}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ThemeProvider theme={textFieldTheme}>
                            <TextField 
                                style={{width: 180}} 
                                id="date" 
                                label="" 
                                type="date" 
                                onChange={(e) => handleChangeDate(e)} 
                                value={displayDate}
                                disabled={cycle.indexOf(selectedCycle) === 1 ? true : false}
                                InputLabelProps={{shrink: true,}}
                                inputProps={{min: strToday, max: strMaxDate}}/>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                }
                {cycle.indexOf(selectedCycle) === 2 &&
                    <Grid container justify='center' alignItems='center' style={{paddingTop:20}}>
                        <Grid item xs={12} >
                            <div style={{color: '#00000061', fontSize: 11, paddingBottom: 5}}>曜日</div>
                            <label>
                                <select 
                                    style={{fontSize:18, width: Utility.getType() === 2 ? 70 : 80}} 
                                    onChange={(e) => handleChangeDayOfWeek(e.target.value as string)} 
                                    value={selectedDayOfWeek}>
                                    {dayOfWeek.map(arrayItem =>
                                        <option value={arrayItem}>{arrayItem}</option>
                                    )}
                                </select>
                            </label>
                        </Grid>
                    </Grid>
                }
                {cycle.indexOf(selectedCycle) === 3 &&
                    <Grid container justify='flex-start' alignItems='center' style={{paddingTop:20}}>
                        <Grid item xs={12} >
                            <div style={{color: '#00000061', fontSize: 11, paddingBottom: 5}}>表示日付</div>
                        </Grid>
                        <Grid item xs={2}>
                            <select 
                                style={{fontSize:18, width: Utility.getType() === 2 ? 50 : 50}} 
                                onChange={(e) => handleChangeMonth(e.target.value as string)} 
                                value={selectedMonth}>
                                {month.map(arrayItem =>
                                    <option value={arrayItem}>{arrayItem}</option>
                                )}
                            </select>
                        </Grid>
                        <Grid item xs={1}>月</Grid>
                        <Grid item xs={2}>
                            <select 
                                style={{fontSize:18, width: Utility.getType() === 2 ? 50 : 50}} 
                                onChange={(e) => handleChangeDay(e.target.value as string)} 
                                value={selectedDay}>
                                {selectedMonth === 2 ?
                                    day28.map(arrayItem =>
                                        <option value={arrayItem}>{arrayItem}</option>)
                                 :
                                 (selectedMonth === 4 || selectedMonth === 6 || selectedMonth === 9 || selectedMonth === 11) ?
                                    day30.map(arrayItem =>
                                        <option value={arrayItem}>{arrayItem}</option>)
                                 :
                                    day31.map(arrayItem =>
                                        <option value={arrayItem}>{arrayItem}</option>)
                                }
                            </select>
                        </Grid>
                        <Grid item xs={1}>日</Grid>
                    </Grid>
                }
                <Grid container justify='flex-start' alignItems='center' style={{paddingTop:20}}>
                    <Grid item xs={3} style={{marginBottom: -12}}>
                        <div style={{color: '#00000061', fontSize: 11}}>表示時間</div>
                    </Grid>
                    <Grid item xs={9} style={{marginBottom: -12}}>
                        <FormControl error={timeBlank || timeReverse}>
                            <FormHelperText id="characterObjectHelperText" style={{color: 'red'}}>{timeBlank ? "未設定の時間があります": timeReverse ? "開始時刻は終了時刻の以前の時間に設定して下さい" : ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id="time"
                            label=""
                            type="time"
                            style = {{ width:100 }}
                            onChange={(e) => handleChangeStartTime(e)}
                            value={displayStartTime}
                            InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={2} style={{paddingBottom:10, paddingRight:37}}>
                    　　～　　
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id="time"
                            label=""
                            type="time"
                            style = {{ width:100 }}
                            onChange={(e) => handleChangeEndTime(e)}
                            value={displayEndTime}
                            InputLabelProps={{shrink: true,}}/>
                    </Grid>
                </Grid>
                <Grid container justify='center' alignItems='center' style={{paddingTop: 10}}>
                    <Grid item xs={3} style={{marginBottom: -12}}>
                        <div style={{color: '#00000061', fontSize: 11, paddingBottom: 5}}>メッセージ</div>
                    </Grid>
                    <Grid item xs={9}>
                        <DialogContentText style={{ marginBottom: "0px" }} >
                            {progressBar}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <ThemeProvider theme={textFieldTheme}>
                        <TextField
                            id="outlined-basic"
                            //label={"イベント名"}
                            variant="outlined"
                            multiline
                            autoFocus
                            margin='dense'
                            fullWidth
                            value={inputText}
                            autoComplete={'off'}
                            onChange={e => setInputText(e.target.value)}
                            style={{ pointerEvents: 'auto'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={onClearText}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        </ThemeProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={async () => {await handleDisabled();handleOkMessageEdit();}} color="primary" style={{ pointerEvents: isDisabled ? 'none' : 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                <Button onClick={handleCloseMessageEdit} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [openAddSetting, selectedCycle, selectedDayOfWeek, selectedMonth, selectedDay, displayDate, displayStartTime, displayEndTime, timeBlank, timeReverse, inputText, dateBlank, dateReverse, notExistDate]);

    const drawDeleteConfirmDialog = useMemo(() => 
        <BaseDialog
            id="DeleteConfirmDialog"
            open={openDeliteConfirm}
            onClose={() => setOpenDeliteConfirm(false)}
            aria-labelledby="DeleteConfirmDialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                メッセージ設定削除
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                        選択したメッセージ設定を削除します。
                        <br/>
                        よろしいですか？
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteOk} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} >OK</Button>
                <Button onClick={() => setOpenDeliteConfirm(false)} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [openDeliteConfirm]);

    const drawCsvDownloadError = useMemo(() =>
        <BaseDialog
            id="CsvDownloadErrorDialog"
            open={csvDownloadErrorDialogOpen}
            onClose={() => setCsvDownloadErrorDialogOpen(false)}
            aria-labelledby="CsvDownloadErrorDialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                CSVダウンロードエラー
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                        {csvDownloadError}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setCsvDownloadErrorDialogOpen(false)} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
    , [csvDownloadErrorDialogOpen])

    const drawCsvUpload = useMemo(() =>
        <BaseDialog
            id="CsvUploadDialog"
            open={csvUploadDialogOpen}
            //onClose={() => handleClickCSVUploadCancel()}
            aria-labelledby="CsvUploadDialog"
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="CsvUploadDialogTitle" className={classes.dialogTitle}>
                CSVアップロード
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                        アップロードする設定ファイルを選択して下さい。
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                        autoFocus
                        margin="dense"
                        id="characterMessageCSVFile"
                        name="characterMessageCSVFile"
                        label="ファイル名"
                        variant={'outlined'}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        value={isFileEmptyCheck(uploadCSVFile as File) ? "選択されていません。":(uploadCSVFile as File).name}
                        /*InputProps={{
                            readOnly:true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant="contained" color="primary" size="small" onClick={() => handleClickFileSelectButton()}>ファイル選択</Button>
                                </InputAdornment>
                            )
                        }}*/
                        />
                    </Grid>
                    <Grid item xs={2} style={{marginLeft: 15}}>
                        <Button variant="contained" color="primary" onClick={() => handleClickFileSelectButton()} style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 120 }}>ファイル選択</Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmInfo({...confirmInfo, open: true, message: '表示時間データをアップロードします。よろしいですか？', type: 1})} color="primary" style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '31px', width: 100 }} disabled = {uploadCSVFile instanceof File ? false : true}>OK</Button>
                <Button onClick={() => handleClickCSVUploadCancel()} color="primary" style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 100  }} >キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [csvUploadDialogOpen,uploadCSVFile])

    const drawCsvUploadError = useMemo(() =>
        <BaseDialog
            id="CsvUploadErrorDialog"
            open={csvUploadFinishDialogOpen}
            onClose={() => setCsvUploadFinishDialogOpen(false)}
            aria-labelledby="CsvUploadFinishDialog"
            maxWidth="xs"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="CsvUploadFinishDialogTitle" className={classes.dialogTitle}>
                {csvUploadFinishTitle}
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12} style={{paddingTop: 5}}>
                        {csvUploadFinishContents}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setCsvUploadFinishDialogOpen(false)} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
    , [csvUploadFinishDialogOpen])

    const draw = useMemo(() =>
        <BaseDialog
            id="CharacterMessageEditDialog"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="CharacterMessageEditDialog"
            maxWidth="md"
            fullWidth={true}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogTitle id="avatarCustomizeDialogTitle" className={classes.dialogTitle}>
                オブジェクトメッセージ設定
            </DialogTitle>
            <DialogContent>
                {drawMessageSettingList()}
                {drawMessageSettingTable()}
                {drawAddSetting()}
                {drawCsvSetting()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus style={{color: '#006FBC'}}>
                    閉じる
                </Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [props.open, characterMessageList]);

    const confirm = useMemo(() =>
        <BaseDialog disableBackdropClick disableEscapeKeyDown open={confirmInfo.open} onClose={() => { setConfirmInfo({ ...confirmInfo, open: false })}}
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <DialogContent>
            <DialogContentText>{confirmInfo.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: true }) }}>OK</Button>
            <Button variant={"text"} color="primary" onClick={() => { setConfirmInfo({ ...confirmInfo, open: false, isOk: false }) }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
    , [confirmInfo.open]);

    const downdating = useMemo(() =>
        <BaseDialog className={classes.backdrop} open={showBackdropForDowndating} 
            PaperProps={{
                style: {
                    border: '6px solid #57BBFF',
                    borderRadius: '25px',
                }
            }}
        >
            <Slide direction="up" in={showBackdropForDowndating}>
            <Card className={classes.card}>
                <CardContent>
                <ThemeProvider theme={circularTheme}>
                    <CircularProgress size={55} style={{ marginBottom: 18, marginLeft:14 }} color='primary' />
                </ThemeProvider>
                <Typography variant="subtitle2" >{confirmInfo.type === 0 ? 'データ取得中' : 'データ登録中'}</Typography>
                </CardContent>
            </Card>
            </Slide>
        </BaseDialog>
    , [showBackdropForDowndating]);

    return (
        <Fragment>
            {draw}
            {drawEditMessageDialog}
            {drawDeleteConfirmDialog}
            {drawCsvDownloadError}
            {drawCsvUpload}
            {drawCsvUploadError}
            {confirm}
            {downdating}
        </Fragment>
    )
}

export const CharacterMessageEditDialogRef = forwardRef(CharacterMessageEditDialog);

import React, { Fragment, useRef, useState, useEffect, useMemo } from 'react'
import axios, { AxiosResponse } from 'axios';
import { withStyles, makeStyles, createStyles, Theme, useTheme, createMuiTheme } from '@material-ui/core/styles';
import {Backdrop, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, IconButton, Paper, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Breadcrumbs, } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import BaseDialog from '../user/BaseDialog';

import { userListConst } from './UserListConst';

import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import JfsClient from "@fsi/jfs-sdk";

export type HeadCell = {
  id: string;
  label: string;
};

type SelectableTableHeadProps = {
  onLabelClick: () => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  headCells: HeadCell[];
  checked: boolean;
  indeterminate: boolean;
};

export const SelectableTableHead: React.VFC<SelectableTableHeadProps> = ({
  onLabelClick,
  onSelectAllClick,
  headCells,
  checked,
  indeterminate,
}) => {
  return (
    <TableHead style={{width: 140, marginTop: -5,float: 'left'}}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={indeterminate}
            checked={checked}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} padding={"none"} onClick={onLabelClick}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useRowSelect = (
  rowIds: number[],
  initialSelectedRowIds: number[] = []
): {
  selectedRowIds: number[];
  isSelected: (rowId: number) => boolean;
  isSelectedAll: boolean;
  isIndeterminate: boolean;
  toggleSelected: (id: number) => void;
  toggleSelectedAll: () => void;
  toggleSelectedAllClear: () => void;
} => {
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>(
    initialSelectedRowIds
  );

  const isSelected = (rowId: number) => selectedRowIds.includes(rowId);
  const isSelectedAll =
    rowIds.length > 0 && selectedRowIds.length === rowIds.length;
  const isIndeterminate =
    selectedRowIds.length > 0 && selectedRowIds.length < rowIds.length;

  const toggleSelected = (rowId: number) => {
    isSelected(rowId)
      ? setSelectedRowIds(
          selectedRowIds.filter((selectedId) => selectedId !== rowId)
        )
      : setSelectedRowIds([...selectedRowIds, rowId]);
  };
  const toggleSelectedAll = () => {
    isSelectedAll ? setSelectedRowIds([]) : setSelectedRowIds(rowIds);
  };
  const toggleSelectedAllClear = () => {
    setSelectedRowIds([]);
  };

  return {
    selectedRowIds,
    isSelected,
    isSelectedAll,
    isIndeterminate,
    toggleSelected,
    toggleSelectedAll,
    toggleSelectedAllClear,
  };
};

class FinishInfo {
  open: boolean = true;
  message: string = "";
  password: null | string = null;

  constructor(init: Partial<FinishInfo>) {
    Object.assign(this, init);
  }
}

class Tag {
  item: string = '';
  value: string = '';
  profileOrder: number = 0;
}

class SearchData {
  public subId: string = '';
  public loginId: string = '';
  public displayName: string = '';
  public tag1: string = '';
  public tag2: string = '';
  public tag3: string = '';
  public tags: Tag[] = [];
}

export default function AttendanceStatusCheck() {
  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;
  const tabId = sessionStorage.getItem('TABID') || '';
  const classes = useStyles();

  const [crudType, setCrudType] = useState(0); // 0: get, 1: register 
  const [isLoading, setLoading] = useState(false);

  const [userProfileSummaryList, setUserProfileSummaryList] = React.useState<UserProfileSummaryData[]>([]);

  /** ソート用 */
  const [selectedDisplayName, setSelectedDisplayName] = React.useState(false);
  const [selectedTag1, setSelectedTag1] = React.useState(false);
  const [selectedTag2, setSelectedTag2] = React.useState(false);
  const [selectedTag3, setSelectedTag3] = React.useState(false);
  const [sortDisplay, setSortDisplay] = React.useState(false);
  const [sortTag1, setSortTag1] = React.useState(false);
  const [sortTag2, setSortTag2] = React.useState(false);
  const [sortTag3, setSortTag3] = React.useState(false);

  /** pagenation */
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(userListConst.table.rowsPerPage);
  /** message */
  const [finishInfo, setFinishInfo] = useState(new FinishInfo({ open: false }));
  /** エラー画面用 */
  const [errorOpened, setErrorOpened] = React.useState(false);
  const [errorData, setErrorData] = React.useState({
    code: '',
    message: '',
    errorDetail: [],
  });
    const clearErrorData = () => {
    errorData.code = '';
    errorData.message = '';
    if (errorData.errorDetail === null || errorData.errorDetail === undefined) {
      errorData.errorDetail = [];
    } else {
      errorData.errorDetail.length = 0;
    }
    return errorData;
  }
  
  /** 検索用 */
  const [searchData, setSearchData] = React.useState(new SearchData());
  const clearSearchData = () => {
    // stateへの直接書き込みはReactのanti paternなので修正を行ったが、修正後動作確認で、
    // 「全ユーザーリストダウンロード」の挙動が変わってしまったため、修正前のロジックへ戻した。
    searchData.subId = '';
    // searchData.loginId = '';
    searchData.displayName = '';
    searchData.tag1 = '';
    searchData.tag2 = '';
    searchData.tag3 = '';
    searchData.tags.length = 0;
    setSearchData(Object.assign({}, searchData));
    return searchData;
  }
  const clearSearchElement = () => {
    /*
    let loginId_search = document.getElementById('loginId_search') as HTMLInputElement;
    if (loginId_search != null) {
      loginId_search.value = '';
    }
    */
    let displayName_search = document.getElementById('displayName_search') as HTMLInputElement;
    if (displayName_search != null) {
      displayName_search.value = '';
    }
    let tag1_search = document.getElementById('tag1_search') as HTMLInputElement;
    if (tag1_search != null) {
      tag1_search.value = '';
    }
    let tag2_search = document.getElementById('tag2_search') as HTMLInputElement;
    if (tag2_search != null) {
      tag2_search.value = '';
    }
    let tag3_search = document.getElementById('tag3_search') as HTMLInputElement;
    if (tag3_search != null) {
      tag3_search.value = '';
    }
  }

  /** TablePaginationActions で使用 */
  const handleChangePage = (event: any, newPage: number) => {
    if(!selectedDisplayName && !selectedTag1 && !selectedTag2 && !selectedTag3){ // タブがどれも選択されていない(ソート状態になっていない)
      executeSearchByPage(newPage + 1); // pagenationのpageは0が先頭, APIのpageは1が先頭
    }else{ // タブのどれかが選択されている(選択されてタブのソート状態でデータを取ってくる)
      if(selectedDisplayName){
        executeSearchByPageAndSort(newPage+1, 0, sortDisplay);
      }else if(selectedTag1){
        executeSearchByPageAndSort(newPage+1, 1, sortTag1);
      }else if(selectedTag2){
        executeSearchByPageAndSort(newPage+1, 2, sortTag2);
      }else {
        executeSearchByPageAndSort(newPage+1, 3, sortTag3);
      }
    }
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  // ユーザープロフィール一覧表示
  const fetchUSerProfileSummaryList = async (page: number) => {
    // axios.get('/api/v1/admin/pointmanager/list', {
    //   params: {
    //     loginId: searchData.loginId,
    //     name: searchData.displayName,
    //     page: page,
    //     count: rowsPerPage,
    //     tabId: tabId,
    //   }
    // })
    httpClient.getUserProfileList(
      tabId,
      searchData.displayName,
      searchData.tag1,
      searchData.tag2,
      searchData.tag3,
      page,
      rowsPerPage,
    )
    .then((res) => {
      const _userProfileSummaryList = res.userProfileSummaryList
        ? res.userProfileSummaryList.map((v: any, i: number, d: any) => {
          const userProfileSummary: UserProfileSummaryData = new UserProfileSummaryData();
          userProfileSummary.id = v.id;
          userProfileSummary.userName = v.userName;
          userProfileSummary.displayName = v.displayName;
          userProfileSummary.role = v.role;
          userProfileSummary.tenantId = v.tenantId;
          userProfileSummary.tenantContractId = v.tenantContractId;
          userProfileSummary.i1 = v.i1;
          userProfileSummary.v1 = v.v1;
          userProfileSummary.i2 = v.i2;
          userProfileSummary.v2 = v.v2;
          userProfileSummary.i3 = v.i3;
          userProfileSummary.v3 = v.v3;
          userProfileSummary.i4 = v.i4;
          userProfileSummary.v4 = v.v4;
          userProfileSummary.i5 = v.i5;
          userProfileSummary.v5 = v.v5;
          userProfileSummary.i6 = v.i6;
          userProfileSummary.v6 = v.v6;
          userProfileSummary.i7 = v.i7;
          userProfileSummary.v7 = v.v7;
          userProfileSummary.i8 = v.i8;
          userProfileSummary.v8 = v.v8;
          userProfileSummary.i9 = v.i9;
          userProfileSummary.v9 = v.v9;
          userProfileSummary.i10 = v.i10;
          userProfileSummary.v10 = v.v10;
          userProfileSummary.attendanceType = v.attendanceType;
          return userProfileSummary;
        })
      : [] as UserProfileSummaryData[];
      if (res.pageInfo) setTotalCount(res.pageInfo.totalCount);

      setUserProfileSummaryList(_userProfileSummaryList);
      // setPointManagerListOrg(JSON.parse(JSON.stringify(_pointSummaryList)));
      setLoading(false);

    }).catch(err => {
      assignErrorData(err);
    })
  }

  // ユーザープロフィール情報を指定のソートをかけて取ってくる
  const fetchUSerProfileSummaryListOrderBy = async (page: number, sortTarget: number, sortType: boolean) => {
    // sortTypeがtrueなら降順、sortTypeがfalseなら昇順
    httpClient.getUserProfileListOrderBy(
      tabId,
      searchData.displayName,
      searchData.tag1,
      searchData.tag2,
      searchData.tag3,
      page,
      rowsPerPage,
      sortTarget,
      sortType,
    )
    .then((res) => {
      const _userProfileSummaryList = res.userProfileSummaryList
        ? res.userProfileSummaryList.map((v: any, i: number, d: any) => {
          const userProfileSummary: UserProfileSummaryData = new UserProfileSummaryData();
          userProfileSummary.id = v.id;
          userProfileSummary.userName = v.userName;
          userProfileSummary.displayName = v.displayName;
          userProfileSummary.role = v.role;
          userProfileSummary.tenantId = v.tenantId;
          userProfileSummary.tenantContractId = v.tenantContractId;
          userProfileSummary.i1 = v.i1;
          userProfileSummary.v1 = v.v1;
          userProfileSummary.i2 = v.i2;
          userProfileSummary.v2 = v.v2;
          userProfileSummary.i3 = v.i3;
          userProfileSummary.v3 = v.v3;
          userProfileSummary.i4 = v.i4;
          userProfileSummary.v4 = v.v4;
          userProfileSummary.i5 = v.i5;
          userProfileSummary.v5 = v.v5;
          userProfileSummary.i6 = v.i6;
          userProfileSummary.v6 = v.v6;
          userProfileSummary.i7 = v.i7;
          userProfileSummary.v7 = v.v7;
          userProfileSummary.i8 = v.i8;
          userProfileSummary.v8 = v.v8;
          userProfileSummary.i9 = v.i9;
          userProfileSummary.v9 = v.v9;
          userProfileSummary.i10 = v.i10;
          userProfileSummary.v10 = v.v10;
          userProfileSummary.attendanceType = v.attendanceType;
          return userProfileSummary;
        })
      : [] as UserProfileSummaryData[];
      if (res.pageInfo) setTotalCount(res.pageInfo.totalCount);

      setUserProfileSummaryList(_userProfileSummaryList);
      // setPointManagerListOrg(JSON.parse(JSON.stringify(_pointSummaryList)));
      setLoading(false);

    }).catch(err => {
      assignErrorData(err);
    })
  }

  /**
  * useEffect
  */
  useEffect(() => {
    setCrudType(0);
    setLoading(true);
    executeSearchByPage(1);
  }, []);

  useEffect(() => {
    executeSearchByPage(1);
  }, [rowsPerPage]);

  // 処理中表示
  const drawLoading = useMemo(() => {
    const operation = crudType === 0 ? '取得' : '登録';

    return (
      <Backdrop className={classes.backdrop} open={isLoading} >
        <Slide direction="up" in={isLoading}>
          <Card className={classes.loadingCard}>
            <CardContent>
              <ThemeProvider theme={circularTheme}>
                <CircularProgress size={55} style={{ marginBottom: 15 }} color='primary' />
              </ThemeProvider>
              <Typography variant="subtitle2" >{`データ${operation}中`}</Typography>
            </CardContent>
          </Card>
        </Slide>
      </Backdrop>
    );
  }, [crudType, isLoading]);

  // ユーザープロフィール一覧のリロード
  /*const handleReloadUserProfile = (page: number) => {
    setLoading(true);
    fetchUSerProfileSummaryList(page);
    //toggleSelectedAllClear();
  }*/

  
  /**
   * 検索ボタンのハンドラ
   * 新規検索を行い、検索結果の先頭ページを表示する
   */
  const handleSearchBtn = () => {
    executeSearchByPage(1);
    setSelectedDisplayName(false);
    setSelectedTag1(false);
    setSelectedTag2(false);
    setSelectedTag3(false);
    setSortDisplay(false);
    setSortTag1(false);
    setSortTag2(false);
    setSortTag3(false);
  }
  const handleClearBtn = () => {
    clearSearchText();
    setSelectedDisplayName(false);
    setSelectedTag1(false);
    setSelectedTag2(false);
    setSelectedTag3(false);
    setSortDisplay(false);
    setSortTag1(false);
    setSortTag2(false);
    setSortTag3(false);
  }

  /**
   * タブクリック用(ソート用)ハンドラ
   * タブをクリックしてソートする関数は一旦使用しない方向で決定
   */
  const handleClickTab = (sortTab: number, currentPage: number) => {

    switch(sortTab){
      case 0: // 表示名クリック
        setSelectedDisplayName(true);
        setSelectedTag1(false);
        setSelectedTag2(false);
        setSelectedTag3(false);
        setSortDisplay(!sortDisplay);
        setSortTag1(false);
        setSortTag2(false);
        setSortTag3(false);
        executeSearchByPageAndSort(currentPage,sortTab,!sortDisplay); // sortがtrueなら昇順、falseなら降順だが、まだsortは更新されていないので!sortを利用
        break;
      case 1: // タグ1クリック
        setSelectedDisplayName(false);
        setSelectedTag1(true);
        setSelectedTag2(false);
        setSelectedTag3(false);
        setSortDisplay(false);
        setSortTag1(!sortTag1);
        setSortTag2(false);
        setSortTag3(false);
        executeSearchByPageAndSort(currentPage,sortTab,!sortTag1); // sortがtrueなら昇順、falseなら降順だが、まだsortは更新されていないので!sortを利用
        break;
      case 2: // タグ2クリック
        setSelectedDisplayName(false);
        setSelectedTag1(false);
        setSelectedTag2(true);
        setSelectedTag3(false);
        setSortDisplay(false);
        setSortTag1(false);
        setSortTag2(!sortTag2);
        setSortTag3(false);
        executeSearchByPageAndSort(currentPage,sortTab,!sortTag2); // sortがtrueなら昇順、falseなら降順だが、まだsortは更新されていないので!sortを利用
        break;
      case 3: // タグ3クリック
        setSelectedDisplayName(false);
        setSelectedTag1(false);
        setSelectedTag2(false);
        setSelectedTag3(true);
        setSortDisplay(false);
        setSortTag1(false);
        setSortTag2(false);
        setSortTag3(!sortTag3);
        executeSearchByPageAndSort(currentPage,sortTab,!sortTag3); // sortがtrueなら昇順、falseなら降順だが、まだsortは更新されていないので!sortを利用
        break;
      default:
        break;
    }
  }

  /**
   * 「状態更新」ボタン用ハンドラ
   */
  const handleUpdateBtn = () => {
    if(!selectedDisplayName && !selectedTag1 && !selectedTag2 && !selectedTag3){ // タブがどれも選択されていない(ソート状態になっていない)
      executeSearchByPage(page + 1); // pagenationのpageは0が先頭, APIのpageは1が先頭
    }else{ // タブのどれかが選択されている(選択されてタブのソート状態でデータを取ってくる)
      if(selectedDisplayName){
        executeSearchByPageAndSort(page+1, 0, sortDisplay);
      }else if(selectedTag1){
        executeSearchByPageAndSort(page+1, 1, sortTag1);
      }else if(selectedTag2){
        executeSearchByPageAndSort(page+1, 2, sortTag2);
      }else {
        executeSearchByPageAndSort(page+1, 3, sortTag3);
      }
    }
  };

  /**
   * 出席状態の判定
   * @param stateNum 
   * @returns 
   */
  const setAttendanceState = (stateNum: number) => {
    switch (stateNum) {
      case 0:
        return "滞在中";
      case 1:
        return "欠席";
      case 2:
        return "帰宅済み";
      case 3:
        return "オフライン";
      default:
        return "";
    }
  }

  const setAttendanceColor = (stateNum: number) => {
    switch (stateNum) {
      case 0:
        return "#6495ED";
      case 1:
        return "#696969";
      case 2:
        return "#7CFC00";
      case 3:
        return "#FF7F50";
      default:
        return "";
    }
  }

  /** ユーザー検索を実行し、ページを設定する
   * @param page
   */
  const executeSearchByPage = async (page: number) => {
    clearErrorData();
    clearSearchData();
    setSearchData(Object.assign(searchData, {
      // loginId: (document.getElementById('loginId_search') as HTMLInputElement)?.value,
      displayName: (document.getElementById('displayName_search') as HTMLInputElement)?.value,
      tag1: (document.getElementById('tag1_search') as HTMLInputElement)?.value,
      tag2: (document.getElementById('tag2_search') as HTMLInputElement)?.value,
      tag3: (document.getElementById('tag3_search') as HTMLInputElement)?.value,
    }));
    await fetchUSerProfileSummaryList(page);
    setLoading(false);
    setPage(page - 1);
    //toggleSelectedAllClear();
    return true;
  }

  const executeSearchByPageAndSort = async (page: number, sortTarget: number, sortType: boolean) => {
    clearErrorData();
    clearSearchData();
    setSearchData(Object.assign(searchData, {
      // loginId: (document.getElementById('loginId_search') as HTMLInputElement)?.value,
      displayName: (document.getElementById('displayName_search') as HTMLInputElement)?.value,
      tag1: (document.getElementById('tag1_search') as HTMLInputElement)?.value,
      tag2: (document.getElementById('tag2_search') as HTMLInputElement)?.value,
      tag3: (document.getElementById('tag3_search') as HTMLInputElement)?.value,
    }));
    await fetchUSerProfileSummaryListOrderBy(page,sortTarget,sortType);
    setLoading(false);
    setPage(page - 1);
    //toggleSelectedAllClear();
    return true;
  }

  // 検索項目クリア後にデータ全表示
  const clearSearchText = async () => {
    clearSearchElement();
    executeSearchByPage(1);
    return true;
  }

  /**
   * APIエラーハンドル 共通
   * @param err エラーオブジェクト
   */
  const assignErrorData = (err: any) => {
    const errorData = {
      code: '',
      message: userListConst.unexpectedMessage,
      errorDetail: [],
    }

    if (err.response) {
      if (err.response.status === 403) {
        Object.assign(errorData, userListConst.responseError403);
      } else if (err.response.status >= 500) {
        // errorDataを書き換えない
      } else if (!err.response.data?.hasOwnProperty('message')) {
        // CSVダウンロードAPIのみ、err.response.dataに直接、テキストメッセージが
        // 格納されて送信されてくるので、オブジェクト形式へ整形
        Object.assign(errorData, { message: err.response.data });
      } else {
        Object.assign(errorData, err.response.data);
      }
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    } else if (err.request) {
      Object.assign(errorData, userListConst.requestError);
      setErrorData(errorData);
      setErrorOpened(true);
      return;
    }
  }

  /*const {
    selectedRowIds,
    isSelected,
    isSelectedAll,
    isIndeterminate,
    toggleSelected,
    toggleSelectedAll,
    toggleSelectedAllClear,
  } = useRowSelect(userProfileSummaryList.map((row) => row.id));*/

  return (
    <Fragment>
    <div style={{marginTop: -20}}>
      <Card className={classes.root} style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 10}}>
        <CardHeader
        title='出欠状況'
        titleTypographyProps={{
        align: 'center',
        variant: 'h6',
        }}
        />
      </Card>
      <Fragment>
          <div className={classes.search}>
            {/*<TextField id="loginId_search" style={{float: 'left', minWidth: 45}} size="small" label="ID" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;*/}
            <TextField id="displayName_search" style={{minWidth: 70}} size="small" label="表示名" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <TextField id="tag1_search" style={{float: 'left', minWidth: 70}} size="small" label="タグ１" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <TextField id="tag2_search" style={{float: 'left', minWidth: 70}} size="small" label="タグ２" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <TextField id="tag3_search" style={{float: 'left', minWidth: 70}} size="small" label="タグ３" variant="outlined" inputProps={{ autoComplete: "off" }}/>&nbsp;
            <Button id="search" style={{marginLeft: 2, float: 'left'}} variant="contained" color="primary" onClick={handleClearBtn}>クリア</Button>
            <Button id="search" style={{marginLeft: 2, float: 'left'}} variant="contained" color="primary" onClick={handleSearchBtn}>検索</Button>
          </div><br/>
          {/*<SelectableTableHead
              onLabelClick={toggleSelectedAll}
              onSelectAllClick={toggleSelectedAll}
              headCells={headCells}
              checked={isSelectedAll}
              indeterminate={isIndeterminate}
          />*/}
          <Button id="search" style={{marginRight: 10, marginTop: -5, float: 'right'}} variant="contained" color="primary" onClick={() => handleUpdateBtn()} >状態更新</Button>
          <TableContainer style={{ height: `calc(97vh - 280px)`}}>
            <Table stickyHeader aria-label="customized table" style={{tableLayout:'fixed'}}>
                <TableHead>
                <TableRow>
                    {/*<StyledTableCell key = 'check' style={{minWidth: 80,maxWidth: 80,width: 80,}}></StyledTableCell>*/}
                    {/*<StyledTableCell key = 'userId' style={{minWidth: 300,maxWidth: 300,width: 300}}>ユーザーID</StyledTableCell>*/}
                    <StyledTableCell key = 'displayName' style={{minWidth: 70,maxWidth: 70,width: 70,}} /*onClick={() => handleClickTab(0,page+1)}*/ >表示名</StyledTableCell>
                    <StyledTableCell key = 'tag1' style={{minWidth: 100,maxWidth: 100,width: 100,}} /*onClick={() => handleClickTab(1,page+1)}*/ >タグ１</StyledTableCell>
                    <StyledTableCell key = 'tag2' style={{minWidth: 100,maxWidth: 100,width: 100,}} /*onClick={() => handleClickTab(2,page+1)}*/ >タグ２</StyledTableCell>
                    <StyledTableCell key = 'tag3' style={{minWidth: 100,maxWidth: 100,width: 100,}} /*onClick={() => handleClickTab(3,page+1)}*/ >タグ３</StyledTableCell>
                    <StyledTableCell key = 'state' style={{minWidth: 70,maxWidth: 70,width: 70,textAlign: "center"}}>状態</StyledTableCell>
                    <StyledTableCell key = 'temp1' style={{minWidth: 100,maxWidth: 100,width: 100,}}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userProfileSummaryList.map((userProfileSummary: UserProfileSummaryData, rowIdx) => {
                  //const isItemSelected = isSelected(userProfileSummary.id);

                  return (
                    <StyledTableRow 
                      // hover
                      // role="checkbox"
                      // tabIndex={-1}
                      key={userProfileSummary.id}
                      // onClick={() => toggleSelected(userProfileSummary.id)}
                      // selected={isItemSelected}
                    >
                      {/*<TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>*/}
                      <StyledTableCell>
                        {userProfileSummary.displayName}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "left"}}>
                        {userProfileSummary.v1}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "left"}}>
                        {userProfileSummary.v2}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "left"}}>
                        {userProfileSummary.v3}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign: "center", backgroundColor: setAttendanceColor(userProfileSummary.attendanceType)}} >
                        {setAttendanceState(userProfileSummary.attendanceType)}
                      </StyledTableCell>
                      <StyledTableCell>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"1ページの表示人数"}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
      </Fragment>
      {drawLoading}
    </div>
    <BaseDialog disableBackdropClick disableEscapeKeyDown open={finishInfo.open} onClose={() => { setFinishInfo({ ...finishInfo, open: false }) }}>
      <DialogContent>
        <DialogContentText>{finishInfo.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} color="primary" onClick={() => { setFinishInfo({ ...finishInfo, open: false }) }}>閉じる</Button>
      </DialogActions>
    </BaseDialog>
    </Fragment>
  )
}

const headCells: HeadCell[] = [
  {
    id: "check",
    label: "画面内全選択",
  },
];

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "2px 16px"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    overflowWrap: 'break-word',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
      width: '50%',
      minWidth: 300,
  },
  content: {
    //    flexShrink: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  cardContent: {
      padding: '0 40px',
  },
  cardActions: {
      paddingLeft: 40,
      paddingRight: 40,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
  },
  inputTextField: {
      width: '100%',
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#A39F98',
  },
  errorMessage: {
      ...theme.typography.caption,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  loadingCard: {
      width: 150,
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  button: {
    display: 'flex',
    //    flexShrink: 0,
    //    width: 500,// - ${drawerWidth})`,
    justifyContent: 'flex-end',
  },
  card: {
    width: 150,
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    tableLayout:'fixed',
  },
  search: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const circularTheme = createMuiTheme({
  palette: {
      primary:{
          main: "#57BBFF",
      },
      secondary:{
          main: "#006FBC",
      },
  },
})

// UI表示用
class UserProfileSummaryData {
  id: number = 0;
  userName: string = "";
  displayName: string = "";
  role:string = "";
  tenantId:number = 0;
  tenantContractId:number = 0;
  i1: string = "";
  i2: string = "";
  i3: string = "";
  i4: string = "";
  i5: string = "";
  i6: string = "";
  i7: string = "";
  i8: string = "";
  i9: string = "";
  i10: string = "";
  v1: string = "";
  v2: string = "";
  v3: string = "";
  v4: string = "";
  v5: string = "";
  v6: string = "";
  v7: string = "";
  v8: string = "";
  v9: string = "";
  v10: string = "";
  attendanceType: number = 0; // 出欠状態 出席:0 欠席:1 帰宅:2 オフライン:3
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
import React from "react";

/** pointer captureを行う要素に付与するclass名（Window外ドラッグ処理で使用している）*/
export const NEED_TO_POINTER_CAPTURE = "need_to_pointer_capture";
/** 画面共有要素の背景色 */
export const SHARE_SCREEN_BG_COLOR = "#121212";
/** 画面共有要素に表示するvideoのデフォルトのアスペクト比 */
export const DEFAULT_SCREEN_SHARE_VIDEO_ASPECT_RATIO = 1.77777; // defaultは16:9とした

/** 下記を指定するCSSプロパティ
 *   ・テキスト,画像の選択不可
 *   ・画像保存ポップアップメニュー非表示 
 */
export const SELECT_NONE: React.CSSProperties = {
    MozUserSelect: "none", // 選択不可（テキストなど）
    WebkitUserSelect: "none",
    userSelect: "none",
    WebkitTouchCallout: "none", // ポップアップメニュー非表示
};

/** ユーザー検索要素の背景色 */
export const USER_SEARCH_BG_COLOR = "rgba(223,223,223,1.0)"; // "rgba(223,223,223,0.8)"

/** メモ要素の背景色 */
export const MEMO_FRAME_BG_COLOR = "rgba(223,223,223,1.0)";// "rgba(223,223,223,0.8)"
import React, { Fragment, LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import useReactRouter from 'use-react-router';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import AudioPlayer from './AudioPlayer';
import NoSleepWrapper from './NoSleepWrapper';  // iPad対応-3
import { siteKeyV2 } from '..';
import fsiLogo from '../img/fsiLogo.png';
import { RequireReloadDialog, RequireReloadDialogHandler } from "./RequireReloadDialog";
import { appFrontVersion } from '../common/Version';
import { Session } from 'inspector';
import { useParams } from 'react-router';
import { Utility } from '../common/Utility';
import WebrtcService from '../webrtc/WebrtcService';
import JfsClient, { JfsError } from '@fsi/jfs-sdk';

declare namespace chrome {
  export var webview: WebView;
}

interface WebView {
  hostObjects: HostObjects;
}

interface HostObjects {
  myHostObject: MyHostObject;
  sync: SyncProxy;
}

interface MyHostObject {
  AutoLogin(message: String): void;
  SigninErrMsgNotification(message: string): void;
}

interface SyncProxy {
  myHostObject: MyHostObject;
}

function Copyright() {

  //let p = require('../../package.json');

  return (
    <Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.famcampus.jp">
          FAMcampus
      </Link>{' '}
        {new Date().getFullYear()}
        {'.　'}

      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Ver "}{appFrontVersion}　
      </Typography>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
  },
  image: {
    backgroundImage: `url(../../api/service/picture)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  },
  paper: {
    marginTop: '50px',
    padding: '20px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    // margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notify: {
    marginTop: '50px',
    overflow: 'auto',
    //height: '250px',
    maxHeight: '270px',
    textAlign: 'left',
  },
  paper2: {
    marginTop: '0px',
    padding: '0px',
    backgroundColor:'rgba(255,255,255,0.8)',
    // margin: theme.spacing(1, 2),
    display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'center',
  },
  fsilogo: {
    marginTop: '30px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  famOfficelogo: {
    //marginTop: '50px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //transform: 'translateY(-40%)',
  },
  famOfficeText: {
    marginTop: '30px',
    //padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  copyright: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

export default function SingleSignOn() {
  const classes = useStyles();
  const { history } = useReactRouter();
  const query: string = history.location.search;

  const ref = useRef("" as any);

  const [errMsgOnLogin, setErrMsgOnLogin] = useState('');
  const [information, setInformation] = useState('');
  const [termsOfUseURL, setTermsOfUseURL] = useState('');
  const [ssoLoginURL, setSsoLoginURL] = useState("");

  const requireReloadDialogRef = useRef({} as RequireReloadDialogHandler);
  const urlParams: {registrationId: string} = useParams();

  const jfsClient = JfsClient.getInstance();
  const { httpClient } = jfsClient;

  const getAuthError = () => {
    var code = "";
    if (query !== "") {
      code = Utility.getQueryParam(query, "code");
    }
    if (code === "errorMaxNumOfContracts") {
      setErrMsgOnLogin("ご契約数の上限に達しているためサインインできません。ご契約数を確認して下さい。");
    } else if(code === "errorAuthType") {
      setErrMsgOnLogin("認証方式がパスワードに設定されているためサインインできません。");
    } else if(code === "errorDisabledSSO") {
      setErrMsgOnLogin("有効なシングルサインオンの設定がありません。");
    } else if(code === "errorNetworkAccess") {
      setErrMsgOnLogin("許可されていないネットワークからのアクセスです。サインイン出来ません。");
    }
  }

  useEffect(() => {
    function preLogin() {
      var params = new URLSearchParams();
      axios.post('/login/prelogin', params)
        .then((e: AxiosResponse) => {
          // console.log("prelogin OK!");
          getAuthError();
          getInformation();
          getTermsOfUseURL();   // 利用規約URL取得
          getSsoLoginURL();     // SAML対応
        }).catch(err => {
          console.log(err);
        });
    }

    // iOS 音声の自動再生対応
    AudioPlayer.loadData();
    // iPad対応-3
    NoSleepWrapper.initNoSleep();
    // preLogin();
    getAuthError();
    getInformation();
    getSsoLoginURL();

    function getInformation() {
      var params = new URLSearchParams();
      axios.post('/api/service/information', params)
        .then((e: AxiosResponse) => {
          setInformation(e.data);
        }).catch(() => {
        });
    }

    // 利用規約URL取得
    function getTermsOfUseURL() {
      var params = new URLSearchParams();
      axios.post('/api/service/termsofuse', params)
        .then((e: AxiosResponse) => {
          setTermsOfUseURL(e.data);
        }).catch(() => {
        });
    }

    function getSsoLoginURL() {
      if(urlParams !== null){
        // var params = new URLSearchParams();
        // axios.post('/api/service/registrationId/' + urlParams.registrationId, params)
        // .then((e: AxiosResponse) => {
        //   //sessionStorage.setItem("SIGNINPAGE", "/signin/" +  urlParams.registrationId)
        //   Utility.setSigninPage("/signin/" +  urlParams.registrationId);
        //   setSsoLoginURL("/sso/login/" + urlParams.registrationId);
        // }).catch((err) => {
        //   if ( err.response.data === '認証方式がパスワードに設定されているためサインインできません。') {
        //     //テナントの認証方式が変更されているため保存しているサインインページは無効
        //   } else {
        //     const signinpage = Utility.getSigninPage();
        //     history.push(signinpage);
        //   }
        // });

        httpClient.checkRegistrationId(urlParams.registrationId)
            .then((e: string) => {
                //sessionStorage.setItem("SIGNINPAGE", "/signin/" +  urlParams.registrationId)
                Utility.setSigninPage("/signin/" +  urlParams.registrationId);
                // setSsoLoginURL("/sso/login/" + urlParams.registrationId);
                // setSsoLoginURL(httpClient.createSsoSigninURL(urlParams.registrationId));
                if(WebrtcService.isiOS() === false) {
                    if(chrome.webview !== undefined) {
                        chrome.webview.hostObjects.sync.myHostObject.AutoLogin("js send text");
                    }
                }
            }).catch((err: JfsError) => {
                // if ( err.response.data === '認証方式がパスワードに設定されているためサインインできません。') {
                if ( err.code === 103002) {
                    //テナントの認証方式が変更されているため保存しているサインインページは無効
                } else {
                    const signinpage = Utility.getSigninPage();
                    history.push(signinpage);
                }
            });

      }
    }

  }, [])


  ///////////////////////////////////////////////////////////////////
  /* */
  // サインイン時のバージョンチェック
  const handleLoginCheck = async () => {
    setErrMsgOnLogin('');
    let check = await checkServerVersion();
    if(check === 0) {
      handleLogin();
    } else if(check === 1) {
      requireReloadDialogRef.current.open(true);
    } else {
      if(window.navigator.onLine) {
        setErrMsgOnLogin('サインインできません。しばらくしてからもう一度やり直してください。');
      } else {
        setErrMsgOnLogin('サインインできません。ネットワークの状態を確認してください。');
      }
    }
  }
  const checkServerVersion = async () => {
    console.log("######## checkVersion");
    var check = 0;
    var params = new URLSearchParams();
    try {
      const response = await axios.post('/api/service/version', params);
      console.log("####### checkVersion get");
      if(response === undefined || response === null) {
        console.log("response null");
        return 1;
      }
      console.log("checkVersion response="+response.status);
      if(response.status === 200) {
        if (response.data == "") {
            // 空文字が返ってきた(exec_varがないとき)はOK
            console.log("バージョンが空");
        }
        else if (response.data === appFrontVersion) {
            // バージョンが同じときはOK
            console.log("バージョンが同じ");
        }
        else {
            // バージョンが違う
            console.log("バージョンが違う");
            check = 1;
        }
      } else {
        console.log("err");
        check = 2;
      }
    } catch(err) {
      console.log("err catch : "+err);
      check = 2;
    }
    return check;
  };
  /**/

  ///////////////////////////////////////////////////////////////////
  // テナントメンテ中などの対応のため、Login2から移植
  const handleLogin = async () => {
    setErrMsgOnLogin('');

    function login() {
      //let tabID = new Date().getTime();
      // var params = new URLSearchParams();
      // params.append('version', appFrontVersion);
      // axios.post('/api/service/ssoLoginCheck/registrationId/' + urlParams.registrationId, params)
      //   .then(response => {
      //     console.log("SSO_LOGIN start URL=["+ssoLoginURL+"]")
      //     /////history.push(ssoLoginURL);
      //     const link = document.createElement('a');
      //     link.href = ssoLoginURL;
      //     document.body.appendChild(link);
      //     console.log("SSO_LOGIN link start")
      //     link.click();
      //     console.log("SSO_LOGIN link end")
      //   }).catch(err => {
      //     if(!window.navigator.onLine) {
      //         // 接続失敗メッセージの表示
      //         setErrMsgOnLogin('サインインできません。ネットワーク環境を見直してください。');
      //     } else {
      //       if (err.response.data === 'BOTまたは攻撃の可能性があります。') {
      //         setErrMsgOnLogin('');
      //       }else{
      //         console.log(err);
      //         if(err.response.status >= 500) {
      //           // 接続失敗メッセージの表示
      //           setErrMsgOnLogin('サインインできません。しばらくしてからもう一度やり直してください。');
      //         } else {
      //           if (err.response.data === '許可されていないネットワークからのアクセスです。サインイン出来ません。') {
      //             // ADMIN権限を持っているユーザーはホワイトリストチェック対象外のようなので、ここでチェックできない！！
      //             setErrMsgOnLogin(`${err.response.data}`);
      //           } else if ( err.response.data === 'ただいまメンテナンス中のためサインインできません。しばらくお待ちください。') { // 上もそうだがこんなif文でいいのか
      //             setErrMsgOnLogin(`${err.response.data}`);
      //           } else if ( err.response.data === 'バージョンが違います') { // 上もそうだがこんなif文でいいのか
      //             requireReloadDialogRef.current.open(true);
      //           } else if ( err.response.data === '認証方式がパスワードに設定されているためサインインできません。') { // 上もそうだがこんなif文でいいのか
      //             setErrMsgOnLogin(`${err.response.data}`);
      //           } else {
      //             // 認証失敗メッセージの表示
      //             setErrMsgOnLogin('サインインできません。しばらくしてからもう一度やり直してください。');
      //           }
      //         }
      //       }
      //     }
      //   });
      httpClient.checkSsoSigninFromRegistrationId(urlParams.registrationId)
            .then(response => {
                // console.log("SSO_LOGIN start URL=["+ssoLoginURL+"]")
                /*
                /////history.push(ssoLoginURL);
                const link = document.createElement('a');
                link.href = ssoLoginURL;
                document.body.appendChild(link);
                console.log("SSO_LOGIN link start")
                link.click();
                console.log("SSO_LOGIN link end")
                // document.location.href = ssoLoginURL;
                */
                httpClient.ssosignin(urlParams.registrationId);
                if(WebrtcService.isiOS() === false) {
                    if(chrome.webview !== undefined) {
                        // Windowsアプリへエラーメッセージがなかったことを伝える
                        chrome.webview.hostObjects.sync.myHostObject.SigninErrMsgNotification('');
                    }
                }
            }).catch((err: JfsError) => {
                let errMsgToWinApp:string = '';
                if(!window.navigator.onLine) {
                    // 接続失敗メッセージの表示
                    setErrMsgOnLogin('サインインできません。ネットワーク環境を見直してください。');
                    errMsgToWinApp = 'サインインできません。ネットワーク環境を見直してください。';
                } else {
                    // if (err.response.data === 'BOTまたは攻撃の可能性があります。') {
                    if (err.code === 101001) {
                        setErrMsgOnLogin('');
                        errMsgToWinApp = 'BOTまたは攻撃の可能性があります。';
                    }else{
                        console.log(err);
                        if(err.httpStatusCode >= 500) {
                            // 接続失敗メッセージの表示
                            setErrMsgOnLogin('サインインできません。しばらくしてからもう一度やり直してください。');
                            errMsgToWinApp = 'サインインできません。しばらくしてからもう一度やり直してください。';
                        } else {
                            // if (err.response.data === '許可されていないネットワークからのアクセスです。サインイン出来ません。') {
                            if (err.code === 101002) {
                            // ADMIN権限を持っているユーザーはホワイトリストチェック対象外のようなので、ここでチェックできない！！
                                setErrMsgOnLogin('許可されていないネットワークからのアクセスです。サインイン出来ません。');
                                errMsgToWinApp = '許可されていないネットワークからのアクセスです。サインイン出来ません。';
                            // } else if ( err.response.data === 'ただいまメンテナンス中のためサインインできません。しばらくお待ちください。') { // 上もそうだがこんなif文でいいのか
                            } else if (err.code === 103003) {
                                setErrMsgOnLogin('ただいまメンテナンス中のためサインインできません。しばらくお待ちください。');
                                errMsgToWinApp = 'ただいまメンテナンス中のためサインインできません。しばらくお待ちください。';
                            // } else if ( err.response.data === 'バージョンが違います') { // 上もそうだがこんなif文でいいのか
                            } else if (err.code === 103001) {
                                requireReloadDialogRef.current.open(true);
                                errMsgToWinApp = 'バージョンが違います';
                            // } else if ( err.response.data === '認証方式がパスワードに設定されているためサインインできません。') { // 上もそうだがこんなif文でいいのか
                            } else if (err.code === 103002) {
                                setErrMsgOnLogin('認証方式がパスワードに設定されているためサインインできません。');
                                errMsgToWinApp = '認証方式がパスワードに設定されているためサインインできません。';
                            } else if (err.code === 102007) {
                                setErrMsgOnLogin('ご契約数の上限に達しているためサインインできません。ご契約数を確認して下さい。');
                                errMsgToWinApp = 'ご契約数の上限に達しているためサインインできません。ご契約数を確認して下さい。';
                            } else {
                                // 認証失敗メッセージの表示
                                setErrMsgOnLogin('サインインできません。しばらくしてからもう一度やり直してください。');
                                errMsgToWinApp = 'サインインできません。しばらくしてからもう一度やり直してください。';
                            }
                        }
                    }
                }
            });
    }

    login();
  }
  ///////////////////////////////////////////////////////////////////

  const submitButton = useMemo(() => {
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleLogin}
      >
        サインイン
      </Button>
    )
  }, [ssoLoginURL])

  return (
    <Fragment>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className={classes.image}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.paper}>
              <div className={classes.famOfficelogo}>
                {/*<img src='../../api/service/famLogo'  alt="" />*/}
                <img src={'../service_info/famcampus_logo_image.png'} alt="" />
              </div>
              {errMsgOnLogin.length > 0 ? (<div style={{ padding: 5, color: 'red' }}>{errMsgOnLogin}</div>) : ('')}
              {submitButton}
              <div className={classes.fsilogo}>
                <img src={fsiLogo}  alt="" />
                {termsOfUseURL ?
                  <p><a href={termsOfUseURL} target="_blank">利用規約</a></p>
                :
                  <Fragment/>
                }
              </div>
            </div>

          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={11} md={7} className={classes.notify}>
            <div className={classes.paper2}>
              <div dangerouslySetInnerHTML={{ __html: information }}></div>
            </div>
          </Grid>

        </Grid>

        <div className={classes.copyright}>
              <Copyright />
        </div>

      </Grid>
    </Grid>
    <RequireReloadDialog ref={requireReloadDialogRef} />
    </Fragment>
  );
}
import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import ClearIcon from '@material-ui/icons/Clear';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SELECT_NONE } from "../common/Constants";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { chkJsonFormat } from '../common/JsonClass';
import { Utility } from '../common/Utility';

// 外部ビデオ連携・会議室名・座席ラベル用
// 外部会議URLを自動で消すかどうか（true:消す）
const isResetURLDefChecked = false;

const checkBoxTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

interface Props {
    className?: string,
    isOpen: boolean,
    floorObjectId: number,
    textId: number,
    textType: number,
    title: string,
    text: string,
    chairText?: string,
    chairId?: number,
    maxLength: number,
    isPlayAudio?: boolean,
    isResetURL?: boolean,
    onClose: () => void,
    sendText: (
        id: number,
        textId: number,
        text: string,
        isPlayAudio: boolean,
        type: number,
    ) => void
}

const LinearProgressStyle: React.CSSProperties = {
    ...SELECT_NONE,
    backgroundColor: 'rgba(0, 0, 255, 1)',
};

const progressBarTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#FFC1A2",
        },
    },
})

type ChairLabelData = {
    [key: string]: {
      text: string;
      top: number;
      left: number;
    };
};

export default function EditTextDialogInRoom(props: Props) {
    const defaultProps = {
        isPlayAudio: false,
        isResetURL: isResetURLDefChecked
    }

    const { className, isOpen, floorObjectId, title, textId, textType, text, maxLength } = props;
    const isPlayAudio = props.isPlayAudio ?? defaultProps.isPlayAudio;
    const chairText = props.chairText ?? "";
    const chairId = props.chairId ?? 0;
    const [inputText, setInputText] = useState(text);
    const [tempText, setTempText] = useState(text);
    const [dialogWidth, setDialogWidth] = useState("420px");
    // ResetURLの初期値
    const isResetURL = props.isResetURL ?? defaultProps.isResetURL;
    const [isCheckedResetURL, setIsCheckedResetURL] = useState(isResetURL);

    // テキストのuseEffect
    useEffect(() => {
        // Jsonフォーマットチェック
        let jf: chkJsonFormat = new chkJsonFormat();
        let jsonCheck:[ boolean, string, any ] = jf.checked( chkJsonFormat.FLOOR_OBJECT_TEXT2_MEETINGROOM, text);

        if( jsonCheck[0] ){
           // Jsonの場合
            const setting = jsonCheck[2];

            // Jsonの場合
            if(title === '外部Web会議ツール設定'){
                setInputText(setting.url);
            }
            else{
                setInputText(setting.message);
            }
        } else {
            // Jsonでない場合
            if(title === '外部Web会議ツール設定'){
                // 一度も保存されていない（未設定）場合、urlは空白
                setInputText("");
            } else {
                setInputText(jsonCheck[1]);
            }
        }
        setTempText(inputText);
        return () => setTempText(inputText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, text]);

    // CheckBoxのuseEffect
    useEffect(() => {
        setIsCheckedResetURL(isResetURL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, isResetURL]);

    useEffect(() => {
        // ダイアログの幅を設定
        if (maxLength === 12) {
            setDialogWidth("420px")
        } else if (maxLength === 30) {
            setDialogWidth("630px")
        }
    }, [maxLength, dialogWidth])

    // OK
    const onOk = useCallback(() => {
        // inputTextが取得できない場合の対応
        if(inputText===null || inputText===undefined){
            return;
        }

        let len = judgehz(inputText)
        if (len > maxLength) {
            return;
        }

        let json;
        if(title === '外部Web会議ツール設定'){
            // 外部ビデオ連携の設定(url、urlリセット)を行う ※会議室名はここで取り扱わない
            let text = {message: "", url: inputText, isResetURL: isCheckedResetURL};
            json = JSON.stringify(text); // json形式にしてバックエンドに送信する
        }else{
            // 会議室名や座席ラベルの設定を行う
            json = inputText;
            if(textType === 3){ // 座席ラベルの場合は座席のjson(座席ラベル情報が全て入ったデータ)をそのまま送る
                const chairLabelContents: ChairLabelData = JSON.parse(chairText);
                const key = `id${chairId}`;
                chairLabelContents[key].text = inputText;
                json = JSON.stringify(chairLabelContents); 
            }
        }

        
        
        props.sendText(floorObjectId, textId, json, isPlayAudio, textType);
        
        setTempText(inputText);
        props.onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, textId, inputText, isPlayAudio, isCheckedResetURL, title, chairText, chairId]);

    // Clear（ダイアログそのまま）
    const onClearText = useCallback(() => {
        // props.sendText(floorObjectId, '', isPlayAudio);
        // props.onClose();
        setInputText('');
        setTempText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, text, isPlayAudio, title]);

    // Clear（ダイアログ閉じる）
    const onClear = useCallback(() => {
        let json;
        if(title === '外部Web会議ツール設定'){
            // 外部ビデオ連携の設定(url、urlリセット)を行う ※会議室名はここで取り扱わない
            let text = {message: "", url: "", isResetURL: isResetURLDefChecked};
            json = JSON.stringify(text); // json形式にしてバックエンドに送信する
        }else{
            // 会議室名や座席ラベルの設定を行う
            json = inputText;
        }
        props.sendText(floorObjectId, textId, json, isPlayAudio, textType);

        setInputText('');
        setTempText('');
        setIsCheckedResetURL(isResetURLDefChecked);
        props.onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorObjectId, textId, isPlayAudio, title]);

    // Cancel
    const onCancel = useCallback(() => {
        setInputText(tempText);
        setIsCheckedResetURL(isResetURL);
        props.onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempText, isResetURL]);

    // URL削除チェックボックスのON/OFF
    const handlChangeCheckedResetURL = ((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckedResetURL(event.target.checked);
    });

    // 表示文言
    const guideText = useMemo(() => {
        if (title === '外部Web会議ツール設定') {
            return (
                <Fragment>
                    <div style={{ fontFamily: "Hiragino Maru Gothic StdN" }}>
                        ZoomやGoogle Meetなどの外部Web会議ツールへの参加URLを設定すると、
                        この部屋に入室したメンバーをその会議へ招待することができます。<br />
                        ※外部Web会議ツール使用中はFAMcampusのビデオ通話が切断されます。<br />
                        <br />
                    </div>
                    <br />
                    <FormGroup>
                        <FormControlLabel control={
                            <ThemeProvider theme={checkBoxTheme}>
                                <Checkbox
                                    id='cbIsResetURL'
                                    name='cbIsResetURL'
                                    color='primary'
                                    onChange={e => handlChangeCheckedResetURL(e)}
                                    checked={isCheckedResetURL}
                                    inputProps={{ 'aria-label': 'checkResetURL' }}
                                />
                            </ThemeProvider>
                        } label='この部屋から全員退出したら自動的にURLを消す' />
                    </FormGroup>
                    <br />
                </Fragment>
            );
        } else {
            return;
            //return(<br />);
        }
    }, [title, isCheckedResetURL]);

    const judgehz = (intext: string) => {
        let len = 0
        if (intext === null) return len;
        let text = intext.split('')

        // 半角全角判定
        for (let i = 0; i < intext.length; i++) {
            if (text[i].match(/^[A-Za-z0-9 ｦ-ﾟ-_!?.,;:/#$%&<>=~|`@\{\}\(\)\"\'\+\*\[\]\\]*$/)) {
                len = len + 0.5     // 半角
            } else {
                len = len + 1       // 全角
            }
        }

        return len
    };

    // 入力URL長チェック
    const textLength = useMemo(() => {
        // inputTextが取得できない場合の対応
        if(inputText === null || inputText === undefined){
            return (
                <div> </div>
            );
        }
        let len = judgehz(inputText)
        if (maxLength < len) {
            return (
                <div style={{ color: "red" }}>入力した文字列が長すぎます。 </div>
            );
        }
    }, [maxLength, inputText]);

    const progressBar = useMemo(() => {
        let parsent = (judgehz(inputText) / maxLength) * 100;
        let progressColor = 'rgba(0, 0, 255, 0.3)';

        // 入力文字数超過した場合はバーが赤色になる
        if (parsent > 100) {
            parsent = 200;
            progressColor = 'rgba(255, 0, 0, 1)';
        } else {
            progressColor = '#FFE1A2';
        }

        return (
            <Grid container >
                <div style={{ flexGrow: 1 }}></div>
                {textLength}
                <Grid style={{ padding: "20px 0px 0px 0px", width: "20%" }}>
                    <ThemeProvider theme={progressBarTheme}>
                        <LinearProgress
                            variant="determinate"
                            value={parsent}
                            style={{
                                ...LinearProgressStyle,
                                backgroundColor: progressColor,
                            }}
                        />
                        </ThemeProvider>
                </Grid>
            </Grid>
        );
    }, [inputText])

    // 描画
    const draw = useMemo(() =>
        <BaseDialog
            className={className}
            open={isOpen}
            onClose={() => props.onClose()}
            aria-labelledby='editTtextDialogTitle'
            // fullWidth={true}
            maxWidth={Utility.getType() === 2 ? 'xs' : 'sm'}
            PaperProps={{
                style: {
                    minWidth: Utility.getType() === 2 ? 200 : dialogWidth,
                    border: "6px solid #57BBFF",
                    borderRadius: "25px",
                },
            }}
        >
            <DialogTitle id='editTtextDialogTitle' style={{ padding: "5px 25px 5px 25px", background: '#57BBFF 0% 0% no-repeat padding-box', fontFamily: 'Hiragino Maru Gothic StdN', color: '#555555' }} >{title}</DialogTitle>
            <DialogContent style={{ padding: "5px 25px 0px 25px" }}>
                <DialogContentText style={{ marginBottom: "0px", paddingTop: "3px" }} >
                    {guideText}
                    {progressBar}
                </DialogContentText>
                <div style={{color: '#707070'}}>{title === '外部Web会議ツール設定' ? "参加URL" : "内容"}</div>
                <TextField
                    id="outlined-basic"
                    //label={title === '外部Web会議ツール設定' ? "参加URL" : "内容"}
                    variant="outlined"
                    multiline
                    autoFocus
                    margin='dense'
                    fullWidth
                    value={inputText}
                    autoComplete={'off'}
                    onChange={e => setInputText(e.target.value)}
                    style={{ pointerEvents: 'auto' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onClearText}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={onClear} color='primary' style={{ pointerEvents: 'auto' }}>消去</Button> */}
                <Button onClick={onOk} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', borderRadius: '31px', color: '#FFFFFF', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>OK</Button>
                <Button onClick={onCancel} color='primary' style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 110 , fontFamily: 'Hiragino Maru Gothic StdN' }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isOpen, floorObjectId, title, text, inputText, tempText, isCheckedResetURL])

    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
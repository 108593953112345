import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';
import { Button, Slide } from '@material-ui/core';
import { TestParticipantsDialog, TestParticipantsDialogHandler } from "../test/TestParticipantsDialog"
import { RequireReloadDialog, RequireReloadDialogHandler } from "./RequireReloadDialog"
import { printable } from '../log/LogSetting'
import { MoveFloorRef } from './MoveFloor';
import { Utility } from '../common/Utility';
import { FloorClosedDialogRef, FloorClosedDialogHandler } from './FloorClosedDialog';
import { OkCancelDialog, OkCancelDialogHandler } from '../common/OkCancelDialog';
import JfsClient, {JfsError} from '@fsi/jfs-sdk';


if(printable == 0){
  console.log = function(){}
  console.warn = function(){}
  console.error = function(){}
}
export class Office {
    id: number = 0;
    officeName: string = "";
    privacyRoom: boolean = false;
}

export class Floor {
    id: number = 0;
    floorName: string = "";
    floorOrder: number = 0;
    enabledFloor: boolean = true;
}

export default function Top() {
    const { history } = useReactRouter();

    const [officeList, setOfficeList] = useState([] as Office[]);
    const [officeSlide, setOfficeSlide] = useState(false);

    const [floorList, setFloorList] = useState([] as Floor[]);
    const [floorSlide, setFloorSlide] = useState(false);

    const testParticipantsDialogRef = useRef({} as TestParticipantsDialogHandler);
    const requireReloadDialogRef = useRef({} as RequireReloadDialogHandler);
    const floorClosedDialogRef = useRef({} as FloorClosedDialogHandler);
    const migratingMaintenanceDlgRef = useRef({} as OkCancelDialogHandler);
    const okCancelDialogRef = useRef({} as OkCancelDialogHandler);
    const entryRestrictDlgRef = useRef({} as OkCancelDialogHandler);

    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    
    useEffect(() => {
        function getOfficeList() {
            // var params = new URLSearchParams();
            // params.append("tab_id", sessionStorage.getItem("TABID") as string);
            // params.append("remove_floor_id", "true");
            // axios.post('/api/user/office/list/get', params)
            //     .then((e: AxiosResponse) => {
            //         setOfficeList(e.data as Office[]);
            //         setOfficeSlide(true);
            //     }).catch(err => {
            //         console.log("errer=["+err+"]");
            //         if(!window.navigator.onLine) {
            //             const signinpage = Utility.getSigninPage();
            //             history.push({
            //                 pathname: signinpage
            //             });
            //         } else {
            //             if((err.response.status === 403) ||
	        //                (err.response.status === 502) ||
    	    //                (err.response.status === 504) || (err.response.status === 400)) {
            //                 const signinpage = Utility.getSigninPage();
            //                 history.push({
            //                     pathname: signinpage
            //                 });
            //             }
            //         }
            //     });
            httpClient.getOfficeList(sessionStorage.getItem("TABID") as string)
                .then((e) => {
                    setOfficeList(e as Office[]);
                    setOfficeSlide(true);
                }).catch(err => {
                    console.log("errer=[" + err + "]");
                    if (!window.navigator.onLine) {
                        const signinpage = Utility.getSigninPage();
                        history.push({
                            pathname: signinpage
                        });
                    } else {
                        // if ((err.response.status === 403) ||
                        //     (err.response.status === 502) ||
                        //     (err.response.status === 504) || (err.response.status === 400)) {
                        if ((err.httpStatusCode === 403) ||
                            (err.httpStatusCode === 502) ||
                            (err.httpStatusCode === 504) || (err.httpStatusCode === 400)) {
                            const signinpage = Utility.getSigninPage();
                            history.push({
                                pathname: signinpage
                            });
                        }
                    }
                });
        }
        getOfficeList();
    }, [])

    const selectOffice = (id: number) => {
        setOfficeSlide(false);

        function getFloorList(id: number) {
            // var params = new URLSearchParams();
            // params.append("office_id", id.toString());
            // params.append("tab_id", sessionStorage.getItem("TABID") as string);
            // axios.post('/api/user/floor/list/get', params)
            //     .then((e: AxiosResponse) => {
            //         // #11529 floorOrderでソートする
            //         let sortedFloorList = (e.data as Floor[]).sort((floor1, floor2) => {
            //             if (floor1.floorOrder > floor2.floorOrder) return 1;
            //             if (floor1.floorOrder < floor2.floorOrder) return -1;
            //             return 0;
            //         });

            //         // 
            //         let enabledSortFloor = sortedFloorList.filter(x => x.enabledFloor === true);

            //         setFloorList(enabledSortFloor);
            //         setFloorSlide(true);
            //     }).catch(err => {
            //         console.log(err);
            //     });
            httpClient.getFloorList(id, sessionStorage.getItem("TABID") as string)
                .then((e) => {
                    // #11529 floorOrderでソートする
                    let sortedFloorList = (e as Floor[]).sort((floor1, floor2) => {
                        if (floor1.floorOrder > floor2.floorOrder) return 1;
                        if (floor1.floorOrder < floor2.floorOrder) return -1;
                        return 0;
                    });

                    // 
                    let enabledSortFloor = sortedFloorList.filter(x => x.enabledFloor === true);

                    setFloorList(enabledSortFloor);
                    setFloorSlide(true);
                }).catch(err => {
                    console.log(err);
                });
        }

        getFloorList(id);
    }

    const selectFloor = (id: number) => {
        function send(id: number) {
            // var params = new URLSearchParams();
            // params.append("floor_id", id.toString());
            // params.append("tab_id", sessionStorage.getItem("TABID") as string);

            // axios.post('/api/user/floor/checkFloorOpen', params)
            //     .then((e: AxiosResponse) => {
            //         let result = e.data as boolean;
            //         if(!result){
            //             //alert("ただいまの時間は、選択されたフロアには入れません。\r\n立ち入り可能な時間帯は、管理者にお問い合わせください。");
            //             //setAlert(true);
            //             floorClosedDialogRef.current.setDialogOpen(true);
            //             return;
            //         }
            //         axios.post('/api/user/floor/select', params)
            //             .then((e: AxiosResponse) => {
            //                 history.push({
            //                     pathname: "/floor",
            //                     state: { path: e.data, id: id }
            //                 });
            //             }).catch(err => {
            //                 console.log(err);
            //             });
            //     }).catch(err => {
            //         console.log(err);
            //     });

            httpClient.checkFloorOpen(sessionStorage.getItem("TABID") as string, id)
                .then((result: boolean) => {
                    // if(!result){
                    //     //alert("ただいまの時間は、選択されたフロアには入れません。\r\n立ち入り可能な時間帯は、管理者にお問い合わせください。");
                    //     //setAlert(true);
                    //     floorClosedDialogRef.current.setDialogOpen(true);
                    //     return;
                    // }
                    let destinationFloor;
                    if(result){ // 開放時間内
                        destinationFloor = id;
                    }else{ // 開放時間外
                        destinationFloor = -1;
                    }

                    httpClient.setEnterFloor(destinationFloor, sessionStorage.getItem("TABID") as string)
                        .then((e: string) => {
                            history.push({
                                pathname: "/floor",
                                state: { path: e, id: id }
                            });
                        }).catch(err => {
                            const jfserr = err as JfsError;
                            console.log('httpClient.updateFloorManager error httpStatusCode=['+jfserr.httpStatusCode+'] code=['+jfserr.code+'] detail=['+jfserr.detail+']');
                            /*
                            if(err.response.data === '対象のフロアは入室制限がかけられています。'){
                                entryRestrictDlgRef.current.open();
                            } else if (err.response.data === '対象のフロアはメンテナンス開始/解除の処理中です。'){
                                migratingMaintenanceDlgRef.current.open();
                            } else {
                                okCancelDialogRef.current.open();
                            }
                            */
                            if(jfserr.detail === '対象のフロアは入室制限がかけられています。'){
                                entryRestrictDlgRef.current.open();
                            } else if (jfserr.detail === '対象のフロアはメンテナンス開始/解除の処理中です。'){
                                migratingMaintenanceDlgRef.current.open();
                            } else {
                                if(!result){
                                    floorClosedDialogRef.current.setDialogOpen(true);
                                }else{
                                    okCancelDialogRef.current.open();
                                }
                            }
                            console.log(jfserr);
                        });
                }).catch(err => {
                    console.log(err);
                });
        }
        send(id);
    }

    const drawOfficeList = useMemo(() => {
        try {
            return officeList.map((e: Office) => {
                return (
                    <Slide key={e.id} in={officeSlide}>
                        <div>
                            <Button style={{width:'400px'}} onClick={() => selectOffice(e.id)} variant="outlined" color="primary">{e.officeName}</Button>
                        </div>
                    </Slide>
                )
            })
            
        } catch(e) {
            console.log(e);
            const signinpage = Utility.getSigninPage();
            history.push({
                pathname: signinpage
            });
        }
        
    }, [officeList, officeSlide])

    const drawFloorList = useMemo(() => {
        return floorList.map((e: Floor) => {
            return (
                <Slide key={e.id} in={floorSlide}>
                    <div>
                        <Button style={{width:'400px'}} onClick={() => selectFloor(e.id)} variant="outlined" color="primary">{e.floorName}</Button>
                    </div>
                </Slide>
            )
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorList, floorSlide])

    const handleOpenAddUser = () => {
        history.push({
            pathname: "/adduser",
        });
    }
    const handleCopyFloor = () => {
        history.push({
            pathname: "/copyfloor",
        });
    }

    const handlePartcipants = () => {
        testParticipantsDialogRef.current.open();
    }

    const handleEventRegist = () => {
        history.push({
            pathname: "/eventregist",
        });
    }

    const drawMoveFloorDialog = useMemo(() => {
        return (
            <MoveFloorRef open={true} floorId={0} onMoveFloor={selectFloor} myRole={'TOP'} myState={-1} onJumpToMySeat={()=>{}} onStateChange={()=>{}} requestPrivacyRoom={()=>{}} sendPrivacyRoomTimeOut={()=>{}} sendMovePrivacyRoomInfo={()=>{}} ></MoveFloorRef>
        );
    }, [])

    const dummy = () => {
    }

    return (
        <Fragment>
            {/*
            <div style={{position: "absolute", top: 0, left: 0}}>
                <Button variant="contained" color="primary" onClick={handleOpenAddUser}>ユーザ追加</Button>
            </div>
            <div style={{position: "absolute", top:100, left:0}}>
                <Button variant="contained" color="primary" onClick={handleCopyFloor}>フロアコピー</Button>
            </div>
            <div style={{position: "absolute", top:150, left:0}}>
                <Button variant="contained" color="primary" onClick={handlePartcipants}>フロア参加人数</Button>
            </div>
            <div style={{ position: "absolute", top: 200, left: 0 }}>
                <Button variant="contained" color="primary" onClick={handleEventRegist}>イベント登録</Button>
            </div>
            */}
            {/*
            {officeSlide?
            <div id="id01" style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)",
            overflow:'auto', height:'100vh', width:'100%',display:'flex', justifyContent:'center', alignItems:'center' }}>
                <div>
                <h2>オフィスを選択してください</h2>
                {drawOfficeList}
                </div>
            </div>
            :
            <div id="id01" style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
                {drawOfficeList}
            </div>}

            {floorSlide?
            <div id="id02" style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)",
            overflow:'auto', height:'100vh', width:'100%',display:'flex', justifyContent:'center', alignItems:'center' }}>
                <div>
                <h2>フロアを選択してください</h2>
                {drawFloorList}
                </div>
            </div>
            :
            <div id="id01" style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
                {drawFloorList}
            </div>}
            */}
            {/*
            <div id="id01" style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%,-50%)",
            overflow:'auto', height:'100vh', width:'100%',display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'white' }}>
            */}
                {drawMoveFloorDialog}
            {/*
            </div>
            */}
            <TestParticipantsDialog ref={testParticipantsDialogRef} />
            <RequireReloadDialog ref={requireReloadDialogRef} />
            <FloorClosedDialogRef ref={floorClosedDialogRef} />
            <OkCancelDialog ref={migratingMaintenanceDlgRef} title='フロア移動' message='メンテナンス処理中です。しばらくしてからもう一度やり直してください。' okCallback={dummy}
                closeCallback={dummy} okButtonName={"閉じる"} closeButtonName={"閉じる"} enableCloseButton={false} />
            <OkCancelDialog ref={okCancelDialogRef} title='フロア移動' message='移動先のフロアがメンテナンス中の為、フロア移動を中止しました。' okCallback={dummy} 
                closeCallback={dummy} okButtonName={"閉じる"} closeButtonName={"閉じる"} enableCloseButton={false} />
            <OkCancelDialog ref={entryRestrictDlgRef} title='フロアに入室できません' message='移動先のフロアに入室する権限がありません。' okCallback={dummy}
                closeCallback={dummy} okButtonName={"閉じる"} closeButtonName={"閉じる"} enableCloseButton={false} />
       </Fragment>
    )
}
import axios from 'axios';
import React, { forwardRef, ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FloorData } from '../common/JsonClass';
import { Redirect } from 'react-router';
import { Logger } from '../common/Logger';
import { Utility } from '../common/Utility';
import JfsClient, { User } from '@fsi/jfs-sdk';

interface Props {
    open: boolean;
    account: string;
    isWhiteBoard: boolean;
    user: User;
    floorData: FloorData,
    reConnectHello: boolean,
    onLogin: (url: string) => void;
    onError: () => void;
    handleCheckOpenedHello: () => void;
    setMoreNoteGuestUrl: (url: string) => void;
}

export interface MoreNoteHandler {
    openedHello: (json: any) => void;
    requestHelloInvitee: (roomId: string, loginId: string, webSocketUrl: string) => void;
}

const MoreNoteComponent: ForwardRefRenderFunction<MoreNoteHandler, Props> = (props, ref) => {
    const [account, setAccount] = React.useState(props.account);
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState(false);
    const [showErrorDialog, setShowErrorDialog] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [redirect, setRedirect] = React.useState('');
    const [confirmReOpenHello, setConfirmReOpenHello] = React.useState({
        open: false as boolean,
        guestUrl: "" as string,
    });
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    const logger: Logger = Logger.getInstance();

    useImperativeHandle(ref, () => ({
        openedHello: (json: any) => {
            logger.info("json.openedHello:" + json.openedHello);
            if(json.openedHello) {
                console.log("props.onError()");
                props.onError();
                logger.info("setConfirmReOpenHello(1) open:true guestUrl:" + json.guestUrl);
                setConfirmReOpenHello({
                    open: true,
                    guestUrl: json.guestUrl,
                });
            } else {
                console.log("requestHelloLogin");
                requestHelloLogin();
            }
        },
        requestHelloInvitee: (roomId: string, loginId: string, webSocketUrl: string) => {
            requestHelloInvitee(roomId, loginId, webSocketUrl);
        }
    }))

    const clearInput = () => {
        setAccount("");
        setPassword("");
    }

    const requestHelloLogin = () => {
        // const params = new URLSearchParams();
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        // params.append("name_id", props.user.loginId);
        // params.append("cooperate_name", props.user.webRtcRoomId);
        // params.append("whiteboard", props.isWhiteBoard ? "true" : "false");
        // params.append("nickname", props.user.displayName);
        console.log("tab_id", sessionStorage.getItem("TABID") as string);
        console.log("name_id", props.user.loginId);
        console.log("cooperate_name", props.user.webRtcRoomId);
        console.log("whiteboard", props.isWhiteBoard ? "true" : "false");
        console.log("nickname", props.user.displayName);
        // axios.post('/api/user/' + props.floorData.webSocketUrl + '/hello/login', params)
        httpClient.signinHello(Number(props.floorData.webSocketUrl),
            sessionStorage.getItem("TABID") as string,
            props.user.loginId,
            props.user.displayName,
            props.user.webRtcRoomId,
            props.isWhiteBoard ? true : false)
            .then(response => {
                sucessCallback(response);
                clearInput();
            }).catch(err => {
                clearInput();
                // if (err.response.status === 403) {
                //     setShowErrorDialog(true);
                //     setErrorMessage("セッションがタイムアウトしました。\n引き続きご利用いただくには、サインインする必要があります。");
                // } else if (err.response.status === 500) {
                //     setShowErrorDialog(true);
                //     setErrorMessage("moreNOTE Hello! へのログインに失敗しました。少し待ってからお試しください。\n" +
                //             "なお、引き続きご利用いただくには再度サインインする必要があります。");
                // } else {
                    setError(true);
                // }
            });
    }

    /**
     * 既存のHelloルームに参加するためのリクエストを行う
     * 
     * @param roomId 
     */
    const requestHelloInvitee = (roomId: string, loginId: string, webSocketUrl: string) => {
        // const params = new URLSearchParams();
        // params.append("room_id", roomId);
        // params.append("user_id", loginId);
        // params.append("tab_id", sessionStorage.getItem("TABID") as string);
        console.log("room_id", roomId);
        console.log("tab_id", sessionStorage.getItem("TABID") as string);
        // axios.post('/api/user/' + webSocketUrl + '/hello/invitee', params)
        httpClient.signinOpenedHello(Number(webSocketUrl),
            sessionStorage.getItem("TABID") as string,
            loginId,
            roomId)
            .then(response => {
                sucessCallback(response);
            }).catch(err => {
                // if (err.response.status === 403) {
                //     setShowErrorDialog(true);
                //     setErrorMessage("セッションがタイムアウトしました。\n引き続きご利用いただくには、サインインする必要があります。");
                // } else if (err.response.status === 500) {
                //     setShowErrorDialog(true);
                //     setErrorMessage("moreNOTE Hello! へのログインに失敗しました。少し待ってからお試しください。\n" +
                //             "なお、引き続きご利用いただくには再度サインインする必要があります。");
                // } else {
                    setError(true);
                // }
            });
    }

    const sucessCallback = (url: string) => {
        callback(false, url);
    }

    const errCallback = () => {
        callback(true, "");
    }

    const cancelCallback = () => {
        callback(false, "");
    }

    const callback = (err: boolean, url: string) => {
        setError(false);
        if (err) {
            props.onError();
        } else {
            props.onLogin(url);
        }
    }

    const handleClickSignin = () => {
        setShowErrorDialog(false);
        const signinpage = Utility.getSigninPage();
        setRedirect(signinpage);
    }

    const drawLogin = () => {
        return (
            <Fragment key="moreNoteLogin">
                <DialogTitle id="moreNoteLoginDialogTitle">moreNOTE Hello! ログイン</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="account"
                        label="アカウント名"
                        defaultValue={account}
                        // disabled
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAccount(event.target.value)}
                    />
                    <TextField
                        // autoFocus
                        margin="dense"
                        id="password"
                        label="パスワード"
                        type="password"
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => cancelCallback()} color="primary">キャンセル</Button>
                    <Button onClick={() => requestHelloLogin()} color="primary">OK</Button>
                </DialogActions>
            </Fragment>
        );
    }

    const closeConfirmReOpenHello = () => {
        logger.info("setConfirmReOpenHello(3) open:false guestUrl:\"\"");
        setConfirmReOpenHello({
            open: false,
            guestUrl: "",
        });
    }

    const reConnectOpenedHello = () => {
        logger.info("setConfirmReOpenHello(4) open:false guestUrl:\"\"");
        setConfirmReOpenHello({
            open: false,
            guestUrl: "",
        })

        logger.info("call setMoreNoteGuestUrl guestUrl:" + confirmReOpenHello.guestUrl);
        props.setMoreNoteGuestUrl(confirmReOpenHello.guestUrl);
    }

    const drawConfirmReOpenHello = useMemo(() => {
        logger.info("confirmReOpenHello.open:" + confirmReOpenHello.open);
        if(confirmReOpenHello.open === false) return; 
        return (
            <BaseDialog
                id="confirmReOpenHello"
                open={confirmReOpenHello.open}
                // onClose={() => cancelCallback()}
                aria-labelledby='sessonTimeoutAlertTitle'
                maxWidth={'sm'}
            >
                <DialogTitle id="moreNoteLoginDialogTitle">資料・ホワイトボードの共有</DialogTitle>
                <DialogContent>
                    既に資料・ホワイトボードが共有されています。
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeConfirmReOpenHello()} color="primary">キャンセル</Button>
                    <Button onClick={() => reConnectOpenedHello()} color="primary">既存の共有に参加</Button>
                    <Button onClick={() => requestHelloLogin()} color="primary">新しく共有</Button>
                </DialogActions>
            </BaseDialog>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmReOpenHello.open])

    const drawError = () => {
        return (
            <Fragment key="moreNoteError">
                <DialogTitle id="moreNoteLoginDialogTitle">moreNote Hello のログインに失敗しました</DialogTitle>
                <DialogActions>
                    <Button onClick={() => errCallback()} color="primary">OK</Button>
                </DialogActions>
            </Fragment>
        );
    }

    const drawContent = useMemo(() => {
        return (
            <Fragment>
                {!error ? drawLogin() : drawError()}
            </Fragment>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, password, error]);

    const drawLoginDialog = useMemo(() => {
        if(props.floorData === undefined) return;
        if(!props.floorData.morenoteDifferentPassword) return;
        
        return (
            <BaseDialog
                id="moreNoteLoginDialog"
                open={props.open}
                onClose={() => cancelCallback()}
                aria-labelledby='moreNoteLoginDialogTitle'
                maxWidth={'sm'}
            >
                {drawContent}
            </BaseDialog>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, account, password, error]);

    const drawErrorDialog = useMemo(() => {
        return (
            <BaseDialog
                id="sessonTimeoutAlert"
                open={showErrorDialog}
                // onClose={() => cancelCallback()}
                aria-labelledby='sessonTimeoutAlertTitle'
                maxWidth={'sm'}
            >
                <DialogContent>{
                    errorMessage.split("\n").map((row: string, index: number) => {
                        if (index === 0) {
                            return (<span key={'msg-row-' + index}>{row}</span>);
                        } else {
                            return (<span key={'msg-row-' + index}><br />{row}</span>);
                        }
                    })
                }</DialogContent>
                <DialogActions>
                <Button onClick={handleClickSignin} color="primary" style={{ pointerEvents: 'auto' }} >サインイン</Button>
                </DialogActions>
            </BaseDialog>
        );
    }, [showErrorDialog, errorMessage]);

    const drawRedirect = useMemo(() => {
        if (redirect === '') {
            return <Fragment />
        } else {
            return <Redirect to={redirect} />
        }
    }, [redirect]);

    useEffect(() => {
        if(!props.open) return;

        console.log("requestHelloLogin");
        requestHelloLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.open]) 

    return (
        <Fragment>
            {drawLoginDialog}
            {drawErrorDialog}
            {drawRedirect}
            {drawConfirmReOpenHello}
        </Fragment>
    );
}

export const MoreNote = forwardRef(MoreNoteComponent);
import React, { useEffect, useMemo, useCallback } from 'react';
import SetMyAvatar from './SetMyAvatar';
import SetMySeat from './SetMySeat';
import Tubuyaki from './Tubuyaki';
import { AvatarCustomizeDialogRef } from './AvatarCustomizeDialog';
import ReleaseOtherSeat from './ReleaseOtherSeat';
import { MoveFloorRef } from './MoveFloor';
import Fab from '@material-ui/core/Fab';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { AvatarData, AvatarMenuData, FloorObject, Seat, FloorData, FlexibleNamePlate } from '../common/JsonClass';
import { Utility } from '../webrtc/Utility';
import { calcAvatarHeight, getAvatarVideoSize } from "../common/AvatarSize";
import DynamicIcon from '../common/DynamicIcon';
import { SELECT_NONE } from "../common/Constants";
import WebrtcService from '../webrtc/WebrtcService';
import { Tooltip } from '@material-ui/core';
import { isConstructorDeclaration } from 'typescript';
import { ThemeProvider } from '@material-ui/styles';
import JfsClient, { JfsError, User } from '@fsi/jfs-sdk';


//import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(-0.1), // = 8 * -0.1 = -0.8
        },
        position: "absolute",
    },
    fab: {
        "&$disabled": {
            backgroundColor: "#999999",
            color: "white"
        }
    },
    disabled: {},
    svgIconClass: {
        ...SELECT_NONE,
        position:'relative',
        marginBottom: '13px',
        color: 'white',
    },
    iconNoName: {
        ...SELECT_NONE,
        position:'relative',
    },
    svgIcon:{
        ...SELECT_NONE,
        position: 'absolute', 
        width:30,
        height:30,
        top:-5, 
        left:-3
    },
    avatarName:{
        ...SELECT_NONE,
        position: "absolute", 
        fontSize: 10, 
        top: 30, 
        left: 0,
        overflow: "hidden", 
        textOverflow: "ellipsis", 
        whiteSpace: "nowrap", 
        boxSizing: "border-box", 
        padding: "0 2px", 
        width: "100%",
        color: "white",
    }
}));

const fabTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#57BBFF",
        },
        secondary:{
            main: "#006FBC",
        },
    },
    overrides: {
        MuiFab: {
            primary: {
                "&:hover": {
                    backgroundColor: "#0095FA",
                },
            }
        },
    },
})

interface Props {
    top: number; // user中心のfloorに対する絶対Y座標
    left: number; // user中心のfloorに対する絶対X座標
    tubuyaki: string;
    user: User;
    avatarMenuDataList: AvatarMenuData[];
    floorId: number;
    onTubuyakiChange: any;
    onStateChange: (state: number, enableMeet: boolean) => void;
    onClickMoreNote: (isWhiteBoard: boolean) => void;
    onJumpToMySeat: () => void;
    onMoveFloor: (id: number) => void;
    onMyAvatarChange: (yes: boolean, value?: number) => void;
    onMySeatChange: (yes: boolean) => void;
    onMySeatRelease: (yes: boolean) => void;
    onOtherSeatRelease: (yes: boolean) => void;
    onClickUserInfo: () => void;
    onChangeWork: (isWork: boolean) => void;
    onChangeScreenShare: (isScreenShare: boolean) => void;
    onChangeWebRtcAllUsers: (isBroadcast:boolean, userId: number) => void;
    onForceMute: (isForceMute: boolean) => void;
    onClapping: () => void;
    onClickSearch: () => void;
    onCardDisclose: (isDisclose: boolean) => void;
    zIndex: number;
    isLarge: boolean;
    avatarDatas: AvatarData[];
    scale: number;
    // フロア単位での「ビデオ通話前の確認」
    onMyConfirmSettingChange: (value?: number) => void;
    // フロア単位での「ログイン通知」 //TODO?
    onChangeLoginoutNotification: (value?: number) => void;
    purposeOfUse: number;
    floorStartConfirmSetting: number;
    onPictograph:(state: number) => void;
    onOpenUsersControl: () => void;
    getFloorObjectFromId: (id: number) => FloorObject | undefined
    enabledBusinessCard: boolean;
    enabledTubuyaki: boolean;
    enabledMeet: boolean;
    enabledChat: boolean;
    floorData: FloorData,
    namePlateColors: FlexibleNamePlate[],
    onChangeNamePlate:(value: number) => void,
    requestPrivacyRoom: (floorId: number, userId: number, targetFloorId:number) => void;
    sendPrivacyRoomTimeOut: (kind: number, floorId: number) => void;
    sendMovePrivacyRoomInfo: (stayPrivacyroom: boolean, preFloorId: number, preFloorName: string) => void;
}

export class FunctionIds {
    public static StateChange: number = 1;
    public static Tubuyaki: number = 2;
    public static MoreNote: number = 3;
    public static JumpToMySeat: number = 4;
    public static ReleaseMySeat: number = 5;
    public static SetMySeat: number = 6;
    public static ClickUserInfo: number = 7;
    public static State: number = 8;
    public static StateEnableMeet: number = 9;
    public static ShowMoveFloor: number = 13;
    public static ShowSetMyAvatar: number = 14;
    public static GoToWork: number = 17;
    public static LeaveWork: number = 18;
    public static ConnectWebRtcAllUsers: number = 19;
    public static DisconnectWebRtcAllUsers: number = 20;
    public static ScreenShare: number = 21;
    public static StopScreenShare: number = 22;
    public static ForceMute: number = 23;
    public static ForceMuteOff: number = 24;
    public static Clapping:number = 25;
    public static SearchUser: number = 26;
    public static Pictograph: number = 27;
    public static CardUnDisclose: number = 28;
    public static CardDisclose: number = 29;
    public static UsersControl: number = 30;
    public static dressingUp: number = 31;
    public static Others: number = 32;
    public static ReleaseOtherSeat: number = 43; // 席解放 - officeからの移植(v2.4追加開発)
}

const apiBase = "/api/user";

export default function UserMenu(props: Props) {

    //　アバターメニュー情報を初期化する
    const getAvatarMenuList = useCallback((id: number) => {

        let avatarMenuList: AvatarMenuData[] = [];

        if (props.avatarMenuDataList.length) {
            // myUserが会議室にいるかどうか
            const isMeetingRoom = !!props.user.webRtcRoomId && WebrtcService.getWebRtcRoomType(props.user.webRtcRoomId) === 2;

            // 同一シートかどうか
            const isSameSeat =  (seat1: Seat, seat2: Seat): boolean => {
                if(seat1 == null || seat2 == null) {
                    return false;
                }
                if(seat1.floorObjectId !== seat2.floorObjectId) {
                    return false;
                }
                if(seat1.seatNo !== seat2.seatNo) {
                    return false;
                }
                return true;
            }

            avatarMenuList = [...props.avatarMenuDataList.filter(item => item.parentId === id)];

            avatarMenuList =
              !props.user.webRtcCall && props.user.seat != null 
              // && props.user.seat.enabledSetMySeat    // 条件削除：不使用のためundifinedになってしまう
              && props.user.isSitOnDesk && isSameSeat(props.user.mySeat, props.user.seat)
                ? avatarMenuList.filter((item) => item.functionId !== 6)
                : avatarMenuList.filter((item) => item.functionId !== 5);

            avatarMenuList.sort((item1, item2) => {
                return item1.orderBy - item2.orderBy
            })

            // 権限レベルによってユーザーメニューの表示、非表示を決める
            const isHiddenMenu = (userLevel: number, authorityLevel: number): boolean => {
                if (userLevel == null || userLevel === 0) {
                    return false;
                }
                if (authorityLevel == null || authorityLevel === 0) {
                    return false;
                }
                if (userLevel >= authorityLevel) {
                    return false;
                }
                return true;
            }

            avatarMenuList.forEach(menu => {
                menu.disabled = false;
                menu.hidden = isHiddenMenu(props.user.userLevel, menu.authorityLevel);
                if (menu.hidden) {
                    return;
                }
                if (menu.functionId === 2) { // つぶやき
                    // DBの設定でつぶやきが無効になっている場合はhiddenをtrueにする
                    menu.hidden = !props.enabledTubuyaki;
                }
                if (menu.functionId === 4) { // 自席へ
                    menu.disabled = props.user.mySeat === null
                }
                // 自席解除または自席設定
                if (menu.functionId === 5 || menu.functionId === 6) {
                    // #11054 自席解除後、立ち歩いているときに自席設定ができる
                    //menu.disabled = props.user.webRtcCall ? true : (props.user.isSitOnDesk ? false : (props.user.seat == null ? true : false))
                    // menu.disabled = props.user.seat?.isMeetingSeat ? true : (props.user.isSitOnDesk ? false : (props.user.seat == null ? true : (!props.user.seat.isSit) ? true : false))
                    // menu.disabled = (props.user.seat == null || props.user.seat?.isMeetingSeat || !props.user.seat.enabledSetMySeat && props.user.isSitOnDesk || !props.user.seat.isSit);    // officeの条件と合わせる
                    menu.disabled = props.user.seat?.isMeetingSeat
                      ? true
                      : props.user.isSitOnDesk
                      ? false
                      : props.user.seat == null
                      ? true
                      : !props.user.seat.isSit
                      ? true
                      : false;
                }
                // 出勤
                if (menu.functionId === 17) {
                    menu.hidden = props.user.isCommuting;
                }
                // 退勤
                if (menu.functionId === 18) {
                    menu.hidden = !props.user.isCommuting;
                }
                // 画面共有
                if (menu.functionId === 19) {
                    // !isMeetingRoom || 
                    menu.hidden = props.user.isNoVideo || props.user.isScreenShare || !props.user.webRtcCall || !WebrtcService.hasScreenShareFunctionCheck(navigator);
                }
                // 画面共有停止
                if (menu.functionId === 20) {
                    // !isMeetingRoom || 
                    menu.hidden = props.user.isNoVideo || !props.user.isScreenShare || !props.user.webRtcCall;
                }
                // 全体放送開始
                if (menu.functionId === 21) {
                    // お立ち台に立っている、且つ自分が全体放送していない、かつDBの通話設定が有効の場合は全体放送開始を表示する
                    menu.hidden = !props.user.isLargeVideo || (props.user.isLargeVideo && props.user.isBroadcast && props.user.isBroadcastSender) || !props.enabledMeet
                    // お立ち台に立っているけど、ほかのユーザが全体放送していた場合は禁止ボダンで表示する
                    menu.disabled = props.user.isLargeVideo && props.user.isBroadcast && !props.user.isBroadcastSender
                }
                // 全体放送終了
                if (menu.functionId === 22) {
                    // お立ち台に立っている、且つ自分が全体放送中が全体放送終了を表示
                    menu.hidden = !(props.user.isLargeVideo && props.user.isBroadcast && props.user.isBroadcastSender);
                }
                // 一斉ミュート
                if (menu.functionId === 23) {
                    menu.hidden = !props.user.isLargeVideo || props.user.isInForceMuteRoom; // お立ち台の時だけ表示
                }
                if( menu.functionId === 24 ){
                    menu.hidden = !props.user.isLargeVideo || !props.user.isInForceMuteRoom; // お立ち台の時だけ表示
                }
                // 拍手
                if (menu.functionId === 25) {
                    // 拍手ボタンを特定の人にだけ表示したい場合に使用する
                    // const isAuthUser = props.user.displayName === 'FSI永瀬' || props.user.displayName === 'FSI 岡本' || props.user.displayName === '事務局';
                    // menu.hidden = !isAuthUser;
                }
                // 名刺公開
                if (menu.functionId === 28) {
                    menu.hidden = !props.user.isCardDisclose || !props.enabledBusinessCard;
                }
                if (menu.functionId === 29) {
                    menu.hidden = props.user.isCardDisclose || !props.enabledBusinessCard;
                }
                // 聴衆設定
                if (menu.functionId === 30) {
                    // MyUser が Zoom 開始オブジェクト（type: 2000006）内にいる場合、ボタンを表示する
                    menu.hidden = !(props.getFloorObjectFromId(props.user.stayObjectId)?.objectMaster.type === 2000006)
                }
                // 席解放
                if (menu.functionId === 43) {
                    const isAdmin = props.user.role === undefined ? false : props.user.role.indexOf("ROLE_ADMIN") !== -1;
                    menu.hidden = !isAdmin;  // ROLE_ADMINでなければ表示しない
                    if(props.user.seat === null || props.user.seat.isMeetingSeat){
                        menu.disabled = true;
                    } else if (props.user.isSitOnDesk || props.user.seat.isSit){
                        menu.disabled = !props.user.seat.isViewName || props.user.seat.viewText === "";  // 座っていて、席札が出ていたら表示 (自席に座っている場合は席札が出ない)
                    } else {
                        menu.disabled = true;
                    }
                }
            })
        }
        return avatarMenuList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.avatarMenuDataList, props.user.isScreenShare, props.user.webRtcCall, props.user.webRtcRoomId, props.user.stayObjectId, props.user.isCardDisclose]);

    const [menu, setMenu] = React.useState(getAvatarMenuList(0));
    const [showTubuyakiDialog, setShowTubuyakiDialog] = React.useState(false);
    const [showAvatarCustomizeDialog, setShowAvatarCustomizeDialog] = React.useState(false);
    const [showSetMyAvatarDialog, setShowSetMyAvatarDialog] = React.useState(false);
    const [showSetMySeatDialog, setShowSetMySeatDialog] = React.useState(false);
    const [showReleaseMySeatDialog, setShowReleaseMySeatDialog] = React.useState(false);
    const [showReleaseOtherSeatDialog, setShowReleaseOtherSeatDialog] = React.useState(false);
    const [showMoveFloorDialog, setShowMoveFloorDialog] = React.useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient, JFS_ERRORS } = jfsClient;
    const classes = useStyles();

    useEffect(() => {
        setMenu(getAvatarMenuList(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.isScreenShare, props.user.webRtcCall, props.user.webRtcRoomId]);

    // useLayoutEffect(() => {
    //     menu.forEach(m => {
    //         // SVGアイコン
    //         if (m.iconPath.endsWith(".svg"))
    //             axios.get(`${apiBase}/avatarmenu/picture/${m.avatarMenuMasterId}`)
    //     });
    // }, [menu]);

    // avatar要素の高さ
    const avatarHeight = useMemo(() => {
        return calcAvatarHeight(props.user.height, props.isLarge);
    }, [props.user.height, props.isLarge]);

    // avatarの映像サイズ
    const avatarVideoHeight = useMemo(() => {
        // ビデオ拡大のときだけサイズを返す
        return (props.user.isLargeVideo && props.user.webRtcCall && !props.user.isMediaWaiting)
            ? 0.9 * getAvatarVideoSize(props.user.isLargeVideo) // 0.9は微調整
            : 0;
    }, [props.user.isLargeVideo, props.user.webRtcCall, props.user.isMediaWaiting]);

    // 表示されているメニュー項目の数
    const visibleMenuLength = useMemo(() => {
        return menu.filter(m => m.hidden === false).length;
    }, [menu]);

    const TOP = React.useMemo(() => {
        const wrapper = document.getElementById("floorWrapper") as HTMLDivElement;
        const nameHeight = 20; // 名前要素の高さ

        let calcTop = props.top - wrapper.scrollTop / props.scale;
        if (calcTop < 110) {
            calcTop = calcTop + avatarHeight + nameHeight; // 名前要素の下に配置
        } else {
            calcTop = calcTop - 75 - avatarVideoHeight; // 状態表示要素の上に配置
        }
        return calcTop + wrapper.scrollTop / props.scale;
    }, [props.top, avatarHeight, avatarVideoHeight]);

    const LEFT = React.useMemo(() => {
        const wrapper = document.getElementById("floorWrapper") as HTMLDivElement;
        const LEFT_MARGIN = 5;
        const RIGHT_MARGIN = 7;

        // メニュー1個当たり幅
        // 8 * 0.1 は、classes.rootの「theme.spacing(-0.1)」の値
        const UNIT_WIDTH = 56 - 8 * 0.1 * 2;

        // メニュー全体幅
        const menuWidth = UNIT_WIDTH * (visibleMenuLength);

        // 基準位置（prop.left - wrapper.scrollLeft）から、メニュー全体幅の半分を引いた位置
        let calcLeft = props.left - wrapper.scrollLeft /props.scale - menuWidth / 2;

        // 左端
        if (calcLeft < 0) {
            calcLeft = LEFT_MARGIN;
        }
        // 右端
        if (calcLeft > (wrapper.offsetWidth / props.scale - visibleMenuLength * UNIT_WIDTH - RIGHT_MARGIN)) {
            calcLeft = wrapper.offsetWidth / props.scale - visibleMenuLength * UNIT_WIDTH - RIGHT_MARGIN;
        }
        return calcLeft + wrapper.scrollLeft / props.scale;
    }, [props.left, visibleMenuLength]);

    const handleTubuyakiChange = (done: boolean, text: string) => {
        setShowTubuyakiDialog(false);
        props.onTubuyakiChange(done ? text : "");
    }

    const handleClickSetMyAvatar = (yes: boolean, value?: number) => {
        setShowSetMyAvatarDialog(false);
        props.onMyAvatarChange(yes, value);
    }

    // ビデオ通話前の確認の設定変更ハンドラ
    const handleChangeSetting = (value?: number) => {
        setShowSetMyAvatarDialog(false);
        console.log("ビデオ通話前の確認設定変更された:"+value)
        props.onMyConfirmSettingChange(value);
    }

    // ログイン通知の設定変更ハンドラ
    const handleChangeLoginoutNotification = (value?: number) => {
        setShowSetMyAvatarDialog(false);
        console.log("ログイン通知の確認設定変更された:"+value)
        props.onChangeLoginoutNotification(value);
    }
    
    const handleClickSetMySeat = (yes: boolean) => {
        setShowSetMySeatDialog(false);
        props.onMySeatChange(yes);
    }

    const handleClickReleaseMySeat = (yes: boolean) => {
        setShowReleaseMySeatDialog(false);
        props.onMySeatRelease(yes);
    }

    const handleClickReleaseOtherSeat = (yes: boolean) => {
        setShowReleaseOtherSeatDialog(false);
        props.onOtherSeatRelease(yes);
    }

    const handleMoveFloor = (id: number) => {
        setShowMoveFloorDialog(false);
        props.onMoveFloor(id);
    }

    const handleFunctionIds = ((id: FunctionIds, menuId: number) => {
        switch (id) {
            case FunctionIds.StateChange:
                return;
            case FunctionIds.Tubuyaki:
                return setShowTubuyakiDialog(true);
            case FunctionIds.MoreNote:
                return props.onClickMoreNote(false);
            // case FunctionIds.MoreNoteWhiteBoard: 
            //     return props.onClickMoreNote(true);
            case FunctionIds.JumpToMySeat:
                return props.onJumpToMySeat();
            case FunctionIds.ReleaseMySeat:
                return setShowReleaseMySeatDialog(true);
            case FunctionIds.SetMySeat:
                return setShowSetMySeatDialog(true);
            case FunctionIds.ClickUserInfo:
                return props.onClickUserInfo();
            case FunctionIds.State:
                return props.onStateChange(menuId, false);
            case FunctionIds.StateEnableMeet:
                return props.onStateChange(menuId, true);
            // case FunctionIds.state:
            //     return props.onStateChange(menuId);
            // case FunctionIds.state:
            //     return props.onStateChange(menuId);
            // case FunctionIds.state:
            //     return props.onStateChange(menuId);
            // case FunctionIds.state:
            //     return props.onStateChange(menuId);
            case FunctionIds.ShowMoveFloor:
                return setShowMoveFloorDialog(true);
            case FunctionIds.ShowSetMyAvatar:
                return setShowSetMyAvatarDialog(true);
            // case FunctionIds.state:
            //     return props.onStateChange(menuId);
            // case FunctionIds.state:
            //     return props.onStateChange(menuId);
            case FunctionIds.GoToWork:
                return props.onChangeWork(true);
            case FunctionIds.LeaveWork:
                return props.onChangeWork(false);
            case FunctionIds.ScreenShare:
                return props.onChangeScreenShare(true);
            case FunctionIds.StopScreenShare:
                return props.onChangeScreenShare(false);
            case FunctionIds.ConnectWebRtcAllUsers:
                return props.onChangeWebRtcAllUsers(true, props.user.id);
            case FunctionIds.DisconnectWebRtcAllUsers:
                return props.onChangeWebRtcAllUsers(false, props.user.id);
            case FunctionIds.ForceMute:
                return props.onForceMute(true);
            case FunctionIds.ForceMuteOff:
                return props.onForceMute(false);
            case FunctionIds.Clapping:
                return props.onClapping();
            case FunctionIds.SearchUser:
                return props.onClickSearch();
            case FunctionIds.Pictograph:
                return props.onPictograph(menuId);
            case FunctionIds.CardUnDisclose:
                return props.onCardDisclose(false);
            case FunctionIds.CardDisclose:
                return props.onCardDisclose(true);
            case FunctionIds.UsersControl:
                return props.onOpenUsersControl();
            case FunctionIds.dressingUp:
                return setShowAvatarCustomizeDialog(true);
            case FunctionIds.ReleaseOtherSeat:
                return setShowReleaseOtherSeatDialog(true);
            default:
                return;
        }
    })

    // アバターメニューアイコンをクリックイベント
    const handleClickAvatarMenu = (avatarMenu: AvatarMenuData) => {
        const subMenuList = getAvatarMenuList(avatarMenu.avatarMenuId);
        if (subMenuList.length) {
            setMenu(subMenuList)
            return;
        }
        switch (avatarMenu.functionId) {
            case 1:  // 状況設定
                handleFunctionIds(FunctionIds.StateChange, avatarMenu.avatarMenuMasterId);
                break;
            case 2:  // つぶやく
                handleFunctionIds(FunctionIds.Tubuyaki, avatarMenu.avatarMenuMasterId);
                break;
            case 3:  // 書類
                handleFunctionIds(FunctionIds.MoreNote, avatarMenu.avatarMenuMasterId);
                break;
            case 4:  // 自席へ
                handleFunctionIds(FunctionIds.JumpToMySeat, avatarMenu.avatarMenuMasterId);
                break;
            case 5:  // 自席解除
                handleFunctionIds(FunctionIds.ReleaseMySeat, avatarMenu.avatarMenuMasterId);
                break;
            case 6:  // 自席設定
                handleFunctionIds(FunctionIds.SetMySeat, avatarMenu.avatarMenuMasterId);
                break;
            case 7:  // 状態表示
                handleFunctionIds(FunctionIds.ClickUserInfo, avatarMenu.avatarMenuMasterId);
                break;
            case 8:  // 状況設定・子メニュー
                handleFunctionIds(FunctionIds.State, avatarMenu.avatarMenuMasterId);
                break;
            case 9:  // 状況設定・会話可能子メニュー
                handleFunctionIds(FunctionIds.StateEnableMeet, avatarMenu.avatarMenuMasterId);
                break;
            // case 9:   // 会議中
            //     handleFunctionIds(FunctionIds.Meeting, avatarMenuId);
            //     break;
            // case 10:  // 電話中
            //     handleFunctionIds(FunctionIds.Calling, avatarMenuId);
            //     break;
            // case 11:  // 外出中
            //     handleFunctionIds(FunctionIds.OutNow, avatarMenuId);
            //     break;
            // case 12:  // 離席中
            //     handleFunctionIds(FunctionIds.OutSeatNow, avatarMenuId);
            //     break;
            case 13:  // フロア
                handleFunctionIds(FunctionIds.ShowMoveFloor, avatarMenu.avatarMenuMasterId);
                break;
            case 14:  // 設定
                handleFunctionIds(FunctionIds.ShowSetMyAvatar, avatarMenu.avatarMenuMasterId);
                break;
            // case 15:  // いいね
            //     handleFunctionIds(FunctionIds.SayGood, avatarMenuId);
            //     break;
            // case 16:  // だめね
            //     handleFunctionIds(FunctionIds.SayBad, avatarMenuId);
            //     break;
            case 17:  // 出勤
                handleFunctionIds(FunctionIds.GoToWork, avatarMenu.avatarMenuMasterId);
                break;
            case 18:  // 退勤
                handleFunctionIds(FunctionIds.LeaveWork, avatarMenu.avatarMenuMasterId);
                break;
            case 19:  // 画面共有
                handleFunctionIds(FunctionIds.ScreenShare, avatarMenu.avatarMenuMasterId);
                break;
            case 20:  // 画面共有停止
                handleFunctionIds(FunctionIds.StopScreenShare, avatarMenu.avatarMenuMasterId);
                break;
            case 21:  // 全体放送開始
                handleFunctionIds(FunctionIds.ConnectWebRtcAllUsers, avatarMenu.avatarMenuMasterId);
                break;
            case 22:  // 全体放送終了
                handleFunctionIds(FunctionIds.DisconnectWebRtcAllUsers, avatarMenu.avatarMenuMasterId);
                break;
            case 23:  // 一斉ミュート
                handleFunctionIds(FunctionIds.ForceMute, avatarMenu.avatarMenuMasterId);
                break;
            case 24:  // 一斉ミュート解除
                handleFunctionIds(FunctionIds.ForceMuteOff, avatarMenu.avatarMenuMasterId);
                break;
            case 25:  // 拍手
                handleFunctionIds(FunctionIds.Clapping, avatarMenu.avatarMenuMasterId);
                break;
            case 26:  // 探す
                handleFunctionIds(FunctionIds.SearchUser, avatarMenu.avatarMenuMasterId);
                break;
            case 27: // 絵文字表示
                handleFunctionIds(FunctionIds.Pictograph, avatarMenu.avatarMenuMasterId);
                break;
            case 28: // 名刺非公開を設定
                handleFunctionIds(FunctionIds.CardUnDisclose, avatarMenu.avatarMenuMasterId);
                break;
            case 29: // 名刺公開を設定
                handleFunctionIds(FunctionIds.CardDisclose, avatarMenu.avatarMenuMasterId);
                break;
            case 30: // ユーザーコントロール
                handleFunctionIds(FunctionIds.UsersControl, avatarMenu.avatarMenuMasterId);
                break;
            case 31: // きせかえ
                handleFunctionIds(FunctionIds.dressingUp, avatarMenu.avatarMenuMasterId);
                break;
            case 43: // 席解放
                handleFunctionIds(FunctionIds.ReleaseOtherSeat, avatarMenu.avatarMenuMasterId);
                break;
            default:
                console.log(`functionId: ${avatarMenu.functionId} is null`);
        }
    }

    const drawTubuyakiDialog = useMemo(() => {
        return (
            <Tubuyaki open={showTubuyakiDialog} tubuyaki={props.tubuyaki} onChange={handleTubuyakiChange} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTubuyakiDialog])

    const drawAvatarCustomizeDialog = useMemo(() => {
        return (
            <AvatarCustomizeDialogRef open={showAvatarCustomizeDialog} onClickClose={() => setShowAvatarCustomizeDialog(false)} onMyAvatarChange={props.onMyAvatarChange} backGroundObjectId={props.floorData.backgroundObject.id} avatarId={props.user.avatarId}/>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAvatarCustomizeDialog])

    const drawSetMyAvatarDialog = useMemo(() => {
        // ビデオ通話前の確認の設定用
        let myStartConfirmSetting: number = props.user.avatarConfirmSetting;
        if(props.floorStartConfirmSetting === 10) {
            myStartConfirmSetting = 0;
        } else if(props.floorStartConfirmSetting === 11) {
            myStartConfirmSetting = 1;
        }

        let myLoginoutNotificationSetting = props.user.avatarLoginoutNotification;
        let myLoginoutNotificationSoundSetting = props.user.avatarLoginoutNotificationSound;

        console.log("AAAAA myStartConfirmSetting=["+myStartConfirmSetting+"] props.floorStartConfirmSetting=["+props.floorStartConfirmSetting+"]");
        return (
            <SetMyAvatar open={showSetMyAvatarDialog} onClick={handleClickSetMyAvatar} avatarId={props.user.avatarId} avatarDatas={props.avatarDatas}
                onChangeSetting={handleChangeSetting}
                onChangeLoginoutNotification={handleChangeLoginoutNotification}
                myLoginoutNotificationSetting={myLoginoutNotificationSetting}
                myLoginoutNotificationSoundSetting={myLoginoutNotificationSoundSetting}
                purposeOfUse={props.purposeOfUse}
                floorStartConfirmSetting={props.floorStartConfirmSetting}
                myStartConfirmSetting={myStartConfirmSetting}
                user={props.user}
                avatarMenuDataList={props.avatarMenuDataList}
                floorData={props.floorData}
                namePlateColors={props.namePlateColors}
                onChangeNamePlate={props.onChangeNamePlate}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSetMyAvatarDialog, props.user.avatarConfirmSetting, props.floorStartConfirmSetting])

    const drawSetMySeatDialog = useMemo(() => {
        return (
            <SetMySeat open={showSetMySeatDialog} onClick={handleClickSetMySeat} isSetDialog={true} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSetMySeatDialog])

    const drawReleaseMySeatDialog = useMemo(() => {
        return (
            <SetMySeat open={showReleaseMySeatDialog} onClick={handleClickReleaseMySeat} isSetDialog={false} />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showReleaseMySeatDialog])

    const drawReleaseOtherSeatDialog = useMemo(() => {
        return (
            <ReleaseOtherSeat open={showReleaseOtherSeatDialog} onClick={handleClickReleaseOtherSeat} />
        );
    }, [showReleaseOtherSeatDialog])

    const drawMoveFloorDialog = useMemo(() => {
        return (
            <MoveFloorRef open={showMoveFloorDialog} floorId={props.floorId} onMoveFloor={handleMoveFloor} myRole={props.user.role} myState={props.user.state} onJumpToMySeat={props.onJumpToMySeat} onStateChange={props.onStateChange} requestPrivacyRoom={props.requestPrivacyRoom} sendPrivacyRoomTimeOut={props.sendPrivacyRoomTimeOut} sendMovePrivacyRoomInfo={props.sendMovePrivacyRoomInfo} ></MoveFloorRef>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMoveFloorDialog])

    useEffect(() => {
        const target = document.getElementById("UserMenuDiv") as HTMLDivElement;
        target.addEventListener('pointerdown', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('touchmove', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('pointermove', (e) => Utility.forbiddenTattle(e), false);
        target.addEventListener('pointerup', (e) => Utility.forbiddenTattle(e), false);

        return function cleanup() {
            target.removeEventListener('pointerdown', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('touchmove', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('pointermove', (e) => Utility.forbiddenTattle(e), false);
            target.removeEventListener('pointerup', (e) => Utility.forbiddenTattle(e), false);
        }
    }, [])

    const drawIcon = ({ iconPath, avatarMenuId, avatarMenuMasterId, avatarName }: AvatarMenuData) => {
        if (!iconPath) {
            return console.error(`icon path cannot be null, avatar_menu_id: ${avatarMenuId}`);
        }
        // SVGアイコン
        if (iconPath.endsWith(".svg")) {
            return (
                <div className={avatarName ? classes.svgIconClass : classes.iconNoName} style={{ width: '18px', height: '18px' }}>
                    {/* <img className={classes.svgIcon} src={`${apiBase}/avatarmenu/picture/${avatarMenuMasterId}`} alt="" /> */}
                    <img 
                        className={classes.svgIcon} 
                        // src={`${apiBase}/avatarmenu/picture/${avatarMenuMasterId}`} 
                        src={`${httpClient.createAvatarMenuImgUrl(avatarMenuMasterId)}`}
                        alt="" 
                    />
                </div>
            )
        }
        // Materialアイコン
        return (
            <div className={avatarName ? classes.svgIconClass : classes.iconNoName}>
                <DynamicIcon iconName={iconPath} />
            </div>
        )
    }

    const draw = useMemo(() => {
        if (menu == null) return;
        return (
            <div className={classes.root} style={{ top: TOP, left: LEFT, zIndex: props.zIndex,
//これを有効にすると、アバターメニューは拡大の影響なく同じサイズにすることが出来る。が、Fabの折り返し問題が表面化する。                
//                transform: 'scale('+ 1/props.scale +')', transformOrigin: '0% 0%'
                }}>
                {menu.map((spaction) => (
                    <ThemeProvider theme={fabTheme}>
                        <Fab
                            key={spaction.avatarMenuId}
                            variant="round"
                            size="large"
                            color="primary"
                            disabled={spaction.disabled}
                            onClick={() => handleClickAvatarMenu(spaction)}
                            classes={{ root: classes.fab, disabled: classes.disabled }}
                            style={{ display: spaction.hidden ? "none" : "" }}
                        >
                            <Tooltip title={spaction.avatarName}>
                                <div>
                                    {drawIcon(spaction)}
                                    <div className={classes.avatarName}>
                                        {spaction.avatarName} 
                                    </div>
                                </div>
                            </Tooltip>
                        </Fab>
                    </ThemeProvider>
                ))}
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.top, props.left, menu, TOP, LEFT, visibleMenuLength]);


    return (
        <div id="UserMenuDiv">
            {draw}
            {drawTubuyakiDialog}
            {drawAvatarCustomizeDialog}
            {drawSetMyAvatarDialog}
            {drawSetMySeatDialog}
            {drawReleaseMySeatDialog}
            {drawReleaseOtherSeatDialog}
            {drawMoveFloorDialog}
        </div>
    )
}

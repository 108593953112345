import React, { Fragment, useState, useEffect, useMemo, useCallback, useImperativeHandle, ForwardRefRenderFunction, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup } from '@material-ui/core';
import { Theme, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, createStyles, makeStyles } from '@material-ui/styles';
import JfsClient, { StudyPurposeMasterInfo, StudySubjectMasterInfo, StudyObjectSetting } from '@fsi/jfs-sdk';

const radioButtonTheme = createMuiTheme({
    palette: {
        primary:{
            main: "#006FBC",
        },
    },
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            background: '#57BBFF 0% 0% no-repeat padding-box',
            fontFamily: 'Hiragino Maru Gothic StdN',
            color: '#555555'
        },
        selectBox: {
            width: 140,
            height: 30,
            fontSize: 20,
            marginLeft: 10,
            marginRight: 15
        },
    }),
);

interface Props {
    className?: string,
    id: number,
    isOpen: boolean,
    onClose: () => void,
    isEnable: boolean,
    setIsEnable: (isEnable: boolean) => void,
    updateStudyObjectSetting: StudyObjectSetting,
}

export default function StudySeatSettingDialog(props: Props) {


    const {className, isOpen, isEnable, updateStudyObjectSetting} = props;
    const classes = useStyles();
    // 自習席機能の有効フラグ
    const [isEnableFlag, setIsEnableFlag] = useState(false);
    // 自習科目の設定
    const [isOpenEditSubject, setIsOpenEditSubject] = useState(false);
    const [subjectList, setSubjectList] = useState([] as StudySubjectMasterInfo[]);
    // 自習内容の設定
    const [isOpenEditPurpose, setIsOpenEditPurpose] = useState(false);
    const [purposeList, setPurposeList] = useState([] as StudyPurposeMasterInfo[]);
    // オブジェクトに設定されている初期値
    const [settingSubjects, setSettingSubjects] = useState([] as string[]);
    const [settingPurposes, setSettingPurposes] = useState([] as string[]);
    // 変更中の設定値
    const [selectedSubjects, setSelectedSubjects] = useState([] as string[]);
    const [selectedPurposes, setSelectedPurposes] = useState([] as string[]);
    

    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient } = jfsClient;


    // 初期表示処理
    useEffect(() => {

        getSetting(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSetting = async (initFlg : boolean) => {
        await httpClient.getStudyObjectSetting(sessionStorage.getItem("TABID") as string, props.id)
        .then((e: StudyObjectSetting) => {
            if(initFlg){
                if (e.enable) {
                    props.setIsEnable(true);
                    setIsEnableFlag(true);
                } else {
                    props.setIsEnable(false);
                    setIsEnableFlag(false);
                }
            }

            // 自習科目設定情報を取得
            setSettingSubjects(e.subjectList.split(","));
            setSelectedSubjects(e.subjectList.split(","));
            // 自習内容設定情報を取得
            setSettingPurposes(e.purposeList.split(","));
            setSelectedPurposes(e.purposeList.split(","));
            }).catch(err => {
                console.log(err);
            });
        }

    // 自習席情報更新
    useEffect(() => {
        if (updateStudyObjectSetting) {
            if (updateStudyObjectSetting.floorObjectId == props.id) {
                props.setIsEnable(updateStudyObjectSetting.enable);
                // 自習科目設定情報を取得
                setSettingSubjects(updateStudyObjectSetting.subjectList.split(","));
                // 自習内容設定情報を取得
                setSettingPurposes(updateStudyObjectSetting.purposeList.split(","));
                if (!isOpen) {
                    setIsEnableFlag(updateStudyObjectSetting.enable);
                }
                if (!isOpenEditSubject) {
                    setSelectedSubjects(updateStudyObjectSetting.subjectList.split(","));
                }
                if (!isOpenEditPurpose) {
                    setSelectedPurposes(updateStudyObjectSetting.purposeList.split(","));
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStudyObjectSetting]);

        
    const handleClickEditSubject = async () => {
        //  科目初期設定
        await getSetting(false).then(() => {

            httpClient.getStudySubjectMaster(sessionStorage.getItem("TABID") as string)
            .then((e: StudySubjectMasterInfo[]) => {
                // 非表示を追加
                const subjects: StudySubjectMasterInfo[] = [{
                    id: 0,
                    subjectName: "非表示",
                    displayOrder: 0,
                    graphColor: ""
                }]
                // その他は選択できない
                e.map(u => {
                    if (u.subjectName !== "その他") {
                        subjects.push(u);
                    }
                });
                setSubjectList(subjects);
            }).catch(err => {
                console.log(err);
            });

        // // 対象オブジェクトの現在の設定をセット
        // setSelectedSubjects(settingSubjects);

            setIsOpenEditSubject(true);
        }).catch(err => {
            console.log(err);
        });
        
    }

    const handleClickOkEditSubject = async () => {
        const zeros = selectedSubjects.filter(e => (Number(e) == 0));
        const nonZeros = selectedSubjects.filter(e => (Number(e) != 0));
        const sort =  [...nonZeros, ...zeros];
        const stringSubjectList = sort.join(',');
        // 対象オブジェクトの自習席機能の自習内容設定を更新
        // await httpClient.updateStudyObjectSubject(sessionStorage.getItem("TABID") as string, props.id, stringSubjectList)
        //     .then((e) => {
                
        //     }).catch(err => {
        //         console.log(err);
        //     });
        let studyObjectSetting  = new StudyObjectSetting();
        studyObjectSetting.floorObjectId = props.id;
        studyObjectSetting.subjectList = stringSubjectList;
        try {
            wsClient.sendUpdateStudyObjectSubject(studyObjectSetting);
        } catch (error) {
            console.info(error);
        }
        setSelectedSubjects(sort);
        setIsOpenEditSubject(false);
    }

    const handleClickCancelEditSubject = () => {
        setIsOpenEditSubject(false);
        setSelectedSubjects(settingSubjects);
    }

    const handleChangeSubject = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {value, name} = event.target;
        setSelectedSubjects(selectedSubjects.map((e, index) => {
            // 変更箇所のみ更新
            if (Number(name) == index + 1) {
                return value;
            }
            return e;
        }));
    }

    const isAlreadySet = (id: number, setList: string[], index: number) => {
        for (var i = 0; i < setList.length; i++) {
            if (Number(setList[i]) == id && i != index && id != 0) {
                return true;
            }
        }
        
        return false;
    }

    const drawEditSubject = useMemo(() =>
        <BaseDialog
            className={className}
            open={isOpenEditSubject}
            aria-labelledby='EditSubjectDialogTitle'
            maxWidth={'md'}
            PaperProps={{
                style: {
                    minWidth: 420,
                    border: "6px solid #57BBFF",
                    borderRadius: "25px",
                },
            }}
        >
            <DialogTitle id='EditSubjectDialogTitle' className={classes.dialogTitle} >自習科目の設定</DialogTitle>
            <DialogContent style={{ padding: "5px 25px 0px 25px" }}>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <div style={{ fontSize: 16 }}>一般ユーザーの画面に表示される自習目標ボタンを設定します。<br />
                        （非表示に設定したボタンは表示されません。）</div>
                    </Grid>
                </Grid>
                <DialogContentText style={{ marginBottom: 0, paddingTop: 18, fontSize: 20 }} >
                    <div style={{ textAlign: "center" }}>
                        {1}
                        <select name="1" className={classes.selectBox} onChange={handleChangeSubject} value={selectedSubjects[0]}>
                            {subjectList.filter(e => !isAlreadySet(e.id, selectedSubjects, 0))
                                        .map(u => (
                                <option key={u.id} value={u.id}>
                                    {u.subjectName}
                                </option>
                            ))}
                        </select>
                        {2}
                        <select name="2" className={classes.selectBox} onChange={handleChangeSubject} value={selectedSubjects[1]}>
                            {subjectList.filter(e => !isAlreadySet(e.id, selectedSubjects, 1))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.subjectName}
                                </option>
                            ))}
                        </select>
                        {3}
                        <select name="3" className={classes.selectBox} onChange={handleChangeSubject} value={selectedSubjects[2]}>
                            {subjectList.filter(e => !isAlreadySet(e.id, selectedSubjects, 2))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.subjectName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div style={{ textAlign: "center", marginTop: 10 }}>
                        {4}
                        <select name="4" className={classes.selectBox} onChange={handleChangeSubject} value={selectedSubjects[3]}>
                            {subjectList.filter(e => !isAlreadySet(e.id, selectedSubjects, 3))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.subjectName}
                                </option>
                            ))}
                        </select>
                        {5}
                        <select name="5" className={classes.selectBox} onChange={handleChangeSubject} value={selectedSubjects[4]}>
                            {subjectList.filter(e => !isAlreadySet(e.id, selectedSubjects, 4))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.subjectName}
                                </option>
                            ))}
                        </select>
                        {6}
                        <select name="6" className={classes.selectBox} onChange={handleChangeSubject} value={selectedSubjects[5]}>
                            {subjectList.filter(e => !isAlreadySet(e.id, selectedSubjects, 5))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.subjectName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        7 その他
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickOkEditSubject} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', borderRadius: '31px', color: '#FFFFFF', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>OK</Button>
                <Button onClick={handleClickCancelEditSubject} color='primary' style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isOpenEditSubject, subjectList, settingSubjects, selectedSubjects])

    const handleClickEditPurpose = async () => {
        // APIで設定可能な値を取得
        await getSetting(false).then(() => {

            httpClient.getStudyPurposeMaster(sessionStorage.getItem("TABID") as string)
            .then((e: StudyPurposeMasterInfo[]) => {
                // 非表示を追加
                const purposes : StudyPurposeMasterInfo[] = [{
                    id: 0,
                    purposeName: "非表示",
                    displayOrder: 0
                }]
                // その他は選択できない
                e.map(u => {
                    if (u.purposeName !== "その他") {
                        purposes.push(u);
                    }
                });
                setPurposeList(purposes);
            }).catch(err => {
                console.log(err);
            });

        // // 対象オブジェクトの現在の設定をセット
            // setSelectedPurposes(settingPurposes);

            setIsOpenEditPurpose(true);
        }).catch(err => {
            console.log(err);
        });
        
    }
    
    const handleClickOkEditPurpose = async () => {
        const zeros = selectedPurposes.filter(e => (Number(e) == 0));
        const nonZeros = selectedPurposes.filter(e => (Number(e) != 0));
        const sort =  [...nonZeros, ...zeros];
        const stringPurposeList = sort.join(',');
        // 対象オブジェクトの自習席機能の自習内容設定を更新
        // await httpClient.updateStudyObjectPurpose(sessionStorage.getItem("TABID") as string, props.id, stringPurposeList)
        //     .then((e) => {
                
        //     }).catch(err => {
        //         console.log(err);
        //     });
        let studyObjectSetting  = new StudyObjectSetting();
        studyObjectSetting.floorObjectId = props.id;
        studyObjectSetting.purposeList = stringPurposeList;
        try {
            wsClient.sendUpdateStudyObjectPurpose(studyObjectSetting);
        } catch (error) {
            console.info(error);
        }
        setSelectedPurposes(sort);
        setIsOpenEditPurpose(false);
    }

    const handleClickCancelEditPurpose = () => {
        setIsOpenEditPurpose(false);
        setSelectedPurposes(settingPurposes);
    }

    const handleChangePurpose = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {value, name} = event.target;
        setSelectedPurposes(selectedPurposes.map((e, index) => {
            // 変更箇所のみ更新
            if (Number(name) == index + 1) {
                return value;
            }
            return e;
        }));
    }

    const drawEditPurpose = useMemo(() =>
        <BaseDialog
            className={className}
            open={isOpenEditPurpose}
            aria-labelledby='EditPurposeDialogTitle'
            maxWidth={'md'}
            PaperProps={{
                style: {
                    minWidth: 420,
                    border: "6px solid #57BBFF",
                    borderRadius: "25px",
                },
            }}
        >
            <DialogTitle id='EditPurposeDialogTitle' className={classes.dialogTitle} >自習内容の設定</DialogTitle>
            <DialogContent style={{ padding: "5px 25px 0px 25px" }}>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <div style={{ fontSize: 16 }}>一般ユーザーの画面に表示される自習目標ボタンを設定します。<br />
                            （非表示に設定したボタンは表示されません。）</div>
                    </Grid>
                </Grid>
                <DialogContentText style={{ marginBottom: 0, paddingTop: 18, fontSize: 20 }} >
                    <div style={{ textAlign: "center" }}>
                        {1}
                        <select name = "1" className={classes.selectBox} onChange={handleChangePurpose} value={selectedPurposes[0]}>
                            {purposeList.filter(e => !isAlreadySet(e.id, selectedPurposes, 0))
                                        .map(u => (
                                <option key={u.id} value={u.id}>
                                    {u.purposeName}
                                </option>
                            ))}
                        </select>
                        {2}
                        <select name = "2" className={classes.selectBox} onChange={handleChangePurpose} value={selectedPurposes[1]}>
                            {purposeList.filter(e => !isAlreadySet(e.id, selectedPurposes, 1))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.purposeName}
                                </option>
                            ))}
                        </select>
                        {3}
                        <select name = "3" className={classes.selectBox} onChange={handleChangePurpose} value={selectedPurposes[2]}>
                            {purposeList.filter(e => !isAlreadySet(e.id, selectedPurposes, 2))
                                        .map(u => (
                                <option value={u.id}>
                                    {u.purposeName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        4 その他
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickOkEditPurpose} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', borderRadius: '31px', color: '#FFFFFF', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>OK</Button>
                <Button onClick={handleClickCancelEditPurpose} color='primary' style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isOpenEditPurpose, purposeList, settingPurposes, selectedPurposes])

    // 自習席ON/OFF機能
    const enableFlag = useMemo(() => {
            return (
                <Fragment>
                    <div style={{ fontFamily: "Hiragino Maru Gothic StdN" }}>
                        自習席ON/OFF機能
                    </div>
                    <FormControl component="fieldset" style={{ marginTop: 5, marginBottom: 5, fontSize: 20, padding: "0px 0px 0px 15px" }}>
                        <ThemeProvider theme={radioButtonTheme}>
                            <RadioGroup aria-label="ON/OFF機能" name="enableflagSetting" value={isEnableFlag} style={{ width: '400px', height: '50px' }} >
                                <FormControlLabel control={<Radio color="primary" />} checked={isEnableFlag === true} label="ON" onChange={() => setIsEnableFlag(true)} />
                                <FormControlLabel control={<Radio color="primary" />} checked={isEnableFlag === false} label="OFF" onChange={() => setIsEnableFlag(false)} />
                            </RadioGroup>
                        </ThemeProvider>
                    </FormControl>
                    <br />
                    <div style={{ fontFamily: "Hiragino Maru Gothic StdN" }}>
                        　※着席時に自習機能が使用可能になります。<br/>
                        　　OFFの場合、通常オブジェクトとして使用可能です。
                    </div>
                </Fragment>
            );

    }, [isEnableFlag]);

    // 自習席ラベル設定
    const settingLabel = useMemo(() => {
        return (
            <Fragment>
                <div style={{ fontFamily: "Hiragino Maru Gothic StdN" }}>
                    自習席ラベル設定
                </div>
                <DialogContent style={{marginTop: 5, marginBottom: 5}}>
                    <div style={{ textAlign: "center" }}>
                        <Button onClick={() => handleClickEditSubject()} variant='contained' size="medium" color="primary" style={{ marginRight: 10, pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '35px' }}>自習科目の設定</Button>
                        <Button onClick={() => handleClickEditPurpose()} variant='contained' size="medium" color="primary" style={{ marginRight: 10, pointerEvents: 'auto', backgroundColor: '#006FBC', color: '#FFFFFF', borderRadius: '35px' }}>自習内容の設定</Button>
                    </div>
                </DialogContent>
            </Fragment>
        );

    }, [isEnableFlag]);

    // OK
    const onOk = useCallback(async () => {
        // 対象オブジェクトの自習席機能の有効・無効を更新
        // await httpClient.updateStudyObjectEnable(sessionStorage.getItem("TABID") as string, props.id, isEnableFlag)
        //     .then((e) => {
        //         props.setIsEnable(isEnableFlag);
        //     }).catch(err => {
        //         console.log(err);
        //     });
        let studyObjectSetting  = new StudyObjectSetting();
        studyObjectSetting.floorObjectId = props.id;
        studyObjectSetting.enable = isEnableFlag;
        try {
            wsClient.sendUpdateStudyObjectEnable(studyObjectSetting);
            props.setIsEnable(isEnableFlag);
        } catch (error) {
            console.info(error);
        }
        props.onClose();
    }, [isEnableFlag]);

    // Cancel
    const onCancel = useCallback(() => {
        setIsEnableFlag(isEnable);
        props.onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnable]);

    // 描画
    const draw = useMemo(() =>
        <BaseDialog
            className={className}
            open={isOpen}
            onClose={() => props.onClose()}
            aria-labelledby='StudySeatSettingDialogTitle'
            maxWidth={'sm'}
            PaperProps={{
                style: {
                    minWidth: 420,
                    border: "6px solid #57BBFF",
                    borderRadius: "25px",
                },
            }}
        >
            <DialogTitle id='StudySeatSettingDialogTitle' className={classes.dialogTitle} >自習席設定</DialogTitle>
            <DialogContent style={{ padding: "5px 25px 0px 25px" }}>
                <DialogContentText style={{ marginBottom: "0px", paddingTop: "3px" }} >
                    {enableFlag}
                    <br />
                    {settingLabel}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} color='primary' style={{ pointerEvents: 'auto', backgroundColor: '#006FBC', borderRadius: '31px', color: '#FFFFFF', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>OK</Button>
                <Button onClick={onCancel} color='primary' style={{ pointerEvents: 'auto', border: '3px solid #A7A7A7', borderRadius: '31px', color: '#676767', width: 110, fontFamily: 'Hiragino Maru Gothic StdN' }}>キャンセル</Button>
            </DialogActions>
        </BaseDialog>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isOpen, isEnableFlag, isEnable])

    return (
        <Fragment>
            {draw}
            {drawEditSubject}
            {drawEditPurpose}
        </Fragment>
    )
}
/**
 * 2つのボタンがある共通ダイアログ
 */
import React, { forwardRef, useImperativeHandle, Fragment, ForwardRefRenderFunction, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@material-ui/core';

interface Props {
    title: string,
    message: string,
    closeButtonName: string,
    okButtonName: string,
    closeCallback: () => void,
    okCallback: () => void,
    enableCloseButton: boolean,
    dialogTitleStyle?: React.CSSProperties,
}

export interface OkCancelDialogHandler {
    open: () => void;
}

const OkCancelDialogComponect: ForwardRefRenderFunction<OkCancelDialogHandler, Props> = (props, ref) => {
    const [open, setOpen] = useState(false);
    const [t] = useTranslation();

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
    }))

    const handleClose = () => {
        setOpen(false);
        props.closeCallback();
    };

    const handleOk = () => {
        setOpen(false);
        props.okCallback();
    };

    const drawContentText = useMemo(() => {
        let splitText = props.message.split("#SPLIT#");

        return (
            splitText.map(e => {
                return (
                    <Typography variant="body2">{e}</Typography>
                )
            })
        )
    }, [props.message])

    const drawCancelButtoon = useMemo(() => {
        if(props.enableCloseButton === false) return;
        return (
            <Button onClick={handleClose} color="primary">
                {t(props.closeButtonName)}
            </Button>
        )
    }, [props.enableCloseButton])

    const draw = useMemo(() => {
        if (open === false) return;

        let setDialogStyle: React.CSSProperties;
        if(props.dialogTitleStyle === undefined){
            setDialogStyle = {
                backgroundColor: "#ef6c00",
                color: "white",
            }
        } else {
            setDialogStyle = props.dialogTitleStyle;
        }

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="ok-cancel-dialog-title"
                aria-describedby="ok-cancel-dialog-description"
            >
                <DialogTitle style={setDialogStyle} id="ok-cancel-dialog-title">{t(props.title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ok-cancel-dialog-description">
                        {drawContentText}
                        {/* <Typography variant="body2">{t(props.message)}</Typography> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary">
                        {t(props.okButtonName)}
                    </Button>
                    {drawCancelButtoon}
                </DialogActions>
            </Dialog>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, props, props.message, drawContentText, props.enableCloseButton])


    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}

export const OkCancelDialog = forwardRef(OkCancelDialogComponect);
import React, { Fragment, useState, useEffect, useMemo, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, } from 'react';
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import Grid from '@material-ui/core/Grid';
import ZIndex from "../common/ZIndex";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { EditorSnackBar, FloorObjectPaletteCategory } from '../common/JsonClass';
import axios, { AxiosResponse } from 'axios';
import { Box, Button, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Alert from '@material-ui/lab/Alert';
import BaseDialog from './BaseDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useWindowSize from '../common/useWindowSize';
//icons
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import JfsClient, { ObjectMaster, FloorData, FloorObject } from '@fsi/jfs-sdk';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        width:"20%",
        height:"85%", 
        backgroundColor:"#e8eaed", 
        borderRadius:3,
        position:'fixed', 
        top:100, 
        left:20, 
        zIndex: ZIndex.fixedUiLayer,
        minWidth:210,
    },
    strong: {
        width: "100%",
    },
    draggableArea: {
        borderRadius:3,
        backgroundColor: "#ffffff",
        height: "5%",
        //display: "table-cell",
    },
    ListArea: {
        maxHeight: '80%',
        overflow: 'auto',
    },
    header: {
        backgroundColor: "#ffffe4",
        border: "1px solid #d2d2d2",
        height: 35,
        padding: 10,
    },
    category: {
        backgroundColor: "#ffffff",
    },
    floorAlert: {
        position: 'fixed',
        zIndex: ZIndex.experiment,
    },
    resize: {
        height: 40,
    },
    listItem: {
        padding: 4,
    },
    listItemImageText: {
        fontWeight: 'bold',
        width:'100%', 
        height: 20
    }

}));

interface Props {
    floorEditMode: boolean,
    floorId: number,
    floorData: FloorData,
    sendWebSocket: (action: string, object: object) => void,
    deleteObj: () => void,
    floorResize: (floorHeight:number, floorWidth:number) => void,
    isFloorObjSelected: boolean,
    object: FloorObject,
}

export interface WindowHandler {
    open: () => void;
    close: () => void;
    editFloorSnackBar: (data: EditorSnackBar, message: string) => void;
    deleteFloorObject: () => void;
    noticeDialog: (dialogMessage: string) => void;
}

const NewFloorObjectWindowComponent: ForwardRefRenderFunction<WindowHandler, Props> = (props, ref) => {
    const [isDisplay, setDisplay] = useState("none");
    const [open, setOpen] = useState(0);
    const [objectMaster, setObjectMaster] = useState([] as ObjectMaster[]);
    const [category, setCategory] = useState([] as FloorObjectPaletteCategory[]);
    const [types, setTypes] = useState([0]);
    const [isDispNoticeAlert, setDsispNoticeAlert] = useState(false);
    const [isDispDeleteAlert, setDispDeleteAlert] = useState(false);
    const [isDispNoticeDialog, setDispNoticeDialog] = useState(false);
    const [noticeMessage, setNoticeMessage] = useState("");
    const [alertMessage, setMessage] = useState("");
    //const [floorDialogMessage, setDialogMessage] = useState("");
    const [dispName, setDispName] = useState("");
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [currentPosition, setCurrentPosition] = useState({x:0,y:0});
    const jfsClient = JfsClient.getInstance();
    const { httpClient, wsClient } = jfsClient;

    // コンポーネント内のメソッドを外部へ公開
    useImperativeHandle(ref, () => ({
        open: () => {
            setCurrentPosition({x:0, y:0});
            toggleDisplay();
            getObjectMasterList();
            getPaletteCategoryList();
        },
        close() {
        
        },
        editFloorSnackBar: (data: EditorSnackBar) => {
            snackbar(data);
        },
        deleteFloorObject: () => {
            setDispDeleteAlert(true);
        },
        noticeDialog: (dialogMessage: string) => {
            setNoticeMessage(dialogMessage);
            setDispNoticeDialog(true);
        },
    }));

    useEffect(() => {
        if(!props.floorEditMode) setDisplay("none");
        if(props.floorEditMode){
            setDisplay("block");
            getObjectMasterList();
            getPaletteCategoryList();
        }
    }, [props.floorEditMode]);

    const toggleDisplay = () =>{
        setCurrentPosition({x:0, y:0});
        setDisplay(isDisplay == "none" ? "block" : "none");
        //alert((props.testx / 2 + props.testScrollx) + " " + (props.testy / 2 + props.testScrolly)); //adjustScreenPosition参考
    }

    const getObjectMasterList = () => {
        if(isDisplay == "block") return;
        // axios
        // .create({
        //     withCredentials: true,
        // })
        // .post('/api/flooroperator/objectmaster/get', new URLSearchParams({ //
        //     tab_id: sessionStorage.getItem("TABID") as string,
        //     floor_id: props.floorId.toString(),
        // }))
        httpClient.getObjectMaster(sessionStorage.getItem("TABID") as string, props.floorId)
        .then((response: ObjectMaster[]) => {
            let temp = [...response];
            setObjectMaster(temp);
            // divideFloorObject(result)
        })
        .catch((err) => {
            //alert("neko");
        });
    }

    // apiでパレットに表示するカテゴリを取得
    const getPaletteCategoryList = () => {
        if(isDisplay == "block") return;
        // axios
        // .create({
        //     withCredentials: true,
        // })
        // .post('/api/flooroperator/palettecategory/get', new URLSearchParams({
        //     tab_id: sessionStorage.getItem("TABID") as string,
        // }))
        httpClient.getPalettecategory(sessionStorage.getItem("TABID") as string)
        .then((response: FloorObjectPaletteCategory[]) => {
            let result = response;
            setCategory(result);
        })
        .catch((err) => {
            //alert("neko");
        });
    }

    const onDrag = (e: DraggableEvent, data: DraggableData) => {
        setCurrentPosition({x:data.lastX,y:data.lastY});
    }

    // 1000000:会話できない部屋（長机とか）  
    // 2000000:会議室（会話できる）  
    // 3000000:その他（立て看板とか） 

    const handleOpen = (type : number) => {
        if (type === open) {
            setOpen(0);
            return;
        }
        setOpen(type);
    };

    const snackbar = (data: EditorSnackBar) => {
        if(data.type){
            setDsispNoticeAlert(true);
            setDispName(data.editor);
            setMessage(data.editor+"さんがフロアを編集中です");
        }else{
            setDsispNoticeAlert(false);
            setDispName("");
            setMessage("");
        }
    }

    //オブジェクトのタイプとリストでの表示名を紐づける 
    // DBにカテゴリ名とtypeを対応させるテーブルを追加する　4000000
    // 現状のobjectmasterの3000003を4000000に書き換えるバッチを追加

    //floor sizeを変更する
    const floorResize = (direction:string, amount:number) => {
        var editedFloor = props.floorData;
        var floorHeight = editedFloor.floorHeight;
        var floorWidth = editedFloor.floorWidth;
        
        if(direction == 'bottom'){
            floorHeight += amount;
        }
        if(direction == 'right'){
            floorWidth += amount;
        }
        //props.sendWebSocket("FLOOR_RESIZE", {floorHeight, floorWidth});
        try {
            wsClient.sendFloorResize(floorHeight, floorWidth);
        } catch (error) {
            console.error(error);            
        }
    }

    const handleClickOk = () => {
        setDispDeleteAlert(false);
        props.deleteObj();
    }

    //削除確認ダイアログ
    const deleteDialog = useMemo(() => {
        return (
            <BaseDialog
                open={isDispDeleteAlert}
                //onClose={}
                aria-labelledby='DeleteObjectDialog'
                //fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle>フロアエディター</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        本当に削除してもよろしいですか？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => setDispDeleteAlert(false)} style={{ pointerEvents: 'auto' }}>キャンセル</Button>
                    <Button color='primary' onClick={handleClickOk} style={{ pointerEvents: 'auto' }}>OK</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [isDispDeleteAlert]);

    //その他のダイアログ
    const noticeDialog = useMemo(() => {
        return (
            <BaseDialog
                open={isDispNoticeDialog}
                aria-labelledby='EditFloorNoticeDialog'
                maxWidth={'sm'}
            >
                <DialogTitle>フロアエディター</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {noticeMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => setDispNoticeDialog(false)} style={{ pointerEvents: 'auto' }}>OK</Button>
                </DialogActions>
            </BaseDialog>
        )
    }, [isDispNoticeDialog, noticeMessage]);


    const drawNoticeAlert = useMemo(() => {
        let centerWidth = window.innerWidth / 2 - 150;
           return (
                <Collapse in={isDispNoticeAlert} className={classes.floorAlert} style={{left: centerWidth, top: 20}}>
                    <Alert severity="warning">{alertMessage}</Alert>
                </Collapse>
            ) //エントランスの警告は赤
    }, [isDispNoticeAlert, dispName, alertMessage]);

    //　新規オブジェクトリスト描画
    const draw = useMemo(() => {
        return(
            <List component="nav" className={classes.ListArea}>
                <ListItem className={classes.header}>
                    <ListItemText primary={'オブジェクト'} primaryTypographyProps={{
                        style: {fontWeight: 'bold'}
                    }} />
                </ListItem>
                {category.map(o => {
                    return (
                        <Fragment>
                            {o.type === 3000106 ? <ListItem className={classes.header}>
                                <ListItemText primary={'オリジナルオブジェクト'} primaryTypographyProps={{
                                    style: { fontWeight: 'bold' }
                                }} />
                            </ListItem> : <></>}
                            <ListItem button onClick={() => handleOpen(o.type)} className={classes.category}>
                                <ListItemText primary={o.categoryName} />
                                {open === o.type ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={open === o.type} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {objectMaster.filter(om => // 装飾系は3000100（機能なし）、3000106（オリジナルオブジェクト）とそれ以外で分ける
                                                    o.type === 3000100 || o.type === 3000106 ? om.type === o.type : 
                                                    om.type !== 3000100 && om.type !== 3000106 && Number(om.type.toString().slice(0,-3)+"000") === o.type)
                                                 .sort((object1, object2) => {
                                                    if (object1.paletteOrder > object2.paletteOrder) return 1;
                                                    if (object1.paletteOrder < object2.paletteOrder) return -1;
                                                    return 0;
                                                 })
                                                 .map(om => { 
                                        return(
                                            <Fragment>
                                                <div className={classes.listItemImageText}>{om.paletteText}</div>
                                                <ListItem className={classes.listItem} button onClick={() => newFloorObject(om.id)}//() => newFloorObject(om.id)
                                                    style={{
                                                        height:120,
                                                        backgroundSize: "contain",
                                                        // backgroundImage: "url(./api/user/object/picture/" + om.id + ")",
                                                        backgroundImage: `url(${httpClient.createObjectImgUrl(om.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center center",
                                                    }}
                                                >
                                                   {/* <ListItemText 
                                                        secondary={om.paletteText}
                                                        classes={{ secondary: classes.listItemText }}
                                                        style={{
                                                            height:120,
                                                            backgroundSize: "contain",
                                                            backgroundImage: "url(./api/user/object/picture/" + om.id + ")",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center center",
                                                            floorList.map(floor => ({ ...floor, stay: false }))
              
                                                        }}
                                                   /> */}
                                                </ListItem>
                                                <Divider style={{marginTop: 10, marginBottom: 2}} />
                                            </Fragment>
                                        )
                                    })}
                                </List>
                            </Collapse>
                            <Divider/>
                        </Fragment>
                    )
                })}
                
            </List>
        )
    },[objectMaster, open, category, props.isFloorObjSelected])
            // <div>
            //     {objectMaster.map((id) => {
            //         return <div onClick={() => newFloorObject(id)}>{id}</div><ListItemText primary={om.id} />
            //     })}
            // </div>
    const windowSize = useWindowSize();
    //新しいフロアオブジェクトのobjectMasterIdを送信
    const newFloorObject = (objectMaster_id:number) => {
        if(props.object.id != 0)return;
        const parent = document.getElementById('floorWrapper') as HTMLDivElement;
        const { scrollTop, scrollLeft, offsetTop, offsetLeft } = parent;
        
        // var x = Math.round((props.testx - 300) / 2 + props.testScrollx);
        // var y = Math.round((props.testy - 300) / 2 + props.testScrolly);

        var x = Math.round(offsetLeft + scrollLeft + windowSize.width / 2);// windowSize.width
        var y = Math.round(offsetTop + scrollTop + windowSize.height / 2);// windowSize.height

        // props.sendWebSocket("NEW_FLOOR_OBJECT", {objectMaster_id, x, y});
        try {
            wsClient.sendNewFloorObject(objectMaster_id, x, y);
        } catch (error) {
            console.error(error);            
        }
    }

    return ( //ウィンドウのminを設定 200くらい？
        <Fragment>
            <Draggable handle="strong" position={currentPosition} onDrag={onDrag}> 
                <Box boxShadow={3} className={classes.root} style={{display: isDisplay}}>
                    <strong className={classes.strong}>
                        <div className={classes.draggableArea}>
                            <Grid container>
                                <Grid container item xs={10} alignContent="center">
                                    <p style={{margin:13}}>パレット</p>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={() => toggleDisplay()} style={{ backgroundColor: 'transparent' }} >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>
                    </strong>
                    <div>
                        フロアのサイズを変更
                    </div>
                    <div className={classes.resize}>
                        <Grid container>
                            <Grid item xs={3}>
                                <IconButton onClick={() => floorResize("right", 300)} color={'primary'} style={{ backgroundColor: 'transparent' }}>
                                    <KeyboardArrowRightIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton onClick={() => floorResize("right", -300)} color={'primary'} style={{ backgroundColor: 'transparent' }}>
                                    <KeyboardArrowLeftIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton onClick={() => floorResize("bottom", 300)} color={'primary'} style={{ backgroundColor: 'transparent' }}>
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton onClick={() => floorResize("bottom", -300)} color={'primary'} style={{ backgroundColor: 'transparent' }}>
                                    <KeyboardArrowUpIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider/>
                    {draw}
                </Box>
            </Draggable>
            {drawNoticeAlert}
            {deleteDialog}
            {noticeDialog}
        </Fragment>
    )
}

export const NewFloorObjectWindow = forwardRef(NewFloorObjectWindowComponent);

import { FC } from 'react';
import List from '@material-ui/core/List';
import UserListItem from './UserListItem';
import { DisplayUserInfo } from './specifics';

interface Props {
    list: DisplayUserInfo[];
    sendZoomSpeakable: (id: number, isZoomSpeakable: boolean) => void;
}

const UserList: FC<Props> = ({ list, sendZoomSpeakable }) => (
    <List style={{ width: '100%', height: '100%' }}>
        {list.map(user => <UserListItem key={user.id} {...user} sendZoomSpeakable={sendZoomSpeakable} />)}
    </List>
);

export default UserList;
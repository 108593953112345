import React, { Fragment, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import useReactRouter from 'use-react-router';
import axios, { AxiosResponse } from 'axios';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { userListConst } from './UserListConst';
import JfsClient from '@fsi/jfs-sdk';

// const MAX_LIST_LENGTH = 30;
const Message = {
    UNEXPECTED: userListConst.unexpectedMessage,
    REQUEST_ERROR: userListConst.requestError.message,
} as const;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '50%',
        minWidth: 300,
        maxWidth: 450,
    },
    cardContent: {
        padding: '0 40px',
    },
    cardActions: {
        paddingLeft: 40,
        paddingRight: 40,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    inputTextField: {
        width: '100%',
        background: '#FFFFFF',
        borderRadius: 4,
        color: '#A39F98',
    },
    errorMessage: {
        ...theme.typography.caption,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingCard: {
        width: 150,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

interface ResClapping {
    data: string;
}
/**
 * API Request
 */
// const sendClapping = async (tabId: string): Promise<AxiosResponse<ResClapping>> => {
//     try {
//         const res: AxiosResponse<ResClapping> = await axios.post('/api/v1/admin/tenant/clap',{ tabId });
//         return res;
//     } catch (err) {
//         throw err;
//     }
// }

/**
 * Utility
 */
export default function WhiteList() {
    const tabId = sessionStorage.getItem('TABID') || '';
    const classes = useStyles();
    const { history } = useReactRouter();

    const [clappingMsg, setClappingErrMsg] = useState<ReactNode>(null);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;
    /**
     * error handle
     */
    const handleClappingError = (err: any) => {
        const msgList: string[] = [];

        if (err.response) {
            if (err.response.status >= 500) {
                msgList.push(Message.UNEXPECTED);
            } else if (err.response.status === 403) {
                history.push({ pathname: '/' });
                return;
            } else {
                msgList.push(Message.REQUEST_ERROR);
            }
            setClappingErrMsg(drawError(msgList));
            return;
        } else if (err.request) {
            setClappingErrMsg(Message.REQUEST_ERROR);
            return;
        }
    }

    const drawError = useCallback((messageList: string[] = []) => {
        return (
            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                {
                    messageList.length > 0 &&
                    <ul style={{ margin: 5, paddingLeft: 10 }}>
                        {messageList.map(msg => <li>{msg}</li>)}
                    </ul>
                }
            </div>
        )
    }, []);

    // Request
    const sendClapping = async (tabId: string): Promise<ResClapping> => {
        try {
            // SDKにない
            // const res: AxiosResponse<ResClapping> = await axios.post('/api/v1/admin/tenant/clap',{ tabId });
            const res: ResClapping = await httpClient.sendClapping(tabId);
            return res;
        } catch (err) {
            throw err;
        }
    }

    /**
     * useEffect
     */
    useEffect(() => {
    }, []);

    /**
     * event handlers
     */
    const handleClickClapBtn = async () => {
        try {
            const res = await sendClapping(tabId);
            setClappingErrMsg(null);
        } catch (err) {
            console.error(err);
            handleClappingError(err);
        }
    }

    /**
     * parts draw
     */
    const drawClapButton = useMemo(() => {
        return (
            <Tooltip title='拍手'>
                <Button
                    variant='contained'
                    onClick={handleClickClapBtn}
                    color='primary'
                    name='enabledWhiteList'
                >拍手</Button>
            </Tooltip>
        )
    }, []);

    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Card className={classes.root}>
                <CardHeader
                    action={drawClapButton}
                    title='拍手'
                    titleTypographyProps={{
                        align: 'left',
                        variant: 'h6',
                    }}
                />
                {
                    clappingMsg &&
                    <CardContent classes={{ root: classes.cardContent }}>
                        <Typography align='left' variant='caption' color='error'>
                            {clappingMsg}
                        </Typography>
                    </CardContent>
                }
            </Card>
        </Grid>
    )
}
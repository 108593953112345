import React, { useState, useMemo, Fragment } from 'react';
import { FloorObject } from '../common/JsonClass';
import EditTextDialog from './EditTextDialog';
import ZIndex from "../common/ZIndex";
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '../img/edit_B.png';
import JfsClient from '@fsi/jfs-sdk';

interface Props {
    role: string, 
    className: string,
    floorObject: FloorObject,
    sendBookShelfUrl: (id: number, text: string, isPlayAudio: boolean) => void
}

export default function BookShelf(props: Props) {
    const { className } = props;
    const { id, offsetLeft, offsetTop, objectMaster, text1, text2 } = props.floorObject;
    const [isOpen, setIsOpen] = useState(false);
    const jfsClient = JfsClient.getInstance();
    const { httpClient } = jfsClient;

    const drawBookShelf = useMemo(() => {

        return (
        <div 
            onClick={() => {
                if(text2 != "") window.open(text2, "_blank");
            }}
            style={{
                position: 'absolute',
                background: 'transparent',
                padding: '15px 20px',
                margin: '0',
                boxSizing: 'border-box',
                width: '100%',
                height: '100%',
                textAlign: 'left',
                whiteSpace: 'pre-line',
                overflow: 'auto',
                cursor: 'pointer',
                zIndex: ZIndex.floorObject + 1,
            }}
            >
            &nbsp;
        </div>)
        }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [text2])

    /*
    * 編集アイコンの表示
    */
    const drawSettingIcon = useMemo(() => {

        let allowSetting = props.role !== undefined && props.role != "ROLE_USER";
        
        if(allowSetting) {
            return (
                <Fragment>
                    <IconButton onClick={() => setIsOpen(true)} disableTouchRipple={true} 
                        style={{
                            position: 'absolute',
                            right: -8,
                            top: 16,
                            zIndex: ZIndex.fixedUiLayer,
                            backgroundColor: 'white',
                            border: '1px solid black',
                            width: 11,
                            height: 11,
                        }}>
                        <img src={CreateIcon} style={{ width: 24, height: 24 }} />
                    </IconButton>
                </Fragment>
            )
        }
    }, 
    [props.role])
    
    const draw = useMemo(() => 
        <Fragment>
        <div
            key={`BookShelf${id}`}
            id={`BookShelf${id}`}
            className={className}
            style={{
                position: "absolute",
                left: offsetLeft,
                top: offsetTop,
                backgroundSize: "contain",
                //backgroundImage: `url(./api/user/object/picture/${objectMaster.id})`,
                backgroundImage: `url(${httpClient.createObjectImgUrl(objectMaster.id, undefined, sessionStorage.getItem("TABID") as string)})`,
                backgroundRepeat: "no-repeat",
                width: objectMaster.width,
                height: objectMaster.height,
                zIndex: ZIndex.floorObject,
            }}
        >
            {drawBookShelf}
            {drawSettingIcon}
            <EditTextDialog 
                className={className}
                floorObjectId={id}
                isOpen={isOpen}
                title={text1 ? `${text1}: URL` : 'URL'}
                text={text2}
                maxLength={500}
                onClose={() => setIsOpen(false)}
                sendText={props.sendBookShelfUrl}
            />
        </div>
        </Fragment>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [offsetLeft, offsetTop, objectMaster, text1, text2, isOpen, props.role]);
    
    return (
        <Fragment>
            {draw}
        </Fragment>
    )
}
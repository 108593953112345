import { useEffect, useMemo } from 'react';
import { useSpring, animated } from 'react-spring'

interface Props {
    isAnimate: boolean;
    width?: number | string;
}

export default function Circle001(props: Props) {
    const { isAnimate, width } = props;

    useEffect(() => {
        if (isAnimate) {
            setCssProps({
                from: {
                    opacity: 0.7,
                    backgroundColor: '#006FBC',
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnimate]);

    // useSpringで変化させたいstyleを定義
    const [cssProps, setCssProps] = useSpring(() => ({
        opacity: 0,
        backgroundColor: 'rgb(255,255,255)',
        delay: 400,
        config: {
            mass: 1,
            tension: 170,
            friction: 450,
        },
        reset: true // これがないとsetProps時のresetが効かない
    }));

    // interpolateの起点を定義
    const { origin } = useSpring({
        origin: 1,
        from: {
            origin: 0
        },
        config: {
            mass: 1,
            tension: 100,
            friction: 200,
        },
        reset: true
    });

    const component = useMemo(() =>
        <animated.div
            className='effect-circle-001'
            style={{
                userSelect: 'none',
                pointerEvents: 'none',
                boxSizing: 'border-box',
                borderRadius: '50%',
                visibility: isAnimate ? 'visible' : 'hidden',
                width: isAnimate ? (width || 36) : 0,
                height: isAnimate ? (width || 36) : 0,
                ...cssProps,// springで定義したstyleをマージ
                transform:
                    origin.interpolate({
                        range: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 1],
                        output: [1, 2.0, 4.0, 2.0, 2.2, 4.0, 2.0, 0]
                    }).interpolate(origin => `scale(${origin})`)
            }}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [isAnimate, width])

    return component;
};

